import ServiceBase from "./ServiceBase";
//import Globals from "../Globals";

export default class DomRfService extends ServiceBase {

    static async getSystemCalculations(filter) {
        var result = await this.requestJson({
            url: "api/DomRf/GetSystemCalculations",
            method: "POST",
            data: filter
        });

        return result;
    }

    static async getSystemCalculationsRange(dateFrom, dateTo) {
        var result = await this.requestJson({
            url: "api/DomRf/GetSystemCalculations",
            method: "GET",
            data: { dtFrom: dateFrom, dtTo: dateTo }
        });

        return result;
    }

    static async getBonds(query, existId) {
        var result = await this.requestJson({
            url: "api/DomRf/GetBonds",
            method: "GET",
            data: { query: query, existId: existId }
        });
        return result;
    }

    static async getAllBonds() {
        var result = await this.requestJson({
            url: "api/DomRf/GetAllBonds",
            method: "GET",
            data: null
        });

        return result;

    }
    static async calculateBondsStatistics(data) {
        var result = await this.requestJson({
            url: "api/DomRf/CalculateBondsStatistics",
            method: "POST",
            data: data
        });

        return result;
    }

    static async getBondData(date, id) {
        var result = await this.requestJson({
            url: "api/DomRf/GetBondData",
            method: "GET",
            data: { date: date, id: id }
        });
         //console.log('BondData', result)

        return result;
    }

    static async getChartData(id) {
        var result = await this.requestJson({
            url: "api/DomRf/GetChartsData",
            method: "GET",
            data: { id: id }
        });

        return result;
    }

    static async getBondsChartData(ids) {
        var result = await this.requestJson({
            url: "api/DomRf/GetBondsChartsData",
            method: "POST",
            data: ids
        });

        return result;
    }

    static async getSCurveData(date, min, max) {
        var result = await this.requestJson({
            url: "api/DomRf/GetSCurveData",
            method: "GET",
            data: { date: date, min: min, max: max }
        });

        return result;
    }

}