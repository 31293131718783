import * as React from 'react';

import { withStyles } from '@mui/styles';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
// import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';

import { DataGrid, GridOptions } from "../../framework/components/Table";
import { ColumnModel, SelectionType } from "../../framework/components/Table/Common";

import CalculatorService from "../../services/CalculatorService";
// import CommonService from "../../services/CommonService";
import SiteHelper from "../../shared/SiteHelper";
// import { CouponType } from "../../components/Definitions";

// import { DataSourceChangeType } from '../../framework/components/Common/Definitions';
import { /*AggregateFunctions,*/ ColumnDataType, ColumnSortDirection, TrimContentType } from '../../framework/components/Table/Common/Models/Column';

// import Localization from '../../components/Localization';
import { withTranslation } from "react-i18next";

interface IProps {
    show: false,
    onClose: any;
    onCancel: any;
    onOK: any;
    classes: any;
    t: any;
}

interface IState {
    isOkDisabled: boolean;
    selected: any[];
}

const styles = (theme: any) => ({
    root: {
        height: '100%'
    }
});

class CurvesParametersSelectDialog extends React.Component<IProps, IState> {

    constructor(props: any) {
        super(props);
        this.state = {
            isOkDisabled: true,
            selected: []
        }
    }

    componentDidUpdate(prevProps: IProps) {
        if (this.props.show !== prevProps.show) {
            if (this.props.show === false)
                this.setState({ isOkDisabled: true, selected: [] });
        }
    }

    //componentWillMount() {
        //this.setState({isOkDisabled: true, selected: []});
    //}

    onWindowClose = (event) => {
        if (this.props.onClose)
            this.props.onClose(event);
    }

    handleCancel = (event) => {

        if (this.props.onCancel)
            this.props.onCancel(event);

        this.onWindowClose(event);
    }

    handleOK = (event) => {
        if (this.props.onOK)
            this.props.onOK(event, this.state.selected);

        this.onWindowClose(event);
    }

    dataSourceQuery = async (request: any) => {

        var filter = SiteHelper.CreateFilter(request);

        var result = await CalculatorService.getZCYCCurves(filter);
        if (result.hasErrors) {
            throw ('Error while processing request');
        }

        //IGridPayload
        return {
            totalCount: result.value.count,
            payload: result.value.items
        };
    }

    handleRowClick = (row: any, selected: any[]) => {
        this.setState({ selected: selected, isOkDisabled: selected.length === 0 });
    }

    handleRowDoubleClick = (row: any) => {
        if (this.props.onOK)
            this.props.onOK(null, this.state.selected);

        this.onWindowClose(null);
    }

    columns = [
        new ColumnModel('id', { Label: this.props.t('ID'), DataType: ColumnDataType.INT, IsKey: true, Visible: false }),
        new ColumnModel('createdDate', { Label: this.props.t('CreatedDate'), DataType: ColumnDataType.DATE, Searchable: true, Filterable: true, Sortable: true, IsReadonly: true, SortDirection: ColumnSortDirection.DESCENDING, SortOrder: 1 }),
        new ColumnModel('name', { Label: this.props.t('Name'), DataType: ColumnDataType.STRING, Searchable: true, Filterable: true, Sortable: true }),
        new ColumnModel('description', { Label: this.props.t('Description'), DataType: ColumnDataType.STRING, Searchable: true, Filterable: true, Sortable: true, TrimContent: TrimContentType.ELLIPSIS })
    ];

    settings = { query: this.dataSourceQuery };

    gridOptions = new GridOptions({
        topPager: false,
        fixedHeader: true,
        fullHeight: true,
        canSearch: true,
        selectionType: SelectionType.TYPE_SINGLE
    });

    render() {

        const {
            classes,
            t
        } = this.props;

        return (

            <Dialog fullScreen onClose={this.onWindowClose} aria-labelledby="curves-parameters-dialog-title" open={this.props.show}>
                <MuiDialogContent className={classes.root}>
                    <DataGrid
                        gridName={t('CurvesParameters')}
                        dataSource={this.settings}
                        columns={this.columns}
                        gridOptions={this.gridOptions}
                        onRowClick={this.handleRowClick}
                        onRowDoubleClick={this.handleRowDoubleClick}
                    />
                </MuiDialogContent>
                <MuiDialogActions>
                    <Button variant="contained" color="primary" onClick={this.handleCancel}>
                        {t('Cancel')}
                    </Button>
                    <Button disabled={this.state.isOkDisabled} variant="contained" color="secondary" onClick={this.handleOK}>
                        {t('OK')}
                    </Button>
                </MuiDialogActions>
            </Dialog>
        );
    }
}

export default withStyles(styles)(withTranslation('translations')(CurvesParametersSelectDialog));