import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import AddIcon from '@mui/icons-material/Add';
import MinimizeIcon from '@mui/icons-material/Minimize';
import * as React from 'react';
import { ColumnModel } from '../Common';
import IDetailComponent from '../DataGridInterfaces/IDetailComponent';
import { useMasterDetails } from '../Hooks';

interface IProps {
    detail: React.ReactElement<IDetailComponent>;
    renderCells: any;
    style: React.CSSProperties;
    clickEvent: (ev: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => void;
    rowData: any;
    columns: ColumnModel[];
}

export const MasterDetailRow: React.FunctionComponent<IProps> = ({
    detail,
    renderCells,
    style,
    clickEvent,
    rowData,
    columns,
}) => {

    const [open, openDetails] = useMasterDetails();
    const openMasterDetails = () => {
        openDetails();
    };

    const childWithRowData = React.cloneElement(detail, { row: rowData });

    return (
        <>
            <TableRow
                hover={true}
                style={style}
                onClick={clickEvent}
            >
                <TableCell padding='checkbox' size='small' align='center'>
                    <IconButton size='small' onClick={openMasterDetails}>
                        {open ? <MinimizeIcon /> : <AddIcon />}
                    </IconButton>
                </TableCell>
                {renderCells}
            </TableRow>
            {open &&
            (
            <TableRow
                hover={true}
                style={style}
            >
                <TableCell colSpan={columns.length + 1}>
                    <Collapse in={open} timeout='auto' unmountOnExit={true}>
                        {childWithRowData}
                    </Collapse>
                </TableCell>
            </TableRow>
            )}
        </>
    );

};

export default MasterDetailRow;
