import { useState, useRef, useEffect } from 'react';
import { actionCreators as administrationStore } from "@Store/AdministrationStore";
import { Tabs, Tab } from "@mui/material";
import { ValidatorForm, TextValidator, SelectValidator, DatePickerValidator, AutocompleteComponent, TextInputComponent } from "../../../framework/components/Form";
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Localization from '@Components/Localization';
import TooltipInfoLabel from '../../../components/shared/TooltipInfoLabel';

import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next'
import { createStyles, makeStyles } from '@mui/styles'

import { Theme } from '@mui/material';

import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import UniversalInputField, { InputDataType } from '@Base/framework/components/Form/UniversalInputField'

const useStyles = makeStyles((theme: Theme) => createStyles({
    paddingBottom: {
        paddingBottom: '1rem'
    },
    paddingTop: {
        paddingTop: '1rem'
    },
    flex: {
        display: 'flex'
    }
}));

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    const classes = useStyles();

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`wrapped-tabpanel-${index}`}
            aria-labelledby={`wrapped-tab-${index}`}
            {...other}
        >
            {value === index && (
                <div className={classes.paddingTop}>{children}</div>
            )}
        </div>
    );
}

interface IState {

    mailType: string;

    /*
    userID?: number;
    groupID?: number;
    isActive: boolean;
    isExcludeFromList: boolean;
    name: string;
    outerUserEmail: string;
    receiver: string;
    adNewslettersFilter: string;
    monday: boolean;
    tuesday: boolean;
    wednesday: boolean;
    thursday: boolean;
    friday: boolean;
    saturday: boolean;
    sunday: boolean;
    */
}

interface IProps {

    row: any;
    onChange: any;
    onApplyChanges: any;
    onCancelChanges: any;
    open: boolean;
    title: string;
}

const MailingEditorPage = ({
    row,
    onChange,
    onApplyChanges,
    onCancelChanges,
    open,
    title
}: IProps) => {

    const classes = useStyles()
    const { i18n, t } = useTranslation()
    const dispatch = useDispatch();

    const editFormRef = useRef(null);
    const updatedFieldsRef = useRef({});

    const [localData, setLocalData] = useState<any>({
        userID: null,
        groupID: null,
        isActive: false,
        isExcludeFromList: false,
        mailType: '0',
        name: '',
        outerUserEmail: '',
        receiver: '',
        adNewslettersFilter: '',
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false
    });
    
    //const [userID, setUserID] = useState(null);
    //const [groupID, setGroupID] = useState(null);
    //const [mailType, setMailType] = useState(null);
    //const [isActive, setIsActive] = useState(true);
    //const [isExcludeFromList, setIsExcludeFromList] = useState(false);
    //const [name, setName] = useState('');
    //const [outerUserEmail, setOuterUserEmail] = useState('');
    //const [receiver, setReceiver] = useState('');
    //const [adNewslettersFilter, setAdNewslettersFilter] = useState('');
    //const [monday, setMonday] = useState(false);
    //const [tuesday, setTuesday] = useState(false);
    //const [wednesday, setWednesday] = useState(false);
    //const [thursday, setThursday] = useState(false);
    //const [friday, setFriday] = useState(false);
    //const [saturday, setSaturday] = useState(false);
    //const [sunday, setSunday] = useState(false);

    const {
        users,
        groups,
        departments,
    } = useSelector((state: any) => state.administration)

    //createState = () =>
    //{
    //var mailType = this.getMailType(this.props);
    //return {
    //userID: this.props.row.userID,
    //groupID: this.props.row.groupID,
    //mailType: mailType
    //isActive: this.props.row.isActive,
    //isExcludeFromList: this.props.row.isExcludeFromList,
    //name: this.props.row.name,
    //outerUserEmail: this.props.row.outerUserEmail,
    //receiver: this.props.row.receiver,
    //adNewslettersFilter: this.props.row.adNewslettersFilter,
    //monday: this.props.row.monday,
    //tuesday: this.props.row.tuesday,
    //wednesday: this.props.row.wednesday,
    //thursday: this.props.row.thursday,
    //friday: this.props.row.friday,
    //saturday: this.props.row.saturday,
    //sunday: this.props.row.sunday,
    //}
    //}

    useEffect(() => {

        //setUserID(row.userID);
        //setGroupID(row.groupID);
        //setMailType(getMailType(row));
        //setIsActive(row.isActive);
        //setIsExcludeFromList(row.isExcludeFromList);
        //setName(row.name);
        //setOuterUserEmail(row.outerUserEmail);
        //setReceiver(row.receiver);
        //setAdNewslettersFilter(row.adNewslettersFilter);
        //setMonday(row.monday);
        //setTuesday(row.tuesday);
        //setWednesday(row.wednesday);
        //setThursday(row.thursday);
        //setFriday(row.friday);
        //setSaturday(row.saturday);
        //setSunday(row.sunday);
        //if (localData?.mailType == null)
            setLocalData({ ...row, mailType: getMailType(row) });
        //else
            //setLocalData({ ...row, mailType: localData.mailType });

    }, [row]);

    const getMailType = (row: any): string => {
        if (row.userID != null)
            return '0'; //user
        if (row.adNewslettersFilter != null)
            return '1'; //AD
        if (row.outerUserEmail != null)
            return '2'; //outer
        return '0'; //user selection by default
    }

    /*
    const handleSelectChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        onChange(event.target.name, event.target.value);

        setLocalData({ ...localData, [event.target.name]: event.target.value });
        updatedFieldsRef.current[event.target.name] = event.target.value;
    };

    const handleCheckChange = (event) => {
        onChange(event.target.name, event.target.value);

        setLocalData({ ...localData, [event.target.name]: event.target.value });
        updatedFieldsRef.current[event.target.name] = event.target.value;
    }
    */

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {

        setLocalData({ ...localData, mailType: newValue });
        //updatedFieldsRef.current[event.target.name] = event.target.value;
    }

    const handleInputChange = (name: string, value: any) => {

        switch (name) {
            case 'groupID':
                {
                    if (groups != null) {

                        var index = groups.findIndex((item) => item.key == value);
                        if (index != -1) {
                            onChange('name', groups[index].value);
                        }
                    }
                }
                break;
            case 'userID':
                {
                    if (users != null) {

                        var index = users.findIndex((item) => item.key == value);
                        if (index != -1) {
                            onChange('receiver', users[index].value);
                        }
                    }
                }
                break;
        }

        onChange(name, value);

        setLocalData({ ...localData, [name]: value });
        updatedFieldsRef.current[name] = value;
    };

    const getUsers = (searchValue: string, existedValue: string) => {
        administrationStore.getUsers(searchValue, existedValue)(dispatch);
    }

    const getGroups = (searchValue: string, existedValue: string) => {
        administrationStore.getGroups(searchValue, existedValue)(dispatch);
    }

    const getDepartments = (searchValue: string, existedValue: string) => {
        //we are load full list, so, we don't neet to update it
        if (departments == null)
            administrationStore.getDepartments()(dispatch);
    }

    const handleSubmit = () => {
        //cleanup other tabls
        switch (localData.mailType) {
            case '0': //user
                {
                    onChange('adNewslettersFilter', '');
                    onChange('outerUserEmail', '');

                }
                break;
            case '1': //AD
                {
                    onChange('userID', '');
                    onChange('receiver', '');
                    onChange('isExcludeFromList', false);

                    onChange('outerUserEmail', '');
                }
                break;
            case '2': //outer Email
                {
                    onChange('userID', '');
                    onChange('receiver', '');
                    onChange('isExcludeFromList', false);


                    onChange('adNewslettersFilter', '');
                }
                break;
        }

        onApplyChanges(localData, updatedFieldsRef.current);
    }

    const onValidateChanges = (event) => {
        editFormRef.current.submit();
    }

    const onCancelChanges_Local = (event: any, reason: string) => {
        if (reason == 'backdropClick') {
            event.preventDefault();
            return;
        }

        onCancelChanges(event);
    }

    const daysOfWeek = [
        { name: "monday", description: t('Monday') },
        { name: "tuesday", description: t('Tuesday') },
        { name: "wednesday", description: t('Wednesday') },
        { name: "thursday", description: t('Thursday') },
        { name: "friday", description: t('Friday') },
        { name: "saturday", description: t('Saturday') },
        { name: "sunday", description: t('Sunday') }
    ];

    return (
        <Dialog
            /*disableBackdropClick*/
            disableEscapeKeyDown
            fullWidth
            maxWidth='sm'
            open={open}
            onClose={onCancelChanges_Local}>
            <DialogTitle>Редактировать</DialogTitle>
            <DialogContent>
                <ValidatorForm ref={editFormRef} onSubmit={handleSubmit}>
                    <Grid container sx={{ paddingBottom: '1rem' }}>
                        <Grid xs={12}>
                            <UniversalInputField
                                name='isActive'
                                dataType={InputDataType.BOOLEAN}
                                handleValueChange={handleInputChange}
                                locale={Localization}
                                label='Рассылка активна'
                                tooltip='Определяет, будет ли участвовать в рассылке данный набор пользователей этой группы рассылки'
                                value={localData.isActive}
                                fullWidth
                            />
                        </Grid>
                    </Grid>

                    <Grid container sx={{ paddingBottom: '1rem' }}>
                        <Grid xs={12}>
                            <AutocompleteComponent
                                name='groupID'
                                freeSolo={false}
                                handleValueChange={handleInputChange}
                                loadingText={t('Loading')}
                                noOptionsText={t('NoData')}
                                label='Группа рассылки'
                                tooltip='Группа, по которой будет происходить рассылка писем'
                                value={localData.groupID}
                                dataSource={groups}
                                loadDataSource={getGroups}
                                getKey={(x) => x?.key}
                                fullWidth
                                validators={['required']}
                                errorMessages={[t('FieldRequired')]}
                            />
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid xs={12}>
                            <TooltipInfoLabel content='Тип рассылки в зависимости от цели. Можно делать рассылку по внутренним группам компании, по зарегистрированным и не зарегистрированным пользователям.'>
                                <h6>Цель рассылки</h6>
                            </TooltipInfoLabel>
                        </Grid>
                    </Grid>
                    <Grid container sx={{ paddingBottom: '1rem' }}>
                        <Grid xs={12}>
                            <Paper>
                                <Tabs
                                    value={localData.mailType}
                                    onChange={handleTabChange}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    variant="fullWidth"
                                >
                                    <Tab value='0' label="Пользователь" />
                                    <Tab value='1' label="Отдел" />
                                    <Tab value='2' label="Email" />
                                </Tabs>
                            </Paper>

                            <TabPanel value={localData.mailType} index="0">
                                <AutocompleteComponent
                                    name='userID'
                                    freeSolo={false}
                                    handleValueChange={handleInputChange}
                                    loadingText={t('Loading')}
                                    noOptionsText={t('NoData')}
                                    label='Зарегистрированный пользователь'
                                    tooltip='Пользователь, который будет подписан на рассылку'
                                    value={localData.userID}
                                    dataSource={users}
                                    loadDataSource={getUsers}
                                    fullWidth
                                    validators={['required']}
                                    errorMessages={[t('FieldRequired')]}
                                />
                                <TooltipInfoLabel className={classes.flex} content='Пользователь будет исключён из рассылки этой группы по отделам, если входит в один из отделов'>
                                    <UniversalInputField
                                        name='isExcludeFromList'
                                        dataType={InputDataType.BOOLEAN}
                                        handleValueChange={handleInputChange}
                                        locale={Localization}
                                        label='Исключить из рассылки'
                                        value={localData.isExcludeFromList}
                                        fullWidth
                                    />
                                </TooltipInfoLabel>
                            </TabPanel>

                            <TabPanel value={localData.mailType} index="1">
                                <AutocompleteComponent
                                    name='adNewslettersFilter'
                                    freeSolo={false}
                                    handleValueChange={handleInputChange}
                                    loadingText={t('Loading')}
                                    noOptionsText={t('NoData')}
                                    //getOptionLabel={option => option?.value || ''}
                                    //getKey={(value) => value?.filter}
                                    //isOptionEqualToValue={(option, value) => option?.key === value?.key}
                                    label='Отдел'
                                    tooltip='Отдел, сотрудники которого будут подписаны на рассылку'
                                    value={localData.adNewslettersFilter}
                                    dataSource={departments}
                                    loadDataSource={getDepartments}
                                    fullWidth
                                    validators={['required']}
                                    errorMessages={[t('FieldRequired')]}
                                />
                            </TabPanel>

                            <TabPanel value={localData.mailType} index="2">
                                <TextInputComponent
                                    name='outerUserEmail'
                                    handleValueChange={handleInputChange}
                                    label='Email'
                                    tooltip='Имя почтового ящика, на который будут отсылаться письма'
                                    value={localData.outerUserEmail}
                                    fullWidth
                                    validators={['required']}
                                    errorMessages={[t('FieldRequired')]}
                                />
                            </TabPanel>

                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid xs={12}>
                            <TooltipInfoLabel content='Дни, по которым будет осущетвляться рассылка'>
                                <h6>Дни рассылки</h6>
                            </TooltipInfoLabel>
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid md={6}>
                            {daysOfWeek.slice(0, 4).map((value) => {

                                return (
                                    <UniversalInputField
                                        key={value.name}
                                        name={value.name}
                                        dataType={InputDataType.BOOLEAN}
                                        handleValueChange={handleInputChange}
                                        locale={Localization}
                                        label={value.description}
                                        value={localData[value.name]}
                                        fullWidth
                                    />
                                );
                            })}
                        </Grid>
                        <Grid md={6}>
                            {daysOfWeek.slice(4, 7).map((value) => {

                                return (
                                    <UniversalInputField
                                        key={value.name}
                                        name={value.name}
                                        dataType={InputDataType.BOOLEAN}
                                        handleValueChange={handleInputChange}
                                        locale={Localization}
                                        label={value.description}
                                        value={localData[value.name]}
                                        fullWidth
                                    />
                                );
                            })}
                        </Grid>
                    </Grid>
                </ValidatorForm>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancelChanges_Local} variant="contained" color="primary">Отмена</Button>
                <Button onClick={onValidateChanges} variant="contained" color="secondary">Сохранить</Button>
            </DialogActions>
        </Dialog>
    );
}

export default MailingEditorPage;
