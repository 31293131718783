import ServiceBase from "./ServiceBase";
//import Globals from "../Globals";

export default class SensitivityService extends ServiceBase {

    static async getDOMRFFactoryStatistics(bondId, date) {
        var result = await this.requestJson({
            url: "api/DomRf/GetDOMRFFactoryStatistics",
            method: "GET",
            data: { bondId: bondId, date: date}
        });

        return result;
    }

}
