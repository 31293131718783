import "@Styles/pages/LoginPage.scss";
import { TextField, Checkbox, Button, Tabs, Tab, FormControlLabel, Container } from "@mui/material";
import { TabContent } from "@Framework/components/TabContent";
import { ValidatorForm, TextValidator } from "@Framework/components/Form";
import queryString from "query-string";

import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "../components/shared/CustomRouter";

import * as ConfirmRegistrationStore from "@Store/ConfirmRegistrationStore";
import { PageLogo } from "@Components/shared/PageLogo";
import { Spinner } from "@Components/shared/Spinner";
import * as Utilities from '@Utilities';
import { ProcessingState } from "@Components/Definitions";
import InformationForm from "@Components/shared/InformationForm";
import Localization from "@Components/Localization";
import { withTranslation } from "react-i18next";

class AccessDeniedPage extends React.Component {

    constructor(props) {
        super(props);
    }

    handleRedirectToMain = (event) => {
        let path = "/";
        this.props.router.navigate(path);
    };

    render() {

        const { t } = this.props;

        return (
            <Container sx={{ height: '100%', marginTop: '1rem' }}>

                <InformationForm type="failure" onSubmit={this.handleRedirectToMain} content={t('PageRequestedNotFound')} />

            </Container>
        )
    }
}

export default withRouter(withTranslation('translations')(AccessDeniedPage));