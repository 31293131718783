import { PoolProcessingStatus } from '@Base/components/Definitions'
import React from 'react'
import MessagesListDialog from '../components/MessagesListDialog'
import PoolProcessingPage from '../components/PoolProcessingPage'
import PoolStatisticsPage from '../PoolStatisticsPage'
import { actionCreators as actionSecuritization } from '@Base/store/SecuritizationStore'
import { EntityType } from '@Base/components/Definitions'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import style from '@Styles/domrf-theme.module.scss'
import { t } from 'i18next'

const useStyles = makeStyles((theme: Theme) => createStyles({
    alertWin: {
        display: 'flex',
        fontSize: '1.3rem',
        color: style.colorPrimary3_40,
        justifyContent: 'center',
        position: 'absolute', left: 0, top: 0, right: 0, bottom: 0,
        alignItems: 'center',
    }
}))

function PoolStatisticTab({ pool }) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const {
        selectedNode = null,
    } = useSelector((state: any) => state.securitization)

    const acceptWarnings = (event) => {
        //update pool
        actionSecuritization.applyPoolWarnings(pool.id, true)(dispatch);
    }

    const rejectWarnings = (event) => {
        //update pool
        actionSecuritization.applyPoolWarnings(pool.id, false)(dispatch);
    }

    const formMessagesData = (pageIndex, pageSize) => {

        var filter = {
            // ItemIndex: pageIndex * pageSize,
            // PageSize: pageSize,
            Params: { id: pool.id, type: EntityType.TYPE_POOL }
        }

        // console.log('filter', filter)
        actionSecuritization.loadPoolMessages(filter)(dispatch);
    }

    // console.log('pool  selectedNode !!!!!!!!!!!!!!!!!!!!!!!!!',  selectedNode)
    return (pool == null? <Box className={classes.alertWin}>{t('PleaseSelectOrLoadPool')}</Box>  
        :
    (
        pool?.status === PoolProcessingStatus.STATUS_FAILED ||
        pool?.status === PoolProcessingStatus.STATUS_ATTENTION_REQUIRED ||
        pool?.status === PoolProcessingStatus.STATUS_REMOVED
    ) ?
        <MessagesListDialog
            key={`pool_${pool.id}`}
            sourceObject={pool}
            onAccept={acceptWarnings}
            onReject={rejectWarnings}
            onDataRequest={formMessagesData}
        />
        :
        (pool?.status === PoolProcessingStatus.STATUS_SUCCESS ||
            pool?.status === PoolProcessingStatus.STATUS_ARCHIVED) ?
            <PoolStatisticsPage
                key={`pool-${pool.id}`}
                poolId={pool.id}
            />
            :
            <PoolProcessingPage
                pool={pool}
            />
    )
}

export default PoolStatisticTab