import * as React from 'react';
import clsx from 'clsx';
// import HelpIcon from '@mui/icons-material/Help';
// import { Theme } from '@mui/material/styles';
// import withStyles from '@mui/styles/withStyles';
import makeStyles from '@mui/styles/makeStyles';
import TooltipInfoLabel from '@Components/shared/TooltipInfoLabel';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%'
    }
});

const NameValueTextBox = ({ name, value, tooltip, classes }) => {

    const classesInternal = useStyles();

    const rootStyle = classes?.root || '';
    const nameStyle = classes?.name || '';
    const valueStyle = classes?.value || '';

    return (
        <TooltipInfoLabel className="d-flex w-100" content={tooltip}>
            <div className={clsx(classesInternal.root, rootStyle)}>
                <div className={clsx("me-auto", nameStyle)}>{name}</div>
                <div className={valueStyle}>{value}</div>
            </div>
        </TooltipInfoLabel>
    );
}

export default NameValueTextBox;