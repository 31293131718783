import { ePoolNodeStatus, ePoolNodeType, PoolProcessingStatus } from '@Base/components/Definitions'
import SpinnerBars from '@Base/components/shared/SpinnerBars'
import { actionCreators as actionSecuritization } from '@Base/store/SecuritizationStore'
import { Box, Stack, Button, Tooltip, IconButton } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useState, useEffect, useLayoutEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import PoolNodeCard from './PoolNodeCard'

const useStyles = makeStyles({
    root: {
        marginRight: '3px'
    },
    scrollablePanel: {
        position: 'absolute', left: 0 /*'4px'*/, right: 0, top: 0, bottom: 0,
        overflowY: 'auto',
        // overflow: 'visible !important',
        '&::-webkit-scrollbar': {

            width: '4px',
        },
        // '&::-webkit-scrollbar:disabled':{
        //     opacity:0,
        // },
        '&:hover': {
            '&::-webkit-scrollbar-track:disabled': {
                backgroundColor: '#0000',
            },
            '&::-webkit-scrollbar-thumb:disabled': {
                background: '#0000',
            },
            '&::-webkit-scrollbar-track': {
                backgroundColor: '#00000008',
            },
            '&::-webkit-scrollbar-thumb': {
                background: '#00000018',
            }
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'unset',
            borderRadius: '3px',
            // border: 'solid 1px #00000000',
            backgroundColor: '#00000000',
            transition: 'all 0.3s ease'
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#0000',
            borderRadius: '3px',
            height: '4px'
        },
    },
})

const PoolNodesList = ({ dealId = null, addNodePool }) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const [selectedNodeId, setSelectedNodeId] = useState(null)
    const { deal,
        selectedNode,
        operationLoading,
        poolsHubMessage,
        permissions, ...otherStor } = useSelector((state: any) => state.securitization)
    const poolNodes = [
        { dealID: 1, id: 1, name: 'addTransferPool', status: ePoolNodeStatus.STATUS_NORMAL, type: ePoolNodeType.TYPE_POOL_AT_TRANSFER_DATE }, //delivery
        { dealID: 1, id: 1, name: 'addRegistrationPool', status: ePoolNodeStatus.STATUS_NORMAL, type: ePoolNodeType.TYPE_POOL_AT_REGISTRATION_DATE }, //registration
        { dealID: 1, id: 1, name: 'addLLDPoold', status: ePoolNodeStatus.STATUS_NORMAL, type: ePoolNodeType.TYPE_POOL_PLAIN },
    ]
    // const [loading, setLoading] = useState(false)

    // useEffect(() => {
    //     console.log('deal', deal)
    //     setLoading(false)
    // }, [deal])

    /*
    const timer = useRef({
        timerId: null,
        isStarted: false
    });
    */

    useLayoutEffect(() => {
        // вызывает мигаение карточек
        //console.log(`------------------selectedNode changed! Current id: ${selectedNodeId} selectedNode?.node?.id: ${selectedNode?.node?.id} node:`, selectedNode);

        if (selectedNodeId != selectedNode?.node?.id)
            setSelectedNodeId(selectedNode?.node?.id)
    }, [selectedNode])

    // useEffect(() => {
    //     if (dealId != null && deal?.deal?.id != dealId) {
    //         setLoading(true)
    //         //         // console.log('getDealWithChanges ', dealId)
    //         //         actionSecuritization.getDealWithChanges(dealId)(dispatch) //CHECKME!!! PoolNodesList ничего не знает о сделке, поэтому не надо навешивать функционал, которого не должно быть в этом контексте.
    //     }
    // }, [dealId])

    /*
    useEffect(() => {
        if (deal?.deal?.id == poolsHubMessage?.dealID) {

            //console.log('getDealWithChanges (poolMessage) ', dealId, poolsHubMessage);

            actionSecuritization.getDealWithChanges(poolsHubMessage?.dealID)(dispatch)
        }
    }, [poolsHubMessage])
    */

    const loadNode = (nodeId) => {

        //console.log(`load node with changes new NodeId: ${nodeId} selectedNodeId: ${selectedNodeId}`);

        if (nodeId != null && nodeId != selectedNodeId) {
            setSelectedNodeId(nodeId)
            actionSecuritization.getNodeWithChanges(nodeId)(dispatch)
        }
    }

    // console.log('selectedNode----------------!!!!', selectedNode)
    // console.log('poolNodes------------------', poolNodes)
    // useEffect(() => {
    //     console.log('****************************************deal', deal)
    // }, [deal])
    /*
    const startUpdateTimer = () => {

        if (timer.current.isStarted) {

            stopTimer();

            timer.current.timerId = null;
            timer.current.isStarted = false;
        }
        else {

            startTimer();

            timer.current.isStarted = true;
        }
    }

    const stopTimer = () => {

        if (timer.current.timerId == null || !timer.current.isStarted)
            return;

        clearTimeout(timer.current.timerId)

        var message = {
            dealID: 92,
            sodeID: 46,
            poolID: 1409,

            status: PoolProcessingStatus.STATUS_SUCCESS
        };

        actionSecuritization.setPoolsHubMessage(message)(dispatch)
    }

    const startTimer = () => {

        var message = {
            dealID: 92,
            nodeID: 46,
            poolID: 1409,

            status: PoolProcessingStatus.STATUS_SENT,
            statusString: "Test string",
            percents: Math.floor(Math.random() * 100.0)
        };

        actionSecuritization.setPoolsHubMessage(message)(dispatch)

        timer.current.timerId = setTimeout(startTimer, 2000);
    }
    */

    return (
        <Stack className={classes.scrollablePanel}>
            {/* {dealId != deal?.deal?.id && <SpinnerBars withBackground />} */}
            {/*
            <Button sx={{ marginBottom: '1rem' }} variant="contained" color="secondary" onClick={startUpdateTimer}>
                {timer.current.isStarted ? "Stop timer" : "Start timer"}
            </Button>
            */}
            <Box className={classes.root}>
                {poolNodes?.length && poolNodes?.map((nodeList, index) => {
                    const nodes = deal?.deal?.poolNodes?.filter(poolNode => poolNode.type == nodeList.type && poolNode?.pools?.length)
                    const type = nodeList?.type
                    // const name = nodeList?.name
                    // {console.log('nodes----------', name, nodes, nodes.length, nodes[0]?.pools?.length)}
                    // console.log('nodes-----!!!!!!!!!!!!!!!!!!',deal, nodes)
                    return (
                        <React.Fragment
                            key={`NodeList-${index}`}
                        >
                            {(permissions?.isAdmin || permissions?.isManager) &&
                                (Array.isArray(nodes)
                                    && nodes?.length == 0
                                    || (nodes?.length != 0 && nodes[0]?.pools?.length == 0)
                                    || nodes[0]?.pool?.status == PoolProcessingStatus.STATUS_REMOVED
                                    || type == ePoolNodeType.TYPE_POOL_PLAIN) ?
                                <PoolNodeCard
                                    template={nodes[0]?.pools == null || nodes[0]?.pools?.length == 0 || type == ePoolNodeType.TYPE_POOL_PLAIN}
                                    // name={name ? t(name) : ''}
                                    type={type}
                                    clickEvent={() => addNodePool(type)}
                                />
                                :
                                <></>
                            }
                            {
                                nodes?.map(node => {
                                    // if (node?.pools?.length)
                                    // {console.log('node', node)}
                                    return (
                                        <PoolNodeCard
                                            key={`poolNode-${node.id}-${node.pools[0].id}`}
                                            selected={selectedNodeId == node.id}
                                            // name={node.name ?? `${t('node')} ${node.id}`}
                                            type={type}
                                            node={node}
                                            poolData={{ ...node.pools[0] }}
                                            clickEvent={() => { loadNode(node?.id) }}
                                        />
                                    )
                                })
                            }
                        </React.Fragment>)
                })
                }
            </Box>
        </Stack>
    )
}

export default PoolNodesList