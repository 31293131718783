import ServiceBase from "./ServiceBase";
//import Globals from "../Globals";

export default class ConventionService extends ServiceBase {


    static async runCalculation(data) {

        var result = await this.requestJson({
            url: "api/Convention/convention2",
            // url: "https://калькулятор.дом.рф:8193/Convention2/v1/Calculate",
            method: "POST",
            data: data
        });

        return result;
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    static async getBondsQuery(value, existId, index, pageSize) {
        var result = await this.requestJson({
            url: "api/Calculator/GetBondsQuery",
            method: "GET",
            data: { query: value, existId: existId, top: pageSize }
        });

        return result;
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    static async getBonds(filter) {
        var result = await this.requestJson({
            url: "api/Calculator/GetBonds",
            method: "POST",
            data: filter
        });

        return result;
    }

        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    static async getDataForCalculation(isin) {
        var result = await this.requestJson({
            url: "api/Convention/GetDataForCalculation",
            method: "GET",
            data: {isin : isin}
        });

        return result;
    }

}