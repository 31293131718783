import React from 'react';
import { withRouter } from "./CustomRouter";
import { connect } from "react-redux";
// import { Link } from 'react-router-dom';

import Drawer from '@mui/material/Drawer';
// import Button from '@mui/material/Button';
import List from '@mui/material/List';
// import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
// import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
// import MenuIcon from '@mui/icons-material/Menu';

// import Fab from '@mui/material/Fab';
// import Zoom from '@mui/material/Zoom';

import '@Styles/components/Sidebar.scss';

import * as SidebarStore from "@Store/SidebarStore";

class Sidebar extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            sidebarVisible: false,
        };
    }

    componentDidUpdate(prevProps, prevState) {

        if (this.props.area !== prevProps.area || this.props.items == null) {
            this.props.getActualDetails(this.props.area);
        }

        if (this.props.sidebarVisible !== prevProps.sidebarVisible) {
            this.setState({ sidebarVisible: this.props.sidebarVisible });
        }
    }

    showSidebar = (show) => event => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        if (!show)
            this.props.closeSidebar();
    };

    onMenuItemClick = (event, url) => {
        this.props.router.navigate(url);
    };

    render() {

        let list = (
            this.props.items != null ? (
                this.props.items.map((item, index) => (!item.isHidden &&
                    (
                    <ListItem button key={item.id} onClick={(e) => { this.onMenuItemClick(e, item.url) }}>
                        {/*<ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>*/}
                        <ListItemText disableTypography primary={item.name} className={"sidebar-menu-item header-sm " + (item.url === this.props.url ? 'sidebar-menu-item-selected' : '')} />
                    </ListItem>
                    )
                ))) : null
        );

        return (
            <div>
                <Drawer open={this.state.sidebarVisible} onClose={this.showSidebar(false)}>

                    <div
                        className="sidebar-container"
                        role="presentation"
                        onClick={this.showSidebar(false)}
                        onKeyDown={this.showSidebar(false)}
                    >
                        <div className="sidebar-header header-md">{this.props.name}</div>

                        <List>
                            {list}
                        </List>
                        {/*
                        <Divider />
                        <List>
                            {['All mail', 'Trash', 'Spam'].map((text, index) => (
                                <ListItem button key={text}>
                                    <ListItemText primary={text} />
                                </ListItem>
                            ))}
                        </List>
                        */}
                    </div>

                </Drawer>
            </div>
        );
    }
}

export default withRouter(connect(
    state => state.sidebar, // Selects which state properties are merged into the component's props
    SidebarStore.actionCreators // Selects which action creators are merged into the component's props
)(Sidebar));