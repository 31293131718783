import { Button, Divider, IconButton, Menu, MenuItem } from '@mui/material';
import React, { useState } from 'react';

export default function CustomMenu({ icon = null, disabled = false, title = null, items = null, className = null }) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (menuAction = () => { }) => {
        menuAction()
        setAnchorEl(null);
    };

    return (
        <div className={className ?? ''}>
            {
                icon ?
                    <IconButton
                        id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClickCapture={handleClick}
                        disabled={disabled}
                    >
                        {icon}
                    </IconButton>
                    :
                    <Button
                        id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClickCapture={handleClick}
                        disabled={disabled}
                    >
                        {title ? title : 'Menu'}
                    </Button>
            }
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={() => handleClose()}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {(items && items.length) &&
                    items.map((item, index) =>
                        item.divider ? <Divider key={item?.id} /> : 
                        <MenuItem key={item?.id} onClick={() => handleClose(item?.handle ? item.handle : null)}>
                            {item?.name ? item.name : `Item-${index}`}
                        </MenuItem>)
                }
            </Menu>
        </div>
    );
}