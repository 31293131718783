import { useState } from "react"
import { Box, Button, alpha } from "@mui/material";
import { useTranslation } from "react-i18next";
import { makeStyles } from '@mui/styles';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import style from '@Styles/domrf-theme.module.scss';
import { humanReadableBondStatus } from "@Base/Utilities";
import UniversalInputField, { InputDataType } from "@Base/framework/components/Form/UniversalInputField";
import { BondStatus } from "@Base/components/Definitions";
import { actionCreators as actionSecuritization } from '@Base/store/SecuritizationStore'
import { useDispatch } from "react-redux";

interface IProps {
    dealId: number,
    isOpen: boolean,
    onClose: (e) => void,
}

const useStyles = makeStyles({
    row: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignItems: 'flex-end',
        width: '100%',
    },
    field: {
        flexGrow: 1,
        marginLeft: '1rem',
    },
    root: {
        minWidth: '25%',
        '& *': {
            userSelect: 'none',
        },
    },
    title: {
        padding: '0 0.5rem',
        fontWeight: 'normal',
        fontSize: '0.9rem',
        color: style.colorPrimary3,
        textTransform: 'uppercase',
        lineHeight: '2rem',
        boxShadow: `0px 1px ${alpha(style.colorPrimary3, 0.7)}`,
    },
    bodyBlock: {
        padding: '1rem 1.5rem !important',
        textAlign: 'center',
    },
    buttonsBlock: {

    },
    icon: {
        color: style.colorWarning
    }
});

const CreateBondForm = ({ dealId = null, isOpen = false, onClose = (e) => { } }: IProps) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const dispatch = useDispatch()

    const [bondStatus, setBondStatus] = useState(BondStatus.STATUS_NOT_ISSUED)

    const onAccept = (e) => {
        // BondStatus 
        //     STATUS_UNKNOWN = 0,
        //     STATUS_NOT_ISSUED = 1,
        //     STATUS_PLACEMENT = 2,
        //     STATUS_ISSUED = 3,
        //     STATUS_REDEEMED = 4
        //     @param {dealID: number, bondState: BondStatus} config

        if (dealId != null && [BondStatus.STATUS_NOT_ISSUED,BondStatus.STATUS_PLACEMENT,BondStatus.STATUS_ISSUED].includes(bondStatus))
            actionSecuritization.createDealBond({ dealID: dealId, bondState: bondStatus })(dispatch)
        onClose(e)
    }

    if (!isOpen || dealId == null) return

    const selector = []
    for (const [key, value] of humanReadableBondStatus) {
        if (key != BondStatus.STATUS_REDEEMED)
            selector.push({ key: key, value: t(value) })
    }

    const Selector = () => {
        return (
            <Box className={classes.row}>
                <Box>{t("SelectStatus")}</Box>
                <Box className={classes.field}>
                    <UniversalInputField
                        needConfirm
                        dataType={InputDataType.ENUM}
                        name={'bondType'}
                        value={bondStatus}
                        fullWidth={true}
                        handleValueChange={(name, value) => setBondStatus(value)}
                        dataSource={selector}
                    />
                </Box>
            </Box>
        )
    }

    return (

        <Dialog open={isOpen} classes={{ paper: classes.root }}>
            <DialogTitle classes={{ root: classes.title }}>{t("CreateBond")}</DialogTitle>
            <DialogContent classes={{ root: classes.bodyBlock }}>
                <DialogContentText>
                    <Selector />
                </DialogContentText>
            </DialogContent>
            <DialogActions classes={{ root: classes.buttonsBlock }}>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={onAccept}>{t("Place")}</Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onClose}>{t("Cancel")}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default CreateBondForm