import { ReactNode } from 'react';
import { default as GlobalLocalization } from '@Components/Localization';
//import ruLocale from "date-fns/locale/ru";

//interface IDateFormat {
//    date: (arg: any) => string;
//    time: (arg: any) => string;
//}
//
//const customDatePatternRU = 'dd.MM.yyyy';
//CHECKME!!! completley remove
export class Localization  {

    //public locale: Locale = ruLocale;

    public DateMask() {
        return GlobalLocalization.DateMask();
    };

    public ShortDatePattern() {
        return GlobalLocalization.ShortDatePattern();
    };

    public ShortDateTimePattern() {
        return GlobalLocalization.ShortDateTimePattern();
    };

    public FormatNumber(number: number, minfractionDigits: number = 2, maxfractionDigits: number = 2) {
        return GlobalLocalization.FormatNumber(number, minfractionDigits, maxfractionDigits);
    }

    public of: string = 'из';
    public recordsFound = 'найдено записей';
    public to: string = 'до';
    public from: string = 'из';
    public records: string = 'записей';
    public NoData: string = 'Нет данных';
    public FirstPage: string = 'Первая страница';
    public PreviousPage: string = 'Предыдущая страница';
    public Page: string = 'Страница';
    public NextPage: string = 'Следующая страница';
    public LastPage: string = 'Последняя страница';
    public ClickToSort_I: string = 'Кликните для сортировки. Удерживайте Ctrl для сортировки по нескольким колонкам';
    public Clear: string = 'Очистить';
    public Apply: string = 'Применить';
    public Operator: string = 'Оператор';
    public Value: string = 'Значение';
    public FirstValue: string = 'Первое значение';
    public SecondValue: string = 'Второе значение';
    public Export: string = 'Экспортировать';
    public Print: string = 'Распечатать';
    public Search: string = 'Поиск';
    public RowsPerPage: string = 'Строк на странице';


    public None: string = 'Нет';
    public NotEquals: string = 'Не равно';
    public Equals: string = 'Равно';
    public GreaterOrEquals: string = 'Больше или равно';
    public Greater: string = 'Больше';
    public LesserOrEquals: string = 'Меньше или равно';
    public Lesser: string = 'Меньше';

    public Between: string = 'Между';
    public Contains: string = 'Содержит';
    public NotContains: string = 'Не содержит';
    public StartsWith: string = 'Начинается с';
    public NotStartsWith: string = 'Не начинается с';
    public EndsWith: string = 'Заканчивается на';
    public NotEndsWith: string = 'Не заканчивается на';

    public CreateNewRecord: string = 'Создать новую запись';
    public Delete: string = 'Удалить';
    public Edit: string = 'Редактировать';
    public Create: string = 'Создать';

    public Yes: string = 'Да';
    public No: string = 'Нет';
    public Undefined: string = 'Не определено';
    public True: string = 'Да';
    public False: string = 'Нет';

    public Cancel: string = 'Отмена';
    public Ok: string = 'Ок';

    public Loading: string = 'Загрузка'
    public LoadingWithEllipsis: string = 'Загрузка...'

    public DragOrSelectFileText: string = 'Перетащите сюда файл или кликните'

    public MaximumNumberFilesExceededFn = (filesLimit: number) => { return `Превышен лимит файлов для загрузки. Можно загрузить только ${filesLimit} шт` };
    public FileAddedFn = (filename: string) => { return `Файл ${filename} добавлен` };
    public FileRemoved = (filename: string) => { return `Файл ${filename} удалён` };
    public FileRejectedFn = (rejectedFile: string) => { return `Файл ${rejectedFile} отбракован` };
    public FileIsTooBigFn = (maxFileSize: string) => { return `Файл слишком большой, допустимый размер составляет ${maxFileSize}` };
    public FileTypeNotSupported: string = 'Тип файла не поддерживается';
    public Preview: string = 'Предпросмотр:';


    constructor(options?: Partial<Localization>) {
        if (options)
            Object.assign(this, options);
    }
}
