import "@Styles/pages/LoginPage.scss";
import { TextField, Checkbox, Button, Tabs, Tab, FormControlLabel, Container } from "@mui/material";
import { TabContent } from "@Framework/components/TabContent";
import { ValidatorForm, TextValidator } from "@Framework/components/Form";
import queryString from "query-string";

import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "../../components/shared/CustomRouter";

import * as ConfirmRegistrationStore from "@Store/ConfirmRegistrationStore";
import { PageLogo } from "@Components/shared/PageLogo";
import { Spinner } from "@Components/shared/Spinner";
import * as Utilities from '@Utilities';
import { ProcessingState } from "@Components/Definitions";
import InformationForm from "@Components/shared/InformationForm";

class ConfirmRegistrationPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            userId: "",
            code: ""
        };

        if (this.props.router.location.search) {
            const values = queryString.parse(this.props.router.location.search);
            if (values.userId)
                this.state.userId = values.userId;
            if (values.code)
                this.state.code = values.code;
        }
    }

    componentDidMount() {

        this.props.init();

        var state = {
            userId: this.state.userId,
            code: this.state.code
        };

        this.props.confirmRegistration(state);
    }

    handleRedirectToMain = (event) => {
        let path = "/login";
        this.props.router.navigate(path);
    };
    
    render() {

        let content = "";

        if (this.props.confirmEmailState == ProcessingState.Response_Success) {
            content = <InformationForm type="success" onSubmit={this.handleRedirectToMain} content="Ваш почтовый ящик подтверждён. Вы можете зайти на сайт под логином, указанным при регистрации." />;
        }
        else if (this.props.confirmEmailState == ProcessingState.Response_Failed) {
            content = <InformationForm type="failure" onSubmit={this.handleRedirectToMain} content="Произошла ошибка при валидации почтового ящика. Попробуйте зарегистрироваться снова, а если ваш логин уже существует, воспользуетесь функционалом восстановления пароля." />;
        }

        return (
            <Container sx={{ height: '100%', marginTop: '1rem' }}>

                <Spinner show={this.props.operationLoading} fullScreen content="Обработка..." />
                {content}

            </Container>
        );
    }
}

var component = connect(
    state => state.confirmRegistration,     // Selects which state properties are merged into the component's props
    ConfirmRegistrationStore.actionCreators // Selects which action creators are merged into the component's props
)(ConfirmRegistrationPage);

export default withRouter(component);