import * as React from 'react';
import { connect } from 'react-redux';
// import { debounce } from 'throttle-debounce';
import clsx from 'clsx';
import stringInject from 'stringinject';
import Helmet from "react-helmet";
import Globals from "@Base/Globals";
import queryString from 'query-string';
import "@Styles/pages/CalculationResultsPage.scss";
import "@Styles/style-main.scss";

import { Spinner } from "@Components/shared/Spinner";
import * as DomRfStore from "@Store/DomRfStore";
import * as NavbarStore from '@Store/TopNavbarStore';

import { withRouter } from "../../../components/shared/CustomRouter";
import { Theme } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import ClickAwayListener from '@mui/material/ClickAwayListener';

import { Grid, Paper, Button, Checkbox, IconButton, Tooltip, Container, Stack, styled, TableContainer, TableHead, TableRow, TableCell, Table, TableBody, Box, Typography, Divider, debounce, Switch } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { AutoGraph, BackspaceOutlined, FileCopyOutlined, HelpOutline, InfoOutlined, SaveOutlined, Search, SsidChart, WarningAmber, ZoomInOutlined, ZoomOutOutlined } from '@mui/icons-material';
import DateFnsUtils from '@date-io/date-fns';
import { isValid as isValidDateFns, parse as parseDateFns } from 'date-fns';

import Highcharts from 'highcharts/highstock';
// import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HC_exporting from 'highcharts/modules/exporting';
// import HC_exporting_data from 'highcharts/modules/export-data';
import HC_patternFill from "highcharts-pattern-fill";

import Localization from '@Components/Localization';
import style from '@Styles/domrf-theme.module.scss';
import { BondStatus, FlowTypes, CouponType, CouponTypeStr } from '../../../components/Definitions';
import { setHighchartsLocalization, copyTextToClipboard, b64EncodeUnicode, hexToRgbA, getDate, getTime, toUnixDate } from '@Utilities';
import ZCYCDialog from './ZCYCDialog';

import TooltipInfoLabel from '@Components/shared/TooltipInfoLabel';
// import TooltipWithLink from '@Components/shared/TooltipWithLink';
//import AutoComplete from '@Components/shared/AutoComplete';
import { DataGrid, GridOptions } from "../../../framework/components/Table";
import { ColumnModel } from "../../../framework/components/Table/Common";
import { AggregateFunctions, ColumnDataType, ColumnSortDirection, TrimContentType } from '../../../framework/components/Table/Common/Models/Column';
import AboutConventionDialog from './AboutConventionDialog';
import ConventionVersionsDialog from './ConventionVersionsDialog';
import ConventionInformation from './ConventionInformation';
import ConfirmDialog from '@Framework/components/ConfirmDialog';
import { withTranslation } from "react-i18next";
// import SaveOutlined from '@mui/icons-material/Save';

// import NameDialog from "@Pages/NameDialog";
// import LayoutHolder from '../../../components/shared/LayoutHolder';

import SensitivityPanel from "./panels/SensitivityPanel";
import SpectrumPanel from "./panels/SpectrumPanel";
import KeyRatePanel from "../../common/KeyRatePanel";
import InputPanel from "./panels/InputPanel";
import StatisticsPanel from "./panels/StatisticsPanel";
import FlowsPanel from "./panels/FlowsPanel";

import RotateLocker from '../Components/RotateLocker';
import TooltipWithLink from '../../../components/shared/TooltipWithLink';
import { ReactComponent as ApiIcon } from "../../../../src/images/api-icon.svg"
import ConfirmDialog2 from '@Base/framework/components/ConfirmDialog2';
import ConfirmationForm from '@Base/components/shared/ConfirmationForm';
import { getTooltip } from '@Base/components/shared/CustomTooltip';


const Item = styled('div')(({ theme }) => ({
    backgroundColor: 'none',
    padding: 0,
    textAlign: 'center',
    border: 'none'
}));

const styles = (theme: Theme) => ({
    dialogContent: {
        textAlign: 'left'
    },
    dialogActions: {
        padding: '0 0 0.5rem 0',
        justifyContent: 'center',
        flexDirection: 'row-reverse',
        '&>*': { margin: '0 0.5rem' },
    },
    description: {
        color: style.colorPrimary3,
        fontFamily: style.fontFamilyBase,
        fontSize: theme.typography.subtitle2.fontSize,
        lineHeight: theme.typography.subtitle2.lineHeight,
        letterSpacing: theme.typography.subtitle2.letterSpacing
    },
    tableHeader: {
        fontSize: '14px',
        //fontWeight: '600',
        textTransform: 'uppercase',
        color: style.colorPrimary3,
    },
    overflow: {
        //height: '100%',
        overflowY: 'scroll',
        overflowX: 'auto',
        height: '450px'
    },
    toListButton: {
        position: 'absolute',
        left: '15px',
        bottom: '15px',
        zIndex: '1100'
    },
    tabContainer: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    tabButtons: {
        '& .MuiTab-root': {
            fontSize: '14px',
            minWidth: '72px'
        },
    },
    tab: {

        '& div': { //CHECKME!!! temporary!
            padding: '0'
        }
    },
    fullTab: {

        display: 'flex',
        flexDirection: 'column',
        height: '100%',

        '& >div': {
            padding: '0',
            height: '100%'
        }
    },
    fullRowHeight: {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'row',
        height: '100%',
        boxSizing: 'border-box'
    },
    fullColumnHeight: {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column'
        //height: '100%',
        //boxSizing: 'border-box'
    },
    header: {

        [theme.breakpoints.down('sm')]: {
            paddingRight: '10px',
            paddingLeft: '10px',
        }
    },
    dialogTitle: {

        [theme.breakpoints.down('sm')]: {
            padding: '16px'
        }
    },
    container: {

        width: '100%',
        height: '100%',
        paddingRight: '15px',
        paddingLeft: '15px',
        marginRight: 'auto',
        marginLeft: 'auto',
        overflowX: 'hidden',

        [theme.breakpoints.up('xl')]: {
            maxWidth: '1400px'
        },
        [theme.breakpoints.down('xl')]: {
            maxWidth: '1400px'
        },
        [theme.breakpoints.down('xl')]: {
            maxWidth: '1300px'
        },
        [theme.breakpoints.down('lg')]: {
            maxWidth: '800px'
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: '550px'
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
            paddingRight: '0',
            paddingLeft: '0',
        }
    },

    cell: {
        fontWeight: '700',
        padding: '0.25rem 0.5rem'
    },
    toolbar: {
        display: 'flex',
        // minHeight: '24px',
        justifyContent: 'flex-end',
        paddingLeft: '2px',
        paddingRight: '2px',
        borderBottom: '1px solid #e0e0e0'
    },
    aboutButton: {
        position: 'absolute',
        left: '15px',
        bottom: '15px',
        zIndex: '1100'
    },
    selectDescription: {
        fontSize: '14px'
    },
    chartButton: {
        border: '1px solid #8BC540'
    },
    buttonsContainer: {
        display: 'flex',
        justifyContent: 'space-between'
        //paddingLeft: '0.5rem',
        //paddingRight: '0.5rem'
    },
    infoText: {
        fontStyle: 'italic',
        fontSize: '14px'
    },
    none: {
        display: 'none'
    },
    flex: {
        display: 'flex'
    },
    historyTableRow: {
        '& td': {
            color: style.colorPrimary3_60
        }
    },
    usedHistoryTableRow: {
        '& td': {
            color: style.colorPrimary2
        }
    },
    hideButton: {
        position: 'absolute',
        cursor: 'pointer',
        fontSize: '20px',
        top: '0',
        right: '5px',
        zIndex: '1000'
    },
    hidden: {
        display: 'none'
    },
    bondDialog: {
        '& .MuiDialog-container': {

            alignItems: 'flex-start',

            '& .MuiDialog-paper': {
                top: '5%'
            }
        },
    },
    // dialogContent: {
    //     padding: '10px'
    // },
    layoutGrid: {
        '& .react-grid-item': {
            padding: '0.2rem'
        }
    },
    toggleLabel: {
        marginBottom: '0'
    },
    flowChartHeader: {
        textAlign: 'center',
        width: '100%',
        position: 'absolute'
    },
    flowChartHeaderContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        minHeight: '30px'
    },
    switchContainer:
    {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    leftValue: {
        borderRight: '1px solid ' + style.colorPrimary3
    },
    panel: {
        //overflow: 'auto'
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
    },
    leftCell: {
        paddingRight: 0
    },
    rightCell: {
        paddingLeft: 0
    },
    warning: {
        color: '#7bad38',
        fontSize: '16px',
        textAlign: 'center'
    },
    flexContainer: {
        display: 'flex',
        width: '100%',
        justifyItems: 'stretch'
    },
    sCurveButton: {
        padding: '0px 5px 1px 5px',
        fontSize: '1.5rem'
    }
});


const isBondVisible = (status: BondStatus) => {
    return status == BondStatus.STATUS_PLACEMENT || (status == BondStatus.STATUS_NOT_ISSUED && Globals.user.isEmployee);
}

const gDateFnsUtils = new DateFnsUtils();

interface IProp {
    classes: any;
    bond: any;
    options: any[];

    init: () => void;
    loadBonds: (query: string, existId: string) => void;
    calculateResults: (data: any) => void;
    getBondData: (date: any, id: any) => void;
    getComplexYZCYCC: (date: any) => void;
    loadLayouts: (id: number) => void;
    removeLayout: (id: string) => void;
    saveLayout: (data: any) => void;
    loadMoexSecurities: (date: string | Date) => void;

    //from 
    cprcdr: any;
    bonds_data: any[];
    bondsLoading: boolean;
    zcy_curve_base_chart_data: any[];
    operationLoading: boolean;
    parametersLoading: boolean;
    zcycLoaded: boolean;
    ZCYCDate: any;
    bonds_points: any[];
    t: any;
    loadedLayouts: any[];
    newLayout: any;
    moexSecurities: any[];
    location: any;
}

interface IState {
    showConvention2Alert: boolean
    evaluationMode: number
    isMobile: boolean
    variableFixType: number
    ZCYCDate: Date
    maxZCYCDate: Date
    minZCYCDate: Date
    evaluationDate: Date | string
    operationLoading: boolean
    cpr: number | string
    cdr: number | string

    isPlacementCPR: boolean
    isPlacementCDR: boolean

    variableCPR: any[];
    backupedCPR: number | string;
    backupedCDR: number | string;
    editedCPR: any[];
    dirtyPrice: number | string;
    cleanPrice: number | string;
    zSpread: number | string;
    gSpread: number | string;
    couponType: string;
    couponRate: number | string;
    minEvaluationDate: Date;
    maxEvaluationDate: Date;
    isFixedCashflow: boolean;
    isUseKnownFlows: boolean;
    isUseAdjustedWAM: boolean;
    conventionVersion: number;
    preSelectedBond: any;
    selectedBond: any;
    selectOpened: boolean;
    bondPreSearchValue: string;
    bondSearchValue: string;
    [key: string]: any;

    columns: any[];
    tableColumnExtensions: any[];
    doubleColumns: string[];
    dateColumns: string[];
    showDisclaimer: boolean;
    showVersionsInfo: boolean;
    showFloaterWarning: boolean;

    showConfirm: boolean;

    showHistoryFlows: boolean;
    historyFlowsDisabled: boolean;

    bondResults: any;

    layoutNameDialog: boolean;
    layoutToSave: any;
    isFirstCalculation: boolean;

    isBondsLoaded: boolean;
    isManualZCYCChange: boolean;
    openGridTooltip: boolean;

    spectrumParameters: any;

    calculationModeTabIndex: number;
    priceDataType: boolean;


    keyRates: any;
    isCustomForwardRate: boolean;

    flowsTabIndex: number;

    lockPlacementPrice: boolean;
    lockPlacementCoupon: boolean;
    defaultPlacementPrice?: number;
    usePlacementCoupon: boolean;
    queryData: any;
    keyrateChartData: any[];
    statsEmptyLabel: string;
    flowsLocalization: any;
}


class ConventionCalculationPage extends React.Component<IProp, IState> {

    //static whyDidYouRender = true;
    // test=''

    constructor(props) {
        super(props);

        HC_exporting(Highcharts);
        HC_patternFill(Highcharts);
        setHighchartsLocalization(Highcharts, this.props.t);

        var queryData = {};
        // console.log('props',props);

        if (this.props.router.location.search) {
            // console.log('queryString', decodeURI(this.props.router.location.search.replaceAll('%25','%')))
            // const repl = (v)=>v.replaceAll('%25','%')
            // this.test = queryString.parse(decodeURI(this.props.router.location.search.toString().replaceAll('%25','%'))).zcycdate
            // const url = new URL(this.props.router.location.search)
            // const values = url.toJSON()
            const values = queryString.parse(decodeURI(this.props.router.location.search.toString().replaceAll('%25', '%')));
            // console.log('values', values)
            queryData = this.validateQuery(values);
        }

        this.state = {
            showConvention2Alert: true,
            evaluationMode: 1,
            isMobile: false,
            queryData: queryData,
            isFirstCalculation: true,
            operationLoading: false,
            variableFixType: 4, //g-spread by default
            ZCYCDate: new Date(),
            maxZCYCDate: new Date((new Date()).toDateString()),
            minZCYCDate: new Date('2014-01-07'),
            evaluationDate: null, //queryData.evaluationDate,
            cpr: '',
            cdr: '',
            isPlacementCPR: false,
            isPlacementCDR: false,
            variableCPR: null,
            editedCPR: null,
            backupedCPR: '',
            backupedCDR: '',
            dirtyPrice: '',
            cleanPrice: '',
            zSpread: '',
            gSpread: '',
            couponType: CouponTypeStr.UNKNOWN,
            couponRate: '',
            minEvaluationDate: new Date(),
            maxEvaluationDate: gDateFnsUtils.addDays(new Date((new Date()).toDateString()), 10),
            isFixedCashflow: false,
            isUseKnownFlows: true,
            preSelectedBond: null,
            selectedBond: null,
            selectOpened: false,
            bondSearchValue: '',
            bondPreSearchValue: '',
            showDisclaimer: false,
            showVersionsInfo: false,
            showFloaterWarning: false,
            showConfirm: false,
            bondId: null,
            //options: [],
            isBondsLoaded: false,
            //showChart: false,
            showZCYCChart: false,
            showHistoryFlows: false,
            historyFlowsDisabled: true,
            bondResults: null,
            //isVariableCPR: false,
            openGridTooltip: false,

            isManualZCYCChange: false,
            conventionVersion: 1,
            isUseAdjustedWAM: true,
            //isScenarioAnalysis: false,

            spectrumParameters: {},

            calculationModeTabIndex: 1, //base evaluation

            priceDataType: false, //false - percents, true - rubles

            keyRates: null,
            isCustomForwardRate: false,

            flowsTabIndex: 1,

            lockPlacementPrice: false,
            lockPlacementCoupon: false,
            defaultPlacementPrice: null,
            usePlacementCoupon: false,

            keyrateChartData: queryData.keyrates,

            statsEmptyLabel: null,
            flowsLocalization: this.createFlowsLocalization(),

            //rows: [],
            columns: this.getColumns(),

            tableMessages: this.getMessages(),

            tableColumnExtensions: [
                { columnName: 'couponDate', align: 'left' },
                { columnName: 'bondNominal', align: 'right' },
                { columnName: 'bondAmortization', align: 'right' },
                { columnName: 'bondCoupon', align: 'right' },
                { columnName: 'couponRate', align: 'right' }
            ],

            doubleColumns: [
                'bondNominal',
                'bondAmortization',
                'bondCoupon',
                'couponRate'
            ],

            dateColumns: ['couponDate'],

            zcycChartOptions: {

                chart: {
                    // zoomType: 'x',
                    plotAreaHeight: 300,
                    // // plotBorderWidth:1,
                    // // plotBorderColor: hexToRgbA(style.colorPrimary3, 0.2),
                },

                exporting:
                {
                    enabled: false,
                },
                navigator: {
                    enabled: true,
                    height: 20,
                    margin: 10,
                    maskFill: hexToRgbA(style.colorPrimary3, 0.2),
                    xAxis: {
                        // min: 0,
                        // max: 5, //CHECKME!!! hardcoded
                        labels: {
                            formatter: function () {
                                return this.value;
                            }
                        },
                        zoomEnabled: true,
                    }
                },

                plotOptions: {
                    series: {
                        stickyTracking: false
                    }
                },
                /*
                exporting: {
                    buttons: {
                        contextButton: {
                            menuItems: [
                                {
                                    textKey: 'printChart',
                                    onclick: function () { this.print(); }
                                },
                                {
                                    separator: true
                                },
                                {
                                    textKey: 'downloadPNG',
                                    onclick: function () { this.exportChart(); }
                                },
                                {
                                    textKey: 'downloadJPEG',
                                    onclick: function () { this.exportChart({ type: 'image/jpeg' }); }
                                }
                            ]
                        }
                    }
                },
                */

                title: {
                    text: ''//this.props.t('BondsYield'),
                    //style: { "color": `"${style.colorPrimary1}"`, "fontSize": "18px", "fontWeight": "bold" }
                },

                credits: {
                    enabled: false
                },

                xAxis: {
                    //type: 'logarithmic',
                    title: this.props.t('Years'),
                    min: 0,
                    max: 5, //CHECKME!!! navigator hardcoded bounds!
                    //range: 5
                    gridLineWidth: 1,
                    lineWidth: 1,
                    lineColor: hexToRgbA(style.colorPrimary3, 0.2),//style.colorPrimary1
                    startOnTick: false,
                    endOnTick: false,
                    events: {
                        setExtremes: function (e) {
                            // console.log('e', e, this)
                            if (this.max != e.max || e.min > this.dataMin)
                                setTimeout(() => {
                                    this.setExtremes(0, e.max)
                                }, 100);
                        }
                    }
                },

                yAxis: {
                    title: null,
                    offset: 0,
                    color: style.colorPrimary1,
                    //min: 0
                },

                tooltip: {
                    hideDelay: 0,
                    useHTML: true
                },

                series: [
                    {
                        kdNow: true,
                        name: this.props.t('ZCYC'), //temporary, will be replaced at data load
                        //type: 'spline',
                        data: null,
                        showInNavigator: true,
                        color: style.colorPrimary1,
                        marker: {
                            enabled: false,
                            symbol: 'circle'
                        },

                        tooltip: {
                            headerFormat: '<b>{series.name}</b><br>',
                            pointFormat: `<span>${this.props.t('ConventionNameYieldsGraphDurationRunner')}</span>: <b>{point.x:.2f}</b><br/><span>${this.props.t('ConventionNameYieldsGraphYieldRunner')}</span>: <b>{point.y:.2f}</b>`,
                            //crosshairs: true,
                            //shared: true,
                            //positioner: function () {
                            //    return { x: 0, y: 0 };
                            //}
                        },
                        /*states: {
                            hover: {
                                enabled: false
                            }
                        },
                        */
                        zIndex: 1
                    }
                ]
            },

            flowsChartOptions: {

                defs: {
                    patterns: [{
                        id: 'left-slash-pattern',
                        path: {
                            d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
                            stroke: style.colorPrimary3_60,
                            strokeWidth: 1
                        }
                    },
                    {
                        id: 'left-white-slash-pattern',
                        path: {
                            d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
                            stroke: 'white',
                            strokeWidth: 1,
                            fill: style.colorPrimary1_40
                        }
                    },
                    {
                        id: 'left-white-green-slash-pattern',
                        path: {
                            d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
                            stroke: 'white',
                            strokeWidth: 1,
                            fill: style.colorPrimary2_40
                        }
                    },
                    {
                        id: 'dotted-pattern',
                        path: {
                            d: "M 0 0 L 10 0 M 0 5 L 10 5 M 0 10 L 10 10",
                            stroke: style.colorPrimary3_60,
                            strokeWidth: 1
                        }
                    }
                    ]
                },

                exporting:
                {
                    enabled: false,
                    chartOptions: {
                        title: {
                            text: this.props.t('ConventionNameCashflowGraphName')
                        },
                        legend: {
                            align: 'center',
                            verticalAlign: 'bottom',
                            itemDistance: 40,
                            itemStyle: {
                                fontSize: '7px',
                                overflow: 'hidden',
                            },
                            floating: false
                        }
                    },

                },

                plotOptions: {
                    series: {
                        pointPadding: 0,
                        groupPadding: 0.1,

                        states: {
                            hover: {
                                //opacity: 1.5,
                                brightness: -0.2
                                //color: 'rgba(68, 188, 93, 1)'
                            }
                        }
                    },
                    column: {
                        stacking: 'normal'
                    }
                },

                chart: {
                    plotAreaHeight: 310,
                    type: 'column',
                    reflow: true,
                    plotBorderWidth: 1,
                    plotBorderColor: hexToRgbA(style.colorPrimary3, 0.2),
                    allowMutatingData: true,
                    animation: false,
                    events: {
                        render: (e) => {
                            if (e.target?.callback)
                                e.target?.callback(e.target)
                            // if(e.target?.legend){
                            //     Highcharts.objectEach(e.target.legend.allItems, function (el) {
                            //         console.log('el', el)
                            //         if(el.legendSymbol && el.name==this.t("HistoricalAmortization"))
                            //            el.legendSymbol.element.style.stroke = "#cccccc";
                            //     })
                            // }
                            // console.log('e', e)
                            // x.options.dataLabels.events.init=()=>{console.log('intEv')}
                        }
                    }
                },
                title: {
                    text: '',
                    //style: { "color": "#2F444E", "fontSize": "18px", "fontWeight": "bold" }
                },

                xAxis: {
                    type: 'datetime',
                    crosshair: true,
                    gridLineWidth: 1,
                    lineWidth: 1,
                    lineColor: hexToRgbA(style.colorPrimary3, 0.2), //style.colorPrimary1,
                    title: {
                        enabled: false
                    }

                },
                yAxis: {
                    title: {
                        text: this.props.t('ConventionNameCashflowGraphRubles')
                    },
                    reversedStacks: false,
                },

                legend: {
                    enabled: true,
                    // itemStyle:{"color":"#F00"}
                    // borderColor:'#CCCCCC',
                    // borderWidth:20,
                    // itemStyle :{'border':'solid 1px #CCC'}
                },

                tooltip: {
                    formatter: function () {


                        var sorted = [...this.points].sort((a, b) => { return (a.series.options.legendIndex || 0) - (b.series.options.legendIndex || 0); });

                        return '<table style="min-width:200px;max-width:600px"><tr><td></td>' +
                            '<td style="text-align:end">' +

                            sorted.reduce(function (s, point) {
                                return s + '<tr><td style="padding:0">' + point.series.name + ': </td>' +
                                    '<td style="padding:0;text-align:end"><b>' + Localization.FormatNumberString(point.y) + '</b></td></tr>'
                            }, '<b>' + Localization.FormatDateLocale(new Date(this.x)) + '</b>') +

                            '</td > ' +
                            '</tr>' +
                            '</table>';
                    },

                    hideDelay: 0,
                    followPointer: true,
                    shared: true,
                    useHTML: true
                },

                credits: {
                    enabled: false
                },

                series: [
                    {
                        kdNow: true,
                        name: this.props.t('ConventionNameCashflowGraphRealPayment'),
                        color: 'url(#left-white-slash-pattern)',
                        data: null,
                        legendIndex: 3
                    },
                    {
                        kdNow: true,
                        name: this.props.t('ConventionNameCashflowGraphPlan'),
                        color: style.colorPrimary1_40,
                        data: null,
                        legendIndex: 5
                    },
                    {
                        kdNow: true,
                        name: this.props.t('ConventionNameCashflowGraphDefault'),
                        color: style.colorSecondary1,
                        data: null,
                        legendIndex: 6
                    },
                    {
                        name: this.props.t('ConventionNameCashflowGraphPrepay'),
                        color: style.colorPrimary3_40,
                        data: null,
                        legendIndex: 7
                    },

                    {
                        kdNow: true,
                        name: this.props.t('ConventionNameCashflowGraphRealCoupon'),
                        color: 'url(#left-white-green-slash-pattern)',
                        // borderColor: '#CCC',
                        data: null,
                        legendIndex: 4
                    },
                    {
                        kdNow: true,
                        name: this.props.t('ConventionNameCashflowGraphCoupon'),
                        color: style.colorPrimary2_40,
                        data: null,
                        legendIndex: 8
                    },
                    {
                        kdNow: true,
                        name: this.props.t('ConventionNameCashflowGraphCleanUp'),
                        color: 'url(#left-slash-pattern)',
                        data: null,
                        legendIndex: 9,
                    },
                    {
                        kdNow: true,
                        name: this.props.t('HistoricalAmortization'),
                        color: 'white',
                        visible: false,
                        showInLegend: false,
                        data: null,
                        borderWidth: 1,
                        borderColor: style.colorPrimary3,
                        legendIndex: 1,
                        states: {
                            hover: {
                                enabled: false
                            },
                            inactive: {
                                enabled: false
                            },
                            normal: {
                                enabled: false
                            },
                            select: {
                                enabled: false
                            }
                        }
                    },
                    {
                        kdNow: true,
                        name: this.props.t('HistoricalCoupon'),
                        color: 'url(#dotted-pattern)',
                        visible: false,
                        showInLegend: false,
                        data: null,
                        borderWidth: 1,
                        borderColor: style.colorPrimary3,
                        legendIndex: 2,
                        states: {
                            hover: {
                                enabled: false
                            },
                            inactive: {
                                enabled: false
                            },
                            normal: {
                                enabled: false
                            },
                            select: {
                                enabled: false
                            }
                        }
                    }
                ]
            },

            prepaymentChartOptions: {
                exporting: {
                    enabled: false
                },

                chart: {
                    type: 'area',
                    zoomType: 'x',
                },

                title: {
                    //text: '@Localizer["CashflowMln"]',
                    text: null
                    //style: { "color": "#2F444E", "fontSize": "18px", "fontWeight": "bold" }
                },

                xAxis: {
                    type: 'datetime',
                    title: this.props.t('Date'),
                    gridLineWidth: 1,
                    lineWidth: 1,
                    lineColor: style.colorPrimary1,
                },

                yAxis: {
                    title: {
                        text: null
                    }
                },
                tooltip: {
                    pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y:.2f}</b><br/>',
                    crosshairs: true,
                    shared: true
                },

                credits: {
                    enabled: false
                },

                legend: {
                },

                plotOptions: {
                    area: {
                        stacking: 'normal'
                    }
                },

                series: [
                    {
                        name: this.props.t('CPR_AsIs'),
                        data: null,
                        color: style.colorPrimary3,
                        lineWidth: 0,
                        fillOpacity: 0.5,
                        marker: {
                            enabled: false,
                            symbol: 'circle'
                        }
                    },
                    {
                        name: this.props.t('CDR_AsIs'),
                        data: null,
                        color: style.colorSecondary1,
                        lineWidth: 0,
                        fillOpacity: 0.5,
                        marker: {
                            enabled: false,
                            symbol: 'circle'
                        }
                    },
                    {
                        name: this.props.t('ScheduledRate'),
                        data: null,
                        color: style.colorPrimary1,
                        lineWidth: 0,
                        fillOpacity: 0.5,
                        marker: {
                            enabled: false,
                            symbol: 'circle'
                        }
                    }
                ]
            },

            WACChartOptions: {

                exporting: {
                    enabled: false
                },

                chart: {
                    zoomType: 'x'
                },

                title: {
                    text: null,
                    style: { "color": "#2F444E", "fontSize": "18px", "fontWeight": "bold" }
                },

                credits: {
                    enabled: false
                },


                xAxis: {
                    type: 'datetime',
                    title: null,
                    gridLineWidth: 1,
                    lineWidth: 1,
                    lineColor: style.colorPrimary1,
                },

                yAxis: {
                    title: null,
                },

                tooltip: {
                    crosshairs: true,
                    shared: true
                },

                series: [
                    {
                        name: this.props.t('WACDynamics'),
                        type: 'spline',
                        data: null,
                        dashStyle: 'longdash',
                        color: style.colorPrimary1,
                        lineWidth: 1,
                        marker: {
                            enabled: false,
                            symbol: 'circle'
                        }
                    }
                ]
            },

            mounted: false,

            //responsive layout settings
            breakpoints: { lg: 1310, md: 810, sm: 560, xs: 430, xxs: 0 },
            compactType: "vertical",
            rowHeight: 30,
            cols: { lg: 30, md: 30, sm: 20, xs: 1, xxs: 1 },
            currentBreakpoint: 'lg',//this.layoutHolder.breakpoint,
            layouts: [

                { id: "input", isVisible: true },
                { id: "stats", isVisible: true },
                { id: "results", isVisible: true },

                { id: "sensitivity", isVisible: false },
                { id: "keyrate", isVisible: false },
                { id: "spectrum", isVisible: false },

                { id: "zcyc", isVisible: true },
                { id: "flows", isVisible: true },
            ],

            layoutNameDialog: false,
            layoutToSave: null
        }

        this.state.maxZCYCDate.setDate(this.state.maxZCYCDate.getDate() + 1);
        this.state.maxZCYCDate.setTime(this.state.maxZCYCDate.getTime() - 1);
    }

    layoutHolder = null;
    submitForm = null;

    zcycChart = null;
    flowChart = null;

    calculationData = null;

    inputPanelRef = null;

    mobileDetect = () => {
        var mql = window.matchMedia("(min-width: 1024px)");
        if (mql.matches)
            return false
        return true
    }

    landscapeDetect = () => {
        var mql = window.matchMedia("(orientation: landscape)");
        if (mql.matches)
            return true
        else
            return false
    }

    componentDidMount() { // после вставки компонента в DOM

        if (window)
            window.addEventListener('resize', debounce(() => this.setState({ isMobile: this.mobileDetect() }), 100))
        this.setState({ isMobile: this.mobileDetect() })

        Localization.onLanguageChanged(this.onLanguageChanged);

        this.props.init();
        this.props.loadBonds('', null);

        if (this.state.queryData.zcycDate == null) {        //get data if no data at query string
            this.props.getComplexYZCYCC(this.state.ZCYCDate);
            this.props.loadMoexSecurities(this.state.ZCYCDate);
        }

        if (Globals.isAuthenticated) {
            this.props.loadLayouts(2); //2 - id of convention page
        }

        this.setState({ mounted: true });

        //log user activity
        Globals.LogActivity(2); //calculations
        this.props.setTitle(this.props.t('ConventionNameInstrumentTitle')) //Устанавлимваем заголовок в Store
    }

    componentWillUnmount() {
        Localization.offLanguageChanged(this.onLanguageChanged);
        this.props.setTitle('') //Устанавлимваем заголовок в Store
    }

    showPanel(id) {
        this.setState(prevState => {
            // console.log(prevState.layouts)
            return { layouts: prevState.layouts.map(layout => layout.id == id ? { ...layout, isVisible: true } : { ...layout }) }
        }
        )
    }

    hidePanel(id) {
        this.setState(prevState => {
            // console.log('hidePanel',id);
            // console.log(prevState.layouts)
            return { layouts: prevState.layouts.map(layout => layout.id == id ? { ...layout, isVisible: false } : { ...layout }) }
        }
        )
    }



    componentDidUpdate(prevProps, prevState) { //вызывается сразу после обновления. Не вызывается при первом рендере.

        this.props.setTitle(this.props.t('ConventionNameInstrumentTitle')) //Устанавлимваем заголовок в Store

        // console.log('componentDidUpdate')
        // if (this.props.newLayout != prevProps.newLayout) {
        //     //add new layout to menu
        //     this.layoutHolder.addLoadedLayout({ ...this.props.newLayout });
        // }

        // if (this.props.loadedLayouts != prevProps.loadedLayouts && this.props.loadedLayouts != null) {

        //     this.layoutHolder.setLoadedLayouts([...this.props.loadedLayouts]);
        // }
        if (prevState.evaluationMode != this.state.evaluationMode) {
            let changes: any = {}
            switch (this.state.evaluationMode) {
                case 1: //base evaluation
                    {
                        // this.layoutHolder.hidePanel('spectrum');
                        // this.layoutHolder.hidePanel('sensitivity');
                        this.hidePanel('spectrum');
                        this.hidePanel('sensitivity');
                        if (this.state.selectedBond.couponType === CouponType.TYPE_KEYRATE_AND_PREMIUM)
                            changes.statsEmptyLabel = 'UseKeyrateChart';
                        else
                            changes.statsEmptyLabel = '';
                    }
                    break;
                case 2: //variable CPR
                    {
                        // this.layoutHolder.hidePanel('spectrum');
                        // this.layoutHolder.showPanel('sensitivity');
                        this.hidePanel('spectrum');
                        this.showPanel('sensitivity');
                        changes.statsEmptyLabel = 'UseCPRChart';
                        changes.variableCPR = null
                    }
                    break;
                case 3: //scenario analysis
                    {
                        // this.layoutHolder.hidePanel('sensitivity');
                        // this.layoutHolder.showPanel('spectrum');
                        this.hidePanel('sensitivity');
                        this.showPanel('spectrum');
                        changes.statsEmptyLabel = '';
                    }
                    break;
            }
            this.setState(changes)
        }

        if (this.props.bond != prevProps.bond && this.props.bond != null) {
            var disabled = (this.props.bond.flow.findIndex(item => item.flowType == FlowTypes.HISTORY) == -1);
            this.setState({ bondResults: this.props.bond, historyFlowsDisabled: disabled });
        }

        if ((this.state.bondResults != prevState.bondResults) /*|| (this.state.skipCleanUp != prevState.skipCleanUp) || (this.state.showHistoryFlows != prevState.showHistoryFlows)*/) {
            var newZcycChart = { ...this.state.zcycChartOptions };

            if (this.state.bondResults == null) {
                var index = newZcycChart.series.findIndex(x => x.id == 'calculatedBond');
                if (index != -1)
                    newZcycChart.series.splice(index, 1);
                this.setState({ zcycChartOptions: newZcycChart }, () => {
                    this.zcycChart.chart.xAxis[0].setExtremes(0, 5);
                });
                return;
            }

            var name = (this.state.bondResults.extraDisplayName?.length || 0) != 0 ? ' (' + this.state.bondResults.extraDisplayName + ')' : '';
            var series = {
                id: 'calculatedBond',
                name: 'bonds',
                type: 'scatter',
                color: style.colorPrimary2,
                showInLegend: false,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    formatter: function () {
                        return '<span style="color: ' + this.color + '">' + this.point.label + '</span>';
                    }
                },
                tooltip: {
                    pointFormatter: function () {

                        return '<table>' +
                            '<tr><td colspan=2><b>' + this.label + this.extraLabel + '</b></td></tr>' +
                            '<tr><td style="padding:0">' + Localization.Translate('ConventionNameYieldsGraphDurationRunner') + ': </td>' +
                            '<td style="padding:0;text-align:end"><b>' + Localization.FormatNumber(this.x) + '</b></td></tr>' +
                            '<tr><td style="padding:0">' + Localization.Translate('ConventionNameYieldsGraphYieldRunner') + ': </td>' +
                            '<td style="padding:0;text-align:end"><b>' + Localization.FormatNumber(this.y) + '</b></td></tr>' +
                            '</table>'
                    },
                    headerFormat: null
                },
                marker: {
                    enabled: true,
                    symbol: 'circle'
                },
                data: [{ x: this.state.bondResults.durMac, y: this.state.bondResults.ytm, label: this.state.bondResults.displayName, extraLabel: name, color: style.colorPrimary2 }],
                zIndex: 200
            };

            var index = newZcycChart.series.findIndex(x => x.id == 'calculatedBond');
            if (index == -1) // none
            {
                if (this.state.bondResults != null)
                    newZcycChart.series.push(series);
            }
            else {
                if (this.state.bondResults != null)
                    newZcycChart.series[index] = series;
                else
                    newZcycChart.series.removeAt(index);
            }

            var changes = {};
            /////////////////////////////////////////////////////////////////////////
            if (this.state.bondResults.rates != null) {
                var rateGraphPlan = [];
                var rateGraphCPR = [];
                var rateGraphCDR = [];
                var rateGraphWAC = [];

                var newPrepaymentChartOptions = { ...this.state.prepaymentChartOptions };

                this.state.bondResults.rates.forEach((point) => {

                    var date = new Date(point.date);
                    var unixDate = date.getTime() - (date.getTimezoneOffset() * 60 * 1000);

                    rateGraphPlan.push({ x: unixDate, y: point.ratePlan });
                    rateGraphCPR.push({ x: unixDate, y: point.rateCPR });
                    rateGraphCDR.push({ x: unixDate, y: point.rateCDR });
                    rateGraphWAC.push({ x: unixDate, y: point.rateWAC });
                });

                newPrepaymentChartOptions.series[0].data = rateGraphCPR;
                newPrepaymentChartOptions.series[1].data = rateGraphCDR;
                newPrepaymentChartOptions.series[2].data = rateGraphPlan;


                /////////////////////////////////////////////////////////////////////////

                var newWACChartOptions = { ...this.state.WACChartOptions };
                newWACChartOptions.series[0].data = rateGraphWAC;

                changes.prepaymentChartOptions = newPrepaymentChartOptions;
                changes.WACChartOptions = newWACChartOptions;
            }

            this.setState({
                zcycChartOptions: newZcycChart,
                ...changes
            }, () => {
                this.zcycChart.chart.xAxis[0].setExtremes(0, 5);
            });
        }

        if (prevProps.moexSecurities != this.props.moexSecurities) {

            var newZcycChart = { ...this.state.zcycChartOptions };

            if (this.props.moexSecurities == null) {
                var index = newZcycChart.series.findIndex(x => x.id == 'moexSecurities');
                if (index != -1)
                    newZcycChart.series.splice(index, 1);

                this.setState({ zcycChartOptions: newZcycChart }, () => {
                    this.zcycChart.chart.xAxis[0].setExtremes(0, 5);
                });
                return;
            }

            var moexSeries = {
                id: 'moexSecurities',
                name: this.props.t('FederalLoanBonds'),
                type: 'scatter',
                color: 'white',
                showInLegend: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: false
                    /*
                    formatter: function () {
                        return '<span style="color: ' + this.color + '">' + this.point.label + '</span>';
                    }
                    */
                },
                tooltip: {
                    pointFormatter: function () {

                        return '<table>' +
                            '<tr><td colspan=2><b>' + this.label + '</b></td></tr>' +
                            '<tr><td style="padding:0">' + Localization.Translate('Convention2_Duration') + ': </td>' +
                            '<td style="padding:0;text-align:end"><b>' + Localization.FormatNumber(this.x) + '</b></td></tr>' +
                            '<tr><td style="padding:0">' + Localization.Translate('ConventionNameYieldsGraphYieldRunner') + ': </td>' +
                            '<td style="padding:0;text-align:end"><b>' + Localization.FormatNumber(this.y) + '</b></td></tr>' +
                            '</table>'
                    },
                    headerFormat: null,
                    //pointFormat: `<span>${this.props.t('ConventionNameYieldsGraphDurationRunner')}</span>: <b>{point.x:.2f}</b><br/><span>${this.props.t('ConventionNameYieldsGraphYieldRunner')}</span>: <b>{point.y:.2f}</b>`,
                },
                marker: {
                    enabled: true,
                    symbol: 'circle',
                    lineColor: style.colorPrimary3,
                    lineWidth: 1
                },
                data: this.props.moexSecurities.map(item => { return { x: item.duration, y: item.yield, label: `${item.isin} (${item.name})` }; }),
                zIndex: 201
            }

            var index = newZcycChart.series.findIndex(x => x.id == 'moexSecurities');
            if (index == -1) // none
            {
                newZcycChart.series.push(moexSeries);
            }
            else {
                newZcycChart.series[index] = moexSeries;
            }

            this.setState({ zcycChartOptions: newZcycChart }, () => {
                this.zcycChart.chart.xAxis[0].setExtremes(0, 5);
            });
        }

        if (prevState.selectedBond != this.state.selectedBond) {

            //console.log("===Bond selection changed");
            //show/hide keyrate panel

            if (this.state.selectedBond?.couponType == CouponType.TYPE_KEYRATE_AND_PREMIUM) {
                // console.log("===Showing keyrate panel");
                // this.layoutHolder.showPanel('keyrate');
                this.cleanupResults();
                this.showPanel('keyrate');
            }
            else {
                // this.layoutHolder.hidePanel('keyrate');
                this.hidePanel('keyrate');
            }
            let changes = { variableCPR: null }
            this.setState({ ...changes })
            this.onRequestCalculation(null);
        }

        if (prevProps.zcy_curve_base_chart_data != this.props.zcy_curve_base_chart_data && this.props.zcy_curve_base_chart_data != null) {

            var newZcycChart = { ...this.state.zcycChartOptions };
            this.updateZCYCChartLegend(newZcycChart);

            var dateNow = new Date();
            var date = new Date(this.state.ZCYCDate);
            if (this.props.ZCYCDate) {
                date = this.props.ZCYCDate;
            }

            var timeLabel = '';
            var tooltipLabel = '';

            if (
                dateNow.getFullYear() == date.getFullYear() &&
                dateNow.getMonth() == date.getMonth() &&
                dateNow.getDate() == date.getDate()
            ) {
                var time = Localization.FormatTimeLocale(date);
                timeLabel = stringInject(this.props.t('YCurveWithDateAt'), [time]);
                tooltipLabel = stringInject(this.props.t('YCurveAt'), [time]);
            }
            else {
                var time = Localization.FormatDateTimeLocale(date);
                timeLabel = stringInject(this.props.t('YCurveWithDateOn'), [time]);
                tooltipLabel = stringInject(this.props.t('YCurveOn'), [time]);
            }

            newZcycChart.series[0].data = this.props.zcy_curve_base_chart_data;
            newZcycChart.series[0].name = timeLabel;
            newZcycChart.series[0].tooltip.headerFormat = `<b>${tooltipLabel}</b><br>`;


            //newZcycChart.series.splice(1, newZcycChart.series.length - 1);

            if (this.props.bonds_points != null) {

                var bondsSeries = {
                    id: "bondsChart",
                    name: 'bondsChart',
                    type: 'scatter',
                    color: '#2F444E',
                    showInLegend: false,
                    cursor: 'pointer',

                    point: {
                        events: {
                            click: (e) => {
                                this.setState({ showConfirm: true, bondId: e.point.id });
                            }
                        }
                    },
                    dataLabels: {
                        enabled: true,
                        formatter: function () {
                            return this.point.label;
                        }
                    },
                    tooltip: {
                        pointFormatter: function () {

                            return '<table>' +
                                '<tr><td colspan=2><b>' + this.label + '</b></td></tr>' +
                                '<tr><td style="padding:0">' + Localization.Translate('ConventionNameYieldsGraphDurationRunner') + ': </td>' +
                                '<td style="padding:0;text-align:end"><b>' + Localization.FormatNumber(this.x) + '</b></td></tr>' +
                                '<tr><td style="padding:0">' + Localization.Translate('ConventionNameYieldsGraphYieldRunner') + ': </td>' +
                                '<td style="padding:0;text-align:end"><b>' + Localization.FormatNumber(this.y) + '</b></td></tr>' +
                                '</table>'
                        },
                        headerFormat: null
                    },
                    marker: {
                        enabled: true,
                        symbol: 'triangle'
                    },
                    data: null, //[{ x: x.duration, y: x.yield, label: x.isin + ' - ' + x.name }],
                    zIndex: 2
                };

                bondsSeries.data = this.props.bonds_points.map((x, index) => {
                    return { x: x.duration, y: x.yield, label: x.name, id: x.id }
                });

                var index = newZcycChart.series.findIndex(x => x.id == 'bondsChart');
                if (index == -1) // none
                {
                    newZcycChart.series.push(bondsSeries);
                }
                else {
                    newZcycChart.series[index] = bondsSeries;
                }
            }
            else {
                var index = newZcycChart.series.findIndex(x => x.id == 'bondsChart');
                if (index != -1)
                    newZcycChart.series.splice(index, 1);
            }

            this.setState({
                zcycChartOptions: newZcycChart
            }, () => {

                this.zcycChart.chart.xAxis[0].setExtremes(0, 5);
            });
        }

        if (this.props.ZCYCDate != null && prevProps.ZCYCDate != this.props.ZCYCDate && !this.state.skipEvaluationDateDueLoad) {

            //console.log('received ZCYC data  at date: ' + this.props.ZCYCDate);
            var time = getTime(this.props.ZCYCDate);

            if (!gDateFnsUtils.isEqual(time, this.state.ZCYCDate))
                this.setState({ ZCYCDate: getTime(this.props.ZCYCDate), updateZCYCFromProp: true }, () => this.loadMoexDebounced(this.state.ZCYCDate));
        }
    }

    validateQuery = (data) => {

        var output: any = {};

        //parsing input data
        //issued bonds
        //mode: standard, cpr, scenario
        //isin
        //:gspread,zspread,price
        //zcycdate
        //evaluationdate
        //cpr
        //cdr
        //conventionversion
        //useadjustedwam
        //useknownflows
        //pricedatatype
        //premium

        //variable spr
        //cpr - array of cpr's
        //date

        //scenario analisys
        //cpr - array of cpr's
        //price step

        //check for isin
        //no isin - no processing
        // console.log('data', data)
        if (data.isin == null || data.isin == '')
            return output;

        //const isinRegex = /^[A-Za-z0-9]{12}$/;
        //if (!isinRegex.test(data.isin))
        //return output;
        output.isin = data.isin;

        //check input type
        //2 - price
        //3 - zspread
        //4 - gspread

        var inputs = ['price', 'zspread', 'gspread'];

        //first, check if we have type selection
        if (data.valueType != null) {
            var valueType = parseFloat(data.valueType);
            if (!isNaN(valueType) && valueType > 1 && valueType < 5) {

                //matches
                var current = data[inputs[valueType - 2]];
                if (current != null) {
                    var inputData = parseFloat(current);
                    if (!isNaN(inputData)) {
                        output[inputs[valueType - 2]] = inputData;
                        output.valueType = valueType;
                    }
                }
            }
        }

        if (output.valueType == null) //not found, try to parse by available arguments
        {
            for (var i = 0; i < inputs.length; i++) {
                var current = data[inputs[i]];
                if (current != null) {
                    var inputData = parseFloat(current);
                    if (!isNaN(inputData)) {
                        output[inputs[i]] = inputData;
                        output.valueType = i + 2;
                        break;
                    }
                }
            }
        }

        //zcycdate
        if (data.zcycdate != null) {
            // var date = Date.parse(data.zcycdate);
            // if (!isNaN(parseInt(date))) {
            //     output.zcycdate = new Date(date);
            // }
            // else 
            //     output.zcycdate = new Date() 
            var date = parseDateFns(data.zcycdate, 'yyyy-MM-dd HH:mm', new Date())
            if (isValidDateFns(date))
                output.zcycdate = date
            else
                output.zcycdate = new Date()
            // console.log('!isNaN(date)', !isNaN(date))

        }

        //evaluationdate
        if (data.evaluationdate != null) {
            var date = Date.parse(data.evaluationdate);
            if (!isNaN(date)) {
                output.evaluationdate = getDate(new Date(date));
            }
        }

        //Тип идентификатора
        if (data.bondNameType != null) {
            output.bondNameType = data.bondNameType
        }

        //cpr
        if (data.cpr != null) {
            var value = parseFloat(data.cpr);
            if (!isNaN(value)) {
                output.cpr = value;
            }
        }
        //cdr
        if (data.cdr != null) {
            var value = parseFloat(data.cdr);
            if (!isNaN(value)) {
                output.cdr = value;
            }
        }

        if (data.couponrate != null) {
            var value = parseFloat(data.couponrate);
            if (!isNaN(value)) {
                output.couponrate = value;
            }
        }

        //conventionversion
        if (data.cdr != null) {
            var value = parseInt(data.conventionversion, 10);
            if (!isNaN(value)) {
                output.conventionversion = value;
            }
        }

        //useknownflows
        if (data.useknownflows != null) {
            if (data.useknownflows == 'true' || data.useknownflows == 1)
                output.useknownflows = true;
            else if ((data.useknownflows == 'false' || data.useknownflows == 0))
                output.useknownflows = false;
        }

        //useadjustedwam
        if (data.useadjustedwam != null) {
            if (data.useadjustedwam == 'true' || data.useadjustedwam == 1)
                output.useadjustedwam = true;
            else if ((data.useadjustedwam == 'false' || data.useadjustedwam == 0))
                output.useadjustedwam = false;
        }

        //pricedatatype
        if (data.pricedatatype != null) {
            if (data.pricedatatype == 'true' || data.pricedatatype == 1)
                output.pricedatatype = true;
            else if ((data.pricedatatype == 'false' || data.pricedatatype == 0))
                output.pricedatatype = false;
        }

        //keyrate, manually entered data
        if (data.keyrate != null) {
            var arr = data.keyrate.split(',');
            var points = [];

            for (var i = 0; i < arr.length; i += 2) {
                try {
                    points.push({ x: toUnixDate(gDateFnsUtils.parseISO(arr[i])), y: parseFloat(arr[i + 1]) });
                }
                catch (e) {
                    console.log(e);
                    points = null; //if data corrupted, we dont use it at all
                    break;
                }
            }

            output.keyrates = points;
        }

        return output;
    }

    clearPage = () => {

        var newFlowsChartData = this.getCleanFlowsData();

        //clear results
        this.setState({ bondResults: null, flowsChartData: newFlowsChartData });

        //causes to validate combobox
        this.inputPanelRef.clearPanel();
    }

    updateQueryString = (data: any) => {

        if (data != null) {
            // console.log('data', data)
            var query = {};
            if (data.bondNameType != null)
                query.bondNameType = data.bondNameType;
            if (data.ISIN != null)
                query.isin = data.ISIN;
            if (data.DataFixType != null)
                query.valueType = data.DataFixType;
            if (data.ZCYCDate != null)
                query.zcycdate = data.ZCYCDate;
            if (data.EvaluationDate != null)
                query.evaluationdate = data.EvaluationDate;
            if (data.CPR != null)
                query.cpr = data.CPR;
            if (data.CDR != null)
                query.cdr = data.CDR;
            if (data.ConventionVersion != null)
                query.conventionversion = data.ConventionVersion;
            if (data.UseRealFlows != null)
                query.useknownflows = data.UseRealFlows;
            if (data.UseAdjustedWAM != null)
                query.useadjustedwam = data.UseAdjustedWAM;
            if (data.CorrectedYield != null)
                query.correctedyield = data.CorrectedYield;
            if (data.PriceDataType != null)
                query.pricedatatype = data.PriceDataType;

            if (data.ConventionVersion == 2) //corrected yield for 2nd version only
                query.correctedyield = data.CorrectedYield;

            //if (data.CouponType == CouponTypeStr.KEYRATE_AND_PREMIUM) {
            if (data.CouponRate != null)
                query.couponrate = data.CouponRate;
            //}

            switch (data.DataFixType) {
                case 2:
                    {
                        query.price = (data.CouponType == CouponTypeStr.FIXED || data.CouponType == CouponTypeStr.KEYRATE_AND_PREMIUM) ? data.CleanPrice : data.DirtyPrice;
                    }
                    break;
                case 3:
                    {
                        query.zspread = data.ZSpread;
                    }
                    break;
                case 4:
                    {
                        query.gspread = data.GSpread;
                    }
                    break;
            }

            if (data.KeyRates != null) {
                query.keyrate = data.KeyRates.map(x => `${x.x},${x.y}`).join(',');
            }

            if (query.isin) {
                var urlQueryParameters = Object.keys(query).map(function (k) {
                    // return encodeURIComponent(k) + '=' + encodeURIComponent(query[k])
                    return k + '=' + query[k]
                }).join('&')

                window.history.replaceState(null, '', `${window.location.origin + window.location.pathname}?${urlQueryParameters}`);
            }
        }
        else {
            window.history.replaceState(null, '', window.location.origin + window.location.pathname);
        }
    }

    formDescription = (option: any): string => {

        if (option == null)
            return '';

        switch (option.bondStatus) {
            case BondStatus.STATUS_NOT_ISSUED:
                {
                    return option.displayName + ' ' + this.props.t('Preparing');
                }
            case BondStatus.STATUS_PLACEMENT:
                {
                    return option.displayName + ' ' + this.props.t('PreIssue');
                }
            case BondStatus.STATUS_ISSUED:
                {
                    return option.displayName;
                }
            case BondStatus.STATUS_REDEEMED:
                {
                    return option.displayName + ' ' + this.props.t('Redeemed');
                }
            default:
                return option.displayName;
        }
    }

    updateZCYCChartLegend = (newZcycChart) => {

        var dateNow = new Date();
        // console.log('this.state.',this.state)
        var date = new Date(this.state.ZCYCDate);
        if (this.props.ZCYCDate)
            date = this.props.ZCYCDate;

        var timeLabel = '';
        var tooltipLabel = '';

        if (
            dateNow.getFullYear() == date.getFullYear() &&
            dateNow.getMonth() == date.getMonth() &&
            dateNow.getDate() == date.getDate()
        ) {
            var time = Localization.FormatTimeLocale(date);
            timeLabel = stringInject(this.props.t('YCurveWithDateAt'), [time]);
            tooltipLabel = stringInject(this.props.t('YCurveAt'), [time]);
        }
        else {
            var time = Localization.FormatDateTimeLocale(date);
            timeLabel = stringInject(this.props.t('YCurveWithDateOn'), [time]);
            tooltipLabel = stringInject(this.props.t('YCurveOn'), [time]);
        }

        newZcycChart.series[0].name = timeLabel;
        newZcycChart.series[0].tooltip.headerFormat = `<b>${tooltipLabel}</b><br>`;
    }

    getColumns = () => {
        return [
            { name: 'couponDate', title: this.props.t('ConventionNameCouponDate'), align: 'left' },
            { name: 'bondNominal', title: this.props.t('ConventionNamePrincipalAfterCouponDate'), align: 'right' },
            { name: 'bondAmortization', title: this.props.t('ConventionNameAmortization'), align: 'right' },
            { name: 'bondCoupon', title: this.props.t('ConventionNameCoupon'), align: 'right' },
            { name: 'couponRate', title: this.props.t('ConventionNameCouponRate'), align: 'right' }
        ];
    }

    getMessages = () => {
        return {
            noData: '' //this.props.t('NoData'),
        };
    }

    onLanguageChanged = (lng: any) => {

        //TODO!!!
        this.setState({ columns: this.getColumns(), tableMessages: this.getMessages() });
        setHighchartsLocalization(Highcharts, this.props.t);
        this.updateHighchartsOptions();
    }

    updateHighchartsOptions = () => {

        var zcycOptions = { ...this.state.zcycChartOptions };

        zcycOptions.xAxis.title = this.props.t('Years');
        zcycOptions.series.forEach(item => item.tooltip.pointFormat = `<span>${this.props.t('ConventionNameYieldsGraphDurationRunner')}</span>: <b>{point.x:.2f}</b><br/><span>${this.props.t('ConventionNameYieldsGraphYieldRunner')}</span>: <b>{point.y:.2f}</b>`);

        this.updateZCYCChartLegend(zcycOptions);

        var index = zcycOptions.series.findIndex(x => x.id == 'moexSecurities');
        if (index != -1) {
            zcycOptions.series[index].name = this.props.t('FederalLoanBonds');
        }

        var newFlowsLocalization = this.createFlowsLocalization();
        this.setState({ zcycChartOptions: zcycOptions, flowsLocalization: newFlowsLocalization });
    }

    createFlowsLocalization = () => {
        var newFlowsLocalization = {};
        newFlowsLocalization.title = this.props.t('ConventionNameCashflowGraphRubles');
        newFlowsLocalization.tooltipFormatter = function () {

            return '<table style="min-width:200px;max-width:600px"><tr><td></td>' +
                '<td style="text-align:end">' +

                this.points.reduce(function (s, point) {
                    return s + '<tr><td style="padding:0">' + point.series.name + ': </td>' +
                        '<td style="padding:0;text-align:end"><b>' + Localization.FormatNumberString(point.y) + '</b></td></tr>'
                }, '<b>' + Localization.FormatDateLocale(new Date(this.x)) + '</b>') +

                '</td > ' +
                '</tr>' +
                '</table>';
        };

        newFlowsLocalization.RealPayment = this.props.t('ConventionNameCashflowGraphRealPayment');
        newFlowsLocalization.Plan = this.props.t('ConventionNameCashflowGraphPlan');
        newFlowsLocalization.Default = this.props.t('ConventionNameCashflowGraphDefault');
        newFlowsLocalization.Prepay = this.props.t('ConventionNameCashflowGraphPrepay');
        newFlowsLocalization.CleanUp = this.props.t('ConventionNameCashflowGraphCleanUp');
        newFlowsLocalization.RealCoupon = this.props.t('ConventionNameCashflowGraphRealCoupon');
        newFlowsLocalization.Coupon = this.props.t('ConventionNameCashflowGraphCoupon');

        return newFlowsLocalization;
    }

    updateEvaluationMode = (newValue: number) => {
        this.setState({ evaluationMode: newValue })
    }

    onBondSelected = (bond: any) => {
        // console.log("===On bond selected", this.state.editedCPR);
        var changes = { editedCPR: null, keyRates: null, selectedBond: bond };

        if (bond?.couponType == CouponType.TYPE_KEYRATE_AND_PREMIUM)
            changes.statsEmptyLabel = 'UseKeyrateChart';
        else
            if (this.state.evaluationMode == 2)
                changes.statsEmptyLabel = 'UseCPRChart';
            else
                changes.statsEmptyLabel = null;

        this.setState(changes);
    }

    cleanupResults() {


        var newFlowsChartData = this.getCleanFlowsData();

        //clear results
        this.setState({ bondResults: null, flowsChartData: newFlowsChartData });
    }

    getCleanFlowsData = () => {

        if (this.state.flowsChartData == null)
            return null;

        var newFlowsChartData = { ...this.state.flowsChartData };
        Object.keys(newFlowsChartData).forEach(x => {
            if (Array.isArray(newFlowsChartData[x])) {
                newFlowsChartData[x] = [];
            }
        });

        return newFlowsChartData;
    }


    onRequestCalculation = (data) => {

        this.calculationData = data;

        this.requestCalculation();
    }

    requestCalculation = () => {

        //console.log("---------------Let's calculate!");

        // let changes = { editedCPR: null}
        // this.setState({...changes})
        // this.cleanupResults()
        // console.log('this.state.evaluationMode===2 && editedCPR===null', this.state.evaluationMode, this.state.variableCPR)
        if (this.calculationData == null || (this.state.evaluationMode === 2 && this.state.variableCPR === null)) {
            //console.log("---------------No data, returning");
            this.cleanupResults();
            return;
        }

        var query = { ...this.calculationData };

        //console.log("Let's calculate!");
        //CHECKME!!! ugly, replace by component method to receive parameters instead of cachiing it here
        var parameters = {};
        if (this.calculationData.AnalysisType == 3) { //scenario analysis
            var { checkCouponRate, ...other } = this.state.spectrumParameters;
            if (!other.isValid) {
                //console.log("---------------Not valid, returning");
                return;
            }

            if (this.state.selectedBond?.bondStatus == BondStatus.STATUS_PLACEMENT && this.state.couponRate != checkCouponRate)
                return; //seems, not refreshed

            parameters = other;
            parameters.ScenarioAnalysis = true;
        }
        else if (this.calculationData.AnalysisType == 2) {
            parameters.VariableCPR = this.state.variableCPR; //variable CPR
        }

        //console.log("---------------this.calculationData:", this.calculationData, this.state.keyRates);

        if (this.state.selectedBond?.couponType == CouponType.TYPE_KEYRATE_AND_PREMIUM && this.state.keyRates == null)
            return; //no data to send
        else {
            parameters.KeyRates = this.state.keyRates != null ? { model: this.state.keyRates.editModel } : null;
            query.KeyRates = this.state.keyRates != null ? this.state.keyRates.editModel : null;
        }

        var result = { ...this.calculationData, ...parameters };

        //console.log("---------------calculating...");

        this.props.calculateResults(result);


        this.updateQueryString(query);
    };

    // gridOptions = new GridOptions({
    //     topPager: false,
    //     bottomPager: false,
    //     fixedHeader: true,
    //     fullHeight: true,
    //     canSearch: false
    // });


    columnWidths: any[] = [
        { columnName: 'couponDate', width: 120 }, //120
        { columnName: 'bondNominal', width: 120 }, //120
        { columnName: 'bondAmortization', width: 100 }, //100
        { columnName: 'bondCoupon', width: 90 }, //90
        { columnName: 'couponRate', width: 105 } //105
    ];

    onExportAtExcelButtonClick = (event) => {

        (async () => {

            if (this.calculationData == null) {
                return;
            }
            /*
             *         var query = {
            isin: data.ISIN,
            valueType: data.DataFixType,
            zcycdate: Localization.FormatDateTimeISO(data.ZCYCDate),
            evaluationdate: Localization.FormatDateISO(data.EvaluationDate),
            cpr: data.CPR,
            cdr: data.CDR,
            conventionversion: data.ConventionVersion,
            useknownflows: data.UseRealFlows,
            useadjustedwam: data.UseAdjustedWAM,
            correctedyield: data.CorrectedYield,
            pricedatatype: data.PriceDataType
        };
             */
            var data = { ...this.calculationData }; //it about 90% the same
            //convert to ISO
            data.EvaluationDate = Localization.FormatDateISO(new Date(data.EvaluationDate));
            data.ZCYCDate = Localization.FormatDateTimeISO(new Date(data.ZCYCDate));
            //get from calculated result
            data.CleanPrice = this.state.bondResults.cleanPrice != "" ? [this.state.bondResults.cleanPrice] : null;
            data.DirtyPrice = this.state.bondResults.dirtyPrice != "" ? [this.state.bondResults.dirtyPrice] : null;
            data.KeyRates = this.state.keyRates != null ? { model: this.state.keyRates.editModel } : null;

            /*
            var data = {
                BondID: this.state.selectedBond.id,
                EvaluationDate: Localization.FormatDateISO(this.state.evaluationDate),
                ZCYCDate: this.state.ZCYCDate,
                CPR: this.state.cpr,
                CDR: this.state.cdr,
                //DirtyPrice: this.state.dirtyPrice,
                //CleanPrice: this.state.cleanPrice,
                ZSpread: this.state.zSpread != "" ? [this.state.zSpread] : null,
                GSpread: this.state.gSpread != "" ? [this.state.gSpread] : null,
                CouponRate: [this.state.couponRate],
                DataFixType: this.state.variableFixType, //to match eCalculationType
                CleanPrice: this.state.bondResults.cleanPrice != "" ? [this.state.bondResults.cleanPrice] : null,
                DirtyPrice: this.state.bondResults.dirtyPrice != "" ? [this.state.bondResults.dirtyPrice] : null,
                //Expenses: this.state.bondResults.expenses,
                ConventionVersion: this.state.conventionVersion,
                UseAdjustedWAM: this.state.isUseAdjustedWAM,
                CorrectedYield: this.state.isCorrectedYield,
                KeyRates: this.state.keyRates != null ? { model: this.state.keyRates.editModel } : null
            };
            */
            var json = JSON.stringify(data);
            var base64 = b64EncodeUnicode(json);

            //downloadData('/api/DomRf/DownloadBondsStatistics?data=' + encodeURI(base64));

            window.open(`/api/DomRf/DownloadBondsStatistics?data=` + escape(base64), '_blank');

        })();
    }

    onSaveFlowsButtonClick = (event) => {

        if (this.state.bondResults == null)
            return;

        var textToCopy = this.state.columns.map((item) => item.title).join('\t') + '\r\n';

        textToCopy += this.state.bondResults.flow
            .filter((item) => this.state.showHistoryFlows ? true : item.flowType == FlowTypes.MODEL || item.flowType == FlowTypes.USED_HISTORY)
            .map((item) => (
                Localization.FormatDateStringLocale(item.couponDate) + '\t' +
                Localization.FormatNumberString(item.bondNominal) + '\t' +
                Localization.FormatNumberString(item.bondAmortization) + '\t' +
                Localization.FormatNumberString(item.bondCoupon) + '\t' +
                Localization.FormatNumberString(item.couponRate))).join('\r\n');

        copyTextToClipboard(textToCopy);
    }

    /*
    onSaveZCYCAsImageButtonClick = (event) => {
        if (this.zcycChart)
            this.zcycChart.chart.exportChart({ width: 2048 });
    }
    */

    onSaveStatsButtonClick = (bondsHeader, convMetrics, bondData, poolData) => {

        var textToCopy = this.props.t('EvaluationParameters') + '\r\n';

        textToCopy += this.props.t('Bond') + '\t' + [0] + '\r\n';

        textToCopy += this.props.t('ConventionNameEnterZCYCDateTime') + '\t' + Localization.FormatDateTimeStringLocale(this.state.ZCYCDate) + '\r\n';
        textToCopy += (isBondVisible(this.state.selectedBond?.bondStatus) ? this.props.t('ConventionNameEnterEvaluationDatePlacement') : this.props.t('ConventionNameEnterEvaluationDate')) + '\t' + Localization.FormatDateStringLocale(this.state.evaluationDate) + '\r\n';
        //textToCopy += this.props.t('ConventionNameEnterCPR') + '\t' + Localization.FormatNumberString(this.state.cpr) + '\r\n';
        //textToCopy += this.props.t('ConventionNameEnterCDR') + '\t' + Localization.FormatNumberString(this.state.cdr) + '\r\n' + '\r\n';

        textToCopy += this.props.t('ConventionNamePricingMetrics') + '\r\n';

        //textToCopy += convMetrics.map((item) => item[0].propertyName + '\t' + Localization.FormatNumberString(item[1])).join('\r\n');

        textToCopy += convMetrics.map((row, index) =>
            row.filter(x => x != null).map((cell, index) =>
                index == 0 ? (cell.propertyName) : (row[0].extraProperty != null ?
                    (
                        Localization.FormatNumberString(cell, row[0].decimalPlaces, row[0].decimalPlaces) + ' ' + (index == 1 ? row[0].propertyUnit : row[0].extraPropertyUnit)
                    ) : (
                        row[0].formatter != null ? row[0].formatter(cell) : Localization.FormatNumberString(cell, row[0].decimalPlaces, row[0].decimalPlaces)
                    )
                )).join('\t')
        ).join('\r\n');

        textToCopy += '\r\n' + '\r\n';
        textToCopy += this.props.t('ConventionNameBondParameters') + '\r\n';

        //textToCopy += bondData.map((item) => (item[0].propertyName + '\t' + Localization.FormatNumberString(item[1]))).join('\r\n');

        textToCopy += bondData.map((row, index) =>
            row.map((cell, index) =>
                index == 0 ? (cell.propertyName) :
                    (
                        row[0].formatter != null ? row[0].formatter(cell) : Localization.FormatNumberString(cell, row[0].decimalPlaces, row[0].decimalPlaces)
                    )).join('\t')
        ).join('\r\n');

        textToCopy += '\r\n' + '\r\n';
        textToCopy += this.props.t('PoolParameters') + '\r\n';

        textToCopy += poolData.map((row, index) =>
            row.map((cell, index) =>
                index == 0 ? (cell.propertyName) : (
                    row[0].formatter != null ? row[0].formatter(cell) : Localization.FormatNumberString(cell, row[0].decimalPlaces, row[0].decimalPlaces)
                )).join('\t')
        ).join('\r\n');

        copyTextToClipboard(textToCopy);
    }

    conventionVersions = () => {
        this.setState({ showVersionsInfo: true })
    }

    onCloseConventionVersions = () => {
        this.setState({ showVersionsInfo: false })
    }

    conventionInfo = () => {
        this.setState({ showDisclaimer: true })
    }

    onCloseAbout = () => {
        this.setState({ showDisclaimer: false })
    }

    handleHistoryCheckChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ showHistoryFlows: e.target.checked });
    }

    // toolbarRoot = (props, state) => (
    //     <div className={this.props.classes.toolbar}>
    //      {/* <Toolbar.Root className={this.props.classes.toolbar} {...props}> */}
    //         <FormControlLabel
    //             className='m-0 me-auto'
    //             disabled={this.state.bondResults == null || this.state.historyFlowsDisabled}
    //             control={
    //                 <Checkbox
    //                     id='showHistoryFlows'
    //                     name='showHistoryFlows'
    //                     size='small'
    //                     checked={state.showHistoryFlows && !this.state.historyFlowsDisabled}
    //                     onChange={this.handleHistoryCheckChange}
    //                     color="primary"
    //                 />
    //             }
    //             label={this.props.t('ShowHistory')}
    //         />

    //         <ClickAwayListener onClickAway={this.handleTooltipClose}>
    //             <div>
    //                 <Tooltip
    //                     PopperProps={{
    //                         // disablePortal: true,
    //                     }}
    //                     onClose={this.handleTooltipClose}
    //                     open={this.state.openGridTooltip}
    //                     disableFocusListener
    //                     disableHoverListener
    //                     disableTouchListener
    //                     title={
    //                         <React.Fragment>
    //                             <p>
    //                                 <b style={{ color: style.colorPrimary3_120, fontSize: '2rem', lineHeight: 0 }}>■</b>{this.props.t('GridColorsInformation_Gray')}
    //                             </p>
    //                             <p>
    //                                 <b style={{ color: style.colorPrimary2, fontSize: '2rem', lineHeight: 0 }}>■</b>{this.props.t('GridColorsInformation_Green')}
    //                             </p>
    //                             <p>
    //                                 <b style={{ color: style.colorPrimary1, fontSize: '2rem', lineHeight: 0 }}>■</b>{this.props.t('GridColorsInformation_Black')}
    //                             </p>
    //                         </React.Fragment>
    //                     }>
    //                     <Button color="primary" onClick={this.openTooltipClick}>
    //                         {this.props.t('WhatInTheTable')}
    //                     </Button>

    //                 </Tooltip>
    //             </div>
    //         </ClickAwayListener>


    //         <Tooltip
    //             disableFocusListener
    //             //interactive
    //             title={this.props.t('CopyAtBuffer')}>
    //                 <IconButton size="small" disabled={this.state.bondResults == null} onClick={this.onSaveFlowsButtonClick}>
    //                     <FileCopyOutlined />
    //                 </IconButton>
    //         </Tooltip>
    //     {/* </Toolbar.Root> */}
    //     </div>

    // );

    openTooltipClick = (evt) => {
        this.setState({ openGridTooltip: true });
    }

    handleTooltipClose = (evt) => {
        if (this.state.openGridTooltip)
            this.setState({ openGridTooltip: false });
    }

    // tableRoot = props =><GridL.Root {...props} style={{  height: '100%'}}/>;

    // headerCell = props => <TableHeaderRow.Cell {...props} style={{ textAlign: 'center', position:'sticky', top:0, backgroundColor:'#FFF' }} />


    // tableRow = ({ row, ...restProps }) => (
    //     <Table.Row
    //         className={clsx(restProps.className, this.getRowClass(row.flowType))}
    //         // eslint-disable-next-line no-alert
    //         //style={row.isHistorical ? { color: style.colorPrimary2 } : {}}
    //         {...restProps}

    //     />
    // );

    getRowClass = (type) => {
        switch (type) {
            case FlowTypes.MODEL:
                return {};
            case FlowTypes.HISTORY:
                return this.props.classes.historyTableRow;
            case FlowTypes.USED_HISTORY:
                return this.props.classes.usedHistoryTableRow;
        }
        return {};
    }

    onBondLoadConfirm = () => {

        if (this.state.bondId == null) {
            this.onConfirmClose();
            return;
        }

        if (this.props.options == null || this.props.options.length == 0) {
            this.props.loadBonds('', this.state.bondId);
            return;
        }


        var bond = this.props.options.find((item) => item.id == this.state.bondId);
        if (bond == null) {
            this.props.loadBonds('', this.state.bondId);
            return; //CHECKME!!! won't switch
        }

        var changes = {};

        if (isBondVisible(bond.bondStatus)) {
            //if (this.state.variableFixType != 2) {
            //changes['variableFixType'] = 2;
            //}

            changes['cleanPrice'] = 100;
            changes['dirtyPrice'] = 100;
        }
        else {

            //if (bond.bondStatus != BondStatus.STATUS_PLACEMENT && this.state.variableFixType != 4) {
            //changes['variableFixType'] = 4;
            //}

            //changes['minEvaluationDate'] = new Date();
            //changes['evaluationDate'] = new Date();
        }

        this.setState({ selectedBond: bond, ...changes });
        this.onConfirmClose();
    }

    onConfirmClose = () => {
        this.setState({ showConfirm: false, bondId: null });
    }

    //handleChartClose = () => {
    //this.setState({ showChart: false });
    //}

    handleChartsButtonClick = () => {
        //this.setState({ showChart: true });
        var query = '';
        if (this.state.selectedBond != null) {
            query = '?isins=' + this.state.selectedBond.isin;
        }
        window.open(`/instruments/pools/statistics` + query, "_blank");
    }

    handleZCYCChartClose = () => {
        this.setState({ showZCYCChart: false });
    }

    handleZCYCChartsButtonClick = () => {
        this.setState({ showZCYCChart: true });
    }

    handleMOEXButtonClick = () => {
        window.open("https://www.moex.com/ru/marketdata/indices/state/g-curve/", "_blank");
    }

    handleClearButtonClick = () => {
        // console.log(this.state)
        this.setState({ selectedBond: null, evaluationDate: new Date() }, () => {
            this.clearPage();
            this.updateQueryString();
        });
    }

    onBreakpointChange = breakpoint => {
        this.layoutHolder.breakpoint = breakpoint;
        //this.setState({ currentBreakpoint: breakpoint });
    };

    onBreakpointUpdate = breakpoint => {
        this.setState({ currentBreakpoint: breakpoint });
    };

    // onLayoutChange = (layout, layouts) => {

    //     //we don't save "edit" state
    //     //var layoutsToSave = {};
    //     //Object.keys(layouts).forEach(key => {
    //     //layoutsToSave[key] = layouts[key].map(panel => { return { i: panel.i, x: panel.x, y: panel.y, w: panel.w, h: panel.h, isResizable: false, isDraggable: false } });
    //     //});

    //     /*
    //     Globals.setState('conventionLayout', { layouts: layoutsToSave, breakpoint: this.state.currentBreakpoint });
    //     */

    //     this.layoutHolder.layouts = layouts;
    //     this.setState({ layouts: layouts });
    // };

    // onLayoutUpdate = (layouts: any[]) => {
    //     this.setState({ layouts: layouts });
    // }

    // onRemoveLayoutFromDB = (id: string) => {
    //     this.props.removeLayout(id);

    //     this.layoutHolder.removeLoadedLayout(id);
    // }

    //{ layouts: this.layouts, breakpoint: this.breakpoint }
    // onSaveLayout = (data: any) => {
    //     this.setState({ layoutNameDialog: true, layoutToSave: data });
    // }

    // handleLayoutNameDialogClose = (event, data) => {

    //     if (data != null && this.state.layoutToSave != null) {

    //         //save data
    //         //layout to save
    //         var layoutData = {
    //             name: data.name,
    //             pageLayout: JSON.stringify(this.state.layoutToSave),
    //             location: 2
    //         };

    //         this.props.saveLayout(layoutData);
    //     }

    //     this.setState({ layoutNameDialog: false, layoutToSave: null });
    // }

    // CPRCDRSumValidate = () => {
    //     if (this.state.cpr == '' || this.state.cdr == '')
    //         return true; //no data, must fire required rule if available
    //     return (parseFloat(this.state.cpr.toString()) + parseFloat(this.state.cdr.toString())) < 100;
    // }

    onApplyVariableCPR = (data: any) => {

        //CHECKME!!! set correct data
        this.setState({ variableCPR: data }, this.requestCalculation);
    }

    onApplyLoadedKeyRate = (data: any) => {

        if (this.state.keyrateChartData == null)
            return;

        //CHECKME!!! set correct data
        this.setState({ keyRates: data }, this.requestCalculation);
    }

    onApplyKeyRate = (data: any) => {
        //data is  { historical: historical, model: model, editModel: editModel }
        //CHECKME!!! set correct data
        this.setState({ keyRates: data }, this.requestCalculation);
    }

    onCPREdit = (data: any) => {
        this.setState({ editedCPR: data });
    }

    handleInputChanged = (data: any) => {
        var changes: any = {};

        if (this.state.bondNameType != data.bondNameType)
            changes.bondNameType = data.bondNameType


        if (this.state.isUseAdjustedWAM != data.isUseAdjustedWAM)
            changes.isUseAdjustedWAM = data.isUseAdjustedWAM
        if (this.state.evaluationDate?.getTime() != data.evaluationDate?.getTime()) {
            changes.evaluationDate = data.evaluationDate;
            changes.variableCPR = null
        }
        if (this.state.backupedCPR != data.backupedCPR)
            changes.backupedCPR = data.backupedCPR;
        if (this.state.editedCPR != data.editedCPR)
            changes.editedCPR = data.editedCPR;
        if (this.state.cpr != data.cpr)
            changes.cpr = data.cpr;
        if (this.state.cdr != data.cdr)
            changes.cdr = data.cdr;
        if (this.state.backupCPR != data.backupCPR)
            changes.backupCPR = data.backupCPR;
        if (this.state.zSpread != data.zSpread)
            changes.zSpread = data.zSpread;
        if (this.state.gSpread != data.gSpread)
            changes.gSpread = data.gSpread;
        if (this.state.couponRate != data.couponRate)
            changes.couponRate = data.couponRate;
        if (this.state.couponType != data.couponType)
            changes.couponType = data.couponType;
        if (this.state.cleanPrice != data.cleanPrice)
            changes.cleanPrice = data.cleanPrice;
        if (this.state.dirtyPrice != data.dirtyPrice)
            changes.dirtyPrice = data.dirtyPrice;
        if (this.state.priceDataType != data.priceDataType)
            changes.priceDataType = data.priceDataType;
        if (this.state.selectedBond != data.selectedBond)
            changes.selectedBond = data.selectedBond;
        if (this.state.variableFixType != data.variableFixType)
            changes.variableFixType = data.variableFixType;

        if (getTime(data.ZCYCDate)?.getTime() != getTime(this.state.ZCYCDate)?.getTime()) {
            //changes.ZCYCDate = data.ZCYCDate; updates from props directly
            this.props.getComplexYZCYCC(data.ZCYCDate);
            //this.props.loadMoexSecurities(data.ZCYCDate);
        }

        //console.log("===Inputs changed, data: " + JSON.stringify(changes));
        this.setState(changes);

        //update query
        //CHECKME!!! merge with requestCalculation
        var query = { ...this.calculationData, ...changes };
        if (data.selectedBond?.isin != null)
            query.ISIN = data.selectedBond.isin;
        /*
        if (data.selectedBond?.id != null)
            query.BondID = data.selectedBond?.id;
        if (data.evaluationDate != null)
            query.EvaluationDate = data.evaluationDate;
        if (this.state.ZCYCDate != null)
            query.ZCYCDate = this.state.ZCYCDate;
        if (data.cpr != null)
            query.CPR = data.cpr;
        if (data.cdr != null)
            query.CDR = data.cdr;
        if (data.dirtyPrice != null)
            query.DirtyPrice = data.dirtyPrice;
        if (data.cleanPrice != null)
            query.CleanPrice = data.cleanPrice;
        if (data.zSpread != null)
            query.ZSpread = data.zSpread;
        if (data.gSpread != null)
            query.GSpread = data.gSpread;
        if (data.couponRate != null)
            query.CouponRate = data.couponRate;
        if (data.tabIndex != null)
            query.DataFixType = data.tabIndex;
        if (data.isUseKnownFlows != null)
            query.UseRealFlows = data.isUseKnownFlows;
        if (data.isUseAdjustedWAM != null)
            query.UseAdjustedWAM = data.isUseAdjustedWAM;
        if (data.isCorrectedYield != null)
            query.CorrectedYield = data.isCorrectedYield;
        if (data.conventionVersion != null)
            query.ConventionVersion = data.conventionVersion;
        if (data.calculationModeTabIndex != null)
            query.AnalysisType = data.calculationModeTabIndex;
        if (data.priceDataType != null)
            query.PriceDataType = data.priceDataType;
        if (data.couponType != null)
            query.CouponType = data.couponType;
        */
        this.updateQueryString(query);

    }

    loadMoexDebounced = debounce((zcycDate) => this.props.loadMoexSecurities(zcycDate), 1000);

    inputPanel = (t, classes) => {
        //1-я панель
        return (
            <Grid item xs={12} sm={6} md={6} lg={3.6} xl={3.6}
                key='inputPanel'
                sx={{ position: 'relative' }}>
                <Paper key='input' sx={{ m: 0, p: 0, height: '100%' }}>
                    <Box sx={{
                        display: 'flex', pl: 1,
                        flexWrap: 'nowrap',
                        flexDirection: 'row',
                        alignContent: 'center',
                        alignItems: 'baseline',
                    }}>
                        <Typography sx={{ textTransform: 'uppercase', lineHeight: '2rem', maxHeight: '2rem', overflow: 'hidden', ml: 1, fontSize: '14px', flexGrow: 1, color: style.colorPrimary3 }} >
                            {t("InputPanelTitle")}
                        </Typography>

                        {(this.state.selectedBond?.couponType == CouponType.TYPE_FLOAT) && (
                            <Box component="span" sx={{ pr: 1, display: { xs: 'initial' } }}>
                                <TooltipWithLink
                                    id='warning-button'
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                    open={this.state.showFloaterWarning}
                                    onClose={() => this.setState({ showFloaterWarning: false })}
                                    content={t('Conv1FloatersWarning')}
                                >
                                    {/* кнопка Warning */}
                                    <IconButton
                                        sx={{ width: 30, height: 30 }}
                                        onClick={() => this.setState({ showFloaterWarning: true })}
                                    // disabled={!this.state.selectedBond}
                                    >
                                        <WarningAmber sx={{ fill: style.colorPrimary2 }} />
                                    </IconButton>
                                </TooltipWithLink>
                                {/* <span className={classes.warning} dangerouslySetInnerHTML={{ __html: t('Conv1FloatersWarning') }}></span>     */}
                            </Box>
                        )}

                        <Box component="span" sx={{ pr: 1, overflow: 'visible', display: { xs: 'none', lg: 'initial' } }}>
                            <Tooltip
                                disableFocusListener
                                title={this.props.t('APIButton')}>
                                {/* кнопка API */}
                                <IconButton sx={{ width: 31, p: '3px' }}
                                    onClick={() => window.open(`https://калькулятор.дом.рф:8193/`, '_blank')}
                                >
                                    <ApiIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                        <Box component="span" sx={{ pr: 1, overflow: 'visible',  /*, display:{xs:'none', lg:'initial'}*/ }}>
                            {/*  кнопка Дисклеймер */}
                            <TooltipWithLink
                                id='disclamer-button'
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                open={this.state.showDisclaimer}
                                onClose={this.onCloseAbout}
                                // content={this.test}
                                content={t('ConventionNewText')} //t('ScenarioAnalysis_Info')
                                linkName={t('Convention')}
                                link="https://fs.moex.com/files/19698/"
                            >
                                <IconButton sx={{ width: 30, height: 30 }} onClick={() => this.conventionInfo()}>
                                    <InfoOutlined />
                                </IconButton>
                            </TooltipWithLink>
                        </Box>
                        <Box component="span" sx={{ pr: 1, overflow: 'visible', display: { xs: 'initial' } }}>
                            <Tooltip
                                disableFocusListener
                                title={this.props.t('CleanParameters')}>
                                {/* кнопка Очистить */}
                                <span> { /*A disabled element does not fire events. Tooltip needs to listen to the child element's events to display the title. */}
                                    <IconButton
                                        sx={{ width: 30, height: 30 }}
                                        onClick={() => this.handleClearButtonClick()}
                                        disabled={!this.state.selectedBond}
                                    >
                                        <BackspaceOutlined />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        </Box>
                    </Box>
                    <Divider />
                    {/* {console.log('bondResults', this.state.options)} */}
                    <InputPanel
                        ref={x => this.inputPanelRef = x}
                        bonds={this.props.options}
                        ZCYCDate={this.props.ZCYCDate}
                        requestCalculation={this.onRequestCalculation}
                        bondSelected={this.onBondSelected}
                        getBondData={this.props.getBondData}
                        bondStats={this.props.cprcdr}
                        changeEvaluationMode={this.updateEvaluationMode}
                        onInputChanged={this.handleInputChanged}
                        query={this.state.queryData}
                        // test={this.state?.queryData?.zcycdate}
                        bondResults={this.state.bondResults}
                    // onAdjustedWAMchange={this.onAdjustedWAMchange}

                    />
                </Paper>
            </Grid>
        );
    };



    statsPanel = (t, classes) => {
        // {console.log(this.state.selectedBond)}
        //2-я панель
        return (
            <Grid item xs={12} sm={6} md={6} lg={3.4} xl={3.4}
                key='statsPanel'
                sx={{ position: 'relative', }}>
                <Paper key='stats' className={classes.panel}
                    sx={
                        ((this.state.isMobile == true && !this.landscapeDetect()) && !this.state.statsEmptyLabel && !this.state.bondResults && (window && window.screen.width < 700)) ?
                            { display: 'none !important' }
                            : { pb: 0 }
                    }
                >
                    {/* <div id='stats' className={clsx(classes.hideButton, this.layoutHolder.isEditLayoutMode ? '' : classes.hidden)} onClick={this.layoutHolder.onHidePanel}>&times;</div> */}

                    <StatisticsPanel
                        evaluationMode={this.state.evaluationMode}
                        isUseAdjustedWAM={this.state.isUseAdjustedWAM}
                        conventionVersion={this.state.conventionVersion}
                        bondResults={this.state.bondResults}
                        emptyLabel={this.state.statsEmptyLabel}
                        isFixedCashflow={this.state.isFixedCashflow}
                        onExportAtExcelButtonClick={this.onExportAtExcelButtonClick}
                    />
                </Paper>
            </Grid>
        );
    };

    resultsPanel = (t, flows) => {
        //3-я панель
        let prevFlow
        return (
            <Grid item xs={12} sm={12} md={12} lg={5} xl={5}
                key='resultsPanel'
                sx={{
                    position: 'relative',
                    // minHeight:{xs:'100vh', lg:'unset'},
                    // maxHeight:{xl:'35vh'},
                    display: { xs: 'none !important', sm: 'initial !important' },
                    // order:{sm:'1000',md:'unset'}
                }}>
                <Paper key='results' className={this.props.classes.panel} sx={{ width: '100%', height: '100%', position: 'relative' }}>
                    <Box sx={{
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        overflow: 'hidden',
                        display: 'flex',
                        flexDirection: 'column',
                        flexWrap: 'nowrap',
                        whiteSpace: 'nowrap',
                    }}
                    >
                        <Box sx={{
                            display: 'flex', pl: 1,
                            flexWrap: 'nowrap',
                            flexDirection: 'row',
                            alignContent: 'center',
                            alignItems: 'center',

                        }}>
                            <Typography sx={{
                                textTransform: 'uppercase', lineHeight: '2rem', maxHeight: '2rem',
                                overflow: 'hidden', ml: 1,
                                display: 'flex', alignItems: 'center',
                                flexGrow: 1, fontSize: '14px', color: style.colorPrimary3
                            }} >
                                {
                                    // this.state..map((item, index) =>item)
                                    t("BondsCasheFlowPanelTitle")
                                }
                                {/* <Box component="span" sx={{pr:1}}> */}
                                <ClickAwayListener onClickAway={this.handleTooltipClose}>
                                    <Tooltip
                                        onClose={this.handleTooltipClose}
                                        open={this.state.openGridTooltip}
                                        disableFocusListener
                                        disableHoverListener
                                        disableTouchListener
                                        title={
                                            <>
                                                <p>
                                                    <b style={{ color: style.colorPrimary3_120, fontSize: '2rem', lineHeight: 0 }}>■</b>{this.props.t('GridColorsInformation_Gray')}
                                                </p>
                                                <p>
                                                    <b style={{ color: style.colorPrimary2, fontSize: '2rem', lineHeight: 0 }}>■</b>{this.props.t('GridColorsInformation_Green')}
                                                </p>
                                                <p>
                                                    <b style={{ color: style.colorPrimary1, fontSize: '2rem', lineHeight: 0 }}>■</b>{this.props.t('GridColorsInformation_Black')}
                                                </p>
                                            </>
                                        }>
                                        <IconButton onClick={this.openTooltipClick}>
                                            <HelpOutline sx={{ width: 18, height: 18, }} />
                                        </IconButton>
                                    </Tooltip>
                                </ClickAwayListener>
                                {/* </Box> */}
                            </Typography>

                            <Box component="span" sx={{ pr: 1 }}>
                                <FormControlLabel
                                    // className='m-0 me-auto'
                                    disabled={this.state.bondResults == null || this.state.historyFlowsDisabled}
                                    control={
                                        <Checkbox
                                            id='showHistoryFlows'
                                            name='showHistoryFlows'
                                            size='small'
                                            checked={this.state.showHistoryFlows && !this.state.historyFlowsDisabled}
                                            onChange={this.handleHistoryCheckChange}
                                            color="primary"
                                            sx={{ p: 0, pr: 1, lineHeight: '2rem', height: '2rem', overflow: 'hidden' }}
                                        />
                                    }
                                    sx={{ m: 0, fontSize: '1rem' }}
                                    label={this.props.t('ShowHistory')}
                                />
                            </Box>

                            <Box component="span" sx={{ pr: 1 }}>
                                <Tooltip
                                    disableFocusListener
                                    //interactive
                                    title={this.props.t('CopyCashFlowResult')}>
                                    <span> { /*A disabled element does not fire events. Tooltip needs to listen to the child element's events to display the title. */}
                                        <IconButton sx={{ width: 30, height: 30 }} disabled={this.state.bondResults == null} onClick={this.onSaveFlowsButtonClick}>
                                            <FileCopyOutlined />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                            </Box>
                        </Box>
                        <Divider />
                        {console.log('column', this.state.columns)}
                        {/* {this.toolbarRoot({}, this.state)} */}
                        <TableContainer sx={{ m: 0, p: 0, flexGrow: 1, overflow: 'hidden auto' }} className={'modernScrollbar'}>
                            {/* height:'100%', */}
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {this.state.columns.map((column, index) => (
                                            <TableCell
                                                key={`flow-header-${index}`}
                                                align={'center'}
                                                sx={{ pl: 1, pr: 2, pt: 1, pb: 1 }}
                                            >
                                                {column.title}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {flows.map((row, index) => {
                                        let showDivider = false
                                        if (prevFlow && prevFlow != row.flowType && prevFlow === FlowTypes.HISTORY) showDivider = true
                                        prevFlow = row.flowType
                                        return (
                                            <TableRow /*hover*/ role="checkbox" tabIndex={-1} key={`flow-row-${index}`}
                                                className={clsx(this.getRowClass(row.flowType))}
                                                sx={{ position: 'relative', '&:hover': { bgcolor: style.colorPrimary2_20 } }}
                                            >
                                                {this.state.columns.map((column, idx) => {
                                                    const value = row[column.name];
                                                    return (
                                                        <TableCell key={`flow-cell-${index}-${idx}`} align='center' //align={column.align} 
                                                            sx={{
                                                                pl: 2, pr: 2, pt: 0.1, pb: 0.1,
                                                                // borderTop:showDivider?`dashed 1px ${style.colorPrimary3}`:'',
                                                            }}>
                                                            {showDivider ? <Box sx={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, borderTop: `dashed 1px ${style.colorPrimary3_40}` }}></Box> : <></>}

                                                            {typeof value === 'number' ?
                                                                Localization.FormatNumber(value)
                                                                : (typeof value === 'string' ?
                                                                    (Date(value) !== 'Invalid date' ? Localization.FormatDateLocale(new Date(value)) : value)
                                                                    : value
                                                                )
                                                            }
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        )
                                    })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Paper>
            </Grid>
        );
    };

    zcycPanel = () => {
        //4-я панель (КБД Москвоской биржи)
        return (
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}
                key='zcycPanel'
                sx={{
                    position: 'relative',
                    // minHeight:{xs:'100vh', sm:'80vh', lg:'unset'},
                    // maxHeight:{xs:'unset', sm:'80vh',md:'65vh'},
                    display: { xs: 'none !important', sm: 'initial !important' },
                    // border:1
                }}
            >
                <Paper key='zcyc' sx={{ width: '100%', height: '100%', }}>
                    <Stack sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexWrap: 'nowrap',
                        justifyContent: 'space-between',
                    }}>
                        <Box sx={{
                            display: 'flex', pl: 1,
                            flexWrap: 'nowrap',
                            flexDirection: 'row',
                            alignContent: 'center',
                            alignItems: 'baseline',
                        }}
                        >

                            <Typography sx={{
                                textTransform: 'uppercase', lineHeight: '2rem', maxHeight: '2rem', overflow: 'hidden', ml: 1,
                                display: 'flex', alignItems: 'center',
                                flexGrow: 1, fontSize: '14px', color: style.colorPrimary3
                            }} >
                                {this.props.t('ConventionNameYieldsGraphTitle')}
                                <TooltipInfoLabel right content={this.props.t('TooltipConventionZCYCGraphTitle')} />
                            </Typography>
                            <Box component="span" sx={this.state.isMobile ? { display: 'none' } : { pr: 1 }}>
                                {/* кнопка сравнить две КБД  */}
                                <Tooltip
                                    disableFocusListener
                                    title={this.props.t('TooltipConventionCompareCurvesButton')}
                                >
                                    {/* <span> */}
                                    <IconButton sx={{ width: 30, height: 30 }} onClick={() => this.handleZCYCChartsButtonClick()}>
                                        <SsidChart style={{ fill: style.colorPrimary3 }} />
                                    </IconButton>
                                    {/* </span> */}
                                </Tooltip>
                            </Box>
                            <Box component="span" sx={this.state.isMobile ? { display: 'none' } : { pr: 1 }}>
                                {/* кнопка MOEX */}
                                <Tooltip
                                    disableFocusListener
                                    title={this.props.t('ConventionNameZCYCWebsiteButton')}
                                >
                                    {/* <span> */}
                                    <Button variant="text" color="primary" onClick={this.handleMOEXButtonClick} sx={{ p: 0 }}>
                                        MOEX
                                    </Button>
                                    {/* </span> */}
                                </Tooltip>
                            </Box>                </Box>
                        <Divider />
                        <Item>
                            {/* <div className="text-center">
                        <TooltipInfoLabel key="chart-tooltip" content={this.props.t('TooltipConventionZCYCGraphTitle')}>
                            {this.props.t('ConventionNameYieldsGraphTitle')}
                        </TooltipInfoLabel>
                    </div> */}
                            {/* <ZcycChartPanel
                            zcycChartOptions={this.state.zcycChartOptions}
                        /> */}
                            <HighchartsReact
                                key="zcycChart"
                                ref={x => this.zcycChart = x}
                                highcharts={Highcharts}
                                options={this.state.zcycChartOptions}
                                containerProps={{ style: { height: "100%", width: "100%" } }}
                                callback={(chart) => {
                                    var handles = chart?.scroller?.handles
                                    if (handles)
                                        setTimeout(() => {
                                            if (chart?.series) Highcharts.objectEach(chart.series, function (el) { el.kdNow = true; })

                                            handles[0].hide()
                                        }, 1);
                                }}

                            />
                        </Item>
                        {/* <Item sx={{ml:1,mr:1}}>
                    <div className={this.props.classes.buttonsContainer}>
                        <Button variant="outlined" color="primary" onClick={this.handleMOEXButtonClick}>
                            {this.props.t('ConventionNameZCYCWebsiteButton')}
                        </Button>

                        <Tooltip
                            disableFocusListener
                            //interactive
                            title={this.props.t('TooltipConventionCompareCurvesButton')}
                        >
                            <span>
                            <Button variant="outlined" color="secondary" onClick={this.handleZCYCChartsButtonClick}
                                    sx={{ml:2}}
                            >
                                {this.props.t('ConventionNameCompareCurvesButton')}
                            </Button>
                            </span>
                        </Tooltip>
                    </div>
                </Item> */}
                    </Stack>
                </Paper>
            </Grid>
        );
    };

    flowsPanel = () => {
        //5-я панель (Денежный поток по облигации)
        return (
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}
                key='flowsPanel'
                sx={{
                    position: 'relative',
                    // minHeight:{xs:'initial', sm:'initial', md:'50vh', lg:'50vh', xl:'50vh'},
                    display: { xs: 'none !important', sm: 'initial !important' },
                    // border:1,
                }}>
                <Paper key='flows' className={this.props.classes.panel}
                    sx={{ width: '100%' }}
                >
                    {/* {console.log('this.state.bondResults', this.state.selectedBond)} */}

                    <FlowsPanel
                        cpr={this.state.cpr}
                        cdr={this.state.cdr}
                        showHistoryFlows={this.state.showHistoryFlows}
                        bondResults={this.state.bondResults}
                        selectedBond={this.state.selectedBond}
                        localization={this.state.flowsLocalization} />

                </Paper>
            </Grid>
        );
    };

    keyRatePanel = () => {
        //Ожидаемые значения ключевой ставки

        let rotateLocker = false;
        if (this.state.isMobile && !this.landscapeDetect() && window && window.screen.width < 700)
            rotateLocker = true
        else
            rotateLocker = false

        return (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}
                key='keyRatePanel'
                sx={{
                    position: 'relative',
                    height: '460px'
                    // height:(this.state.isMobile && this.landscapeDetect() && window && window.screen.width>400)?{xs:'95vh'}:{},
                    // maxHeight:{xs:'100vh', sm:'100vh',lg:'unset'}
                }}>
                {rotateLocker ? <RotateLocker /> : <></>}

                <Paper key='keyrate' className={this.props.classes.panel} sx={{ position: 'relative' }}>
                    {/* <div id='keyrate' className={clsx(this.props.classes.hideButton, this.layoutHolder.isEditLayoutMode ? '' : this.props.classes.hidden)} onClick={this.layoutHolder.onHidePanel}>&times;</div> */}
                    <KeyRatePanel
                        keyrateChartData={this.state.keyrateChartData}
                        //zcycDate={this.state.ZCYCDate}
                        evaluationDate={this.state.evaluationDate}
                        onApply={this.onApplyKeyRate} // вызываем с параметрами { historical: historical, model: model, editModel: editModel }
                        onLoaded={this.onApplyLoadedKeyRate}
                        isEditable={true} />

                </Paper>
            </Grid>
        );
    };

    sensitivityPanel = () => {
        //Задайте свой CPR
        return (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}
                key='sensitivityPanel'
                sx={{
                    position: 'relative',
                    minHeight: { xs: 'initial', sm: 'initial', md: '50vh', lg: '50vh', xl: '50vh' },
                    // maxHeight:{xs:'50vh', sm:'50vh', md:'50vh', lg:'50vh', xl:'50vh'},
                    display: { xs: 'none !important', sm: 'initial !important' },
                }}>
                <Paper key='sensitivity' className={this.props.classes.panel} sx={{ p: 0, position: 'relative' }}>
                    {/* <div id='sensitivity' className={clsx(this.props.classes.hideButton, this.layoutHolder.isEditLayoutMode ? '' : this.props.classes.hidden)} onClick={this.layoutHolder.onHidePanel}>&times;</div> */}
                    <SensitivityPanel
                        averageCPR={this.state.backupedCPR}
                        editedCPR={this.state.editedCPR}
                        onCPREdit={this.onCPREdit}
                        bondId={this.state.selectedBond?.id}
                        evaluationDate={this.state.evaluationDate}
                        chartName={this.state.selectedBond?.displayName}
                        onApply={this.onApplyVariableCPR} />
                </Paper>
            </Grid>
        );
    };

    spectrumPanel = () => {
        //Сценарный анализ
        return (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}
                key='spectrumPanel'
                sx={{
                    position: 'relative',
                    // minHeight:{xs:'initial', sm:'initial', md:'50vh', lg:'50vh', xl:'50vh'},
                    // maxHeight:{xs:'50vh', sm:'50vh', md:'50vh', lg:'50vh', xl:'50vh'}
                }}>
                <Paper key='sensitivity' className={this.props.classes.panel} sx={{ p: 0, position: 'relative' }}>
                    <SpectrumPanel
                        cpr={this.state.cpr}
                        cdr={this.state.cdr}
                        backupCPR={this.state.backupedCPR}
                        zSpread={this.state.zSpread}
                        gSpread={this.state.gSpread}
                        couponRate={this.state.couponRate}
                        couponType={this.state.couponType}
                        cleanPrice={this.state.cleanPrice}
                        dirtyPrice={this.state.dirtyPrice}
                        priceMeasureType={this.state.priceDataType}
                        selectedBond={this.state.selectedBond}
                        variableFixType={this.state.variableFixType}
                        results={this.state.bondResults}
                        onSettingsChanged={this.spectrumChanged} />
                </Paper>
            </Grid>
        );
    };

    spectrumChanged = (settings) => {

        var cpr = this.state.cpr;
        if (cpr != settings.SecondCPR)
            cpr = settings.SecondCPR;

        //var changes = {};
        //if (settings.isValid) {
        //changes["requestsToCalculate"] = this.state.requestsToCalculate + 1;
        //}

        this.setState({ spectrumParameters: settings, cpr: cpr /*, ...changes*/ }, this.requestCalculation);
    }

    /*
    couponFormatter = (value) => {
        switch (value) {
            case CouponTypeStr.FIXED:
                return this.props.t('Fixed');
            case CouponTypeStr.FLOATING:
                return this.props.t('Floating');
            case CouponTypeStr.KEYRATE_AND_PREMIUM:
                {
                    //if (this.state.bondResults != null)
                    //return stringInject(this.props.t('KeyRateAndPremium_Params'), [Localization.FormatNumberString(this.state.bondResults.couponRate)]);

                    return this.props.t('KeyRateAndPremium');
                }
            default:
                return this.props.t('Unknown');
        }
    }

    dateFormatter = (value) => {
        return value != null ? Localization.FormatDateStringLocale(value) : '-';
    }
    */
    render() {

        const { classes, bond, t, setTitle } = this.props;

        var flows: any[] = [];

        if (this.state.bondResults != null) {
            flows = (this.state.showHistoryFlows) ? this.state.bondResults.flow : this.state.bondResults.flow.filter(x => x.flowType == FlowTypes.MODEL || x.flowType == FlowTypes.USED_HISTORY);
        }

        const layoutPanels = {
            input: () => this.inputPanel(t, classes),
            stats: () => this.statsPanel(t, classes),
            zcyc: () => this.zcycPanel(),
            results: () => this.resultsPanel(t, flows),
            flows: () => this.flowsPanel(),
            sensitivity: () => this.sensitivityPanel(),
            spectrum: () => this.spectrumPanel(),
            keyrate: () => this.keyRatePanel()
        };
        // console.log('this.state',this.state.currentBreakpoint);

        // setTitle(t('ConventionNameInstrumentTitle')) //Устанавлимваем заголовок в Store

        return (
            <>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{t('ConventionNameInstrumentTitle')}</title>
                </Helmet>

                <ConfirmationForm
                    isOpen={this.state.showConvention2Alert}
                    header={t("convention.newVersionAlertTitle")}
                    content={<div dangerouslySetInnerHTML={{ __html: getTooltip({ tooltip: 'convention.newVersionAlert' }) }} />}
                    classes={{ dialogActions: classes.dialogActions, dialogContent: classes.dialogContent }}
                    declineButton={t('convention.acceptButton')}
                    onDecline={() => { this.setState({ showConvention2Alert: false }) }}
                    acceptButton={t('convention.declineButton')}
                    onAccept={() => { window.open(`/instruments/convention2`, '_self') }}
                />

                <ZCYCDialog show={this.state.showZCYCChart} firstZCYCDate={this.state.ZCYCDate} onClose={this.handleZCYCChartClose} />
                <Container maxWidth="xxl" // className={clsx("d-flex align-items-center flex-wrap pt-2", classes.header)}
                    sx={{
                        mt: 2,
                        // mb:{xs:2,sm:2,md:3,},
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'nowrap',
                        justifyContent: 'flex-end',
                    }}>
                    <Spinner show={this.props.operationLoading} fullScreen content={t('Loading')} />


                    {/* <h3 className="me-auto">{t('ConventionNameInstrumentTitle')}</h3> */}



                    {/* <Tooltip
                            disableFocusListener
                            title={<div dangerouslySetInnerHTML={{ __html: t('TooltipConventionCPRGraphsButton') }}></div>}>
                            <span>
                            {// кнопка Статистика }
                                <Button className="me-2" variant="outlined" color="secondary" onClick={this.handleChartsButtonClick}
                                        sx={{ display:{xs:'none', lg:'inherit'}}}
                                >
                                    {this.props.t('Statistics')}
                                </Button>
                            </span>
                        </Tooltip>

                        <Tooltip
                            disableFocusListener
                            //interactive
                            title={this.props.t('TooltipConventionExcelButton')}>
                            
                            <span>
                            {// кнопка Экспорт в Эксель }
                                <Button className="me-2" variant="outlined" color="secondary" disabled={(this.state.bondResults == null) || this.state.isFixedCashflow} onClick={this.onExportAtExcelButtonClick}
                                        sx={{ display:{xs:'none', lg:'inherit'}}}
                                >
                                    {this.props.t('ConvenionNameExcelButton')}
                                </Button>
                            </span>
                        </Tooltip> */}


                </Container>
                <Container maxWidth="xxl">
                    {/* <AboutConventionDialog show={this.state.showDisclaimer} title={t('ConventionNameMainStatements')} information={t('ConventionMainStatementsText')} onClose={this.onCloseAbout} /> */}
                    <ConventionVersionsDialog show={this.state.showVersionsInfo} title={t('ConventionVersions')} onClose={this.onCloseConventionVersions}>
                        <ConventionInformation />
                    </ConventionVersionsDialog>

                    <ConfirmDialog
                        show={this.state.showConfirm}
                        title={t('Confirm')}
                        description={t('LoadBondParameters')}
                        onConfirm={this.onBondLoadConfirm}
                        onClose={this.onConfirmClose} />
                    {
                        <Grid container
                            spacing={{ xs: 2, md: 2, xl: 2 }}
                            alignItems="stretch"
                            sx={{
                                justifyContent: "center",
                                // borderSpacing:{xs:3, sm:3, md: 5, xl: 5},
                                // "& > div":{border:1}
                            }}
                        >
                            {
                                this.state.layouts.map((panel, index) => {
                                    return (
                                        panel.isVisible && layoutPanels[panel.id]()
                                    )
                                })
                            }
                        </Grid>
                    }
                    {/* </div> */}
                </Container>
            </>
        );
    }
}

var component = connect(
    state => { return { ...(state as any).domrf, ...(state as any).topNavbar } }, // Selects which state properties are merged into the component's props
    { ...DomRfStore.actionCreators, ...NavbarStore.actionCreators }
)(ConventionCalculationPage);

export default withRouter(withStyles(styles)(withTranslation('translations')(component)));