import * as React from 'react';
import { Routes } from 'react-router';
import { Route } from "react-router-dom";

import AuthorizedRoute from "@Components/authorization/AuthorizedRoute";

import LoginPage from '@Pages/authorization/LoginPage';
import SilentLoginPage from '@Pages/authorization/SilentLoginPage';
import ConfirmRegistrationPage from '@Pages/authorization/ConfirmRegistrationPage';
import PasswordResetPage from '@Pages/authorization/PasswordResetPage';
import ConfirmNewslettersUnsubscribePage from '@Pages/profile/ConfirmNewslettersUnsubscribePage';

import InstrumentsPage from '@Pages/InstrumentsPage';
import NotFoundPage from '@Pages/NotFoundPage';
import AccessDeniedPage from '@Pages/AccessDeniedPage';
import ProfilePage from '@Pages/ProfilePage';
import NotificationsPage from '@Pages/NotificationsPage';
import DownloadPage from '@Pages/DownloadPage';
import AutorizationCallbackPage from '@Pages/AutorizationCallbackPage';

import * as Instruments from "@Components/Instruments";
// import useLocalStorage from './components/hook/useLocalStorage';

// import Globals from "@Globals";
// import AuthorizedLayout from './layouts/AuthorizedLayout';
const availableRoutes = Instruments.Instruments()
    .filter((value) => value.actions != null)
    .map((element) => {
        return element.actions.map((item) => {
            return (
                <Route path={item.url} element={
                    <AuthorizedRoute
                        isUseAnonymous={item.isUseAnonymous || false}
                        path={item.url}
                        area={element.area}
                        extraProps={item.props}
                        permissionRequired={element.permissionRequired}
                        childElements={element.actions?.length || 0}
                        component={item.component} />
                } />
            );
        });
    });

export const AppRoutes = (

    <Routes>

        <Route path="/" element={<AuthorizedRoute path="/" />} />
        <Route path="/instruments" element={<AuthorizedRoute path="/instruments" component={InstrumentsPage} />} />
        <Route path="/login" element={<AuthorizedRoute path="/login" isUseAnonymous component={LoginPage} />} />
        <Route path="/silentLogin" element={<AuthorizedRoute path="/silentLogin" isUseAnonymous component={SilentLoginPage} />} />

        <Route path="/confirmRegistration" element={<AuthorizedRoute isUseAnonymous path="/confirmRegistration" component={ConfirmRegistrationPage} />} />
        <Route path="/resetPassword" element={<AuthorizedRoute isUseAnonymous path="/resetPassword" component={PasswordResetPage} />} />
        <Route path="/unsubscribe" element={<AuthorizedRoute isUseAnonymous path="/unsubscribe" component={ConfirmNewslettersUnsubscribePage} />} />
        <Route path="/profile" element={<AuthorizedRoute path="/profile" component={ProfilePage} />} />
        <Route path="/notifications" element={<AuthorizedRoute path="/notifications" component={NotificationsPage} />} />

        {availableRoutes}

        <Route path="/files/*" element={<AuthorizedRoute path="/files" isUseAnonymous component={DownloadPage} />} />
        <Route exact path="/authCallback" element={<AuthorizedRoute path="/authCallback" isUseAnonymous component={AutorizationCallbackPage} />} />
        <Route exact path="/accessDenied" element={<AuthorizedRoute path="/accessDenied" isUseAnonymous component={AccessDeniedPage} />} />
        <Route path="*" element={<AuthorizedRoute path="*" isUseAnonymous component={NotFoundPage} />} />
    </Routes>
);