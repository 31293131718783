import ConventionService from "@Base/services/ConventionService";
import PoolService from '@Base/services/PoolService'
import CalculatorService from "@Services/CalculatorService";
import DomRfService from "@Services/DomRfService";
import test from './test_data/covention.json'
import { Actions as LoaderActions } from "@Store/LoaderStore";
import Localization from "@Base/components/Localization";
import SecuritizationService from "@Base/services/SecuritizationService";

const Actions = {

    Init: "CONVENTION_INIT",

    RequestStart: "CONVENTION_REQUEST_START",
    RequestSuccess: "CONVENTION_REQUEST_SUCCESS",
    RequestFailure: "CONVENTION_REQUEST_FAILURE",

    // PartialRequestStart: "CONVENTION_PARTIAL_REQUEST_START",
    // PartialRequestSuccess: "CONVENTION_PARTIAL_REQUEST_SUCCESS",
    // PartialRequestFailure: "CONVENTION_PARTIAL_REQUEST_FAILURE",
}


export const actionCreators = {

    init: () => async (dispatch, getState) => {
        dispatch({ type: Actions.Init });
    },

    getZCYCDateTime: (date) => async (dispatch) => {

        dispatch({ type: Actions.RequestStart });

        var result = await SecuritizationService.getZCYCDateTime(date);
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailed });
            return;
        }

        dispatch({ type: Actions.RequestSuccess, payload: { zcycDate: { requertedDate: date, resultDate: result.value } } });
    },

    clearValue: (paramKey) => async (dispatch) => {
        // console.log('clear paramKey', paramKey)
        if (paramKey == null) {
            dispatch({ type: Actions.RequestFailure, payload: { loading: false } });
            return;
        }

        dispatch({ type: Actions.RequestSuccess, payload: { [paramKey]: null } });
    },


    getPoolLLDForChart: (isin, wacwamDate, step) => async (dispatch) => {

        dispatch({ type: Actions.RequestStart, payload: { loadingChartData: true } });
        var result = await PoolService.getPoolLLDForChart(isin, wacwamDate, step)

        if (result.hasErrors) {
            const error = result?.errors?.join('; ')
            dispatch({ type: Actions.RequestFailure, payload: { loadingChartData: false, chartsData: null, error: error } });
            return;
        }

        const error = result?.value?.error
        dispatch({ type: Actions.RequestSuccess, payload: { chartsData: { currentRateChart: result?.value }, loadingChartData: false, error: error } });
    },


    setData: (data) => async (dispatch) => {
        // console.log('clear paramKey', paramKey)
        if (data == null) {
            dispatch({ type: Actions.RequestFailure, payload: { loading: false } });
            return;
        }

        // console.log('data', data)
        dispatch({ type: Actions.RequestSuccess, payload: { ...data } });
    },

    runCalculation: (params) => async (dispatch) => {

        // console.log('StartRequest params=', params)
        dispatch({ type: Actions.RequestStart, payload: { loading: true } });
        // dispatch({ type: Actions.RequestSuccess, payload: { ...test, loading: false } }); return //TEST 
        // console.log('params', params)
        var result = await ConventionService.runCalculation(params);
        // console.log('result==================================', result)
        if (result.hasErrors) {
            // console.log('ErrorRequest', result)
            const error = result?.errors?.join('; ')
            dispatch({ type: Actions.RequestFailure, payload: { loading: false, error: error } });
            return;
        }

        // console.log('result.value', result.value)
        const error = result?.value?.error
        dispatch({ type: Actions.RequestSuccess, payload: { ...result?.value, loading: false, error: error } });
    },

    getDataForCalculation: (isin) => async (dispatch) => {

        const loader = "dataForCalculationLoad";
        dispatch({ type: LoaderActions.ShowLoader, payload: loader });
        // dispatch({ type: Actions.RequestStart, payload: { loading: true } });
        // dispatch({ type: Actions.RequestSuccess, payload: { ...test, loading: false } }); return //TEST 

        var result = await ConventionService.getDataForCalculation(isin);
        if (result.hasErrors) {
            console.log('ErrorRequest', result)
            // dispatch({ type: Actions.RequestFailure, payload: { loading: false } });
            dispatch({ type: LoaderActions.HideLoader, payload: loader });
            return;
        }

        // console.log('result.value', result.value)
        const error = result?.value?.Error
        dispatch({ type: Actions.RequestSuccess, payload: { ...result?.value, Error: error } });
        dispatch({ type: LoaderActions.HideLoader, payload: loader });
    },

    findBondsQuery: (value) => async (dispatch) => {

        dispatch({ type: Actions.RequestStart, payload: { bondListLoading: true } });

        var result = await ConventionService.getBondsQuery(value);

        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailure, payload: { bondListLoading: false } });
            return;
        }
        dispatch({ type: Actions.RequestSuccess, payload: { ...result.value, bondListLoading: false } });
    },


    getBonds: (request) => async (dispatch, getState) => {
        dispatch({ type: Actions.RequestStart, payload: { loading: true } })

        var filter = {
            ItemIndex: request.Skip,
            PageSize: request.Take,
            Filters: request.Filters,
            Sorters: request.Sorters,
            Params: null,

        }

        var result = await ConventionService.getBonds(filter)
        // console.log('result', result)
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailure, payload: { loading: false } });
            return;
        }

        const items = result.value.items

        dispatch({ type: Actions.RequestSuccess, payload: { count: result.value.count, items: items, loading: false } });
    },

    getBond: (id) => async (dispatch, getState) => {

        // dispatch({ type: Actions.RequestStart, payload: {loading: true} })

        // var result = await CalculatorService.getBonds(filter)
        // // console.log('result', result)
        // if (result.hasErrors) {
        //     dispatch({ type: Actions.RequestFailure, payload: {loading: false} });
        //     return;
        // }

        dispatch({ type: Actions.RequestData, payload: id, loading: false });
    },

    loadMoexSecurities: (date) => async (dispatch, getState) => {

        // dispatch({ type: Actions.RequestStart });

        var result = await CalculatorService.loadMoexSecurities(Localization.FormatDateStringISO(date));
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailure });
            return;
        }

        dispatch({ type: Actions.RequestSuccess, payload: { moexSecurities: result.value } });
    }

};

const initialState = {
    loading: false,
    items: []
};

export const reducer = (currentState, incomingAction) => {

    if (currentState === undefined)
        return initialState;

    const action = incomingAction;

    switch (action.type) {
        case Actions.Init:
            return initialState;

        case Actions.RequestStart:
            // console.log('start')
            return { ...currentState, ...action.payload };

        case Actions.RequestSuccess:
            // console.log('sucess', { ...currentState, ...action.payload })
            return { ...currentState, ...action.payload };

        case Actions.RequestFailure:
            // console.log('failure')
            return { ...currentState, ...action.payload };

        default:
            return currentState || initialState;
    }
};