import React from 'react'
import { Close } from '@mui/icons-material'
import { Box, Button, IconButton, Modal } from '@mui/material'
import { makeStyles, styled } from '@mui/styles'
import style from '@Styles/domrf-theme.module.scss'
import { t } from 'i18next'
import Localization from '@Base/components/Localization'

const Conv2Button = styled(Button)(({ theme }) => ({
    fontFamily: "DINPro-Bold",
    fontWeight: "700",
    letterSpacing: '1.5px',
    fontSize: '0.8rem !important',
    whiteSpace: 'nowrap',
    '& *': {
        fontSize: '0.8rem !important',
    },
    // color: style.colorPrimary3,
    width: 'min-content',
    padding: '0 25px',
    margin: '3px 0',
    backgroundColor: '#eef6e4',
    borderColor: 'rgb(61,149,109)',
    color: 'rgb(61,149,109)',
    dislay: 'flex',
    flexDirection: 'column',
    '& .comment': {
        fontSize: '0.6rem !important',
        fontFamily: 'DINPro !important',
        lineHeight: '0.8rem !important'
    },
    '&:hover': {
        borderColor: `${style.colorPrimary2} !important`,
        backgroundColor: '#eef6e4 !important',
        color: `rgb(0,176,80) !important`,
    }
}))

const useStyles = makeStyles({
    modal: {
        display: 'flex',
        padding: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    rootModal: {
        position: 'relative',
        width: '500px',
        height: 'max-content',
        outline: 'none',
        '& $rootPaper': {
            backgroundColor: '#FFF',
        }
    },
    root: {
        // marginRight: '0.2rem',
        // marginLeft: '0.2rem',
        // maxHeight: '400px',
        // position: 'relative',
        position: 'absolute',
        left: '0.2rem', top: 0, bottom: 0, right: '0.2rem',
        // maxHeight:'400px',
        '& $panelHeader *': {
            fontSize: '1rem'
        }
    },
    rootPaper: {
        // position: 'absolute',
        // left: 0, top: 0, bottom: 0, right: 0,
        borderRadius: '0.5rem',
        border: `solid 1px ${style.colorPrimary3_40}`,
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        justifyContent: 'flex-start',
        overflow: 'hidden',
    },
    panelHeader: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignContent: 'center',
        alignItems: 'center',
        height: '2rem !important',
        minHeight: '2rem !important',
        overflow: 'hidden',
        borderBottom: `solid 1px ${style.colorPrimary3_40}`,
        padding: '0 0.2rem 0 0',
        justifyContent: 'space-between',
        '& *': { userSelect: 'none', },
    },
    panelHeaderTitle: {
        textTransform: 'uppercase',
        color: style.colorPrimary3_40,
        whiteSpace: 'nowrap',
        marginLeft: '0.5rem',
    },
    panelHeaderButtons: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        // '&>*:not(:first-child)': {
        //     marginLeft: '0.5rem',
        // }
    },
    panelIconsBox: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignContent: 'center',
        alignItems: 'center',
    },
    icon: {
        width: '23px',
        height: '23px',
        // color: style.colorPrimary3_80
    },
    panelData: {
        margin: '0.5rem 0.8rem',
        overflow: 'hidden',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'no-wrap',
        alignItems: 'center',
        '& sup': {
            lineHeight: '0.6rem',
            fontSize: '0.6rem',
        }
    },
    infoWinText: {
        fontSize: '1rem',
        color: style.colorPrimary3_60,
        hyphens: 'auto',
        textAlign: 'justify',
        '& a': {
            color: 'rgb(0,176,80)'
        },
        '& .col': {
            marginTop: '1rem',
            marginBottom: '0.5rem',
            textAlign: 'justify',
            fontSize: '0.9rem',
        }
    }
})

const Convention2InfoWin = ({ show, setShow }) => {
    const cls = useStyles()

    const hideModal = () => { setShow(false) }

    return (
        <Modal
            className={cls.modal}
            open={show}
            onClose={hideModal}
        >
            <Box className={cls.rootModal}>
                <Box className={cls.rootPaper}>
                    <Box className={cls.panelHeader}>
                        <Box className={cls.panelHeaderTitle}>
                            {t('convention2.infoWinTextTitle0')}
                        </Box>
                        {/* <Box className={cls.panelHeaderButtons}>
                            <IconButton size="small" onClick={hideModal}>
                                <Close className={cls.icon} />
                            </IconButton>
                        </Box> */}
                    </Box>
                    <Box className={cls.panelData}>
                        <div className={cls.infoWinText} dangerouslySetInnerHTML={{ __html: t('convention2.infoWinText0') }}></div>
                        <Conv2Button
                            variant="outlined"
                            size="small"
                            onClick={hideModal}
                        >
                            {t('convention2.infoWinContinue')}
                        </Conv2Button>
                    </Box>
                </Box>
            </Box>
        </Modal >
    )
}

export default Convention2InfoWin