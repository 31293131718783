import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { actionCreators as actionTopNavbar } from '@Base/store/TopNavbarStore'
import { Box, Button, Container } from '@mui/material'
import { t } from 'i18next'
import { useDispatch } from 'react-redux'
import Helmet from 'react-helmet'
import { createStyles, makeStyles } from '@mui/styles'
import { useSelector } from 'react-redux'
import { base64ToBytes, bytesToBase64 } from '@Base/Utilities'
import style from '@Styles/domrf-theme.module.scss'

const useStyles = makeStyles((theme) => createStyles({
    rootContainer: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        overflow: 'hidden',
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
        color: style.colorPrimary3
    },
    link: {
        color: style.colorPrimary1,
        borderBottom: `dashed 1px ${style.colorPrimary1}`,
        cursor: 'pointer'
    },
    fileName: {
        marginBottom: '1rem',
        fontSize: '1.2rem',
        color: style.colorPrimary1,
    }
}))

export const Downloader = () => {
    // const { fileLoading, isLoaded } = useSelector(store => store.downloader)
    const [progress, setProgress] = useState(null)
    const [isLoaded, setIsLoaded] = useState(false)
    const [error, setError] = useState(null)
    const [fileName, setFileName] = useState(null)
    // console.log('fileLoading, isLoaded ', fileLoading, isLoaded)
    let [searchParams, setSearchParams] = useSearchParams()
    const dispatch = useDispatch()
    const cls = useStyles()

    useEffect(() => { //установка\сброс title в NavBar
        actionTopNavbar.setTitle(t("downloader.title"))(dispatch)

        return () => {
            actionTopNavbar.setTitle("")(dispatch)
        }
    }, [])

    useLayoutEffect(() => {
        // setTimeout(() => {
            restartDowload()
        // }, 2000)
    }, [searchParams])

    const restartDowload = () => {
        const l = searchParams?.get('l')
        if (l == null) {
            setError(0) // empty link
            return
        }
        const url = base64ToBytes(l)
        // console.log('parametres changed', url)
        setIsLoaded(false)
        readFile(url)
    }

    function downloadBlob(blob, name = 'file.txt') {
        // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
        const blobUrl = URL.createObjectURL(blob);

        // Create a link element
        const link = document.createElement("a");

        // Set link's href to point to the Blob URL
        link.href = blobUrl;
        link.download = name;

        // Append link to the body
        document.body.appendChild(link);

        // Dispatch click event on the link
        // This is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(
            new MouseEvent('click', {
                bubbles: true,
                cancelable: true,
                view: window
            })
        );

        // Remove link from body
        document.body.removeChild(link);
        setIsLoaded(true)
        setProgress(null)
    }

    // Usage
    const getFileName = (disposition) => {
        const utf8FilenameRegex = /filename\*=UTF-8''([\w%\-\.]+)(?:; ?|$)/i
        const asciiFilenameRegex = /^filename=(["']?)(.*?[^\\])\1(?:; ?|$)/i

        let fileName = null;
        if (utf8FilenameRegex.test(disposition)) {
            fileName = decodeURIComponent(utf8FilenameRegex.exec(disposition)[1])
        } else {
            // prevent ReDos attacks by anchoring the ascii regex to string start and
            //  slicing off everything before 'filename='
            const filenameStart = disposition.toLowerCase().indexOf('filename=')

            if (filenameStart >= 0) {
                const partialDisposition = disposition.slice(filenameStart)
                const matches = asciiFilenameRegex.exec(partialDisposition)
                if (matches != null && matches[2]) {
                    fileName = matches[2]
                }
            }

        }
        return fileName
    }

    const readFile = async (url) => {
        if (url == null) {
            setError(0) // empty link
            return
        }

        try {

            let response = await fetch(url)
            const header = response.headers.get('Content-Disposition')

            if (!header) {
                setError(0) // file not found
                return
            }

            const filename = getFileName(header)
            setFileName(filename)

            //  get total length
            const contentLength = +response.headers.get('Content-Length');

            //  read the data
            const reader = response.body.getReader()
            let receivedLength = 0; // received that many bytes at the moment
            let chunks = []; // array of received binary chunks (comprises the body)
            while (true) {
                const { done, value } = await reader.read()

                if (done) {
                    break;
                }

                chunks.push(value);
                receivedLength += value.length;

                // console.log(`Received ${receivedLength} of ${contentLength} = ${receivedLength / contentLength * 100}%`)
                setProgress(Math.round(receivedLength / contentLength * 100))
                //https://developer.mozilla.org/en-US/docs/Web/API/Streams_API/Using_readable_streams
            }

            let theBlob = new Blob(chunks)
            // console.log('fileName', fileName)
            downloadBlob(theBlob, filename);
        }
        catch (error) {
            console.log('error', error)
        }
    }

    // console.log('error', error)
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{t('downloader.title')}</title>
            </Helmet>
            <Container
                maxWidth="xxl"
                className={cls.rootContainer}
            >
                {
                    error != null ?
                        <>
                            {t(`convention2.error${error}`)}
                        </>
                        :
                        isLoaded ? <>
                            {/* {console.log('isLoaded', isLoaded)} */}

                            <Box className={cls.fileName}>{`"${fileName}"`}</Box>
                            <Box>{'Загрузка завершена'}</Box>
                        </> :
                            progress != null ?
                                <>
                                    {/* {console.log('progress', progress)} */}
                                    <Box className={cls.fileName}>{`"${fileName}"`}</Box>
                                    <Box>{`Загружено: ${progress}%`}</Box>
                                </>
                                :
                                <>
                                    <Box>{'Загрузка начнется через несколько секунд...'}</Box>
                                    <Box>{'Если загрузка не началась нажмите '}<span className={cls.link} onClick={restartDowload}>{'сюда'}</span></Box>
                                </>
                }
                <Box sx={{ mt: 1 }}>
                    <Button size='small' variant='outlined' color='secondary' onClick={() => window.close()}>Закрыть</Button>
                </Box>
            </Container>
        </>
    )
}
