/* eslint-disable */
import React, { useState, useEffect } from 'react';

/* eslint-enable */
import TooltipInfoLabel from '../../../components/shared/TooltipInfoLabel';
// import ErrorTooltip from '../../../components/shared/ErrorTooltip';
// import { isValid } from 'date-fns';
import { makeStyles } from '@mui/styles';
import TimePicker from './TimePicker';
// import { ConsoleLogger } from '@microsoft/signalr/dist/esm/Utils';

const useStyles = makeStyles({
    tooltip: {
        display: 'flex',
        width: '100%'
    }
});

const TimePickerValidator = ({
    name,
    errorMessages,
    validators,
    requiredError,
    helperText,
    tooltip,
    inputClassName,
    //disabled,
    value,
    onChange,
    cancelLabel,
    okLabel,
    tReady,
    fullWidth,
    extraButtonsProps,
    inputProps,
    InputProps,
    format,
    mask,
    disabled,

    ...rest
}) => {

    // const { isValid, errorMessage } = useFormValidation(disabled, name, value, errorMessages, validators);
    const classes = useStyles();
    // const { t, i18n } = useTranslation();

    // const [isOpen, setIsOpen] = useState(false);
    const [timeInputValue, setTimeInputValue] = useState(value);
    const [localValue, setLocalValue] = useState(value);

    useEffect(() => {
        setTimeInputValue(value);
        setLocalValue(value);
    }, [value]);

    // const showDatePicker = (evt) => {
    //     setIsOpen(true);
    // }

    // const handleDateChange = (newValue) => {
    //     // console.log('handleDateChange',value,newValue)
    //     setLocalValue(newValue);
    // }

    // const handleInputChange = (event) => {
    //     setTimeInputValue(event.target.value);
    // }

    const handleDateAccept = (newValue) => {
        if (onChange != null) {
            onChange(newValue);
        }
    }

    return (
        <TooltipInfoLabel className={classes.tooltip} content={tooltip}>
            <TimePicker
                name={name}
                value={localValue}
                onChange={handleDateAccept}
                minutesstep={10}
                hoursfrom={10}
                hoursto={19}
                digheight={3}
                neighborcnt={2}
                selectedcolor={'#8bc54094'}
                inputProps={inputProps}
                InputProps={InputProps}
                disabled={disabled}

                {...rest} />
            {/* <MobileTimePicker
                disabled={disabled}
                name={name}
                ampm={false}
                value={localValue}
                onChange={handleDateChange}
                onAccept={handleDateAccept}
                cancelText={cancelLabel || t('Cancel')}
                okText={okLabel || t('OK')}
                //variant="inline"
                //margin="none"
                closeOnSelect={false}
                // showToolbar={true}
                open={isOpen}
                onOpen={() => setIsOpen(true)}
                onClose={() => setIsOpen(false)}
                //mask={mask}
                //inputFormat={format}
                renderInput={(params) =>
                    <TextField
                        {...params}
                        className={inputClassName}
                        value={timeInputValue}
                        onChange={handleInputChange}
                        fullWidth={fullWidth}
                        variant="standard"
                        error={!isValid}
                        helperText={helperText}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">

                                    {InputProps?.endAdornment}

                                    {extraButtonsProps}

                                    <IconButton disabled={params.disabled} onClick={showDatePicker} size="large">
                                        <AccessTimeIcon />
                                    </IconButton>

                                    {
                                        !isValid && (
                                            <InputAdornment position="end">
                                                <ErrorTooltip content={errorMessage} />
                                            </InputAdornment>
                                        )
                                    }

                                </InputAdornment>
                            )
                        }}
                    />}
                {...rest} /> */}
        </TooltipInfoLabel>
    );
}

export default TimePickerValidator;
