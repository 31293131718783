import { Box, alpha } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { HighchartsReact } from 'highcharts-react-official'
import Highcharts from 'highcharts'
import { t } from 'i18next'
import React, { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { format } from 'date-fns'
import SpinnerBars from '@Base/components/shared/SpinnerBars'
import Localization from '@Base/components/Localization'
import style from '@Styles/domrf-theme.module.scss'

const useStyles = makeStyles({
  root: {
    position: 'absolute',
    left: '0.5rem', top: 0, right: 0, bottom: 0,
  }
})

const CompareCharts = () => {
  const cls = useStyles()
  const env = useRef()
  const {
    calculations: calculationData,
    currentDateRange,
    selectedFields,
    selectedBonds,
    isCalculationLoading,
    ...otherStore
  } = useSelector((state) => state.domrf)

  // console.log('calculationData', calculationData)
  const colors = useRef([null, null, null, null, null, null, null])
  const { items: bonds } = useSelector((state) => state.bonds)

  const xAxisxFormatter = (date) => {
    // console.log('currentDateRange', currentDateRange)
    switch (currentDateRange) {
      case 7:
        return format(new Date(date).getTime(), "dd.MM.yy")
        break

      case 30:
        if (new Date(date).getDay() == 1)
          return format(new Date(date).getTime(), "dd.MM.yy")
        break

      case 90:
        if (new Date(date).getDay() == 1)
          return format(new Date(date).getTime(), "dd.MM.yy")
        break

      case 365:
        // console.log('new Date(date).getDate()', new Date(date).getDate())
        if (new Date(date).getDate() == 1)
          return format(new Date(date).getTime(), "dd.MM.yy")
        break

      case 1825:
        if (new Date(date).getMonth() == 0 && new Date(date).getDate() == 1) {
          return format(new Date(date).getTime(), "dd.MM.yy")
        }

      // default:
      //     return format(new Date(date).getTime(), "dd.MM.yy")
    }

    return ''
  }

  const [chart, setChart] = useState(
    {
      chart: {
        type: 'line',
        animation: false,
        render: (e) => {
          if (e.target?.callback) {
            e.target?.callback(e.target)
          }
        },
      },
      plotOptions: {
        line: {
          pointPlacement: 'on',
          stacking: null,
          tickPosition: 'on',
          marker: {
            symbol: 'circle',
            radius: 2,
            lineWidth: 0,
            // lineColor:style.colorPrimary1,
            halo: false,
          },
          states: {
            hover: {
              // enabled:false,
              lineWidthPlus: 0,
              halo: {
                size: 0
              }
            },
            inactive: {
              enabled: false,
            },
            active: {
              enabled: false,
            },
          },
        }
      },
      title: {
        text: ''
      },
      tooltip: {
        shared: true,
        useHTML: true,
        formatter: function () {
          // console.log('this', this)
          return `<div><b>${Localization.FormatDateLocale(this?.x)}</b></div>` +
            this.points?.map(point => `<div style="display:flex;flex-direction:row;flex-wrap:nowrap;justify-content:space-between">
          <div><span style="color:${point?.series?.color}">■</span>&nbsp;${point?.series?.name}</div>
          <div>&nbsp;<strong>${Localization.FormatNumberString(point.y, 2, 2)}</strong></div>
          </div>`).join('')
        }
      },
      xAxis: {
        visible: true,
        crosshair: true,
        crosshair: {
          color: alpha(style.colorPrimary2, 0.5), // Color of the crosshair line
          width: 1, // Width of the crosshair line
          // dashStyle: 'dash' // Style of the crosshair line (e.g., 'solid', 'dash', 'dot', etc.)
        },
        tickInterval: 10000,//disable fantom tick at zero position
        type: 'datetime',
        // categories: [],
        gridLineWidth: 0,
        gridZIndex: 1,
        lineWidth: 1,
        lineColor: style.colorPrimary3_60,
        tickColor: style.colorPrimary3_60,
        // tickPosition: 'inside',
        tickWidth: 1,
        tickLength: 5,
        startOnTick: false,
        tickPositions: [],// env.current?.categories?.filter(date=> env.current?.xAxisxFormatter(date) != '').map((i,index)=>index),
        // tickPositioner: function () {
        //   // console.log('val', val)
        //   // return env.current?.xAxisxFormatter(env.current?.categories[val.pos])
        //   // console.log('this', this)
        //   var positions = [], i


        //   for (i = 0; i < env.current?.categories?.length; i++) {
        //     if (env.current?.xAxisxFormatter(env.current?.categories[i]) != '') {
        //       console.log('env.current?.xAxisxFormatter(i)', env.current?.xAxisxFormatter(env.current?.categories[i]))
        //       positions.push(i)
        //       // console.log('i', i)
        //     }
        //     else {
        //       positions.push(null)
        //     }
        //   }

        //   return positions;
        // },
        labels: {
          enabled: true,
          rotation: 0,
          style: {
            whiteSpace: 'nowrap',
            textOverflow: 'none'
          },
          allowOverlap: true,
          // staggerLines: 1,
          overflow: 'justify',
          formatter: function (val) {
            // console.log('val', val)
            return env.current?.xAxisxFormatter(val.pos)
          }
        }
      },
      yAxis: [
        {
          visible: true,
          // min: 0, max: 100,
          // gridLineWidth: 0,
          gridZIndex: 1,
          lineWidth: 1,
          lineColor: style.colorPrimary3_60,
          title: {
            text: ''
          }
        },
        {
          opposite: true,
          visible: true,
          // gridLineWidth: 0,
          gridZIndex: 1,
          lineWidth: 1,
          lineColor: style.colorPrimary3_60,
          // min: 100, max: 1000,
          title: {
            text: ''
          }
        },
      ],
      series: []
    }
  )

  const getFieldIndex = (bond, field) => {
    const ind = selectedFields?.findIndex(f => f == field)
    if (selectedFields?.length > 1)
      return Math.round((ind) * 70 / (selectedFields?.length - 1))
    else
      return 0

    // const keysArr = Object.keys(bond).filter(key => selectedFields?.some(field => field == key))
    // const i = keysArr.findIndex(key => key == field)
    // const length = keysArr?.length
    // return Math.round((i + 1) * 40 / length)
  }

  // const origsIdArr = bonds.map(bond => bond?.originator?.id)
  // const minOrigId = Math.min.apply(null, origsIdArr)
  // const maxOrigId = Math.max.apply(null, origsIdArr)
  // const origCoeff = 360 / (maxOrigId - minOrigId)

  const origCoeff = 360 / 7
  // console.log('selectedBonds', selectedBonds, colors?.current)

  const getBondColor = (originatorId, bondId) => {
    const bondsIdArr = bonds.filter(bond => bond.originator.id == originatorId).map(bond => bond?.id)
    const bondIndex = bondsIdArr?.findIndex(id => id == bondId)
    const bondCoeff = 360 / origCoeff / bondsIdArr.length


    let origIndex = selectedBonds?.findIndex(id => id == bondId)

    return Math.round(origCoeff * origIndex + bondCoeff * bondIndex)

    // const bondsIdArr = bonds.filter(bond => bond.originator.id == originatorId).map(bond => bond?.id)
    // const bondIndex = bondsIdArr?.findIndex(id => id == bondId)
    // const bondCoeff = 360 / origCoeff / bondsIdArr.length

    // return Math.round(origCoeff * originatorId + bondCoeff * bondIndex)
  }

  useEffect(() => {
    // if (!calculationData?.length){
    //   console.log('no data')
    //   return
    // }


    let series = []
    let categories = []

    for (let i = 0; i <= currentDateRange; i++) {
      categories.unshift(new Date(Date.now()).setHours(0, 0, 0) - i * 24 * 60 * 60 * 1000)
    }
    // console.log('categories', categories)
    env.current = { categories: categories /*bond.zcycDate*/, xAxisxFormatter: xAxisxFormatter }
    //  console.log('calculationData', calculationData)
    calculationData
      ?.filter(bond => selectedBonds?.findIndex(el => el == bond.bondID) != -1)
      ?.forEach(bond => {

        const timeArr = bond.zcycDate.map(date => new Date(date).getTime())
        const minDate = Math.min.apply(null, timeArr)
        const maxDate = Math.max.apply(null, timeArr)
        const data = {}
        for (let i = minDate; i <= maxDate; i = i + 24 * 60 * 60 * 1000) {
          data[i] = null
        }

        selectedFields?.forEach((field, index) => {
          bond.zcycDate.forEach((date, index) => data[new Date(date).getTime()] = bond[field][index])

          // const color = makeColor(bond?.originatorID, bond.bondID, getFieldIndex(bond, field))
          // s : 80-50 %
          // const color = `hsl(${getBondColor(bond?.originatorID, bond?.bondID)},${getFieldIndex(bond, field)+50}%,${getFieldIndex(bond, field)+30}%)`
          const color = `hwb(${getBondColor(bond?.originatorID, bond?.bondID)}deg ${getFieldIndex(bond, field)}% 10%)`
          // console.log(color, bond.bondName)
          series.push(
            {
              id: `${bond.bondID}_${field}`,
              color: color,
              yAxis: bond[field]?.some(x => x > 60) ? 1 : 0,
              name: `${t(`quotes.${field}`)} ${bond.bondName}`,
              // data: [...bond.zcycDate.map((date, index) => [new Date(date).getTime(), bond[field][index]])], //fill nulls
              data: [...Object.keys(data).map((key) => [parseInt(key), data[key]])], //show nulls
              connectNulls: false,
              marker: { enabled: false }
            }
          )

        })
      })
    const tickPositions = categories?.map(date => (env.current?.xAxisxFormatter(date) != '') ? date : null)
    // console.log('tickPositions', tickPositions)
    // console.log('series', series)
    setChart({
      ...chart,
      xAxis: {
        ...chart.xAxis, categories: categories, tickPositions: tickPositions,
        max: new Date(Date.now()).setHours(0, 0, 0) - 24 * 60 * 60 * 1000,
        min: new Date(Date.now()).setHours(0, 0, 0) - (currentDateRange + 1) * 24 * 60 * 60 * 1000 - 1000 // -1000 for enable first crosshair hover
      },
      series: series
    })
  }, [calculationData, selectedFields, selectedBonds])

  // console.log('chart', chart)
  // console.log('calculationData', calculationData)
  // console.log('otherStore', otherStore)
  return (
    <Box className={cls.root}>
      {isCalculationLoading && <SpinnerBars withBackground />}
      <HighchartsReact
        // ref={flowChartRef}
        highcharts={Highcharts}
        containerProps={{ style: { height: "100%", width: "100%" } }}
        options={chart}
        callback={(chart) => {
          // if (chart?.legend) {
          //   Highcharts.objectEach(chart.legend.allItems, function (el) {
          //     //  console.log('el', el)
          //     //  if (el.legendSymbol ) //&& el.name == t("HistoricalAmortization"))
          //     //     el.legendSymbol.element.style.stroke = style.colorPrimary3_40;
          //     if (el?.legendItem?.symbol && el.name == t("Convention2_NameHistoricalAmortization"))
          //       el.legendItem.symbol.element.style.stroke = style.colorPrimary3_40;
          //   })
          // }
          chart.reflow()
        }
        }
      />
    </Box>
  )
}

export default CompareCharts