/* eslint-disable */
import React, { useState, useEffect, useContext, useRef, forwardRef, useImperativeHandle, useMemo } from 'react';
import PropTypes from 'prop-types';
import Promise from 'promise-polyfill';
/* eslint-enable */
import { polyfill } from 'react-lifecycles-compat';
import { FormContext } from './ValidatorForm';
import { debounce } from 'throttle-debounce';

type Context = {
    attachToForm: (component: any) => void;
    detachFromForm: (component: any) => void;
    instantValidate: (component: any) => void;
    debounceTime: number;
}

const useFormValidation = (
    disabled,
    name,
    value,
    errorMessages,
    validators
) => {

    const [updateCounter, setUpdateCounter] = useState(0);

    const context: Context = useContext(FormContext);

    const localData = useRef({
        instantValidate: true,
        invalid: [],
        debounceTime: null,
        isValid: true,
        isGlobalValid: true,
        localValidators: validators,
        localErrorMessages: errorMessages,
        globalErrorMessages: null,
        value: value
    });

    const handle = useRef(null);

    useEffect(() => {

        if (context?.form) {

            //console.log("=============attaching " + name);
            context.form.attachToForm(handle);
            localData.current.instantValidate = context.form.instantValidate;
            localData.current.debounceTime = context.form.debounceTime;
        }

        return () => {
            detachFromForm();
        };

    }, []);

    useEffect(() => {

        /*        
        var bIsUpdateNotRequired = checkUpdateState(undefined, validators, errorMessages);
        */
        localData.current.localValidators = validators;
        localData.current.localErrorMessages = errorMessages;

        /*
        //do not validate if no value set
        if (!bIsUpdateNotRequired && localData.current.instantValidate) {
            validateDebounced(false);
        }
        */

    }, [errorMessages, validators]);

    useEffect(() => {

        // console.log(`---${name}----value changed to ${value}`);

        if (localData.current.value == value)
            return;

        localData.current.value = value;

        if (localData.current.instantValidate) {
            validateDebounced(false);
        }

    }, [value]);

    const detachFromForm = () => {

        validateDebounced.cancel();

        //console.log("=============detaching " + name);
        if (context?.form) {

            //console.log("=============form exists");
            context.form.detachFromForm(handle);
        }

        localData.current = {
            instantValidate: true,
            invalid: [],
            debounceTime: null,
            isValid: true,
            isGlobalValid: true,
            localValidators: undefined,
            localErrorMessages: undefined,
            globalErrorMessages: null,
            value: undefined
        };
    }

    const getErrorMessage = () => {

        if (!localData.current.isValid) {
            const type = typeof localData.current.localErrorMessages;

            if (type === 'string') {
                return localData.current.localErrorMessages;
            } else if (type === 'object') {
                // console.log(localData.current)
                if (localData.current.invalid.length > 0) {
                    return localData.current.localErrorMessages[localData.current.invalid[0]];
                }
            }
            else {
                // eslint-disable-next-line
                console.log('unknown errorMessages type=' + type + '; ', errorMessages);
            }
        } else if (!localData.current.isGlobalValid) {
            return localData.current.globalErrorMessages;
        }

        return null;
    }

    const validate = (dryRun = false) => {

        if (context?.form == null)
            return Promise.resolve({ name: '', isValid: true });

        if (localData.current == null)
            return Promise.resolve({ name: '', isValid: false });

        if (disabled || localData.current.localValidators == null || localData.current.localValidators.length == 0) {

            if (!dryRun) {

                var stateChanged = localData.current.isValid !== true;

                //console.log('===ValidStateUpdate name: ' + name + ', value: ' + localData.current.value + ' , isValid: ' + localData.current.isValid + ' valid: true ' + ', error message: ' + getErrorMessage());

                localData.current.isValid = true;

                if (localData.current.instantValidate && context?.form !=null) {

                    context.form.onChildStateChanged(name, localData.current.value, true, stateChanged);
                    if (stateChanged)
                        setUpdateCounter(prev => prev + 1);
                }


                //console.log('--1--localIsValid: ' + true + ' is valid: ' + true);

            }

            return Promise.resolve({ name: name, isValid: localData.current.isValid && localData.current.isGlobalValid, isLocalValid: localData.current.isValid, isGlobalValid: localData.current.isGlobalValid });
        }

        const validations = Promise.all(localData.current.localValidators?.map(validator => context.form.getValidator(validator, localData.current.value)));

        let valid = true;

        return validations.then((results) => {
            localData.current.invalid = [];
            results.forEach((result, key) => {
                if (!result) {
                    valid = false;
                    localData.current.invalid.push(key);
                }
            });

            if (!dryRun) {

                //console.log('--2--localIsValid: ' + localData.current.isValid + ' is valid: ' + valid);

                var stateChanged = localData.current.isValid !== valid || (valid && !localData.current.isGlobalValid);

                //if (localData.current.isValid !== true || valid !== true) {

                //console.log('===ValidStateUpdate name: ' + name + ', value: ' + localData.current.value + ' , isValid: ' + localData.current.isValid + ' valid: ' + valid + 'stateChanged: ' + stateChanged + ', error message: ' + getErrorMessage());

                localData.current.isValid = valid;

                if (localData.current.instantValidate) {

                    context.form.onChildStateChanged(handle, name, localData.current.value, valid, stateChanged);
                    if (stateChanged)
                        setUpdateCounter(prev => prev + 1);
                }
                //}
            }

            //console.log('validate field: ' + name + ' is valid: ' + valid);

            return { name: name, isValid: localData.current.isValid && localData.current.isGlobalValid, isLocalValid: localData.current.isValid, isGlobalValid: localData.current.isGlobalValid };
        });
    }

    //debounce here is just a setTimeout using debounce with memo causes problems with a stale closures at dependent functions, like a onFieldChanged()
    const validateDebounced = /*useMemo(() => */debounce(localData.current.debounceTime, validate);/*, [value, validators]);*/

    const getState = () => {
        return { name: name, value: localData.current.value, isValid: localData.current.isValid };
    }

    const setState = (valid, message) => {

        var needToUpdate = false;

        if (valid == localData.current.isGlobalValid) {
            if (localData.current.globalErrorMessages == message)
                return;
            else
                needToUpdate = true;
        }

        localData.current.isGlobalValid = valid;

        if (valid)
            localData.current.globalErrorMessages = null;
        else
            localData.current.globalErrorMessages = message;

        if (localData.current.isValid || needToUpdate)
            setUpdateCounter(prev => prev + 1);
    }

    useImperativeHandle(handle, () => ({
        validate: (dryRun) => validate(dryRun),
        cancelValidation: () => validateDebounced.cancel(),
        getState: () => getState(),
        setState: (isValid, message) => setState(isValid, message), //sets global state at field
        onFormUnmounted: () => detachFromForm()
    }));

    return { isValid: localData.current.isValid && localData.current.isGlobalValid, errorMessage: getErrorMessage() };
}

export default useFormValidation;