import GridRequest from '../framework/components/Table/Common/Models/GridRequest';
import RequestFilter from '../models/RequestFilter';
import SortRule from '../models/SortRule';
import FilterRule from '../models/FilterRule';
import Filter from '../models/Filter';
import GridFilterState from '../framework/components/Table/Common/Models/GridFilterState';
import { ColumnSortDirection, CompareOperators } from '../framework/components/Table/Common/Models/Column';
import ColumnState from '../framework/components/Table/Common/Models/ColumnState';

export default class SiteHelper {

    static CreateFilter = (request: GridRequest): RequestFilter => {

        var filter: RequestFilter = {
            ItemIndex: request.Skip,
            PageSize: request.Take,
            Filters: null,
            Params: null,
            Sorters: request.Columns.filter(x => x.Sortable).map(x => {

                if (x.SortOrder === -1)
                    return null;

                var rule: SortRule = {
                    Name: x.Name,
                    SortOrder: -1,
                    IsAscending: null
                };

                rule.SortOrder = x.SortOrder;

                switch (x.SortDirection.toLowerCase()) {
                    case "ascending":
                        {
                            rule.IsAscending = true;
                        }
                        break;
                    case "descending":
                        {
                            rule.IsAscending = false;
                        }
                        break;
                }

                return rule;
            }).filter(x => x != null)
        };

        //just filter it
        if (request.Search != null && request.Search.Text.length != 0) {

            var rules = request.Columns.filter(x => x.Searchable).map(x => {
                return {
                    Name: x.Name,
                    Type: "contains",
                    FirstValue: request.Search.Text.toLowerCase(),
                    DataType: "string"
                };
            });

            if (rules.length != 0) {
                filter.Filters = [
                    {
                        Operator: "or",
                        Values: rules
                    }
                ];
            }
        }
        else { //apply column filters

            var columnFilters = request.Columns.filter(x => x.Filterable).map(x => {
                if (x.Filter == null || x.Filter.HasFilter != true)
                    return null;

                var rule: Filter = {
                    Name: x.Name,
                    Type: null,
                    FirstValue: null,
                    DataType: null
                };

                rule.Type = x.Filter.Operator;
                rule.FirstValue = x.Filter.Value;
                rule.DataType = x.DataType;

                /*
                if (typeof x.Filter.Argument[0] != 'undefined') {
                    rule.SecondValue = x.Filter.Argument[1];
                }
                */

                return rule;
            }).filter(x => x != null)

            if (columnFilters.length != 0)
                filter.Filters = [{
                    Operator: "and",
                    Values: columnFilters
                } as FilterRule];
        }

        return filter;
    };

    static CreateFilterState = (request: GridRequest): GridFilterState =>
    {
        var fState = new GridFilterState();

        fState.RowIndex = request.Skip;
        fState.PageSize = request.Take;
        fState.SearchText = request.Search.Text;
        fState.ColumnState = request.Columns.filter(x => x.Visible && ((x.Sortable && x.SortDirection != ColumnSortDirection.NONE) || (x.Filterable && x.hasFilter))).map((item) => {

            var column = new ColumnState();

            column.Id = item.Name;

            if (item.Sortable) {
                column.SortDirection = item.SortDirection;
                column.SortOrder = item.SortOrder;
            }

            if (item.hasFilter) {
                column.Filter = item.Filter;
            }

            return column;
        });

        return fState;
    }

    static CreateFilterNew = (searchValue: string, skipPage: number, pageSize: number, searchFields: any[], sortColumns: any[]): RequestFilter => {

        var filter: RequestFilter = {
            ItemIndex: skipPage * pageSize,
            PageSize: pageSize,
            Filters: null,
            Params: null,
            Sorters: sortColumns.map(x => {

                var rule: SortRule = {
                    Name: x.columnName,
                    SortOrder: -1,
                    IsAscending: x.direction === 'asc' ? true : (x.direction === 'desc' ? false : null)
                };

                return rule;
            })
        };

        //just filter it
        if (searchValue != null && searchValue.length != 0 && searchFields.length != 0) {

            var rules = searchFields.map(x => {
                return {
                    Name: x.name,
                    Type: "contains",
                    FirstValue: searchValue.toLowerCase(),
                    DataType: null
                };
            });

            if (rules.length != 0) {
                filter.Filters = [
                    {
                        Operator: "or",
                        Values: rules
                    }
                ];
            }
        }
        else { //apply column filters
            ///todo
        }

        return filter;
    };
}