import * as React from 'react';

import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { ColumnModel } from '../Common';

const dropdown = {
    marginLeft: '10%',
    width: '90%',
};

const getValue = (op: string) => !op || op === '' ? 'None' : op;

export const OperatorsDropdown: React.FunctionComponent<any> = ({ activeColumn, handleFilterChange, locale }) => {
    const onChange = ({ target }: any) => handleFilterChange({ Operator: target.value });

    return (
        <TextField
            style={dropdown}
            select={true}
            value={getValue(activeColumn.Filter.Operator)}
            onChange={onChange}
            label={locale.Operator}
        >
            {ColumnModel
                .getOperators(activeColumn)
                .map((row: any) => (
                    <MenuItem key={row.Value} value={row.Value}>
                        {locale[row.Title] != null ? locale[row.Title] : row.Title}
                    </MenuItem>
                ))}
        </TextField>
    );
};
