import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles, useTheme } from '@mui/styles';
import { useTranslation } from "react-i18next";
import Localization from '@Components/Localization';
import { Box, Button, Divider, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import CustomTooltip from '../../../../components/shared/CustomTooltip';
import style from '@Styles/domrf-theme.module.scss';
import SpinnerBars from '../../../../components/shared/SpinnerBars';
import { alpha, Stack } from '@mui/system';
import { UnfoldLess, UnfoldMore, WarningAmber, SaveOutlined } from '@mui/icons-material';
import { IterationStatus } from  '../../../../components/Definitions';
import useLocalStorage from '../../../../components/hook/useLocalStorage';
import { ReactComponent as ExcelIcon } from "@Images/excel-icon.svg"
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme: Theme) => createStyles({
    dataAlertBlock: {
        position: 'absolute', left: 0, top: 0, right: 0, bottom: 0,
        justifyContent: 'center',
        '& *': {
            color: '#ff6e6e'
        }
    },
    icon: {
        width: '40px',
        height: '40px',
    },
    containerBox: {
        position: 'absolute', left: 0, right: 0, top: 0, bottom: 0,
        [theme.breakpoints.down('xxl')]: {
            position: 'relative',
            maxWidth: '472px',
            margin: '0 auto',
        },
        padding: '0 5px', margin: 0,
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        // width: '452px !important',
        // '&::before': {
        //     content: '""',
        //     position: 'absolute',
        //     left: 0, top: '3px', bottom: '3px',
        //     width: '1px',
        //     backgroundColor: `${alpha(style.colorPrimary3, 0.2)}`
        // }
    },
    disabled: {
        color: `rgb(220,220,220) !important`,
    },
    disabledButton: {
        backgroundColor: `${alpha(style.colorPrimary3_120, 0.1)} !important`,
        boxShadow: `0px 0px 0px 1px ${style.colorPrimary3_120} !important`,
        color: `${style.colorPrimary3_120} !important`,
        fontWeight: '200 !important',
        fontFamily: 'DINPro',
    },
    headerButtons: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        // marginRight: '0.5rem',
        textAlign: 'right',
        width: '65px',
        alignSelf: 'center',
    },
    fixed: {
        position: 'absolute',
        top: 0, left: 0, right: 0, bottom: 0,
    },
    GlobalTitle: {
        color: style.colorPrimary3_120,
        lineHeight: '1rem',
        textTransform: 'uppercase',
        fontSize: '1rem',
        alignSelf: 'end',
        padding: '6px 0 4px 0',
    },
    headerBlock: {
        // paddingLeft: '0.5rem',
        height: '30px !important',// minHeight: '30px', maxHeight: '30px',
        maxHeight: '2rem',
        display: 'flex',
        alignItems: 'baseline',
        justifyContent: 'space-between',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        overflow: 'hidden',
        boxShadow: `0px 1px ${alpha(style.colorPrimary3, 0.7)}`,
    },
    GlobalError: {
        backgroundColor: alpha(theme.palette.error.light, 0.7),
        '& *': {
            color: '#FFF !important',
        }
    },
    uncollapsed: {
        display: 'flex !important'
    },
    Block: {
        '&>.global>$rowName': {
            fontWeight: 'bolder',
            backgroundColor: `${alpha(style.colorPrimary2, 0.1)}`,
            textTransform: 'uppercase',
            // marginLeft: '0.2rem',
            marginTop: '0.5rem',
            fontSize: '0.9rem',
            // paddingLeft: '1rem',
        },

        '&>.main': {
            '&>$rowBlock': {
                display: 'none',
            },
            '&>$rowName': {
                backgroundColor: `${alpha(style.colorPrimary3, 0.07)}`,
                // marginLeft: '0.6rem',
                marginRight: 0,
                textTransform: 'initial',
                marginTop: '0.5rem',
                fontSize: '1rem',
            },
        },
        '&>.sub': {
            '&>$rowBlock': {
                display: 'none',
            },
            '&>.collapsed': {
                boxShadow: `none !important`,
                textTransform: 'initial !important',
                padding: '0 0.6rem !important',
            },
            '&>$rowName': {
                // marginLeft:'3rem',
                boxShadow: `inset 0px 0px 0 1px ${alpha(style.colorPrimary3, 0.3)}`,
                borderSpacing: '-1px',
                // marginLeft: '0.6rem',
                marginRight: 0,
                textTransform: 'uppercase',
                marginTop: '0.5rem',
                fontSize: '0.9rem',
                padding: '0.3rem 0.6rem',
            },
        },
        '&>.sub1': {
            borderLeft: `1px solid ${alpha(style.colorPrimary3, 0.3)}`,
            marginLeft: '3rem',
            '&>$rowName': {
                color: `${alpha(style.colorPrimary3, 0.6)}`,
                fontSize: '0.9rem',
                // marginLeft: '0.6rem',
                marginRight: 0,
                // marginBottom: '0.3rem',
                padding: '0.3rem 0.6rem',
            },
        },
    },
    rowName: {
        display: 'flex',
        flexShrink: 1,
        padding: '0.4rem 0.6rem',

    },
    rowBlock: {
        display: 'flex',
        flexDirection: 'column',
    },
    rowNameBlock: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'baseline',
    },

    rowValuesBlock: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        flexGrow: 1,
        // marginRight: '0.5rem',
        alignItems: 'baseline',
    },
    rowValue: {
        // marginLeft: '0.5rem',
        minWidth: '55px',
        textAlign: 'right',
    },
    rowUnit: {
        textAlign: 'left',
        marginLeft: '0.5rem',
        width: '37px !important',
        fontSize: '0.8rem',
        color: `${alpha(style.colorPrimary3, 0.5)}`,
        textTransform: 'initial',
    },
    columnUnit: {},
    columnBlock: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        flexGrow: 1,
        marginRight: '1.2rem',
        alignItems: 'center',
        marginRight: 0,
        alignSelf: 'end',
        '& $columnUnit': {
            textAlign: 'right',
            marginLeft: '0.5rem',
            width: '65px',
            fontSize: '1rem',
            color: style.colorPrimary3_120,
            textTransform: 'uppercase',
            // display: 'flex',
            // flexDirection: 'row',
            // flexWrap: 'nowrap',
            whiteSpace: 'nowrap'
        },
    },
    scrollablePanel: {
        position: 'absolute', left: 0, right: 0, top: 0, bottom: 0,
        [theme.breakpoints.down('xxl')]: {
            position: 'relative',
        },
        '&>*': {
            position: 'relative'
        },
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: '4px',
        },
        '&:hover': {
            '&::-webkit-scrollbar-track': {
                backgroundColor: '#00000008',
            },
            '&::-webkit-scrollbar-thumb': {
                background: '#00000018',
            }
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'unset',
            borderRadius: '3px',
            // border: 'solid 1px #00000000',
            backgroundColor: '#00000000',
            transition: 'all 0.3s ease'
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#0000',
            borderRadius: '3px',
            height: '4px'
        },
    },
}))


const PricingResultsPanel = ({ needRecalc, newPoolReceived, saveFunc, isEdit }) => {

    const classes = useStyles();
    const { t } = useTranslation();
    const theme: Theme = useTheme()
    const [saveState, setSaveState] = useState(false)
    const [collapse, setCollapse] = useLocalStorage(false, 'Securitization/IterationResultCollapse')
    const [isCalculating, setIsCalculating] = useState(false)
    const [disabledColumnId, setDisabledColumnId] = useState(null);
    const { deal = null, backupDeal = null,
        selectedNode = null, backupNode = null,
        permissions,
        ...otherStore } = useSelector((state: any) => state.securitization)
    const { validationState: nodeValidationState = null } = (selectedNode?.node || {});

    const [structure, setStructure] = useState(null)
    const [iteration, setIteration] = useState(null)

    const [dealParametersChanged, setDealParametersChanged] = useState(false)

    const nodeUpdatedFields = useRef(null)

    const checkDealUpdates = () => {
        if (deal?.onlineDealChanges != null) {
            return !Object.entries(deal.onlineDealChanges).every(([key, value]) => {
                if (value != null && backupDeal[key] != value?.newValue) { //CHECKME!!! need a normal comparsion
                    return false
                }

                return true
            })
        }

        return false
    }


    useEffect(() => {
        setDealParametersChanged(checkDealUpdates())
    }, [deal])

    useLayoutEffect(() => {

        setIteration(selectedNode?.lastIteration)
        setStructure(selectedNode?.structure)

    }, [selectedNode])

    useEffect(() => {
        window.addEventListener("unload", (e) => setCollapse(false)) //инициализация LocalStorage - Securitization/IterationResultCollapse пред закрытием браузера//beforeunload
    }, [])

    const startCalculating = () => {
        setIsCalculating(true)
        saveFunc();
    }

    useLayoutEffect(() => {
        if (iteration == null || iteration?.status == IterationStatus.STATUS_FAILED || iteration?.status == IterationStatus.STATUS_SUCCESS) {
            setIsCalculating(false)
        }

        if (iteration != null && iteration?.status != null && iteration?.status != IterationStatus.STATUS_FAILED && iteration?.status != IterationStatus.STATUS_SUCCESS) {
            setIsCalculating(true)
        }
    }, [iteration])

    useLayoutEffect(() => {
        setDisabledColumnId(null)
        structure?.columns?.map((item, index) => {
            if (item?.highlight != null && item?.column_ID != null && item.highlight === false /*&& disabledColumnId != item?.column_ID*/) {
                setDisabledColumnId(item.column_ID)
            }
        })
    }, [structure])

    useLayoutEffect(() => {
        setSaveState(needRecalc)
    }, [needRecalc])

    const valueOutput = (value, type, fractionPart) => {
        if (value === null) return <></>
        // console.log('fractalPart', fractionPart, value)
        switch (type) {
            case 'numeric':
                return (Localization.FormatNumberString(parseFloat(value), parseInt(fractionPart), parseInt(fractionPart)))
                break;

            default:
                return (value)
                break;
        }
    }

    const BlockOutput = (item, showParam = false, unitParam = '', typeParam = '', fractionParam = null) => {
        if (!item)
            return

        let type = item?.type ? item.type : typeParam
        let fractionPart = item?.fractionPart != null ? item.fractionPart : fractionParam

        let unit = item?.unit != null ? item.unit : unitParam
        let frame = item?.frame != null ? item.frame : null
        let show = item?.show != null ? item.show : showParam

        if (!show) return <React.Fragment key={`${item?.alias}-block`} />
        return (
            <Box key={`${item?.alias}-block`} className={classes.Block}>
                <Box className={[frame].join(' ')}>
                    {!!item?.alias && (
                        <Box className={[classes.rowName, collapse ? 'collapsed' : ''].join(' ')}>
                            {item?.tooltip ?
                                <CustomTooltip
                                    id={`${item.alias}-field-id`}
                                    content={t(item.tooltip)}
                                    placement="left-start"
                                    hideIcon
                                // sx={{ ml: 1 }}
                                >{t(item.alias)}</CustomTooltip> :
                                t(item.alias)
                            }

                            {!!item?.value?.length &&
                                <Box className={classes.rowValuesBlock} key={`iteration_${iteration?.id}`}>
                                    {item.value?.sort((a, b) => a.columnID > b.columnID ? 1 : a.columnID < b.columnID ? -1 : 0).map((x, index) => {
                                        return <Box key={`${item.alias}-value-${index}`}
                                            className={[classes.rowValue, (x?.columnID != null && x?.columnID == disabledColumnId) ? classes.disabled : ''].join(' ')}
                                        >{x.value != null ? valueOutput(x.value, type, fractionPart) : ''}</Box>
                                    })}
                                    {(item?.unit != null || unit != null) && <Box className={classes.rowUnit}>{unit}</Box>}
                                </Box>
                            }
                        </Box>
                    )}

                    <Stack
                        key={`row-${item.alias}-group`}
                        className={[classes.rowBlock, !collapse ? classes.uncollapsed : ''].join(' ')}
                    >
                        {item?.data?.map((subitem, index) => {
                            return (BlockOutput(subitem,
                                subitem?.show ?? show,
                                subitem?.unit ?? unit,
                                subitem?.type ?? type,
                                subitem?.fractalPart ?? fractionPart))
                        })
                        }
                    </Stack>
                </Box>
            </Box>
        )
    }

    const downloadIterationResults = () => {
        window.open('/api/Securitization/DownloadPoolFlows?poolid=' + iteration.poolID/*selectedPool.id*/, '_blank');
        //window.open(`/api/securitization/DownloadIterationResult?dealId=${iteration.dealID}`, "_blank");
    }

    return (
        <Box className={classes.containerBox}>
            {isCalculating && <Box className={classes.fixed}><SpinnerBars withBackground /></Box>}
            {
                <>
                    <Box className={[classes.headerBlock, (iteration != null && iteration?.status === IterationStatus.STATUS_FAILED) ? classes.GlobalError : ''].join(' ')}>
                        <Typography className={classes.GlobalTitle}
                        >
                            {t("PricingTabResultsTitile")}
                        </Typography>
                        <Box className={classes.columnBlock}>
                            {structure?.columns != null
                                && (structure?.columns
                                    ?.sort((a, b) => a.column_ID > b.column_ID ? 1 : a.column_ID < b.column_ID ? -1 : 0)
                                    ?.map((item, index) => {
                                        return <Box key={`${index}-column-title`} className={classes.columnUnit}>
                                            {
                                                item?.tooltip != null && !!item?.highlight ?
                                                    <Tooltip title={t(item.tooltip)} disableInteractive>
                                                        <Box>
                                                            {t(item.alias)}
                                                        </Box>
                                                    </Tooltip>
                                                    :
                                                    !!item?.highlight ? <Box>{t(item.alias)}</Box> : <Box>&nbsp;</Box>
                                            }
                                        </Box>
                                    }
                                    ))
                            }
                        </Box>
                        <Box className={classes.headerButtons}>
                            <IconButton size='small' color='primary' onClick={downloadIterationResults} disabled={iteration == null}>
                                <Tooltip title={t("DownloadIterationResults")} disableInteractive>
                                    <ExcelIcon style={{ width: '24px', height: '24px' }} />
                                </Tooltip>
                            </IconButton>
                            <IconButton size='small' onClick={() => setCollapse(x => !x)}>
                                <Tooltip title={t(collapse ? "Upcollapse" : "Collapse")} disableInteractive>
                                    {collapse ? <UnfoldMore /> : <UnfoldLess />}
                                </Tooltip>
                            </IconButton>
                        </Box>
                    </Box>
                    <Box sx={{ position: 'relative', flexGrow: 1, }}>
                        {(!isCalculating && iteration != null && iteration?.status === IterationStatus.STATUS_FAILED) &&
                            <Stack className={classes.dataAlertBlock}>
                                <Typography color='error' sx={{ textAlign: 'center' }}>
                                    <WarningAmber className={classes.icon} />
                                    <br />{t("IterationFaled")}
                                </Typography>
                            </Stack>
                        }                        <Box className={classes.scrollablePanel}>
                            <Box sx={{ marginBottom: '8px' }}>
                                {structure != null && (structure?.data?.map((item, index) => {
                                    return BlockOutput(item, item?.show ?? false, item?.unit ?? '', item?.type ?? '', item?.fractionPart ?? null)
                                })
                                )}
                            </Box>
                        </Box>
                    </Box>
                </>}

            {(permissions?.isAdmin || permissions?.isManager) &&
                <Box sx={{ p: 0 }}>
                    <Divider />
                    <Box sx={{ m: 1 }}>
                        <Button
                            disabled={!saveState || isCalculating}
                            variant="contained"
                            fullWidth
                            color='secondary'
                            onClick={startCalculating}
                            classes={{ disabled: classes.disabledButton }}
                        >
                            {t("Recalculate")}
                        </Button>
                    </Box>
                </Box>
            }
        </Box >
    )
}

export default PricingResultsPanel;
