import React, { useState, useEffect } from "react";
import InformationForm from "@Components/shared/InformationForm";

import { actionCreators } from "@Store/AccountStore";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useAuth } from "react-oidc-context";
import { Container } from "@mui/material";

const AutorizationCallbackPage = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const accountStore = useSelector((state: any) => state.account);
    const auth = useAuth();
    const [authorized, setAuthorized] = useState(false);

    //useEffect(() => {
        //actionCreators.init()(dispatch);

        //console.log(auth);

    //}, []);

    useEffect(() => {

        try {

            //console.log(auth);

            //alert("auth check")

            if (auth.isAuthenticated) {

                //console.log("!!!!!!!!!!!!!!!!!!!!!!!!!auth 1")

                if (auth.user?.id_token != null) {

                    //console.log("!!!!!!!!!!!!!!!!!!!!!!!!!auth 2")

                    if (authorized)
                        return;

                    //console.log("!!!!!!!!!!!!!!!!!!!!!!!!!auth 3")
                    setAuthorized(true);

                    //alert("getting info about employee")

                    actionCreators.loginOpenIdEmployeeRequest(auth.user.id_token)(dispatch);
                }
                else
                    console.error("user doesn't have valid email");
            }
        } catch (e) {
            console.error(e);
        }
    }, [auth]);

    useEffect(() => {
        const { indicators } = accountStore;

        //console.log(`---------------------indicators `, indicators)

        if (indicators && indicators.loginSuccess) {

            //console.log("!!!!!!!!!!!!!!!!!!!!!!!!!indicators = " + indicators.loginSuccess)
            //alert("navigate to /")
            navigate("/");
        }

    }, [accountStore]);

    const handleRedirectToMain = (event) => {
        navigate("/");
    };

    return (
        <Container sx={{ height: '100%', marginTop: '1rem' }}>

            <InformationForm
                type={auth.isLoading ? "processing" : (auth.isAuthenticated ? "success" : "failure")}
                onSubmit={handleRedirectToMain}
                content={auth.isLoading ? t('PleaseWait') : (auth.isAuthenticated ? null : t('ErrorDueToUserAuthorization'))} />

        </Container>
    )
}

export default AutorizationCallbackPage;