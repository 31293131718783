import * as React from 'react';
import { withStyles } from '@mui/styles';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
// import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';

import { withTranslation } from "react-i18next";

interface IProps {
    show: false,
    onClose: any;
    classes: any;
}

interface IState {
}

const styles = (theme: any) => ({
    root: {
        height: '100%'
    }
});

class OriginatorInfoDialog extends React.Component<IProps, IState> {

    // constructor(props: any) {
    //     super(props);
    // }

    componentDidUpdate(prevProps: IProps) {
        if (this.props.show !== prevProps.show) {
            if (this.props.show === false)
                this.setState({ isOkDisabled: true });
        }
    }

    onWindowClose = (event) => {
        if (this.props.onClose)
            this.props.onClose(event);
    }

    render() {

        const {
            classes,
            t
        } = this.props;

        return (

            <Dialog fullScreen onClose={this.onWindowClose} aria-labelledby="bonds-select-dialog-title" open={this.props.show}>
                <MuiDialogContent className={classes.root}>
                   TEST!
                </MuiDialogContent>
                <MuiDialogActions>
                    <Button variant="contained" color="secondary" onClick={this.onWindowClose}>
                        {t('Close')}
                    </Button>
                </MuiDialogActions>
            </Dialog>
        );
    }
}

export default withStyles(styles)(withTranslation('translations')(OriginatorInfoDialog));