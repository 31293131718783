
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Popover from '@mui/material/Popover';
import * as React from 'react';
import { ColumnModel, CompareOperators, IFilterWrapper, getDefaultValue } from '../Common';
import DialogInput from './DialogInput';
import { OperatorsDropdown } from './OperatorsDropdown';
import { GridOptions } from '../DataGrid';

const dropdown = {
    marginLeft: '10%',
    marginTop: '5%',
    width: '80%',
};

interface IDialogModalProps {
    anchorFilter: HTMLElement;
    activeColumn: ColumnModel;
    setAnchorFilter: (anchorEl: HTMLElement | null) => void;
    setFilter: (filter: IFilterWrapper) => void;
    handleFilterChange: (value: IFilterWrapper | any) => any;
    gridOptions: GridOptions;
}

interface IDialogModalState {
    activeColumn: ColumnModel;
}

export default class DialogModal extends React.Component<IDialogModalProps, IDialogModalState> {

    constructor(props: IDialogModalProps) {
        super(props);

        this.state = {
            activeColumn: this.props.activeColumn
        };
    }

    componentDidUpdate(prevProps: IDialogModalProps, prevState: IDialogModalState) {

        if (this.props.activeColumn != prevProps.activeColumn) {

            if (this.props.activeColumn.Filter.Value == null) {
                var newState = { activeColumn: { ...this.props.activeColumn } };
                newState.activeColumn.Filter.Value = getDefaultValue(this.props.activeColumn.DataType);
                this.setState(newState);
            }
            else
                this.setState({ activeColumn: this.props.activeColumn });
        }
    }

    clearFilter = () => this.props.setFilter(ColumnModel.clearFilterPatch());
    handleInput = (name: string, value: any) => this.props.handleFilterChange({ Value: value });
    handleBetweenInput = (e: any) => this.props.handleFilterChange({ Argument: [e] });
    submit = () => this.props.setFilter(ColumnModel.createFilterPatch(this.state.activeColumn));
    onClose = () => this.props.setAnchorFilter(null);
    locale = this.props.gridOptions.locale;

    render() {
        return (
            <Popover
                open={Boolean(this.props.anchorFilter)}
                onClose={this.onClose}
                anchorEl={this.props.anchorFilter}
                anchorOrigin={{
                    horizontal: 'center',
                    vertical: 'bottom',
                }}
                transformOrigin={{
                    horizontal: 'center',
                    vertical: 'top',
                }}
            >
                <Card >
                    <CardContent>
                        <OperatorsDropdown activeColumn={this.props.activeColumn} handleFilterChange={this.props.handleFilterChange} locale={this.locale} />
                        <DialogInput
                            column={this.props.activeColumn}
                            handleValueChange={this.handleInput}
                            locale={this.locale}
                            disabled={this.props.activeColumn.Filter.Operator === CompareOperators.NONE}
                            label={this.props.activeColumn.Filter.Operator !== CompareOperators.BETWEEN
                                ? this.locale.Value
                                : this.locale.FirstValue}
                            value={this.state.activeColumn.Filter.Value}
                            style={dropdown}
                        />

                        {this.props.activeColumn.Filter.Operator === CompareOperators.BETWEEN &&
                            (
                                <DialogInput
                                    column={this.props.activeColumn}
                                    handleValueChange={this.handleBetweenInput}
                                    locale={this.locale}
                                    disabled={false}
                                    label={this.locale.SecondValue}
                                    value={this.state.activeColumn.Filter.Argument[0]}
                                    style={dropdown}
                                />
                            )}
                    </CardContent>
                    <CardActions>
                        <Button
                            size='medium'
                            color='secondary'
                            onClick={this.clearFilter}
                        >{this.locale.Clear}</Button>
                        <Button
                            size='medium'
                            color='primary'
                            onClick={this.submit}
                            disabled={this.state.activeColumn.Filter.Operator === CompareOperators.NONE}
                        >{this.locale.Apply}</Button>
                    </CardActions>
                </Card>
            </Popover>
        );
    }
}
