import * as React from "react";
import Tooltip from '@mui/material/Tooltip';
// import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Theme } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
// import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ButtonBase from '@mui/material/ButtonBase';
import clsx from 'clsx';
// import style from '@Styles/domrf-theme.module.scss';
import Globals from '../../Globals';
import { withTranslation } from "react-i18next";

// import Localization from "../Localization";

const styles = (theme: Theme) => createStyles({

    /* Styles applied to the root element. */
    root: {
        cursor: 'pointer',
        display: 'inline-flex',
        justifyContent: 'flex-start',
        flexDirection: 'inherit',
        marginRight: 10
    },
    /* Styles applied to the icon component. */
    icon: {
        fontSize: 18,
        //marginLeft: 15,
        userSelect: 'none',
        color: 'red',
        opacity: 0.8
    },

    button: {
        color: 'white'
    },
    buttonContainer: {
        textAlign: 'end'
    },
    popper: {
        zIndex: 10001
    }

    //children: {
    //cursor: 'pointer'
    //}
});

interface IProps {
    classes: any;
    //className: any;
    IconComponent?: any;
    content: any;
    link?: string;
    t: any;
    placement: any
}

interface IState {
    tooltipOpened: boolean;
}

class ErrorTooltip extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            tooltipOpened: false
        }
    }

    handleClick = (evt: any) => {
        window.open(Globals.WikiServer + '/' + this.props.link, "_blank");
        //window.location.href = Globals.WikiServer + '/' + this.props.link;
    }

    handleTooltipClose = () => {
        this.setState({ tooltipOpened: false });
    };

    handleTooltipOpen = (evt: any) => {
        evt.stopPropagation();

        this.setState({ tooltipOpened: true });
    };

    render() {

        const
            {
                classes,
                //className,
                IconComponent = ErrorOutlineIcon,
                content,
                t,
                tReady,
                placement,

                ...other
            } = this.props;

        return (
            <ClickAwayListener onClickAway={this.handleTooltipClose}>
                <Tooltip
                    className={classes.root}
                    PopperProps={{
                        //disablePortal: true,
                        className: classes.popper
                    }}
                    onClose={this.handleTooltipClose}
                    open={this.state.tooltipOpened}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    placement={placement}
                    //interactive
                    title={
                        <React.Fragment>
                            <div dangerouslySetInnerHTML={{ __html: content }}></div>
                            {
                                this.props.link != null && (
                                    <div className={classes.buttonContainer}>
                                        <Button variant="contained" color="secondary" onClick={this.handleClick}>{t('Article')}</Button>
                                    </div>
                                )
                            }
                        </React.Fragment>
                    }
                    {...other}
                >

                    <ButtonBase
                        className={clsx(classes.root)}
                        component="span"
                        disableRipple
                        onClick={this.handleTooltipOpen}
                    >
                        <IconComponent
                            className={clsx(classes.icon)}
                        />
                    </ButtonBase>
                </Tooltip>
            </ClickAwayListener>
        );
    }
}

export default withStyles(styles)(withTranslation('translations')(ErrorTooltip));