import { Button, alpha } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Grid } from '@mui/material';

//import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { makeStyles } from '@mui/styles';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import style from '@Styles/domrf-theme.module.scss';

interface IProps {
    isOpen: boolean;
    header?: string;
    content: string | object;
    onAccept: (e) => void;
    onDecline: (e) => void;
    acceptButton: string
    declineButton: string
    classes: object
}

const useStyles = makeStyles({
    root: {
        minWidth: '25%',
        '& *': {
            userSelect: 'none',
        },
    },
    title: {
        padding: '0 0.5rem',
        fontWeight: 'normal',
        fontSize: '0.9rem',
        color: style.colorPrimary3,
        textTransform: 'uppercase',
        lineHeight: '2rem',
        boxShadow: `0px 1px ${alpha(style.colorPrimary3, 0.7)}`,
    },
    bodyBlock: {
        padding: '1rem 1.5rem !important',
        textAlign: 'center',
    },
    buttonsBlock: {

    },
    icon: {
        color: style.colorWarning
    }
});

const ConfirmationForm = ({ isOpen, header, content, onAccept, onDecline, acceptButton = null, declineButton = null, classes = {} }: IProps) => {

    const { t, i18n } = useTranslation();
    const cls = useStyles();

    return (

        <Dialog open={isOpen} classes={{ paper: cls.root }}>
            <DialogTitle classes={{ root: cls.title }}>{header}</DialogTitle>
            <DialogContent classes={{ root: cls.bodyBlock }}>
                {/* <Grid container>
                    <Grid item xs={3}>
                        <FontAwesomeIcon icon={faExclamationTriangle} size="4x" className={cls.icon} />
                    </Grid>

                    <Grid item xs={9}> */}
                <DialogContentText className={classes?.dialogContent ?? '' }>
                    {content}
                </DialogContentText>
                {/* </Grid>
                </Grid> */}
            </DialogContent>
            <DialogActions classes={{ root: [cls.buttonsBlock, classes?.dialogActions ?? ''].join(' ') }}>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={onAccept}>{acceptButton ? acceptButton : t('Remove')}</Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onDecline}>{declineButton ? declineButton : t('Cancel')}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default ConfirmationForm;