import * as React from 'react';
import clsx from 'clsx';
// import HelpIcon from '@mui/icons-material/Help';
import { Theme } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
// import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
// import ButtonBase from '@mui/material/ButtonBase';
import InfoTooltip from './InfoTooltip';
import style from '@Styles/domrf-theme.module.scss';
// import { VerticalAlignBottom } from '@mui/icons-material';

const styles = (theme: Theme) => createStyles({
    /* Styles applied to the root element. */
    root: {
        cursor: 'pointer',
        display: 'inline-flex',
        justifyContent: 'flex-start',
        flexDirection: 'row',
        alignItems: 'center',
        //flexDirection: 'inherit',
        //alignItems: 'center',
        /*
        '&:focus': {
            color: theme.palette.text.secondary,
        },
        '&:hover': {
            color: theme.palette.text.secondary,
            '& $icon': {
                opacity: 0.5,
            },
        },
        '&$active': {
            color: theme.palette.text.primary,
            // && instead of & is a workaround for https://github.com/cssinjs/jss/issues/1045
            '&& $icon': {
                opacity: 1,
                color: theme.palette.text.secondary,
            },
        },
        */
    },
    /* Pseudo-class applied to the root element if `active={true}`. */
    active: {},
    /* Styles applied to the icon component. */
    icon: {
        fontSize: 18,
        //marginLeft: 15,
        userSelect: 'none',
        color: style.colorPrimary3,
        opacity: 0.8
    },
    container: {
        display: 'inline-flex',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        },
        fullWidth: {
            width:'100%'
        }
});

interface IProps {
    content: any;
    fullWidth?: any;
    classes: any;
    className?: any;
    children: any;
    link?: string;
    linkName?: string;
    onLinkClick?: () => void;
    right?:boolean
}

interface IState {

}

class TooltipInfoLabel extends React.PureComponent<IProps, IState>
{

    // constructor(props) {
    //     super(props);
    // }

    render() {

        const {
            children,
            content,
            fullWidth,
            classes,
            link,
            linkName,
            onLinkClick,
            className,
            right,
            
            ...rest
        } = this.props;



        return (
            <span className={clsx(classes.container, className, fullWidth ? classes.fullWidth : {})} {...rest}>
                {
                    (content != null && content.length !== 0) && (<InfoTooltip right={right} content={content} link={link} linkName={linkName} onLinkClick={onLinkClick} />)
                }
                {children}
            </span>
        );
    }
}

export default withStyles(styles)(TooltipInfoLabel);