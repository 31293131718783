import UI from "../UIHelper";
import Result from "../models/Result";
import Axios, { AxiosRequestConfig, AxiosError } from "axios";
import Globals from "../Globals";
import queryString from "query-string";

import AccountService from "./AccountService";
/**
 * Represents base class of the isomorphic service.
 */
export interface IRequestOptions {
    url: string;
    method: string;
    data: any;
    cancellationToken?: AbortController;
    showError?: boolean;
}

export default class ServiceBase {

    static requests = {};

    /**
     * Make request with JSON data.
     * @param opts
     */
    static async requestJson(opts: IRequestOptions) {

        var axiosResult = null;
        var result = null;

        //opts.url = transformUrl(opts.url); // Allow requests also for the Node.

        var processQuery = (url, data) => {
            if (data) {
                /*
                let urlParameters = Object.keys(data).map(function (k) {
                    return encodeURIComponent(k) + '=' + encodeURIComponent(data[k])
                }).join('&')
                */
                let urlParameters = queryString.stringify(data);

                return `${url}?${urlParameters}`;
            }
            return url;
        };

        var axiosRequestConfig: AxiosRequestConfig = {
            headers: {
                'Content-Type': 'application/json'
            },
            withCredentials: false
        }

        if (opts.cancellationToken != null)
            axiosRequestConfig.signal = opts.cancellationToken.signal;

        var user = Globals.user;
        if (user != null && user.token != null) {
            axiosRequestConfig.headers["Authorization"] = "Bearer " + user.token;
        }

        try {

            const isExtUrl = !RegExp('^http://',"i").test(opts.url)
            // console.log('isExtUrl', isExtUrl)
            switch (opts.method) {
                case "GET":
                    axiosResult = await Axios.get(`${isExtUrl?`${process.env.REACT_APP_PUBLIC_URL}`:``}${processQuery(opts.url, opts.data)}`, axiosRequestConfig);
                    break;
                    case "POST":
                    axiosResult = await Axios.post(`${isExtUrl?`${process.env.REACT_APP_PUBLIC_URL}`:``}${opts.url}`, opts.data, axiosRequestConfig);
                    break;
                case "PUT":
                    axiosResult = await Axios.put(`${isExtUrl?`${process.env.REACT_APP_PUBLIC_URL}`:``}${opts.url}`, opts.data, axiosRequestConfig);
                    break;
                case "PATCH":
                    axiosResult = await Axios.patch(`${isExtUrl?`${process.env.REACT_APP_PUBLIC_URL}`:``}${opts.url}`, opts.data, axiosRequestConfig);
                    break;
                case "DELETE":
                    axiosResult = await Axios.delete(processQuery(`${isExtUrl?`${process.env.REACT_APP_PUBLIC_URL}`:``}${opts.url}`, opts.data), axiosRequestConfig);
                    break;
                default:
                    throw "Unknown request type: " + opts.method;
                    break;
            }
            result = new Result(axiosResult.data.value, null, ...axiosResult.data.errors);
        } catch (error) {

            if (!Axios.isCancel(error))
                console.log(error);

            //console.log('url', `${process.env.REACT_APP_PUBLIC_URL}/${opts.url}`);

            if (Axios.isCancel(error)) {
                result = new Result(null, 200);
            }
            else if (Axios.isAxiosError(error))
                result = new Result(null, error.response?.status, error.message);
            else
                result = new Result(null, null, error.toString());
        }

        if (opts.showError !== false && !result.isDenied && result.hasErrors) {
            // console.error(result)
            UI.showErrors(...result.errors);
        }

        if (result.isDenied) {
            //clear session
            Globals.reset();
            //redirect at start page
            Globals.redirect("/login");
            //store.dispatch(push('/login'));
        }

        if (axiosResult?.headers['refresh-token'] != null) {
            var tokenResult = await AccountService.refreshToken(axiosResult.headers['refresh-token']);

            if (tokenResult.hasErrors) {

                Globals.reset();
                Globals.redirect("/login");
            }
            else {
                Globals.updateUser(tokenResult.value);
            }
        }

        return result;
    }

    /**
     * Allows you to send files to the server.
     * @param opts
     */
    static async sendFormData(opts: IRequestOptions) {
        var axiosResult = null;
        var result = null;

        // opts.url = transformUrl(opts.url); // Allow requests also for Node.

        var axiosRequestConfig: AxiosRequestConfig = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        };

        if (opts.cancellationToken != null)
            axiosRequestConfig.signal = opts.cancellationToken.signal;

        var user = Globals.user;
        if (user != null && user.token != null) {
            axiosRequestConfig.headers["Authorization"] = "Bearer " + user.token;
        }

        try {
            switch (opts.method) {
                case "POST":
                    axiosResult = await Axios.post(`${process.env.REACT_APP_PUBLIC_URL}/${opts.url}`, opts.data, axiosRequestConfig);
                    break;
                case "PUT":
                    axiosResult = await Axios.put(`${process.env.REACT_APP_PUBLIC_URL}/${opts.url}`, opts.data, axiosRequestConfig);
                    break;
                case "PATCH":
                    axiosResult = await Axios.patch(`${process.env.REACT_APP_PUBLIC_URL}/${opts.url}`, opts.data, axiosRequestConfig);
                    break;
                default:
                    throw "Unknown request type: " + opts.method;
                    break;
            }
            result = new Result(axiosResult.data.value, ...axiosResult.data.errors);
        } catch (error) {

            console.log('error', error)
            if (Axios.isCancel(error)) {
                result = new Result(null, 200);
            }
            else if (Axios.isAxiosError(error))
                result = new Result(null, error.response?.status, error.message);
            else
                result = new Result(null, null, error.toString());
        }

        if (opts.showError !== false && !result.isDenied && result.hasErrors) {
            UI.showErrors(...result.errors);
        }

        if (result.isDenied) {
            //clear session
            Globals.reset();
            //redirect at start page
            Globals.redirect("/login");
            //store.dispatch(push('/login'));
        }

        if (axiosResult?.headers['refresh-token'] != null) {
            var tokenResult = await AccountService.refreshToken(axiosResult.headers['refresh-token']);

            if (tokenResult.hasErrors) {

                Globals.reset();
                Globals.redirect("/login");
            }
            else {
                Globals.updateUser(tokenResult.value);
            }
        }

        return result;
    }

    static async requestWithCancellation(name: string, func: (token: AbortController) => Promise<any>) {

        var container = ServiceBase.requests[name];

        if (container == null) {
            container = { task: null, signal: null };
            ServiceBase.requests[name] = container;
        }

        //console.log("---------cancelling request");
        if (container.signal != null)
            container.signal.abort();

        if (container.task != null)
            await container.task;

        //console.log("---------creating request");
        container.signal = new AbortController();

        container.task = func(container.signal);

        var result = await container.task;

        //console.log("---------finishing request, ", result);
        if (container.task != null /*&& SecuritizationService.requests.getPoolData.id == requestId*/) {

            //console.log("---------cleanup mess");
            result.isCancelled = container.signal.signal.aborted;
            container.task = null;
            container.signal = null;
        }

        //console.log(`-->> ${name} >>result`, result)

        return result;
    }
}