import ServiceBase from "./ServiceBase";
import Globals from "../Globals";

export default class AccountService extends ServiceBase {

    static async checkCredentials() {
        var result = await this.requestJson({
            url: "api/Account/CheckCredentials",
            method: "GET",
            showError: false
        });

        return result;
    }

    static async refreshToken(token) {
        var result = await this.requestJson({
            url: "api/Account/RefreshToken",
            method: "GET",
            showError: false,
            data: { token: token }
        });

        return result;
    }

    static async createUser(data) {

        var result = await this.requestJson({
            url: "api/Account/CreateUser",
            method: "POST",
            data: data
        });

        return result;
    }

    static async login(loginModel) {
        var result = await this.requestJson({
            url: "api/Account/Login",
            method: "POST",
            data: loginModel
        });

        if (!result.hasErrors) {
            Globals.user = result.value;
        }

        return result;
    }

    static async logout() {
        var result = await this.requestJson({
            url: "api/Account/Logout",
            method: "POST"
        });

        if (!result.hasErrors) {
            Globals.reset();
        }

        return result;
    }

    static async register(model) {
        var result = await this.requestJson({
            url: "api/Account/Register",
            method: "POST",
            showError: false,
            data: model
        });

        return result;
    }

    static async forgotPassword(model) {

        var result = await this.requestJson({
            url: "api/Account/ForgotPassword",
            method: "GET",
            showError: false,
            data: model
        });

        return result;

    }
    static async resetPassword(model) {

        var result = await this.requestJson({
            url: "api/Account/ResetPassword",
            method: "POST",
            data: model
        });

        return result;
    }

    static async confirmEmail(model) {

        var result = await this.requestJson({
            url: "api/Account/ConfirmEmail",
            method: "POST",
            data: model
        });

        return result;
    }

    static async loginAsEmployee() {

        var result = await this.requestJson({
            url: "api/Account/LoginAsEmployee",
            method: "GET"
        });

        if (!result.hasErrors) {
            var user = result.value;
            user.isRemember = true; //CHECKME!!! force for now
            Globals.user = user;
        }

        return result;
    }

    static async loginOpenIdAsEmployee(jwtToken) {

        var result = await this.requestJson({
            url: "api/Account/LoginOpenIdAsEmployee",
            method: "GET",
            data: { jwtToken: jwtToken }
        });

        if (!result.hasErrors) {

            console.log("!!!!!!!!!!!!!!!!!!!!!!!!!setting user")
            var user = result.value;
            user.isRemember = true; //CHECKME!!! force for now
            Globals.user = user;
        }

        return result;
    }

    static async enterAtAccount(id) {
        var result = await this.requestJson({
            url: "api/Account/EnterAtAccount",
            method: "GET",
            data: {id: id}
        });

        if (!result.hasErrors) {
            var user = result.value;
            user.isRemember = true; //CHECKME!!! force for now
            Globals.switchToUser(user)
        }

        return result;
    }

    static async changePassword(oldPasword, newPassword) {
        var result = await this.requestJson({
            url: "api/Account/ChangePassword",
            method: "GET",
            data: { oldPasword: oldPasword, newPassword: newPassword }
        });

        return result;
    }

    static async getUserSettings() {
        var result = await this.requestJson({
            url: "api/Account/GetUserSettings",
            method: "GET"
        });

        return result;
    }

    static async updateUserSettings(data) {
        var result = await this.requestJson({
            url: "api/Account/UpdateUserSettings",
            method: "POST",
            data: data
        });

        return result;
    }

    static async addVisitor(userId, target) {
        var result = await this.requestJson({
            url: "api/Account/AddVisitor",
            method: "GET",
            showError: false,
            data: { anonymousUserID: userId, target: target }
        });

        return result;
    }
    /*
    static async getEmployeesByRole(role) {
        var result = await this.requestJson({
            url: "api/Account/GetEmployeesByRole",
            method: "GET",
            data: { role: role }
        });

        return result;
    }
    */
}