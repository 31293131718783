import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as CalculatorStore from "@Store/CalculatorStore";
import { withRouter } from "../components/shared/CustomRouter";
import { Paper } from "@mui/material";

import { DataGrid, ToolbarOptions, GridOptions } from "@Framework/components/Table";
import { ColumnModel } from "@Framework/components/Table/Common";

import AdministrationService from "@Services/AdministrationService";
import SiteHelper from "@Shared/SiteHelper";

import { DataSourceChangeType } from '@Framework/components/Common/Definitions';
import Localization from '@Components/Localization';
import Globals from '@Base/Globals.ts';
import OriginatorInfoDialog from "@Components/shared/OriginatorInfoDialog";
import { AggregateFunctions, ColumnDataType, ColumnSortDirection, TrimContentType } from '@Framework/components/Table/Common/Models/Column';
import { withTranslation } from "react-i18next";

class NotificationsPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showInfo: false,
            objectId: null
        }
    }

    grid = null;

    async componentDidMount() {

        (async () => {

            var state = Globals.state.notifiactions;
            if (state != null)
                await this.grid.SetFiltersState(state);
        })();
    }

    dataSource = {
        items: [{ key: null, value: this.props.t('Undefined') }],
        count: 0
    };

    dataSourceQuery = async (request) => {

        //save actual state
        var columnsState = SiteHelper.CreateFilterState(request);
        Globals.setState('notifications', columnsState);


        var filter = SiteHelper.CreateFilter(request);

        var result = await AdministrationService.getNotifications(filter);
        if (result.hasErrors) {
            throw ('Error while processing request');
        }

        //IGridPayload
        return {
            totalCount: result.value.count,
            payload: result.value.items
        };
    }

    dataSourceUpdate = async (type, data) => {
        if (data == null)
            return false;

        var result = null;
        switch (type) {
            /*
            case DataSourceChangeType.CREATE:
                {
                    result = await CalculatorService.createOriginator(data);
                }
                break;
            case DataSourceChangeType.UPDATE:
                {
                    //form update structure
                    var toUpdate = new Object();
                    var keyField = "id";

                    if (data.changedFields != null) {
                        toUpdate[keyField] = data[keyField];
                        data.changedFields.forEach((item) => {
                            toUpdate[item] = { Value: data[item] };
                        });
                    }
                    else {
                        //update all
                        Object.keys(data).forEach((key) => {
                            toUpdate[key] = { Value: data[key] };
                        });
                    }

                    result = await CalculatorService.updateOriginator(toUpdate);
                }
                break;
            */
            case DataSourceChangeType.REMOVE:
                {
                    result = await AdministrationService.removeNotification(data.id);
                }
                break;
        }

        if (result.hasErrors) {
            throw ('Error while processing request');
        }

        return result.value;
    };

    renderProductCell = (row) => {
        return row.productName;
    };

    productQuery = (value, index, pageSize) => {
        return this.dataSource;
    };

    handleRowDoubleClick = (row) => {
        /* uncomment when dialog content will be available
        this.setState({ showInfo: true, objectId: row['id'] });
        */
    };

    handleInfoDialogClose = (event) => {
        this.setState({ showInfo: false });
    };

    columns = [
        new ColumnModel('id', { Label: this.props.t('ID'), Tooltip: this.props.t('NotificationID_Info'), DataType: ColumnDataType.INT, IsKey: true, Sortable: true, SortDirection: 'Ascending', SortOrder: 1 }),
        new ColumnModel('date', { Label: this.props.t('CreatedDate'), Tooltip: this.props.t('NotificationDate_Info'), DataType: ColumnDataType.DATE, Searchable: true, Filterable: true, Sortable: true, IsReadonly: true }),
        new ColumnModel('name', { Label: this.props.t('Name'), Tooltip: this.props.t('NotificationName_Info'), DataType: ColumnDataType.STRING, Searchable: true, Filterable: true, Sortable: true }),
        new ColumnModel('description', { Label: this.props.t('Description'), Tooltip: this.props.t('NotificationDescription_Info'), DataType: ColumnDataType.STRING, Searchable: true, Filterable: true, Sortable: true }),

        //for admins only
        ...(Globals.hasAccess(Globals.Administrator_Role) ? [

            new ColumnModel('', {
                Actions: [{ Type: 'remove' }],
                Label: this.props.t('Actions'),
                IsAction: true
            })
        ] : []
        )
    ];

    settings = { query: this.dataSourceQuery, update: this.dataSourceUpdate };

    gridOptions = new GridOptions({
        topPager: false,
        fixedHeader: false,
        fullHeight: true,
        fullWidth: false,
        floatingToolbar: true,
        canSearch: true,

        confirmTitle: this.props.t('AreYouSureQuestion'),
        confirmDescription: this.props.t('RemoveNotificationDescription')
    });

    render() {

        const { t } = this.props;

        return (
            <Paper sx={{width: '100%', height: '100%', paddingX: '1rem'}}>
                <OriginatorInfoDialog show={this.state.showInfo} objectId={this.state.objectId} onClose={this.handleInfoDialogClose} />
                <DataGrid
                    ref={x => this.grid = x}
                    gridName={t('Notifications')}
                    dataSource={this.settings}
                    columns={this.columns}
                    gridOptions={this.gridOptions}
                    onRowDoubleClick={this.handleRowDoubleClick}
                />
            </Paper>
        );
    }
}

export default withRouter(withTranslation('translations')(NotificationsPage));