import InstrumentsService from "../services/InstrumentsService";

const Actions = {

    Init: "INSTRUMENTS_SEARCH_INIT",

    SearchRequest: "INSTRUMENTS_SEARCH_REQUEST",
    SearchSuccess: "INSTRUMENTS_SEARCH_SUCCESS",
    SearchFailure: "INSTRUMENTS_SEARCH_FAILURE"
}


export const actionCreators = {

    init: () => async (dispatch, getState) => {
        dispatch({ type: Actions.Init });
    },

    getFavouriteInstruments: () => async (dispatch, getState) => {

        dispatch({ type: Actions.SearchRequest });

        var result = await InstrumentsService.getFavouriteInstruments();
        if (result.hasErrors) {
            dispatch({ type: Actions.SearchFailure });
            return;
        }

        dispatch({ type: Actions.SearchSuccess, payload: result.value });
    },

    findInstruments: (model) => async (dispatch, getState) => {

        dispatch({ type: Actions.SearchRequest });

        var result = await InstrumentsService.findInstruments(model);
        if (result.hasErrors) {
            dispatch({ type: Actions.SearchFailure });
            return;
        }

        dispatch({ type: Actions.SearchSuccess, payload: result.value });
    }
};

const initialState = {
    operationLoading: false,
    instruments: null
};

export const reducer = (currentState, incomingAction) => {

    if (currentState === undefined)
        return initialState; 


    const action = incomingAction;

    switch (action.type) {
        case Actions.Init:
            return initialState;

        case Actions.SearchRequest:
            return { ...currentState, operationLoading: true };

        case Actions.SearchSuccess:
            return { ...currentState, operationLoading: false, instruments: action.payload };

        case Actions.SearchFailure:
            return { ...currentState, operationLoading: false, instruments: null };
        default:
            return currentState || initialState;
    }
};