import React, { useState, useEffect } from 'react'
import { alpha, Box, Button, debounce, Modal, Paper, TextField, Theme, Tooltip } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import style from '@Styles/domrf-theme.module.scss'
import { useSelector } from 'react-redux';
import { ePoolNodeType, PoolProcessingStatus } from '@Base/components/Definitions';
import { actionCreators as actionSecuritization } from '@Base/store/SecuritizationStore'
import { useDispatch } from 'react-redux';
import { t } from 'i18next';
import { Add, AddCircleOutline, CheckCircle, CloudUpload, Inventory2Outlined, ReportProblemOutlined, RunningWithErrors } from '@mui/icons-material';
import CustomCircularProgress from '@Base/components/shared/CustomCircularProgress';
import Localization from '@Base/components/Localization';
import { humanReadableStatuses } from '@Base/Utilities'
import ServiceMenu from './ServiceMenu';

const useStyles = makeStyles((theme: Theme) => createStyles({
    modalButtonBox: {
        display: 'flex',
        flexWrap: 'nowrap',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginTop: '0.5rem',
    },
    rootModal: {
        pointerEvents: 'none',
        position: 'relative',
        display: 'flex',
        overflow: 'hidden',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    },
    modalPaper: {
        pointerEvents: 'all',
        width: '450px',
        padding: '1rem',
        borderRadius: '0.5rem',
        border: `solid 1px ${style.colorPrimary3_40}`,
        backgroundColor: '#FFFFFF',
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        justifyContent: 'flex-start',
    },
    poolCell: {
        height: '76px !important',
        position: 'relative',
        marginBottom: '0.7rem',
        overflow: 'visible',
        '& *': {
            transition: 'all 0s ease'
        },
        '& .rip': {
            // border: `solid 1px ${style.colorPrimary3_40}`,
            border: `solid 1px #dfdfe1`,
            // borderColor: '#818f81',
            color: `${style.colorPrimary3}`
        },
        '& .transfer': {
            // border: `solid 1px ${style.colorPrimary3_40}`,
            border: `solid 1px #dfdfe1`,
            // borderColor: '#818f81',
            color: `${style.colorPrimary3}`
        },
        '& .lld': {
            // border: `solid 1px ${style.colorPrimary3_40}`,
            // border: `solid 1px #dfdfe1`,
            border: `solid 1px #dfdfe1`,
            // borderColor: '#818f81',
            color: `${style.colorPrimary3}`
        },
        '& .template': {
            // opacity: 0.5,
            // borderColor: '#818f81',
            borderLeftStyle: 'dashed',
            borderRightStyle: 'dashed',
            borderTopStyle: 'dashed',
            borderBottomStyle: 'dashed',
            '& $nodeName': {
                lineHeight: '1.5rem',
            },
        },
        '&:hover ': {
            '& .rip': {
                borderColor: `#8f8f81`,
            },
            '& .transfer': {
                borderColor: `#8f8f81`,
            },
            '& .lld': {
                borderColor: `#8f8f81`,
            },
            '& $addIconBox': {
                opacity: 0.5
            }
            // '& $nodeInfoDateSlice': {
            //     opacity: '1 !important',
            // },
            // '& $nodeInfoValue': {
            //     opacity: '1 !important',
            // },
            // '& $nodeInfoWAC': {
            //     opacity: '1 !important',
            // },
        },
        '& .selected': {
            cursor: 'default',
            opacity: '1 !important',
            backgroundColor: `${alpha(style.colorPrimary2, 0.2)}`,
            borderColor: `${alpha(style.colorPrimary2, 1)} !important`,
            // color:`${alpha(style.colorPrimary2,1)}`
            '& $nodeInfoDateSlice': {
                opacity: 1,
            },
            '& $nodeInfoValue': {
                opacity: 1,
            },
            '& $nodeInfoWAC': {
                opacity: 1,
            },
            '& $nodePoolStatus': {
                opacity: 1,
            },
        }
    },
    nodePoolBox: {
        position: 'absolute',
        left: 0, top: 0, right: 0, bottom: 0,
        overflow: 'hidden',
        borderRadius: '0.4rem',
        flexWrap: 'nowrap',
        // padding: '0.5rem',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        letterSpacing: 'normal',
        // padding:0
        fontWeight: 100,
        fontFamily: 'DINPro, Courier',
        fontSize: '1rem',
        "&:hover not:selected": {
            color: style.colorPrimary1,
            backgroundColor: `${alpha(style.colorPrimary3, 0.2)}`,

        }
    },
    nodeName: {
        fontSize: '1rem',
        whiteSpace: 'normal',
        fontWeight: 'bold',
        lineHeight: '1rem',
        // color:`${style.colorPrimary1}`,
    },
    nodeIcon: {
        position: 'absolute',
        right: '8px', top: '1px',
        width: '24px',
        height: '24px',
        // lineHeight: '1rem',
        // marginBottom: '6px',
        // marginTop: '3px',
    },
    nodePoolName: {
        lineHeight: '1rem',
        fontSize: '0.6rem',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    nodePoolNameIsEditable: {
        cursor: 'text',
    },
    nodeTitleRowContainer: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        letterSpacing: 'normal',
        width: '100%',
        // height: '1rem',
        // marginBottom: '0.5rem',
        justifyContent: 'space-between',
    },
    nodeInfoRowContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        letterSpacing: 'normal',
        width: '100%',
        justifyContent: 'center',
        // minHeight: '31px',
        paddingBottom: '16px',
        alignItems: 'baseline',
        lineHeight: '16px',
    },
    nodeInfoRowContainer2: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        letterSpacing: 'normal',
        width: '100%',
        justifyContent: 'space-between',
        minHeight: '15px',
        alignItems: 'baseline',
        lineHeight: '15px',
    },

    nodeInfoWAC: {
        opacity: 0.5,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignItems: 'baseline',
        flexGrow: 1,
        // marginRight: '5px',
        // lineHeight: '1.5rem',
        position: 'relative',
        // paddingTop: '1rem',
        fontWeight: 'bold',
        textAlign: 'right',
        justifyContent: 'flex-end',
        color: `${style.colorPrimary3_80}`,
        // '&::before': {
        //     content: '"' + `${t('NodePoolWAC')}` + '"',
        //     position: 'absolute',
        //     left: 0, right: 0, top: 0,
        //     fontWeight: 'normal',
        //     fontSize: '0.7rem',
        // },
        '&>.value': {
            fontSize: '16px',
            fontWeight: 'bold',
            color: `${style.colorPrimary1}`,
        },
        '&>.unit': {
            marginLeft: '3px',
            fontSize: '0.7rem'
        }
    },
    nodeInfoValue: {
        opacity: 0.5,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignItems: 'baseline',
        flexGrow: 1,
        // marginLeft: '5px',
        // lineHeight: '1.5rem',
        position: 'relative',
        // paddingTop: '1rem',
        textAlign: 'left',
        justifyContent: 'flex-start',
        color: `${style.colorPrimary3_80}`,
        // '&::before': {
        //     content: '"' + `${t('NodePoolDebt')}` + '"',
        //     position: 'absolute',
        //     left: 0, right: 0, top: 0,
        //     fontWeight: 'normal',
        //     fontSize: '0.7rem',
        // },
        '&>.value': {
            fontSize: '16px',
            fontWeight: 'bold',
            color: `${style.colorPrimary1}`,
        },
        '&>.unit': {
            marginLeft: '3px',
            fontSize: '9px'
        }
    },
    nodePoolRoot: {
        // position: 'relative',
        overflow: 'hidden',
        width: '100%',
    },
    nodeTypeInfo: {
        color: style.colorPrimary3_60,
        // fontWeight:'500',
    },
    nodeInfoDateSlice: {
        opacity: 0.5,
        // display:'flex',
        // flexDirection:'row',
        // flexWrap:'nowrap',
        // alignItems:'baseline',
        flexGrow: 1,
        // lineHeight: '1rem',
        position: 'relative',
        // paddingTop: '1rem',
        fontWeight: 'bold',
        textAlign: 'right',
        color: `${style.colorPrimary1}`,
        // '&::before': {
        //     content: '"' + `${t('NodePoolDate')}` + '"',
        //     position: 'absolute',
        //     left: 0, right: 0, top: 0,
        //     fontWeight: 'normal',
        //     fontSize: '0.7rem',
        // },
        // '&>.value': {
        //     fontSize: '16px',
        //     fontWeight: 'bold',
        //     color: `${style.colorPrimary1}`,
        // },
        // '&>.unit': {
        //     marginLeft: '3px',
        //     fontSize: '0.7rem'
        // }
    },
    nodePoolStatus: {
        opacity: 0.5,
        color: style.colorPrimary3_60,
        fontSize: '0.6rem',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    addIconBox: {
        position: 'absolute', left: 0, top: 0, right: 0, bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        opacity: '0.35',
    },
    addIcon: {
        width: '40px',
        height: '40px',
        color: `${alpha(style.colorPrimary3_40, 1)}`,

        // stroke: `${alpha(style.colorPrimary3_40, 1)}`,
        // strokeWidth: '0.5',
    }
}))

function PoolNodeCard({ template = false, selected = false, node = null, type = null, poolData = null, clickEvent }) {
    const classes = useStyles()
    const { deal, poolsHubMessage, permissions, ...otherStor } = useSelector((state: any) => state.securitization)
    const dispatch = useDispatch()
    const [poolStateData, setPoolStateData] = useState(null)
    const [anchorEl, setAnchorEl] = useState(null)
    // console.log('poolData', poolData)
    const [editNameNodeId, setEditNameNodeId] = useState(null)
    const [nodeName, setNodeName] = useState(null)

    // console.log('otherStor', otherStor)
    useEffect(() => {
        if (poolStateData == null) {
            setPoolStateData(poolData)
        }
    }, [poolData])

    // console.log('node', node)
    useEffect(() => {

        if (poolsHubMessage != null && poolsHubMessage?.poolID == poolStateData?.id) {

            //console.log('poolsHubMessage----- setPoolStateData', poolsHubMessage)

            setPoolStateData({
                ...poolStateData,
                status: poolsHubMessage?.status,
                statusString: poolsHubMessage?.statusString,
                percents: poolsHubMessage?.percents,
                currentWAC: poolsHubMessage?.currentWAC,
                currentMainDebt: poolsHubMessage?.currentMainDebt,
            })
            // if (!template) clickEvent()
        }
    }, [poolsHubMessage])

    const getTypeStyle = () => {
        switch (type) {
            case ePoolNodeType.TYPE_POOL_AT_REGISTRATION_DATE:
                return 'rip'

            case ePoolNodeType.TYPE_POOL_AT_TRANSFER_DATE:
                return 'transfer'

            case ePoolNodeType.TYPE_POOL_PLAIN:
            default:
                return 'lld'
        }
    }

    const getNodeTooltip = (nodeType) => {
        switch (nodeType) {
            case ePoolNodeType.TYPE_POOL_AT_REGISTRATION_DATE:
                return t('addRegistrationPool')
            case ePoolNodeType.TYPE_POOL_AT_TRANSFER_DATE:
                return t('addTransferPool')
            case ePoolNodeType.TYPE_POOL_PLAIN:
            default:
                return t('addLLDPoold')
        }
    }

    const poolStatusToIcon = (status: PoolProcessingStatus, percents: any = null) => {
        const color = selected ? style.colorPrimary2 : style.colorPrimary3_40
        switch (status) {
            case PoolProcessingStatus.STATUS_SUCCESS:
                return <CheckCircle htmlColor={color} /*color='secondary'*/ />
            // break
            case PoolProcessingStatus.STATUS_FAILED:
                return <ReportProblemOutlined htmlColor={color} /*htmlColor={'#ff9235'} */ />
            // break
            case PoolProcessingStatus.STATUS_ARCHIVED:
                return <Inventory2Outlined htmlColor={color} />
            // break
            case PoolProcessingStatus.STATUS_ATTENTION_REQUIRED:
                return <RunningWithErrors htmlColor={color} /*htmlColor={style.colorSecondary2} */ />
                break
            default:
                // console.log('statusPercent', statusPercent, ' ms=', Date.now())
                return (<CustomCircularProgress
                    size={22}
                    thickness={6}
                    color={color}
                    /*color='RGB(78,195,224)'*/
                    sx={{ mt: '4px' }}
                    value={percents}
                />)
            // break
        }
    }

    const poolHumanReadableStatus = (pool) => {
        if (pool?.statusString != null)
            return pool?.statusString

        if (pool?.status) {
            return t(humanReadableStatuses.get(pool?.status))
        }

        return ''
    }

    const mouseOverEvent = (e) => {
        if (!e?.relatedTarget?.contains(anchorEl)) setAnchorEl(e.currentTarget)
    }

    // if(poolStateData == null) return <></>
    const mouseOutEvent = (e) => {
        // console.log('e', e?.relatedTarget?.contains(anchorEl))
        // if(e?.relatedTarget?.contains(anchorEl))
        setAnchorEl(null)
    }

    const editNameHandler = (e) => {
        if (!(permissions?.isAdmin || permissions?.isManager))
            return

        e.stopPropagation()
        if (selected) {
            setEditNameNodeId(node?.id)
            setNodeName(node?.name)
            // console.log('node', node)
        }
    }

    const saveEditName = () => {
        actionSecuritization.updateNode({ id: node.id, name: { value: nodeName } })(dispatch)

        const updatePoolNodes = deal?.deal?.poolNodes
        const index = updatePoolNodes?.findIndex(poolNode => poolNode.id == node.id)
        if (index != -1) {
            updatePoolNodes[index].name = nodeName
            actionSecuritization.setDealField({ poolNodes: updatePoolNodes })(dispatch)
            console.log('updatePoolNodes', updatePoolNodes)
        }
        // actionSecuritization.setNodeDataParametr({ name: 'name', value: nodeName })(dispatch)
        setEditNameNodeId(null)
    }

    const cancelEditName = () => {
        setEditNameNodeId(null)
    }

    return (
        <>
            {editNameNodeId &&
                <Modal open={Boolean(editNameNodeId != null)} onClose={cancelEditName}>
                    <Box className={classes.rootModal}>
                        <Paper className={classes.modalPaper}>
                            <TextField
                                label={t('Securitization_NodeName')}
                                value={nodeName}
                                onChange={(e) => setNodeName(e.currentTarget.value)}
                            />
                            <Box className={classes.modalButtonBox}>
                                <Button onClick={saveEditName} color='secondary'>{t('Save')}</Button>
                                <Button onClick={cancelEditName}>{t('Cancel')}</Button>
                            </Box>
                        </Paper>
                    </Box>
                </Modal>
            }
            <Box className={classes.poolCell}
                onMouseOver={mouseOverEvent}
                onMouseOut={mouseOutEvent}
            >
                {/* {console.log('anchorEl', Boolean(anchorEl))} */}
                {/* {console.log('poolStateData', poolStateData)} */}
                {Boolean(anchorEl) && !template && poolStateData?.status == PoolProcessingStatus.STATUS_SUCCESS &&
                    <ServiceMenu
                        key={`pool${poolStateData?.id}`}
                        poolId={poolStateData?.id}
                        pool={{ ...poolStateData, nodeType: type }}
                        node={node}
                        anchor={anchorEl}
                        mouseOutEvent={mouseOutEvent}
                    />}
                <Button className={[
                    // classes.nodePoolBox,
                    getTypeStyle(),
                    selected ? 'selected' : '',
                    template ? 'template' : '',
                ].join(' ')}
                    classes={{ root: classes.nodePoolBox }}
                    // onClick={() => openPool(node?.pools[0]?.id)}
                    onClick={clickEvent}
                >
                    <Box className={classes.nodePoolRoot}>
                        {/* <Box className={classes.nodeTitleRowContainer}>
                        <Box className={classes.nodeName}>{name}</Box>
                        <Box className={classes.nodeIcon}>{poolStateData ? poolStatusToIcon(poolStateData.status, poolStateData.percents) : ''}</Box>
                    </Box> */}
                        {template &&
                            <Tooltip
                                title={getNodeTooltip(type)}
                                placement='left-start'
                                disableInteractive={true}
                            >
                                <Box className={classes.addIconBox}>
                                    <CloudUpload className={classes.addIcon} />
                                </Box>
                            </Tooltip>
                        }
                        {poolStateData && poolStateData?.status !== PoolProcessingStatus.STATUS_SUCCESS &&
                            <Box className={classes.nodeIcon}>{poolStateData ? poolStatusToIcon(poolStateData.status, poolStateData.percents) : ''}</Box>
                        }
                        <Box className={classes.nodeInfoRowContainer}>
                            <Box className={classes.nodeTypeInfo}>
                                {t(`Node_${getTypeStyle()}`)}
                            </Box>
                            {/* {console.log('poolStateData', poolStateData)} */}
                            <Box className={classes.nodeInfoDateSlice}>
                                {poolStateData?.status == PoolProcessingStatus.STATUS_SUCCESS &&
                                    Localization.FormatDateStringLocale(poolStateData?.poolDate)
                                }
                            </Box>
                        </Box>
                        <Box className={classes.nodeInfoRowContainer2}>
                            {poolStateData?.status !== PoolProcessingStatus.STATUS_SUCCESS ?
                                <Box className={classes.nodePoolStatus}>
                                    {/* {console.log('poolStateData----------------------', poolStateData, poolData)} */}
                                    {poolHumanReadableStatus(poolStateData)}
                                </Box>
                                : <>

                                    {poolStateData?.currentMainDebt != null &&
                                        <Box className={classes.nodeInfoValue}>
                                            <Box className="value">{Localization.FormatNumber((poolStateData?.currentMainDebt ?? 0) / 1000000000.0, 1, 1)}
                                            </Box>
                                            <Box className="unit">{t("Billions")}</Box>
                                        </Box>
                                    }
                                    {
                                        poolStateData?.currentWAC != null &&
                                        <Box className={classes.nodeInfoWAC}>
                                            <Box className="value">{Localization.FormatNumberString(poolStateData?.currentWAC, 2, 2)}</Box>
                                            <Box className="unit">{t("percent")}</Box>
                                        </Box>
                                    }
                                </>}
                        </Box>
                        {/* {console.log('node!!!!!!!!!!!!!!!!!!!!!!!!!!',poolData)} */}

                        {!Boolean(template) &&
                            <Box className={[classes.nodePoolName, selected ? classes.nodePoolNameIsEditable : ''].join(' ')} onClickCapture={selected ? editNameHandler : null}>{
                                // poolStateData ?
                                node?.name ? node?.name
                                    : `${t('NodeCardPool')} ${poolData?.id}`
                                // :
                                // ''
                            }
                            </Box>}
                    </Box>
                </Button >
            </Box >
        </>
    )
}

export default PoolNodeCard