import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as AdministrationStore from "../../../store/AdministrationStore";
import { withRouter } from "../../../components/shared/CustomRouter";
import { Container } from '@mui/material';

interface IState {
}

interface IProps {
}

class DataSourcesPage extends React.Component<IProps, IState> {

    constructor(props) {
        super(props);
    }

    render() {

        return (
            <Container className="h-100 pt-2">
                Источники данных
            </Container>
        );
    }
}

var component = connect(
    (state: any) => state.administration, // Selects which state properties are merged into the component's props
    AdministrationStore.actionCreators // Selects which action creators are merged into the component's props
)(DataSourcesPage);

export default withRouter(component);