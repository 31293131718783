import Localization from '@Components/Localization';
import { CouponType, SwapType } from "@Components/Definitions";

//CHECKME!!! arrays forms before localization load

/*
const couponDataSource = {
        items: [
            //{ key: null, value: t('Undefined') },
            { key: CouponType.TYPE_FIXED, value: Localization.Translate('FixedCoupon') },
            { key: CouponType.TYPE_FLOAT, value: Localization.Translate('FloatCoupon') },
            { key: CouponType.TYPE_KEYRATE_AND_PREMIUM, value: Localization.Translate('KeyRateAndPremium') }
            //{ key: CouponType.TYPE_AHML, value: Localization.Translate('AHMLCoupon') },
            //{ key: CouponType.TYPE_THIRD, value: Localization.Translate('ThirdCoupon') },
            //{ key: CouponType.TYPE_LOSSES, value: Localization.Translate('LossesCoupon') }
        ],
        count: 3
    };

const swapDataSource = {
    items: [
        { key: SwapType.TYPE_UNKNOWN, value: Localization.Translate('Unknown') },
        { key: SwapType.TYPE_NETTING_BEFORE_COUPON_PAYMENT, value: Localization.Translate('NettingBeforeCouponPayment') },
        { key: SwapType.TYPE_NETTING_AFTER_COUPON_PAYMENT, value: Localization.Translate('NettingAfterCouponPayment') }
    ],
    count: 3
};
*/
export function getCouponTypes() {

    return {
        items: [
            //{ key: null, value: t('Undefined') },
            { key: CouponType.TYPE_FIXED, value: Localization.Translate('securitization.CouponTypeFixedShort') },
            { key: CouponType.TYPE_FLOAT, value: Localization.Translate('securitization.CouponTypeFloatingShort') },
            { key: CouponType.TYPE_KEYRATE_AND_PREMIUM, value: Localization.Translate('securitization.CouponTypeStrKeyrateAndPremiumShort') }
            //{ key: CouponType.TYPE_AHML, value: Localization.Translate('AHMLCoupon') },
            //{ key: CouponType.TYPE_THIRD, value: Localization.Translate('ThirdCoupon') },
            //{ key: CouponType.TYPE_LOSSES, value: Localization.Translate('LossesCoupon') }
        ],
        count: 3
    }
};

export function getSwapTypes() {
    return {
        items: [
            //{ key: SwapType.TYPE_UNKNOWN, value: Localization.Translate('Unknown') },
            { key: SwapType.TYPE_NETTING_BEFORE_COUPON_PAYMENT, value: Localization.Translate('NettingBeforeCouponPayment') },
            { key: SwapType.TYPE_NETTING_AFTER_COUPON_PAYMENT, value: Localization.Translate('NettingAfterCouponPayment') }
        ],
        count: 3
    }
}