import { useState, useEffect, useRef } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Checkbox from '@mui/material/Checkbox';
import { Paper, Box } from '@mui/material';
import Button, { ButtonProps } from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';

import { Container } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next'
import { createStyles, makeStyles } from '@mui/styles'

import { Theme } from '@mui/material/styles';

import { isPermissionGranted, setPermissionState } from '../../Utilities';


import {
    DataGridPremium,
    GridColDef,
    DataGridPremiumProps,
    GridRenderCellParams,
    GridRowsProp,
    GridActionsCellItem,
    GridColumnGroupingModel,
    GridComparatorFn,
    GridValueFormatterParams,
    useGridApiRef,
    useKeepGroupedColumnsHidden,
    useGridApiContext,
    useGridSelector,
    gridFilteredDescendantCountLookupSelector,
    GridValueGetterParams,

    ruRU,
    enUS
} from '@mui/x-data-grid-premium';

const useStyles = makeStyles((theme: Theme) => createStyles({

    gridContainer: {
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1 auto',
        height: '100%',
        overflowY: 'auto'
    },
    listContainer: {
        display: 'flex',
        flexDirection: 'column'
    }
}));

interface IProps {

    isReadOnly?: boolean;
    isDisabledRoles?: boolean;
    isDisabledUser?: boolean;
    showUserPermissions?: boolean;
    permissions: any[];
    rolePermissions: string;
    userPermissions: string;

    onPermissionsChanged: (type: string, newPermissions: string) => void;
}

interface Row {
    hierarchy: string[];
    name: string;
    description: string;
    id: number;
    enumId: number;
    rolePermission: boolean | null;
    userPermission: boolean | null;
}

interface BooleanCellProps extends GridRenderCellParams {
    onPermissionChange: (event: React.ChangeEvent<HTMLInputElement>, permission: string, enumId: number) => void;
    permissions: string;
    isDisabled: boolean;
}

function getTreeDataPath(row) {
    return row.hierarchy;
}

const PermissionsTreeComponent = ({
    isReadOnly,
    isDisabledRoles,
    isDisabledUser,
    showUserPermissions,
    permissions,
    rolePermissions,
    userPermissions,

    onPermissionsChanged,
}: IProps) => {

    const classes = useStyles();
    const { i18n, t } = useTranslation()

    const [locale, setLocale] = useState(ruRU);
    const [expandedPermissionsGroup, setExpandedPermissionsGroup] = useState(new Set<number>());
    const [items, setItems] = useState<GridRowsProp<Row>>([]);
    //const [groups, setGroups] = useState({});

    useEffect(() => {
        if (i18n.language == 'ru') {
            setLocale(ruRU);
        }
        else {
            setLocale(enUS);
        }

    }, [i18n]);

    useEffect(() => {

        var permResult: GridRowsProp<Row> = [];

        if (permissions?.length != 0) {
            permissions[0].items.forEach(x => flatternPermissions(x, permResult));
        }

        setItems(permResult);

    }, [permissions]);

    var columns: GridColDef[] = [
        {
            field: 'rolePermission',
            headerName: t('Role'),
            sortable: false,
            groupable: false,
            filterable: false,
            width: 60,
            renderCell: (params) => <BooleanCell permissions={rolePermissions} onPermissionChange={handleRolePermissionChange} isDisabled={!!isDisabledRoles || !!isReadOnly} {...params} />
        }
    ];

    if (showUserPermissions) {
        columns.push(
            {
                field: 'userPermission',
                headerName: t('User'),
                sortable: false,
                groupable: false,
                filterable: false,
                width: 100,
                renderCell: (params) => <BooleanCell permissions={userPermissions} onPermissionChange={handleUserPermissionChange} isDisabled={!!isDisabledUser || !!isReadOnly} {...params} />
            }
        )
    }

    columns.push(
        {
            field: 'description',
            headerName: t('Description'),
            sortable: false,
            groupable: false,
            filterable: false,
            width: 220,
            //valueGetter: (params: GridValueGetterParams) => {
            //    //if (params.rowNode.type == 'group') {
            //    //    return 'group!';
            //    //}
            //
            //    return params.row.description;
            //}
        });

    const flatternPermissions = (perm, outArray, path = []) => {

        var innerPath = [...path];

        innerPath.push(perm.name);

        if (perm.isGroup) {


            outArray.push(
                {
                    hierarchy: innerPath,
                    name: perm.name,
                    description: perm.description ?? '',
                    id: 100 + perm.elementID, //to be unique through all elements
                    enumId: perm.enumID,
                });
        }

        if (perm.items != null && perm.items.length != 0) {
            perm.items.forEach(x => flatternPermissions(x, outArray, innerPath));
            return;
        }

        outArray.push(
            {
                hierarchy: innerPath,
                name: perm.name,
                description: perm.description ?? '',
                id: perm.elementID,
                enumId: perm.enumID,
            });
    }


    const handlePermissionGroupChange = (id) => {
        if (expandedPermissionsGroup.has(id))
            expandedPermissionsGroup.delete(id)
        else
            expandedPermissionsGroup.add(id)

        setExpandedPermissionsGroup(new Set<number>(expandedPermissionsGroup));
    }

    const handleRolePermissionChange = (event: React.ChangeEvent<HTMLInputElement>, permissions: string, enumId: number) => {
        var newPermissions = setPermissionState(enumId, permissions, event.target.checked);
        onPermissionsChanged("role", newPermissions);
    }

    const handleUserPermissionChange = (event: React.ChangeEvent<HTMLInputElement>, permissions: string, enumId: number) => {
        var newPermissions = setPermissionState(enumId, permissions, event.target.checked);
        onPermissionsChanged("user", newPermissions);
    }

    const BooleanCell = (props: BooleanCellProps) => {
        const { onPermissionChange, permissions, isDisabled, rowNode, row } = props;

        if (rowNode.type == 'group') {
            //CHECKME!!! add checkbox to set all descendants
            //return (
            //    <Checkbox
            //        key={row.id + '_group_item'}
            //        disabled={isDisabled}
            //        checked={isPermissionGranted(row.enumId, permissions)}
            //        onChange={(event) => onPermissionChange(event, permissions, row.enumId)}
            //    />);
            return null;
        }

        return (
            <Checkbox
                key={row.id + '_item'}
                disabled={isDisabled}
                checked={isPermissionGranted(row.enumId, permissions)}
                onChange={(event) => onPermissionChange(event, permissions, row.enumId)}
            />
        );
    }

    const groupingColDef: DataGridPremiumProps['groupingColDef'] = {
        headerName: t('Section'),
        width: 210
    };

    return (
        <Paper className={classes.gridContainer}>
            <DataGridPremium
                treeData
                disableColumnMenu
                disableColumnFilter
                disableRowSelectionOnClick
                hideFooter
                columnHeaderHeight={30}
                rowHeight={25}
                localeText={locale.components.MuiDataGrid.defaultProps.localeText}
                rows={items}
                columns={columns}
                getTreeDataPath={getTreeDataPath}
                groupingColDef={groupingColDef}
            />
        </Paper>
    );
}

export default PermissionsTreeComponent;