import React, { useState, useEffect, useRef, useCallback, useLayoutEffect } from 'react';
import { actionCreators } from "@Store/KeyRateStore";
import { useDispatch, useSelector } from "react-redux";

import { makeStyles } from '@mui/styles';
import { useTranslation } from "react-i18next";

import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';

import Localization from '@Components/Localization';

import Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
// import HC_exporting_data from 'highcharts/modules/export-data';
import HighchartsReact from 'highcharts-react-official';
import HC_more from "highcharts/highcharts-more"; //module
import HC_draggable_points from "highcharts/modules/draggable-points";

import { setHighchartsLocalization, copyTextToClipboard, toUnixDate, toUnixDateGMT, hexToRgbA } from '@Utilities';
import style from '@Styles/domrf-theme.module.scss';
// import TooltipInfoLabel from '@Components/shared/TooltipInfoLabel';

import { addMonths, startOfMonth } from 'date-fns'
// import { useResizeDetector } from 'react-resize-detector';
import { Box, debounce, Divider, Typography, Stack, alpha } from '@mui/material';
import { BackspaceOutlined, HelpOutline } from '@mui/icons-material';
import TooltipInfoLabel from '../../components/shared/TooltipInfoLabel';
import PointsEditorComponent from './PointsEditorComponent';

const useStyles = makeStyles({

    controlsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'start',
        paddingBottom: '1rem'
    },
    editToolbar: {
        position: 'absolute',
        top: '0.1rem',
        right: '1.7rem',
        display: 'inline-flex',
        flexDirection: 'row'
    },
    buttonsContainer:
    {
        display: 'flex',
        flexDirection: 'row',
        //width: '100%',
        justifyContent: 'flex-end',
        padding: '1rem'
    },
    checkbox: {
        marginBottom: '0',
        '& .MuiCheckbox-root': {
            padding: '0'
        }
    },
    warningContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'start',
        paddingLeft: '1rem',
        paddingRight: '1rem',
        color: style.colorError
        //backgroundColor: 'rgba(255,165,0, 0.6)'
    }
});

const chartsColours = [
    style.colorPrimary1,
    style.colorPrimary2,
    style.colorPrimary3,

    style.colorPrimary1_80,
    style.colorPrimary2_80,
];

type KeyRateProps = {

    onHidePanel?: () => void;
    onLoaded?: (data: any) => void;
    onApply: (data: any) => void;
    evaluationDate: Date;
    //zcycDate: Date;
    keyrateChartData: any[];
    isEditable: boolean;
    isTrackChanges: boolean;
}

const KeyRatePanel = ({

    onHidePanel,
    onLoaded,
    onApply,
    evaluationDate,
    //zcycDate,
    keyrateChartData,
    isEditable,
    isTrackChanges

}: KeyRateProps) => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const keyrateChart = useRef(null);

    const dispatch = useDispatch();
    const keyrateStore = useSelector((state: any) => state.keyrate);

    const [minEditDate, setMinEditDate] = useState(null);
    const [maxEditDate, setMaxEditDate] = useState(null);
    const [minEditValue, setMinEditValue] = useState(0);
    const [maxEditValue, setMaxEditValue] = useState(20);
    const [isChartChanged, setIsChartChanged] = useState(false);
    const [dates, setDates] = useState([]);
    const [actionIndex, setActionIndex] = useState(-1);
    const [isInEditMode, setIsInEditMode] = useState(isEditable);
    const [isEditChartWasCorrected, setIsEditChartWasCorrected] = useState(false);
    const [pointsData, setPointsData] = useState([]);

    const binding = useRef({
        isInEditMode,
        minEditDate,
        maxEditDate,
        minEditValue,
        maxEditValue,
        actionBuffer: [], //массив заданных точек
        actionIndex
    });

    const [keyrateChartOptions, setKeyrateChartOptions] = useState({

        chart: {
            plotAreaHeight: 300,
            zoomType: undefined,
            plotBorderWidth: 1,
            animation: false,
            events: {

                render: function (e) {
                    setTimeout(onResize, 0);
                },
                click: function (e) {

                    const { isInEditMode, minEditDate, maxEditDate, minEditValue, maxEditValue } = binding.current;

                    if (!isInEditMode)
                        return;

                    if (minEditDate == null || maxEditDate == null)
                        return;

                    var chart = keyrateChart.current.chart;

                    var xValue = Math.round(e.xAxis[0].value);
                    var yValue = e.yAxis[0].value;

                    var { x, y } = snapToGrid(xValue, yValue);

                    if (x < minEditDate || x > maxEditDate)
                        return;

                    if (y < minEditValue || y > maxEditValue)
                        return;

                    if (chart.series[2].data.findIndex(item => item.x == x) != -1)
                        return; //point already have

                    // Add it
                    chart.series[2].addPoint({ x, y }, true, false, false, false);

                    updateOptions();

                    addAction({ type: 'addPoint', x: x, y: y });
                }
            }

        },

        exporting:
        {
            enabled: false
        },

        plotOptions: {
            series: {
                //stickyTracking: false,

                point: {
                    events: {
                        click: function (e) {

                            if (!isInEditMode)
                                return

                            var chart = keyrateChart.current.chart;
                            if (chart.series[2].data.length > 2) {

                                if (e.point.index == 0 || e.point.index == chart.series[2].data.length - 1)
                                    return;

                                var stats = { type: 'removePoint', x: e.point.x, y: e.point.y };

                                e.point.remove();

                                updateOptions();

                                addAction(stats);
                            }
                        }
                    }
                }
            }
        },

        /*
        exporting: {
            buttons: {
                contextButton: {
                    menuItems: [
                        {
                            textKey: 'printChart',
                            onclick: function () { this.print(); }
                        },
                        {
                            separator: true
                        },
                        {
                            textKey: 'downloadPNG',
                            onclick: function () { this.exportChart(); }
                        },
                        {
                            textKey: 'downloadJPEG',
                            onclick: function () { this.exportChart({ type: 'image/jpeg' }); }
                        }
                    ]
                }
            }
        },
        */

        title: {
            text: ''//t('BondsYield'),
            //style: { "color": `"${style.colorPrimary1}"`, "fontSize": "18px", "fontWeight": "bold" }
        },

        credits: {
            enabled: false
        },

        tooltip: {

            enabled: true,
            //followPointer: false,
            //followTouchMove: false,

            formatter: function () {

                if (this.points != null) {
                    return '<table style="min-width:150px;max-width:600px;font-size: 16px"><tr><td>' + Localization.Translate('Date') + '</td>' +
                        '<td style="text-align:end">' +

                        this.points.reduce(function (s, point) {
                            return s + '<tr style="color:' + point.series.color + '"><td style="padding:0">' + point.series.name + ': </td>' +
                                '<td style="padding:0;text-align:end"><b>' + ((point.series.index == 2) ? Localization.FormatNumberString(point.y) : Localization.FormatNumberString(point.y)) + '</b></td></tr>'
                        }, '<b>' + Localization.FormatDateLocale(new Date(this.x)) + '</b>') +

                        '</td > ' +
                        '</tr>' +
                        '</table>';
                }
                else {
                    return '<table style="min-width:150px;max-width:600px;font-size: 16px"><tr><td>' + Localization.Translate('Date') + '</td>' +
                        '<td style="text-align:end">' + '<b>' + Localization.FormatDateLocale(new Date(this.x)) + '</b>' +
                        '<tr style="color:' + this.series.color + '"><td style="padding:0">' + this.series.name + ': </td>' +
                        '<td style="padding:0;text-align:end"><b>' + ((this.series.index == 2) ? Localization.FormatNumberString(this.y) : Localization.FormatNumberString(this.y)) + '</b></td></tr>' +
                        '</td > ' +
                        '</tr>' +
                        '</table>';
                }
            },

            hideDelay: 0,
            shared: false,
            useHTML: true
        },



        xAxis: {
            type: 'datetime',
            title: t('Years'),
            //crosshair: { snap: false },
            plotBands: [], // dynamically aded
            labels: {
                style: {
                    fontSize: '14px',
                }
            },

            gridLineWidth: 1,
            gridZIndex: 1,
            lineWidth: 1,
            lineColor: style.colorPrimary3_20,
        },

        yAxis: [{ // Primary yAxis
            title: {
                text: t('KeyRatePA'),
                style: {
                    fontSize: '14px',
                    color: style.colorPrimary1
                }
            },
            labels: {
                style: {
                    fontSize: '14px',
                    color: style.colorPrimary1
                }
            },
            // tickAmount: 5,
            minPadding: 0,
            maxPadding: 0,
            gridZIndex: 1,
            alignTicks: false,
            tickInterval: 3,
        },
        { // Secondary yAxis
            opposite: true,
            title: {
                text: t('KeyRatePA'),
                style: {
                    fontSize: '14px',
                    color: style.colorPrimary2
                }
            },
            labels: {
                style: {
                    fontSize: '14px',
                    color: style.colorPrimary2
                }
            },

            // tickAmount: 5,
            // minPadding: 0,
            // maxPadding: 0,
            // softMin:3,
            // softMax:23,
            alignTicks: false,
            gridZIndex: 1,
            visible: true,
            tickInterval: 3,
        }],

        series: [
            {
                name: t('HistoricalKeyRate'),
                yAxis: 0,
                //type: 'spline',
                showInLegend: false,
                data: null,
                color: style.colorPrimary1,
                //stickyTracking: false,
                zIndex: 3,
                marker: {
                    enabled: false,
                    symbol: 'circle'
                },
            },
            {
                name: t('ModelKeyRate'),
                yAxis: 0,
                //type: 'spline',
                showInLegend: false,
                visible: false,
                data: null,
                color: style.colorPrimary1,
                //stickyTracking: false,
                zIndex: 3,
                marker: {
                    enabled: false,
                    symbol: 'circle'
                },
                tooltip: {
                    enabled: true
                }
            },
            {
                id: 'editKeyRate',
                yAxis: 1,
                name: t('EditKeyRate'),
                type: 'line',
                showInLegend: false,
                visible: true,
                data: null,
                color: style.colorPrimary2,
                //stickyTracking: false,
                zIndex: 5,
                marker: {
                    enabled: true,
                    radius: 7,
                    fillColor: 'white',
                    lineWidth: 2,
                    lineColor: style.colorPrimary2
                },
                dragDrop: {
                    draggableX: true,
                    draggableY: true
                },
                point: {
                    events: {

                        dragStart: function (e) {

                            const { isInEditMode } = binding.current;

                            //console.log('---dragStart-->');

                            if (!isInEditMode)
                                return false;

                            keyrateChart.current.chart.series[0].update({ enableMouseTracking: false });

                            return true;
                        },

                        drag: function (e) {

                            const { isInEditMode, minEditDate, maxEditDate, minEditValue, maxEditValue } = binding.current;

                            if (!isInEditMode)
                                return false;

                            if (e.newPoint) {

                                if (e.newPoint.x < minEditDate)
                                    e.newPoint.x = minEditDate;
                                if (e.newPoint.x > maxEditDate)
                                    e.newPoint.x = maxEditDate;

                                if (e.newPoint.y < minEditValue)
                                    e.newPoint.y = minEditValue;
                                if (e.newPoint.y > maxEditValue)
                                    e.newPoint.y = maxEditValue;

                                var xValue = null;

                                var series = keyrateChart.current.chart.series[2];
                                var dragPointIndex = e.target.index;
                                if (dragPointIndex == 0) //first || last
                                {
                                    xValue = minEditDate;
                                }
                                else if (series.data.length == dragPointIndex + 1) {
                                    xValue = maxEditDate;
                                }
                                else {


                                    //all others
                                    var leftPoint = series.data[dragPointIndex - 1];
                                    var rightPoint = series.data[dragPointIndex + 1];

                                    var dateLeft = new Date(leftPoint.x);
                                    dateLeft = addMonths(dateLeft, 1);
                                    dateLeft = toUnixDate(dateLeft);

                                    var dateRight = new Date(rightPoint.x);
                                    dateRight = addMonths(dateRight, -1);
                                    dateRight = toUnixDate(dateRight);

                                    xValue = Math.round(e.newPoint.x);

                                    if (dateLeft >= xValue) {
                                        xValue = dateLeft;
                                    }
                                    if (dateRight <= xValue) {
                                        xValue = dateRight;
                                    }
                                }
                                //try to update points dynamically
                                var { x, y } = snapToGrid(xValue, e.newPoint.y);

                                //console.log(`---drag--> x: ${x}, y: ${y}`);

                                e.newPoint.x = x;
                                e.newPoint.y = y;

                                return true;
                            }

                        },

                        drop: function (e) {

                            const { isInEditMode } = binding.current;

                            if (!isInEditMode)
                                return false;

                            if (e.newPoint) {

                                var point = e.origin.points[e.newPointId];

                                //console.log(`---drop--> x: ${e.newPoint.x}, y: ${e.newPoint.y}`);

                                //console.log('---drop--> chardOptsdata: ', keyrateChartOptions.series[2].data, 'chartData: ', this.series.yData);
                                updateOptions();
                                //causes point reset
                                addAction({ type: 'movePoint', x: e.newPoint.x, y: e.newPoint.y, prevX: point.x, prevY: point.y });
                            }

                            keyrateChart.current.chart.series[0].update({ enableMouseTracking: true });

                            return true;
                        }
                    }
                }
            }
        ]
    });

    HC_exporting(Highcharts);
    HC_more(Highcharts);
    HC_draggable_points(Highcharts);

    setHighchartsLocalization(Highcharts, t);

    const onResize = () => {
        if (keyrateChart.current != null) {
            keyrateChart.current.chart.reflow();
            //console.log('------------resize')
        }
    };

    useEffect(() => {
        Localization.onLanguageChanged(onLanguageChanged);

        actionCreators.getKeyrates(evaluationDate != null ? Localization.FormatDateISO(evaluationDate) : null/*, Localization.FormatDateISO(zcycDate)*/)(dispatch);

        return () => {
            Localization.offLanguageChanged(onLanguageChanged);
        };

    }, [])
    
    //useLayoutEffect(() => {
    //    setTimeout(onResize, 1000);
    //}, [])
    
    useEffect(() => {
        //if (/*zcycDate != null && */evaluationDate != null) {
        actionCreators.getKeyrates(evaluationDate != null ? Localization.FormatDateISO(evaluationDate) : null/*, Localization.FormatDateISO(zcycDate)*/)(dispatch);
        //}
    }, [/*zcycDate,*/ evaluationDate])

    useEffect(() => {
        setIsInEditMode(isEditable);
    }, [isEditable]);

    useEffect(() => {
        const { keyrate } = keyrateStore;

        if (keyrate == null)
            return;

        var newKeyrateChartOptions = { ...keyrateChartOptions };

        //console.log('keyrateStore => newKeyrateChartOptions.series[2].data', newKeyrateChartOptions.series[2].data)

        if (keyrate?.historical?.length)
            newKeyrateChartOptions.series[0].data = keyrate.historical.map((item, index) => {
                return { x: toUnixDate(new Date(item.x)), y: item.y };
            });
        // console.log('first',newKeyrateChartOptions, keyrate)
        updateEditChart(newKeyrateChartOptions, keyrate);
        // console.log('first1')
        setIsInEditMode(isEditable);
        setKeyrateChartOptions(newKeyrateChartOptions);

        if (onLoaded != null) {
            onLoaded(makeRatesData(true));
        }

    }, [keyrateStore]);

    useEffect(() => {

        const { keyrate } = keyrateStore;

        var newKeyrateChartOptions = { ...keyrateChartOptions };

        //console.log('keyrateChartData newKeyrateChartOptions.series[2].data', newKeyrateChartOptions.series[2].data)

        if (keyrate?.historical?.length) {
            newKeyrateChartOptions.series[0].data = keyrate.historical.map((item, index) => {
                return { x: toUnixDate(new Date(item.x)), y: item.y };
            });
        }

        updateEditChart(newKeyrateChartOptions, keyrate, true);

    }, [keyrateChartData]);

    useEffect(() => {

        binding.current.isInEditMode = isInEditMode;
        binding.current.minEditDate = minEditDate;
        binding.current.maxEditDate = maxEditDate;
        binding.current.minEditValue = minEditValue;
        binding.current.maxEditValue = maxEditValue;
        binding.current.actionIndex = actionIndex;

    }, [isInEditMode, minEditDate, maxEditDate, minEditValue, maxEditValue, actionIndex]);
    ////////////////////////////////////////////////////////////////////////
    const snapToGrid = (xValue, yValue) => {

        var date = new Date(xValue);
        if (date.getDate() >= 15) {
            date = new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0);
            date = addMonths(date, 1);
        } else {
            date = new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0);
        }

        var x = toUnixDate(date);

        /*
        var data = dates;

        var rightIndex = data.findIndex(item => item >= xValue);
        var leftIndex = rightIndex != -1 && rightIndex > 1 ? rightIndex-- : -1;

        var x = xValue;
        var y = yValue;
        if (leftIndex != -1 && rightIndex != -1) {
            //get closest
            var dx1 = Math.abs(data[leftIndex] - xValue);
            var dx2 = Math.abs(data[rightIndex] - xValue);
            x = dx1 <= dx2 ? data[leftIndex] : data[rightIndex];
        }
        else if (leftIndex != -1) {
            x = data[leftIndex];
        }
        else if (rightIndex != -1) {
            x = data[rightIndex];
        }
        */

        var y = Math.round(yValue * 10) / 10;
        ////////////////////////////////////////////////////////////////////////
        //console.log(`x:${new Date(x).toISOString()} y:${y}`);

        return { x: x, y: y };
    }

    const onLanguageChanged = (lng: any) => {

        setHighchartsLocalization(Highcharts, t);
        updateHighchartsOptions();
    }

    const updateOptions = () => {

        var series = keyrateChart.current?.chart?.series[2];

        if (series == null)
            return;

        var data = [];

        for (var i = 0; i < series.xData.length; i++) {
            data.push({ x: series.xData[i], y: series.yData[i] });
        }


        keyrateChartOptions.series[2].data = data;

        setPointsData(data);
    }

    const updateHighchartsOptions = () => {

        var newKeyrateChartOptions = { ...keyrateChartOptions };

        //console.log("updateHighchartsOptions===>")

        newKeyrateChartOptions.tooltip.formatter = function () {

            return '<table style="min-width:150px;max-width:600px;font-size: 16px"><tr><td>' + Localization.Translate('Date') + '</td>' +
                '<td style="text-align:end">' +
                //при смене языка вылезает баг =>  this.points == undefined
                this.points?.reduce(function (s, point) {
                    return s + '<tr style="color:' + point.series.color + '"><td style="padding:0">' + point.series.name + ': </td>' +
                        '<td style="padding:0;text-align:end"><b>' + ((point.series.index == 2) ? Localization.FormatNumberString(point.y) : Localization.FormatNumberString(point.y)) + '</b></td></tr>'
                }, '<b>' + Localization.FormatDateLocale(new Date(this.x)) + '</b>') +

                '</td > ' +
                '</tr>' +
                '</table>';
        };

        newKeyrateChartOptions.xAxis.title = t('Years');
        newKeyrateChartOptions.yAxis[0].title.text = t('KeyRatePA');
        newKeyrateChartOptions.yAxis[1].title.text = t('KeyRatePA');

        newKeyrateChartOptions.series[0].name = t('HistoricalKeyRate');
        newKeyrateChartOptions.series[1].name = t('ModelKeyRate');
        newKeyrateChartOptions.series[2].name = t('EditKeyRate');

        setKeyrateChartOptions(newKeyrateChartOptions);
    }

    const addAction = (action: any) => {

        const { actionBuffer, actionIndex } = binding.current;

        if (actionIndex != -1 && actionIndex != actionBuffer.length - 1) {

            actionBuffer.splice(actionIndex + 1, actionBuffer.length - actionIndex - 1);
        }

        actionBuffer.push(action);

        setActionIndex(actionIndex + 1);
        setIsChartChanged(true);
    }

    const onUndoClick = (e) => {

        const { actionBuffer, actionIndex } = binding.current;

        if (actionBuffer.length == 0 || actionIndex == -1)
            return;

        var action = actionBuffer[actionIndex];

        //console.log(`undo: type ${action.type}, index: ${actionIndex}`);

        switch (action.type) {
            case 'addPoint':
                {
                    var removeData = keyrateChartOptions.series[2].data.filter(item => item.x != action.x);

                    keyrateChartOptions.series[2].data = removeData;
                    keyrateChart.current.chart.series[2].setData(removeData);

                    setPointsData(removeData);
                    setIsChartChanged(true);
                    setActionIndex(actionIndex - 1);
                }
                break;
            case 'removePoint':
                {
                    keyrateChartOptions.series[2].data.push({ x: action.x, y: action.y });
                    var addData = [...keyrateChartOptions.series[2].data];
                    keyrateChart.current.chart.series[2].setData(addData);

                    setPointsData(addData);
                    setIsChartChanged(true);
                    setActionIndex(actionIndex - 1);
                }
                break;
            case 'movePoint':
                {
                    setIsChartChanged(true);
                    setActionIndex(actionIndex - 1);

                    var data = [...keyrateChartOptions.series[2].data];
                    var index = data.findIndex(item => item.x == action.x);
                    var point = data[index];
                    point.x = action.prevX;
                    point.y = action.prevY;

                    keyrateChartOptions.series[2].data = data;
                    keyrateChart.current.chart.series[2].setData(data);

                    setPointsData(data);
                }
                break;
            default: {
                throw 'Undo: unknown action type!';
            }
        }
    }

    const onRedoClick = (e) => {

        const { actionBuffer, actionIndex } = binding.current;

        if (actionBuffer.length == 0 || actionIndex == actionBuffer.length)
            return;

        var index = actionIndex + 1;
        var action = actionBuffer[index];

        //console.log(`redo: type ${action.type}, index: ${actionIndex}`);

        switch (action.type) {
            case 'addPoint':
                {
                    var addData = [...keyrateChartOptions.series[2].data, { x: action.x, y: action.y }];
                    keyrateChartOptions.series[2].data = addData;
                    keyrateChart.current.chart.series[2].setData(addData);

                    setPointsData(addData);
                    setIsChartChanged(true);
                    setActionIndex(index);
                }
                break;
            case 'removePoint':
                {

                    var removeData = keyrateChartOptions.series[2].data.filter(item => item.x != action.x);

                    keyrateChartOptions.series[2].data = removeData;
                    keyrateChart.current.chart.series[2].setData(removeData);

                    setPointsData(removeData);
                    setIsChartChanged(true);
                    setActionIndex(index);
                }
                break;
            case 'movePoint':
                {
                    setIsChartChanged(true);
                    setActionIndex(index);

                    var data = [...keyrateChartOptions.series[2].data];
                    var index = data.findIndex(item => item.x == action.prevX);
                    var point = data[index];
                    point.x = action.x;
                    point.y = action.y;

                    keyrateChartOptions.series[2].data = data;
                    keyrateChart.current.chart.series[2].setData(data);

                    setPointsData(data);
                }
                break;
            default: {
                throw 'Redo: unknown action type!';
            }
        }
    }

    const onClearClick = (e) => {
        /*
            var newKeyrateChartOptions = { ...keyrateChartOptions };
            updateEditChart(newKeyrateChartOptions);
        */
        var data = createEditChart(minEditDate, maxEditDate, keyrateChartOptions.series[0]);

        keyrateChartOptions.series[2].data = data;
        keyrateChart.current.chart.series[2].setData(data);
        setPointsData(data);

        setIsEditChartWasCorrected(false);
        binding.current.actionBuffer = [];
        setActionIndex(-1);
        setIsChartChanged(true);
    }

    const onApplyChangesClick = (e) => {

        if (onApply != null) {
            onApply(makeRatesData(isInEditMode));

            setIsEditChartWasCorrected(false);
            setIsChartChanged(false);
        }
    }

    const makeRatesData = (useEditData: boolean = true) => {

        //console.log('makeRatesData keyrateChartOptions?.series[2]?.data', keyrateChartOptions?.series[2]?.data)

        var histData = keyrateChartOptions.series[0].data;

        var model = null;
        var editModel = null;
        // console.log('keyrateChartOptions?.series[2]?.data', keyrateChartOptions?.series[2]?.data)
        if (useEditData && keyrateChartOptions?.series[2]?.data?.length) {
            var data = keyrateChartOptions.series[2].data;

            //var left = data[0];
            //var right = data[1];
            var index = 0;

            model = dates.map(item => {

                for (; index < data.length; index++) {
                    if (data[index].x > item) {
                        break;
                    }
                }

                var left = data[index - 1];
                var right = data[index];
                var y = left.y;

                if (right != null)
                    y = left.y + (right.y - left.y) / (right.x - left.x) * (item - left.x);

                return { x: Localization.FormatDateISO(new Date(item)), y: Math.round(y * 100.0) / 100.0 };
            });

            editModel = data?.map(item => {
                // console.log('item', item)
                return { x: Localization.FormatDateISO(new Date(item.x)), y: Math.round(item.y * 100.0) / 100.0 };
            });
        }
        /*
        else {
            model = keyrateChartOptions.series[1].data.map(item => {
                return { x: Localization.FormatDateISO(new Date(item.x)), y: Math.round(item.y * 100.0) / 100.0 };
            });
        }
        */

        var historical = histData?.length ?
            histData.map(item => {
                return { x: Localization.FormatDateISO(new Date(item.x)), y: Math.round(item.y * 100.0) / 100.0 };
            })
            :
            [];

        return { historical: historical, model: model, editModel: editModel };
    }

    const updateEditChart = (newKeyrateChartOptions, keyrate = null, updateChart = false) => {

        //console.log('updateEditChart, newKeyrateChartOptions', newKeyrateChartOptions.series[2])

        let minHistoryDate = null;
        let maxHistoryDate = null;
        let minEditDate = null;
        let maxEditDate = null;
        let historicalSeries = newKeyrateChartOptions.series[0];
        let series = newKeyrateChartOptions.series[2];
        let dates = [];

        if (keyrate?.historical != null && historicalSeries?.data?.length) {

            var xArr = historicalSeries.data.map(x => x.x);
            minHistoryDate = +Infinity; //Math.min(...xArr);
            maxHistoryDate = -Infinity; //Math.max(...xArr);

            xArr.forEach(item => {
                minHistoryDate = minHistoryDate < item ? minHistoryDate : item;
                maxHistoryDate = maxHistoryDate > item ? maxHistoryDate : item;
            });

            //dates = keyrate.model.map((item, index) => toUnixDate(new Date(item.x)));

            //var date = new Date(maxHistoryDate);
            minEditDate = toUnixDate(startOfMonth(maxHistoryDate)); // new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0);
            //var maxHistoryDateDate = new Date(maxHistoryDate);
            if (minEditDate <= maxHistoryDate) {
                minEditDate = maxHistoryDate;
                minEditDate = toUnixDateGMT(addMonths(minEditDate, 1));
            }

            maxEditDate = toUnixDateGMT(addMonths(minEditDate, 60)); // 120 - 10лет , 60 - 5 лет
            //minEditDate = toUnixDateGMT(minEditDate);

            //minEditDate = +Infinity;
            //maxEditDate = -Infinity;

            /*
            dates.forEach(item => {
                minEditDate = minEditDate < item ? minEditDate : item;
                maxEditDate = maxEditDate > item ? maxEditDate : item;
            });
            */

            var data = null;
            if (keyrateChartData != null && keyrateChartData.length > 0) {

                var temp = keyrateChartData
                    .map(x => { return { x: x.x, y: x.y } })
                    .sort((a, b) => a.x - b.x);
                //.filter(item => item.x >= minEditDate || item.x <= maxEditDate);

                //to fit the bounds
                if (temp[0].x != minEditDate) {
                    var diff = 0;
                    if (temp[0].x < minEditDate) {
                        diff = minEditDate - temp[0].x;

                        //update the view
                        setIsEditChartWasCorrected(true);

                        setIsChartChanged(true);

                        //slide right
                        temp.forEach(item => {
                            var cur = item.x + diff;
                            var date = startOfMonth(cur);
                            item.x = toUnixDateGMT(date);
                        });
                    }

                    temp[0].x = minEditDate;
                }

                //right bounds
                temp.forEach(item => item.x = (item.x > maxEditDate ? maxEditDate : item.x));

                //if (temp[temp.length - 1].x != maxEditDate)
                //temp[temp.length - 1].x = maxEditDate;

                data = temp;
            }
            else {
                //calculate average

                // console.log('data', minEditDate, maxEditDate, historicalSeries)

                data = createEditChart(minEditDate, maxEditDate, historicalSeries);
            }

            if (series.data == null || updateChart) {
                series.data = data;
            }
            else {

                //because dragging points required updated data immediately
                keyrateChart.current.chart.series[2].setData(data);
            }

            var minRate = +Infinity;
            var maxRate = -Infinity;
            for (var i = 0; i < newKeyrateChartOptions.series[0].data.length; i++) {

                if (newKeyrateChartOptions.series[0].data[i].y < minRate)
                    minRate = newKeyrateChartOptions.series[0].data[i].y;

                if (newKeyrateChartOptions.series[0].data[i].y > maxRate)
                    maxRate = newKeyrateChartOptions.series[0].data[i].y;
            }

            /*
            for (var i = 0; i < newKeyrateChartOptions.series[1].data.length; i++) {

                if (newKeyrateChartOptions.series[1].data[i].y < minRate)
                    minRate = newKeyrateChartOptions.series[1].data[i].y;

                if (newKeyrateChartOptions.series[1].data[i].y > maxRate)
                    maxRate = newKeyrateChartOptions.series[1].data[i].y;
            }
            */

            minRate = Math.floor(minRate)
            if (minRate > 3)
                minRate = 3
            else
                minRate -= 1;
            maxRate = Math.floor(maxRate)
            if (maxRate < 15)
                maxRate = 15
            else
                maxRate += 1

            //set min/max
            newKeyrateChartOptions.xAxis.min = minHistoryDate;
            newKeyrateChartOptions.xAxis.max = maxEditDate;

            newKeyrateChartOptions.yAxis[0].min = minRate;
            newKeyrateChartOptions.yAxis[0].max = maxRate;
            newKeyrateChartOptions.yAxis[1].min = minRate;
            newKeyrateChartOptions.yAxis[1].max = maxRate;
            const ticksInterval = Math.floor((maxRate - minRate) / 5)
            // console.log('minRate, maxRate', minRate, maxRate, ticksInterval)

            newKeyrateChartOptions.yAxis[0].tickInterval = ticksInterval
            newKeyrateChartOptions.yAxis[1].tickInterval = ticksInterval
            // console.log(' newKeyrateChartOptions',  newKeyrateChartOptions)
            //newKeyrateChartOptions.yAxis[0][1].startOnTick = false;
            //newKeyrateChartOptions.yAxis[0][1].endOnTick = false;

            //add plot bands
            newKeyrateChartOptions.xAxis.plotBands = [
                {
                    from: minHistoryDate,
                    to: maxHistoryDate,
                    color: 'rgba(47, 68, 78, 0.02)'
                }
            ];
        }

        setKeyrateChartOptions(newKeyrateChartOptions);
        setMinEditDate(minEditDate);
        setMaxEditDate(maxEditDate);
        setMinEditValue(minRate);
        setMaxEditValue(maxRate);
        setDates(dates);

        setPointsData(data);
    }

    const createEditChart = (minEditDate, maxEditDate, historicalSeries) => {

        var avgRate = 8.0; // CHECKME!!! hardcode! better to move at bond specific field sum / baseChart.length;

        if (historicalSeries && historicalSeries?.data && Array.isArray(historicalSeries?.data) && historicalSeries?.data?.length) //находим последнюю историчекую ключевыю ставку 
            avgRate = historicalSeries.data[historicalSeries.data.length - 1].y

        var dateX = [
            minEditDate,
            minEditDate + ((maxEditDate - minEditDate) / 4),
            minEditDate + ((maxEditDate - minEditDate) / 2),
            minEditDate + ((maxEditDate - minEditDate) / 4 * 3),
            maxEditDate
        ];
        // console.log('dateX', dateX)

        for (var i = 0; i < dateX.length; i++) {
            var date = new Date(dateX[i]);
            if (date.getDate() >= 15) { //add month
                date = new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0);
                date = addMonths(date, 1);
            }
            else {
                date = new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0);
            }

            dateX[i] = toUnixDate(date);
        }

        var data = [
            { x: dateX[0], y: avgRate },
            { x: dateX[1], y: avgRate },
            { x: dateX[2], y: avgRate },
            { x: dateX[3], y: avgRate },
            { x: dateX[4], y: avgRate }
        ];

        return data;
    }

    const handleRowUpdate = (updatedRow: any, oldRow: any) => {

        var data = [...keyrateChartOptions.series[2].data];
        var index = data.findIndex(item => item.x == oldRow.id);
        if (index == -1) {

            //new point added

            data.push({ x: updatedRow.id, y: updatedRow.value });

            keyrateChartOptions.series[2].data = data;
            keyrateChart.current.chart.series[2].setData(data);

            addAction({ type: 'addPoint', x: updatedRow.id, y: updatedRow.value });
            return;
        }

        var point = data[index];

        //causes point reset
        addAction({ type: 'movePoint', x: updatedRow.id, y: updatedRow.value, prevX: point.x, prevY: point.y });

        point.x = updatedRow.id;
        point.y = updatedRow.value;

        keyrateChartOptions.series[2].data = data;
        keyrateChart.current.chart.series[2].setData(data);

        //setPointsData(data);
    }

    const handleRowRemove = (removedRow: any) => {

        if (removedRow.date == '')
            return;

        var date = toUnixDateGMT(removedRow.date);

        var chart = keyrateChart.current.chart;
        var series = chart.series[2].data;
        if (series.length <= 2)
            return;

        var index = series.findIndex(x => x.x == date);
        if (index == -1)
            return;

        var point = series[index];

        var stats = { type: 'removePoint', x: point.x, y: point.y };

        point.remove();

        updateOptions();

        addAction(stats);
    }


    //console.log('---render--> chardOptsData: ', keyrateChartOptions.series[2].data, 'chartData: ', keyrateChart.current?.chart?.series[2]?.yData);

    return (
        <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', p: 0, pb: 1 }}>
            {/* {console.log('keyratechart draw')} */}
            {
                isEditChartWasCorrected && (
                    <Box className={classes.warningContainer}>
                        {t('HistoricalChartChanged')}
                    </Box>
                )
            }
            <Stack>
                <Box sx={{
                    display: 'flex', pl: 1,
                    flexWrap: 'nowrap',
                    flexDirection: 'row',
                    alignContent: 'center',
                    alignItems: 'baseline'
                }}
                >
                    <Typography sx={{
                        textTransform: 'uppercase', lineHeight: '2rem', maxHeight: '2rem', overflow: 'hidden', ml: 1,
                        display: 'flex', alignItems: 'center',
                        flexGrow: 1, fontSize: '14px', color: style.colorPrimary3
                    }} >
                        {t('KeyRateTitle')}
                        <TooltipInfoLabel right content={t('KeyRateTooltip')} />
                    </Typography>

                    <Box component="span" sx={{ pr: 1 }}>
                        {/* кнопка Undo */}
                        <Tooltip
                            disableFocusListener
                            //interactive
                            title={t('Undo_Info')}>
                            <div>
                                <IconButton size="small" disabled={!isInEditMode || actionIndex == -1} onClick={onUndoClick}>
                                    <UndoIcon />
                                </IconButton>
                            </div>
                        </Tooltip>
                    </Box>
                    <Box component="span" sx={{ pr: 1 }}>
                        {/* кнопка Redo */}
                        <Tooltip
                            disableFocusListener
                            //interactive
                            title={t('Redo_Info')}>
                            <div>
                                <IconButton size="small" disabled={!isInEditMode || actionIndex == binding.current.actionBuffer.length - 1} onClick={onRedoClick}>
                                    <RedoIcon />
                                </IconButton>
                            </div>
                        </Tooltip>
                    </Box>
                    <Box component="span" sx={{ pr: 1 }}>
                        {/* кнопка Clear_Info */}
                        <Tooltip
                            disableFocusListener
                            //interactive
                            title={t('Clear_Info')}>
                            <div>
                                <IconButton size="small"
                                    // disabled={!isInEditMode || actionBuffer.length == 0} 
                                    onClick={onClearClick}>
                                    <BackspaceOutlined />
                                </IconButton>
                            </div>
                        </Tooltip>
                    </Box>
                </Box>
                <Divider />
            </Stack>
            <Stack direction="row" sx={{ display: 'flex', flexGrow: 1, minHeight: '0' }}>
                <Box sx={{ flexGrow: 1, flexShrink: 1, overflow: 'hidden' }}>
                    <HighchartsReact
                        ref={keyrateChart}
                        highcharts={Highcharts}
                        containerProps={{ style: { height: "100%", width: "100%" } }}
                        options={keyrateChartOptions}
                        /*
                        callback={(chart) => {
                            setTimeout(onResize, 0);
                    }}
                        */
                    />
                </Box>
                <PointsEditorComponent pointsData={pointsData} onRowUpdated={handleRowUpdate} onRowRemoved={handleRowRemove} />
            </Stack>
            <Stack className={classes.buttonsContainer}>
                {/* <div className={classes.buttonsContainer}> */}
                <Button variant="contained" color="secondary" disabled={isTrackChanges && !isChartChanged} onClick={onApplyChangesClick}>
                    {t('Apply')}
                </Button>
                {/* </div> */}
            </Stack>
            {/* </div > */}
        </Box>
    )
}

export default KeyRatePanel;