import * as React from "react";
import { isNode } from "@Utilities";
import '@Framework/scss/Tab.scss';

export class TabContent extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return <div className="tab-content">{this.props.children}</div>;
    }
}