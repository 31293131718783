import * as React from "react";
import '@Styles/components/Spinner.scss';

export class Spinner extends React.Component {

    // constructor(props) {
    //     super(props);
    // }

    render() {

        var css = { "display": (this.props.show ? "block" : "none") };

        return (
            <div key={this.renderKey} className="spinner-bg" style={css}>
                <div className="spinner-centred">
                    <div className="loader-spinner"></div>
                    <div className="loader-text">{this.props.content}</div>
                </div>
            </div>
        );
    }
}