import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as CalculatorStore from "@Store/CalculatorStore";
import { withRouter } from "../../../components/shared/CustomRouter";
import { Paper, Box } from "@mui/material";
import { withStyles } from '@mui/styles';

import { DataGrid, ToolbarOptions, GridOptions } from "@Framework/components/Table";
import { ColumnModel } from "@Framework/components/Table/Common";

import CalculatorService from "@Services/CalculatorService";
import CommonService from "@Services/CommonService";
import SiteHelper from "@Shared/SiteHelper";

import { DataSourceChangeType } from '@Framework/components/Common/Definitions';
import Localization from '@Components/Localization';
import Globals from '../../../Globals';
import OriginatorInfoDialog from "@Components/shared/OriginatorInfoDialog";
import { AggregateFunctions, ColumnDataType, ColumnSortDirection, TrimContentType } from '../../../framework/components/Table/Common/Models/Column';
import { withTranslation } from "react-i18next";

interface IProps {
    t: any;
}

interface IStats {
    showInfo: boolean;
    objectId: number;
}

const styles = theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '0.5rem 1rem 0 1rem',
        flex: '1 1 auto',
        height: 0
    },
    gridContainer: {
        display: 'flex',
        height: '100%'
    },
    grid: {
        marginLeft: 'auto',
        marginRight: 'auto'
    }
});

class OriginatorsPage extends React.Component<IProps, IStats> {

    constructor(props) {
        super(props);
        this.state = {
            showInfo: false,
            objectId: null
        }
    }

    grid = null;

    async componentDidMount() {

        (async () => {

            var state = Globals.state.originators;
            if (state != null)
                await this.grid.SetFiltersState(state);

            var products = await CommonService.getProducts();
            if (products != null) {

                this.dataSource = {
                    items: [{ key: null, value: this.props.t('Undefined') }],
                    count: 0
                }

                this.dataSource.count = products.count;
                if (products.items != null)
                    this.dataSource.items = this.dataSource.items.concat(products.items.map(item => ({ key: item.id, value: item.name })));
            }

        })();
    }

    dataSource = {
        items: [{ key: null, value: this.props.t('Undefined') }],
        count: 0
    };

    dataSourceQuery = async (request) => {

        //save actual state
        var columnsState = SiteHelper.CreateFilterState(request);
        Globals.setState('originators', columnsState);


        var filter = SiteHelper.CreateFilter(request);

        var result = await CalculatorService.getOriginators(filter);
        if (result.hasErrors) {
            throw ('Error while processing request');
        }

        //IGridPayload
        return {
            totalCount: result.value.count,
            payload: result.value.items
        };
    }

    dataSourceUpdate = async (type, data) => {
        if (data == null)
            return false;

        var result = null;
        switch (type) {
            case DataSourceChangeType.CREATE:
                {
                    result = await CalculatorService.createOriginator(data);
                }
                break;
            case DataSourceChangeType.UPDATE:
                {
                    //form update structure
                    var toUpdate = new Object();
                    var keyField = "id";

                    if (data.changedFields != null) {
                        toUpdate[keyField] = data[keyField];
                        data.changedFields.forEach((item) => {
                            toUpdate[item] = { Value: data[item] };
                        });
                    }
                    else {
                        //update all
                        Object.keys(data).forEach((key) => {
                            toUpdate[key] = { Value: data[key] };
                        });
                    }

                    result = await CalculatorService.updateOriginator(toUpdate);
                }
                break;
            case DataSourceChangeType.REMOVE:
                {
                    result = await CalculatorService.removeOriginator(data.id);
                }
                break;
        }

        if (result.hasErrors) {
            throw ('Error while processing request');
        }

        return result.value;
    };

    renderProductCell = (row) => {
        return row.productName;
    };

    productQuery = (value, index, pageSize) => {
        return this.dataSource;
    };

    handleRowDoubleClick = (row) => {
        /* uncomment when dialog content will be available
        this.setState({ showInfo: true, objectId: row['id'] });
        */
    };

    handleInfoDialogClose = (event) => {
        this.setState({ showInfo: false });
    };

    columns = [
        //new ColumnModel('id', { Label: this.props.t('ID'), Tooltip: this.props.t('OriginatorID_Info'), DataType: ColumnDataType.INT, IsKey: true, Sortable: true, SortDirection: 'Ascending', SortOrder: 1, DefaultValue: 0 }),
        //new ColumnModel('createdDate', { Label: this.props.t('CreatedDate'), Tooltip: this.props.t('OriginatorCreatedDate_Info'), DataType: ColumnDataType.DATE, Searchable: true, Filterable: true, Sortable: true, IsReadonly: true, DefaultValue: Localization.FormatDateISO(new Date()) }),
        new ColumnModel('name', { Label: this.props.t('BondNameRU'), Tooltip: this.props.t('OriginatorNameRU_Info'), DataType: ColumnDataType.STRING, Searchable: true, Filterable: true, Sortable: true }),
        new ColumnModel('nameEN', { Label: this.props.t('BondNameEN'), Tooltip: this.props.t('OriginatorNameEN_Info'), DataType: ColumnDataType.STRING, Searchable: true, Filterable: true, Sortable: true }),
        //new ColumnModel('description', { Label: this.props.t('Description'), Tooltip: this.props.t('OriginatorDescription_Info'), DataType: ColumnDataType.STRING, Searchable: true, Filterable: true, Sortable: true }),

        //for admins only
        ...(Globals.hasAccess(Globals.Administrator_Role) ? [
            new ColumnModel('productId', { Label: this.props.t('Product'), Tooltip: this.props.t('Product_Info'), DataType: ColumnDataType.ENUM, Render: this.renderProductCell, DataSource: this.productQuery }),
            new ColumnModel('isShared', { Label: this.props.t('VisibleToAll'), Tooltip: this.props.t('OriginatorVisibleToAll_Info'), DataType: ColumnDataType.BOOLEAN }),
            new ColumnModel('isVisibleAtInternet', { Label: this.props.t('VisibleAtInternet'), Tooltip: this.props.t('OriginatorVisibleAtInternet_Info'), DataType: ColumnDataType.BOOLEAN }),

            new ColumnModel('', {
                Actions: [{ Type: 'add' }, { Type: 'remove' }, { Type: 'edit' }],
                Label: this.props.t('Actions'),
                IsAction: true
            })
        ] : []
        )
    ];

    settings = { query: this.dataSourceQuery, update: this.dataSourceUpdate };

    gridOptions = new GridOptions({
        topPager: false,
        fixedHeader: false,
        fullHeight: true,
        fullWidth: false,
        floatingToolbar: true,
        canSearch: true,

        confirmTitle: this.props.t('AreYouSureQuestion'),
        confirmDescription: this.props.t('RemoveOriginatorDescription')
    });

    render() {

        const { t } = this.props;

        return (
            <Box sx={{width: '100%', paddingX: '1rem'}} className={this.props.classes.container}>
                <OriginatorInfoDialog show={this.state.showInfo} objectId={this.state.objectId} onClose={this.handleInfoDialogClose} />
                <div className={this.props.classes.gridContainer} >
                    <DataGrid
                        className={this.props.classes.grid}
                        ref={x => this.grid = x}
                        gridName={t('Originators')}
                        dataSource={this.settings}
                        columns={this.columns}
                        gridOptions={this.gridOptions}
                        onRowDoubleClick={this.handleRowDoubleClick}
                    />
                </div>
            </Box>
        );
    }
}

var component = connect(
    state => state.calculator, // Selects which state properties are merged into the component's props
    CalculatorStore.actionCreators // Selects which action creators are merged into the component's props
)(withStyles(styles)(OriginatorsPage));

export default withRouter(withTranslation('translations')(component));