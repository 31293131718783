import React, { useState, useEffect } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { useTranslation } from "react-i18next";
import { Theme } from '@mui/material/styles';
import { MessageType, EntityType } from '@Components/Definitions'
import style from '@Styles/domrf-theme.module.scss'
import { alpha, Button, Box, Divider, Stack, Typography, CircularProgress } from '@mui/material'
import { PoolProcessingStatus } from '../../../../components/Definitions'
import { useSelector } from 'react-redux'
import CustomButton from '@Base/components/shared/CustomButton';

const useStyles = makeStyles((theme: Theme) => createStyles({
    buttonRoot: {
        height: '34px',
        position: 'relative',
    },
    acceptButton: {
        height: '34px',
        position: 'relative',
        borderColor: 'rgb(255, 185, 20)',
        color: 'rgb(255, 185, 20)',
        backgroundColor: 'rgb(255, 250, 235)',
        '&:hover': {
            borderColor: 'rgb(235, 155, 5)',
            color: 'rgb(235, 155, 5)',
            backgroundColor: 'rgb(255, 250, 235)',
        }
    },
    infoRow: {
        display: 'flex',
        flexDirection: 'row',
        lineHeight: '2rem',
        '&>*': {
            display: 'inherit',
        },
        '& .data': {
            paddingRight: '0.5rem',
            paddingLeft: '0.5rem',
            width: '100%',
            cursor: 'default',
        },
        '& .rowNumber': {
            minWidth: '30px',
            textAlign: 'right',
            paddingRight: '0.5rem',
        },
        '&:nth-of-type(2n)': {
            backgroundColor: `${alpha(style.colorPrimary3, 0.05)}`
        },
        '&:hover': {
            '& .error': {
                backgroundColor: 'rgb(255,241,242)',
                //color:'rgb(192,0,0)'
            },
            '& .warning': {
                backgroundColor: 'rgb(255, 250, 235)',
                //color:'rgb(255, 185, 20)',
            }
        }
    },
    scrollablePanel: {
        position: 'absolute', left: 0, right: 0, top: 0, bottom: 0,
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: '4px',
        },
        '&:hover': {
            '&::-webkit-scrollbar-track': {
                backgroundColor: '#00000008',
            },
            '&::-webkit-scrollbar-thumb': {
                background: '#00000018',
            }
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'unset',
            borderRadius: '3px',
            // border: 'solid 1px #00000000',
            backgroundColor: '#00000000',
            transition: 'all 0.3s ease'
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#0000',
            borderRadius: '3px',
            height: '4px'
        },
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
    },
    warningIcon: {
        color: style.colorWarning
    },
    ErrorOutline: {
        color: style.colorError
    },
    dialog: {
        height: '60%'
    },
    table: {
        /*width: '100%',*/
        height: '100%' //to fill container
    },
    container: {
        '& *': {
            userSelect: 'none !important',
        },
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        position: 'absolute', left: 0, top: 0, right: 0, bottom: 0,
    },
    header: {
        display: 'flex', paddingLeft: 1,
        flexWrap: 'nowrap',
        flexDirection: 'row',
        alignContent: 'center',
        alignItems: 'baseline',

    }
}));


const MessagesListDialog =
    (
        {
            // header,
            // messages,
            totalCount,
            onDataRequest,
            onClose,
            onAccept,
            onReject,
            // showAcceptButton,
            sourceObject
        }
    ) => {
        const { poolMessages = null, ...otherStor } = useSelector((state: any) => state.securitization)
        const classes = useStyles();
        const { t, i18n } = useTranslation();
        const [selectedRow, setSelectedRow] = useState([]);
        const [statusColumns] = useState(['type']);
        const [messages, setMessages] = useState([])
        const header = sourceObject?.status == PoolProcessingStatus.STATUS_ATTENTION_REQUIRED ?
            t('ThereAreWarningsDueValidation') :
            sourceObject?.status == PoolProcessingStatus.STATUS_FAILED ?
                t('ThereAreErrorsDueValidation') :
                ''
        const showAcceptButton = sourceObject?.status == PoolProcessingStatus.STATUS_ATTENTION_REQUIRED
        useEffect(() => {
            setMessages(poolMessages?.items)
            // console.log('poolMessages', poolMessages)
        }, [poolMessages])

        useEffect(() => {
            if (sourceObject != null)
                onDataRequest(/*currentPage/*, pageSize*/);

        }, [/*currentPage,/* pageSize, sourceObject*/]);

        const markRowType = (wrapped, type) => {
            let typeStyle
            switch (type) {
                case MessageType.TYPE_ERROR:
                    typeStyle = 'error'
                    break;
                case MessageType.TYPE_WARNING:
                    typeStyle = 'warning'
                    break;
                default:
                    return wrapped
                    break;
            }
            return (<span className={`${typeStyle} data`}>{wrapped}</span>)
        }
        return (
            <Box className={classes.container} >
                {Boolean(header) &&
                    <>
                        <Box className={classes.header}>
                            <Typography sx={{ textTransform: 'uppercase', lineHeight: '2rem', maxHeight: '2rem', overflow: 'hidden', ml: 1, flexGrow: 1, fontSize: '14px', color: style.colorPrimary3 }} >
                                {header}
                            </Typography>
                        </Box>
                        <Divider />
                    </>
                }
                <Stack sx={{ p: 1, flexGrow: 1, position: 'relative' }}>
                    <Box className={classes.scrollablePanel}>
                        {
                            !!messages?.length && messages?.map((row, index) =>
                                <Stack key={`infoRow-${index}`} className={classes.infoRow}>
                                    {markRowType(
                                        <>
                                            <Box className='rowNumber'>{index + 1}</Box>
                                            <Box>{row?.description}</Box>
                                        </>, row.type
                                    )}
                                </Stack>)
                        }
                    </Box>
                </Stack>
                {sourceObject?.status === PoolProcessingStatus.STATUS_FAILED && (
                    <Stack sx={{ flexDirection: 'row', justifyContent: 'flex-end', p: 1 }}>
                        <CustomButton
                            variant="contained"
                            color="primary"
                            onClick={onReject}
                            sx={{ ml: 1 }}
                            progress={"applyPoolWarningsProgress"}
                            classes={{ root: classes.buttonRoot }}
                        >
                            {t('RemoveRecord')}
                        </CustomButton>
                    </Stack>
                )}
                {showAcceptButton && (
                    <Stack sx={{ flexDirection: 'row', justifyContent: 'flex-end', p: 1 }}>
                        <CustomButton
                            variant="outlined"
                            classes={{
                                root: classes.acceptButton,
                            }}
                            /*sx={{borderColor: 'rgb(255, 185, 20)', color:'rgb(255, 185, 20)', backgroundColor:'rgb(255, 250, 235)'}}*/
                            onClick={onAccept}
                            progress={"applyPoolWarningsProgress"}
                            progressProps={{size:22}}
                        >
                            {t('UploadPoolAnyway')}

                        </CustomButton>
                        <CustomButton
                            variant="contained"
                            color="primary"
                            onClick={onReject}
                            sx={{ ml: 1 }}
                            progress={"applyPoolWarningsProgress"}
                            progressProps={{size:22}}
                            classes={{ root: classes.buttonRoot }}
                        >
                            {t('DoNotUpload')}

                        </CustomButton>
                        {/* <Button className="ms-auto" variant="contained" color="secondary" onClick={handleClose}>
                            {t('Close')}
                        </Button> */}
                    </Stack>
                )
                }
            </Box>
        );
    }


export default MessagesListDialog;