import React, { useState, useEffect, useRef } from 'react'
import { CloseOutlined, SaveOutlined } from '@mui/icons-material'
import { Box, Button, Dialog, DialogActions, DialogContent, Divider, IconButton, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@mui/material'
import { MobileDatePicker } from '@mui/x-date-pickers'
import { PickersActionBarProps } from '@mui/x-date-pickers/PickersActionBar'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import HC_exporting from 'highcharts/modules/exporting';
import HC_more from "highcharts/highcharts-more"; //module
import { setHighchartsLocalization, copyTextToClipboard } from '@Utilities'
import stringInject from 'stringinject'

import { t } from 'i18next'
import Localization from '../../../../components/Localization'
import { actionCreators as actionDomRFStore } from '../../../../store/DomRfStore'
import { isValid, toDate } from 'date-fns'
import style from '@Styles/domrf-theme.module.scss'
import { useDispatch, useSelector } from 'react-redux'

export default function ZCYCDialog({ onClose, classes, show, firstZCYCDate }) {
    interface IState {
        firstZCYCDateInner: any,
        firstZCYCDate: any,
        secondZCYCDateInner: any,
        secondZCYCDate: any
    }

    const chartsColours = [
        style.colorPrimary1,
        style.colorPrimary2,
        style.colorPrimary3,

        style.colorPrimary1_80,
        style.colorPrimary2_80,
    ]


    const minDate = new Date(2014, 1, 6)

    // Максимальная дата Календаря определяется по следующему алгоритму:
    // если текущее время между 00:00 и 19:10, то максимальная доступная дата - вчера
    // если иначе, то максимальная доступная дата - сегодня. то есть "сегодня" только когда между 19:10 и 23:59 включительно
    const maxDate = new Date();
    if (maxDate.getTime() <= new Date(Date.now()).setHours(19, 10)) {//19:10
        maxDate.setHours(0, 0, -1)
    }

    const checkDates = (day) => {
        const curTime = new Date(day).getTime()
        const minTime = minDate.getTime()
        const maxTime = maxDate.getTime()
        return !(curTime >= minTime && curTime < maxTime)
    }

    const [date, setDate] = useState({
        firstZCYCDateInner: null,
        firstZCYCDate: null,
        secondZCYCDateInner: null,
        secondZCYCDate: null
    })

    const [visible, setVisible] = useState(show)
    useEffect(() => {
        setVisible(show)
    }, [show])

    const zcycChart = useRef(null)
    const [needUpdateChart, setNeedUpdateChart] = useState(false)
    const [zcycChartOptions, setZcycChartOptions] = useState(
        {
            chart: {
                zoomType: 'x',
                events: {}
            },
            exporting: {
                enabled: false,
                chartOptions: {
                    legend: {
                        itemDistance: 40,
                        itemStyle: {
                            fontSize: '7px',
                            overflow: 'hidden',
                        },
                    },
                }
            },
            title: { text: '', },
            credits: { enabled: false },
            xAxis: {
                title: t('Years'),
                gridLineWidth: 1,
                lineWidth: 1,
                lineColor: style.colorPrimary1
            },
            yAxis: { title: null },
            tooltip: {
                shared: false,
                split: true,
                formatter: function () {
                    const vals = this.points.map(point => `<span style="color:${point.color}">\u25CF</span> ${point.series.name} <b>${Localization.FormatNumber(point.y, 2, 2)}</b>`).join('<br/>')
                    return `<b>${Localization.Translate('ConventionNameZCYCComparisonGraphDurationRunner')}: ${Localization.FormatNumber(this.x, 2, 2)}</b><br>${vals}`
                },
            },
            series: []
        }
    )
    const dispatch = useDispatch()
    const { zcycData, ...otherDomRFStore } = useSelector((state: any) => state.domrf)

    useEffect(() => {
        HC_exporting(Highcharts);
        HC_more(Highcharts);
        setHighchartsLocalization(Highcharts, t);
        Highcharts.SVGRenderer.prototype.symbols.SaveFileIcon = function (x, y, w, h) {
            const icon = 'M17 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V7l-4-4zm2 16H5V5h11.17L19 7.83V19zm-7-7c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3zM6 6h9v4H6z'
            return icon.split(' ')
        }
    }, [])

    useEffect(() => {
        if (zcycData == null)
            return

        var newZCYCChartOptions = { ...zcycChartOptions };

        newZCYCChartOptions.series = zcycData.map((data, index) => {

            var zcycData = data.zcycCurve.map(item => { return { x: item.year, y: item.value } });

            var dateNow = new Date();
            var date = new Date(data.zcycDate);
            var timeLabel = '';
            var tooltipLabel = '';

            {
                var time = Localization.FormatDateTimeLocale(date);
                timeLabel = stringInject(t('YCurveOn'), [time]);
                //tooltipLabel = stringInject(t('YCurveOn'), [time]);
            }

            return {
                name: timeLabel,
                color: chartsColours[index],
                showInLegend: true,
                lineWidth: 1,
                data: zcycData,
                marker: {
                    symbol: 'circle',
                    enabled: false
                }
            }
        });

        let dateUpdate = {}
        if (zcycData.length > 0) {
            let fDate = new Date(zcycData[0].zcycDate)
            // console.log('new Date(zcycData[0].zcycDate)', fDate )
            dateUpdate.firstZCYCDate =  fDate
            dateUpdate.firstZCYCDateInner = fDate
        }

        if (zcycData.length > 1) {
            let sDate = new Date(zcycData[1].zcycDate)
            dateUpdate.secondZCYCDate= sDate
            dateUpdate.secondZCYCDateInner= sDate
        }

        setZcycChartOptions(newZCYCChartOptions)
        
        setDate({ ...date, ...dateUpdate})
        setNeedUpdateChart(false)
    }, [zcycData, Localization.locale])

    useEffect(() => {
        if (firstZCYCDate != date.firstZCYCDate && firstZCYCDate != null) {
            setDate({ ...date, firstZCYCDate: firstZCYCDate, firstZCYCDateInner: firstZCYCDate })
            setNeedUpdateChart(true)
        }
    }, [firstZCYCDate])

    useEffect(() => {
        if (date.firstZCYCDate == null && date.secondZCYCDate == null || !needUpdateChart)
            return

        var args = [];

        let fDate = toDate(Date.parse(date.firstZCYCDate))
        if (isValid(fDate)) {
            args.push(Localization.FormatDateTimeISO(fDate))
        }
        let sDate = toDate(Date.parse(date.secondZCYCDate))
        if (isValid(sDate))
            args.push(Localization.FormatDateTimeISO(sDate))

        if (args.length != 0) {
            actionDomRFStore.getZCYCChartData(args)(dispatch)
            setNeedUpdateChart(false)
        }

        // console.log('args', args)
        // if (show !== visible) {
        //     setVisible(show)
        // }

        // if (this.state.zcycChartOptions != prevState.zcycChartOptions) {
        //     this.setState({ needUpdateChart: false });
        // }

    }, [date])

    const MyActionBar = ({
        onAccept,
        onCancel,
        onClear,
        onSetToday,
    }: PickersActionBarProps) => {

        return (
            <DialogActions>
                <Button onClick={onCancel}> {t('Cancel')} </Button>
                <Button onClick={onAccept}> {t('OK')} </Button>
            </DialogActions>
        );
    };

    const onWindowClose = (event) => {
        if (onClose)
            onClose(event, false);
    }

    const handleDateChange = (name: keyof IState, dateval) => {
        if (isValid(dateval)) {
            setDate({ ...date, [name]: dateval })
            setNeedUpdateChart(true)
        }
    }

    const handleInnerDateChange = (name: keyof IState, dateval) => {
        var innerDate = isValid(dateval) ? new Date(dateval.setHours(23, 59, 59)) : dateval;
        setDate({ ...date, [name]: innerDate })
    }

    return (
        <>
            <Dialog maxWidth="md" fullWidth onClose={onWindowClose} open={visible} disableScrollLock>
                <Box sx={{
                    display: 'flex', pl: 1,
                    flexWrap: 'nowrap',
                    flexDirection: 'row',
                    alignContent: 'center',
                    alignItems: 'baseline',
                }}
                >
                    <Typography sx={{ textTransform: 'uppercase', lineHeight: '2rem', maxHeight: '2rem', overflow: 'hidden', ml: 1, flexGrow: 1, fontSize: '14px', color: style.colorPrimary3 }} >
                        {t('ConventionNameZCYCComparisonWindow')}
                    </Typography>

                    <Box component="span" sx={{ pr: 1 }}>
                        <IconButton sx={{ width: 30, height: 30 }} onClick={() => zcycChart.current?.chart.exportChart({ width: 2048 })}>
                            <SaveOutlined />
                        </IconButton>
                    </Box>

                    <Box component="span" sx={{ pr: 1 }}>
                        {
                            // кнопка Close
                        }
                        <IconButton size="small" onClick={onWindowClose}>
                            <CloseOutlined />
                        </IconButton>
                    </Box>
                </Box>
                <Divider />
                <DialogContent dividers>
                    <Box>
                        <Box sx={{ mr: 3 }} component="span">
                            <MobileDatePicker
                                //disableCloseOnSelect={true}
                                label={t('ConventionNameEnterFirstZCYCDate')}
                                //ampm={false}
                                //showTodayButton={true}
                                components={{
                                    ActionBar: MyActionBar
                                }}
                                value={date?.firstZCYCDateInner}
                                inputFormat={Localization.ShortDatePattern()}
                                minDate={minDate}
                                maxDate={maxDate}
                                shouldDisableDate={checkDates}
                                shouldDisableMonth={checkDates}
                                //shouldDisableYear={checkDates}
                                renderInput={(params) => <TextField sx={{ mr: 1, maxWidth: '130px' }} {...params} variant="standard" />}
                                onChange={(e) => handleInnerDateChange("firstZCYCDateInner", e)}
                                onAccept={(e) => handleDateChange("firstZCYCDate", e)}
                            />
                            {/* <TimePicker
                                sx={{ ml: 0, mr: 1, maxWidth: '150px' }}
                                InputProps={{}} //убирает правую иконку
                                name={t('ConventionNameEnterFirstZCYCDateTime')}
                                value={date.firstZCYCDateInner}
                                label={t('ConventionNameEnterFirstZCYCDateTime')}
                                onChange={(e) => this.handleDateChange("firstZCYCDate", e)}
                                minutesstep={10}
                                hoursfrom={10}
                                hoursto={19}
                                digheight={3}
                                neighborcnt={2}
                                selectedcolor={'#8bc54094'}
                            /> */}
                        </Box>
                        <Box sx={{ mr: 3 }} component="span">
                            <MobileDatePicker
                                //disableCloseOnSelect={true}
                                label={t('ConventionNameEnterSecondZCYCDate')}
                                //ampm={false}
                                //showTodayButton={true}
                                components={{
                                    ActionBar: MyActionBar
                                }}
                                value={date?.secondZCYCDateInner}
                                inputFormat={Localization.ShortDatePattern()}
                                minDate={minDate}
                                maxDate={maxDate}
                                shouldDisableDate={checkDates}
                                shouldDisableMonth={checkDates}
                                //shouldDisableYear={checkDates}
                                renderInput={(params) => <TextField sx={{ mr: 1, maxWidth: '130px' }} {...params} variant="standard" />}
                                onChange={(e) => handleInnerDateChange("secondZCYCDateInner", e)}
                                onAccept={(e) => handleDateChange("secondZCYCDate", e)}
                            />
                            {/* <TimePicker
                                sx={{ ml: 0, mr: 1, maxWidth: '150px' }}
                                InputProps={{}} //убирает правую иконку
                                name={t('ConventionNameEnterSecondZCYCDateTime')}
                                value={date.secondZCYCDateInner}
                                disabled={!date.secondZCYCDateInner}
                                label={t('ConventionNameEnterSecondZCYCDateTime')}
                                onChange={(e) => this.handleDateChange("secondZCYCDate", e)}
                                minutesstep={10}
                                hoursfrom={10}
                                hoursto={19}
                                digheight={3}
                                neighborcnt={2}
                                selectedcolor={'#8bc54094'}
                            /> */}
                        </Box>
                        {/* </div> */}
                    </Box>

                    <div className="w-100">
                        <HighchartsReact
                            ref={zcycChart}
                            highcharts={Highcharts}
                            options={zcycChartOptions}
                        />
                    </div>

                    <div>
                        {
                            zcycData != null && (
                                <Table aria-label="zcyc table" className={classes?.tableStyle}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell key={"zcyc-x-date"} className={classes?.tableHeader}></TableCell>
                                            {
                                                zcycData[0].tableZCYCCurve.map((item, index) => {
                                                    return (
                                                        <TableCell key={"zcyc-x-" + index} className={classes?.tableHeader} align='right'>
                                                            {item.year < 1 ? Localization.FormatNumberString(item.year) : Localization.FormatNumberString(item.year, 0, 0)}
                                                        </TableCell>
                                                    )
                                                })
                                            }
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            zcycData.map((item, index) => {
                                                return (
                                                    <TableRow key={'zcyc-series-' + index}>
                                                        <TableCell key={"zcyc-y-date"}>{Localization.FormatDateTimeStringLocale(item.zcycDate) + ', Y(t), %'}</TableCell>
                                                        {
                                                            item.tableZCYCCurve.map((curve, index) => {
                                                                return (
                                                                    <TableCell key={"zcyc-y-" + index} align='right'>
                                                                        {Localization.FormatNumberString(curve.value)}
                                                                    </TableCell>
                                                                )
                                                            })
                                                        }
                                                    </TableRow>
                                                )
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            )}
                    </div>
                </DialogContent>
            </Dialog>
        </>
    )
}
