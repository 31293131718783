import { Box, SvgIcon } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import React from 'react'

const useStyles = makeStyles({
    mainContainer: (props) => ({
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '100%',
        borderLeft: `1px solid ${props.borderColor}`,
        borderBottom: `1px solid ${props.borderColor}`,
        borderRight: `1px solid ${props.borderColor}`,
        borderRadius: '5px',
        marginTop: '0.5em',
    }),
    childrenContainer: (props) => ({
        // padding: '0.5em',
    }),
    header: (props) => ({
        display: 'flex',
        flexDirection: 'row',
        width: '100% !important',
        color: `${props.borderColor}`
    }),
    headerBorderBefore: (props) => ({
        borderTop: `1px solid ${ props.borderColor }`,
        width: '1em',
        borderTopLeftRadius: '5px',
    }), 
    headerTitle: (props) => ({
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignItems: 'center',
        gap: '0.25em',
        width: 'fit-content',
        height: '2em',
        margin: '-1em 0.5em 0em 0.5em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: '1em',
        // fontWeight: '600',
    }),
    headerBorderAfter: (props) => ({
        borderTop: `1px solid ${ props.borderColor }`,
        width: '1em',
        flexGrow: '2',
        borderTopRightRadius: '5px',
    })
})

const CustomBox = ({ icon, label, children, ...otherProps }) => {

    const cls = useStyles({ borderColor: '#b2b2b2' })
    return (
        <Box className={cls.mainContainer}>
            <Box className={cls.header}>
                <Box className={cls.headerBorderBefore}></Box>
                {(icon || label) && (
                    <Box className={cls.headerTitle}>
                        {icon && <SvgIcon component={icon} />}
                        {label && <span className={cls.label}>{label}</span>}
                    </Box>
                )}
                <Box className={cls.headerBorderAfter}></Box>
            </Box>
            <Box className={cls.childrenContainer}>{children}</Box>
        </Box>
    )
}

export default CustomBox