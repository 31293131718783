import { connect } from 'react-redux';
import * as React from 'react';

import clsx from 'clsx';
import { debounce } from 'throttle-debounce';
import * as SensitivityStore from "@Store/SensitivityStore";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import { default as MuiTable } from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import ReplayIcon from '@mui/icons-material/Replay';
import InputAdornment from '@mui/material/InputAdornment';
import { BondStatus } from '@Components/Definitions';

import stringInject from 'stringinject';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import { Theme } from '@mui/material/styles';

import withStyles from '@mui/styles/withStyles';

import Typography from '@mui/material/Typography';
import Localization from '../../../../components/Localization';
import Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import HC_exporting_data from 'highcharts/modules/export-data';
import HighchartsReact from 'highcharts-react-official';
import HC_more from "highcharts/highcharts-more"; //module
import HC_draggable_points from "highcharts/modules/draggable-points";

import * as DomRfStore from "@Store/DomRfStore";
import { valueOrDefault, setHighchartsLocalization, copyTextToClipboard, toUnixDate } from '@Utilities';
import { CouponTypeStr } from '../../../../components/Definitions';
import style from '@Styles/domrf-theme.module.scss';
import { withTranslation } from "react-i18next";
import TooltipInfoLabel from '@Components/shared/TooltipInfoLabel';
import TableInfoTooltip from '../../../../components/shared/TableInfoTooltip';
import { ValidatorForm, TextValidator, SelectValidator, DatePickerValidator, DateTimePickerValidator, ComboboxValidator } from "@Framework/components/Form";
import { Box, Divider } from '@mui/material';
import { BackspaceOutlined, HelpOutline } from '@mui/icons-material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

const elementsCount: number = 15;

const styles = (theme: Theme) => ({

    layoutRow: {
        display: 'flex',
        alignItems: 'flex-end',
        overflowX: 'hidden'
        //marginLeft: '15px',
        //marginRight: '15px'
    },
    table: {
        tableLayout: 'fixed',
        minWidth: (elementsCount + 1) * 70 + 'px'
    },
    scrollingRow: {
        overflowX: 'auto !important'
    },
    hoverTable: {

        tableLayout: 'fixed',
        //overflowX: 'hidden',
        minWidth: (elementsCount + 1) * 70 + 'px',

        '& th': {
            cursor: 'pointer',
            position: 'relative',
            outline: '0'
        }
    },
    /*
    row: {
        borderTop: '1px solid ' + style.colorPrimary3_40,
        borderBottom: '1px solid ' + style.colorPrimary3_40
    },
    */
    headerCell: {
        backgroundColor: 'white'
    },
    stepHeaderTooltip: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },
    cell: {

        backgroundColor: 'white',

        borderLeft: '1px solid ' + style.colorPrimary3_40,
        //borderRight: '1px solid ' + style.colorPrimary3_40,
        borderTop: '1px solid ' + style.colorPrimary3_40,
        borderBottom: '1px solid ' + style.colorPrimary3_40,
        fontSize: '14px',

        cursor: 'pointer',
        //position: 'relative',
        //outline: '0'

        '&:last-child': {
            borderRight: '1px solid ' + style.colorPrimary3_40
        }
    },
    selectedHeaderCell: {
        backgroundColor: style.colorPrimary2_40
    },
    selectedCell: {
        backgroundColor: style.colorPrimary3_5
    },
    border: {
        border: '1px solid ' + style.colorPrimary1
    },
    //CHECKME!!! need to define widths globally
    container: {

        width: '100%',
        paddingRight: '15px',
        paddingLeft: '0',
        overflowX: 'hidden',

        /*
        [theme.breakpoints.up('xl')]: {
            maxWidth: '1400px'
        },
        [theme.breakpoints.down('xl')]: {
            maxWidth: '1400px'
        },
        [theme.breakpoints.down('lg')]: {
            maxWidth: '1300px'
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: '800px'
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: '550px'
        },
        */
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
            paddingRight: '15px',
            paddingLeft: '15px'
        }
    },
    grayCell: {
        color: style.colorPrimary3_40
    }
});

interface IProps {
    classes: any;
    t: any;

    cpr: any;
    cdr: any;
    results: any;
    selectedBond: any;
    variableFixType: any;

    backupCPR: any;

    zSpread: any;
    gSpread: any;
    couponRate: any;
    cleanPrice: any;
    dirtyPrice: any;
    couponType: any;

    priceMeasureType: boolean;

    onSettingsChanged: (settings: any) => void;
}

interface IState {

    step: number | string;
    firstCPR: number | string;
    secondCPR: number | string;
    thirdCPR: number | string;

    firstCPRSelectedIndex: number | null;
    secondCPRSelectedIndex: number | null;
    thirdCPRSelectedIndex: number | null;

    inputOptions: any;

    steps: number[];
    stepMiddleIndex: number | null;

    backupedSecondCPR: number | string | null;

    [key: string]: any;
}

class SpectrumPanel extends React.Component<IProps, IState> {

    constructor(props) {
        super(props);

        this.stepContainerRef = React.createRef();
        this.firstCPRContainerRef = React.createRef();
        this.secondCPRContainerRef = React.createRef();
        this.thirdCPRContainerRef = React.createRef();


        this.state = {
            step: 5,
            firstCPR: '',
            secondCPR:  valueOrDefault(this.props.cpr, ''),
            thirdCPR: '',
            inputOptions: '',
            steps: new Array(elementsCount).fill(''),

            firstCPRSelectedIndex: null,
            secondCPRSelectedIndex: null,
            thirdCPRSelectedIndex: null,

            stepMiddleIndex: null,
            backupedSecondCPR: null
        }
    }

    submitForm = null;

    stepContainerRef = null;
    firstCPRContainerRef = null;
    secondCPRContainerRef = null;
    thirdCPRContainerRef = null;

    componentDidMount() {
        Localization.onLanguageChanged(this.onLanguageChanged);
    }

    componentWillUnmount() {
        Localization.offLanguageChanged(this.onLanguageChanged);
    }

    componentDidUpdate(prevProps, prevState) {

        if (this.props.backupCPR != prevProps.backupCPR || this.props.backupCPR != this.state.backupedSecondCPR) {
            this.setState({
                backupedSecondCPR: this.props.backupCPR
            });
        }

        if (this.state.step != prevState.step) {

            var newSteps = new Array(elementsCount).fill('');
            var step = parseFloat(this.state.step.toString()) || 0;
            if (this.state.step != 0 && this.state.inputOptions.stepMiddle != '') {

                var min = this.state.inputOptions.stepMiddle - step * Math.floor(elementsCount / 2); //because we have elementsCount cells
                for (var i = 0; i < elementsCount; i++) {
                    min = Math.round(min * 100) / 100;
                    newSteps[i] = min;
                    min += step;
                }
            }


            var changes = { steps: newSteps };
            this.setState(changes);
        }

        if (this.props.cpr != prevProps.cpr ||
            this.props.cdr != prevProps.cdr ||
            this.props.selectedBond != prevProps.selectedBond ||
            this.props.variableFixType != prevProps.variableFixType ||
            this.props.zSpread != prevProps.zSpread ||
            this.props.gSpread != prevProps.gSpread ||
            this.props.couponRate != prevProps.couponRate ||
            this.props.cleanPrice != prevProps.cleanPrice ||
            this.props.dirtyPrice != prevProps.dirtyPrice ||
            this.props.priceMeasureType != prevProps.priceMeasureType ||
            this.state.inputOptions == '') {

            var inputOptions = this.formSpectrumOptions();

            var newSteps = new Array(elementsCount).fill('');
            var step = 0;
            
            if (this.props.priceMeasureType != prevProps.priceMeasureType) {
                //update step
                step = inputOptions.tableHeaderProps.step || 0;
            }
            else
                step = parseFloat(this.state.step.toString()) || inputOptions.tableHeaderProps.step || 0;

            if (step < inputOptions.tableHeaderProps.min || step > inputOptions.tableHeaderProps.max)
                step = inputOptions.tableHeaderProps.step;

            if (step != 0 && inputOptions.stepMiddle != '') {

                var min = inputOptions.stepMiddle - step * Math.floor(elementsCount / 2); //because we have elementsCount cells
                for (var i = 0; i < elementsCount; i++) {
                    min = Math.round(min * 100) / 100;
                    newSteps[i] = min;
                    min += step;
                }
            }

            var changes = { inputOptions: inputOptions, step: step, steps: newSteps };
            if (this.props.cpr != prevProps.cpr)
                changes.secondCPR = this.props.cpr;

            if (this.props.selectedBond?.id != prevProps.selectedBond?.id) {
                changes.firstCPR = '';
                changes.thirdCPR = '';
            }

            this.setState(changes, this.requestCalculation);
        }

        if (this.props.results != prevProps.results) {
            var inputOptions = this.formSpectrumOptions();
            var changes = {
                inputOptions: inputOptions
            };

            this.setState(changes);
        }
    }

    onLanguageChanged = (lng: any) => {

        var inputOptions = this.formSpectrumOptions();
        this.setState({inputOptions: inputOptions});
    }

    fillIfEmpty = (array: any[], count: number, round: boolean = false) => {

        if (array == null || array.length == 0)
            return new Array(count).fill('');

        if (round)
            return array.map(x => Math.round(x));

        return array;
    }

    formSpectrumOptions = () => {

        var type = 'GSpread';
        var stepMiddle: string | number = 0;
        var tableHeaderProps = {};
        var tableBodyProps = [];
        var params = null;
        var compareTypeIndex = null;
        if (this.props.selectedBond?.bondStatus == BondStatus.STATUS_PLACEMENT) {

            stepMiddle = this.props.couponRate;

            compareTypeIndex = 0; //'GSpread';

            type = 'CouponRate';

            tableHeaderProps = {
                stepName: Localization.Translate('ConventionStepCoupon'),
                stepTooltip: Localization.Translate('TooltipConventionStepCoupon'),
                name: Localization.Translate('Coupon'),
                tooltip: Localization.Translate('ScenarioAnalysisExplainedPlacement'),
                boundsError: '',
                step: 0.05,
                min: 0.01,
                max: 0.2
            };

            tableHeaderProps.boundsError = stringInject(this.props.t('ValueMustBeBetween_Params'), [tableHeaderProps.min, tableHeaderProps.max, this.props.t('PercentPoints')]);

            tableBodyProps = [
                { name: Localization.Translate('GSpread'), round: true },
                { name: Localization.Translate('YTM'), round: false },
                { name: Localization.Translate('ZSpread'), round: true },
                { name: Localization.Translate('Duration'), round: false }
            ];

            if (this.props.results?.firstCPR != null) {

                params = [
                    [
                        this.fillIfEmpty(this.props.results.firstCPR.gspread, elementsCount),
                        this.fillIfEmpty(this.props.results.firstCPR.ytm, elementsCount),
                        this.fillIfEmpty(this.props.results.firstCPR.zspread, elementsCount),
                        this.fillIfEmpty(this.props.results.firstCPR.durMac, elementsCount),
                    ],
                    [
                        this.fillIfEmpty(this.props.results.secondCPR.gspread, elementsCount),
                        this.fillIfEmpty(this.props.results.secondCPR.ytm, elementsCount),
                        this.fillIfEmpty(this.props.results.secondCPR.zspread, elementsCount),
                        this.fillIfEmpty(this.props.results.secondCPR.durMac, elementsCount),
                    ],
                    [
                        this.fillIfEmpty(this.props.results.thirdCPR.gspread, elementsCount),
                        this.fillIfEmpty(this.props.results.thirdCPR.ytm, elementsCount),
                        this.fillIfEmpty(this.props.results.thirdCPR.zspread, elementsCount),
                        this.fillIfEmpty(this.props.results.thirdCPR.durMac, elementsCount),
                    ],
                ];
            }
        }
        else {
            switch (this.props.variableFixType) {
                case 2:
                    {
                        if (this.props.selectedBond?.bondStatus != BondStatus.STATUS_PLACEMENT) {

                            compareTypeIndex = 0; //'GSpread';

                            if (this.props.couponType == CouponTypeStr.FIXED || this.props.couponType == CouponTypeStr.KEYRATE_AND_PREMIUM) {
                                stepMiddle = this.props.cleanPrice;

                                type = 'CleanPrice';

                                tableHeaderProps = {
                                    stepName: ((Localization.Translate('ConventionStepPrice') + ', ' + (this.props.priceMeasureType ? '₽' : '%'))),
                                    stepTooltip: Localization.Translate('TooltipConventionStepPrice'),
                                    name: Localization.Translate('CleanPrice'),
                                    tooltip: Localization.Translate('ScenarioAnalysisExplainedPrice'),
                                    step: this.props.priceMeasureType ? (Math.round(Number(stepMiddle) * 0.1) / 100) : 0.1,
                                    min: this.props.priceMeasureType ? (Math.round(Number(stepMiddle) * 0.01) / 100) : 0.01,
                                    max: this.props.priceMeasureType ? (Math.round(Number(stepMiddle)) / 100) : 1
                                };

                                
                            } else {
                                stepMiddle = this.props.dirtyPrice;

                                type = 'DirtyPrice';

                                tableHeaderProps = {
                                    stepName: ((Localization.Translate('ConventionStepPrice') + ', ' + (this.props.priceMeasureType ? '₽' : '%'))),
                                    stepTooltip: Localization.Translate('TooltipConventionStepPrice'),
                                    name: Localization.Translate('DirtyPrice'),
                                    tooltip: Localization.Translate('ScenarioAnalysisExplainedPrice'),
                                    step: this.props.priceMeasureType ? (Math.round(Number(stepMiddle) * 0.1) / 100) : 0.1,
                                    min: this.props.priceMeasureType ? (Math.round(Number(stepMiddle) * 0.01) / 100) : 0.01,
                                    max: this.props.priceMeasureType ? (Math.round(Number(stepMiddle)) / 100) : 1
                                };
                            }

                            tableHeaderProps.boundsError = stringInject(this.props.t('ValueMustBeBetween_Params'), [tableHeaderProps.min, tableHeaderProps.max, this.props.t('PercentPoints')]);

                            tableBodyProps = [
                                { name: Localization.Translate('GSpread'), round: true },
                                { name: Localization.Translate('YTM'), round: false },
                                { name: Localization.Translate('ZSpread'), round: true },
                                { name: Localization.Translate('Duration'), round: false }
                            ];

                            if (this.props.results?.firstCPR != null) {

                                params = [
                                    [
                                        this.fillIfEmpty(this.props.results.firstCPR.gspread, elementsCount),
                                        this.fillIfEmpty(this.props.results.firstCPR.ytm, elementsCount),
                                        this.fillIfEmpty(this.props.results.firstCPR.zspread, elementsCount),
                                        this.fillIfEmpty(this.props.results.firstCPR.durMac, elementsCount)
                                    ],
                                    [
                                        this.fillIfEmpty(this.props.results.secondCPR.gspread, elementsCount),
                                        this.fillIfEmpty(this.props.results.secondCPR.ytm, elementsCount),
                                        this.fillIfEmpty(this.props.results.secondCPR.zspread, elementsCount),
                                        this.fillIfEmpty(this.props.results.secondCPR.durMac, elementsCount)
                                    ],
                                    [
                                        this.fillIfEmpty(this.props.results.thirdCPR.gspread, elementsCount),
                                        this.fillIfEmpty(this.props.results.thirdCPR.ytm, elementsCount),
                                        this.fillIfEmpty(this.props.results.thirdCPR.zspread, elementsCount),
                                        this.fillIfEmpty(this.props.results.thirdCPR.durMac, elementsCount)
                                    ],
                                ];
                            }
                        }
                    }
                    break;
                case 3:
                    {
                        if (this.props.selectedBond?.bondStatus != BondStatus.STATUS_PLACEMENT) {
                            stepMiddle = this.props.zSpread;

                            type = 'ZSpread';

                            tableHeaderProps = {
                                stepName: Localization.Translate('ConventionStepZspread'),
                                stepTooltip: Localization.Translate('TooltipConventionStepZspread'),
                                name: Localization.Translate('ZSpread'),
                                tooltip: Localization.Translate('ScenarioAnalysisExplainedZspread'),
                                step: 5,
                                min: 1,
                                max: 20
                            };

                            tableHeaderProps.boundsError = stringInject(this.props.t('ValueMustBeBetween_Params'), [tableHeaderProps.min, tableHeaderProps.max, this.props.t('BasePoints')]);

                            if (this.props.couponType == CouponTypeStr.FIXED || this.props.couponType == CouponTypeStr.KEYRATE_AND_PREMIUM) {

                                compareTypeIndex = 0; //'CleanPrice';

                                tableBodyProps = [
                                    { name: Localization.Translate('CleanPrice'), round: false },
                                    { name: Localization.Translate('YTM'), round: false },
                                    { name: Localization.Translate('GSpread'), round: true },
                                    { name: Localization.Translate('Duration'), round: false }
                                ];

                                if (this.props.results?.firstCPR != null) {
                                    params = [
                                        [
                                            this.fillIfEmpty(this.props.results.firstCPR.cleanPrice, elementsCount),
                                            this.fillIfEmpty(this.props.results.firstCPR.ytm, elementsCount),
                                            this.fillIfEmpty(this.props.results.firstCPR.gspread, elementsCount),
                                            this.fillIfEmpty(this.props.results.firstCPR.durMac, elementsCount),
                                        ],
                                        [
                                            this.fillIfEmpty(this.props.results.secondCPR.cleanPrice, elementsCount),
                                            this.fillIfEmpty(this.props.results.secondCPR.ytm, elementsCount),
                                            this.fillIfEmpty(this.props.results.secondCPR.gspread, elementsCount),
                                            this.fillIfEmpty(this.props.results.secondCPR.durMac, elementsCount),
                                        ],
                                        [
                                            this.fillIfEmpty(this.props.results.thirdCPR.cleanPrice, elementsCount),
                                            this.fillIfEmpty(this.props.results.thirdCPR.ytm, elementsCount),
                                            this.fillIfEmpty(this.props.results.thirdCPR.gspread, elementsCount),
                                            this.fillIfEmpty(this.props.results.thirdCPR.durMac, elementsCount),
                                        ],
                                    ];
                                }

                            } else {

                                compareTypeIndex = 0; //'DirtyPrice';

                                tableBodyProps = [
                                    { name: Localization.Translate('DirtyPrice'), round: false },
                                    { name: Localization.Translate('YTM'), round: false },
                                    { name: Localization.Translate('GSpread'), round: true },
                                    { name: Localization.Translate('Duration'), round: false }
                                ];

                                if (this.props.results?.firstCPR != null) {
                                    params = [
                                        [
                                            this.fillIfEmpty(this.props.results.firstCPR.dirtyPrice, elementsCount),
                                            this.fillIfEmpty(this.props.results.firstCPR.ytm, elementsCount),
                                            this.fillIfEmpty(this.props.results.firstCPR.gspread, elementsCount),
                                            this.fillIfEmpty(this.props.results.firstCPR.durMac, elementsCount),
                                        ],
                                        [
                                            this.fillIfEmpty(this.props.results.secondCPR.dirtyPrice, elementsCount),
                                            this.fillIfEmpty(this.props.results.secondCPR.ytm, elementsCount),
                                            this.fillIfEmpty(this.props.results.secondCPR.gspread, elementsCount),
                                            this.fillIfEmpty(this.props.results.secondCPR.durMac, elementsCount),
                                        ],
                                        [
                                            this.fillIfEmpty(this.props.results.thirdCPR.dirtyPrice, elementsCount),
                                            this.fillIfEmpty(this.props.results.thirdCPR.ytm, elementsCount),
                                            this.fillIfEmpty(this.props.results.thirdCPR.gspread, elementsCount),
                                            this.fillIfEmpty(this.props.results.thirdCPR.durMac, elementsCount),
                                        ],
                                    ];
                                }
                            }
                        }
                    }
                    break;
                case 4:
                    {
                        if (this.props.selectedBond?.bondStatus != BondStatus.STATUS_PLACEMENT) {

                            type = 'GSpread';

                            stepMiddle = this.props.gSpread;

                            tableHeaderProps = {
                                stepName: Localization.Translate('ConventionStepGspread'),
                                stepTooltip: Localization.Translate('TooltipConventionStepGspread'),
                                name: Localization.Translate('GSpread'),
                                tooltip: Localization.Translate('ScenarioAnalysisExplainedGspread'),
                                step: 5,
                                min: 1,
                                max: 20
                            };

                            tableHeaderProps.boundsError = stringInject(this.props.t('ValueMustBeBetween_Params'), [tableHeaderProps.min, tableHeaderProps.max, this.props.t('BasePoints')]);

                            if (this.props.couponType == CouponTypeStr.FIXED || this.props.couponType == CouponTypeStr.KEYRATE_AND_PREMIUM) {

                                compareTypeIndex = 0; //'CleanPrice';

                                tableBodyProps = [
                                    { name: Localization.Translate('CleanPrice'), round: false },
                                    { name: Localization.Translate('YTM'), round: false },
                                    { name: Localization.Translate('ZSpread'), round: true },
                                    { name: Localization.Translate('Duration'), round: false }
                                ];

                                if (this.props.results?.firstCPR != null) {
                                    params = [
                                        [
                                            this.fillIfEmpty(this.props.results.firstCPR.cleanPrice, elementsCount),
                                            this.fillIfEmpty(this.props.results.firstCPR.ytm, elementsCount),
                                            this.fillIfEmpty(this.props.results.firstCPR.zspread, elementsCount),
                                            this.fillIfEmpty(this.props.results.firstCPR.durMac, elementsCount),
                                        ],
                                        [
                                            this.fillIfEmpty(this.props.results.secondCPR.cleanPrice, elementsCount),
                                            this.fillIfEmpty(this.props.results.secondCPR.ytm, elementsCount),
                                            this.fillIfEmpty(this.props.results.secondCPR.zspread, elementsCount),
                                            this.fillIfEmpty(this.props.results.secondCPR.durMac, elementsCount),
                                        ],
                                        [
                                            this.fillIfEmpty(this.props.results.thirdCPR.cleanPrice, elementsCount),
                                            this.fillIfEmpty(this.props.results.thirdCPR.ytm, elementsCount),
                                            this.fillIfEmpty(this.props.results.thirdCPR.zspread, elementsCount),
                                            this.fillIfEmpty(this.props.results.thirdCPR.durMac, elementsCount),
                                        ],
                                    ];
                                }
                            }
                            else {

                                compareTypeIndex = 0; //'DirtyPrice';

                                tableBodyProps = [
                                    { name: Localization.Translate('DirtyPrice'), round: false },
                                    { name: Localization.Translate('YTM'), round: false },
                                    { name: Localization.Translate('ZSpread'), round: true },
                                    { name: Localization.Translate('Duration'), round: false }
                                ];

                                if (this.props.results?.firstCPR != null) {
                                    params = [
                                        [
                                            this.fillIfEmpty(this.props.results.firstCPR.dirtyPrice, elementsCount),
                                            this.fillIfEmpty(this.props.results.firstCPR.ytm, elementsCount),
                                            this.fillIfEmpty(this.props.results.firstCPR.zspread, elementsCount),
                                            this.fillIfEmpty(this.props.results.firstCPR.durMac, elementsCount),
                                        ],
                                        [
                                            this.fillIfEmpty(this.props.results.secondCPR.dirtyPrice, elementsCount),
                                            this.fillIfEmpty(this.props.results.secondCPR.ytm, elementsCount),
                                            this.fillIfEmpty(this.props.results.secondCPR.zspread, elementsCount),
                                            this.fillIfEmpty(this.props.results.secondCPR.durMac, elementsCount),
                                        ],
                                        [
                                            this.fillIfEmpty(this.props.results.thirdCPR.dirtyPrice, elementsCount),
                                            this.fillIfEmpty(this.props.results.thirdCPR.ytm, elementsCount),
                                            this.fillIfEmpty(this.props.results.thirdCPR.zspread, elementsCount),
                                            this.fillIfEmpty(this.props.results.thirdCPR.durMac, elementsCount),
                                        ],
                                    ];
                                }
                            }
                        }
                    }
                    break;
                default:
                    throw 'unknown fix type!';
            }
        }

        if (params == null) //no data, fill by empty values
        {
            params = [
                [
                    new Array(elementsCount).fill(''),
                    new Array(elementsCount).fill(''),
                    new Array(elementsCount).fill(''),
                    new Array(elementsCount).fill('')
                ],
                [
                    new Array(elementsCount).fill(''),
                    new Array(elementsCount).fill(''),
                    new Array(elementsCount).fill(''),
                    new Array(elementsCount).fill('')
                ],
                [
                    new Array(elementsCount).fill(''),
                    new Array(elementsCount).fill(''),
                    new Array(elementsCount).fill(''),
                    new Array(elementsCount).fill('')
                ]
            ];
        }

        return {
            cpr: this.props.cpr,
            cdr: this.props.cdr,
            couponType: this.props.couponType,
            bondStatus: this.props.selectedBond?.bondStatus,
            stepMiddle: stepMiddle,
            stepMiddleIndex: Math.floor(elementsCount / 2),
            variableFixName: type,
            compareTypeIndex: compareTypeIndex,
            tableHeaderProps: tableHeaderProps,
            tableBodyProps: tableBodyProps,
            params: params
        }
    }

    handleValueChange = (name: keyof IState) => (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ [name]: event.currentTarget.value }, this.requestCalculation);
    }

    isValid = async () => {
        var isValid = await this.submitForm.isFormValid(false);

        //when switching to a bond with a "placement" state, we don't have coupon rate to create steps array
        //and we prevent page from update
        isValid = isValid && this.state.inputOptions.stepMiddle != 0; /* && this.state.steps.every(x => x != '');*/

        return isValid;
    }

    //
    requestCalculation = debounce(500, async () => {

        var isValid = await this.isValid();

        var settings = {
            isValid: isValid,
            checkCouponRate: this.state.inputOptions.stepMiddle,
            [this.state.inputOptions.variableFixName]: this.state.steps,
            FirstCPR: this.state.firstCPR,
            SecondCPR: this.state.secondCPR,
            ThirdCPR: this.state.thirdCPR,
        };

        this.props.onSettingsChanged(settings);
    });

    CPRCDRSumValidate = (cpr: string | number) => {
        return (parseFloat((cpr || 0).toString()) + parseFloat(this.state.inputOptions.cdr.toString())) < 100;
    }

    ValidateMin = (value: string | number, bound: string | number) => {
        return parseFloat(value.toString()) >= parseFloat(bound.toString());
    }

    ValidateMax = (value: string | number, bound: string | number) => {
        return parseFloat(value.toString()) <= parseFloat(bound.toString());
    }

    getCompareIndex(array: any[], value) {

        var index = null;
        var minDelta = null;
        var newDelta = null;
        for (var i = 0; i < array.length; i++) {

            if (array[i] == '')
                continue;

            newDelta = Math.abs(value - array[i])
            if (minDelta == null) {
                index = i;
                minDelta = newDelta;
                continue;
            }
            else if (minDelta > newDelta) {
                minDelta = newDelta;
                index = i;
            }
        }

        return index;
    }

    firstCPRCell = (props) => {
        return <td {...props} onMouseOver={this.firstCPRCellOver} onMouseOut={this.cprCellOut} />;
    }

    cprCellOut = (evt) => {

        //reset only if mouse moved out
        //if (evt.relatedTarget?.nodeName != 'TD')
        this.setState({ firstCPRSelectedIndex: null, secondCPRSelectedIndex: null, thirdCPRSelectedIndex: null });
    }

    firstCPRCellOver = (evt) => {

        if (this.state.firstCPRSelectedIndex != evt.target.cellIndex - 1) {

            if (this.state.inputOptions.params[0][this.state.inputOptions.compareTypeIndex].every(x => x == '')) {
                this.setState({ firstCPRSelectedIndex: null, secondCPRSelectedIndex: null, thirdCPRSelectedIndex: null });
            }
            else {

                var value = this.state.inputOptions.params[0][this.state.inputOptions.compareTypeIndex][evt.target.cellIndex - 1];

                var secondIndex = this.getCompareIndex(this.state.inputOptions.params[1][this.state.inputOptions.compareTypeIndex], value);
                var thirdIndex = this.getCompareIndex(this.state.inputOptions.params[2][this.state.inputOptions.compareTypeIndex], value);


                this.setState({ firstCPRSelectedIndex: evt.target.cellIndex - 1, secondCPRSelectedIndex: secondIndex, thirdCPRSelectedIndex: thirdIndex });
            }
        }
    }

    secondCPRCell = (props) => {
        return <td {...props} onMouseOver={this.secondCPRCellOver} onMouseOut={this.cprCellOut} />;
    }

    secondCPRCellOver = (evt) => {

        if (this.state.secondCPRSelectedIndex != evt.target.cellIndex - 1) {

            if (this.state.inputOptions.params[1][this.state.inputOptions.compareTypeIndex].every(x => x == '')) {
                this.setState({ firstCPRSelectedIndex: null, secondCPRSelectedIndex: null, thirdCPRSelectedIndex: null });
            }
            else {

                var value = this.state.inputOptions.params[1][this.state.inputOptions.compareTypeIndex][evt.target.cellIndex - 1];

                var firstIndex = this.getCompareIndex(this.state.inputOptions.params[0][this.state.inputOptions.compareTypeIndex], value);
                var thirdIndex = this.getCompareIndex(this.state.inputOptions.params[2][this.state.inputOptions.compareTypeIndex], value);


                this.setState({ firstCPRSelectedIndex: firstIndex, secondCPRSelectedIndex: evt.target.cellIndex - 1, thirdCPRSelectedIndex: thirdIndex });
            }
        }
    }

    thirdCPRCell = (props) => {
        return <td {...props} onMouseOver={this.thirdCPRCellOver} onMouseOut={this.cprCellOut} />;
    }

    thirdCPRCellOver = (evt) => {

        if (this.state.thirdCPRSelectedIndex != evt.target.cellIndex - 1) {

            if (this.state.inputOptions.params[2][this.state.inputOptions.compareTypeIndex].every(x => x == '')) {
                this.setState({ firstCPRSelectedIndex: null, secondCPRSelectedIndex: null, thirdCPRSelectedIndex: null });
            }
            else {

                var value = this.state.inputOptions.params[2][this.state.inputOptions.compareTypeIndex][evt.target.cellIndex - 1];

                var firstIndex = this.getCompareIndex(this.state.inputOptions.params[0][this.state.inputOptions.compareTypeIndex], value);
                var secondIndex = this.getCompareIndex(this.state.inputOptions.params[1][this.state.inputOptions.compareTypeIndex], value);


                this.setState({ firstCPRSelectedIndex: firstIndex, secondCPRSelectedIndex: secondIndex, thirdCPRSelectedIndex: evt.target.cellIndex - 1 });
            }
        }
    }

    isRound = () => {
        return this.state.inputOptions?.variableFixName == 'GSpread' || this.state.inputOptions?.variableFixName == 'ZSpread';
    }

    handleRestoreCPR = (event) => {
        this.setState({ secondCPR: this.state.backupedSecondCPR }, this.requestCalculation);
    }

    handleRestoreStep = (event) => {
        this.setState({ step: this.state.inputOptions.tableHeaderProps?.step }, this.requestCalculation);
    }

    onScrollTable = (event) => {
        this.stepContainerRef.current.scrollTo(event.currentTarget.scrollLeft, 0);
        this.firstCPRContainerRef.current.scrollTo(event.currentTarget.scrollLeft, 0);
        this.secondCPRContainerRef.current.scrollTo(event.currentTarget.scrollLeft, 0);
    }

    checkStepSelections = (index: number) => {

        if (this.state.firstCPRSelectedIndex == null &&
            this.state.secondCPRSelectedIndex == null &&
            this.state.thirdCPRSelectedIndex == null)
            return {};

        return (
            this.state.firstCPRSelectedIndex == index ||
            this.state.secondCPRSelectedIndex == index ||
            this.state.thirdCPRSelectedIndex == index)
            ? this.props.classes.selectedCell : this.props.classes.grayCell;
    }

    checkGraySelection = (currentIndex: number | null, index: number, rowIndex: number) => {
        if (this.state.firstCPRSelectedIndex == null &&
            this.state.secondCPRSelectedIndex == null &&
            this.state.thirdCPRSelectedIndex == null)
            return {};

        if (currentIndex == index) {
            return rowIndex == 0 ? this.props.classes.selectedHeaderCell : this.props.classes.selectedCell;
        }

        return this.props.classes.grayCell;
    }

    render() {

        const { classes, t } = this.props;

        return (<>
             {/* <div className={classes.container}> */}
                <Box sx={{display:'flex', pl:1,
                            flexWrap: 'nowrap',
                            flexDirection: 'row',
                            alignContent: 'center',
                            alignItems: 'baseline',
                            }}
                >
                    <Typography sx={{textTransform:'uppercase', lineHeight: '2rem', maxHeight: '2rem', overflow:'hidden', ml:1,
                                    display:'flex', alignItems:'center',
                                    flexGrow:1, fontSize:'14px', color: style.colorPrimary3 }} >
                    {this.props.t('ScenarioAnalisys')}
                    <TooltipInfoLabel right content={this.props.t('ScenarioAnalysis_Info')}/>
                    </Typography>
                </Box>
                <Divider sx={{opacity:'0.2'}}/>
                <Box sx={{m:1,p:0, overflow:'hidden'}}>
                <ValidatorForm onSubmit={() => { }}
                    ref={x => this.submitForm = x}>
                    <Grid container>
                        <Grid>
                            <Grid container className="pb-md-3">
                                <div className="col-md-3 col-lg-2 pb-3 pb-sm-0">
                                    <TextValidator
                                        needConfirm
                                        type="number"
                                        label={this.state.inputOptions.tableHeaderProps?.stepName}
                                        name="step"
                                        id="step"
                                        tooltip={this.state.inputOptions.tableHeaderProps?.stepTooltip}
                                        fullWidth={true}
                                        onChange={this.handleValueChange('step')}
                                        value={this.state.step}
                                        validators={[
                                            'required',
                                            (val) => this.ValidateMin(val, this.state.inputOptions.tableHeaderProps?.min),
                                            (val) => this.ValidateMax(val, this.state.inputOptions.tableHeaderProps?.max)
                                        ]}
                                        errorMessages={[
                                            t('FieldRequired'),
                                            this.state.inputOptions.tableHeaderProps?.boundsError,
                                            this.state.inputOptions.tableHeaderProps?.boundsError
                                        ]}
                                        InputProps={{
                                            endAdornment: (this.state.inputOptions.tableHeaderProps?.step != this.state.step) && (
                                                <InputAdornment position="end">
                                                    <Tooltip
                                                        disableFocusListener
                                                        //interactive
                                                        title={t('BackToDefaultValue')}
                                                    >
                                                        <IconButton onClick={this.handleRestoreStep} size="large">
                                                            <ReplayIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </div>
                                <div id='stepContainer' ref={this.stepContainerRef} className={clsx('col-md-9 col-lg-10', classes.layoutRow, "px-0 pb-sm-3")} onScroll={this.onScrollTable}>
                                    <MuiTable aria-label="step table" size="small" className={classes.table} sx={{mr:'12px'}}>
                                        <TableBody>
                                            <TableRow key={'step-row'}>
                                                <TableCell key='step-header' colSpan={3} align="right" className={classes.headerCell}>
                                                    <TooltipInfoLabel content={this.state.inputOptions.tableHeaderProps?.tooltip} className={classes.stepHeaderTooltip}>
                                                        {this.state.inputOptions.tableHeaderProps?.name}
                                                    </TooltipInfoLabel>
                                                </TableCell>
                                                {
                                                    this.state.steps.map((item, index) => {
                                                        return (
                                                            <TableCell key={'step-' + index} align="right" className={clsx(classes.cell, this.checkStepSelections(index), (index == this.state.inputOptions.stepMiddleIndex ? classes.border : {}))}>
                                                                {Localization.FormatNumber(item, this.isRound() ? 0 : 2)}
                                                            </TableCell>
                                                        )
                                                    })
                                                }
                                            </TableRow>
                                        </TableBody>
                                    </MuiTable>
                                </div>
                            </Grid>

                            <Grid container className="pb-3">
                                <div className='col-md-3 col-lg-2 pb-3 pb-sm-0'>
                                    <TextValidator
                                        needConfirm
                                        type="number"
                                        label={t('ScenarioAnalysisFirstAndThirdCPRName')}
                                        name="firstCPR"
                                        id="firstCPR"
                                        tooltip={this.props.t('ScenarioAnalysisFirstAndThirdCPRTooltip')}
                                        fullWidth={true}
                                        onChange={this.handleValueChange('firstCPR')}
                                        value={this.state.firstCPR}
                                        validators={['maxFloat:100', 'minFloat:0', this.CPRCDRSumValidate]}
                                        errorMessages={[t('ValueMustBeLesserThan100'), t('ValueMustBeGreaterThan0'), t('CPRCDRSumMustBeLessOf100')]}
                                    /*
                                    InputProps={
                                        (this.state.backupedCPR != '') ? {
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <Tooltip
                                                        disableFocusListener
                                                        //interactive
                                                        title={this.state.isPlacementCPR ? t('TooltipConventionRefreshCPRPlacement') : t('TooltipConventionRefreshCPR')}
                                                    >
                                                        <IconButton onClick={this.handleRestoreCPR}>
                                                            <ReplayIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </InputAdornment>
                                            )
                                        } : {}}
                                     */
                                    />
                                </div>
                                <div id='firstCPRContainer' ref={this.firstCPRContainerRef} className={clsx('col-md-9 col-lg-10', classes.layoutRow, 'px-0 pb-sm-3')} onScroll={this.onScrollTable}>
                                    <MuiTable aria-label="first cpr table" size="small" className={classes.hoverTable} sx={{mr:'12px'}}>
                                        <TableBody>
                                            {
                                                this.state.inputOptions.tableBodyProps?.map((row, rowIndex) => {
                                                    return (
                                                        <TableRow key={'first-cpr-row-' + rowIndex}>
                                                            <TableCell key={'first-cpr-header-' + rowIndex} id={'first-cpr-header-' + rowIndex} colSpan={3} align="right" className={classes.headerCell}>{row.name}</TableCell>
                                                            {

                                                                this.state.inputOptions?.params[0][rowIndex].map((item, index) => {
                                                                    return (
                                                                        <TableCell key={'first-cpr-' + index} align="right" className={clsx(classes.cell, this.checkGraySelection(this.state.firstCPRSelectedIndex, index, rowIndex))} component={this.firstCPRCell}>
                                                                            {Localization.FormatNumber(item, row.round ? 0 : 2, row.round ? 0 : 2)}
                                                                        </TableCell>
                                                                    )
                                                                })
                                                            }
                                                        </TableRow>
                                                    )
                                                })
                                            }
                                        </TableBody>
                                    </MuiTable>
                                </div>
                            </Grid>

                            <Grid container className="pb-3">
                                <div className='col-md-3 col-lg-2 pb-3 pb-sm-0'>
                                    <TextValidator
                                        needConfirm
                                        type="number"
                                        label={t('ScenarioAnalysisSecondCPRName')}
                                        name="secondCPR"
                                        id="secondCPR"
                                        tooltip={this.props.t('ScenarioAnalysisSecondCPRTooltip')}
                                        fullWidth={true}
                                        onChange={this.handleValueChange('secondCPR')}
                                        value={this.state.secondCPR}
                                        validators={['required', 'maxFloat:100', 'minFloat:0', this.CPRCDRSumValidate]}
                                        errorMessages={[t('FieldRequired'), t('ValueMustBeLesserThan100'), t('ValueMustBeGreaterThan0'), t('CPRCDRSumMustBeLessOf100')]}

                                        InputProps={
                                            (this.state.backupedSecondCPR != '') ? {
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <Tooltip
                                                            disableFocusListener
                                                            //interactive
                                                            title={this.state.isPlacementCPR ? t('TooltipConventionRefreshCPRPlacement') : t('TooltipConventionRefreshCPR')}
                                                        >
                                                            <IconButton onClick={this.handleRestoreCPR} size="large">
                                                                <ReplayIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </InputAdornment>
                                                )
                                            } : {}}
                                    />
                                </div>
                                <div id='secondCPRContainer' ref={this.secondCPRContainerRef} className={clsx('col-md-9 col-lg-10', classes.layoutRow, 'px-0 pb-sm-3')} onScroll={this.onScrollTable}>
                                    <MuiTable aria-label="second cpr table" size="small" className={classes.hoverTable} sx={{mr:'12px'}}>
                                        <TableBody>
                                            {
                                                this.state.inputOptions.tableBodyProps?.map((row, rowIndex) => {
                                                    return (
                                                        <TableRow key={'second-cpr-row-' + rowIndex}>
                                                            <TableCell key={'second-cpr-header-' + rowIndex} colSpan={3} align="right" className={classes.headerCell}>{row.name}</TableCell>
                                                            {

                                                                this.state.inputOptions?.params[1][rowIndex].map((item, index) => {
                                                                    return (
                                                                        <TableCell key={'second-cpr-' + index} align="right" className={clsx(classes.cell, this.checkGraySelection(this.state.secondCPRSelectedIndex, index, rowIndex), (index == this.state.inputOptions.stepMiddleIndex ? classes.border : {}))} component={this.secondCPRCell}>
                                                                            {Localization.FormatNumber(item, row.round ? 0 : 2, row.round ? 0 : 2)}
                                                                        </TableCell>
                                                                    )
                                                                })
                                                            }
                                                        </TableRow>
                                                    )
                                                })
                                            }
                                        </TableBody>
                                    </MuiTable>
                                </div>
                            </Grid>

                            <Grid container>
                                <div className='col-md-3 col-lg-2 pb-3 pb-sm-0'>
                                    <TextValidator
                                        needConfirm
                                        type="number"
                                        label={t('ScenarioAnalysisFirstAndThirdCPRName')}
                                        name="thirdCPR"
                                        id="thirdCPR"
                                        tooltip={this.props.t('ScenarioAnalysisFirstAndThirdCPRTooltip')}
                                        fullWidth={true}
                                        onChange={this.handleValueChange('thirdCPR')}
                                        value={this.state.thirdCPR}
                                        validators={['maxFloat:100', 'minFloat:0', this.CPRCDRSumValidate]}
                                        errorMessages={[t('ValueMustBeLesserThan100'), t('ValueMustBeGreaterThan0'), t('CPRCDRSumMustBeLessOf100')]}
                                    /*
                                    InputProps={
                                        (this.state.backupedCPR != '') ? {
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <Tooltip
                                                        disableFocusListener
                                                        //interactive
                                                        title={this.state.isPlacementCPR ? t('TooltipConventionRefreshCPRPlacement') : t('TooltipConventionRefreshCPR')}
                                                    >
                                                        <IconButton onClick={this.handleRestoreCPR}>
                                                            <ReplayIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </InputAdornment>
                                            )
                                        } : {}}
                                     */
                                    />
                                </div>
                                <div id='thirdCPRContainer' ref={this.thirdCPRContainerRef} className={clsx(classes.layoutRow, classes.scrollingRow, 'px-0 col-md-9 col-lg-10 ')} onScroll={this.onScrollTable}>
                                    <MuiTable aria-label="third cpr table" size="small" className={classes.hoverTable}  sx={{mr:'12px'}}>
                                        <TableBody>
                                            {
                                                this.state.inputOptions.tableBodyProps?.map((row, rowIndex) => {
                                                    return (
                                                        <TableRow key={'third-cpr-row-' + rowIndex}>
                                                            <TableCell key={'third-cpr-header-' + rowIndex} colSpan={3} align="right" className={classes.headerCell}>{row.name}</TableCell>
                                                            {

                                                                this.state.inputOptions?.params[2][rowIndex].map((item, index) => {
                                                                    return (
                                                                        <TableCell key={'third-cpr-' + index} align="right" className={clsx(classes.cell, this.checkGraySelection(this.state.thirdCPRSelectedIndex, index, rowIndex))} component={this.thirdCPRCell}>
                                                                            {Localization.FormatNumber(item, row.round ? 0 : 2, row.round ? 0 : 2)}
                                                                        </TableCell>
                                                                    )
                                                                })
                                                            }
                                                        </TableRow>
                                                    )
                                                })
                                            }
                                        </TableBody>
                                    </MuiTable>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </ValidatorForm >
                </Box>
             {/* </div> */}
            </>
        );
    }
}

export default withStyles(styles)(withTranslation('translations')(SpectrumPanel));