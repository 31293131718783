import ServiceBase from "./ServiceBase";
import Globals from "@Base/Globals";
import { Instruments } from "@Components/Instruments";

export default class InstrumentsService extends ServiceBase {

    static async getFavouriteInstruments() {

        var data = Instruments().filter(item =>
            (item.permissionRequired == null || Globals.hasPermissionAccess(item.permissionRequired)) &&
            item.favourite);

        return {
            value: data,
            hasErrors: false
        }
    }

    static async findInstruments(query) {

        var queryLower = query.toLowerCase();
        var data = Instruments().filter(item =>
            (item.permissionRequired == null || Globals.hasPermissionAccess(item.permissionRequired)) &&
            (
                item.title.toLowerCase().includes(queryLower) ||
                item.description.toLowerCase().includes(queryLower))
            );

        return {
            value: data,
            hasErrors: false
        }
    }

    static async getInstrument(area) {

        if (area == null) {
            return {
                value: null,
                hasErrors: true
            }

            alert('!!!!! error !!!!!');
        }

        var areaLower = area.toLowerCase();
        var data = Instruments().find(item =>
            (item.permissionRequired == null || Globals.hasPermissionAccess(item.permissionRequired)) &&
            ((item.area || "").toLowerCase() === areaLower)
        );

        return {
            value: data,
            hasErrors: false
        }
    }
}