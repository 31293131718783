import React, { useEffect, useState } from 'react'
import { base64ToBytes, bytesToBase64 } from '@Base/Utilities'
import { actionCreators as actionDownload } from '@Base/store/DownloadStore'
import { useDispatch } from 'react-redux'
import { Box, Fade, IconButton, alpha } from '@mui/material'
import { Close } from '@mui/icons-material'
import style from '@Styles/domrf-theme.module.scss'
import { createStyles, makeStyles } from '@mui/styles'
import { t } from 'i18next'

const useStyles = makeStyles((theme) => createStyles({
    root: {
        lineHeight: '1rem',
        pointerEvents: 'all',
        margin: '0.3rem 0 0 1rem',
        borderRadius: '0.5rem',
        border: `solid 1px`,
        borderColor: alpha(style.colorPrimary1, 1),
        width: 'fit-content !important',
        padding: '0.5rem',
        fontSize: '1rem',
        backgroundColor: '#FFF',//alpha(color, 0.1),
        color: alpha(style.colorPrimary1, 1),
        position: 'relative',
        whiteSpace: 'nowrap',
        float: 'left',
        '&:before': {
            position: 'absolute',
            left: 0, top: 0, right: 0, bottom: 0,
            content: '""',
            backgroundColor: alpha(style.colorPrimary1, 0.05),
        },

        display: 'flex',
        flexDirection: 'row',
        // flexGrow: 1,
        // overflow: 'hidden',
        // position: 'relative',
        alignItems: 'center',
        // justifyContent: 'center',
        // color: style.colorPrimary3,
        // border: `solid 1px style.colorPrimary3`,
        // borderRadius: '0.5rem',
    },
    removeButton:{
        marginLeft:'1rem',
    },
    icon: {
        width: '18px',
        height: '18px',
        color: style.colorPrimary3_60,
        '$:hover': {
            color: style.colorPrimary3,
        }
    },
    link: {
        pointerEvents: 'all',
        color: style.colorPrimary,
        borderBottom: `dashed 1px ${style.colorPrimary1}`,
        cursor: 'pointer !important'
    },
    fileName: {
        marginBottom: '1rem',
        fontSize: '1.2rem',
        color: style.colorPrimary1,
    }
}))


const DownloaderAlert = ({ id, link }) => {
    // const [url, setURL] = useState(null)
    const [fadeOn, setFadeOn] = useState(true)
    const [progress, setProgress] = useState(null)
    // const [isLoaded, setIsLoaded] = useState(false)
    const [error, setError] = useState(null)
    const [fileName, setFileName] = useState(null)
    // console.log('fileLoading, isLoaded ', fileLoading, isLoaded)
    // let [searchParams, setSearchParams] = useSearchParams()
    const dispatch = useDispatch()
    const cls = useStyles()

    useEffect(() => {
        restartDowload()
        // setURL(bytesToBase64(link))
    }, [link])

    const restartDowload = () => {
        if (link == null) {
            setError(0) // empty link
            return
        }
        const url = link
        // const url = base64ToBytes(link)
        // console.log('parametres changed', url)
        // setIsLoaded(false)
        readFile(url)
    }

    function downloadBlob(blob, name = 'file.txt') {
        // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
        const blobUrl = URL.createObjectURL(blob);

        // Create a link element
        const link = document.createElement("a");

        // Set link's href to point to the Blob URL
        link.href = blobUrl;
        link.download = name;

        // Append link to the body
        document.body.appendChild(link);

        // Dispatch click event on the link
        // This is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(
            new MouseEvent('click', {
                bubbles: true,
                cancelable: true,
                view: window
            })
        );

        // Remove link from body
        document.body.removeChild(link);
        // setIsLoaded(true)
        setTimeout(() => {
            removeDownload()
        }, 2000)

        // setProgress(null)
    }

    const removeDownload = () => {
        actionDownload.removeDownload(id)(dispatch)
        setFadeOn(false)
    }

    // Usage
    const getFileName = (disposition) => {
        const utf8FilenameRegex = /filename\*=UTF-8''([\w%\-\.]+)(?:; ?|$)/i
        const asciiFilenameRegex = /^filename=(["']?)(.*?[^\\])\1(?:; ?|$)/i

        let fileName = null;
        if (utf8FilenameRegex.test(disposition)) {
            fileName = decodeURIComponent(utf8FilenameRegex.exec(disposition)[1])
        } else {
            // prevent ReDos attacks by anchoring the ascii regex to string start and
            //  slicing off everything before 'filename='
            const filenameStart = disposition.toLowerCase().indexOf('filename=')

            if (filenameStart >= 0) {
                const partialDisposition = disposition.slice(filenameStart)
                const matches = asciiFilenameRegex.exec(partialDisposition)
                if (matches != null && matches[2]) {
                    fileName = matches[2]
                }
            }

        }
        return fileName
    }

    const readFile = async (url) => {
        if (url == null) {
            setError(0) // empty link
            return
        }

        try {

            let response = await fetch(url)
            const header = response.headers.get('Content-Disposition')

            if (!header) {
                setError(0) // file not found
                return
            }

            const filename = getFileName(header)
            setFileName(filename)

            //  get total length
            const contentLength = +response.headers.get('Content-Length');

            //  read the data
            const reader = response.body.getReader()
            let receivedLength = 0; // received that many bytes at the moment
            let chunks = []; // array of received binary chunks (comprises the body)
            while (true) {
                const { done, value } = await reader.read()

                if (done) {
                    break;
                }

                chunks.push(value);
                receivedLength += value.length;

                // console.log(`Received ${receivedLength} of ${contentLength} = ${receivedLength / contentLength * 100}%`)
                setProgress(Math.round(receivedLength / contentLength * 100))
                //https://developer.mozilla.org/en-US/docs/Web/API/Streams_API/Using_readable_streams
            }

            let theBlob = new Blob(chunks)
            // console.log('fileName', fileName)
            downloadBlob(theBlob, filename);
        }
        catch (error) {
            console.log('error', error)
        }
    }

    // console.log('progress', progress, fileName ,error)

    return (
        <Fade in={fadeOn} mountOnEnter unmountOnExit easing={{ enter: 'linear', exit: 'linear' }}>
            <Box className={cls.root}>
                {
                    error != null ?
                        <Box>
                            {t(`convention2.error${error}`)}
                        </Box>
                        :
                        progress != null ?
                            <Box>{`${t('convention2.download')} "${fileName}": ${progress}%`}</Box>
                            :
                            <Box>{t('convention2.downloadWillStart')}<span className={cls.link} onClick={restartDowload}>{t('convention2.here')}</span></Box>
                }
                <Box>
                    <IconButton size='small' variant='outlined' className={cls.removeButton} color={`${style.colorPrimary3}`} onClick={removeDownload}><Close className={cls.icon} /></IconButton>
                </Box>
            </Box>
        </Fade>
    )
}

export default DownloaderAlert