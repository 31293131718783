import IconButton from '@mui/material/IconButton';
import FirstPage from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPage from '@mui/icons-material/LastPage';
import makeStyles from '@mui/styles/makeStyles';
import * as React from 'react';
import { Localization } from '../DataGrid/Localization';

const useStyles = makeStyles({
    root: {
        flexShrink: 0,
    },
});

interface IProps {
    locale: Localization;
    count: number;
    isAdvanced: boolean;
    isLoading: boolean;
    page: number;
    rowsPerPage: number;
    onChangePage(event: React.MouseEvent<HTMLElement>, page: number): void;
}

const getPages = (currentPage: number, totalRows: number, rowsPerPage: number) => {
    const pages = [];

    // Default page limits
    const totalPages = Math.ceil(totalRows / rowsPerPage);
    let startPage = 1;
    let endPage = totalPages;
    const maxSize = 6;
    const isMaxSized = maxSize < totalPages;

    // recompute if maxSize
    if (isMaxSized) {
        // Current page is displayed in the middle of the visible ones
        startPage = Math.max(currentPage - Math.floor(maxSize / 2), 1);
        endPage = startPage + maxSize - 1;

        // Adjust if limit is exceeded
        if (endPage > totalPages) {
            endPage = totalPages;
            startPage = endPage - maxSize + 1;
        }
    }

    // Add page number links
    for (let num = startPage; num <= endPage; num++) {
        pages.push(num - 1);
    }

    return pages;
};

export const AdvancePaginationActions: React.FunctionComponent<IProps> = ({
    locale,
    count,
    isAdvanced,
    isLoading,
    page,
    rowsPerPage,
    onChangePage,
}) => {
    const classes = useStyles({});
    const pages = getPages(page, count, rowsPerPage);
    const lastPage = Math.ceil(count / rowsPerPage) - 1;
    const gotoPage = (value: number) => (e: any) => onChangePage(e, value);

    const gotoFirstPage = gotoPage(0);
    const gotoPrevPage = gotoPage(page - 1);
    const gotoNextPage = gotoPage(page + 1);
    const gotoLastPage = gotoPage(Math.max(0, lastPage));

    const canNotBack = page === 0 || isLoading;
    const canNotFwd = page === lastPage || isLoading;

    return (
        <div className={classes.root}>
            {isAdvanced && (
                <IconButton
                    onClick={gotoFirstPage}
                    disabled={canNotBack}
                    aria-label={locale.FirstPage}
                    size="large">
                    <FirstPage />
                </IconButton>
            )}
            <IconButton
                onClick={gotoPrevPage}
                disabled={canNotBack}
                aria-label={locale.PreviousPage}
                size="large">
                <KeyboardArrowLeft />
            </IconButton>

            {isAdvanced &&
                pages.map((value) => (
                    <IconButton
                        key={value}
                        onClick={gotoPage(value)}
                        disabled={value >= Math.ceil(count / rowsPerPage) || isLoading}
                        aria-label={`${locale.Page} ${value + 1}`}
                        color={value === page ? 'primary' : 'default'}
                        size="large">
                        {value + 1}
                    </IconButton>
                ))
            }

            <IconButton
                onClick={gotoNextPage}
                disabled={canNotFwd}
                aria-label={locale.NextPage}
                size="large">
                <KeyboardArrowRight />
            </IconButton>

            {isAdvanced && (
                <IconButton
                    onClick={gotoLastPage}
                    disabled={canNotFwd}
                    aria-label={locale.LastPage}
                    size="large">
                    <LastPage />
                </IconButton>
            )}
        </div>
    );
};
