import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import CircularProgress, { circularProgressClasses, CircularProgressProps } from '@mui/material/CircularProgress'


const CustomCircularProgress = (props: CircularProgressProps) => {
  const { sx, value =null, color, ...otherProps } = props
  const displayValue = value > 99 ? 99 : Math.round(value)
  // console.log('Progress value', value, ' dv = ',displayValue)

  return (
    <Box sx={{ position: 'relative', display: 'inline-flex', ...sx }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) => theme.palette.grey[300],
        }}
        size={24}
        thickness={6}
        {...otherProps}
        value={100}
      />
      <CircularProgress
        variant={value !== null ? "determinate" : "indeterminate"}
        disableShrink={value !== null ? false : true}
        // color='primary'
        sx={{
          animationDuration: '550ms',
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
          color: color
        }}
        value={displayValue}
        size={24}
        thickness={6}
        {...otherProps}
      />
      {value != null &&
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="caption"
            component="div"
            color="text.secondary"
            fontSize={otherProps?.size ? Math.round(otherProps?.size / 3) : 8}
            sx={{ letterSpacing: 'normal' }}
          >{`${displayValue}%`}</Typography>
        </Box>
      }
    </Box>
  )
}

export default CustomCircularProgress