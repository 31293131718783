import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import Tooltip from '@mui/material/Tooltip';
import FilterList from '@mui/icons-material/FilterList';
import TableFilterLabel from '../Filtering/TableFilterLabel';
import { ColumnModel, getColumnAlign, ColumnSortDirection, CompareOperators } from '../Common';
import { Localization } from './Localization';
import TableInfoTooltip from '../../../../components/shared/TableInfoTooltip';

interface IGridHeaderCellProps {
    column: ColumnModel;
    key: string;
    setActiveColumn: (column: ColumnModel, event: React.MouseEvent<HTMLElement>) => void;
    sortColumn: (property: string) => void;
    locale: Localization;
}

export const GridHeaderCell: React.FunctionComponent<IGridHeaderCellProps> =
    ({ column, sortColumn, setActiveColumn, locale }) => {

        const sort = () => sortColumn(column.Name);
        const handleClick = (e: any) => setActiveColumn(column, e);

        const direction = column.SortDirection === ColumnSortDirection.ASCENDING ||
            column.SortDirection === ColumnSortDirection.NONE ? 'asc' : 'desc';

        const render = (
            <TableInfoTooltip content={column.Tooltip}>
                {
                    column.Sortable ? (
                        <TableSortLabel
                            onClick={sort}
                            direction={direction}
                            active={column.SortDirection !== ColumnSortDirection.NONE}
                        >
                            <span dangerouslySetInnerHTML={{ __html: column.Label }}></span>
                        </TableSortLabel>
                    ) : (
                            <span dangerouslySetInnerHTML={{ __html: column.Label }}></span>
                        )
                }
            </TableInfoTooltip>
        );

        const filter = column.Filterable && (
            <TableFilterLabel
                id={column.Name}
                onClick={handleClick}
                active={column.Filter.HasFilter === true}
                iconColor={
                    column.Filter.HasFilter &&
                        column.Filter.Operator !== CompareOperators.NONE
                        ? 'action'
                        : 'disabled'
                }
            >
            </TableFilterLabel>
        );

        var styles = {};
        if (column.BreakSpace === true) {
            styles = { ...styles, 'whiteSpace': 'break-spaces' };
        }

        if (column.Width != null) {
            styles = { ...styles, 'width': column.Width };
        }

        return (
            <TableCell
                key={column.Label}
                padding={column.Label === '' ? 'none' : 'normal'}
                style={styles}
                align={getColumnAlign(column)}
            >
                {render}
                {filter}
            </TableCell>
        );
    };
