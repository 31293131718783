import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { TabPanel } from '@Base/Utilities'
import { Menu } from '@mui/icons-material'
import { ReactComponent as ExcelIcon } from "../../../../../src/images/excel-icon.svg"
import { alpha, Box, debounce, Grid, IconButton, Tab, Tabs, Theme, Tooltip } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { t } from 'i18next'
import PoolNodesList from './PoolNodesList'
import style from '@Styles/domrf-theme.module.scss'
import { actionCreators as actionSecuritization } from '@Base/store/SecuritizationStore'
import { useDispatch, useSelector } from 'react-redux'
import { PoolProcessingStatus } from '@Base/components/Definitions'
import CreateNodePool from './CreateNodePool'
import DealReleaseParametersTab from '../tabs/DealReleaseParametersTab'
import DealParametersTab from '../tabs/DealParametersTab'
import PricingTab from '../tabs/PricingTab'
import usePrevious from '@Base/components/hook/usePrevious'
import PoolStatisticTab from '../tabs/PoolStatisticTab'
import CustomMenu from '@Base/components/shared/CustomMenu'
import ConfirmationForm from "@Components/shared/ConfirmationForm";
import useMobileDetect from '@Base/components/hook/useMobileDetect'

const useStyles = makeStyles((theme: Theme) => createStyles({
    modernScroll: {
        overflowY: 'auto',
        overflowX: 'hidden',
        '&::-webkit-scrollbar': {
            width: '4px',
        },
        '&:hover': {
            '&::-webkit-scrollbar-track': {
                backgroundColor: '#00000008',
            },
            '&::-webkit-scrollbar-thumb': {
                background: '#00000018',
            }
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'unset',
            borderRadius: '3px',
            // border: 'solid 1px #00000000',
            backgroundColor: '#00000000',
            transition: 'all 0.3s ease'
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#0000',
            borderRadius: '3px',
            height: '4px'
        },
    },
    gridPanelLeft: {
        position: 'relative',
        [theme.breakpoints.down('xxl')]: {
            maxWidth: 'Calc(100% - 147px)',
            '& $gridPanelDataFirst': {
                margin: '4px'
                // left: '4px', top: 0, right: '4px', bottom: '4px',
            }

        },
    },
    gridPanelRight: {
        position: 'relative',
        // minWidth:'147px'
        [theme.breakpoints.down('xxl')]: {
            minWidth: '147px',
        },
    },
    gridPanel: {
        position: 'absolute', left: 0, top: 0, right: 0, bottom: 0,
        display: 'flex',
    },
    gridPanelDataFirst: {
        position: 'relative'
    },
    gridPanelDataSecond: {
        position: 'relative'
    },
    tabBox: {
        display: 'flex',
        position: 'absolute', left: 0, top: 0, right: 0, bottom: 0,
        flexGrow: 1,
        flexDirection: 'column',
    },
    tabLeftPadding: {
        paddingLeft: '4px'
    },
    tabRightPadding: {
        paddingRight: '4px'
    },
    fixedTabs: {
        overflow: 'visible !important',
    },
    dealTabContaner: {
        overflow: 'visible',
        borderTopLeftRadius: '0.6rem',
        borderTopRightRadius: '0.6rem',
        borderTop: `solid 1px ${style.colorPrimary3_5}`,
        borderLeft: `solid 1px ${style.colorPrimary3_5}`,
        borderRight: `solid 1px ${style.colorPrimary3_5}`,
        backgroundColor: 'transparent',
        // marginTop: '4px',
        minHeight: 'unset',
        '& .MuiTabs-indicator': {
            backgroundColor: 'transparent',
        }
    },
    fixedTab: {
        width: '40px !important',
        maxWidth: '40px !important',
        minWidth: '40px !important',
    },
    dealTab: {
        color: '#0005',
        // backgroundColor:'#0002',
        borderTopLeftRadius: '0.6rem',
        borderTopRightRadius: '0.6rem',
        // borderBottom: `solid 1px ${style.colorPrimary3_80}`,
        borderBottom: `solid 1px #dfdfe1`,
        borderRight: `solid 1px ${style.colorPrimary3_5}`,
        // borderTop: `solid 1px #0001`,
        marginTop: '-1px',
        padding: '4px 1rem',
        // opacity: '0.7',
        transition: 'all 0.2s ease-in-out',
        '&:hover': {
            backgroundColor: alpha(style.colorPrimary3, 0.05),
            // borderBottom: `none`,
            color: '#0008',
            fontWeight: 'bold',
        },
        '&.Mui-selected': {
            '&:nth-child(-1)': {
                backgroundColor: '#000',
            },
            backgroundColor: '#FFF',
            // zIndex: '+1',
            borderTopLeftRadius: '0.6rem',
            // borderTop: `solid 1px ${style.colorPrimary3_80}`,
            // borderRight: `solid 1px ${style.colorPrimary3_80}`,
            // borderLeft: `solid 1px ${style.colorPrimary3_80}`,
            borderTop: `solid 1px #dfdfe1`,
            borderRight: `solid 1px #dfdfe1`,
            borderLeft: `solid 1px #dfdfe1`,
            borderBottom: 'none',
            '&:hover': { color: 'initial', fontWeight: 'initial', },
        },
        '&.Mui-disabled': {
            color: style.colorPrimary3_20
        },
        '&.MuiTab-root': {
            minHeight: '2rem',
            height: '2rem',
        }
    },
    panel: {
        // borderBottom: `solid 1px ${style.colorPrimary3_80}`,
        // borderLeft: `solid 1px ${style.colorPrimary3_80}`,
        // borderRight: `solid 1px ${style.colorPrimary3_80}`,
        borderBottom: `solid 1px #dfdfe1`,
        borderLeft: `solid 1px #dfdfe1`,
        borderRight: `solid 1px #dfdfe1`,
        flexGrow: 1,
        margin: '0px 1px 1px 1px',
        position: 'relative',
    },
    alertWin: {
        display: 'flex',
        fontSize: '1.3rem',
        color: style.colorPrimary3_40,
        justifyContent: 'center',
        // alignItems: 'center',
    },
    tabRightElement: {
        position: 'absolute',
        right: 0,
    },
    clickable: {
        pointerEvents: 'all',
    }
}
))

const DealTemplate = ({/* dealId = null,*/ createdDealId = null, downloadTemplate, editPermission = null, creatingPoolEntity }) => {
    const classes = useStyles()
    const [tab1Index, setTab1Index] = useState(0)
    const [dealTabIndex, setDealTabIndex] = useState(0)
    const dispatch = useDispatch()
    const { deal = null, backupDeal = null,
        selectedNode = null, backupNode = null, createdNode = null, removedNode = null,
        createdDeal = null,
        poolsHubMessage,
        permissions
    } = useSelector((state: any) => state.securitization)
    const [selectedPool, setSelectedPool] = useState(null)
    const [openAddNodePool, setOpenAddNodePool] = useState(false)
    const [addNodePoolType, setAddNodePoolType] = useState(null)
    const [currentNodePoolId, setCurrentNodePoolId] = useState(null)
    const [selectedDeal, setSelectedDeal] = useState(deal)
    const prevDealId = usePrevious(selectedDeal?.deal?.id)
    // const prevNodeId = usePrevious(selectedNode?.node?.id)
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
    const device = useMobileDetect()

    const addNodePool = (type = null, selectedNodeId = null) => {
        if (type != null) {
            setCurrentNodePoolId(selectedNodeId)
            setAddNodePoolType(type)
            setOpenAddNodePool(true)
        }
        else {
            console.log('type is empty')
        }
    }

    const downloadPool = () => {
        if (selectedPool?.id != null)
            window.open(`/api/securitization/DownloadPoolLLD?poolId=${selectedPool?.id}`, "_blank");
    }

    const downloadStatistics = () => {
        if (selectedPool?.id != null)
            window.open(`/api/securitization/DownloadPoolStatistics?poolId=${selectedPool?.id}`, "_blank");
    }

    const downloadPoolFlows = () => {
        if (selectedPool?.id != null)
            window.open(`/api/securitization/DownloadPoolSchduledFlows?poolId=${selectedPool?.id}`, "_blank");
    }

    const downloadMonthlyPoolFlows = () => {
        if (selectedPool?.id != null)
            window.open(`/api/securitization/DownloadPoolSchduledFlowsMonthly?poolId=${selectedPool?.id}`, "_blank");
    }

    const closeAddNodePool = () => {
        setOpenAddNodePool(false)
    }

    const removeNodeHandler = () => {
        setShowConfirmationDialog(true);
    }

    const applyNodeRemove = () => {

        setShowConfirmationDialog(false);

        if (selectedNode?.node == null)
            return;

        actionSecuritization.removeNode(selectedNode.node.id)(dispatch);
    }

    const declineNodeRemove = () => {
        setShowConfirmationDialog(false);
    }

    const handleTabChange = (tabs = [], val = null, setDealTabIndex = (val) => { }) => {
        try {
            if (tabs[val]?.menu == true)
                return

            setDealTabIndex(val)
        } catch (error) {

        }
    }

    const tabs0 = [
        {
            order: 0,
            name: t('Parameters'),
            classes: { tab: classes.dealTab, data: classes.panel },
            content: <DealParametersTab
                deal={selectedDeal}
                pool={selectedPool}
                editPermission={editPermission} />,
        },
        {
            order: 1,
            name: t('PreIssue'),
            classes: { tab: classes.dealTab, data: classes.panel },
            content: <DealReleaseParametersTab
                deal={selectedDeal}
                editPermission={editPermission} />,
        },
    ]

    const tabs1 = [
        {
            order: 2,
            name: t('Statistic'),
            classes: { tab: classes.dealTab, data: classes.panel },
            content: <PoolStatisticTab
                pool={selectedPool}
            />,
        },
        {
            order: 3,
            name: t('Pricing'),
            classes: { tab: classes.dealTab, data: [classes.panel, classes.modernScroll].join(' ') },
            disabled: selectedPool?.status !== PoolProcessingStatus.STATUS_SUCCESS,
            content: <PricingTab
                editPermission={editPermission}
            />,
        },
        {
            order: 4,
            menu: true,
            name: (selectedPool?.id != null) &&
                <CustomMenu
                    icon={<Menu />}
                    className={classes.tabRightElement}
                    disabled={selectedPool?.status !== PoolProcessingStatus.STATUS_SUCCESS && selectedPool?.status !== PoolProcessingStatus.STATUS_FAILED}
                    items={
                        [
                            (permissions?.isAdmin || permissions?.isManager) ? { id: 1, name: t("RenewPool"), handle: () => addNodePool(selectedNode?.node?.type, selectedNode?.node?.id) } : null,
                            { id: 2, name: t("SecuritizationDownloadPool"), handle: downloadPool },
                            { id: 3, name: t("DownloadPoolStatistics"), handle: downloadStatistics },
                            { id: 4, name: t("SecuritizationDownloadPoolFlows"), handle: downloadPoolFlows },
                            { id: 5, name: t("SecuritizationDownloadMonthlyPoolFlows"), handle: downloadMonthlyPoolFlows },

                            ...(permissions?.isAdmin ? [{ id: 6, divider: true }, { id: 7, name: t("RemovePool"), handle: removeNodeHandler }] : [null])
                            /*{ id: 3, name: t("SucuritizationRemovePool"), handle: deletePool },*/
                        ]?.filter(item => item != null)}
                />,
            classes: { tab: [classes.clickable, classes.dealTab, classes.fixedTab].join(' ') }
        }
    ]

    const [rerenderState, setRerenderState] = useState(Math.random())
    const rerender = () => {
        setRerenderState(Math.random()) // need for faked Rerender during resize
    }

    useLayoutEffect(() => {
        if (window) {
            window.addEventListener('resize', debounce(rerender, 300))
            // rerender()
        }
        return () => {
            window.removeEventListener('resize', debounce(rerender, 300))
        }
    }, [])

    useEffect(() => {
        if (selectedPool != null && currentNodePoolId != null
            && ((selectedPool?.id == poolsHubMessage?.poolID) || (selectedNode?.node?.id == poolsHubMessage?.nodeID))) {
            // console.log('getNodeWithChanges')
            actionSecuritization.getNodeWithChanges(currentNodePoolId)(dispatch)
        }
    }, [poolsHubMessage])

    useLayoutEffect(() => {

        if (createdDeal?.deal?.id == deal?.deal?.id) {
            setDealTabIndex(0)
            actionSecuritization.clearCreatedDeal()(dispatch)
        }

        setSelectedDeal(deal)

    }, [deal])

    useEffect(() => {
        //if (selectedNode?.node?.id == createdNode?.id) {
        setSelectedPool(createdNode?.pools[0])
        setCurrentNodePoolId(createdNode?.id)
        setOpenAddNodePool(false)
        //}
    }, [createdNode])

    // useLayoutEffect(() => {

    // }, [createdDeal])

    useLayoutEffect(() => {
        // console.log('selectedPool', selectedPool)
        if (selectedPool?.status !== PoolProcessingStatus.STATUS_SUCCESS)
            setTab1Index(0)
    }, [selectedPool])

    useEffect(() => {

        if (
            // (selectedNode?.node?.id != prevNodeId)
            (selectedNode?.node?.id != currentNodePoolId)
            || (
                Array.isArray(selectedNode?.node?.pools) &&
                ((selectedNode?.node?.pools[0]?.id != selectedPool?.id)
                    || (selectedNode?.node?.pools[0]?.status != selectedPool?.status)
                    || (selectedNode?.node?.pools[0]?.statusString != selectedPool?.statusString)
                )
            )
        ) {
            //  console.log('setSelectedPool ****************', selectedNode?.node?.pools[0])

            setSelectedPool(selectedNode?.node?.pools[0])
            setCurrentNodePoolId(selectedNode?.node?.id)
            //CHECKME!!!
            //setOpenAddNodePool(false)
        }
    }, [selectedNode])


    useEffect(() => {
        console.log('removedNode', removedNode)
        console.log('selectedNode', selectedNode)
        
        if ((selectedDeal?.deal?.id != null || selectedDeal?.deal?.id != selectedNode?.node?.dealID)
            && (selectedNode?.node?.id == null || selectedDeal?.deal?.id != prevDealId || removedNode?.id == selectedNode?.node?.id)
        ) {
            let lastNode = selectedDeal?.deal?.poolNodes?.reduce((currentMax, current) => {
                if (Array.isArray(current?.pools) && current?.pools?.length > 0) {
                    if (currentMax != null) {
                        return (Date.parse(currentMax?.pools[0].createdDate) > Date.parse(current?.pools[0].createdDate)
                        ) ? currentMax : current
                    }
                    else {
                        return current
                    }
                }

                return currentMax

            }, null)

            // console.log('lastNode-----', lastNode)
            if (lastNode != null) {
                actionSecuritization.getNodeWithChanges(lastNode.id)(dispatch)
            }
            else
                // if (selectedNode?.node?.id == null)
                setSelectedPool(null)
        }

    }, [selectedDeal, removedNode])

    if (!deal) return <></>//<Box className={classes.alertWin}>{t('SelectDeal')}</Box>


    // console.log('rerender')
    const screenConditions = (window?.innerWidth < 900 || window.innerHeight < 768)

    return (
        <>
            <ConfirmationForm
                isOpen={showConfirmationDialog}
                header={t("RemovePool")}
                content={t("PoolRemoveWarning")}
                onAccept={applyNodeRemove}
                onDecline={declineNodeRemove}
            />

            {openAddNodePool &&
                <CreateNodePool
                    currentNodePoolId={currentNodePoolId}
                    addNodePoolType={addNodePoolType}
                    closeAddNodePool={closeAddNodePool}
                    downloadTemplate={downloadTemplate}
                />}
            <Grid container sx={{ flexGrow: 1 }}>
                <Grid item xs={10.8} className={classes.gridPanelLeft}>
                    <Grid container sx={{ flexGrow: 1 }} className={classes.gridPanel}>
                        <Grid item xs={12} xxl={4.2} className={classes.gridPanelDataFirst}>
                            <Box className={classes.tabBox}>
                                <Tabs
                                    className={classes.dealTabContaner}
                                    value={dealTabIndex}
                                    onChange={(e, val) => { handleTabChange((screenConditions ? [...tabs0, ...tabs1] : tabs0), val, setDealTabIndex) }}
                                variant="fullWidth"
                                classes={{ fixed: classes.fixedTabs }}
                                >
                                {(screenConditions ? [...tabs0, ...tabs1] : tabs0)?.map((tab, index) => {
                                    return (
                                        <Tab
                                            key={`tab0_${index}`}
                                            label={tab?.name}
                                            className={tab?.classes?.tab}
                                            disabled={tab?.disabled}
                                        />
                                    )
                                })}
                            </Tabs>
                            {(screenConditions ? [...tabs0, ...tabs1] : tabs0)?.map((tab, index) => {
                                return (
                                    <TabPanel
                                        key={`tab0Panel_${index}`}
                                        className={tab?.classes?.data} value={dealTabIndex} index={index}>
                                        {tab.content}
                                    </TabPanel>
                                )
                            })}
                        </Box>
                    </Grid>
                    {!screenConditions &&
                        <Grid item xs={12} xxl={7.8} className={classes.gridPanelDataSecond}>
                            <Box className={[classes.tabBox, classes.tabLeftPadding, classes.tabRightPadding].join(' ')}>
                                <Tabs
                                    className={classes.dealTabContaner}
                                    value={tab1Index}
                                    onChange={(e, val) => { handleTabChange(tabs1, val, setTab1Index) }}
                                    variant="fullWidth"
                                    classes={{ fixed: classes.fixedTabs }}
                                >
                                    {tabs1?.map((tab, index) => {
                                        return (
                                            <Tab
                                                key={`tab1_${index}`}
                                                label={tab?.name}
                                                className={tab?.classes?.tab}
                                                disabled={tab?.disabled}
                                            />
                                        )
                                    })}
                                </Tabs>
                                {tabs1?.map((tab, index) => {
                                    return (
                                        <TabPanel
                                            key={`tabPanel1_${index}`}
                                            className={tab?.classes?.data} value={tab1Index} index={index}>
                                            {tab.content}
                                        </TabPanel>
                                    )
                                })}
                            </Box>
                        </Grid>
                    }
                </Grid>
            </Grid>

            <Grid item xs={1.2} className={classes.gridPanelRight}>
                <PoolNodesList dealId={deal?.deal?.id} addNodePool={addNodePool}/*selectPool={selectPool}*/ />
            </Grid>
        </Grid >
        </>
    )
}

export default DealTemplate