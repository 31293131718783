import AccountService from "../services/AccountService";
import { ProcessingState } from "@Components/Definitions";

const Actions = {
    Init: "EMAIL_CONFIRM_INIT",
    EmailConfirmRequest: "EMAIL_CONFIRM_REQUEST",
    EmailConfirmSuccess: "EMAIL_CONFIRM_SUCCESS",
    EmailConfirmFailure: "EMAIL_CONFIRM_FAILURE"

}

export const actionCreators = {

    init: () => async (dispatch, getState) => {
        dispatch({ type: Actions.Init })
    },

    
    confirmRegistration: (model) => async (dispatch, getState) => {

        dispatch({ type: Actions.EmailConfirmRequest });
        var result = await AccountService.confirmEmail(model);

        if (result.hasErrors) {
            dispatch({ type: Actions.EmailConfirmFailure });
            return;
        }

        dispatch({ type: Actions.EmailConfirmSuccess });
    }
};

const initialState =
{
    confirmEmailState: ProcessingState.Undefined
};

export const reducer = (currentState, incomingAction) => {

    if (currentState === undefined)
        return initialState; 


    const action = incomingAction;

    switch (action.type) {
        case Actions.Init:
            {
                return initialState;
            }
        case Actions.EmailConfirmRequest:
            {
                return { ...currentState, confirmEmailState: ProcessingState.Request };
            }
        case Actions.EmailConfirmSuccess:
            {
                return { ...currentState, confirmEmailState: ProcessingState.Response_Success };
            }
        case Actions.EmailConfirmFailure:
            {
                return { ...currentState, confirmEmailState: ProcessingState.Response_Failed };
            }
        default:
            return currentState || initialState;
    }
};
