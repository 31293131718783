import * as React from 'react';
import TableCell from '@mui/material/TableCell';
import { ColumnModel, getColumnAlign, ColumnDataType } from '../Common';
import { RenderCellContent } from './RenderCellContent';
import { GridOptions } from '../DataGrid';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { IDataGrid } from '../DataGridInterfaces/IDataGrid';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: '0 ' + theme.typography.fontSize + 'px !important'
        }
    })
);

export const RenderCells: any = (grid: IDataGrid, columns: ColumnModel[], row: any, options: GridOptions) => {

    const classes = useStyles();

    return columns
        .filter((col: ColumnModel) => col.Visible)
        .map((column: ColumnModel) => {

            var styles = {};
            if (column.Width != null) {
                styles = { 'width': column.Width };
            }

            if (column.TrimContent != null) {
                styles = { ...styles, 'textOverflow': column.TrimContent, 'overflow': 'hidden', 'whiteSpace': 'nowrap' };
            }

            //if (column.Fixed)
                //return;

            return (
                <TableCell
                    className={(column.IsAction || column.DataType === ColumnDataType.BOOLEAN) ? classes.root : ''}
                    key={column.Name}
                    //padding={(column.Label === '') ? 'none' : 'default'} //we don't use styles at cell if rendering used
                    align={getColumnAlign(column)}
                    style={styles}>
                    {RenderCellContent(grid, column, row, options)}
                </TableCell>

            );
        });
}