import React, { useEffect } from 'react'
import Helmet from "react-helmet"
import { useDispatch } from 'react-redux'
import { actionCreators as actionConvention } from '@Base/store/ConventionStore'
import { actionCreators as actionTopNavbar } from '@Base/store/TopNavbarStore'
import { t } from 'i18next'
import { Box, Container, Grid, Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import InputPanel from './panels/InputPanel'
import FlowsChartPanel from './panels/FlowsChartPanel'
import FlowsPanel from './panels/FlowsPanel'
import SCurveChartPanel from './panels/SCurveChartPanel'
import { useSelector } from 'react-redux'
import Alert from './components/Alert'
import useMobileDetect from '@Base/components/hook/useMobileDetect'
import { getTooltip } from '@Base/components/shared/CustomTooltip'
import style from '@Styles/domrf-theme.module.scss'
import CurrentRateChartPanel from './panels/CurrentRateChartPanel'
import DownloaderAlert from '../downloader/DownloaderAlert'
import Notifications from './panels/Notifications'


const useStyles = makeStyles((theme: Theme) => createStyles({
    container: {
        minHeight: '725px',
        maxHeight: '830px',
        position: 'absolute',
        left: 0, top: 0, right: 0, bottom: 0
    },
    topContainer: {
        position: 'absolute',
        left: 0, right: 0,
        top: 0, bottom: '51%',
        overflow: 'hidden',
        minHeight: '355px',
    },
    bottomContainer: {
        position: 'absolute',
        left: 0, right: 0,
        top: '49%', bottom: 0,
        overflow: 'hidden',
        minHeight: '370px',
    },
    virtualScrollablePanel: {
        // border: 'solid 1px #000',
        // padding: '0 11px 0 0',
        flexGrow: 1,
        overflowY: 'scroll',
        overflowX: 'none',
        // marginTop: '0.5rem',
        '& *': { userSelect: 'none', },
        // '& .Mui-selected': {
        //     // backgroundColor: `#e4f1d5 !important`,
        //     backgroundColor: `#e4f1d500 !important`,
        // },
        position: 'absolute', left: 0, right: 0, top: 0, bottom: 0,
        // overflowY: 'auto',
        // padding: 0,
        '&::-webkit-scrollbar': {
            width: '4px',
            height: '6px',
        },
        // '&::-webkit-scrollbar:disabled':{
        //     opacity:0,
        // },
        '&:hover': {
            '&::-webkit-scrollbar-track:disabled': {
                backgroundColor: '#0000',
            },
            '&::-webkit-scrollbar-thumb:disabled': {
                background: '#0000',
            },
            // '&::-webkit-scrollbar-track': {
            //     backgroundColor: '#00000008',
            // },
            '&::-webkit-scrollbar-thumb': {
                background: '#00000018',
            }
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'unset',
            borderRadius: '3px',
            // border: 'solid 1px #00000000',
            backgroundColor: '#00000000',
            transition: 'all 0.3s ease'
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#0000',
            borderRadius: '3px',
            height: '4px',
        },
    },
    hidden: {
        display: 'none',
    },
    bottomInfo: {
        position: 'absolute',
        left: 0, right: 0, bottom: '21px',
        textAlign: 'center',
        color: style.colorPrimary3_60,
        // zIndex: 100,
        '& a': {
            color: 'rgb(0,176,80)'
        }
    },
    bottomInfoMobile: {
        // fontSize: '0.9em'
    },
    marginPanel: {
        marginLeft: '0.5rem',
        marginRight: '0.5rem',
        marginBottom: '0.5rem',
    },
    rootMobile: {
        minWidth: '100%',
    },
    rootInputPanel: {
        // height: '100%',
        minWidth: '396px',
        [theme.breakpoints.down(396)]: {
            minWidth: '358px',
        },
        // width:'396px',
        minHeight: 'Calc( 100vh - 101px)',
        position: 'relative',
    },
    rootFlowPanel: {
        // height: '100%',
        // width: 'auto',
        position: 'relative',
        // border: 'solid 1px',
        // overflow: 'hidden',
        // minWidth: '600px',
        marginLeft: '4px',
        maxWidth: '558px',
        maxHeight: 'Calc(100vh - 101px)',
        [theme.breakpoints.down(1030)]: {
            '&': { display: 'none' },
        },
    },
    rootChartsPanel: {
        // width: 'auto',
        // height: '100%',
        position: 'relative',
        maxHeight: 'Calc(100vh - 101px)',
        [theme.breakpoints.down(1520)]: {
            '&': { display: 'none' },
        },
    },
    fakedPreloadFont: {//need for PREloading font before render real elements with this font
        fontFamily: 'DINPro-Bold',
    },
    rootContainer: {

        '&:parent': {
            backgroundColor: '#0001',
        },
        '& >*>*': {
            // border: 'solid 1px #0002',
        },
        marginTop: '1rem',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        overflow: 'hidden',
        position: 'relative',
    },
    rootGridMobile: {
        flexGrow: 1,
        alignContent: 'flex-start',
        position: 'relative',
    },
    rootGrid: {
        flexGrow: 1,
        alignContent: 'flex-start',
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
    },
}))

const Convention2 = () => {

    const cls = useStyles()
    const dispatch = useDispatch()
    const {
        pricingParameters = null,
        pricingResult = null,
        calculatedParameters = null,
        ...otherStore } = useSelector((state: any) => state.convention)
    const { isMobile, isLandscape } = useMobileDetect()
    // console.log('alerts ', alerts)
    useEffect(() => { //установка\сброс title в NavBar
        actionTopNavbar.setTitle(t("Convention2"))(dispatch)
        actionConvention.setData({
            showHistoryFlows: false,
            mbsCashflowTable: null,
            mbsCashflowGraph: null,
            selectedBond: null,
            zcycGraph: null,
            sCurveGraph: null,
            calculatedParameters: null
        })(dispatch)

        return () => {
            actionTopNavbar.setTitle("")(dispatch)
            actionConvention.setData({
                showHistoryFlows: false,
                mbsCashflowTable: null,
                mbsCashflowGraph: null,
                selectedBond: null,
                zcycGraph: null,
                sCurveGraph: null,
                calculatedParameters: null
            })(dispatch)
        }

    }, [])

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{t('Convention2_instrumentHeader')}</title>
            </Helmet>
            <Notifications />
            <Container
                maxWidth={isMobile ? 'xs' : 'xxl'}
                className={cls.rootContainer}
            >
                <Grid container justifyContent={'center'} className={isMobile ? cls.rootGridMobile : cls.rootGrid} columnSpacing={0} rowSpacing={0}>

                    <Grid item xs className={[cls.rootChartsPanel, (!Boolean(pricingResult) || isMobile) && cls.hidden].join(' ')}>
                        <Box className={cls.virtualScrollablePanel}>
                            <Box className={cls.container}>
                                <Box className={cls.topContainer}>
                                    <SCurveChartPanel />
                                </Box>
                                <Box className={cls.bottomContainer}>
                                    <CurrentRateChartPanel />
                                </Box>
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item className={[cls.rootInputPanel, isMobile ? cls.rootMobile : ''].join(' ')}>
                        <Box className={cls.virtualScrollablePanel}>
                            <InputPanel />
                            {/* {!Boolean(pricingResult) && <Box className={[cls.bottomInfo].join(' ')}>
                                <div dangerouslySetInnerHTML={{ __html: getTooltip({ tooltip: "convention2.bottomInfo" }) }}></div>
                            </Box>} */}
                            <pre className={cls.fakedPreloadFont}> </pre>{/*do not delete*/}
                        </Box>
                    </Grid>

                    <Grid item xs className={[cls.rootFlowPanel, (!Boolean(pricingResult) || isMobile) && cls.hidden].join(' ')}>
                        <Box className={cls.virtualScrollablePanel}>
                            <Box className={cls.container}>
                                <Box className={cls.topContainer}>
                                    <FlowsPanel />
                                </Box>
                                <Box className={cls.bottomContainer}>
                                    <FlowsChartPanel />
                                </Box>
                            </Box>
                        </Box>
                    </Grid>

                </Grid>
            </Container>
        </>
    )
}

export default Convention2