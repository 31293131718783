import CommonService from "../services/CommonService";
import AccountService from "../services/AccountService";
import { ProcessingState } from "@Components/Definitions";
import * as Sidebar from "@Store/SidebarStore";
import * as Account from "@Store/AccountStore";

export const Actions = {

    ShowLoader: "SHOW_LOADER",
    HideLoader: 'HIDE_LOADER'
}

export const actionCreators = {

    showLoader: (name) => async (dispatch) => {
        if (name == null || name.length == 0)
            throw "Loader name cannot be empty!"

        dispatch({ type: Actions.ShowLoader, payload: name})
    },

    hideLoader: (name) => async (dispatch) => {
        if (name == null || name.length == 0)
            throw "Loader name cannot be empty!"

        dispatch({ type: Actions.HideLoader, payload: name })
    }
};

const initialState =
{
    loaders: {}
};

export const reducer = (currentState, incomingAction) => {

    if (currentState === undefined)
        return initialState; 


    const action = incomingAction;

    switch (action.type) {
        case Actions.ShowLoader:
            {
                var loaders = currentState.loaders;
                var loader = loaders[action.payload];
                if (loader == null) {
                    loader = {
                        count: 0,
                        flag: false
                    }

                    loaders[action.payload] = loader;
                }

                loader.count++;
                loader.flag = true;

                return { ...currentState, [action.payload]: loader.flag };
            }

        case Actions.HideLoader:
            {
                var loaders = currentState.loaders;
                var loader = loaders[action.payload];
                if (loader == null) {
                    throw "Cannot hide not existed loader!";
                }

                loader.count--;
                loader.flag = (loader.count != 0);

                return { ...currentState, [action.payload]: loader.flag };
            }

        default:
            return currentState || initialState;
    }
};
