import ServiceBase from "./ServiceBase";
import Globals from "../Globals";

export default class CommonService extends ServiceBase {

    static async getProducts() {
        var result = await this.requestJson({
            url: "api/Misc/GetProducts",
            method: "GET"
        });

        if (!result.hasErrors) {
            return result.value;
        }

        return null;
    }

    static async getComplexZCYCC(date, parametersId) {
        var result = await this.requestJson({
            url: "api/Misc/GetComplexZCYCC",
            method: "GET",
            data: { dateStart: date, parametersId: parametersId }
        });

        return result;
    }

    static async getComplexYZCYCC(date) {
        var result = await this.requestJson({
            url: "api/Misc/GetComplexYZCYCC",
            method: "GET",
            data: { dateStart: date }
        });

        return result;
    }

    static async getZCYCUpdateData(date, curveData) {
        var result = await this.requestJson({
            url: "api/Misc/GetZCYCUpdateData",
            method: "POST",
            data: { startDate: date, curve: curveData }
        });

        return result;
    }

    static async getZCYCChartDataFull(arrDates) {
        var result = await this.requestJson({
            url: "api/Misc/GetZCYCChartDataFull",
            method: "POST",
            data: arrDates
        });

        return result;
    }
}