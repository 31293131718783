import React, { useRef, useState, useEffect } from 'react';
import { Theme } from '@mui/material/styles';
import stringInject from 'stringinject';
import Localization from '@Components/Localization';
import style from '@Styles/domrf-theme.module.scss';
import { makeStyles } from '@mui/styles';
import { useTranslation } from "react-i18next";
import { IconButton, Tooltip, Box, Typography, Divider, alpha } from '@mui/material';
import { FileCopyOutlined, SaveOutlined, ZoomInOutlined, ZoomOutOutlined } from '@mui/icons-material';
import { hexToRgbA, copyTextToClipboard } from '@Utilities';
import TooltipInfoLabel from '@Components/shared/TooltipInfoLabel';
import useMobileDetect from '@Components/hook/useMobileDetect';
import { FlowTypes } from '@Components/Definitions';

// import Highcharts from 'highcharts/highstock';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
// import FlowsChart from '../charts/FlowsChart';

const useStyles = makeStyles({
    tooltip: {
        '&>span': {
            // outline:'solid'
            //     border: 'solid 1px #000',
        }
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
    }
});

interface IProp {
    data: any;
    localization: any;
    selectedBond: any;
    bondResults: any;
    showHistoryFlows: any;
    cpr: number;
    cdr: number;
}

const FlowsPanel = ({ localization, selectedBond, bondResults, showHistoryFlows, cpr, cdr }: IProp) => {

    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const device = useMobileDetect();
    const flowChartRef = useRef();
    const [flowsChartOptions, setFlowsChartOptions] = useState({

        defs: {
            patterns: [{
                id: 'left-slash-pattern',
                path: {
                    d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
                    stroke: style.colorPrimary3_60,
                    strokeWidth: 1
                }
            },
            {
                id: 'left-white-slash-pattern',
                path: {
                    d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
                    stroke: 'white',
                    strokeWidth: 1,
                    fill: style.colorPrimary1_40
                }
            },
            {
                id: 'left-white-green-slash-pattern',
                path: {
                    d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
                    stroke: 'white',
                    strokeWidth: 1,
                    fill: style.colorPrimary2_40
                }
            },
            {
                id: 'dotted-pattern',
                path: {
                    d: "M 0 0 L 10 0 M 0 5 L 10 5 M 0 10 L 10 10",
                    stroke: style.colorPrimary3_60,
                    strokeWidth: 1
                }
            }
            ]
        },

        exporting: {
            enabled: false,
            chartOptions: {
                title: {
                    text: t('ConventionNameCashflowGraphName')
                },
                legend: {
                    align: 'center',
                    verticalAlign: 'bottom',
                    itemDistance: 40,
                    itemStyle: {
                        fontSize: '7px',
                        overflow: 'hidden',
                    },
                    floating: false
                }
            },

        },

        plotOptions: {
            series: {
                pointPadding: 0,
                groupPadding: 0.1,

                states: {
                    hover: {
                        //opacity: 1.5,
                        brightness: -0.2
                        //color: 'rgba(68, 188, 93, 1)'
                    }
                },
                events: {
                    click: (e) => e.preventDefault()
                }
            },
            column: {
                stacking: 'normal'
            }
        },

        chart: {
            plotAreaHeight: 310,
            type: 'column',
            // reflow: true,
            plotBorderWidth: 1,
            plotBorderColor: hexToRgbA(style.colorPrimary3, 0.2),
            // allowMutatingData: true,
            // animation: false,
            events: {
                render: (e) => {
                    if (e.target?.callback)
                        e.target?.callback(e.target)
                    // if(e.target?.legend){
                    //     Highcharts.objectEach(e.target.legend.allItems, function (el) {
                    //         console.log('el', el)
                    //         if(el.legendSymbol && el.name==this.t("HistoricalAmortization"))
                    //            el.legendSymbol.element.style.stroke = "#cccccc";
                    //     })
                    // }
                    // console.log('e', e)
                    // x.options.dataLabels.events.init=()=>{console.log('intEv')}
                }
            }
        },
        title: {
            text: '',
            //style: { "color": "#2F444E", "fontSize": "18px", "fontWeight": "bold" }
        },
        xAxis: {
            type: 'datetime',
            crosshair: true,
            gridLineWidth: 1,
            // endOnTick:true,
            // startOnTick:true,
            lineWidth: 1,
            lineColor: alpha(style.colorPrimary3, 0.2), //style.colorPrimary1,
            title: {
                enabled: false
            },
            tickmarkPlacement: 'between',
        },
        yAxis: {
            title: {
                text: t('ConventionNameCashflowGraphRubles')
            },
            reversedStacks: false,
        },

        // legend: {
        //     enabled: false,

        //     // itemStyle:{"color":"#F00"}
        //     // borderColor:'#CCCCCC',
        //     // borderWidth:20,
        //     // itemStyle :{'border':'solid 1px #CCC'}
        // },

        tooltip: {
            className: classes.tooltip,
            borderColor: '#000',
            // formatter: function () {


            //     var sorted = [...this.points].sort((a, b) => { return (a.series.options.legendIndex || 0) - (b.series.options.legendIndex || 0); });

            //     return '<table style="min-width:200px;max-width:600px"><tr><td></td>' +
            //         '<td style="text-align:end">' +

            //         sorted.reduce(function (s, point) {
            //             return s + '<tr><td style="padding:0">' + point.series.name + ': </td>' +
            //                 '<td style="padding:0;text-align:end"><b>' + Localization.FormatNumberString(point.y) + '</b></td></tr>'
            //         }, '<b>' + Localization.FormatDateLocale(new Date(this.x)) + '</b>') +

            //         '</td > ' +
            //         '</tr>' +
            //         '</table>';
            // },
            hideDelay: 0,
            followPointer: true,
            shared: true,
            useHTML: true
        },

        credits: {
            enabled: false
        },

        series: [
            {
                name: t('ConventionNameCashflowGraphRealPayment'),
                color: 'url(#left-white-slash-pattern)',
                data: null,
                legendIndex: 3,
                clickable: false,
                events: {
                    /* checkboxClick:function(){
                         return false
                     },
                     legendItemClick:function(){
                         return false
                     }*/
                }
            },
            {
                name: t('ConventionNameCashflowGraphPlan'),
                color: style.colorPrimary1_40,
                data: null,
                legendIndex: 5,
                clickable: false,
                events: {
                    /* checkboxClick:function(){
                         return false
                     },
                     legendItemClick:function(){
                         return false
                     }*/
                }
            },
            {
                name: t('ConventionNameCashflowGraphDefault'),
                color: style.colorSecondary1,
                data: null,
                legendIndex: 6,
                clickable: false,
                events: {
                    /* checkboxClick:function(){
                         return false
                     },
                     legendItemClick:function(){
                         return false
                     }*/
                }
            },
            {
                name: t('ConventionNameCashflowGraphPrepay'),
                color: style.colorPrimary3_40,
                data: null,
                legendIndex: 7,
                clickable: false,
                events: {
                    /* checkboxClick:function(){
                         return false
                     },
                     legendItemClick:function(){
                         return false
                     }*/
                }
            },

            {
                name: t('ConventionNameCashflowGraphRealCoupon'),
                color: 'url(#left-white-green-slash-pattern)',
                // borderColor: '#CCC',
                data: null,
                legendIndex: 4,
                clickable: false,
                events: {
                    /* checkboxClick:function(){
                         return false
                     },
                     legendItemClick:function(){
                         return false
                     }*/
                }
            },
            {
                name: t('ConventionNameCashflowGraphCoupon'),
                color: style.colorPrimary2_40,
                data: null,
                legendIndex: 8,
                clickable: false,
                events: {
                    /* checkboxClick:function(){
                         return false
                     },
                     legendItemClick:function(){
                         return false
                     }*/
                }
            },
            {
                name: t('ConventionNameCashflowGraphCleanUp'),
                color: 'url(#left-slash-pattern)',
                data: null,
                legendIndex: 9,
                events: {
                    /* checkboxClick:function(){
                         return false
                     },
                     legendItemClick:function(){
                         return false
                     }*/
                }
            },
            {
                name: t('HistoricalAmortization'),
                color: 'white',
                visible: false,
                showInLegend: false,
                data: null,
                borderWidth: 1,
                borderColor: style.colorPrimary3,
                legendIndex: 1,
                clickable: false,
                states: {
                    hover: {
                        enabled: false
                    },
                    inactive: {
                        enabled: false
                    },
                    normal: {
                        enabled: false
                    },
                    select: {
                        enabled: false
                    }
                }
            },
            {
                name: t('HistoricalCoupon'),
                color: 'url(#dotted-pattern)',
                visible: false,
                showInLegend: false,
                data: null,
                borderWidth: 1,
                borderColor: style.colorPrimary3,
                legendIndex: 2,
                clickable: false,
                states: {
                    hover: {
                        enabled: false
                    },
                    inactive: {
                        enabled: false
                    },
                    normal: {
                        enabled: false
                    },
                    select: {
                        enabled: false
                    }
                }
            }
        ]
    });
console.log('bondResults', bondResults)
    const [disableCleanupButton, setDisableCleanupButton] = useState(false);
    const [skipCleanUp, setSkipCleanUp] = useState(true);

    useEffect(() => {
        updateChartLocalization();
    }, [localization]);

    useEffect(() => {
        setSkipCleanUp(true);
        setDisableCleanupButton(false);
    }, [selectedBond]);

    useEffect(() => {
        updateChartData();
    }, [bondResults, skipCleanUp, showHistoryFlows]);

    const updateChartData = () => {

        if (bondResults == null)
            return;

        var newFlowsChart = { ...flowsChartOptions };

        var plan = [];
        var defaults = [];
        var prepayments = [];
        var pleanup = [];
        var coupons = [];
        var realPayments = [];
        var realCoupons = [];

        var historicalAmortization = [];
        var historicalCoupons = [];
        let maxY: number = 0
        let maxYwoCleanup: number = 0
        // let calcArr = [];
        let sumY: number = 0
        let sumWoCleanup: number = 0
        bondResults?.flow?.forEach((point) => {

            var date = new Date(point.couponDate);
            var unixDate = date.getTime() - (date.getTimezoneOffset() * 60 * 1000);

            //no historical points available for charts
            if (point.flowType != FlowTypes.HISTORY) {

                defaults.push({ x: unixDate, y: point.modelBondDefault });
                prepayments.push({ x: unixDate, y: point.modelBondPrepayment });
                pleanup.push({ x: unixDate, y: point.modelBondCleanUp });
                coupons.push({ x: unixDate, y: point.modelBondCoupon });

                plan.push({ x: unixDate, y: point.modelBondPlan });
                realPayments.push({ x: unixDate, y: point.realBondPayments });
                realCoupons.push({ x: unixDate, y: point.realBondCoupons });

                sumY = parseFloat(point.modelBondDefault) +
                    parseFloat(point.modelBondPrepayment) +
                    parseFloat(point.modelBondCoupon) +
                    parseFloat(point.modelBondCleanUp) +
                    parseFloat(point.modelBondPlan) +
                    parseFloat(point.realBondPayments) +
                    parseFloat(point.realBondCoupons)
                maxY = sumY > maxY ? sumY : maxY

                sumWoCleanup = parseFloat(point.modelBondDefault) +
                    parseFloat(point.modelBondPrepayment) +
                    parseFloat(point.modelBondCoupon) +
                    parseFloat(point.modelBondPlan) +
                    parseFloat(point.realBondPayments) +
                    parseFloat(point.realBondCoupons)
                maxYwoCleanup = maxYwoCleanup > sumWoCleanup ? maxYwoCleanup : sumWoCleanup

                // calcArr.push(sumY)

            }
            else {
                //historical
                historicalAmortization.push({ x: unixDate, y: point.bondAmortization });
                historicalCoupons.push({ x: unixDate, y: point.bondCoupon });
                if (showHistoryFlows) {
                    sumWoCleanup = parseFloat(point.bondAmortization) + parseFloat(point.bondCoupon)
                    // console.log('maxYwoCleanup', maxYwoCleanup, maxYwoCleanup)
                    maxYwoCleanup = maxYwoCleanup > sumWoCleanup ? maxYwoCleanup : sumWoCleanup
                }
            }
        });
        // console.log('maxY', maxY)
        // console.log('maxYwoCleanup', maxYwoCleanup)
        // console.log('(maxY - maxYwoCleanup)/Math.max(maxY,maxYwoCleanup,0.00000001', (maxY - maxYwoCleanup)/Math.max(maxY,maxYwoCleanup,0.00000001))
        if ((maxY - maxYwoCleanup) / Math.max(maxY, maxYwoCleanup, 0.00000001) <= 0.2) {
            maxY = Math.max(maxY, maxYwoCleanup)
            setDisableCleanupButton(true);
        }
        else {
            maxY = !skipCleanUp ? parseFloat(maxY) : parseFloat(maxYwoCleanup)
            setDisableCleanupButton(false);
        }
        // let prevMax = Math.max.apply(null,calcArr.filter(val=>val!=Math.max.apply(null, calcArr)))

        // if(this.state.skipCleanUp) maxY = prevMax
        let tickAmount = 6
        let cellY = Math.ceil(maxY / (tickAmount - 1) / 10) * 10
        newFlowsChart.yAxis.max = Math.ceil(maxY / 10) * 10
        newFlowsChart.yAxis.tickInterval = newFlowsChart.yAxis.max >= 30 ? cellY : newFlowsChart.yAxis.max / 4
        // console.log('prevMax',prevMax,'maxY=', maxY, 'cellY=',cellY, 'yAxis.max', newFlowsChart.yAxis.max, newFlowsChart.yAxis.tickAmount )
        // console.log('maxY=',maxY,'yAxis.max=', newFlowsChart.yAxis.max, 'tickInterval',newFlowsChart.yAxis.tickInterval )
        // const lineStyle = {
        //     color: '#000000',
        //     dashStyle: 'solid',
        //     width: 10,
        //     zIndex: 2,
        //     label: {
        //         text: 'test',
        //         rotation: 0
        //     }
        // }
        // newFlowsChart.xAxis.plotLines = []
        // for (var x = 0; x < 1000; x++)
        //     newFlowsChart.xAxis.plotLines.push({ ...lineStyle, value: x+100000 })
        //     console.log('newFlowsChart.xAxis', newFlowsChart.xAxis)
        // // console.log('newFlowsChart', newFlowsChart)

        newFlowsChart.exporting.filename = stringInject(t('FlowsChartFilename_Params'), [selectedBond?.ISIN, cpr, cdr]);

        //GraphPlan
        if (historicalCoupons.length != 0) {
            newFlowsChart.series[8].data = historicalCoupons;
            newFlowsChart.series[8].showInLegend = showHistoryFlows;
            newFlowsChart.series[8].visible = showHistoryFlows;
        }
        else {
            newFlowsChart.series[8].data = null;
            newFlowsChart.series[8].showInLegend = false;
            newFlowsChart.series[8].visible = false;
        }

        if (historicalAmortization.length != 0) {
            newFlowsChart.series[7].data = historicalAmortization;
            newFlowsChart.series[7].showInLegend = showHistoryFlows;
            newFlowsChart.series[7].visible = showHistoryFlows;
        }
        else {
            newFlowsChart.series[7].data = null;
            newFlowsChart.series[7].showInLegend = false;
            newFlowsChart.series[7].visible = false;
        }

        newFlowsChart.series[6].data = pleanup;
        newFlowsChart.series[5].data = coupons;
        newFlowsChart.series[4].data = realCoupons;
        newFlowsChart.series[3].data = prepayments;
        newFlowsChart.series[2].data = defaults;
        newFlowsChart.series[1].data = plan;

        //newFlowsChart.series[0].visible = knownFlows.length;
        newFlowsChart.series[0].data = realPayments;

        setFlowsChartOptions(newFlowsChart);
    }

    const updateChartLocalization = () => {

        var flowsOptions = { ...flowsChartOptions };
        flowsOptions.yAxis.title.text = localization.title;
        flowsOptions.tooltip.formatter = localization.tooltipFormatter;

        flowsOptions.series[0].name = localization.RealPayment;
        flowsOptions.series[1].name = localization.Plan;
        flowsOptions.series[2].name = localization.Default;
        flowsOptions.series[3].name = localization.Prepay;
        flowsOptions.series[4].name = localization.RealCoupon;
        flowsOptions.series[5].name = localization.Coupon;
        flowsOptions.series[6].name = localization.CleanUp;

        setFlowsChartOptions(flowsOptions);
    }

    const onSaveFlowAsImageButtonClick = (event) => {
        if (flowChartRef.current)
            flowChartRef.current.chart.exportChart({ width: 2048 });
    }

    const onSaveGraphFlowsButtonClick = (event) => {

        if (!bondResults == null)
            return;


        var extraColumns = [
            { title: t('ConventionNameCouponDate') },
            { title: t('ConventionNameCashflowGraphRealPayment') },
            { title: t('ConventionNameCashflowGraphPlan') },
            { title: t('ConventionNameCashflowGraphDefault') },
            { title: t('ConventionNameCashflowGraphPrepay') },
            { title: t('ConventionNameCashflowGraphCleanUp') },
            { title: t('ConventionNameCashflowGraphRealCoupon') },
            { title: t('ConventionNameCashflowGraphCoupon') },
        ];

        var textToCopy = extraColumns.map((item) => item.title).join('\t') + '\r\n';

        textToCopy += bondResults.flow
            .filter((item) => item.flowType == FlowTypes.MODEL || item.flowType == FlowTypes.USED_HISTORY)
            .map((item) => (
                Localization.FormatDateStringLocale(item.couponDate) + '\t' +
                Localization.FormatNumberString(item.realBondPayments) + '\t' +
                Localization.FormatNumberString(item.modelBondPlan) + '\t' +
                Localization.FormatNumberString(item.modelBondDefault) + '\t' +
                Localization.FormatNumberString(item.modelBondPrepayment) + '\t' +
                Localization.FormatNumberString(item.modelBondCleanUp) + '\t' +
                Localization.FormatNumberString(item.realBondCoupons) + '\t' +
                Localization.FormatNumberString(item.modelBondCoupon)
            )).join('\r\n');

        copyTextToClipboard(textToCopy);
    }

    return (
        <div className={classes.container}>
            <Box sx={{
                display: 'flex', pl: 1,
                flexWrap: 'nowrap',
                flexDirection: 'row',
                alignContent: 'center',
                alignItems: 'baseline',
            }}
            >
                <Typography sx={{
                    textTransform: 'uppercase', lineHeight: '2rem', maxHeight: '2rem', overflow: 'hidden',
                    display: 'flex', alignItems: 'center',
                    ml: 1, flexGrow: 1, fontSize: '14px', color: style.colorPrimary3
                }} >
                    {t('ConventionNameCashflowGraphName')}
                    <TooltipInfoLabel right content={t('TooltipConventionCashFlowGraph')} />
                </Typography>
                <Box component="span" sx={disableCleanupButton ? { display: 'none' } : { pr: 1 }}>
                    {/* кнопка "выключить Экстремум" */}
                    <Tooltip
                        disableFocusListener
                        //interactive
                        title={t(skipCleanUp ? "skipCleanUpTrue" : "skipCleanUpFalse")}>
                        <span> { /*A disabled element does not fire events. Tooltip needs to listen to the child element's events to display the title. */}
                            <IconButton size="small"
                                onClick={() => { setSkipCleanUp(!skipCleanUp) }}
                                // console.log('clickLupa' )
                                disabled={bondResults == null}
                            >
                                {skipCleanUp ? <ZoomOutOutlined /> : <ZoomInOutlined />}
                            </IconButton>
                        </span>
                    </Tooltip>
                </Box>
                <Box component="span" sx={device.isMobile ? { display: 'none' } : { pr: 1 }}>
                    {/* кнопка Save */}
                    <Tooltip
                        disableFocusListener
                        //interactive
                        title={t('SaveAsImage')}>
                        <span> { /*A disabled element does not fire events. Tooltip needs to listen to the child element's events to display the title. */}
                            <IconButton size="small" disabled={bondResults == null} onClick={onSaveFlowAsImageButtonClick}>
                                <SaveOutlined />
                            </IconButton>
                        </span>
                    </Tooltip>
                </Box>
                <Box component="span" sx={device.isMobile ? { display: 'none' } : { pr: 1 }}>
                    {/* кнопка Copy */}
                    <Tooltip
                        disableFocusListener
                        //interactive
                        title={t('CopyPlotResult')}>
                        <span> { /*A disabled element does not fire events. Tooltip needs to listen to the child element's events to display the title. */}
                            <IconButton size="small" disabled={bondResults == null} onClick={onSaveGraphFlowsButtonClick}>
                                <FileCopyOutlined />
                            </IconButton>
                        </span>
                    </Tooltip>
                </Box>

            </Box>
            <Divider />
            {/* <FlowsChart data={flowsChartOptions}/> */}
            <HighchartsReact
                ref={flowChartRef}
                highcharts={Highcharts}
                containerProps={{ style: { height: "100%", width: "100%" } }}
                options={flowsChartOptions ? flowsChartOptions : {}}
                callback={(chart) => {
                    if (chart?.legend) {
                        Highcharts.objectEach(chart.legend.allItems, function (el) {
                            //  console.log('el', el)
                            //  if (el.legendSymbol ) //&& el.name == t("HistoricalAmortization"))
                            //     el.legendSymbol.element.style.stroke = style.colorPrimary3_40;
                            if(el?.legendItem?.symbol && el.name == t("HistoricalAmortization"))
                                el.legendItem.symbol.element.style.stroke = style.colorPrimary3_40;
                        })
                    }
                }
                }
            />
        </div>
    );
}

export default FlowsPanel;