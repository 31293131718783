import { connect } from 'react-redux';
import * as React from 'react';

import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import Chip from '@mui/material/Chip';

//import CloseIcon from '@mui/icons-material/Close';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import makeStyles from '@mui/styles/makeStyles';
import SaveIcon from '@mui/icons-material/Save';
//import withWidth, { isWidthUp, isWidthDown } from '@mui/material/withWidth';

import Typography from '@mui/material/Typography';
import Localization from '../../../components/Localization';
import Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import HC_exporting_data from 'highcharts/modules/export-data';
import HighchartsReact from 'highcharts-react-official';
import HC_more from "highcharts/highcharts-more"; //module
import * as DomRfStore from "@Store/DomRfStore";
import { setHighchartsLocalization, copyTextToClipboard, getChartsData } from '@Utilities';
import style from '@Styles/domrf-theme.module.scss';
import { useTranslation } from "react-i18next";
import TooltipInfoLabel from '@Components/shared/TooltipInfoLabel';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

const useStyles = makeStyles(theme => ({

    image: {
        width: '100%'
    }
}));

const chartsColours = [
    style.colorPrimary1,
    style.colorPrimary2,
    style.colorSecondary1,
    style.colorSecondary2,
    style.colorPrimary3

    //style.colorPrimary1_80,
    //style.colorPrimary2_80,
];

export default function ConventionInformation(props) {

    const { t, i18n } = useTranslation();
    const classes = useStyles();

    return (
        <Paper sx={{width: '100%', paddingX: '1rem'}}>
            <Grid container>
                <Grid>
                    <p dangerouslySetInnerHTML={{ __html: t('TooltipConvention1') }}></p>
                    <p dangerouslySetInnerHTML={{ __html: t('TooltipConvention2') }}></p>
                </Grid>
            </Grid>
            <Grid>
                <Grid>
                    <img src={t('ConventionsImage')} alt="Conventions image" className="w-100"/>
                </Grid>
            </Grid>
        </Paper>
    );
}
