import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import style from '@Styles/domrf-theme.module.scss'
import { createStyles, makeStyles } from '@mui/styles'
import { Box, Button, IconButton, Slider, Tab, Tabs, ToggleButton, ToggleButtonGroup, Tooltip, alpha, debounce } from '@mui/material'
// import { ReactComponent as ExcelIcon } from "../../../../../src/images/excel-icon.svg"
import { t } from 'i18next'
import { useSelector } from 'react-redux'
import { actionCreators as actionAlert } from '@Base/store/AlertStore'
import { useDispatch } from 'react-redux'
import { SaveOutlined } from '@mui/icons-material'
import Highcharts from 'highcharts'
import HC_exporting from 'highcharts/modules/exporting'
import HC_exporting_data from 'highcharts/modules/export-data'
import HighchartsReact from 'highcharts-react-official'
import { roundDown, roundUp, setHighchartsLocalization } from '@Base/Utilities'
import { actionCreators as actionConvention } from '@Base/store/ConventionStore'
import { actionCreators as actionDownloads } from '@Base/store/DownloadStore'

import Localization from '@Base/components/Localization'
import SpinnerBars from '@Base/components/shared/SpinnerBars'
import CustomTooltip, { getTooltip } from '@Base/components/shared/CustomTooltip'

const DownloadButton = styled(Button)(({ theme }) => ({
    fontFamily: "DINPro-Bold",
    fontWeight: "700",
    letterSpacing: '1.5px',
    fontSize: '0.8rem !important',
    '& *':{
        fontSize: '0.8rem !important',
    },
    // color: style.colorPrimary3,
    padding: '0 0.5rem',
    marginRight: '0.5rem',
    backgroundColor: 'rgba(183, 217, 139, 0.23)',
    borderColor: 'rgb(61,149,109)',
    color: 'rgb(61,149,109)',
    '&:hover': {
        borderColor: `${style.colorPrimary2} !important`,
        backgroundColor: 'rgba(183, 217, 139, 0.23) !important',
        color: `rgb(0,176,80) !important`,
    }
}));

const useStyles = makeStyles((theme) => createStyles({
    fullPanelAlert: {
        position: 'absolute',
        left: 0, top: 0, right: 0, bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: style.colorPrimary3_40,
        textAlign: 'center',
        userSelect: 'none',
        textTransform: 'uppercase',
    },
    headersButtonOutlined: {
        letterSpacing: '1.5px',
        fontSize: '0.8rem !important',
        // color: style.colorPrimary3,
        padding: '0 0.5rem',
        marginRight: '0.5rem',
        backgroundColor: 'rgba(183, 217, 139, 0.23)',
        borderColor: 'rgb(61,149,109)',
        color: 'rgb(61,149,109)',
        '&:hover': {
            borderColor: `${style.colorPrimary2} !important`,
            backgroundColor: 'rgba(183, 217, 139, 0.23) !important',
            color: `rgb(0,176,80) !important`,
        }
        // not(.Mui-disabled)
        // lineHeight: '2rem',
    },
    textIcon: {
        fontSize: '0.8rem !important',
        textTransform: 'initial',
        padding: '0 0.5rem !important',
        minWidth: '40px !important',
        letterSpacing: 'initial',
        color: style.colorPrimary3_80,
        fontFamily: 'DINPro, Helvetica',
        fontWeight: 100,
    },
    wideTooltip: {
        maxWidth: '400px !important'
    },
    coverLayout: {
        position: 'absolute',
        left: 0, top: 0, bottom: 0, right: 0,
        zIndex: '+100',
        backgroundColor: 'rgba(255, 255, 255, 0.5)',

    },
    root: {
        // marginRight: '0.2rem',
        // marginLeft: '0.2rem',
        // marginTop: '0.5rem',
        // height: '421px',
        // position: 'relative',
        position: 'absolute',
        left: '0.2rem', top: '0.5rem', bottom: 0, right: '0.2rem',
        // maxHeight:'375px',
        '& $panelHeader *': {
            fontSize: '1rem'
        }
    },
    rootPaper: {
        borderRadius: '0.5rem',
        border: `solid 1px ${style.colorPrimary3_40}`,
        position: 'absolute',
        left: 0, top: 0, bottom: 0, right: 0,
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        justifyContent: 'flex-start',
        overflow: 'hidden',
    },
    panelHeader: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignContent: 'center',
        alignItems: 'center',
        height: '2rem !important',
        minHeight: '2rem !important',
        overflow: 'visible',
        boxSizing: 'border-box',
        borderBottom: `solid 1px ${style.colorPrimary3_40}`,
        padding: '0 0.2rem 0 0',
        justifyContent: 'space-between',
        '& *': { userSelect: 'none', },
    },
    panelHeaderTitle: {
        marginLeft: '0.5rem',
        textTransform: 'uppercase',
        color: style.colorPrimary3_40,
        whiteSpace: 'nowrap',
    },
    panelIconsBox: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignContent: 'center',
        alignItems: 'center',
    },
    icon: {
        width: '23px',
        height: '23px',
        // color: style.colorPrimary3_80,
    },
    panelData: {
        margin: '0.8rem',
        overflow: 'hidden',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        wlexWrap: 'nopwrap',
    },
    tooltip: {
        '& *': { fontSize: '0.8rem !important' },
        // '&>span': {
        //     outline: 'solid',
        //     border: 'solid 1px #000',
        // }
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    poolTabContaner: {
        margin: 0,
        backgroundColor: 'transparent',
        minHeight: 'unset',
        position: 'relative',
        '& .MuiTabs-indicator': {
            backgroundColor: 'transparent',
        },
    },
    tabRoot: {
        backgroundColor: '#FFF !important',
        padding: '0 0.5rem !important',
        minWidth: 'fit-content !important',
        color: `${alpha(style.colorPrimary3_60, 0.5)} !important`,
        borderBottom: `solid 1px ${style.colorPrimary3_40}`,
        minHeight: '2rem',
        height: '2rem',
        fontSize: '1rem',
        whiteSpace: 'nowrap',
        color: style.colorPrimary3_40,
        position: 'relative',
        overflow: 'visible',
        transition: 'all 0.2s ease-in-out',
        // borderRight: `solid 1px ${style.colorPrimary3_40}`,
        '&::before': {
            content: '""',
            position: 'absolute',
            right: 0,
            top: '3px',
            bottom: '-1px',
            width: '1px',
            backgroundColor: `${style.colorPrimary3_40}`,
        },
    },
    selectedTab: {
        '&::after': {
            backgroundColor: '#0002',
        },
        color: `${style.colorPrimary3_60} !important`,
        backgroundColor: '#FFF !important',
        borderBottom: 'solid 1px #FFF !important',
    },
    chartContainer: {
        position: 'relative',
        flexGrow: 1
    },
    chartContainerFlex: {
        position: 'absolute', top: 0, left: 0, right: 0, bottom: 0,
        display: 'flex',
        flexDirection: 'column'
    },
    centerData: {
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        color: style.colorPrimary3_40
    },
    saveButton: {
        position: 'absolute',
        top: '5px',
        right: '60px',
    },
    info: {
        display: 'flex',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
        alignItems: 'center',
        textAlign: 'center',
        fontSize: '1.2rem',
        color: style.colorPrimary3_40
    },
    markActive: {
        boxShadow: `0px 0px 0px 4px ${alpha(style.colorPrimary2, 0.5)} !important`
    },
    mark: {
        backgroundColor: 'transparent',
    },
    sliderRoot: {
        color: style.colorPrimary3,
        width: '240px',
        minWidth: '240px',
        maxWidth: '240px',
        paddingTop: 0,
        paddingBottom: 0,
        // zIndex: +1,

    },
    // container: {
    //     position: 'relative',
    //     display: 'flex',
    //     flexDirection: 'column',
    //     height: '100%'
    // },

    controlsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        //alignItems: 'center',
        justifyContent: 'center',
        // position: 'absolute',
        // left: '60px',
        // top: '7px',
    },

    controlBox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: 'max-content',
        flexWrap: 'nowrap',
        // width: '30%'
    },
    spinnerContainer: {
        position: 'relative',
        width: '100%',
        height: '100%'
    }
}))

const CurrentRateChartPanel = () => {

    HC_exporting(Highcharts)
    HC_exporting_data(Highcharts)
    setHighchartsLocalization(Highcharts, t)

    const cls = useStyles()
    const dispatch = useDispatch()
    const chart = useRef()
    const { deal = null, selectedNode = null, ...otherStore } = useSelector((state: any) => state.securitization)

    const {
        loading = false,
        pricingParameters = null,
        error = null,
        calculatedParameters = null,
        chartsData = {},
        loadingChartData = false
    } = useSelector((state: any) => state.convention)

    const [chartData, setChartData] = useState(null)
    const [chartLoading, setChartLoading] = useState(false);
    const [step, setStep] = useState(null)
    const [steps, setSteps] = useState([0.1, 0.5, 1])
    const [lods, setLods] = useState([])
    const [lod, setLod] = useState(null);
    const [binCount, setBinCount] = useState(0)
    const [currentWACBinIndex, SetCurrentWACBinIndex] = useState(0)


    const onSaveChartAsImageButtonClick = () => {
        if (chart.current) {
            chart.current?.chart?.exportChart({ width: 2048, height: 1365 });
        }
    }

    const chartTitle = `${t("convention2.chartTitle")} ${pricingParameters?.isin} ${Localization.FormatDateStringLocale(calculatedParameters?.wacwamDate)}`

    const [chartOptions, setChartOptions] = useState({
        poolChartOptions: {
            customData: () => { return { step: step } },
        },
        exporting: {
            enabled: false,
            filename: chartTitle,
            chartOptions: {
                // title: {
                //     text: chartTitle,
                //     style: { display: "block", "color": "#2F444E", "fontSize": "20px", "fontWeight": "bold", 'float': 'left' },
                // },
                chart: {
                    // style: { "fontSize": "5px" },
                    marginBottom: 55,
                    // events: {
                    // load: function () {
                    //     var renderer = this.renderer;
                    //     console.log('this', this, renderer)
                    //         renderer.path(['M', 30, 385, 'L', 570, 385, 'Z']).attr({
                    //             stroke: 'black',
                    //             'stroke-width': 1
                    //         }).add();

                    //         renderer.text('Some text...', 30, 400).add();
                    // }
                    // }
                },
                legend: {
                    // verticalAlign: 'bottom',
                    align: 'center',
                    // y: 15,
                    // x: 0,
                    itemDistance: 40,
                    itemStyle: {
                        fontSize: '10px',
                        overflow: 'hidden',
                    },
                },
            }
        },
        chart: {
            animation: false,
            // marginBottom:25,
            // height: 300,
            // marginTop: 40,

            marginLeft: 34,
            marginRight: 34,
            marginBottom: 50,
            /*events: {
                load: () => {
                    //console.log('-----CHART LOADED: ' + nodeWithChangesLoad + " " + CurrentRateLoad);
                    setChartLoading(false)
                }
            }*/
        },
        plotOptions: {
            series: {
                /*
                dataLabels: {
                    enabled: true,
                    color: '#2F444E',
                    format: '{y:.3f}'
                },
                */
                pointPadding: 0,
                groupPadding: 0.1,
                dataLabels: {
                    enabled: false,
                },
                states: {
                    inactive: {
                        enabled: false,
                    },
                    hover: {
                        halo: {
                            size: 0
                        }
                    }
                },
                events: {
                    legendItemClick: function () {
                        return false; // Prevent the default legendItemClick behavior
                    }
                }
            },
        },

        title: {
            text: t('CurrentCreditRate'),
            style: { display: "none", "color": "#2F444E", "fontSize": "18px", "fontWeight": "bold", 'float': 'left' },
            align: 'left',
            verticalAlign: 'top',
            x: 50,
            y: 8,
        },

        xAxis: {
            crosshair: {
                color: alpha(style.colorPrimary2, 0.1)
            },
            categories: null,
            labels: {
                enabled: true,
                // formatter: null,
                rotation: 0,
                allowOverlap: true,
                style: {
                    whiteSpace: 'nowrap',
                    textOverflow: 'none',
                    color: style.colorPrimary3
                }
            },

            title: {
                enabled: false
            },

            gridLineWidth: 0,
            gridZIndex: 1,
            lineWidth: 1,
            lineColor: alpha(style.colorPrimary3, 0.5),
            tickmarkPlacement: 'between',

            plotLines: [
                {
                    color: style.colorPrimary3_60,
                    dashStyle: 'Dash',
                    width: 1.3,
                    value: -0.2,
                    zIndex: 2,
                    label: {
                        rotation: 0,
                        textAlign: 'right',
                        x: -5,
                        y: 15,
                        style: {
                            color: style.colorPrimary3,
                            fontWeight: 'bolder',
                            fontSize: '0.9rem'
                        },
                    }
                }]
        },

        yAxis: [{
            visible: true,
            labels: {
                fontSize: '0.8rem',
                enabled: true,
                x: -4,
                style: {
                    color: style.colorPrimary3
                }
            },
            stackLabels: {
                enabled: false
            },
            title: {
                text: t('convention2.CurrentDebtShare'),
                offset: 21,
            },
            // tickPositions: null,
            tickPositioner: function (min, max) {
                const rounder = 5
                const rMax = roundUp(max, rounder)
                const rMin = roundDown(min, rounder)

                const count = 5
                const step = roundUp((rMax - rMin) / count, rounder)
                var positions = Array.from({ length: count + 1 }, (x, i) => i * step)
                // var positions = [0, 10, 20, 30, 40, 50];
                return positions;
            },

            gridLineColor: alpha(style.colorPrimary3, 0.1),
            gridLineWidth: 1,
            gridLineDashStyle: 'solid',
            lineWidth: 1,
            lineColor: alpha(style.colorPrimary3, 0.5),
            gridZIndex: 1,
        }, {
            visible: true,
            labels: {
                fontSize: '0.8rem',
                enabled: true,
                x: 4,
                style: {
                    color: style.colorPrimary3
                }
            },
            stackLabels: {
                enabled: true
            },
            title: {
                text: t('convention2.Years'),
                offset: 21,
            },
            // min:0,
            // max:25,
            opposite: true,
            // tickPositions: null,
            tickPositioner: function (min, max) {
                // console.log('min, max', min, max)
                const rounder = 5
                const rMax = roundUp(max, rounder)
                const rMin = roundDown(min, rounder)

                const count = 5
                const step = roundUp((rMax - rMin) / count, 1)
                // console.log('rMax - rMin', rMax, max, rMin, min)
                var positions = Array.from({ length: count + 1 }, (x, i) => rMin + i * step)
                // var positions = [0, 10, 20, 30, 40, 50];
                return positions;
            },
            // gridLineColor: alpha(style.colorPrimary3, 0.1),
            gridLineWidth: 0,
            lineWidth: 1,
            lineColor: alpha(style.colorPrimary3, 0.5),
            gridZIndex: 1,
            plotLines: [
                {
                    color: style.colorPrimary2,
                    dashStyle: 'dash',
                    width: 2,
                    value: -0.2,
                    zIndex: 2,
                    label: {
                        rotation: 0,
                    }
                }]
        }],

        // legend: {
        //     enabled: true,
        //     itemMarginTop: -4,
        //     itemMarginBottom: 0,
        //     margin: 0,
        // },
        legend: {
            margin: 0,
            y: 10,
            itemStyle: {
                cursor: 'default'
            },
        },
        tooltip: {
            shape: "square",
            animation: false,
            hideDelay: 0,
            useHTML: true,
            shared: true,
            borderColor: style.colorPrimary3_60,
            positioner: function (labelWidth, labelHeight, point) {
                return {
                    x: this.chart.plotLeft + 10,
                    y: this.chart.plotTop + 10
                };
            },
            headerFormat: '<table><tr>' +
                '<td>' + t('convention2.CurrentRate') + ':</td>' +
                '<td style="text-align:end"><b>{point.key:.1f}</b></td>' +
                '</tr>',
            pointFormat: '<tr><td style="padding:0">{series.name}: </td>' +
                '<td style="padding-left:0.5rem;text-align:end"><b>{point.y: .1f}</b></td></tr>',
            footerFormat: '</table>',
        },

        credits: {
            enabled: false
        },

        series: [{
            id: 'c1',
            type: 'column',
            name: t('convention2.CurrentDebt'),
            color: alpha(style.colorPrimary3, 0.1),
            borderColor: alpha(style.colorPrimary3, 0.5),
            yAxis: 0,
            data: null,
            animation: false,
            showInLegend: true,
            column: {
                zones: {
                    fillColor: style.colorPrimary2_20
                }
            },
            visible: true,
            stacking: true,
            dataLabels: {
                enabled: false
            }
        },
        {
            id: 'c2',
            type: 'column',
            name: t('convention2.CurrentDebtWG'),
            color: 'rgba(183, 217, 139, 0.23)',//alpha(style.colorPrimary2, 0.2),
            borderColor: alpha(style.colorPrimary3, 0.5),
            yAxis: 0,
            data: null,
            animation: false,
            showInLegend: true,
            visible: true,
            stacking: true,
            dataLabels: {
                enabled: false
            }
        }, {
            type: 'line',
            name: t('convention2.WAM_year'),
            color: style.colorPrimary3_60,
            lineWidth: 1.5,
            yAxis: 1,
            data: null,
            showInLegend: true,
            states: {
                hover: {
                    enabled: false
                }
            },
            marker: {
                enabled: true,
                symbol: 'circle',
                lineColor: style.colorPrimary3_80,
                lineWidth: 1,
                radius: 4,
                fillColor: '#FFFFFF',
            },
            animation: false,
            visible: true,
        }]
    })


    useLayoutEffect(() => {
        setLod(0)
        setStep(steps[1])
        setChartData(null)

        if (window) {
            window.addEventListener('resize', debounce(onResize, 300))
            onResize();
        }

        return () => {
            window.removeEventListener('resize', debounce(onResize, 300))
        }
    }, [])

    useEffect(() => {
        // console.log('clear chartsData')
        actionConvention.clearValue('chartsData')(dispatch)

    }, [pricingParameters?.isin])

    useLayoutEffect(() => {
        // console.log('calculatedParameters?.wacwamDate', calculatedParameters?.wacwamDate)
        if (step != null) {
            // setBinCount(step == 1 ? 4
            //     : step == 0.5 ? 7
            //         : 30)

            if (pricingParameters?.isin && calculatedParameters?.wacwamDate) {
                actionConvention.getPoolLLDForChart(pricingParameters.isin, calculatedParameters.wacwamDate, step)(dispatch)
            } else {
                actionConvention.clearValue('chartsData')(dispatch)
            }
        }
    }, [pricingParameters?.isin, calculatedParameters?.wacwamDate, step])

    // console.log('chartsData', chartsData)

    useLayoutEffect(() => {

        // console.log("----- UPDATE ------");

        if (chartsData?.currentRateChart?.steps != null) {
            const binCountVal = step == 1 ? 4
                : step == 0.5 ? 7
                    : 30
            setBinCount(binCountVal)


            let arr = chartsData?.currentRateChart?.steps
            const cnt = arr?.length - 1
            var deltaWACArr = arr?.map(x => Math.abs(parseFloat(x) - chartsData?.currentRateChart?.wac))
            var minDeltaWAC = Math.min.apply(null, deltaWACArr)
            var currentWACBin = deltaWACArr?.findIndex(x => x == minDeltaWAC)

            SetCurrentWACBinIndex(currentWACBin)
            if (cnt != null) {
                let sizeLeft = currentWACBin - binCountVal > 0 ? currentWACBin - binCountVal : 0
                let sizeRight = cnt - currentWACBin - binCountVal > 0 ? cnt - currentWACBin - binCountVal : 0
                let lodsSize = sizeRight > sizeLeft ? sizeRight : sizeLeft
                // console.log('lodsSize', lodsSize)
                if (lodsSize > 1) {
                    //    console.log('update lods', lodsSize)
                    let lodsArr = Array.from(Array(lodsSize + 1).keys()).map(x => { return { value: x } })
                    setLods(lodsArr)
                } else {
                    setLods([])
                }
            }

        }
        else {
            SetCurrentWACBinIndex(null)
            setLods([])
        }

        setLod(0)
        setChartData(chartsData?.currentRateChart);
    }, [chartsData]);


    // useLayoutEffect(() => {
    //     if (lods?.length && lod != 0)
    //         setLod(0)
    // }, [lods])


    useEffect(() => {
        if (chart.current != null)
            chart.current.chart.reflow();
    }, [chartOptions])

    useEffect(() => {
        setChartLoading(loadingChartData);
    }, [loadingChartData])

    useLayoutEffect(() => {
        updateChart()
    }, [lod, chartData])

    

    const downloadHandler = () => {
        if (pricingParameters?.isin && calculatedParameters?.wacwamDate) {
            // actionAlert.addAlert('Загрузка начнеся через несколько секунд', style.colorPrimary1)(dispatch)
            //fileDownloadPage(`api/Convention/DownloadConventionPoolLLD?isin=${pricingParameters.isin}&wacwamDate=${calculatedParameters.wacwamDate}`, '_blank')
            actionDownloads.addDownload(`api/Convention/DownloadConventionPoolLLD?isin=${pricingParameters.isin}&wacwamDate=${calculatedParameters.wacwamDate}`)(dispatch)
            // window.open(`api/Convention/DownloadConventionPoolLLD?isin=${pricingParameters.isin}&wacwamDate=${calculatedParameters.wacwamDate}`)//, '_blank');
        }
        else
            actionAlert.addAlert('Расчет не может быть загружен', style.colorPrimary1)(dispatch)
    }

    const updateChart = () => {

        if (chartData?.steps == null || lod < 0)
            return

        const lineStyle = {
            color: alpha(style.colorPrimary3, 0.1),
            dashStyle: 'solid',
            width: 1,
            zIndex: 2,
            label: {
                rotation: 0
            }
        }
        //make chart
        var opts = { ...chartOptions };
        var data = { ...chartData }
        let sliceLeftIndex = currentWACBinIndex - (binCount + parseInt(lods[lod]?.value)) //позиция среза слева
        let minX = parseFloat(data?.steps[0])
        if (sliceLeftIndex < 0) sliceLeftIndex = 0

        data.steps = data.steps.map(x => `${x}+`) //добавляем плюсы в бины

        if (sliceLeftIndex > 0) {

            let y1ArrLeft = data?.ood?.slice(0, sliceLeftIndex)
            const sliceDebtLeft = y1ArrLeft?.some(x => x) ? y1ArrLeft?.reduce((x, s) => (x ?? 0) + s, 0) : null

            let y1ArrLeftWG = data?.oodWithGov?.slice(0, sliceLeftIndex)
            const sliceDebtLeftWG = y1ArrLeftWG?.some(x => x) ? y1ArrLeftWG?.reduce((x, s) => (x ?? 0) + s, 0) : null

            let y2ArrLeft = data?.wam?.slice(0, sliceLeftIndex)
            let sliceYearsLeft = y2ArrLeft?.map((x, index) => {
                if (x == null || (y1ArrLeft[index] == null && y1ArrLeftWG[index] == null)) return null
                return x * (y1ArrLeft[index] ?? 0 + y1ArrLeftWG[index] ?? 0)
            }).reduce((x, s) => (x ?? 0) + s, 0) / (sliceDebtLeft + sliceDebtLeftWG)

            data.ood = [sliceDebtLeft != null ? sliceDebtLeft : null, ...data?.ood?.slice(sliceLeftIndex)]
            data.oodWithGov = [sliceDebtLeftWG != null ? sliceDebtLeftWG : null, ...data?.oodWithGov?.slice(sliceLeftIndex)]
            data.wam = [sliceYearsLeft != null ? sliceYearsLeft : null, ...data?.wam?.slice(sliceLeftIndex)]

            data.steps = data?.steps?.slice(sliceLeftIndex ? sliceLeftIndex - 1 : 0)
            minX = minX + step * (sliceLeftIndex - (sliceDebtLeft != null && sliceDebtLeftWG != null ? 1 : 0)) - step

            data.steps[0] = `<${data?.steps[1]?.replace(/[\+]/g, '')}`
        }

        // let sliceRightIndex = -sliceLeftIndex + currentWACBinIndex - (binCount + parseInt(lods[lod]?.value)) +
        //     (binCount + parseInt(lods[lod]?.value)) * 2 +1   //позиция среза справа
        let sliceRightIndex = -sliceLeftIndex + currentWACBinIndex + (binCount + parseInt(lods[lod]?.value)) + 1   //позиция среза справа
        // console.log('sliceLeftIndex', sliceLeftIndex)
        // console.log('sliceRightIndex', sliceRightIndex)
        if (sliceRightIndex < chartData.ood?.length) {

            let y1ArrRight = data.ood?.slice(sliceRightIndex - 1)
            const sliceDebtRight = y1ArrRight?.some(x => x) ? y1ArrRight?.reduce((x, s) => (x ?? 0) + s, 0) : null

            let y1ArrRightWG = data.oodWithGov?.slice(sliceRightIndex - 1)
            const sliceDebtRightWG = y1ArrRightWG?.some(x => x) ? y1ArrRightWG?.reduce((x, s) => (x ?? 0) + s, 0) : null

            let y2ArrRight = data.wam?.slice(sliceRightIndex - 1)
            let sliceYearsRight = y2ArrRight?.map((x, index) => {
                if (x == null || (y1ArrRight[index] == null && y1ArrRightWG[index] == null)) return null
                return x * (y1ArrRight[index] ?? 0 + y1ArrRightWG[index] ?? 0)
            }).reduce((x, s) => (x ?? 0) + s, 0) / (sliceDebtRight + sliceDebtRightWG)

            data.ood = [...data?.ood?.slice(0, sliceRightIndex - 1), sliceDebtRight != null ? sliceDebtRight : null]
            data.oodWithGov = [...data?.oodWithGov?.slice(0, sliceRightIndex - 1), sliceDebtRightWG != null ? sliceDebtRightWG : null]
            data.wam = [...data?.wam?.slice(0, sliceRightIndex - 1), sliceYearsRight != null ? sliceYearsRight : null]

            data.steps = data?.steps?.slice(0, sliceRightIndex)

            const lastBin = data.steps?.length - 1
            data.steps[lastBin] = `≥${data?.steps[lastBin]?.replace(/[\+]/g, '')}`
        }

        opts.xAxis.plotLines = [opts.xAxis.plotLines[0]]

        opts.xAxis.categories = data.steps?.map((x, index) => {
            // console.log('x', x)
            if (x == '' || x == null)
                return ""

            const tmplReg = new RegExp('[>=≥]', 'g')

            let value = parseFloat(`${x}`?.replace(/[<>=≥\+]/g, ''))
            const testVal = tmplReg.test(`${x}`)
            if (value % 1 === 0 /*|| (testVal && (value % 1 != 0))*/ && index != 0)
                opts.xAxis.plotLines.push({ ...lineStyle, value: index - 0.5 })

            return x
        })

        opts.xAxis.labels.formatter = function () {

            let textVal = `${this.value}`
            let value = parseFloat(textVal?.replace(/[<>=≥\+]/g, ''))

            if (this.pos == 0 || this.pos == opts.xAxis?.categories?.length - 1) return textVal

            const leftPadding = opts.xAxis?.categories?.length > 50 ? 4 : 2
            const rightPadding = opts.xAxis?.categories?.length > 50 ? 6 : 4
            // console.log('rightPadding', rightPadding)
            switch (step) {
                case 0.1:
                    textVal = (this.pos < leftPadding || this.pos > opts.xAxis?.categories?.length - rightPadding) ? '' :
                        data.steps?.length < 50 ?
                            (value % 0.5 === 0 ? textVal : '') :
                            (value % 1 === 0 ? textVal : '')
                    break

                case 0.5:
                    textVal =
                        data.steps?.length < 20 ?
                            (value % 0.5 === 0 ? textVal : '') :
                            data.steps?.length < 35 ?
                                (value % 1 === 0 ? textVal : '') :
                                (value % 2 === 0 ? textVal : '')
                    break
            }

            return textVal
        }

        opts.series[0].data = data.ood//?.map(val => parseFloat(val ?? 0))
        opts.series[0].showInLegend = data.ood?.some(x => x)
        opts.series[1].data = data.oodWithGov//.map(val => parseFloat(val ?? 0))
        opts.series[1].showInLegend = data.oodWithGov?.some(x => x)

        opts.series[2].data = data.wam.map((item, index) => [index, item])

        opts.yAxis[1].visible = (step == steps[0] ? false : true)
        opts.series[2].visible = (step == steps[0] ? false : true)
        opts.series[2].showInLegend = (step == steps[0] ? false : true)

        opts.xAxis.plotLines[0].value = (chartData?.wac - minX) / step - 0.5
        opts.xAxis.plotLines[0].label.text = `${t('WAC')} ${Localization.FormatNumber(chartData.wac, 1, 2)}`

        opts.exporting.filename = chartTitle
        // opts.exporting.chartOptions.title.text = chartTitle

        setChartOptions(opts)
    }

    const onResize = () => {
        if (chart.current != null) {
            chart.current?.chart.reflow();
            // console.log('resize')
        }
    }

    const handleStep = (event: React.MouseEvent<HTMLElement>, newValue: number | null) => {
        if (newValue != null)
            setStep(newValue)
    }

    const handleLODTrack = (event: Event, value: number | Array<number>, activeThumb: number) => {
        // console.log('value', value)
        setLod(value);
    }

    return (
        <Box className={cls.root}>
            <Box className={cls.rootPaper}>
                {loading && <Box className={cls.coverLayout} />}
                <Box className={cls.panelHeader}>

                    {calculatedParameters?.wacwamDate ?
                        <>
                            <Box className={cls.panelHeaderTitle}>
                                <CustomTooltip
                                    // hideIcon
                                    classes={{ popper: cls.wideTooltip }}
                                    sx={{ '& .MuiButtonBase-root': { marginLeft: '4px', }, '& svg': { color: style.colorPrimary3_40, width: '20px', height: '20px', } }}
                                    right
                                    content={getTooltip({ tooltip: 'convention2.currentRateTitleTooltip', source: { isin: pricingParameters?.isin, wacwamDate: calculatedParameters?.wacwamDate } })}
                                >
                                    {`${t('convention2.CurrentMortgageRateAt')} ${Localization.FormatDateStringLocale(calculatedParameters?.wacwamDate)}`}
                                </CustomTooltip>
                            </Box>
                            <Box>
                                {/* кнопка СКАЧАТЬ ПУЛ */}
                                {
                                    <DownloadButton
                                        variant="outlined"
                                        size="small"
                                        disabled={chartData?.steps == null}
                                        onClick={downloadHandler}
                                    >
                                        <Tooltip
                                            disableFocusListener
                                            disableInteractive
                                            title={t('convention2.downloadPoolTooltip')}>
                                            {t("convention2.downloadPool")}
                                        </Tooltip>
                                    </DownloadButton>

                                }

                                {/* кнопка Save */}
                                {<Tooltip
                                    disableFocusListener
                                    disableInteractive
                                    title={t('convention2.SaveAsImage')}>
                                    <IconButton size="small" disabled={chartData?.steps == null} onClick={onSaveChartAsImageButtonClick}>
                                        <SaveOutlined className={cls.icon} />
                                    </IconButton>
                                </Tooltip>
                                }
                            </Box>
                        </>
                        :
                        <Box className={cls.panelHeaderTitle}>
                            {t('convention2.CurrentMortgageRate')}
                        </Box>
                    }
                </Box>
                <Box className={cls.chartContainer}>
                    <Box className={cls.chartContainerFlex}>
                        {/* <Box sx={{ flex: 1, overflow: 'hidden' }}> */}
                        {/* {console.log('chartData', chartData, chartsData)} */}
                        {chartLoading ? <SpinnerBars withBackground label={t('convention2.loadingPoolsData')} /> : <></>}
                        {(!chartData?.steps || !chartsData || chartOptions?.series == null || chartOptions.series?.length == 0) ?
                            <Box className={cls.fullPanelAlert}>
                                {chartData?.steps == null && !loadingChartData ?
                                    <div dangerouslySetInnerHTML={{ __html: t('convention2.currenRateChartIsEmpty') }} />
                                    : ''}
                            </Box> :
                            <>
                                <HighchartsReact
                                    ref={chart}
                                    highcharts={Highcharts}
                                    options={chartOptions}
                                    containerProps={{ style: { /*height: "100%", */width: "100%", flexGrow: 1 } }}
                                    callback={() => {
                                        setTimeout(() => {
                                            onResize()
                                        }, 1)
                                    }
                                    }
                                />

                                <Box className={cls.controlsContainer} sx={{ mb: 1 }}>
                                    <Box className={cls.controlBox}>
                                        <ToggleButtonGroup
                                            value={step}
                                            exclusive
                                            onChange={handleStep}
                                            aria-label="step"
                                        >
                                            {steps?.map(x => <ToggleButton
                                                size='small'
                                                sx={{ pt: 0, pb: 0 }}
                                                key={`step_${x}`} value={x}>
                                                {Localization.FormatNumber(x, 1, 1)}
                                            </ToggleButton>
                                            )}
                                        </ToggleButtonGroup>
                                    </Box>
                                    {lods?.length > 1 &&
                                        <Box className={cls.controlBox} sx={{ ml: 4 }}>
                                            <Slider
                                                aria-label={`LOD_steps`}
                                                size='small'
                                                value={lod}
                                                track={false}
                                                defaultValue={lods.length}
                                                marks={lods}
                                                max={lods[lods.length - 1].value}
                                                min={lods[0].value}
                                                onChange={handleLODTrack}
                                                classes={{
                                                    root: cls.sliderRoot,
                                                    mark: cls.mark,
                                                    active: cls.markActive,
                                                }}
                                            />
                                        </Box>
                                    }
                                </Box>
                            </>
                        }
                        {/* </Box> */}
                    </Box >
                </Box>
            </Box>
        </Box >
    )
}

export default CurrentRateChartPanel