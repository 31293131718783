import TopNavbar from "@Components/shared/TopNavbar";
import Footer from "@Components/shared/Footer";
import Sidebar from "@Components/shared/Sidebar";
import { ToastContainer } from 'react-toastify';
import Globals from "@Base/Globals";
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    layout: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%'
    },


    authContent: {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
        overflowY: 'auto'
    },

    childContainer: {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column'
        //height: 0
    },
    footer: {
        display: 'flex',
        justifyContent: 'end',
        padding: '1.5rem',
        flexShrink: 0
    }
});

const AuthorizedLayout = ({ showHamburger, area, url, history, children }) => {

    const classes = useStyles();

    return (
        <div id="authorizedLayout" className={classes.layout} >
            <TopNavbar area={area} userSwitched={Globals.isUserSwitched} showHamburger={showHamburger} />
            <Sidebar area={area} url={url} history={history} />
            <div className={classes.authContent} >
                <div className={classes.childContainer}>
                    {children}
                </div>
                <Footer />
            </div>
            <ToastContainer />
        </div>
    );
}

export default AuthorizedLayout;