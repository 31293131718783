import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Container, FormGroup } from "@mui/material";
import Helmet from "react-helmet";
import Globals from "@Base/Globals";

//import "@Styles/pages/CreateNewCalculationPage.scss";
import CalculatorService from "../../../services/CalculatorService";
import CommonService from "../../../services/CommonService";

import { Spinner } from "@Components/shared/Spinner";
import * as CalculatorStore from "@Store/CalculatorStore";
import { withRouter } from "../../../components/shared/CustomRouter";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons'
import withStyles from '@mui/styles/withStyles';

import Switch from '@mui/material/Switch';
import Card from '@mui/material/Card';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Menu from "@mui/material/Menu";
import MenuItem from '@mui/material/MenuItem';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import List from '@mui/material/List';
import ListItem, { ListItemProps } from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton, InputAdornment, Tooltip } from "@mui/material";

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HC_more from "highcharts/highcharts-more"; //module

import UI from "../../../UIHelper";
//import CalculationParameters from '@Pages/instruments/calculator/CalculationParameters';
import Localization from '@Components/Localization';
import { ValidatorForm, TextValidator, SelectValidator, DatePickerValidator, ComboboxValidator } from "@Framework/components/Form";
import BondSelectDialog from "@Components/shared/BondSelectDialog";
import CurvesParametersSelectDialog from "@Components/shared/CurvesParametersSelectDialog";
import ConfirmationDialog from "@Pages/ConfirmationDialog";
import NameDescriptionDialog from "@Pages/NameDescriptionDialog";
//import classes from '*.module.sass';
import style from '@Styles/domrf-theme.module.scss';
import { setHighchartsLocalization } from '@Utilities';
import HelpButton from '@Components/shared/HelpButton';
import { CalculationType } from '@Components/Definitions';
import { withTranslation } from "react-i18next";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

require("highcharts/modules/draggable-points")(Highcharts);

const styles = {

    root: {
        position: 'fixed',
        left: '15px',
        bottom: '15px',
        zIndex: '1100'
    },
    stickyHeader: {
        position: 'sticky',
        top: '0',
        zIndex: '1100',
        backgroundColor: '#FFFFFF'
    },
    infoText: {
        fontWeight: '400',
        fontStyle: 'italic',
        color: '#2e454f',
        marginBottom: '0',

        '& a': {
            fontWeight: 'bold'
        }
    },
    switch: {
        marginLeft: 0,
        marginBottom: 0,
    },
    tableHeader: {
        fontSize: '16px',
        //fontWeight: '600',
        color: style.colorPrimary3
    },
    tableStyle: {
        '& th': {
            paddingRight: "15px"
        },
        '& td': {
            paddingLeft: "0",
            paddingRight: "0 !important"
        },
        '& input': {
            textAlign: "right"
        }
    },
    paddingTop: {
        paddingTop: '1.5rem'
    }
};

class CreateCalculationPage extends React.Component {

    constructor(props) {
        super(props);

        HC_more(Highcharts);
        setHighchartsLocalization(Highcharts, this.props.t);

        this.state = {
            loading: false,
            showBondSelectionDialog: false,
            showCurveParametersSelectionDialog: false,
            showConfirmationDialog: false,
            showCurveParametersSaveDialog: false,

            //bond: {},
            //if we edit calculation
            calculationId: this.props.router.params.id && parseInt(this.props.router.params.id, 10),
            calculationName: '',
            calculationDescription: '',
            bondId: '',
            bondName: '',
            calculationPurpose: 0,
            type: this.props.action,
            validateErrors: [],
            //updateFlag: false,
            anchorEl: null,

            complexCalculation: this.props.router.params?.mode == 'complex',
            calculationTypeName: this.props.router.params?.mode == 'complex' ? this.props.t('Complex') : this.props.t('Simple'),
            zcycStresses: [],

            parameters: {
                evaluationDate: new Date().toISOString(),
                calculationType: CalculationType.TYPE_OAS,
                calculationValue: 130,
                actualLLDDate: new Date().toISOString(),
                zcycDate: new Date().toISOString(),
                //forwardCurveValues:null,
                //ZCYCValues: null,
                currentNominal: '',
                couponType: 1, //fixed
                couponPercentage: '',
                couponPeriod: 3,
                issueDate: null,

                firstCouponDate: null,
                startIssueAmount: '',
                cleanUpCall: '',
                nonRecurringExpenses: '',
                constantExpenses: '',
                //variableExpenses: '',
                numberOfMonteCarloScenarios: 5000,
                modelingHorizon: 20,
                numberOfEventSimulations: 1000,
                numberOfSyntheticLoans: 25,
                randomNumberSeed: '',
                sensitivityIRDelta: '',
                modelsOfDefaults: 1,
                earlyPaymentModels: 1,
                modelsOfAcceleratedDepreciation: 1,
                betModels: 1,
                mortgageRatesModels: 1,
                realEstateIndexModels: 1,
                taskArgs: ''
            },

            zcycChartOptions: {

                chart: {
                    zoomType: 'x'
                },

                exporting: { enabled: false },

                title: {
                    text: this.props.t('ChartsAndGCurveValues'),
                    style: { "color": "#2F444E", "fontSize": "18px", "fontWeight": "bold" }
                },

                credits: {
                    enabled: false
                },

                xAxis: {
                    //type: 'logarithmic',
                    title: this.props.t('Years')
                },

                yAxis: {
                    title: null
                    //min: 0
                },

                tooltip: {
                    headerFormat: '<b>{point.x:.2f}</b><br>',
                    pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y:.2f}</b><br/>',
                    //crosshairs: true,
                    //shared: true,
                    positioner: function () {
                        return { x: 0, y: 0 };
                    }
                },

                series: [
                    {
                        name: this.props.t('ForwardCurve'),
                        visible: false,
                        //type: 'spline',
                        dashStyle: 'longdash',
                        data: null,
                        color: '#8BC540',
                        lineWidth: 1,
                        marker: {
                            enabled: false,
                            symbol: 'circle'
                        },
                        /*
                        states: {
                            hover: {
                                enabled: false
                            }
                        },
                        */
                        zIndex: 1
                    },
                    {
                        name: this.props.t('GCurve'),
                        //type: 'spline',
                        data: null,
                        color: '#8BC540',
                        lineWidth: 1,
                        marker: {
                            enabled: false,
                            symbol: 'circle'
                        },
                        /*states: {
                            hover: {
                                enabled: false
                            }
                        },
                        */
                        zIndex: 2
                    },
                    {
                        name: this.props.t('StressedForwardCurve'),
                        //type: 'spline',
                        dashStyle: 'longdash',
                        data: null,
                        color: '#979ba2',
                        lineWidth: 1,
                        dragDrop: {
                            draggableY: true,
                            dragPrecisionY: 0.01
                        },
                        marker: {
                            enabled: true,
                            symbol: 'circle',
                            radius: 5
                        },
                        stickyTracking: true,
                        point: {
                            events: {
                                drop: function (e) {

                                    this.props.getZCYCUpdateData(this.state.parameters.zcycDate, e.target.series.data.map((item) => {
                                        return {
                                            year: item.x,
                                            value: item.y
                                            //no stress
                                        }
                                    }));
                                }.bind(this)
                            }
                        },
                        states: {
                            inactive: {
                                opacity: 1
                            }
                        },
                        zIndex: 10
                    },
                    {
                        name: this.props.t('StressedGCurve'),
                        //type: 'spline',
                        data: null,
                        color: '#979ba2',
                        lineWidth: 1,
                        marker: {
                            enabled: false,
                            symbol: 'circle'
                        },
                        /*
                        states: {
                            hover: {
                                enabled: false
                            }
                        },
                        */
                        zIndex: 3
                    }
                ]
            }
        }
    }

    _isMounted = false;

    /*
    commonRef = null;
    curvesRef = null;
    bondParamsRef = null;
    IssueParamsRef = null;
    ExpensesParamsRef = null;
    CalculationParamsRef = null;
    BaseRiskRef = null;
    VolatilityRef = null;
    ExtraRef = null;
    */
    //this.props.init();
    //this.findBonds('');
    //}

    //processScroll = (itemRef) => {
    //itemRef.scrollIntoView({ behavior: 'smooth' })
    //}

    calculationFormRef = null;
    chartRef = null;

    calculationTypeSource = [
        { key: 1, value: this.props.t('OAS') },
        { key: 2, value: this.props.t('Price') }
    ];

    couponTypeSource = [
        { key: 1, value: this.props.t('FixedCoupon') },
        { key: 2, value: this.props.t('FloatCoupon') },
        { key: 3, value: this.props.t('AHMLCoupon') },
        { key: 4, value: this.props.t('ThirdCoupon') },
        { key: 5, value: this.props.t('LossesCoupon') }
    ];

    couponPeriodSource = [
        { key: 1, value: '1' },
        { key: 2, value: '3' },
        { key: 3, value: '6' },
    ];

    calculationPurposeSource = [
        { key: 0, value: this.props.t('Standard') },
        { key: 1, value: this.props.t('Statistical') },
        { key: 2, value: this.props.t('System') },
        { key: 3, value: this.props.t('Marketing') },
        { key: 4, value: this.props.t('EndOfDate') },
        { key: 5, value: this.props.t('Intradate') }
    ];

    componentDidMount() {

        this._isMounted = true;

        this.props.init();

        if ((this.state.type === 'recalculate' || this.state.type === 'duplicate') && this.state.calculationId != null) {
            this.props.getCalculation(this.state.calculationId);
        }
        else
            this.props.getComplexZCYCC(this.state.parameters.zcycDate);
    }

    componentDidUpdate(prevProps, prevState) {

        if (this._isMounted == false)
            return;

        if (this.props.operationLoading != prevState.loading) {
            this.setState({ loading: this.props.operationLoading });
        }

        if (this.props.parameters != null) {

            if (prevProps.parameters?.zcycDate != this.props.parameters.zcycDate) {

                if (this.state.calculationId != null) {

                    //fix nulls
                    this.props.parameters.randomNumberSeed = this.props.parameters.randomNumberSeed || '';
                    this.props.parameters.sensitivityIRDelta = this.props.parameters.sensitivityIRDelta || '';

                    var calculationName = this.props.name;
                    if (this.state.type === 'duplicate')
                        calculationName += ' - ' + this.props.t('Duplicate_Noun');

                    this.setState({

                        loading: false,

                        calculationName: calculationName,
                        calculationDescription: this.props.description,
                        calculationPurpose: this.props.calculationPurpose,

                        zcycDate: this.props.parameters.zcycDate,
                        currentNominal: this.props.parameters.currentNominal,
                        bondName: this.props.bondName,
                        portfolioId: this.props.portfolioId,
                        bondId: this.props.bondId,

                        parameters: {
                            ...this.state.parameters,
                            ...this.props.parameters,
                            actualLLDDate: this.props.portfolioActualDate,
                        }
                    });
                }
            }
            if (this.state.zcycChartOptions.series[2].data != this.props.forward_curve_modified_chart_data) {

                var newZcycChart = { ...this.state.zcycChartOptions };
                newZcycChart.series[0].data = this.props.forward_curve_base_chart_data;
                newZcycChart.series[1].data = this.props.zcy_curve_base_chart_data;
                newZcycChart.series[2].data = this.props.forward_curve_modified_chart_data;
                newZcycChart.series[3].data = this.props.zcy_curve_modified_chart_data;
                var newZcycStresses = newZcycChart.series[2].data.map((item) => { return { x: item.x, y: Localization.FormatNumberStringISO(item.y) } })

                this.setState({
                    zcycChartOptions: newZcycChart,
                    zcycStresses: newZcycStresses
                });
            }
            else if (prevState.parameters.zcycDate != this.state.parameters.zcycDate) {
                if (this.state.parameters.zcycDate.toString() != 'Invalid Date')
                    this.props.getComplexZCYCC(this.state.parameters.zcycDate);
            }
        }

        if (prevProps.bondStats != this.props.bondStats) {

            var value = this.props.bondStats;
            var bond = value.bond;

            var parameters = {
                ...this.state.parameters,
                actualLLDDate: value.portfolioActualDate,
                zcycDate: value.zcycDate,
                currentNominal: value.nominal,
            };

            var coupon = this.couponTypeSource.find(x => x.key == bond.couponType)?.value;

            var bondStats = [
                { name: this.props.t('IssueDate'), description: this.props.t('Parameters_IssueDate'), value: Localization.FormatDateStringLocale(bond.issueDate) || '–' },
                { name: this.props.t('NextCouponPaymentDate'), description: this.props.t('CouponDateDescription'), value: Localization.FormatDateStringLocale(value.nextCouponPaymentDate) || '–' },
                { name: this.props.t('CouponPeriod'), description: this.props.t('Parameters_CouponPeriod'), value: bond.couponPeriod },
                { name: this.props.t('StartIssueAmount'), description: this.props.t('Parameters_StartIssueAmount'), value: bond.startIssueAmount || '–' },
                { name: this.props.t('CurrentIssueAmount'), description: this.props.t('CurrentIssueAmount_Info'), value: value.currentIssueAmount || '–' },
                { name: this.props.t('CurrentNominalPrc'), description: this.props.t('NominalDescription'), value: value.nominal || '–' },
                { name: this.props.t('CouponType'), description: this.props.t('Parameters_CouponType'), value: coupon || '–' },
                { name: this.props.t('CurrentCouponRate'), description: this.props.t('RateDescription'), value: value.currentCouponRate || '–' },
                { name: this.props.t('CleanUpCall'), description: this.props.t('Parameters_CleanUpCall'), value: bond.cleanUpCall || '–' },
                { name: this.props.t('AverageCPR_Info'), description: this.props.t('CPRHist_Info'), value: value.historicalCPR || '–' },
                { name: this.props.t('AverageCDR_Info'), description: this.props.t('CDRHist_Info'), value: value.historicalCDR || '–' }
            ];

            this.copyBondParameters(parameters, bond);

            this.setState({
                bondName: bond.name,
                bondId: bond.id,
                portfolioId: value.portfolioId,
                parameters: parameters,
                bondStats: bondStats
            });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    resetState() {

        this.setState({
            loading: false,
            showBondSelectionDialog: false,
            showConfirmationDialog: false,
            showCurveParametersSelectionDialog: false,
            showCurveParametersSaveDialog: false,

            calculationId: null,
            calculationName: '',
            calculationDescription: '',

            bondName: '',
            calculationPurpose: 0,

            showConfirmationDialog: false,
            validateErrors: [],
            //updateFlag: false,
            anchorEl: null,

            parameters: {
                evaluationDate: new Date().toISOString(),
                calculationType: CalculationType.TYPE_OAS,
                calculationValue: 130,
                actualLLDDate: new Date().toISOString(),
                zcycDate: new Date().toISOString(),
                //forwardCurveValues:null,
                //ZCYCValues: null,
                currentNominal: '',
                couponType: 1, //fixed
                couponPercentage: '',
                couponPeriod: 3,
                issueDate: null,

                firstCouponDate: null,
                startIssueAmount: '',
                cleanUpCall: '',
                nonRecurringExpenses: '',
                constantExpenses: '',
                //variableExpenses: '',
                numberOfMonteCarloScenarios: 5000,
                modelingHorizon: 20,
                numberOfEventSimulations: 1000,
                numberOfSyntheticLoans: 25,
                randomNumberSeed: '',
                sensitivityIRDelta: '',
                modelsOfDefaults: 1,
                earlyPaymentModels: 1,
                modelsOfAcceleratedDepreciation: 1,
                betModels: 1,
                mortgageRatesModels: 1,
                realEstateIndexModels: 1,
                taskArgs: ''
            }
        });
    }

    handleTopInputChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleInputChange = (event) => {
        this.setState({ parameters: { ...this.state.parameters, [event.target.name]: event.target.value } });
    }

    handleDateChange = (name, date) => {
        this.setState({ parameters: { ...this.state.parameters, [name]: date } });
    }

    handleCheckChange = (event) => {
        this.setState({ parameters: { ...this.state.parameters, [event.target.name]: event.target.checked } });
    }

    handleZCYCDateChange = (name, event) => {
        this.handleDateChange(name, event);
        //if (event.toString() != 'Invalid Date')
        //this.props.getComplexZCYCC(event, false);
    }

    handleZCYCChange = (event, index) => {
        var newZcycStresses = this.state.zcycStresses.slice();
        newZcycStresses[index].y = event.target.value;

        this.setState({ zcycStresses: newZcycStresses });
    }

    handleZCYCBlur = (event, index) => {
        var data = this.state.zcycStresses[index].y;

        if (data == null || data == '') {

            var newZcycStress = this.state.zcycStresses.slice();
            newZcycStress[index].y = this.state.zcycChartOptions.series[2].data[index].y;
            this.setState({ zcycStresses: newZcycStress });
            return;
        }


        if ((Math.round(this.state.zcycChartOptions.series[2].data[index].y * 100) / 100) == parseFloat(data))
            return;

        /*
        var newZcycChart = { ...this.state.zcycChartOptions };

        newZcycChart.series[2].data[index].y = parseFloat(data);

        this.setState({ zcycChartOptions: newZcycChart });
        */

        var newData = this.state.zcycChartOptions.series[2].data.slice();
        newData[index].y = parseFloat(data);

        this.props.getZCYCUpdateData(this.state.parameters.zcycDate, newData.map((item) => {
            return {
                year: item.x,
                value: item.y
                //no stress
            }
        }));
    }

    //update portfolio
    handleEvaluationDateChange = (name, date) => {

        this.handleDateChange(name, date);

        if ((this.state.bondId || '') == '') {
            return;
        }

        this.props.getBondInformation(this.state.bondId, date.toISOString());
    }


    startCalculation = () => {
        this.calculationFormRef.submit();
    }

    handleConfirmationDialogClose = (event, isApply) => {
        this.setState({ showConfirmationDialog: false });

        if (isApply == false)
            return;

        (async () => {

            var result = null;

            if (this.state.complexCalculation) {
                var data = {
                    calculationId: this.state.calculationId,
                    name: this.state.calculationName,
                    description: this.state.calculationDescription,
                    portfolioId: this.state.portfolioId,
                    bondId: this.state.bondId,
                    calculationPurpose: this.state.calculationPurpose,
                    parameters: this.state.parameters
                };

                var stresses = [];
                for (var i = 0; i < this.props.forward_curve_modified_chart_data.length; i++) {
                    var point = this.props.forward_curve_base_chart_data.find(x => x[0] == this.props.forward_curve_modified_chart_data[i].x);
                    if (point != null)
                        stresses.push({ year: this.props.forward_curve_modified_chart_data[i].x, stress: Math.round((this.props.forward_curve_modified_chart_data[i].y - point[1] + 0.0005) * 100) / 100 });
                }

                if (!stresses.every((item) => item.stress == 0))
                    data.parameters.forwardCurveValues = stresses;

                this.setState({ loading: true });

                switch (this.state.type) {
                    case 'recalculate':
                        {
                            result = await CalculatorService.recalculateCalculation(data);
                        }
                        break;
                    default:
                        result = await CalculatorService.createCalculation(data);
                }
            }
            else {
                //simple calculation
                var data = {
                    name: this.state.calculationName,
                    description: this.state.calculationDescription,
                    bondId: this.state.bondId,
                    evaluationDate: this.state.parameters.evaluationDate,
                    calculationType: this.state.parameters.calculationType,
                    calculationValue: this.state.parameters.calculationValue
                };

                result = await CalculatorService.createSimpleCalculation(data);
            }

            this.setState({ loading: false });

            if (result.hasErrors) {
                return;
            }

            UI.showSuccess(this.props.t('CalculationCreated'));

            this.resetState();
        })();
    }

    handleConfirmRender = () => {
        return (
            <div>
                {
                    (this.state.validateErrors.length != 0) && (
                        <div>
                            <h4 className="color-error">
                                {this.props.t('Attention')}
                            </h4>
                            <ul>
                                {
                                    this.state.validateErrors.map((item, index) => {
                                        return <li key={"error-" + index}>{item}</li>
                                    })
                                }
                            </ul>
                        </div>
                    )
                }
                {this.props.t('CreateCalculationQuestion')}
            </div >
        )
    }

    handleCalculationSubmit = (event) => {

        var evalDate = new Date(this.state.parameters.evaluationDate);
        var lldDate = new Date(this.state.parameters.actualLLDDate);

        var errors = [];

        var futureLLDDate = new Date(lldDate);
        futureLLDDate.setMonth(lldDate.getMonth() + 2);

        if (lldDate > evalDate) {
            errors.push(this.props.t('ActualLLDDateBiggerThanCalculationDate_Info'));
        }

        if (futureLLDDate < evalDate) {
            errors.push(this.props.t('ActualLLDDateLesser2Month_Info'));
        }

        this.setState({ showConfirmationDialog: true, validateErrors: errors });
    }

    handleCurveDialogClose = () => {
        this.setState({ showCurveParametersSelectionDialog: false });
    }

    handleCurveSelection = (event, selected) => {
        if (selected == null || selected.length == 0)
            return;

        this.props.getCurveData(selected[0], this.state.parameters.zcycDate);
    }

    handleClickBondSearch = () => {

        this.setState({ showBondSelectionDialog: true });
    }

    handleBondDialogClose = () => {
        this.setState({ showBondSelectionDialog: false });
    }

    //CHECKME!!! move to redux
    handleBondSelection = (event, selected) => {

        if (selected == null || selected.length == 0)
            return;

        this.setState({ loading: true });

        (async () => {

            var result = await CalculatorService.getBondInformation(selected[0], this.state.parameters.evaluationDate);
            if (result.hasErrors) {
                this.setState({ loading: false });
                return;
            }

            var value = result.value;

            if (value != null) {

                var bond = value.bond;

                var parameters = {
                    ...this.state.parameters,
                    actualLLDDate: value.portfolioActualDate,
                    zcycDate: value.zcycDate,
                    currentNominal: value.nominal,
                };

                this.copyBondParameters(parameters, bond);

                this.setState({

                    //bond: bond,
                    bondName: bond.name,
                    bondId: bond.id,
                    portfolioId: value.portfolioId,
                    parameters: parameters

                });
            }

            this.setState({ loading: false });

        })();

    }

    copyBondParameters = (parameters, bond) => {

        if (bond.couponType != null)
            parameters.couponType = bond.couponType;

        if (bond.couponPercentage != null)
            parameters.couponPercentage = bond.couponPercentage;

        if (bond.couponPeriod != null)
            parameters.couponPeriod = bond.couponPeriod;

        if (bond.issueDate != null)
            parameters.issueDate = bond.issueDate;

        if (bond.firstCouponDate != null)
            parameters.firstCouponDate = bond.firstCouponDate;

        if (bond.startIssueAmount != null)
            parameters.startIssueAmount = bond.startIssueAmount;

        if (bond.cleanUpCall != null)
            parameters.cleanUpCall = bond.cleanUpCall;

        if (bond.nonRecurringExpenses != null)
            parameters.nonRecurringExpenses = bond.nonRecurringExpenses;

        if (bond.constantExpenses != null)
            parameters.constantExpenses = bond.constantExpenses;

        //if (bond.variableExpenses != null)
        //parameters.variableExpenses = bond.variableExpenses;
    }

    onExtraButtonClick = (event) => {
        this.setState({ anchorEl: event.currentTarget });
    }

    onZCYCMenuClose = (event) => {
        this.setState({ anchorEl: null });
    }

    onZCYCReset = async (event) => {

        this.setState({ anchorEl: null });

        this.props.getComplexZCYCC(this.state.parameters.zcycDate, true);
    }

    onZCYCLoad = (event) => {

        this.setState({ anchorEl: null });
        this.setState({ showCurveParametersSelectionDialog: true });
    }

    onZCYCSave = (event) => {

        this.setState({ anchorEl: null });
        this.setState({ showCurveParametersSaveDialog: true });
    }

    handleSaveCurveDialogClose = (event, data) => {
        if (data != null) {

            //save data
            data.values = [];

            for (var i = 0; i < this.state.forward_curve_modified_chart_data.length; i++) {
                var point = this.state.forward_curve_base_chart_data.find(x => x[0] == this.state.forward_curve_modified_chart_data[i].x);
                if (point != null)
                    data.values.push({ year: this.state.forward_curve_modified_chart_data[i].x, value: Math.round((this.state.forward_curve_modified_chart_data[i].y - point[1] + 0.0005) * 100) / 100 });
            }

            this.props.saveZCYCValues(data)
        }

        this.setState({ showCurveParametersSaveDialog: false });
    }

    handleSaveCurve = (event, data) => {
        this.setState({ showCurveParametersSaveDialog: false });

    }

    switchView = (event, checked) => {
        this.setState({
            [event.target.name]: checked, calculationTypeName: checked ? this.props.t('Complex') : this.props.t('Simple')
        });
    };

    render() {

        const { classes, t } = this.props;

        var title = t('NewCalculation');
        var header = t('MBSCalculator');
        if (this.state.calculationId != null) {
            switch (this.state.type) {
                case 'recalculate': {
                    title = t('Recalculation');
                    header += '-' + t('Recalculation');
                }
                    break;
                case 'duplicate':
                    {
                        title = t('Duplicate');
                        header += '-' + t('Duplicate');
                    }
                    break;
                default: {
                    header += '-' + t('CreateCalculation');
                }
            }
        }

        return (
            <Container sx={{height: '100%'}}>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{header}</title>
                </Helmet>

                <Spinner show={this.state.loading} fullScreen content="Обработка..." />
                <BondSelectDialog area="newcalculation" show={this.state.showBondSelectionDialog} onClose={this.handleBondDialogClose} onOK={this.handleBondSelection} />
                <CurvesParametersSelectDialog show={this.state.showCurveParametersSelectionDialog} onClose={this.handleCurveDialogClose} onOK={this.handleCurveSelection} />
                <NameDescriptionDialog show={this.state.showCurveParametersSaveDialog} onClose={this.handleSaveCurveDialogClose} onOK={this.handleSaveCurve} title={t('SaveZCYCValues')} />

                <ConfirmationDialog show={this.state.showConfirmationDialog} onClose={this.handleConfirmationDialogClose} title={t('Calculation')} renderBody={this.handleConfirmRender} />


                <Button className={classes.root} variant="contained" color="secondary" onClick={this.startCalculation}>
                    {t('BeginCalculation')}
                </Button>

                {/*
                <Paper className={classes.root}>
                    <List component="nav" aria-label="calculation">
                        <ListItem button onClick={() => this.processScroll(this.dataRef)}>
                            <ListItemText primary={t('Data')} />
                        </ListItem>

                        <ListItem button onClick={() => this.processScroll(this.commonRef)}>
                            <ListItemText primary={t('Common')} />
                        </ListItem>

                        <ListItem button onClick={() => this.processScroll(this.curvesRef)}>
                            <ListItemText primary={t('Curves')} />
                        </ListItem>
                        <ListItem button onClick={() => this.processScroll(this.bondParamsRef)}>
                            <ListItemText primary={t('BondParameters')} />
                        </ListItem>
                        <ListItem button onClick={() => this.processScroll(this.IssueParamsRef)}>
                            <ListItemText primary={t('IssueParameters')} />
                        </ListItem>
                        <ListItem button onClick={() => this.processScroll(this.ExpensesParamsRef)}>
                            <ListItemText primary={t('ExpensesParameters')} />
                        </ListItem>
                        <ListItem button onClick={() => this.processScroll(this.CalculationParamsRef)}>
                            <ListItemText primary={t('CalculationParameters')} />
                        </ListItem>

                        <ListItem button onClick={() => this.processScroll(this.BaseRiskRef)}>
                            <ListItemText primary={t('BaseRiskFunctionsModification')} />
                        </ListItem>

                        <ListItem button onClick={() => this.processScroll(this.VolatilityRef)}>
                            <ListItemText primary={t('VolatilityModification')} />
                        </ListItem>

                        <ListItem button onClick={() => this.processScroll(this.ExtraRef)}>
                            <ListItemText primary={t('ExtraParameters')} />
                        </ListItem>

                    </List>
                    <Button fullWidth variant="contained" color="secondary" onClick={this.startCalculation}>
                        {t('CreateCalculation')}
                    </Button>
                </Paper>
                */}
                <Grid container>
                    <Grid md={8}>
                        <h3>{title}</h3>
                    </Grid>
                    <Grid md={4} sx={{ display: 'flex', justifyContent:'end', alignItems: 'center' }}>
                        <FormControlLabel
                            className={classes.switchLabel}
                            control={<Switch color="secondary" name="complexCalculation" checked={this.state.complexCalculation} onChange={this.switchView} />}
                            label={this.state.calculationTypeName}
                            labelPlacement="start"
                        />
                    </Grid>
                </Grid>
                <ValidatorForm ref={x => this.calculationFormRef = x} onSubmit={this.handleCalculationSubmit}>
                    {
                        (!this.state.complexCalculation) && (
                            <Paper sx={{padding: '3rem', marginTop: '1rem'}}>

                                <div className={classes.stickyHeader}>
                                    <h4>{t('Data')}</h4>
                                </div>

                                <div sx={{ paddingTop: '1rem' }}>
                                    <FormGroup sx={{ paddingX: '1rem', marginBottom: '1rem' }}>
                                        <TextValidator
                                            type="text"
                                            label={t('CalculationName')}
                                            name="calculationName"
                                            id="calculationName"
                                            fullWidth={true}
                                            onChange={this.handleTopInputChange}
                                            value={this.state.calculationName}
                                            validators={['required']}
                                            errorMessages={[this.props.t('FieldRequired')]} />
                                    </FormGroup>
                                    <FormGroup sx={{ paddingX: '1rem', marginBottom: '1rem' }}>
                                        <TextField
                                            type="text"
                                            label={t('Description')}
                                            name="calculationDescription"
                                            id="calculationDescription"
                                            fullWidth={true}
                                            onChange={this.handleTopInputChange}
                                            value={this.state.calculationDescription} />
                                    </FormGroup>

                                    <FormGroup sx={{ paddingX: '1rem', marginBottom: '1rem' }}>
                                        <TextValidator
                                            type="text"
                                            label={t('Bond')}
                                            value={this.state.bondName}
                                            name="bond"
                                            id="bond"
                                            fullWidth={true}
                                            InputProps={{
                                                readOnly: true,
                                                endAdornment: (
                                                    <InputAdornment position="end" >
                                                        <IconButton
                                                            aria-label="search"
                                                            onClick={this.handleClickBondSearch}
                                                            edge="end"
                                                            size="large">
                                                            {<SearchIcon />}
                                                        </IconButton>
                                                    </InputAdornment>)
                                            }}
                                            validators={['required']}
                                            errorMessages={[this.props.t('FieldRequired')]}
                                        />
                                    </FormGroup>

                                    <FormGroup sx={{ paddingX: '1rem', marginBottom: '1rem' }}>
                                        <DatePickerValidator
                                            label={t('EvaluationDate')}
                                            name="evaluationDate"
                                            id="evaluationDate"
                                            fullWidth={true}
                                            mask={Localization.DateMask()}
                                            format={Localization.ShortDatePattern()}
                                            value={this.state.parameters.evaluationDate}
                                            onChange={(e) => this.handleEvaluationDateChange("evaluationDate", e)}
                                            validators={['required', 'invalidDate']}
                                            errorMessages={[this.props.t('FieldRequired'), this.props.t('InvalidDate')]} />
                                    </FormGroup>
                                    <FormGroup sx={{ paddingX: '1rem', marginBottom: '1rem' }}>
                                        <Grid container>
                                            <Grid md={8} sx={{paddingRight: '1rem'}}>
                                                <SelectValidator
                                                    type="text"
                                                    label={t('CalculationType')}
                                                    name="calculationType"
                                                    id="calculationType"
                                                    fullWidth={true}
                                                    onChange={this.handleInputChange}
                                                    value={this.state.parameters.calculationType}
                                                    validators={['required']}
                                                    errorMessages={[this.props.t('FieldRequired')]}>
                                                    {
                                                        (this.calculationTypeSource || []).map(option => (
                                                            <MenuItem key={option.key} value={option.key}>
                                                                {option.value}
                                                            </MenuItem>))
                                                    }
                                                </SelectValidator>
                                            </Grid>
                                            <Grid md={4}>
                                                <TextValidator
                                                    type="number"
                                                    label={t('Value')}
                                                    name="calculationValue"
                                                    id="calculationValue"
                                                    fullWidth={true}
                                                    onChange={this.handleInputChange}
                                                    value={this.state.parameters.calculationValue}
                                                    validators={['required']}
                                                    errorMessages={[this.props.t('FieldRequired')]} />
                                            </Grid>
                                        </Grid>
                                    </FormGroup>
                                </div>
                            </Paper>
                        )
                    }

                    {
                        (this.state.complexCalculation) && (
                            <div>
                                <Paper sx={{padding: '3rem', marginTop:'1rem'}}>

                                    <div className={classes.stickyHeader}>
                                        <h4>{t('Data')}</h4>
                                    </div>

                                    <div className={classes.paddingTop}>
                                        <FormGroup sx={{ paddingX: '1rem', marginBottom: '1rem' }}>
                                            <TextValidator
                                                type="text"
                                                label={t('CalculationName')}
                                                name="calculationName"
                                                id="calculationName"
                                                fullWidth={true}
                                                onChange={this.handleTopInputChange}
                                                value={this.state.calculationName}
                                                validators={['required']}
                                                errorMessages={[this.props.t('FieldRequired')]} />
                                        </FormGroup>
                                        <FormGroup sx={{ paddingX: '1rem', marginBottom: '1rem' }}>
                                            <TextField
                                                type="text"
                                                label={t('Description')}
                                                name="calculationDescription"
                                                id="calculationDescription"
                                                fullWidth={true}
                                                onChange={this.handleTopInputChange}
                                                value={this.state.calculationDescription} />
                                        </FormGroup>

                                        <FormGroup sx={{ paddingX: '1rem', marginBottom: '1rem' }}>
                                            <TextValidator
                                                type="text"
                                                label={t('Bond')}
                                                value={this.state.bondName}
                                                name="bond"
                                                id="bond"
                                                fullWidth={true}
                                                InputProps={{
                                                    readOnly: true,
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton onClick={this.handleClickBondSearch} size="large">
                                                                <SearchIcon />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                                validators={['required']}
                                                errorMessages={[this.props.t('FieldRequired')]}
                                            />
                                        </FormGroup>
                                    </div>
                                </Paper>

                                <Paper sx={{padding: '3rem', marginTop:'1rem'}}>

                                    <div className={classes.stickyHeader}>
                                        <h4>{t('Common')}</h4>
                                    </div>

                                    <div className={classes.paddingTop}>

                                        <FormGroup sx={{ paddingX: '1rem', marginBottom: '1rem' }}>
                                            <DatePickerValidator
                                                label={t('EvaluationDate')}
                                                name="evaluationDate"
                                                id="evaluationDate"
                                                fullWidth={true}
                                                mask={Localization.DateMask()}
                                                format={Localization.ShortDatePattern()}
                                                value={this.state.parameters.evaluationDate}
                                                onChange={(e) => this.handleEvaluationDateChange("evaluationDate", e)}
                                                validators={['required', 'invalidDate']}
                                                errorMessages={[this.props.t('FieldRequired'), this.props.t('InvalidDate')]} />
                                        </FormGroup>
                                        <FormGroup sx={{ paddingX: '1rem', marginBottom: '1rem' }}>
                                            <Grid container>
                                                <Grid md={8}>
                                                    <SelectValidator
                                                        type="text"
                                                        label={t('CalculationType')}
                                                        name="calculationType"
                                                        id="calculationType"
                                                        fullWidth={true}
                                                        onChange={this.handleInputChange}
                                                        value={this.state.parameters.calculationType}
                                                        validators={['required']}
                                                        errorMessages={[this.props.t('FieldRequired')]}>
                                                        {
                                                            (this.calculationTypeSource || []).map(option => (
                                                                <MenuItem key={option.key} value={option.key}>
                                                                    {option.value}
                                                                </MenuItem>))
                                                        }
                                                    </SelectValidator>
                                                </Grid>
                                                <Grid md={4}>
                                                    <TextValidator
                                                        type="number"
                                                        label={t('Value')}
                                                        name="calculationValue"
                                                        id="calculationValue"
                                                        fullWidth={true}
                                                        onChange={this.handleInputChange}
                                                        value={this.state.parameters.calculationValue}
                                                        validators={['required']}
                                                        errorMessages={[this.props.t('FieldRequired')]} />
                                                </Grid>
                                            </Grid>
                                        </FormGroup>
                                        <FormGroup sx={{ paddingX: '1rem', marginBottom: '1rem' }}>
                                            <DatePickerValidator
                                                label={t('PortfolioDate')}
                                                name="actualLLDDate"
                                                id="actualLLDDate"
                                                fullWidth={true}
                                                disabled
                                                mask={Localization.DateMask()}
                                                format={Localization.ShortDatePattern()}
                                                value={this.state.parameters.actualLLDDate}
                                                onChange={(e) => this.handleDateChange("actualLLDDate", e)}
                                                validators={['required', 'invalidDate']}
                                                errorMessages={[this.props.t('FieldRequired'), this.props.t('InvalidDate')]} />
                                        </FormGroup>

                                        <FormGroup sx={{ paddingX: '1rem', marginBottom: '1rem' }}>
                                            <DatePickerValidator
                                                label={t('ActualZCYCDate')}
                                                name="zcycDate"
                                                id="zcycDate"
                                                fullWidth={true}
                                                mask={Localization.DateMask()}
                                                format={Localization.ShortDatePattern()}
                                                value={this.state.parameters.zcycDate}
                                                onChange={(e) => this.handleZCYCDateChange("zcycDate", e)}
                                                validators={['required', 'invalidDate']}
                                                errorMessages={[this.props.t('FieldRequired'), this.props.t('InvalidDate')]} />
                                        </FormGroup>

                                        <FormGroup sx={{ paddingX: '1rem', marginBottom: '1rem' }}>
                                            <HighchartsReact
                                                ref={x => this.chartRef = x}
                                                highcharts={Highcharts}
                                                options={this.state.zcycChartOptions}
                                            />
                                        </FormGroup>
                                        <FormGroup sx={{ paddingX: '1rem', marginBottom: '1rem' }}>
                                            {
                                                this.state.zcycStresses.length != 0 && (
                                                    <Table aria-label="zcyc table" className={classes.tableStyle} size="small">
                                                        <TableHead>
                                                            <TableRow>
                                                                {
                                                                    this.state.zcycStresses.map((item, index) => {
                                                                        return (
                                                                            <TableCell key={"zcyc-x-" + index} className={classes.tableHeader} align="right">
                                                                                {Localization.FormatNumberString(item.x)}
                                                                            </TableCell>
                                                                        )
                                                                    })
                                                                }
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            <TableRow>
                                                                {
                                                                    this.state.zcycStresses.map((item, index) => {
                                                                        return (
                                                                            <TableCell key={"zcyc-y-" + index}>
                                                                                <InputBase type="number" value={item.y} onBlur={(event) => this.handleZCYCBlur(event, index)} onChange={(event) => this.handleZCYCChange(event, index)} />
                                                                            </TableCell>
                                                                        )
                                                                    })
                                                                }
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                )}
                                        </FormGroup>
                                        <FormGroup sx={{ paddingX: '1rem', marginBottom: '1rem' }}>
                                            <Grid container>
                                                <Grid md={9}>
                                                    <p className={classes.infoText}>
                                                        {t('LearnAbountZCYC')}
                                                        <a href="https://www.moex.com/ru/marketdata/indices/state/g-curve/">{' ' + this.props.t('here')}</a>
                                                    </p>
                                                </Grid>
                                                <Grid md={3} className="text-right">
                                                    <Button aria-controls="extra-menu" aria-haspopup="true" variant="text" color="primary" onClick={this.onExtraButtonClick}>
                                                        {t('Extra')}
                                                    </Button>

                                                    <Menu
                                                        id="extra-menu"
                                                        anchorEl={this.state.anchorEl}
                                                        keepMounted
                                                        open={Boolean(this.state.anchorEl)}
                                                        onClose={this.onZCYCMenuClose}>

                                                        <MenuItem onClick={this.onZCYCReset}>{t('Reset')}</MenuItem>
                                                        <MenuItem onClick={this.onZCYCLoad}>{t('Load')}</MenuItem>
                                                        <MenuItem onClick={this.onZCYCSave}>{t('Save')}</MenuItem>
                                                    </Menu>
                                                </Grid>
                                            </Grid>
                                        </FormGroup>

                                    </div>
                                </Paper>

                                <Paper sx={{padding: '3rem', marginTop:'1rem'}}>

                                    <div className={classes.stickyHeader}>
                                        <h4>{t('BondParameters')}</h4>
                                    </div>

                                    <div className={classes.paddingTop}>
                                        <FormGroup sx={{ paddingX: '1rem', marginBottom: '1rem' }}>
                                            <TextValidator
                                                type="number"
                                                label={this.props.t('CurrentNominal_Info')}
                                                name="currentNominal"
                                                id="currentNominal"
                                                fullWidth={true}
                                                onChange={this.handleInputChange}
                                                value={this.state.parameters.currentNominal}
                                                validators={['required']}
                                                errorMessages={[this.props.t('FieldRequired')]} />
                                        </FormGroup>
                                        <FormGroup sx={{ paddingX: '1rem', marginBottom: '1rem' }}>
                                            <SelectValidator
                                                label={t('CouponType')}
                                                name="couponType"
                                                id="couponType"
                                                fullWidth={true}
                                                onChange={this.handleInputChange}
                                                value={this.state.parameters.couponType}
                                                validators={['required']}
                                                errorMessages={[this.props.t('FieldRequired')]}>
                                                {
                                                    (this.couponTypeSource || []).map(option => (
                                                        <MenuItem key={option.key} value={option.key}>
                                                            {option.value}
                                                        </MenuItem>))
                                                }
                                            </SelectValidator>
                                        </FormGroup>

                                        <FormGroup sx={{ paddingX: '1rem', marginBottom: '1rem' }}>
                                            <TextValidator
                                                type="number"
                                                label={t('CouponPercentage')}
                                                value={this.state.parameters.couponPercentage}
                                                name="couponPercentage"
                                                id="couponPercentage"
                                                fullWidth={true}
                                                onChange={this.handleInputChange}
                                                validators={['required']}
                                                errorMessages={[this.props.t('FieldRequired')]}
                                                inputProps={{ step: '0.05' }} />
                                        </FormGroup>
                                    </div>
                                </Paper>

                                <Paper sx={{padding: '3rem', marginTop:'1rem'}}>

                                    <div className={classes.stickyHeader}>
                                        <h4>{t('IssueParameters')}</h4>
                                    </div>

                                    <div className={classes.paddingTop}>
                                        <FormGroup sx={{ paddingX: '1rem', marginBottom: '1rem' }}>
                                            <SelectValidator
                                                label={t('CouponPeriod')}
                                                name="couponPeriod"
                                                id="couponPeriod"
                                                fullWidth={true}
                                                onChange={this.handleInputChange}
                                                value={this.state.parameters.couponPeriod}
                                                validators={['required']}
                                                errorMessages={[this.props.t('FieldRequired')]}>
                                                {
                                                    (this.couponPeriodSource || []).map(option => (
                                                        <MenuItem key={option.key} value={option.value}>
                                                            {option.value}
                                                        </MenuItem>))
                                                }
                                            </SelectValidator>
                                        </FormGroup>

                                        <FormGroup sx={{ paddingX: '1rem', marginBottom: '1rem' }}>
                                            <DatePickerValidator
                                                label={t('IssueDate')}
                                                name="issueDate"
                                                id="issueDate"
                                                fullWidth={true}
                                                mask={Localization.DateMask()}
                                                format={Localization.ShortDatePattern()}
                                                value={this.state.parameters.issueDate}
                                                onChange={(e) => this.handleDateChange("issueDate", e)}
                                                validators={['required', 'invalidDate']}
                                                errorMessages={[this.props.t('FieldRequired'), this.props.t('InvalidDate')]} />

                                        </FormGroup>

                                        <FormGroup sx={{ paddingX: '1rem', marginBottom: '1rem' }}>
                                            <DatePickerValidator
                                                label={t('FirstCouponDate')}
                                                name="firstCouponDate"
                                                id="firstCouponDate"
                                                fullWidth={true}
                                                mask={Localization.DateMask()}
                                                format={Localization.ShortDatePattern()}
                                                value={this.state.parameters.firstCouponDate}
                                                onChange={(e) => this.handleDateChange("firstCouponDate", e)}
                                                validators={['required', 'invalidDate']}
                                                errorMessages={[this.props.t('FieldRequired'), this.props.t('InvalidDate')]} />
                                        </FormGroup>

                                        <FormGroup sx={{ paddingX: '1rem', marginBottom: '1rem' }}>
                                            <TextValidator
                                                type="number"
                                                label={t('StartIssueAmount')}
                                                value={this.state.parameters.startIssueAmount}
                                                name="startIssueAmount"
                                                id="startIssueAmount"
                                                fullWidth={true}
                                                onChange={this.handleInputChange}
                                                validators={['required']}
                                                errorMessages={[this.props.t('FieldRequired')]} />
                                        </FormGroup>

                                        <FormGroup sx={{ paddingX: '1rem', marginBottom: '1rem' }}>
                                            <TextValidator
                                                type="number"
                                                label={t('CleanUpCall')}
                                                value={this.state.parameters.cleanUpCall}
                                                name="cleanUpCall"
                                                id="cleanUpCall"
                                                fullWidth={true}
                                                onChange={this.handleInputChange}
                                                validators={['required']}
                                                errorMessages={[this.props.t('FieldRequired')]} />
                                        </FormGroup>

                                    </div>
                                </Paper>

                                <Paper sx={{padding: '3rem', marginTop:'1rem'}}>

                                    <div className={classes.stickyHeader}>
                                        <h4>{t('ExpensesParameters')}</h4>
                                    </div>

                                    <div className={classes.paddingTop}>

                                        <FormGroup sx={{ paddingX: '1rem', marginBottom: '1rem' }}>
                                            <TextValidator
                                                type="number"
                                                label={t('NonRecurringExpenses')}
                                                name="nonRecurringExpenses"
                                                id="nonRecurringExpenses"
                                                fullWidth={true}
                                                onChange={this.handleInputChange}
                                                value={this.state.parameters.nonRecurringExpenses}
                                                validators={['required']}
                                                errorMessages={[this.props.t('FieldRequired')]} />
                                        </FormGroup>

                                        <FormGroup sx={{ paddingX: '1rem', marginBottom: '1rem' }}>
                                            <TextValidator
                                                type="number"
                                                id="constantExpenses"
                                                name="constantExpenses"
                                                label={t('ConstantExpensesWithUnits')}
                                                value={this.state.parameters.constantExpenses}
                                                fullWidth={true}
                                                onChange={this.handleInputChange}
                                                validators={['required']}
                                                errorMessages={[this.props.t('FieldRequired')]} />
                                        </FormGroup>
                                        {/*
                                        <FormGroup sx={{ paddingX: '1rem', marginBottom: '1rem' }}>
                                            <TextValidator
                                                type="number"
                                                id="variableExpenses"
                                                name="variableExpenses"
                                                label={t('VariableExpenses')}
                                                value={this.state.parameters.variableExpenses}
                                                fullWidth={true}
                                                onChange={this.handleInputChange}
                                                validators={['required']}
                                                errorMessages={[this.props.t('FieldRequired')]} />
                                        </FormGroup>
                                        */}
                                    </div>
                                </Paper>

                                <Paper sx={{padding: '3rem', marginTop:'1rem'}}>

                                    <div className={classes.stickyHeader}>
                                        <h4>{t('CalculationParameters')}</h4>
                                    </div>

                                    <div className={classes.paddingTop}>

                                        <FormGroup sx={{ paddingX: '1rem', marginBottom: '1rem' }}>
                                            <TextValidator
                                                type="number"
                                                label={t('NumberOfMonteCarloScenarios')}
                                                name="numberOfMonteCarloScenarios"
                                                id="numberOfMonteCarloScenarios"
                                                fullWidth={true}
                                                onChange={this.handleInputChange}
                                                value={this.state.parameters.numberOfMonteCarloScenarios}
                                                validators={['required']}
                                                errorMessages={[this.props.t('FieldRequired')]} />
                                        </FormGroup>

                                        <FormGroup sx={{ paddingX: '1rem', marginBottom: '1rem' }}>
                                            <TextValidator
                                                type="number"
                                                id="numberOfEventSimulations"
                                                name="numberOfEventSimulations"
                                                label={t('NumberOfEventSimulations')}
                                                value={this.state.parameters.numberOfEventSimulations}
                                                fullWidth={true}
                                                onChange={this.handleInputChange}
                                                validators={['required']}
                                                errorMessages={[this.props.t('FieldRequired')]} />
                                        </FormGroup>

                                        <FormGroup sx={{ paddingX: '1rem', marginBottom: '1rem' }}>
                                            <TextValidator
                                                type="number"
                                                id="numberOfSyntheticLoans"
                                                name="numberOfSyntheticLoans"
                                                label={t('NumberOfSyntheticLoans')}
                                                value={this.state.parameters.numberOfSyntheticLoans}
                                                fullWidth={true}
                                                onChange={this.handleInputChange}
                                                validators={['required']}
                                                errorMessages={[this.props.t('ValueRequired')]} />
                                        </FormGroup>

                                        <FormGroup sx={{ paddingX: '1rem', marginBottom: '1rem' }}>
                                            <TextField
                                                type="number"
                                                id="randomNumberSeed"
                                                name="randomNumberSeed"
                                                label={t('RandomNumberSeed')}
                                                value={this.state.parameters.randomNumberSeed}
                                                fullWidth={true}
                                                onChange={this.handleInputChange} />
                                        </FormGroup>

                                        <FormGroup sx={{ paddingX: '1rem', marginBottom: '1rem' }}>
                                            <TextField
                                                type="number"
                                                id="sensitivityIRDelta"
                                                name="sensitivityIRDelta"
                                                label={t('SensitivityIRDelta')}
                                                value={this.state.parameters.sensitivityIRDelta}
                                                fullWidth={true}
                                                onChange={this.handleInputChange}
                                            />
                                        </FormGroup>

                                        <FormGroup sx={{ paddingX: '1rem', marginBottom: '1rem' }}>
                                            <TextValidator
                                                type="number"
                                                id="modelingHorizon"
                                                name="modelingHorizon"
                                                label={t('ModelingHorizon')}
                                                value={this.state.parameters.modelingHorizon}
                                                fullWidth={true}
                                                onChange={this.handleInputChange}
                                                validators={['required']}
                                                errorMessages={[this.props.t('FieldRequired')]} />
                                        </FormGroup>

                                    </div>
                                </Paper>

                                <Paper sx={{padding: '3rem', marginTop:'1rem'}}>

                                    <div className={classes.stickyHeader}>
                                        <h4>{t('BasicRiskFunctions')}</h4>
                                    </div>

                                    <div className={classes.paddingTop}>

                                        <FormGroup sx={{ paddingX: '1rem', marginBottom: '1rem' }}>
                                            <TextValidator
                                                type="number"
                                                id="modelsOfDefaults"
                                                name="modelsOfDefaults"
                                                label={t('ModelsOfDefaults')}
                                                value={this.state.parameters.modelsOfDefaults}
                                                fullWidth={true}
                                                onChange={this.handleInputChange}
                                                validators={['required']}
                                                errorMessages={[this.props.t('FieldRequired')]}
                                                inputProps={{ step: '0.1' }} />
                                        </FormGroup>

                                        <FormGroup sx={{ paddingX: '1rem', marginBottom: '1rem' }}>
                                            <TextValidator
                                                type="number"
                                                id="earlyPaymentModels"
                                                name="earlyPaymentModels"
                                                label={t('EarlyPaymentModels')}
                                                value={this.state.parameters.earlyPaymentModels}
                                                fullWidth={true}
                                                onChange={this.handleInputChange}
                                                validators={['required']}
                                                errorMessages={[this.props.t('FieldRequired')]}
                                                inputProps={{ step: '0.1' }} />
                                        </FormGroup>

                                        <FormGroup sx={{ paddingX: '1rem', marginBottom: '1rem' }}>
                                            <TextValidator
                                                type="number"
                                                id="modelsOfAcceleratedDepreciation"
                                                name="modelsOfAcceleratedDepreciation"
                                                label={t('ModelsOfAcceleratedDepreciation')}
                                                value={this.state.parameters.modelsOfAcceleratedDepreciation}
                                                fullWidth={true}
                                                onChange={this.handleInputChange}
                                                validators={['required']}
                                                errorMessages={[this.props.t('FieldRequired')]}
                                                inputProps={{ step: '0.1' }} />
                                        </FormGroup>
                                    </div>
                                </Paper>

                                <Paper sx={{padding: '3rem', marginTop:'1rem'}}>

                                    <div className={classes.stickyHeader}>
                                        <h4>{t('VolatilityModification')}</h4>
                                    </div>

                                    <div className={classes.paddingTop}>

                                        <FormGroup sx={{ paddingX: '1rem', marginBottom: '1rem' }}>
                                            <TextValidator
                                                type="number"
                                                id="betModels"
                                                name="betModels"
                                                label={t('BetModels')}
                                                value={this.state.parameters.betModels}
                                                fullWidth={true}
                                                onChange={this.handleInputChange}
                                                validators={['required']}
                                                errorMessages={[this.props.t('FieldRequired')]}
                                                inputProps={{ step: '0.1' }} />
                                        </FormGroup>

                                        <FormGroup sx={{ paddingX: '1rem', marginBottom: '1rem' }}>
                                            <TextValidator
                                                type="number"
                                                id="mortgageRatesModels"
                                                name="mortgageRatesModels"
                                                label={t('MortgageRatesModels')}
                                                value={this.state.parameters.mortgageRatesModels}
                                                fullWidth={true}
                                                onChange={this.handleInputChange}
                                                validators={['required']}
                                                errorMessages={[this.props.t('FieldRequired')]}
                                                inputProps={{ step: '0.1' }} />
                                        </FormGroup>

                                        <FormGroup sx={{ paddingX: '1rem', marginBottom: '1rem' }}>
                                            <TextValidator
                                                type="number"
                                                id="realEstateIndexModels"
                                                name="realEstateIndexModels"
                                                label={t('RealEstateIndexModels')}
                                                value={this.state.parameters.realEstateIndexModels}
                                                fullWidth={true}
                                                onChange={this.handleInputChange}
                                                validators={['required']}
                                                errorMessages={[this.props.t('FieldRequired')]}
                                                inputProps={{ step: '0.1' }} />
                                        </FormGroup>
                                    </div>
                                </Paper>

                                {
                                    Globals.user.isEmployee && (
                                        <Paper sx={{padding: '3rem', marginTop:'1rem'}}>

                                            <div className={classes.stickyHeader}>
                                                <h4>{t('ExtraParameters')}</h4>
                                            </div>

                                            <div className={classes.paddingTop}>

                                                <FormGroup sx={{ paddingX: '1rem', marginBottom: '1rem' }}>
                                                    <SelectValidator
                                                        id="calculationPurpose"
                                                        name="calculationPurpose"
                                                        label={t('CalculationPurpose')}
                                                        value={this.state.calculationPurpose}
                                                        fullWidth={true}
                                                        onChange={this.handleTopInputChange}
                                                        validators={['required']}
                                                        errorMessages={[this.props.t('FieldRequired')]}>
                                                        {
                                                            (this.calculationPurposeSource || []).map(option => (
                                                                <MenuItem key={option.key} value={option.key}>
                                                                    {option.value}
                                                                </MenuItem>))
                                                        }
                                                    </SelectValidator>
                                                </FormGroup>

                                                <FormGroup sx={{ paddingX: '1rem', marginBottom: '1rem' }}>
                                                    <TextValidator
                                                        type="text"
                                                        id="taskArgs"
                                                        name="taskArgs"
                                                        label={t('TaskParameters')}
                                                        value={this.state.parameters.taskArgs}
                                                        fullWidth={true}
                                                        onChange={this.handleInputChange} />
                                                </FormGroup>
                                            </div>
                                        </Paper>
                                    )
                                }
                            </div>
                        )}
                </ValidatorForm>
            </Container >
        );
    }
}

var component = connect(
    state => state.calculator, // Selects which state properties are merged into the component's props
    CalculatorStore.actionCreators // Selects which action creators are merged into the component's props
)(CreateCalculationPage);

export default withStyles(styles)(withRouter(withTranslation('translations')(component)));