import * as React from 'react';
import clsx from 'clsx';
import FilterList from '@mui/icons-material/FilterList';
import { Theme } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import ButtonBase from '@mui/material/ButtonBase';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
    /* Styles applied to the root element. */
    root: {
        cursor: 'pointer',
        display: 'inline-flex',
        justifyContent: 'flex-start',
        flexDirection: 'inherit',
        alignItems: 'center',
        color: theme.palette.text.secondary,
        '& $icon': {
            opacity: 0.5,
        },
        '&$active': {
            color: theme.palette.text.primary,
            // && instead of & is a workaround for https://github.com/cssinjs/jss/issues/1045
            '&& $icon': {
                opacity: 1,
                color: theme.palette.text.secondary,
            },
        },
    },
    /* Pseudo-class applied to the root element if `active={true}`. */
    active: {},
    /* Styles applied to the icon component. */
    icon: {
        fontSize: 18,
        marginRight: 4,
        marginLeft: 4,
        opacity: 0,
        transition: theme.transitions.create(['opacity', 'transform'], {
            duration: theme.transitions.duration.shorter,
        }),
        userSelect: 'none',
    }
}));

/**
 * A button based label for placing inside `TableCell` for column sorting.
 */
const TableFilterLabel = React.forwardRef((props: any, ref) => {
    const {
        active = false,
        children,
        className,
        hideFilterIcon = false,
        IconComponent = FilterList,
        iconColor,
        ...other
    } = props;

    const classes = useStyles();

    return (
        <ButtonBase
            className={clsx(classes.root, { [classes.active]: active }, className)}
            component="span"
            disableRipple
            ref={ref}
            {...other}
        >
            {children}
            {hideFilterIcon && !active ? null : (
                <IconComponent
                    className={clsx(classes.icon)}
                    color={iconColor}
                />
            )}
        </ButtonBase>
    );
});

export default TableFilterLabel;