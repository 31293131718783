import InstrumentsService from "../services/InstrumentsService";
import * as TopNavbar from "@Store/TopNavbarStore";

export const Actions = {

    Init: "SIDEBAR_INIT",
    SidebarDetailsRequest: "SIDEBAR_DETAILS_REQUEST",
    ShowSidebar: "SIDEBAR_SHOW"
}


export const actionCreators = {
    init: () => async (dispatch, getState) => {
        dispatch({ type: Actions.Init });
    },

    getActualDetails: (area) => async (dispatch) => {

        var data = await InstrumentsService.getInstrument(area);
        if (data && !data.hasErrors)
            dispatch({ type: Actions.SidebarDetailsRequest, data: data.value });
    },

    showSidebar: (show) => async (dispatch) => {
        dispatch({ type: Actions.ShowSidebar, isVisible: show });
    },

    closeSidebar: () => async (dispatch) => {
        dispatch({ type: Actions.ShowSidebar, isVisible: false });
        dispatch({ type: TopNavbar.Actions.SidebarVisibilityChanged, isVisible: false });
    },
};

const initialState = {
    visible: false,
    id: null,
    name: null,
    //selectedItem: null,
    items: null
};

export const reducer = (currentState, incomingAction) => {

    if (currentState === undefined)
        return initialState; 


    const action = incomingAction;

    switch (action.type) {
        case Actions.Init:
            return initialState;

        case Actions.SidebarDetailsRequest:
            {
                if (action.data == null)
                    return { ...currentState };
                
                if (currentState.id == action.data.id) //the same
                    return { ...currentState };

                var result = {};
                
                if (action.data != null) {
                    
                    result.id = action.data.id;
                    result.name = action.data.title;
                    result.items = action.data.actions;
                    //result.url = action.data.url;
                    //...result
                    return { ...currentState, ...result};
                }
                else {
                    return { ...currentState };
                }
            }
        case Actions.ShowSidebar:
            {
                return {
                    ...currentState, sidebarVisible: action.isVisible
                };
            }

        default:
            return currentState || initialState;
    }
};