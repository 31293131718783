import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
//import IconButton from '@mui/material/IconButton';
//import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { FormGroup } from "@mui/material";
import { ValidatorForm, TextValidator } from "../framework/components/Form";

interface IProps {
    show: boolean,
    title: string,
    description: string,
    onClose: any
}

interface IState {

    show: boolean,
    date: any,
    name: string,
    description: string
}

export default class NameDescriptionDialog extends React.Component<IProps, IState> {

    constructor(props) {
        super(props);

        this.state = {
            show: this.props.show,
            date: new Date().toISOString(),
            name: '',
            description: ''
        };
    }

    submitButton = null;

    componentDidUpdate(prevProps) {

        if (this.props.show !== prevProps.show) {
            this.setState({ show: this.props.show });
        }
    }

    onWindowClose = (event) => {

        if (this.props.onClose)
            this.props.onClose(event);

        this.setState({
            show: false,
        });
    }

    handleInputChange = (event) => {

        const key = event.target.name;

        if (Object.keys(this.state).includes(key)) {
            this.setState({ [key]: event.target.value } as Pick<IState, keyof IState>);
        }
    }

    handleSubmit = (event) => {

        if (this.props.onClose)
            this.props.onClose(event, { createdDate: this.state.date, name: this.state.name, description: this.state.description });

        this.setState({
            show: false,
        });
    };

    render() {
        return (
            <Dialog open={this.state.show} aria-labelledby="form-dialog-title">
                <ValidatorForm
                    onSubmit={this.handleSubmit}>

                    <MuiDialogTitle disableTypography id="form-dialog-title">
                        <h4>
                            {this.props.title}
                        </h4>
                    </MuiDialogTitle>
                    <MuiDialogContent dividers>
                        {
                            (this.props.description) && (
                                <DialogContentText>
                                    {this.props.description}
                                </DialogContentText>
                            )
                        }

                        <FormGroup className="px-3">
                            <TextValidator
                                type="name"
                                label="Название"
                                name="name"
                                id="name"
                                fullWidth
                                onChange={this.handleInputChange}
                                value={this.state.name}
                                validators={['required']}
                                errorMessages={['Требуется название']} />
                        </FormGroup>

                        <FormGroup className="px-3">
                            <TextValidator
                                type="description"
                                label="Описание"
                                name="description"
                                id="description"
                                fullWidth
                                onChange={this.handleInputChange}
                                value={this.state.description} />
                        </FormGroup>

                    </MuiDialogContent>
                    <MuiDialogActions>
                        <Button
                            onClick={this.onWindowClose}
                            variant="contained"
                            color="primary">Отмена</Button>
                        <Button
                            type="submit"
                            variant="contained"
                            color="secondary">OK</Button>
                    </MuiDialogActions>

                </ValidatorForm>
            </Dialog>
        );
    }
}