import { createStyles, makeStyles } from '@mui/styles'
import { IconButton, Tooltip, Theme } from '@mui/material';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import Search from '@mui/icons-material/Search';
import Close from '@mui/icons-material/Close';

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.action.active,
    },
}));

const SearchPanelInput = ({
    onValueChange, value, placeHolder, tooltip, ...restProps
}) => {

    const classes = useStyles();

    return (
        <Input
            //fullWidth={true}
            type='text'
            placeholder={placeHolder}
            value={value}
            onChange={e => onValueChange(e.target.value)}
            {...restProps}
            startAdornment={(
                <InputAdornment position='start'>
                    <Tooltip
                        disableFocusListener
                        title={tooltip}>
                        <Search />
                    </Tooltip>
                </InputAdornment>
            )}
            endAdornment={
                value !== '' && (
                    <InputAdornment position='end'>
                        <IconButton onClick={e => onValueChange('')} size="large">
                            <Close />
                        </IconButton>
                    </InputAdornment>
                )
            }
        />
    )
}

export default SearchPanelInput;
