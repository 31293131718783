import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react'
import style from '@Styles/domrf-theme.module.scss'
import { Autocomplete, Box, Paper, TextField, Theme, alpha, IconButton, Tooltip, Grid, Button, Dialog, Modal, debounce } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import UniversalInputField, { InputDataType } from '@Base/framework/components/Form/UniversalInputField'
import { useDispatch } from 'react-redux'
import { actionCreators as actionConvention } from '@Base/store/ConventionStore'
import { useSelector } from 'react-redux'
import { t } from 'i18next'
import { BondStatus, CalculationTypeV2, CouponType } from '@Base/components/Definitions'
import Localization from '@Base/components/Localization'
import { NumericValidator } from '@Base/framework/components/Form'
import { GitHub, InfoOutlined, OfflineShare, Replay, Update } from '@mui/icons-material'
import { ReactComponent as ApiIcon } from "../../../../../src/images/api-icon.svg"
import CustomCircularProgress from '@Base/components/shared/CustomCircularProgress'
import { useSearchParams } from 'react-router-dom'
import SpinnerBars from '@Base/components/shared/SpinnerBars'
import { ReactComponent as ExcelIcon } from "../../../../../src/images/excel-icon.svg"
import { b64EncodeUnicode, copyTextToClipboard, roundDown } from '@Utilities';
import { withCustomizationInput } from '../hocs/withCustomizationInput'
import { actionCreators as actionAlert } from '@Base/store/AlertStore'
import CustomTooltip, { getTooltip } from '@Base/components/shared/CustomTooltip'
import useMobileDetect from '@Base/components/hook/useMobileDetect'
import { actionCreators as actionDownloads } from '@Base/store/DownloadStore'
import Convention2InfoWin from '../components/Convention2InfoWin'
import ConfirmDialog2 from '@Base/framework/components/ConfirmDialog2'

const useStyles = makeStyles((theme: Theme) => createStyles({
    nextCouponString: {
        whiteSpace: 'nowrap',
    },
    greyText: {
        color: style.colorPrimary3_60,
    },
    wideTooltip: {
        maxWidth: '400px !important'
    },
    customTooltip: {
        userSelect: 'auto'
    },
    autoselectPopper: {
        marginRight: '-1px',

    },
    option: {
        '& *[data-focus="true"]': {
            border: 'solid 1px #000 !important',
            backgroundColor: "#363636",
        },
    },
    test: {
        border: 'solid 10px #000',
    },
    tooltip: {
        maxWidth: '600px',
        marginTop: '0.5rem !important',
    },
    paramsRoot: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        // overflow: 'hidden',
        marginTop: '0.5rem',
        marginBottom: '0.5rem',
        // marginLeft: '6px',
        padding: '0 11px 0 Calc(11px + 5px)',
    },
    paramsRoot1: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        overflow: 'hidden',
        // marginTop: '1rem',
        // marginLeft: '6px',
    },
    block: {
        marginBottom: '0.5rem',
    },
    blockTitle: {
        color: style.colorPrimary3_60,
        borderBottom: `solid 1px ${style.colorPrimary3_60}`,
        textTransform: 'uppercase',
    },
    resultRow: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        minHeight: '2rem',
        overflow: 'hidden',
        '&:nth-of-type(even)': {
            backgroundColor: '#f7f7f7',//alpha(style.colorPrimary3, 0.05)
        },
    },
    resultRowName: {
        whiteSpace: 'nowrap',
        paddingLeft: '0.5rem',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        // alignSelf: 'baseline',
    },
    resultRowSubname: {
        fontSize: '0.8rem !important',
        color: style.colorPrimary3_60,
        marginTop: '-0.2rem',
        lineHeight: '1.2rem',
        // marginBottom: '0.3rem',
    },
    resultRowDataBlock: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'center', //'flex-end',
        alignItems: 'baseline',
    },
    resultRowData: {
        // flexGrow: 1,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'flex-end',
        alignItems: 'baseline',
        // '&:not(:first-child):before': {

        //     position: 'absolute',
        //     left: 0, top: '-2rem', bottom: '-2rem', width: '1px',
        //     content: '""',
        //     // backgroundColor: `${style.colorPrimary3_60}`,
        //     borderLeft: `solid 1px ${style.colorPrimary3_60}`,
        //     paddingLeft: '1rem',
        // },
    },
    resultRowValue: {
        minWidth: '4rem',
        textAlign: 'right',
        position: 'relative',
    },
    exResultRowValue: {
        textAlign: 'right',
        display: 'flex',
        flexDirection: 'column',

    },
    resultRowSubvalue: {
        fontSize: '0.8rem !important',
        color: style.colorPrimary3_60,
        marginTop: '-0.2rem',
        lineHeight: '1.2rem',
        // marginBottom: '0.3rem',
    },
    resultRowUnit: {
        width: '3.3rem',
        color: style.colorPrimary3_60,
        whiteSpace: 'nowrap',
        paddingLeft: '0.3rem',
        fontSize: '0.9rem !important',
        alignSelf: 'flex-start',//'baseline',
        // height: '1rem',
        lineHeight: '1rem',
        margin: '3px 0 2px 0'
    },
    resultRowUnitFit: {
        width: 'fit-content !important',
        paddingRight: '10px',
        position: 'relative',
        '&:after': {
            position: 'absolute',
            right: 0, top: '-2rem', bottom: '-2rem', width: '1px',
            content: '""',
            // backgroundColor: `${style.colorPrimary3_60}`,
            borderLeft: `solid 1px ${style.colorPrimary3_60}`,
            // paddingLeft: '1rem',
        },
    },
    optionParamItem: {
        margin: 0,
        // backgroundColor: '#00000009',
        padding: '0.2rem 0.5rem',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        justifyContent: 'start',
        alignItems: 'center',
    },
    optionParamItemInput: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignItems: 'baseline',
        height: '30px',
        '-webkit-align-items': 'baseline',
        '& input': { fontSize: '1.3rem', padding: 0, textAlign: 'right', marginRight: '5px' },
        // '&:not(&optionParamItemUnit)': {
        //     // flexGrow: 1,
        //     // width: '4.4rem',
        // },
    },
    optionParamItemUnit: {
        // maxWidth: '2rem !important',
        alignSelf: 'baseline',
        color: style.colorPrimary3_40,
        fontSize: '0.9rem !important',
        whiteSpace: 'nowrap',
        textWrap: 'nowrap',
        userSelect: 'none',
        // lineHeight: '1.3rem',
        height: '30px',
    },
    optionParams: {
        margin: 0,
        marginTop: '0.5rem',
        // marginRight: '4px',
        borderRadius: '0.5rem',
        border: `solid 1px ${style.colorPrimary3_40}`,
        '&>*:not(:first-child)': {
            borderLeft: `solid 1px ${style.colorPrimary3_40}`,
            margin: 0,
        },
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'center',
    },
    optionExParamsBox: {
        position: 'relative',
    },
    optionExParams: {
        position: 'relative',
        marginTop: '0.5rem',
        marginRight: '4px',
        borderRadius: '0.5rem',
        border: `solid 1px ${style.colorPrimary3_40}`,
        '&>*': {
            minWidth: '8rem',
        },
        '&>*:not(:first-child)': {
            borderLeft: `solid 1px ${style.colorPrimary3_40}`
        },
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'center',
        width: 'min-content',
        alignSelf: 'center',
    },
    optionParamItemName: {
        color: `${style.colorPrimary3_60} !important`,
        // fontSize: '0.9rem !important',
        whiteSpace: 'nowrap',
        userSelect: 'none'
    },
    labelStyle: {
        color: `${style.colorPrimary3_40} !important`,
    },
    mobileRoot: {
        // marginRight: '0.5rem',
        position: 'relative',
        marginLeft: '0.2rem',
        marginRight: '0',
        // position: 'absolute',
        // left: 0, top: 0, bottom: '2px', right: 0,
        '& *': {
            fontSize: '1rem'
        }
    },
    root: {
        // marginRight: '0.5rem',
        // position: 'relative',
        position: 'absolute',
        // minWidth: '390px',
        maxHeight: '828px',
        left: '0.2rem', top: 0, bottom: '2px', right: '0',
        '& *': {
            fontSize: '1rem'
        }
    },
    rootPaper: {
        maxHeight: '100%',
        position: 'relative',
        // overflow: 'hidden',
        // position: 'absolute',
        // left: 0, top: 0, bottom: 0, right: '0.5rem',
        borderRadius: '0.5rem',
        border: `solid 1px ${style.colorPrimary3_40}`,
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        justifyContent: 'flex-start',
    },
    panelHeader: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignContent: 'center',
        alignItems: 'center',
        height: '2rem !important',
        minHeight: '2rem !important',
        overflow: 'hidden',
        borderBottom: `solid 1px ${style.colorPrimary3_40}`,
        padding: '0 0.2rem 0 .5rem',
        justifyContent: 'space-between',
        // lineHeight: '2rem',
        '& *': { userSelect: 'none', },
    },
    panelHeaderTitle: {
        textTransform: 'uppercase',
        color: style.colorPrimary3_40,
        whiteSpace: 'nowrap',
        alignContent: 'center',
        alignItems: 'center',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    panelHeaderIcons: {
        marginRight: '0.1rem',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
    },
    panelHeaderIcon: {
        width: '23px',
        height: '23px',
    },
    panelIconsBox: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignContent: 'center',
        alignItems: 'center',
    },
    icon: {
        width: '23px',
        height: '23px',
        // color: style.colorPrimary3_80
    },
    unitButton: {
        // padding: 0,
        // marginBottom: '2px',
        margin: '1px 0 2px 0',
        padding: 0
    },
    unitIcon: {
        padding: 0,
        width: '18px',
        height: '18px',
        // color: 'rgb(0, 0, 0, 0.54)'
    },
    smallIconButton: {
        position: 'absolute',
        right: 0,
        top: 0,
        width: '15px',
        height: '15px',
    },
    smallIcon: {
        width: '15px',
        height: '15px',
        color: style.colorPrimary3_80
    },
    panelData: {
        // margin: '0 1rem 0.5rem 1rem',
        margin: '0 0 0 0',
        paddingTop: '0.5rem',
        overflow: 'hidden',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
    },
    row: {
        margin: '0 1rem',
        paddingBottom: '0.5rem',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        // minHeight: '2rem',
    },
    extandesRow: {
        marginLeft: '1rem',
    },
    rowName: {
        flexGrow: 1,
    },
    fw: {
        width: '100% !important',
    },
    rowData: {
        width: '200px',
        textAlign: 'right',
    },
    alertBox: {
        display: 'flex',
        placeContent: 'center',
        color: 'red',
        margin: '0.5rem 1rem',
    },
    optionBox: {
        display: 'flex !important',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'space-between  !important',
        alignContent: 'center  !important',
        alignItems: 'center  !important',
        minWidth: '250px !important',
        padding: '5px 0',
        cursor: 'pointer !important',
        // '&:hover': {
        //     backgroundColor: alpha(style.colorPrimary3, 0.1)
        // }
    },
    optionMobile: {
        '& $optionLabel': {
            fontSize: '0.9rem !important',
            // width: 'fit-content',
        },
        '& $optionISIN': {
            fontSize: '0.9rem !important',
            // width: 'fit-content',
        },
    },
    optionName: {
        whiteSpace: 'nowrap',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'flex-start',
        margin: '0 !important',
        padding: '0 !important',
        // justifyContent: 'space-between',
        // width: '220px',
    },
    optionLabel: {
        textAlign: 'left',
        marginRight: '0.5rem',
        // width: '100px',
        width: 'fit-content',
        // border: 'solid 1px #000'
    },
    optionISIN: {
        // width: '120px',
        width: 'fit-content',
        color: style.colorPrimary3_40,
        textAlign: 'left',
        // border: 'solid 1px #000'
    },
    optionStatusBox: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        alignItems: 'flex-end',
        marginLeft: '0.5rem'
    },
    optionStatus: {
        fontSize: '11px',
        whiteSpace: 'nowrap',
    },
    optionCouponType: {
        fontSize: '12px',
        whiteSpace: 'nowrap',
        color: 'rgb(0,176,80)', //style.colorPrimary2_80,
        fontSmooth: 'never'
    },
    divider: {
        position: 'relative',
        '&:before': {
            content: '""',
            position: 'absolute',
            left: '-2rem', right: '-2rem', top: 0, height: '1px',
            borderTop: `solid 1px ${alpha(style.colorPrimary3_40, 1)}`,
        },
    },
    virtualScrollablePanel: {
        padding: '0.5rem 11px 0 Calc(11px + 5px)',
        flexGrow: 1,
        overflow: 'auto',
        // marginTop: '0.5rem',
        '& *': { userSelect: 'none', },
        '& .Mui-selected': {
            // backgroundColor: `#e4f1d5 !important`,
            backgroundColor: `#e4f1d500 !important`,
        },
        // position: 'absolute', left: '4px', right: 0, top: 0, bottom: 0,
        // overflowY: 'auto',
        // padding: 0,
        '&::-webkit-scrollbar': {
            width: '4px',
            height: '6px',
        },
        // '&::-webkit-scrollbar:disabled':{
        //     opacity:0,
        // },
        '&:hover': {
            '&::-webkit-scrollbar-track:disabled': {
                backgroundColor: '#0000',
            },
            '&::-webkit-scrollbar-thumb:disabled': {
                background: '#0000',
            },
            // '&::-webkit-scrollbar-track': {
            //     backgroundColor: '#00000008',
            // },
            '&::-webkit-scrollbar-thumb': {
                background: '#00000018',
            }
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'unset',
            borderRadius: '3px',
            // border: 'solid 1px #00000000',
            backgroundColor: '#00000000',
            transition: 'all 0.3s ease'
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#0000',
            borderRadius: '3px',
            height: '4px',
        },
    },
    scrollableList: {
        padding: '0 !important',
    },
}))


const InputPanel = () => {
    const cls = useStyles()
    const dispatch = useDispatch()
    const {
        selectedBond = null,
        items: storeBondList = null, bondListLoading = false,
        pricingParameters = null, loading = false, pricingResult = null, error: apiError = null,
        calculatedParameters = null,
        bondParameters = null,
        zcycDate = null,
        ...otherStore } = useSelector((state: any) => state.convention)

    // console.log('otherStore', otherStore)
    const [inputPricingParameters, setInputPricingParameters] = useState(null)
    const [selectedBondType, setSelectedBondType] = useState(null)
    const [bondList, setBondList] = useState([])
    // const [selectedBond, setSelectedBond] = useState(null)
    const [calcType, setCalcType] = useState(null)
    const [calcValue, setCalcValue] = useState(null)
    const [calcTypeItems, setCalcTypeItems] = useState(null)
    let [searchParams, setSearchParams] = useSearchParams()
    const [URLWasChangedManualy, setURLWasChangedManualy] = useState(false)
    const [showConvention2InfoWin, setShowConvention2InfoWin] = useState(false)
    const initAvailableCalcOptions = {
        zSpread: { active: true, unit: 'Convention2_bp', tooltip: "Convention2Tooltip_zSpreadParam", width: 4.7, decimalScale: 0, limits: { min: -300, max: 500 } },
        gSpread: { active: true, unit: 'Convention2_bp', tooltip: "Convention2Tooltip_gSpreadParam", width: 4.7, decimalScale: 0, limits: { min: -300, max: 500 } },
        requiredKeyRatePremium: { active: false, unit: 'Convention2_bp', tooltip: "Convention2Tooltip_requiredKeyRatePremiumParam", width: 4.7, decimalScale: 0, limits: { min: -300, max: 500 } },
        cleanPrice: { active: true/*false*/, unit: 'Convention2_percent', tooltip: "Convention2Tooltip_cleanPriceParam", width: 5.1, decimalScale: 2, limits: { min: 0, max: 150 }, allowNegative: false, },
        dirtyPrice: { active: true, unit: 'Convention2_percent', tooltip: "Convention2Tooltip_dirtyPriceParam", width: 5.1, decimalScale: 2, limits: { min: 0, max: 150 }, allowNegative: false, },
        couponRate: { active: false, disabled: false, unit: 'Convention2_percent', tooltip: "Convention2Tooltip_couponRateParam", width: 5.1, decimalScale: 2, limits: { min: 0, max: 20 }, allowNegative: false, },
    }

    const [viewHeight, setViewHeight] = useState(0)
    useLayoutEffect(() => {
        setViewHeight(window?.innerHeight)
        if (window) {
            window.addEventListener('resize', debounce(onResize, 300))
            onResize();
        }

        return () => {
            window.removeEventListener('resize', debounce(onResize, 300))
        }
    }, [])

    const onResize = () => {
        setViewHeight(window?.innerHeight)
    }
    // console.log('PricingParameters', PricingParameters)
    // console.log('PricingResult', PricingResult)
    useLayoutEffect(() => {
        if (pricingParameters == null) {
            setInputPricingParameters(null)

            return
        }
        // console.log('pricingParameters****', pricingParameters)
        const feltredParams = {}
        Object.keys(pricingParameters).forEach(key => {
            switch (key) {
                case 'isin':
                case 'dirtyPrice':
                case 'cleanPrice':
                case 'zSpread':
                case 'gSpread':
                case 'requiredKeyRatePremium':
                case 'couponRate':
                case 'cpr':
                case 'cdr':
                    feltredParams[key] = pricingParameters[key]
                    break
                // case 'zcycDateTime':
                //     const param = {}
                //     searchParams?.forEach((val, key) => { param[key] = val })
                //     if (searchParams.get('zcycDateTime') != null && new Date(pricingParameters?.zcycDateTime) != new Date(searchParams.get('zcycDateTime'))) {
                //         console.log('searchParams.get(zcycDateTime)', searchParams.get('zcycDateTime'), param)
                //         // console.log('params', param)
                //         setSearchParams({...param, zcycDateTime: pricingParameters?.zcycDateTime })
                //     }
                //     break
                default:
                    break
            }
            // console.log('first', key)
        })

        setInputPricingParameters(feltredParams) //установка объекта инпутов со значениями
    }, [pricingParameters])

    const [availableCalcOptions, setAvailableCalcOptions] = useState(initAvailableCalcOptions)

    const CustomNumericValidator = useMemo(() => withCustomizationInput(NumericValidator,
        {
            needConfirm: true,
            helper: Localization.Translate('PressEnterToLaunchCalculation'),
            helperShowDelay: 3000,
            //autoConfirmTimeout: 1000,
            // helper: t('PressEnterToSubmit'),
            // helperShowDelay: 1000,
            changesHighlight: alpha(style.colorPrimary2, 0.2),
            disableWheel: true,
            selectOnFocus: true,
            disableCloseOnSelect: false,
            hideValidationIcon: true,
            placeholder: ' ',
            // autoComplete: "off",
            // inputProps: {
            //     autoComplete: "off",
            //     //     // disableUnderline: true,
            // },
            classes: { FieldRoot: cls.optionParamItem, FieldInputRoot: cls.optionParamItemInput, FieldUnit: cls.optionParamItemUnit, FieldTitle: cls.optionParamItemName }
        })
        , [calcValue, calcType])

    useEffect(() => {

        // console.log('calcValue', calcValue)
        // console.log('calcType?.key', calcType)
        // console.log('selectedBond?.isin', selectedBond?.isin)
        if (URLWasChangedManualy) {
            // actionConvention.clearValue('pricingParameters')(dispatch)

            setSearchParams({})
        }

    }, [calcType?.key])

    useLayoutEffect(() => {
        // console.log('selectedBond?.isin', selectedBond?.isin)
        if (`${selectedBond?.isin}`.toUpperCase() == 'RU000A107SY1' ||
            `${selectedBond?.isin}`.toUpperCase() == 'RU000A106YR5' ||
            `${selectedBond?.isin}`.toUpperCase() == 'RU000A108GC0')
            setShowConvention2InfoWin(true)
    }, [selectedBond?.isin])

    useEffect(() => {

        if (selectedBond?.isin == null || calcType?.key == null || calcValue == null)
            return

        actionConvention.getDataForCalculation(selectedBond.isin)(dispatch)
        const vars = { 'isin': selectedBond.isin, [calcType.key]: calcValue }

        if (URLWasChangedManualy) {

            const query = {}
            searchParams?.forEach((val, key) => {
                switch (key) {
                    case 'cleanPrice':
                    case 'dirtyPrice':
                    case 'zSpread':
                    case 'gSpread':
                    case 'requiredKeyRatePremium':
                    case 'couponRate':
                        break

                    default:
                        query[key] = val
                }

            })
            // actionConvention.clearValue('pricingResult')(dispatch)
            setSearchParams(parseParams({ ...query, ...vars }))

        }


    }, [calcValue, calcType?.key, selectedBond?.isin])

    const parseBool = (params) => {
        // console.log('params-------------------------------', params)
        return !(
            params === false ||
            params === "false" ||
            params === "0" ||
            params === "" ||
            params === undefined
        )
    }

    const searchParamRemove = (name) => {
        searchParams.delete(name)
        setSearchParams(searchParams)
    }

    const parseParams = (query) => {
        // console.log('query', query)
        let newQuery = {}
        Object.keys(query).forEach((key) => { if (query[key] !== null && query[key] !== '') newQuery[key] = query[key] })
        // console.log('newQuery', newQuery)
        return newQuery
    }


    const parseUrl = () => {
        try {
            let query = {}

            searchParams?.forEach((val, key) => {
                if (key == 'usePricingDateDataOnly')
                    query[key] = parseBool(val)
                else
                    query[key] = val
            })

            // if (query?.usePricingDateDataOnly != null) query.usePricingDateDataOnly = parseBool(query.usePricingDateDataOnly)

            let bondParam = null
            let calcTypeKey = null
            let calcValParam = null
            searchParams?.forEach((val, key) => {
                switch (key) {
                    case 'isin':
                        bondList.map(bond => {
                            if (val != null && bond?.isin == val)
                                bondParam = bond
                        })
                        break
                    case 'cleanPrice':
                    case 'dirtyPrice':
                    case 'zSpread':
                    case 'gSpread':
                    case 'requiredKeyRatePremium':
                    case 'couponRate':
                        calcTypeKey = key
                        calcValParam = val
                        break
                }
            })

            if (bondParam != null)
                handleSelectBond(bondParam, calcTypeKey, calcValParam)

            if (Object.keys(query).length > 0 && pricingParameters != null)
                setInputPricingParameters(query)

        } catch (error) {
            console.error(error.message)
        }
    }

    useLayoutEffect(() => {

        actionConvention.clearValue('pricingResult')(dispatch)
        actionConvention.clearValue('calculatedParameters')(dispatch)
        actionConvention.clearValue('pricingParameters')(dispatch)
        actionConvention.clearValue('mbsCashflowGraph')(dispatch)
        actionConvention.clearValue('sCurveGraph')(dispatch)
        actionConvention.clearValue('Error')(dispatch)
        actionConvention.clearValue('moexSecurities')(dispatch)
        setURLWasChangedManualy(false)

        if (bondType?.length)
            setSelectedBondType(bondType[0])

        actionConvention.findBondsQuery()(dispatch)
    }, [])

    useLayoutEffect(() => {
        // console.log('URLWasChangedManualy', URLWasChangedManualy)
        actionConvention.clearValue('pricingResult')(dispatch)

        if (URLWasChangedManualy) {
            setSearchParams({})
            // console.log('selectedBond', selectedBond)
            // console.log('isBondPlacementAndFixed(selectedBond)', isBondPlacementAndFixed(selectedBond))
            // console.log('calcType', calcType)
            if (isBondPlacementAndFixed(selectedBond) && /*calcType?.key == 'couponRate' &&*/ calcValue == null) {
                if (selectedBond?.usePlacementCoupon == true && selectedBond?.couponPercentage != null) {
                    setCalcType({ id: 0, key: 'couponRate', name: getParamTitle('couponRate') })
                    setCalcValue(selectedBond?.couponPercentage)
                    return
                }
            } else
                setCalcValue(null)

            setCalcType(null)
        }
        
        actionConvention.clearValue('error')(dispatch)
        actionConvention.clearValue('pricingParameters')(dispatch)
        actionConvention.clearValue('calculatedParameters')(dispatch)
        actionConvention.setData({ showHistoryFlows: false })(dispatch)
        actionConvention.clearValue('mbsCashflowTable')(dispatch)
        actionConvention.clearValue('mbsCashflowGraph')(dispatch)
        actionConvention.clearValue('zcycGraph')(dispatch)
        actionConvention.clearValue('sCurveGraph')(dispatch)
        actionConvention.clearValue('Error')(dispatch)
        actionConvention.clearValue('moexSecurities')(dispatch)
    }, [selectedBond?.isin])

    useEffect(() => {
        // console.log('searchParams', searchParams)

        if (searchParams?.get('isin')) {
            const query = {}
            const availableKeys = [...Object.keys({ ...initAvailableCalcOptions })]

            if (availableKeys.some((el) => searchParams.forEach((val, key) => {
                return el == key
            }))) return

            searchParams.forEach((val, key) => {
                // if (!(availableKeys.includes(key?.toString()))) {
                //     return
                // }
                if (key == 'zcycParameters')
                    return

                query[key] = (key == 'usePricingDateDataOnly' ? parseBool(val) : val)
            })

            if (query?.isin != null && searchParams?.size == 1)
                return

            // console.log('query', query)
            actionConvention.runCalculation(query)(dispatch)
        }
    }, [searchParams])



    useEffect(() => {
        try {
            if (storeBondList != null && selectedBondType?.field != null) {
                setBondList(storeBondList
                    ?.filter(bond => bond.bondStatus != BondStatus.STATUS_NOT_ISSUED &&
                        !(bond.bondStatus == BondStatus.STATUS_PLACEMENT && (
                            // bond.couponType == CouponType.TYPE_KEYRATE_AND_PREMIUM ||
                            bond.couponType == CouponType.TYPE_FLOAT
                        )))
                    ?.map(bond => {
                        const value = {
                            label: bond[selectedBondType?.field],
                            ...bond
                        }
                        // if (selectedBond?.id == bond?.id)
                        //     setSelectedBond(value)

                        return value
                    }))
            }
        } catch (error) {
            console.error(error.message)
        }
    }, [storeBondList, selectedBondType])

    useEffect(() => {
        if (!bondList?.length)
            return

        if (!URLWasChangedManualy) {
            parseUrl()
        }

    }, [bondList])

    const bondType = [
        { id: 3, name: t('TickerMBS'), source: '', field: 'ticker', },
        { id: 1, name: t('ISINMBS'), source: '', field: 'isin', },
        { id: 2, name: t('RegistrationNumberMBS'), source: '', field: 'regNumber', },
        // {key: 4, value: t('BloombergTicker')},
        { id: 5, name: t('NameMBS'), source: '', field: 'name', },
    ]

    const getBondStatus = (bond) => {
        // console.log('bond', bond.bondStatus)
        let title = ''
        let date = bond?.issueDate
        switch (bond.bondStatus) {
            case BondStatus.STATUS_NOT_ISSUED:
                title = "Convention2_BondNotIssued"
                break
            case BondStatus.STATUS_PLACEMENT:
                title = "Convention2_BondPlacement"
                break
            case BondStatus.STATUS_ISSUED:
                title = "Convention2_BondIssued"
                break
            case BondStatus.STATUS_REDEEMED:
                title = "Convention2_BondRedeemed"
                date = bond?.actualRedemptionDate
                break
        }

        return `${t(title)} ${Localization.FormatDateStringLocale(date)}`
    }

    const getBondCoupon = (couponType) => {
        switch (couponType) {
            case CouponType.TYPE_FIXED:
                return t("Convention2_CouponTypeFixed")

            case CouponType.TYPE_FLOAT:
                return t("Convention2_CouponTypeFloating")

            case CouponType.TYPE_KEYRATE_AND_PREMIUM:
                return t("Convention2_CouponTypeStrKeyrateAndPremium")
        }
        return ''
    }

    const handleSelectBond = (bond, calcTypeKey = null, calcValParam = null) => {

        let newAvailableCalcOptions = { ...initAvailableCalcOptions }
        switch (bond?.couponType) {
            case CouponType.TYPE_KEYRATE_AND_PREMIUM:
                newAvailableCalcOptions.zSpread.active = false
                newAvailableCalcOptions.gSpread.active = false
                newAvailableCalcOptions.dirtyPrice.active = false
                newAvailableCalcOptions.couponRate.active = false
                newAvailableCalcOptions.requiredKeyRatePremium.active = true
                newAvailableCalcOptions.cleanPrice.active = true
                newAvailableCalcOptions.cleanPrice.tooltip = "Convention2Tooltip_cleanPriceParamKaP"
                if (bond.bondStatus == BondStatus.STATUS_PLACEMENT) {

                }
                break
            case CouponType.TYPE_FIXED:
                newAvailableCalcOptions.cleanPrice.active = true
                newAvailableCalcOptions.cleanPrice.tooltip = "Convention2Tooltip_cleanPriceParam"
                newAvailableCalcOptions.dirtyPrice.active = false
                if (bond.bondStatus == BondStatus.STATUS_PLACEMENT) {
                    newAvailableCalcOptions.zSpread.active = false
                    newAvailableCalcOptions.gSpread.active = false
                    newAvailableCalcOptions.cleanPrice.active = false
                    newAvailableCalcOptions.requiredKeyRatePremium.active = false
                    newAvailableCalcOptions.couponRate.active = true
                    // console.log('bond!!!!!!!!!!!!!!!!!!!!!!', bond)
                    if (bond?.lockPlacementCoupon == true) newAvailableCalcOptions.couponRate.disabled = true
                }
                break
            case CouponType.TYPE_FLOAT:
                newAvailableCalcOptions.couponRate.active = false
                newAvailableCalcOptions.cleanPrice.active = true//false
                newAvailableCalcOptions.dirtyPrice.active = false//true
                newAvailableCalcOptions.zSpread.active = true
                newAvailableCalcOptions.gSpread.active = true
                if (bond.bondStatus == BondStatus.STATUS_PLACEMENT) {
                }
                break


        }
        setAvailableCalcOptions(newAvailableCalcOptions)

        const calcTypeArr = Object.keys(newAvailableCalcOptions)
            // ?.filter(key => newAvailableCalcOptions[key].active != false)
            ?.map((key, index) => { return { id: index, key: key, name: getParamTitle(key) } })

        setCalcTypeItems(calcTypeArr)

        let calcKeyInitVal = null
        if (calcTypeKey != null) {
            calcTypeArr?.forEach(type => {
                if (type.key == calcTypeKey) {
                    calcKeyInitVal = type
                    // setCalcType(type);
                }
            })
        }
        else {
            calcKeyInitVal = calcTypeArr[0]
            // setCalcType(calcTypeArr[0])
            // console.log('calcTypeKey !!!!', calcTypeKey)
        }

        setCalcType(calcKeyInitVal)
        // if (newAvailableCalcOptions[calcTypeKey ?? calcType?.key] == false)
        //     setCalcType(null)

        if (calcValParam == null) {
            // console.log('calcTypeKey!!!!!!!!!!!!!!', calcKeyInitVal)
            setCalcValue(null)
        }
        else
            setCalcValue(calcValParam)

        // console.log('bond', bond)
        // setSelectedBond(bond)
        actionConvention.setData({ selectedBond: bond })(dispatch)
    }



    const getParamTitle = (param) => {
        switch (param) {
            case 'zSpread':
                return t("CalcByZSpread")

            case 'gSpread':
                return t("CalcByGSpread")

            case 'cleanPrice':
            case 'dirtyPrice':
                return t("CalcByPrice")

            case 'requiredKeyRatePremium':
                return t("CalcByKeyrateAndPremium")

            case 'couponRate':
                return t("CalcByCouponRate")
        }
    }

    // const handleChangeCalcType = (item) => {
    //     if (item?.key != calcType?.key) {
    //         actionConvention.clearValue('PricingResult')(dispatch)
    //         actionConvention.clearValue('PricingParameters')(dispatch)

    //         setCalcValue(null)
    //         setCalcType(item)
    //     }
    // }

    const getDate = (row) => {
        try {
            if (row?.source == null)
                return

            return Localization.FormatDateStringLocale(row?.source[row?.field])

        } catch (error) {
            console.error(error.message)
        }
    }

    // const getTime = (row) => {
    //     try {
    //         if (inputPricingParameters == null)
    //             return

    //         return Localization.FormatDateTimeStringISO(inputPricingParameters[row?.field])


    //     } catch (error) {
    //         console.error(error.message)
    //     }
    // }
    const getCouponType = (row) => {
        try {
            if (row?.source == null)
                return

            switch (row?.source[row?.field]) {
                case 1:
                    return t('Convention2_fix')
                case 2:
                    return t('Convention2_float')
                case 3:
                    return t('Convention2_kp')
            }

        } catch (error) {
            console.error(error.message)
        }
    }

    const getCouponPeriodValue = (row) => {
        try {
            if (row?.source == null || row?.source[row?.field] == 0)
                return

            return 12 / row?.source[row?.field]

        } catch (error) {
            console.error(error.message)
        }
    }


    const getNextCoupon = (row) => {
        const StyledString = () => {
            return <span className={cls.nextCouponString}>
                <span className={cls.greyText}>
                    {`${Localization.FormatNumberString(calculatedParameters?.nextCouponKeyRate, 2, 2)} + `}
                </span>
                {Localization.FormatNumberString(bondParameters?.fixedKeyRatePremium, 2, 2)}
            </span>
        }

        try {
            return StyledString()
        } catch (error) {
            console.error(error.message)
        }
    }
    // console.log('selectedBond', selectedBond)

    const valueGetter = (row) => {
        // console.log(row.name, ' =', row?.source[row?.field]) 
        // console.log(inputPricingParameters, inputPricingParameters)
        try {
            if (row?.source == null) //inputPricingParameters
                return

            let val = null

            // if (row?.field == 'zcycDateTime')
            // console.log(row?.field, 'урл =', searchParams.get(row?.field), 'Паша =', row.source[row?.field], 'loading = ', loading)

            val = searchParams.get(row?.field)
            val = (val !== null && loading) ? val :
                row.source[row?.field] != null ? row.source[row?.field] : null
            // console.log('val!!!!!!!', val)
            switch (row?.field) {
                case 'usePricingDateDataOnly':
                    // console.log('val!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!', parseBool(val))
                    // return val
                    return parseBool(val)
                    break

                case 'zcycDateTime':
                    // console.log('val, new DATE', val, new Date(val))
                    return new Date(val)
                    break

                default:
                    if (row.dataType == InputDataType.FLOAT && row.fractionLength != null && val != null)
                        val = Localization.FormatNumberString(val, row.fractionLength)

                    // console.log(row.name, val)
                    return val
            }

            // return val != null ? val : row.source[row?.field] //inputPricingParameters

        } catch (error) {
            console.error(error.message)
        }
    }

    useEffect(() => {
        // console.log('zcycDate', zcycDate)
        const zcycDateTimeParam = searchParams.get('zcycDateTime')
        if (zcycDate != null && new Date(zcycDateTimeParam).getTime() != new Date(zcycDate?.resultDate).getTime()) {
            // console.log('zcycDateTimeParam', zcycDateTimeParam)
            // console.log('zcycDate', zcycDate)
            // console.log('pricingParameters', pricingParameters)

            const query = {}
            searchParams?.forEach((val, key) => { query[key] = val })
            query['zcycDateTime'] = zcycDate?.resultDate

            setInputPricingParameters(query)
            setURLWasChangedManualy(true)
            setSearchParams(parseParams(query))
            // console.log('inputPricingParameters', inputPricingParameters)
        }
    }, [zcycDate])

    const handleValueChange = (name, value, type = null) => {
        // console.log('value', value)
        if (value === null || value === '' || (value === '-' && (type == InputDataType.FLOAT || type == InputDataType.INT))) {
            return false
        }
        if (name == 'zcycDateTime') {
            let valT
            switch (type) {
                case InputDataType.DATE_T:
                case InputDataType.DATE_TIME:
                    if (name == 'zcycDateTime') value?.setHours(23, 59, 59)
                    valT = value !== '' ? Localization.FormatDateTimeISO(value).replace(' ', 'T') : null
                    actionConvention.getZCYCDateTime(valT)(dispatch)
                    break
                case InputDataType.TIME:
                    valT = value !== '' ? Localization.FormatDateTimeISO(value).replace(' ', 'T') : null
                    // console.log('valT', valT)
                    actionConvention.getZCYCDateTime(valT)(dispatch)
                    break
            }
            return false
        }

        // console.log('type', type)
        const query = {}
        searchParams?.forEach((val, key) => { query[key] = val })
        // console.log('query', query)
        let val
        switch (type) {
            case InputDataType.DATE_T:
            case InputDataType.DATE_TIME:
                if (name == 'zcycDateTime') value?.setHours(23, 59, 59)
                val = value !== '' ? Localization.FormatDateTimeISO(value).replace(' ', 'T') : null
                break
            case InputDataType.TIME:
                val = value !== '' ? Localization.FormatDateTimeISO(value).replace(' ', 'T') : null
                break

            default:
                val = value !== '' ? value : null
                break
        }
        query[name] = val
        setInputPricingParameters(query)
        setURLWasChangedManualy(true)
        setSearchParams(parseParams(query))

        // console.log('query------------------------------', query)
    }

    const handleSetCalValue = (e) => {
        if (e.target.value == null || e.target.value == '' || e.target.value === '-') {
            return false
        }
        // console.log('e.target.name', e.target.name, '=', e.target.value)
        setURLWasChangedManualy(true)
        setCalcType({ id: 0, key: e.target.name, name: getParamTitle(e.target.name) })
        setCalcValue(e.target.value)
    }

    const onExportAtExcelButtonClick = (event) => {

        (async () => {

            if (pricingResult == null || pricingParameters == null) {
                return;
            }

            var data =
            {
                isin: pricingParameters.isin,
                pricingDate: pricingParameters.pricingDate,
                cpr: pricingParameters.cpr,
                cdr: pricingParameters.cdr,
                zcycDate: pricingParameters.zcycDateTime,
                usePricingDateData: pricingParameters.usePricingDateDataOnly,
                fixType: CalculationTypeV2.TYPE_UNKNOWN,
                fixValue: null
            };

            //CHECKME!!! crap
            if (pricingParameters.zSpread != null) {
                data.fixType = CalculationTypeV2.TYPE_Z_SPREAD;
                data.fixValue = pricingParameters.zSpread;
            }
            else if (pricingParameters.gSpread != null) {
                data.fixType = CalculationTypeV2.TYPE_G_SPREAD;
                data.fixValue = pricingParameters.gSpread;
            }
            else if (pricingParameters.requiredKeyRatePremium != null) {
                data.fixType = CalculationTypeV2.TYPE_PREMIUM;
                data.fixValue = pricingParameters.requiredKeyRatePremium;
            }
            else if (pricingParameters.cleanPrice != null) {
                data.fixType = CalculationTypeV2.TYPE_CLEAN_PRICE;
                data.fixValue = pricingParameters.cleanPrice;
            }
            else if (pricingParameters.dirtyPrice != null) {
                data.fixType = CalculationTypeV2.TYPE_DIRTY_PRICE;
                data.fixValue = pricingParameters.dirtyPrice;
            }
            else if (pricingParameters.couponRate != null) {
                data.fixType = CalculationTypeV2.TYPE_COUPON_RATE;
                data.fixValue = pricingParameters.couponRate;
            }
            else
                throw "Unknown fix parameter type";

            var json = JSON.stringify(data);
            var base64 = b64EncodeUnicode(json);

            //downloadData('/api/DomRf/DownloadBondsStatistics?data=' + encodeURI(base64));

            actionDownloads.addDownload(`/api/Securitization/DownloadConvention?data=` + escape(base64))(dispatch)
            // window.open(`/api/Securitization/DownloadConvention?data=` + escape(base64), '_blank');

        })();
    }

    const isBondPlacementAndFixed = (bond) => {
        return Boolean(bond?.bondStatus == BondStatus.STATUS_PLACEMENT && bond?.couponType != CouponType.TYPE_FLOAT)
    }

    const calcParamsRows = [
        {
            name: 'CPR', hide: false, source: pricingParameters, tooltip: 'Convention2Tooltip_cpr', field: 'cpr', settersource: '', alias: 'Convention2_CPR', dataType: InputDataType.FLOAT, unit: 'Convention2_percentPerYear', width: 5.6, decimalScale: 1, limits: { min: 0, max: 100 }, allowNegative: false,
            resetIcon: true, resetTooltip: 'Convention2_tooltipResetCRP',
        },
        {
            name: 'CDR', hide: false, source: pricingParameters, tooltip: 'Convention2Tooltip_cdr', field: 'cdr', settersource: '', alias: 'Convention2_CDR', dataType: InputDataType.FLOAT, unit: 'Convention2_percentPerYear', width: 5.6, decimalScale: 1, limits: { min: 0, max: 100 }, allowNegative: false,
            resetIcon: true, resetTooltip: 'Convention2_tooltipResetCDR',
        },
        { name: 'PricingDate', hide: true, source: pricingParameters, field: 'pricingDate', },
        { name: 'UsePricingDateDataOnly', hide: true, source: pricingParameters, field: 'usePricingDateDataOnly' },
        { name: 'ZCYCDateTime', hide: true, source: pricingParameters, field: 'zcycDateTime' },
    ]

    // console.log('bondParameters', bondParameters)
    // console.log('PricingParameters', PricingParameters)
    const getMaxDate = () => {
        const tenDaysAfterNow = Date.now() + 10 * 60 * 60 * 24 * 1000
        const maxDate = Math.min(tenDaysAfterNow, new Date(selectedBond?.actualRedemptionDate ?? tenDaysAfterNow)?.getTime(), new Date(selectedBond?.maturityDate ?? tenDaysAfterNow)?.getTime())
        // console.log('maxDate', maxDate, '=', tenDaysAfterNow, new Date(selectedBond?.actualRedemptionDate ?? tenDaysAfterNow)?.getTime(), new Date(selectedBond?.maturityDate ?? tenDaysAfterNow)?.getTime())
        return maxDate
    }


    const ZCYCDateTimeSetter = (row) => {
        try {
            // console.log('row', row)
            // const zcycDate = new Date(row.source[row.field])
            // const now = new Date(Date.now())

            // if (!(zcycDate.setHours(0, 0, 0) == now.setHours(0, 0, 0) && zcycDate.setSeconds(0, 0) < now.setSeconds(0, 0)))
            //     return
            return (
                <IconButton
                    size="small"
                    className={cls.unitButton}
                    onClick={() => handleValueChange('zcycDateTime', Date.now(), row?.dataType) /* searchParamRemove('zcycDateTime')*/}
                >
                    <Tooltip
                        disableInteractive
                        title={t('convention2.zcycyDateRimeReset')}
                    >
                        <Update className={cls.unitIcon} />
                    </Tooltip>
                </IconButton>
            )

        } catch (error) {

        }
    }

    const paramRows = [
        {
            name: 'params', alias: 'Convention2_CalculateParams', rows: [
                {
                    name: 'PricingDate', source: pricingParameters, field: 'pricingDate', alias: 'Convention2_PricingDate', dataType: InputDataType.DATE_T,
                    disabled: isBondPlacementAndFixed(selectedBond),
                    tooltip: 'Convention2Tooltip_pricingDate',
                    minDate: new Date(bondParameters?.issueDate ? bondParameters?.issueDate : '2014-01-07').getTime(),
                    maxDate: getMaxDate()
                    // (Date.now() + 10 * 60 * 60 * 24 * 1000) < new Date(bondParameters?.legalRedemptionDate).getTime() ?
                    //     (Date.now() + 10 * 60 * 60 * 24 * 1000)
                    //     : new Date(bondParameters?.legalRedemptionDate).getTime()
                },
                { name: 'ZCYCDate', source: pricingParameters, field: 'zcycDateTime', alias: 'Convention2_ZCYCDate', tooltip: 'Convention2Tooltip_zcycDate', dataType: InputDataType.DATE_T, minDate: new Date('2014-01-07').getTime(), maxDate: Date.now() },
                { name: 'ZCYCTime', source: pricingParameters, field: 'zcycDateTime', alias: 'Convention2_ZCYCTime', tooltip: 'Convention2Tooltip_zcycTime', unitComponent: ZCYCDateTimeSetter, dataType: InputDataType.TIME },
                (selectedBond?.isin != 'RU000A100DQ4') ?
                    (pricingParameters?.pricingDate != null && new Date(pricingParameters?.pricingDate).getTime() < new Date().setHours(0, 0, 0, 0)) ?
                        { name: 'UsePricingDateDataOnly', source: pricingParameters, field: 'usePricingDateDataOnly', alias: 'Convention2_UsePricingDateDataOnly', tooltip: 'Convention2Tooltip_usePricingDateDataOnly', dataType: InputDataType.BOOLEAN, unit: '', fractionLength: 2 }
                        : null
                    : null,
            ]
        },
        {
            name: 'bondParams', alias: 'Convention2_BondParams', rows: [
                { name: 'CurrentBondPrincipal', source: calculatedParameters, field: 'currentBondPrincipal', alias: 'Convention2_CurrentBondPrincipal', tooltip: 'Convention2Tooltip_currentBondPrincipal', dataType: InputDataType.FLOAT, unit: 'Convention2_rubSign', fractionLength: 2, readOnly: true },
                { name: 'couponType', source: bondParameters, field: 'couponType', valueGetter: getCouponType, alias: 'Convention2_CouponType', tooltip: 'Convention2Tooltip_couponType', dataType: InputDataType.STRING, readOnly: true },
                { name: 'couponPeriod', source: bondParameters, field: 'couponPeriod', valueGetter: getCouponPeriodValue, alias: 'Convention2_CouponPeriod', tooltip: 'Convention2Tooltip_couponPeriod', dataType: InputDataType.INT, readOnly: true },
                bondParameters?.couponType == 1 ?
                    { name: 'fixedCouponRate', source: bondParameters, field: 'fixedCouponRate', alias: 'Convention2_FixedCouponRate', tooltip: 'Convention2Tooltip_fixedCouponRate', dataType: InputDataType.FLOAT, unit: 'Convention2_percentPerYear', fractionLength: 2, readOnly: true }
                    : bondParameters?.couponType == 3 ?
                        { name: 'fixedKeyRatePremium', source: bondParameters, field: 'fixedKeyRatePremium', alias: 'Convention2_FixedKeyRatePremium', tooltip: 'Convention2Tooltip_fixedKeyRatePremium', dataType: InputDataType.FLOAT, unit: 'Convention2_percentPerYear', fractionLength: 2, readOnly: true }
                        : null,
                { name: 'cleanUpPercentage', source: bondParameters, field: 'cleanUpPercentage', alias: 'Convention2_CleanUpPercentage', tooltip: 'Convention2Tooltip_cleanUpPercentage', dataType: InputDataType.INT, unit: 'Convention2_percent', fractionLength: 2, readOnly: true },
                { name: 'legalRedemptionDate', source: bondParameters, field: 'legalRedemptionDate', valueGetter: getDate, alias: 'Convention2_LegalRedemptionDate', tooltip: 'Convention2Tooltip_legalRedemptionDate', dataType: InputDataType.DATE, fractionLength: 2, readOnly: true },
            ]
        },
        {
            name: 'mortgageCoverage', alias: 'Convention2_MortgageCoverage', rows: [
                { name: 'WAC', source: calculatedParameters, field: 'wac', datefield: 'wacwamDate', alias: 'Convention2_WAC', tooltip: 'Convention2Tooltip_wac', dataType: InputDataType.FLOAT, unit: 'Convention2_percentPerYear', fractionLength: 2, readOnly: true },
                { name: 'CalculationRefinancingRate', source: calculatedParameters, field: 'calculationRefinancingRate', datefield: 'calculationRefinancingRateReportDate', alias: 'Convention2_CalculationRefinancingRate', tooltip: 'Convention2Tooltip_refinancingRate', dataType: InputDataType.FLOAT, unit: 'Convention2_percentPerYear', fractionLength: 2, readOnly: true },
                { name: 'IncentiveToRefinance', source: calculatedParameters, field: 'incentiveToRefinance', alias: 'Convention2_IncentiveToRefinance', tooltip: 'Convention2Tooltip_incentiveToRefinance', dataType: InputDataType.FLOAT, unit: 'Convention2_PercentagePoints', fractionLength: 1, readOnly: true },
                { name: 'SCurveCPR', source: calculatedParameters, field: 'sCurveCPR', datefield: 'calculationSCurveReportDate', alias: 'Convention2_SCurveCPR', tooltip: 'Convention2Tooltip_sCurveCPR', dataType: InputDataType.FLOAT, unit: 'Convention2_percentPerYear', fractionLength: 1, readOnly: true },
                {
                    name: 'HistoricalCPR',
                    source: { ...calculatedParameters, issueDate: selectedBond?.issueDate }, //need for tooltip variables
                    field: 'historicalCPR', datefield: 'historicalCPRDate', subalias: 'Convention2_AvgFromIssueDate', alias: 'Convention2_HistoricalCPR',
                    tooltip: calculatedParameters?.historicalCPR != null ? 'Convention2Tooltip_historicalCPR' : 'Convention2Tooltip_historicalCPR_null'
                    , dataType: InputDataType.FLOAT, unit: 'Convention2_percentPerYear', fractionLength: 1, readOnly: true
                },
                {
                    name: 'SixMonthsCPR',
                    source: { ...calculatedParameters, issueDate: selectedBond?.issueDate }, //need for tooltip variables
                    field: 'sixMonthsCPR', datefield: 'historicalCPRDate', subalias: 'Convention2_AvgSixMonths', alias: 'Convention2_HistoricalCPR',
                    tooltip: calculatedParameters?.historicalCPR != null ? 'Convention2Tooltip_sixMonthsCPR' : 'Convention2Tooltip_sixMonthsCPR_null',
                    dataType: InputDataType.FLOAT, unit: 'Convention2_percentPerYear', fractionLength: 1, readOnly: true
                },
                {
                    name: 'HistoricalCDR',
                    source: { ...calculatedParameters, issueDate: selectedBond?.issueDate }, //need for tooltip variables
                    field: 'historicalCDR', datefield: 'historicalCDRDate', subalias: 'Convention2_AvgFromIssueDate', alias: 'Convention2_HistoricalCDR',
                    tooltip: calculatedParameters?.historicalCPR != null ? 'Convention2Tooltip_historicalCDR' : 'Convention2Tooltip_historicalCDR_null',
                    dataType: InputDataType.FLOAT, unit: 'Convention2_percentPerYear', fractionLength: 1, readOnly: true
                },
            ]
        },
    ]
    // console.log('calculatedParameters', calculatedParameters)
    // console.log('PricingParameters', PricingParameters)
    const resultRows = [
        {
            name: 'bondParams', alias: 'Convention2_BondParams', rows: [
                { name: 'AccruedCouponInterest', source: pricingResult, field: ['accruedCouponInterest', 'accruedCouponInterestRub'], alias: 'Convention2_AccruedCouponInterest', tooltip: "Convention2Tooltip_accruedCouponInterest", unit: ['Convention2_percent', 'Convention2_rubSign'], dataType: InputDataType.FLOAT, fractionLength: 2, readOnly: true },
                { name: 'DirtyPrice', source: pricingResult, field: ['dirtyPrice', 'dirtyPriceRub'], alias: 'Convention2_dirtyPrice', tooltip: "Convention2Tooltip_dirtyPrice", unit: ['Convention2_percent', 'Convention2_rubSign'], dataType: InputDataType.FLOAT, fractionLength: 2, readOnly: true },
                { name: 'CleanPrice', source: pricingResult, field: ['cleanPrice', 'cleanPriceRub'], alias: 'Convention2_cleanPrice', tooltip: "Convention2Tooltip_cleanPrice", unit: ['Convention2_percent', 'Convention2_rubSign'], dataType: InputDataType.FLOAT, fractionLength: 2, readOnly: true },
                ...(selectedBond?.couponType == CouponType.TYPE_KEYRATE_AND_PREMIUM) ?
                    [{ name: 'NextCouponKeyRatePlusPremiumValuePercents', source: calculatedParameters, field: 'nextCouponKeyRatePlusPremiumValuePercents', valueGetter: getNextCoupon, alias: 'convention2.NextCoupon', tooltip: 'convention2.NextCouponTooltip', unit: 'Convention2_percentPerYear', dataType: InputDataType.FLOAT, fractionLength: 2, readOnly: true }]
                    : []
                ,
                ...([CouponType.TYPE_FIXED, CouponType.TYPE_FLOAT].includes(selectedBond?.couponType)) ?
                    [
                        { name: 'YTM', source: pricingResult, field: 'ytm', alias: 'Convention2_YTM', tooltip: "Convention2Tooltip_ytm", unit: 'Convention2_percentPerYear', dataType: InputDataType.FLOAT, fractionLength: 2, readOnly: true },
                        ...Boolean(isBondPlacementAndFixed(selectedBond)) ? [
                            { name: 'f_zSpread', source: pricingResult, field: 'zSpread', alias: 'Convention2_zSpread', tooltip: "Convention2Tooltip_f_zSpreadParam", unit: 'Convention2_bp', dataType: InputDataType.INT, readOnly: true },
                            { name: 'f_gSpread', source: pricingResult, field: 'gSpread', alias: 'Convention2_gSpread', tooltip: "Convention2Tooltip_f_gSpreadParam", unit: 'Convention2_bp', dataType: InputDataType.INT, readOnly: true },
                        ]
                            : [],
                        { name: 'DurationMacaulay', source: pricingResult, field: 'durationMacaulay', alias: 'Convention2_DurationMacaulay', tooltip: "Convention2Tooltip_durationMacaulay", unit: 'convention2.ofYear', dataType: InputDataType.FLOAT, fractionLength: 2, readOnly: true },
                        { name: 'DurationModified', source: pricingResult, field: 'durationModified', alias: 'Convention2_DurationModified', tooltip: "Convention2Tooltip_durationModified", unit: 'Convention2_PercentagePoints', dataType: InputDataType.FLOAT, fractionLength: 2, readOnly: true },
                        // { name: '', source:'', field: '', alias: 'Convention2_', unit: '', fractionLength: 2 },        
                    ] : []
            ]
        },
    ]

    // useEffect(() => {
    //     //   if(PricingResult)
    // }, [PricingResult])

    const handleCopyLink = () => {
        // console.log('PricingParameters', PricingParameters)
        // console.log(selectedBond?.isin, calcType, calcValue, PricingParameters)
        if (selectedBond?.isin == null || calcType?.key == null || calcValue == null || pricingParameters == null)
            //showAlert
            return

        const params = { 'isin': selectedBond.isin, [calcType.key]: calcValue }

        if (URLWasChangedManualy) {

            // const query = {}
            searchParams?.forEach((val, key) => {
                switch (key) {
                    case 'cleanPrice':
                    case 'dirtyPrice':
                    case 'zSpread':
                    case 'gSpread':
                    case 'requiredKeyRatePremium':
                    case 'couponRate':
                        params[key] = val
                        break

                    default:
                        return
                }

            })

            calcParamsRows?.forEach(row => {
                params[row.field] = pricingParameters[row.field]
            })
        }
        // console.log('params', params)
        const url = window.location.protocol + '//' + window.location.hostname + (window.location.port ? `:${window.location.port}` : ``) + window.location.pathname + '?' + new URLSearchParams(params).toString()
        copyTextToClipboard(url)
        actionAlert.addAlert('Ссылка на расчет сформирована и скопирована', style.colorPrimary1)(dispatch)
    }

    const checkLimits = (val = 0, min = null, max = null) => {
        // console.log('val, min, max', val, min, max)
        let minCondition = true
        let maxCondition = true
        if (max != null)
            maxCondition = (val <= max)

        if (min != null)
            minCondition = (val >= min)

        return minCondition && maxCondition
    }

    const downloadMethodologyHandler = () => {
        window.open('/files/Конвенция ИЦБ ДОМ.РФ 2.0.pdf', '_blank')
    }

    function chEndAdornment(endAdornment) {
        const children = React.Children.map(endAdornment.props.children, ch => { if (ch) return React.cloneElement(ch, { title: '' }) }) //remove title for each endAdornment
        return React.cloneElement(endAdornment, {}, children);
    }

    const checkResetCondition = (row) => {
        try {
            if (row.resetIcon == null || loading)
                return

            switch (row?.field) {
                case 'cpr':
                    return Boolean(row?.source[row?.field] != calculatedParameters?.sCurveCPR)

                case 'cdr':
                    return Boolean(row?.source[row?.field] != calculatedParameters?.conventionalCDR)

                default:
                    return false
            }
        } catch (error) {

        }
    }

    const resetHandler = (row) => {
        try {
            if (row.resetIcon == null || loading)
                return

            switch (row?.field) {
                case 'cpr':
                case 'cdr':
                    searchParamRemove(row?.field)
                    break
                // case 'cpr':
                //     handleValueChange(row?.field, calculatedParameters?.sCurveCPR)
                //     break

                // case 'cdr':
                //     handleValueChange(row?.field, calculatedParameters?.conventionalCDR)
                //     break

            }
        } catch (error) {

        }
    }

    // console.log('bondList', bondList)
    const { isMobile, isLandscape } = useMobileDetect()

    return (<>
        {/* <ConfirmDialog2
            show={showConvention2InfoWin}
            description={<div className={cls.infoWinText} dangerouslySetInnerHTML={{ __html: t('convention2.infoWinText0') }}></div>}
            confirmationText={t('ok')}
            cancellationText={null}
            dialogProps={{}}
            onClose={() => { setShowConvention2InfoWin(false) }}
            onCancel={() => { setShowConvention2InfoWin(false) }}
            onConfirm={() => { setShowConvention2InfoWin(false) }}
        /> */}
        <Convention2InfoWin
            show={showConvention2InfoWin}
            setShow={setShowConvention2InfoWin}
        />
        <Box className={[/*isMobile || viewHeight < 800 ? cls.mobileRoot :*/ cls.root].join(' ')}>
            <Box className={cls.rootPaper}>
                {loading && <SpinnerBars withBackground />}
                <Box className={cls.panelHeader}>
                    <Box className={cls.panelHeaderTitle}>{t("Convention2_InputPanelTitle")}</Box>
                    <Box className={cls.panelHeaderIcons}>
                        <Tooltip
                            disableFocusListener
                            title={<div dangerouslySetInnerHTML={{ __html: getTooltip({ tooltip: 'Convention2_metodic' }) }} />}
                            classes={{
                                tooltip: cls.tooltip
                            }}
                        >
                            <IconButton size='small'> {/*for hover highlight effect*/}
                                <InfoOutlined className={cls.panelHeaderIcon} />
                            </IconButton>
                        </Tooltip>

                        <Tooltip
                            title={t('Convention2_GitHub')}
                            disableInteractive
                            classes={{ tooltip: cls.tooltip }}
                        >
                            <IconButton
                                size='small'
                                onClick={() => window.open(`https://github.com/pdovbnya/convention`, '_blank')}
                            >
                                <GitHub className={cls.panelHeaderIcon} />
                            </IconButton>
                        </Tooltip>

                        <Tooltip
                            title={<div dangerouslySetInnerHTML={{ __html: getTooltip({ tooltip: 'Convention2_API' }) }} />}
                            classes={{ tooltip: cls.tooltip }}
                            disableFocusListener
                        >
                            <IconButton
                                size='small'
                            // onClick={}
                            >
                                <ApiIcon className={cls.panelHeaderIcon} />
                            </IconButton>
                        </Tooltip>

                        <Tooltip
                            title={t('Convention2_exportToExcel')}
                            disableInteractive
                            disableFocusListener
                            classes={{ tooltip: cls.tooltip }}
                        >
                            <IconButton
                                disabled={!Boolean(pricingResult)}
                                size='small'
                                onClick={onExportAtExcelButtonClick}
                            >
                                <ExcelIcon className={cls.panelHeaderIcon} />
                            </IconButton>
                        </Tooltip>

                        <Tooltip
                            title={t('Convention2_copyFullLink')}
                            disableInteractive
                            disableFocusListener
                            classes={{ tooltip: cls.tooltip }}
                        >
                            <IconButton
                                disabled={!Boolean(pricingResult)}
                                size='small'
                                onClick={handleCopyLink}
                            >
                                <OfflineShare className={cls.panelHeaderIcon} />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Box>

                <Box className={cls.panelData}>
                    <Box className={cls.row}>
                        <Box className={[cls.rowData, cls.fw].join(' ')}>
                            <Autocomplete
                                disablePortal
                                id="bonds-list"
                                options={bondList}
                                disabled={loading || bondListLoading}
                                classes={{ option: cls.option, paper: cls.autoselectPopper, listbox: [cls.virtualScrollablePanel, cls.scrollableList].join(' ') }}
                                componentsProps={{ popper: { sx: { minWidth: 'fit-content' } } }}
                                noOptionsText={t("Convention2_noOptions")}
                                renderInput={(params) => {
                                    return (
                                        <TextField
                                            {...params}
                                            // label={selectedBond ? '' : t("Convention2_InputISINLabel")}
                                            label={t("Convention2_InputISINLabel")}
                                            InputLabelProps={{
                                                className: cls.labelStyle,
                                            }}
                                            inputProps={{ ...params.inputProps, autoComplete: "off", style: {/* textAlign: 'right'*/ } }}
                                            InputProps={{
                                                ...params.InputProps,
                                                startAdornment: bondListLoading ? <CustomCircularProgress color={style.colorPrimary3_40} size={20} /> : null,
                                                endAdornment: <>{chEndAdornment(params.InputProps.endAdornment)}</>
                                            }}
                                        />
                                    )
                                }}
                                getOptionLabel={(bond) => {
                                    if (bond.bondStatus == BondStatus.STATUS_PLACEMENT && bond.couponType == CouponType.TYPE_FIXED)
                                        return `${bond?.regNumber}`
                                    else
                                        return `${bond?.label} / ${bond?.isin}`
                                }}
                                value={selectedBond}
                                onChange={(e, bond, reason, details) => {

                                    setURLWasChangedManualy(true)
                                    handleSelectBond(bond)
                                }}
                                renderOption={(props, bond, { selected }) => {
                                    return <Box {...props} className={[cls.optionBox, props.className].join(' ')}>
                                        <Box className={[cls.optionName, isMobile ? cls.optionMobile : ''].join(' ')}>
                                            {
                                                (bond.bondStatus == BondStatus.STATUS_PLACEMENT && bond.couponType != CouponType.TYPE_FLOAT) ?
                                                    <Box className={cls.optionLabel}>{bond?.regNumber}</Box>
                                                    : <>
                                                        <Box className={cls.optionLabel}>{bond.label}</Box>
                                                        <Box className={cls.optionISIN}>{bond.isin}</Box>
                                                    </>
                                            }
                                        </Box>
                                        <Box className={cls.optionStatusBox}>
                                            <Box className={cls.optionStatus}>
                                                {getBondStatus(bond)}
                                            </Box>
                                            <Box className={cls.optionCouponType}>
                                                {getBondCoupon(bond?.couponType)}
                                            </Box>
                                        </Box>
                                    </Box>
                                }
                                }
                            />
                        </Box>
                    </Box>
                    {/* {console.log('availableCalcOptions, PricingResult', availableCalcOptions, PricingResult, otherStore)} */}
                    {(Boolean(selectedBond) && Boolean(availableCalcOptions)) &&
                        <Box className={cls.row}>
                            <Grid container className={cls.optionParams}>{
                                Object.keys(availableCalcOptions).filter(key => availableCalcOptions[key].active == true).map(key => {

                                    return <Grid item key={`option_${key}`} xs={12 / Object.keys(availableCalcOptions).filter(key => availableCalcOptions[key].active == true).length}>
                                        <CustomNumericValidator
                                            name={key}
                                            disabled={availableCalcOptions[key]?.disabled}
                                            header={t(`Convention2_${key}`)}
                                            tooltip={getTooltip(availableCalcOptions[key])}
                                            unit={availableCalcOptions[key].unit ? t(availableCalcOptions[key].unit) : null}
                                            width={availableCalcOptions[key]?.width != null ? availableCalcOptions[key].width : 'initial'}
                                            value={
                                                key == calcType?.key ? calcValue : (pricingResult != null ? pricingResult[key] : null)
                                            }
                                            onChange={handleSetCalValue}
                                            inputProps={{
                                                autoComplete: "off",
                                                style: { textAlign: 'right', paddingRight: '5px' },
                                                isAllowed: (values) => {
                                                    const { floatValue } = values;
                                                    return checkLimits(floatValue, availableCalcOptions[key]?.limits?.min, availableCalcOptions[key]?.limits?.max)
                                                },
                                                thousandSeparator: false,
                                                // fixedDecimalScale: true,
                                                ...availableCalcOptions[key]?.decimalScale != null ? { decimalScale: availableCalcOptions[key].decimalScale } : null,
                                                ...availableCalcOptions[key]?.allowNegative != null ? { allowNegative: availableCalcOptions[key].allowNegative } : null,
                                            }}
                                            sx={{
                                                '& .MuiInput-root:before': { border: 'none!important' },
                                                // '& .MuiInput-root:after': { border: 'none!important' },
                                            }}
                                        />
                                    </Grid>
                                })
                            }</Grid>
                        </Box>
                    }

                    {Boolean(inputPricingParameters) &&
                        calcParamsRows?.filter(row => !row?.hide)?.some(row => /*row?.source[row?.field]*/row?.valueGetter ? row.valueGetter(row) : valueGetter(row) != null) &&
                        <Box className={cls.row}>
                            <Grid container className={cls.optionExParams}>
                                {calcParamsRows
                                    ?.filter(row => !row?.hide)
                                    ?.map(row => {
                                        if (row?.name == null)
                                            return (<hr />)
                                        return <Grid item key={`option_${row.name}`} xs={12 / calcParamsRows?.filter(row => !row?.hide).length} className={cls.optionExParamsBox} >
                                            <CustomNumericValidator
                                                name={row.field}
                                                tooltip={getTooltip(row)}
                                                header={t(row.alias)}
                                                unit={row.unit ? t(row.unit) : null}
                                                width={row?.width != null ? row.width : 'initial'}
                                                onChange={(e) => {
                                                    return handleValueChange(e.target.name, e.target.value) //need RETURN for right behaviour with empty value FALSE - emulate Escape behaviour
                                                }}
                                                value={row?.valueGetter ? row.valueGetter(row) : valueGetter(row)}
                                                {...row.validators}
                                                sx={{
                                                    '& .MuiInput-root:before': { border: 'none!important' },
                                                    // '& .MuiInput-root:after': { border: 'none!important' },
                                                }}
                                                inputProps={{
                                                    // autoComplete: "off",
                                                    style: { textAlign: 'right', paddingRight: '5px' },
                                                    isAllowed: (values) => {
                                                        const { floatValue } = values;
                                                        return checkLimits(floatValue, row?.limits?.min, row?.limits?.max)
                                                    },
                                                    thousandSeparator: false,
                                                    // fixedDecimalScale: true,
                                                    ...row?.decimalScale ? { decimalScale: row.decimalScale } : null,
                                                    // ...row?.allowNegative != null ? { allowNegative: row.allowNegative } : null,
                                                    allowNegative: false
                                                }}
                                            />
                                            {Boolean(row?.resetIcon != null) && checkResetCondition(row) && <>
                                                <Tooltip
                                                    disableInteractive
                                                    title={row?.resetTooltip ? t(row.resetTooltip) : null}
                                                    classes={{ tooltip: cls.wideTooltip }}
                                                >
                                                    <IconButton className={cls.smallIconButton} onClick={() => resetHandler(row)}>
                                                        <Replay className={cls.smallIcon} />
                                                    </IconButton>
                                                </Tooltip>
                                            </>
                                            }
                                        </Grid>
                                    })
                                }
                            </Grid>
                        </Box>
                    }

                    {apiError ? <Box className={cls.alertBox}>{apiError}</Box>
                        : Boolean(pricingResult != null) &&
                        <>
                            <Box className={cls.paramsRoot}>
                                <Box sx={{ marginRight: '4px' }}>
                                    {resultRows
                                        ?.map(block => {
                                            return <Box key={`block_${block?.name}`}>
                                                {/* {Boolean(block?.alias !== null) && <Box></Box>} */}
                                                {block?.rows
                                                    ?.filter(row => row != null)
                                                    ?.map(row => {
                                                        return (
                                                            <Box key={`result_${row.name}`} className={cls.resultRow}>
                                                                <Box className={cls.resultRowName}>
                                                                    {Boolean(row?.tooltip) ?
                                                                        <CustomTooltip
                                                                            content={getTooltip(row)}
                                                                            hideIcon={true}
                                                                            className={cls.customTooltip}
                                                                        >
                                                                            {t(row?.alias)}
                                                                        </CustomTooltip>
                                                                        :
                                                                        t(row?.alias)
                                                                    }
                                                                </Box>
                                                                {
                                                                    <Box className={cls.resultRowDataBlock}>
                                                                        {[...Array.isArray(row?.field) ? row?.field : [row?.field]]?.map((field, index) => {
                                                                            return (<Box className={cls.resultRowData} key={`result_${field}`}>
                                                                                <Box className={cls.resultRowValue}>
                                                                                    {row?.valueGetter ? row.valueGetter({ ...row, field }) : valueGetter({ ...row, field })}
                                                                                </Box>
                                                                                <Box className={[cls.resultRowUnit, (Array.isArray(row?.field) && index == 0) ? cls.resultRowUnitFit : ''].join(' ')}>{t([...Array.isArray(row?.unit) ? row.unit : [row.unit]][index])}</Box>
                                                                            </Box>)
                                                                        })}
                                                                    </Box>
                                                                }
                                                            </Box>
                                                        )
                                                    })
                                                }
                                            </Box>
                                        })
                                    }
                                </Box>
                            </Box>
                            <Box className={cls.paramsRoot1}>
                                <Box className={cls.divider} />
                                <Box className={cls.virtualScrollablePanel}>
                                    {paramRows
                                        ?.filter(row => row?.rows?.some(item => {
                                            try {
                                                return item?.source[item?.field] != null
                                            }
                                            catch (error) { }
                                        }))
                                        ?.map(block => {
                                            return <Box key={`block_${block?.name}`} className={cls.block}>
                                                {Boolean(block?.alias !== null) && <Box className={cls.blockTitle}>{t(block.alias)}</Box>}
                                                {block?.rows
                                                    ?.filter(row => row != null)
                                                    ?.map(row => {
                                                        return (
                                                            <Box key={`result_${row.name}`} className={cls.resultRow}>
                                                                <Box className={cls.resultRowName}>
                                                                    {Boolean(row?.tooltip) ?
                                                                        <CustomTooltip
                                                                            content={getTooltip(row)}
                                                                            hideIcon={true}
                                                                        >
                                                                            {t(row?.alias)}
                                                                        </CustomTooltip>
                                                                        :
                                                                        t(row?.alias)
                                                                    }
                                                                    {Boolean(row?.subalias) && <Box className={cls.resultRowSubname}>{t(row?.subalias)}</Box>}
                                                                </Box>
                                                                <Box className={cls.resultRowData}>
                                                                    {
                                                                        [...Array.isArray(row?.field) ? row?.field : [row?.field]]?.map((field, index) => {

                                                                            let val = (row?.valueGetter ? row.valueGetter(row) : valueGetter(row))
                                                                            if (val === null) val = '–'
                                                                            // console.log('val', val, row)
                                                                            return (<React.Fragment key={`result_${field}`}>
                                                                                <Box className={cls.exResultRowValue}>
                                                                                    {Boolean(row?.readOnly) ?
                                                                                        val
                                                                                        : <UniversalInputField
                                                                                            needConfirm
                                                                                            allowKeyboardControl={![InputDataType.DATE_T, InputDataType.TIME].includes(row?.dataType)}
                                                                                            dataType={row?.dataType ? row?.dataType : InputDataType.STRING}
                                                                                            name={row.field}
                                                                                            disabled={row?.disabled === true}
                                                                                            readOnly={Boolean(row?.readOnly)}
                                                                                            title={''}
                                                                                            width={row?.width != null ? row.width : 'initial'}
                                                                                            handleValueChange={(name, val) => { handleValueChange(name, val, row?.dataType) }}
                                                                                            value={val}
                                                                                            {...row.validators}
                                                                                            sx={{
                                                                                                //     '& .Mui-disabled:before': { borderBottomStyle: 'solid !important', borderBottomColor: `${alpha(style.colorPrimary3, 0.5)} !important` },
                                                                                                //     '& .MuiInput-root:hover:not(.Mui-disabled):before': { borderBottomColor: `${alpha(style.colorPrimary3, 0.5)} !important` },
                                                                                                '& .MuiInput-root': { cursor: 'pointer !important', },
                                                                                                '& input': { paddingTop: 0, paddingBottom: 0, paddingRight: '0 !important', cursor: 'pointer', userSelect: 'none !important', },
                                                                                                '& .MuiInput-root:before': { border: 'none!important' },
                                                                                                '& .MuiInput-root:after': { border: 'none!important' },
                                                                                                '& .MuiInput-underline:before': { border: 'none!important' },
                                                                                                '& .MuiInputAdornment-root': { marginLeft: 0, marginTop: '3px' },
                                                                                                //     '& .Mui-readOnly': !row?.readOnly ? { color: 'initial !important', '& *': { '-webkit-text-fill-color': 'inherit !important' }, } : {},
                                                                                                //     '& .Mui-disabled': !!row?.disabled ? { color: `${style.colorPrimary3_60} !important`, } : {},
                                                                                                //     '& .Mui-readOnly:after': { border: 'none' },
                                                                                                //     '& .Mui-readOnly:before': { border: 'none !important' },
                                                                                                //     '& .Mui-focused:after': !row?.readOnly ? { border: `solid 2px ${alpha(style.colorPrimary2, 1)} !important`, top: '1px' } : {},
                                                                                                //     '& .Mui-error:after': { border: `none` },
                                                                                                //     '& svg': { fontSize: '20px' },
                                                                                                //     '& .MuiSwitch-track': { opacity: '0.5 !important' }, // отключаем прозрачность на disabled
                                                                                            }
                                                                                            }
                                                                                            // isChanged={Object.hasOwn(updatedFields, row.name)}
                                                                                            // disableCloseOnSelect={false}
                                                                                            hideValidationIcon={true}
                                                                                            minDate={
                                                                                                (row.dataType == InputDataType.DATE ||
                                                                                                    row.dataType == InputDataType.DATE_TIME ||
                                                                                                    row.dataType == InputDataType.DATE_T) ?
                                                                                                    row?.minDate : null
                                                                                            }
                                                                                            maxDate={
                                                                                                (row.dataType == InputDataType.DATE ||
                                                                                                    row.dataType == InputDataType.DATE_TIME ||
                                                                                                    row.dataType == InputDataType.DATE_T) ?
                                                                                                    row?.maxDate : null
                                                                                            }
                                                                                            inputProps={{
                                                                                                autoComplete: "off",
                                                                                                style: { textAlign: 'right', paddingRight: '5px' },
                                                                                                // isAllowed: (values) => {
                                                                                                //     const { floatValue } = values;
                                                                                                //     return checkLimits(floatValue, row?.limits?.min, row?.limits?.max)
                                                                                                // },
                                                                                                ...row?.decimalScale ? { decimalScale: row.decimalScale } : null,
                                                                                                // ...row?.allowedDecimalSeparators ? { allowedDecimalSeparators: row.allowedDecimalSeparators } : null,
                                                                                            }}
                                                                                            closeOnSelect={false}

                                                                                        />}
                                                                                    {(Boolean(row?.datefield) && Boolean(val))
                                                                                        && <Box className={cls.resultRowSubvalue}>{Localization.FormatDateStringLocale(row?.source[row.datefield])}</Box>}

                                                                                </Box>
                                                                                <Box className={cls.resultRowUnit}>
                                                                                    {row?.unitComponent != null ?
                                                                                        row?.unitComponent(row) :
                                                                                        t([...Array.isArray(row?.unit) ? row.unit : [row.unit]][index])
                                                                                    }
                                                                                </Box>
                                                                            </React.Fragment>)
                                                                        })
                                                                    }
                                                                </Box>
                                                            </Box>
                                                        )
                                                    })
                                                }
                                            </Box>
                                        })
                                    }
                                </Box>
                            </Box>
                        </>
                    }
                </Box>
            </Box >
        </Box >
    </>
    )
}

export default InputPanel