import AdministrationService from "@Services/AdministrationService";
import { Actions as LoaderActions } from "@Store/LoaderStore";

const Actions = {

    Init: "ADMINISTRATION_INIT",
    SendRequest: "ADMINISTRATION_SEND",
    RequestFailed: "ADMINISTRATION_REQUEST_FAILED",
    RequestSuccess: "ADMINISTRATION_REQUEST_SUCCESS",
}

export const actionCreators = {

    init: () => async (dispatch, getState) => {
        dispatch({ type: Actions.Init });
    },

    getMailingList: (filter) => async (dispatch) => {

        dispatch({ type: Actions.SendRequest, payload: { isLoading: true } });

        var result = await AdministrationService.getMailingList(filter);
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailed, payload: { isLoading: false } });
            return;
        }

        dispatch({ type: Actions.RequestSuccess, payload: { data: result.value, isLoading: false } });
    },

    getUsersList: (filter) => async (dispatch) => {

        dispatch({ type: Actions.SendRequest });
        const loader = "usersLoad";
        dispatch({ type: LoaderActions.ShowLoader, payload: loader });

        var result = await AdministrationService.getUsers(filter);
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailed });
            dispatch({ type: LoaderActions.HideLoader, payload: loader });
            return;
        }

        //console.log("---- searchFinished ", filter);

        dispatch({ type: Actions.RequestSuccess, payload: { users: result.value } }); //CHECKME!!! payload matches to getUsers() func
        dispatch({ type: LoaderActions.HideLoader, payload: loader });
    },

    getManagersList: (filter) => async (dispatch) => {

        dispatch({ type: Actions.SendRequest });
        const loader = "managersLoad";
        dispatch({ type: LoaderActions.ShowLoader, payload: loader });

        var result = await AdministrationService.getUsers(filter);
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailed });
            dispatch({ type: LoaderActions.HideLoader, payload: loader });
            return;
        }

        dispatch({ type: Actions.RequestSuccess, payload: { managers: result.value } }); //CHECKME!!! payload matches to getUsers() func
        dispatch({ type: LoaderActions.HideLoader, payload: loader });
    },

    getUsers: (searchValue, existedValue) => async (dispatch) => {

        dispatch({ type: Actions.SendRequest });

        var result = await AdministrationService.getUsersQuery(searchValue, existedValue);
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailed });
            return;
        }

        var users = result.value.items.map((item) => { return { key: item.id, value: item.email }; });

        dispatch({ type: Actions.RequestSuccess, payload: { users: users } });
    },

    /* DUMMY! user can be created through registration routine
        createUser: (data) => async (dispatch) => {
            
            dispatch({ type: Actions.SendRequest });
    
            var result = await AccountService.createUser(data);
            if (result.hasErrors) {
                dispatch({ type: Actions.RequestFailed });
                return;
            }
    
            dispatch({ type: Actions.RequestSuccess, payload: { createdUser: result.value } });
        },
    */

    updateUser: (data) => async (dispatch) => {

        dispatch({ type: Actions.SendRequest, payload: { isProceeding: true } });

        var result = await AdministrationService.updateUser(data);
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailed, payload: { isProceeding: false } });
            return;
        }

        dispatch({ type: Actions.RequestSuccess, payload: { updatedUser: result.value, isProceeding: false } });
    },

    updateUserPermissions: (data) => async (dispatch) => {

        dispatch({ type: Actions.SendRequest, payload: { isPermissionsSaved: false, isProceeding: true } });

        var result = await AdministrationService.updateUserPermissions(data);
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailed, payload: { isProceeding: false } });
            return;
        }

        dispatch({ type: Actions.RequestSuccess, payload: { isPermissionsSaved: true, isProceeding: false } });
    },

    removeUser: (data) => async (dispatch) => {

        dispatch({ type: Actions.SendRequest, payload: { isProceeding: true } });

        var result = await AdministrationService.removeUser(data);
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailed, payload: { isProceeding: false } });
            return;
        }

        dispatch({ type: Actions.RequestSuccess, payload: { removedUser: result.value, isProceeding: false } });
    },

    //newsletters groups
    getGroups: (searchValue, existedValue) => async (dispatch) => {

        dispatch({ type: Actions.SendRequest });

        var result = await AdministrationService.getGroupsQuery(searchValue, existedValue);
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailed });
            return;
        }

        var groups = result.value.items.map((item) => { return { key: item.id, value: item.name }; });

        dispatch({ type: Actions.RequestSuccess, payload: { groups: groups } });
    },

    getDepartments: () => async (dispatch) => {

        dispatch({ type: Actions.SendRequest });

        var result = await AdministrationService.getDepartments();
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailed });
            return;
        }

        var departments = result.value.map((item) => { return { key: item.filter, value: item.name }; });

        dispatch({ type: Actions.RequestSuccess, payload: { departments: departments } });
    },

    createGroups: (data) => async (dispatch) => {

        dispatch({ type: Actions.SendRequest });

        var result = await AdministrationService.createGroups(data);
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailed });
            return;
        }

        dispatch({ type: Actions.RequestSuccess, payload: { createdGroups: result.value } });
    },

    updateGroups: (data) => async (dispatch) => {

        dispatch({ type: Actions.SendRequest });

        var result = await AdministrationService.updateGroups(data);
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailed });
            return;
        }

        dispatch({ type: Actions.RequestSuccess, payload: { updatedGroups: result.value } });
    },

    removeGroups: (data) => async (dispatch) => {

        await AdministrationService.removeGroups(data);
    },

    loadRoles: () => async (dispatch) => {

        dispatch({ type: Actions.SendRequest, payload: { isLoading: true } });

        var result = await AdministrationService.getRoles();
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailed, payload: { isLoading: false } });
            return;
        }

        dispatch({ type: Actions.RequestSuccess, payload: { roles: result.value, isLoading: false } });
    },

    loadPermissions: () => async (dispatch) => {

        dispatch({ type: Actions.SendRequest });

        var result = await AdministrationService.getPermissions();
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailed });
            return;
        }

        dispatch({ type: Actions.RequestSuccess, payload: { permissions: result.value, isLoading: false } });
    },

    /*
    loadRolePermissions: (id: number) => async (dispatch) => {

        dispatch({ type: Actions.SendRequest, payload: { isPermissionsLoading: true } });

        var result = await AdministrationService.getRolePermissions(id);
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailed, payload: { isPermissionsLoading: false } });
            return;
        }

        dispatch({ type: Actions.RequestSuccess, payload: { rolePermissions: result.value, isPermissionsLoading: false } });
    },
    */

    loadUserPermissions: (id: number) => async (dispatch) => {
        dispatch({ type: Actions.SendRequest, payload: { isPermissionsLoading: true } });

        var result = await AdministrationService.getUserPermissions(id);
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailed, payload: { isPermissionsLoading: false } });
            return;
        }

        dispatch({ type: Actions.RequestSuccess, payload: { userPermissions: result.value, isPermissionsLoading: false } });
    },

    saveRole: (role: any) => async (dispatch) => {

        dispatch({ type: Actions.SendRequest, payload: { isProceeding: true } });

        var result = await AdministrationService.updateRole(role);
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailed, payload: { isProceeding: false } });
            return;
        }

        dispatch({
            type: Actions.RequestSuccess, payload: {
                updatedRole: result.value,
                isProceeding: false,

                //cleanup loaded roles/permissions
                roles: null,
                permissions: null
            }
        });
    },

    createRole: (roleName: string) => async (dispatch) => {

        dispatch({ type: Actions.SendRequest, payload: { isProceeding: true } });

        var result = await AdministrationService.createRole(roleName);
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailed, payload: { isProceeding: false } });
            return;
        }

        dispatch({
            type: Actions.RequestSuccess, payload: {
                createdRole: result.value,
                isProceeding: false,

                //cleanup loaded roles/permissions
                roles: null,
                permissions: null
            }
        });
    },

    /*
    addEmployeesRole: (users: any[]) => async (dispatch) => {
        dispatch({ type: Actions.SendRequest });

        var result = await AdministrationService.addEmployeesRole(users);
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailed });
            return;
        }

        dispatch({ type: Actions.RequestSuccess, payload: { addedUsersRole: result.value } });
    },

    removeEmployeesRole: (id: any[], role: any) => async (dispatch) => {
        dispatch({ type: Actions.SendRequest });

        var result = await AdministrationService.removeEmployeesRole(id, role);
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailed });
            return;
        }

        dispatch({ type: Actions.RequestSuccess, payload: { removedUsersRole: result.value } });
    }
    */
};

const initialState = {
    isLoading: false,
    isPermissionsLoading: false,
    isProceeding: false
};

export const reducer = (currentState, incomingAction) => {

    if (currentState === undefined)
        return initialState;

    const action = incomingAction;

    switch (action.type) {
        case Actions.Init:
            return initialState;
        case Actions.SendRequest:
            {
                return { ...currentState, ...action.payload };
            }
        case Actions.RequestFailed:
            {
                return { ...currentState, ...action.payload };
            }
        case Actions.RequestSuccess:
            {
                return { ...currentState, ...action.payload };
            }
        default:
            return currentState || initialState;
    }
};