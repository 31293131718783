export const Actions = {
    AddAlert: "ADD_DOWNLOAD",
    RemoveAlert: "REMOVE_DOWNLOAD"
    // SetTitle: "SET_NAVBAR_TITLE",
    // Init: "TOP_NAVBAR_INIT",
    // ShowSidebar: "TOP_NAVBAR_SHOW_SIDEBAR",
    // SidebarVisibilityChanged: "TOP_NAVBAR_SIDEBAR_VISIBILITY_CHANGED"
}

export const actionCreators = {

    addDownload: (link, color = null) => async (dispatch, getState) => {
        const id = Date.now()
        dispatch({ type: Actions.AddAlert, payload: { id, link, color } })
    },

    removeDownload: (id) => async (dispatch, getState) => {
        dispatch({ type: Actions.RemoveAlert, payload: id })
    },

    init: () => async (dispatch, getState) => {
        dispatch({ type: Actions.Init })
    },

}

const initialState =
{
    downloads: []
}

export const reducer = (currentState, incomingAction) => {

    if (currentState === undefined)
        return initialState;

    const action = incomingAction

    switch (action.type) {
        case Actions.Init:
            return initialState
            break

        case Actions.AddAlert:
            return {
                ...currentState,
                downloads: [...currentState?.downloads, action.payload]
            }
            break

        case Actions.RemoveAlert:
            return {
                ...currentState,
                downloads: currentState?.downloads.filter(download => download?.id !== action.payload)
            }
            break

        default:
            return currentState || initialState
    }
}
