import { useState, useEffect, useLayoutEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";

import { makeStyles } from '@mui/styles'
import { useTranslation } from "react-i18next";

import Localization from '@Components/Localization'

import { PoolProcessingStatus } from '@Components/Definitions'

import { actionCreators as actionSeciritization } from "../../../store/SecuritizationStore"
import Paper from '@mui/material/Paper'

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { TabPanelWithState } from '@Utilities'

import MaturityTab from './charts/MaturityTab'
import StartLTVTab from './charts/StartLTVTab'
import CurrentLTVTab from './charts/CurrentLTVTab'
import CurrentPTITab from './charts/CurrentPTITab'

import StatisticsTable from '@Components/shared/StatisticsTable'
import style from '@Styles/domrf-theme.module.scss'
import { Box, Grid, Stack, alpha } from '@mui/material'
import useLocalStorage from '../../../components/hook/useLocalStorage'
import CurrentRateTab from './charts/CurrentRateTab'
import PaymentDayTab from './charts/PaymentDayTab'
import { getNameAbbreviation } from '../../../Utilities'
import ProductsDistribution from './components/ProductsDistribution';
import RegionsDistribution from './components/RegionsDistribution';
import MortgageProgramsDistribution from './components/MortgageProgramsDistribution';
import IncomeConfirmedDistribution from './components/IncomeConfirmedDistribution';

const useStyles = makeStyles({
    globalRoot: {
        '& *': { userSelect: 'none !important' },
        flexDirection: 'row',
        margin: 0,
        position: 'absolute', left: 0, right: 0, top: 0, bottom: 0,
    },
    scrollablePanel: {
        position: 'absolute', left: '4px', right: 0, top: 0, bottom: 0,
        overflowY: 'auto',
        '&::-webkit-scrollbar': {

            width: '4px',
        },
        '&:hover': {
            '&::-webkit-scrollbar-track:disabled': {
                backgroundColor: '#0000',
            },
            '&::-webkit-scrollbar-thumb:disabled': {
                background: '#0000',
            },
            '&::-webkit-scrollbar-track': {
                backgroundColor: '#00000008',
            },
            '&::-webkit-scrollbar-thumb': {
                background: '#00000018',
            }
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'unset',
            borderRadius: '3px',
            backgroundColor: '#00000000',
            transition: 'all 0.3s ease'
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#0000',
            borderRadius: '3px',
            height: '4px'
        },
    },
    tabPanel: {
        flexGrow: 1,
        position: 'relative'
    },
    poolTabContaner: {
        margin: '0 3px',
        backgroundColor: 'transparent',
        minHeight: 'unset',
        '& .MuiTabs-indicator': {
            backgroundColor: 'transparent',
        }
    },
    poolTab: {
        fontSize: '14px',
        whiteSpace: 'nowrap',
        color: style.colorPrimary3_40,
        borderBottom: 'solid 1px #0002',
        padding: '4px 1rem',
        position: 'relative',
        overflow: 'visible',
        transition: 'all 0.2s ease-in-out',
        '&::after': {
            content: '""',
            position: 'absolute',
            right: '-1px',
            top: 0,
            bottom: 0,
            width: '1px',
            backgroundColor: '#0001',
        },
        '&:hover': { backgroundColor: '#0001', color: '#0008' },
        '&.Mui-disabled': {
            color: `${alpha(style.colorPrimary3, 0.15)} !important`,
        },
        '&.Mui-selected': {
            '&::after': {
                backgroundColor: '#0002',
            },
            backgroundColor: '#FFF',
            borderBottom: 'none',
            color: style.colorPrimary1,
            '&::before': {
                content: '""',
                position: 'absolute',
                left: '-1px',
                top: 0,
                bottom: 0,
                width: '1px',
                backgroundColor: '#0002',
            },
        },
        '&.MuiTab-root': {
            minHeight: '2rem',
            height: '2rem',
        }
    },
    chartsBlock: {
        height: '370px',
        overflow: 'hidden',
        position: 'relative',
    },
    chartsBlockData: {
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute', left: '2px', top: '2px', right: '2px', bottom: '2px',
        boxShadow: 'none',
    },
    distributionContainer: {
        position: 'relative',
    },
    distributionBlock: {
        position: 'relative',
        flexGrow: 1,
    },
    distributionBlockData: {
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute', left: 0, top: 0, right: 0, bottom: 0,
    },
});


const PoolStatisticsPage = ({ poolId = null }) => {

    const classes = useStyles()
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { operationLoading = null,
        selectedNode = null,
        preferentialDistribution = null,
        ...otherStore } = useSelector((state: any) => state.securitization)

    const [tabIndex, setTabIndex] = useLocalStorage(0, 'Securitization/PoolStatistics/ChartsTab') //useState(null)
    const [currentPool, setCurrentPool] = useState(null)
    let index = 0
    const rows = [
        [{ key: index++, name: t('ActualDate'), value: currentPool?.poolDate ? Localization.FormatDateStringLocale(currentPool?.poolDate) : '', tooltip: t('ActualDate_Info') },
        { key: index++, name: t('MortgagesCountShort'), value: Localization.FormatNumber(currentPool?.mortgagesCount, 0, 0), tooltip: t('MortgagesCount_Info') },
        { key: index++, name: t('CurrentMainDebt'), unit: 'rubSign', value: Localization.FormatNumber(currentPool?.currentMainDebt), tooltip: t('CurrentMainDebt_Info') },
        { key: index++, name: t('AverageMainDebt'), unit: 'rubSign', value: Localization.FormatNumber(currentPool?.averageMainDebt), tooltip: t('AverageMainDebt_Info') },
        { key: index++, separator: true },
        { key: index++, name: t('InitialWAC'), unit: 'percentInYear', grayscaledIfFull: true, value: Localization.FormatNumber(currentPool?.initialWAC), tooltip: t('InitialWAC_Info') },
        { key: index++, name: t('MinimalWAC'), unit: 'percentInYear', grayscaledIfFull: true, value: Localization.FormatNumber(currentPool?.minimalWAC), tooltip: t('MinimalWAC_Info') },
        { key: index++, name: t('CurrentWAC'), unit: 'percentInYear', value: Localization.FormatNumber(currentPool?.currentWAC), tooltip: t('CurrentWAC_Info') },
        { key: index++, name: t('LifetimeWAC'), unit: 'percentInYear', value: Localization.FormatNumber(currentPool?.lifetimeWAC), tooltip: t('LifetimeWAC_Info') },
        { key: index++, separator: true },
        { key: index++, name: t('WALAPlainShort'), unit: 'yearSign', value: Localization.FormatNumber(currentPool?.wala, 1, 1), tooltip: t('WALA_Info') },
        { key: index++, name: t('WAM'), unit: 'yearSign', value: Localization.FormatNumber(currentPool?.wam / 12.0, 1, 1), tooltip: t('WAM_Info_Full') },
        { key: index++, name: t('AdjustedWAMShort'), unit: 'yearSign', value: Localization.FormatNumber(currentPool?.adjustedWAM / 12.0, null, 1), tooltip: t('AdjustedWAM_Info') },
            // { key: index++, separator: true },
        ],
        [
            { key: index++, name: t('GovernProgramsFraction'), unit: 'percent', grayscaledIfFull: true, value: Localization.FormatNumber(currentPool?.governProgramsFraction), tooltip: t('GovernProgramsFraction_Info') },
            { key: index++, name: t('GovernProgramsKeyRateDeduction'), unit: 'percent', grayscaledIfFull: true, invertValueSign: true, value: Localization.FormatNumber(currentPool?.governProgramsKeyRateDeduction), tooltip: t('GovernProgramsKeyRateDeduction_Info') },
            // { key: index++, name: t('PreferenceFraction'), unit: 'percent', grayscaledIfFull: true, value: Localization.FormatNumber(currentPool?.preferenceFraction), tooltip: t('PreferenceFraction_Info') },
            // { key: index++, name: t('FamilyFraction'), unit: 'percent', grayscaledIfFull: true, value: Localization.FormatNumber(currentPool?.familyFraction), tooltip: t('FamilyFraction_Info') },
            // { key: index++, name: t('PreferenceKeyRateDeduction'), unit: 'percentInYear', grayscaledIfFull: true, invertValueSign: true, value: Localization.FormatNumber(currentPool?.preferenceKeyRateDeduction), tooltip: t('PreferenceKeyRateDeduction_Info') },
            // { key: index++, name: t('FamilyKeyRateDeduction'), unit: 'percentInYear', grayscaledIfFull: true, invertValueSign: true, value: Localization.FormatNumber(currentPool?.familyKeyRateDeduction), tooltip: t('FamilyKeyRateDeduction_Info') },
            { key: index++, separator: true },
            { key: index++, name: t('StartLTV_short'), unit: 'percent', grayscaledIfFull: true, value: Localization.FormatNumber(currentPool?.startLTV, 1, 1), tooltip: t('StartLTV_Info') },
            { key: index++, name: t('CurrentLTV_short'), unit: 'percent', grayscaledIfFull: true, value: Localization.FormatNumber(currentPool?.currentLTV, 1, 1), tooltip: t('CurrentLTV_Info') },
            { key: index++, name: t('StartPTI_short'), unit: 'percent', grayscaledIfFull: true, value: Localization.FormatNumber(currentPool?.startPTI, 1, 1), tooltip: t('StartPTI_Info') },
            { key: index++, name: t('CurrentPTI_short'), unit: 'percent', grayscaledIfFull: true, value: Localization.FormatNumber(currentPool?.currentPTI, 1, 1), tooltip: t('CurrentPTI_Info') },
            { key: index++, separator: true },
            { key: index++, name: t('LoadEmployee'), grayscaled: true, value: getNameAbbreviation(currentPool?.user?.userName ?? t("Unknown")) /*, tooltip: t('UploadEmployee_Info')*/ },
            { key: index++, name: t('UploadDate'), grayscaled: true, value: currentPool?.createdDate ? Localization.FormatDateLocale(new Date(currentPool?.createdDate)) : '' /*, tooltip: t('UploadTime_Info')*/ },
            {
                key: index++, name: t('UploadTime'), grayscaled: true, value: currentPool?.createdDate ? Localization.FormatTimeLocale(new Date(currentPool?.createdDate)) : ''/*, tooltip: t('UploadTime_Info')*/
            },
            { key: index++, name: t('PoolIDatSystem'), grayscaled: true, value: currentPool?.id /*, tooltip: t('PoolID_Info_Info')*/ }
        ],
    ]

    const selectNotDisabledTab = (index) => {
        if (currentPool == null || index < 0)
            return

        switch (index) {
            case 2: if (currentPool?.startLTV == null) { selectNotDisabledTab(--index); return }
            case 3: if (currentPool?.currentLTV == null) { selectNotDisabledTab(--index); return }
            case 4: if (currentPool?.currentPTI == null) { selectNotDisabledTab(--index); return }
        }

        setTabIndex(index)
    }

    useLayoutEffect(() => {
        selectNotDisabledTab(tabIndex)
    }, [currentPool])

    useEffect(() => {
        window.addEventListener("unload", (e) => setTabIndex(0)) //инициализация LocalStorage - Securitization/PoolStatistics/ChartsTab пред закрытием браузера
    }, [])

    useLayoutEffect(() => {
        if (Array.isArray(selectedNode?.node?.pools) && selectedNode?.node?.pools.length && selectedNode?.node?.pools[0].id != currentPool?.id) {
            actionSeciritization.getPoolPreferentialMortgageDistribution(poolId)(dispatch)
            setCurrentPool(selectedNode?.node?.pools[0])
        }
    }, [selectedNode])

    return (<>
        {/* {(currentPool?.id != null) && ( */}
        <Box className={classes.globalRoot}>
            <Box className={classes.scrollablePanel}>
                <Stack className={classes.chartsBlock}>
                    <Paper className={classes.chartsBlockData}>
                        {
                            (currentPool?.status == PoolProcessingStatus.STATUS_SUCCESS ||
                                currentPool?.status == PoolProcessingStatus.STATUS_ARCHIVED) && (
                                <>
                                    <Tabs
                                        className={classes.poolTabContaner}
                                        value={tabIndex}
                                        onChange={(e, v) => setTabIndex(v)}
                                        variant="fullWidth"
                                    >
                                        <Tab className={classes.poolTab} label={t('CurrentMortgageRateTab')} />
                                        <Tab className={classes.poolTab} label={t('MaturityTab')} />
                                        <Tab className={classes.poolTab} label={t('StartLTVTab')} disabled={currentPool?.startLTV ? false : true} />
                                        <Tab className={classes.poolTab} label={t('CurrentLTVTab')} disabled={currentPool?.currentLTV ? false : true} />
                                        <Tab className={classes.poolTab} label={t('CurrentPTITab')} disabled={currentPool?.currentPTI ? false : true} />
                                        <Tab className={classes.poolTab} label={t('PaymenDayTab')} />
                                    </Tabs>

                                    <TabPanelWithState className={classes.tabPanel} value={tabIndex} index={0}>
                                        <CurrentRateTab poolId={currentPool?.id} />
                                    </TabPanelWithState>
                                    <TabPanelWithState className={classes.tabPanel} value={tabIndex} index={1}>
                                        <MaturityTab poolId={currentPool?.id} />
                                    </TabPanelWithState>
                                    <TabPanelWithState className={classes.tabPanel} value={tabIndex} index={2}>
                                        <StartLTVTab poolId={currentPool?.id} />
                                    </TabPanelWithState>
                                    <TabPanelWithState className={classes.tabPanel} value={tabIndex} index={3}>
                                        <CurrentLTVTab poolId={currentPool?.id} />
                                    </TabPanelWithState>
                                    <TabPanelWithState className={classes.tabPanel} value={tabIndex} index={4}>
                                        <CurrentPTITab poolId={currentPool?.id} />
                                    </TabPanelWithState>
                                    <TabPanelWithState className={classes.tabPanel} value={tabIndex} index={5}>
                                        <PaymentDayTab poolId={currentPool?.id} />
                                    </TabPanelWithState>
                                </>
                            )
                        }
                    </Paper>
                </Stack>
                <Stack /*className={classes.statisticBlock}*/>
                    {/* {operationLoading ? <SpinnerBars withBackground /> : */}
                    <StatisticsTable poolId={currentPool?.id} rows={rows} />
                    {/* } */}
                </Stack>
                <Stack>
                    <Grid container className={classes.distributionContainer}>
                        <Grid xs={6} item className={classes.distributionBlock}>
                            <ProductsDistribution poolId={currentPool?.id} />
                        </Grid>
                        {Boolean(preferentialDistribution?.distribution?.filter(x => x?.preferentialType != null).length) &&
                            <Grid xs={6} item className={classes.distributionBlock}>
                                <MortgageProgramsDistribution poolId={currentPool?.id} />
                            </Grid>}
                        <Grid xs={6} item className={classes.distributionBlock}>
                            <RegionsDistribution poolId={currentPool?.id} />
                        </Grid>
                        <Grid xs={6} item className={classes.distributionBlock}>
                            <IncomeConfirmedDistribution poolId={currentPool?.id} />
                        </Grid>
                    </Grid>
                </Stack>
            </Box>
        </Box>
        {/* )} */}
    </>
    )
}

export default PoolStatisticsPage;