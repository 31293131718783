export enum MortgageType {
    UNKNOWN = 0,
    PREFERENTIAL = 1,
    FAMILY = 2,
    FAMILYDFO = 3,
    DFO = 4,
    IT = 5,
    OTHER = 6
}

export enum ProductsType {
    UNKNOWN = 0,
    PRIMARY = 1,
    SECONDARY = 2,
    REFINANCING = 3,
    OTHER = 4
}
export enum ProcessingState {

    Undefined = 0,
    Request = 1,
    Response_Success = 2,
    Response_Failed = 3
};

export enum CouponType {
    TYPE_UNKNOWN = 0,
    TYPE_FIXED = 1,
    TYPE_FLOAT = 2,
    TYPE_AHML = 3,
    TYPE_THIRD = 4,
    TYPE_LOSSES = 5,
    TYPE_KEYRATE_AND_PREMIUM = 6,
};

export enum ProcessingStatus {
    STATUS_UNKNOWN = 0,
    STATUS_AWAITING = 1,
    STATUS_PROCESSING = 2,
    STATUS_READY = 3,
    STATUS_FAILED = 4,
    STATUS_SENDING = 5,
    STATUS_SENT = 6
};

/// <summary>Фиксация параметра</summary>
export enum CalculationType {
    /// <summary>Неопределённый</summary>
    TYPE_UNKNOWN = 0,
    /// <summary>OAS</summary>
    TYPE_OAS,
    /// <summary>Чистая или грязная цена в зависимости от ставки</summary>
    TYPE_PRICE,
    /// <summary>Z-спред</summary>
    TYPE_Z_SPREAD,
    /// <summary>G-спред</summary>
    TYPE_G_SPREAD,
};

/// <summary>Фиксация параметра</summary>
export enum CalculationTypeV2 {
    /// <summary>Неопределённый</summary>
    TYPE_UNKNOWN = 0,
    /// <summary>Z-спред</summary>
    TYPE_Z_SPREAD,
    /// <summary>G-спред</summary>
    TYPE_G_SPREAD,
    /// <summary>Грязная цена в зависимости от ставки</summary>
    TYPE_DIRTY_PRICE,
    /// <summary>Чистая цена в зависимости от ставки</summary>
    TYPE_CLEAN_PRICE,
    /// <summary>надбавка</summary>
    TYPE_PREMIUM,
    /// <summary>ставка купона</summary>
    TYPE_COUPON_RATE
};

export enum MessageType {
    TYPE_UNKNOWN = 0,
    TYPE_INFORMATIONAL = 1,
    TYPE_WARNING = 2,
    TYPE_ERROR = 3
};

export enum EntityType {
    TYPE_UNKNOWN = 0,
    TYPE_PORTFOLIO = 1,
    TYPE_CALCULATION = 2,
    TYPE_POOL = 3
};

export enum BondStatus {
    STATUS_UNKNOWN = 0,
    STATUS_NOT_ISSUED = 1,
    STATUS_PLACEMENT = 2,
    STATUS_ISSUED = 3,
    STATUS_REDEEMED = 4
};

export enum FlowTypes {
    MODEL = 0,
    HISTORY = 1,
    USED_HISTORY = 2
};

export enum CouponTypeStr {
    UNKNOWN = '',
    FIXED = 'Fixed',
    FLOATING = 'Floating',
    KEYRATE_AND_PREMIUM = 'Keyrate'
}

export enum DealType {
    TYPE_UNKNOWN = 0,
    TYPE_BOND_PUBLISH = 1,
    TYPE_BOND_AT_BALANCE = 2,
    TYPE_POOL_BUYOUT = 3
}

export enum PoolProcessingStatus {
    STATUS_UNKNOWN = 0,
    STATUS_LOADED = 1,
    STATUS_PROCESSING = 2,
    STATUS_UPLOADING_AT_DB = 3,
    STATUS_UPLOADED_AT_DB = 4,
    STATUS_SENDING = 5,
    STATUS_SENT = 6,
    //STATUS_VALIDATION_FAILED = 7, not used
    STATUS_ATTENTION_REQUIRED = 8,
    STATUS_SUCCESS = 9,
    STATUS_FAILED = 10,
    STATUS_ARCHIVED = 11,
    STATUS_REMOVED = 12
}

export enum IterationStatus {
    STATUS_UNKNOWN = 0,
    STATUS_PROCESSING = 1,
    STATUS_SERVICE_PROCESSING = 2,
    STATUS_SUCCESS = 3,
    STATUS_FAILED = 4
}

export enum eDealStatus {
    STATUS_UNKNOWN = 0,
    STATUS_NORMAL = 1,
    STATUS_FINISHED = 2,
    STATUS_REMOVED = 3,
    STATUS_ARCHIVED = 4
}

export enum efDealDataState {
    STATE_NONE = 0,
    STATE_KEYRATE_CHART_DATA_REQUIRED = 1 //some of pools has a preferential credits, we need to get keyrate data
}

export enum efDealValidationState {
    STATE_VALID = 0,
    STATE_DEAL_PARAMETERS_INVALID = 1,
    STATE_HISTORICAL_KEYRATE_OUTDATED = 2,
    STATE_KEYRATE_REQUIRED = 4 //no data available for calculation
}

export enum efNodeValidationState {
    STATE_VALID = 0,
    STATE_NODE_PARAMETERS_INVALID = 1,
    STATE_ITERATION_OUTDATED = 2, //curent node iteration doesn't fit to deal parameters
}

//CHECKME!!! remove from code!
export enum IterationValidationState {
    STATE_VALID = 0,
    STATE_PARAMETERS_INVALID = 1,
    STATE_POOL_INVALID = 2,
    STATE_GUARANTEE_INVALID = 4,
    STATE_PRICING_PARAMETERS_INVALID = 8
}

export enum SwapType {
    TYPE_UNKNOWN = 0,
    TYPE_NETTING_BEFORE_COUPON_PAYMENT = 1,
    TYPE_NETTING_AFTER_COUPON_PAYMENT = 2
}

export enum EmployeeRole {
    ROLE_SECURITIZATION_DEAL_MANAGER = 1
}

export enum SecurityObject {
    OBJECT_SECURITIZATION_DEAL = 1,
}

//CHECKME!!! sync with C# Permissions enum if changes were made
export enum Permissions {

    PERMISSION_UNDEFINED = 0,
    PERMISSION_ORIGINATORS_VIEW = 1,

    PERMISSION_BONDS_VIEW = 2,

    PERMISSION_PORTFOLIOS_VIEW = 3,

    PERMISSION_WIKI_VIEW = 4,

    PERMISSION_CONVENTION_CALCULATOR_VIEW = 5,

    PERMISSION_POOL_STATISTICS_VIEW = 6,

    PERMISSION_QUOTES_VIEW = 7,

    PERMISSION_CALCULATOR_VIEW = 8, //�������� ������

    PERMISSION_ADMINISTRATION_VIEW = 9,
    PERMISSION_ADMINISTRATION_DASHBOARD_VIEW = 10,
    PERMISSION_ADMINISTRATION_DATASOURCES_VIEW = 11,
    PERMISSION_ADMINISTRATION_USERS_VIEW = 12,
    PERMISSION_ADMINISTRATION_ROLES_VIEW = 13,
    PERMISSION_ADMINISTRATION_NEWSLETTERS_VIEW = 14,

    PERMISSION_SECURITIZATION_VIEW = 15,
    PERMISSION_SECURITIZATION_DASHBOARD_VIEW = 16,
    PERMISSION_SECURITIZATION_EDIT = 17,
    PERMISSION_SECURITIZATION_ADMIN = 18
}

export enum ePoolNodeType {
    TYPE_UNKNOWN = 0,
    TYPE_POOL_PLAIN = 1,
    TYPE_POOL_AT_REGISTRATION_DATE = 2,
    TYPE_POOL_AT_TRANSFER_DATE = 3
}

export enum ePoolNodeStatus {
    STATUS_UNKNOWN = 0,
    STATUS_NORMAL = 1,
    STATUS_REMOVED = 2
}

export enum ePreferentialProgram {
    PROGRAM_NONE = 0,
    PROGRAM_PREFERENTIAL = 1,
    PROGRAM_FAMILY = 2,
    PROGRAM_FAMILY_FAREAST = 3,
    PROGRAM_FAREAST = 4,
    PROGRAM_IT = 5
}
