import { alpha, Box, Container, Stack, Button, Theme, TextField } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { ValidatorForm, TextValidator } from "@Framework/components/Form";

import React, { useState, useEffect, useRef } from "react";

const useStyles = makeStyles((theme: Theme) => createStyles({
    formBox: {
        maxWidth: '300px',
        paddingBottom: '1rem'
    }
}))

const FormTestPage = () => {

    const classes = useStyles();

    const [first, setFirst] = useState('');
    const [second, setSecond] = useState('');
    const [third, setThird] = useState('');
    const formRef = useRef();

    useEffect(() => {

    }, []);


    const handleFormSubmit = () => {

    }

    const validateForm = () => {
        formRef.current.isFormValid(false, true);
    }

    const validateFormFields = (fields) => {
        console.log('Global validation rules called', fields);

        if (fields.first !== fields.second) {
            return [{ name: 'first', message: 'Значения должны совпадать' }, { name: 'second', message: 'Значения должны совпадать' }];
        }

        return null;
    }

    const onFormStateChanged = (isValid: boolean) => {
        console.log(`Form state changed, isValid: ${isValid}`);
    }

    const onFieldChanged = (name: string, value: any, isValid: boolean) => {
        console.log(`Field changed, name: ${name}, value: ${value}, isValid: ${isValid}`);
    }

    return (

        <Container>
            <div className={classes.formBox}>
                <ValidatorForm
                    ref={formRef}
                    onSubmit={handleFormSubmit}
                    validators={[validateFormFields]}
                    onFieldChanged={onFieldChanged}
                    onFormStateChanged={onFormStateChanged}
                >
                    <TextValidator
                        label="first"
                        name="first"
                        id="first"
                        fullWidth={true}
                        onChange={(event) => setFirst(event.target.value)}
                        value={first}
                        validators={['required']}
                        errorMessages={['Required']} />
                    <TextValidator
                        label="second"
                        name="second"
                        id="second"
                        fullWidth={true}
                        onChange={(event) => setSecond(event.target.value)}
                        value={second}
                        validators={['required']}
                        errorMessages={['Required']} />
                    <TextValidator
                        label="third"
                        name="third"
                        id="third"
                        fullWidth={true}
                        onChange={(event) => setThird(event.target.value)}
                        value={third}
                        validators={['required']}
                        errorMessages={['Required']} />

                </ValidatorForm>
            </div>
            <div>
                <Stack spacing={2} direction="row">
                    <Button variant="contained" onClick={validateForm}>Validate</Button>
                </Stack>
            </div>
        </Container>
    );
}

export default FormTestPage;