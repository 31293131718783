/* eslint-disable */
import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
//import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';
import { Button, DialogActions, IconButton, InputAdornment, Popper, Tooltip } from "@mui/material";
// import EventIcon from '@mui/icons-material/Event';
import { useTranslation } from "react-i18next";
import clsx from 'clsx';

/* eslint-enable */
import TooltipInfoLabel from '../../../components/shared/TooltipInfoLabel';
import ErrorTooltip from '../../../components/shared/ErrorTooltip';

import { makeStyles } from '@mui/styles';
import { TodayOutlined } from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers';
import '@mui/lab';
import { PickersActionBarProps } from '@mui/x-date-pickers/PickersActionBar';
import style from '@Styles/domrf-theme.module.scss';
import DateInputPicker from './DateInputPicker';
import Localization from '../../../components/Localization';


const useStyles = makeStyles({
    dateRoot: {
        userSelect: 'none',
    },
    error: {
        backgroundColor: 'rgb(255,237,237) !important',
    },
    errorPopper: {
        backgroundColor: style.colorPrimary3,
        maxWidth: '300px',
        color: '#FFF',
        fontSize: '1rem',
        padding: '3px 8px',
        borderRadius: '4px',
        margin: '0 4px !important',
        zIndex: '+2'
    },
    tooltip: {
        display: 'flex',
        alignItems: 'stretch',
        minHeight: '32px',
        width: '100%'
    }
});

const DatePickerStandaloneValidator = ({
    name,
    isValid,
    errorMessage = null,
    helperText,
    tooltip,
    inputClassName,
    readOnly,
    //disabled,
    value,
    onChange,
    cancelLabel,
    okLabel,
    todayLabel,
    tReady,
    fullWidth,
    extraButtonsProps,
    InputProps,
    mask,
    disabled,
    minDate = null,
    maxDate = null,
    mobile = true,
    disableCloseOnSelect = true,
    hideValidationIcon = false,
    disableErrorHighlight = false,
    displayPart = null,
    placeholder,

    ...rest
}) => {

    const classes = useStyles();
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [dateValue, setDateValue] = useState(typeof (value) == 'string' ? new Date(value) : value);
    const [dateInputValue, setDateInputValue] = useState(value);
    const [localValidation, setLocalValidation] = useState(true)
    const elRef = useRef()

    useEffect(() => {
        // console.log('value', typeof (value) )
        if (value == null || value === '') {
            setDateValue(null);
            setDateInputValue('');
        }
        else {
            setDateValue(typeof (value) == 'string' ? new Date(value) : value);
            setDateInputValue(typeof (value) == 'string' ? value : Localization.FormatDateISO(value));
        }
    }, [value]);

    const showDatePicker = (evt) => {
        setIsOpen(true);
    }

    const handleDateChange = (newValue) => {

        setDateValue(newValue);
    }

    const handleInputChange = (event) => {
        // const parcedDate = parse(event.target.value, format, new Date())
        // if(parcedDate !== 'Invalid Date')
        // console.log('event.target.value', event.target.value)
        setDateInputValue(event.target.value);
    }

    const handleDateAccept = (newValue) => {
        if (newValue == value)
            return
        // console.log('newValue', newValue)
        if ((onChange == null || newValue?.toString() === 'Invalid Date')
        ) {
            setLocalValidation(false)
            return
        }

        setLocalValidation(true)
        onChange(newValue);
        // console.log('blur')
        // setTimeout(() => {
        //     document?.activeElement?.blur()    
        // }, 500);

    }

    const MyActionBar = ({
        onAccept,
        onCancel,
        onClear,
        onSetToday,
    }: PickersActionBarProps) => {

        return (
            <DialogActions>
                <Button onClick={onCancel}> {cancelLabel || t('Cancel')} </Button>
                <Button onClick={onAccept}> {okLabel || t('OK')} </Button>
            </DialogActions>
        );
    };

    const checkDates = (day) => {
        const curTime = new Date(day).getTime()
        const minTime = minDate != null ? new Date(minDate)?.getTime() : curTime
        const maxTime = maxDate != null ? new Date(maxDate)?.getTime() : curTime
        // console.log(name, minDate, maxDate , curTime,  minTime, maxTime)
        return !(curTime => minTime && curTime <= maxTime)
    }

    return (
        <>
            <TooltipInfoLabel className={classes.tooltip} content={tooltip}>
                {mobile ?
                    <MobileDatePicker
                        name={name}
                        //views={['date']}
                        disableCloseOnSelect={disableCloseOnSelect}
                        showTodayButton={true}
                        value={dateValue}
                        onChange={handleDateChange}
                        onAccept={handleDateAccept}
                        components={{
                            ActionBar: MyActionBar
                        }}
                        // cancelText={cancelLabel || t('Cancel')}
                        // okText={okLabel || t('OK')}
                        todayText={todayLabel || t('Today')}
                        //variant="inline"
                        //margin="none"
                        open={isOpen}
                        onOpen={() => (!!readOnly) ? {} : setIsOpen(true)}
                        onClose={() => (!!readOnly) ? {} : setIsOpen(false)}
                        mask={mask}
                        minDate={minDate}
                        maxDate={maxDate}
                        shouldDisableDate={checkDates}
                        shouldDisableMonth={checkDates}
                        // shouldDisableYear={checkDates}
                        //format={format}
                        fullWidth={fullWidth}
                        disabled={disabled}
                        renderInput={(params) =>
                            <Tooltip
                                disableInteractive
                                title={errorMessage}
                                placement="right-start"
                                classes={{ tooltip: classes.errorPopper }}
                            >
                                <TextField
                                    {...params}
                                    ref={elRef}
                                    value={dateInputValue}
                                    onChange={handleInputChange}
                                    fullWidth={fullWidth}
                                    variant="standard"
                                    error={!disableErrorHighlight && !isValid}
                                    helperText={helperText}
                                    className={[inputClassName, (hideValidationIcon == true && !isValid) && classes.error].join(' ')}
                                    InputProps={{
                                        readOnly: !!readOnly,
                                        endAdornment: (
                                            <InputAdornment position="end">

                                                {InputProps?.endAdornment}

                                                {extraButtonsProps}

                                                <IconButton disabled={params.disabled || (!!readOnly)} onClick={showDatePicker} size="small">
                                                    <TodayOutlined />
                                                </IconButton>

                                                {
                                                    (!disableErrorHighlight && hideValidationIcon == false && !isValid) && (
                                                        <InputAdornment position="end">
                                                            <ErrorTooltip content={errorMessage} />
                                                        </InputAdornment>
                                                    )
                                                }

                                            </InputAdornment>
                                        )
                                    }}
                                /></Tooltip>}
                        {...rest} />
                    :
                    <DatePicker
                        name={name}
                        disableCloseOnSelect={disableCloseOnSelect}
                        showTodayButton={true}
                        value={dateValue}
                        onChange={handleDateChange}
                        onAccept={handleDateAccept}
                        todayText={todayLabel || t('Today')}
                        components={{
                            ActionBar: disableCloseOnSelect == true ? MyActionBar : null
                        }}
                        //variant="inline"
                        //margin="none"
                        open={isOpen}
                        onOpen={() => (!!readOnly) ? {} : setIsOpen(true)}
                        onClose={() => (!!readOnly) ? {} : setIsOpen(false)}
                        mask={mask}
                        minDate={minDate}
                        maxDate={maxDate}
                        shouldDisableDate={checkDates}
                        shouldDisableMonth={checkDates}
                        // shouldDisableYear={checkDates}
                        fullWidth={fullWidth}
                        disabled={disabled}
                        PopperProps={{ anchorEl: elRef.current }}
                        classes={{ root: classes.dateRoot }}
                        ref={elRef}
                        renderInput={(params) =>
                            <>
                                {/* {console.log('params', rest.allowKeyboardControl)} */}
                                <DateInputPicker
                                    {...params}
                                    errorMessage={errorMessage}
                                    name={name}
                                    open={isOpen}
                                    showDatePicker={showDatePicker}
                                    onChange={handleDateAccept}
                                    placeholder={placeholder ?? Localization.ShortDatePattern()}// "dd.MM.yyyy"
                                    mask={mask ?? Localization.DateMask()}
                                    value={Localization.FormatDateStringLocale(dateInputValue)}
                                    variant="standard"
                                    error={!disableErrorHighlight && !(isValid && localValidation)}
                                    className={[inputClassName, (hideValidationIcon == true && !isValid) ? classes.error : ''].join(' ')}
                                    InputProps={{

                                        readOnly: !!readOnly,
                                        endAdornment: (
                                            <InputAdornment position="end" sx={{ m: 0 }}>

                                                {InputProps?.endAdornment}

                                                {extraButtonsProps}

                                                <IconButton
                                                    size="small"
                                                    tabIndex={rest?.allowKeyboardControl ? -1 : 0}
                                                    disabled={params.disabled || (!!readOnly)}
                                                    onClick={(e) => { document.activeElement.blur(); showDatePicker(e) }}>
                                                    <TodayOutlined />
                                                </IconButton>

                                                {
                                                    (!disableErrorHighlight && hideValidationIcon == false && !isValid) && (
                                                        <InputAdornment position="end">
                                                            <ErrorTooltip content={errorMessage} placement="left-start" />
                                                        </InputAdornment>
                                                    )
                                                }

                                            </InputAdornment>
                                        )
                                    }}
                                    {...rest}

                                />
                            </>
                        }
                        {...rest} />
                }
            </TooltipInfoLabel>
        </>
    );
}

export default DatePickerStandaloneValidator;