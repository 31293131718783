import { Button, CircularProgress, alpha } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles';
import style from '@Styles/domrf-theme.module.scss'
import { useSelector } from 'react-redux';


const useStyles = makeStyles(() => createStyles({
    loader: {
        position: 'absolute',
        color: `${alpha(style.colorPrimary3, 0.3)}`
    },
}))

function CustomButton({ children, progress, progressProps, ...otherProps }) {
    const classes = useStyles()
    const { ...otherLoaders } = useSelector((state) => state.loader);
    const loading = otherLoaders[progress]

    return (
        <Button {...otherProps}
            disabled={loading}
        >
            {children}
            {loading == true ? <CircularProgress className={classes.loader} {...progressProps} /> : <></>}
        </Button>
    )
}

export default CustomButton