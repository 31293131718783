import useLocalStorage from '@Base/components/hook/useLocalStorage'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import style from '@Styles/domrf-theme.module.scss'
import { actionCreators as actionDomRf } from '@Base/store/DomRfStore'
import { format } from 'date-fns'
import { useDispatch } from 'react-redux'
import i18next, { t } from 'i18next'
import { Box, IconButton, Tooltip, alpha } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { CheckBox, CheckBoxOutlineBlank, Clear, FilterAlt, RemoveDone } from '@mui/icons-material'


const useStyles = makeStyles({
  fieldItem: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    cursor: 'pointer',
    width: '100%',
    padding: '4px 0',
    '&:hover': {
      backgroundColor: alpha(style.colorPrimary3, 0.2)
    }
  },
  fieldName: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    flexGrow: 1,
    paddingRight: '5px',
  },
  ckeckboxIcon: {
    color: style.colorPrimary3_60,
    marginLeft: '5px',
    marginRight: '5px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    color: style.colorPrimary3_60,
    borderBottom: `solid 1px ${style.colorPrimary3_60}`,
    height: '1.5rem',
  }
})
const CompareFieldList = () => {
  const cls = useStyles()
  const dispatch = useDispatch()
  const {
    calculations: calculationData,
    currentDateRange,
    ...otherStore
  } = useSelector((state) => state.domrf)
  const [selectedFields, setSelectedFields] = useLocalStorage(new Array(), 'quotes/selectedFields')
  const [showOnlyMainFields, setShowOnlyMainFields] = useLocalStorage(false, 'quotes/showOnlyMainFields')
  // const [currentDateRange, setCurrentDateRange] = useLocalStorage(7, 'quotes.dateSelector.value')
  const [fieldList, setFieldList] = useState(null)

  // console.log('showOnlyMainFields', showOnlyMainFields)
  const fullFieldList = [
    "gSpread",
    "zeroVolOAS",
    "oasDifference",
    "spreadOAS",
    // "calculationID",
    // "zcycDate",
    // "evaluationDate",
    "currentNominal",
    "convZSpread",
    "dirtyPrice",
    "clearPrice",
    "ytm",
    "macaulayDuration",
    "effectiveDuration",
    "effectiveDurationSensitivityUp",
    "effectiveDurationSensitivityDown",
    "wac",
    "wala",
    "wam",
    "cprModel",
    "cprHist",
    "cdrModel",
    "cdrHist",
    "convZSpreadHist",
    "convIRRHist",
    "convMacDurationHist",
    "convPriceHist",
    "rate",
    "expenses",
    "swapMortgageAgentPV",
    "swapOriginatorPV",
    "swapMortgageAgentPVRel",
    "swapOriginatorPVRel",
    "cprSensitivityM",
    "cprSensitivityP",
    // "dealIndex"
  ]

  const secondaryFields = [
    "rate",
    "expenses",
    "swapMortgageAgentPV",
    "swapOriginatorPV",
    "swapMortgageAgentPVRel",
    "swapOriginatorPVRel",
    "cprSensitivityP",
    "cprSensitivityM",
  ]
  // console.log('currentDateRange', currentDateRange)
  useEffect(() => {
    if (!currentDateRange)
      return

    const dateTo = format(Date.now() - 24 * 60 * 60 * 1000, "yyyy-MM-dd")
    const dateFrom = format(Date.now() - (currentDateRange + 1) * 24 * 60 * 60 * 1000, "yyyy-MM-dd")
    dispatch(actionDomRf.getSystemCalculationsRange(dateFrom, dateTo))

  }, [currentDateRange])

  useEffect(() => {
    setFieldList(fullFieldList
      ?.filter(field => {
        // console.log('----------------------<', field)
        return showOnlyMainFields ? !secondaryFields.includes(field) : true
      }
      ))
  }, [showOnlyMainFields])

  // useEffect(() => {
  //   if (!calculationData?.length)
  //     return
  //   // console.log('Object.keys(calculationData[0])', Object.keys(calculationData[0]))
  //   setFieldList(Object.keys(calculationData[0])
  //     ?.filter(field => Array.isArray(calculationData[0][field]))
  //     ?.filter(field => {
  //       // console.log('----------------------<', field)
  //       return showOnlyMainFields ? !secondaryFields.includes(field) : true
  //     }
  //     ))
  // }, [calculationData, showOnlyMainFields])

  // console.log('fieldList', fieldList)
  useEffect(() => {
    dispatch(actionDomRf.setData({ selectedFields: selectedFields }))
  }, [selectedFields])

  const getFieldName = (key) => {
    const keyLink = `quotes.${key}`
    if (i18next.exists(keyLink))
      return (t(keyLink))

    return null
  }

  const handleFieldClick = (key) => {
    const newSelectedFields = [...selectedFields ?? []]
    const index = selectedFields?.indexOf(key)
    if (index == -1) {
      newSelectedFields.push(key)
    }
    else {
      newSelectedFields.splice(index, 1)
    }
    setSelectedFields(newSelectedFields)
  }

  // console.log('calculationData', calculationData, otherStore)

  const clearFieldsHandler = () => {
    //localStorage.removeItem('quotes/selectedFields')
    setSelectedFields(new Array())
  }

  const filterAltHandler = () => {
    setShowOnlyMainFields(!showOnlyMainFields)
  }

  return (
    <Box>
      <Box className={cls.header}>
        <Box>
          {t('quotes.fieldHeaderTitle')}
        </Box>
        <Box>
          <Tooltip title={t("quotes.filterAltTooltip")}>
            <IconButton size="small" onClick={filterAltHandler}>
              <FilterAlt color={showOnlyMainFields ? 'secondary' : style.colorPrimary3} />
            </IconButton>
          </Tooltip>
          <Tooltip title={t("quotes.clearAllSelectedFieldsTooltip")}>
            <IconButton size="small" onClick={clearFieldsHandler}>
              <RemoveDone />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      {Boolean(fieldList != null) &&
        fieldList
          ?.map(key => {
            const name = getFieldName(key)
            if (name == null)
              return

            return (<Box
              key={`fieldKey_${key}`}
              onClick={() => { handleFieldClick(key) }}
              className={cls.fieldItem}
            >
              {selectedFields?.some(field => field == key) ? <CheckBox className={cls.ckeckboxIcon} /> : <CheckBoxOutlineBlank className={cls.ckeckboxIcon} />}
              <Box className={cls.fieldName}>{name}</Box>
            </Box>)
          }
          )}
    </Box>
  )
}

export default CompareFieldList