import "@Styles/pages/LoginPage.scss";

import React, { useState, useEffect } from "react";
import { actionCreators } from "@Store/AccountStore";
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { useAuth, hasAuthParams } from "react-oidc-context";

const SilentLoginPage = () => {

    const dispatch = useDispatch();
    const auth = useAuth();

    const [searchParams, setSearchParams] = useSearchParams();
    const [backUrl, setBackUrl] = useState("/");
    const [hasTriedSignin, setHasTriedSignin] = useState(false);

    useEffect(() => {

        if (searchParams.get('back_url') != null)
            setBackUrl(searchParams.get('back_url'));

        actionCreators.init()(dispatch);

    }, []);

    React.useEffect(() => {

        if (!hasAuthParams() &&
            !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading &&
            !hasTriedSignin
        ) {
            setHasTriedSignin(true);
            auth.signinRedirect();
        }
    }, [auth, hasTriedSignin]);

    return (
        <div/>
    );
}

export default SilentLoginPage;