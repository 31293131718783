import React, { useRef, useState, forwardRef, useImperativeHandle } from 'react';
import { Button, Paper, FormGroup } from "@mui/material";

// import { PageLogo } from "@Components/shared/PageLogo";
import { ValidatorForm, TextValidator } from "@Framework/components/Form";
// import Localization from '@Components/Localization';
import { useTranslation } from "react-i18next";

import { makeStyles, createStyles } from '@mui/styles';
import { Theme, Container } from '@mui/material';
import clsx from 'clsx';
import style from '@Styles/domrf-theme.module.scss';

const useStyles = makeStyles((theme: Theme) => createStyles({
    pb3: {
        paddingBottom: '1rem'
    },
    paperBody: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '0 1rem 1rem 1rem'
    },
    paperFooter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 1rem 1rem 1rem'
        //border - top: 0; //1px solid #dee2e6;
    },
    displayNone: {
        display: 'none'
    }
}));

const PasswordChangeForm = forwardRef(({ onSubmit }, ref) => {

    const classes = useStyles();
    const { t, i18n } = useTranslation();

    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const submitButton = useRef(null);

    useImperativeHandle(ref, () => ({
        reset: reset,
    }));

    const handleSubmit = (event) => {
        onSubmit(event, password);
    }

    const onSubmitButtonClick = (event) => {
        submitButton.current.click();
    }

    const reset = () => {
        setPassword("");
        setConfirmPassword("");
    }

    //global validator for registration form
    const passwordFieldsMustMatch = (fields) => {

        if (fields.password !== fields.confirmPassword) {
            return [{ name: 'password', message: 'Введённые пароли не совпадают' }, { name: 'confirmPassword', message: 'Введённые пароли не совпадают' }];
        }

        return null;
    }

    return (

        <Container maxWidth='sm'>
            <Paper>
                <div className={clsx(classes.pb3, "paper-header")}>
                    {t('PasswordRecovery')}
                </div>
                <div className={classes.paperBody} >
                    <ValidatorForm
                        validators={[passwordFieldsMustMatch]}
                        onSubmit={handleSubmit}>

                        <TextValidator
                            type="password"
                            label={t('NewPassword')}
                            name="password"
                            id="password"
                            fullWidth
                            onChange={(event) => setPassword(event.target.value)}
                            value={password}
                            validators={['required']}
                            errorMessages={[t('FieldMustBeFilled')]} />

                        <TextValidator
                            type="password"
                            label={t('RetypePassword')}
                            name="confirmPassword"
                            id="confirmPassword"
                            fullWidth
                            onChange={(event) => setConfirmPassword(event.target.value)}
                            value={confirmPassword}
                            validators={['required']}
                            errorMessages={[t('FieldMustBeFilled')]} />

                        <input ref={submitButton} type="submit" className={classes.displayNone} />

                    </ValidatorForm>
                </div>
                <div className={classes.paperFooter}>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={onSubmitButtonClick}>{t('OK')}</Button>
                </div>
            </Paper>
        </Container>
    )
});

export default PasswordChangeForm;