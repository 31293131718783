import * as React from "react";

import { withTranslation } from "react-i18next";
import { withStyles } from '@mui/styles';
import { ValidatorForm } from "@Framework/components/Form";

import { Button } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

//import style from '../../styles/domrf-theme.module.scss';


interface IProps {
    classes: any;
    t: any;

    isOpen: boolean;
    title: string;
    extraProps?: any;
    contentText?: string | null;
    onDialogClose: (isOk: boolean) => void;
}

interface IState {

}


const styles = (theme) => ({

});


class GenericFormDialog extends React.Component<IProps, IState> {

    // constructor(props) {
    //     super(props);
    // }

    submitForm = null;

    handleOkClose = async () => {

        var res = await this.submitForm.isFormValid(false);
        if (res === false) {
            return;
        }

        this.props.onDialogClose(true);
    };

    handleCancelClose = () => {
        this.props.onDialogClose(false);
    };

    render() {

        const { t, children, isOpen, extraProps, ...rest } = this.props;

        return (
            <Dialog open={isOpen} {...rest}>
                <DialogTitle>{this.props.title}</DialogTitle>
                <DialogContent dividers>
                    {
                        this.props.contentText != null && (
                            <DialogContentText>
                                {this.props.contentText}
                            </DialogContentText>
                            )
                    }
                    <ValidatorForm onSubmit={() => { }} ref={x => this.submitForm = x}>
                        {children}
                    </ValidatorForm>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCancelClose}>{t('Cancel')}</Button>
                    <Button variant='contained' color="secondary" onClick={this.handleOkClose}>{t('Ok')}</Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withTranslation('translations')(withStyles(styles)(GenericFormDialog));