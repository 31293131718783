/* eslint-disable */
import React, { useEffect, useState, useRef } from 'react';
import { TextField, IconButton, InputAdornment, Tooltip, Popper } from "@mui/material";
/* eslint-enable */
import useFormValidation from './ValidationForm/useFormValidation';
import ErrorTooltip from '../../../components/shared/ErrorTooltip';

import { makeStyles } from '@mui/styles';
import CustomTooltip from '../../../components/shared/CustomTooltip';
import style from '@Styles/domrf-theme.module.scss'

const useStyles = makeStyles({

    compact: {
        marginTop: '8px'
    },
    error: {
        backgroundColor: 'rgb(255,237,237) !important',
    },
    errorPopper: {
        backgroundColor: style.colorPrimary3,
        maxWidth: '300px',
        color: '#FFF',
        fontSize: '1rem',
        padding: '0.2rem 0.5rem',
        borderRadius: '4px',
        margin: '0 4px !important',
    },
    tooltip: {
        display: 'flex',
        width: '100%',
        zIndex: '+10000 !important',
    }
});

type IProps = {
    name: string;
    errorMessages: string[];
    validators: any[];
    tooltip: string;
    tooltipLink: string;
    tooltipButtonName: string;
    onLinkClick: () => void;
    readOnly: boolean;
    inputProps: any;
    InputProps: any;
    value: any;
    disabled: boolean;
    type: any;
    hideValidationIcon: boolean
    disableErrorHighlight: boolean
}

const TextValidator = React.forwardRef(({
    name,
    errorMessages,
    validators,
    tooltip,
    tooltipLink,
    tooltipButtonName, 
    onLinkClick,
    readOnly,
    inputProps,
    InputProps,
    value,
    disabled,
    // inputmode,
    type,
    hideValidationIcon = false,
    disableErrorHighlight = false,

    ...rest
}: IProps, ref) => {

    const { isValid, errorMessage } = useFormValidation(disabled, name, value, errorMessages, validators);
    const classes = useStyles();
    const [displayWarning, setDisplayWarning] = useState(false)
    const [displayActiveWarning, setDisplayActiveWarning] = useState(false)
    const elRef = useRef()

    //console.log("====rendering " + name + ' value: ' + value + ' isValid: ' + isValid);

    useEffect(() => {
        if (isValid){
             setDisplayWarning(false)
             setDisplayActiveWarning(false)
        }
    }, [isValid])

    return (
        <>
            {(hideValidationIcon == true && !isValid) &&
                <Popper open={displayWarning || displayActiveWarning} anchorEl={elRef?.current}
                    // disablePortal={false}
                    placement="right-start"
                    className={classes.errorPopper}
                >
                    {errorMessage}
                </Popper>
            }
            {/* {console.log('InputProps',name, InputProps.readOnly, readOnly)} */}
            <CustomTooltip
                className={classes.tooltip}
                content={tooltip}
                link={tooltipLink}
                linkName={tooltipButtonName}
                onLinkClick={onLinkClick}>
                <TextField
                    {...rest}
                    // type="number"
                    // ref={ref}
                    ref={elRef}
                    autoComplete="off"
                    name={name}
                    value={value}
                    variant="standard"
                    error={!disableErrorHighlight && !isValid}
                    disabled={disabled}
                    type={type}
                    onFocusCapture={() => { if (hideValidationIcon == true && !isValid && !displayActiveWarning) setDisplayActiveWarning(true) }}
                    onBlurCapture={() => { if (hideValidationIcon == true && !isValid && displayActiveWarning) setDisplayActiveWarning(false) }}
                    onMouseOver={(e) => { if (hideValidationIcon == true && !isValid && !displayWarning) setDisplayWarning(true) }}
                    onMouseOut={(e) => { if (hideValidationIcon == true && !isValid && displayWarning) setDisplayWarning(false) }}


                    inputProps={type === 'number' ? {
                        ...inputProps, inputMode: 'decimal', pattern: '[0-9]*'
                        // , autocomplete:"off"
                        // , className: [(hideValidationIcon == true && !isValid) && classes.error].join(' ')
                        , className: [(hideValidationIcon == true && !isValid) ? classes.error : ''].join(' ')
                    } : {
                        ...inputProps
                        , className: [(hideValidationIcon == true && !isValid) ? classes.error : ''].join(' ')
                    }}
                    InputProps={{
                        readOnly: !!readOnly || !!InputProps?.readOnly
                        , startAdornment: (InputProps?.startAdornment ? InputProps?.startAdornment : null)
                        , endAdornment: (
                            (!InputProps?.endAdornment && hideValidationIcon) ? null :
                                <InputAdornment sx={!!isValid && !InputProps?.endAdornment ? { display: 'none' } : {}} position="end">
                                    {/* отображаем только если поле не валидно, иначе в конце поля появляется пустое место */}
                                    {InputProps?.endAdornment}
                                    {(!disableErrorHighlight && hideValidationIcon == false && !isValid) && (
                                        <ErrorTooltip content={errorMessage} placement="left-start" />
                                    )}
                                </InputAdornment>
                        )
                    }}
                />
            </CustomTooltip>
        </>
    )
}
)

export default TextValidator;