import * as React from "react";
import clsx from 'clsx'
import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) => createStyles({
    logoImage: {
        width: '40px',
        height: '40px',
    }
}));

const PageLogo = ({ color, className }) => {

    const classes = useStyles();

    return (
        <div className={clsx(classes.logoImage, className)}>
            <a href="https://xn--90an6b.xn--d1aqf.xn--p1ai/" title="ицб.дом.рф">
                <svg viewBox="0 0 95 95" id="logo">
                    <path fill={color || "#2F444E"}
                        d="M0 55.7h94.7v5.6H0zM0 94.8h5.8v-6.3H39V66.8H0v28zm5.8-22.4h27.4V83H5.8V72.4zM44.6 88.5h22v6.3h6v-6.3h22.1V66.8H44.6v21.7zm28-16.1h16.3V83H72.6V72.4zm-22.2 0h16.2V83H50.4V72.4z"></path>
                    <path fill={color || "#2F444E"} d="M50.3 0H28v50.3h22.3V0zm-5.9 44.7H33.8V5.6h10.6v39.1zM61.8 16.7L72.4 5.6v44.7h5.8V16.7L88.9 5.6v44.7h5.8V0h-8.2l-8.3 8.7V0h-8.1L56 14.6v35.7h5.8zM16.5 50.3h5.8V0h-8.2L0 14.6v35.7h16.5zM5.8 16.7L16.5 5.6v39.1H5.8v-28z"></path>
                </svg>
            </a>
        </div>
    );
}

export default PageLogo;