/* eslint-disable */
import React, { useEffect, useState, useRef } from 'react';
import TextField from '@mui/material/TextField';
import { IconButton, InputAdornment, Popover, Popper, Tooltip } from "@mui/material";
/* eslint-enable */
import useFormValidation from './ValidationForm/useFormValidation';
import TooltipInfoLabel from '../../../components/shared/TooltipInfoLabel';
import ErrorTooltip from '../../../components/shared/ErrorTooltip';

import { makeStyles } from '@mui/styles'
import style from '@Styles/domrf-theme.module.scss'
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import Localization, { i18nLocales } from '@Base/components/Localization';
import NumericStandaloneValidator from './NumericStandaloneValidator';

type IProps = {
    name: string;
    errorMessages: string[];
    validators: any[];
    tooltip: string;
    tooltipLink: string;
    tooltipButtonName: string;
    onLinkClick: () => void;
    readOnly: boolean;
    inputProps: any;
    InputProps: any;
    value: any;
    disabled: boolean;
    type: any;
    hideValidationIcon: boolean
}


const NumericValidator = React.forwardRef((props: IProps, ref) => {
    const {
        name,
        value,
        errorMessages,
        validators,
        disabled,

        ...rest
    } = props

    const { isValid, errorMessage } = useFormValidation(disabled, name, value, errorMessages, validators);

    // console.log('InputProps*', InputProps)
    // console.log('props.disabled', props?.disabled)
    return (
        <NumericStandaloneValidator ref={ref} isValid={isValid} errorMessage={errorMessage} value={value} disabled={disabled} {...rest} />
    )
})

export default NumericValidator;