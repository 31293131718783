import { useEffect, useState } from 'react'

function useMobileDetect() {

    const [isMobile, setIsMobile] = useState(false)
    const [isLandscape, setIsLandscape] = useState(false)

    const mobileDetect = () => {
        return window.matchMedia("(max-width: 1024px)").matches;
    }

    const landscapeDetect = () => {
        return window.matchMedia("(orientation: landscape)").matches;
    }

    useEffect(() => {
        setIsMobile(mobileDetect())
        setIsLandscape(landscapeDetect())
    }, [])

    if (window)
        window.addEventListener('resize', () => {
            setIsMobile(mobileDetect())
            setIsLandscape(landscapeDetect())
        })


    return { isMobile: isMobile, isLandscape: isLandscape, width: window?.screen?.width, height: window?.screen?.height }
}

export default useMobileDetect