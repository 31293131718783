import React, { useRef, useState, useEffect, useLayoutEffect } from 'react'
import { useSelector } from 'react-redux'

import MenuItem from '@mui/material/MenuItem'
import { ValidatorForm, TextValidator, ComboboxValidator, SelectValidator } from "../../../framework/components/Form"
import Button from '@mui/material/Button'
import { Spinner } from "@Components/shared/Spinner"
import Dialog from '@mui/material/Dialog';
import { Box, Stack, Theme, Tooltip } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import style from '@Styles/domrf-theme.module.scss';
import { t } from 'i18next'

interface IState {
    onCancelChanges: () => void;
    onApplyChanges: (value: any) => void;
    //selectedManager: any;
    managerSearchValue: string;
    dealName: string;
    dealType: string;
    [name: string]: any;
    isUserAvailable: boolean;
    fileData: string;
    poolDate: Date;
    expandedPanel: string;
    originators: any[];
    originatorSearchValue: string;
    selectedOriginator: string;
    disableButtons: boolean
}

interface IProps {
    deals: any[]
    open: boolean
    dealTypes: any[]
    originators: any[]
    onApplyChanges: (value: any) => void
    onCancelChanges: () => void
    isBusy: boolean
    downloadTemplate: () => void
    // classes: any
    // t: any
    //availableManagers: any[]
}
const useStyles = makeStyles((theme: Theme) => createStyles({
    header: {
        userSelect: 'none',
        display: 'flex',
        flexDirection: 'row',
        paddingLeft: '0.5rem',
        lineHeight: '33px',
        height: '33px !important',
        overflow: 'hidden',
        alignItems: 'center',
        borderBottom: 'solid 1px #0002',
        '&, & *': {
            fontSize: '0.9rem',
        },
    },
    title: {
        textTransform: 'uppercase',
        color: style.colorPrimary3,
    },
    data: {
        padding: '0 1rem 0.5rem 1rem',
        boxSizing: 'content-box'
    },
    actions: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
        justifyContent: 'space-between',
        marginTop: '1rem',
    },
    actionButton: {
        marginLeft: '0.5rem',
    },
    line: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
        marginTop: '1rem',
        '& .MuiInputBase-root': {
            marginTop: '7px',
        },
        '& label': {
            marginTop: '-7px'
        },
        '& label:not(.MuiFormLabel-filled):is(.Mui-focused)': {
            marginTop: '-4px',
        },
        '& .MuiFormLabel-filled': {
            marginTop: '-4px',
        },
    },
    input: {
        flexGrow: 1,
    }
}))

const CreateDealDialog = (
    {
        deals,
        open,
        //availableManagers,
        dealTypes,
        onApplyChanges,
        onCancelChanges,
        isBusy,
        downloadTemplate
    }: IProps
) => {
    const classes = useStyles()
    const [form, setForm] = useState({
        //selectedManager: null,
        managerSearchValue: '',
        dealName: '',
        dealType: 1, //CHECKME!!! hardcode
        poolDate: null,
        fileData: null,
        originatorSearchValue: '',
        selectedOriginator: null,
    })
    const { originators } = useSelector((state: any) => state.securitization)
    const [disableButtons, setDisableButtons] = useState(true)

    const editFormRef = useRef()

    useEffect(() => {
        validateForm()
    }, [form])

    const validateForm = () => {
        if (open) {
            setTimeout(async () => {
                const formValidation = await editFormRef.current.isFormValid(false)
                if (formValidation) setDisableButtons(false)
                else
                    setDisableButtons(true)
            }, 0)
        }
    }
    useLayoutEffect(() => {
        validateForm()
        if (open == false)
            resetForm();
    }, [open])

    const checkUnique = (value) => {
        if (!deals || !deals?.length) return true

        return !deals.some(item => item?.deal?.name === value)
    }

    const resetForm = () => {
        setForm({
            //selectedManager: null,
            managerSearchValue: '',
            dealName: '',
            dealType: 1, //CHECKME!!! hardcode
            poolDate: null,
            fileData: null,
            originatorSearchValue: '',
            selectedOriginator: null
        })
    }

    const handleValueChange = (name: keyof IState) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setForm({ ...form, [name]: event.currentTarget.value })
    }

    const handleDealChange = (event) => {
        setForm({ ...form, dealType: event.target.value })
    }

    const handleSubmit = () => {
        onApplyChanges({
            //manager: form.selectedManager,
            dealName: form.dealName,
            dealType: form.dealType,
            originator: form.selectedOriginator
        })
    }

    const onValidateChanges = (event) => {
        if (editFormRef.current) editFormRef.current.submit();
    }

    const onCancelChanges_Local = (event?: any, reason?: string) => {
        if (reason == 'backdropClick') {
            event.preventDefault();
            return;
        }

        onCancelChanges();
    }
    const onOriginatorSelected = (event, value) => {
        setForm({ ...form, selectedOriginator: value })
    }

    
    return (
        <>

            <Dialog
                disableEscapeKeyDown
                fullWidth
                maxWidth='sm'
                open={open}
                onClose={onCancelChanges_Local}>
                <Spinner show={isBusy} fullScreen content={t('Loading')} />
                <Box>
                    <Box className={classes.header}>
                        <Box className={classes.title}>{t('CreateNewDeal')}</Box>
                    </Box>
                    <Box className={classes.data}>
                        <ValidatorForm ref={editFormRef} onSubmit={handleSubmit}>
                            <Stack className={classes.line}>
                                <Box className={classes.input}>
                                    <ComboboxValidator
                                        disableErrorHighlight={true}
                                        name="originator"
                                        fullWidth
                                        label={t('Originator')}
                                        getOptionSelected={(option, value) => option.id === value.id}
                                        getOptionLabel={(option) => option.name}
                                        noOptionsText={t('NoData')}
                                        // tooltip={t('Originator')}
                                        options={originators}
                                        validators={['required']}
                                        errorMessages={[t('PleaseSelectOriginator')]}
                                        onChange={onOriginatorSelected}
                                        inputValue={form.originatorSearchValue}
                                        value={form.selectedOriginator}
                                        onInputChanged={(event, newInputValue) => setForm({ ...form, originatorSearchValue: newInputValue })}
                                    />
                                </Box>
                            </Stack>
                            <Stack className={classes.line}>
                                <Box className={classes.input}>
                                    <SelectValidator
                                        name="dealType"
                                        type="text"
                                        disabled={true}
                                        label={t('DealType')}
                                        // tooltip={t('DealType_Info')}
                                        fullWidth={true}
                                        onChange={handleDealChange}
                                        value={form.dealType}
                                        validators={['required']}
                                        errorMessages={[t('FieldRequired')]}>
                                        {
                                            (dealTypes || []).map(option => (
                                                <MenuItem key={option.key} value={option.key}>
                                                    {option.value}
                                                </MenuItem>))
                                        }
                                    </SelectValidator>
                                </Box>
                            </Stack>
                            <Stack className={classes.line}>
                                <Box className={classes.input}>
                                    <TextValidator
                                        disableErrorHighlight={true}
                                        name="dealName"
                                        type="text"
                                        label={t('innerDealName')}
                                        // tooltip={t('DealName_tooltip')}
                                        fullWidth={true}
                                        onChange={handleValueChange('dealName')}
                                        value={form.dealName}
                                        validators={['required', checkUnique]}
                                        errorMessages={[t('FieldRequired'), t("NameMustBeUnique")]}
                                    />
                                </Box>
                            </Stack>
                            
                        </ValidatorForm>
                        <Stack className={classes.actions}>
                            {/*<Box>
                                 <Tooltip title={t("LoadPoolTemplate")} disableInteractive>
                                    <Button className={classes.poolTemplateButton} variant="outlined" color="primary" onClick={downloadTemplate}>{t('Template')}</Button>
                                </Tooltip> 
                            </Box>*/}
                            <Box>
                                <Button className={classes.actionButton} onClick={onCancelChanges_Local} variant="contained" color="primary">
                                    {t('Cancel')}
                                </Button>
                                <Button className={classes.actionButton} onClick={onValidateChanges} disabled={disableButtons} variant="contained" color="secondary">{t('Create')}</Button>
                            </Box>
                        </Stack>
                    </Box>
                </Box >
            </Dialog >
        </>
    );
}


export default CreateDealDialog
