import * as React from 'react';
import { connect } from 'react-redux';

import * as AdministrationStore from "@Store/AdministrationStore";
import { withRouter } from "../../../components/shared/CustomRouter";

import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import Paper from '@mui/material/Paper';

import { Container } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next'
import { createStyles, makeStyles } from '@mui/styles'

import { Spinner } from "@Components/shared/Spinner";
import GenericFormDialog from "@Components/shared/GenericFormDialog";
import { TextValidator } from "@Framework/components/Form";
import ConfirmDialog2 from "@Framework/components/ConfirmDialog2";

import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';

import style from '@Styles/domrf-theme.module.scss';

import { Theme } from '@mui/material/styles';
import { withTranslation } from "react-i18next";

import Button from '@mui/material/Button';

import PermissionsTreeComponent from "@Components/shared/PermissionsTreeComponent";

const styles = (theme: Theme) => ({

    container: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '0.5rem',
        flex: '1 1 auto',
        height: 0
    },
    gridContainer: {
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1 auto',
        height: '100%'
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        [theme.breakpoints.down('sm')]: {
            paddingRight: '10px',
            paddingLeft: '10px',
        }
    },
    buttonsContainer: {
        paddingBottom: '0.5rem'
    },
    fullWidth: {
        height: '100%',
    },
    listContainer: {
        display: 'flex',
        flexDirection: 'column'
    }
});

interface IState {

    selectedRoleIndex: number | null;
    availableRoles: any[];
    permissions: any[];
    isRoleChanged: boolean;
    rolePermissions: string;
    openCreateDialog: boolean;

    newRoleName: string;
    showConfirm: boolean;
    confirmCallback: () => void;
    rejectCallback: () => void;
}

interface IProps {

    classes: any;
    history: any;
    t: any;
    isLoading: boolean;
    isProceeding: boolean;

    loadRoles: () => void;
    loadPermissions: () => void;
    saveRole: (role: any) => void;
    createRole: (roleName: string) => void;

    roles: any[];
    permissions: any[];

    updatedRole: any;
    createdRole: any;
}

class RolesPage extends React.Component<IProps, IState> {

    constructor(props) {
        super(props);

        this.state = {
            selectedRoleIndex: null,
            availableRoles: [],
            permissions: [],
            isRoleChanged: false,
            rolePermissions: null,
            openCreateDialog: false,
            newRoleName: '',
            showConfirm: false,
            confirmCallback: null,
            rejectCallback: null
        }
    }

    componentDidMount() {

        this.props.loadRoles();
        this.props.loadPermissions();
    }

    componentDidUpdate(prevProps, prevState) {

        if (this.props.roles != prevProps.roles && this.props.roles != null) {

            this.setState({ availableRoles: this.props.roles || [] });
        }

        if (this.props.permissions != prevProps.permissions && this.props.permissions != null) {

            this.setState({ permissions: this.props.permissions || [] });
        }

        //if (prevState.selectedRoleIndex != this.state.selectedRoleIndex) {

            //var role = this.state.availableRoles[this.state.selectedRoleIndex];
            //var isEditGranted = true;
            //if (role.normalizedName == 'ADMIN' || role.normalizedName == 'USER') { //CHECKME!!! add aa flag to prevent role change instead of hardcode
                //isEditGranted = false;
            //}

            //if (isEditGranted != this.state.isEditGranted)
                //this.setState({ isEditGranted: isEditGranted });
        //}

        if (prevProps.updatedRole != this.props.updatedRole) //seems, we have role updated
        {
            var availableRoles = [...this.state.availableRoles];
            var roleIndex = availableRoles.findIndex(item => item.id == this.props.updatedRole.id);
            availableRoles[roleIndex] = this.props.updatedRole;

            this.setState({ isRoleChanged: false, availableRoles: availableRoles });
        }

        if (prevProps.createdRole != this.props.createdRole) //seems, we have role created
        {

            var availableRoles = [...this.state.availableRoles, this.props.createdRole];

            this.setState({ openCreateDialog: false, newRoleName: '', availableRoles: availableRoles });
        }
    }

    handleRolesItemClick = (event, index, id) => {

        if (this.state.selectedRoleIndex == index)
            return;

        if (this.state.isRoleChanged) {
            this.setState({
                showConfirm: true, confirmCallback: () => this.changeRole(index, true), rejectCallback: () => this.changeRole(index, false),
            });
        }
        else
            this.changeRole(index);
    }

    changeRole = (index: number, needToSaveRole: boolean = false): void => {


        if (needToSaveRole) {
            var roleToSave = this.state.availableRoles[this.state.selectedRoleIndex];
            this.props.saveRole({ ID: roleToSave.id, Permissions: { Value: this.state.rolePermissions } }); // we save permissions only. Later,need to add name change and remove role
        }

        var role = this.state.availableRoles[index];
        this.setState({ selectedRoleIndex: index, rolePermissions: role.permissions, isRoleChanged: false });
    }

    handlePermissionChange = (permissionType: string, newPermissions: string) => {
        this.setState({ rolePermissions: newPermissions, isRoleChanged: true });
    }

    onAddButtonClick = (event) => {

        if (this.state.isRoleChanged) {
            this.setState({ showConfirm: true, confirmCallback: () => this.openCreateDialog(true), rejectCallback: () => this.openCreateDialog(false) });
        }
        else {
            this.openCreateDialog(true);
        }
    }

    openCreateDialog = (isRoleSaved: boolean) => {

        var changes = { openCreateDialog: true, isRoleChanged: false };
        if (!isRoleSaved) {
            var role = this.state.availableRoles[this.state.selectedRoleIndex];
            changes.rolePermissions = role.permissions;
        }

        this.setState(changes);
    }

    onSaveButtonClick = (event) => {

        var role = this.state.availableRoles[this.state.selectedRoleIndex];
        this.props.saveRole({ ID: role.id, Permissions: { Value: this.state.rolePermissions } });
    }

    onCreateDialogClose = (isValidated: boolean) => {

        if (!isValidated) {
            this.setState({ openCreateDialog: false });
            return;
        }

        this.props.createRole(this.state.newRoleName);
    }

    onRoleNameChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ newRoleName: event.currentTarget.value });
    }

    onConfirmSave = () => {

        this.state.confirmCallback();
        //this.onCloseConfirmation();
    }

    onCancelSave = () => {
        this.state.rejectCallback();
    }

    onCloseConfirmation = () => {
        this.setState({ showConfirm: false });
    }

    render() {

        const { classes, t } = this.props;

        return (

            <Container className={classes.container}>
                <GenericFormDialog
                    title={t('CreateRole')}
                    isOpen={this.state.openCreateDialog}
                    onDialogClose={this.onCreateDialogClose}
                    fullWidth={true}
                    maxWidth="xs"
                >
                    <TextValidator
                        type="text"
                        label={t('RoleName')}
                        name="roleName"
                        id="roleName"
                        fullWidth={true}
                        onChange={this.onRoleNameChanged}
                        value={this.state.newRoleName}
                        validators={['required']}
                        errorMessages={[t('FieldRequired')]}
                    />

                </GenericFormDialog>

                <ConfirmDialog2
                    show={this.state.showConfirm}
                    description={t('RoleHasChangedInformation')}
                    onConfirm={this.onConfirmSave}
                    onCancel={this.onCancelSave}
                    onClose={this.onCloseConfirmation} />

                <Spinner show={this.props.isLoading || this.props.isProceeding} fullScreen content={t('Loading')} />

                <div className={clsx("pt-2", classes.header)}>
                    <h3 className="me-auto">{t('RolesAndPermissions')}</h3>
                </div>

                <Grid container>
                    <Grid>
                        <div className={classes.buttonsContainer}>
                            <Button sx={{marginRight:'1rem'}} variant="outlined" color="secondary" onClick={this.onAddButtonClick}>
                                {this.props.t('Add')}
                            </Button>
                            <Button disabled={!this.state.isRoleChanged} variant="outlined" color="secondary" onClick={this.onSaveButtonClick}>
                                {this.props.t('Save')}
                            </Button>
                        </div>
                    </Grid>
                </Grid>
                <Grid container columnSpacing={3} className={classes.fullWidth}>
                    <Grid md={3} sm={4} sx={{ paddingBottom: { xs: '1rem', sm: 0 } }}>

                        <Paper className={classes.gridContainer}>
                            <List component="nav" aria-label="roles">
                                {
                                    this.state.availableRoles.map((item, index) => (
                                        <ListItemButton
                                            key={item.id}
                                            selected={this.state.selectedRoleIndex === index}
                                            onClick={(event) => this.handleRolesItemClick(event, index, item.id)}
                                        >
                                            <ListItemText primary={item.name} />
                                        </ListItemButton>
                                    ))
                                }
                            </List>
                        </Paper>
                    </Grid>
                    <Grid md={9} sm={8} className="ps-sm-0">
                        <PermissionsTreeComponent
                            isReadOnly={this.state.selectedRoleIndex == null}
                            permissions={this.state.permissions}
                            rolePermissions={this.state.rolePermissions}
                            onPermissionsChanged={this.handlePermissionChange}
                        />
                    </Grid>
                </Grid>
            </Container>
        );
    }
}

var component = connect(
    (state: any) => state.administration, // Selects which state properties are merged into the component's props
    AdministrationStore.actionCreators
)(RolesPage);

export default withRouter(withStyles(styles)(withTranslation('translations')(component)));
