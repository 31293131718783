import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from "../../../components/shared/CustomRouter";
import { Paper } from "@mui/material";
import { withStyles } from '@mui/styles';
import LinearProgress from '@mui/material/LinearProgress';
import TextField from '@mui/material/TextField';
import DoneIcon from '@mui/icons-material/Done';
import WarningIcon from '@mui/icons-material/Warning';
import CloseIcon from '@mui/icons-material/Close';
import { TaskTimer, ITaskTimerOptions } from 'tasktimer';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';

import * as CalculatorStore from "../../../store/CalculatorStore";
import { DataGrid, GridOptions } from "../../../framework/components/Table";
import { DataSourceChangeType } from '../../../framework/components/Common/Definitions';
import { ColumnModel, GridRequest } from "../../../framework/components/Table/Common";
import CalculatorService from "../../../services/CalculatorService";
import CommonService from "../../../services/CommonService";
import SiteHelper from "../../../shared/SiteHelper";
import { CouponType } from "../../../components/Definitions";
import { ProcessingStatus } from '../../../components/Definitions';
import { AggregateFunctions, ColumnDataType, ColumnSortDirection, TrimContentType } from '../../../framework/components/Table/Common/Models/Column';

import Localization from '../../../components/Localization';
import Globals from '../../../Globals';

import MessagesListDialog from '../../../components/shared/MessagesListDialog';
import { MessageType, EntityType } from '../../../components/Definitions';

import style from '@Styles/domrf-theme.module.scss';
import { withTranslation } from "react-i18next";

const styles = theme => ({
    container: {
        //display: 'flex',
        //flexDirection: 'column',
        padding: '0.5rem 1rem 0 1rem',
        //flex: '1 1 auto',
        height: '100%'
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    progress: {
        width: '100%'
    },
    warningIcon: {
        color: style.colorWarning
    },
    statusButton: {
        padding: '0'
    }
});

interface IProps {
    classes: any;
    loadCalculationErrors: any;
    showErrorsDialog: false;
    errors: [] | null;
    t: any;
}

interface IState {
    showErrors: boolean,
    entityId: number | null;
}

class PortfoliosPage extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {
            showErrors: false,
            entityId: null
        }
    }

    actualData = null;
    timer = null;
    grid = null;

    componentDidMount() {

        (async () => {

            var state = Globals.state.portfolios;
            if (state != null)
                await this.grid.SetFiltersState(state);

            this.timer = new TaskTimer({ interval: 5000, precision: false, stopOnCompleted: false });
            this.timer.on('tick', () => this.updateGridState());
            this.timer.start();

        })();
    }

    componentDidUpdate(prevProps, prevState) {

        if (prevProps.showErrorsDialog != this.props.showErrorsDialog) {
            this.setState({ showErrors: this.props.showErrorsDialog })
        }
    }

    componentWillUnmount() {
        this.timer.stop();
    }

    /*
    productDataSource = {
        items: [{ key: null, value: this.props.t('Undefined') }],
        count: 0
    };
    */
    dataSourceQuery = async (request: GridRequest) => {

        //save actual state
        var columnsState = SiteHelper.CreateFilterState(request);
        Globals.setState('portfolios', columnsState);

        var filter = SiteHelper.CreateFilter(request);

        var result = await CalculatorService.getPortfolios(filter);
        if (result.hasErrors) {
            throw ('Error while processing request');
        }

        this.actualData = result.value.items.map(x => x.id);

        //IGridPayload
        return {
            totalCount: result.value.count,
            payload: result.value.items
        };
    }

    dataSourceUpdate = async (type: DataSourceChangeType, data: any) => {
        if (data == null)
            return false;

        var result = null;
        switch (type) {
            case DataSourceChangeType.CREATE:
                {

                    var formData = new FormData();
                    for (var propertyName in data) {

                        formData.append(propertyName, data[propertyName] instanceof Date ? Localization.FormatDateISO(data[propertyName]) : data[propertyName]);
                    }

                    result = await CalculatorService.createPortfolio(formData);
                }
                break;
            case DataSourceChangeType.UPDATE:
                {
                    //form update structure
                    var toUpdate = new Object();
                    var keyField = "id";

                    if (data.changedFields != null) {
                        (toUpdate as any)[keyField] = data[keyField];
                        data.changedFields.forEach((item: string) => {
                            (toUpdate as any)[item] = { Value: data[item] instanceof Date ? Localization.FormatDateISO(data[item]) : data[item] };
                        });
                    }
                    else {
                        //update all
                        Object.keys(data).forEach((key) => {
                            (toUpdate as any)[key] = { Value: data[key] instanceof Date ? Localization.FormatDateTimeISO(data[key]) : data[key] };
                        });
                    }

                    result = await CalculatorService.updatePortfolio(toUpdate);
                }
                break;
            case DataSourceChangeType.REMOVE:
                {
                    result = await CalculatorService.removePortfolio(data.id);
                }
                break;
        }

        if (result.hasErrors) {
            throw ('Error while processing request');
        }

        return result.value;
    };

    updateGridState() {

        if (this.actualData == null || this.actualData.length == 0 || this.grid.UpdateDataSource == null)
            return; //nothing to change for now

        (async () => {

            var result = await CalculatorService.getPortfoliosStatus(this.actualData);
            if (!result.hasErrors && this.grid) {
                await this.grid.UpdateDataSource(result.value, this.rowUpdater);
            }
        })();
    }

    rowUpdater = (currentItem: any, newItem: any) => {
        currentItem.status = newItem.status;
        currentItem.currentTime = newItem.timeLeft;
        currentItem.percentage = newItem.percentage;
    }

    renderOriginatorCell = (row: any) => {
        return row.originatorName;
    }

    renderBondCell = (row: any) => {
        return row.bondName;
    }

    renderStatusCell = (row: any) => {

        const { classes } = this.props;
        var content = null;
        switch (row.status) {
            case ProcessingStatus.STATUS_UNKNOWN:
                {
                    return this.props.t('Error');
                }

            case ProcessingStatus.STATUS_AWAITING:
                {
                    return this.props.t('Awaiting');
                }
            case ProcessingStatus.STATUS_PROCESSING:
                {
                    return (
                        <div className={classes.root}>
                            {row.currentTime}
                            <LinearProgress variant="determinate" value={row.percentage} color="secondary" className={classes.progress} />
                        </div>
                    );
                }
            case ProcessingStatus.STATUS_READY:
                {
                    if (row.messagesAvailable) {
                        return (
                            <DoneIcon color="secondary" />
                        )
                    }
                    else {
                        return (
                            <IconButton
                                className={classes.statusButton}
                                aria-label="ready"
                                onClick={() => this.onPortfolioOpen(row)}
                                color="inherit"
                                size="large">
                                <WarningIcon className={classes.warningIcon} />
                            </IconButton>
                        );
                    }
                }
            case ProcessingStatus.STATUS_FAILED:
                {
                    return (
                        <IconButton
                            className={classes.statusButton}
                            aria-label="ready"
                            onClick={() => this.onPortfolioOpen(row)}
                            color="inherit"
                            size="large">
                            <CloseIcon color="error" />
                        </IconButton>
                    );
                }
            case ProcessingStatus.STATUS_SENDING:
                {
                    return this.props.t('Sending');
                }
            case ProcessingStatus.STATUS_SENT:
                {
                    return this.props.t('Sent');
                }
        }

        return ({ content });
    }

    bondQuery = async (value?: string, existId?: number, index?: number, pageSize?: number) => {

        var bondsDataSource = {
            items: [{ key: null, value: this.props.t('Undefined') }],
            count: 0
        }

        var bonds = await CalculatorService.getBondsQuery(value, existId, index, pageSize);

        if (bonds.hasErrors) {
            return bondsDataSource;
        }

        bondsDataSource.count = bonds.value.count;
        bondsDataSource.items = bondsDataSource.items.concat(bonds.value.items.map((item) => {
            return { key: item.id, value: item.name }
        }));

        return bondsDataSource;
    };

    onFileUploaded = (name: string, object: any, data: any) => {

        object['fileData'] = data[0];
        return new Object({ 'fileData': data[0] });
    }

    renderFileEdit = (object: any, column: ColumnModel, locale: Localization) => {
        if (object.sourceFileId != null)
            return (
                <TextField
                    margin="none"
                    key={column.Name}
                    id={column.Name}
                    name={column.Name}
                    disabled={true}
                    value={object[column.Name] || ""}
                    label={column.Label}
                    type="text"
                    fullWidth>
                </TextField>
            );

        return null;
    };

    onPortfolioOpen = (row: any) => {
        this.setState({ entityId: row.id, showErrors: true })
    }

    onErrorsDialogClose = () => {
        this.setState({ showErrors: false });
    }

    downloadTemplate = () => {
        window.open('/files/ModelLLDTemplate.xlsx', '_blank');
    }

    renderHeader = () => {
        return (
            <div style={{ marginLeft: 'auto' }}>
                <Button variant="text"
                    sx={{ fontSize: '14px' }}
                    onClick={this.downloadTemplate}
                >
                    {this.props.t('Template')}
                </Button>
            </div>
        )
    }

    columns = [

        new ColumnModel('id', { Label: this.props.t('ID'), Tooltip: this.props.t('PortfolioID_Info'), DataType: ColumnDataType.INT, IsKey: true }),
        new ColumnModel('createdDate', { Label: this.props.t('CreatedDate'), Tooltip: this.props.t('PortfolioCreatedDateID_Info'), DataType: ColumnDataType.DATE, Searchable: true, Filterable: true, Sortable: true, SortDirection: ColumnSortDirection.DESCENDING, SortOrder: 1 }),
        new ColumnModel('actualDate', { Label: this.props.t('ActualDate'), Tooltip: this.props.t('PortfolioActualDate_Info'), DataType: ColumnDataType.DATE, Searchable: true, Filterable: true, Sortable: true }),
        new ColumnModel('originatorId', { Label: this.props.t('Originator'), Tooltip: this.props.t('PortfolioOriginator_Info'), DataType: ColumnDataType.STRING, Render: this.renderOriginatorCell, VisibleInEdit: false }),
        new ColumnModel('bondId', { Label: this.props.t('Bond'), Tooltip: this.props.t('PortfolioBond_Info'), DataType: ColumnDataType.ENUM_AUTOCOMPLETE, Render: this.renderBondCell, DataSource: this.bondQuery }),
        new ColumnModel('name', { Label: this.props.t('Name'), Tooltip: this.props.t('PortfolioName_Info'), DataType: ColumnDataType.STRING, Searchable: true, Filterable: true, Sortable: true, TrimContent: TrimContentType.ELLIPSIS }),
        new ColumnModel('description', { Label: this.props.t('Description'), Tooltip: this.props.t('PortfolioDescription_Info'), DataType: ColumnDataType.STRING, Searchable: true, Filterable: true, Sortable: true, TrimContent: TrimContentType.ELLIPSIS }),

        new ColumnModel('status', { Label: this.props.t('Status'), Tooltip: this.props.t('PortfolioStatus_Info'), DataType: ColumnDataType.FLOAT, Render: this.renderStatusCell, VisibleInEdit: false }),

        new ColumnModel('fileName', { Label: this.props.t('UploadFile'), Tooltip: this.props.t('PortfolioUploadFile_Info'), DataType: ColumnDataType.FILE, Visible: false, DataChanged: this.onFileUploaded, RenderEdit: this.renderFileEdit }),

        //for admins only
        ...(Globals.hasAccess(Globals.Administrator_Role) ? [
            new ColumnModel('isShared', { Label: this.props.t('VisibleToAll'), Tooltip: this.props.t('PortfolioVisibleToAll_Info'), DataType: ColumnDataType.BOOLEAN }),
            new ColumnModel('', {
                Actions: [{ Type: 'add' }, { Type: 'remove' }, { Type: 'edit' }],
                Label: this.props.t('Actions'),
                IsAction: true
            })
        ] : []
        )
    ];

    settings = { query: this.dataSourceQuery, update: this.dataSourceUpdate };

    gridOptions = new GridOptions({
        topPager: false,
        fixedHeader: false,
        fullHeight: true,
        fullWidth: false,
        floatingToolbar: true,
        canSearch: true,

        confirmTitle: this.props.t('AreYouSureQuestion'),
        confirmDescription: this.props.t('RemovePortfolioDescription'),
        renderHeader: this.renderHeader
    });

    render() {

        const { t } = this.props;

        return (
            <div sx={{width: '100%', paddingX: '1rem'}} className={this.props.classes.container} >
                <DataGrid
                    ref={x => this.grid = x}
                    gridName={t('Portfolios')}
                    dataSource={this.settings}
                    columns={this.columns}
                    gridOptions={this.gridOptions}
                />

                <MessagesListDialog
                    show={this.state.showErrors}
                    onClose={this.onErrorsDialogClose}
                    entityType={EntityType.TYPE_PORTFOLIO}
                    entityId={this.state.entityId}
                />
            </div>
        );
    }
}

var component = connect(
    state => (state as any).calculator, // Selects which state properties are merged into the component's props
    CalculatorStore.actionCreators // Selects which action creators are merged into the component's props
)(withStyles(styles)(PortfoliosPage));

export default withRouter(withTranslation('translations')(component));