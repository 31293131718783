import React, { useEffect, useRef, useState } from 'react'
import { FormControl, FormLabel, List, ListItemButton, Divider, useTheme, Box } from '@mui/material';

const ScrollableList = (props) => {
    const { id, data, title, selected, setData,
        digheight, neighborcnt, selectedcolor
    } = props
    // console.log('props', props)
    const spacing_data = Array.from({ length: neighborcnt }, (x, i) => ' ')
    // console.log('props', props)
    const rotator = spacing_data.concat(data.map((i, index) => `0${i}`.slice(-2)).concat(spacing_data))
    // console.log('rotator', rotator)
    const transition = '0.2s linear'// cubic-bezier(0.82, 0.21, 0, 0.88)'
    const listRef = useRef();
    // const theme = useTheme();
    const [current, setCurrent] = useState()

    // const [touchY, setTouchY] = useState(0)
    const [directionY, setDirectionY] = useState(0)
    const [curIndex, setCurIndex] = useState(rotator.findIndex(i => `0${i}`.slice(-2) === `0${selected}`.slice(-2)))
    // const neighborcnt = 2;
    // const empty_arr = Array.from({length: neighborcnt}, (x, i) => '')

    // console.log('init curIndex', curIndex, selected, rotator.findIndex(i=>`0${i}`.slice(-2)==`0${selected}`.slice(-2)), parseInt(selected),rotator)

    // useEffect(() => {
    //     // перерендер в случае обновления времени снаружи
    //     // setCurIndex(data.findIndex(i=>i==selected))
    //     //   console.log('внешнее событие', title)
    // }, [selected])

    // useEffect(() => {
    //     // созвращаем родителю текуще значение
    //         setData(current)
    // }, [current])

    useEffect(() => {
        // возвращаем родителю текуще значение
        // console.log('curIndex', curIndex)
        setData(rotator.find((i, index) => index === curIndex))
        // console.log(data,curIndex)
    }, [curIndex])


    const whileEvent = (e) => {
        // const syntheticEvent = new WheelEvent("syntheticWheel", { "deltaX": 4, "deltaMode": 0 });
        // console.log(e.deltaY, e.deltaX);
        e.preventDefault();
        if (e.deltaY > 0) {
            setDirectionY(-1)
        }
        if (e.deltaY < 0) {
            setDirectionY(1)
        }
    }

    const touchStartEvent = (e) => {
        // e.preventDefault()
        e.stopPropagation()
        localStorage.setItem('startY', e.touches[0].screenY);
        // console.log('touchStart')
    }

    // const touchEndEvent= (e)=> {
    //     // debounce(setTouchY(0),500)
    //     console.log('touchEnd')
    // }

    const touchMoveEvent = (e) => {
        // document.preventDefault()

        // for (var i=0; i < e.changedTouches.length; i++) {
        //     console.log("changedTouches[" + i + "].identifier = ",e.changedTouches[i]);
        //   }

        let startY = localStorage.getItem('startY');
        if (startY && (startY - e.touches[0].screenY > digheight * 16 / 2 /*16*2*/)) { //16*2 высота в пикселах при которой происходит смета элемента
            setDirectionY(-1)
            localStorage.setItem('startY', e.touches[0].screenY);
        }
        if (startY && (startY - e.touches[0].screenY < -digheight * 16 / 2 /*-16*2*/)) {
            localStorage.setItem('startY', e.touches[0].screenY);
            setDirectionY(1)
        }
        e.stopPropagation()

    }

    const change_current = (delta, arr) => {
        if (curIndex + delta < arr.length - neighborcnt && curIndex + delta >= 0 + neighborcnt) {
            setCurIndex(curIndex + delta)
            setCurrent(arr[curIndex + delta])
        }
    }

    useEffect(() => {
        if (directionY) {
            // debounce(change_current(directionY<0?1:-1,data),50)
            change_current(directionY < 0 ? 1 : -1, rotator)
        }
        setDirectionY(0)
    }, [directionY])

    useEffect(() => {
        const inputElement = listRef.current;
        if (inputElement) {
            // console.log('addListeners')
            inputElement.addEventListener('wheel', whileEvent)
            inputElement.addEventListener('touchmove', touchMoveEvent)
            inputElement.addEventListener('touchstart', touchStartEvent)
            // listRef.current.addEventListener('touchcancel',()=>console.log('touchCancel'))
        }

        setCurIndex(rotator.findIndex(i => `0${i}`.slice(-2) === `0${selected}`.slice(-2)))
        return () => {
            if (inputElement) {
                // console.log('removeListeners')
                inputElement.removeEventListener('wheel', whileEvent)
                // inputElement.removeEventListener('touchmove',touchMoveEvent)
                inputElement.removeEventListener('touchstart', touchStartEvent)
                // listRef.current.removeEventListener('touchcancel',()=>console.log('touchCancel'))
            }
        }
    }, [])


    return (
        <Box
            sx={{
                borderLeft: 'solid 1px #0002',
                // pl: '3px',
                // pr: '3px',
                width: '100%',
                alignItems: 'center',
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                userSelect: 'none',
            }}
        >
            <Box sx={{ lineHeight: '1.5rem' }}>{title}</Box>
            <Divider sx={{ width: '100%' }} />
            <List
                ref={listRef}
                id={`list_${id}`}
                component='div'
                sx={{
                    width: 'fit-content',
                    // maxWidth: 360,
                    position: 'relative',
                    overflow: 'auto',
                    minHeight: `${digheight * 16 * (neighborcnt * 2 + 1)}px`,//250,
                    height: `${digheight * 16 * (neighborcnt * 2 + 1)}px`,//250,
                    maxHeight: `${digheight * 16 * (neighborcnt * 2 + 1)}px`,//250,
                    paddingTop: '0 !important',
                    paddingBottom: '0 !important',
                    textAlign: 'center',
                    '& > *': {
                        paddingTop: '0 !important',
                        paddingBottom: '0 !important',
                        overflow: 'hidden !important',
                    },
                    '&::-webkit-scrollbar': { display: 'none' },
                    '& > .Mui-selected': {
                        background: `none !important`//`${SelectedColor} !important`,
                    },
                    '::after': {
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0,
                        content: '""',
                        background: `linear-gradient(0deg, #FFF, #FFF0 ${Math.round(100 / (neighborcnt * 2 + 1) * neighborcnt)}%,
                                                            #FFF0 ${100 - Math.round(100 / (neighborcnt * 2 + 1) * neighborcnt)}%, #FFF 100%)`,
                        pointerEvents: 'none',
                    },
                    '::before': {
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0,
                        content: '""',
                        background: `linear-gradient(0deg, #FFF0 ${Math.round(100 / (neighborcnt * 2 + 1) * neighborcnt)}%, 
                                                            #0007 calc( ${Math.round(100 / (neighborcnt * 2 + 1) * neighborcnt)}% + 1px), ${selectedcolor} calc( ${Math.round(100 / (neighborcnt * 2 + 1) * neighborcnt)}% + 1px), 
                                                            ${selectedcolor} calc( ${100 - Math.round(100 / (neighborcnt * 2 + 1) * neighborcnt)}% - 1px), #0007 calc( ${100 - Math.round(100 / (neighborcnt * 2 + 1) * neighborcnt)}% - 1px), 
                                                            #FFF0 ${100 - Math.round(100 / (neighborcnt * 2 + 1) * neighborcnt)}%)`,
                        pointerEvents: 'none',
                    },
                }}
            >
                {/* {
                    empty_arr.map((i,index)=>(
                        <ListItemButton  key={`item-empty-before-${id}-${index}`}
                                sx={{
                                    // transform:`scaleY(${(index < neighborcnt-curIndex)?1:0})`
                                    height:`${(index <= neighborcnt-curIndex)?`${digheight}rem`:'0px !important'}`,
                                    transition:`height ${transition}`,
                                }}>
                        </ListItemButton>
                        )
                    )
                } */}
                {
                    rotator.map((i, index) => {
                        // console.log(`item-${id}-${index}`,curIndex-neighborcnt, index, curIndex ,curIndex+neighborcnt, (index>=parseInt(curIndex)-parseInt(neighborcnt) && index<= parseInt(curIndex)+parseInt(neighborcnt)))
                        return (
                            <ListItemButton key={`item-${id}-${index}`}
                                autoFocus={i === parseInt(data[curIndex], 10)}
                                selected={i === parseInt(data[curIndex], 10)}
                                onClick={() => {
                                    if (index > neighborcnt - 1 && index < rotator.length - neighborcnt) {
                                        setCurIndex(index)
                                        setCurrent(data[index])
                                    }
                                    // console.log(data[index])
                                }}
                                sx={{
                                    // transform: `scaleY(${(index>=curIndex-neighborcnt && index<= curIndex+neighborcnt)?1:0})`
                                    height: `${(index >= parseInt(curIndex) - parseInt(neighborcnt) && index <= parseInt(curIndex) + parseInt(neighborcnt)) ? `${digheight}rem` : '0px !important'}`,
                                    transition: `height ${transition}`,
                                }}
                            >
                                {i}
                            </ListItemButton>
                        )
                    }
                    )
                }
                {/* {
                    empty_arr.map((i,index)=>(
                        <ListItemButton   key={`item-empty-after-${id}-${index}`}
                                sx={{
                                    height:`${(index <= (neighborcnt+curIndex-data.length ))?`${digheight}rem`:'0px !important'}`,
                                    transition:`height ${transition}`,
                                }}>
                        </ListItemButton>
                        )
                    )
                } */}
            </List>
        </Box >
    )
}

export default ScrollableList