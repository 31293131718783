export const Actions = {
    AddAlert: "ADD_ALERT",
    RemoveAlert: "REMOVE_ALERT"
    // SetTitle: "SET_NAVBAR_TITLE",
    // Init: "TOP_NAVBAR_INIT",
    // ShowSidebar: "TOP_NAVBAR_SHOW_SIDEBAR",
    // SidebarVisibilityChanged: "TOP_NAVBAR_SIDEBAR_VISIBILITY_CHANGED"
}

export const actionCreators = {
    addAlert: (message, color = null, timeout = null) => async (dispatch, getState) => {
        const id = Date.now()
        dispatch({ type: Actions.AddAlert, payload: { id, message, color, timeout } })

        // setTimeout(() => {
        //     dispatch({type: Actions.RemoveAlert, payload: id })
        // }, time);
    },
    removeAlert: (id) => async (dispatch, getState) => {
        dispatch({ type: Actions.RemoveAlert, payload: id })

        // setTimeout(() => {
        //     dispatch({type: Actions.RemoveAlert, payload: id })
        // }, time);
    },
    // setTitle: (val) => async (dispatch, getState) => {
    //     // console.log(val)
    //     dispatch({ type: Actions.SetTitle, payload: val })
    // },

    init: () => async (dispatch, getState) => {
        dispatch({ type: Actions.Init })
    },

    // showSidebar: (show) => async (dispatch) => {
    //     dispatch({ type: Actions.ShowSidebar, isVisible: show });
    //     dispatch({ type: Sidebar.Actions.ShowSidebar, isVisible: show });
    // },

    // logoutRequest: () => async (dispatch) => {
    //     await Account.actionCreators.logoutRequest()(dispatch);
    // }
}

const initialState =
{
    alerts: []
}

export const reducer = (currentState, incomingAction) => {

    if (currentState === undefined)
        return initialState;


    const action = incomingAction;

    switch (action.type) {
        case Actions.Init:
            {
                return initialState;
            }
        case Actions.AddAlert:
            return {
                ...currentState,
                alerts: [...currentState?.alerts, action.payload]
            };
            break
        case Actions.RemoveAlert:
            // console.log('action.payload', action.payload)
            // const index = currentState?.alerts?.findIndex(element=>element?.id == action.payload)
            // const newAlerts = [...currentState?.alerts]
            // newAlerts.splice(index,1)
            return {
                ...currentState,
                alerts: currentState?.alerts.filter(alert => alert?.id !== action.payload)
            };
            break

        // case Actions.SetTitle:
        //     {
        //         return {
        //             ...currentState,
        //             title: action.payload
        //         };
        //     }


        // case Actions.ShowSidebar:
        //     {
        //         return {
        //             ...currentState,
        //             buttonVisible: !action.isVisible
        //         };
        //     }
        // case Actions.SidebarVisibilityChanged:
        //     {
        //         return {
        //             ...currentState,
        //             buttonVisible: !action.isVisible
        //         };
        //     }

        default:
            return currentState || initialState;
    }
}
