import "@Styles/pages/LoginPage.scss";

import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "../components/shared/CustomRouter";

import InformationForm from "@Components/shared/InformationForm";
import { withTranslation } from "react-i18next";
import { Container } from "@mui/material";

class DownloadPage extends React.Component {

    constructor(props) {
        super(props);
    }

    handleRedirectToMain = (event) => {
        let path = "/";
        this.props.router.navigate(path);
    };

    render() {

        const { t } = this.props;

        return (
            <Container sx={{ height: '100%', marginTop: '1rem' }}>

                <InformationForm type="download" header='' content={t('DownloadWillStartShortly')} onSubmit={this.handleRedirectToMain} />

            </Container>
        )
    }
}

export default withRouter(withTranslation('translations')(DownloadPage));