import { alpha, Box, Divider, Paper, Stack, Theme, Typography } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import style from '@Styles/domrf-theme.module.scss'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import SpinnerBars from '../../../../components/shared/SpinnerBars'
import { actionCreators as actionSeciritization } from '../../../../store/SecuritizationStore'

const useStyles = makeStyles((theme: Theme) => createStyles({
    root:{
        margin:'0.5rem 4px 2px 2px',
        // ml: '2px', mt: 1, mb: '2px', mr: '4px',
        flexGrow: 1,
        display: 'flex', 
        flexDirection: 'column',
        boxShadow: 'none',
        minHeight:'120px'
    },
    headerRoot:{
        display: 'flex', 
        paddingLeft: '0.5rem',
        flexWrap: 'nowrap',
        flexDirection: 'row',
        alignContent: 'center',
        alignItems: 'center',
        maxHeight: '30px', 
        overflow: 'hidden'
    },
    info: {
        display: 'flex',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
        alignItems: 'center',
        textAlign: 'center',
        fontSize: '1.2rem',
        color: style.colorPrimary3_40
    },
    'capitalize': {
        textTransform: 'capitalize'
    },
    line: {
        lineHeight: '2.1rem',
        flexDirection: 'row',
        position: 'relative',
        paddingLeft: '8px',
        paddingRight: '8px',
        justifyContent: 'space-between',
        alignItems: 'center',
        '&:hover': {
            backgroundColor: alpha(style.colorPrimary2, 0.17),
            '& $percentsBar': {
                borderColor: style.colorPrimary2_80,
                backgroundColor: alpha(style.colorPrimary2, 0.17),
            }
        },

    },
    columnHeader: {
        textTransform: 'uppercase',
        lineHeight: '2rem',
        maxHeight: '2rem',
        overflow: 'hidden',
        marginLeft: 1,
        flexGrow: 1,
        fontSize: '14px',
        color: style.colorPrimary3_40
    },
    tableDataSign: {
        color: style.colorPrimary3_40,
        fontSize: '0.9rem',
        display: 'inline-block',
        marginLeft: '4px',
    },
    percentsBar: {
        position: 'absolute',
        left: 0, height: '8px',
        bottom: '4px',
        backgroundColor: style.colorPrimary3_5,
        border: `solid 1px ${style.colorPrimary3_60}`
    },
    scrollablePanel: {
        position: 'absolute', left: 0, right: 0, top: 0, bottom: 0,
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: '4px',
        },
        '&:hover': {
            '&::-webkit-scrollbar-track': {
                backgroundColor: '#00000008',
            },
            '&::-webkit-scrollbar-thumb': {
                background: '#00000018',
            }
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'unset',
            borderRadius: '3px',
            // border: 'solid 1px #00000000',
            backgroundColor: '#00000000',
            transition: 'all 0.3s ease'
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#0000',
            borderRadius: '3px',
            height: '4px'
        },
    },
    wrappedText: {
        fontSize: '14px',
        color: style.colorPrimary3_60
    },
    grayscaled: {
        color: style.colorPrimary3_60
    }
}))

function RegionsDistribution({ poolId = null }) {
    const classes = useStyles()
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { regionDistribution = null } = useSelector((state: any) => state.securitization)

    useEffect(() => {
        if (poolId != null)
            actionSeciritization.getPoolRegionDistribution(poolId)(dispatch)
    }, [poolId])

    //  console.log('regionDistribution', regionDistribution)

    const formatRegionTitle = ({ name, type }) => {

        const Capitalize = ({ name }) => <span className={classes.capitalize}>{name}</span>

        if (name == null) return null

        switch (type) {
            case 'республика':
                let typeStr = type[0].toUpperCase() + type.slice(1)
                return (<>{typeStr}&nbsp;<Capitalize name={name} /></>)

            case 'область':
            case 'край':
                return (<><Capitalize name={name} />&nbsp;{type}</>)

            default:
                return (<><Capitalize name={name} /></>)
        }
    }

    const RegionsOutput = ({ regions = [] }) => {
        if (!regions?.length)
            return <></>

        const LineOutput = (id, name, percents, wac) =>
            <Stack key={`region_${id}`} className={classes.line}>
                <Box sx={{ zIndex: +1, width: '1.5rem !important', minWidth: '2rem !important', textAlign: 'right', whiteSpace: 'nowrap' }}>
                    {parseFloat(percents)?.toFixed(0)}<span className={classes.tableDataSign}>%</span>
                </Box>
                <Typography component='div' sx={{ ml: 1.5, height: '2rem', position: 'relative', flexGrow: 1, fontSize: '0.9rem' }}>
                    <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                        <Box sx={{ right: `${100 - parseFloat(percents)}%` }} className={classes.percentsBar} />
                        {name}
                    </Box>
                </Typography>
                <Box sx={{ zIndex: +1, width: '4rem !important', minWidth: '4rem !important', textAlign: 'right', whiteSpace: 'nowrap' }}>
                    {parseFloat(wac)?.toFixed(1)}
                    <span className={classes.tableDataSign}>%</span>
                </Box>
            </Stack>

        let otherRegions: any = null
        let unknownRegions: any = null
        let knowProductsCount = 0
        return (
            <>
                {regions
                    .sort((a, b) => parseFloat(a?.percents) > parseFloat(b?.percents) ? -1 : parseFloat(a?.percents) < parseFloat(b?.percents) ? 1 : 0)
                    .map((item) => {
                        let name = formatRegionTitle(item)
                        const itemPercents = parseFloat(item.percents)
                        const itemWac = parseFloat(item.wac)

                        if (item.name == null || name == null) {
                            const percents = parseFloat(unknownRegions?.percents)
                            const fraction_sum = parseFloat(unknownRegions?.wac_fraction_sum)
                            const count = parseInt(unknownRegions?.count)
                            unknownRegions = {
                                percents: (!isNaN(percents) ? percents : 0) + (!isNaN(itemPercents) ? itemPercents : 0),
                                wac_fraction_sum: (!isNaN(fraction_sum) ? fraction_sum : 0) + ((!isNaN(itemWac) && !isNaN(itemPercents)) ? itemWac * itemPercents : 0),
                                count: (!isNaN(count) ? count + 1 : 0)
                            }
                        }
                        else
                            if (item.percents < 1) {
                                const percents = parseFloat(otherRegions?.percents)
                                const fraction_sum = parseFloat(otherRegions?.wac_fraction_sum)
                                const count = parseInt(otherRegions?.count)
                                knowProductsCount++
                                otherRegions = {
                                    percents: (!isNaN(percents) ? percents : 0) + (!isNaN(itemPercents) ? itemPercents : 0),
                                    wac_fraction_sum: (!isNaN(fraction_sum) ? fraction_sum : 0) + ((!isNaN(itemWac) && !isNaN(itemPercents)) ? itemWac * itemPercents : 0),
                                    count: (!isNaN(count) ? count + 1 : 1)
                                }
                            }
                            else {
                                knowProductsCount++
                                return LineOutput(item?.regionCode, name, item.percents, item.wac)
                            }
                    })}
                {
                    otherRegions != null &&
                    LineOutput('other_regions', t("OtherRegions") + ` (${otherRegions?.count})`, otherRegions.percents.toFixed(0) == 0 ? 1 : otherRegions.percents, (otherRegions.wac_fraction_sum / otherRegions.percents)?.toFixed(2))
                }
                {knowProductsCount == 0 ?
                    <Box className={classes.info}>{t("RegionDistributionLoadingFail")}</Box> :
                    <>{
                        unknownRegions != null &&
                        LineOutput('unknown_regions', t("Unknown"), unknownRegions.percents?.toFixed(0) == 0 ? 1 : unknownRegions.percents, (unknownRegions.wac_fraction_sum / unknownRegions.percents)?.toFixed(2))
                    }</>
                }
            </>)
    }

    return (
        <Paper className={classes.root}>
            <Stack sx={{ flexShrink: 1 }}>
                <Box className={classes.headerRoot}>
                    <Typography className={classes.columnHeader} >
                        {t("PoolRegionsDistribution")}
                    </Typography>
                    <Typography className={classes.columnHeader} sx={{ textAlign: 'right', pr: 3 }}>
                        {t("WAC")}
                    </Typography>
                </Box>
                <Divider />
            </Stack>
            <Stack sx={{
                position: 'relative', flexGrow: 1,
                ...Boolean(regionDistribution?.distribution?.length > 8)
                    ? { flexBasis: '310px' }
                    : { ...Boolean(regionDistribution?.distribution?.length) ? { flexBasis: `${regionDistribution.distribution.length * 35}px` } : { flexBasis: '200px' } }
            }}>
                {!(regionDistribution?.distribution?.length && regionDistribution.poolId == poolId) ?
                    <>
                        <SpinnerBars withBackground />
                        {/* <Box className={classes.info}>{t("RegionDistribution")}</Box> */}
                    </>
                    :
                    <>
                        <Stack sx={{ position: 'relative', flexGrow: 1 }}>
                            <Box className={classes.scrollablePanel}>
                                <RegionsOutput regions={regionDistribution.distribution} />
                            </Box>
                        </Stack>
                    </>

                }
            </Stack>
        </Paper>

    )
}

export default RegionsDistribution