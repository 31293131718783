import * as React from 'react';
import Localization from "./components/Localization";
//import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
//import DateFnsUtils from '@date-io/date-fns';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
//import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { Locale } from 'date-fns';

const getActualLocalization = (): Locale => {
    return Localization.locale;
}

export const LocalizationContext = React.createContext({ updateLocalization: () => { } });

interface IProps {
    children: any;
}

interface IState {
    localization: any;
}

export default class LocalizationWrapper extends React.PureComponent<IProps, IState>
{
    constructor(props) {
        super(props);

        this.state = {
            localization: getActualLocalization()
        };
    }

    updateLocalization = () => {
        this.setState({ localization: getActualLocalization() });
    }

    componentDidMount() {

        if (Localization?.locale?.code != 'ru') { //принудительно переключаем на русский
            // console.log('Localization.locale', Localization.locale)
            Localization.setLocalization('ru')
        }
    }

    render() {

        var { children } = this.props;

        return (
            <LocalizationContext.Provider value={{ updateLocalization: this.updateLocalization }} >
                {/* {console.log('this.state.localization', this.state.localization)} */}
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={this.state.localization} locale={this.state.localization}>
                    {children}
                </LocalizationProvider>
            </LocalizationContext.Provider>
        );
    }
}