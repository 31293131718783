import { toast } from "react-toastify";

export default class UI {

    static showErrors(...messages) {

        messages.forEach(x => {
            if (!Array.isArray(x)) {
                toast.error(x);
            }
            else {
                x.forEach(y => toast.error(y));
            }
        });
    }

    static showInfo(message) {
        toast.info(message);
    }

    static showSuccess(message) {
        toast.success(message, { className: 'toast-success' });
    }
}