import { ReactNode } from 'react';
import { Localization } from './Localization';
import { SelectionType } from '../Common/Models/Column';

export class GridOptions {
    public advancePagination: boolean = true;
    public bottomPager: boolean = true;
    public customItems: any = null;

    public topPager: boolean = true;
    public rowsPerPageOptions: number[] = [50, 100, 300, 500];
    public itemsPerPage: number = 100;

    //grid
    public fixedHeader: boolean = true;
    public fullHeight: boolean = true;
    public fullWidth: boolean = false;

    public floatingToolbar: boolean = false;
    public floatingFooter: boolean = false;

    public locale: Localization = new Localization();
    //text search wait timeout
    public searchDelayInterval: number = 500;

    public canExport: boolean = false;
    public canPrint: boolean = false;
    public canSearch: boolean = false;

    //confirmation
    public confirmTitle: string = "";
    public confirmDescription: string = "";

    //selection
    public selectionType: SelectionType = SelectionType.TYPE_NONE;

    savedSettings: any = null;

    public renderHeader: () => React.ReactNode;

    constructor(options?: Partial<GridOptions>) {
        Object.assign(this, options);
    }

    public SetMobileMode() {

        if (this.savedSettings != null)
            return;

        this.savedSettings = {
            advancePagination: this.advancePagination,
            bottomPager: this.bottomPager,
            canExport: this.canExport,
            canPrint: this.canPrint,
            canSearch: this.canSearch,
            rowsPerPageOptions: this.rowsPerPageOptions,
            itemsPerPage: this.itemsPerPage,
            topPager: this.topPager,

            fixedHeader: this.fixedHeader,
            fullHeight: this.fullHeight,
            fullWidth: this.fullWidth,
            floatingToolbar: this.floatingToolbar,
            floatingFooter: this.floatingFooter,
            searchDelayInterval: this.searchDelayInterval,
            selectionType: this.selectionType
        }

        this.advancePagination = false;
        //this.bottomPager = true;
        //this.canExport = false;
        //this.canPrint = false;
        //this.canSearch = true;
        this.rowsPerPageOptions = [50, 100];
        this.itemsPerPage = 100;
        //this.topPager = false;

        this.fixedHeader = false;
        this.fullHeight = false;
        this.fullWidth = false;
        this.floatingToolbar = false;
        this.floatingFooter = false;
        this.searchDelayInterval = 500;
        this.selectionType = SelectionType.TYPE_NONE;
    }

    public SetDesktopMode() {

        if (this.savedSettings == null)
            return;

        this.advancePagination = this.savedSettings.advancePagination;
        this.bottomPager = this.savedSettings.bottomPager;
        this.canExport = this.savedSettings.canExport;
        this.canPrint = this.savedSettings.canPrint;
        this.canSearch = this.savedSettings.canSearch;
        this.rowsPerPageOptions = this.savedSettings.rowsPerPageOptions;
        this.topPager = this.savedSettings.topPager;
        this.fixedHeader = this.savedSettings.fixedHeader;
        this.fullHeight = this.savedSettings.fullHeight;
        this.fullWidth = this.savedSettings.fullWidth;
        this.floatingToolbar = this.savedSettings.floatingToolbar;
        this.floatingFooter = this.floatingFooter;
        this.searchDelayInterval = this.savedSettings.searchDelayInterval;
        this.selectionType = this.savedSettings.selectionType;
        this.itemsPerPage = this.savedSettings.itemsPerPage;

        this.savedSettings = null;
    }
}
