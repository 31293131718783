import React, { Component } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
//import IconButton from '@mui/material/IconButton';
//import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

export default class InformationDialog extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            visible: this.props.show
        };
    }

    componentDidUpdate(prevProps) {

        if (this.props.show !== prevProps.show) {
            this.setState({ visible: this.props.show });
        }
    }

    onWindowClose = (event) => {

        if (this.props.onClose)
            this.props.onClose(event);

        this.setState({
            visible: false
        });
    }

    render() {
        return (

            <Dialog onClose={this.onWindowClose} aria-labelledby="information-dialog-title" open={this.state.visible}>
                <MuiDialogTitle id="information-dialog-title" onClose={this.onWindowClose}>
                    {
                        (this.props.renderBody != null && typeof this.props.renderBody == 'function') && (
                            this.props.renderBody()
                        )
                    }
                    {(this.props.title != null) && (this.props.title)}
                </MuiDialogTitle>
                <MuiDialogContent dividers>
                    <Typography gutterBottom>
                        {
                            (this.props.render != null && typeof this.props.render == 'function') && (
                                this.props.render()
                            )}
                        {
                            (this.props.information != null) && (this.props.information)
                        }
                    </Typography>
                </MuiDialogContent>
                <MuiDialogActions>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={this.onWindowClose}>
                        Закрыть
                        </Button>
                </MuiDialogActions>
            </Dialog>
        );
    }
}