import React, { useState, useMemo, useRef, useEffect } from 'react';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import Input from '@mui/material/Input';
import Localization from '../../../components/Localization';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useTheme, Theme, alpha } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import DateFnsUtils from '@date-io/date-fns';
import format from 'date-fns/format';
import isValid from 'date-fns/isValid';
import { DefaultNullValue } from "../Common/Definitions";
import { debounce } from 'throttle-debounce';
import { styled, withStyles } from '@mui/styles';
import { DropzoneArea } from '../DropZone';
import { convertBytesToMbsOrKbs } from '../DropZone/helpers';
import { ValidatorForm, TextValidator, SelectValidator, DatePickerValidator, ComboboxValidator, NumericValidator, AutocompleteComponent, TimePickerValidator } from "@Framework/components/Form";

import style from '@Styles/domrf-theme.module.scss';
import useValueChange from '../../../components/hook/useValueChange';
import { getDate, getDateUTC, getTime } from '@Utilities';
import { LocalOffer } from '@mui/icons-material';
import { withCustomization } from '../../../components/hoc/withCustomization';
import { t } from 'i18next';
import { Switch } from '@mui/material';



const BooleanInputOperators = [
    { Value: 'true', Title: 'True' },
    { Value: 'false', Title: 'False' }
];

export enum InputDataType {
    UNKNOWN = 'unknown',
    STRING = 'string',
    FLOAT = 'float',
    INT = 'int',
    BOOLEAN = 'boolean',
    BOOLEAN_TRISTATE = 'boolean_nullable',
    DATE = 'date',
    TIME = 'time',
    DATE_TIME = 'datetime',
    DATE_TIME_UTC = 'datetimeutc',
    ENUM = 'enum',
    ENUM_AUTOCOMPLETE = 'enum_autocomplete',
    FILE = 'file',
    DATE_T = 'dateWithSavedTime', //дата с сохраненным временем
}


const ColumnDataTypeToHtmlType = {
    boolean: 'text',
    date: 'date',
    datetime: 'datetime-local',
    datetimeutc: 'datetime-local',
    numeric: 'number',
    string: 'text',
};

const useStyles = makeStyles({
    changed: {
        // boxShadow:'1px 1px #f2f8e8'
        // '&::before':{
        //     // content:'"!"',
        //     // color:'red',
        // },
        backgroundColor: /*`rgb(241,241,241)`*/`#f2f8e8`,
    }
})

const GreenSwitch = styled(Switch)(({ theme }) => ({
    marginBottom: '3px',
    '& .MuiSwitch-switchBase .MuiSwitch-thumb': {
        color: 'rgb(245,245,245)',
        border: `solid 1px rgb(189,189,189)`,

    },
    '& .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb': {
        color: 'rgb(197,226,158)',
        border: `solid 1px rgb(139,197,64)`,

    },
    '& .MuiSwitch-switchBase.Mui-focusVisible': {
        color: `${alpha(style.colorPrimary3, 1)}`,
        // padding:'5px',
        // paddingRight:0,
        // paddingBottom:0,
    },
    '& .MuiSwitch-switchBase + .MuiSwitch-track': {
        backgroundColor: 'rgb(255,255,255)',
        border: `solid 1px rgb(189,189,189)`,
        // opacity:'0.5 !important'
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: 'rgb(242,248,232)',
        border: `solid 1px  rgb(139,197,64)`
    },
}));

const UniversalInputField = ({
    isChanged,
    dataType,
    name,
    label,
    disabled,
    value,
    fullWidth,
    handleValueChange,
    locale,
    dataSource,
    loadDataSource,
    fieldProps,
    validators,
    errorMessages,
    storeChanges,
    minDate = null,
    maxDate = null,
    disableCloseOnSelect,
    needConfirm,
    readOnly,
    hideValidationIcon,
    InputProps,
    inputProps,
    SelectProps,

    ...rest

}) => {
    // console.log('isChanged', isChanged)
    // name == "poolDate" &&  console.log( name, '!!!= ', value)
    const classes = useStyles()
    // const ref = useRef()
    // const prevValue = useRef(value)

    const [open, setOpen] = useState(false);
    //const [localDataSource, setLocalDataSource] = useState([]);
    const [selectValue, setSelectValue] = useState(null);
    const [loading, setLoading] = useState(false);
    // console.log('\==============================')
    // console.log('val, ischs',value, isChanged)
    // const [prevValue, compareWithPrevValue ] = useValueChange(value,isChanged)

    const CustomNumericValidator = useMemo(() => withCustomization(NumericValidator,
        {
            needConfirm: true,
            //autoConfirmTimeout: 1000,
            // helper: t('PressEnterToSubmit'),
            // helperShowDelay: 1000,
            changesHighlight: `#f2f8e8`,//`rgb(241,241,241)`,
            disableWheel: true,
            selectOnFocus: true,
            // inputProps: {
            //     // disableUnderline: true,
            // }
        }), [])

    const CustomTextValidator = useMemo(() => withCustomization(TextValidator,
        {
            needConfirm: true,
            //autoConfirmTimeout: 1000,
            // helper: t('PressEnterToSubmit'),
            // helperShowDelay: 1000,
            changesHighlight: `#f2f8e8`, //`rgb(241,241,241)`,
            disableWheel: true,
            selectOnFocus: true,
            // inputProps: {
            //     disableUnderline: true,
            // }
        }), [])

    const handleChangeInt = (e: React.ChangeEvent<HTMLInputElement>) => {
        let val = e.target.value?.replace(/\d+/g, '')
        // console.log('val', val)
        handleValueChange(e.target.name, val);
    }


    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        // console.log('e', e)
        handleValueChange(e.target?.name, e.target.value);
    }

    const handleDateChange = (name: string, date: Date | null, dataType: InputDataType) => {

        // console.log('date!!!!!!!!!!!!!!!!!!!!!!!!!!!!', date)
        switch (dataType) {
            case InputDataType.TIME:
                handleValueChange(name, isValid(date) ? getTime(date) : date);
                break
            case InputDataType.DATE:
                {
                    handleValueChange(name, isValid(date) ? getDate(date) : date);
                }
                break;
            case InputDataType.DATE_TIME_UTC:
                {
                    handleValueChange(name, isValid(date) ? getDateUTC(date) : date);
                }
                break;
            default:
                {
                    handleValueChange(name, date);
                }
                break;
        }
    };

    const handleCheckChange = (e: React.ChangeEvent<HTMLInputElement>) => handleValueChange(e.target.name, e.target.checked);
    const handleSelectChange = (e: SelectChangeEvent) => {
        handleValueChange(e.target.name, e.target.value === DefaultNullValue ? null : e.target.value);
    }
    const handleDynamicSelectChange = (name, value: any) => {

        setSelectValue(value);

        handleValueChange(name, value)// == null ? null : (value.key === DefaultNullValue ? null : value.key));

        // if (value == null && loadDataSource != null) {
        //     //update datasource
        //     loadDataSource('');
        // }
    }
    // const handleFileChange = (files: any) => {

    //     //called later, that user can override data
    //     if (dataChanged != null && typeof dataChanged === 'function') {
    //         var data = dataChanged(name, object, files);
    //         if (data != null) {

    //             data[name] = files.length != 0 ? files[0].name : null;

    //             for (var propertyName in data) {
    //                 handleValueChange(e, propertyName, data[propertyName]);
    //             }
    //         }
    //     }
    //     else {
    //         handleValueChange(e, name, files.length != 0 ? files[0].name : null);
    //     }
    // };

    // const isNumber = (val) => {
    //     return !!val && isFinite(val)
    // }

    const render = () => {
        switch (dataType) {
            case InputDataType.TIME:
                {
                    return (
                        <TimePickerValidator
                            inputClassName='ms-1'
                            label={label}
                            name={name}
                            id={name}
                            fullWidth={true}
                            value={value}
                            disabled={disabled}
                            //mask={Localization.TimeMask()}
                            //format={Localization.ShortTimePattern()}
                            onChange={(e: any) => handleDateChange(name, e, dataType)}
                            validators={['required', 'invalidDate']}
                            errorMessages={[t('FieldRequired'), t('InvalidTime')]}
                            inputProps={{ ...inputProps }}
                            InputProps={InputProps}
                            {...rest}
                        />
                    );
                }
                break;
            case InputDataType.DATE_T:
            case InputDataType.DATE:
            case InputDataType.DATE_TIME:
            case InputDataType.DATE_TIME_UTC:
                {
                    return (
                        <DatePickerValidator
                            fullWidth={fullWidth}
                            //views={['date']}
                            mobile={false} //Enable manual input
                            placeholder={Localization.ShortDatePattern()}// "dd.MM.yyyy"
                            mask={Localization.DateMask()}//"__.__.____"
                            allowKeyboardControl={true}
                            name={name}
                            inputProps={{ autoComplete: "off", ...inputProps }}
                            InputProps={InputProps}
                            minDate={minDate}
                            maxDate={maxDate}
                            margin="none"
                            disabled={!!disabled}
                            value={value}
                            label={label}
                            readOnly={!!readOnly}
                            onChange={(e: any) => handleDateChange(name, e, dataType)}
                            validators={validators}
                            errorMessages={errorMessages}
                            disableCloseOnSelect={disableCloseOnSelect}
                            hideValidationIcon={hideValidationIcon}
                            //className={compareWithPrevValue()}

                            {...rest}
                        />
                    );
                }
                break;
            case InputDataType.BOOLEAN:
                {
                    return (label != null) ?

                        (

                            <FormControlLabel
                                sx={{ marginLeft: 0 }}
                                control={
                                    <Checkbox
                                        sx={{ padding: 0 }}
                                        name={name}
                                        disabled={!!readOnly || disabled}
                                        inputProps={{ autoComplete: "off", ...inputProps }}
                                        checked={value}
                                        onChange={handleCheckChange}
                                        color="primary"
                                        {...rest}
                                    />
                                } label={label} />
                        ) :
                        (
                            <GreenSwitch
                                size="small"
                                // <Checkbox
                                // sx={{ mb: '2px' }}
                                name={name}
                                disabled={!!readOnly || disabled}
                                inputProps={{ autoComplete: "off", ...inputProps }}
                                checked={value}
                                onChange={handleCheckChange}
                                color="primary"
                                // className={compareWithPrevValue()}

                                {...rest}
                            />
                        )
                }
                break;
            case InputDataType.FLOAT:
                return (
                    <CustomNumericValidator
                        // needConfirm={needConfirm}
                        margin="none"
                        name={name}
                        inputProps={{
                            autoComplete: "off",
                            step: 0,
                            inputMode: 'numeric',
                            pattern: '[0-9\,.]*',
                            ...inputProps
                        }}
                        InputProps={{
                            ...InputProps,
                        }}
                        readOnly={!!readOnly}
                        label={label}
                        fullWidth={fullWidth}
                        disabled={!!disabled}
                        value={value}
                        onChange={handleChange}
                        validators={validators}
                        errorMessages={errorMessages}
                        hideValidationIcon={hideValidationIcon}

                        // autocomplete:"off"
                        {...rest}
                    />
                );
                break;
            case InputDataType.INT:
                {
                    // console.log('rest', rest)
                    return (
                        <CustomNumericValidator
                            // needConfirm={needConfirm}
                            margin="none"
                            name={name}
                            inputProps={{
                                autoComplete: "off",
                                step: 0,
                                inputMode: 'numeric',
                                pattern: '[0-9]*',
                                ...inputProps
                            }}
                            InputProps={{
                                ...InputProps,
                            }}
                            readOnly={!!readOnly}
                            label={label}
                            fullWidth={fullWidth}
                            disabled={!!disabled}
                            value={value}
                            onChange={handleChange}
                            validators={validators}
                            errorMessages={errorMessages}
                            hideValidationIcon={hideValidationIcon}

                            // autocomplete:"off"
                            {...rest}
                        />
                    );
                }
                break;
            case InputDataType.BOOLEAN_TRISTATE:
            case InputDataType.STRING:
                {
                    return (

                        <CustomTextValidator
                            // needConfirm={needConfirm}
                            select={dataType === InputDataType.BOOLEAN_TRISTATE}
                            type={(ColumnDataTypeToHtmlType as any)[dataType]}
                            margin="none"
                            name={name}
                            inputProps={{
                                autoComplete: "off",
                                step: 'any',
                                ...inputProps
                            }}
                            disabled={!!disabled}
                            value={value}
                            label={label}
                            onChange={handleChange}
                            fullWidth={fullWidth}
                            validators={validators}
                            errorMessages={errorMessages}
                            InputProps={{
                                ...InputProps,
                                readOnly: !!readOnly
                            }}
                            hideValidationIcon={hideValidationIcon}
                            {...rest}
                        >
                            {dataType === InputDataType.BOOLEAN_TRISTATE &&
                                BooleanInputOperators.map((option) => (
                                    <MenuItem key={option.Value} value={option.Value}>
                                        {(locale as any)[option.Title]}
                                    </MenuItem>
                                ))}
                        </CustomTextValidator>
                    );
                }
                break;
            case InputDataType.ENUM:
                {
                    return (
                        <FormControl variant="standard" fullWidth={fullWidth}>
                            {/* {console.log('value', name, value)} */}
                            {/* {console.log('SelectProps', rest)} */}
                            <SelectValidator
                                margin="none"
                                name={name}
                                inputProps={{ autoComplete: "off", ...inputProps }}
                                disabled={!!disabled}
                                value={value}
                                label={label}
                                readOnly={!!readOnly}
                                type={(ColumnDataTypeToHtmlType as any)[dataType]}
                                onChange={handleSelectChange}
                                fullWidth={fullWidth}
                                validators={validators}
                                errorMessages={errorMessages}
                                hideValidationIcon={hideValidationIcon}
                                SelectProps={{ ...SelectProps, onClose: () => setTimeout(() => { document.activeElement?.blur() }, 0) }} // потеря фокуса после изменения
                                //className={compareWithPrevValue()}
                                {...rest}>
                                {
                                    (dataSource || []).map((option: any) => (
                                        <MenuItem key={option.key} value={option.key}>
                                            {option.value}
                                        </MenuItem>))
                                }
                            </SelectValidator>
                        </FormControl>
                    );

                }
                break;
            case InputDataType.ENUM_AUTOCOMPLETE:
                {
                    return (
                        <AutocompleteComponent
                            name={name}
                            freeSolo={false}//!column.ForceSelection
                            open={open}
                            onOpen={() => setOpen(true)}
                            onClose={() => setOpen(false)}
                            getOptionLabel={option => option?.value || ''}
                            isOptionEqualToValue={(option, value) => option.key === value.key}
                            value={selectValue}
                            fullWidth={true}
                            inputProps={{ autoComplete: "off", ...inputProps }}
                            SelectProps={{ ...SelectProps }}
                            loadingText={locale?.LoadingWithEllipsis}
                            noOptionsText={locale?.NoData}
                            onChange={handleDynamicSelectChange}
                            dataSource={dataSource}
                            loading={open && loading}
                            validators={validators}
                            errorMessages={errorMessages}
                            hideValidationIcon={hideValidationIcon}
                            //className={compareWithPrevValue()}
                            /*
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    name={name}
                                    margin="none"
                                    label={label}
                                    onChange={(event) => {
                                        loadDataSource(event.target.value);
                                    }}
                                    fullWidth={fullWidth}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                />
                            )}
                            */
                            {...rest}
                        />);
                }
                break;
            /*
        case InputDataType.FILE:
            {
                return (
                    <div className={classes.preview}>
                        <InputLabel className={classes.dropZoneCaption}>{label}</InputLabel>
                        <DropzoneArea
                            filesLimit={1}
                            dropzoneClass={classes.dropzone}
                            dropzoneParagraphClass={classes.dropZoneLabel}
                            dropzoneText={locale.DragOrSelectFileText}
                            showAlerts={false}
                            showPreviews={true}
                            showPreviewsInDropzone={false}
                            showFileNames={true}
                            showFileNamesInPreview={true}
                            maxFileSize={50000000}
                            previewText=''
                            onChange={handleFileChange}
                            getFileLimitExceedMessage={(filesLimit) => locale.MaximumNumberFilesExceededFn(filesLimit)}
                            getFileAddedMessage={(fileName) => locale.FileAddedFn(fileName)}
                            getFileRemovedMessage={(fileName) => locale.FileRemoved(fileName)}
                            getDropRejectMessage={(rejectedFile, acceptedFiles, maxFileSize) => {
                                let message = locale.FileRejectedFn(rejectedFile.name as string);
                                if (!acceptedFiles.includes(rejectedFile.type)) {
                                    message += locale.FileTypeNotSupported
                                }
                                if (rejectedFile.size > maxFileSize) {
                                    message += locale.FileIsTooBigFn(convertBytesToMbsOrKbs(maxFileSize))
                                }
                                return message;
                            }}
                        />
                    </div>
                );
            }
            break;
            */
        }
    }

    return (
        //<div key={name} className={isChanged && prevValue !== null && compareWithPrevValue(value/*,true/*enable console log*/) ? classes.changed : null}>
        <div key={name} className={isChanged ? classes.changed : null}> {/*постоянное свечение измененнных полей*/}
            {render()}
        </div>
    )
}

export default UniversalInputField;