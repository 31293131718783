import AppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import makeStyles from '@mui/styles/makeStyles';
import * as React from 'react';
import { useResolutionSwitch } from '../uno-react';
import { Container } from '@mui/material';

const useStyles = makeStyles({
    logo: {
        marginRight: 'auto',
        width: '200px',
    },
});

const outerWidth = 600;
const timeout = 1000;

export const NavBar = (props: any) => {
    const classes = useStyles({});
    const [isMobileView] = useResolutionSwitch(outerWidth, timeout);
    const { title, logo, drawerOpen, setDrawerOpen } = props;
    const handleDrawerOpen = (e: any) => {
        e.preventDefault();
        setDrawerOpen(!drawerOpen);
    };

    return (
        <AppBar position='absolute' color='primary'>
            <Toolbar>
                <Grid container={true} direction='row' item={true} xs={12}>
                    <Grid
                        item={true}
                        xs={6}
                        container
                        direction='row'
                        justifyContent='flex-start'
                        alignItems='center'
                    >
                        {isMobileView ?
                            (
                                <IconButton
                                    color='inherit'
                                    aria-label='Open drawer'
                                    onClick={handleDrawerOpen}
                                    size="large">
                                    <MenuIcon />
                                </IconButton>
                            )
                            :
                            <img src={logo} className={classes.logo} />
                        }
                        <Typography color='textSecondary' variant='h5'>
                            {title}
                        </Typography>
                    </Grid>
                    <Grid
                        xs={6}
                        item={true}
                        container
                        direction='row'
                        justifyContent='flex-end'
                        alignItems='center'
                    >
                        {props.children}
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    );
};
