import * as React from 'react';
import { connect } from 'react-redux';
import { generatePath } from "react-router-dom";
import { withRouter } from "../../../components/shared/CustomRouter";

import { withStyles } from '@mui/styles';
import LinearProgress from '@mui/material/LinearProgress';
import TextField from '@mui/material/TextField';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { TaskTimer, ITaskTimerOptions } from 'tasktimer';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import IconButton from '@mui/material/IconButton';
import { Paper } from '@mui/material';

import * as CalculatorStore from "../../../store/CalculatorStore";
import { DataGrid, GridOptions } from "../../../framework/components/Table";
import { DataSourceChangeType } from '../../../framework/components/Common/Definitions';
import { ColumnModel, GridRequest, Action } from "../../../framework/components/Table/Common";
import CalculatorService from "../../../services/CalculatorService";
import CommonService from "../../../services/CommonService";
import SiteHelper from "../../../shared/SiteHelper";
import { CouponType } from "../../../components/Definitions";
import { ProcessingStatus } from '../../../components/Definitions';
import { AggregateFunctions, ColumnDataType, ColumnSortDirection, TrimContentType } from '../../../framework/components/Table/Common/Models/Column';
import Localization from '../../../components/Localization';
import { Instruments } from '../../../components/Instruments';
import ConfirmDialog from '../../../framework/components/ConfirmDialog';
import MessagesListDialog from '../../../components/shared/MessagesListDialog';
import { MessageType, EntityType } from '../../../components/Definitions';
import Globals from "@Base/Globals";
import { withTranslation } from "react-i18next";

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    progress: {
        width: '100%'
    },
    statusButton: {
        padding: '0'
    }
});

interface IState {
    showConfirm: boolean,
    restartCalculationQuestion: string,
    actualObject: any | null,
    showErrors: boolean,
    entityId: number | null;
}

interface IProps {
    classes: any,
    history: any
    loadCalculationErrors: any;
    showErrorsDialog: false;
    errors: [] | null;
    t: any;
}

class CalculationsPage extends React.Component<IProps, IState> {

    constructor(props: any) {
        super(props);
        this.state = {
            showConfirm: false,
            restartCalculationQuestion: '',
            actualObject: null,
            showErrors: false,
            entityId: null
        }
    }
    _isMounted = false;
    actualData = null;
    timer = null;
    grid = null;

    componentDidMount() {

        this._isMounted = true;

        (async () => {
            var state = Globals.state.calculations;
            if (state != null)
                await this.grid.SetFiltersState(state);

            this.timer = new TaskTimer({ interval: 5000, precision: false, stopOnCompleted: false });
            this.timer.on('tick', () => this.updateGridState());
            this.timer.start();
        })();
    }

    componentDidUpdate(prevProps, prevState) {

        if (prevProps.showErrorsDialog != this.props.showErrorsDialog) {
            this.setState({ showErrors: this.props.showErrorsDialog })
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.timer.stop();
    }

    dataSourceQuery = async (request: GridRequest) => {

        //save actual state
        var columnsState = SiteHelper.CreateFilterState(request);
        Globals.setState('calculations', columnsState);

        var filter = SiteHelper.CreateFilter(request);

        var result = await CalculatorService.getCalculations(filter);
        if (result.hasErrors) {
            throw ('Error while processing request');
        }

        this.actualData = result.value.items.map(x => x.id);

        //IGridPayload
        return {
            totalCount: result.value.count,
            payload: result.value.items
        };
    }

    dataSourceUpdate = async (type: DataSourceChangeType, data: any) => {
        if (data == null)
            return false;

        var result = null;
        switch (type) {
            case DataSourceChangeType.CREATE:
                {
                    //var formData = new FormData();
                    //for (var propertyName in data) {
                    //formData.append(propertyName, data[propertyName]);
                    //}

                    //result = await CalculatorService.createPortfolio(formData);
                    return null;
                }
            case DataSourceChangeType.UPDATE:
                {
                    //form update structure
                    //var toUpdate = new Object();
                    //var keyField = "id";

                    //if (data.changedFields != null) {
                    //(toUpdate as any)[keyField] = data[keyField];
                    //data.changedFields.forEach((item: string) => {
                    //(toUpdate as any)[item] = { Value: data[item] };
                    //});
                    //}
                    //else {
                    ////update all
                    //Object.keys(data).forEach((key) => {
                    //(toUpdate as any)[key] = { Value: data[key] };
                    //});
                    //}

                    //result = await CalculatorService.updatePortfolio(toUpdate);
                    return null;
                }
            case DataSourceChangeType.REMOVE:
                {
                    result = await CalculatorService.removeCalculation(data.id);
                }
                break;
        }

        if (result.hasErrors) {
            throw ('Error while processing request');
        }

        return result.value;
    };

    updateGridState() {

        if (this.actualData == null || this.actualData.length == 0 || this.grid.UpdateDataSource == null)
            return; //nothing to change for now

        (async () => {

            var result = await CalculatorService.getCalculationsStatus(this.actualData);
            if (!result.hasErrors && this.grid) {
                if (this._isMounted) {
                    await this.grid.UpdateDataSource(result.value, this.rowUpdater);
                }
            }
        })();
    }

    rowUpdater = (currentItem: any, newItem: any) => {
        currentItem.status = newItem.status;
        currentItem.currentTime = newItem.timeLeft;
        currentItem.percentage = newItem.percentage;
    }

    renderOriginatorCell = (row: any) => {
        return row.originatorName;
    }

    renderBondCell = (row: any) => {
        return row.bondName;
    }

    renderStatusCell = (row: any) => {

        const { classes } = this.props;
        var content = null;
        switch (row.status) {
            case ProcessingStatus.STATUS_UNKNOWN:
                {
                    return this.props.t('Error');
                }

            case ProcessingStatus.STATUS_AWAITING:
                {
                    return this.props.t('Awaiting');
                }
            case ProcessingStatus.STATUS_PROCESSING:
                {
                    return (
                        <div className={classes.root}>
                            {row.currentTime}
                            <LinearProgress variant="determinate" value={row.percentage} color="secondary" className={classes.progress} />
                        </div>
                    );
                }
            case ProcessingStatus.STATUS_READY:
                {
                    return (
                        <IconButton
                            className={classes.statusButton}
                            aria-label="ready"
                            onClick={() => this.onCalculationOpen(row)}
                            color="inherit"
                            size="large">
                            <DoneIcon color="secondary" />
                        </IconButton>
                    );
                }
            case ProcessingStatus.STATUS_FAILED:
                {
                    return (
                        <IconButton
                            className={classes.statusButton}
                            aria-label="ready"
                            onClick={() => this.onCalculationOpen(row)}
                            color="inherit"
                            size="large">
                            <CloseIcon color="error" />
                        </IconButton>
                    );
                }
            case ProcessingStatus.STATUS_SENDING:
                {
                    return this.props.t('Sending');
                }
            case ProcessingStatus.STATUS_SENT:
                {
                    return this.props.t('Sent');
                }
        }

        return ({ content });
    }

    renderPortfolioCell = (row: any) => {
        return row.portfolioName;
    }

    handleAddCalculation = (action: Action, object: any) => {

        var instrument = Instruments().find(x => x.area === 'Calculator');
        if (instrument != null) {
            var action = instrument.actions.find(x => x.id === 'new');
            if (action != null) {
                this.props.router.navigate(action.url);
            }
        }

        return true;
    }

    handleEditCalculation = (action: Action, object: any) => {

        var instrument = Instruments().find(x => x.area === 'Calculator');
        if (instrument != null) {
            var action = instrument.actions.find(x => x.id === 'recalculate');
            if (action != null) {
                var params = { ...action.optionalParameters };
                params.id = object.id;

                var path = generatePath(action.url, params);
                this.props.router.navigate(path);
            }
        }
    }

    handleRecalculate = (action: Action, object: any) => {

        var question = this.props.t('RecalculationWillTakeTime');

        switch (object.status) {
            case ProcessingStatus.STATUS_PROCESSING:
                {
                    question = this.props.t('ProcessingInProgress');
                }
                break;
            case ProcessingStatus.STATUS_READY:
                {
                    question = this.props.t('ReadyCalculationNotChnaged');
                }
                break;
            case ProcessingStatus.STATUS_FAILED:
                {
                    question = this.props.t('TryToRecalculate');
                }
                break;
            case ProcessingStatus.STATUS_SENT:
                {
                    question = this.props.t('CalculationSentAndQuiet');
                }
                break;
        }

        this.setState({ showConfirm: true, restartCalculationQuestion: question, actualObject: object });

        return true;
    }

    handleDuplicate = (action: Action, object: any) => {

        this.props.router.navigate('/instruments/calculator/duplicate/' + object.id + '/complex');

        return true;
    }

    onConfirmRecalculation = () => {

        CalculatorService.recalculateCalculationById(this.state.actualObject.id);

        this.setState({ showConfirm: false });
    }

    onCloseRecalculation = () => {
        this.setState({ showConfirm: false });
    }

    onErrorsDialogClose = () => {
        this.setState({ showErrors: false });
    }


    statusQuery = async (value?: string, index?: number, pageSize?: number) => {

        var couponDataSource = {
            items: [
                { key: null, value: this.props.t('Undefined') },
                { key: ProcessingStatus.STATUS_UNKNOWN, value: this.props.t('Unknown') },
                { key: ProcessingStatus.STATUS_AWAITING, value: this.props.t('Awaiting') },
                { key: ProcessingStatus.STATUS_SENDING, value: this.props.t('Sending') },
                { key: ProcessingStatus.STATUS_SENT, value: this.props.t('Sent') },
                { key: ProcessingStatus.STATUS_PROCESSING, value: this.props.t('Processing') },
                { key: ProcessingStatus.STATUS_READY, value: this.props.t('Ready') },
                { key: ProcessingStatus.STATUS_FAILED, value: this.props.t('Error') }
            ],
            count: 8
        }

        return couponDataSource;
    };

    columns = [
        new ColumnModel('id', { Label: this.props.t('ID'), DataType: ColumnDataType.INT, IsKey: true }),
        //new ColumnModel('calculationDate', { Label: this.props.t('EvaluationDate'), DataType: ColumnDataType.DATE, Searchable: true, Filterable: true, Sortable: true, SortDirection: ColumnSortDirection.DESCENDING, SortOrder: 1 }),
        new ColumnModel('createdDate', { Label: this.props.t('CreatedDate'), DataType: ColumnDataType.DATE, Searchable: true, Filterable: true, Sortable: true, SortDirection: ColumnSortDirection.DESCENDING, SortOrder: 1 }),
        new ColumnModel('originatorId', { Label: this.props.t('Originator'), DataType: ColumnDataType.FLOAT, Render: this.renderOriginatorCell, TrimContent: TrimContentType.ELLIPSIS }),
        new ColumnModel('bondId', { Label: this.props.t('Bond'), DataType: ColumnDataType.FLOAT, Render: this.renderBondCell, TrimContent: TrimContentType.ELLIPSIS }),
        new ColumnModel('portfolioId', { Label: this.props.t('Portfolio'), DataType: ColumnDataType.FLOAT, Render: this.renderPortfolioCell, TrimContent: TrimContentType.ELLIPSIS }),
        new ColumnModel('name', { Label: this.props.t('Name'), DataType: ColumnDataType.STRING, Searchable: true, Filterable: true, Sortable: true, TrimContent: TrimContentType.ELLIPSIS }),
        new ColumnModel('description', { Label: this.props.t('Description'), DataType: ColumnDataType.STRING, Searchable: true, Filterable: true, Sortable: true, TrimContent: TrimContentType.ELLIPSIS }),

        new ColumnModel('status', { Label: this.props.t('Status'), DataType: ColumnDataType.ENUM, Render: this.renderStatusCell, DataSource: this.statusQuery, Filterable: true, Sortable: true, VisibleInEdit: false }),

        new ColumnModel('actions', {
            Actions: [
                {
                    Type: 'add',
                    onClick: this.handleAddCalculation
                },
                {
                    Type: 'remove'
                },
                {
                    Type: 'edit',
                    onClick: this.handleEditCalculation
                },
                {
                    Type: 'custom',
                    Name: this.props.t('Recalculate'),
                    Icon: (<AutorenewIcon />),
                    onClick: this.handleRecalculate
                },
                {
                    Type: 'custom',
                    Name: this.props.t('Duplicate'),
                    Icon: (<FileCopyIcon />),
                    onClick: this.handleDuplicate
                }
            ],
            Label: this.props.t('Actions'),
            IsAction: true
        })
    ];

    settings = { query: this.dataSourceQuery, update: this.dataSourceUpdate };

    gridOptions = new GridOptions({
        topPager: false,
        fixedHeader: false,
        fullHeight: true,
        fullWidth: false,
        floatingToolbar: true,
        canSearch: true,

        confirmTitle: this.props.t('AreYouSureQuestion'),
        confirmDescription: this.props.t('RemoveCalculationDescription')
    });

    onCalculationOpen = (row: any) => {

        if (row.status == ProcessingStatus.STATUS_FAILED) {
            this.setState({ entityId: row.id, showErrors: true })
            return;
        }

        if (row.status != ProcessingStatus.STATUS_READY)
            return;

        var instrument = Instruments().find(x => x.area === 'Calculator');
        if (instrument != null) {
            var action = instrument.actions.find(x => x.id === 'calculationResults');
            if (action != null) {
                this.props.router.navigate(action.url.split(':')[0] + row.id);
            }
        }
    }

    render() {

        const { t } = this.props;

        return (
            <Paper sx={{ width: '100%', height: '100%', paddingX: '1rem' }}>
                <DataGrid
                    ref={x => this.grid = x}
                    gridName={t('Calculations')}
                    dataSource={this.settings}
                    columns={this.columns}
                    gridOptions={this.gridOptions}
                    onRowDoubleClick={this.onCalculationOpen}
                />
                <MessagesListDialog
                    show={this.state.showErrors}
                    onClose={this.onErrorsDialogClose}
                    entityType={EntityType.TYPE_CALCULATION}
                    entityId={this.state.entityId}
                />
                <ConfirmDialog
                    show={this.state.showConfirm}
                    title={t('AreYouSureQuestion')}
                    description={this.state.restartCalculationQuestion}
                    onConfirm={this.onConfirmRecalculation}
                    onClose={this.onCloseRecalculation} />
            </Paper>
        );
    }
}

var component = connect(
    state => (state as any).calculator, // Selects which state properties are merged into the component's props
    CalculatorStore.actionCreators // Selects which action creators are merged into the component's props
)(withStyles(styles)(CalculationsPage));

export default withRouter(withTranslation('translations')(component));