import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import UI from "@Base/UIHelper.js";

import "@Styles/pages/InstrumentsPage.scss";

import * as InstrumentsStore from "@Store/InstrumentsStore";
import { withRouter } from "../../components/shared/CustomRouter";

import { Theme } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import List from '@mui/material/List';
import ListItem, { ListItemProps } from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import SecurityIcon from '@mui/icons-material/Security';
import DraftsIcon from '@mui/icons-material/Drafts';
import { FormGroup } from "@mui/material";
import Button from "@mui/material/Button";
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';

import { ValidatorForm, TextValidator } from "@Framework/components/Form";
import InstrumentsList from "@Components/shared/InstrumentsList";
import { Spinner } from '../components/shared/Spinner';
import Localization from '@Components/Localization';
import { ProcessingState } from "@Components/Definitions";
import ForgotPasswordDialog from "@Pages/authorization/ForgotPasswordDialog";
import { withTranslation } from "react-i18next";

const styles = (theme: Theme) => ({

    container: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '0.5rem'
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'row',
        flex: '1 1 auto'
    },
    list: {
        width: '100%',
        maxWidth: 250,
        marginRight: '1rem',
        backgroundColor: theme.palette.background.paper,
    },
    menuContent: {
        flex: '1 1 auto'
    },
    resetInfo: {
        fontStyle: 'italic'
    }
});

interface IProps {
    init: any;
    classes: any;
    visible: boolean;
    t: any;
}

interface IState {
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
    recoverPasswordState: ProcessingState;
}

class SecuritySubPage extends React.Component<IProps, IState> {

    constructor(props) {
        super(props);

        this.state = {
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
            recoverPasswordState: ProcessingState.Undefined
        };
    }

    componentDidMount() {

        this.props.init();
    }

    submitForm;

    handlePasswordChange = (event) => {

        var newState = {};
        newState[event.target.name] = event.target.value;
        this.setState(newState);
    };

    onSubmitButtonClick = async (event) => {
        var res = await this.submitForm.isFormValid(false);

        if (res) {

            var result = await AccountService.changePassword(this.state.oldPassword, this.state.newPassword);
            if (!result.hasErrors) {
                this.setState({
                    oldPassword: "",
                    newPassword: "",
                    confirmPassword: "",
                    recoverPasswordState: ProcessingState.Undefined
                });

                UI.showSuccess(this.props.t('PasswordChangedSuccessfully'));
            }
        }
    }

    handleResetPassword = (event) => {
        this.setState({ recoverPasswordState: ProcessingState.Request });
    }

    handleRecoverDialogClose = async (event, data) => {

        this.setState({ recoverPasswordState: ProcessingState.Undefined });

        if (data != null) {
            await this.props.forgotPasswordRequest(data);
        }
    }

    onSubmit = (event) => {
        alert("all OK 2");
    }

    //global validator for registration form
    passwordFieldsMustMatch = (fields) => {

        if (fields.password != fields.confirmPassword) {
            return [{ name: 'password', message: 'Введённые пароли не совпадают' }, { name: 'confirmPassword', message: 'Введённые пароли не совпадают' }];
        }

        return null;
    }

    render() {

        const {
            classes,
            t
        } = this.props;

        if (this.props.visible === false)
            return null;

        return (

            <Paper className="paper col-xl-5 col-md-8">
                <div className="paper-header pb-3">
                    {t('ChangePassword')}
                </div>
                <div className="paper-body">
                    <ForgotPasswordDialog show={this.state.recoverPasswordState == ProcessingState.Request} onClose={this.handleRecoverDialogClose} />
                    <ValidatorForm
                        validators={[this.passwordFieldsMustMatch]}
                        onSubmit={this.onSubmit}
                        ref={x => this.submitForm = x}>
                        <FormGroup>

                            <div className="control-placeholder">
                                <TextValidator
                                    type="password"
                                    label={t('OldPassword')}
                                    name="password"
                                    id="oldPassword"
                                    fullWidth
                                    onChange={this.handlePasswordChange}
                                    value={this.state.oldPassword}
                                    validators={['required']}
                                    errorMessages={[this.props.t('FieldMustBeFilled')]} />
                            </div>

                            <div className="control-placeholder">
                                <TextValidator
                                    type="password"
                                    label={t('NewPassword')}
                                    name="password"
                                    id="newPassword"
                                    fullWidth
                                    onChange={this.handlePasswordChange}
                                    value={this.state.newPassword}
                                    validators={['required']}
                                    errorMessages={[this.props.t('FieldMustBeFilled')]} />
                            </div>

                            <div className="control-placeholder">
                                <TextValidator
                                    type="password"
                                    label={t('RetypePassword')}
                                    name="confirmPassword"
                                    id="confirmPassword"
                                    fullWidth
                                    onChange={this.handlePasswordChange}
                                    value={this.state.confirmPassword}
                                    validators={['required', 'isPasswordMatch']}
                                    errorMessages={[this.props.t('FieldMustBeFilled'), this.props.t('EnteredPasswordsDoesNotMatch')]} />
                            </div>

                        </FormGroup>

                    </ValidatorForm>
                </div>
                <div className="paper-footer">
                    <div className={classes.resetInfo}>
                        {this.props.t('ResetPassword_Info') + ' '}
                        <a href='#' onClick={this.handleResetPassword}>{this.props.t('ResetPassword_Inline')}</a>
                    </div>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={this.onSubmitButtonClick}>OK</Button>
                </div>
            </Paper>
        );
    }
}

var component = connect(
    state => state.account, // Selects which state properties are merged into the component's props
    InstrumentsStore.actionCreators // Selects which action creators are merged into the component's props
)(withStyles(styles)(SecuritySubPage));

export default withRouter(withTranslation('translations')(component));