import { useState, useEffect } from 'react';

import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';

import PermissionsTreeComponent from "@Components/shared/PermissionsTreeComponent";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next'
import { createStyles, makeStyles } from '@mui/styles'

import clsx from 'clsx';

// import Localization from '@Components/Localization';
// import Globals from '../../Globals';
// import style from '@Styles/domrf-theme.module.scss';

import { Theme } from '@mui/material/styles';
import { mergePermissions } from '../../Utilities';


const useStyles = makeStyles((theme: Theme) => createStyles({

    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
    },
    gridContainer: {
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1 auto',
        height: '100%',
        overflowY: 'scroll'
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        [theme.breakpoints.down('sm')]: {
            paddingRight: '10px',
            paddingLeft: '10px',
        }
    },
    buttonsContainer: {
        paddingBottom: '0.5rem'
    },
    fullWidth: {
        height: '100%',
    },
    listContainer: {
        display: 'flex',
        flexDirection: 'column'
    }
}));

interface IProps {

    availableRoles: any[];
    permissions: any[];
    selectedRoles: any[];
    userPermissions: string;

    onRolesChanged?: (roles: any[]) => void;
    //onPermissionsChanged?: (permissions: string) => void;
    //TODO!!! add user permissions control
    onUserPermissionsChanged?: (permissions: string) => void;
}

const RolesSelectComponent = ({
    availableRoles,
    permissions,
    selectedRoles,
    //actualPermissions,
    userPermissions,

    onRolesChanged,
    //onPermissionsChanged,
    onUserPermissionsChanged
}: IProps) => {

    const classes = useStyles();

    const [resultingPermissions, setResultingPermissions] = useState('');

    useEffect(() => {

        //merge with roles permissions
        var roles = selectedRoles.map(x => (x.permissions || ''));

        //roles.push(actualPermissions || '');
        var permissionsResult = mergePermissions(roles);

        setResultingPermissions(permissionsResult);

    }, [selectedRoles]);

    const handleRoleToggle = (item) => {

        var newRoles = null;
        var index = selectedRoles.indexOf(item);
        if (index !== -1) {
            newRoles = [...selectedRoles];
            newRoles.splice(index, 1);
        }
        else {
            newRoles = [...selectedRoles, item];
        }

        //merge with roles permissions
        var roles = newRoles.map(x => (x.permissions || ''));
        //roles.push(actualPermissions || '');
        var permissionsResult = mergePermissions(roles);

        setResultingPermissions(permissionsResult);

        //CHECKME!! all after state update maybe?
        if (onRolesChanged != null)
            onRolesChanged(newRoles);

        //if (onPermissionsChanged != null)
            //onPermissionsChanged(permissionsResult);
    }

    const handlePermissionChange = (type, permissions) => {

        //we don't need to handle roles permissions
        if (type != 'user')
            return;

        if (onUserPermissionsChanged != null)
            onUserPermissionsChanged(permissions);
    }

    return (

        <Grid container columnSpacing={2} className={classes.fullWidth}>
            <Grid md={4} sm={4} sx={{ height: '100%' }}>

                <Paper className={classes.gridContainer}>
                    <List component="nav" aria-label="roles">
                        {
                            availableRoles.map((item, index) => {

                                const labelId = `checkbox-list-label-${item.id}`;

                                return (
                                    <ListItem
                                        key={item.id}
                                        disablePadding
                                    >
                                        <ListItemButton
                                            role={undefined}
                                            onClick={(event) => handleRoleToggle(item)}
                                            dense>
                                            <ListItemIcon>
                                                <Checkbox
                                                    edge="start"
                                                    checked={selectedRoles.indexOf(item) !== -1}
                                                    tabIndex={-1}
                                                    disableRipple
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                />
                                            </ListItemIcon>
                                            <ListItemText id={labelId} primary={item.name} />
                                        </ListItemButton>
                                    </ListItem>
                                );
                            })
                        }
                    </List>
                </Paper>
            </Grid>
            <Grid md={8} sm={8} sx={{ height: '100%' }}>
                <PermissionsTreeComponent
                    permissions={permissions}
                    rolePermissions={resultingPermissions}
                    userPermissions={userPermissions}
                    isDisabledRoles={true}
                    showUserPermissions={true}
                    onPermissionsChanged={handlePermissionChange}
                />
            </Grid>
        </Grid>
    );

}

export default RolesSelectComponent;

/*
interface IState {

    resultingPermissions: string;
}

interface IProps {

    classes: any;
    history: any;
    t: any;

    availableRoles: any[];
    permissions: any[];
    selectedRoles: any[];
    actualPermissions: string;

    onRolesChanged?: (roles: any[]) => void;
    onPermissionsChanged?: (permissions: string) => void;
    //TODO!!! add user permissions control
    onUserPermissionsChanged?: (permissions: string) => void;
}

class RolesSelectComponent extends React.Component<IProps, IState> {

    constructor(props) {
        super(props);

        this.state = {
            resultingPermissions: ''
        }
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState) {

        if (prevProps.selectedRoles !== this.props.selectedRoles || prevProps.availableRoles !== this.props.availableRoles || prevProps.actualPermissions !== this.props.actualPermissions) {

            //merge with roles permissions
            var roles = this.props.selectedRoles.map(x => (x.permissions || ''));
            roles.push(this.props.actualPermissions || '');
            var permissionsResult = mergePermissions(roles);

            this.setState({ resultingPermissions: permissionsResult });
        }
    }

    handleRoleToggle = (item) => {

        var newRoles = null;
        var index = this.props.selectedRoles.indexOf(item);
        if (index !== -1) {
            newRoles = [...this.props.selectedRoles];
            newRoles.splice(index, 1);
        }
        else {
            newRoles = [...this.props.selectedRoles, item];
        }

        //merge with roles permissions
        var roles = newRoles.map(x => (x.permissions || ''));
        roles.push(this.props.actualPermissions || '');
        var permissionsResult = mergePermissions(roles);

        this.setState({ resultingPermissions: permissionsResult });

        //CHECKME!! all after state update maybe?
        if (this.props.onRolesChanged != null)
            this.props.onRolesChanged(newRoles);

        if (this.props.onPermissionsChanged != null)
            this.props.onPermissionsChanged(permissionsResult);
    }

    render() {

        const { classes } = this.props;

        return (

            <Grid container columnSpacing={2} className={classes.fullWidth}>
                <Grid md={4} sm={4}>

                    <Paper className={classes.gridContainer}>
                        <List component="nav" aria-label="roles">
                            {
                                this.props.availableRoles.map((item, index) => {

                                    const labelId = `checkbox-list-label-${item.id}`;

                                    return (
                                        <ListItem
                                            key={item.id}
                                            disablePadding
                                        >
                                            <ListItemButton
                                                role={undefined}
                                                onClick={(event) => this.handleRoleToggle(item)}
                                                dense>
                                                <ListItemIcon>
                                                    <Checkbox
                                                        edge="start"
                                                        checked={this.props.selectedRoles.indexOf(item) !== -1}
                                                        tabIndex={-1}
                                                        disableRipple
                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                    />
                                                </ListItemIcon>
                                                <ListItemText id={labelId} primary={item.name} />
                                            </ListItemButton>
                                        </ListItem>
                                    );
                                })
                            }
                        </List>
                    </Paper>
                </Grid>
                <Grid md={8} sm={8}>
                    <PermissionsTreeComponent
                        permissions={this.props.permissions}
                        rolePermissions={this.state.resultingPermissions}
                        isReadOnly={true}
                    />
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(withTranslation('translations')(RolesSelectComponent));
*/