import { TableCell } from '@mui/material';
import clsx from 'clsx';
import TableRow from '@mui/material/TableRow';
import * as React from 'react';
import { ColumnModel, IFilterWrapper } from '../Common';
import { IDataGrid } from '../DataGridInterfaces/IDataGrid';
import IDetailComponet from '../DataGridInterfaces/IDetailComponent';
import DialogModal from '../Filtering/DialogModal';
import { GridHeaderCell } from './GridHeaderCell';
import { GridOptions } from './GridOptions';
import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& .MuiTableCell-head': {
                fontSize: '14px',
                color: theme.palette.primary.light,

                //textOverflow: 'ellipsis',
                //overflow: 'hidden',
                //whiteSpace: 'nowrap',

                '& svg': {
                    width: '16px',
                    height: '16px'
                }
            }
        },
        noWrap: {
            whiteSpace: 'nowrap'
        }

        /*
            root: {
                width: '100%',
                marginTop: theme.spacing.unit * 3,
                overflowX: 'auto',
            },
            table: {
                minWidth: 700
            },
        tablecell: {
            fontSize: '10px'
        }
        */
    })
);


interface IProps {
    grid: IDataGrid;
    detailComponent?: React.ReactElement<IDetailComponet>;
    gridOptions: GridOptions;
}

export const GridHeader: React.FunctionComponent<IProps> = ({ grid, gridOptions, detailComponent }) => {

    const classes = useStyles({});

    const [anchorFilter, setAnchorFilter] = React.useState<any>(null);

    const setActiveColumn = (column: ColumnModel, event: React.MouseEvent<HTMLElement>) => {
        grid.api.setActiveColumn(column);
        setAnchorFilter(event.currentTarget);
    };

    const setFilter = (filter: IFilterWrapper) => {
        grid.api.setFilter(filter);
        setAnchorFilter(null);
    };

    return (
        <TableRow className={clsx(classes.root, gridOptions.fullWidth ? classes.noWrap : '') }>
            {detailComponent && <TableCell key='Detail' padding="normal" />}
            {grid.state.activeColumn &&
                (
                    <DialogModal
                        gridOptions={gridOptions}
                        activeColumn={grid.state.activeColumn}
                        anchorFilter={anchorFilter}
                        setAnchorFilter={setAnchorFilter}
                        setFilter={setFilter}
                        handleFilterChange={grid.api.handleFilterChange}
                    />
                )}
            {grid.state.columns
                .filter((col: ColumnModel) => col.Visible)
                .map((column: ColumnModel) => (
                    <GridHeaderCell
                        key={column.Name}
                        column={column}
                        locale={gridOptions.locale}
                        sortColumn={grid.api.sortColumn}
                        setActiveColumn={setActiveColumn}
                    />
                ))}
        </TableRow>
    );
};
