import React, { useState, useEffect, useRef } from 'react'
import { ePoolNodeType } from '@Base/components/Definitions'
import { ValidatorForm } from '@Base/framework/components/Form'
import { Box, Button, Dialog, DialogActions, DialogContent, Divider, Theme, Tooltip, Typography } from '@mui/material'
import CreateNodePoolDialog from './CreateNodePoolDialog'
import style from '@Styles/domrf-theme.module.scss'
import { actionCreators as actionSecuritization } from '@Base/store/SecuritizationStore'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { t } from 'i18next'
import { createStyles, makeStyles } from '@mui/styles'
import Localization from '@Base/components/Localization'
import SpinnerBars from '@Base/components/shared/SpinnerBars'

const useStyles = makeStyles((theme: Theme) => createStyles({
    dialogBody: {
        padding: '0 1rem 1rem 1rem',
        position: 'relative'
    },
    headerBox: {
        display: 'flex',
        paddingLeft: '0.5rem',
        flexWrap: 'nowrap',
        flexDirection: 'row',
        alignContent: 'center',
        // alignItems: 'baseline',
        alignItems: 'center',
    },
    title: {
        textTransform: 'uppercase',
        lineHeight: '2rem',
        maxHeight: '2rem',
        overflow: 'hidden',
        marginLeft: '0.5rem',
        flexGrow: 1,
        fontSize: '14px',
        color: style.colorPrimary3,
        userSelect: 'none',
    }
}))

const CreateNodePool = ({ currentNodePoolId = null, addNodePoolType = null, closeAddNodePool, downloadTemplate }) => {
    // const [open, setOpen] = useState(false)
    const classes = useStyles()
    // const [name, setName] = useState('')
    const [isUserAvailable, setIsUserAvailable] = useState(false)
    // const [dealId, setDealId] = useState(null)
    const [fileData, setFileData] = useState(null)
    const [nodeName, setNodeName] = useState('');
    const [poolDate, setPoolDate] = useState('');
    const [poolLoading, setPoolLoading] = useState(false)

    const editFormRef = useRef(null)
    const dispatch = useDispatch()
    const { deal, selectedNode, ...otherStor } = useSelector((state: any) => state.securitization)

    useEffect(() => {
        setPoolLoading(false)
    }, [])

    useEffect(() => {
        if (deal?.deal != null)
            setNodeName(`${deal?.deal?.name}.${deal?.deal?.poolNodes ? deal?.deal?.poolNodes.length + 1 : 1}`)
    }, [deal])

    //dealId = null, nodeType = ePoolNodeType.TYPE_POOL_PLAIN
    // useEffect(() => {
    //     if (deal?.deal?.id != null)
    //         setDealId(deal?.deal?.id)
    // }, [deal])

    /*CHECKME!!! pseudocode */
    const handlePoolDateChange = (value: Date) => {
        setPoolDate(value)
    }

    const handleNodeNameChange = (value: string) => {
        setNodeName(value)
    }

    const handleLLDFileLoad = (files: any) => {
        var fileData = null;
        if (files.length != 0) {
            fileData = files[0];
        }
        setFileData(fileData)
    }

    const createPoolNode = (
        dealId = deal?.deal?.id,
        // name = nodeName ?? '',
        nodeType = addNodePoolType ?? ePoolNodeType.TYPE_POOL_PLAIN
    ) => {

        var formData = new FormData()

        //required
        formData.append('DealID', dealId);
        // formData.append('Name', name);
        formData.append('Type', nodeType);

        //optional
        if (poolDate != null)
            formData.append('PoolDate', Localization.FormatDateISO(poolDate))
        if (fileData != null)
            formData.append('FileData', fileData)
        if (currentNodePoolId)
            formData.append('PoolNodeID', currentNodePoolId);

        //console.log('formData', formData)

        actionSecuritization.createPoolNode(formData)(dispatch)
        setPoolLoading(true)
        // runPoolLoadingProcess()
        // for (const value of formData.values()) {
        //     console.log('formData', value);
        // }
    }

    const getTitle = (nodeType) => {
        if (selectedNode?.node?.type == nodeType && selectedNode?.node?.pools?.length && selectedNode?.node?.id == currentNodePoolId)
            return t('RenewPool')

        switch (nodeType) {
            case ePoolNodeType.TYPE_POOL_AT_REGISTRATION_DATE:
                return t('addRegistrationPool')
            case ePoolNodeType.TYPE_POOL_AT_TRANSFER_DATE:
                return t('addTransferPool')
            case ePoolNodeType.TYPE_POOL_PLAIN:
            default:
                return t('addLLDPoold')
        }
    }

    return (
        <Dialog
            disableEscapeKeyDown
            fullWidth
            maxWidth='sm'
            open/*={open}*/
        // onClose={this.onCancelChanges_Local}
        // onBackdropClick={this.onCancelChanges_Local} //умышленно выключено
        >
            {/* <SpinnerBars show={this.props.isBusy} fullScreen content={t('Loading')} /> */}
            <Box className={classes.headerBox}>
                <Typography className={classes.title} >
                    {getTitle(addNodePoolType)}
                    {/* {t('LoadNewPool')} */}
                </Typography>
                <Box component="span" sx={{ pr: 1 }}>
                    <Tooltip title={t("LoadPoolTemplate")} disableInteractive>
                        <Button variant="text"
                            sx={{ fontSize: '14px' }}
                            onClick={downloadTemplate}
                        >
                            {t('Template')}
                        </Button>
                    </Tooltip>
                </Box>

            </Box>
            <Divider />
            {/* <DialogTitle>{t('CreateNewPool')}</DialogTitle> */}
            <DialogContent className={classes.dialogBody} >
                {poolLoading && <SpinnerBars withBackground />}
                <ValidatorForm ref={editFormRef} onSubmit={createPoolNode}>
                    <CreateNodePoolDialog
                        fileData={fileData}
                        poolDate={poolDate}
                        currentNodePoolId={currentNodePoolId}
                        nodeName={nodeName}
                        handleNodeNameChange={handleNodeNameChange}
                        poolDateChanged={handlePoolDateChange}
                        fileLoaded={handleLLDFileLoad}
                    />
                </ValidatorForm>
            </DialogContent>
            <DialogActions sx={{ pt: 0 }}>
                <Button onClick={closeAddNodePool} variant="contained" color="primary">{t('Cancel')}</Button>
                <Button onClick={() => editFormRef?.current?.submit()} disabled={!(fileData && poolDate) || poolLoading} variant="contained" color="secondary">
                    {t('Load')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default CreateNodePool