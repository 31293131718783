import { createTheme, responsiveFontSizes } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    error: {
      main: '#f44336',
    },
    primary: {
      main: '#304FF3',
    },
    secondary: {
      main: '#5c5b5b',
    },
    text: {
      primary: '#191919',
      secondary: '#000',
    },
  },
  zIndex: {
    appBar: 1201,
  },
});

export const UnoTheme = responsiveFontSizes(theme);
