import ServiceBase from "./ServiceBase";
//import Globals from "../Globals";

export default class KeyRateService extends ServiceBase {

    static async getKeyrates(evaluationDate/*, zcycDate*/) {
        var result = await this.requestJson({
            url: "api/DomRf/GetKeyrates",
            method: "GET",
            data: { evaluationDate: evaluationDate/*, zcycDate: zcycDate*/}
        });

        return result;
    }

}
