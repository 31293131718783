import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Theme } from '@mui/material/styles';
import { efDealDataState, efDealValidationState, efNodeValidationState, PoolProcessingStatus } from '@Components/Definitions';
import { makeStyles, createStyles } from '@mui/styles';
import { useTranslation } from "react-i18next";
import { ValidatorForm } from "@Framework/components/Form";
import style from '@Styles/domrf-theme.module.scss';
import { CouponType } from '@Components/Definitions';
import { hexToRgbA } from '@Utilities';
import { actionCreators as actionSecuritization } from '@Store/SecuritizationStore';
import PricingResultsPanel from "../panels/PricingResultsPanel";
import PoolParametersPanel from "../panels/PoolParametersPanel"
import { Box, useTheme, Grid } from '@mui/material';
import SpinnerBars from '@Components/shared/SpinnerBars';
import { isValid as isValidDateFns } from 'date-fns'
import { IterationStatus } from '../../../../components/Definitions'

const useStyles = makeStyles((theme: Theme) => createStyles({
    error: {
        position: 'relative',
        '&:before': {
            content: '""',
            position: 'absolute',
            left: 0, right: 0, bottom: '6px', top: '6px',
            backgroundColor: '#FF000012',
        }
    },
    edited: {
        position: 'relative',
        '&:before': {
            content: '""',
            position: 'absolute',
            left: 0, right: 0, bottom: '6px', top: '6px',
            backgroundColor: hexToRgbA(style.colorPrimary2, 0.1)
        }
    },
    errorMessage: {
        margin: '0 0.5rem',
        color: style.colorError,
    },
    globalRoot: {
        position: 'absolute', left: 0, right: 0, top: 0, bottom: 0,
        paddingTop: '1px',
        '& *': {
            userSelect: 'none',
        },
    },
    tabHeaderButton: {
        padding: '2px 8px'
    },
    paramsTab: {
        // height: '100%',

        '&>*': {
            color: style.colorPrimary3_120,
            padding: '0px 10px 0px 10px',
            fontSize: '1rem',
            letterSpacing: 'initial',
            // lineHeight: '1.2rem',
            // textTransform: 'uppercase',

            '&:hover': {
                color: style.colorPrimary3
            },
        },
        '&::after': {
            content: '""',
            position: 'absolute',
            top: 6, bottom: 6, right: 0,
            width: '2px',
            borderRight: `solid 1px ${style.colorPrimary3_120}`,
        }
        // 
        // cursor: 'pointer'
    },
    activeTab: {
        '& *': {
            color: style.colorPrimary1,
            fontWeight: 'bolder'
        }
    },
    scrollablePanel: {
        position: 'absolute', left: '4px', right: 0, top: 0, bottom: 0,
        [theme.breakpoints.down('xxl')]: {
            position: 'relative'
        },
        overflowY: 'auto',
        '&::-webkit-scrollbar': {

            width: '4px',
        },
        // '&::-webkit-scrollbar:disabled':{
        //     opacity:0,
        // },
        '&:hover': {
            '&::-webkit-scrollbar-track:disabled': {
                backgroundColor: '#0000',
            },
            '&::-webkit-scrollbar-thumb:disabled': {
                background: '#0000',
            },
            '&::-webkit-scrollbar-track': {
                backgroundColor: '#00000008',
            },
            '&::-webkit-scrollbar-thumb': {
                background: '#00000018',
            }
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'unset',
            borderRadius: '3px',
            // border: 'solid 1px #00000000',
            backgroundColor: '#00000000',
            transition: 'all 0.3s ease'
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#0000',
            borderRadius: '3px',
            height: '4px'
        },
    },
    dealsContainer: {
        display: 'flex',
        minHeight: '200px',
        padding: '15px',
        background: `repeating-linear-gradient(45deg, ${style.colorPrimary3_5}, ${style.colorPrimary3_5} 10px, white 10px, white 20px)`
    },
    deals: {
        display: 'flex',
        overflowX: 'auto',
        width: '100%',
        //paddingTop: '15px',
        //paddingBottom: '15px',
        marginRight: '15px'
    },
    dealCard: {
        display: 'flex',
        flexDirection: 'column',
        minWidth: '280px',
        marginRight: '15px'
    },
    addDealButton: {
        alignSelf: 'center'
        //height: '200px',
        //width: '200px'
    },
    itemActionArea: {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
        justifyContent: 'flex-start'
    },
    itemContent: {
        display: 'flex',
        flex: '1 1 auto',
        height: '100%'
    },
    buttonContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    cardSelect: {
        border: '1px solid ' + style.colorPrimary1
    },
    layoutGrid: {
        '& .react-grid-item': {
            padding: '0.2rem'
        }
    },
    infoPanel: {
        display: 'flex',
        justifyContent: 'end',
    },
    panel: {
        //overflow: 'auto'
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
    },
    hideButton: {
        position: 'absolute',
        cursor: 'pointer',
        fontSize: '20px',
        top: '0',
        right: '5px',
        zIndex: '1000'
    },
    hidden: {
        display: 'none'
    },
    tabContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        paddingTop: '1rem',
        flexGrow: 1,
    },
    tabContainerNoData: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: '1rem',
        flexGrow: 1,
    },
    labelColor: {
        color: style.colorPrimary3_120,
        userSelect: 'none',
    },
    colContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    paper: {
        padding: '0.5rem 0',
        flex: '1 1 auto'
    },
    fullHeightPaper: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        padding: '0.5rem 0',
        flex: '1 1 auto'
    },
    parametersPanel: {
        display: 'flex',
        flexDirection: 'column',

        [theme.breakpoints.up('lg')]: {
            height: '100%'
        },
        [theme.breakpoints.down('lg')]: {
            height: '400px'
        }
    },
    dataBlock: {
        position: 'absolute', left: 0, right: 0, top: 0, bottom: 0,
        margin: 0,
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap'
    },
    header: {
        marginLeft: '0.5rem',
        height: '30px', minHeight: '30px', maxHeight: '30px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        overflow: 'hidden',
    },
    headerButtonsBlock: {
        marginLeft: '0.5rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        flexWrap: 'nowrap',
    },
    headerTabsBlock: {
        display: 'flex',
        flexDirection: 'row',
    },
    alertWin: {
        display: 'flex',
        fontSize: '1.3rem',
        color: style.colorPrimary3_40,
        justifyContent: 'center',
        // alignItems: 'center',
    }
}));

const PricingTab = ({
    // deal,
    // pool,
    // iteration,
    // iterationOnlineChanges,
    editPermission,
}) => {

    // console.log('deal', deal)

    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [pool, setPool] = useState(null)
    const {
        deal = null,
        selectedNode = null,
        backupNode = null,
        backupDeal = null,
        operationLoading = false,
        proceededIteration = null,
        ...otherStor } = useSelector((state: any) => state.securitization)
    //const [nodeValidationState, setNodeValidationState] = useState(null)
    //const [dealValidationState, setDealValidationState] = useState(null)
    const [onlineIterationChanges, setOnlineIterationChanges] = useState(null)

    const { validationState: nodeValidationState } = (selectedNode?.node || {});
    // const nodeValidationState = selectedNode?.node?.validationState ?? efNodeValidationState.STATE_NODE_PARAMETERS_INVALID
    const { validationState: dealValidationState } = (deal?.deal || {});
    // const dealValidationState = deal?.deal?.validationState ?? efDealValidationState.STATE_DEAL_PARAMETERS_INVALID

    // var dealValidationState = null
    // var onlineIterationChanges = null

    // console.log('isNodeParametersValid!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!', isNodeParametersValid)


    // const [activeTab, setActiveTab] = useLocalStorage(null, 'Securitization/Dial/Pricing/innerTab') //useState(null)

    // const [localIteration, setLocalIteration] = useState({ ...iteration });
    const [showSpinner, setShowSpinner] = useState(false);
    const [keyrateChartData, setKeyrateChartData] = useState(null);
    const updatedFields = useRef({});
    const theme = useTheme()
    const [formIsValid, setFormIsValid] = useState(false)
    const [needRecalc, setNeedRecalc] = useState(false)
    const [newPoolReceived, setNewPoolReceived] = useState(false);
    const [iteration, setIteration] = useState(null)
    // if (selectedNode?.node?.pools?.length && pool.id != selectedNode?.node?.pools[0]?.id && showSpinner == false)
    //     setShowSpinner(true)
    // else setShowSpinner(false)

    // console.log('selectedNode', selectedNode)
    // console.log('operationLoading', operationLoading)

    useLayoutEffect(() => {
        if (Array.isArray(selectedNode?.node?.pools) && selectedNode?.node?.pools?.length && selectedNode?.node?.pools[0]?.id !== pool?.id)
            setPool(selectedNode.node.pools[0])
        setIteration(selectedNode?.lastIteration)
    }, [selectedNode])

    // var structure = null
    // console.log('deal', deal, pricingResult, proceededIteration)
    // if (selectedNode != null)
    // const checkIterationOnlineChanges = () => {

    //     if (onlineIterationChanges != null) {

    //         updatedFields.current = {};

    //         Object.entries(onlineIterationChanges).forEach(entry => {

    //             if (entry[1] == null) {
    //                 return;
    //             }

    //             //if (entry[1]?.newValue != null)
    //             if (backupNode[entry[0]] != entry[1].newValue)
    //                 updatedFields.current[entry[0]] = { ...entry[1] }//, oldValue: deal.deal[entry[0]] }
    //         });
    //     }
    //     else {
    //         updatedFields.current = {}
    //     }
    // }

    // useEffect(() => {

    //     checkIterationOnlineChanges()

    // }, [onlineIterationChanges])

    useLayoutEffect(() => {

        /*
        if (selectedNode?.node != null
            && Array.isArray(selectedNode?.node?.pools) && selectedNode?.node?.pools?.length
            && selectedNode?.node?.pools[0]?.id == pool?.id
            ) {
            console.log('ser')
            setDealValidationState(deal?.deal?.validationState)
            setNodeValidationState(selectedNode?.node?.validationState)
            setOnlineIterationChanges(selectedNode?.onlineIterationChanges)

        }
        */

        updatedFields.current = []
        if (selectedNode != null && selectedNode.onlineIterationChanges != null)
            Object.entries(selectedNode.onlineIterationChanges).forEach(([key, value]) => {
                if (value !== null && backupNode[key] != value?.newValue) { //CHECKME!!! need a normal comparsion
                    updatedFields.current[key] = value
                }
            })
        // console.log('deal', deal)
        if (deal != null && deal.onlineDealChanges != null)
            Object.entries(deal.onlineDealChanges).forEach(([key, value]) => {
                if (value != null && backupDeal[key] != value?.newValue) { //CHECKME!!! need a normal comparsion
                    updatedFields.current[key] = value
                }
            })

        // console.log('------deal.onlineDealChanges', deal.onlineDealChanges)
        // console.log('updatedFields.current', updatedFields.current)
        setNeedRecalc(updatechangesDetector())
        // console.log('############################ selectedNode?.lastIteration############################ ', selectedNode?.lastIteration, selectedNode)
        if (Array.isArray(selectedNode?.node?.pools)
            && selectedNode?.node?.pools?.length
            && selectedNode?.lastIteration?.poolID != selectedNode?.node?.pools[0]?.id) {
            //console.log('first', selectedNode?.node?.pools[0]?.id)
            setNewPoolReceived(true)
        }
        else
            setNewPoolReceived(false)

    }, [deal, selectedNode, onlineIterationChanges])

    // const mergeKeyrates = (source, dest = []) => {
    //     var i = 0;
    //     for (; i < source?.length; i++) {

    //         if (dest?.length == i) {
    //             dest.push({ ...dest[0] });
    //         }
    //         dest[i].date = source[i].x;
    //         dest[i].key = source[i].y;
    //     }

    //     if (dest?.length - 1 > i) {
    //         dest.splice(i, dest.length - i);
    //     }
    // }

    // const updateIterationByOnlineContents = (updIteration) => {

    //     //setNeedRecalc(updatechangesDetector())

    //     var isChanged = false;

    //     if (updIteration == null)
    //         return isChanged;

    //     if (iterationOnlineChanges?.changes == null)
    //         return isChanged;

    //     //console.log("=========update online changes ", localIteration)
    //     // console.log('updIteration', updIteration)
    //     Object.entries(iterationOnlineChanges.changes).forEach(entry => {
    //         // console.log(`changing name = ${entry[0]} value = ${entry[1].newValue}`, entry, localIteration)
    //         if (entry[0] == 'keyRates') {
    //             var data = JSON.parse(entry[1].newValue).map(x => { return { x: x.Date, y: x.Key } });
    //             // console.log('data', data)
    //             var updRates = updIteration.keyRateGroup ?? {};
    //             // console.log('updRates', updRates)
    //             mergeKeyrates(data, updRates?.keyRates);
    //             // console.log('updRates?.keyRates', updRates)

    //             isChanged = true;
    //             return;
    //         }

    //         if (updIteration[entry[0]] != entry[1].newValue) { //CHECKME!!! need a normal comparsion
    //             updIteration[entry[0]] = entry[1].newValue;
    //             isChanged = true;
    //         }
    //     });
    //     //console.log('updIteration', updIteration,iteratinOnlineChanges)
    //     //deserialize keyrates array
    //     /*
    //     var keyrates = updatedFields.current["keyRateGroup"];
    //     if (keyrates != null) {
    //         updatedFields.current["keyRateGroup"] = JSON.parse(keyrates);
    //     }
    //     */

    //     return isChanged;
    // }

    // const checkIterationOnlineChanges = () => {
    //     if (iterationOnlineChanges?.changes != null) {
    //         Object.entries(iterationOnlineChanges.changes).forEach(entry => {
    //             if (entry?.lastValue)
    //                 delete entry.lastValue
    //             if (entry[1]?.newValue !== deal?.lastIteration[entry[0]])
    //                 updatedFields.current[entry[0]] = { ...entry[1], oldValue: deal?.lastIteration[entry[0]] }
    //             else
    //                 delete updatedFields.current[entry[0]]
    //         });
    //     }
    //     else {
    //         updatedFields.current = {}
    //     }
    // }

    // const lastIterationId = deal?.lastIteration?.id


    /*
    useEffect(() => {

        //the form covers all the tabs and tab switching not causes to re-validate existed data. We do it manually
        setTimeout(() => submitForm?.current?.isFormValid(false), 0);

    }, []);
    */

    // useEffect(() => {

    //     //console.log("=====iterationOnlineChanges", iterationOnlineChanges);

    //     checkIterationOnlineChanges();

    //     setNeedRecalc(updatechangesDetector());

    //     //var isChanged = false;

    //     if (localIteration != null) {
    //         var newIteration = { ...localIteration };
    //         updateIterationByOnlineContents(newIteration);
    //         setLocalIteration(newIteration);

    //         //update keyrate chart data
    //         var chartData = null;
    //         if (newIteration?.keyRateGroup != null) {
    //             chartData = newIteration.keyRateGroup.keyRates.map(x => { return { x: toUnixDate(new Date(x.date)), y: x.key } });
    //             setKeyrateChartData(chartData);
    //         }
    //         else
    //             setKeyrateChartData(null);
    //     }

    // }, [iterationOnlineChanges]);

    // useEffect(() => {

    //     console.log("=====iteration", iteration);
    //     //console.log("=====changes", iterationOnlineChanges);

    //     // var newIteration = { ...iteration };
    //     // updateIterationByOnlineContents(newIteration);

    //     // // console.log("=====iteration after changes", newIteration);

    //     // setLocalIteration(newIteration);

    //     // //update keyrate chart data
    //     // var chartData = null;
    //     // if (newIteration?.keyRateGroup != null) {
    //     //     chartData = newIteration.keyRateGroup.keyRates.map(x => { return { x: toUnixDate(new Date(x.date)), y: x.key } });
    //     //     setKeyrateChartData(chartData);
    //     // }
    //     // else
    //     //     setKeyrateChartData(null);

    // }, [iteration]);

    /*
    const checkFormDataBeforeRecalc = () => {

        //console.log('----checkFormDataBeforeRecalc-----');

        //CHECKME!!!! temporary comment
        
        //submitForm?.current?.isFormValid(false).then((result) => {
        //
        //    //console.log('----checkFormDataBeforeRecalc----- formIsValid: ' + result);
        //
        //    setFormIsValid(result);
        //    if (result)
        //        setNeedRecalc(updatechangesDetector());
        //    else
        //        setNeedRecalc(false);
        //});
    };
    */

    // useEffect(() => {
    //     // console.log('proceededIteration', proceededIteration)
    //     if (proceededIteration != null) {
    //         updatedFields.current = {};
    //     }
    // }, [proceededIteration])

    // useEffect(() => {
    //     if (pricingResult != null) {

    //         if (pricingResult.value != null) {

    //             //update pricing result
    //             var keys = Object.keys(pricingResult.value);

    //             if (keys.length != 0) {
    //                 keys.forEach(key => {
    //                     localIteration[key] = pricingResult.value[key];
    //                 });
    //             }

    //             setLocalIteration({ ...localIteration });
    //         }
    //     }
    // }, [pricingResult]);

    // useEffect(() => {
    //     if (operationLoading != null && operationLoading != showSpinner) {
    //         setShowSpinner(operationLoading);
    //     }
    //     else
    //         setShowSpinner(false);
    //     // setSaving(false)
    // }, [operationLoading]);

    /*
    useLayoutEffect(() => {

        // if (submitForm.current != null)
        //     submitForm.current.isFormValid(false);

        checkFormDataBeforeRecalc();

    }, [deal, localIteration]);
    */

    // const initialData = () => {

    //     setDealState(deal?.dealState ?? DealValidationState.STATE_VALID);
    //     actionSecuritization.resetIterationChanges(deal.deal.id, localIteration.id)(dispatch);
    // }

    const isNumber = (val) => {
        return !!val && isFinite(val)
    }
    const isDate = (val) => {
        const date = Date.parse(val)
        // console.log(val, 'ch_date', date)
        return isValidDateFns(date)
    }
    const getType = (value) => {

        if (isNumber(value?.toString()))
            return 'number';

        if (isDate(value?.toString()))
            return 'date';

        if (value === null)
            return 'nullObject';

        if (value === undefined)
            return 'undefined';

        if (typeof (value) === 'boolean')
            return 'boolean'

        return 'string';
    }

    const updatechangesDetector = () => {
        // console.log('updatechangeDetector')
        // console.log("updated fields =>", updatedFields?.current);

        // console.log('isNodeParametersValid', isNodeParametersValid)
        // console.log('isDealParametersValid', isDealParametersValid)

        if (updatedFields?.current && Object.keys(updatedFields?.current).length == 0)
            return false

        return true

        // return Object.keys(updatedFields?.current).some((key) => {
        //     //console.log('updatedFields.current[key]', updatedFields.current[key])
        //     // console.log('val', updatedFields.current[key]?.newValue?.toString(), updatedFields.current[key]?.oldValue?.toString())
        //     if (getType(updatedFields.current[key]?.newValue) == 'date')
        //         return (Date.parse(updatedFields.current[key].newValue?.toString()) !== Date.parse(updatedFields.current[key].oldValue?.toString()))

        //     return updatedFields.current[key]?.newValue?.toString() !== updatedFields.current[key]?.oldValue?.toString()
        // })
    }

    // const onApplyKeyRate = (data: any) => {

    //     var rates = data.editModel.map(x => { return { Date: x.x, Key: x.y }; });
    //     var keyRates = JSON.stringify(rates);
    //     console.log('keyRates ', keyRates)
    //     console.log('updatedFields', updatedFields)
    //     updatedFields.current.keyRates = { ...updatedFields?.current?.keyRates ?? {}, newValue: keyRates }; //stub to show changes at UI, full update will receive from hub
    //     var iterationKeyrates = { ...localIteration.keyRateGroup };
    //     iterationKeyrates.keyRates = [...localIteration?.keyRateGroup?.keyRates ?? []];

    //     mergeKeyrates(data.editModel, iterationKeyrates.keyRates);

    //     setLocalIteration({ ...localIteration, keyRateGroup: iterationKeyrates });

    //     var update = {
    //         IterationID: localIteration.id, keyRates: { Value: keyRates }
    //     };
    //     //we collect all changes
    //     //Object.keys(updatedFields.current).forEach(name => update[name] = { Value: updatedFields.current[name].newValue });
    //     console.log("onApplyKeyRate => send iteration change");
    //     console.log(update);
    //     actionSecuritization.sendIterationChange(update)(dispatch);

    //     setDealState(state => state &= ~DealValidationState.STATE_HISTORICAL_KEYRATE_OUTDATED);
    // }

    // const firstDayOfMonth = (date: number) => {
    //     var localDate = new Date(date);
    //     return new Date(localDate.getFullYear(), localDate.getMonth(), 1, 0, 0, 0, 0);
    // }

    useLayoutEffect(() => {
        // console.log('isNodeParametersValid, isDealParametersValid', isNodeParametersValid, isDealParametersValid)
        // console.log('updatedFields', updatedFields)
        // console.log('updatechangesDetector', updatechangesDetector())
        setNeedRecalc(updatechangesDetector())
    }, [dealValidationState, nodeValidationState])





    // const updateFields = (updates: any) => {

    //     var keys = Object.keys(updates);
    //     if (keys.length == 0)
    //         return;

    //     var iterationChanges = { ...localIteration };
    //     var update = { IterationID: localIteration.id, ...updates };

    //     keys.forEach(key => {
    //         iterationChanges[key] = updates[key].Value;
    //         updatedFields.current[key] = { ...updatedFields.current[key], newValue: updates[key].Value };
    //     });

    //     setLocalIteration(iterationChanges);
    //     // console.log('iterationChanges', iterationChanges)
    //     actionSecuritization.sendIterationChange(update)(dispatch);
    // }

    // const onUpdateField = (name, value) => {

    //     updateFields({ [name]: { Value: value } });
    //     /*
    //     var iterationChanges = { ...localIteration, [name]: value };
    //     var update = { IterationID: localIteration.id, [name]: { Value: value } };

    //     updatedFields.current[name] = { ...updatedFields.current[name], newValue: value } //stub to show changes at UI, full update will receive from hub

    //     setLocalIteration(iterationChanges);

    //     actionSecuritization.sendIterationChange(update)(dispatch);
    //     */
    // };

    const saveEdit = async (isManualSave = false, isForceToSave = false) => {

        // // console.log('run calc, localIteration=', localIteration, 'pool=', pool)
        // // setSaving(true)
        // var keys = Object.keys(updatedFields.current);
        // // console.log('localIteration', localIteration)
        // if (keys.length != 0 || localIteration.status === IterationStatus.STATUS_FAILED || localIteration.poolID != pool.id) {
        //     //no need anymore to run iteration
        var update = {
            nodeID: selectedNode.node.id
        };
        /*
        Object.keys(updatedFields.current).forEach((name) => {
            if (name == 'keyRates') {
                update['keyRateGroup'] = {
                    Value: { keyRates: JSON.parse(updatedFields.current[name].newValue) }
                }
            }
            else
                update[name] = { Value: updatedFields.current[name].newValue };
        });
        */

        actionSecuritization.proceedIteration(update)(dispatch);
    }

    // useEffect(() => {

    //         actionSecuritization.getDealsWithChanges()(dispatch) //need for update deal type in deallist

    // }, [deal])

    // const downloadFlows = () => {
    //     window.open('/api/Securitization/DownloadPoolFlows?poolid=' + pool.id, '_blank');
    // }

    // const showKeyRate = (localIteration.couponType == CouponType.TYPE_KEYRATE_AND_PREMIUM || pool?.preferenceFraction > 0 || pool?.familyFraction > 0);
    // console.log(`couponType = ${deal?.deal?.couponType} pool.preferenceFraction = ${pool.preferenceFraction} pool.familyFraction = ${pool.familyFraction}`);
    //const isIterationValid = () => iteration?.validationState === 0;
    // console.log('iteration', iteration)
    //const isPricingParametersInvalidOnly = () => (iteration?.validationState == IterationValidationState.STATE_PRICING_PARAMETERS_INVALID);

    //const keyRateIsInvalid = (deal.deal?.couponType == CouponType.TYPE_KEYRATE_AND_PREMIUM && deal.deal?.keyRateGroupID == null && updatedFields.current?.keyRates == null);

    // const showParamsTab = () => {
    //     return (
    //         <Box sx={{ flexGrow: 1, p: '0', height: '100%', position: 'relative' }}>
    //             <Box className={classes.scrollablePanel} >
    //                 <Box className={classes.scrollablePanel} sx={{ overflowY: 'scroll !important' }}>
    //                     <PoolParametersPanel
    //                         updatedFields={updatedFields.current ? updatedFields.current : {}}
    //                         // deal={deal}
    //                         // pool={pool}
    //                         // iteration={localIteration}
    //                         // isEdit={editPermission}
    //                         onFieldUpdate={onUpdateField}
    //                     // onFieldLeft={saveEdit}
    //                     />
    //                 </Box>
    //             </Box>
    //         </Box>
    //     )
    // }

    // console.log('localIteration', localIteration)
    // const showKeyrateTab = () => {

    //     if (!localIteration || !isValid(parseISO(localIteration.zcycDate))) {
    //         return (
    //             <Grid item xs={12} className={classes.tabContainerNoData}>
    //                 <h4 className={classes.labelColor}>{t("FillZCYCDateAtParameters")}</h4>
    //             </Grid>
    //         )
    //     }
    //     else {
    //         return (
    //             <Grid item xs={12} sx={{ mt: 1 }}>
    //                 {/* Attention message here*/}
    //                 {keyRateIsInvalid && <Box><WarningAmber className={classes.errorMessage} />{t("KeyrateGroupEmptyWarning")}</Box>}
    //                 {
    //                     // <Paper className={classes.paper}>
    //                     <KeyRatePanel
    //                         keyrateChartData={keyrateChartData}
    //                         //zcycDate={new Date(localIteration.zcycDate)}
    //                         //evaluationDate={new Date(localIteration.zcycDate)}
    //                         isEditable={editPermission}
    //                         isTrackChanges={!keyRateIsInvalid}
    //                         //onLoaded={onApplyLoadedKeyRate}
    //                         onApply={onApplyKeyRate} />
    //                     // </Paper>
    //                 }
    //             </Grid>)
    //     }
    // }

    // const checkKeyrateErrors = () => {
    //     // console.log('updatedFields', updatedFields.current)
    //     if (localIteration?.couponType != CouponType.TYPE_KEYRATE_AND_PREMIUM)
    //         return '';


    //     if (dealState & DealValidationState.STATE_HISTORICAL_KEYRATE_OUTDATED != 0) {

    //         if (keyRateIsInvalid)
    //             return classes.error;
    //         else if (updatedFields.current?.keyRates != null && updatedFields.current.keyRates?.newValue !== updatedFields.current.keyRates?.oldValue)
    //             return classes.edited;
    //     }
    //     else if (keyRateIsInvalid) {
    //         return classes.error;
    //     }
    //     else if (updatedFields.current?.keyRates != null && updatedFields.current.keyRates?.newValue !== updatedFields.current.keyRates?.oldValue) {
    //         return classes.edited;
    //     }
    // }

    // const innerTabs = [
    //     { id: 'params', order: 0, visible: true, title: t('PricingTabParamsTitile'), callback: showParamsTab },
    // ]

    // const activeTabOut = () => {
    //     if (!innerTabs || !innerTabs.length)
    //         return null

    //     const activeTabsIndex = innerTabs.findIndex(tab => tab.id === activeTab)
    //     if (activeTabsIndex == -1)
    //         return null
    //     return innerTabs[activeTabsIndex].callback()
    // }

    // useEffect(() => {
    //     if ((!innerTabs || !innerTabs.length || (activeTab && innerTabs.find(x => x.id == activeTab)?.visible)))
    //         return
    //     setActiveTab(innerTabs[0].id)
    // }, [innerTabs, activeTab])


    const showKeyRate = deal?.deal?.couponType == CouponType.TYPE_KEYRATE_AND_PREMIUM || ((deal?.deal?.dataState & efDealDataState.STATE_KEYRATE_CHART_DATA_REQUIRED) == 1) /*((deal?.deal?.validationState & efDealValidationState.STATE_KEYRATE_REQUIRED) != 0); /*|| pool?.preferenceFraction > 0 || pool?.familyFraction > 0*/
    const keyRateIsInvalid = (showKeyRate && deal.deal?.keyRateGroupID == null && updatedFields.current?.keyRates == null);


    // console.log('dealPT', deal)
    if (deal == null || pool == null)
        return (
            <Box className={classes.alertWin}>
                {deal == null ?
                    t('SelectDeal')
                    :
                    t('PleaseLoadPool')}
            </Box>
        )

    if ((deal != null && pool != null /*&& (isPricingParametersInvalidOnly() || isIterationValid())*/))
        return (<>
            {/* {operationLoading && <SpinnerBars withBackground />}*/}
            <Grid className={classes.globalRoot} container>
                <Grid item xs={12} xxl={5.65} sx={{ position: 'relative', p: 0 }}>
                    <Box className={classes.scrollablePanel} sx={{ mt: '2px' }}>
                        <PoolParametersPanel
                            newPoolReceived={newPoolReceived}
                        />
                    </Box>
                </Grid>

                <Grid item xs={12} xxl={6.35} sx={{ position: 'relative', p: 0 }} /*className={classes.parametersPanel}*/>
                    <PricingResultsPanel
                        needRecalc={
                            (dealValidationState == efDealValidationState.STATE_VALID || (dealValidationState == efDealValidationState.STATE_KEYRATE_REQUIRED && (deal.deal.keyRateGroup != null || deal.deal.keyRateGroupID != null)))
                            && pool?.status == PoolProcessingStatus.STATUS_SUCCESS
                            && ((nodeValidationState & efNodeValidationState.STATE_NODE_PARAMETERS_INVALID) == 0)
                            && (needRecalc || newPoolReceived || iteration?.status === IterationStatus.STATUS_FAILED
                                || (nodeValidationState & efNodeValidationState.STATE_ITERATION_OUTDATED) != 0
                                || selectedNode?.structure == null
                            )
                            && (iteration?.status !== IterationStatus.STATUS_PROCESSING)
                        }
                        saveFunc={saveEdit}
                        isEdit={editPermission}
                        newPoolReceived={newPoolReceived}
                    />
                </Grid>
            </Grid >
        </>
        )
}

export default PricingTab;