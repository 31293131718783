import Toolbar from '@mui/material/Toolbar';
import * as React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useResolutionSwitch } from '../../uno-react';
import { IDataGrid } from '../DataGridInterfaces/IDataGrid';
import { SearchTextInput } from '../Filtering/SearchTextInput';
import { ExportButton } from './ExportButton';
import { CreateNewButton } from './CreateNewButton';
import { GridOptions } from '../DataGrid/GridOptions';
import { ColumnModel, Action, ActionType } from '../Common';
import { FixedLinearProgress, ConfirmationDialog } from '../../uno-material-ui';
import { Typography } from "@mui/material";

const useStyles = makeStyles({
    mobileSpacer: {
        flexShrink: 1,
    },
    spacer: {
        flex: '1 1 45%',
    },
    floatingHeader: {
        position: 'sticky',
        top: 0,
        backgroundColor: 'inherit',
        zIndex: 100
    }
});

const outerWidth = 800;
const timeout = 400;

interface IProps {
    gridOptions: GridOptions;
    gridName: string;
    grid: IDataGrid;
}

export const GridToolbar: React.FunctionComponent<IProps> = ({ gridOptions, gridName, grid }) => {

    const classes = useStyles();

    const [isMobileResolution] = useResolutionSwitch(outerWidth, timeout);

    const handleButtonClick = (action: Action | null, row: any) => {

        if (action != null && action.onClick != null && typeof action.onClick === 'function') {
            var result = action.onClick(action, row);
            if (result === true) {
                return;
            }
        }

        grid.api.createNewRecord();
    }

    var column = grid.state.columns.find(x => x.IsAction);
    var action: Action | null = null;
    if (column != null) {
        action = (column.Actions != null) ? column.Actions.find(y => y.Type == ActionType.TYPE_ADD) || null : null;
    }

    if (gridName == null && gridOptions.customItems == null && !gridOptions.canExport && !gridOptions.canPrint && !gridOptions.canSearch) {
        return (
            <div className={gridOptions.floatingToolbar ? classes.floatingHeader : ''}>
                <FixedLinearProgress isLoading={grid.state.isLoading} />
            </div>
        );
    }

    return (
        <div className={gridOptions.floatingToolbar ? classes.floatingHeader : ''}>
            <Toolbar data-testid='grid-toolbar'>
                <Typography variant="h4">{gridName}</Typography>
                <div className={isMobileResolution ? classes.mobileSpacer : classes.spacer} />
                {gridOptions.customItems && gridOptions.customItems()}
                {action != null && (
                    <CreateNewButton
                        onButtonClick={() => handleButtonClick(action, null)}
                        toolTip={action.Name || gridOptions.locale.CreateNewRecord}
                    />
                )}
                {gridOptions.canExport && (
                    <ExportButton
                        type='csv'
                        gridName={gridName}
                        exportTo={grid.api.exportTo}
                        filteredRecordCount={grid.state.filteredRecordCount}
                        data-testid='export-button-csv'
                        toolTip={gridOptions.locale.Export}
                    />
                )}
                {gridOptions.canPrint && (
                    <ExportButton
                        type='print'
                        gridName={gridName}
                        exportTo={grid.api.exportTo}
                        filteredRecordCount={grid.state.filteredRecordCount}
                        data-testid='export-button-print'
                        toolTip={gridOptions.locale.Print}
                    />
                )}
                {gridOptions.canSearch && (
                    <SearchTextInput
                        searchText={grid.state.searchText}
                        updateSearchText={grid.api.updateSearchText}
                        data-testid='search-text-input'
                        toolTip={gridOptions.locale.Search}
                    />
                )}
            </Toolbar>
            <FixedLinearProgress isLoading={grid.state.isLoading} />
        </div>
    );
};
