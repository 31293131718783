import { Box } from '@mui/material'
import React from 'react'
import Alert from '../components/Alert'
import DownloaderAlert from '../../downloader/DownloaderAlert'
import { useSelector } from 'react-redux'
import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => createStyles({
    rootLayout: {
        pointerEvents: 'none',
        position: 'absolute', left: 0, right: 0, top: '70px', bottom: 0,
        zIndex: '9999',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        width: '100%',
    }
}))
const Notifications = () => {
    const { alerts = [] } = useSelector(state => state.alerts)
    const { downloads = [] } = useSelector(state => state.downloads)
    const cls = useStyles()
    
    return (
        <Box className={cls.rootLayout}>
            {alerts.map(alert => <Alert key={`alert_${alert.id}`} id={alert.id} text={alert.message} color={alert.color} timeout={6000} />)}
            {downloads.map(download => <DownloaderAlert key={`downloadAlert_${download.id}`} id={download.id} link={download?.link} />)}
        </Box>
    )
}

export default Notifications