import CommonService from "../services/CommonService";
import AccountService from "../services/AccountService";
import { ProcessingState } from "@Components/Definitions";
import * as Sidebar from "@Store/SidebarStore";
import * as Account from "@Store/AccountStore";

export const Actions = {

    SetTitle: "SET_NAVBAR_TITLE",
    Init: "TOP_NAVBAR_INIT",
    ShowSidebar: "TOP_NAVBAR_SHOW_SIDEBAR",
    SidebarVisibilityChanged: "TOP_NAVBAR_SIDEBAR_VISIBILITY_CHANGED"
}

export const actionCreators = {
    setTitle: (val) => async (dispatch, getState) => {
        // console.log(val)
        dispatch({ type: Actions.SetTitle, payload: val})
    },

    init: () => async (dispatch, getState) => {
        dispatch({ type: Actions.Init })
    },

    showSidebar: (show) => async (dispatch) => {
        dispatch({ type: Actions.ShowSidebar, isVisible: show });
        dispatch({ type: Sidebar.Actions.ShowSidebar, isVisible: show });
    },

    logoutRequest: () => async (dispatch) => {
        await Account.actionCreators.logoutRequest()(dispatch);
    }
};

const initialState =
{
    buttonVisible: undefined,
    title:''
};

export const reducer = (currentState, incomingAction) => {

    if (currentState === undefined)
        return initialState; 


    const action = incomingAction;

    switch (action.type) {
        case Actions.SetTitle:
            {
                return {
                    ...currentState,
                    title: action.payload
                };
            }

        case Actions.Init:
            {
                return initialState;
            }

        case Actions.ShowSidebar:
            {
                return {
                    ...currentState,
                    buttonVisible: !action.isVisible
                };
            }
        case Actions.SidebarVisibilityChanged:
            {
                return {
                    ...currentState,
                    buttonVisible: !action.isVisible
                };
            }

        default:
            return currentState || initialState;
    }
};
