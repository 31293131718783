import { useState, useEffect } from 'react';

import Button from '@mui/material/Button';
import RolesSelectComponent from '@Components/shared/RolesSelectComponent';

import { Theme } from '@mui/material';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { useTranslation } from 'react-i18next'
import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) => createStyles({
    listText: {
        fontSize: '1.15rem'
    },
    dialogContent: {
        height: '50%'
    }
}));

interface IProps {

    open: boolean;
    onChange: any;
    onApplyChanges: any;
    onCancelChanges: any;

    permissions: any[];
    userPermissions: any;
    roles: any[];
}

const UserPermissionsDialog = ({
    open,
    userPermissions,
    permissions,
    roles,
    onChange,
    onApplyChanges,
    onCancelChanges
}: IProps) => {

    const classes = useStyles();
    const { t } = useTranslation();

    const [selectedRoles, setSelectedRoles] = useState([]);
    //CHECKME!!! not used, implement per-user permissions
    //const [selectedPermissions, setSelectedPermissions] = useState('');
    const [selectedUserPermissions, setSelectedUserPermissions] = useState('');


    useEffect(() => {

        var selectedRoles = userPermissions?.roles || [];
        if (selectedRoles.length != 0)
            selectedRoles = roles.filter(item => selectedRoles.findIndex(x => x == item.id) != -1);

        var permissions = userPermissions?.permissions || '';

        setSelectedRoles(selectedRoles);
        setSelectedUserPermissions(permissions);

    }, [userPermissions]);

    const handleSubmit = () => {
        onApplyChanges({ roles: selectedRoles, permissions: selectedUserPermissions });
    }

    const handleReject = (event: any, reason: string) => {
        if (reason == 'backdropClick') {
            event.preventDefault();
            return;
        }

        onCancelChanges(event);
    }

    //const onPermissionsChanged = (newPermissions: string) => {
        //setSelectedPermissions(newPermissions);
    //}

    const onUserPermissionsChanged = (newPermissions: string) => {

        //CHECKME!!!
        //compare with roles permissions and remove duplicates
        setSelectedUserPermissions(newPermissions);
    }

    const onRolesChanged = (selectedRoles: any[]) => {
        setSelectedRoles(selectedRoles);
    }

    return (
        <Dialog
            classes={{ paper: classes.dialogContent }}
            disableEscapeKeyDown
            fullWidth
            maxWidth='md'
            open={open}
            onClose={handleReject}>
            <DialogTitle>{t('RolesAndPermissions')}</DialogTitle>
            <DialogContent>
                <RolesSelectComponent
                    availableRoles={roles}
                    permissions={permissions}
                    selectedRoles={selectedRoles}
                    userPermissions={selectedUserPermissions}
                    //onPermissionsChanged={onPermissionsChanged}
                    onRolesChanged={onRolesChanged}
                    onUserPermissionsChanged={onUserPermissionsChanged}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleReject} variant="contained" color="primary">{t('Cancel')}</Button>
                <Button onClick={handleSubmit} variant="contained" color="secondary">{t('Save')}</Button>
            </DialogActions>
        </Dialog>
    );
}

export default UserPermissionsDialog;