import { red } from '@mui/material/colors';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';

import withTheme from '@mui/styles/withTheme';

// const defaultTheme = createTheme();

// console.log('defaultTheme', defaultTheme)
//const light = defaultTheme.palette.mode === 'light';
//const bottomLineColor = light ? 'rgba(0, 0, 0, 0.42)' : 'rgba(255, 255, 255, 0.7)';

// A custom theme for this app

const muiThemeInner = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            iPadAirP: 820,
            md: 920,
            iPadAirL: 1180,
            lg: 1200,
            xl: 1400,
            xxl: 1520,
        },
    },

    typography: {
        // Use the system font.
        fontFamily: "'DINPro', 'Helvetica Neue', Arial",
        //fontSize: 12 //total font size
    },

    palette: {
        // action:{
        //     selected:'#8BC540b3'
        // },
        mode: 'light',

        primary: {
            light: '#557b8e', //+20%
            main: '#2F444E',
            dark: '#090d0e', //-20%
        },

        secondary: {
            light: '#bbdd8e', //+20%
            main: '#8BC540',
            dark: '#557a25', //-20%
        },

        default: {
            light: '#557b8e', //+20%
            main: '#2F444E',
            dark: '#090d0e', //-20%
        },

        error: {
            main: red.A700,
        },
        background: {
            default: '#fff',
        }
    },

    components: {

        MuiButton: {
            variants: [
                {
                    props: { variant: 'contained' },
                    style: {
                        color: "white"
                    }
                }],
            styleOverrides: {
                // Name of the styleSheet         
                root: {
                    fontFamily: "DINPro-Bold",
                    fontWeight: "700",
                    fontSize: ".777rem",
                    letterSpacing: "1.9px",
                }
            }
        },

        MuiIconButton: {
            styleOverrides: {

                sizeSmall: {
                    padding: '3px'
                }
            }
        },

        MuiTextField: {

            defaultProps: {
                // The props to change the default for.
                variant: "standard",
                helperText: ""
            }
        },
        MuiInput: {
            styleOverrides: {
                underline: {

                    '&:after': {
                        transformOrigin: "left"
                    },

                    '&:hover:not($disabled):before': {
                        borderBottom: '1px solid ${defaultTheme.palette.text.primary}',
                    }

                }
            }
        },

        Toolbar: {
            styleOverrides: {
                toolbar: {
                    borderBottom: '1px solid rgba(224, 224, 224, 1)'
                }
            }
        },

        Cell: {
            styleOverrides: {
                cell: {
                    borderRight: '1px solid rgba(224, 224, 224, 1)',
                    borderBottom: '1px solid rgba(224, 224, 224, 1)'
                }
            }
        },
        BandedHeaderCell: {
            styleOverrides: {
                beforeBorder: {
                    borderLeft: '1px solid rgba(224, 224, 224, 1)'
                },
                headerCellBorder: {
                    borderRight: '1px solid rgba(224, 224, 224, 1)'
                }
            }
        },

        //TableHeaderCell: {
        //    cell: {
        //        color: '#557b8e',
        //        fontSize: '14px',
        //        whiteSpace: 'normal',
        //        lineHeight: '1.2rem'
        //    }
        //},

        TableFixedCell: {
            styleOverrides: {
                dividerRight: {
                    borderRight: '1px solid rgba(224, 224, 224, 1)'
                },
                dividerLeft: {
                    borderLeft: '1px solid rgba(224, 224, 224, 1)'
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    padding: '0 5px 0 5px'
                },
                head: {
                    color: '#557b8e',
                    fontSize: '14px',
                    whiteSpace: 'normal !important',
                    lineHeight: '1.2rem'
                },
                body: {
                    fontSize: '16px',
                    borderWidth: '0'
                }
            }
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    '&:nth-of-type(even)': {
                        backgroundColor: '#f7f7f7'
                    }
                }
            }
        },
        MuiSpeedDial: {
            styleOverrides: {
                root: {
                    height: '30px'
                },
                fab: {
                    height: '30px',
                    width: '30px',
                    minHeight: '30px',
                    minWidth: '30px',
                    lineHeight: 'inherit'
                },
                actions: {
                    zIndex: 1
                }
            }
        },
        MuiSpeedDialIcon: {
            styleOverrides: {
                root: {
                    height: 'inherit' //fix icon moved at bottom
                }
            }
        },

        MuiFormControlLabel: {
            styleOverrides: {
                label: {
                    color: "inherit",
                    fontFamily: "DINPro-Bold",
                    fontWeight: "700",
                    fontSize: ".777rem",
                    letterSpacing: "1.9px",
                    textTransform: "uppercase"
                }
            }
        },

        //CHECKME!!! when this shit will work, remove style MuiTooltip-tooltip from sass
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    fontSize: "16px"
                }
            }
        },

        MuiTab: {
            styleOverrides: {
                root: {
                    fontSize: "16px"
                }
            }
        },
        MuiCellButton: {
            styleOverrides: {
                root: {

                }
            }
        }

        //
        //MuiTab:
        //{
        //
        //    root: {
        //
        //        MuiButtonBase: {
        //            root: {
        //                "&:hover": {
        //                    color: "red" //"${defaultTheme.palette.text.secondary}"
        //                }
        //            }
        //        }
        //    }
        //}
        //
        //
        //MuiTabs: {
        //    root: {
        //        borderBottom: '1px solid #76787A' //gray
        //    }
        //}
        //
        // 
        //MuiMenu: {
        //    paper: {
        //        margin: 0,
        //        background: "red"
        //    }
        //}
        //
        //
        //MuiTableSortLabel: {
        //    root: {
        //        "& .$MuiSvgIcon-root": {
        //            height: "1rem",
        //            margin: 0
        //        }
        //    }
        //}
        //
        //
        //MuiSvgIcon:
        //{
        //    root: {
        //        height: '1rem',
        //        margin: 0
        //    }
        //}
        //
    }
});

const muiTheme = responsiveFontSizes(muiThemeInner);

export default muiTheme;
