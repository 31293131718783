import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import "@Styles/pages/InstrumentsPage.scss";
import Globals from "@Base/Globals";

import * as AccountStore from "@Store/AccountStore";
import { withRouter } from "../components/shared/CustomRouter";
import { Paper } from "@mui/material";

import { Theme } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import List from '@mui/material/List';
import ListItem, { ListItemProps } from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import SecurityIcon from '@mui/icons-material/Security';
import DraftsIcon from '@mui/icons-material/Drafts';
import Button from "@mui/material/Button";

import { Container } from '@mui/material';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import SecuritySubPage from '@Pages/profile/SecuritySubPage';
import CommonSubPage from '@Pages/profile/CommonSubPage';

import { ValidatorForm, TextValidator } from "@Framework/components/Form";
import InstrumentsList from "@Components/shared/InstrumentsList";
import { Spinner } from '../components/shared/Spinner';
import Localization from '@Components/Localization';
import { withTranslation } from "react-i18next";

const styles = (theme: Theme) => ({

    container: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '0.5rem'
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'row',
        flex: '1 1 auto'
    },
    listContainer: {
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1 auto',
        maxWidth: 250,
        marginRight: '1rem',
        backgroundColor: theme.palette.background.paper,
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1 auto'
    },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'row',
        paddingTop: '15px',
        justifyContent: 'stretch'
    },
    buttonOk: {
        marginRight: '15px',
        width: '100%'
    },
    buttonCancel: {
        width: '100%'
    },
    menuContent: {
        flex: '1 1 auto'
    }
});

interface IProps {
    init: any;
    classes: any;
    getUserSettings(): any;
    updateUserSettings(data: any): void;
    user: any;
    t: any;
}

interface IState {
    selectedIndex: number;
    user: any;
    changedData: any;
    isChanged: boolean;
}

class ProfilePage extends React.Component<IProps, IState> {

    constructor(props) {
        super(props);
        this.state = {
            selectedIndex: 0,
            user: null,
            changedData: null,
            isChanged: false
        }
    }

    componentDidMount() {

        this.props.init();
        this.props.getUserSettings();
    }

    componentDidUpdate(prevProps, prevState) {

        if (this.state.user == null && this.props.user != this.state.user) {
            this.setState({ user: this.props.user });
        }
    }

    handleListItemClick = (event, index) => {
        this.setState({ selectedIndex: index });
    }

    handleSettingsChanged = (data: any) => {
        this.setState({ isChanged: true, user: { ...this.state.user, ...data}, changedData: { ...this.state.changedData, ...data } });
    }

    handleDataSave = () => {

        this.props.updateUserSettings(this.state.changedData);
        this.setState({ isChanged: false, changedData: null });
    }

    handleDataRestore = () => {
        this.setState({ isChanged: false, changedData: null });
    }

    render() {

        const { classes, t } = this.props;

        return (
            <Container className={classes.container}>
                <div>
                    <h3>{t('Profile')}</h3>
                </div>
                <div className={classes.contentContainer}>
                    <div className={classes.listContainer}>
                        <Paper className={classes.paper}>
                            <List component="nav" aria-label="common information">
                                <ListItem
                                    button
                                    selected={this.state.selectedIndex === 0}
                                    onClick={event => this.handleListItemClick(event, 0)}
                                >
                                    <ListItemIcon>
                                        <LibraryBooksIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={t('Common')} />
                                </ListItem>

                                {
                                    (!Globals.user.isEmployee) &&
                                    (
                                        <ListItem
                                            button
                                            selected={this.state.selectedIndex === 1}
                                            onClick={event => this.handleListItemClick(event, 1)}
                                        >
                                            <ListItemIcon>
                                                <SecurityIcon />
                                            </ListItemIcon>
                                            <ListItemText primary={t('Security')} />
                                        </ListItem>
                                    )
                                }
                            </List>
                        </Paper>
                        <div className={classes.buttonsContainer}>
                            <Button className={classes.buttonOk} variant="contained" color="secondary" disabled={!this.state.isChanged} onClick={this.handleDataSave}>
                                {t('OK')}
                            </Button>
                            <Button className={classes.buttonCancel} variant="contained" color="primary" disabled={!this.state.isChanged} onClick={this.handleDataRestore}>
                                {t('Cancel')}
                            </Button>
                        </div>
                    </div>
                    <div className={classes.menuContent}>
                        <CommonSubPage visible={this.state.selectedIndex === 0} data={this.state.user} settingsChanged={this.handleSettingsChanged} />
                        {
                            (!Globals.user.isEmployee) && <SecuritySubPage visible={this.state.selectedIndex === 1} />
                        }
                    </div>
                </div>
            </Container>
        );
    }
}

var component = connect(
    state => state.account, // Selects which state properties are merged into the component's props
    AccountStore.actionCreators // Selects which action creators are merged into the component's props
)(withStyles(styles)(ProfilePage));

export default withRouter(withTranslation('translations')(component));