import { connect } from 'react-redux';
import * as React from 'react';
// import clsx from 'clsx';
import * as SensitivityStore from "@Store/SensitivityStore";
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';

import withStyles from '@mui/styles/withStyles';

import Typography from '@mui/material/Typography';
import Localization from '../../../../components/Localization';
import Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import HC_exporting_data from 'highcharts/modules/export-data';
import HighchartsReact from 'highcharts-react-official';
import HC_more from "highcharts/highcharts-more"; //module
import HC_draggable_points from "highcharts/modules/draggable-points";

import { setHighchartsLocalization, copyTextToClipboard, toUnixDate } from '@Utilities';
import style from '@Styles/domrf-theme.module.scss';
import { withTranslation } from "react-i18next";
import { Box, Divider, Stack } from '@mui/material';
import { BackspaceOutlined, HelpOutline } from '@mui/icons-material';
import { addMonths } from 'date-fns';
import TooltipInfoLabel from '../../../../components/shared/TooltipInfoLabel';

const styles = {

    controlsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'start',
        paddingBottom: '1rem'
    },
    editToolbar: {
        position: 'absolute',
        top: '0.1rem',
        right: '1.7rem',
        display: 'inline-flex',
        flexDirection: 'row'
    },
    buttonsContainer:
    {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'flex-end',
        padding: '0 1.7rem'
    }
};

const chartsColours = [
    style.colorPrimary1,
    style.colorPrimary2,
    style.colorPrimary3,

    style.colorPrimary1_80,
    style.colorPrimary2_80,
];

interface IProps {
    classes: any;
    t: any;
    getDOMRFFactoryStatistics?: (bondId: number, date: string | Date) => void;
    statistics: any;
    onHidePanel?: () => void;
    averageCPR: number;
    chartName: string;
    bondId: number | null;
    onApply: (data: any) => void;
    editedCPR: any[];
    onCPREdit: (data: any[]) => void;
    evaluationDate: any;
}

interface IState {
    title: string;
    sensitivityChartOptions: any;
    minHistoryDate: number | null;
    maxHistoryDate: number | null;
    minEditDate: number | null;
    maxEditDate: number | null;
    minEditValue: number;
    maxEditValue: number;
    minHistoricalYValue: number;
    maxHistoricalYValue: number;
    isChartChanged: boolean;
    couponDates: any[];
    actionBuffer: any[];
    actionIndex: number;
    prevBondId: number | null;
    prevEvaluationDate: Date | string | null;
}

class SensitivityPanel extends React.Component<IProps, IState> {

    constructor(props) {
        super(props);

        HC_exporting(Highcharts);
        HC_more(Highcharts);
        HC_draggable_points(Highcharts);

        setHighchartsLocalization(Highcharts, this.props.t);

        this.state = {

            title: this.props.t('ConventionNameZCYCComparisonWindow'),

            sensitivityChartOptions: {

                customData: () => this,
                chart: {
                    plotAreaHeight: 350,
                    zoomType: undefined,
                    events: {
                        load: (e) => {
                            // console.log(Highcharts.Axis);
                            // console.log(this.state);
                        },
                        click: function (e) {
                            // console.log('1',this);
                            if (this.state.minEditDate == null || this.state.maxEditDate == null)
                                return;

                            var chart = this.sensitivityChart.chart;

                            var xValue = Math.round(e.xAxis[0].value);
                            var yValue = e.yAxis[1].value;

                            var { x, y } = this.snapToGrid(xValue, yValue);

                            if (x < this.state.minEditDate || x > this.state.maxEditDate)
                                return;

                            if (y < this.state.minEditValue || y > this.state.maxEditValue)
                                return;

                            if (chart.series[2].data.findIndex(item => item.x == x) != -1)
                                return; //point already have

                            // Add it
                            chart.series[2].addPoint({ x, y }, true, false, false, false);

                            this.addAction({ type: 'addPoint', x: x, y: y });

                        }.bind(this)
                    }

                },

                exporting:
                {
                    enabled: false
                },

                plotOptions: {
                    series: {
                        //stickyTracking: false,

                        point: {
                            events: {
                                click: function (e) {

                                    var chart = this.sensitivityChart.chart;
                                    if (chart.series[2].data.length > 2) {

                                        if (e.point.index == 0 || e.point.index == chart.series[2].data.length - 1)
                                            return;

                                        var stats = { type: 'removePoint', x: e.point.x, y: e.point.y };

                                        e.point.remove();

                                        this.addAction(stats);
                                    }
                                }.bind(this)
                            }
                        }
                    }
                },

                /*
                exporting: {
                    buttons: {
                        contextButton: {
                            menuItems: [
                                {
                                    textKey: 'printChart',
                                    onclick: function () { this.print(); }
                                },
                                {
                                    separator: true
                                },
                                {
                                    textKey: 'downloadPNG',
                                    onclick: function () { this.exportChart(); }
                                },
                                {
                                    textKey: 'downloadJPEG',
                                    onclick: function () { this.exportChart({ type: 'image/jpeg' }); }
                                }
                            ]
                        }
                    }
                },
                */

                title: {
                    text: ''//this.props.t('BondsYield'),
                    //style: { "color": `"${style.colorPrimary1}"`, "fontSize": "18px", "fontWeight": "bold" }
                },

                credits: {
                    enabled: false
                },

                tooltip: {

                    enabled: true,
                    //followPointer: false,
                    //followTouchMove: false,

                    formatter: function () {

                        return '<table style="min-width:150px;max-width:600px;font-size: 16px"><tr><td>' + (this.points[0].series.userOptions.id == 'editCPR' ? Localization.Translate('CouponDate') : Localization.Translate('Date')) + '</td>' +
                            '<td style="text-align:end">' +

                            this.points.reduce(function (s, point) {
                                return s + '<tr style="color:' + point.series.color + '"><td style="padding:0">' + point.series.name + ': </td>' +
                                    '<td style="padding:0;text-align:end"><b>' + ((point.series.index == 2) ? Localization.FormatNumberString(point.y, 0, 0) : Localization.FormatNumberString(point.y)) + '</b></td></tr>'
                            }, '<b>' + (this.points[0].series.userOptions.id == 'editCPR' ? Localization.FormatDateLocale(new Date(this.x)) : Localization.FormatDateHumanReadable(new Date(this.x))) + '</b>') +

                            '</td > ' +
                            '</tr>' +
                            '</table>';
                    },

                    hideDelay: 0,
                    shared: true,
                    useHTML: true
                },



                xAxis: {
                    type: 'datetime',
                    title: this.props.t('Years'),
                    //crosshair: { snap: false },
                    plotBands: [], // dynamically aded 
                    labels: {
                        style: {
                            fontSize: '14px',
                        }
                    },

                    gridLineWidth: 1,
                    gridZIndex: 1
                },

                yAxis: [
                    { // Primary yAxis
                        alignTicks: false,
                        gridLineWidth: 0,
                        title: {
                            text: this.props.t('AverageRefinanceRate'),
                            style: {
                                fontSize: '14px',
                                color: style.colorPrimary1
                            }
                        },
                        labels: {
                            // format: '{value:.1f}',
                            style: {
                                fontSize: '14px',
                                color: style.colorPrimary1
                            }
                        },
                        tickPositioner: function () {
                            var positions = [6, 10, 14, 18, 22];
                            return positions;
                        },
                        // tickAmount: 5,
                        minPadding: 0,
                        maxPadding: 0,

                        //startOnTick: true,
                        //endOnTick: true,

                        gridZIndex: 1
                    }, { // Secondary yAxis
                        alignTicks: false,
                        title: {
                            text: this.props.t('CPRPrc'),
                            style: {
                                fontSize: '16px',
                                color: style.colorPrimary2
                            }
                        },
                        labels: {
                            format: '{value:.0f}',
                            style: {
                                fontSize: '16px',
                                color: style.colorPrimary2
                            }
                        },

                        opposite: true,
                        // tickAmount: 5,
                        minPadding: 0,
                        maxPadding: 0,
                        //startOnTick: true,
                        //endOnTick: true,

                        gridZIndex: 2
                        //crosshair: { snap: false }
                    }
                ],

                series: [
                    {
                        name: this.props.t('AverageRefinanceRate'),
                        //type: 'spline',
                        yAxis: 0,
                        showInLegend: false,
                        data: null,
                        color: style.colorPrimary1,
                        //stickyTracking: false,
                        zIndex: 3,
                        marker: {
                            enabled: false,
                            symbol: 'circle'
                        },

                        /*states: {
                            hover: {
                                enabled: false
                            }
                        },
                        */
                    },
                    {
                        name: this.props.t('AverageCPROfMBSPools'),
                        //type: 'spline',
                        yAxis: 1,
                        showInLegend: false,
                        data: null,
                        color: style.colorPrimary2,
                        min: 6,
                        max: 22,
                        minorTickInterval: 4,
                        minorTicks: true,
                        // stickyTracking: false,
                        zIndex: 4,
                        marker: {
                            enabled: false,
                            symbol: 'circle'
                        },

                        /*states: {
                            hover: {
                                enabled: false
                            }
                        },
                        */
                    },
                    {
                        id: 'editCPR',
                        name: this.props.t('CPRPrc'),
                        type: 'line',
                        yAxis: 1,
                        showInLegend: false,
                        data: null,
                        color: style.colorPrimary2,
                        //stickyTracking: false,
                        min: 0,
                        max: 40,
                        minorTickInterval: 20,
                        minorTicks: true,
                        // tickAmount: 10,
                        zIndex: 5,
                        marker: {
                            enabled: true,
                            radius: 7,
                            fillColor: 'white',
                            lineWidth: 2,
                            lineColor: style.colorPrimary2
                            /*
                            states: {
                                hover: {
                                    enabled: false
                                }
                            }
                            */
                        },
                        dragDrop: {
                            draggableX: true,
                            draggableY: true
                            //liveRedraw: false
                        },
                        point: {
                            events: {
                                dragStart: function (e) {
                                    this.sensitivityChart.chart.series[0].update({ enableMouseTracking: false });
                                    this.sensitivityChart.chart.series[1].update({ enableMouseTracking: false });
                                }.bind(this),

                                /*
                                dragStart: function (e) {


                                    //console.log('Drag started at page coordinates ' +
                                    //    e.chartX + '/' + e.chartY + (
                                    //        e.updateProp ?
                                    //            '. Updating ' + e.updateProp :
                                    //            ''
                                    //    ) + '. ');

                                    //var newSensitivityChartOptions = { ...this.state.sensitivityChartOptions };
                                    //newSensitivityChartOptions.tooltip.enabled = false;
                                    //this.setState({ sensitivityChartOptions: newSensitivityChartOptions });

                                }.bind(this),
                                */
                                drag: function (e) {
                                    // Returning false stops the drag and drops. Example:

                                    /*
                                    if (e.newPoint && e.newPoint.x < 300) {
                                        return false;
                                    }
                                    */
                                    /*
                                    var status = 'Dragging "' +
                                        (this.name || this.id) + '". ' + e.numNewPoints +
                                        ' point(s) selected.';
                                    */

                                    // If more than one point is being updated, see
                                    // e.newPoints for a hashmap of these. Here we just add
                                    // info if there is a single point.
                                    if (e.newPoint) {

                                        if (e.newPoint.x < this.state.minEditDate)
                                            e.newPoint.x = this.state.minEditDate;
                                        if (e.newPoint.x > this.state.maxEditDate)
                                            e.newPoint.x = this.state.maxEditDate;

                                        if (e.newPoint.y < this.state.minEditValue)
                                            e.newPoint.y = this.state.minEditValue;
                                        if (e.newPoint.y > this.state.maxEditValue)
                                            e.newPoint.y = this.state.maxEditValue;


                                        var series = this.sensitivityChart.chart.series[2];
                                        var dragPointIndex = e.target.index;
                                        if (dragPointIndex == 0) //first || last
                                        {
                                            e.newPoint.x = this.state.minEditDate;
                                        }
                                        else if (series.data.length == dragPointIndex + 1) {
                                            e.newPoint.x = this.state.maxEditDate;
                                        }
                                        else {
                                            //all others
                                            var leftPoint = series.data[dragPointIndex - 1];
                                            var rightPoint = series.data[dragPointIndex + 1];

                                            var leftIndex = this.state.couponDates.indexOf(leftPoint.x) + 1;
                                            var rightIndex = this.state.couponDates.indexOf(rightPoint.x) - 1;

                                            leftIndex = leftIndex < 0 ? 1 : leftIndex;
                                            rightIndex = rightIndex < 0 ? this.state.couponDates.length - 2 : rightIndex;

                                            var leftX = this.state.couponDates[leftIndex];
                                            var rightX = this.state.couponDates[rightIndex];

                                            e.newPoint.x = Math.round(e.newPoint.x);

                                            //console.log(`Found point x: ${rightX} x2: ${e.newPoint.x}`);

                                            if (leftX >= e.newPoint.x) {
                                                e.newPoint.x = leftX;
                                            }
                                            if (rightX <= e.newPoint.x) {
                                                e.newPoint.x = rightX;
                                            }
                                        }
                                        //var chart = this.sensitivityChart.chart;
                                        //var xIndex = chart.series[2].findIndex(item => item.x <)


                                        //var rightIndex = data.findIndex(item => item >= xValue);
                                        //var leftIndex = rightIndex != -1 && rightIndex > 1 ? rightIndex-- : -1;


                                        //try to update points dynamically
                                        var { x, y } = this.snapToGrid(e.newPoint.x, e.newPoint.y);

                                        //status += ' New x/x2/y: ' + e.newPoint.x + '/' + e.newPoint.x2 + '/' + e.newPoint.y;

                                        e.newPoint.x = x;
                                        e.newPoint.y = y;
                                    }

                                    //console.log(`Drag point x: ${x} y: ${y}`);

                                }.bind(this),
                                drop: function (e) {
                                    // The default action here runs point.update on the
                                    // new points. Return false to stop this. Here we stop
                                    // the "Group A" points from being moved to the
                                    // "Prototyping" row.
                                    /*
                                    if (
                                        this.groupId === 'Group A' &&
                                        e.newPoints[this.id].newValues.y === 0
                                    ) {
                                        //console.log('Drop was blocked by event handler.');
                                        return false;
                                    }

                                    //console.log('Dropped ' + e.numNewPoints + ' point(s)');
                                    */

                                    if (e.newPoint) {

                                        if (e.newPoint.x < this.state.minEditDate)
                                            e.newPoint.x = this.state.minEditDate;
                                        if (e.newPoint.x > this.state.maxEditDate)
                                            e.newPoint.x = this.state.maxEditDate;

                                        if (e.newPoint.y < this.state.minEditValue)
                                            e.newPoint.y = this.state.minEditValue;
                                        if (e.newPoint.y > this.state.maxEditValue)
                                            e.newPoint.y = this.state.maxEditValue;


                                        var series = this.sensitivityChart.chart.series[2];
                                        var dragPointIndex = e.target.index;
                                        if (dragPointIndex == 0) //first || last
                                        {
                                            e.newPoint.x = this.state.minEditDate;
                                        }
                                        else if (series.data.length == dragPointIndex + 1) {
                                            e.newPoint.x = this.state.maxEditDate;
                                        }
                                        else {
                                            //all others
                                            var leftPoint = series.data[dragPointIndex - 1];
                                            var rightPoint = series.data[dragPointIndex + 1];

                                            var leftIndex = this.state.couponDates.indexOf(leftPoint.x) + 1;
                                            var rightIndex = this.state.couponDates.indexOf(rightPoint.x) - 1;

                                            leftIndex = leftIndex < 0 ? 1 : leftIndex;
                                            rightIndex = rightIndex < 0 ? this.state.couponDates.length - 2 : rightIndex;

                                            var leftX = this.state.couponDates[leftIndex];
                                            var rightX = this.state.couponDates[rightIndex];

                                            e.newPoint.x = Math.round(e.newPoint.x);

                                            if (leftX >= e.newPoint.x) {
                                                e.newPoint.x = leftX;
                                            }
                                            if (rightX <= e.newPoint.x) {
                                                //console.log(`Finished point x: ${rightX} x2: ${e.newPoint.x}`);
                                                e.newPoint.x = rightX;
                                            }
                                        }
                                        //var chart = this.sensitivityChart.chart;
                                        //var xIndex = chart.series[2].findIndex(item => item.x <)


                                        //var rightIndex = data.findIndex(item => item >= xValue);
                                        //var leftIndex = rightIndex != -1 && rightIndex > 1 ? rightIndex-- : -1;


                                        //try to update points dynamically
                                        var { x, y } = this.snapToGrid(e.newPoint.x, e.newPoint.y);

                                        //status += ' New x/x2/y: ' + e.newPoint.x + '/' + e.newPoint.x2 + '/' + e.newPoint.y;

                                        e.newPoint.x = x;
                                        e.newPoint.y = y;

                                        var point = e.origin.points[e.newPointId];

                                        this.addAction({ type: 'movePoint', x: x, y: y, prevX: point.x, prevY: point.y });
                                    }

                                    //var newSensitivityChartOptions = { ...this.state.sensitivityChartOptions };
                                    //newSensitivityChartOptions.tooltip.enabled = true;
                                    //this.setState({ sensitivityChartOptions: newSensitivityChartOptions });

                                    //console.log(`Drop point x: ${e.newPoint.x} y: ${e.newPoint.y}`);
                                    this.sensitivityChart.chart.series[0].update({ enableMouseTracking: true });
                                    this.sensitivityChart.chart.series[1].update({ enableMouseTracking: true });
                                }.bind(this)
                            }
                        }
                    }
                ]
            },

            minHistoryDate: null,
            maxHistoryDate: null,
            minEditDate: null,
            maxEditDate: null,
            minEditValue: 0,
            maxEditValue: 40,

            minHistoricalYValue: 6,
            maxHistoricalYValue: null,
            isChartChanged: false,
            couponDates: [],
            actionBuffer: [],
            actionIndex: -1,
            prevBondId: null,
            prevEvaluationDate: null
        };
    }

    //sensitivityChartContainer = null;
    sensitivityChart = null;
    //sensitivityChartReflow = () => this.sensitivityChart.reflow();

    //startX = null;
    //startY = null;
    //nextX = null;
    //nextY = null;

    crosshairs = null;
    pointTooltip = null;

    componentDidMount() { // после вставки компонента в DOM

        Localization.onLanguageChanged(this.onLanguageChanged);

        this.props.getDOMRFFactoryStatistics(this.props.bondId, Localization.FormatDateISO(this.props.evaluationDate));

        //if (this.sensitivityChartContainer != null) {
        //this.sensitivityChartContainer.addEventListener('transitionend', this.sensitivityChartReflow);

        /*
        this.sensitivityChartContainer.addEventListener('mousedown', this.onChartMouseDown);
        this.sensitivityChartContainer.addEventListener('mouseup', this.onChartMouseUp);
        this.sensitivityChartContainer.addEventListener('mousemove', this.onChartMouseMove);
        this.sensitivityChartContainer.addEventListener('touchmove', this.onChartMouseMove);
        this.sensitivityChartContainer.addEventListener('mouseleave', this.onChartMouseLeave);
        */
        //}
        //'.highcharts-container'

        //setup chart axis
        //this.sensitivityChart.chart.container.addEventListener('mousemove', this.onChartMouseMove);

    }

    componentDidUpdate(prevProps, prevState) {

        if (this.props.bondId != prevProps.bondId || this.props.evaluationDate != prevProps.evaluationDate) {
            this.props.getDOMRFFactoryStatistics(this.props.bondId, Localization.FormatDateISO(this.props.evaluationDate));
        }

        if (this.props.evaluationDate != prevProps.evaluationDate) {
            this.setState({ isChartChanged: true, actionIndex: this.state.actionIndex + 1 });
        }

        if (this.props.statistics != prevProps.statistics && this.props.statistics != null) {

            var newSensitivityChartOptions = { ...this.state.sensitivityChartOptions };

            newSensitivityChartOptions.series[0].data = this.props.statistics.macroeconomics.map((item, index) => {
                return { x: toUnixDate(new Date(item.date)), y: item.refinancingRate };
            });

            newSensitivityChartOptions.series[1].data = this.props.statistics.statistics.map((item, index) => {
                return { x: toUnixDate(new Date(item.date)), y: item.cprwa };
            });

            this.updateEditChart(newSensitivityChartOptions);

            this.setState({ sensitivityChartOptions: newSensitivityChartOptions });
        }
    }

    componentWillUnmount() {

        Localization.offLanguageChanged(this.onLanguageChanged);

        //if (this.sensitivityChartContainer != null) {
        //this.sensitivityChartContainer.removeEventListener('transitionend', this.sensitivityChartReflow);

        /*
        this.sensitivityChartContainer.removeEventListener('mousedown', this.onChartMouseDown);
        this.sensitivityChartContainer.removeEventListener('mouseup', this.onChartMouseUp);
        this.sensitivityChartContainer.removeEventListener('mousemove', this.onChartMouseMove);
        this.sensitivityChartContainer.removeEventListener('touchmove', this.onChartMouseMove);
        this.sensitivityChartContainer.removeEventListener('mouseleave', this.onChartMouseLeave);
        */
        //}

        this.props.onCPREdit(this.state.sensitivityChartOptions.series[2].data);
        //this.sensitivityChart.chart.container.removeEventListener('mousemove', this.onChartMouseMove);
    }

    ////////////////////////////////////////////////////////////////////////
    snapToGrid = (xValue, yValue) => {

        var data = this.state.couponDates;

        var rightIndex = data.findIndex(item => item >= xValue);
        var leftIndex = rightIndex != -1 && rightIndex > 1 ? rightIndex-- : -1;

        var x = xValue;
        var y = yValue;
        if (leftIndex != -1 && rightIndex != -1) {
            //get closest
            var dx1 = Math.abs(data[leftIndex] - xValue);
            var dx2 = Math.abs(data[rightIndex] - xValue);
            x = dx1 <= dx2 ? data[leftIndex] : data[rightIndex];
        }
        else if (leftIndex != -1) {
            x = data[leftIndex];
        }
        else if (rightIndex != -1) {
            x = data[rightIndex];
        }

        y = Math.round(yValue);
        ////////////////////////////////////////////////////////////////////////
        //console.log(`x:${new Date(x).toISOString()} y:${y}`);

        return { x: x, y: y };
    }

    onLanguageChanged = (lng: any) => {

        setHighchartsLocalization(Highcharts, this.props.t);
        this.updateHighchartsOptions();
    }

    updateHighchartsOptions = () => {

        var newSensitivityChartOptions = { ...this.state.sensitivityChartOptions };

        newSensitivityChartOptions.tooltip.formatter = function () {

            return '<table style="min-width:150px;max-width:600px;font-size: 16px"><tr><td>' + Localization.Translate('Date') + '</td>' +
                '<td style="text-align:end">' +

                this.points.reduce(function (s, point) {
                    return s + '<tr style="color:' + point.series.color + '"><td style="padding:0">' + point.series.name + ': </td>' +
                        '<td style="padding:0;text-align:end"><b>' + ((point.series.index == 2) ? Localization.FormatNumberString(point.y, 0, 0) : Localization.FormatNumberString(point.y)) + '</b></td></tr>'
                }, '<b>' + Localization.FormatDateLocale(new Date(this.x)) + '</b>') +

                '</td > ' +
                '</tr>' +
                '</table>';
        };

        newSensitivityChartOptions.xAxis.title = this.props.t('Years');
        newSensitivityChartOptions.yAxis[0].title.text = this.props.t('AverageRefinanceRate');
        newSensitivityChartOptions.yAxis[1].title.text = this.props.t('CPRPrc');

        newSensitivityChartOptions.series[0].name = this.props.t('AverageRefinanceRate');
        newSensitivityChartOptions.series[1].name = this.props.t('AverageCPROfMBSPools');
        newSensitivityChartOptions.series[2].name = this.props.t('CPRPrc');

        this.setState({ sensitivityChartOptions: newSensitivityChartOptions });
    }

    /*
    onChartMouseMove = (e) => {

        var chart = this.sensitivityChart.chart;
        var eNorm = chart.pointer.normalize(e);

        var xValue = Math.round(chart.xAxis[0].toValue(eNorm.chartX));
        var yValue = chart.yAxis[1].toValue(eNorm.chartY);

        if (this.crosshairs) {
            this.crosshairs.destroy();
            this.crosshairs = undefined;
        }

        if (chart.rGroup) {
            chart.rGroup.destroy();
            chart.rGroup = undefined;
        }

        if (this.pointTooltip) {
            this.pointTooltip.destroy();
            this.pointTooltip = undefined;
        }

        if (xValue < this.state.minEditDate || xValue > this.state.maxEditDate)
            return;
        if (yValue < this.state.minEditValue || yValue > this.state.maxEditValue)
            return;

        //correct x & y to snap to grid
        ////////////////////////////////////////////////////////////////////////
        var { x, y } = this.snapToGrid(xValue, yValue);

        var snapX = chart.xAxis[0].toPixels(x);
        var snapY = chart.yAxis[1].toPixels(y);
        ////////////////////////////////////////////////////////////////////////

        this.crosshairs = chart.renderer.path([
            'M',
            chart.plotLeft, snapY,
            'L',
            chart.plotLeft + chart.plotWidth, snapY,
            'M',
            snapX, chart.plotTop,
            'L',
            snapX, chart.plotTop + chart.plotHeight
        ]).attr({
            'stroke-width': 1,
            stroke: '#e6e6e6',
            id: 'vert',
            'stroke-dasharray': "5,5",
            zIndex: 2000
        }).add();


        chart.rGroup = chart.renderer.g('tooltipGroup').add().toFront();

        this.pointTooltip = chart.renderer.label( // render tooltip

            '<table style="min-width:80px;max-width:100px">' +
            '<tr><td style="padding:0">' + this.props.t('Date') + ': </td>' +
            '<td style="padding:0 0 0 5px;text-align:end"><b>' + Localization.FormatDateLocale(new Date(x)) + '</b></td></tr>' +
            '<tr><td style="padding:0 0 0 5px">' + this.props.t('CPRAsIs') + ': </td>' +
            '<td style="padding:0;text-align:end"><b>' + Localization.FormatNumber(y, 1, 1) + '</b></td></tr>' +
            '</table>',

            snapX, //eNorm.chartX
            snapY, //eNorm.chartY
            undefined,
            undefined,
            undefined,
            true
        )
            .attr({ // style tooltip
                'stroke-width': 1,
                zIndex: 8,
                stroke: 'gray',
                padding: 8,
                r: 3,
                fill: 'rgb(247, 247, 247)'
            })
            .add(chart.rGroup);

        chart.rGroup.translate(20, 20).toFront(); // translate(-50%, -50%)
    }
    */

    /*
    onChartMouseDown = (e) => {

        if (this.props.editMode) {
            this.setState({ isDrawing: true });
        }

        var chart = this.sensitivityChart.chart;
        var eNorm = chart.pointer.normalize(e);

        this.startX = Math.round(chart.xAxis[0].toValue(eNorm.chartX));
        this.startY = chart.yAxis[1].toValue(eNorm.chartY);
    }
    */
    /*
    onChartMouseMove = (e) => {

        if (!this.state.isDrawing)
            return;

        var chart = this.sensitivityChart.chart;
        var eNorm = chart.pointer.normalize(e);

        var xValue = Math.round(chart.xAxis[0].toValue(eNorm.chartX));
        var yValue = chart.yAxis[1].toValue(eNorm.chartY);

        if (xValue < this.state.minEditDate || xValue > this.state.maxEditDate)
            return;

        this.nextX = xValue;
        this.nextY = yValue;

        if (this.startX == this.nextX)
            return; //nothing to interpolate

        var isReversed = this.startX > this.nextX;
        if (isReversed) {
            var temp = this.startX;
            this.startX = this.nextX;
            this.nextX = temp;

            temp = this.startY;
            this.startY = this.nextY;
            this.nextY = temp;
        }
        //calculate covered points

        var data = this.state.sensitivityChartOptions.series[2].data;

        var startIndex = data.findIndex(item => item.x >= this.startX);
        var endIndex = data.findIndex(item => item.x > this.nextX);
        if (endIndex != -1 && endIndex != data.length - 1)
            endIndex--;

        if (startIndex == -1 || endIndex == -1)
            return;

        for (var i = startIndex; i <= endIndex; i++) {

            data[i].y = this.startY + (this.nextY - this.startY) / (this.nextX - this.startX) * (data[i].x - this.startX);

            //console.log(`x: ${data[i].x} y: ${data[i].y}`);
        }

        chart.series[2].setData(data, true, false, false);

        //console.log(`sx: ${this.startX} nx:${this.nextX} sy: ${this.startY} ny:${this.nextY}`);

        if (!isReversed) {
            this.startX = this.nextX;
            this.startY = this.nextY;
        }
    }
    */
    /*
    onChartMouseUp = (e) => {

        if (this.props.editMode) {
            this.setState({ isDrawing: false });
        }

        this.startX = null;
        this.startY = null;
        this.nextX = null;
        this.nextX = null;
    }
    */
    /*
    onChartMouseLeave = (e) => {

        if (this.props.editMode) {
            this.setState({ isDrawing: false });
        }

        this.startX = null;
        this.startY = null;
        this.nextX = null;
        this.nextX = null;
    }
    */

    addAction = (action: any) => {

        var buffer = [...this.state.actionBuffer];
        if (this.state.actionIndex != -1 && this.state.actionIndex != this.state.actionBuffer.length - 1) {

            buffer.splice(this.state.actionIndex + 1, this.state.actionBuffer.length - this.state.actionIndex - 1);
        }

        buffer.push(action);
        this.setState({ isChartChanged: true, actionBuffer: buffer, actionIndex: this.state.actionIndex + 1 });
    }

    onUndoClick = (e) => {

        if (this.state.actionBuffer.length == 0 || this.state.actionIndex == -1)
            return;

        var action = this.state.actionBuffer[this.state.actionIndex];

        //console.log(`undo: type ${action.type}, index: ${this.state.actionIndex}`);

        switch (action.type) {
            case 'addPoint':
                {
                    var removeData = this.state.sensitivityChartOptions.series[2].data.filter(item => item.x != action.x);
                    this.sensitivityChart.chart.series[2].setData(removeData);

                    this.setState({ isChartChanged: true, actionIndex: this.state.actionIndex - 1 });
                }
                break;
            case 'removePoint':
                {
                    var addData = [...this.state.sensitivityChartOptions.series[2].data, { x: action.x, y: action.y }];
                    this.sensitivityChart.chart.series[2].setData(addData);

                    this.setState({ isChartChanged: true, actionIndex: this.state.actionIndex - 1 });
                }
                break;
            case 'movePoint':
                {
                    this.setState({ isChartChanged: true, actionIndex: this.state.actionIndex - 1 });

                    var data = [...this.state.sensitivityChartOptions.series[2].data];
                    var index = data.findIndex(item => item.x == action.x);
                    var point = data[index];
                    point.x = action.prevX;
                    point.y = action.prevY;

                    this.sensitivityChart.chart.series[2].setData(data);
                }
                break;
            default: {
                throw 'Undo: unknown action type!';
            }
        }
    }

    onRedoClick = (e) => {
        if (this.state.actionBuffer.length == 0 || this.state.actionIndex == this.state.actionBuffer.length)
            return;

        var index = this.state.actionIndex + 1;
        var action = this.state.actionBuffer[index];

        //console.log(`redo: type ${action.type}, index: ${this.state.actionIndex}`);

        switch (action.type) {
            case 'addPoint':
                {
                    var addData = [...this.state.sensitivityChartOptions.series[2].data, { x: action.x, y: action.y }];
                    this.sensitivityChart.chart.series[2].setData(addData);

                    this.setState({ isChartChanged: true, actionIndex: index });
                }
                break;
            case 'removePoint':
                {

                    var removeData = this.state.sensitivityChartOptions.series[2].data.filter(item => item.x != action.x);
                    this.sensitivityChart.chart.series[2].setData(removeData);

                    this.setState({ isChartChanged: true, actionIndex: index });
                }
                break;
            case 'movePoint':
                {
                    this.setState({ isChartChanged: true, actionIndex: index });

                    var data = [...this.state.sensitivityChartOptions.series[2].data];
                    var index = data.findIndex(item => item.x == action.prevX);
                    var point = data[index];
                    point.x = action.x;
                    point.y = action.y;

                    this.sensitivityChart.chart.series[2].setData(data);
                }
                break;
            default: {
                throw 'Redo: unknown action type!';
            }
        }
    }

    onClearClick = (e) => {

        var couponDates = this.state.couponDates;
        //   console.log(couponDates)
        var data = [
            { x: this.state.minEditDate, y: this.props.averageCPR },
            { x: couponDates[Math.round(couponDates.length / 4)], y: this.props.averageCPR },
            { x: couponDates[Math.round(couponDates.length / 2)], y: this.props.averageCPR },
            { x: couponDates[Math.round(couponDates.length / 4 * 3)], y: this.props.averageCPR },
            { x: this.state.maxEditDate, y: this.props.averageCPR }
        ];

        this.sensitivityChart.chart.series[2].setData(data);

        this.setState({ isChartChanged: true, actionBuffer: [], actionIndex: -1 });
    }

    onApplyChangesClick = (e) => {

        if (this.props.onApply != null) {
            this.props.onApply(this.makeCPRData());
            this.setState({ isChartChanged: false /*, actionBuffer: [], actionIndex: -1*/ });
        }
    }

    makeCPRData = () => {
        var data = this.state.sensitivityChartOptions.series[2].data;

        //var left = data[0];
        //var right = data[1];
        var index = 0;

        return this.state.couponDates.map(item => {

            for (; index < data.length; index++) {
                if (data[index].x > item) {
                    break;
                }
            }

            var left = data[index - 1];
            var right = data[index];
            var y = left.y;

            if (right != null)
                y = left.y + (right.y - left.y) / (right.x - left.x) * (item - left.x);

            return y;
            //return { x: item, y: y };
        });
    }

    updateEditChart = (newSensitivityChartOptions) => {
        // console.log('newSensitivityChartOptions');

        var minHistoryDate = null;
        var maxHistoryDate = null;
        var minEditDate = null;
        var maxEditDate = null;
        var series = newSensitivityChartOptions.series[2];
        var couponDates = [];
        var prevBondId = this.state.prevBondId;
        var prevEvaluationDate = this.state.prevEvaluationDate;

        //series.visible = true;
        //series.showInLegend = true;
        //series.name = this.props.chartName;

        if (this.props.statistics.variableCPR != null) {

            minHistoryDate = toUnixDate(new Date(this.props.statistics.minHistoricalCPR));
            maxHistoryDate = toUnixDate(new Date(this.props.statistics.maxHistoricalCPR));
            minEditDate = toUnixDate(new Date(this.props.statistics.minVariableCPR));
            // maxEditDate = toUnixDate(new Date(this.props.statistics.maxVariableCPR));
            maxEditDate = toUnixDate(addMonths(maxHistoryDate, 60)); // 120 - 10лет , 60 - 5 лет

            couponDates = this.props.statistics.variableCPR.filter((item, index) => {
                const t = toUnixDate(new Date(item.date))
                // console.log(t <= maxEditDate && t!=='Invalid Date')
                if (t <= maxEditDate && t !== 'Invalid Date') return true
            }).map(item => toUnixDate(new Date(item.date)));

            //not update data if it already loaded
            if (this.props.bondId != null && this.state.prevBondId != this.props.bondId || this.props.evaluationDate != this.state.prevEvaluationDate) {

                var data = null;
                /*
                if (this.props.editedCPR != null) {

                    data = [...this.props.editedCPR];
                    //load saved data
                    if (series.data == null)
                        series.data = data;
                    else
                        this.sensitivityChart.chart.series[2].setData(data);
                }
                else {
                */
                //    const len =  (maxEditDate-minEditDate)/1000
                //    console.log(len, couponDates.map((t)=>new Date(t)), couponDates.length)
                data = [
                    { x: minEditDate, y: this.props.averageCPR },
                    { x: couponDates[Math.round(couponDates.length / 4)], y: this.props.averageCPR },
                    { x: couponDates[Math.round(couponDates.length / 2)], y: this.props.averageCPR },
                    { x: couponDates[Math.round(couponDates.length / 4 * 3)], y: this.props.averageCPR },
                    { x: maxEditDate, y: this.props.averageCPR }
                ];


                if (series.data == null) {
                    series.data = data;
                }
                else
                    this.sensitivityChart.chart.series[2].setData(data);
                //}
            }


            var minRate = +Infinity;
            var maxRate = -Infinity;
            for (var i = 0; i < newSensitivityChartOptions.series[0].data.length; i++) {

                if (newSensitivityChartOptions.series[0].data[i].y < minRate)
                    minRate = newSensitivityChartOptions.series[0].data[i].y;

                if (newSensitivityChartOptions.series[0].data[i].y > maxRate)
                    maxRate = newSensitivityChartOptions.series[0].data[i].y;
            }

            minRate = Math.floor(minRate);
            maxRate = Math.round(maxRate);

            //set min/max
            newSensitivityChartOptions.xAxis.min = minHistoryDate;
            newSensitivityChartOptions.xAxis.max = maxEditDate;

            newSensitivityChartOptions.yAxis[0].min = minRate;
            newSensitivityChartOptions.yAxis[0].max = maxRate;
            // newSensitivityChartOptions.yAxis[0].min = 7;
            // newSensitivityChartOptions.yAxis[0].max = 20;
            newSensitivityChartOptions.yAxis[1].min = this.state.minEditValue;
            newSensitivityChartOptions.yAxis[1].max = this.state.maxEditValue;

            //newSensitivityChartOptions.yAxis[1].startOnTick = false;
            //newSensitivityChartOptions.yAxis[1].endOnTick = false;

            //add plot bands
            newSensitivityChartOptions.xAxis.plotBands = [
                {
                    from: minHistoryDate,
                    to: maxHistoryDate,
                    color: 'rgba(47, 68, 78, 0.02)'
                }
            ];

            prevBondId = this.props.bondId;
            prevEvaluationDate = this.props.evaluationDate;
        }

        this.setState({
            sensitivityChartOptions: newSensitivityChartOptions,
            minHistoryDate: minHistoryDate,
            maxHistoryDate: maxHistoryDate,
            minEditDate: minEditDate,
            maxEditDate: maxEditDate,
            couponDates: couponDates,
            prevBondId: prevBondId,
            prevEvaluationDate: prevEvaluationDate
        });

    }

    render() {

        const { classes, t } = this.props;

        return (
            <Box sx={{ position: 'relative', left: 0, right: 0, top: 0, bottom: 0, display: 'flex', flexDirection: 'column', p: 0, pb: 1, height: '100%' }}>
                <Stack>
                    <Box sx={{
                        display: 'flex', pl: 1,
                        flexWrap: 'nowrap',
                        flexDirection: 'row',
                        alignContent: 'center',
                        alignItems: 'baseline',
                    }}
                    >
                        <Typography sx={{
                            textTransform: 'uppercase', lineHeight: '2rem', maxHeight: '2rem', overflow: 'hidden',
                            display: 'flex', alignItems: 'center',
                            ml: 1, flexGrow: 1, fontSize: '14px', color: style.colorPrimary3
                        }} >
                            {this.props.t('ConventionNameSensitivityGraphTitle')}
                            <TooltipInfoLabel right content={this.props.t('TooltipConventionSensitivityGraphTitle')} />
                        </Typography>

                        <Box component="span" sx={{ pr: 1 }}>
                            {/* кнопка Undo */}
                            <Tooltip
                                disableFocusListener
                                //interactive
                                title={this.props.t('Undo_Info')}>
                                <div>
                                    <IconButton size="small" disabled={this.state.actionIndex == -1} onClick={this.onUndoClick}>
                                        <UndoIcon />
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Box>
                        <Box component="span" sx={{ pr: 1 }}>
                            {/* кнопка Redo */}
                            <Tooltip
                                disableFocusListener
                                //interactive
                                title={this.props.t('Redo_Info')}>
                                <div>
                                    <IconButton size="small" disabled={this.state.actionIndex == this.state.actionBuffer.length - 1} onClick={this.onRedoClick}>
                                        <RedoIcon />
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Box>
                        <Box component="span" sx={{ pr: 1 }}>
                            {/* кнопка Clear_Info */}
                            <Tooltip
                                disableFocusListener
                                //interactive
                                title={this.props.t('Clear_Info')}>
                                <div>
                                    <IconButton size="small" disabled={this.state.actionBuffer.length == 0} onClick={this.onClearClick}>
                                        <BackspaceOutlined />
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Box>
                    </Box>
                    <Divider/>
                </Stack>
                <Stack sx={{ flexGrow: 1 }}>
                    {/* <div> */}
                    <HighchartsReact
                        ref={x => this.sensitivityChart = x}
                        highcharts={Highcharts}
                        containerProps={{ style: { height: "100%", width: "100%" } }}
                        options={this.state.sensitivityChartOptions}
                    />
                    {/* </div> */}
                </Stack>
                <Stack className={classes.buttonsContainer}>
                    {/* <div className={classes.buttonsContainer}> */}
                    <Button variant="contained" color="secondary" /* disabled={this.state.isChartChanged == false}*/ onClick={this.onApplyChangesClick}>
                        {this.props.t('Apply')}
                    </Button>
                    {/* </div> */}
                </Stack>
            </Box>
        );
    }
}

var component = connect(
    state => state.sensitivity, // Selects which state properties are merged into the component's props
    SensitivityStore.actionCreators // Selects which action creators are merged into the component's props
)(SensitivityPanel);

export default withStyles(styles)(withTranslation('translations')(component));