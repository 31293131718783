import React from 'react';
import Divider from '@mui/material/Divider';

// import { styled } from '@mui/material/styles';
import CustomTooltip from './CustomTooltip';
import { alpha, Box, Grid, IconButton, Stack, Theme, Typography } from '@mui/material';
import style from '@Styles/domrf-theme.module.scss';
import { createStyles, makeStyles } from '@mui/styles';
import { t } from 'i18next';
import FormatedOutput from '../functions/FormatedOutput';
import { Menu } from '@mui/icons-material';
import CustomMenu from './CustomMenu';

const useStyles = makeStyles((theme: Theme) => createStyles({
    globalRoot:{
        padding:'5px 0',
    },
    columnWithGrid: {
        paddingLeft: '1px',
        paddingRight: '1px',
        '&>div:nth-of-type(odd)': {
            backgroundColor: alpha(style.colorPrimary3, 0.07),
        }
    },
    Block: {
        display: 'flex',
        flexDirection: 'column',
        '& *': {
            fontSize: '1rem',
        },
    },
    rowBlock: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        // lineHeight: '2rem !important',
        // height: '2rem !important',
        paddingTop: '1px',
    },
    rowName: {
        flexShrink: 1,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        paddingLeft: '0.5rem',
    },
    rowValuesBlock: {
        display: 'flex',
        flexWrap: 'nowrap',
        textAlign: 'right',
        justifyContent: 'flex-end',
        flexGrow: 1,
        // lineHeight: '32px',
        alignItems: 'baseline',
    },
    valueData: {
        // width: '115px !important',
        whiteSpace: 'nowrap',
        // height: '32px',
        paddingRight: '0.5rem',
    },
    valueUnit: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        width: '3rem !important',
        fontSize: '0.8rem',
        paddingLeft: '0.3rem',
        color: style.colorPrimary3_40,
        textAlign: 'left',
    },
    wrappedText: {
        fontSize: '14px',
        color: style.colorPrimary3_60
    },
    grayscaled: {
        color: style.colorPrimary3_60
    }
}));

const StatisticsTable = ({ poolId, /*header,*/ rows }) => {
    const classes = useStyles()
    // const test = 0;
    // console.log('-------------------------rows', rows)

    return (
        <Stack className={classes.globalRoot}>
            {/* <Stack sx={{ flexShrink: 1 }}>
                <Box sx={{
                    display: 'flex', pl: 1,
                    flexWrap: 'nowrap',
                    flexDirection: 'row',
                    alignContent: 'center',
                    alignItems: 'center',
                    maxHeight: '30px', overflow: 'hidden'
                }}
                >
                    <Typography sx={{ textTransform: 'uppercase', lineHeight: '2rem', maxHeight: '2rem', overflow: 'hidden', ml: 1, flexGrow: 1, fontSize: '14px', color: style.colorPrimary3_40 }} >
                        {t("PoolStatistic")}
                    </Typography>                    
                </Box>
                <Divider />
            </Stack> */}
            <Stack sx={{ flexGrow: 1, position: 'relative' }}>
                <Box>
                    {rows != null && (
                        <Grid container>
                            {rows?.map((column, index) => {
                                return (
                                    <Grid item
                                        key={`pool-statistic-column-${index}`}
                                        xs={12 / rows?.length ?? 1}
                                        className={classes.columnWithGrid}
                                    >
                                        {
                                            column?.map((row, index) => {
                                                return (
                                                    row.separator ? <Divider key={`pool_statistic_data_${index}`} sx={{ m: 1 }} />
                                                        :
                                                        <Box key={`pool_statistic_data_${index}`} component='div'
                                                            className={
                                                                [classes.rowBlock,
                                                                row?.grayscaled ||
                                                                    row?.value == null ||
                                                                    !row?.value ||
                                                                    (row.grayscaledIfFull && !parseFloat(`${row?.value}`.replace(',', '.')))
                                                                    ? classes.grayscaled : ''].join(' ')}
                                                        >
                                                            <Box className={classes.rowName}>
                                                                <CustomTooltip
                                                                    id={`${row.key}-field-id`}
                                                                    content={row.tooltip}
                                                                    hideIcon
                                                                    component='span'
                                                                    placement="right-start"
                                                                >
                                                                    {t(row?.name)}
                                                                </CustomTooltip>
                                                            </Box>
                                                            <Box className={classes.rowValuesBlock}>
                                                                <Box className={classes.valueData}>
                                                                    {parseFloat(`${row?.value}`?.replace(',', '.')) && row?.invertValueSign ?
                                                                        <span className={classes.grayscaled} >&minus;&nbsp;</span> :
                                                                        ''}
                                                                    {(row.grayscaledIfFull && !parseFloat(`${row?.value}`?.replace(',', '.'))) ? '-' :
                                                                        row?.value != null && row?.value ? row?.value : '-'}
                                                                </Box>

                                                                <Box className={classes.valueUnit}>
                                                                    {row?.unit ? t(row.unit) : ''}
                                                                </Box>
                                                            </Box>
                                                        </Box >
                                                    // )
                                                )
                                            })
                                        }
                                    </Grid>
                                )
                            })}
                        </Grid>


                    )
                    }

                </Box>
            </Stack>
        </Stack >
    );
}

export default StatisticsTable;