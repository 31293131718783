import React, { useState, useEffect } from 'react';
import { InputLabel, FormControl, MenuItem, Autocomplete, TextField, Select, CircularProgress } from '@mui/material';
import TooltipInfoLabel from '../../../components/shared/TooltipInfoLabel';
import clsx from 'clsx';
import ErrorTooltip from '../../../components/shared/ErrorTooltip';
import { InputAdornment } from "@mui/material";

import useFormValidation from './ValidationForm/useFormValidation';
import { useTranslation } from "react-i18next";
import { makeStyles } from '@mui/styles';
import useMobileDetect from '../../../components/hook/useMobileDetect';

const useStyles = makeStyles({
    compact: {
        marginTop: '8px',
    },
    tooltip: {
        display: 'flex'
    },
    fullWidth: {
        width: '100%'
    }
});

const ComboboxValidator = ({
    id,
    name,
    value,
    errorMessages,
    validators,
    requiredError,
    helperText,
    loadingText,
    noOptionsText,
    getOptionLabel,
    getOptionSelected,
    options,
    onInputChanged,
    label,
    tooltip,
    fullWidth,
    isLoading,
    tReady,
    readOnly,
    disabled,
    renderOption,
    onChange,
    inputValue,
    disableErrorHighlight = false,
    ...rest

}) => {

    const classes = useStyles();
    const { t /*, i18n*/ } = useTranslation();

    const [isOpen, setIsOpen] = useState(false);
    // const [inputValue, setInputValue] = useState(value);

    const { isValid, errorMessage } = useFormValidation(disabled, name, value, errorMessages, validators);
    const device = useMobileDetect();

    // useEffect(() => { 
    //    setInputValue(value)
    // }
    // , [value]);
    // console.log('label', label, value, id + '_autocomplete')
    return (
        <TooltipInfoLabel className={clsx(classes.tooltip, fullWidth ? classes.fullWidth : {})} content={tooltip}>
            {device.isMobile ?
                <FormControl variant="standard" fullWidth>
                    <InputLabel
                        shrink={!!value}
                        htmlFor={id + '_autocomplete'}
                    >{label}</InputLabel>
                    <Select
                        sx={{ cursor: 'pointer' }}
                        name={name}
                        id={id + '_autocomplete'}
                        disabled={disabled}
                        // open={isOpen}
                        value={value}
                        onOpen={() => setIsOpen(true)}
                        onClose={() => setIsOpen(false)}
                        fullWidth={fullWidth}
                        // loading={isOpen && isLoading}
                        //inputValue={()=>getOptionLabel(inputValue)}
                        readOnly={!!readOnly}
                        native={false}
                        renderValue={() => getOptionLabel(value)}
                        onChange={event => {
                            // console.log('event, value', event.target.value)
                            onInputChanged(event, event.target.value);
                            //setInputValue(event.target.value);
                            onChange(event, event.target.value);
                        }}
                        MenuProps={{
                            BackdropProps: { style: { position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: +99997, backgroundColor: 'unset', } },
                            style: { zIndex: +99998 },
                            PaperProps: { sx: { zIndex: +99999, p: 1, maxHeight: '65vh', } },
                        }}
                        {...rest}
                    >
                        {!!options?.length ?
                            options.map(option => <MenuItem sx={{ '&>*': { width: '100%' } }} key={`item-${option.id}`} value={option} component="span">{renderOption(rest, { ...option, value: option }, {})}</MenuItem>)
                            :
                            <MenuItem disabled>{t('Loading')}</MenuItem>
                        }
                    </Select>
                </FormControl>
                :
                <Autocomplete
                    name={name}
                    id={id + '_autocomplete'}
                    disabled={disabled}
                    open={isOpen}
                    value={value}
                    onOpen={() => setIsOpen(true)}
                    onClose={() => setIsOpen(false)}
                    getOptionLabel={getOptionLabel}
                    isOptionEqualToValue={getOptionSelected}
                    fullWidth={fullWidth}
                    loadingText={loadingText}
                    noOptionsText={noOptionsText}
                    options={options}
                    loading={isOpen && isLoading}
                    inputValue={inputValue}
                    readOnly={!!readOnly}
                    renderOption={renderOption}
                    onChange={onChange}
                    onInputChange={(event, value) => {
                        //setInputValue(value);
                        onInputChanged(event, value);
                    }}
                    renderInput={params => (
                        <TextField
                            {...params}
                            margin={"none"}
                            variant="standard"
                            label={label}
                            fullWidth={fullWidth}
                            error={!disableErrorHighlight && !isValid}
                            helperText={helperText}
                            // inputProps={{disabled:true}}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                        {
                                            !disableErrorHighlight && !isValid && (
                                                <InputAdornment position="end">
                                                    <ErrorTooltip content={errorMessage} />
                                                </InputAdornment>
                                            )
                                        }
                                    </React.Fragment>
                                ),
                            }}
                        />
                    )
                    }
                    {...rest}
                />
            }
        </TooltipInfoLabel>
    );
}

export default ComboboxValidator;