import Paper from '@mui/material/Paper';
import MuiTable from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import makeStyles from '@mui/styles/makeStyles';
import * as React from 'react';
import { forwardRef } from 'react';
import { ColumnModel, IDataGridStorage, ITubularHttpClient, GridRequest, ActionType } from '../Common';
import { FixedLinearProgress, ConfirmationDialog } from '../../uno-material-ui';
import { useResolutionSwitch } from '../../uno-react';
import { ITbRow } from '../BareBones/TbRow';
import { IDataGridConfig } from '../DataGridInterfaces/IDataGridConfig';
import { IDataGridVisibleApi } from '../DataGridInterfaces/IDataGridVisibleApi';
import IDetailComponet from '../DataGridInterfaces/IDetailComponent';
import useDataGrid from '../Hooks/useDataGrid';
import { Paginator } from '../Pagination';
import { GridToolbar } from '../Toolbar/GridToolbar';
import { GridOptions } from './GridOptions';
import DataGridTable from './DataGridTable';
import { MobileDataGridTable } from './MobileDataGridTable';
import clsx from 'clsx';
import { IDataSource, DSChangedHandler } from '../DataGridInterfaces/IDataSource';
import { RenderFields } from '../utils/RenderFields';
import GridFilterState from "../Common/Models/GridFilterState";
import ConfirmDialog from '../../ConfirmDialog';

import EditDialogPopup from './EditDialogPopup';

const useStyles = makeStyles({
    linearProgress: {
        marginTop: '-10px',
    },
    root: {
        //height: 'inherit',
        width: '100%'
    },
    paperFullHeight: {
        flex: '1 1 auto',
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
    },
    floatingFooter: {
        position: 'sticky',
        bottom: 0,
        backgroundColor: 'inherit',
        zIndex: 100
    }
});

const timeout = 400;

interface IProps {
    columns: ColumnModel[];
    dataSource: any[] | string | Request | ITubularHttpClient | IDataSource;
    deps?: any[];
    detailComponent?: React.ReactElement<IDetailComponet>;
    gridName: string;
    storage?: IDataGridStorage;
    gridOptions?: GridOptions;

    // ToDo: new ones:
    mobileBreakpointWidth?: number;
    rowComponent?: React.FunctionComponent<ITbRow>;
    rowMobileComponent?: React.FunctionComponent<ITbRow>;
    footerComponent?: React.FunctionComponent<any>;
    onError?(err: any): void;
    onRowClick?(row: any, selected: any[]): void;
    onRowDoubleClick?(row: any): void;
    context: any;
}

export const DataGrid = forwardRef((props: IProps, ref: React.Ref<IDataGridVisibleApi>) => {
    const {
        columns,
        dataSource,
        deps,
        footerComponent,
        gridName,
        mobileBreakpointWidth = props.mobileBreakpointWidth || 800,
        onError,
        onRowClick,
        onRowDoubleClick,
        rowComponent,
        rowMobileComponent,
        storage,
        gridOptions = props.gridOptions || new GridOptions(),
        detailComponent,
        context
    } = props;

    const classes = useStyles({});
    const gridConfig: Partial<IDataGridConfig> = {
        gridName,
        itemsPerPage: gridOptions.itemsPerPage,
        onError,
        storage,
        searchDelayInterval: gridOptions.searchDelayInterval
    };

    const grid = useDataGrid(columns, gridConfig, dataSource, deps);
    const [isMobileResolution] = useResolutionSwitch(mobileBreakpointWidth, timeout);
    var paperClasses = '';
    if (gridOptions) {
        if (gridOptions.fullHeight) {
            paperClasses = classes.paperFullHeight;
        }
    }

    React.useEffect(() => {

        if (isMobileResolution) {
            gridOptions.SetMobileMode();
        }
        else {
            gridOptions.SetDesktopMode();
        }

        grid.api.updateItemPerPage(gridOptions.itemsPerPage);

    }, [isMobileResolution]);

    React.useImperativeHandle(ref, () => ({

        UpdateDataSource: async (data: any, updater: (currentItem: any, newItem: any) => void) => {
            if (data == null || updater == null)
                return;

            await grid.api.reflectDataSourceChanges(data, updater);
        },

        RefreshDataSource: async () => {
            await grid.api.processRequest();
        },

        SetFiltersState: async (filter: GridFilterState) =>
        {
            await grid.api.setFiltersState(filter);
        }

    }));

    const editPopup = (grid.state.columns.some(x => x.IsAction && x.Actions != null && x.Actions.some(y => y.Type != ActionType.TYPE_ADD))) && grid.state.editDialogState.isShow && (
        <EditDialogPopup
            columns={grid.state.columns}
            initialRowData={grid.state.editDialogState.initialRowData}
            gridOptions={gridOptions}
            isProcessing={grid.state.editDialogState.isProcessing}
            isOpen={grid.state.editDialogState.isShow}
            applyChanges={grid.api.applyChanges}
            caption={grid.state.editDialogState.isEdit === true ? gridOptions.locale.Edit : gridOptions.locale.Create}>
        </EditDialogPopup>
    );

    const confirmPopup = (grid.state.confirmDialogState.isShow) && (
        <ConfirmDialog
            show={true}
            title={gridOptions.confirmTitle}
            description={gridOptions.confirmDescription}
            onConfirm={grid.state.confirmDialogState.onConfirm}
            onClose={grid.state.confirmDialogState.onClose} />
    );

    const paginator = (position: string) => (
        <MuiTable data-testid={`${position}-paginator`} className={gridOptions.floatingFooter ? classes.floatingFooter : ''}>
            <TableHead>
                <TableRow>
                    <Paginator
                        advancePagination={gridOptions.advancePagination}
                        rowsPerPageOptions={gridOptions.rowsPerPageOptions}
                        grid={grid}
                        locale={gridOptions.locale}
                    />
                </TableRow>
            </TableHead>
        </MuiTable>
    );

    if (isMobileResolution) {

        return (
            <Paper className={clsx(classes.root, paperClasses)}>
                <GridToolbar gridOptions={gridOptions} grid={grid} gridName={gridName} />
                {gridOptions.topPager && paginator('top')}
                <MobileDataGridTable
                    grid={grid}
                    gridOptions={gridOptions}
                    onRowClick={onRowClick}
                    onRowDoubleClick={onRowDoubleClick}
                    rowComponent={rowMobileComponent}
                />
                {gridOptions.bottomPager && paginator('bottom')}
                {editPopup}
                {confirmPopup}
            </Paper>
        );
    }

    return (
        <Paper className={clsx(classes.root, paperClasses)}>
            <GridToolbar gridName={gridName} gridOptions={gridOptions} grid={grid}/>
            {gridOptions.topPager && paginator('top')}
            <DataGridTable
                grid={grid}
                gridOptions={gridOptions}
                rowComponent={rowComponent}
                footerComponent={footerComponent}
                detailComponent={detailComponent || null}
                onRowClick={onRowClick}
                onRowDoubleClick={onRowDoubleClick}
            />
            {gridOptions.bottomPager && paginator('bottom')}
            {editPopup}
            {confirmPopup}
        </Paper>
    );
});
