import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from "./CustomRouter";

import * as CalculatorStore from "../../store/CalculatorStore";

import { withStyles } from '@mui/styles';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
// import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

// import { DataGrid, GridOptions } from "../../framework/components/Table";
// import { ColumnModel, SelectionType } from "../../framework/components/Table/Common";

// import CalculatorService from "../../services/CalculatorService";
// import CommonService from "../../services/CommonService";
// import SiteHelper from "../../shared/SiteHelper";
// import { CouponType } from "../../components/Definitions";

// import { DataSourceChangeType } from '../../framework/components/Common/Definitions';
// import { AggregateFunctions, ColumnDataType, ColumnSortDirection, TrimContentType } from '../../framework/components/Table/Common/Models/Column';
import { Spinner } from "@Components/shared/Spinner";

import Localization from '../Localization';
import TooltipInfoLabel from '@Components/shared/TooltipInfoLabel';
import style from '@Styles/domrf-theme.module.scss';
import { setHighchartsLocalization } from '@Utilities';

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HC_more from "highcharts/highcharts-more"; //module
import { withTranslation } from "react-i18next";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

interface IProps {

    show: boolean;
    onClose: any;
    classes: any;
    statistics: any;
    cprLoading: any;
    objectId: number | null;

    loadBondStatistics: any;
    loadBondCPRCDR: any;

    operationLoading: any;

    cpr_curve_data: any;
    cdr_curve_data: any;

    t: any;
}

interface IState {
    cprcdrChartOptions: any;
}

const styles = (theme: any) => ({
    root: {
        height: '100%'
    },
    container: {
        height: '100%',
        overflowY: 'scroll'
    },
    description: {
        fontSize: style.fontSizeSecondary,
        color: style.colorPrimary3
    },
    spinner: {
        position: 'relative',
        height: '100%'
    }
});

class BondInfoDialog extends React.Component<IProps, IState> {

    constructor(props: any) {
        super(props);

        HC_more(Highcharts);
        setHighchartsLocalization(Highcharts, this.props.t);

        this.state = {
            cprcdrChartOptions: {

                chart: {
                    zoomType: 'x'
                },

                exporting: { enabled: false },

                title: {
                    text: this.props.t('CPRCDR'),
                    style: { "color": "#2F444E", "fontSize": "18px", "fontWeight": "bold" }
                },

                credits: {
                    enabled: false
                },

                xAxis: {
                    //type: 'logarithmic',
                    type: 'datetime',
                    title: this.props.t('Date')
                },

                yAxis: {
                    title: null
                    //min: 0
                },

                tooltip: {
                    pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y:.2f}</b><br/>',
                    //crosshairs: true,
                    //shared: true,
                },

                series: [
                    {
                        name: this.props.t('CPR'),
                        //type: 'spline',
                        dashStyle: 'longdash',
                        data: null,
                        color: '#8BC540',
                        lineWidth: 1,
                        marker: {
                            enabled: false,
                            symbol: 'circle'
                        }
                    },
                    {
                        name: this.props.t('CDR'),
                        //type: 'spline',
                        data: null,
                        color: '#8BC540',
                        lineWidth: 1,
                        marker: {
                            enabled: false,
                            symbol: 'circle'
                        }
                    }
                ]
            }
        }
    }

    componentDidUpdate(prevProps: IProps) {

        if (this.props.show !== prevProps.show && this.props.objectId !== prevProps.objectId) {
            if (this.props.show === true && this.props.objectId != null) {
                this.props.loadBondStatistics(this.props.objectId);
            }
        }

        var newChart = null;
        if (this.props.cpr_curve_data !== prevProps.cpr_curve_data) {
            newChart = { ...this.state.cprcdrChartOptions };
            newChart.series[0].data = this.props.cpr_curve_data;
        }

        if (this.props.cdr_curve_data !== prevProps.cdr_curve_data) {
            if (newChart == null)
                newChart = { ...this.state.cprcdrChartOptions };
            newChart.series[1].data = this.props.cdr_curve_data;
        }

        if (newChart != null) {
            this.setState({
                cprcdrChartOptions: newChart
            });
        }
    }

    onWindowClose = (event) => {
        if (this.props.onClose)
            this.props.onClose(event);
    }

    render() {

        const {
            classes,
            statistics,
            t
        } = this.props;

        return (
            <div>
                <Dialog fullScreen onClose={this.onWindowClose} aria-labelledby="bonds-select-dialog-title" open={this.props.show}>
                    {
                        (!this.props.operationLoading && statistics) && (
                            <MuiDialogTitle disableTypography>
                                <h3>{statistics.name}</h3>
                                <div className={classes.description}>{statistics.description}</div>
                            </MuiDialogTitle>
                        )
                    }
                    <MuiDialogContent className={classes.root}>
                        <Paper className={classes.container}>
                            {
                                this.props.operationLoading ? (
                                    <div className={classes.spinner}>
                                        <Spinner show={this.props.operationLoading} content={t('Loading')} />
                                    </div>
                                ) :
                                    (
                                        <Grid container className="g-0">
                                            <Grid md={6}>
                                                <Table aria-label="statistics table" size="small">
                                                    <TableBody>
                                                        {
                                                            statistics && statistics.items.map((row, index) => {
                                                                return (
                                                                    <TableRow key={index}>
                                                                        {
                                                                            <TableCell key={"row-main-" + index}>
                                                                                <TooltipInfoLabel key={"tooltip-" + index} content={t(row.resourceDescription)}>
                                                                                    {t(row.resourceName)}
                                                                                </TooltipInfoLabel>
                                                                            </TableCell>
                                                                        }
                                                                        {
                                                                            <TableCell key={"row-" + index} align="right">{Localization.FormatByType(row.type, row.value)}</TableCell>
                                                                        }
                                                                    </TableRow>
                                                                )
                                                            })
                                                        }
                                                    </TableBody>
                                                </Table>
                                            </Grid>
                                            <Grid md={6}>
                                                <Grid container className='g-0'>
                                                    <Grid xs={12}>
                                                        <HighchartsReact
                                                            highcharts={Highcharts}
                                                            options={this.state.cprcdrChartOptions}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid container className='g-0'>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )
                            }
                        </Paper>
                    </MuiDialogContent>
                    <MuiDialogActions>
                        <Button variant="contained" color="secondary" onClick={this.onWindowClose}>
                            {t('Close')}
                        </Button>
                    </MuiDialogActions>
                </Dialog>
            </div>
        );
    }
}

var component = connect(
    state => (state as any).calculator, // Selects which state properties are merged into the component's props
    CalculatorStore.actionCreators // Selects which action creators are merged into the component's props
)(BondInfoDialog);

export default withRouter(withStyles(styles)(withTranslation('translations')(component)));