import * as React from "react";
import Tooltip from '@mui/material/Tooltip';
import {Box /*, Typography*/ } from '@mui/material';
import Button from '@mui/material/Button';
import { Theme } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
// import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ButtonBase from '@mui/material/ButtonBase';
import clsx from 'clsx';
import style from '@Styles/domrf-theme.module.scss';
import Globals from '../../Globals';
import { withTranslation } from "react-i18next";

// import Localization from "../Localization";

const styles = (theme: Theme) => createStyles({

    /* Styles applied to the root element. */
    root: {
        cursor: 'pointer',
        display: 'inline-flex',
        justifyContent: 'flex-start',
        flexDirection: 'inherit',
    },
    /* Styles applied to the icon component. */
    icon: {
        fontSize: 18,
        //marginLeft: 15,
        userSelect: 'none',
        color: style.colorPrimary3,
        opacity: 0.8
    },

    button: {
        color: 'white'
    },
    buttonContainer: {
        textAlign: 'end'
    },
    popper: {
        zIndex: 10001
    }

    //children: {
    //cursor: 'pointer'
    //}
});

interface IProps {
    classes: any;
    //className: any;
    IconComponent?: any;
    content: any;
    link?: string;
    linkName?: string;
    t: any;
    onLinkClick?: () => void;
    right?: boolean
}

interface IState {
    tooltipOpened: boolean;
}

class InfoTooltip extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            tooltipOpened: false
        }
    }

    handleClick = (evt: any) => {
        Globals.redirectToArticle(this.props.link);
    }

    handleTooltipClose = () => {
        this.setState({ tooltipOpened: false });
    };

    handleTooltipOpen = () => {
        this.setState({ tooltipOpened: true });
    };

    render() {

        const
            {
                classes,
                //className,
                IconComponent = HelpOutlineIcon,
                content,
                t,
                tReady,
                link,
                linkName,
                onLinkClick,
                right,

                ...other
            } = this.props;

        return (
            <ClickAwayListener onClickAway={()=>this.handleTooltipClose()}>
                <Tooltip
                    className={classes.root}
                    sx={{verticalAlign:'sub',...(!right ? {mr:'10px'} : {ml:'10px'})}}
                    PopperProps={{
                        //disablePortal: true,
                        className: classes.popper
                    }}
                    onClose={this.handleTooltipClose}
                    open={this.state.tooltipOpened}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    // interactive
                    title={
                        <Box>
                            <div dangerouslySetInnerHTML={{ __html: content }}></div>
                            {
                                (link != null || onLinkClick != null) && (
                                    <div className={classes.buttonContainer}>
                                        <Button variant="contained" color="secondary" onClick={onLinkClick || this.handleClick}>{linkName || t('Article')}</Button>
                                    </div>
                                )
                            }
                        </Box>
                    }
                    {...other}
                >

                    <ButtonBase
                        className={clsx(classes.root/*, className*/)}
                        component="span"
                        disableRipple
                        onClick={this.handleTooltipOpen}
                    >
                        <IconComponent
                            className={clsx(classes.icon)}
                        />
                    </ButtonBase>
                </Tooltip>
            </ClickAwayListener>
        );
    }
}

export default withStyles(styles)(withTranslation('translations')(InfoTooltip));