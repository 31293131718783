import ServiceBase from "./ServiceBase";
//import Globals from "../Globals";

export default class ProfileService extends ServiceBase {

    static async unsubscribe(data) {
        var result = await this.requestJson({
            url: "api/Profile/Unsubscribe",
            method: "GET",
            data: data
        });

        return result;
    }
}