import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Spinner } from "../../../../components/shared/Spinner";

import { IDataGrid } from '../DataGridInterfaces/IDataGrid';
import { GridOptions } from './GridOptions';
import { IDataSource } from '../DataGridInterfaces/IDataSource';
import { RenderFields } from '../utils/RenderFields';
import { IEditDialogState } from '../DataGridInterfaces/IEditDialogState';
import ColumnModel from "../Common/Models/ColumnModel";

export interface IProps {
    initialRowData: any,
    columns: ColumnModel[],
    gridOptions: GridOptions,
    isOpen: boolean,
    isProcessing: boolean,
    applyChanges: (applyState: boolean | null, row: any) => void
    caption: string
}

export default class EditDialogPopup extends React.Component<IProps, any> {

    constructor(props: any) {
        super(props);

        this.state = {
            editRow: this.props.initialRowData
        };
    }

    //const classes = useStyles();

    componentDidUpdate(prevProps: any) {

        if (this.props.initialRowData !== prevProps.initialRowData) {
            this.setState({ editRow: this.props.initialRowData });
        }
    }

    handleInputChange = (name: string, value: any) => {

        var newEditRow = { ...this.state.editRow };
        newEditRow[name] = value;
        var changed = this.state.editRow.changedFields;
        //this adds array of fields was changed. Used to update only changed fields
        if (changed == null) {
            changed = new Array();
        }
        if (changed.findIndex((x: string) => x === name) === -1)
            changed.push(name);

        newEditRow.changedFields = changed;

        this.setState({ editRow: newEditRow });
    };

    applyChanges_Local = (event: any, reason: string) => {
        if (reason == 'backdropClick') {
            event.preventDefault();
            return;
        }

        this.props.applyChanges(null, null)
    }

    render() {

        const {
            columns,
            gridOptions,
            isOpen,
            isProcessing,
            applyChanges,
            caption,
        } = this.props;

        return (
            <Dialog
                //disableBackdropClick
                disableEscapeKeyDown
                fullWidth
                maxWidth='sm'
                open={isOpen}
                onClose={(event: object, reason: string) => this.applyChanges_Local(event, reason)}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description">
                <DialogTitle id="scroll-dialog-title">
                    <div style={{ display: 'flex' }}>
                        {caption}
                        {gridOptions.renderHeader && gridOptions.renderHeader()}
                    </div>
                </DialogTitle>
                <DialogContent dividers>
                    {RenderFields(this.state.editRow, columns, gridOptions.locale, this.handleInputChange)}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => applyChanges(false, null)} color="primary" variant="contained">{gridOptions.locale.Cancel}</Button>
                    <Button onClick={() => applyChanges(true, this.state.editRow)} color="secondary" variant="contained">{gridOptions.locale.Ok}</Button>
                </DialogActions>
                <Spinner show={isProcessing} />
            </Dialog>
        )
    }
};