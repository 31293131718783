import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Helmet from "react-helmet";
import "@Styles/pages/InstrumentsPage.scss";

import * as InstrumentsStore from "@Store/InstrumentsStore";
import { withRouter } from "../components/shared/CustomRouter";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons'
import { alpha } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import { withTranslation } from "react-i18next";

import InstrumentsList from "../components/shared/InstrumentsList";
import { Spinner } from '../components/shared/Spinner';

import Localization from '../components/Localization';

interface IProp {
    init: () => void;
    getFavouriteInstruments: () => void;
}

interface IState {
    query?: string;
}

class InstrumentsPage extends React.Component<IProp, IState> {

    constructor(props) {
        super(props);

        this.state = {
            query: ""
        };
    }

    componentDidMount() {

        Localization.onLanguageChanged(this.onLanguageChanged);

        this.props.init();
        this.props.getFavouriteInstruments();
    }

    componentWillUnmount() {

        Localization.offLanguageChanged(this.onLanguageChanged);
    }

    onLanguageChanged = (lng: any) => {

        //CHECKME!!! dirty hack, refactory later
        this.props.getFavouriteInstruments();
    }

    onClearQuery = (event) => {
        this.setState({ query: "" });
    };

    onSearchInstruments = (event) => {
        if (this.state.query.length === 0)
            this.props.getFavouriteInstruments();
        else
            this.props.findInstruments(this.state.query);
    };

    handleSearchKeyPress = (event) => {
        if (event.key == "Enter") {
            event.preventDefault();

            this.onSearchInstruments();
        }
    }

    handleQueryChange = (event) => {
        this.setState({ query: event.target.value });
    };

    handleInstrumentOpen = (event, data) => {

        if (data.url == null)
            return;

        if (data.isExternalLink === true) {
            window.location.href = data.url;
        }
        else {
            this.props.router.navigate(data.url);
        }
    }

    render() {

        const { t } = this.props;

        return (
            <div sx={{width: '100%', height: '100%', paddingX: '1rem'}}>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{t('MBSInstruments')}</title>
                </Helmet>
                {/* CHECKME!!!
                <div className="d-flex justify-content-center pt-5">
                    <Paper className="is-paper col-sm-7 col-md-6 col-lg-4">
                        <InputBase
                            className="is-search"
                            placeholder="Поиск по инструментам"
                            inputProps={{ 'aria-label': 'поиск по инструментам' }}
                            onChange={this.handleQueryChange}
                            value={this.state.query}
                            onKeyPress={this.handleSearchKeyPress}
                        />
                        <IconButton
                            className="is-search-icon"
                            aria-label="clear"
                            onClick={this.onClearQuery}
                            size="large">
                            <FontAwesomeIcon icon={faTimes} size="xs" />
                        </IconButton>
                        <Divider className="is-search-divider" orientation="vertical" />
                        <IconButton
                            className="is-search-icon"
                            aria-label="search"
                            onClick={this.onSearchInstruments}
                            size="large">
                            <FontAwesomeIcon icon={faSearch} />
                        </IconButton>
                    </Paper>
                </div>
                */}
                <div className="is-instruments-container">
                    {/* <Spinner show={this.props.operationLoading} content="Обработка..." /> */}
                    <InstrumentsList Instruments={this.props.instruments} onOpenInstrument={this.handleInstrumentOpen}/>
                </div>
            </div>
        );
    }
}

var component = connect(
    state => state.instruments, // Selects which state properties are merged into the component's props
    InstrumentsStore.actionCreators // Selects which action creators are merged into the component's props
)(InstrumentsPage);

export default withRouter(withTranslation('translations')(component));