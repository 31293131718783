import React, { useState } from 'react';
import TableCell from '@mui/material/TableCell';
import { ColumnModel, getColumnAlign } from '../Common';
import { RenderCellContent } from './RenderCellContent';
import { GridOptions } from '../DataGrid';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { IDataGrid } from '../DataGridInterfaces/IDataGrid';
import DialogInput from '../Filtering/DialogInput';
import { Localization } from '../DataGrid/Localization';
import { object } from 'prop-types';


/*
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: '0 ' + theme.typography.fontSize + 'px'
        }
    })
);
*/

export const RenderFields = (row: any, columns: ColumnModel[], locale: Localization, handleInputChange: (name: string, value: any) => any): any => {

    //const classes = useStyles();

    return row && columns
        .filter((column: ColumnModel) => {
            return !(column.IsKey || column.IsAction || !column.VisibleInEdit);// || column.IsReadonly);
        })
        .map((column: ColumnModel) => {
            var result = null;
            if (column.RenderEdit != null && typeof column.RenderEdit === 'function')
                result = column.RenderEdit(row, column, locale);

            if (result)
                return result;

            return (
                <DialogInput
                    key={column.Name}
                    column={column}
                    disabled={column.IsReadonly}
                    handleValueChange={handleInputChange}
                    locale={locale}
                    label={column.Label}
                    tooltip={column.Tooltip}
                    object={row}
                    value={row[column.Name]}
                    fullWidth
                />);
        });
}
