import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import Input from '@mui/material/Input';
import { ColumnDataType, ColumnModel, CompareOperators, formatDate } from '../Common';
import { Localization } from '../DataGrid/Localization';
//import DatePicker from '@mui/lab/DatePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useTheme, Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import DateFnsUtils from '@date-io/date-fns';
import format from 'date-fns/format';
import isValid from 'date-fns/isValid';
import { getDefaultValue } from '../Common/utils';
import { DefaultNullValue } from "../../Common/Definitions";
import { debounce } from 'throttle-debounce';
import { withStyles } from '@mui/styles';
import { DropzoneArea, } from '../../../components/DropZone';
import { convertBytesToMbsOrKbs } from "../../../components/DropZone/helpers";
import TooltipInfoLabel from '../../../../components/shared/TooltipInfoLabel';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { getDate, getDateTimeGMT } from '../../../../Utilities';
//import { default as GlobalLocalization } from "@Components/Localization";

const BooleanInputOperators = [
    { Value: 'true', Title: 'True' },
    { Value: 'false', Title: 'False' }
];

interface IProps {
    column: ColumnModel;
    handleValueChange(name: string, value: any): void;
    locale: Localization;
    label: string;
    disabled?: boolean;
    value: any;
    fullWidth?: boolean;
    style?: any;
    classes?: any; //from withStyles
    object?: any;
    tooltip?: string;
}

interface IState {
    open: boolean,
    dataSource: any[],
    selectValue: any,
    loading: boolean
}

const ColumnDataTypeToHtmlType = {
    boolean: 'text',
    date: 'date',
    datetime: 'datetime-local',
    datetimeutc: 'datetime-local',
    numeric: 'number',
    string: 'text',
};

const getValue = (
    dataType: ColumnDataType,
    value: any
) => {

    switch (dataType) {
        case ColumnDataType.DATE_TIME:
        case ColumnDataType.DATE_TIME_UTC:
            {
                if (typeof value == 'string')
                    return value;

                if (value)
                    return formatDate(value, 'yyyy-MM-dd[T]HH:mm');

                //var date = getDefaultValue(dataType);
                return null;
            }
        case ColumnDataType.BOOLEAN:
            {
                return typeof value === 'boolean' ? value : (value != null ? true : false);
            }
        case ColumnDataType.ENUM:
        case ColumnDataType.ENUM_AUTOCOMPLETE:
            {
                if (value == null)
                    return DefaultNullValue;

                return value;
            }
        case ColumnDataType.ENUM_AUTOCOMPLETE_MULTIPLE:
            {
                if (value == null)
                    return [];

                if (Array.isArray(value))
                    return value;

                return [value];
            }
        case ColumnDataType.FLOAT:
            {
                if (value == null)
                    return '';

                return value;
            }

        default:
            return value || '';
    }
};

const checkboxIcon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedCheckboxIcon = <CheckBoxIcon fontSize="small" />;

const styles = (theme: any) => ({
    dropzone: {
        minHeight: 'inherit',
        border: '2px dashed ' + theme.palette.grey[500]
    },
    dropZoneLabel: {
        fontSize: theme.typography.body1.fontSize,
        lineHeight: theme.typography.body1.lineHeight,
        letterSpacing: theme.typography.body1.letterSpacing,
    },
    dropZoneCaption: {
        marginTop: '16px',
        fontSize: theme.typography.caption.fontSize,
        lineHeight: theme.typography.caption.lineHeight,
        letterSpacing: theme.typography.caption.letterSpacing,
        color: 'rgba(0, 0, 0, 0.47)'
    },
    preview: {
        '& .MuiGrid-spacing-xs-8': {
            marginTop: '0',
            marginBottom: '0',
            width: 'inherit',
            margin: 'inherit',
            '& .MuiGrid-item':
            {
                padding: '0',
                margin: '32px',
                '& svg': {
                    height: '50px'
                },
                '& p': {
                    fontSize: theme.typography.caption.fontSize,
                    lineHeight: theme.typography.caption.lineHeight,
                    letterSpacing: theme.typography.caption.letterSpacing
                }
            }
        }
    },
    flex: {
        display: 'flex'
    },
    checkbox: {
        marginRight: 0,
        marginBottom: 0
    },
    autocomplete: {
        width: '100%'
    }
});


class DialogInput extends React.Component<IProps, IState>
{
    constructor(props: IProps) {
        super(props);

        this.state = {
            open: false,
            dataSource: [],
            selectValue: props.column.DataType == ColumnDataType.ENUM_AUTOCOMPLETE_MULTIPLE ? [] : '',
            loading: false
        };
    }

    componentDidMount() {

        if (this.props.column.DataSource) {
            if (typeof this.props.column.DataSource === 'function') {
                (async () => {

                    var localValue = getValue(
                        this.props.column.DataType,
                        this.props.value
                    );

                    var queryValue = localValue;
                    if (queryValue == DefaultNullValue)
                        queryValue = null; //for our query

                    const response = await this.props.column.DataSource.call(this.props.object, "", queryValue, null, null);
                    var items = [];
                    if (response != null) {
                        items = response.items.map((x: any) => x.key === null ? { key: DefaultNullValue, value: x.value } : x);
                    }

                    //response.count - total count of records
                    var selectedValue = null;
                    if (Array.isArray(localValue)) {
                        selectedValue = items.filter((x: any) => localValue.findIndex(y => y.accessAreaTypeID == x.key) != -1);
                    }
                    else {
                        selectedValue = items.find((x: any) => x.key === localValue);
                    }

                    if (selectedValue == null) {
                        if (this.props.column.ForceSelection)
                            selectedValue = items.find((x: any) => x.key === DefaultNullValue);
                        else {
                            if (Array.isArray(localValue))
                                selectedValue = localValue;
                            else
                                selectedValue = { key: localValue, value: localValue };
                        }
                    }

                    this.setState({ dataSource: items, selectValue: selectedValue });
                })();
            }
            else if (Array.isArray(typeof this.props.column.DataSource)) {
                var localValue = getValue(
                    this.props.column.DataType,
                    this.props.value
                );

                this.setState({ dataSource: this.props.column.DataSource.items, selectValue: this.props.column.DataSource.items.find((x: any) => x.key === localValue) })
            }
        }
    }

    loadDataSource = debounce(500, async (searchValue: string) => {

        this.setState({ loading: true });

        var localValue = getValue(
            this.props.column.DataType,
            searchValue
        );

        const response = await this.props.column.DataSource(searchValue, null, null);
        var items = [];
        if (response != null) {
            items = response.items.map((x: any) => x.key === null ? { key: DefaultNullValue, value: x.value } : x);
        }

        var selected = items.find((x: any) => x.key === localValue);
        if (selected == null) {
            //response.count - total count of records
            if (this.props.column.ForceSelection === true)
                this.setState({ loading: false, dataSource: items });
            else {
                if (Array.isArray(localValue))
                    this.setState({ loading: false, dataSource: items });
                else
                    this.setState({ loading: false, dataSource: items, selectValue: { key: localValue, value: localValue } });
                this.props.handleValueChange(this.props.column.Name, localValue);
            }
        }
        else {
            this.setState({ loading: false, dataSource: items, selectValue: selected });
        }

    });

    handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.props.handleValueChange(e.target.name, e.target.value);
    }

    handleDateChange = (name: string, date: any | null, column: ColumnModel) => {

        this.props.handleValueChange(name, date);

        /*
        if (date != null && isValid(date)) {
            switch (column.DataType) {
                case ColumnDataType.DATE:
                    {
                        this.props.handleValueChange(name, getDate(date));
                    }
                    break;
                case ColumnDataType.DATE_TIME_UTC:
                    {

                        this.props.handleValueChange(name, getDateTimeGMT(date));
                    }
                    break;
            }
        }
        else {
            this.props.handleValueChange(name, date);
        }
        */
    };
    handleCheckChange = (e: React.ChangeEvent<HTMLInputElement>) => this.props.handleValueChange(e.target.name, e.target.checked);
    handleSelectChange = (e: React.ChangeEvent<HTMLInputElement>) => this.props.handleValueChange(e.target.name, e.target.value === DefaultNullValue ? null : e.target.value);
    handleDynamicSelectChange = (e: React.ChangeEvent<{}>, value: any) => {

        this.setState({ selectValue: value });

        if (Array.isArray(value))
            this.props.handleValueChange(this.props.column.Name, value.map(x => x.key));
        else
            this.props.handleValueChange(this.props.column.Name, value == null ? null : (value.key === DefaultNullValue ? null : value.key));
    }
    handleFileChange = (files: any) => {

        //called later, that user can override data
        if (this.props.column.DataChanged != null && typeof this.props.column.DataChanged === 'function') {
            var data = this.props.column.DataChanged(this.props.column.Name, this.props.object, files);
            if (data != null) {

                data[this.props.column.Name] = files.length != 0 ? files[0].name : null;

                for (var propertyName in data) {
                    this.props.handleValueChange(propertyName, data[propertyName]);
                }
            }
        }
        else {
            this.props.handleValueChange(this.props.column.Name, files.length != 0 ? files[0].name : null);
        }
    };

    render() {
        var content = null;

        const {
            column,
            locale,
            label,
            disabled,
            value,
            fullWidth,
            style,
            classes
        } = this.props;

        const localValue =
            getValue(
                column.DataType,
                value
            );


        switch (column.DataType) {
            case ColumnDataType.DATE:
            case ColumnDataType.DATE_TIME:
            case ColumnDataType.DATE_TIME_UTC:
                {
                    content = (
                        <TooltipInfoLabel className={classes.flex} content={column.Tooltip}>
                            <DatePicker
                                //views={['date']}
                                //allowKeyboardControl={false}
                                //name={column.Name}
                                //variant="inline"
                                //margin="none"
                                disabled={disabled || false}
                                value={localValue}
                                label={label}
                                //style={style}
                                mask={locale.DateMask()}
                                inputFormat={locale.ShortDatePattern()}
                                renderInput={(params) => <TextField {...params} fullWidth={true} helperText={params.placeholder || ''} />}
                                onChange={(e: any) => this.handleDateChange(column.Name, e, column)}
                            />
                        </TooltipInfoLabel>
                    );
                }
                break;
            case ColumnDataType.BOOLEAN:
                {
                    content = (
                        <TooltipInfoLabel className={classes.flex} content={column.Tooltip}>
                            <FormControlLabel
                                className={classes.checkbox}
                                control={
                                    <Checkbox
                                        id={column.Name}
                                        name={column.Name}
                                        checked={localValue}
                                        onChange={this.handleCheckChange}
                                        color="primary"
                                    />
                                }
                                label={label}
                            />
                        </TooltipInfoLabel>
                    );
                }
                break;
            case ColumnDataType.FLOAT:
            case ColumnDataType.INT:
            case ColumnDataType.BOOLEAN_TRISTATE:
            case ColumnDataType.STRING:
                {
                    content = (

                        <TooltipInfoLabel className={classes.flex} content={column.Tooltip}>
                            <TextField
                                select={column.DataType === ColumnDataType.BOOLEAN_TRISTATE}
                                margin="none"
                                style={style}
                                id={column.Name}
                                name={column.Name}
                                disabled={disabled || false}
                                value={localValue}
                                label={label}
                                type={(ColumnDataTypeToHtmlType as any)[column.DataType]}
                                onChange={this.handleChange}
                                fullWidth={fullWidth}>
                                {column.DataType === ColumnDataType.BOOLEAN_TRISTATE &&
                                    BooleanInputOperators.map((option) => (
                                        <MenuItem key={option.Value} value={option.Value}>
                                            {(locale as any)[option.Title]}
                                        </MenuItem>
                                    ))}
                            </TextField>
                        </TooltipInfoLabel>
                    );
                }
                break;
            case ColumnDataType.ENUM:
                {
                    content = (
                        <TooltipInfoLabel className={classes.flex} content={column.Tooltip}>
                            <TextField
                                select
                                margin="none"
                                style={style}
                                id={column.Name}
                                name={column.Name}
                                disabled={disabled || false}
                                value={localValue}
                                label={label}
                                type={(ColumnDataTypeToHtmlType as any)[column.DataType]}
                                onChange={this.handleSelectChange}
                                fullWidth={fullWidth}>
                                {
                                    (this.state.dataSource || []).map((option: any) => (
                                        <MenuItem key={option.key || DefaultNullValue} value={option.key || DefaultNullValue}>
                                            {option.value}
                                        </MenuItem>))

                                    /*
                                    ((typeof column.DataSource == "function" ? column.DataSource() : column.DataSource) || [])
                                            .items.map((option: any) => (
                                                <MenuItem key={option.key || DefaultNullValue} value={option.key || DefaultNullValue}>
                                                    {option.value}
                                </MenuItem>))                       
                                */
                                }
                            </TextField>
                        </TooltipInfoLabel>
                    );

                }
                break;
                {
                    /*
                     <Autocomplete
                      multiple
                      id="checkboxes-tags-demo"
                      options={top100Films}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.title}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.title}
                        </li>
                      )}
                      style={{ width: 500 }}
                      renderInput={(params) => (
                        <TextField {...params} label="Checkboxes" placeholder="Favorites" />
                      )}
                    />
                     */
                }
            case ColumnDataType.ENUM_AUTOCOMPLETE_MULTIPLE:
                {
                    content = (
                        <TooltipInfoLabel className={classes.flex} content={column.Tooltip}>
                            <Autocomplete
                                className={classes.autocomplete}
                                id={column.Name + '_autocomplete_multiple'}
                                freeSolo={!column.ForceSelection}
                                multiple
                                disableCloseOnSelect
                                open={this.state.open}
                                onOpen={() => this.setState({ open: true })}
                                onClose={() => this.setState({ open: false })}
                                getOptionLabel={option => option.value || ""}
                                isOptionEqualToValue={(option, value) => option.key === value.key}
                                value={this.state.selectValue}
                                loadingText={this.props.locale.LoadingWithEllipsis}
                                noOptionsText={this.props.locale.NoData}
                                onChange={this.handleDynamicSelectChange}
                                options={this.state.dataSource}
                                loading={this.state.open && this.state.loading}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        <Checkbox
                                            icon={checkboxIcon}
                                            checkedIcon={checkedCheckboxIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option.value}
                                    </li>
                                )}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        id={column.Name}
                                        name={column.Name}
                                        margin="none"
                                        label={column.Label}
                                        //CHECKME!!! temporary! onChange={(event) => {
                                            //this.loadDataSource(event.target.value);
                                        //}}
                                        fullWidth={fullWidth}
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <React.Fragment>
                                                    {this.state.loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </TooltipInfoLabel>);
                }
                break;

            case ColumnDataType.ENUM_AUTOCOMPLETE:
                {
                    content = (
                        <TooltipInfoLabel className={classes.flex} content={column.Tooltip}>
                            <Autocomplete
                                className={classes.autocomplete}
                                id={column.Name + '_autocomplete'}
                                freeSolo={!column.ForceSelection}
                                open={this.state.open}
                                onOpen={() => this.setState({ open: true })}
                                onClose={() => this.setState({ open: false })}
                                getOptionLabel={option => option.value || ""}
                                isOptionEqualToValue={(option, value) => option.key === value.key}
                                value={this.state.selectValue}
                                loadingText={this.props.locale.LoadingWithEllipsis}
                                noOptionsText={this.props.locale.NoData}
                                onChange={this.handleDynamicSelectChange}
                                options={this.state.dataSource}
                                loading={this.state.open && this.state.loading}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        id={column.Name}
                                        name={column.Name}
                                        margin="none"
                                        label={column.Label}
                                        onChange={(event) => {
                                            this.loadDataSource(event.target.value);
                                        }}
                                        fullWidth={fullWidth}
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <React.Fragment>
                                                    {this.state.loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </TooltipInfoLabel>);
                }
                break;
            case ColumnDataType.FILE:
                {
                    content = (
                        <div className={classes.preview}>
                            <InputLabel className={classes.dropZoneCaption}>{label}</InputLabel>
                            <DropzoneArea
                                filesLimit={1}
                                dropzoneClass={classes.dropzone}
                                dropzoneParagraphClass={classes.dropZoneLabel}
                                dropzoneText={locale.DragOrSelectFileText}
                                showAlerts={false}
                                showPreviews={true}
                                showPreviewsInDropzone={false}
                                showFileNames={true}
                                showFileNamesInPreview={true}
                                maxFileSize={50000000}
                                previewText=''
                                onChange={this.handleFileChange}
                                getFileLimitExceedMessage={(filesLimit) => locale.MaximumNumberFilesExceededFn(filesLimit)}
                                getFileAddedMessage={(fileName) => locale.FileAddedFn(fileName)}
                                getFileRemovedMessage={(fileName) => locale.FileRemoved(fileName)}
                                getDropRejectMessage={(rejectedFile, acceptedFiles, maxFileSize) => {
                                    let message = locale.FileRejectedFn(rejectedFile.name as string);
                                    if (!acceptedFiles.includes(rejectedFile.type)) {
                                        message += locale.FileTypeNotSupported
                                    }
                                    if (rejectedFile.size > maxFileSize) {
                                        message += locale.FileIsTooBigFn(convertBytesToMbsOrKbs(maxFileSize))
                                    }
                                    return message;
                                }}
                            />
                        </div>
                    );
                }
                break;
        }

        return (content);
    }
}

export default withStyles(styles)(DialogInput);