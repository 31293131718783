import * as React from 'react';
// import { connect } from 'react-redux';

import { debounce } from 'throttle-debounce';
import clsx from 'clsx';
import stringInject from 'stringinject';
import Globals from "@Base/Globals";

import "@Styles/pages/CalculationResultsPage.scss";

import { Spinner } from "@Components/shared/Spinner";
// import * as DomRfStore from "@Store/DomRfStore";
// import { withRouter } from "../../../../components/shared/CustomRouter";
import { Theme } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';

import Switch from '@mui/material/Switch';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import DateFnsUtils from '@date-io/date-fns';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Tooltip from '@mui/material/Tooltip';

//import CalculationParameters from '@Pages/instruments/calculator/CalculationParameters';
import Localization from '@Components/Localization';
import { ValidatorForm, TextValidator, SelectValidator, DatePickerValidator, TimePickerValidator, ComboboxValidator, NumericValidator } from "@Framework/components/Form";
import style from '@Styles/domrf-theme.module.scss';
import { BondStatus, FlowTypes, CouponType, CouponTypeStr } from '../../../../components/Definitions';
import { getDate, getTime, TabPanel } from '@Utilities';


import TooltipInfoLabel from '@Components/shared/TooltipInfoLabel';
import TooltipWithLink from '@Components/shared/TooltipWithLink';
//import AutoComplete from '@Components/shared/AutoComplete';
import { withTranslation } from "react-i18next";
import { Box, TextField } from '@mui/material';
import { ReactComponent as ScurveIcon } from "../../../../images/s-curve.svg"

import { styled } from '@mui/material/styles';
import { Update as UpdateIcon, Replay as ReplayIcon, SocialDistanceOutlined, } from '@mui/icons-material';
// import { theme } from 'highcharts';
import { toUnixDate } from '../../../../Utilities';
import { withCustomization } from '../../../../components/hoc/withCustomization';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

// const TimeField = styled((props)=>{ 
//     const {color,...otherProps}=props
//     return (<TextField {...otherProps}/>)
//     })(({ theme }) => ({
//                         }
//     ));


// const StyledTab = styled((props) => {
//     console.log(props);
//     return ( <Tab {...props} />)
// }
// )(({ theme }) => ({

//   }));

const styles = (theme: Theme) => ({
    description: {
        color: style.colorPrimary3,
        fontFamily: style.fontFamilyBase,
        fontSize: theme.typography.subtitle2.fontSize,
        lineHeight: theme.typography.subtitle2.lineHeight,
        letterSpacing: theme.typography.subtitle2.letterSpacing
    },
    tableHeader: {
        fontSize: '14px',
        //fontWeight: '600',
        textTransform: 'uppercase',
        color: style.colorPrimary3
    },
    overflow: {
        //height: '100%',
        overflowY: 'scroll',
        overflowX: 'auto',
        height: '450px'
    },
    toListButton: {
        position: 'absolute',
        left: '15px',
        bottom: '15px',
        zIndex: '1100'
    },
    tabContainer: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    tabButtons: {
        '& .MuiTab-root': {
            fontSize: '14px',
            minWidth: '72px'
        },
    },
    tab: {

        '& > div': { //CHECKME!!! temporary!
            padding: '0'
        }
    },
    fullTab: {

        display: 'flex',
        flexDirection: 'column',
        height: '100%',

        '& >div': {
            padding: '0',
            height: '100%'
        }
    },
    fullRowHeight: {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'row',
        height: '100%',
        boxSizing: 'border-box'
    },
    fullColumnHeight: {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column'
        //height: '100%',
        //boxSizing: 'border-box'
    },
    header: {

        [theme.breakpoints.down('sm')]: {
            paddingRight: '10px',
            paddingLeft: '10px',
        }
    },
    dialogTitle: {

        [theme.breakpoints.down('sm')]: {
            padding: '16px'
        }
    },
    container: {

        width: '100%',
        height: '100%',
        paddingRight: '15px',
        paddingLeft: '15px',
        marginRight: 'auto',
        marginLeft: 'auto',
        overflowX: 'hidden',

        [theme.breakpoints.up('xl')]: {
            maxWidth: '1400px'
        },
        [theme.breakpoints.down('xl')]: {
            maxWidth: '1400px'
        },
        [theme.breakpoints.down('xl')]: {
            maxWidth: '1300px'
        },
        [theme.breakpoints.down('lg')]: {
            maxWidth: '800px'
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: '550px'
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
            paddingRight: '0',
            paddingLeft: '0',
        }
    },

    cell: {
        fontWeight: '700',
        padding: '0.25rem 0.5rem'
    },
    toolbar: {
        display: 'flex',
        minHeight: '24px',
        justifyContent: 'flex-end',
        paddingLeft: '2px',
        paddingRight: '2px',
        borderBottom: '1px solid #e0e0e0'
    },
    aboutButton: {
        position: 'absolute',
        left: '15px',
        bottom: '15px',
        zIndex: '1100'
    },
    selectDescription: {
        fontSize: '14px'
    },
    buttonsContainer: {
        display: 'flex',
        justifyContent: 'space-between'
        //paddingLeft: '0.5rem',
        //paddingRight: '0.5rem'
    },
    infoText: {
        fontStyle: 'italic',
        fontSize: '14px'
    },
    none: {
        display: 'none'
    },
    flex: {
        display: 'flex'
    },
    historyTableRow: {
        '& td': {
            color: style.colorPrimary3
        }
    },
    usedHistoryTableRow: {
        '& td': {
            color: style.colorPrimary2
        }
    },
    hideButton: {
        position: 'absolute',
        cursor: 'pointer',
        fontSize: '20px',
        top: '0',
        right: '5px',
        zIndex: '1000'
    },
    hidden: {
        display: 'none'
    },
    bondDialog: {
        '& .MuiDialog-container': {

            alignItems: 'flex-start',

            '& .MuiDialog-paper': {
                top: '5%'
            }
        },
    },
    dialogContent: {
        padding: '10px'
    },
    layoutGrid: {
        '& .react-grid-item': {
            padding: '0.2rem'
        }
    },
    toggleLabel: {
        marginBottom: '0'
    },
    flowChartHeader: {
        textAlign: 'center',
        width: '100%',
        position: 'absolute'
    },
    flowChartHeaderContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        minHeight: '30px'
    },
    switchContainer:
    {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    leftValue: {
        borderRight: '1px solid ' + style.colorPrimary3
    },
    panel: {
        //overflow: 'auto'
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
    },
    leftCell: {
        paddingRight: 0
    },
    rightCell: {
        paddingLeft: 0
    },
    warning: {
        color: '#7bad38',
        fontSize: '16px'
    },
    flexContainer: {
        display: 'flex',
        width: '100%',
        justifyItems: 'stretch'
    },
    sCurveButton: {
        // padding: '0px 5px 1px 5px',
        fontSize: '1.5rem',
        lineHeight: '1.5rem',
        fontWeight: 600,
        alignSelf: 'baseline',
    },
    comboboxItemContainer: {
        padding: '2px 8px'
    },
    comboboxItem: {
        display: 'flex',
        justifyContent: 'end'
    },

    bondElementHeader: {
        display: 'flex',
        alignItems: 'center',
        marginRight: 'auto'
    },
    bondElementStatus: {
        display: 'flex',
        flexDirection: 'column'
    },
    paddingBottom2: {
        paddingBottom2:'0.5rem'
    }
});

const isBondIssued = (status: BondStatus) => {
    return status == BondStatus.STATUS_ISSUED || status == BondStatus.STATUS_REDEEMED;
}

const isBondVisible = (status: BondStatus) => {
    return status == BondStatus.STATUS_PLACEMENT || (status == BondStatus.STATUS_NOT_ISSUED && Globals.user.isEmployee);
}

const isPriceEditable = (status: BondStatus, notEditable: boolean) => {

    if (Globals.user.isEmployee)
        return true;

    if (status == BondStatus.STATUS_PLACEMENT) {
        return !notEditable;
    }

    return status == BondStatus.STATUS_ISSUED || status == BondStatus.STATUS_REDEEMED;
}

const gDateFnsUtils = new DateFnsUtils();

interface IProp {
    classes: any;
    bond: any;
    options: any[];

    init: () => void;
    loadBonds: (query: string, existId: string) => void;

    getBondData: (date: any, id: any) => void;

    //from 

    bonds_data: any[];
    bondsLoading: boolean;
    zcy_curve_base_chart_data: any[];
    operationLoading: boolean;
    ZCYCDate: any;
    bonds_points: any[];
    t: any;
    moexSecurities: any[];
    location: any;

    //
    requestCalculation: (data: any) => void;
    changeEvaluationMode: (value: number) => void;
    bondSelected: (bond: any) => void;
    onInputChanged: (data: any) => void;
    bonds: any[];
    bondStats: any;
    queryData: any;
    bondResults: any; //CHECKME!!! remove!!!!
}

interface IState {
    sCurveCPR: number | string
    tabIndex: number;
    ZCYCDate: Date;
    maxZCYCDate: Date;
    minZCYCDate: Date;
    evaluationDate: Date | string;
    operationLoading: boolean;
    cpr: number | string;
    cdr: number | string;

    isPlacementCPR: boolean;
    isPlacementCDR: boolean;

    variableCPR: any[];
    backupedCPR: number | string;
    backupedCDR: number | string;
    dirtyPrice: number | string;
    cleanPrice: number | string;
    zSpread: number | string;
    gSpread: number | string;
    couponType: string;
    couponRate: number | string;
    minEvaluationDate: Date;
    maxEvaluationDate: Date;
    isFixedCashflow: boolean;
    isUseKnownFlows: boolean;
    isUseAdjustedWAM: boolean;
    isCorrectedYield: boolean;
    conventionVersion: number;
    preSelectedBond: any;
    selectedBond: any;
    selectOpened: boolean;
    bondPreSearchValue: string;
    bondSearchValue: string;
    [key: string]: any;

    openZCYCTooltip: boolean;
    openCDRTooltip: boolean;
    showDisclaimer: boolean;
    showVersionsInfo: boolean;

    showConfirm: boolean;
    options: any[];
    //shouldNotUpdateIssueDate: boolean; //to prevent update statistics when evalDate changes to issue date
    showHistoryFlows: boolean;
    historyFlowsDisabled: boolean;

    //isFirstRun: boolean;
    isFirstCalculation: boolean;
    //isVariableCPR: boolean;

    isBondsLoaded: boolean;
    openGridTooltip: boolean;

    //isScenarioAnalysis: boolean;

    spectrumParameters: any;

    calculationModeTabIndex: number;
    priceDataType: boolean;

    queryData: {
        bondNameType: number;
        isin?: string;
        valueType?: number;
        gspread?: number;
        zspread?: number;
        price?: number;
        zcycdate?: Date;
        evaluationdate?: Date;
        cpr?: number;
        cdr?: number;
        conventionversion?: number;
        useknownflows?: boolean;
        useadjustedwam?: boolean;
        pricedatatype?: boolean;
        correctedyield?: boolean;
        couponrate?: number;
    };

    skipEvaluationDateDueLoad: boolean;

    isCustomForwardRate: boolean;

    flowsTabIndex: number;

    requestsToCalculate: number;

    lockPlacementPrice: boolean;
    lockPlacementCoupon: boolean;
    defaultPlacementPrice?: number;
    usePlacementCoupon: boolean;

    parametersLoading: boolean;
    updateZCYCFromProp: boolean;
    bondNameType: number;

    queryString: string;
    ZCYCStateChanged: boolean;
}

var CustomNumericValidator: any
var CustomTextValidator: any

class InputPanel extends React.Component<IProp, IState> {

    //static whyDidYouRender = true;

    constructor(props) {
        super(props);
        var queryData = this.props.query;
        // console.log('queryData',queryData)
        // console.log('props.test', props.test)
        this.state = {
            // test:queryData?.zcycdate,
            bondNameType: queryData?.bondNameType != null ? queryData.bondNameType : 3,
            updateZCYCFromProp: false,
            parametersLoading: false,
            isFirstCalculation: true,
            operationLoading: false,
            tabIndex: 4, //g-spread by default
            ZCYCDate: queryData.zcycdate != null ? queryData.zcycdate : new Date(),
            maxZCYCDate: new Date((new Date()).toDateString()),
            minZCYCDate: new Date('2014-01-07'),
            evaluationDate: queryData.evaluationdate != null ? getDate(new Date(queryData.evaluationdate)) : '',
            sCurveCPR: '',
            cpr: '',
            cdr: '',
            isPlacementCPR: false,
            isPlacementCDR: false,
            variableCPR: null,
            backupedCPR: '',
            backupedCDR: '',
            dirtyPrice: '',
            cleanPrice: '',
            zSpread: '',
            gSpread: '',
            couponType: CouponTypeStr.UNKNOWN,
            couponRate: '',
            minEvaluationDate: new Date(),
            maxEvaluationDate: gDateFnsUtils.addDays(new Date((new Date()).toDateString()), 10),
            isFixedCashflow: false,
            isUseKnownFlows: queryData.useknownflows != null ? queryData.useknownflows : true,
            preSelectedBond: null,
            selectedBond: null,
            selectOpened: false,
            bondSearchValue: '',
            bondPreSearchValue: '',
            openZCYCTooltip: false,
            openCDRTooltip: false,
            showDisclaimer: false,
            showVersionsInfo: false,
            showConfirm: false,
            bondId: null,
            options: [],
            isBondsLoaded: false,
            //showChart: false,
            //shouldNotUpdateIssueDate: false,
            showHistoryFlows: false,
            historyFlowsDisabled: true,
            //isVariableCPR: false,
            openGridTooltip: false,

            conventionVersion: queryData.conventionversion != null ? queryData.conventionversion : 1,
            isUseAdjustedWAM: queryData.useadjustedwam != null ? queryData.useadjustedwam : true,
            isCorrectedYield: queryData.correctedyield != null ? queryData.correctedyield : true,
            //isScenarioAnalysis: false,

            spectrumParameters: {},

            calculationModeTabIndex: 1, //base evaluation

            priceDataType: queryData.pricedatatype != null ? queryData.pricedatatype : false, //false - percents, true - rubles

            queryData: queryData,

            skipEvaluationDateDueLoad: queryData.zcycdate != null,

            isCustomForwardRate: false,

            flowsTabIndex: 1,
            requestsToCalculate: 0,


            lockPlacementPrice: false,
            lockPlacementCoupon: false,
            defaultPlacementPrice: null,
            usePlacementCoupon: false,

            ZCYCStateChanged: false
        };

        this.state.maxZCYCDate.setDate(this.state.maxZCYCDate.getDate() + 1);
        this.state.maxZCYCDate.setTime(this.state.maxZCYCDate.getTime() - 1);
    }

    submitForm = null;

    onLanguageChanged = () => {
        CustomNumericValidator = withCustomization(NumericValidator,
            {
                needConfirm: true,
                helper: Localization.Translate('PressEnterToLaunchCalculation'),
                helperShowDelay: 3000,
                changesHighlight: style.colorPrimary2_20,
            })

        CustomTextValidator = withCustomization(TextValidator,
            {
                needConfirm: true,
                helper: Localization.Translate('PressEnterToLaunchCalculation'),
                helperShowDelay: 3000,
                changesHighlight: style.colorPrimary2_20,
            })
    }

    componentDidMount() {

        Localization.onLanguageChanged(this.onLanguageChanged);
        this.onLanguageChanged()

        //console.log("---query data: " + JSON.stringify(this.state.queryData));
        //console.log("---bonds: " + JSON.stringify(this.props.bonds) );
        // if (new Date(this.state.zcycdate).toString() === 'Invalid Date') {
        //     this.setState({ ZCYCDate: new Date() })
        // }
    }

    componentWillUnmount() {
        this.requestCalculation.cancel();
        Localization.offLanguageChanged(this.onLanguageChanged);
    }

    componentDidUpdate(prevProps, prevState) {

        // console.log('this.props', this.props)
        // console.log('requestsToCalculate',this.state.requestsToCalculate)
        if (prevProps.queryData != this.props.queryData && this.state.queryData != this.props.queryData && this.props.queryData != null) {
            //console.log("===setting query data from props");
            this.setState({ queryData: this.props.queryData });
        }


        if ((this.props.bonds != prevProps.bonds && this.props.bonds != null) || (prevState.queryData != this.state.queryData)) {

            var isSet = false;

            if (this.props.bonds != null && (this.state.bondId != null || this.state.queryData.isin != null)) {

                //console.log("---bonds at update: " + JSON.stringify(this.props.bonds));

                var bond = null;
                if (this.state.bondId != null)
                    bond = this.props.bonds.find((item) => item.id == this.state.bondId);
                else if (this.state.queryData.isin != null)
                    bond = this.props.bonds.find((item) => item.isin == this.state.queryData.isin);

                var changes = {};
                if (bond != null) {
                    //console.log('===bond found');

                    /*
                    if (isBondVisible(bond.bondStatus)) {

                        changes['cleanPrice'] = 100;
                        changes['dirtyPrice'] = 100;
                    }

                    if (bond?.bondStatus == BondStatus.STATUS_NOT_ISSUED ||
                        bond?.bondStatus == BondStatus.STATUS_PLACEMENT) {

                        changes.evaluationDate = getDate(new Date(bond.issueDate));
                        changes.minEvaluationDate = changes.evaluationDate;
                        changes.maxEvaluationDate = changes.evaluationDate;
                    }
                    else if (bond?.bondStatus == BondStatus.STATUS_REDEEMED) {

                        changes.evaluationDate = getDate(new Date(bond.issueDate));
                        changes.minEvaluationDate = getDate(new Date(bond.issueDate));
                        changes.maxEvaluationDate = getDate(new Date(bond.redeemDate));
                    }
                    else {
                        changes.evaluationDate = getDate(new Date());
                        changes.minEvaluationDate = getDate(new Date(bond.issueDate));
                        changes.maxEvaluationDate = gDateFnsUtils.addDays(getDate(new Date()), 10);
                    }
                    */

                    var options = this.props.bonds == null ? [] : this.props.bonds;
                    var opts = options.map(x => {
                        return {
                            ...x,
                            issueDate: (x.issueDate ? new Date(Date.parse(x.issueDate)) : null),
                            redeemDate: (x.redeemDate ? new Date(Date.parse(x.redeemDate)) : null)
                        }
                    });

                    bond = opts.find(item => item.id == bond.id);


                    this.setState({ options: opts, bondId: null, ...changes }, () => this.onBondSelected(null, bond));
                    isSet = true;
                }
                //else
                //console.log("===bond is not found");
            }

            if (!isSet) {
                var options = this.props.bonds == null ? [] : this.props.bonds;

                var opts = options.map(x => {
                    return {
                        ...x,
                        issueDate: (x.issueDate ? new Date(Date.parse(x.issueDate)) : null),
                        redeemDate: (x.redeemDate ? new Date(Date.parse(x.redeemDate)) : null)
                    }
                });
                this.setState({ options: opts, bondId: null, isBondsLoaded: true, ...changes });
            }
        }

        if (prevState.selectedBond != this.state.selectedBond ||
            getDate(prevState.evaluationDate)?.getTime() != getDate(this.state.evaluationDate)?.getTime()) {

            if (this.state.selectedBond != null &&
                this.state.evaluationDate != null && this.state.evaluationDate.toString() != 'Invalid Date') {

                //if (!this.state.shouldNotUpdateIssueDate) {

                var evaluationDatePrev = prevState.evaluationDate != '' ? getDate(prevState.evaluationDate) : null;
                var evaluationDateNext = getDate(this.state.evaluationDate);

                if (evaluationDateNext != null && evaluationDatePrev?.getTime() != evaluationDateNext?.getTime() || prevState.selectedBond != this.state.selectedBond) {
                    this.setState({ parametersLoading: true });
                    // console.log('this.state.skipEvaluationDateDueLoad', this.state.skipEvaluationDateDueLoad)
                    this.props.getBondData(Localization.FormatDateISO(this.state.evaluationDate), this.state.selectedBond.id, this.state.skipEvaluationDateDueLoad);
                }

                if (this.state.selectedBond.couponType === CouponType.TYPE_KEYRATE_AND_PREMIUM
                    ||
                    this.state.calculationModeTabIndex === 2) {
                    // Смена Даты оценки и тип купона === 6 или выбран переменный CPR
                    this.cleanupResults()
                    this.onBondSelected(null, this.state.selectedBond)
                }
            }

            //if (this.state.shouldNotUpdateIssueDate)
            //this.setState({ shouldNotUpdateIssueDate: false });
            // console.log('skipEvaluationDateDueLoad',this.state.skipEvaluationDateDueLoad, this.state.isUseKnownFlows)
            // let changes={}
            // if(bond !== null){
            //     console.log(bond)

            //     if(bond?.couponType === CouponType.TYPE_KEYRATE_AND_PREMIUM){
            //         this.cleanupResults()
            //     }
            //     changes.calculationModeTabIndex = 1;
            //     this.props.changeEvaluationMode(changes.calculationModeTabIndex)

            // }


            if (this.state.selectedBond == null) {
                this.cleanupResults();
            }
            else {
                // console.log('this.state', this.state)
                // if (!this.state.skipEvaluationDateDueLoad) {
                // this.setState({ isUseKnownFlows: this.state.isUseKnownFlows === false ? this.state.isUseKnownFlows : !isBondVisible(this.state.selectedBond.bondStatus) });
                // }
                this.setState({ isUseKnownFlows: !isBondVisible(this.state.selectedBond.bondStatus) });
            }
        }

        if (prevProps.bondStats != this.props.bondStats && this.props.bondStats != null) {

            //var date = new Date(this.state.ZCYCDate);

            var cdrTooltip = this.state.openCDRTooltip;
            if (cdrTooltip === false && prevProps.bondStats?.isFixedCashflow != this.props.bondStats?.isFixedCashflow && this.props.bondStats?.isFixedCashflow === true) {
                cdrTooltip = true;
            }

            var changes = this.formQueryChangeState();
            // console.log('changes.cpr',changes.cpr, this.props.bondStats)
            changes.parametersLoading = false;

            //var evaluationDate = changes.evaluationDate || this.state.evaluationDate;
            //var isChangeZCYC = false;
            if (this.state.selectedBond.bondStatus == BondStatus.STATUS_NOT_ISSUED ||
                this.state.selectedBond.bondStatus == BondStatus.STATUS_PLACEMENT) {

                if (this.props.bondStats.defaultPlacementPrice != null) {
                    changes.dirtyPrice = Math.round(this.props.bondStats.defaultPlacementPrice * 100) / 100.0;
                    changes.cleanPrice = changes.dirtyPrice;
                }

                //evaluationDate = getDate(new Date(this.props.bondStats.issueDate));
                //if (this.state.selectedBond.couponType == CouponType.TYPE_KEYRATE_AND_PREMIUM) {
                //changes.ZCYCDate = getTime(new Date());
                //}
            }
            /*
            else {

                if (this.state.selectedBond != prevState.selectedBond)
                    evaluationDate = getDate(new Date());

                if (evaluationDate < new Date(this.props.bondStats.issueDate)) {
                    evaluationDate = getDate(new Date(this.props.bondStats.issueDate));
                }

                if (this.state.selectedBond.bondStatus == BondStatus.STATUS_REDEEMED) {
                    changes.maxEvaluationDate = new Date(this.state.selectedBond.redeemDate);
                    evaluationDate = new Date(this.state.selectedBond.issueDate);
                }
                else if (evaluationDate.getTime() > this.state.maxEvaluationDate.getTime()) {
                    evaluationDate = this.state.maxEvaluationDate;
                }
            }

            changes.evaluationDate = evaluationDate;
            */

            if (changes.cpr == null || changes.cpr == undefined) {
                if (!this.state.skipEvaluationDateDueLoad) {
                    changes.cpr = this.props.bondStats.cpr == null ? '' : Math.round(this.props.bondStats.cpr * 100) / 100.0;
                    changes.backupedCPR = changes.cpr;
                }
            }
            else
                changes.backupedCPR = changes.cpr;

            if (changes.cdr == null) {
                if (!this.state.skipEvaluationDateDueLoad) {
                    changes.cdr = this.props.bondStats.cdr == null ? '' : Math.round(this.props.bondStats.cdr * 100) / 100.0;
                    changes.backupedCDR = changes.cdr;
                }
            }
            else
                changes.backupedCDR = changes.cdr;

            //var issueDate = (isBondVisible(this.state.selectedBond?.bondStatus)) ? new Date(this.props.bondStats.issueDate) : evaluationDate;

            if (Globals.user.isEmployee || this.state.queryData.isin != null) {

                if (changes.dirtyPrice == null && !this.state.skipEvaluationDateDueLoad)
                    changes.dirtyPrice = (isBondVisible(this.state.selectedBond?.bondStatus)) ? 100 : (this.props.bondStats.dirtyPrice == null ? '' : Math.round(this.props.bondStats.dirtyPrice * 100) / 100.0);
                if (changes.cleanPrice == null && !this.state.skipEvaluationDateDueLoad)
                    changes.cleanPrice = (isBondVisible(this.state.selectedBond?.bondStatus)) ? 100 : (this.props.bondStats.cleanPrice == null ? '' : Math.round(this.props.bondStats.cleanPrice * 100) / 100.0);
                if (changes.zSpread == null && !this.state.skipEvaluationDateDueLoad)
                    changes.zSpread = this.props.bondStats.zSpread == null ? '' : Math.round(this.props.bondStats.zSpread * 100) / 100.0;
                if (changes.gSpread == null && !this.state.skipEvaluationDateDueLoad)
                    changes.gSpread = this.props.bondStats.gSpread == null ? '' : Math.round(this.props.bondStats.gSpread * 100) / 100.0;

                if (this.state.queryData.isin != null) {
                    changes.calculationModeTabIndex = 1; //CHECKME!!! for now, we force to standard calculation (variable cdr atc not supported yet)
                    changes.queryData = {};
                }
            }

            if (this.props.bondStats.isFixedCashflow == true) {

                this.props.changeEvaluationMode(1);

                changes['calculationModeTabIndex'] = 1; //base evaluation
                changes['conventionVersion'] = 1; //only v1.0
            }

            if (changes.couponRate == null && this.state.couponRate == '') {

                if ((this.state.selectedBond.bondStatus == BondStatus.STATUS_NOT_ISSUED ||
                    this.state.selectedBond.bondStatus == BondStatus.STATUS_PLACEMENT) &&
                    this.props.bondStats.usePlacementCoupon) {
                    changes.couponRate = (this.props.bondStats.couponRate == null ? '' : this.props.bondStats.couponRate);
                }
                else {
                    changes.couponRate = (isBondVisible(this.state.selectedBond?.bondStatus)) ? '' : (this.props.bondStats.couponRate == null ? '' : this.props.bondStats.couponRate);
                }
            }

            //console.log('this.props.bondStats',this.props.bondStats)

            this.setState({

                queryData: {},
                isFirstCalculation: false,

                isPlacementCPR: this.props.bondStats.isPlacementCPR,
                isPlacementCDR: this.props.bondStats.isPlacementCDR,

                lockPlacementPrice: this.props.bondStats.lockPlacementPrice,
                lockPlacementCoupon: this.props.bondStats.lockPlacementCoupon,

                isFixedCashflow: this.props.bondStats.isFixedCashflow,
                couponType: this.props.bondStats.couponType,
                openCDRTooltip: cdrTooltip,
                //minEvaluationDate: getDate(new Date(this.props.bondStats.issueDate)),
                //evaluationDate: issueDate,
                //shouldNotUpdateIssueDate: evaluationDate.getTime() != issueDate.getTime(),
                ...changes
            }, () => {

                //dirty hack
                if (changes.evaluationDate != null)
                    this.notifyInputChanged();

                if (this.state.openCDRTooltip) {
                    setTimeout(() => this.handleCDRTooltipClose(), 3000);
                }

                //console.log("Get bondStats results");
                this.setState({ skipEvaluationDateDueLoad: false, requestsToCalculate: this.state.requestsToCalculate + 1 });
            });
        }

        // console.log('props',this.props.query)
        // if (this.props.query && this.props.query.cpr != null && prevProps.cpr != this.props.query.cpr) {
        //      this.setState({ cpr: this.props.query.cpr, update });
        //  }
        // console.log('PRE check date: ' + this.props.ZCYCDate);

        if (this.props.ZCYCDate != null && prevProps.ZCYCDate != this.props.ZCYCDate) {

            //  console.log('received ZCYC propz at date: ' + this.props.ZCYCDate);
            // console.log('received corrected ZCYC props  at date: ' + getTime(this.props.ZCYCDate));

            if (this.state.ZCYCStateChanged) {
                // console.log('this.props.ZCYCDate', this.props.ZCYCDate)
                this.setState({ ZCYCDate: getTime(this.props.ZCYCDate), ZCYCStateChanged: false, updateZCYCFromProp: true });
            }
            //var tempZCYCDate = getTime(this.props.ZCYCDate);

        }


        if (getDate(prevState.evaluationDate)?.getTime() != getDate(this.state.evaluationDate)?.getTime() && !this.state.skipEvaluationDateDueLoad) {

            //console.log('changed evaluation date');
            //not current day
            var zcycDate = new Date(this.state.evaluationDate.getFullYear(), this.state.evaluationDate.getMonth(), this.state.evaluationDate.getDate(), 23, 59, 59);
            this.setState({ ZCYCDate: zcycDate }, this.notifyInputChanged);
        }

        if ((new Date(prevState.ZCYCDate).toString() !== 'Invalid Date') &&
            prevState.ZCYCDate?.getTime() != this.state.ZCYCDate?.getTime() &&
            (this.state.ZCYCDate != null || this.state.updateZCYCFromProp)) {

            var isUpdate = this.state.updateZCYCFromProp;
            var changes = {};
            changes.updateZCYCFromProp = false;

            if (isUpdate) {

                //console.log('received ZCYC data  from props: ' + this.state.ZCYCDate);

                changes.requestsToCalculate = this.state.requestsToCalculate + 1;
            }
            else {

                //console.log('received ZCYC data itself: ' + this.state.ZCYCDate);
                changes.ZCYCStateChanged = true;
                this.notifyInputChanged();
            }

            this.setState(changes);
        }

        if (prevState.requestsToCalculate != this.state.requestsToCalculate) {

            //console.log(`Data acquired!, this.state.requestsToCalculate: ${this.state.requestsToCalculate}, this.state.parametersLoading:${this.state.parametersLoading}`);
            //we need to update outer data

            //we don't update inputs if zcycDate is in progress

            if (!this.state.updateZCYCFromProp && this.state.requestsToCalculate == 0) {
                // console.log('notifyInputChanged')
                this.notifyInputChanged();
            }

            if (this.state.requestsToCalculate != 0 && this.state.parametersLoading === false) {
                // console.log('requestsToCalculate : 0 ')
                this.setState({ requestsToCalculate: 0 });
            }
            else if (this.state.requestsToCalculate == 0) {
                // console.log('requestsToCalculate : ',this.state.isFirstCalculation)
                this.requestCalculation(this.state.isFirstCalculation);
            }
        }
    }

    formQueryChangeState = () => {

        var changes: any = {};

        if (this.state.queryData.isin == null)
            return changes;

        if (this.state.queryData.valueType != null) {
            changes.tabIndex = this.state.queryData.valueType;
            switch (this.state.queryData.valueType) {
                case 2:
                    {
                        var price = Math.round(this.state.queryData.price * 100) / 100.0;

                        changes.dirtyPrice = price;
                        changes.cleanPrice = price;
                    }
                    break;
                case 3:
                    {
                        changes.zSpread = this.state.queryData.zspread;
                    }
                    break;
                case 4:
                    {
                        changes.gSpread = this.state.queryData.gspread;
                    }
                    break;
            }
        }

        if (this.state.queryData.bondNameType != null) {
            changes.bondNameType = this.state.queryData.bondNameType
        }

        //  console.log('(new Date(this.state.queryData?.zcycdate',new Date(this.state.queryData?.zcycdate), this.state.selectedBond.bondStatus )
        if (this.state.selectedBond.bondStatus === BondStatus.STATUS_REDEEMED && (new Date(this.state.queryData?.zcycdate).toString() === 'Invalid Date')) {
            // для погашеных бондов выставляем дату КБД в дату выпуска
            changes.ZCYCDate = new Date(this.state.selectedBond.issueDate);
            changes.isManualZCYCChange = false; //force to update date
        }
        else
            if (this.state.queryData.zcycdate != null) {
                changes.ZCYCDate = this.state.queryData.zcycdate;
                changes.isManualZCYCChange = true; //force to update date
            }

        if (this.state.queryData.evaluationdate != null) {
            changes.evaluationDate = getDate(this.state.queryData.evaluationdate);
        }

        if (this.state.queryData.cpr != null) {
            changes.cpr = Math.round(this.state.queryData.cpr * 100) / 100.0;
            // console.log('cpr',changes.cpr)
        }

        if (this.state.queryData.cdr != null) {
            changes.cdr = Math.round(this.state.queryData.cdr * 100) / 100.0;
        }

        if (this.state.queryData.conventionversion != null) {
            changes.conventionVersion = this.state.queryData.conventionversion;
        }

        if (this.state.queryData.useknownflows != null) {
            changes.isUseKnownFlows = this.state.queryData.useknownflows;
        }

        if (this.state.queryData.useadjustedwam != null) {
            changes.isUseAdjustedWAM = this.state.queryData.useadjustedwam;
        }

        if (this.state.queryData.correctedyield != null) {
            changes.isCorrectedYield = this.state.queryData.correctedyield;
        }

        if (this.state.queryData.pricedatatype != null) {
            changes.priceDataType = this.state.queryData.pricedatatype;
        }

        if (this.state.queryData.couponrate != null) {
            changes.couponRate = this.state.queryData.couponrate;
        }

        return changes;
    }

    notifyInputChanged = () => {

        if (this.props.onInputChanged == null)
            return;

        var parameters = {
            bondNameType: this.state.bondNameType,
            ZCYCDate: this.state.ZCYCDate,
            evaluationDate: this.state.evaluationDate == '' ? null : this.state.evaluationDate,
            backupedCPR: this.state.backupedCPR,
            cpr: this.state.cpr,
            cdr: this.state.cdr,
            backupCPR: this.state.backupedCPR,
            zSpread: this.state.zSpread,
            gSpread: this.state.gSpread,
            couponRate: this.state.couponRate,
            couponType: this.state.couponType,
            cleanPrice: this.state.cleanPrice,
            dirtyPrice: this.state.dirtyPrice,
            priceDataType: this.state.priceDataType,
            selectedBond: this.state.selectedBond,
            variableFixType: this.state.tabIndex,
            isUseAdjustedWAM: this.state.isUseAdjustedWAM,
        };

        this.props.onInputChanged(parameters);
    };

    /*
    formDescription = (option: any, type: number): string => {

        if (option == null)
            return '';

        switch (option.bondStatus) {
            case BondStatus.STATUS_NOT_ISSUED:
                {
                    return this.getBondName(option, type) + ' ' + this.props.t('Preparing');
                }
            case BondStatus.STATUS_PLACEMENT:
                {
                    return this.getBondName(option, type) + ' ' + this.props.t('PreIssue');
                }
            case BondStatus.STATUS_ISSUED:
                {
                    return this.getBondName(option, type);
                }
            case BondStatus.STATUS_REDEEMED:
                {
                    return this.getBondName(option, type) + ' ' + this.props.t('Redeemed');
                }
            default:
                return option.displayName;
        }
    }
    */
    /*
    formDescription = (option: any, type: number): React.ReactNode => {
    
        if (option == null)
            return <span/>;
    
        switch (option.bondStatus) {
            case BondStatus.STATUS_NOT_ISSUED:
                {
                    return (<React.Fragment>
                            <span className='me-auto'>this.getBondName(option, type)</span>
                            <span>this.props.t('Preparing')</span>
                            </React.Fragment>
                            );
                }
            case BondStatus.STATUS_PLACEMENT:
                {
                    return (<React.Fragment>
                            <span className='me-auto'>this.getBondName(option, type)</span>
                            <span>this.props.t('PreIssue')</span>
                            </React.Fragment>
                            );
                }
            case BondStatus.STATUS_ISSUED:
                {
                    return (<span>this.getBondName(option, type)</span>);
                }
            case BondStatus.STATUS_REDEEMED:
                {
                    return (<React.Fragment>
                            <span className='me-auto'>this.getBondName(option, type)</span>
                            <span>this.props.t('Redeemed')</span>
                            </React.Fragment>
                            );
                }
            default:
                {
                    return (<span>option.displayName</span>);
                }
        }
    }
    */


    handleConvVersionTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        this.setState({ conventionVersion: newValue, requestsToCalculate: this.state.requestsToCalculate + 1 });
    };

    handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        this.setState({ tabIndex: newValue, requestsToCalculate: this.state.requestsToCalculate + 1 });
    };

    handleModeTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {

        this.props.changeEvaluationMode(newValue);

        this.setState({ calculationModeTabIndex: newValue, requestsToCalculate: this.state.requestsToCalculate + 1 });
    };

    handleDateChange = (name: keyof IState, value: Date) => {

        if (value.toString() != 'Invalid Date') {
            // console.log('handleDateChange')
            this.setState({ [name]: value });
        }
    }

    handleZCYCDateChange = (value: Date) => {
        if (value.toString() != 'Invalid Date') {
            var result = value;

            //not current day
            if (getDate(value).getTime() != getDate(new Date()).getTime()) {
                result = new Date(value.getFullYear(), value.getMonth(), value.getDate(), 23, 59, 59); //set time to end of the day
            }

            this.setState({ ZCYCDate: result });
        }
    }

    handleZCYCTimeChange = (value: Date) => {
        // console.log(value)
        if (value.toString() != 'Invalid Date') {
            this.setState({ ZCYCDate: value });
        }
    }

    handleDateTimeChange = (name: keyof IState, value: Date) => {
        if (value.toString() != 'Invalid Date') {
            this.setState({ [name]: value });
        }
    }

    handleRestore = (event) => {
        event.preventDefault();
        event.stopPropagation();
        // console.log('ZCYCDate: getTime(new Date()) ', {ZCYCDate: getTime(new Date())} )
        this.setState({ ZCYCDate: getTime(new Date()) });
    }

    handleRestoreCDR = (event) => {
        // console.log('handleRestoreCDR', this.state.backupedCDR, this.props.bondStats.cdr)
        this.setState({ cdr: this.props.bondStats?.cdr ? Math.round(this.props.bondStats.cdr * 100.0) / 100.0 : this.state.backupedCDR, requestsToCalculate: this.state.requestsToCalculate + 1 });
    }

    handleRestoreCPR = (event) => {
        // console.log('handleRestoreCDR', this.state.backupedCPR, this.props.bondStats.cpr)

        this.setState({ cpr: this.props.bondStats?.cpr ? Math.round(this.props.bondStats.cpr * 100.0) / 100.0 : this.state.backupedCPR, requestsToCalculate: this.state.requestsToCalculate + 1 });
    }

    handleSetSCurveCPR = (event) => {
        // console.log('sCurveCPR', this.props.bondStats.sCurveCPR, this.props.bondResults?.zcyccpr)
        // if(this.props.bondResults?.zcyccpr ) this.setState({ cpr: Math.round(this.props.bondResults?.zcyccpr * 100.0) / 100.0, requestsToCalculate: this.state.requestsToCalculate + 1 });
        if (this.props.bondStats?.sCurveCPR || this.props.bondResults?.zcyccpr)
            this.setState({ cpr: Math.round((this.props.bondStats?.sCurveCPR ? this.props.bondStats.sCurveCPR : this.props.bondResults?.zcyccpr ? this.props.bondResults?.zcyccpr : 0) * 100.0) / 100.0, requestsToCalculate: this.state.requestsToCalculate + 1 });
    }

    /*
    updateZCYC = () => {
    
        //this.props.getComplexYZCYCC(this.state.ZCYCDate);
        //this.props.loadMoexSecurities(this.state.ZCYCDate);
    
        this.setState({ requestsToCalculate: this.state.requestsToCalculate + 1 });
    }
    */

    handleValueChange = (name: keyof IState) => (event: React.ChangeEvent<HTMLInputElement>) => {
        // console.log('event', event)
        // if(event?.currentTarget?.value)
        this.setState({ [name]: event.currentTarget.value != '' ? Number(event.currentTarget.value) : '', requestsToCalculate: this.state.requestsToCalculate + 1 });
    }

    handleWholeValueChange = (name: keyof IState) => (event: React.ChangeEvent<HTMLInputElement>) => {

        this.setState({ [name]: event.currentTarget.value != '' ? Math.floor(Number(event.currentTarget.value)) : '', requestsToCalculate: this.state.requestsToCalculate + 1 });
    }

    onBondSelected = (event: any, bond: any) => {

        //console.log('===onBondSelected called');
        // console.log('bond', bond)

        var changes = {};
        if (bond != null && isBondVisible(bond.bondStatus)) {
            if (this.state.tabIndex != 2) {
                changes['tabIndex'] = 2;
            }
            //if no data at request returned
            changes['cleanPrice'] = 100;
            changes['dirtyPrice'] = 100;
        }
        else {

            if (bond?.bondStatus != BondStatus.STATUS_PLACEMENT && this.state.tabIndex != 4) {
                changes['tabIndex'] = 4;
            }

            if (!Globals.user.isEmployee) {
                changes['cleanPrice'] = '';
                changes['dirtyPrice'] = '';
                changes['zSpread'] = '';
                changes['gSpread'] = '';
            }
        }

        changes['priceDataType'] = false; //false - percents, true - rubles
        changes['couponRate'] = ''; //clear

        if (bond?.bondStatus == BondStatus.STATUS_NOT_ISSUED ||
            bond?.bondStatus == BondStatus.STATUS_PLACEMENT) {

            changes.evaluationDate = getDate(bond.issueDate);
            changes.minEvaluationDate = changes.evaluationDate;
            changes.maxEvaluationDate = changes.evaluationDate;
            // console.log(1,2)
        }
        else if (bond?.bondStatus == BondStatus.STATUS_REDEEMED) {
            // changes.evaluationDate = getDate(bond.issueDate);
            // changes.evaluationDate = (this.state.evaluationDate ?? '') != '' ? getDate(this.state.evaluationDate) : getDate(bond.issueDate) // иначе при передаче в ссылке иных значений CRP CDR проивзохит их инициализация
            changes.evaluationDate = getDate(bond.issueDate) // иначе при передаче в ссылке иных значений CRP CDR проивзохит их инициализация
            changes.minEvaluationDate = getDate(bond.issueDate);
            changes.maxEvaluationDate = getDate(bond.redeemDate);
            // console.log(4)
        }
        else if (bond != null) {
            // console.log(this.state.evaluationDate)
            changes.evaluationDate = (this.state.evaluationDate ?? '') != '' ? getDate(this.state.evaluationDate) : getDate(new Date());
            changes.minEvaluationDate = getDate(bond.issueDate);
            changes.maxEvaluationDate = gDateFnsUtils.addDays(getDate(new Date()), 10);
            // console.log(3)
        }

        if (bond != null && bond?.bondStatus !== BondStatus.STATUS_REDEEMED) {
            if (toUnixDate(changes.minEvaluationDate) > toUnixDate(changes.evaluationDate)) {
                // console.log(changes.minEvaluationDate, changes.evaluationDate)
                // console.log('eval Date < min Date')
                changes.evaluationDate = getDate(new Date())
            }
        }

        changes.isManualZCYCChange = false

        if (bond === null) {
            // console.log('reset date to current')
            changes.isUseKnownFlows = true // initial value
            changes.evaluationDate = getDate(new Date())
        }

        if (bond != null && bond?.couponType === CouponType.TYPE_KEYRATE_AND_PREMIUM || bond?.bondStatus === BondStatus.STATUS_REDEEMED) {
            //если bondType === 6 || bondStatus === 4 то переключаем на Стандартный расчет
            const newCalculationModeTabIndex = 1;
            this.handleModeTabChange(null, newCalculationModeTabIndex)
        }

        this.props.bondSelected(bond);

        this.setState({ selectedBond: bond, ...changes });
    }

    handleCDRTooltipClose = () => {
        this.setState({ openCDRTooltip: false });
    }

    cleanupResults() {
        this.props.requestCalculation(null); //cleanup
    }

    requestCalculation = debounce(500, async (validateWithoutErrors) => {

        var res = await this.submitForm.isFormValid(validateWithoutErrors);
        if (res == false) {
            this.cleanupResults();
            return;
        }

        //console.log("Let's calculate!");
        //CHECKME!!! ugly, replace by component method to receive parameters instead of cachiing it here
        /*
        var parameters = {};
        if (this.state.calculationModeTabIndex == 3) { //scenario analysis
            var { checkCouponRate, ...other } = this.state.spectrumParameters;
            if (!other.isValid)
                return;
    
            if (this.state.selectedBond?.bondStatus == BondStatus.STATUS_PLACEMENT && this.state.couponRate != checkCouponRate)
                return; //seems, not refreshed
    
            parameters = other;
        }
    
        if (this.state.selectedBond?.couponType == CouponType.TYPE_KEYRATE_AND_PREMIUM && this.state.keyRates == null)
            return; //no data to send
        */
        var data = {
            bondNameType: this.state.bondNameType,
            ISIN: this.state.selectedBond.isin,
            BondID: this.state.selectedBond.id,
            EvaluationDate: Localization.FormatDateISO(this.state.evaluationDate),
            ZCYCDate: Localization.FormatDateTimeISO(this.state.ZCYCDate),
            CPR: this.state.cpr,
            CDR: this.state.cdr,
            //VariableCPR: this.state.calculationModeTabIndex == 2 ? this.state.variableCPR : null, //variable CPR
            DirtyPrice: this.state.dirtyPrice !== "" ? [this.state.dirtyPrice] : null,
            CleanPrice: this.state.cleanPrice !== "" ? [this.state.cleanPrice] : null,
            ZSpread: this.state.zSpread !== "" ? [this.state.zSpread] : null,
            GSpread: this.state.gSpread !== "" ? [this.state.gSpread] : null,
            CouponRate: this.state.couponRate !== "" ? [this.state.couponRate] : null,
            DataFixType: this.state.tabIndex, //to match eCalculationType
            UseRealFlows: this.state.isUseKnownFlows,
            UseAdjustedWAM: this.state.isUseAdjustedWAM,
            CorrectedYield: this.state.isCorrectedYield,
            ConventionVersion: this.state.conventionVersion,
            //ScenarioAnalysis: (this.state.calculationModeTabIndex == 3), //ScenarioAnalysis
            AnalysisType: this.state.calculationModeTabIndex,
            PriceDataType: this.state.priceDataType,
            //KeyRates: this.state.keyRates != null ? { model: this.state.keyRates.editModel } : null,
            CouponType: this.state.couponType
        };

        this.props.requestCalculation(data);
    });

    openTooltipClick = (evt) => {
        this.setState({ openGridTooltip: true });
    }

    handleTooltipClose = (evt) => {
        this.setState({ openGridTooltip: false });
    }

    // onBondLoadConfirm = () => {

    //     if (this.state.bondId == null) {
    //         this.onConfirmClose();
    //         return;
    //     }

    //     if (this.props.options == null || this.props.options.length == 0) {
    //         this.props.loadBonds('', this.state.bondId);
    //         return;
    //     }


    //     var bond = this.props.options.find((item) => item.id == this.state.bondId);
    //     if (bond == null) {
    //         this.props.loadBonds('', this.state.bondId);
    //         return; //CHECKME!!! won't switch
    //     }

    //     var changes = {};
    //     if (isBondVisible(bond.bondStatus)) {
    //         //if (this.state.tabIndex != 2) {
    //         //changes['tabIndex'] = 2;
    //         //}

    //         changes['cleanPrice'] = 100;
    //         changes['dirtyPrice'] = 100;
    //     }
    //     else {

    //         //if (bond.bondStatus != BondStatus.STATUS_PLACEMENT && this.state.tabIndex != 4) {
    //         //changes['tabIndex'] = 4;
    //         //}

    //         //changes['minEvaluationDate'] = new Date();
    //         //changes['evaluationDate'] = new Date();
    //     }

    //     this.setState({ selectedBond: bond, ...changes });
    //     this.onConfirmClose();
    // }

    // onConfirmClose = () => {
    //     this.setState({ showConfirm: false, bondId: null });
    // }


    handleChartsButtonClick = () => {
        //нажарие кнопки статистики
        var query = '';
        if (this.state.selectedBond != null) {
            query = '?isins=' + this.state.selectedBond.isin;
        }
        window.open("/instruments/pools/statistics" + query, "_blank");
    }

    //CHECKME!!! move to an API
    clearPanel = () => {
        this.onBondSelected(null, null);
    }

    CPRCDRSumValidate = () => {
        if (this.state.cpr == '' || this.state.cdr == '')
            return true; //no data, must fire required rule if available
        return (parseFloat(this.state.cpr.toString()) + parseFloat(this.state.cdr.toString())) < 100;
    }

    onKnownFlowsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ isUseKnownFlows: event.target.checked, requestsToCalculate: this.state.requestsToCalculate + 1 });
    }

    onAdjustWAMChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // console.log({ isUseAdjustedWAM: event.target.checked, requestsToCalculate: this.state.requestsToCalculate + 1 })
        this.setState({ isUseAdjustedWAM: event.target.checked, requestsToCalculate: this.state.requestsToCalculate + 1 });
    }

    onCorrectYieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ isCorrectedYield: event.target.checked, requestsToCalculate: this.state.requestsToCalculate + 1 });
    }

    onApplyVariableCPR = (data: any) => {

        //CHECKME!!! set correct data
        this.setState({ variableCPR: data, requestsToCalculate: this.state.requestsToCalculate + 1 });
    }

    setEvaluationDateToday = (event: any) => {
        this.setState({ evaluationDate: getDate(new Date()) });
    }

    onPriceTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        //this.props.cprcdr.nominal
        var changes = {};
        if (event.target.checked) {
            if (this.state.dirtyPrice != '')
                changes["dirtyPrice"] = Math.round(this.props.bondStats.nominal * Number(this.state.dirtyPrice)) / 100;
            if (this.state.cleanPrice != '')
                changes["cleanPrice"] = Math.round(this.props.bondStats.nominal * Number(this.state.cleanPrice)) / 100;
        }
        else {
            if (this.state.dirtyPrice != '')
                changes["dirtyPrice"] = Math.round(Number(this.state.dirtyPrice) / this.props.bondStats.nominal * 100 * 100) / 100;
            if (this.state.cleanPrice != '')
                changes["cleanPrice"] = Math.round(Number(this.state.cleanPrice) / this.props.bondStats.nominal * 100 * 100) / 100;
        }

        this.setState({ priceDataType: event.target.checked, requestsToCalculate: this.state.requestsToCalculate + 1, ...changes });
    }

    formCleanPriceLabel = () => {
        return (
            (isBondVisible(this.state.selectedBond?.bondStatus) ? this.props.t('ConventionNameEnterPricePlacement') : this.props.t('ConventionNameEnterPriceFixed')) +
            ', ' +
            (this.state.priceDataType ? this.props.t('Rubles_Short') : this.props.t('Percents_Short')));
    }

    formCleanPriceValidators = () => {
        return this.state.priceDataType ? ['required', 'maxFloat:2000.0', 'minFloat:1.0'] : ['required', 'maxFloat:200.0', 'minFloat:20.0'];
    }

    formCleanPriceErrorMessages = () => {
        return this.state.priceDataType ? [this.props.t('PleaseEnterValue'), stringInject(this.props.t('ValueCannotBeGreaterThan'), [2000.0]), stringInject(this.props.t('ValueCannotBeLesserThan'), [1.0])] : [this.props.t('PleaseEnterValue'), stringInject(this.props.t('ValueCannotBeGreaterThan'), [200.0]), stringInject(this.props.t('ValueCannotBeLesserThan'), [20.0])];
    }


    formDirtyPriceLabel = () => {
        return ((isBondVisible(this.state.selectedBond?.bondStatus) ? this.props.t('ConventionNameEnterPricePlacement') : this.props.t('ConventionNameEnterPriceFloat')) +
            ', ' +
            (this.state.priceDataType ? this.props.t('Rubles_Short') : this.props.t('Percents_Short')));
    }

    formDirtyPriceValidators = () => {
        return this.state.priceDataType ? ['required', 'maxFloat:2000.0', 'minFloat:1.0'] : ['required', 'maxFloat:200.0', 'minFloat:20.0'];
    }

    formDirtyPriceErrorMessages = () => {
        return this.state.priceDataType ? [this.props.t('PleaseEnterValue'), stringInject(this.props.t('ValueCannotBeGreaterThan'), [2000.0]), stringInject(this.props.t('ValueCannotBeLesserThan'), [1.0])] : [this.props.t('PleaseEnterValue'), stringInject(this.props.t('ValueCannotBeGreaterThan'), [200.0]), stringInject(this.props.t('ValueCannotBeLesserThan'), [20.0])];
    }

    couponFormatter = (value) => {
        switch (value) {
            case CouponTypeStr.FIXED:
                return this.props.t('Fixed');
            case CouponTypeStr.FLOATING:
                return this.props.t('Floating');
            case CouponTypeStr.KEYRATE_AND_PREMIUM:
                {
                    //if (this.state.bondResults != null)
                    //return stringInject(this.props.t('KeyRateAndPremium_Params'), [Localization.FormatNumberString(this.state.bondResults.couponRate)]);

                    return this.props.t('KeyRateAndPremium');
                }
            default:
                return this.props.t('Unknown');
        }
    }

    dateFormatter = (value) => {
        return value != null ? Localization.FormatDateStringLocale(value) : '-';
    }

    handleBondNameChange = (event) => {
        this.setState({ bondNameType: event.target.value }, this.notifyInputChanged);
    }

    getBondName = (bond, type) => {
        // console.log('bond', type, bond)
        var name = null;
        switch (parseInt(type)) {
            case 1:
                {
                    if (bond.status == BondStatus.STATUS_PLACEMENT)
                        name = bond.regNumber;
                    else
                        name = bond.isin;
                }
                break;
            case 2:
                {
                    name = bond.regNumber;
                }
                break;
            case 3:
                {
                    name = bond.ticker;
                }
                break;
            case 4:
                {
                    name = bond.bloombergTicker;
                }
                break;
            case 5:
                {
                    name = bond.name;
                }
                break;
        }
        // console.log('name', name, type)
        if (name == null)
            name = ''; // this.props.t("Unknown");

        return name;
    }

    render() {

        const { classes, t } = this.props;
        const validators_issue = ['required', 'invalidDate'];
        const errorMessages_issue = [t('FieldRequired'), t('InvalidDate')];

        // console.log('newInput', newInput)
        return (
            <>
                <Box sx={{ p: 2 }}>
                    <Spinner show={this.state.parametersLoading} />
                    <ValidatorForm onSubmit={() => { }} ref={x => this.submitForm = x}>
                        <div>
                            <TooltipInfoLabel content={t('BondIdentity_Info')} fullWidth>
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel id="bond-select-header">{t('BondIdentity')}</InputLabel>
                                    <Select
                                        fullWidth
                                        variant="standard"
                                        labelId="bond-name-select-label"
                                        id="bond-name-select"
                                        value={this.state.bondNameType}
                                        onChange={this.handleBondNameChange}
                                    >
                                        <MenuItem value={3}>{t('Ticker')}</MenuItem>
                                        <MenuItem value={1}>{t('ISIN')}</MenuItem>
                                        <MenuItem value={2}>{t('RegistrationNumber')}</MenuItem>
                                        {/* <MenuItem value={4}>{t('BloombergTicker')}</MenuItem> */}
                                        {
                                            Globals.user.isEmployee &&
                                            <MenuItem value={5}>{t('Name')}</MenuItem>
                                        }
                                    </Select>
                                </FormControl>
                            </TooltipInfoLabel>
                        </div>
                        <div className='align-items-end'>
                            <ComboboxValidator
                                fullWidth
                                variant="standard"
                                id="bondSelection"
                                label={this.state.selectedBond != null ? t('ConventionNameBondChoice') : t('SelectMBS')}
                                getOptionSelected={(option, value) => option.id === value.id}
                                getOptionLabel={(option) => this.getBondName(option, this.state.bondNameType)}
                                noOptionsText={t('NoData')}
                                tooltip={t('TooltipConventionComboBoxBondChoice')}
                                // tooltip={this.state?.test?.toString()}
                                validators={['required']}
                                errorMessages={[this.props.t('PleaseSelectBond')]}
                                options={this.state.options}
                                onChange={this.onBondSelected}
                                loadingText={this.props.t("Loading")}
                                isLoading={!this.state.isBondsLoaded}
                                inputValue={this.state.bondSearchValue}
                                value={this.state.selectedBond}
                                onInputChanged={(event, newInputValue) => {
                                    // console.log('newInputValue', newInputValue)
                                    this.setState({ bondSearchValue: newInputValue });
                                    /*this.props.loadBonds(newInputValue, null);*/
                                }}
                                renderOption={(props, option, { selected }) => (

                                    <li {...props} key={option.id + '_item'} className={clsx(props.className, this.props.classes.comboboxItemContainer)}>
                                        <div key={option.id} style={{ display: 'flex', width: '100%' }}>
                                            <div className={classes.bondElementHeader}>{this.getBondName(option, this.state.bondNameType)}</div>
                                            <div className={classes.bondElementStatus} >
                                                <div className={this.props.classes.comboboxItem}>
                                                    {
                                                        (option.bondStatus == BondStatus.STATUS_NOT_ISSUED) && ( //no need to check is it an employee
                                                            <span key={option.id} style={{ fontSize: '11px', textTransform: 'uppercase', color: style.colorError }}>
                                                                {this.props.t('Preparing')}
                                                            </span>
                                                        )
                                                    }
                                                    {
                                                        (option.bondStatus == BondStatus.STATUS_PLACEMENT) && (
                                                            <span key={option.id} style={{ fontSize: '11px', textTransform: 'uppercase', color: style.colorPrimary2 }}>
                                                                {this.props.t('PreIssue')}
                                                            </span>
                                                        )
                                                    }
                                                    {
                                                        (option.bondStatus == BondStatus.STATUS_ISSUED) && (
                                                            <span key={option.id} style={{ fontSize: '11px', textTransform: 'uppercase', color: style.colorPrimary2 }}>
                                                                {this.props.t('Issued')}
                                                            </span>
                                                        )
                                                    }
                                                    {
                                                        (option.bondStatus == BondStatus.STATUS_REDEEMED) && ( //no need to check is it an employee
                                                            <span key={option.id} style={{ fontSize: '11px', textTransform: 'uppercase', color: style.colorPrimary3 }}>
                                                                {this.props.t('Redeemed')}
                                                            </span>
                                                        )
                                                    }
                                                </div>
                                                <div className={this.props.classes.comboboxItem}>
                                                    {
                                                        (option.bondStatus == BondStatus.STATUS_NOT_ISSUED) && ( //no need to check is it an employee
                                                            <span key={option.id} style={{ fontSize: '11px' }}>
                                                                Ожидается
                                                            </span>
                                                        )
                                                    }
                                                    {
                                                        (option.bondStatus == BondStatus.STATUS_PLACEMENT) && (
                                                            <span key={option.id} style={{ fontSize: '11px' }}>
                                                                {Localization.FormatDateLocale(option.issueDate)}
                                                            </span>
                                                        )
                                                    }
                                                    {
                                                        (option.bondStatus == BondStatus.STATUS_ISSUED) && (
                                                            <span key={option.id} style={{ fontSize: '11px' }}>
                                                                {Localization.FormatDateLocale(option.issueDate)}
                                                            </span>
                                                        )
                                                    }
                                                    {
                                                        (option.bondStatus == BondStatus.STATUS_REDEEMED) && ( //no need to check is it an employee
                                                            <span key={option.id} style={{ fontSize: '11px' }}>
                                                                {Localization.FormatDateLocale(option.redeemDate)}
                                                            </span>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        {/*
                                        <span key={option.id + '_name'} style={{ fontSize: '14px', marginRight: 'auto' }}>
                                            {this.getBondName(option, this.state.bondNameType)}
                                        </span>
                                        {
                                            (option.bondStatus == BondStatus.STATUS_NOT_ISSUED) && ( //no need to check is it an employee
                                                <span key={option.id} style={{ fontSize: '14px', textTransform: 'uppercase', paddingLeft: '0.5rem', backgroundColor: option.color, color: style.colorError }}>
                                                    {this.props.t('Preparing')}
                                                </span>
                                            )
                                        }
                                        {
                                            (option.bondStatus == BondStatus.STATUS_PLACEMENT) && (
                                                <span key={option.id} style={{ fontSize: '14px', textTransform: 'uppercase', paddingLeft: '0.5rem', backgroundColor: option.color, color: style.colorPrimary2 }}>
                                                    {this.props.t('PreIssue')}
                                                </span>
                                            )
                                        }
                                        {
                                            (option.bondStatus == BondStatus.STATUS_REDEEMED) && ( //no need to check is it an employee
                                                <span key={option.id} style={{ fontSize: '14px', textTransform: 'uppercase', paddingLeft: '0.5rem', backgroundColor: option.color, color: style.colorError }}>
                                                    {this.props.t('Redeemed')}
                                                </span>
                                            )
                                        }
                                        */}
                                    </li>
                                )}
                            />
                        </div>
                        {/* {console.log('this.props.queryData', this.state.test.toString())} */}
                        {
                            (this.state.selectedBond != null) && (
                                <div>
                                    <Paper className={classes.tabContainer}>
                                        {!isBondVisible(this.state.selectedBond?.bondStatus) && (
                                            <Tabs
                                                className={clsx(classes.tabButtons, classes.flex)}
                                                value={this.state.tabIndex}
                                                onChange={this.handleTabChange}
                                                indicatorColor="primary"
                                                textColor="primary"
                                                variant="fullWidth"
                                            >

                                                <Tab label={
                                                    <Tooltip
                                                        disableFocusListener
                                                        disableInteractive
                                                        title={this.props.t('TooltipConventionFixGSpread')}
                                                    >
                                                        {/*<span>{t('ConventionNameFixGSpreadButton')}</span>*/}
                                                        <span>
                                                            <nobr>{t('Set')}</nobr><br /><nobr>{t('GSpread')}</nobr>
                                                        </span>
                                                    </Tooltip>
                                                } value={4} disabled={isBondVisible(this.state.selectedBond?.bondStatus)} />

                                                <Tab label={
                                                    <Tooltip
                                                        disableFocusListener
                                                        disableInteractive
                                                        title={this.props.t('TooltipConventionFixZSpread')}
                                                    >
                                                        {/*<span>{t('ConventionNameFixZSpreadButton')}</span>*/}
                                                        <span>
                                                            <nobr>{t('Set')}</nobr><br /><nobr>{t('ZSpread')}</nobr>
                                                        </span>
                                                    </Tooltip>
                                                } value={3} disabled={isBondVisible(this.state.selectedBond?.bondStatus)} />

                                                <Tab label={
                                                    <Tooltip
                                                        disableFocusListener
                                                        disableInteractive
                                                        title={t('TooltipConventionFixPriceButton')}
                                                    >
                                                        <span>{t('ConventionNameFixPriceButton')}</span>
                                                    </Tooltip>
                                                } value={2} disabled={isBondVisible(this.state.selectedBond?.bondStatus)} />

                                            </Tabs>
                                        )}
                                    </Paper>

                                    <TabPanel value={this.state.tabIndex} index={2} classes={{ root: classes.tab }}>
                                        <Box sx={{ mb: 1, mt: 1, display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', alignItems: 'flex-end' }}>
                                            <Box sx={{ flexGrow: 1 }} component="span">
                                                {
                                                    (this.state.couponType == CouponTypeStr.FIXED || this.state.couponType == CouponTypeStr.KEYRATE_AND_PREMIUM) ? (
                                                        <CustomTextValidator
                                                            type="number"
                                                            label={this.formCleanPriceLabel()}
                                                            name="cleanPrice"
                                                            id="cleanPrice"
                                                            tooltip={isBondVisible(this.state.selectedBond?.bondStatus) ? t('TooltipConventionPriceEnterPlacement') : t('TooltipConventionPriceEnterFixed')}
                                                            fullWidth={true}
                                                            onChange={this.handleValueChange('cleanPrice')}
                                                            value={this.state.cleanPrice}
                                                            validators={this.formCleanPriceValidators()}
                                                            errorMessages={this.formCleanPriceErrorMessages()}
                                                            disabled={!isPriceEditable(this.state.selectedBond?.bondStatus, this.state.lockPlacementPrice)}
                                                            inputProps={{ step: '0.01', autoComplete:"off" }} />
                                                    ) : (
                                                        <CustomTextValidator
                                                            type="number"
                                                            label={this.formDirtyPriceLabel()}
                                                            name="dirtyPrice"
                                                            id="dirtyPrice"
                                                            tooltip={isBondVisible(this.state.selectedBond?.bondStatus) ? t('TooltipConventionPriceEnterPlacement') : t('TooltipConventionPriceEnterFloating')}
                                                            fullWidth={true}
                                                            onChange={this.handleValueChange('dirtyPrice')}
                                                            value={this.state.dirtyPrice}
                                                            validators={this.formDirtyPriceValidators()}
                                                            errorMessages={this.formDirtyPriceErrorMessages()}
                                                            disabled={!isPriceEditable(this.state.selectedBond?.bondStatus, this.state.lockPlacementPrice)}
                                                            inputProps={{ step: '0.01', autoComplete:"off" }} />

                                                    )
                                                }
                                            </Box>
                                            {
                                                (isBondIssued(this.state.selectedBond?.bondStatus)) && (
                                                    <Box sx={{ ml: 1, mb: '-2px', mr: 1, display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', alignItems: 'baseline' }} component="span">
                                                        <Typography>%</Typography>
                                                        <Switch
                                                            checked={this.state.priceDataType}
                                                            onChange={this.onPriceTypeChange}
                                                            color="secondary"
                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                        />
                                                        <Typography>₽</Typography>
                                                    </Box>
                                                )
                                            }
                                            {
                                                (isBondVisible(this.state.selectedBond?.bondStatus) && (this.state.couponType == CouponTypeStr.FIXED || this.state.couponType == CouponTypeStr.KEYRATE_AND_PREMIUM)) && (
                                                    // <Col className='ps-0' style={{minWidth:'100px'}}>
                                                    <Box>
                                                        <CustomTextValidator
                                                            type="number"
                                                            label={(this.state.couponType == CouponTypeStr.KEYRATE_AND_PREMIUM) ? t('EnterPremium') : t('ConventionNameEnterCouponRate')}
                                                            name="couponRate"
                                                            id="couponRate"
                                                            tooltip={isBondVisible(this.state.selectedBond?.bondStatus) ? t('PlacementCouponRateEnter') : ((this.state.couponType == CouponTypeStr.KEYRATE_AND_PREMIUM) ? t('TooltipConventionKeyRate') : t('TooltipConventionCouponRateEnter'))}
                                                            fullWidth={true}
                                                            onChange={this.handleValueChange('couponRate')}
                                                            // value={this.state.couponRate.toLocaleString()}
                                                            value={parseFloat(this.state.couponRate ? this.state.couponRate : 0).toFixed(2)}
                                                            validators={['required', 'maxFloat:50.0', 'minFloat:0.0']}
                                                            errorMessages={[t('PleaseEnterValue'), stringInject(t('ValueCannotBeGreaterThan'), [50.0]), stringInject(t('ValueCannotBeLesserThan'), [0.0])]}
                                                            inputProps={{ step: '0.01' }}
                                                            disabled={this.state.lockPlacementCoupon}
                                                        />
                                                    </Box>
                                                    // </Col>
                                                )
                                            }
                                        </Box>
                                        {/* </Row> */}
                                    </TabPanel>
                                    <TabPanel className={classes.tab} value={this.state.tabIndex} index={3}>
                                        <Grid container sx={{paddingY: '0.5rem !important'}}>
                                            <Grid xs={12}>
                                                <CustomNumericValidator
                                                    // type="number"
                                                    label={t('ZSpreadPrc')}
                                                    name="zSpread"
                                                    id="zSpread"
                                                    tooltip={t('TooltipConventionZSpreadEnter')}
                                                    fullWidth={true}
                                                    onChange={this.handleWholeValueChange('zSpread')}
                                                    value={this.state.zSpread}
                                                    validators={['required', 'maxFloat:1000.0', 'minFloat:-200.0']}
                                                    errorMessages={[t('PleaseEnterValue'), stringInject(t('ValueCannotBeGreaterThan'), [1000.0]), stringInject(t('ValueCannotBeLesserThan'), [-200.0])]}
                                                    inputProps={{ step: '1.00', autoComplete:"off" }} />
                                            </Grid>
                                        </Grid>
                                    </TabPanel>
                                    <TabPanel className={classes.tab} value={this.state.tabIndex} index={4}>
                                        <Grid id="test!" container sx={{ paddingY: '0.5rem !important' }}>
                                            <Grid xs={12}>
                                                <CustomNumericValidator
                                                    // type="number"
                                                    label={t('GSpreadPrc')}
                                                    name="gSpread"
                                                    id="gSpread"
                                                    tooltip={t('TooltipConventionGSpreadEnter')}
                                                    fullWidth={true}
                                                    onChange={this.handleWholeValueChange('gSpread')}
                                                    value={this.state.gSpread}
                                                    validators={['required', 'maxFloat:1000.0', 'minFloat:-200.0']}
                                                    errorMessages={[t('PleaseEnterValue'), stringInject(t('ValueCannotBeGreaterThan'), [1000.0]), stringInject(t('ValueCannotBeLesserThan'), [-200.0])]}
                                                    inputProps={{ step: '1', autoComplete:"off" }} />
                                            </Grid>
                                        </Grid>
                                    </TabPanel>
                                    <div className={classes.paddingBottom2}>
                                        <Tooltip
                                            PopperProps={{
                                                // disablePortal: true
                                            }}
                                            arrow
                                            open={this.state.openZCYCTooltip}
                                            disableFocusListener
                                            disableHoverListener
                                            disableTouchListener
                                            disableInteractive
                                            title={t('DateWasMovedToAClosestTradingSession')}
                                        >
                                            {/* <div className='d-flex'>
                                                    <div className={classes.flexContainer}> */}
                                            <Box sx={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row', alignItems: 'flex-end' }}>
                                                <Box sx={{ flexGrow: 1 }} component="span">
                                                    {/* <Grid item sx={{flexGrow:1}}> */}
                                                    <DatePickerValidator
                                                        inputClassName='me-1'
                                                        label={t('ConventionNameEnterZCYCDate')}
                                                        name="ZCYCDate"
                                                        id="ZCYCDate"
                                                        tooltip={this.state.couponType == CouponTypeStr.KEYRATE_AND_PREMIUM ? t('ZCYCDateTooltipAtPremium') : t('TooltipConventionZCYCDateEnter')}
                                                        // tooltip={this.props.queryData?.zcycdate}

                                                        fullWidth={true}
                                                        value={this.state.ZCYCDate}
                                                        mask={Localization.DateMask()}
                                                        format={Localization.ShortDatePattern()}
                                                        onChange={this.handleZCYCDateChange}
                                                        maxDate={this.state.maxZCYCDate}
                                                        minDate={this.state.minZCYCDate}
                                                        //disabled={this.state.couponType == CouponTypeStr.KEYRATE_AND_PREMIUM}
                                                        validators={['required', 'invalidDate']}
                                                        errorMessages={[t('FieldRequired'), t('InvalidDate')]}
                                                    />
                                                    {/* </Grid> */}
                                                </Box>
                                                {/* </div>
                                                <div className={classes.flexContainer} style={{width:'200px'}}> */}
                                                <Box sx={{ ml: 1, maxWidth: '100px' }} component="span">
                                                    {/* <Grid item xs={4}> */}
                                                    <TimePickerValidator
                                                        inputClassName='ms-1'
                                                        label={t('ConventionNameEnterZCYCTime')}
                                                        name="ZCYCTime"
                                                        id="ZCYCTime"
                                                        fullWidth={true}
                                                        value={this.state.ZCYCDate}
                                                        //mask={Localization.TimeMask()}
                                                        //format={Localization.ShortTimePattern()}
                                                        onChange={(event) => this.handleZCYCTimeChange(event)}
                                                        validators={['required', 'invalidDate']}
                                                        errorMessages={[t('FieldRequired'), t('InvalidTime')]}
                                                    />
                                                    {/* </div> */}
                                                    {/* </Grid> */}
                                                </Box>
                                                {/* <Grid item sx={{width:'min-content',textAlign: 'right'}}> */}
                                                <Box sx={{ ml: 1 }} component="span">
                                                    <Tooltip
                                                        //interactive
                                                        disableFocusListener
                                                        title={t('TooltipConventionRefreshZCYC')}
                                                    >
                                                        <IconButton
                                                            // disabled={this.state.couponType == CouponTypeStr.KEYRATE_AND_PREMIUM}
                                                            onClick={this.handleRestore}
                                                            sx={{ p: '3px' }}
                                                            size="small">
                                                            <UpdateIcon sx={{ width: 26, height: 26 }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Box>
                                                {/* </Grid> */}
                                            </Box>
                                            {/* </div> */}
                                        </Tooltip>
                                    </div>
                                    <div>
                                        {/* {console.log(toUnixDate(this.state.minEvaluationDate), toUnixDate(this.state.maxEvaluationDate), toUnixDate(this.state.evaluationDate) )} */}
                                        <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', alignItems: 'flex-end' }}>
                                            <DatePickerValidator
                                                label={isBondVisible(this.state.selectedBond?.bondStatus) ? this.props.t('ConventionNameEnterEvaluationDatePlacement') : this.props.t('ConventionNameEnterEvaluationDate')}
                                                name="evaluationDate"
                                                id="evaluationDate"
                                                locale={Localization.locale}
                                                fullWidth={true}
                                                disabled={isBondVisible(this.state.selectedBond?.bondStatus)}
                                                tooltip={isBondVisible(this.state.selectedBond?.bondStatus) ? this.props.t('TooltipConventionEvalDatePlacementEnter') : this.props.t('TooltipConventionEvalDateEnter')}
                                                value={this.state.evaluationDate}
                                                mask={Localization.DateMask()}
                                                format={Localization.ShortDatePattern()}
                                                onChange={(event) => this.handleDateChange('evaluationDate', event)}
                                                maxDate={this.state.maxEvaluationDate}
                                                //maxDateMessage={t('NoDateGreaterToday')}
                                                minDate={this.state.minEvaluationDate}
                                                //minDateMessage={t('NoDateLesserIssueDate')}
                                                validators={validators_issue}
                                                errorMessages={errorMessages_issue}
                                            // extraButtonsProps={
                                            //     (!(isBondVisible(this.state.selectedBond?.bondStatus) || (this.state.evaluationDate != '' && this.state.evaluationDate?.toLocaleDateString() == (new Date()).toLocaleDateString()))) && (
                                            //         <Tooltip
                                            //             //interactive
                                            //             disableFocusListener
                                            //             title={this.props.t("TooltipButtonToday")}
                                            //         >
                                            //             <div>
                                            //                 <IconButton onClick={this.setEvaluationDateToday} 
                                            //                 size="small">
                                            //                     <UpdateIcon />
                                            //                 </IconButton>
                                            //             </div>
                                            //         </Tooltip>)
                                            // } 
                                            />
                                            {
                                                !(isBondVisible(this.state.selectedBond?.bondStatus) || (this.state.evaluationDate != '' && this.state.evaluationDate?.toLocaleDateString() == (new Date()).toLocaleDateString())) &&
                                                (
                                                    <Box sx={{ ml: 1 }} component="span">
                                                        <Tooltip
                                                            //interactive
                                                            disableFocusListener
                                                            title={this.props.t("TooltipButtonToday")}
                                                        >
                                                            <div>
                                                                <IconButton onClick={this.setEvaluationDateToday}
                                                                    size="small"
                                                                    disabled={this.state.selectedBond?.bondStatus === BondStatus.STATUS_REDEEMED}
                                                                >
                                                                    <UpdateIcon sx={{ width: 26, height: 26 }} />
                                                                </IconButton>
                                                            </div>
                                                        </Tooltip>
                                                    </Box>
                                                )
                                            }
                                        </Box>
                                    </div>
                                    <div>
                                        {/* {console.log('this.state.cpr',this.state.cpr)} */}
                                        <CustomTextValidator
                                            type={
                                                !(this.state.isFixedCashflow || this.state.calculationModeTabIndex == 2) ? "number" : "text"
                                            }
                                            label={t('ConventionNameEnterCPR')}
                                            name="cpr"
                                            id="cpr"
                                            step="any" //to remove validation popup
                                            tooltip={this.state.isPlacementCPR ? this.props.t('TooltipConventionPlacementCPREnter') : this.props.t('TooltipConventionCPREnter')}
                                            tooltipButtonName={this.props.t('Statistics')}
                                            onLinkClick={this.handleChartsButtonClick}
                                            disabled={this.state.isFixedCashflow || this.state.calculationModeTabIndex == 2} //variable CPR
                                            fullWidth={true}
                                            onChange={this.handleValueChange('cpr')}
                                            value={
                                                !!this.state.isFixedCashflow ?
                                                    t("isFixedCashflow")
                                                    : (
                                                        !(this.state.calculationModeTabIndex == 2) ?
                                                            this.state.cpr
                                                            :
                                                            t("VariableCPRText") //Задан переменынй CPR
                                                    )
                                            }
                                            validators={['required', 'maxFloat:100', 'minFloat:0', this.CPRCDRSumValidate]}
                                            errorMessages={[t('FieldRequired'), t('ValueMustBeLesserThan100'), t('ValueMustBeGreaterThan0'), t('CPRCDRSumMustBeLessOf100')]}
                                            InputProps={
                                                (this.state.backupedCPR != '') ? {
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <Tooltip
                                                                disableFocusListener
                                                                title={t('UseSCurveCPR')}
                                                            // disabled={this.state.isFixedCashflow || this.state.calculationModeTabIndex == 2}
                                                            >
                                                                <span>
                                                                    <IconButton
                                                                        disabled={this.state.isFixedCashflow || (this.state.calculationModeTabIndex == 2)}

                                                                        //для отключения  в пуле данных получаемом из store getBondData' должно возвращаться дополнительно значение zcyccpr

                                                                        onClick={this.handleSetSCurveCPR/*()=>(this.state.isFixedCashflow || this.state.calculationModeTabIndex == 2)?this.handleSetSCurveCPR:null*/}
                                                                        size="small">
                                                                        <ScurveIcon style={{ width: '24px', height: '24px', padding: '3px 0px' }} />
                                                                    </IconButton>
                                                                </span>
                                                            </Tooltip>

                                                            <Tooltip
                                                                disableFocusListener
                                                                // disabled={this.state.isFixedCashflow || this.state.calculationModeTabIndex == 2}
                                                                title={this.state.isPlacementCPR ? t('TooltipConventionRefreshCPRPlacement') : t('TooltipConventionRefreshCPR')}
                                                            >
                                                                {/* {console.log(this.state.isFixedCashflow,this.state.calculationModeTabIndex)} */}
                                                                <span>
                                                                    <IconButton
                                                                        disabled={this.state.isFixedCashflow || (this.state.calculationModeTabIndex == 2) /* || (this.props.bondResults == null)*/}
                                                                        onClick={this.handleRestoreCPR/*()=>(this.state.isFixedCashflow || this.state.calculationModeTabIndex == 2)?this.handleRestoreCPR:null*/}
                                                                        size="small" sx={{ ml: 1 }}>
                                                                        <ReplayIcon />
                                                                    </IconButton>
                                                                </span>
                                                            </Tooltip>
                                                        </InputAdornment>
                                                    )
                                                } : {}}
                                        />
                                    </div>

                                    <div>
                                        <Tooltip
                                            // PopperProps={{
                                            //     disablePortal: true
                                            // }}
                                            arrow
                                            open={this.state.openCDRTooltip}
                                            disableFocusListener
                                            disableHoverListener
                                            disableTouchListener
                                            disableInteractive
                                            title={t('BondPaymentFixed_Info')}>
                                            <div>
                                                {/* {console.log('this.state.cdr',this.state.cdr)} */}
                                                <CustomTextValidator
                                                    type={
                                                        !this.state.isFixedCashflow ? "number" : "text"
                                                    }
                                                    label={t('ConventionNameEnterCDR')}
                                                    name="cdr"
                                                    id="cdr"
                                                    step="any" //to remove validation popup
                                                    tooltip={this.state.isPlacementCDR ? this.props.t('TooltipConventionPlacementCDREnter') : this.props.t('TooltipConventionCDREnter')}
                                                    tooltipButtonName={this.props.t('Statistics')}
                                                    onLinkClick={this.handleChartsButtonClick}
                                                    disabled={this.state.isFixedCashflow}
                                                    fullWidth={true}
                                                    onChange={this.handleValueChange('cdr')}
                                                    value={!!this.state.isFixedCashflow ? t("isFixedCashflow") : this.state.cdr}
                                                    validators={['required', 'maxFloat:100', 'minFloat:0', this.CPRCDRSumValidate]}
                                                    errorMessages={[t('FieldRequired'), t('ValueMustBeLesserThan100'), t('ValueMustBeGreaterThan0'), t('CPRCDRSumMustBeLessOf100')]}
                                                    InputProps={
                                                        (this.state.backupedCDR != '') ? {
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <Tooltip
                                                                        disableFocusListener
                                                                        title={this.state.isPlacementCDR ? t('TooltipConventionRefreshCDRPlacement') : t('TooltipConventionRefreshCDR')}
                                                                    >
                                                                        <span>
                                                                            <IconButton disabled={!!this.state.isFixedCashflow} onClick={this.handleRestoreCDR}
                                                                                size="small"
                                                                            >
                                                                                <ReplayIcon />
                                                                            </IconButton>
                                                                        </span>
                                                                    </Tooltip>
                                                                </InputAdornment>
                                                            )
                                                        } : {}}
                                                />
                                            </div>
                                        </Tooltip>
                                    </div>

                                    {
                                        (!isBondVisible(this.state.selectedBond?.bondStatus)) && (
                                            <div>
                                                <TooltipInfoLabel content={t('UseKnownFlows_Info')}>
                                                    {/* {console.log('!this.state.isFixedCashflow && this.state.isUseKnownFlows', this.state.isFixedCashflow,this.state.isUseKnownFlows)} */}
                                                    <FormControlLabel
                                                        value="end"
                                                        classes={{ root: classes.toggleLabel }}
                                                        control={
                                                            <Switch
                                                                disabled={this.state.isFixedCashflow}
                                                                checked={!this.state.isFixedCashflow && this.state.isUseKnownFlows}
                                                                color="secondary"
                                                                //value={this.state.isVariableCPR}
                                                                onChange={this.onKnownFlowsChange}
                                                            // inputProps={{ 'aria-label': 'controlled' }}
                                                            />
                                                        }
                                                        label={t('UseKnownFlows')}
                                                        labelPlacement="end"
                                                    />
                                                </TooltipInfoLabel>
                                            </div>
                                        )
                                    }
                                    {
                                        (this.state.conventionVersion == 1) ? (
                                            <div>
                                                <TooltipInfoLabel content={t('CorrectSchedule_Info')} link='ru/WAM'>
                                                    <FormControlLabel
                                                        value="end"
                                                        classes={{ root: classes.toggleLabel }}
                                                        control={
                                                            <Switch
                                                                disabled={this.state.isFixedCashflow}
                                                                checked={!this.state.isFixedCashflow && this.state.isUseAdjustedWAM}
                                                                color="secondary"
                                                                //value={this.state.isVariableCPR}
                                                                onChange={this.onAdjustWAMChange}
                                                                inputProps={{ 'aria-label': 'controlled' }}
                                                            />
                                                        }
                                                        label={t('CorrectSchedule')}
                                                        labelPlacement="end"
                                                    />
                                                </TooltipInfoLabel>
                                            </div>
                                        ) : (
                                            <div>
                                                <TooltipInfoLabel content={t('CorrectYield_Info')}>
                                                    <FormControlLabel
                                                        value="end"
                                                        classes={{ root: classes.toggleLabel }}
                                                        control={
                                                            <Switch
                                                                checked={this.state.isCorrectedYield}
                                                                color="secondary"
                                                                onChange={this.onCorrectYieldChange}
                                                                inputProps={{ 'aria-label': 'controlled' }}
                                                            />
                                                        }
                                                        label={t('CorrectYield')}
                                                        labelPlacement="end"
                                                    />
                                                </TooltipInfoLabel>
                                            </div>
                                        )
                                    }

                                    <Paper //className={classes.tabContainer}
                                        sx={{
                                            display: {
                                                xs: 'none', lg: 'flex',
                                                justifyContent: 'center'
                                            }
                                        }}
                                    >
                                        {/* {console.log('this.state.selectedBond', this.state.selectedBond)} */}
                                        <Tabs
                                            className={clsx(classes.tabButtons, classes.flex)}
                                            value={this.state.calculationModeTabIndex}
                                            onChange={this.handleModeTabChange}
                                            indicatorColor="primary"
                                            textColor="primary"
                                            variant="fullWidth"
                                            sx={{ width: '100%' }}
                                        >
                                            <Tab
                                                label={
                                                    <Tooltip
                                                        disableInteractive
                                                        disableFocusListener
                                                        title={this.props.t('StandardEvaluation_Info')}
                                                    >
                                                        {/*<span>{t('ConventionNameFixGSpreadButton')}</span>*/}
                                                        <span>
                                                            <nobr>{t('Standard')}</nobr><br /><nobr>{t('calculation')}</nobr>
                                                        </span>
                                                    </Tooltip>
                                                } value={1} />

                                            <Tab
                                                label={
                                                    <Tooltip
                                                        disableInteractive
                                                        disableFocusListener
                                                        title={this.props.t('VariableCPR_Info')}
                                                    >
                                                        {/*<span>{t('ConventionNameFixZSpreadButton')}</span>*/}
                                                        <span>
                                                            <nobr>{t('Variable')}</nobr><br /><nobr>{t('CPR_Plain')}</nobr>
                                                        </span>
                                                    </Tooltip>
                                                }
                                                value={2}
                                                disabled={this.state.selectedBond.bondStatus === BondStatus.STATUS_REDEEMED || this.state.selectedBond.couponType === CouponType.TYPE_KEYRATE_AND_PREMIUM || !!this.state.isFixedCashflow}

                                            />

                                            <Tab
                                                label={
                                                    <TooltipWithLink
                                                        disableInteractive={true}
                                                        disableFocusListener
                                                        content={t('ScenarioAnalysis_Info')}
                                                        link="ru/Сценарный_анализ">
                                                        <span>
                                                            <nobr>{t('Scenario')}</nobr><br /><nobr>{t('analysis')}</nobr>
                                                        </span>
                                                    </TooltipWithLink>
                                                } value={3}
                                                disabled={this.state.selectedBond.bondStatus === BondStatus.STATUS_REDEEMED || this.state.selectedBond.couponType === CouponType.TYPE_KEYRATE_AND_PREMIUM || !!this.state.isFixedCashflow}
                                            />

                                        </Tabs>
                                    </Paper>

                                </div>)
                        }
                    </ValidatorForm>
                </Box>
            </>
        )
    }
}

export default withStyles(styles)(withTranslation('translations', { withRef: true })(InputPanel));