import * as React from "react";
import IconButton from '@mui/material/IconButton';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

interface IProps {
    helpKey: string
}

export default class HelpButton extends React.Component<IProps> {

    // constructor(props) {
    //     super(props)
    // }

    handleButtonClick = (event) => {
        alert(this.props.helpKey);
    }

    render() {
        return (
            <IconButton color="primary" aria-label="help" size="small" onClick={this.handleButtonClick}>
                <HelpOutlineIcon />
            </IconButton>
        );
    }
}