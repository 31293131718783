//import { useContext } from 'react';
import { LocalizationContext } from '../LocalizationWrapper';
import ruLocale from 'date-fns/locale/ru';
import enLocale from 'date-fns/locale/en-US';
import { format, Locale } from 'date-fns';
//import setDefaultOptions from 'date-fns/setDefaultOptions';

//import 'dayjs/locale/ru';
//import 'dayjs/locale/en';

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";
import backend from "i18next-http-backend";
//import dayjs from 'dayjs';

interface IDateFormat {
    date: (...args: Array<any>) => any;
    time: (...args: Array<any>) => any;
    dateTime: (...args: Array<any>) => any,
}

const customDatePatternRU = 'dd.MM.yyyy';
const customTimePatternRU = 'HH:mm';
const humanReadableDatePattern = 'LLLL yyyy'

export default class Localization {


    static dateMaskMap: any = {
        fr: '__/__/____',
        en: '__/__/____',
        ru: '__.__.____',
        de: '__.__.____',
    };

    static dateTimeMaskMap: any = {
        fr: '__/__/____ __:__',
        en: '__/__/____ __:__',
        ru: '__.__.____ __:__',
        de: '__.__.____ __:__',
    };

    static timeMaskMap: any = {
        fr: '__:__',
        en: '__:__',
        ru: '__:__',
        de: '__:__',
    };

    static locale: Locale = ruLocale;
    static code: string = 'ru'; // () => { return (Localization.locale as any)['code']; };
    static fullCode: string = Localization.locale['code']
    static _context: any = LocalizationContext;
    static separator: any = this.getSeparator(this.fullCode, "decimal")
    static delimiter: any = this.getSeparator(this.fullCode, "group")

    private static getSeparator(
        locale: string,
        separatorType: string
    ): string | undefined {
        const numberWithGroupAndDecimalSeparator = 1000.1;
        return Intl.NumberFormat(locale)
            .formatToParts(numberWithGroupAndDecimalSeparator)
            .find((part) => part.type === separatorType)?.value;
    }


    public static HumanReadableDatePattern() {

        return humanReadableDatePattern;
    };

    public static DateMask() {
        return Localization.dateMaskMap[Localization.code];
    }

    public static TimeMask() {
        return Localization.timeMaskMap[Localization.code];
    }

    public static DateTimeMask() {
        return Localization.dateTimeMaskMap[Localization.code];
    }

    public static ShortDatePattern() {
        if (Localization.code === 'ru')
            return customDatePatternRU;

        return (Localization.locale.formatLong as IDateFormat).date({ width: 'short' });  //CHECKME!!! create my own locale... ((
    };

    public static ShortTimePattern() {
        if (Localization.code === 'ru')
            return customTimePatternRU;

        return (Localization.locale.formatLong as IDateFormat).time({ width: 'short' });
    };

    public static ShortDateTimePattern() {
        var pattern = (Localization.code === 'ru') ? customDatePatternRU : (Localization.locale.formatLong as IDateFormat).date({ width: 'short' });
        pattern = pattern + ' ' + ((Localization.code === 'ru') ? customTimePatternRU : (Localization.locale.formatLong as IDateFormat).time({ width: 'short' }));
        return pattern;
    };

    public static FormatDateHumanReadable(date: Date) {
        return format(date, this.HumanReadableDatePattern(), {
            locale: Localization.locale // window.__localeId__ or global.__localeId__
        });
    }

    public static FormatDateStringHumanReadable(date: string) {
        return format(Date.parse(date), this.HumanReadableDatePattern(), {
            locale: Localization.locale // window.__localeId__ or global.__localeId__
        });
    }

    public static FormatDateISO(date: Date) {
        if (date.toString() === '')
            return '';

        return format(date, 'yyyy-MM-dd');
    }

    public static FormatDateTimeISO(date: Date) {
        if (date == null || date.toString() === '')
            return '';

        return format(date, 'yyyy-MM-dd HH:mm');
    }

    public static FormatDateStringISO(date: string) {
        if (date == null)
            return null;

        return format(Date.parse(date), 'yyyy-MM-dd');
    }

    public static FormatDateTimeStringISO(date: string) {
        return format(Date.parse(date), 'yyyy-MM-dd HH:mm');
    }

    public static FormatDateTimeLocale(date: Date) {

        if (date == null)
            return '';

        return format(date, this.ShortDateTimePattern());
    }
    public static FormatDateLocale(date: Date) {

        if (date == null)
            return '';

        return format(date, this.ShortDatePattern());
    }

    public static FormatTimeLocale(date: Date) {

        if (date == null)
            return '';

        return format(date, this.ShortTimePattern());
    }

    public static FormatDateStringLocale(date: string) {
        if (date == null || date.length === 0)
            return '';

        return format(Date.parse(date), this.ShortDatePattern());
    }

    public static FormatDateTimeStringLocale(date: string) {
        if (date == null || date.length === 0)
            return '';

        return format(Date.parse(date), this.ShortDateTimePattern());
    }

    public static FormatTimeStringLocale(date: string) {
        if (date == null || date.length === 0)
            return '';

        return format(Date.parse(date), this.ShortTimePattern());
    }

    public static FormatNumberString(number: string, minfractionDigits: number = 2, maxfractionDigits: number = 2) {
        if (number == null || number.length === 0)
            return '';

        var parsed = parseFloat(number);
        if (Number.isNaN(parsed))
            return number; //returs as-is

        return parsed.toLocaleString(Localization.code, { 'minimumFractionDigits': minfractionDigits, 'maximumFractionDigits': maxfractionDigits });
    }
    public static FormatNumber(number: number, minfractionDigits: number = 2, maxfractionDigits: number = 2) {

        if (number == null)
            return '';

        return number.toLocaleString(Localization.code, { 'minimumFractionDigits': minfractionDigits, 'maximumFractionDigits': maxfractionDigits });
    }
    public static FormatNumberStringISO(number: string, minfractionDigits: number = 2, maxfractionDigits: number = 2) {
        if (number == null || number.length === 0)
            return '';
        return parseFloat(number).toLocaleString('en', { 'minimumFractionDigits': minfractionDigits, 'maximumFractionDigits': maxfractionDigits });
    }

    public static FormatByType(type: string, value: string) {
        switch (type) {
            case 'float':
                return Localization.FormatNumberString(value);
            case 'int':
                return Localization.FormatNumberString(value, 0, 0);
            case 'date':
                return Localization.FormatDateStringLocale(value);
            default:
                return value;
        }
    }

    static setLocalization(locale: string) {

        var loc = locale;
        if (loc.length > 2) {
            loc = loc.substring(0, 2);
        }

        //CHECKME!!! remove later
        switch (loc) {
            case 'ru':
                Localization.code = loc;
                Localization.locale = ruLocale;
                Localization.fullCode = 'ru-RU'
                break;
            case 'en':
                Localization.code = loc;
                Localization.locale = enLocale;
                Localization.fullCode = 'en-US'
                break;
            default:
                throw 'Unsupported locale';
        }

        Localization.separator = this.getSeparator(this.fullCode, "decimal")
        Localization.delimiter = this.getSeparator(this.fullCode, "group")

        //setDefaultOptions({ locale: Localization.locale });

        i18n.changeLanguage(loc);

        //Localization.context.updateLocalization();
    }

    public static activeLocale() {
        return Localization.code;
    }

    public static localesList() {

        //CHCEKME!!! need to be synced with n18i
        const locales = [
            { key: 'ru', name: 'Русский' },
            { key: 'en', name: 'English' }
        ];

        return locales;
    }

    public static Translate(key: string): string {
        return i18n.t(key);
    }

    public static onLanguageChanged(action: (lng) => void) {
        i18n.on('languageChanged', action);
    }
    public static offLanguageChanged(action: (lng) => void) {
        i18n.off('languageChanged', action);
    }

    public static Initialize(callback) {
        i18n
            .use(detector)
            .use(backend)
            .use(initReactI18next)
            .init({
                load: 'languageOnly',
                //debug: true,
                fallbackLng: 'en',
                keySeparator: '.', // we not use chained keys
                // have a common namespace used around the full app
                nsSeparator: false,
                ns: ["translations"],
                defaultNS: "translations",
                interpolation: {
                escapeValue: false, // not needed for react
                formatSeparator: ","
                },
                
                react: {
                useSuspense: false
                }
                }, () => callback());
    }

}

export { i18n as i18nLocales };