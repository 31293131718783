import useFormValidation from './ValidationForm/useFormValidation';
import DatePickerStandaloneValidator from "./DatePickerStandaloneValidator";

const DatePickerValidator = ({
    name,
    value,
    errorMessages,
    validators,
    disabled,

    ...props
}) => {

    const { isValid, errorMessage } = useFormValidation(disabled, name, value, errorMessages, validators);

    return (
        <DatePickerStandaloneValidator isValid={isValid} errorMessage={errorMessage} value={value} disabled={disabled} {...props} />
    );
}

export default DatePickerValidator;