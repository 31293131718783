/* eslint-disable */
import React from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
/* eslint-enable */
import useFormValidation from './ValidationForm/useFormValidation';
import TooltipInfoLabel from '../../../components/shared/TooltipInfoLabel';
import ErrorTooltip from '../../../components/shared/ErrorTooltip';
import withStyles from '@mui/styles/withStyles';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    tooltip: {
        display: 'flex'
    },
    helperText: {
        color: 'green'
    }

});

const TextInputComponent = ({
    name,
    errorMessages,
    validators,
    requiredError,
    helperText,
    value,
    tooltip,
    handleValueChange,
    disabled,

    ...rest

}) => {

    const { isValid, errorMessage } = useFormValidation(disabled, name, value, errorMessages, validators);
    const classes = useStyles();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        handleValueChange(e.target.name, e.target.value);
    }

    return (
        <TooltipInfoLabel className={classes.tooltip} content={tooltip}>
            <TextField
                name={name}
                select={false}
                margin="none"
                type='text'
                onChange={handleChange}
                error={!isValid}
                helperText={helperText}
                value={value}
                disabled={disabled}
                FormHelperTextProps={{
                    className: (isValid && value === '' && helperText != null) ? classes.helperText : {}
                }}
                InputProps={{
                    endAdornment: !isValid && (
                        <InputAdornment position="end">
                            <ErrorTooltip content={errorMessage} />
                        </InputAdornment>
                    )
                }}
                {...rest}>
            </TextField>
        </TooltipInfoLabel>
    );
}

export default TextInputComponent;