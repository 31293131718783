import ServiceBase from "./ServiceBase";
import Globals from "../Globals";

export default class AdministrationService extends ServiceBase {

    static async getUsers(filter) {
        var result = await this.requestJson({
            url: "api/Administration/GetUsers",
            method: "POST",
            data: filter
        });

        return result;
    }

    static async updateUser(data) {

        var result = await this.requestJson({
            url: "api/Administration/UpdateUser",
            method: "POST",
            data: data
        });

        return result;
    }

    static async removeUser(id) {
        var result = await this.requestJson({
            url: "api/Administration/RemoveUser",
            method: "GET",
            data: { id: id }
        });

        return result;
    }

    static async getNotifications(filter) {
        var result = await this.requestJson({
            url: "api/Administration/GetNotifications",
            method: "POST",
            data: filter
        });

        return result;
    }

    static async removeNotification(id) {
        var result = await this.requestJson({
            url: "api/Administration/RemoveNotification",
            method: "GET",
            data: { id: id }
        });

        return result;
    }

    static async getMailingList(filter) {
        var result = await this.requestJson({
            url: "api/Administration/GetMailingList",
            method: "POST",
            data: filter
        });

        return result;
    }

    static async getUsersQuery(searchValue, existedValue) {
        var result = await this.requestJson({
            url: "api/Administration/GetUsersQuery",
            method: "GET",
            data: { query: searchValue, existId: existedValue }
        });

        return result;
    }

    static async getGroupsQuery(searchValue, existedValue) {
        var result = await this.requestJson({
            url: "api/Administration/GetGroupsQuery",
            method: "GET",
            data: { query: searchValue, existId: existedValue }
        });

        return result;
    }

    static async getDepartments() {
        var result = await this.requestJson({
            url: "api/Administration/GetDepartments",
            method: "GET"
        });

        return result;
    }

    static async createGroups(data) {
        var result = await this.requestJson({
            url: "api/Administration/CreateGroups",
            method: "POST",
            data: data
        });

        return result;
    }
    static async updateGroups(data) {
        var result = await this.requestJson({
            url: "api/Administration/UpdateGroups",
            method: "POST",
            data: data
        });

        return result;
    }
    static async removeGroups(data) {
        var result = await this.requestJson({
            url: "api/Administration/RemoveGroups",
            method: "POST",
            data: data
        });

        return result;
    }
    static async getRoles() {
        var result = await this.requestJson({
            url: "api/Administration/GetRoles",
            method: "GET"
        });

        return result;
    }
    static async getPermissions() {
        var result = await this.requestJson({
            url: "api/Administration/GetPermissions",
            method: "GET"
        });

        return result;
    }
    static async getRolePermissions(id) {
        var result = await this.requestJson({
            url: "api/Administration/GetRolePermissions",
            method: "GET",
            data: {id: id}
        });

        return result;
    }

    static async getUserPermissions(id) {
        var result = await this.requestJson({
            url: "api/Administration/GetUserPermissions",
            method: "GET",
            data: { id: id }
        });

        return result;
    }

    static async updateRole(role) {
        var result = await this.requestJson({
            url: "api/Administration/UpdateRole",
            method: "POST",
            data: role
        });

        return result;
    }
    static async createRole(roleName) {
        var result = await this.requestJson({
            url: "api/Administration/CreateRole",
            method: "GET",
            data: { name: roleName }
        });

        return result;
    }

    static async updateUserPermissions(data) {
        var result = await this.requestJson({
            url: "api/Administration/UpdateUserPermissions",
            method: "POST",
            data: data
        });

        return result;
    }

    /*
    static async addEmployeesRole(managers) {
        var result = await this.requestJson({
            url: "api/Administration/AddEmployeesRole",
            method: "POST",
            data: managers
        });

        return result;
    }

    static async removeEmployeesRole(records, role) {
        var result = await this.requestJson({
            url: "api/Administration/RemoveEmployeesRole",
            method: "POST",
            data: { usersId: records, role: role }
        });

        return result;
    }
    */
}