import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const defaultOptions = {
    show: false,
    title: 'Вы уверены?',
    description: '',
    confirmationText: 'Ок',
    cancellationText: 'Отмена',
    dialogProps: {},
    onClose: () => { },
    onCancel: () => { },
    onConfirm: () => { },
};

const ConfirmDialog = props => {

    const {
        show,
        title,
        description,
        confirmationText,
        cancellationText,
        dialogProps,
        onClose,
        onCancel,
        onConfirm
    } = { ...defaultOptions, ...props };

    const handleClose = () => {
        onClose();
    };
    const handleCancel = () => {
        onCancel();
        handleClose();
    };
    const handleConfirm = (...args) => {
        onConfirm(...args);
        handleClose();
    };

    return (
        <Dialog fullWidth {...dialogProps} open={show} onClose={handleCancel}>
            {title && (
                <DialogTitle>{title}</DialogTitle>
            )}
            {description && (
                <DialogContent dividers>
                    <DialogContentText>{description}</DialogContentText>
                </DialogContent>
            )}
            <DialogActions>
                <Button variant="contained" color="primary" onClick={handleCancel}>
                    {cancellationText}
                </Button>
                <Button variant="contained" color="secondary" onClick={handleConfirm}>
                    {confirmationText}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmDialog;