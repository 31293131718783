import { AggregateFunctions, ColumnDataType, ColumnSortDirection, CompareOperators, TrimContentType } from './Column';
import IColumnModelOptions from './IColumnModelOptions';
import { IFilterWrapper } from './IFilterWrapper';
import Action from './Action';
import { Localization } from '../../DataGrid/Localization';

function filterProps(name: string): object {
    return {
        Argument: [],
        HasFilter: false,
        Name: name,
        Operator: 'None',
        Value: null
    };
}

const NumericOperators = [
    { Value: CompareOperators.NONE, Title: 'None' },
    { Value: CompareOperators.EQUALS, Title: 'Equals' },
    { Value: CompareOperators.BETWEEN, Title: 'Between' },
    { Value: CompareOperators.GTE, Title: 'GreaterOrEquals' },
    { Value: CompareOperators.GT, Title: 'Greater' },
    { Value: CompareOperators.LTE, Title: 'LesserOrEquals' },
    { Value: CompareOperators.LT, Title: 'Lesser' }
];

const StringOperators = [
    { Value: CompareOperators.NONE, Title: 'None' },
    { Value: CompareOperators.EQUALS, Title: 'Equals' },
    { Value: CompareOperators.NOT_EQUALS, Title: 'NotEquals' },
    { Value: CompareOperators.CONTAINS, Title: 'Contains' },
    { Value: CompareOperators.NOT_CONTAINS, Title: 'NotContains' },
    { Value: CompareOperators.STARTS_WITH, Title: 'StartsWith' },
    { Value: CompareOperators.NOT_STARTS_WITH, Title: 'NotStartsWith' },
    { Value: CompareOperators.ENDS_WITH, Title: 'EndsWith' },
    { Value: CompareOperators.NOT_ENDS_WITH, Title: 'NotEndsWith' }
];

const BooleanOperators = [
    { Value: CompareOperators.NONE, Title: 'None' },
    { Value: CompareOperators.EQUALS, Title: 'Equals' },
    { Value: CompareOperators.NOT_EQUALS, Title: 'NotEquals' }
];

const EnumOperators = [
    { Value: CompareOperators.NONE, Title: 'None' },
    { Value: CompareOperators.EQUALS, Title: 'Equals' },
    { Value: CompareOperators.NOT_EQUALS, Title: 'NotEquals' }
];

export interface DSResult {
    items: any[];
}

export default class ColumnModel {
    public static createFilterPatch(column: ColumnModel): IFilterWrapper {
        let filterText = column.Filter.Value;
        let filterArgument = column.Filter.Argument[0];

        if (column.DataType === ColumnDataType.FLOAT) {
            filterText = parseFloat(filterText);
            filterArgument = parseFloat(filterArgument);
        }
        else if (column.DataType === ColumnDataType.INT) {
            filterText = parseInt(filterText);
            filterArgument = parseInt(filterArgument);
        }
        else if (column.DataType === ColumnDataType.BOOLEAN) {
            filterText = filterText === 'true';
            filterArgument = '';
        }

        return {
            Argument: [filterArgument],
            HasFilter: true,
            Operator: column.Filter.Operator || CompareOperators.AUTO,
            Value: filterText,
        };
    }

    public static getOperators(column: ColumnModel) {
        switch (column.DataType) {
            case ColumnDataType.STRING:
                return StringOperators;
            case ColumnDataType.FLOAT:
            case ColumnDataType.INT:
            case ColumnDataType.DATE:
            case ColumnDataType.DATE_TIME:
            case ColumnDataType.DATE_TIME_UTC:
                return NumericOperators;
            case ColumnDataType.BOOLEAN:
                return BooleanOperators;
            case ColumnDataType.ENUM:
            case ColumnDataType.ENUM_AUTOCOMPLETE:
                return EnumOperators;
            default:
                return [];
        }
    }

    public static sortColumnArray(columnName: string, columns: ColumnModel[], multiSort: boolean) {
        const column = columns.find((c: ColumnModel) => c.Name === columnName);
        if (!column) { return; }

        column.SortDirection = column.SortDirection === ColumnSortDirection.NONE
            ? ColumnSortDirection.ASCENDING
            : column.SortDirection === ColumnSortDirection.ASCENDING ?
                ColumnSortDirection.DESCENDING :
                ColumnSortDirection.NONE;

        column.SortOrder = column.SortDirection === ColumnSortDirection.NONE ? -1 : Number.MAX_VALUE;

        if (!multiSort) {
            columns
                .filter((col: any) => col.Name !== columnName)
                .forEach((c: any) => {
                    c.SortOrder = -1;
                    c.SortDirection = ColumnSortDirection.NONE;
                });
        }

        columns
            .filter((col: ColumnModel) => col.SortOrder > 0)
            .sort((a: ColumnModel, b: ColumnModel) => a.SortOrder === b.SortOrder ? 0 : (a.SortOrder > b.SortOrder ? 1 : -1))
            .forEach((col: any, i: number) => { col.SortOrder = i + 1; });

        return columns;
    }

    public static clearFilterPatch(): IFilterWrapper {
        return {
            Argument: [''],
            HasFilter: false,
            Operator: CompareOperators.NONE,
            Value: null,
        };
    }

    public Aggregate: AggregateFunctions;
    public DataType: ColumnDataType;
    public Filter: any;
    public Filterable: boolean;
    public IsKey: boolean;
    public Label: string;
    public Name: string;
    public Tooltip: string;
    public Searchable: boolean;
    public SortDirection: ColumnSortDirection;
    public SortOrder: number;
    public Sortable: boolean;
    public Visible: boolean; //column is visible
    public VisibleInEdit: boolean; //visible in edit dialog
    public Width: string | null;
    public Format: string | null;
    public IsAction: boolean; //action column
    public Actions: Action[] | null;
    public IsReadonly: boolean; //if column is readonly
    public DataSource: DSResult[] | any; //any[] | ((value?: string, index?: number, count?: number) => any); //datasource for enum
    public Render?: (row: any) => string | HTMLElement;
    public TrimContent?: TrimContentType | null;
    public Fixed?: boolean | null;
    public DataChanged?: any; //function, called before data change
    public RenderEdit?: (object: any, column: ColumnModel, locale: Localization) => string | HTMLElement | null;
    public ForceSelection: boolean;
    public DefaultValue: any;
    public BreakSpace: boolean;

    public hasFilter = () => this.Filter &&
        (this.Filter.Value || this.Filter.Argument) &&
        this.Filter.Operator !== CompareOperators.NONE;

    constructor(name: string, options?: IColumnModelOptions) {

        this.Aggregate = options && options.Aggregate || AggregateFunctions.NONE;
        this.DataType = options && options.DataType || ColumnDataType.STRING;
        this.IsKey = options && options.IsKey || false;
        this.Label = options && options.Label || (name || '').replace(/([a-z])([A-Z])/g, '$1 $2');
        this.Name = name;
        this.Tooltip = options && options.Tooltip || '';
        this.Searchable = options && options.Searchable || false;
        this.SortDirection = options && options.Sortable && options.SortDirection || ColumnSortDirection.NONE;
        this.SortOrder = options && this.SortDirection !== ColumnSortDirection.NONE && options.SortOrder || -1;
        this.Sortable = options && options.Sortable || false;
        this.Visible = options && typeof (options.Visible) === 'boolean' ? options.Visible : true;
        this.VisibleInEdit = options && typeof (options.VisibleInEdit) === 'boolean' ? options.VisibleInEdit : true;
        this.Filter = options && options.Filterable ? filterProps(name) : {};
        this.Filterable = options && options.Filterable || false;
        this.Width = options && options.Width || null;
        this.Filter.HasFilter = this.hasFilter();
        this.Format = options && options.Format || null;
        this.IsAction = options && options.IsAction || false;
        this.IsReadonly = options && options.IsReadonly || false;
        this.DataSource = options && options.DataSource || null;
        this.Render = options && options.Render || undefined;
        this.TrimContent = (options != null) && options.TrimContent || null;
        this.DataChanged = (options != null) && options.DataChanged || null;
        this.RenderEdit = options && options.RenderEdit || undefined;
        this.Actions = options && options.Actions || null;
        this.Fixed = options && options.Fixed || null;
        this.ForceSelection = options && typeof (options.ForceSelection) === 'boolean' ? options.ForceSelection : true;
        this.DefaultValue = options && options.DefaultValue;
        this.BreakSpace = options && options.BreakSpace || false;
    }
}
