import KeyRateService from "@Services/KeyRateService";

const Actions = {
    SendRequest: "KEYRATE_REQUEST_START",
    RequestSuccess: "KEYRATE_REQUEST_SUCCESS",
    RequestFailed: "KEYRATE_REQUEST_FAILURE"
}

export const actionCreators = {

    getKeyrates: (evaluationDate/*, zcycDate*/) => async (dispatch) => {
        dispatch({ type: Actions.SendRequest });

        var result = await KeyRateService.getKeyrates(evaluationDate/*, zcycDate*/);
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailed });
            return;
        }

        dispatch({ type: Actions.RequestSuccess, payload: { keyrate: result.value } });
    }

};

const initialState = {
    operationLoading: false
};

export const reducer = (currentState, incomingAction) => {

    if (currentState === undefined)
        return initialState; 

    const action = incomingAction;

    switch (action.type) {
        case Actions.Init:
            return initialState;

        case Actions.RequestStart:
            return { ...currentState, operationLoading: true };

        case Actions.RequestSuccess:
            return { ...currentState, operationLoading: false, ...action.payload};

        case Actions.RequestFailure:
            return { ...currentState, operationLoading: false };
        default:
            return currentState || initialState;
    }
};