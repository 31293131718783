import React, { useState, useEffect, useRef, useCallback } from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { useTranslation } from "react-i18next";
import { CouponType, DealType, efDealValidationState } from '@Components/Definitions';
import { valueOrDefault } from '@Utilities';
import style from '@Styles/domrf-theme.module.scss';
import UniversalInputField, { InputDataType } from "@Framework/components/Form/UniversalInputField";
import CustomTooltip from '../../../../components/shared/CustomTooltip';
import { alpha, Box } from '@mui/material';
import Globals from '../../../../Globals';
import ChipAuthor from '@Base/components/shared/ChipAuthor';
import { useSelector } from 'react-redux';
import { ValidatorForm } from '@Base/framework/components/Form';
import { actionCreators as actionSecuritization } from '@Base/store/SecuritizationStore'
import { debounce } from 'throttle-debounce'
import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme: Theme) => createStyles({
    rootContainer: {
        flex: '1 1 auto',
        margin: '3px 5px 0 0',
        //overflowY: 'auto',
        //height: '0px'
        '& *': {
            userSelect: 'none',
        },
    },
    resetButton: {
        color: style.colorPrimary1,
        position: 'absolute',
        right: '5px',
        top: 0,
    },
    infoField: {
        position: 'relative',
        right: 0
    },
    disabled: {
        color: `${style.colorPrimary3_120} !important`,
        '& .MuiInput-underline': {
            '&:before': {
                borderBottom: 'none !important',
            }
        }
    },
    changed: {
        // '&::before':{
        //     // content:'""',
        // },
        backgroundColor: /*`rgb(248,248,250)`*/`#f2f8e8`,
    },
    Block: {
        display: 'flex',
        flexDirection: 'column',
        '& *': {
            fontSize: '1rem',
        },
        // marginLeft: '8px',
        marginRight: 0,
        // marginBottom:'33px',
        // '&>$rowBlock:nth-child(even)': {
        //     backgroundColor: `${alpha(style.colorPrimary3, 0.07)}`,
        // }
        // '&>$rowBlock:nth-child(n)': {
        //     boxShadow: '1px 1px #00000015',
        // },
    },
    blockName: {
        color: `${style.colorPrimary3_40}`,
        textTransform: 'uppercase',
        boxShadow: `0px 1px ${alpha(style.colorPrimary3, 0.7)}`,
        // borderBottom: `1px solid ${alpha(style.colorPrimary3, 1)}`,
        padding: '0',
        margin: '4px 0',
        // lineHeight:'2rem',
        // fontSize:'0.8rem'
    },
    rowBlock: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'baseline',
        lineHeight: '2rem',
        height: '2rem !important',
    },
    rowName: {
        flexShrink: 1,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        lineHeight: '1rem',
    },
    rowValuesBlock: {
        display: 'flex',
        flexWrap: 'nowrap',
        textAlign: 'right',
        justifyContent: 'flex-end',
        flexGrow: 1,
        // lineHeight: '32px',
        alignItems: 'baseline',
    },
    valueData: {
        width: '130px !important',
        whiteSpace: 'nowrap',
        height: '32px',
        // display: 'flex',
        // justifyContent: 'flex-end',
        '& svg': {
            height: '19px',
            width: '19px',
            padding: 0,
            // marginRight: '-4px !important',
        },
        '& .MuiInputAdornment-root': {
            // marginRight: '-2px',
        },
        '& .MuiInputAdornment-root svg': {
            // fontSize: '23px',
            margin: '6px',
        },
        '& input': {
            '&::selection': {
                backgroundColor: `rgb(200,232,250)`
            },
            textAlign: 'right',
            paddingRight: '0.5rem',
            paddingLeft: '0.5rem',
        }
    },
    valueUnit: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        width: '3rem !important',
        fontSize: '0.8rem',
        paddingLeft: '0.3rem',
        color: `${alpha(style.colorPrimary3, 0.5)}`,
        textAlign: 'left',
    },
    valueAuthor: {
        display: 'flex',
        width: '25px !important',
        textAlign: 'right',
        alignSelf: 'center',
        '& *': {
            fontSize: '0.8rem',
        },
    },
    container: {
        flex: '1 1 auto',
        // overflowY: 'auto',
        //height: '0px'
        margin: '0 5px 0 0',
    },
    subheader: {
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
        textTransform: 'uppercase',
        color: `${alpha(style.colorPrimary3, 0.6)}`,
        backgroundColor: '#FFFFFF',
        borderBottom: `1px solid ${alpha(style.colorPrimary3, 0.6)}`
    },
    grayColor: {
        backgroundColor: '#f7f7f7'
    },
    whiteColor: {
        backgroundColor: '#FFFFFF'
    },
    errorMessage: {
        margin: '0 0.5rem',
        color: style.colorError,
    },
    keyrateButtonsBlock: {
        alignSelf: 'center',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignItems: 'center',
        marginRight: '0.5rem',
    },
    keyrateButtonsIcon: { width: '20px', height: '20px' },
    backDrop: {
        zIndex: '+1',
        // position:'fixed',
        // left:0, right:0, top:0, bottom:0,
        // backgroundColor:`${alpha(style.colorPrimary3,0.3)}`,
        pointerEvents: 'auto'
    },
    popupPaper: {
        padding: '0.5rem',
        width: '60rem',
        position: 'relative',
        overflow: 'visible',
        zIndex: '+1'
    },
    popupPaperCloseElement: {
        position: 'absolute',
        right: '-43px', top: '-30px',
        '&:hover $popupPaperCloseElementIcon': {
            opacity: 0.8,
        },
    },
    popupPaperCloseElementIcon: {
        color: '#FFF',
        width: '30px',
        height: '30px',
        padding: '2px',
        border: 'solid 1px #FFF',
        borderRadius: '50%',
        opacity: 0.5,
    }
}))

const IntegralIdentificatorsPanel = ({ /*updatedFields, /*data,*/ isEdit, readyForRelease = (state) => { }, onFieldUpdate }) => {

    // console.log('data---------------', data)
    const classes = useStyles();
    const { t, i18n } = useTranslation();

    const { deal: data = null, originators = null, permissions, ...otherStore } = useSelector((state: any) => state.securitization)
    const dispatch = useDispatch()

    //identificators
    const [dealName, setDealName] = useState(data?.deal?.name || '');
    const [regNumber, setRegNumber] = useState(data?.deal?.registrationNumber || '');
    const [ISIN, setISIN] = useState(data?.deal?.isin || '');
    const [moexTicker, setMoexTicker] = useState(data?.deal?.moexTicker || '');
    //const [bloombergTicker, setBloombergTicker] = useState(data?.deal?.bloombergTicker || '');

    const [originatorsList, setOriginatorsList] = useState([]);
    const [selectedOriginator, setSelectedOriginator] = useState(data?.deal?.originator?.id || null);

    // console.log('data?.deal?.originator?.id', data?.deal?.originator?.id)
    //bond parameters
    //const [couponType, setCouponType] = React.useState(data?.deal?.couponType || '');
    //const [cleanUpCall, setCleanUpCall] = React.useState(valueOrDefault(data?.iteration?.cleanUpCall, ''));

    /*
    SwapType	Тип свопа
    SwapMA	Своп с ипотечным агентом
    FixDayMA	Своп с ИА: день оплаты фиксированных сумм
    FloatDayMA	Своп с ИА: день оплаты плавающих сумм
    SwapORIG	Своп с Оригинатором
    FixDayORIG	Своп с Оригинатором: день оплаты фиксированных сумм
    FloatDayOrig	Своп с Оригинатором: день оплаты плавающих сумм
    */
    //deal structure
    //const [swapType, setSwapType] = useState(data?.deal?.swapType || '');
    //const [swapMA, setSwapMA] = useState(!!data?.deal?.swapMA || true);
    //const [fixDayMA, setFixDayMA] = useState(valueOrDefault(data?.deal?.fixDayMA, ''));
    //const [floatDayMA, setFloatDayMA] = useState(valueOrDefault(data?.deal?.floatDayMA, ''));
    //const [swapORIG, setSwapORIG] = useState(!!data?.deal?.swapORIG);
    //const [fixDayORIG, setFixDayORIG] = useState(valueOrDefault(data?.deal?.fixDayORIG, ''));
    //const [floatDayOrig, setFloatDayOrig] = useState(valueOrDefault(data?.deal?.floatDayOrig, ''));
    //
    //const swapTypes = getSwapTypes()?.items;
    //const couponTypes = getCouponTypes()?.items;

    //PoolStatisticsAtSignDatePanel
    /*
    FixationWAC	WAC по РИП на дату подписания, % год.
    FixationWALA	WALA по РИП на дату подписания, лет
    FixationWAM	WAM по РИП на дату подписания, мес.
    FixationAdjustedWAM	Скорретированный WAM по РИП на дату подписания, мес.
    */

    //PoolStatisticsAtSignDatePanel
    const [fixationWAC, setFixationWAC] = useState(valueOrDefault(data?.deal?.fixationWAC, ''));
    const [fixationWALA, setFixationWALA] = useState(valueOrDefault(data?.deal?.fixationWALA, ''));
    const [fixationWAM, setFixationWAM] = useState(valueOrDefault(data?.deal?.fixationWAM, ''));
    const [fixationAdjustedWAM, setFixationAdjustedWAM] = useState(valueOrDefault(data?.deal?.fixationAdjustedWAM, ''));

    /*
    ActDeliveryDebt	    Сумма ОСЗ по акту передачи, руб.
    ActDeliveryYield	Начисленные проценты по акту передачи, руб.
    ActDeliveryPremium	Премия/дисконт по акту передачи, руб.
    */
    //DealActOfTransferDataPanel
    const [actDeliveryDebt, setActDeliveryDebt] = useState(valueOrDefault(data?.deal?.actDeliveryDebt, ''));
    const [actDeliveryYield, setActDeliveryYield] = useState(valueOrDefault(data?.deal?.actDeliveryYield, ''));
    const [actDeliveryPremium, setActDeliveryPremium] = useState(valueOrDefault(data?.deal?.actDeliveryPremium, ''));

    /*
    CorrectReportsStartDate	Дата первого корректного сервисного отчета
    ProductID	Тип продукта
    */
    //DealOtherParametersPanel
    const [correctReportsStartDate, setCorrectReportsStartDate] = useState(data?.deal?.correctReportsStartDate || '');
    const [productID, setProductID] = useState(data?.deal?.productID || '');
    const [products, setProducts] = useState(data?.products?.map(prod => { return { key: prod.id, value: prod.name } }) || []);


    //newFields for Bonds
    const [couponType, setCouponType] = React.useState(data?.deal?.couponType || CouponType.TYPE_FIXED)
    // const [bondstatus, setBondstatus] = useState(data?.deal?.bondstatus || '')
    const [inclusionDebtDate, setInclusionDebtDate] = useState(data?.deal?.inclusionDebtDate || '')
    const [guaranteeRate, setGuaranteeRate] = useState(data?.deal?.guaranteeRate || '')
    const [placementCPR, setPlacementCPR] = useState(data?.deal?.placementCPR || '')
    const [placementCDR, setPlacementCDR] = useState(data?.deal?.placementCDR || '')
    const [lockPlacementPrice, setLockPlacementPrice] = useState(data?.deal?.lockPlacementPrice || false)
    const [lockPlacementCoupon, setLockPlacementCoupon] = useState(data?.deal?.lockPlacementCoupon || false)
    const [defaultPlacementPrice, setDefaultPlacementPrice] = useState(data?.deal?.defaultPlacementPrice || '')
    // const [usePlacementCoupon, setUsePlacementCoupon] = useState(data?.deal?.usePlacementCoupon || '')
    const [firstCouponExpensesIssueDoc, setFirstCouponExpensesIssueDoc] = useState(data?.deal?.firstCouponExpensesIssueDoc || '')
    const [otherCouponsExpensesIssueDoc, setOtherCouponsExpensesIssueDoc] = useState(data?.deal?.otherCouponsExpensesIssueDoc || '')
    const [specDepRateIssueDoc, setSpecDepRateIssueDoc] = useState(data?.deal?.specDepRateIssueDoc || '')
    const [specDepMinMonthIssueDoc, setSpecDepMinMonthIssueDoc] = useState(data?.deal?.specDepMinMonthIssueDoc || '')
    const [specDepCompensationMonthIssueDoc, setSpecDepCompensationMonthIssueDoc] = useState(data?.deal?.specDepCompensationMonthIssueDoc || '')
    //***END*** newFields for Bonds

    const submitForm = useRef(null);

    /*
    PoolExpectedLoss	Ожидаемые потери EL, б.п.
    OPEX	Операционные расходы OPEX, б.п.
    PoolCapitalMargin	Маржа на капитал, б.п.
    PoolPrepaymentOption	Опцион досрочных погашений, б.п.
    PoolBankMargin	Маржа Банка ДОМ.РФ, б.п.
    */
    //EvaluationParametersPanel
    //const [poolExpectedLoss, setPoolExpectedLoss] = useState(valueOrDefault(data?.iteration?.poolExpectedLoss, ''));
    //const [opex, setOPEX] = useState(valueOrDefault(data?.iteration?.opex, ''));
    //const [poolCapitalMargin, setPoolCapitalMargin] = useState(valueOrDefault(data?.iteration?.poolCapitalMargin, ''));
    //const [poolPrepaymentOption, setPoolPrepaymentOption] = useState(valueOrDefault(data?.iteration?.poolPrepaymentOption, ''));
    //const [poolBankMargin, setPoolBankMargin] = useState(valueOrDefault(data?.iteration?.poolBankMargin, ''));
    const validateFormFields = (fields) => {

    }

    const handleFormValidation = debounce(700, async () => {
        await submitForm.current?.isFormValid(false, true);
    });

    useEffect(() => {
        // console.log('data!!!!!!!!!!', data!!!!!!!!!!)
        // console.log('first')
        //identificators
        setDealName(data?.deal?.name || '');
        setRegNumber(data?.deal?.registrationNumber || '');
        setISIN(data?.deal?.isin || '');
        setMoexTicker(data?.deal?.moexTicker || '');
        setSelectedOriginator(data?.deal?.originator?.id)
        //setBloombergTicker(data?.deal?.bloombergTicker || '');

        setFixationWAC(valueOrDefault(data?.deal?.fixationWAC, ''));
        setFixationWALA(valueOrDefault(data?.deal?.fixationWALA, ''));
        setFixationWAM(valueOrDefault(data?.deal?.fixationWAM, ''));
        setFixationAdjustedWAM(valueOrDefault(data?.deal?.fixationAdjustedWAM, ''));

        //DealActOfTransferDataPanel
        setActDeliveryDebt(valueOrDefault(data?.deal?.actDeliveryDebt, ''));
        setActDeliveryYield(valueOrDefault(data?.deal?.actDeliveryYield, ''));
        setActDeliveryPremium(valueOrDefault(data?.deal?.actDeliveryPremium, ''));

        //DealOtherParametersPanel
        setCorrectReportsStartDate(data?.deal?.correctReportsStartDate || '');
        setProductID(data?.deal?.productID || '');
        setProducts(data?.products?.map(prod => { return { key: prod.id, value: prod.name } }) || []);

        setOriginatorsList(originators?.map(orig => { return { key: orig.id, value: orig.shortName } }));

        // setBondstatus(valueOrDefault(data?.deal?.bondstatus, ''))
        setInclusionDebtDate(valueOrDefault(data?.deal?.inclusionDebtDate, ''))
        setGuaranteeRate(valueOrDefault(data?.deal?.guaranteeRate, ''))
        setPlacementCPR(valueOrDefault(data?.deal?.placementCPR, ''))
        setPlacementCDR(valueOrDefault(data?.deal?.placementCDR, ''))
        setLockPlacementPrice(valueOrDefault(data?.deal?.lockPlacementPrice, false))
        setLockPlacementCoupon(valueOrDefault(data?.deal?.lockPlacementCoupon, false))
        setDefaultPlacementPrice(valueOrDefault(data?.deal?.defaultPlacementPrice, ''))
        // setUsePlacementCoupon(valueOrDefault(data?.deal?.usePlacementCoupon, ''))
        setFirstCouponExpensesIssueDoc(valueOrDefault(data?.deal?.firstCouponExpensesIssueDoc, ''))
        setOtherCouponsExpensesIssueDoc(valueOrDefault(data?.deal?.otherCouponsExpensesIssueDoc, ''))
        setSpecDepRateIssueDoc(valueOrDefault(data?.deal?.specDepRateIssueDoc, ''))
        setSpecDepMinMonthIssueDoc(valueOrDefault(data?.deal?.specDepMinMonthIssueDoc, ''))
        setSpecDepCompensationMonthIssueDoc(valueOrDefault(data?.deal?.specDepCompensationMonthIssueDoc, ''))

        //EvaluationParametersPanel
        //setPoolExpectedLoss(valueOrDefault(data?.iteration?.poolExpectedLoss, ''));
        //setOPEX(valueOrDefault(data?.iteration?.opex, ''));
        //setPoolCapitalMargin(valueOrDefault(data?.iteration?.poolCapitalMargin, ''));
        //setPoolPrepaymentOption(valueOrDefault(data?.iteration?.poolPrepaymentOption, ''));
        //setPoolBankMargin(valueOrDefault(data?.iteration?.poolBankMargin, ''));

        // if (data?.originators != null) {

        //     if (data?.deal?.originatorID != null) {
        //         var index = data.originators.findIndex(x => x.id == data.deal.deal.originatorID);
        //         if (index != -1)
        //             setSelectedOriginator(data.originators[index].id);
        //     }

        //      setOriginators(data.originators.map(orig => { return { key: orig.id, value: orig.name } }));
        // }

        //bond parameters
        //setCouponPeriod(valueOrDefault(data?.iteration?.couponPeriod, 3));
        //setCleanUpCall(valueOrDefault(data?.iteration?.cleanUpCall, ''));
        setCouponType(data?.deal?.couponType || CouponType.TYPE_FIXED)

        //deal structure
        //setSwapType(data?.deal?.swapType || '');
        //setSwapMA(!!data?.deal?.swapMA || true);
        //setFixDayMA(valueOrDefault(data?.deal?.fixDayMA, ''));
        //setFloatDayMA(valueOrDefault(data?.deal?.floatDayMA, ''));
        //setSwapORIG(!!data?.deal?.swapORIG);
        //setFixDayORIG(valueOrDefault(data?.deal?.fixDayORIG, ''));
        //setFloatDayOrig(valueOrDefault(data?.deal?.floatDayOrig, ''));


    }, [data?.deal]);

    useEffect(() => {
        handleFormValidation()
    }, [data])

    const onFormStateChanged = (isValid: boolean) => {

        readyForRelease(isValid)
        // //console.log("!!!!!!form state changed!!!!! new value: " + isValid);
        // // console.log('isDealParametersValid', isValid)
        // //actionSecuritization.setDealData("isDealParametersValid", isValid)(dispatch);

        // if (data?.deal != null) {
        //     var state = data.deal.validationState;
        //     if (isValid)
        //         state &= ~efDealValidationState.STATE_DEAL_PARAMETERS_INVALID;
        //     else
        //         state |= efDealValidationState.STATE_DEAL_PARAMETERS_INVALID;

        //     if (data.deal.validationState != state) {

        //         console.log(`===onFormStateChanged deal state changed: ${state}`);

        //         updateDealState(deal.deal.id, state);
        //     }
        // }
    }

    const onFieldChanged = (name: string, value: any, isValid: boolean) => {

        if (!isValid)
            return; //non-valid is not checking

        var updates = {};

        switch (name) {
            // case 'fieldName':
            //     updates.fieldName = { Value: 123 };
            //     break;

            default:
                return;
        }

        updateFields(updates) // only if updates maked
    }


    const onUpdateField = (name, value) => {

        updateFields({ [name]: { Value: value } });

    }

    const updateField = (name, setValueFn, value) => {
        //datetime fields does not generates blur() because of modal input
        var field = null;
        for (var i = 0; i < groups.length; i++) {
            field = groups[i].fields.find(x => { if (x != null) return x.name == name });
            if (field != null)
                break;
        }

        setValueFn(value);
        onUpdateField(name, value /*, bIsForceToUpdate*/);
    }

    const updateFields = (updates: any) => {

        var keys = Object.keys(updates);
        if (keys.length == 0)
            return;

        //CHECKME!!! update selected deal state

        //for online changes
        // var update = { DealID: data.deal.id, ...updates };
        // actionSecuritization.sendDealChange(update)(dispatch);

        var update = { id: data.deal.id, ...updates };
        // console.log('update', update)
        actionSecuritization.updateDeal(update)(dispatch);
    }

    const formEditFields = (): any[] => {

        if (data?.deal?.type == null)
            return [];

        // console.log('data?.dealChanges', data?.dealChanges, data, originatorsList)
        // console.log('data?.deal?', data?.deal)
        const originatorName = originatorsList?.length ? originatorsList.filter(x => x.key == selectedOriginator)[0]?.value : ''

        const bondIdentificators = {
            name: 'BondIdentifiersPanel', label: t('OtherIssueIdentifiers'), fields: [
                // { name: 'selectedOriginator', label: t('Originator'), tooltip: t('releaseOriginator_tooltip'), value: selectedOriginator, badgeContent: data?.dealChanges?.originatorID, dataType: InputDataType.ENUM, dataSource: originatorsList, readOnly: true },
                { name: 'selectedOriginator', label: t('Originator'), tooltip: t('releaseOriginator_tooltip'), value: originatorName, badgeContent: data?.dealChanges?.originatorID, dataType: InputDataType.STRING, readOnly: true },
                { name: 'name', label: t('InnerName'), tooltip: t('releaseDeal_tooltip'), value: dealName, updateValue: setDealName, badgeContent: data?.dealChanges?.name, dataType: InputDataType.STRING, readOnly: data?.deal?.bondID != null },
                { name: 'registrationNumber', /* width:'183px',*/ label: t('RegistrationNumber'), tooltip: t('releaseRegistrationNumber_tooltip'), validators: { validators: ['required'], errorMessages: [t('FieldRequired')] }, value: regNumber, updateValue: setRegNumber, badgeContent: data?.dealChanges?.registrationNumber, dataType: InputDataType.STRING, customProps: { style: { textOverflow: 'ellipsis' }, }, },
                { name: 'isin', readOnly: Boolean(data?.deal?.bondID != null), label: t('ISIN'), tooltip: t('releaseISIN_tooltip'), validators: { validators: ['required'], errorMessages: [t('FieldRequired')] }, value: ISIN, updateValue: setISIN, badgeContent: data?.dealChanges?.isin, dataType: InputDataType.STRING, customProps: { style: { textOverflow: 'ellipsis' }, }, },
                { name: 'moexTicker', label: t('Ticker'), tooltip: t('releaseTicker_tooltip'), validators: { validators: ['required'], errorMessages: [t('FieldRequired')] }, value: moexTicker, updateValue: setMoexTicker, badgeContent: data?.dealChanges?.moexTicker, dataType: InputDataType.STRING, customProps: { style: { textOverflow: 'ellipsis' }, }, },
                // { name: 'bloombergTicker', label: t('BloombergTicker'), tooltip: t('BloombergTicker_tooltip'), value: bloombergTicker, updateValue: setBloombergTicker, badgeContent: data?.dealChanges?.bloombergTicker, dataType: InputDataType.STRING }
                { name: 'guaranteeRate', label: t('GuaranteeRate_release'), tooltip: t('GuaranteeRate_release_tooltip'), validators: { validators: ['required'], errorMessages: [t('FieldRequired')] }, value: guaranteeRate, updateValue: setGuaranteeRate, badgeContent: data?.dealChanges?.guaranteeRate, dataType: InputDataType.FLOAT, unit: 'percentInYear' },
            ]
        };

        const parameters = {
            name: 'ConventionParametersPanel', label: t('ConventionParameters_release'), fields: [
                // { name: 'bondstatus', label: t('bondstatus'), tooltip: t('bondstatus_tooltip'), value: bondstatus, updateValue: setBondstatus, badgeContent: data?.dealChanges?.bondstatus, dataType: InputDataType.STRING },
                { name: 'placementCPR', label: t('PlacementCPR_release'), tooltip: t('PlacementCPR_release_tooltip'), value: placementCPR, updateValue: setPlacementCPR, badgeContent: data?.dealChanges?.placementCPR, dataType: InputDataType.FLOAT, unit: 'percentInYear', },
                { name: 'placementCDR', label: t('PlacementCDR_release'), tooltip: t('PlacementCDR_release_tooltip'), validators: { validators: ['required'], errorMessages: [t('FieldRequired')] }, value: placementCDR, updateValue: setPlacementCDR, badgeContent: data?.dealChanges?.placementCDR, dataType: InputDataType.FLOAT, unit: 'percentInYear', },
                { name: 'lockPlacementPrice', label: t('LockPlacementPrice_release'), tooltip: t('LockPlacementPrice_release_tooltip'), validators: { validators: ['required'], errorMessages: [t('FieldRequired')] }, value: lockPlacementPrice, updateValue: setLockPlacementPrice, badgeContent: data?.dealChanges?.lockPlacementPrice, dataType: InputDataType.BOOLEAN },
                couponType == CouponType.TYPE_KEYRATE_AND_PREMIUM ?
                    { name: 'lockPlacementCoupon', label: t('LockPlacementCoupon_release2'), tooltip: t('LockPlacementCoupon_release_tooltip2'), validators: { validators: ['required'], errorMessages: [t('FieldRequired')] }, value: lockPlacementCoupon, updateValue: setLockPlacementCoupon, badgeContent: data?.dealChanges?.lockPlacementCoupon, dataType: InputDataType.BOOLEAN }
                    :
                    couponType !== CouponType.TYPE_FLOAT ?
                        { name: 'lockPlacementCoupon', label: t('LockPlacementCoupon_release1'), tooltip: t('LockPlacementCoupon_release_tooltip1'), validators: { validators: ['required'], errorMessages: [t('FieldRequired')] }, value: lockPlacementCoupon, updateValue: setLockPlacementCoupon, badgeContent: data?.dealChanges?.lockPlacementCoupon, dataType: InputDataType.BOOLEAN }
                        :
                        null,
                { name: 'defaultPlacementPrice', label: t('DefaultPlacementPrice_release'), tooltip: t('DefaultPlacementPrice_release_tooltip'), validators: { validators: ['required'], errorMessages: [t('FieldRequired')] }, value: defaultPlacementPrice, updateValue: setDefaultPlacementPrice, badgeContent: data?.dealChanges?.defaultPlacementPrice, dataType: InputDataType.STRING, unit: 'percent' },
                // { name: 'usePlacementCoupon', label: t('UsePlacementCoupon'), tooltip: t('UsePlacementCoupon_tooltip'), validators: { validators: ['required'], errorMessages: [t('FieldRequired')] }, value: usePlacementCoupon, updateValue: setUsePlacementCoupon, badgeContent: data?.dealChanges?.usePlacementCoupon, dataType: InputDataType.BOOLEAN },

                couponType == CouponType.TYPE_FLOAT ?
                    { name: 'firstCouponExpensesIssueDoc', label: t('FirstCouponExpensesIssueDoc_release'), tooltip: t('FirstCouponExpensesIssueDoc_release_tooltip'), validators: { validators: ['required'], errorMessages: [t('FieldRequired')] }, value: firstCouponExpensesIssueDoc, updateValue: setFirstCouponExpensesIssueDoc, badgeContent: data?.dealChanges?.firstCouponExpensesIssueDoc, dataType: InputDataType.FLOAT, unit: 'percentInYear' }
                    : null,

                couponType == CouponType.TYPE_FLOAT ?
                    { name: 'otherCouponsExpensesIssueDoc', label: t('OtherCouponsExpensesIssueDoc_release'), tooltip: t('OtherCoupon   sExpensesIssueDoc_release_tooltip'), validators: { validators: ['required'], errorMessages: [t('FieldRequired')] }, value: otherCouponsExpensesIssueDoc, updateValue: setOtherCouponsExpensesIssueDoc, badgeContent: data?.dealChanges?.otherCouponsExpensesIssueDoc, dataType: InputDataType.FLOAT, unit: 'percentInYear' }
                    : null,

                couponType == CouponType.TYPE_FLOAT ?
                    { name: 'specDepRateIssueDoc', label: t('SpecDepRateIssueDoc_release'), tooltip: t('SpecDepRateIssueDoc_release_tooltip'), validators: { validators: ['required'], errorMessages: [t('FieldRequired')] }, value: specDepRateIssueDoc, updateValue: setSpecDepRateIssueDoc, badgeContent: data?.dealChanges?.specDepRateIssueDoc, dataType: InputDataType.FLOAT, unit: 'percentInYear' }
                    : null,

                couponType == CouponType.TYPE_FLOAT ?
                    { name: 'specDepMinMonthIssueDoc', label: t('SpecDepMinMonthIssueDoc_release'), tooltip: t('SpecDepMinMonthIssueDo_release_tooltip'), validators: { validators: ['required'], errorMessages: [t('FieldRequired')] }, value: specDepMinMonthIssueDoc, updateValue: setSpecDepMinMonthIssueDoc, badgeContent: data?.dealChanges?.specDepMinMonthIssueDoc, dataType: InputDataType.INT, unit: 'rubSign' }
                    : null,

                couponType == CouponType.TYPE_FLOAT ?
                    { name: 'specDepCompensationMonthIssueDoc', label: t('SpecDepCompensationMonthIssueDoc_release'), tooltip: t('SpecDepCompensationMonthIssueDoc_release_tooltip'), validators: { validators: ['required'], errorMessages: [t('FieldRequired')] }, value: specDepCompensationMonthIssueDoc, updateValue: setSpecDepCompensationMonthIssueDoc, badgeContent: data?.dealChanges?.specDepCompensationMonthIssueDoc, dataType: InputDataType.INT, unit: 'rubSign' }
                    : null,

                //DealBondParametersPanel
                // { name: 'couponType', label: t('CouponType'), tooltip: t('CouponType_tooltip'), value: couponType, updateValue: setCouponType, badgeContent: data?.iterationChanges?.couponType, dataType: InputDataType.ENUM, dataSource: couponTypes, validators: { validators: ['required'], errorMessages: [t('FieldRequired')] } },
                /*
                { name: 'couponPeriod', label: t('CouponPeriod'), tooltip: t('CouponPeriod_tooltip'), value: couponPeriod, updateValue: setCouponPeriod, badgeContent: data?.iterationChanges?.couponPeriod, dataType: InputDataType.ENUM, dataSource: couponPeriods },
                { name: 'cleanUpCall', label: t('CleanUpCall'), tooltip: t('CleanUpCall_tooltip'), value: cleanUpCall, updateValue: setCleanUpCall, badgeContent: data?.iterationChanges?.cleanUpCall, dataType: InputDataType.ENUM, dataSource: cleanUpCalls }
                */
            ]
        };

        const dealStructure = {
            // name: 'DealStructurePanel', label: t('DealStructure'), fields: [
            //     // { name: 'swapType', label: t('SwapTypes'), tooltip: t('SwapTypes_tooltip'), value: swapType, updateValue: setSwapType, badgeContent: data?.dealChanges?.swapType, dataType: InputDataType.ENUM, dataSource: swapTypes, validators: { validators: ['required'], errorMessages: [t('FieldRequired')] } },
            //     // { name: 'swapMA', label: t('SwapMA'), tooltip: t('SwapMA_tooltip'), value: swapMA, updateValue: setSwapMA, badgeContent: data?.iterationChanges?.swapMA, dataType: InputDataType.BOOLEAN },
            //     // { name: 'fixDayMA', label: t('FixDayMA'), tooltip: t('FixDayMA_tooltip'), value: fixDayMA, updateValue: setFixDayMA, badgeContent: data?.dealChanges?.fixDayMA, dataType: InputDataType.FLOAT },
            //     // { name: 'floatDayMA', label: t('FloatDayMA'), tooltip: t('FloatDayMA_tooltip'), value: floatDayMA, updateValue: setFloatDayMA, badgeContent: data?.dealChanges?.floatDayMA, dataType: InputDataType.FLOAT },
            //     { name: 'swapORIG', label: t('SwapORIG'), tooltip: t('SwapORIG_tooltip'), value: swapORIG, updateValue: setSwapORIG, badgeContent: data?.iterationChanges?.swapORIG, dataType: InputDataType.BOOLEAN },
            //     // { name: 'fixDayORIG', label: t('FixDayORIG'), tooltip: t('FixDayORIG_tooltip'), value: fixDayORIG, updateValue: setFixDayORIG, badgeContent: data?.dealChanges?.fixDayORIG, dataType: InputDataType.FLOAT },
            //     { name: 'floatDayOrig', label: t('FloatDayOrig'), tooltip: t('FloatDayOrig_tooltip'), value: floatDayOrig, updateValue: setFloatDayOrig, badgeContent: data?.dealChanges?.floatDayOrig, dataType: InputDataType.FLOAT }
            // ]
        };

        const poolIdentificators = {
            name: 'PoolIdentifiersPanel', label: t('IssueIdentifiers'), fields: [
                { name: 'selectedOriginator', label: t('Originator'), tooltip: t('releaseOriginator_tooltip'), value: selectedOriginator, updateValue: setSelectedOriginator, badgeContent: data?.dealChanges?.originatorID, dataType: InputDataType.ENUM, dataSource: originators },
                { name: 'name', label: t('InnerName'), tooltip: t('releaseDeal_tooltip'), value: dealName, updateValue: setDealName, badgeContent: data?.dealChanges?.name, dataType: InputDataType.STRING }
            ]
        };

        const statistics = {
            name: 'PoolStatisticsAtSignDatePanel', label: t('PoolStatisticsAtSignDate'), fields: [
                { name: 'inclusionDebtDate', label: t('InclusionDebtDate_release'), tooltip: t('InclusionDebtDate_release_tooltip'), validators: { validators: ['required'], errorMessages: [t('FieldRequired')] }, value: inclusionDebtDate, updateValue: setInclusionDebtDate, badgeContent: data?.dealChanges?.inclusionDebtDate, dataType: InputDataType.DATE },
                { name: 'fixationWAC', label: t('WAC'), tooltip: t('releaseWAC_tooltip'), unit: 'percentInYear', validators: { validators: ['required'], errorMessages: [t('FieldRequired')] }, value: fixationWAC, updateValue: setFixationWAC, badgeContent: data?.dealChanges?.WAC, dataType: InputDataType.FLOAT, },
                { name: 'fixationWALA', label: t('WALA'), tooltip: t('releaseWALA_tooltip'), unit: 'yearSign', validators: { validators: ['required'], errorMessages: [t('FieldRequired')] }, value: fixationWALA, updateValue: setFixationWALA, badgeContent: data?.dealChanges?.WALA, dataType: InputDataType.FLOAT, },
                { name: 'fixationWAM', label: t('WAM'), tooltip: t('releaseWAM_tooltip'), unit: 'months', validators: { validators: ['required'], errorMessages: [t('FieldRequired')] }, value: fixationWAM, updateValue: setFixationWAM, badgeContent: data?.dealChanges?.WAM, dataType: InputDataType.FLOAT, },
                { name: 'fixationAdjustedWAM', label: t('AdjustedWAMShort'), unit: 'months', tooltip: t('releaseAdjustedWAM_tooltip'), validators: { validators: ['required'], errorMessages: [t('FieldRequired')] }, value: fixationAdjustedWAM, updateValue: setFixationAdjustedWAM, badgeContent: data?.dealChanges?.AdjustedWAM, dataType: InputDataType.FLOAT, },

            ]
        };

        const actOfDataTransfer = {
            name: 'DealActOfTransferDataPanel', label: t('ActOfTransferData'), fields: [

                { name: 'actDeliveryDebt', label: t('ActDeliveryDebt'), tooltip: t('releaseActDeliveryDebt_tooltip'), unit: 'rubSign', validators: { validators: ['required'], errorMessages: [t('FieldRequired')] }, value: actDeliveryDebt, updateValue: setActDeliveryDebt, badgeContent: data?.dealChanges?.actDeliveryDebt, dataType: InputDataType.FLOAT },
                { name: 'actDeliveryYield', label: t('ActDeliveryYield'), tooltip: t('releaseActDeliveryYield_tooltip'), unit: 'rubSign', validators: { validators: ['required'], errorMessages: [t('FieldRequired')] }, value: actDeliveryYield, updateValue: setActDeliveryYield, badgeContent: data?.dealChanges?.actDeliveryYield, dataType: InputDataType.FLOAT },
                { name: 'actDeliveryPremium', label: t('Award'), tooltip: t('releaseAward_tooltip'), unit: 'rubSign', validators: { validators: ['required'], errorMessages: [t('FieldRequired')] }, value: actDeliveryPremium, updateValue: setActDeliveryPremium, badgeContent: data?.dealChanges?.actDeliveryPremium, dataType: InputDataType.FLOAT, customProps: { pattern: '[0-9\,\-]*', } }
            ]
        };

        // const otherParameters = {
        //     name: 'DealOtherParametersPanel', label: t('OtherParameters'), fields: [

        //         { name: 'correctReportsStartDate', label: t('CorrectReportsStartDate'), tooltip: t('CorrectReportsStartDate_tooltip'), value: correctReportsStartDate, updateValue: setCorrectReportsStartDate, badgeContent: data?.dealChanges?.correctReportsStartDate, dataType: InputDataType.DATE },
        //         { name: 'productID', label: t('Product'), tooltip: t('Product_tooltip'), value: productID, updateValue: setProductID, badgeContent: data?.dealChanges?.productID, dataType: InputDataType.ENUM, dataSource: products }
        //     ]
        // };

        /*
        const evaluationParameters = {
            name: 'EvaluationParametersPanel', label: t('EvaluationParameters'), fields: [

                { name: 'poolExpectedLoss', label: t('PoolExpectedLoss'), tooltip: t('releasePoolExpectedLoss_tooltip'), value: poolExpectedLoss, updateValue: setPoolExpectedLoss, badgeContent: data?.dealChanges?.poolExpectedLoss, dataType: InputDataType.FLOAT },
                { name: 'opex', label: t('OperationalExpenses'), tooltip: t('releaseOperationalExpenses_tooltip'), value: opex, updateValue: setOPEX, badgeContent: data?.dealChanges?.opex, dataType: InputDataType.FLOAT },
                { name: 'poolCapitalMargin', label: t('PoolCapitalMargin'), tooltip: t('releasePoolCapitalMargin_tooltip'), value: poolCapitalMargin, updateValue: poolCapitalMargin, badgeContent: data?.dealChanges?.poolCapitalMargin, dataType: InputDataType.FLOAT },
                { name: 'poolPrepaymentOption', label: t('PoolPrepaymentOption'), tooltip: t('releasePoolPrepaymentOption_tooltip'), value: poolPrepaymentOption, updateValue: setPoolPrepaymentOption, badgeContent: data?.dealChanges?.poolPrepaymentOption, dataType: InputDataType.FLOAT },
                { name: 'poolBankMargin', label: t('PoolBankMargin'), tooltip: t('releasePoolBankMargin_tooltip'), value: poolBankMargin, updateValue: setPoolBankMargin, badgeContent: data?.dealChanges?.poolBankMargin, dataType: InputDataType.FLOAT },
            ]
        };
        */

        switch (data?.deal?.type) {
            case DealType.TYPE_BOND_PUBLISH:
                {
                    return [bondIdentificators, statistics, actOfDataTransfer, parameters];
                }
                break;
            case DealType.TYPE_BOND_AT_BALANCE:
                {
                    return [bondIdentificators];
                }
                break;
            case DealType.TYPE_POOL_BUYOUT:
                {
                    return [poolIdentificators];
                }
                break;
            default:
                {
                    throw "Unknown deal type!";
                }
        }
    }

    const groups = formEditFields();
    // console.log('groups*************', groups)
    //const handleKeyPress = (event) => {
    //    if (event.keyCode == 13) {
    //        onFieldLeft();
    //    }
    //}
    const getBadgeContent = (updFields = {}, row = {}) => {
        if (Object.hasOwn(updFields, row?.name)) {
            // if (updFields[row?.name]?.userID !== Globals.user.id)
            if (updFields[row?.name]?.userID != null)
                return updFields[row?.name]
        }
        // else
        //     if (row?.badgeContent?.userID !== Globals.user.id ) {
        //         // {console.log('row', row.name, !row?.badgeContent?.userID, row?.badgeContent)}
        //         // if (!row?.badgeContent?.userID) return ({ userName: 'UNDEFINED' })
        //         if (row.dataSource != null) {
        //             var content = { ...row?.badgeContent };
        //             var index = row.dataSource.findIndex(x => x.key == content.oldValue);
        //             if (index != -1) {
        //                 content.oldValue = row.dataSource[index].value;
        //                 return content;
        //             }
        //         }

        //         return row?.badgeContent
        //     }

        return {}
    }

    return (
        <ValidatorForm onSubmit={() => { }} ref={submitForm} validators={[validateFormFields]} onFieldChanged={onFieldChanged} onFormStateChanged={onFormStateChanged}>
            <Box className={classes.rootContainer}>
                {
                    groups?.filter(x => x != null)?.map((group) => {
                        if (group?.fields != null)
                            return (
                                <React.Fragment key={group.name + '_id'}>
                                    {/* {console.log('group.name', group.name)} */}
                                    <Box key={group.name + '_block'} className={classes.Block}>
                                        {!!group?.label &&
                                            <Box className={classes.blockName}>
                                                {group.label}
                                            </Box>}
                                        {
                                            group?.fields?.filter(x => x != null)?.map((row, index) => {

                                                const classColor = index % 2 != 0 ? classes.whiteColor : classes.grayColor;

                                                return (
                                                    row.visible === false ? null : (
                                                        <Box key={row.name + '_id'}
                                                            className={[classes.rowBlock,].join(' ')}
                                                        >
                                                            <Box className={classes.rowName}>
                                                                <CustomTooltip
                                                                    id={`${row.name}-field-id`}
                                                                    content={row.tooltip}
                                                                    hideIcon
                                                                    placement="right-start"
                                                                    sx={{ ml: 1, pb: '4px' }}
                                                                >
                                                                    {row.label}
                                                                </CustomTooltip>
                                                            </Box>
                                                            <Box className={classes.rowValuesBlock}>
                                                                {(permissions?.isAdmin || permissions?.isManager) && row.labelButton != null &&
                                                                    row.labelButton}
                                                                <Box className={classes.valueData} sx={row?.width != null ? { minWidth: `${row.width} !important` } : {}}>
                                                                    <UniversalInputField
                                                                        needConfirm
                                                                        dataType={row.dataType}
                                                                        name={row.name}
                                                                        disabled={row?.disabled == true /*|| row?.readOnly*/}
                                                                        readOnly={!isEdit && !row?.readOnly || !(permissions?.isAdmin || permissions?.isManager)}
                                                                        value={row.value}
                                                                        fullWidth={true}
                                                                        fieldProps={row.fieldProps}
                                                                        handleValueChange={(name, value) => updateField(name, row.updateValue, value)}
                                                                        //onBlur={() => onFieldLeft()}
                                                                        //onKeyUp={(event) => handleKeyPress(event)}
                                                                        inputProps={row?.customProps}
                                                                        dataSource={row.dataSource}
                                                                        {...row.validators}
                                                                        disableCloseOnSelect={false}
                                                                        hideValidationIcon={true}
                                                                        sx={{
                                                                            '& .Mui-disabled:before': { borderBottomStyle: 'solid !important', borderBottomColor: `${alpha(style.colorPrimary3, 0.5)} !important` },
                                                                            '& .MuiInput-root:hover:not(.Mui-disabled):before': { borderBottomColor: `${alpha(style.colorPrimary3, 0.5)} !important` },
                                                                            '& .MuiInput-underline:before': { borderBottomColor: `${alpha(style.colorPrimary3, 0.5)} !important` },
                                                                            // '& .MuiInput-underline:after': { borderBottomColor: `${alpha(style.colorPrimary3, 0.5)}` },
                                                                            '& .Mui-focused:not(.Mui-readOnly):after': !row?.readOnly ? { border: `solid 2px ${alpha(style.colorPrimary2, 1)} !important`, top: '1px' } : {},
                                                                            '& .Mui-error:after': { border: `none` },
                                                                            '& .Mui-readOnly': { cursor: 'text', '& *': { cursor: 'text' } },
                                                                            '& .Mui-readOnly:after': { border: `none` },
                                                                            '& .Mui-readOnly:before': { border: `none !important` },
                                                                            '& .Mui-readOnly:hover:before': { border: `none` },
                                                                        }}
                                                                    />
                                                                </Box>
                                                                <Box className={classes.valueUnit}>
                                                                    {row?.unit ? t(row.unit) : ''}
                                                                </Box>
                                                                <Box className={classes.valueAuthor}>
                                                                    {(permissions?.isAdmin || permissions?.isManager) &&
                                                                        <ChipAuthor content={getBadgeContent({ ...data?.dealChanges, ...data.onlineDealChanges }, row)} />
                                                                    }
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    )
                                                )
                                            })
                                        }
                                    </Box>
                                </React.Fragment>

                            )
                    })
                }
            </Box>
        </ValidatorForm>
    )
}

export default IntegralIdentificatorsPanel;
