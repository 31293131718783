import React, { useRef, useState, useEffect, useMemo, useLayoutEffect } from 'react';
import stringInject from 'stringinject';
import Localization from '@Components/Localization';
import style from '@Styles/domrf-theme.module.scss';
import { makeStyles } from '@mui/styles';
import { useTranslation } from "react-i18next";
import { IconButton, Tooltip, Box, Typography, Divider, alpha, Paper, Button } from '@mui/material';
import { FileCopyOutlined, SaveOutlined, SsidChart, ZoomInOutlined, ZoomOutOutlined } from '@mui/icons-material';
import { copyTextToClipboard, roundDown, roundUp } from '@Utilities';
import TooltipInfoLabel from '@Components/shared/TooltipInfoLabel';
import useMobileDetect from '@Components/hook/useMobileDetect';
import { CouponType, FlowTypes } from '@Components/Definitions';
// import Highcharts from 'highcharts/highstock';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HC_patternFill from "highcharts-pattern-fill";
import { useSelector } from 'react-redux';
import HC_exporting from 'highcharts/modules/exporting';
import CustomTooltip from '@Base/components/shared/CustomTooltip';
import HC_more from "highcharts/highcharts-more"; //module
import ZCYCDialog from '../components/ZCYCDialog';
import SpinnerBars from '@Base/components/shared/SpinnerBars';
import { useDispatch } from 'react-redux';
import { actionCreators as actionConvention } from '@Base/store/ConventionStore'

const useStyles = makeStyles({
    textIcon: {
        fontSize: '0.8rem !important',
        textTransform: 'initial',
        padding: '0 0.5rem !important',
        minWidth: '40px !important',
        letterSpacing: 'initial',
        color: style.colorPrimary3,
        fontFamily: 'DINPro, Helvetica',
        fontWeight: 100,
    },
    wideTooltip: {
        maxWidth: '400px !important'
    },
    coverLayout: {
        position: 'absolute',
        left: 0, top: 0, bottom: 0, right: 0,
        zIndex: '+100',
        backgroundColor: 'rgba(255, 255, 255, 0.5)',

    },
    root: {
        // marginRight: '0.2rem',
        // marginLeft: '0.2rem',
        // marginTop: '0.5rem',
        // height: '421px',
        // position: 'relative',
        position: 'absolute',
        left: '0.2rem', top: '0.5rem', bottom: 0, right: '0.2rem',
        // maxHeight:'375px',
        '& $panelHeader *': {
            fontSize: '1rem'
        }
    },
    rootPaper: {
        borderRadius: '0.5rem',
        border: `solid 1px ${style.colorPrimary3_40}`,
        position: 'absolute',
        left: 0, top: 0, bottom: 0, right: 0,
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        justifyContent: 'flex-start',
        overflow: 'hidden',
    },
    panelHeader: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignContent: 'center',
        alignItems: 'center',
        height: '2rem !important',
        minHeight: '2rem !important',
        overflow: 'hidden',
        borderBottom: `solid 1px ${style.colorPrimary3_40}`,
        padding: '0 0.2rem 0 0.5rem',
        justifyContent: 'space-between',
        '& *': { userSelect: 'none', },
    },
    panelHeaderTitle: {
        textTransform: 'uppercase',
        color: style.colorPrimary3_40,
        whiteSpace: 'nowrap',
    },
    panelIconsBox: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignContent: 'center',
        alignItems: 'center',
    },
    icon: {
        width: '23px',
        height: '23px',
        // color: style.colorPrimary3_80
    },
    panelData: {
        margin: '0.8rem',
        overflow: 'hidden',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        wlexWrap: 'nopwrap',
    },
    tooltip: {
        '& *': { fontSize: '0.8rem !important' },
        // '&>span': {
        //     outline: 'solid',
        //     border: 'solid 1px #000',
        // }
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    }
});

interface IProp {
    // showHistoryFlows: any;
}


const FlowsChartPanel = ({ }: IProp) => {
    HC_patternFill(Highcharts)
    HC_exporting(Highcharts)
    HC_more(Highcharts)

    const cls = useStyles();
    const { t, i18n } = useTranslation();
    const device = useMobileDetect();
    const flowChartRef = useRef();
    const [bondResults, setBondResults] = useState(null)
    const [showZCYCChart, setShowZCYCChart] = useState(false)
    const {
        selectedBond = null, pricingParameters = null, mbsCashflowGraph = null, showHistoryFlows = false,
        zcycGraph = null, pricingResult = null, calculatedParameters = null,
        loading = false,
        moexSecurities = null,
        ...otherStore } = useSelector((state: any) => state.convention)

    // const { ...otherDom } = useSelector((state: any) => state.domrf)
    // console.log('otherDom', moexSecurities)
    const dispatch = useDispatch()

    const cpr = pricingParameters?.CPR
    const cdr = pricingParameters?.CDR
    // console.log('CalculatedParameters?.nextCouponDate', CalculatedParameters)
    // console.log('PricingParameters', PricingParameters)
    // console.log(' mbsCashflowGraph',  mbsCashflowGraph)
    // console.log('zcycGraph', zcycGraph)
    const thisRef = useRef({ pricingParameters, selectedBond })

    useEffect(() => { //ref for access from hicharts to vars
        thisRef.current = { pricingParameters, selectedBond }
    }, [pricingParameters, selectedBond])

    const [flowsChartOptions, setFlowsChartOptions] = useState({
        exporting: {
            enabled: false,
            filename: '',
            chartOptions: {
                // title: {
                //     text:  t('ConventionNameCashflowGraphName')
                // },
                legend: {
                    align: 'center',
                    verticalAlign: 'bottom',
                    itemDistance: 40,
                    itemStyle: {
                        fontSize: '7px',
                        overflow: 'hidden',
                    },
                    floating: false
                }
            },

        },

        defs: {
            patterns: [{
                id: 'left-slash-pattern',
                path: {
                    d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
                    stroke: style.colorPrimary3_60,
                    strokeWidth: 1
                }
            },
            {
                id: 'left-white-slash-pattern',
                path: {
                    d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
                    stroke: 'white',
                    strokeWidth: 1,
                    fill: style.colorPrimary1_40
                }
            },
            {
                id: 'left-white-green-slash-pattern',
                path: {
                    d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
                    stroke: 'white',
                    strokeWidth: 1,
                    fill: style.colorPrimary2_40
                }
            },
            {
                id: 'dotted-pattern',
                path: {
                    d: "M 0 0 L 10 0 M 0 5 L 10 5 M 0 10 L 10 10",
                    stroke: style.colorPrimary3_60,
                    strokeWidth: 1
                }
            }
            ]
        },
        tooltip: {
            animation: false,
            shape: "square",
            hideDelay: 0,
            // shared: true,
            useHTML: true,
            borderColor: style.colorPrimary3_60,
            formatter: function (a) {
                // console.log('thisRef', thisRef.current)
                const pricingDate = thisRef?.current?.pricingParameters?.pricingDate
                if (pricingDate == null)
                    return false

                var point = this.point;
                const mp = 60 * 60 * 24 * 1000 * 365
                // const duration = roundDown((this.x - new Date(pricingDate)?.getTime() ) / mp, 0.1)
                const duration = (this.x - new Date(pricingDate)?.setHours(0, 0, 0, 0)) / mp
                // console.log('this.x', this.x, new Date(pricingDate)?.setHours(0, 0, 0, 0))
                switch (point?.series?.name) {
                    case 'MOEXBonds':
                        // console.log('this', this)
                        return '<table>' +
                            '<tr><td colspan=2><b>' + this.point.label + '</b></td></tr>' +
                            '<tr><td style="padding:0">' + Localization.Translate('Convention2_Duration') + ': </td>' +
                            '<td style="padding:0;text-align:end"><b>' + Localization.FormatNumber(duration) + '</b></td></tr>' +
                            '<tr><td style="padding:0">' + Localization.Translate('Convention2_Profitability') + ': </td>' +
                            '<td style="padding:0;text-align:end"><b>' + Localization.FormatNumber(this.y) + '</b></td></tr>' +
                            '</table>'

                    case 'ZCYC_MACALEY':
                        return '<table style="min-width:150px;max-width:150px">' +
                            thisRef?.current?.selectedBond?.ticker + '<br>' +
                            t(`Convention2_Duration`) + ': <b>' + Localization.FormatNumber(duration, 2, 2) + '</b>' + '<br>' +
                            t(`Convention2_Profitability`) + ': <b>' + Localization.FormatNumberString(this.y) + '</b>' +
                            '</table>'

                    default:
                        return '<table style="min-width:150px;max-width:150px">' +
                            t(`Convention2_${point?.series?.name}`) + '<br>' +
                            t(`Convention2_Duration`) + ': <b>' + Localization.FormatNumber(duration, 1, 1) + '</b>' + '<br>' +
                            t(`Convention2_Profitability`) + ': <b>' + Localization.FormatNumberString(this.y) + '</b>' +
                            // + Localization.FormatDateLocale(new Date(this.x)) + ': <b>' + Localization.FormatNumberString(this.y) + '</b>' +
                            '</table>'
                }
                return false
            },
            positioner: function (labelWidth, labelHeight, point) {
                return {
                    x: this.chart.plotLeft + 5,//this.chart.plotWidth - labelWidth - 3,
                    y: this.chart.plotTop + 5//this.chart.plotHeight - labelHeight - 3
                };
                return {
                    x: this.chart.plotWidth - labelWidth - 15 + this.chart.marginRight,
                    y: this.chart.plotHeight - labelHeight - 5
                };
            },
        },
        plotOptions: {
            series: {
                animation: false,
                pointPadding: 0,
                groupPadding: 0,
                marker: {
                    symbol: 'circle', // Set the marker symbol to 'circle'
                    radius: 2,
                    // halo: false
                    // shadow: {
                    //     color: 'rgba(0, 0, 0, 0.3)', // Adjust the color of the marker shadow
                    //     offsetX: 0, // Adjust the X offset of the marker shadow
                    //     offsetY: 0, // Adjust the Y offset of the marker shadow
                    //     opacity: 0.5, // Adjust the opacity of the marker shadow
                    //     width: 0 // Adjust the width of the marker shadow
                    // }
                },
                states: {
                    hover: {
                        halo: {
                            size: 0
                        }
                    }
                },
                events: {
                    legendItemClick: function () {
                        return false; // Disable legend click
                    }
                }
            },
            scatter: {
                color: style.colorPrimary2, // Set the color for all points
                // enableMouseTracking: true,
                zIndex: 10,
                marker: {
                    radius: 6,
                    lineWidth: 1,
                    lineColor: style.colorPrimary3,
                    symbol: 'circle' // Set the shape for all points
                },
                states: {
                    hover: {
                        enabled: true,
                        halo: {
                            size: 0
                        }
                    }
                },
            },
            // columnrange: {
            //     zIndex: 1,
            //     // crisp: false,
            //     // stacking: 'normal',
            //     // pointPlacement: 'on',
            //     pointPlacement: 'between',
            //     // stacking: 'normal', // Disable stacking for columnrange series
            //     states: {
            //         hover: {
            //             enabled: false
            //         }
            //     }
            // },

            column: {
                tickPosition: 'between',
                crisp: false,
                stacking: true,
                pointPlacement: 'between',
                // pointRange: 1,
                pointRange: 24 * 3600 * 1000 * 31, // One day * 31
                enableMouseTracking: false,
                // maxPointWidth: 1000000,
                states: {
                    inactive: {
                        opacity: 0.2
                    },
                    ///     hover: {
                    //         //opacity: 1.5,
                    //         brightness: -0.2
                    //         //color: 'rgba(68, 188, 93, 1)'
                },
            },
            line: {
                pointPlacement: 'on',
                stacking: null,
            }

        },
        legend: {
            margin: 0,
            y: 10,
            itemStyle: {
                cursor: 'default'
            },
        },
        chart: {
            // plotAreaHeight: 250,
            // marginBottom: 50 ,
            unbindReflow: () => this,
            // reflow: true,
            // plotBorderWidth: 1,
            // plotBorderColor: alpha(style.colorPrimary3, 0.15),
            // allowMutatingData: true,
            animation: false,
            marginLeft: 34,
            marginRight: 34,
            events: {
                render: (e) => {
                    if (e.target?.callback)
                        e.target?.callback(e.target)
                    if (e.target?.legend) {
                        Highcharts.objectEach(e.target.legend.allItems, function (el) {
                            // console.log('el', el)
                            if (el.legendSymbol && el.name == t("HistoricalAmortization"))
                                el.legendSymbol.element.style.stroke = "#cccccc";
                        })
                    }
                    // console.log('e', e)
                    // x.options.dataLabels.events.init=()=>{console.log('intEv')}
                }
            }
        },
        title: {
            text: '',
            //style: { "color": "#2F444E", "fontSize": "18px", "fontWeight": "bold" }
        },
        xAxis: [{
            type: 'datetime',
            crosshair: false,
            gridLineWidth: 1,
            gridLineColor: alpha(style.colorPrimary3, 0.1),
            // endOnTick:true,
            // startOnTick:true,
            zoomEnabled: false,
            lineWidth: 1,
            lineColor: alpha(style.colorPrimary3, 0.5),
            title: {
                enabled: false
            },
            labels: {
                style: {
                    color: style.colorPrimary3
                }
            },
            // tickmarkPlacement: 'between',
            startOnTick: false,
            endOnTick: false,
        },
            // {
            //     type: 'datetime',
            //     visible: false,
            //     crosshair: false,
            //     gridLineWidth: 0,
            //     lineWidth: 0,
            //     zoomEnabled: false,
            //     // tickmarkPlacement: 'between',
            //     startOnTick: false,
            //     endOnTick: false,
            // }
        ],
        yAxis: [{
            gridLineColor: alpha(style.colorPrimary3, 0.1),
            lineWidth: 1,
            lineColor: alpha(style.colorPrimary3, 0.5),
            title: {
                text: t('Convention2_NameCashflowGraphRubles'),
                offset: 21,
            },
            labels: {
                fontSize: '0.8rem',
                x: -4,
                style: {
                    color: style.colorPrimary3
                }
            },
            reversedStacks: false,
            min: 0,
            max: 80,
            // startOnTick: false,
            // endOnTick: false,
        },
        {
            visible: false,
            lineWidth: 1,
            lineColor: alpha(style.colorPrimary3, 0.5),
            gridLineWidth: 0,
            title: {
                text: t('percentInYear'),
                offset: 21,
            },
            labels: {
                fontSize: '0.8rem',
                x: 4, // Adjust the margin near the axis values
                style: {
                    color: style.colorPrimary3
                }
            },
            reversedStacks: false,
            min: 7,
            max: 15,
            opposite: true,
            // startOnTick: false,
            // endOnTick: false,
            // style: {
            //     fontSize: '8px !important',    // Set the font size
            // },
        }]
        ,

        // legend: {
        //     enabled: false,

        //     // itemStyle:{"color":"#F00"}
        //     // borderColor:'#CCCCCC',
        //     // borderWidth:20,
        //     // itemStyle :{'border':'solid 1px #CCC'}
        // },

        // tooltip: {
        //     animation: false,
        //     shape: "square",
        //     className: cls.tooltip,
        //     borderColor: '#000',
        //     hideDelay: 0,
        //     followPointer: true,
        //     // shared: true,
        //     useHTML: true,
        //     positioner: function (labelWidth, labelHeight, point) {
        //         return {
        //             x: this.chart.plotWidth - labelWidth - 15 + this.chart.marginRight,
        //             y: this.chart.plotHeight - labelHeight - 5
        //         };
        //     },
        // },

        credits: {
            enabled: false
        },

        series: [
            {
                name: t('Convention2_NameCashflowGraphRealPayment'),
                color: 'url(#left-white-slash-pattern)',
                data: null,
                yAxis: 0,
                xAxis: 0,
                type: 'column',
                legendIndex: 3,
                clickable: false,
                events: {
                    /* checkboxClick:function(){
                         return false
                     },
                     legendItemClick:function(){
                         return false
                     }*/
                }
            },
            {
                name: t('Convention2_NameCashflowGraphPlan'),
                color: style.colorPrimary1_40,
                data: null,
                yAxis: 0,
                xAxis: 0,
                type: 'column',
                legendIndex: 5,
                clickable: false,
                events: {
                    /* checkboxClick:function(){
                         return false
                     },
                     legendItemClick:function(){
                         return false
                     }*/
                }
            },
            {
                name: t('Convention2_NameCashflowGraphDefault'),
                color: style.colorSecondary1,
                data: null,
                yAxis: 0,
                xAxis: 0,
                type: 'column',
                legendIndex: 6,
                clickable: false,
                events: {
                    /* checkboxClick:function(){
                         return false
                     },
                     legendItemClick:function(){
                         return false
                     }*/
                }
            },
            {
                name: t('Convention2_NameCashflowGraphPrepay'),
                color: style.colorPrimary3_40,
                data: null,
                yAxis: 0,
                xAxis: 0,
                type: 'column',
                legendIndex: 7,
                clickable: false,
                events: {
                    /* checkboxClick:function(){
                         return false
                     },
                     legendItemClick:function(){
                         return false
                     }*/
                }
            },

            {
                name: t('Convention2_NameCashflowGraphRealCoupon'),
                color: 'url(#left-white-green-slash-pattern)',
                // borderColor: '#CCC',
                data: null,
                yAxis: 0,
                xAxis: 0,
                type: 'column',
                legendIndex: 4,
                clickable: false,
                events: {
                    /* checkboxClick:function(){
                         return false
                     },
                     legendItemClick:function(){
                         return false
                     }*/
                }
            },
            {
                name: t('Convention2_NameCashflowGraphCoupon'),
                color: style.colorPrimary2_40,
                data: null,
                yAxis: 0,
                xAxis: 0,
                type: 'column',
                legendIndex: 8,
                clickable: false,
                events: {
                    /* checkboxClick:function(){
                         return false
                     },
                     legendItemClick:function(){
                         return false
                     }*/
                }
            },
            {
                name: t('Convention2_NameCashflowGraphCleanUp'),
                data: null,
                yAxis: 0,
                xAxis: 0,
                type: 'column',
                legendIndex: 9,
                color: 'url(#left-slash-pattern)',
                events: {
                    /* checkboxClick:function(){
                        return false
                    },
                    legendItemClick:function(){
                        return false
                    }*/
                }
            },
            {
                name: t('Convention2_NameHistoricalAmortization'),
                color: 'white',
                visible: false,
                showInLegend: false,
                data: null,
                yAxis: 0,
                xAxis: 0,
                type: 'column',
                borderWidth: 1,
                borderColor: style.colorPrimary3,
                legendIndex: 1,
                clickable: false
                // states: {
                //     hover: {
                //         enabled: false
                //     },
                //     inactive: {
                //         enabled: false
                //     },
                //     normal: {
                //         enabled: false
                //     },
                //     select: {
                //         enabled: false
                //     }
                // }
            },
            {
                name: t('Convention2_NameHistoricalCoupon'),
                color: 'url(#dotted-pattern)',
                visible: false,
                showInLegend: false,
                data: null,
                yAxis: 0,
                xAxis: 0,
                type: 'column',
                borderWidth: 1,
                borderColor: style.colorPrimary3,
                legendIndex: 2,
                clickable: false,
                //  states: {
                //      hover: {
                //          enabled: false
                //      },
                //     inactive: {
                //         enabled: false
                //     },
                //     normal: {
                //         enabled: false
                //     },
                //     select: {
                //         enabled: false
                //     }
                //  }
            },
            {
                name: 'ZCYC_CHART',
                type: 'arearange',
                zIndex: 1,
                data: null,
                yAxis: 1,
                xAxis: 0,
                lineWidth: 0,
                // lineColor: style.colorPrimary1_40,
                fillColor: {
                    linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                    stops: [
                        [0, 'rgba(255, 255, 255, 0)'], // Start color (transparent)
                        [1, 'rgba(255, 255, 255, 0)'] // End color (transparent)
                    ]
                },
                fillOpacity: 0.3,
                showInLegend: false,
                stacking: null,
                visible: true,
                marker: {
                    enabled: false,
                },
                states: {
                    hover: {
                        enabled: false
                    },
                    inactive: {
                        enabled: false
                    },
                    normal: {
                        enabled: false
                    },
                    select: {
                        enabled: false
                    }
                }
            },
            {
                name: 'ZCYC_CHART_VALUE',
                type: 'line',
                zIndex: 1,
                data: null,
                yAxis: 1,
                xAxis: 0,
                lineWidth: 1,
                color: style.colorPrimary1_40,
                dashStyle: 'Solid',
                showInLegend: false,
                visible: true,
                marker: {
                    enabled: false,
                },
                // states: {
                //     hover: {
                //         enabled: false
                //     },
                //     inactive: {
                //         enabled: false
                //     },
                //     normal: {
                //         enabled: false
                //     },
                //     select: {
                //         enabled: false
                //     }
                // }
            },
            {
                name: 'ZCYC_CHART_ZSPREAD',
                type: 'line',
                zIndex: 1,
                data: null,
                yAxis: 1,
                xAxis: 0,
                lineWidth: 1,
                color: style.colorPrimary3,
                zoneAxis: 'x',
                dashStyle: 'Dash', // Set the line style to long dashed
                showInLegend: false,
                visible: true,
                marker: {
                    enabled: false,
                },
                // states: {
                //     hover: {
                //         enabled: false
                //     },
                //     inactive: {
                //         enabled: false
                //     },
                //     normal: {
                //         enabled: false
                //     },
                //     select: {
                //         enabled: false
                //     }
                // }
            },
            {
                name: 'ZCYC_MACALEY',
                type: 'scatter',
                zIndex: 2,
                data: null,
                yAxis: 1,
                xAxis: 0,
                // lineWidth: 1,
                color: style.colorPrimary2,
                enableMouseTracking: true,
                showInLegend: false,
                dataLabels: {
                    enabled: false
                },
            },
            {
                id: 'MOEXBonds',
                name: 'MOEXBonds',
                type: 'scatter',
                color: 'white',
                showInLegend: false,
                cursor: 'pointer',
                xAxis: 0,
                yAxis: 1,
                dataLabels: {
                    enabled: false
                },
                marker: {
                    enabled: true,
                    symbol: 'circle',
                    lineColor: style.colorPrimary3_80,
                    lineWidth: 1,
                    radius: 4,
                },
                data: null,
                zIndex: 201
            }
        ]
    })

    const onResize = () => {
        if (flowChartRef?.current != null) {
            flowChartRef.current?.chart?.reflow()
        }
    }

    useLayoutEffect(() => {
        let flow = []
        // console.log('mbsCashflowGraph', mbsCashflowGraph)
        if (mbsCashflowGraph != null)
            Object.entries(mbsCashflowGraph?.graphCouponDates).forEach((date, index) => {
                if (showHistoryFlows === false && mbsCashflowGraph?.graphCashflowType == FlowTypes.USED_HISTORY)
                    return

                flow.push({
                    couponDate: mbsCashflowGraph?.graphCouponDates[index] ?? 0,
                    bondAmortization: mbsCashflowGraph?.graphBondHisroticalAmortization[index] ?? 0,
                    bondCoupon: mbsCashflowGraph?.graphBondHistoricalCouponPayments[index] ?? 0,

                    realBondPayments: mbsCashflowGraph?.graphBondFutureActualAmortization[index] ?? 0,
                    modelBondPrepayment: mbsCashflowGraph?.graphBondFutureModelPrepayment[index] ?? 0,
                    flowType: mbsCashflowGraph?.graphCashflowType[index] ?? 0,
                    modelBondDefault: mbsCashflowGraph?.graphBondFutureModelDefaults[index] ?? 0,
                    modelBondCleanUp: mbsCashflowGraph?.graphBondFutureModelCleanUp[index] ?? 0,
                    modelBondCoupon: mbsCashflowGraph?.graphBondFutureModelCouponPayments[index] ?? 0,
                    modelBondPlan: mbsCashflowGraph?.graphBondFutureModelScheduled[index] ?? 0,
                    realBondCoupons: mbsCashflowGraph?.graphBondFutureActualCouponPayments[index] ?? 0,
                })
            })

        setBondResults({ flow })

    }, [mbsCashflowGraph /*, showHistoryFlows, zcycGraph*/])

    // console.log('bondResults', bondResults)
    // console.log('PricingParameters', PricingParameters)
    // console.log('cdr', cdr)
    // console.log('cpr', cpr)

    const createFlowsLocalization = () => {
        var newFlowsLocalization = {};
        newFlowsLocalization.title = t('Convention2_NameCashflowGraphRubles');
        // newFlowsLocalization.tooltipFormatter = function () {
        //     // console.log('this?.points[0]?.series?.name', this?.points)
        //     const dx = new Date(PricingParameters?.pricingDate)?.getTime()
        //     return this?.points?.map(point => {
        //         // console.log('first', first)
        //         // if (point?.series?.name == 'ZCYC_MACALEY') {
        //         //     return '<table style="min-width:150px;max-width:200px;"><tr><td>' + t('Convention2_Duration') + ': </td>' +
        //         //         // '<td style="text-align:end"><b>' + Localization.FormatDateLocale(new Date(this.x)) + '</b></td > ' +
        //         //         '<td style="text-align:end"><b>' + Localization.FormatNumber((this.x - dx) / (1000 * 60 * 60 * 24) / 365, 2, 2) + '</b></td > ' +
        //         //         '</tr>' +
        //         //         '<tr><td style="padding:0">' + t('Convention2_YMT') + ': </td>' +
        //         //         '<td style="padding:0;text-align:end"><b>' + Localization.FormatNumberString(this.y) + '</b></td></tr>'
        //         //         + '</table>';
        //         // }
        //         if (point?.series?.name == 'ZCYC_CHART') {
        //             return '<table style="min-width:150px;max-width:200px;"><tr><td>' + t('Convention2_Duration') + ': </td>' +
        //                 // '<td style="text-align:end"><b>' + Localization.FormatDateLocale(new Date(this.x)) + '</b></td > ' +
        //                 '<td style="text-align:end"><b>' + Localization.FormatNumber((this.x - dx) / (1000 * 60 * 60 * 24) / 365, 2, 2) + '</b></td > ' +
        //                 '</tr>' +
        //                 '<tr><td style="padding:0">' + t(point?.point?.highTitle) + ': </td>' +
        //                 '<td style="padding:0;text-align:end"><b>' + Localization.FormatNumberString(point?.point?.high) + '</b></td></tr>'
        //                 + '<tr><td style="padding:0">' + t(point?.point?.lowTitle) + ': </td>' +
        //                 '<td style="padding:0;text-align:end"><b>' + Localization.FormatNumberString(point?.point?.low) + '</b></td></tr>'
        //                 + '</table>';
        //         }

        //         return null
        //     }
        //     )
        // };

        newFlowsLocalization.RealPayment = t('Convention2_NameCashflowGraphRealPayment');
        newFlowsLocalization.Plan = t('Convention2_NameCashflowGraphPlan');
        newFlowsLocalization.Default = t('Convention2_NameCashflowGraphDefault');
        newFlowsLocalization.Prepay = t('Convention2_NameCashflowGraphPrepay');
        newFlowsLocalization.CleanUp = t('Convention2_NameCashflowGraphCleanUp');
        newFlowsLocalization.RealCoupon = t('Convention2_NameCashflowGraphRealCoupon');
        // console.log('selectedBond', selectedBond)
        newFlowsLocalization.HistoricalCoupons = selectedBond?.couponType == CouponType.TYPE_KEYRATE_AND_PREMIUM ? t('Convention2_NameHistoricalCouponKP') : t('Convention2_NameHistoricalCoupon')
        // newFlowsLocalization.RealCoupon = selectedBond?.couponType == CouponType.TYPE_KEYRATE_AND_PREMIUM ? t('Convention2_NameCashflowGraphRealCouponKP') : t('Convention2_NameCashflowGraphRealCoupon')
        newFlowsLocalization.Coupon = selectedBond?.couponType == CouponType.TYPE_KEYRATE_AND_PREMIUM ? t('Convention2_NameCashflowGraphCouponKeyratePrem') : t('Convention2_NameCashflowGraphCoupon')
        //newFlowsLocalization.Coupon = t('Convention2_NameCashflowGraphCoupon')

        return newFlowsLocalization;
    }
    // const localization = useMemo(createFlowsLocalization, [])

    useEffect(() => {
        updateChartLocalization();
    }, [Localization.locale, selectedBond, calculatedParameters?.nextCouponDate]);


    const [disableCleanupButton, setDisableCleanupButton] = useState(false);
    const [skipCleanUp, setSkipCleanUp] = useState(true);

    useEffect(() => {
        setSkipCleanUp(true);
        setDisableCleanupButton(false);
    }, [selectedBond]);

    useLayoutEffect(() => {
        // console.log('bondResults, skipCleanUp, showHistoryFlows, zcycGraph, CalculatedParameters', bondResults, skipCleanUp, showHistoryFlows, zcycGraph, CalculatedParameters)
        // if (bondResults == null)
        //     return
        updateChartData();
    }, [bondResults, skipCleanUp, showHistoryFlows, zcycGraph, calculatedParameters]);

    useEffect(() => {
        // console.log('pricingParameters?.pricingDate', pricingParameters?.pricingDate)
        if (pricingParameters?.pricingDate == null)
            return
        actionConvention.clearValue('moexSecurities')(dispatch)
        if (selectedBond?.couponType != CouponType.TYPE_KEYRATE_AND_PREMIUM)
            actionConvention.loadMoexSecurities(pricingParameters?.zcycDateTime)(dispatch)
    }, [pricingParameters])

    // console.log('moexSecurities', moexSecurities)
    useLayoutEffect(() => {
        // console.log('moexSecurities', moexSecurities)
        // if (moexSecurities == null)
        //     return

        const xs = new Date(pricingParameters?.pricingDate).getTime()
        const moexSeries = (selectedBond?.couponType != CouponType.TYPE_KEYRATE_AND_PREMIUM) && (moexSecurities?.length) > 0 ? moexSecurities.map(item => { let x = xs + item.duration * 365 * 60 * 60 * 24 * 1000; return { x: x, y: item.yield, label: `${item.isin} (${item.name})` }; })
            : []
        // console.log('moexSeries', moexSeries)
        var newFlowsChart = { ...flowsChartOptions }
        newFlowsChart.series[13].data = [...moexSeries]
        // console.log('newFlowsChart', newFlowsChart)
        setFlowsChartOptions(newFlowsChart)

    }, [moexSecurities])

    const sortSeries = (a, b) => (a.x > b.x) ? 1 : (a.x < b.x) ? -1 : 0
    const isEmptyY = (series) => {
        // console.log('series', series, series?.filter(item => item?.y != null && item?.y != 0)?.length)
        return !(series?.filter(item => item?.y != null && item?.y != 0)?.length == 0)
    }

    const updateChartData = () => {
        if (bondResults == null)
            return;

        // console.log('zcycGraph', zcycGraph)
        var newFlowsChart = { ...flowsChartOptions };
        let tickAmount = 5

        let zcycSeries = []
        let zcycSeriesValue = []
        let zcycSeriesZSpread = []
        let zcycMacaley = []

        let dy = 0
        const mt = 60 * 60 * 24 * 1000
        if (zcycGraph != null && zcycGraph != null && pricingResult?.zSpread != null && calculatedParameters?.nextCouponDate != null) {
            dy = pricingResult?.zSpread / 100

            // let date = new Date(calculatedParameters.nextCouponDate)

            let pricingDate = new Date(pricingParameters?.pricingDate)
            let xs = (pricingDate.getTime()/* - (date.getTimezoneOffset() * 60 * 1000)*/)

            // let px = pricingDate.getTime() /*- (pricingDate.getTimezoneOffset() * 60 * 1000)*/

            let x = xs
            let low, high, y
            let xMacaley //= 1000000, tmpXMacaley

            // console.log('zcycGraph', zcycGraph)
            Object.entries(zcycGraph)
                ?.forEach((val, index) => {
                    y = zcycGraph[index]
                    low = Math.min(y + dy, y)
                    high = Math.max(y + dy, y)
                    let lowTitle = dy > 0 ? 'Convention2_ZCYCyt' : 'Convention2_ZCYCytPlusZ'
                    let highTitle = dy < 0 ? 'Convention2_ZCYCyt' : 'Convention2_ZCYCytPlusZ'
                    x = new Date(xs + 36.5 * (index + 1) * mt).setHours(0, 0, 0, 0) // в милисекундах

                    zcycSeries.push({
                        x, low, high, lowTitle, highTitle,
                        // color: dy > 0 ? '#b7d98b1a' : '#FF00000a'
                    })

                    zcycSeriesValue.push({ x, y })
                    zcycSeriesZSpread.push({ x, y: y + dy })

                })

            zcycMacaley = [{ x: (xs + pricingResult.durationMacaulay * 365 * mt), y: pricingResult?.ytm }]

            const arr = [...zcycGraph]
            let max = Math.ceil(Math.max.apply(null, arr) + 1 + (dy > 0 ? dy : 0))
            let min = Math.floor(Math.min.apply(null, arr) - 1 + (dy < 0 ? dy : 0))
            // console.log('max', max, min, roundUp((max - min) / tickAmount, 1))
            const tickStep = roundUp((max - min) / (tickAmount - 1), 1)
            // const tickStep = Math.round((max - min) / (tickAmount - 1))
            max = min + tickStep * (tickAmount - 1)
            // console.log('max', max, min)
            newFlowsChart.yAxis[1].max = max
            newFlowsChart.yAxis[1].min = min

            //четкая задача тиков - START
            let ticksPositions = []
            for (var val = min; val <= max; val = val + tickStep)
                ticksPositions.push(val)
            newFlowsChart.yAxis[1].tickPositions = ticksPositions
            //четкая задача тиков - END

            // console.log('ticksPositions', ticksPositions)
            // newFlowsChart.yAxis[1].tickAmount = tickAmount
            newFlowsChart.yAxis[1].alignTicks = true
            // console.log('newFlowsChart', newFlowsChart)
            // console.log('zcycSeries', zcycSeries, zcycSeriesValue, zcycSeriesZSpread, dy)
        }

        // console.log('zcycSeries', zcycSeries)
        var plan = [];
        var defaults = [];
        var prepayments = [];
        var pleanup = [];
        var coupons = [];
        var realPayments = [];
        var realCoupons = [];

        var historicalAmortization = [];
        var historicalCoupons = [];
        let maxY: number = 0
        let maxYwoCleanup: number = 0
        // let calcArr = [];
        let sumY: number = 0
        let sumWoCleanup: number = 0

        let minDate, maxDate
        bondResults?.flow?.forEach((point) => {
            if (showHistoryFlows == false && point.flowType == FlowTypes.USED_HISTORY)
                return
            // if (showHistoryFlows == false && point.bondCoupon != 0)
            //     return
            var date = new Date(point.couponDate)
            // var unixDate = date.getTime() - (date.getTimezoneOffset() * 60 * 1000)
            var unixDate = date.setDate(1) - (date.getTimezoneOffset() * 60 * 1000)
            // console.log('unixDate', new Date(unixDate))
            minDate = minDate > unixDate ? unixDate : minDate ?? unixDate
            maxDate = maxDate < unixDate ? unixDate : maxDate ?? unixDate
            //no historical points available for charts

            if (point.flowType != FlowTypes.USED_HISTORY) {

                defaults.push({ x: unixDate, y: point.modelBondDefault });
                prepayments.push({ x: unixDate, y: point.modelBondPrepayment });
                pleanup.push({ x: unixDate, y: point.modelBondCleanUp });
                coupons.push({ x: unixDate, y: point.modelBondCoupon });

                plan.push({ x: unixDate, y: point.modelBondPlan });
                realPayments.push({ x: unixDate, y: point.realBondPayments });
                realCoupons.push({ x: unixDate, y: point.realBondCoupons });

                sumY = parseFloat(point.modelBondDefault) +
                    parseFloat(point.modelBondPrepayment) +
                    parseFloat(point.modelBondCoupon) +
                    parseFloat(point.modelBondCleanUp) +
                    parseFloat(point.modelBondPlan) +
                    parseFloat(point.realBondPayments) +
                    parseFloat(point.realBondCoupons)
                maxY = roundUp(sumY > maxY ? sumY : maxY, 10)

                sumWoCleanup = parseFloat(point.modelBondDefault) +
                    parseFloat(point.modelBondPrepayment) +
                    parseFloat(point.modelBondCoupon) +
                    parseFloat(point.modelBondPlan) +
                    parseFloat(point.realBondPayments) +
                    parseFloat(point.realBondCoupons)
                maxYwoCleanup = roundUp(maxYwoCleanup > sumWoCleanup ? maxYwoCleanup : sumWoCleanup, 10)

                // calcArr.push(sumY)

            }
            else {
                //historical
                historicalAmortization.push({ x: unixDate, y: point.bondAmortization });
                historicalCoupons.push({ x: unixDate, y: point.bondCoupon });
                if (showHistoryFlows === true) {
                    sumWoCleanup = parseFloat(point.bondAmortization) + parseFloat(point.bondCoupon)
                    // console.log('maxYwoCleanup', maxYwoCleanup, maxYwoCleanup)
                    maxYwoCleanup = maxYwoCleanup > sumWoCleanup ? maxYwoCleanup : sumWoCleanup
                }
            }
        });

        minDate = new Date(minDate).setDate(1)
        maxDate = new Date(maxDate).setDate(31)
        newFlowsChart.xAxis[0].mix = minDate
        newFlowsChart.xAxis[0].max = maxDate
        // console.log('maxY', maxY)
        // console.log('maxYwoCleanup', maxYwoCleanup)
        // console.log('(maxY - maxYwoCleanup)/Math.max(maxY,maxYwoCleanup,0.00000001', (maxY - maxYwoCleanup)/Math.max(maxY,maxYwoCleanup,0.00000001))
        // console.log('(maxY - maxYwoCleanup) / Math.max(maxY, maxYwoCleanup, 0.00000001)', (maxY - maxYwoCleanup) / Math.max(maxY, maxYwoCleanup, 0.00000001))
        if ((maxY - maxYwoCleanup) / Math.max(maxY, maxYwoCleanup, 0.00000001) <= 0.2) {
            maxY = Math.max(maxY, maxYwoCleanup)
            setDisableCleanupButton(true);
        }
        else {
            maxY = !skipCleanUp ? parseFloat(maxY) : parseFloat(maxYwoCleanup)
            setDisableCleanupButton(false);
        }
        // let prevMax = Math.max.apply(null,calcArr.filter(val=>val!=Math.max.apply(null, calcArr)))

        // if(this.state.skipCleanUp) maxY = prevMax
        // let cellY = roundUp(maxY / (tickAmount - 1), 5) //Math.ceil(maxY / (tickAmount - 1) / 10) * 10
        newFlowsChart.yAxis[0].max = roundUp(maxY, 5) //Math.ceil(maxY / 10) * 10
        newFlowsChart.yAxis[0].tickAmount = tickAmount
        // newFlowsChart.yAxis[0].alignTicks = true
        // newFlowsChart.yAxis[0].tickInterval = newFlowsChart.yAxis[0].max >= 30 ? cellY : newFlowsChart.yAxis[0].max / 4
        // console.log('prevMax',prevMax,'maxY=', maxY, 'cellY=',cellY, 'yAxis.max', newFlowsChart.yAxis.max, newFlowsChart.yAxis.tickAmount )
        // console.log('maxY=',maxY,'yAxis.max=', newFlowsChart.yAxis[0].max, 'tickInterval',newFlowsChart.yAxis.tickInterval )
        // const lineStyle = {
        //     color: '#000000',
        //     dashStyle: 'solid',
        //     width: 10,
        //     zIndex: 2,
        //     label: {
        //         text: 'test',
        //         rotation: 0
        //     }
        // }
        // newFlowsChart.xAxis.plotLines = []
        // for (var x = 0; x < 1000; x++)
        //     newFlowsChart.xAxis.plotLines.push({ ...lineStyle, value: x+100000 })
        //     console.log('newFlowsChart.xAxis', newFlowsChart.xAxis)
        // // console.log('newFlowsChart', newFlowsChart)
        selectedBond?.couponType == CouponType.TYPE_KEYRATE_AND_PREMIUM ?
            newFlowsChart.exporting.filename = stringInject(t('Convention2_FlowsChartFilename_ParamsKaP'), [selectedBond?.isin])
            : newFlowsChart.exporting.filename = stringInject(t('Convention2_FlowsChartFilename_Params'), [selectedBond?.isin, Localization.FormatDateTimeStringLocale(pricingParameters?.zcycDateTime)?.replace(':', '-')])

        //GraphPlan
        if (historicalCoupons.length != 0) {
            // historicalCoupons.sort(sortSeries)
            newFlowsChart.series[8].data = historicalCoupons;
            newFlowsChart.series[8].showInLegend = showHistoryFlows;
            newFlowsChart.series[8].visible = showHistoryFlows;
        }
        else {
            newFlowsChart.series[8].data = null;
            newFlowsChart.series[8].showInLegend = false;
            newFlowsChart.series[8].visible = false;
        }

        if (historicalAmortization.length != 0) {
            // historicalAmortization.sort(sortSeries)
            newFlowsChart.series[7].data = historicalAmortization;
            newFlowsChart.series[7].showInLegend = showHistoryFlows;
            newFlowsChart.series[7].visible = showHistoryFlows;
        }
        else {
            newFlowsChart.series[7].data = null;
            newFlowsChart.series[7].showInLegend = false;
            newFlowsChart.series[7].visible = false;
        }

        newFlowsChart.series[6].data = pleanup;
        newFlowsChart.series[6].showInLegend = isEmptyY(pleanup)

        newFlowsChart.series[5].data = coupons;
        newFlowsChart.series[5].showInLegend = isEmptyY(coupons) // Конв. выплаты купона

        newFlowsChart.series[4].data = realCoupons;
        newFlowsChart.series[4].showInLegend = isEmptyY(realCoupons)

        newFlowsChart.series[3].data = prepayments;
        newFlowsChart.series[3].showInLegend = isEmptyY(prepayments)

        newFlowsChart.series[2].data = defaults;
        newFlowsChart.series[2].showInLegend = isEmptyY(defaults)

        newFlowsChart.series[1].data = plan;
        newFlowsChart.series[1].showInLegend = isEmptyY(plan)

        newFlowsChart.series[0].data = realPayments;
        newFlowsChart.series[0].showInLegend = isEmptyY(realPayments)

        if (zcycGraph != null && Object.keys(zcycGraph).length) {
            // zcycSeries.push({ x: minDate, low: null, high: null })
            newFlowsChart.chart.marginRight = 34
            newFlowsChart.yAxis[1].visible = true
            const fillColor = dy + zcycGraph[0] > zcycGraph[0] ? 'rgba(183, 217, 139, 0.23)' : 'rgba(255, 0, 0, 0.1)'
            newFlowsChart.series[9].fillColor.stops = [
                [0, fillColor], // Start color (transparent)
                [1, fillColor] // End color (transparent)
            ]
            newFlowsChart.series[9].turboThreshold = zcycGraph.length + 1
            newFlowsChart.series[10].turboThreshold = zcycGraph.length + 1
            newFlowsChart.series[11].turboThreshold = zcycGraph.length + 1
            // newFlowsChart.xAxis[1].mix = newFlowsChart.xAxis[0].mix
            // newFlowsChart.xAxis[1].max = newFlowsChart.xAxis[0].max
        }
        else {
            newFlowsChart.yAxis[1].visible = false
            newFlowsChart.chart.marginRight = 10
        }
        // zcycSeries.sort(sortSeries)
        newFlowsChart.series[9].data = zcycSeries
        // zcycSeriesValue.sort(sortSeries)
        newFlowsChart.series[10].data = zcycSeriesValue
        // zcycSeriesZSpread.sort(sortSeries)
        newFlowsChart.series[11].data = zcycSeriesZSpread
        // zcycMacaley.sort(sortSeries)
        newFlowsChart.series[12].data = zcycMacaley

        // console.log('zcycSeries', zcycSeries)
        //newFlowsChart.series[0].visible = knownFlows.length;

        // console.log('newFlowsChart', newFlowsChart)
        setFlowsChartOptions(newFlowsChart);
    }

    const updateChartLocalization = () => {
        const localization = createFlowsLocalization()
        var flowsOptions = { ...flowsChartOptions };
        flowsOptions.yAxis[0].title.text = localization.title;
        // flowsOptions.tooltip.formatter = localization.tooltipFormatter;
        flowsOptions.series[0].name = localization.RealPayment;
        flowsOptions.series[1].name = localization.Plan;
        flowsOptions.series[2].name = localization.Default;
        flowsOptions.series[3].name = localization.Prepay;
        flowsOptions.series[4].name = localization.RealCoupon;
        flowsOptions.series[5].name = localization.Coupon;
        flowsOptions.series[6].name = localization.CleanUp;
        flowsOptions.series[8].name = localization.HistoricalCoupons;

        setFlowsChartOptions(flowsOptions);
    }

    const onSaveFlowAsImageButtonClick = (event) => {
        if (flowChartRef?.current)
            flowChartRef.current.chart.exportChart({ width: 2048, height: 1365 });
    }

    const onSaveGraphFlowsButtonClick = (event) => {

        if (!bondResults == null)
            return;


        var extraColumns = [
            { title: t('Convention2_NameCouponDate') },
            { title: t('Convention2_NameCashflowGraphRealPayment') },
            { title: t('Convention2_NameCashflowGraphPlan') },
            { title: t('Convention2_NameCashflowGraphDefault') },
            { title: t('Convention2_NameCashflowGraphPrepay') },
            { title: t('Convention2_NameCashflowGraphCleanUp') },
            { title: t('Convention2_NameCashflowGraphRealCoupon') },
            { title: t('Convention2_NameCashflowGraphCoupon') },
        ];

        var textToCopy = extraColumns.map((item) => item.title).join('\t') + '\r\n';

        textToCopy += bondResults.flow
            .filter((item) => item.flowType == FlowTypes.MODEL || item.flowType == FlowTypes.USED_HISTORY)
            .map((item) => (
                Localization.FormatDateStringLocale(item.couponDate) + '\t' +
                Localization.FormatNumberString(item.realBondPayments) + '\t' +
                Localization.FormatNumberString(item.modelBondPlan) + '\t' +
                Localization.FormatNumberString(item.modelBondDefault) + '\t' +
                Localization.FormatNumberString(item.modelBondPrepayment) + '\t' +
                Localization.FormatNumberString(item.modelBondCleanUp) + '\t' +
                Localization.FormatNumberString(item.realBondCoupons) + '\t' +
                Localization.FormatNumberString(item.modelBondCoupon)
            )).join('\r\n');

        copyTextToClipboard(textToCopy);
    }

    const handleZCYCChartsButtonClick = () => {
        setShowZCYCChart(true)
    }

    const handleZCYCChartClose = () => {
        setShowZCYCChart(false)
    }

    if (mbsCashflowGraph == null) return <></>

    const handleMOEXButtonClick = () => {
        window.open("https://www.moex.com/ru/marketdata/indices/state/g-curve/", "_blank");
    }

    return (
        <Box className={cls.root}>
            <ZCYCDialog show={showZCYCChart} firstZCYCDate={new Date(pricingParameters?.zcycDateTime ?? '')} onClose={handleZCYCChartClose} />
            <Box className={cls.rootPaper}>
                {loading && <Box className={cls.coverLayout} />}
                <Box className={cls.panelHeader}>

                    <Box className={cls.panelHeaderTitle}>
                        {selectedBond?.couponType == CouponType.TYPE_KEYRATE_AND_PREMIUM ? t('Convention2_ConventionNameCashflowGraphNameKaP') :
                            `${t('Convention2_ConventionNameCashflowGraphName')} ${Boolean(pricingParameters?.zcycDateTime) && ` на ${Localization.FormatDateTimeStringLocale(pricingParameters?.zcycDateTime)}`}`
                        }

                    </Box>

                    <Box>
                        {/* кнопка MOEX  */}
                        <Tooltip
                            disableFocusListener
                            disableInteractive
                            title={t('ConventionNameZCYCWebsiteButton')}
                        >
                            <Button variant="text" color="primary" onClick={handleMOEXButtonClick} className={cls.textIcon}>
                                MOEX
                            </Button>
                        </Tooltip>

                        {/* кнопка сравнить две КБД  */}
                        <Tooltip
                            disableFocusListener
                            disableInteractive
                            title={t('TooltipConventionCompareCurvesButton')}
                        >
                            <IconButton
                                size="small"
                                onClick={() => handleZCYCChartsButtonClick()}
                            >
                                <SsidChart className={cls.icon} />
                            </IconButton>
                        </Tooltip>

                        {/* кнопка "выключить Экстремум" */}
                        {!Boolean(disableCleanupButton) &&
                            <Tooltip
                                disableFocusListener
                                disableInteractive
                                title={t(skipCleanUp ? "skipCleanUpTrue" : "skipCleanUpFalse")}
                                classes={{ tooltip: cls.wideTooltip }}
                            >
                                <IconButton
                                    size="small"
                                    onClick={() => { setSkipCleanUp(!skipCleanUp) }}
                                    // console.log('clickLupa' )
                                    disabled={bondResults == null}
                                >
                                    {skipCleanUp ? <ZoomOutOutlined className={cls.icon} /> : <ZoomInOutlined className={cls.icon} />}
                                </IconButton>
                            </Tooltip>}

                        {/* кнопка Save */}
                        {!device.isMobile && <Tooltip
                            disableFocusListener
                            disableInteractive
                            title={t('SaveAsImage')}>
                            <IconButton size="small" disabled={bondResults == null} onClick={onSaveFlowAsImageButtonClick}>
                                <SaveOutlined className={cls.icon} />
                            </IconButton>
                        </Tooltip>
                        }
                        {// кнопка Copy 
                            /* <Box component="span" sx={device.isMobile ? { display: 'none' } : {}}> 
                                }
                                <Tooltip
                                    disableFocusListener
                                    disableInteractive
                                    title={t('CopyPlotResult')}>
                                    <span>
                                        <IconButton size="small" disabled={bondResults == null} onClick={onSaveGraphFlowsButtonClick}>
                                            <FileCopyOutlined className={cls.icon} />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                            </Box> */}
                    </Box>
                </Box>
                {/* <FlowsChart data={flowsChartOptions}/> */}
                <HighchartsReact
                    ref={flowChartRef}
                    highcharts={Highcharts}
                    containerProps={{ style: { height: "100%", width: "100%" } }}
                    options={flowsChartOptions ? flowsChartOptions : {}}
                    callback={(chart) => {
                        if (chart?.legend) {
                            Highcharts.objectEach(chart.legend.allItems, function (el) {
                                //  console.log('el', el)
                                //  if (el.legendSymbol ) //&& el.name == t("HistoricalAmortization"))
                                //     el.legendSymbol.element.style.stroke = style.colorPrimary3_40;
                                if (el?.legendItem?.symbol && el.name == t("Convention2_NameHistoricalAmortization"))
                                    el.legendItem.symbol.element.style.stroke = style.colorPrimary3_40;
                            })
                        }
                        chart.reflow()
                    }
                    }
                />
            </Box>
        </Box >
    );
}

export default FlowsChartPanel