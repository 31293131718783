import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';

import makeStyles from '@mui/styles/makeStyles';
import * as React from 'react';

const useStyles = makeStyles({
    drawer: {
        flexShrink: 0,
    },
    drawerPaper: {
        position: 'relative',
    },
    list: {
        marginTop: '70px',
    },
});

export const MenuList = (props: any) => {
    const classes = useStyles(props);

    return (
        <Drawer
            variant='permanent'
            className={classes.drawer}
            classes={{ paper: classes.drawerPaper }}
        >
            <List className={classes.list}>
                {props.children}
            </List>
        </Drawer>
    );
};
