import React, { useState, useEffect, useRef, useCallback, useLayoutEffect } from 'react';
import { actionCreators } from "@Store/KeyRateStore";
import { useDispatch, useSelector } from "react-redux";

import { format } from 'date-fns';

import { makeStyles } from '@mui/styles';
import { useTranslation } from "react-i18next";

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';

import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';

import Localization from '@Components/Localization';

import Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
// import HC_exporting_data from 'highcharts/modules/export-data';
import HighchartsReact from 'highcharts-react-official';
import HC_more from "highcharts/highcharts-more"; //module
import HC_draggable_points from "highcharts/modules/draggable-points";

import { toUnixDate, toUnixDateGMT, formDatePattern } from '@Utilities';
import style from '@Styles/domrf-theme.module.scss';
// import TooltipInfoLabel from '@Components/shared/TooltipInfoLabel';

import { addMonths, isDate, startOfMonth } from 'date-fns'
// import { useResizeDetector } from 'react-resize-detector';
import { Box, debounce, Divider, Typography, Stack, alpha } from '@mui/material';
import { BackspaceOutlined, HelpOutline } from '@mui/icons-material';
import TooltipInfoLabel from '../../components/shared/TooltipInfoLabel';
import DatePickerStandaloneValidator from '@Framework/components/Form/DatePickerStandaloneValidator';
import NumericStandaloneValidator from '@Framework/components/Form/NumericStandaloneValidator';

import {
    DataGridPremium,
    GridRenderCellParams,
    GridColDef,
    GridRowModesModel,
    GridActionsCellItem,
    useGridApiContext,

    GridRowsProp,
    GridRowModes,
    GridToolbarContainer,
    GridEventListener,
    GridRowId,
    GridRowModel,
    GridRowEditStopReasons,
    GridRenderEditCellParams,
    GridPreProcessEditCellProps,
    GridCellParams,
    GridValueFormatterParams
} from '@mui/x-data-grid-premium';

const useStyles = makeStyles({

    controlsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'start',
        paddingBottom: '1rem'
    },
    editToolbar: {
        position: 'absolute',
        top: '0.1rem',
        right: '1.7rem',
        display: 'inline-flex',
        flexDirection: 'row'
    },
    buttonsContainer:
    {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'flex-end',
        padding: '0 1.7rem'
    },
    checkbox: {
        marginBottom: '0',
        '& .MuiCheckbox-root': {
            padding: '0'
        }
    },

    dateData: {
        //width: '117px !important',
        //whiteSpace: 'nowrap',
        //height: '32px',
        '& svg': {
            height: '19px',
            width: '19px',
            padding: 0,
        },
        '& .MuiInputAdornment-root': {
        },
        '& .MuiInputAdornment-root svg': {
            margin: '6px',
        },
        /*
        '& input': {
            '&::selection': {
                backgroundColor: `rgb(200,232,250)`
            },
            textAlign: 'right',
            paddingRight: '0.5rem',
            paddingLeft: '0.5rem',
        }
        */
    },
    rateData: {
        //width: '117px !important',
        //whiteSpace: 'nowrap',
        //height: '32px',
        '& input': {
            //'&::selection': {
            //backgroundColor: `rgb(200,232,250)`
            //},
            textAlign: 'right',
            paddingRight: '0.5rem',
            paddingLeft: '0.5rem',
        }
    },

    gridCell: {
        fontSize: '1rem'
    }
});

type PointDef = {

    date: Date | number,
    value: number
}


type PointsProps = {

    pointsData: any[];
    onRowUpdated: (updatedRow: GridRowModesModel, oldRow: GridRowModesModel) => void;
    onRowRemoved: (removedRow: GridRowModesModel) => void;
}

const PointsEditorComponent = ({

    pointsData,
    onRowUpdated,
    onRowRemoved,

}: PointsProps) => {

    const classes = useStyles();
    const { t, i18n } = useTranslation();

    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const [rows, setRows] = useState([]);
    const [minRow, setMinRow] = useState(null);
    const [maxRow, setMaxRow] = useState(null);
    const [datePattern, setDatePattern] = useState('');
    const views = ['month', 'year'];
    useEffect(() => {

        const datePtrn = Localization.ShortDatePattern();
        const datePartDivider = datePtrn.match(/[.|\/|-]/);
        const { phParts } = formDatePattern(views, datePartDivider, datePtrn);
        setDatePattern(phParts.join(datePartDivider));

    }, []);

    useEffect(() => {

        if (pointsData != null && pointsData.length != 0) {

            var temp = null;

            if (isDate(pointsData[0].x)) {
                temp = pointsData.map(item => {
                    var date = item.x;
                    return { id: date.getTime(), date: date, value: item.y }
                });
            }
            else if (typeof (pointsData[0].x) == 'number') {
                temp = pointsData.map(item => {
                    var date = new Date(item.x);
                    return { id: item.x, date: date, value: item.y }
                });
            }
            else
                throw "Unknown point data type!";

            temp = temp.sort((a, b) => a.id - b.id);

            var min = temp[0].id;
            var max = temp[temp.length - 1].id;

            setRowModesModel({}); //remove all changes made
            setRows(temp);
            setMinRow(min);
            setMaxRow(max);
        }
        else {
            setRows([]);
        }

    }, [pointsData]);

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const handleEditClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit, isValidFlags: 0 } });
    };

    const handleSaveClick = (id: GridRowId) => () => {

        var model = rowModesModel[id];
        if (model.isValidFlags != 0)
            return;

        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleDeleteClick = (id: GridRowId) => () => {
        var index = rows.findIndex(x => x.id == id);
        var removedRow = rows[index];

        setRows(rows.filter((row) => row.id !== id));

        if (onRowRemoved != null)
            onRowRemoved(removedRow);
    };

    const handleCancelClick = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

        const editedRow = rows.find((row) => row.id === id);
        if (editedRow!.isNew) {
            setRows(rows.filter((row) => row.id !== id));
        }
    };

    const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    const processRowUpdate = (newRow: GridRowModel, oldRow: GridRowModel,) => {

        const date = startOfMonth(newRow.date);
        const updatedRow = { ...newRow, id: date.getTime(), date: date, isNew: false };

        var newRows = rows.map((row) => (row.id === newRow.id ? updatedRow : row));
        var filledRows = newRows.filter(x => !x.isNew);
        var newRows = newRows.filter(x => x.isNew);
        var filledRows = filledRows.sort((a, b) => a.id - b.id);
        filledRows = filledRows.concat(newRows);

        setRows(filledRows);

        if (onRowUpdated != null)
            onRowUpdated(updatedRow, oldRow);

        return updatedRow;
    };

    const handleAddPointClick = () => {

        const id = rows.length + 1;

        setRows((oldRows) => [...oldRows, { id: id, date: null, value: '', isNew: true }]);

        setRowModesModel((oldModel) => ({
            ...oldModel,
            [id]: { mode: GridRowModes.Edit, fieldToFocus: 'value', isValidFlags: 3 /*11*/ },
        }));
    };

    const preProcessDateEditCellProps = (params: GridPreProcessEditCellProps) => {
        //const errorMessage = await validateName(params.props.value!.toString());
        var isValid = true;
        var error = null;
        if (params.props.value == null) {
            isValid = false;
            error = t("FieldRequired");
        }
        else {
            var newTime = toUnixDate(params.props.value);
            if (isValid) {
                var index = rows.findIndex(x => x.date != null && toUnixDateGMT(x.date) == newTime);
                if (index != -1) {
                    isValid = false;
                    error = t("DateAlreadyHas");
                }
            }

            if (isValid) {
                var index = rows.findIndex(x => x.date != null && toUnixDateGMT(x.date) < newTime);
                if (index == -1) {
                    isValid = false;
                    error = t("DateCannotBeSmallerMinimumDate");
                }
            }

            if (isValid) {
                var index = rows.findIndex(x => x.date != null && toUnixDateGMT(x.date) > newTime);
                if (index == -1) {
                    isValid = false;
                    error = t("DateCannotBeBiggerMaximumDate");
                }
            }
        }

        var model = rowModesModel[params.id];

        if (isValid)
            model.isValidFlags &= ~1;
        else
            model.isValidFlags |= 1;

        return { ...params.props, error: error, isValid: isValid };
    };

    const preProcessRateEditCellProps = (params: GridPreProcessEditCellProps) => {
        //const errorMessage = await validateName(params.props.value!.toString());
        var isValid = true;
        var error = null;
        if (params.props.value == null || params.props.value == '') {
            isValid = false;
            error = t("FieldRequired");
        }

        var model = rowModesModel[params.id];

        if (isValid)
            model.isValidFlags &= ~(1 << 1);
        else
            model.isValidFlags |= (1 << 1);

        return { ...params.props, error: error, isValid: isValid };
    };


    function renderDateCell(params: GridRenderEditCellParams) {
        const { api, id, field, isValid, error, value } = params;

        return <DatePickerStandaloneValidator
            views={views}
            inputClassName={classes.dateData}
            value={value}
            fullWidth={true}
            mobile={false} //Enable manual input
            placeholder={Localization.ShortDatePattern()}// "dd.MM.yyyy"
            mask={Localization.DateMask()}//"__.__.____"
            allowKeyboardControl={true}
            hideValidationIcon={true}
            isValid={isValid}
            errorMessage={error}
            onChange={(val) => {
                api.setEditCellValue({ id, field, value: val });
            }}

            //CHECKME!!! doesn't works even if props passes down InputProps={{ disableUnderline: true }}
            {...params}
            sx={{
                '& .MuiInput-underline:after': { border: 'none !important' },
                '& .MuiInput-underline:before': { border: 'none !important' },
            }} />
    }

    function renderRateCell(params: GridRenderEditCellParams) {
        const { api, id, field, isValid, error, value } = params;

        return <NumericStandaloneValidator
            className={classes.rateData}
            value={value}
            fullWidth={true}
            isValid={isValid}
            errorMessage={error}
            hideValidationIcon={true}
            onChange={(evt) => {
                api.setEditCellValue({ id, field, value: evt.target.value });
            }}

            //CHECKME!!! doesn't works even if props passes down InputProps={{ disableUnderline: true }}
            {...params}
            sx={{
                '& .MuiInput-underline:after': { border: 'none !important' },
                '& .MuiInput-underline:before': { border: 'none !important' },
            }}
        />
    }

    const columns: GridColDef[] = [
        {
            field: 'date',
            headerName: t('Date'),
            type: 'date',
            flex: 1,
            editable: true,
            sortable: false,
            preProcessEditCellProps: preProcessDateEditCellProps,
            renderEditCell: renderDateCell,
            cellClassName: (params: GridCellParams<Date>) => classes.gridCell,
            valueFormatter: (params: GridValueFormatterParams<Date>) => {
                //console.log("=====params.value", params);
                if (params.value == null) {
                    return '';
                }
                return format(params.value, datePattern);
            }
        },
        {
            field: 'value',
            headerName: t('Value'),
            type: 'number',
            flex: 1,
            editable: true,
            sortable: false,
            preProcessEditCellProps: preProcessRateEditCellProps,
            renderEditCell: renderRateCell,
            cellClassName: (params: GridCellParams<number>) => classes.gridCell,
            valueFormatter: (params: GridValueFormatterParams<Date>) => {
                if (params.value == null) {
                    return '';
                }
                return Localization.FormatNumber(params.value, 1, 1);
            }
        },
        {
            field: 'actions',
            type: 'actions',
            width: 70,
            cellClassName: 'actions',

            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label={t("Save")}
                            sx={{
                                color: 'primary.main',
                            }}
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label={t("Cancel")}
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label={t("Edit")}
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label={t("Delete")}
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                        disabled={id == minRow || id == maxRow}
                    />,
                ];
            },
        },
    ];

    return (

        <Stack direction="column">
            <Box>
                <Button color="primary" startIcon={<AddIcon />} onClick={handleAddPointClick}>
                    {t('AddPoint')}
                </Button>
            </Box>

            <Box sx={{ width: '300px', height: '100%', minHeight: 0 }}>
                <DataGridPremium
                    rows={rows}
                    columns={columns}
                    editMode="row"
                    rowModesModel={rowModesModel}
                    onRowModesModelChange={handleRowModesModelChange}
                    onRowEditStop={handleRowEditStop}
                    processRowUpdate={processRowUpdate}
                    hideFooter
                    initialState={{ pinnedColumns: { right: ['actions'] } }}
                    disableColumnMenu={true}
                    disableColumnFilter={true}
                    disableColumnResize={true}
                    isCellEditable={(params) => {

                        if (params.field != 'date')
                            return true;

                        if (params.value == null)
                            return true;

                        var min = +Infinity;
                        var max = -Infinity;

                        //border dates cannot be changed
                        rows.forEach(x => {

                            if (x.date == null)
                                return;

                            var cur = x.date.getTime();

                            if (cur < min)
                                min = cur;
                            if (cur > max)
                                max = cur;
                        });

                        return params.value.getTime() != min && params.value.getTime() != max;
                    }}
                />
            </Box>

        </Stack>
    )
}

export default PointsEditorComponent;