import useDebounce from '@Base/components/hook/useDebounce'
import React, { useEffect, useState } from 'react'

const useFilter = (sourceData = [], params = []) => {
    // const { field = null, value = null } = params
    const [data, setData] = useState(sourceData)
    // const [searchValue, setSearchValue] = useState(value)

    const searchParams = useDebounce(params, 300)
    // useEffect(() => {
    //     console.log('searchParams', searchParams)
    // }, [searchParams])

    useEffect(() => {
        // console.log('sourceData', sourceData)
        try {
            setData(sourceData.filter(row => params.some(param => {
                switch (param?.field) {
                    case 'id':
                        return row[param?.field] == param.value

                    default:
                        const reg = new RegExp(`${param?.value ?? ''}`, 'i')
                        return reg.test(row[param?.field])
                }

            })))

        } catch (error) {

        }
    }, [sourceData, searchParams])

    // console.log('regData', sourceData)
    return data
}

export default useFilter