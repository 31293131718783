import React, { useState, useEffect, useRef } from 'react';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
// import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Theme } from '@mui/material/styles';

import { makeStyles, styled } from '@mui/styles';
import { useTranslation } from "react-i18next";
import style from '@Styles/domrf-theme.module.scss';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import Globals from '../../Globals';
import { Paper, Popper, useTheme } from '@mui/material';

const useStyles = makeStyles({

    /* Styles applied to the root element. */
    buttonContainer: {
        textAlign: 'end'
    },
    popper: {
        zIndex: 10001
    }
});

type IProps = {
    content: any
    link?: string
    linkName?: string
    onClose: () => void;
    children?: any;
} & TooltipProps;

interface iTheme {
    theme: Theme
}

const ToolTipBlock = styled('div')(({ theme }: iTheme) => ({
    maxWidth: '300px',
    padding: theme.spacing(1), margin: theme.spacing(1), borderRadius: '4px',
    backgroundColor: '#616161eb',
    color: '#FFF',
    fontSize: style.fontSizePrimary,
    fontFamily: style.fontFamilyBase
}));

const TooltipWithLink = ({ id, link, linkName, content, onClose, children, disableInteractive = false, ...rest }: IProps) => {
    const classes = useStyles();
    const anchorElement = useRef()
    const { t } = useTranslation();
    const [open, setOpen] = useState(false)
    const theme = useTheme()

    const handleClick = (evt: any) => {
        let reg = new RegExp("^(http|https)://", "i")
        const externalLink = reg.test(link)
        if (externalLink)
            window.open(link, "_blank")
        else
            window.open(Globals.WikiServer + '/' + link, "_blank");
        //window.location.href = Globals.WikiServer + '/' + this.props.link;
    }

    // if(disableInteractive)
    // console.log('disableInteractive', id,  disableInteractive)
    if (disableInteractive) {
        let tooltipData = <>
            <div dangerouslySetInnerHTML={{ __html: content }}></div>
            {
                link != null && (
                    <div className={classes.buttonContainer}>
                        <Button variant="contained" color="secondary" onClick={handleClick}>{linkName || t('Article')}</Button>
                    </div>
                )
            }
        </>

        return <>
            <Tooltip
                title={tooltipData}
            >
                {children}
            </Tooltip>
        </>
    }

    return (
        <>
            {open &&
                <ClickAwayListener onClickAway={() => setOpen(false)}>
                    {/* <span> */}
                    <Popper id={id} open={open} anchorEl={anchorElement.current} className={classes.popper}>
                        <ToolTipBlock>
                            <div dangerouslySetInnerHTML={{ __html: content }}></div>
                            {
                                link != null && (
                                    <div className={classes.buttonContainer}>
                                        <Button variant="contained" color="secondary" onClick={handleClick}>{linkName || t('Article')}</Button>
                                    </div>
                                )
                            }
                        </ToolTipBlock>
                    </Popper>
                    {/* <Tooltip
                    open={open}
                    PopperProps={{
                        className: classes.popper
                    }}
                    // interactive
                    onClose={onClose}
                    title={
                        <React.Fragment>
                            <div dangerouslySetInnerHTML={{ __html: content }}></div>
                            {
                                link != null && (
                                    <div className={classes.buttonContainer}>
                                        <Button variant="contained" color="secondary" onClick={handleClick}>{linkName || t('Article')}</Button>
                                    </div>
                                )
                            }
                        </React.Fragment>
                    }
                    {...rest}
                >
                    {anchorElement.current}
                </Tooltip> */}
                    {/* </span> */}
                </ClickAwayListener>
            }
            <span ref={anchorElement} onClick={() => setOpen(prev => !prev)}>
                {children}
            </span>

        </>
    );
}

export default TooltipWithLink;