import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
//import "@Styles/pages/CreateNewCalculationPage.scss";
import CalculatorService from "../../../services/CalculatorService";
import CommonService from "../../../services/CommonService";
import "@Styles/pages/CalculationResultsPage.scss";

import { Spinner } from "@Components/shared/Spinner";
import * as CalculatorStore from "@Store/CalculatorStore";
import { withRouter } from "../../../components/shared/CustomRouter";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons'
import withStyles from '@mui/styles/withStyles';

import { Container } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

import Card from '@mui/material/Card';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import List from '@mui/material/List';
import ListItem, { ListItemProps } from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import HC_exporting_data from 'highcharts/modules/export-data';
import HighchartsReact from 'highcharts-react-official';

import UI from "../../../UIHelper";
//import CalculationParameters from '@Pages/instruments/calculator/CalculationParameters';
import Localization from '@Components/Localization';
import { ValidatorForm, TextValidator, SelectValidator, DatePickerValidator, ComboboxValidator } from "@Framework/components/Form";
import BondSelectDialog from "@Components/shared/BondSelectDialog";
import style from '@Styles/domrf-theme.module.scss';
import { CalculationType } from '@Components/Definitions';
import { Instruments } from '@Components/Instruments';
import { setHighchartsLocalization, b64toBlob } from '@Utilities';
import fileDownload from 'js-file-download';

import TooltipInfoLabel from '@Components/shared/TooltipInfoLabel';
import { withTranslation } from "react-i18next";

const styles = theme => ({
    description: {
        color: style.colorPrimary3,
        fontFamily: style.fontFamilyBase,
        fontSize: theme.typography.subtitle2.fontSize,
        lineHeight: theme.typography.subtitle2.lineHeight,
        letterSpacing: theme.typography.subtitle2.letterSpacing
    },
    tableHeader: {
        fontSize: '16px',
        //fontWeight: '600',
        color: style.colorPrimary3
    },
    overflow: {
        //height: '100%',
        overflowY: 'scroll',
        overflowX: 'auto',
        height: '450px'
    },
    toListButton: {
        position: 'fixed',
        left: '15px',
        bottom: '15px',
        zIndex: '1100'
    },
    tabs: {
        overflowY: 'auto',
        height: '450px',
        '& .MuiBox-root': {
            paddingLeft: 0,
            paddingRight: 0
        }
    },
    marginBottom: {
        marginBottom: '3rem'
    }
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

class CalculationResultsPage extends React.Component {

    constructor(props) {
        super(props);

        HC_exporting(Highcharts);
        HC_exporting_data(Highcharts);
        setHighchartsLocalization(Highcharts, this.props.t);


        this.state = {
            calculationId: parseInt(this.props.router.params.id, 10),
            selectedTabMain: 0,
            selectedTabFlows: 0,
            selectedTabMacro: 0,
            anchorEl: null,

            dirtyPriceChartOptions: {

                exporting: {
                    buttons: {
                        contextButton: {
                            menuItems: ['printChart', 'separator', 'downloadPNG', 'downloadJPEG', 'downloadCSV', 'downloadXLS']
                        }
                    },
                    filename: this.props.t('PriceDistribution')
                },

                plotOptions: {
                    series: {
                        /*
                        dataLabels: {
                            enabled: true,
                            color: '#2F444E',
                            format: '{y:.3f}'
                        },
                        */
                        pointPadding: 0,
                        groupPadding: 0.1,
                    }
                },

                chart: {
                    type: 'column'
                },
                title: {
                    text: this.props.t('PriceDistribution'),
                    style: { "color": "#2F444E", "fontSize": "18px", "fontWeight": "bold" }
                },

                xAxis: {
                    crosshair: true,
                    categories: null,
                    labels: {
                        format: '{value:.2f}'
                    },

                    title: {
                        enabled: false
                    }
                },
                yAxis: {
                    labels: {
                        enabled: false,
                        format: '{value:.2f}'
                    },
                    stackLabels: {
                        enabled: true
                    },
                    title: {
                        text: null
                    }
                },

                legend: {
                    enabled: true
                },

                tooltip: {
                    headerFormat: '<table><tr>' +
                        '<td>' + this.props.t('Price') + ':</td>' +
                        '<td style="text-align:end"><b>{point.key:.3f}</b></td>' +
                        '</tr>',
                    pointFormat: '<tr><td style="padding:0">{series.name}: </td>' +
                        '<td style="padding:0;text-align:end"><b>{point.y: .3f}</b></td></tr>',
                    footerFormat: '</table>',
                    shared: true,
                    useHTML: true
                },

                credits: {
                    enabled: false
                },

                series: [{
                    name: this.props.t('DirtyPriceProbability'),
                    color: "#76787A",
                    data: null
                },
                {
                    name: this.props.t('ClearPriceProbability'),
                    color: "#8bc540",
                    data: null
                }]
            },

            cashflowChartOptions: {
                exporting: {
                    buttons: {
                        contextButton: {
                            menuItems: ['printChart', 'separator', 'downloadPNG', 'downloadJPEG', 'downloadCSV', 'downloadXLS']
                        }
                    },
                    filename: this.props.t('CashflowMln')
                },

                chart: {
                    type: 'area',
                    zoomType: 'x'
                },

                title: {
                    //text: '@Localizer["CashflowMln"]',
                    text: null
                    //style: { "color": "#2F444E", "fontSize": "18px", "fontWeight": "bold" }
                },

                xAxis: {
                    type: 'datetime',
                    title: this.props.t('Date'),
                },

                yAxis: {
                    title: {
                        text: null
                    },
                    min: 0
                },
                tooltip: {
                    pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y:.2f}</b><br/>',
                    crosshairs: true,
                    shared: true
                },

                credits: {
                    enabled: false
                },

                legend: {
                },

                plotOptions: {
                    area: {
                        stacking: 'normal'
                    }
                },

                series: [
                    {
                        name: this.props.t('Coupon'),
                        data: null,
                        color: '#8dc641',
                        lineWidth: 1,
                        fillOpacity: 0.5,
                        marker: {
                            enabled: false,
                            symbol: 'circle'
                        }
                    },
                    {
                        name: this.props.t('UnplannedPrepayment'),
                        data: null,
                        color: '#77787b',
                        lineWidth: 1,
                        fillOpacity: 0.5,
                        marker: {
                            enabled: false,
                            symbol: 'circle'
                        }
                    },
                    {
                        name: this.props.t('PlannedRepayment'),
                        data: null,
                        color: '#2e454f',
                        lineWidth: 1,
                        fillOpacity: 0.5,
                        marker: {
                            enabled: false,
                            symbol: 'circle'
                        }
                    }
                ]
            },

            cprChartOptions: {
                exporting: {
                    buttons: {
                        contextButton: {
                            menuItems: ['printChart', 'separator', 'downloadPNG', 'downloadJPEG', 'downloadCSV', 'downloadXLS']
                        }
                    },
                    filename: this.props.t('CPR')
                },

                chart: {
                    zoomType: 'x'
                },

                title: {
                    text: null,
                    style: { "color": "#2F444E", "fontSize": "18px", "fontWeight": "bold" }
                },

                credits: {
                    enabled: false
                },


                xAxis: {
                    type: 'datetime',
                    title: null
                },

                yAxis: {
                    title: null,
                    min: 0
                },

                tooltip: {
                    pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y:.2f}</b><br/>',
                    crosshairs: true,
                    shared: true
                },

                series: [
                    {
                        name: this.props.t('Level_99'),
                        type: 'spline',
                        data: null,
                        dashStyle: 'longdash',
                        color: '#979ba2',
                        lineWidth: 1,
                        marker: {
                            enabled: false,
                            symbol: 'diamond'
                        }
                    },
                    {
                        name: this.props.t('AverageCPR'),
                        type: 'spline',
                        data: null,
                        color: '#2e454f',
                        lineWidth: 1,
                        marker: {
                            enabled: false,
                            symbol: 'circle'
                        }
                    },
                    {
                        name: this.props.t('Level_1'),
                        type: 'spline',
                        data: null,
                        dashStyle: 'longdash',
                        color: '#979ba2',
                        lineWidth: 1,
                        marker: {
                            enabled: false,
                            symbol: 'diamond'
                        }
                    }
                ]
            },
            shortRateChartOptions: {
                exporting: {
                    buttons: {
                        contextButton: {
                            menuItems: ['printChart', 'separator', 'downloadPNG', 'downloadJPEG', 'downloadCSV', 'downloadXLS']
                        }
                    },
                    filename: this.props.t('ShortRateSimulation')
                },

                chart: {
                    zoomType: 'x'
                },

                title: {
                    text: null,
                    style: { "color": "#2F444E", "fontSize": "18px", "fontWeight": "bold" }
                },

                xAxis: {
                    type: 'datetime',
                    title: null
                },

                yAxis: {
                    title: null
                },
                tooltip: {
                    pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y:.2f}</b><br/>',
                    crosshairs: true,
                    shared: true
                },

                credits: {
                    enabled: false
                },

                legend: {
                },

                series: [
                    {
                        name: this.props.t('Quantile_995'),
                        type: 'spline',
                        data: null,
                        dashStyle: 'longdash',
                        color: '#979ba2',
                        lineWidth: 1,
                        marker: {
                            enabled: false,
                            symbol: 'diamond'
                        }
                    },
                    {
                        name: this.props.t('Quantile_95'),
                        type: 'spline',
                        data: null,
                        color: '#979ba2',
                        lineWidth: 1,
                        marker: {
                            enabled: false,
                            symbol: 'triangle'
                        }
                    },
                    {
                        name: this.props.t('Median'),
                        type: 'spline',
                        data: null,
                        color: '#2e454f',
                        lineWidth: 1,
                        marker: {
                            enabled: false,
                            symbol: 'circle'
                        }
                    },
                    {
                        name: this.props.t('Quantile_5'),
                        type: 'spline',
                        data: null,
                        color: '#979ba2',
                        lineWidth: 1,
                        marker: {
                            enabled: false,
                            symbol: 'triangle'
                        }
                    },
                    {
                        name: this.props.t('Quantile_05'),
                        type: 'spline',
                        data: null,
                        dashStyle: 'longdash',
                        color: '#979ba2',
                        lineWidth: 1,
                        marker: {
                            enabled: false,
                            symbol: 'diamond'
                        }
                    }
                ]
            },
            mortageChartOptions: {
                exporting: {
                    buttons: {
                        contextButton: {
                            menuItems: ['printChart', 'separator', 'downloadPNG', 'downloadJPEG', 'downloadCSV', 'downloadXLS']
                        }
                    },
                    filename: this.props.t('MortgageRateSimulation')
                },

                chart: {
                    zoomType: 'x'
                },

                title: {
                    text: null,
                    style: { "color": "#2F444E", "fontSize": "18px", "fontWeight": "bold" }
                },

                xAxis: {
                    type: 'datetime',
                    title: null
                },

                yAxis: {
                    title: null
                },
                tooltip: {
                    pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y:.2f}</b><br/>',
                    crosshairs: true,
                    shared: true
                },

                credits: {
                    enabled: false
                },

                legend: {
                },

                series: [
                    {
                        name: this.props.t('Quantile_995'),
                        type: 'spline',
                        data: null,
                        dashStyle: 'longdash',
                        color: '#979ba2',
                        lineWidth: 1,
                        marker: {
                            enabled: false,
                            symbol: 'diamond'
                        }
                    },
                    {
                        name: this.props.t('Quantile_95'),
                        type: 'spline',
                        data: null,
                        color: '#979ba2',
                        lineWidth: 1,
                        marker: {
                            enabled: false,
                            symbol: 'triangle'
                        }
                    },
                    {
                        name: this.props.t('Median'),
                        type: 'spline',
                        data: null,
                        color: '#2e454f',
                        lineWidth: 1,
                        marker: {
                            enabled: false,
                            symbol: 'circle'
                        }
                    },
                    {
                        name: this.props.t('Quantile_5'),
                        type: 'spline',
                        data: null,
                        color: '#979ba2',
                        lineWidth: 1,
                        marker: {
                            enabled: false,
                            symbol: 'triangle'
                        }
                    },
                    {
                        name: this.props.t('Quantile_05'),
                        type: 'spline',
                        data: null,
                        dashStyle: 'longdash',
                        color: '#979ba2',
                        lineWidth: 1,
                        marker: {
                            enabled: false,
                            symbol: 'diamond'
                        }
                    }
                ]
            },
            realEstateChartOptions: {
                exporting: {
                    buttons: {
                        contextButton: {
                            menuItems: ['printChart', 'separator', 'downloadPNG', 'downloadJPEG', 'downloadCSV', 'downloadXLS']
                        }
                    },
                    filename: this.props.t('RealEstatePricesSimulation')
                },

                chart: {
                    zoomType: 'x'
                },

                title: {
                    text: null,
                    style: { "color": "#2F444E", "fontSize": "18px", "fontWeight": "bold" }
                },

                xAxis: {
                    type: 'datetime',
                    title: null
                },

                yAxis: {
                    title: null
                },
                tooltip: {
                    pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y:.2f}</b><br/>',
                    crosshairs: true,
                    shared: true
                },

                credits: {
                    enabled: false
                },

                legend: {
                },

                series: [
                    {
                        name: this.props.t('Quantile_995'),
                        type: 'spline',
                        data: null,
                        dashStyle: 'longdash',
                        color: '#979ba2',
                        lineWidth: 1,
                        marker: {
                            enabled: false,
                            symbol: 'diamond'
                        }
                    },
                    {
                        name: this.props.t('Quantile_95'),
                        type: 'spline',
                        data: null,
                        color: '#979ba2',
                        lineWidth: 1,
                        marker: {
                            enabled: false,
                            symbol: 'triangle'
                        }
                    },
                    {
                        name: this.props.t('Median'),
                        type: 'spline',
                        data: null,
                        color: '#2e454f',
                        lineWidth: 1,
                        marker: {
                            enabled: false,
                            symbol: 'circle'
                        }
                    },
                    {
                        name: this.props.t('Quantile_5'),
                        type: 'spline',
                        data: null,
                        color: '#979ba2',
                        lineWidth: 1,
                        marker: {
                            enabled: false,
                            symbol: 'triangle'
                        }
                    },
                    {
                        name: this.props.t('Quantile_05'),
                        type: 'spline',
                        data: null,
                        dashStyle: 'longdash',
                        color: '#979ba2',
                        lineWidth: 1,
                        marker: {
                            enabled: false,
                            symbol: 'diamond'
                        }
                    }
                ]
            }
        }
    }

    _isMounted = false;
    dirtyChart = null;

    componentDidMount() {

        Localization.onLanguageChanged(this.onLanguageChanged);

        this._isMounted = true;
        this.props.init();

        if (this.state.calculationId != null)
            this.props.getCalculationResults(this.state.calculationId);
    }

    componentWillUnmount() {

        Localization.offLanguageChanged(this.onLanguageChanged);
        this._isMounted = false;
    }

    componentDidUpdate(prevProps) {

        if (this.props.isError === true)
            return;

        if (prevProps.isLoaded === this.props.isLoaded && this.props.isLoaded === true)
            return;

        /////////////////////////////////////////////////////////
        var updateState = {};
        var isUpdateRequired = false;

        if (prevProps.ditryPriceData != this.props.ditryPriceData && this.props.ditryPriceData != null) {
            var dirty_price_data_x = [];
            var clean_price_data_y = [];
            var dirty_price_data_y = [];

            this.props.ditryPriceData.forEach((item) => {
                dirty_price_data_x.push(item[0]);
                clean_price_data_y.push(item[1]);
                dirty_price_data_y.push(item[2]);
            });

            var dirtyPriceOpts = { ...this.state.dirtyPriceChartOptions };
            dirtyPriceOpts.xAxis.categories = dirty_price_data_x;
            dirtyPriceOpts.series[0].data = dirty_price_data_y;
            dirtyPriceOpts.series[1].data = clean_price_data_y;

            updateState.dirtyPriceChartOptions = dirtyPriceOpts;
            isUpdateRequired = true;
        }
        ////////////////////////////////////////////////////////

        if (prevProps.depreciationData != this.props.depreciationData && this.props.depreciationData != null) {
            var coupon_data = [];
            var advance_data = [];
            var planned_prepayment_data = [];

            this.props.depreciationData.forEach((item) => {
                coupon_data.push([item[item.length - 1], item[2]]);
                advance_data.push([item[item.length - 1], item[4]]);
                planned_prepayment_data.push([item[item.length - 1], item[3] - item[4]]);
            });

            var cashflowChartOpts = { ...this.state.cashflowChartOptions };
            cashflowChartOpts.series[0].data = coupon_data;
            cashflowChartOpts.series[1].data = advance_data;
            cashflowChartOpts.series[2].data = planned_prepayment_data;

            updateState.cashflowChartOptions = cashflowChartOpts;
            isUpdateRequired = true;
        }
        ////////////////////////////////////////////////////////
        if (prevProps.cprData != this.props.cprData && this.props.cprData != null) {
            var cpr_range_99 = [];
            var cpr_average = [];
            var cpr_range_1 = [];

            this.props.cprData.forEach((item) => {
                cpr_range_99.push([item[0], item[3]]);
                cpr_average.push([item[0], item[2]]);
                cpr_range_1.push([item[0], item[1]]);
            });

            var cprChartOptions = { ...this.state.cprChartOptions };
            cprChartOptions.series[0].data = cpr_range_99;
            cprChartOptions.series[1].data = cpr_average;
            cprChartOptions.series[2].data = cpr_range_1;

            updateState.cprChartOptions = cprChartOptions;
            isUpdateRequired = true;
        }

        ////////////////////////////////////////////////////////
        if (prevProps.spotBidData != this.props.spotBidData && this.props.spotBidData != null) {
            var spot_bid_range_995 = [];
            var spot_bid_range_95 = [];
            var spot_bid_scenario_bid = [];
            var spot_bid_range_5 = [];
            var spot_bid_range_05 = [];

            this.props.spotBidData.forEach((item) => {
                spot_bid_range_995.push([item[0], item[1]]);
                spot_bid_range_95.push([item[0], item[2]])
                spot_bid_scenario_bid.push([item[0], item[3]])
                spot_bid_range_5.push([item[0], item[4]])
                spot_bid_range_05.push([item[0], item[5]])
            });

            var shortRateChartOpts = { ...this.state.shortRateChartOptions };
            shortRateChartOpts.series[0].data = spot_bid_range_995;
            shortRateChartOpts.series[1].data = spot_bid_range_95;
            shortRateChartOpts.series[2].data = spot_bid_scenario_bid;
            shortRateChartOpts.series[3].data = spot_bid_range_5;
            shortRateChartOpts.series[4].data = spot_bid_range_05;

            updateState.shortRateChartOptions = shortRateChartOpts;
            isUpdateRequired = true;
        }
        ////////////////////////////////////////////////////////
        if (prevProps.ratesBidData != this.props.ratesBidData && this.props.ratesBidData != null) {
            var rates_bid_range_995 = [];
            var rates_bid_range_95 = [];
            var rates_bid_scenario_bid = [];
            var rates_bid_range_5 = [];
            var rates_bid_range_05 = [];

            this.props.ratesBidData.forEach((item) => {
                rates_bid_range_995.push([item[0], item[1]]);
                rates_bid_range_95.push([item[0], item[2]])
                rates_bid_scenario_bid.push([item[0], item[3]])
                rates_bid_range_5.push([item[0], item[4]])
                rates_bid_range_05.push([item[0], item[5]])
            });

            var mortageChartOpts = { ...this.state.mortageChartOptions };
            mortageChartOpts.series[0].data = rates_bid_range_995;
            mortageChartOpts.series[1].data = rates_bid_range_95;
            mortageChartOpts.series[2].data = rates_bid_scenario_bid;
            mortageChartOpts.series[3].data = rates_bid_range_5;
            mortageChartOpts.series[4].data = rates_bid_range_05;

            updateState.mortageChartOptions = mortageChartOpts;
            isUpdateRequired = true;
        }
        ////////////////////////////////////////////////////////
        if (prevProps.priceBidData != this.props.priceBidData && this.props.priceBidData != null) {
            var price_bid_range_995 = [];
            var price_bid_range_95 = [];
            var price_bid_scenario_bid = [];
            var price_bid_range_5 = [];
            var price_bid_range_05 = [];

            this.props.priceBidData.forEach((item) => {
                price_bid_range_995.push([item[0], item[1]]);
                price_bid_range_95.push([item[0], item[2]])
                price_bid_scenario_bid.push([item[0], item[3]])
                price_bid_range_5.push([item[0], item[4]])
                price_bid_range_05.push([item[0], item[5]])
            });

            var realEstateChartOpts = { ...this.state.realEstateChartOptions };
            realEstateChartOpts.series[0].data = price_bid_range_995;
            realEstateChartOpts.series[1].data = price_bid_range_95;
            realEstateChartOpts.series[2].data = price_bid_scenario_bid;
            realEstateChartOpts.series[3].data = price_bid_range_5;
            realEstateChartOpts.series[4].data = price_bid_range_05;

            updateState.realEstateChartOptions = realEstateChartOpts;
            isUpdateRequired = true;
        }
        ////////////////////////////////////////////////////////
        if (isUpdateRequired && this._isMounted) {

            this.setState({
                ...updateState
            });
        }
    }

    onLanguageChanged = (lng: any) => {

        setHighchartsLocalization(Highcharts, this.props.t);
        this.updateHighchartsOptions();
    }

    updateHighchartsOptions = () => {

        var dirtyPriceOptions = { ...this.state.dirtyPriceChartOptions }
        var cashflowOptions = { ...this.state.cashflowChartOptions };
        var cprOptions = { ...this.state.cprChartOptions };
        var shortRateOptions = { ...this.state.shortRateChartOptions };
        var mortgageOptions = { ...this.state.mortageChartOptions };
        var realestateOptions = { ...this.state.realEstateChartOptions };

        //dirtyPriceOptions.exporting.buttons.contextButton.menuItems.text = this.props.t('printChart');
        dirtyPriceOptions.exporting.filename = this.props.t('PriceDistribution');
        dirtyPriceOptions.title.text = this.props.t('PriceDistribution');
        dirtyPriceOptions.tooltip.headerFormat = '<table><tr><td>' + this.props.t('Price') + ':</td><td style="text-align:end"><b>{point.key:.3f}</b></td></tr>';
        dirtyPriceOptions.series[0].name = this.props.t('DirtyPriceProbability');
        dirtyPriceOptions.series[1].name = this.props.t('ClearPriceProbability');

        /////////
        cashflowOptions.exporting.filename = this.props.t('CashflowMln');
        cashflowOptions.xAxis.title = this.props.t('Date');
        cashflowOptions.series[0].name = this.props.t('Coupon');
        cashflowOptions.series[1].name = this.props.t('UnplannedPrepayment');
        cashflowOptions.series[2].name = this.props.t('PlannedRepayment');

        ////////
        cprOptions.exporting.filename = this.props.t('CPR');
        cprOptions.series[0].name = this.props.t('Level_99');
        cprOptions.series[1].name = this.props.t('AverageCPR');
        cprOptions.series[2].name = this.props.t('Level_1');


        ///////
        shortRateOptions.exporting.filename = this.props.t('ShortRateSimulation');
        shortRateOptions.series[0].name = this.props.t('Quantile_995');
        shortRateOptions.series[1].name = this.props.t('Quantile_95');
        shortRateOptions.series[2].name = this.props.t('Median');
        shortRateOptions.series[3].name = this.props.t('Quantile_5');
        shortRateOptions.series[4].name = this.props.t('Quantile_05');

        ///////
        mortgageOptions.exporting.filename = this.props.t('MortgageRateSimulation');
        mortgageOptions.series[0].name = this.props.t('Quantile_995');
        mortgageOptions.series[1].name = this.props.t('Quantile_95');
        mortgageOptions.series[2].name = this.props.t('Median');
        mortgageOptions.series[3].name = this.props.t('Quantile_5');
        mortgageOptions.series[4].name = this.props.t('Quantile_05');


        ///////
        realestateOptions.exporting.filename = this.props.t('RealEstatePricesSimulation');
        realestateOptions.series[0].name = this.props.t('Quantile_995');
        realestateOptions.series[1].name = this.props.t('Quantile_95');
        realestateOptions.series[2].name = this.props.t('Median');
        realestateOptions.series[3].name = this.props.t('Quantile_5');
        realestateOptions.series[4].name = this.props.t('Quantile_05');

        this.setState({
            dirtyPriceChartOptions: dirtyPriceOptions,
            cashflowChartOptions: cashflowOptions,
            cprChartOptions: cprOptions,
            shortRateChartOptions: shortRateOptions,
            mortageChartOptions: mortgageOptions,
            realEstateChartOptions: realestateOptions
        });
    }

    correctByType = (name, value) => {

        if (name == 'CouponType') {
            switch (value) {
                case '1':
                    {
                        return this.props.t('FixedCoupon');
                    }
                    break;
                case '2':
                    {
                        return this.props.t('FloatCoupon');
                    }
                    break;
                case '3':
                    {
                        return this.props.t('AHMLCoupon');
                    }
                    break;
                case '4':
                    {
                        return this.props.t('ThirdCoupon');
                    }
                    break;
                case '5':
                    {
                        return this.props.t('LossesCoupon');
                    }
                    break;
                case '6':
                    {
                        return this.props.t('KeyrateAndPremium');
                    }
                    break;
            }
        }

        return value;
    }

    handleMainTabChange = (event, newValue) => {
        this.setState({ selectedTabMain: newValue });
    };

    handleFlowsTabChange = (event, newValue) => {
        this.setState({ selectedTabFlows: newValue });
    };

    handleMacroTabChange = (event, newValue) => {
        this.setState({ selectedTabMacro: newValue });
    };

    handleToList = () => {

        var instrument = Instruments().find(x => x.area === 'Calculator');
        if (instrument != null) {
            var action = instrument.actions.find(x => x.id === 'calculations');
            if (action != null) {
                this.props.router.navigate(action.url);
            }
        }
    }

    handleExtraClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleExtraClose = () => {
        this.setState({ anchorEl: null });
    };

    handleRecalculate = () => {
        this.handleExtraClose();
        this.props.router.navigate('/instruments/calculator/recalculate/' + this.props.calculationID + '/complex');
    }
    handleDuplicate = () => {
        this.handleExtraClose();
        this.props.router.navigate('/instruments/calculator/duplicate/' + this.props.calculationID + '/complex');
    }

    handleExportResults = () => {
        this.handleExtraClose();

        (async () => {

            var result = await CalculatorService.downloadCalculationResults(this.props.calculationID);
            if (!result.hasErrors) {

                var blob = b64toBlob(result.value, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');

                fileDownload(blob, this.props.calculationName + '.xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
            }
        })();


        //window.open('/api/Calculator/DownloadCalculationResults?calculationId=' + this.props.calculationID, '_blank');

        /*
        var sheets = [];

        if (this.props.averageScenarioEstimation != null) {
            var averageScenarioEstimation = this.props.averageScenarioEstimation.map((item, index, arr) => {

                if (item.isRepaid == true)
                    return;

                var outTable = [];

                let title = ((item.value != null) ? ((this.props.parameters.calculationType == CalculationType.TYPE_OAS ? this.props.t('OASValue') : + this.props.t('PriceValue')) + ': ' + item.value) : "") +
                    ((item.trancheID != null) ? (' ' + this.props.t('Tranche') + item.trancheID) : "") +
                    ((item.isRepaid) ? (' ' + this.props.t('Repayed')) : "");

                outTable.push([]);
                if (title != null || title.length != 0) {
                    outTable.push([title]);
                    outTable.push([]);
                }


                var row = [""].concat(item.headers.map((header, index) => {
                    return (header.item1 != null) ? header.item1 + " " + this.props.t('BasisPoints') : this.props.t('Middle');
                }));

                outTable.push(row);

                var rows = item.result.map((row, index) => {

                    var line = [row.name].concat(row.value.map((cell, index) => {
                        return Localization.FormatNumberString(cell);
                    }));

                    return line;
                });

                return outTable.concat(rows);
            });

            var merged = [].concat.apply([], averageScenarioEstimation);

            sheets.push({ key: this.props.t('AverageScenarioEstimation'), value: merged });
        }

        if (this.props.modelCalculationParameters != null) {
            var modelCalculationParameters = this.props.modelCalculationParameters.map((item, index, arr) => {
                return [item.name, item.value];
            });


            sheets.push({ key: this.props.t('ModelCalculationParameters'), value: modelCalculationParameters });
        }

        if (this.props.bondCalculationParameters != null) {
            var bondCalculationParameters = this.props.bondCalculationParameters.map((item, index, arr) => {
                return [item.name, this.correctByType(item.name, item.value)];
            });


            sheets.push({ key: this.props.t('BondCalculationParameters'), value: bondCalculationParameters });
        }

        if (this.props.portfolioSummary != null) {
            var portfolioSummary = this.props.portfolioSummary.map((item, index, arr) => {

                return [item.name, Localization.FormatNumberString(item.value, 0, 2)];
            });


            sheets.push({ key: this.props.t('PortfolioSummary_Short'), value: portfolioSummary });
        }

        if (this.props.depreciationData != null) {

            var headerRow = [this.props.t('Date'), this.props.t('Nominal'), this.props.t('Coupon'), this.props.t('UnplannedPrepayment'), this.props.t('CPRPrc'), this.props.t('RatePrc')];

            var depreciationData = this.props.depreciationData.map((item) => {

                return item.filter((row, index, arr) => { return index != 4 && index != arr.length - 1 }).map((cell, index) => {
                    return (index == 0) ? Localization.FormatDateStringLocale(cell) : Localization.FormatNumberString(cell, 0, 2);
                })
            });

            depreciationData = [headerRow].concat(depreciationData);

            sheets.push({ key: this.props.t('Repayment'), value: depreciationData });
        }

        if (this.props.macroeconomicsData != null) {

            var headerRow = [this.props.t('Date'), this.props.t('RiskFreeProfitability_short'), this.props.t('ScenarioProfitability'), this.props.t('ScenarioRate'), this.props.t('MortgageRate'), this.props.t('GrowthInRealEstate_short')];

            var macroeconomicsData = this.props.macroeconomicsData.map((item, index, arr) => {

                return item.filter((item, index, arr) => { return index != arr.length - 1 }).map((cell, index) => {
                    return (index == 0) ? Localization.FormatDateStringLocale(cell) : Localization.FormatNumberString(cell);
                })
            });

            macroeconomicsData = [headerRow].concat(macroeconomicsData);

            sheets.push({ key: this.props.t('Macroeconomics'), value: macroeconomicsData });
        }

        var wb = XLSX.utils.book_new();

        sheets.forEach((item) => {
            var ws = XLSX.utils.aoa_to_sheet(item.value);
            XLSX.utils.book_append_sheet(wb, ws, item.key);
        });

        XLSX.writeFile(wb, this.props.calculationName + '.xlsx');
        */
    }

    handleExportLLD = () => {
        this.handleExtraClose();

        (async () => {
            var result = await CalculatorService.getLLD(this.props.calculationID);
            if (!result.hasErrors) {

                var blob = b64toBlob(result.value, 'application/x-msdownload');

                fileDownload(blob, this.props.calculationName + '_LLD.csv', 'application/x-msdownload');
            }
        })();
    }

    render() {

        const { classes, t } = this.props;

        return (
            <Container maxWidth="xxl" sx={{ height: '100%' }}>
                <Spinner show={this.props.operationLoading} fullScreen content={t('Loading')} />
                {
                    (this.props.isError === true) && (
                        <Paper sx={{ padding: { sm: '0.5rem', md: '3rem' }, marginTop: '1rem' }} hidden={this.props.isLoaded != true}>
                            <h4>{this.props.calculationName}</h4>
                            <h6 className={classes.description}>{this.props.calculationDescription}</h6>
                            Error here
                        </Paper>
                    )
                }
                {
                    (this.props.isError == false) && (
                        <div hidden={this.props.isLoaded != true}>
                            <Button className={classes.toListButton} variant="contained" color="secondary" onClick={this.handleToList}>
                                {t('ToList')}
                            </Button>

                            <Paper sx={{ padding: { sm: '0.5rem', md: '3rem' }, marginTop: '1rem' }}>
                                <Grid container>
                                    <Grid xs={10}>
                                        <h4>{this.props.calculationName}</h4>
                                        <h6 className={classes.description}>{this.props.calculationDescription}</h6>
                                    </Grid>
                                    <Grid xs={2} className="text-right">
                                        <Button aria-controls="extra-menu" aria-haspopup="true" onClick={this.handleExtraClick}>
                                            {t('Actions')}
                                        </Button>
                                        <Menu
                                            id="extra-menu"
                                            anchorEl={this.state.anchorEl}
                                            keepMounted
                                            open={Boolean(this.state.anchorEl)}
                                            onClose={this.handleExtraClose}
                                        >
                                            <MenuItem onClick={this.handleRecalculate}>{t('Recalculate')}</MenuItem>
                                            <MenuItem onClick={this.handleDuplicate}>{t('Duplicate')}</MenuItem>
                                            <Divider />
                                            <MenuItem onClick={this.handleExportResults}>{t('ExportResults')}</MenuItem>
                                            <MenuItem onClick={this.handleExportLLD}>{t('ExportLLD')}</MenuItem>
                                        </Menu>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid md={6}>
                                        <HighchartsReact
                                            ref={x => this.dirtyChart = x}
                                            highcharts={Highcharts}
                                            options={this.state.dirtyPriceChartOptions}
                                        />
                                    </Grid>
                                    <Grid md={6}>
                                        <Tabs
                                            value={this.state.selectedTabMain}
                                            indicatorColor="secondary"
                                            textColor="primary"
                                            onChange={this.handleMainTabChange}
                                            aria-label="calculation parameters"
                                            centered
                                        >
                                            <Tab label={t('Appraisal')} />
                                            <Tab label={t('TechnicalParameters')} />
                                        </Tabs>
                                        <TabPanel value={this.state.selectedTabMain} index={0} className={classes.tabs}>
                                            {
                                                (this.props.averageScenarioEstimation != null) && (this.props.averageScenarioEstimation.map((item, index, arr) => {

                                                    if (item.isRepaid == true)
                                                        return;

                                                    /*
                                                    let columnCount = item.result[0].value.length + 1;
                                                    let nextColumn = (100 / (columnCount + 3));
                                                    let firstColumn = (nextColumn * 3);
                                                    */
                                                    let title = ((item.value != null) ? ((this.props.parameters.calculationType == CalculationType.TYPE_OAS ? this.props.t('OASValue') : this.props.t('PriceValue')) + ' ' + item.value) : "") +
                                                        ((item.trancheID != null) ? (" " + this.props.t('Tranche') + item.trancheID) : "") +
                                                        ((item.isRepaid) ? (" " + this.props.t('Repayed')) : "");

                                                    return (
                                                        <div key={"stat-" + index}>
                                                            <h6>
                                                                {title}
                                                            </h6>
                                                            <Table aria-label="statistics table" className={(index != arr.length - 1) ? classes.marginBottom : ""}>
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell key={"header-main"} /*width={firstColumn + "%"}*/ />
                                                                        {
                                                                            item.headers.map((header, index) => {

                                                                                return (
                                                                                    <TableCell key={"header-" + index} /*width={nextColumn + "%"}*/
                                                                                        className={classes.tableHeader}
                                                                                        align="right">
                                                                                        {(header.item1 != null) ? header.item1 + " " + this.props.t('BasisPoints') : this.props.t('Middle')}
                                                                                    </TableCell>
                                                                                )
                                                                            })
                                                                        }
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {
                                                                        item.result.map((row, index) => {
                                                                            return (
                                                                                <TableRow key={index}>
                                                                                    {
                                                                                        <TableCell key={"row-main-" + index}>
                                                                                            <TooltipInfoLabel key={"tooltip-" + index} content={this.props.t(row.resourceDescription)}>
                                                                                                {this.props.t(row.resourceName)}
                                                                                            </TooltipInfoLabel>
                                                                                        </TableCell>
                                                                                    }
                                                                                    {
                                                                                        row.value.map((cell, index) => (
                                                                                            <TableCell key={"row-" + index} align="right">{Localization.FormatByType(row.type, cell)}</TableCell>
                                                                                        ))
                                                                                    }
                                                                                </TableRow>
                                                                            )
                                                                        })
                                                                    }
                                                                </TableBody>
                                                            </Table>
                                                        </div>
                                                    );
                                                }))
                                            }
                                        </TabPanel>
                                        <TabPanel value={this.state.selectedTabMain} index={1} className={classes.tabs}>
                                            {
                                                (this.props.modelCalculationParameters != null) && (
                                                    <Table aria-label="parameters table">
                                                        <TableBody>
                                                            {
                                                                this.props.modelCalculationParameters.map((item, index) => (

                                                                    <TableRow key={index}>
                                                                        <TableCell>
                                                                            <TooltipInfoLabel content={this.props.t(item.resourceDescription)}>
                                                                                {this.props.t(item.resourceName)}
                                                                            </TooltipInfoLabel>
                                                                        </TableCell>
                                                                        <TableCell>{Localization.FormatByType(item.type, item.value)}</TableCell>
                                                                    </TableRow>
                                                                ))
                                                            }
                                                        </TableBody>
                                                    </Table>
                                                )
                                            }
                                        </TabPanel>
                                    </Grid>
                                </Grid>
                            </Paper>
                            <Paper sx={{ padding: { sm: '0.5rem', md: '3rem' }, marginTop: '1rem' }}>
                                <h4>{t('Parameters')}</h4>

                                <Grid container>
                                    <Grid md={6}>
                                        <h6>{t('BondCalculationParameters')}</h6>
                                        {
                                            (this.props.bondCalculationParameters != null) && (
                                                <Table aria-label="bond parameters table">
                                                    <TableBody>
                                                        {
                                                            this.props.bondCalculationParameters.map((item, index) => (

                                                                <TableRow key={index}>
                                                                    <TableCell>
                                                                        <TooltipInfoLabel content={this.props.t(item.resourceDescription)}>
                                                                            {this.props.t(item.resourceName)}
                                                                        </TooltipInfoLabel>
                                                                    </TableCell>
                                                                    <TableCell>{Localization.FormatByType(item.type, this.correctByType(item.resourceName, item.value))}</TableCell>
                                                                </TableRow>
                                                            ))
                                                        }
                                                    </TableBody>
                                                </Table>
                                            )
                                        }
                                    </Grid>
                                    <Grid md={6}>
                                        <h6>{t('PortfolioSummary')}</h6>
                                        {
                                            (this.props.portfolioSummary != null) && (
                                                <Table aria-label="portfolio summary table">
                                                    <TableBody>
                                                        {
                                                            this.props.portfolioSummary.map((item, index) => (

                                                                <TableRow key={index}>
                                                                    <TableCell>
                                                                        <TooltipInfoLabel content={this.props.t(item.resourceDescription)}>
                                                                            {this.props.t(item.resourceName)}
                                                                        </TooltipInfoLabel>
                                                                    </TableCell>
                                                                    <TableCell align="right">{Localization.FormatNumberString(item.value)}</TableCell>
                                                                </TableRow>
                                                            ))
                                                        }
                                                    </TableBody>
                                                </Table>
                                            )
                                        }
                                    </Grid>
                                </Grid>
                            </Paper>

                            <Paper sx={{ padding: { sm: '0.5rem', md: '3rem' }, marginTop: '1rem' }}>
                                <h4>{t('Flows')}</h4>

                                <Grid container>
                                    <Grid md={6}>
                                        <Tabs
                                            value={this.state.selectedTabFlows}
                                            indicatorColor="secondary"
                                            textColor="primary"
                                            onChange={this.handleFlowsTabChange}
                                            aria-label="flows"
                                            centered
                                        >
                                            <Tab label={t('CashflowMln')} />
                                            <Tab label={t('CPRPrc')} />
                                        </Tabs>
                                        <TabPanel value={this.state.selectedTabFlows} index={0}>
                                            <HighchartsReact
                                                highcharts={Highcharts}
                                                options={this.state.cashflowChartOptions}
                                            />
                                        </TabPanel>
                                        <TabPanel value={this.state.selectedTabFlows} index={1}>
                                            <HighchartsReact
                                                highcharts={Highcharts}
                                                options={this.state.cprChartOptions}
                                            />
                                        </TabPanel>
                                    </Grid>
                                    <Grid md={6} className={classes.overflow}>
                                        {
                                            (this.props.depreciationData != null) && (
                                                <Table aria-label="flows table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell key={"flowHeader-1"} className={classes.tableHeader}>
                                                                <TooltipInfoLabel content={t('CouponDateDescription')}>
                                                                    {t('Date')}
                                                                </TooltipInfoLabel>
                                                            </TableCell>
                                                            <TableCell key={"flowHeader-2"} className={classes.tableHeader} align="right">
                                                                <TooltipInfoLabel content={t('NominalDescription')}>
                                                                    {t('Nominal')}
                                                                </TooltipInfoLabel>
                                                            </TableCell>
                                                            <TableCell key={"flowHeader-3"} className={classes.tableHeader} align="right">
                                                                <TooltipInfoLabel content={t('CouponDescription')}>
                                                                    {t('Coupon')}
                                                                </TooltipInfoLabel>
                                                            </TableCell>
                                                            <TableCell key={"flowHeader-4"} className={classes.tableHeader} align="right">
                                                                <TooltipInfoLabel content={t('DepreciationDescription')}>
                                                                    {t('Depreciation')}
                                                                </TooltipInfoLabel>
                                                            </TableCell>
                                                            <TableCell key={"flowHeader-5"} className={classes.tableHeader} align="right">
                                                                <TooltipInfoLabel content={t('CPRDescription')}>
                                                                    {t('CPRPrc')}
                                                                </TooltipInfoLabel>
                                                            </TableCell>
                                                            <TableCell key={"flowHeader-6"} className={classes.tableHeader} align="right">
                                                                <TooltipInfoLabel content={t('RateDescription')}>
                                                                    {t('RatePrc')}
                                                                </TooltipInfoLabel>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {this.props.depreciationData.map((row, index) => (
                                                            <TableRow key={index}>
                                                                {
                                                                    row.map((cell, index) => {
                                                                        if (index == 4 || index == row.length - 1)
                                                                            return;

                                                                        return (index == 0) ? (
                                                                            <TableCell key={"flowRow-" + index}>{Localization.FormatDateStringLocale(cell)}</TableCell>
                                                                        ) : (
                                                                            <TableCell key={"flowRow-" + index} align="right">{Localization.FormatNumberString(cell)}</TableCell>
                                                                        )
                                                                    })
                                                                }
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            )
                                        }
                                    </Grid>
                                </Grid>
                            </Paper>

                            <Paper sx={{ padding: { sm: '0.5rem', md: '3rem' }, marginTop: '1rem' }}>
                                <h4>{t('MacroeconomicalIndices')}</h4>

                                <Grid container>
                                    <Grid md={6}>
                                        <Tabs
                                            value={this.state.selectedTabMacro}
                                            indicatorColor="secondary"
                                            textColor="primary"
                                            onChange={this.handleMacroTabChange}
                                            aria-label="flows"
                                            centered
                                        >
                                            <Tab label={t('ShortRateSimulationPer')} />
                                            <Tab label={t('MortgageRateSimulationPer')} />
                                            <Tab label={t('RealEstatePricesSimulationPer')} />
                                        </Tabs>
                                        <TabPanel value={this.state.selectedTabMacro} index={0}>
                                            <HighchartsReact
                                                highcharts={Highcharts}
                                                options={this.state.shortRateChartOptions}
                                            />
                                        </TabPanel>
                                        <TabPanel value={this.state.selectedTabMacro} index={1}>
                                            <HighchartsReact
                                                highcharts={Highcharts}
                                                options={this.state.mortageChartOptions}
                                            />
                                        </TabPanel>
                                        <TabPanel value={this.state.selectedTabMacro} index={2}>
                                            <HighchartsReact
                                                highcharts={Highcharts}
                                                options={this.state.realEstateChartOptions}
                                            />
                                        </TabPanel>
                                    </Grid>
                                    <Grid md={6} className={classes.overflow}>
                                        {
                                            (this.props.macroeconomicsData != null) && (
                                                <Table aria-label="flows table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell key={"flowHeader-1"} className={classes.tableHeader}>
                                                                <TooltipInfoLabel content={t('CouponDateDescription')}>
                                                                    {t('Date')}
                                                                </TooltipInfoLabel>
                                                            </TableCell>
                                                            <TableCell key={"flowHeader-2"} className={classes.tableHeader} align="right">
                                                                <TooltipInfoLabel content={t('RiskFreeProfitabilityDescription')}>
                                                                    {t('RiskFreeProfitability_short')}
                                                                </TooltipInfoLabel>
                                                            </TableCell>
                                                            <TableCell key={"flowHeader-3"} className={classes.tableHeader} align="right">
                                                                <TooltipInfoLabel content={t('ScenarioProfitabilityDescription')}>
                                                                    {t('ScenarioProfitability')}
                                                                </TooltipInfoLabel>
                                                            </TableCell>
                                                            <TableCell key={"flowHeader-4"} className={classes.tableHeader} align="right">
                                                                <TooltipInfoLabel content={t('ScenarioRateDescription')}>
                                                                    {t('ScenarioRate')}
                                                                </TooltipInfoLabel>
                                                            </TableCell>
                                                            <TableCell key={"flowHeader-5"} className={classes.tableHeader} align="right">
                                                                <TooltipInfoLabel content={t('MortgageRateDescription')}>
                                                                    {t('MortgageRate')}
                                                                </TooltipInfoLabel>
                                                            </TableCell>
                                                            <TableCell key={"flowHeader-6"} className={classes.tableHeader} align="right">
                                                                <TooltipInfoLabel content={t('GrowthInRealEstateDescription')}>
                                                                    {t('GrowthInRealEstate_short')}
                                                                </TooltipInfoLabel>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {this.props.macroeconomicsData.map((row, index) => (
                                                            <TableRow key={index}>
                                                                {
                                                                    row.map((cell, index) => {
                                                                        if (index == row.length - 1)
                                                                            return;

                                                                        return (index == 0) ? (
                                                                            <TableCell key={"flowRow-" + index}>{Localization.FormatDateStringLocale(cell)}</TableCell>
                                                                        ) : (
                                                                            <TableCell key={"flowRow-" + index} align="right">{Localization.FormatNumberString(cell)}</TableCell>
                                                                        )
                                                                    })
                                                                }
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            )
                                        }
                                    </Grid>
                                </Grid>
                            </Paper>
                        </div>
                    )
                }
            </Container >
        );
    }
}

var component = connect(
    state => state.calculator, // Selects which state properties are merged into the component's props
    CalculatorStore.actionCreators // Selects which action creators are merged into the component's props
)(CalculationResultsPage);

export default withStyles(styles)(withRouter(withTranslation('translations')(component)));