import React, { useState, useEffect, useLayoutEffect, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Theme } from '@mui/material/styles';
import { DealType, EmployeeRole, Permissions, IterationValidationState, efDealValidationState, IterationStatus, CouponType } from '@Components/Definitions';
import { makeStyles, createStyles } from '@mui/styles';
import { useTranslation } from "react-i18next";
import { isValid, parseISO } from "date-fns";
import style from '@Styles/domrf-theme.module.scss';
import { toUnixDate, hexToRgbA, getType } from '@Utilities';
import DealParametersPanel from "../panels/DealParametersPanel";
import KeyRatePanel from "../../../common/KeyRatePanel";
import { Box, Divider, IconButton, Tooltip, Typography, useTheme, alpha, Stack, Grid, Button } from '@mui/material';
import { CloseFullscreen, SaveAltOutlined, SettingsBackupRestoreOutlined, WarningAmber } from '@mui/icons-material';
import SpinnerBars from '@Components/shared/SpinnerBars';
import useLocalStorage from '@Components/hook/useLocalStorage';
import RestoreIcon from '@mui/icons-material'
import { ReactComponent as ExcelIcon } from "../../../../../src/images/excel-icon.svg"
import clsx from 'clsx';
import { actionCreators as actionSecuritization } from '@Base/store/SecuritizationStore'

const useStyles = makeStyles((theme: Theme) => createStyles({
    error: {
        position: 'relative',
        '&:before': {
            content: '""',
            position: 'absolute',
            left: 0, right: 0, bottom: '6px', top: '6px',
            backgroundColor: '#FF000012',
        }
    },
    edited: {
        position: 'relative',
        '&:before': {
            content: '""',
            position: 'absolute',
            left: 0, right: 0, bottom: '6px', top: '6px',
            backgroundColor: hexToRgbA(style.colorPrimary2, 0.1)
        }
    },
    errorMessage: {
        margin: '0 0.5rem',
        color: style.colorError,
    },
    globalRoot: {
        '& *': {
            userSelect: 'none',
        },
    },
    tabHeaderButton: {
        padding: '2px 8px'
    },
    paramsTab: {
        // height: '100%',

        '&>*': {
            color: style.colorPrimary3_120,
            padding: '0px 10px 0px 10px',
            fontSize: '1rem',
            letterSpacing: 'initial',
            // lineHeight: '1.2rem',
            // textTransform: 'uppercase',

            '&:hover': {
                color: style.colorPrimary3
            },
        },
        '&::after': {
            content: '""',
            position: 'absolute',
            top: 6, bottom: 6, right: 0,
            width: '2px',
            borderRight: `solid 1px ${style.colorPrimary3_120}`,
        }
        // 
        // cursor: 'pointer'
    },
    activeTab: {
        '& *': {
            color: style.colorPrimary1,
            fontWeight: 'bolder'
        }
    },
    scrollablePanel: {
        position: 'absolute', left: '8px', right: 0, top: 0, bottom: 0,
        overflowY: 'auto',
        '&::-webkit-scrollbar': {

            width: '4px',
        },
        // '&::-webkit-scrollbar:disabled':{
        //     opacity:0,
        // },
        '&:hover': {
            '&::-webkit-scrollbar-track:disabled': {
                backgroundColor: '#0000',
            },
            '&::-webkit-scrollbar-thumb:disabled': {
                background: '#0000',
            },
            '&::-webkit-scrollbar-track': {
                backgroundColor: '#00000008',
            },
            '&::-webkit-scrollbar-thumb': {
                background: '#00000018',
            }
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'unset',
            borderRadius: '3px',
            // border: 'solid 1px #00000000',
            backgroundColor: '#00000000',
            transition: 'all 0.3s ease'
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#0000',
            borderRadius: '3px',
            height: '4px'
        },
    },
    dealsContainer: {
        display: 'flex',
        minHeight: '200px',
        padding: '15px',
        background: `repeating-linear-gradient(45deg, ${style.colorPrimary3_5}, ${style.colorPrimary3_5} 10px, white 10px, white 20px)`
    },
    deals: {
        display: 'flex',
        overflowX: 'auto',
        width: '100%',
        //paddingTop: '15px',
        //paddingBottom: '15px',
        marginRight: '15px'
    },
    dealCard: {
        display: 'flex',
        flexDirection: 'column',
        minWidth: '280px',
        marginRight: '15px'
    },
    addDealButton: {
        alignSelf: 'center'
        //height: '200px',
        //width: '200px'
    },
    itemActionArea: {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
        justifyContent: 'flex-start'
    },
    itemContent: {
        display: 'flex',
        flex: '1 1 auto',
        height: '100%'
    },
    buttonContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    cardSelect: {
        border: '1px solid ' + style.colorPrimary1
    },
    layoutGrid: {
        '& .react-grid-item': {
            padding: '0.2rem'
        }
    },
    infoPanel: {
        display: 'flex',
        justifyContent: 'end',
    },
    panel: {
        //overflow: 'auto'
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
    },
    hideButton: {
        position: 'absolute',
        cursor: 'pointer',
        fontSize: '20px',
        top: '0',
        right: '5px',
        zIndex: '1000'
    },
    hidden: {
        display: 'none'
    },
    tabContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        paddingTop: '1rem',
        flexGrow: 1,
    },
    tabContainerNoData: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: '1rem',
        flexGrow: 1,
    },
    labelColor: {
        color: style.colorPrimary3_120,
        userSelect: 'none',
    },
    colContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    paper: {
        padding: '0.5rem 0',
        flex: '1 1 auto'
    },
    fullHeightPaper: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        padding: '0.5rem 0',
        flex: '1 1 auto'
    },
    parametersPanel: {
        display: 'flex',
        flexDirection: 'column',

        [theme.breakpoints.up('lg')]: {
            height: '100%'
        },
        [theme.breakpoints.down('lg')]: {
            height: '400px'
        }
    },
    dataBlock: {
        position: 'absolute', left: 0, right: 0, top: 0, bottom: 0,
        // margin: 0,
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        maxWidth: '472px',
        margin: '0 auto',
    },
    header: {
        marginLeft: '0.5rem',
        height: '30px', minHeight: '30px', maxHeight: '30px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        overflow: 'hidden',
    },
    headerButtonsBlock: {
        marginLeft: '0.5rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        flexWrap: 'nowrap',
    },
    headerTabsBlock: {
        display: 'flex',
        flexDirection: 'row',
    }
}));

const DealParametersTab = ({
    pool,
    iteration,
    iterationOnlineChanges,
    editPermission,
}) => {
    // console.log('deal', deal)
    // console.log('deal.structure', deal?.structure)
    // console.log('iteration', iteration)
    // console.log('deal,pool,iteration,iterationOnlineChanges,editPermission', deal,
    // pool,
    // iteration,
    // iterationOnlineChanges,
    // editPermission)
    const [deal, setDeal] = useState(null)
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const {
        deal: currentDeal = null,
        selectedNode = null,
        proceededIteration = null,
        pricingResult = null } = useSelector((state: any) => state.securitization)
    // console.log('currentDeal', currentDeal)
    // console.log('selectedNode', selectedNode)
    const submitForm = useRef(null);

    const [activeTab, setActiveTab] = useLocalStorage(null, 'Securitization/Dial/Pricing/innerTab') //useState(null)

    const [localIteration, setLocalIteration] = useState({ ...iteration });
    const [showSpinner, setShowSpinner] = useState(false);
    const [keyrateChartData, setKeyrateChartData] = useState(null);
    //const updatedFields = useRef({});
    const theme = useTheme()
    /*
    const [formIsValid, setFormIsValid] = useState(false)
    const [needRecalc, setNeedRecalc] = useState(false)
    */
    // const [newPoolReceived, setNewPoolReceived] = useState(false);

    var structure = null
    // console.log('deal', deal, pricingResult, proceededIteration)
    if (selectedNode != null)
        structure = selectedNode?.structure;

    useEffect(() => {
        if (currentDeal?.deal != null)
            setDeal(currentDeal.deal)
    }, [currentDeal])

    /*
    const mergeKeyrates = (source, dest = []) => {
        var i = 0;
        for (; i < source?.length; i++) {

            if (dest?.length == i) {
                dest.push({ ...dest[0] });
            }
            dest[i].date = source[i].x;
            dest[i].key = source[i].y;
        }

        if (dest?.length - 1 > i) {
            dest.splice(i, dest.length - i);
        }
    }
    */

    /*
    const updateIterationByOnlineContents = (updIteration) => {

        //setNeedRecalc(updatechangesDetector())

        var isChanged = false;

        if (updIteration == null)
            return isChanged;

        if (iterationOnlineChanges?.changes == null)
            return isChanged;

        //console.log("=========update online changes ", localIteration)
        // console.log('updIteration', updIteration)
        Object.entries(iterationOnlineChanges.changes).forEach(entry => {
            // console.log(`changing name = ${entry[0]} value = ${entry[1].newValue}`, entry, localIteration)
            if (entry[0] == 'keyRates') {
                var data = JSON.parse(entry[1].newValue).map(x => { return { x: x.Date, y: x.Key } });
                // console.log('data', data)
                var updRates = updIteration.keyRateGroup ?? {};
                // console.log('updRates', updRates)
                mergeKeyrates(data, updRates?.keyRates);
                // console.log('updRates?.keyRates', updRates)

                isChanged = true;
                return;
            }

            if (updIteration[entry[0]] != entry[1].newValue) { //CHECKME!!! need a normal comparsion
                updIteration[entry[0]] = entry[1].newValue;
                isChanged = true;
            }
        });
        //console.log('updIteration', updIteration,iteratinOnlineChanges)
        //deserialize keyrates array
        
        //var keyrates = updatedFields.current["keyRateGroup"];
        //if (keyrates != null) {
        //    updatedFields.current["keyRateGroup"] = JSON.parse(keyrates);
        //}

        return isChanged;
    }
    */
    /*
    const checkIterationOnlineChanges = () => {
        if (iterationOnlineChanges?.changes != null) {
            Object.entries(iterationOnlineChanges.changes).forEach(entry => {
                if (entry?.lastValue)
                    delete entry.lastValue
                if (entry[1]?.newValue !== deal?.lastIteration[entry[0]])
                    updatedFields.current[entry[0]] = { ...entry[1], oldValue: deal?.lastIteration[entry[0]] }
                else
                    delete updatedFields.current[entry[0]]
            });
        }
        else {
            updatedFields.current = {}
        }
    }
    */

    // const lastIterationId = deal?.lastIteration?.id

    // useEffect(() => {
    //     if (deal?.lastIteration?.poolID != pool?.id) {
    //         setNewPoolReceived(true)
    //     }
    //     else
    //         setNewPoolReceived(false)


    // }, [lastIterationId])

    /*
    useEffect(() => {

        //the form covers all the tabs and tab switching not causes to re-validate existed data. We do it manually
        setTimeout(() => submitForm?.current?.isFormValid(false), 0);

    }, []);

    useEffect(() => {

        //console.log("=====iterationOnlineChanges", iterationOnlineChanges);

        checkIterationOnlineChanges();

        setNeedRecalc(updatechangesDetector());

        //var isChanged = false;

        if (localIteration != null) {
            var newIteration = { ...localIteration };
            updateIterationByOnlineContents(newIteration);
            setLocalIteration(newIteration);

            //update keyrate chart data
            var chartData = null;
            if (newIteration?.keyRateGroup != null) {
                chartData = newIteration.keyRateGroup.keyRates.map(x => { return { x: toUnixDate(new Date(x.date)), y: x.key } });
                setKeyrateChartData(chartData);
            }
            else
                setKeyrateChartData(null);
        }

    }, [iterationOnlineChanges]);

    useEffect(() => {

        //console.log("=====iteration", iteration);
        //console.log("=====changes", iterationOnlineChanges);

        var newIteration = { ...iteration };
        updateIterationByOnlineContents(newIteration);

        // console.log("=====iteration after changes", newIteration);

        setLocalIteration(newIteration);

        //update keyrate chart data
        var chartData = null;
        if (newIteration?.keyRateGroup != null) {
            chartData = newIteration.keyRateGroup.keyRates.map(x => { return { x: toUnixDate(new Date(x.date)), y: x.key } });
            setKeyrateChartData(chartData);
        }
        else
            setKeyrateChartData(null);

    }, [iteration]);
    */

    /*
    const checkFormDataBeforeRecalc = () => {

        //console.log('----checkFormDataBeforeRecalc-----');

        //CHECKME!!!! temporary comment
        
        //submitForm?.current?.isFormValid(false).then((result) => {
        //
        //    //console.log('----checkFormDataBeforeRecalc----- formIsValid: ' + result);
        //
        //    setFormIsValid(result);
        //    if (result)
        //        setNeedRecalc(updatechangesDetector());
        //    else
        //        setNeedRecalc(false);
        //});
    };
    */

    /*
     useEffect(() => {
         // console.log('proceededIteration', proceededIteration)
         if (proceededIteration != null) {
             updatedFields.current = {};
         }
     }, [proceededIteration])
     */

    useEffect(() => {
        if (pricingResult != null) {

            if (pricingResult.value != null) {

                //update pricing result
                var keys = Object.keys(pricingResult.value);

                if (keys.length != 0) {
                    keys.forEach(key => {
                        localIteration[key] = pricingResult.value[key];
                    });
                }

                setLocalIteration({ ...localIteration });
            }
        }
    }, [pricingResult]);

    useEffect(() => {
        // if (operationLoading != null && operationLoading != showSpinner) {
        //     setShowSpinner(operationLoading);
        // }
        // else
        //     setShowSpinner(false);
        // console.log('currentDeal!!!!!!!!!!!!!!!!!!!!!!!!!', currentDeal?.deal )
        if (currentDeal?.deal == null)
            setShowSpinner(true)
        else
            setShowSpinner(false)
        // setSaving(false)
    }, [currentDeal]);

    /*
    useLayoutEffect(() => {

        // if (submitForm.current != null)
        //     submitForm.current.isFormValid(false);

        checkFormDataBeforeRecalc();

    }, [deal, localIteration]);
    */



    /*
    const updatechangesDetector = () => {
        // console.log('updatechangeDetector')
        // console.log(updatedFields?.current)
        if (Object.keys(updatedFields?.current).length == 0)
            return false

        return Object.keys(updatedFields?.current).some((key) => {
            //console.log('updatedFields.current[key]', updatedFields.current[key])
            // console.log('val', updatedFields.current[key]?.newValue?.toString(), updatedFields.current[key]?.oldValue?.toString())
            if (getType(updatedFields.current[key]?.newValue) == 'date')
                return (Date.parse(updatedFields.current[key].newValue?.toString()) !== Date.parse(updatedFields.current[key].oldValue?.toString()))

            return updatedFields.current[key]?.newValue?.toString() !== updatedFields.current[key]?.oldValue?.toString()
        })
    }
    */

    /*
    const onApplyKeyRate = (data: any) => {

        var rates = data.editModel.map(x => { return { Date: x.x, Key: x.y }; });
        var keyRates = JSON.stringify(rates);
        console.log('keyRates ', keyRates)
        console.log('updatedFields', updatedFields)
        updatedFields.current.keyRates = { ...updatedFields?.current?.keyRates ?? {}, newValue: keyRates }; //stub to show changes at UI, full update will receive from hub
        var iterationKeyrates = { ...localIteration.keyRateGroup };
        iterationKeyrates.keyRates = [...localIteration?.keyRateGroup?.keyRates ?? []];

        mergeKeyrates(data.editModel, iterationKeyrates.keyRates);

        setLocalIteration({ ...localIteration, keyRateGroup: iterationKeyrates });

        var update = {
            IterationID: localIteration.id, keyRates: { Value: keyRates }
        };
        //we collect all changes
        //Object.keys(updatedFields.current).forEach(name => update[name] = { Value: updatedFields.current[name].newValue });
        console.log("onApplyKeyRate => send iteration change");
        console.log(update);
        actionSecuritization.sendIterationChange(update)(dispatch);

        setDealState(state => state &= ~DealValidationState.STATE_HISTORICAL_KEYRATE_OUTDATED);
    }
    */

    /*
     const firstDayOfMonth = (date: number) => {
         var localDate = new Date(date);
         return new Date(localDate.getFullYear(), localDate.getMonth(), 1, 0, 0, 0, 0);
     }
    */

    /*
    const onFormStateChanged = (isValid: boolean) => {
        // console.log("!!!!!!form state changed!!!!! new value: " + isValid);

        setFormIsValid(isValid);
        //if (isValid)
        setNeedRecalc(updatechangesDetector());
        //else
        //setNeedRecalc(false);
    }

    const onFieldChanged = (name: string, value: any, isValid: boolean) => {

        if (!isValid)
            return; //non-valid is not checking

        var updates = {};

        switch (name) {
            case 'issueDate':
                {
                    //если
                    //issueDate < inclusionDebtDate
                    //если новая вбитая дата размещения < даты актуализации ОСЗ, то на дату актуализации ОСЗ ставится новая вбитая дата размещения
                    //issueDate < inclusionDate
                    //если новая вбитая дата размещения < даты включения в ИП, то на дату включения в ИП ставится новая вбитая дата размещения
                    //issueDate < fixationDate
                    //если новая вбитая дата размещения < даты подписания решения, то на дату подписания решения ставится новая вбитая дата размещения
                    var issueDate = Date.parse(value);
                    //var inclusionDebtDate = Date.parse(localIteration.inclusionDebtDate);
                    var inclusionDate = Date.parse(localIteration.inclusionDate);
                    var fixationDate = Date.parse(localIteration.fixationDate);

                    //if (issueDate < inclusionDebtDate) {
                    //localIteration.inclusionDebtDate = value;
                    //updates.inclusionDebtDate = { Value: value };
                    //}

                    if (issueDate < inclusionDate) {
                        localIteration.inclusionDate = value;
                        updates.inclusionDate = { Value: value };
                    }

                    if (issueDate < fixationDate) {
                        localIteration.fixationDate = value;
                        updates.fixationDate = { Value: value };
                    }
                }
                break;
            case 'firstCouponDate':
                {
                    //firstCouponDate
                    //secondPeriodStartDate
                    //независимо от того, что стоит в дате начала второго расчетного периода, в нее автоматически ставится дата первого дня месяца первого купона
                    var firstCouponDate = firstDayOfMonth(new Date(value));

                    if (isValidDateFns(firstCouponDate)) {
                        var secondPeriodStartDate = firstDayOfMonth(new Date(localIteration.secondPeriodStartDate));

                        if (firstCouponDate.getTime() != secondPeriodStartDate.getTime())
                            updates.secondPeriodStartDate = { Value: Localization.FormatDateTimeISO(firstCouponDate) };
                    }
                }
                break;
            default:
                return;
        }

        updateFields(updates);
    }

    const updateFields = (updates: any) => {

        var keys = Object.keys(updates);
        if (keys.length == 0)
            return;

        //CHECKME!!! update selected deal state

        //var iterationChanges = { ...localIteration };
        var update = { DealID: currentDeal.deal.id, ...updates };
        
        keys.forEach(key => {
            //iterationChanges[key] = updates[key].Value;
            updatedFields.current[key] = { ...updatedFields.current[key], newValue: updates[key].Value };
        });

        //setLocalIteration(iterationChanges);
        // console.log('iterationChanges', iterationChanges)
        actionSecuritization.sendDealChange(update)(dispatch);
    }
    */
    /*
     const onUpdateField = (name, value) => {
 
         updateFields({ [name]: { Value: value } });
         //
         //var iterationChanges = { ...localIteration, [name]: value };
         //var update = { IterationID: localIteration.id, [name]: { Value: value } };
         //
         //updatedFields.current[name] = { ...updatedFields.current[name], newValue: value } //stub to show changes at UI, full update will receive from hub
         //
         //setLocalIteration(iterationChanges);
         //
         //actionSecuritization.sendIterationChange(update)(dispatch);
     };
     */

    /*
     const saveEdit = async (isManualSave = false, isForceToSave = false) => {
         // console.log('run calc, localIteration=', localIteration, 'pool=', pool)
         // setSaving(true)
         var keys = Object.keys(updatedFields.current);
         // console.log('localIteration', localIteration)
         if (keys.length != 0 || localIteration.status === IterationStatus.STATUS_FAILED || localIteration.poolID != pool.id) {
             //no need anymore to run iteration
             var update = {
                 dealID: localIteration.dealID
             };
 
             Object.keys(updatedFields.current).forEach((name) => {
                 if (name == 'keyRates') {
                     update['keyRateGroup'] = {
                         Value: { keyRates: JSON.parse(updatedFields.current[name].newValue) }
                     }
                 }
                 else
                     update[name] = { Value: updatedFields.current[name].newValue };
             });
 
             actionSecuritization.proceedIteration(update)(dispatch);
         }
     }
     */


    //const showKeyRate = (localIteration.couponType == CouponType.TYPE_KEYRATE_AND_PREMIUM || pool?.preferenceFraction > 0 || pool?.familyFraction > 0);
    // console.log(`couponType = ${deal?.deal?.couponType} pool.preferenceFraction = ${pool.preferenceFraction} pool.familyFraction = ${pool.familyFraction}`);
    //const isIterationValid = () => iteration?.validationState === 0;
    // console.log('iteration', iteration)
    //const isPricingParametersInvalidOnly = () => (iteration?.validationState == IterationValidationState.STATE_PRICING_PARAMETERS_INVALID);
    //const keyRateIsInvalid = (localIteration?.couponType == CouponType.TYPE_KEYRATE_AND_PREMIUM && localIteration?.keyRateGroupID == null && updatedFields.current?.keyRates == null);

    /*
    const showParamsTab = () => {
        return (
            <Box sx={{ flexGrow: 1, p: '0', height: '100%', position: 'relative' }}>
                <Box className={classes.scrollablePanel} >
                    <DealParametersPanel
                    //updatedFields={updatedFields.current ? updatedFields.current : {}}
                    showKeyRate = {showKeyRate}
                    // deal={currentDeal}
                    // iteration={localIteration}
                    isEdit={editPermission}
                    //onFieldUpdate={onUpdateField}
                    pool={pool}
                    />
                </Box>
            </Box>
        )
    }
    */

    // console.log('localIteration', localIteration)
    const showKeyratePanel = () => {

    }

    /*
     const checkKeyrateErrors = () => {
         // console.log('updatedFields', updatedFields.current)
         if (localIteration?.couponType != CouponType.TYPE_KEYRATE_AND_PREMIUM)
             return '';
 
 
         if (dealState & DealValidationState.STATE_HISTORICAL_KEYRATE_OUTDATED != 0) {
 
             if (keyRateIsInvalid)
                 return classes.error;
             else if (updatedFields.current?.keyRates != null && updatedFields.current.keyRates?.newValue !== updatedFields.current.keyRates?.oldValue)
                 return classes.edited;
         }
         else if (keyRateIsInvalid) {
             return classes.error;
         }
         else if (updatedFields.current?.keyRates != null && updatedFields.current.keyRates?.newValue !== updatedFields.current.keyRates?.oldValue) {
             return classes.edited;
         }
     }
     */
    /*
    const innerTabs = [
        { id: 'params', order: 0, visible: true, title: t('PricingTabParamsTitile'), callback: showParamsTab },
        { id: 'keyrate', order: 1, visible: !showKeyRate ? false : true, title: t('KeyrateTabTitle'), callback: showKeyratePanel, classes: checkKeyrateErrors() }
    ]
    */

    // const activeTabOut = () => {
    //     if (!innerTabs || !innerTabs.length)
    //         return null

    //     const activeTabsIndex = innerTabs.findIndex(tab => tab.id === activeTab)
    //     if (activeTabsIndex == -1)
    //         return null
    //     return innerTabs[activeTabsIndex].callback()
    // }

    /*
    useEffect(() => {
        if ((!innerTabs || !innerTabs.length || (activeTab && innerTabs.find(x => x.id == activeTab)?.visible)))
            return
        setActiveTab(innerTabs[0].id)
    }, [innerTabs, activeTab])
    */

    //const validateFormFields = (fields) => {
    //
    //    const fieldBigger = (leftName, rightName) => {
    //        return stringInject(t('FieldBigger_Params'), [leftName, rightName]);
    //    };
    //
    //    const fieldLess = (leftName, rightName) => {
    //        return stringInject(t('FieldLess_Params'), [leftName, rightName]);
    //    };
    //
    //    const fieldBiggerOrEqual = (leftName, rightName) => {
    //        return stringInject(t('FieldBiggerOrEqual_Params'), [leftName, rightName]);
    //    };
    //
    //    const fieldLessOrEqual = (leftName, rightName) => {
    //        return stringInject(t('FieldLessOrEqual_Params'), [leftName, rightName]);
    //    };
    //
    //    /*
    //    const fieldBiggerOrEqualDateSecond = (leftName, day, rightName) => {
    //        return stringInject(t('FieldBiggerOrEqualDateSecond_Params'), [leftName, day, rightName]);
    //    };
    //
    //    const fieldLessOrEqualDateFirst = (leftName, day, rightName) => {
    //        return stringInject(t('FieldLessOrEqualDateFirst_Params'), [leftName, day, rightName]);
    //    };
    //    */
    //
    //    /////////////////////////////////////////////////////////////////////////////////////////////////
    //    var actDeliveryDate = (fields.actDeliveryDate || '') != '' ? Date.parse(fields.actDeliveryDate) : null;
    //    var issueDate = (fields.issueDate || '') != '' ? Date.parse(fields.issueDate) : null;
    //    var firstCouponDate = (fields.firstCouponDate || '') != '' ? Date.parse(fields.firstCouponDate) : null;
    //    var maturityDate = (fields.maturityDate || '') != '' ? Date.parse(fields.maturityDate) : null;
    //    //var inclusionDebtDate = (fields.inclusionDebtDate || '') != '' ? Date.parse(fields.inclusionDebtDate) : null;
    //    var inclusionDate = (fields.inclusionDate || '') != '' ? Date.parse(fields.inclusionDate) : null;
    //    var fixationDate = (fields.fixationDate || '') != '' ? Date.parse(fields.fixationDate) : null;
    //    var secondPeriodStartDate = (fields.secondPeriodStartDate || '') != '' ? Date.parse(fields.secondPeriodStartDate) : null;
    //
    //    if (actDeliveryDate != null) {
    //
    //        if (issueDate != null && actDeliveryDate > issueDate)
    //            return [{ name: 'actDeliveryDate', message: fieldBigger(t('ActDeliveryDate'), t('IssueDate')) }, { name: 'issueDate', message: fieldLess(t('IssueDate'), t('ActDeliveryDate')) }];
    //        if (firstCouponDate != null && actDeliveryDate >= firstDayOfMonth(firstCouponDate).getTime())
    //            return [
    //                { name: 'actDeliveryDate', message: stringInject(t('FieldMustBeAtPrevMonth_Params'), [t('ActDeliveryDate'), t('FirstCouponDate')]) },
    //                { name: 'firstCouponDate', message: stringInject(t('FieldMustBeAtNextMonth_Params'), [t('FirstCouponDate'), t('ActDeliveryDate')]) }
    //            ];
    //        if (maturityDate != null && actDeliveryDate >= maturityDate)
    //            return [{ name: 'actDeliveryDate', message: fieldBiggerOrEqual(t('ActDeliveryDate'), t('MaturityDate')) }, { name: 'maturityDate', message: fieldLessOrEqual(t('MaturityDate'), t//('ActDeliveryDate')) }];
    //    }
    //
    //    if (issueDate != null) {
    //
    //        if (firstCouponDate != null && issueDate >= firstDayOfMonth(firstCouponDate).getTime())
    //            return [{ name: 'issueDate', message: fieldBiggerOrEqual(t('IssueDate'), t('FirstCouponDate')) }, { name: 'firstCouponDate', message: fieldLessOrEqual(t('FirstCouponDate'), t//('IssueDate')) }];
    //        if (maturityDate != null && issueDate >= maturityDate)
    //            return [{ name: 'issueDate', message: fieldBiggerOrEqual(t('IssueDate'), t('MaturityDate')) }, { name: 'maturityDate', message: fieldLessOrEqual(t('MaturityDate'), t('IssueDate')) }];
    //
    //    }
    //
    //    if (firstCouponDate != null) {
    //
    //        if (maturityDate != null) {
    //            if (firstCouponDate >= maturityDate) {
    //                return [{ name: 'firstCouponDate', message: fieldBiggerOrEqual(t('FirstCouponDate'), t('MaturityDate')) }, { name: 'maturityDate', message: fieldLessOrEqual(t('MaturityDate'), t//('FirstCouponDate')) }];
    //            }
    //
    //            if ((new Date(firstCouponDate)).getDate() != (new Date(maturityDate)).getDate())
    //                return [{ name: 'firstCouponDate', message: t('FirstCouponDateMustMatchToMaturityDate') }, { name: 'maturityDate', message: t('FirstCouponDateMustMatchToMaturityDate') }];
    //        }
    //    }
    //
    //    //if (inclusionDebtDate != null && issueDate != null && inclusionDebtDate > issueDate) {
    //    //return [{ name: 'inclusionDebtDate', message: fieldBiggerOrEqual(t('InclusionDebtDate'), t('IssueDate')) }, { name: 'issueDate', message: fieldLessOrEqual(t('IssueDate'), t//('InclusionDebtDate')) }];
    //    //}
    //
    //    if (inclusionDate != null && issueDate != null && inclusionDate >= issueDate) {
    //        return [{ name: 'inclusionDate', message: fieldBiggerOrEqual(t('InclusionDate'), t('IssueDate')) }, { name: 'issueDate', message: fieldLessOrEqual(t('IssueDate'), t('InclusionDate')) }];
    //    }
    //
    //    if (fixationDate != null && issueDate != null && fixationDate >= issueDate) {
    //        return [{ name: 'fixationDate', message: fieldBiggerOrEqual(t('FixationDate'), t('IssueDate')) }, { name: 'issueDate', message: fieldLessOrEqual(t('IssueDate'), t('FixationDate')) }];
    //    }
    //
    //    if (secondPeriodStartDate != null && firstCouponDate != null && secondPeriodStartDate >= firstCouponDate) {
    //        return [{ name: 'secondPeriodStartDate', message: fieldBiggerOrEqual(t('SecondPeriodStartDate'), t('FirstCouponDate')) }, { name: 'firstCouponDate', message: fieldLessOrEqual(t//('IssueDate'), t('FirstCouponDate')) }];
    //    }
    //
    //    if ((fields.deductionRUONIA || '') != '') {
    //        if ((fields.expectedRUONIA || '') == '') {
    //            return [{ name: 'deductionRUONIA', message: t('PleaseFillRUONIAField') }, { name: 'expectedRUONIA', message: t('PleaseFillRUONIAField') }];
    //        } else {
    //            var deductionRUONIA = parseFloat(fields.deductionRUONIA);
    //            var expectedRUONIA = parseFloat(fields.expectedRUONIA);
    //
    //            if (expectedRUONIA < deductionRUONIA) {
    //                return [{ name: 'deductionRUONIA', message: fieldLess(t('ExpectedRUONIA'), t('DeductionRUONIA')) }, { name: 'expectedRUONIA', message: fieldBigger(t('DeductionRUONIA'), t//('ExpectedRUONIA')) }];
    //            }
    //        }
    //    }
    //
    //    return null;
    //}
    // console.log('dealPT', deal)
    if (showSpinner)
        return (
            <SpinnerBars withBackground />
        )

    if ((currentDeal?.deal != null /*&& (isPricingParametersInvalidOnly() || isIterationValid())*/))
        return (
            <>
                {/* {showSpinner && <SpinnerBars withBackground/>} */}
                <>
                    <Grid className={classes.globalRoot} container>
                        <Box className={classes.dataBlock}>
                            {/* <Box className={classes.header}>
                                <Box className={classes.headerTabsBlock}>
                                    {!!innerTabs && innerTabs?.filter(tab => tab.visible)?.length > 1 && (
                                        {// закладки выводится только если их больше одной }
                                        innerTabs.map(tab => {
                                            if (tab.visible)
                                                return (
                                                    <Button key={tab.id} variant="text"
                                                        className={clsx(classes.paramsTab, activeTab == tab.id ? classes.activeTab : '', tab.classes ? tab.classes : '')}
                                                        onClick={() => {
                                                            setActiveTab(tab.id)
                                                        }} >
                                                        <Typography>{tab.title}</Typography>
                                                    </Button>
                                                )
                                        }
                                        )
                                    )}
                                </Box>
                                <Box className={classes.headerButtonsBlock}>
                                    <IconButton sx={{ mr: 1 }} size='small' color='primary' onClick={downloadFlows}>
                                        <Tooltip title={t('DownloadFlowsTooltip')} disableInteractive>
                                            <ExcelIcon style={{ width: '24px', height: '24px' }} />
                                        </Tooltip>
                                    </IconButton>

                                    <IconButton sx={{ mr: 1 }}
                                        size='small'
                                        color='primary'
                                        onClick={initialData}
                                        disabled={!needRecalc}
                                    >
                                        <Tooltip title={t('RestoreParams')} disableInteractive>
                                            <RestoreIcon />
                                        </Tooltip>
                                    </IconButton>
                                </Box>
                            </Box>
                            <Divider /> */}
                            <Box
                                sx={{ display: 'block', position: 'relative', height: '100%' }}
                            >
                                <Box sx={{ flexGrow: 1, p: '0', height: '100%', position: 'relative' }}>
                                    <Box className={classes.scrollablePanel} >
                                        <DealParametersPanel
                                            //updatedFields={updatedFields.current ? updatedFields.current : {}}
                                            // deal={currentDeal}
                                            // iteration={localIteration}
                                            isEdit={editPermission}
                                        //onFieldUpdate={onUpdateField}
                                        // pool={pool}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Grid >
                </>
            </>
        )
}

export default DealParametersTab;