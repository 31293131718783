import "@Styles/pages/LoginPage.scss";
import { useNavigate } from 'react-router-dom';
import InformationForm from "@Components/shared/InformationForm";

import { useTranslation } from "react-i18next";
import { Container } from '@mui/material';

const AccessDeniedPage = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleRedirectToMain = (event) => {
        let path = "/";
        navigate(path);
    };

    return (
        <Container sx={{height: '100%', marginTop: '1rem'}}>
            <InformationForm type="failure" onSubmit={handleRedirectToMain} content={t('NotEnoughRightsToSeePage')} />
        </Container>
    )
}

export default AccessDeniedPage;