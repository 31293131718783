import "@Styles/pages/LoginPage.scss";
import { Container, TextField, Checkbox, Button, Tabs, Tab, FormControlLabel, Menu, MenuItem, FormGroup, Stack } from "@mui/material";

import { TabContent } from "@Framework/components/TabContent";
import { ValidatorForm, TextValidator } from "@Framework/components/Form";

import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

import { actionCreators } from "@Store/AccountStore";

import { Spinner } from "@Components/shared/Spinner";
import InformationDialog from "@Pages/InformationDialog";
import ForgotPasswordDialog from "@Pages/authorization/ForgotPasswordDialog";
import { TabPanel } from '@Utilities';
import { ProcessingState } from "@Components/Definitions";
import Globals from "@Base/Globals";
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useAuth } from "react-oidc-context";

import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

import style from '@Styles/domrf-theme.module.scss';

const useStyles = makeStyles((theme: Theme) => createStyles({

    capitalizeLetter: {
        fontSize: '2rem',
        lineHeight: '1rem',
        fontWeight: '600'
    },

    [theme.breakpoints.up('md')]: {
        leftGreenBorder: {
            borderLeft: '3px solid ' + style.colorPrimary2
        }
    },

    controlPlaceholder: {
        padding: '0.375rem 0.75rem'
    }
}));

const LoginPage = () => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const accountStore = useSelector((state: any) => state.account);
    const auth = useAuth();

    const [searchParams, setSearchParams] = useSearchParams();
    const [backUrl, setBackUrl] = useState("/");

    const [tabIndex, setTabIndex] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);
    const [login, setLogin] = useState({
        email: "",
        password: "",
        rememberMe: false
    });
    const [register, setRegister] = useState({
        email: "",
        password: "",
        retryPassword: ""
    });

    useEffect(() => {

        var action = searchParams.get('action');

        if (searchParams.get('back_url') != null)
            setBackUrl(searchParams.get('back_url'));

        actionCreators.init()(dispatch);

        if (action == 'register') {
            setTabIndex(1); //registration tab
        }
        else if (action == 'recover') {
            onRecoverPassword();
        }

    }, []);

    useEffect(() => {
        const { indicators } = accountStore;

        if (indicators && indicators.loginSuccess) {
            navigate(backUrl);
        }

        if (indicators && indicators.registerSuccess) {

            setRegister({
                email: "",
                password: "",
                retryPassword: ""
            });
        }

    }, [accountStore]);

    const handleLoginSubmit = (event) => {

        var data = JSON.stringify(login);
        actionCreators.loginRequest(data)(dispatch);
    };

    const handleRegisterSubmit = (event) => {

        var data = JSON.stringify(register);
        actionCreators.registerRequest(data)(dispatch);
    };

    const onHandleTabChange = (event, newValue) => {
        if (tabIndex != newValue) {
            setTabIndex(newValue);
        }
    };

    const handleLoginChange = (event) => {
        var currentLogin = { ...login };
        currentLogin[event.target.name] = event.target.value;
        setLogin(currentLogin);
    }

    const handleCheckChange = (event) => {
        var currentLogin = { ...login };
        currentLogin[event.target.name] = event.target.checked;
        setLogin(currentLogin);
    }

    const handleRegisterChange = (event) => {
        var currentRegister = { ...register };
        currentRegister[event.target.name] = event.target.value;
        setRegister(currentRegister);
    }

    const handleInfoDialogClose = (event) => {

        /*
        var registerSuccess = this.props.indicators.registerSuccess;

        this.props.indicators.registerSuccess = false;
        this.props.indicators.showInfoModal = false;
        this.props.indicators.content = "";
        */

        actionCreators.init()(dispatch);

        if (accountStore.indicators.registerSuccess === true && backUrl != null) {
            //redirect
            window.location.href = backUrl;
        }
    }

    const handleRecoverDialogClose = (event, data) => {

        //this.props.indicators.recoverPasswordState = ProcessingState.Undefined;
        //actionCreators.init()(dispatch);

        if (data != null) {
            actionCreators.forgotPasswordRequest(data)(dispatch);
        }
    }

    const onExtraButtonClick = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const onLoginAsEmployee = (event) => {

        setAnchorEl(null);
        //NTLM auth
        if (process.env.REACT_APP_BUILD == 'dev') {
            actionCreators.loginEmployeeRequest()(dispatch);
        }
        else {
            //keycloack (jwt auth)
            auth.signinRedirect();
        }
    }

    const onRecoverPassword = () => {

        actionCreators.recoverPassword()(dispatch);

        //this.props.indicators.recoverPasswordState = ProcessingState.Request;

        setAnchorEl(null);
    }

    const onExtraMenuClose = (event) => {
        setAnchorEl(null);
    }

    const onLibraryClick = (evt) => {
        window.location.href = Globals.WikiServer + "/ru/%D0%93%D0%BB%D0%B0%D0%B2%D0%BD%D0%B0%D1%8F";
    }

    //global validator for registration form
    const passwordFieldsMustMatch = (fields) => {

        if (fields.password != fields.retryPassword) {
            return [{ name: 'password', message: t('EnteredPasswordsDoesNotMatch') }, { name: 'retryPassword', message: t('EnteredPasswordsDoesNotMatch') }];
        }

        return null;
    }

    return (

        <Container sx={{ height: '100%', marginTop: '1rem' }}>

            <Helmet>
                <title>{t('DOMRFCalculator')}</title>
            </Helmet>

            <Spinner show={accountStore.indicators.operationLoading} fullScreen content={t('Processing') + '...'} />

            <InformationDialog show={accountStore.indicators.showInfoModal} information={accountStore.indicators.content} onClose={handleInfoDialogClose} />

            <ForgotPasswordDialog show={accountStore.indicators.recoverPasswordState == ProcessingState.Request} onClose={handleRecoverDialogClose} />

            <Grid container sx={{ height: '100%' }} id="loginPage">
                <Grid container sx={{alignItems: 'center', width: '100%', marginX: '0'}}>

                    <Grid md={6} sx={{ paddingX: '1rem' }}>
                        <p><span className="capitalizeLetter">{t('DOMRFCalculator_InfoStartChar')}</span>{t('DOMRFCalculator_Info')}</p>
                        {/*<p>Для получения конкретной информации вы всегда можете зайти в <span dangerouslySetInnerHTML={{ __html: "<a href='javascript:void(0)' onClick={this.onLibraryClick}>библиотеку</a>" }}></span></p>*/}
                    </Grid>

                    <Grid md={6} sx={{ paddingX: '1rem', height: '370px' }} className={classes.leftGreenBorder}>

                        <Tabs value={tabIndex} onChange={onHandleTabChange} variant="fullWidth">
                            <Tab label={t('Enter')} />
                            <Tab label={t('Registration')} />
                        </Tabs>

                        <TabPanel value={tabIndex} index={0}>
                            <TabContent>
                                <ValidatorForm
                                    onSubmit={handleLoginSubmit}>
                                    <FormGroup sx={{ paddingBottom: '1rem' }}>
                                        <div className={classes.controlPlaceholder}>
                                            <TextValidator
                                                type="email"
                                                label="E-mail"
                                                name="email"
                                                id="inputLogin"
                                                fullWidth={true}
                                                onChange={handleLoginChange}
                                                value={login.email}
                                                validators={['required', 'isEmail']}
                                                errorMessages={[t('LoginRequired'), t('IncorrectEmailFormat')]} />
                                        </div>
                                        <div className={classes.controlPlaceholder}>
                                            <TextValidator
                                                type="password"
                                                label={t('Password')}
                                                name="password"
                                                id="inputPassword"
                                                fullWidth={true}
                                                onChange={handleLoginChange}
                                                value={login.password}
                                                validators={['required']}
                                                errorMessages={[t('PasswordRequired')]} />
                                        </div>

                                        <div className={classes.controlPlaceholder}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        name="rememberMe"
                                                        id="idRememberMe"
                                                        onChange={handleCheckChange}
                                                        value={login.rememberMe}
                                                    />
                                                }
                                                label={t('RememberMe')}
                                            />

                                        </div>
                                    </FormGroup>

                                    <div className="align-items-center">
                                        <Stack direction="row">
                                            <Button
                                                sx={{ marginRight: 'auto' }}
                                                type="submit"
                                                variant="contained"
                                                color="secondary">{t('Enter')}</Button>

                                            <Button
                                                type="button"
                                                aria-controls="extra-menu"
                                                aria-haspopup="true"
                                                variant="text"
                                                color="primary"
                                                onClick={onExtraButtonClick}>
                                                {t('Extra')}
                                            </Button>

                                            <Menu
                                                id="extra-menu"
                                                anchorEl={anchorEl}
                                                keepMounted
                                                open={Boolean(anchorEl)}
                                                onClose={onExtraMenuClose}>

                                                <MenuItem onClick={onLoginAsEmployee}>{t('DOMRFEmployee')}</MenuItem>
                                                <MenuItem onClick={onRecoverPassword}>{t('PasswordRecovery')}</MenuItem>
                                            </Menu>

                                        </Stack>
                                    </div>

                                </ValidatorForm>
                            </TabContent>
                        </TabPanel>

                        <TabPanel value={tabIndex} index={1}>
                            <TabContent>
                                <ValidatorForm
                                    validators={[passwordFieldsMustMatch]}
                                    onSubmit={handleRegisterSubmit}>
                                    <FormGroup sx={{ paddingBottom: '1rem' }}>
                                        <div className={classes.controlPlaceholder}>
                                            <TextValidator
                                                type="email"
                                                label='E-mail'
                                                name="email"
                                                id="regEmail"
                                                fullWidth={true}
                                                onChange={handleRegisterChange}
                                                value={register.email}
                                                validators={['required', 'isEmail']}
                                                errorMessages={[t('LoginRequired'), t('IncorrectEmailFormat')]} />
                                        </div>

                                        <div className={classes.controlPlaceholder}>
                                            <TextValidator
                                                type="password"
                                                label={t('Password')}
                                                name="password"
                                                id="regPassword"
                                                fullWidth={true}
                                                onChange={handleRegisterChange}
                                                value={register.password}
                                                validators={['required']}
                                                errorMessages={[t('PasswordRequired')]} />
                                        </div>

                                        <div className={classes.controlPlaceholder}>
                                            <TextValidator
                                                type="password"
                                                label={t('ReenterPassword')}
                                                name="retryPassword"
                                                id="regRetryPassword"
                                                fullWidth={true}
                                                onChange={handleRegisterChange}
                                                value={register.retryPassword}
                                                validators={['required']}
                                                errorMessages={[t('PasswordRequired')]} />
                                        </div>

                                    </FormGroup>

                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="secondary">{t('Register')}</Button>

                                </ValidatorForm>
                            </TabContent>
                        </TabPanel>

                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
}

export default LoginPage;