import React from 'react';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Box, Typography } from '@mui/material';
// import DialogContentText from '@mui/material/DialogContentText';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

import { useTranslation } from "react-i18next";

import style from '../../styles/domrf-theme.module.scss';


const useStyles =  makeStyles({
    buttonsContainer:{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    headIcon:
    {
        color: style.colorSecondary1
    },
    button:{
        width: '100px'
    }
});

const ConfirmDialog2 = props => {

    const { t } = useTranslation();

    const defaultOptions = {
        show: false,
        description: '',
        confirmationText: t('Yes'),
        cancellationText: t('No'),
        dialogProps: {},
        onClose: () => { },
        onCancel: () => { },
        onConfirm: () => { },
    };

    const {
        show,
        description,
        confirmationText,
        cancellationText,
        dialogProps,
        onClose,
        onCancel,
        onConfirm,
        ...rest
    } = { ...defaultOptions, ...props };

    const handleClose = () => {
        onClose();
    };
    const handleCancel = () => {
        onCancel();
        handleClose();
    };
    const handleConfirm = (...args) => {
        onConfirm(...args);
        handleClose();
    };

    const classes = useStyles();

        return (
            <Dialog open={show} {...rest}>
                <DialogContent>
                    <Box sx={{display: 'flex', flexDirection:'column', width:'100%', alignItems: 'center' }}>
                        <FontAwesomeIcon icon={faExclamationTriangle} size="3x" className={classes.headIcon} />
                        <Typography variant="h5" sx={{ paddingY: '1rem', textAlign:'center' }}>
                            {description}
                        </Typography>
                    </Box>

                    <div className={classes.buttonsContainer}>
                        <Button sx={{marginRight: '1rem'}} size="large" variant='contained' onClick={handleCancel} className={clsx(classes.button)}>{cancellationText}</Button>
                        <Button size="large" variant='contained' color="secondary" onClick={handleConfirm} className={classes.button}>{confirmationText}</Button>
                    </div>

                </DialogContent>
            </Dialog>
        );
};

export default ConfirmDialog2;