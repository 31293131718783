import * as React from 'react';
import { connect } from 'react-redux';
import Helmet from "react-helmet";

import * as PoolStore from "@Store/PoolStore";
import * as NavbarStore from '@Store/TopNavbarStore';
import { withRouter } from "../../../components/shared/CustomRouter";
import FormControlLabel from '@mui/material/FormControlLabel';
import stringInject from 'stringinject';
import queryString from "query-string";

import { Theme } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';

import TooltipInfoLabel from '../../../components/shared/TooltipInfoLabel';

import { BondStatus, CouponType } from '../../../components/Definitions';

import Localization from '../../../components/Localization';
import Globals from '../../../Globals';
import { Spinner } from '../../../components/shared/Spinner';
import { withTranslation } from "react-i18next";
import clsx from 'clsx';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HC_exporting from 'highcharts/modules/exporting';
import HC_exporting_data from 'highcharts/modules/export-data';

import { setHighchartsLocalization, hexToRgbA, makeGradient, toUnixDate, upperFirstChar, roundUp, roundDown } from '../../../Utilities';

import style from '@Styles/domrf-theme.module.scss';

import HighchartsLabelComponent from './HighchartsLabelComponent';

import {
    Container, Paper, Tooltip, ToggleButton, ToggleButtonGroup, Select, SelectChangeEvent,
    MenuItem, Button, Checkbox, Slider, Stack,
    ListItemText, Box, InputLabel, FormControl, Chip, TextField, Autocomplete, debounce, Grid, Typography, Input,
    Divider,
    IconButton,
    Popper,
    alpha,
} from '@mui/material';
import { Clear, SaveOutlined, Padding, ScreenRotation, FileCopy, FileCopyOutlined, PaidTwoTone, LineWeight } from '@mui/icons-material';

import CompareBondsControl from './CompareBondsControl';
import RotateLocker from '../Components/RotateLocker';
import { Log } from 'oidc-client-ts';
import { styled } from '@mui/styles';
import CustomCircularProgress from '@Base/components/shared/CustomCircularProgress';
import { textAlign } from '@mui/system';
import CustomTooltip, { getTooltip } from '@Base/components/shared/CustomTooltip';

const StyledButton = styled(Button)(({ theme }) => ({
    fontFamily: "DINPro-Bold",
    fontWeight: "700",
    letterSpacing: '1.5px',
    fontSize: '0.8rem !important',
    '& *': {
        fontSize: '0.8rem !important',
    },
    // color: style.colorPrimary3,
    padding: '0 0.5rem 0 9px',
    marginRight: '0.5rem',
    backgroundColor: 'rgba(183, 217, 139, 0.23)',
    borderColor: 'rgb(61,149,109)',
    color: 'rgb(61,149,109)',
    '&:hover': {
        borderColor: 'rgb(61,149,109) !important',
        backgroundColor: 'rgba(183, 217, 139, 0.4) !important',
        // color: `rgb(0,176,80) !important`,
    },
    minWidth: '2rem',
}))

function SliderLabelComponent({ children, open, value, ...restProps }) {
    return (
        <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
            {children}
        </Tooltip>
    );
}

interface IProps {
    t: any;
    classes: any;
    location: any;
    router: any;

    getBondsInfo(items: any[], date: string | Date | null): void;
    loading: boolean;
    bonds: any[];
    getPoolStatistics(date: string | Date): void;
    getPoolsTimeline(): void;
    //getPoolCDR(date: string | Date, items: string[], isCorrectedCDR: boolean): void;

    statistics: any;
    timeline: any[];
    bondsLoading: boolean;
    getPoolsSCurve(date: any, minX?: number, maxX?: number): void;
    scurve: any[];
    poolCDR: any;
}

interface IState {
    inputValue: string
    isMobile: boolean;
    boundList: string[];
    topNavbar: object;
    mobileLocker: boolean;
    searchValue: string;
    pageSize: number;
    pageSizes: any[];
    currentPage: number;
    columns: any[];
    sortColumns: any[];
    booleanColumns: any[];
    bonds: any[];
    //compareBonds: any;
    poolChartOptions: any;
    sCurveChartOptions: any;

    cprChartOptions: any;
    cdrChartOptions: any;

    poolColumns: any[];

    poolStatistics: any[];
    timeline: any[];
    timelineValue: number;
    timelineValueCommitted: number;

    bondNameType: number;
    selection: any[];

    minX: number;
    maxX: number;

    poolsSorting: any;
    pointsMap: any[];

    gridState: number;

    cdrSwitch: number;
    bondsColorMapping: any[];

    refreshLabelRequired: boolean;

    showAverageCPRCDR: boolean;

    queryData?: {
        isins?: string[];
        colorIndexes?: number[];
        date?: Date;
        showaverages?: boolean;
        cdrtype?: number;
    },

    columnWidths: any[];

    showRedeemedBonds: boolean;
    prevDate: Date;
    lastSelectedOptionId: string
}

const styles = (theme: Theme) => ({
    autocompletePopper: {
        overflowY: 'auto',
        overflowX: 'hidden',
        '&::-webkit-scrollbar': {
            width: '4px',
        },
        '&:hover': {
            '&::-webkit-scrollbar-track': {
                backgroundColor: '#00000008',
            },
            '&::-webkit-scrollbar-thumb': {
                background: '#00000018',
            }
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'unset',
            borderRadius: '3px',
            // border: 'solid 1px #00000000',
            backgroundColor: '#00000000',
            transition: 'all 0.3s ease'
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#0000',
            borderRadius: '3px',
            height: '4px'
        },
    },
    bgCover: {
        zIndex: '+1',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: '100%',
        height: '100%',
        background: 'rgba(255, 255, 255, 0.5)',
    },
    spinnerBase: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    progressBase: {
        display: 'none'
    },
    progress: {
        animationDuration: '550ms',
    },
    progressValueRoot: {
        // background: '#FFF'
    },
    wideTooltip: {
        maxWidth: '400px !important'
    },
    clickAway: {
        backgroundColor: '#00000010',
        position: 'fixed', top: 0, left: 0, right: 0, bottom: 0,
        zIndex: '+99998'
    },
    tableRow: {
    },
    tableCell: {
        paddingLeft: '0',
        fontSize: '14px'
    },
    selectedTableCell: {
        color: style.colorPrimary2,
    },
    rightAlign: {
        textAlign: 'right'
    },
    lightDate: {
        color: style.colorPrimary3,
    },
    container: {
        padding: '5px 5px 5px 5px'
    },
    checkBox: {
        marginBottom: '0'
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        padding: '1rem',
        flex: '1'
        //height: '100%'
    },
    paperContainer: {
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        maxWidth: '80%'
    },

    markLabel: {
        fontSize: '0.7rem',
        top: '1.2rem'
    },
    sliderThumb: {
        width: '15px',
        height: '15px',
        color: style.colorPrimary1
    },
    sliderLine: {
        height: '2px',
        color: style.colorPrimary3_60,
        // marginBottom:0,
        // padding:0,
    },
    sliderContainer: {
        padding: '0 2rem 0 2rem',
        //width: '100%',
        height: '54px',

        '& .MuiSlider-mark': { backgroundColor: style.colorPrimary1, },
    },
    chartsContainer: {
        flex: '1'
    },
    flexFill:
    {
        flex: '1 1 50%',
        minHeight: '400px'
    },
    flexShrink:
    {
        flex: '0 1 auto'
    },
    table: {
        //width: '100%',
        height: '100%'
    },
    label: {
        position: 'relative',
        marginBottom: '0'
    },
    tooltipLabel: {
        display: 'flex',
        flexDirection: 'row'
    },
    switchContainer: {
        position: 'absolute',
        left: '65px',
        top: '20px',
        zIndex: '2'
    },
    checkboxContainer: {
        position: 'absolute',
        left: '86px',
        top: '10px',
        zIndex: '2'
    },
    toggleButton: {
        padding: '2px 4px 2px 4px'
    },
    poolTooltipLabel: {
        width: '200px',
        whiteSpace: 'normal'
    },
    header: {

        [theme.breakpoints.down('sm')]: {
            paddingRight: '10px',
            paddingLeft: '10px',
        }
    },
    bondMarker: {
        fontSize: '11px',
        textTransform: 'uppercase',
        color: style.colorError
    },
    buttonsContainer: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column'
    }
});

const poolsColors = [
    style.colorPrimary2,
    style.colorSecondary1,
    style.colorSecondary2,
    hexToRgbA(style.colorPrimary3, 0.6),
    style.colorPrimary1
];

function updateChartLabel() {

    var parent = this.options.customData();
    // console.log('parent', this.options.customData())

    if (!parent.labelUpdating) {
        parent.labelUpdating = true;

        // console.log('parent.state.refreshLabelRequired', parent.state.refreshLabelRequired)
        parent.setState({ refreshLabelRequired: !parent.state.refreshLabelRequired }, () => {
            parent.labelUpdating = false;
        });
    }

    //setTimeout(() => this.setState({ refreshLabel: true }), 0);
}

class PoolsPage extends React.Component<IProps, IState> {

    //static whyDidYouRender = true;

    constructor(props: any) {
        super(props);
        this.selectedOptionRef = React.createRef();
        // console.log('pools',props)
        HC_exporting(Highcharts);
        HC_exporting_data(Highcharts);
        setHighchartsLocalization(Highcharts, this.props.t);

        var queryData = {};
        if (this.props.router.location.search) {
            const values = queryString.parse(this.props.router.location.search);
            queryData = this.validateQuery(values);
        }
        // Highcharts.SVGRenderer.prototype.symbols.SaveFileIcon = function (x,y,w,h){
        //     const icon = 'M17 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V7l-4-4zm2 16H5V5h11.17L19 7.83V19zm-7-7c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3zM6 6h9v4H6z'
        //     return icon.split(' ')
        // }
        this.state = {
            inputValue: '',
            isMobile: false,
            topNavbar: {},
            mobileLocker: true,

            queryData: queryData,

            prevDate: queryData.date,

            timeline: [],
            timelineValue: 0,
            timelineValueCommitted: 0,

            selection: [],

            bonds: [],
            //compareBonds: {},

            pageSize: 100,
            pageSizes: [100, 300, 500],
            currentPage: 0,
            searchValue: '',

            minX: null,
            maxX: null,

            pointsMap: [],

            gridState: 1,

            cdrSwitch: queryData.cdrtype != null ? queryData.cdrtype : 2,

            bondsColorMapping: [],

            refreshLabelRequired: false,

            showRedeemedBonds: !!queryData.showredeemed,

            showAverageCPRCDR: queryData.showaverages != null ? queryData.showaverages : false,

            poolsSorting: [{ columnName: 'name', direction: 'asc' }],

            columns: [
                { name: 'issueDate', title: this.props.t('IssueDate') },
                { name: 'isin', title: this.props.t('ISIN') },
                { name: 'name', title: this.props.t('Name') },
                { name: 'isIssued', title: this.props.t('IsIssued') }
            ],
            sortColumns: [
                { columnName: 'issueDate', direction: 'desc' }
            ],
            booleanColumns: ['isIssued'],

            poolStatistics: [],

            bondNameType: 3,

            poolColumns: [
                { name: 'name', title: this.props.t('Name') },
                { name: 'status', title: this.props.t('Status') }
            ],

            columnWidths: [
                { columnName: 'name', width: 170 },
                { columnName: 'status', width: 70 }
            ],

            poolChartOptions: {

                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    formatter: function () {
                        return '<span style="color: ' + this.color + '">' + this.point.label + '</span>';
                    }
                },

                //CHECKME!!! move SetState(refreshLabel) to a rendering, and think, how to prevent cyclic calls
                customData: () => this,

                exporting:
                {
                    enabled: false
                },

                chart: {
                    type: 'scatter',
                    height: 400,
                    // plotBorderWidth:10,
                    marginBottom: 60,
                    spacingBottom: 0,
                    marginLeft: 10, // Keep all charts left aligned

                    events: {
                        render: this.onChartRender,
                        redraw: this.onChartRedraw
                    }
                },

                credits: {
                    enabled: false
                },

                title: {
                    text: ''
                },

                legend: {
                    margin: 0,
                    // y: 10,
                    itemStyle: {
                        fontSize: '12px',
                        fontWeight: 'bold',
                        fill: style.colorPrimary1,
                        color: style.colorPrimary1,
                        paddingLeft: 0,
                        paddingRight: 0,
                        cursor: 'default',
                        textDecoration: 'none'
                    }
                },

                xAxis: {
                    startOnTick: false,
                    endOnTick: false,
                    tickLength: 5,
                    tickColor: alpha(style.colorPrimary3, 0.15),
                    gridLineWidth: 1,
                    lineWidth: 1,
                    lineColor: style.colorPrimary1,
                    title: {
                        text: this.props.t('PoolWACPrc'),
                        style: {
                            color: style.colorPrimary1,
                            fontSize: '0.8rem'
                        }
                    },
                    labels: {
                        y: 18,
                        style: {
                            fontSize: '11px',
                            color: style.colorPrimary1
                        }
                    },
                    plotLines: [{
                        color: 'black',
                        dashStyle: 'solid',
                        width: 1,
                        value: 0,
                        label: {
                            textAlign: 'right',
                            rotation: 0,
                            y: 15,
                            style: {
                                fontSize: '1rem'
                            },
                            useHTML: true,
                            text: '' //this.props.t('MortgageRefinancingRate')
                        },
                        zIndex: 2
                    }],
                },

                yAxis: {

                    startOnTick: true,
                    endOnTick: true,
                    gridLineWidth: 1,
                    lineWidth: 1,
                    lineColor: style.colorPrimary1,
                    title: {
                        text: this.props.t('WALADescription'),
                        style: {
                            color: style.colorPrimary1,
                            fontSize: '0.8rem'
                        }
                    },
                    labels: {
                        style: {
                            color: style.colorPrimary1
                        }
                    }
                },

                //name: this.state.bondResults.displayName,
                /*
                tooltip: {
                    headerFormat: '<b>{series.name}</b><br>',
                    pointFormat: `<span>${this.props.t('ConventionNameYieldsGraphDurationRunner')}</span>: <b>{point.x:.2f}</b><br/><span>${this.props.t('ConventionNameYieldsGraphYieldRunner')}</span>: <b>{point.y:.2f}</b>`,
                },
                */
                plotOptions: {

                    scatter: {

                        allowPointSelect: true,


                        states: {
                            hover: {
                                enabled: false
                            },
                            inactive: {
                                enabled: false
                            },
                            select: {
                                enabled: false
                            }
                        },

                        point: {
                            events: {
                                mouseOver: (event) => this.onPoolMouseOver(event),
                                mouseOut: (event) => this.onPoolMouseOut(event),
                                click: (evt) => this.onPointClick(evt)
                            }
                        },

                        marker: {
                            radius: 8,
                            lineWidth: 1
                            /*
                            states: {
                                select: {
                                    enabled: false,
                                    radius: 10,
                                    //fillColor: hexToRgbA(style.colorSecondary2, 0.3),
                                    //lineColor: style.colorSecondary2,
                                    lineWidth: 1
                                }
                            }
                            */
                        },

                        tooltip: {

                            pointFormatter: function () {

                                return '<table>' +
                                    //'<tr><td><b>' + this.name + '</b></td><td></td></tr>' +
                                    '<tr><td style="padding:0">' + Localization.Translate('WACPrc') + ': </td>' +
                                    '<td style="padding:0;text-align:end"><b>' + Localization.FormatNumber(this.x) + '</b></td></tr>' +
                                    '<tr><td style="padding:0">' + Localization.Translate('WALAPrc') + ': </td>' +
                                    '<td style="padding:0;text-align:end"><b>' + Localization.FormatNumber(this.y, 1, 1) + '</b></td></tr>' +
                                    '</table>'
                            }

                            //headerFormat: '<b>{series.name}</b><br>',
                            //pointFormat: `<b>{point.name}</b></br>${this.props.t('WAC')}: <b>{point.x:.2f}</b><br/> ${this.props.t('WALADescription')}: <b>{point.y:.2f}</b><br/>`
                        }
                    },

                    series: {

                        showInLegend: false,
                        stickyTracking: false,

                        dataLabels: {
                            enabled: true,
                            formatter: this.formatDataLabels
                            //format: '{series.name}'
                        },

                        /*
                        events: {
                            afterAnimate: () => {

                                if (!this.labelUpdating) {
                                    this.labelUpdating = true;
                                    //this.isFirstRun = false;
                                    this.setState({ refreshLabel: !this.state.refreshLabel });
                                }
                            }
                        }
                        */
                    }
                },

                tooltip: {
                    borderColor: style.colorPrimary3_60,
                    hideDelay: 0,
                    animation: false,
                    //headerFormat: `<b>${this.props.t('ConventionNameCPRGraphRunner')}</b><br/><span>${this.props.t('Date')}</span>: <b>{point.x:%Y-%m-%d}</b><br/>`,
                    //pointFormat: `<span>{series.name}</span>: <b>{point.y:.2f}</b><br/>`,
                    useHTML: true,
                    //shared: true
                },

                series: []
            },

            sCurveChartOptions: {
                //График Стимул к рефинансированию

                //chart: {
                //type: 'line'
                //},
                exporting:
                {
                    enabled: false
                },

                chart: {
                    marginLeft: 10, // Keep all charts left aligned
                    height: 400,
                    // plotBorderWidth:10,
                    marginBottom: 80,
                    spacingBottom: 13,
                    events: {
                        render: (e) => {
                            if (e.target?.callback)
                                e.target?.callback(e.target)
                            // x.options.dataLabels.events.init=()=>{console.log('intEv')}
                        }
                    },
                    animation: false,
                },

                dataLabels: {
                    enabled: true,
                    formatter: function () {
                        return '<span style="color: ' + this.color + '">' + this.point.label + '</span>';
                    },
                },

                userData: () => this,

                title: {
                    text: ''
                },
                legend: {
                    margin: 0,
                    // y: 10,
                    itemStyle: {
                        fontSize: '12px',
                        fontWeight: 'bold',
                        fill: style.colorPrimary1,
                        color: style.colorPrimary1,
                        paddingLeft: 0,
                        paddingRight: 0,
                        cursor: 'default',
                        textDecoration: 'none'
                    }
                },

                credits: {
                    enabled: false
                },

                plotOptions: {
                    series: {

                        states: {
                            inactive: {
                                opacity: 1
                            }
                        },

                        marker: {
                            enabled: false
                        },

                    }
                },

                xAxis: {
                    crosshair: true,
                    startOnTick: true,
                    endOnTick: true,
                    tickLength: 5,
                    tickColor: alpha(style.colorPrimary3, 0.15),
                    gridLineWidth: 1,
                    lineWidth: 1,
                    lineColor: style.colorPrimary1,
                    title: {
                        text: this.props.t('RateDifferencePrc'),
                        style: {
                            color: style.colorPrimary1,
                            fontSize: '0.8rem'
                        }
                    },
                    labels: {
                        y: 18,
                        style: {
                            fontSize: '11px',
                            color: style.colorPrimary1
                        }
                    }
                },

                yAxis: [{
                    crosshair: true,
                    startOnTick: true,
                    endOnTick: true,
                    gridLineWidth: 1,
                    lineWidth: 1,
                    lineColor: style.colorPrimary1,
                    title: {
                        text: this.props.t('CPRPrc'),
                        style: {
                            color: style.colorPrimary1,
                            fontSize: '0.8rem'
                        }
                    },
                    labels: {
                        style: {
                            color: style.colorPrimary1
                        }
                    }
                },
                {
                    min: 0,
                    max: 1000.0,
                    visible: false
                }
                ],

                tooltip: {
                    animation: false,
                    shape: "square",
                    hideDelay: 0,
                    useHTML: true,
                    borderColor: style.colorPrimary3_60,
                    shared: true,
                    positioner: function (labelWidth, labelHeight, point) {
                        return {
                            x: this.chart.plotWidth - labelWidth - 3,
                            y: this.chart.plotHeight - labelHeight - 3
                        };
                    },

                    /*
                    formatter: function () {

                        return '<table style="min-width:200px;max-width:600px"><tr><td>' + Localization.Translate('RateDifferencePrc') + '</td><td style="padding:0;text-align:end"><b>' + Localization.FormatNumber(this.x) + '</b></td></tr>' +
                            '<tr><td style="padding:0">' + Localization.Translate('CPRPrc') + ':</td><td style="padding:0;text-align:end"><b>' + Localization.FormatNumber(this.y) + '</b></td></tr>' +
                            '</table>';
                    },
                    */
                    formatter: function () {

                        var points = [
                            { name: Localization.Translate('RateDifferencePrc'), value: this.x, fraction: 1 },
                            { name: Localization.Translate('WACPrc'), value: this.points[0].x + this.points[0].series.chart.userOptions.userData().props.statistics.mortgageRate, fraction: 1 },
                            { name: this.points[0].series.name, value: this.points[0].y, fraction: 1 },
                        ];

                        const debtIndex = this.points[0].series.chart.userOptions.userData().props?.scurve?.debt.findIndex(pair => Math.round(pair.x * 100) / 100 == Math.round(this.points[0].x * 100) / 100)
                        // console.log('debtIndex', this.points[0].x, debtIndex)
                        if (debtIndex != -1)
                            points.push({ name: Localization.Translate('DebtValueBlns'), value: this.points[0].series.chart.userOptions.userData().props?.scurve?.debt[debtIndex]?.y, fraction: 0 })

                        // console.log('this.x', this.points[0].x, this.points[0].series.chart.userOptions.userData().props?.scurve?.debt)
                        // if (this.points.length > 1)
                        //     points.push({ name: this.points[1].series.name, value: this.points[1].y });

                        return '<table style="min-width:200px;max-width:600px">' +

                            points.reduce(function (s, point) {
                                return s + '<tr><td style="padding:0">' + point.name + ': </td>' +
                                    '<td style="padding:0;text-align:end"><b>' + Localization.FormatNumber(point.value, point?.fraction ?? 0, point?.fraction ?? 0) + '</b></td></tr>'
                            }, '') +

                            '</table>';
                    },
                    /*
                    headerFormat: '<table columnspan="2" style="min-width:200px;max-width:600px"><tr><td>{series.name}</td></tr>',
                    pointFormat: '<tr><td style="padding:0">' + Localization.Translate('WACPrc') + ':</td><td style="padding:0;text-align:end"><b>{point.x: .2f}</b></td></tr>' + 
                                 '<tr><td style="padding:0">' + Localization.Translate('CPRPrc') + ':</td><td style="padding:0;text-align:end"><b>{point.y: .2f}</b></td></tr>',
                    footerFormat: '</table>',
                    */
                },

                series: [{
                    name: this.props.t('SCurve'),
                    id: 'scurve',
                    yAxis: 0,
                    color: hexToRgbA(style.colorPrimary3, 0.5),
                    marker: {
                        states: {
                            hover: {
                                enabled: false
                            }
                        }
                    },
                    zIndex: 1,
                    data: []
                }, {
                    name: this.props.t('SCurveApproximation'),
                    id: 'sapprox',
                    yAxis: 0,
                    //color: style.colorPrimary1,
                    marker: {
                        enabled: true,
                        radius: 1, //hack to remove markers
                        states: {
                            hover: {
                                lineWidth: 2,
                                radius: 4,
                                radiusPlus: 2
                            },
                            select: {
                                lineWidth: 2,
                                radius: 4
                                //fillColor: style.primaryColor3,
                                //lineColor: style.primaryColor1
                            }
                        }
                    },

                    dataLabels: {
                        enabled: true,
                        formatter: this.formatSCurveDataLabels,
                        allowOverlap: true,
                    },
                    zIndex: 2,
                    data: []
                },
                {
                    name: this.props.t("DebtValue"),
                    id: "debtBars",
                    type: "column",
                    yAxis: 1,
                    // linkedTo:"sapprox",   
                    // getExtremesFromAll:false,  
                    grouping: false,
                    zIndex: 0,
                    color: alpha(style.colorPrimary3, 0.15),
                    pointPadding: 0.0, // Space between individual columns within a group
                    groupPadding: 0.0, // Space between different groups of columns
                    borderColor: 'none',
                    borderWidth: 0,
                    // pointPlacement: "on",
                    pointPlacement: 'between', //чтобы не было белой сетки
                    data: [],
                }]
            },

            cprChartOptions: {
                exporting: {

                    enabled: true,

                    width: 1024,
                    height: 720,
                    buttons: {
                        contextButton: {
                            // symbol:'SaveFileIcon',
                            // symbolStroke:'0',
                            menuItems: ['downloadCSV', 'downloadXLS', 'downloadJPEG'],
                            // onclick:function(){this.exportChart({ width: 2048 });}
                        }
                    },
                    chartOptions: {
                        legend: {
                            itemDistance: 40,
                            itemStyle: {
                                fontSize: '7px',
                                overflow: 'hidden',
                            },
                        },
                    }
                },

                title: {
                    text: ''
                },

                credits: {
                    enabled: false
                },

                legend: {
                    margin: 0,
                    // y: 0,
                    itemStyle: {
                        fontSize: '12px',
                        fontWeight: 'bold',
                        fill: style.colorPrimary1,
                        color: style.colorPrimary1,
                    }
                },

                chart: {
                    marginLeft: 55, // Keep all charts left aligned
                    marginRight: 55,
                    plotBorderWidth: 1,
                    height: 400,
                    // plotBorderWidth:10,
                    marginBottom: 60,
                    spacingBottom: 13,
                    events: {
                        render: (event) => this.formExportFileName(event, 'CPR')
                    }
                },

                plotOptions: {
                    series: {

                        states: {
                            //hover: {
                            //enabled: false
                            //},
                            inactive: {
                                //enabled: false
                                opacity: 1
                            }
                            //select: {
                            //enabled: true
                            //}
                        },

                        marker: {
                            enabled: false
                        }

                        //stickyTracking: false
                    }
                },

                tooltip: {
                    animation: false,
                    shape: "square",
                    hideDelay: 0,
                    useHTML: true,
                    borderColor: style.colorPrimary3_60,
                    shared: true,
                    positioner: function (labelWidth, labelHeight, point) {
                        return {
                            x: this.chart.plotLeft + 10,
                            y: this.chart.plotTop + 10
                        };
                    },
                    /*
                    headerFormat: '<table><tr>' +
                        '<td>' + this.props.t('Month') + ':</td>' +
                        '<td style="text-align:end"><b>{point.key}</b></td>' +
                        '</tr>',
                    pointFormat: '<tr><td style="padding:0"><span style="color:{series.color}">●</span><span>{series.name}:</span></td>' +
                        '<td style="padding:0;text-align:end"><b>{point.y:.1f}</b></td></tr>',
                    footerFormat: '</table>',
                    */

                    formatter: function () {

                        var orderPoints = [...this.points];

                        orderPoints.sort((a, b) => ((a.series.options.legendIndex ?? 0) - (b.series.options.legendIndex ?? 0)));

                        var temp = '<table style="min-width:270px;max-width:270px"><tr>' +
                            '<td>' + Localization.Translate('Month') + ':</td>' +
                            '<td style="text-align:end"><b>' + upperFirstChar(Localization.FormatDateHumanReadable(new Date(this.x))) + '</b></td> ' +
                            '</tr>';

                        orderPoints.forEach(function (point, index) {
                            temp += '<tr><td style="padding:0"><span style="color:' + point.series.color + '">●</span><span>' + point.series.name + ':</span></td>' +
                                '<td style="padding:0;text-align:end"><b>' + Localization.FormatNumber(point.y) + '</b></td></tr>';
                        });

                        temp += '</table>';

                        return temp;
                    },
                },

                xAxis: {
                    tickLength: 5,
                    tickColor: alpha(style.colorPrimary3, 0.15),
                    type: 'datetime',
                    title: {
                        enabled: false,
                        text: this.props.t('Date'),
                        style: {
                            color: style.colorPrimary1,
                            fontSize: '0.8rem'
                        }
                    },
                    labels: {
                        y: 18,
                        style: {
                            fontSize: '11px',
                            color: style.colorPrimary1
                        },
                        formatter: function () {
                            // console.log('this.value', this.value)
                            if (this.value == null)
                                return ''
                            const date = new Date(this.value)
                            // console.log('date', date)
                            const y = date.getFullYear()
                            const m = date?.getMonth()
                            const d = date?.getDate()
                            if (m == 0 && d == 1) {
                                // console.log('date?.getDate()', this.value, date, y, m, d)
                                return y
                            }

                            return null
                        }
                    },

                    startOnTick: true,
                    //endOnTick: true,
                    // tickInterval: 6 * 28 * 24 * 3600 * 1000,
                    minPadding: 0,
                    gridLineWidth: 1,
                    lineWidth: 1,
                    lineColor: style.colorPrimary1,
                },

                yAxis: [{
                    title: {
                        text: this.props.t('CPRPrc'),
                        style: {
                            color: style.colorPrimary1,
                            fontSize: '0.8rem'
                        }
                    },
                    labels: {
                        style: {
                            color: style.colorPrimary1
                        }
                    },
                    tickPositioner: function (min, max) {
                        const count = 5
                        const step = roundUp((roundUp(max, 5) - roundDown(min, 5)) / count, 1)
                        var positions = Array.from({ length: count + 1 }, (x, i) => i * step)
                        // var positions = [0, 10, 20, 30, 40, 50];
                        return positions;
                    },

                    visible: true,

                    min: 0,
                    max: 50,

                    gridLineWidth: 1,
                    lineWidth: 1,
                    lineColor: style.colorPrimary1,

                }, {
                    id: 'wacAxis',
                    title: {
                        text: this.props.t('WACAndAverageRefRate'),
                        style: {
                            color: style.colorPrimary1
                        }
                    },
                    labels: {
                        style: {
                            color: style.colorPrimary1
                        }
                    },

                    opposite: true,
                    visible: true,

                    tickPositioner: function () {
                        var positions = [7, 10, 13, 16, 19, 22];
                        return positions;
                    },
                    min: 5,
                    max: 15

                }],

                series: [{
                    name: this.props.t('WCPR'),
                    id: 'cpr',
                    color: 'rgb(167,209,111)',//style.colorPrimary3,
                    legendIndex: 3,

                    marker: {
                        enabled: false,
                        symbol: 'circle'
                    },

                    states: {
                        hover: {
                            enabled: true,
                            lineWidthPlus: 0,
                            shadow: false
                        }
                    },

                    data: []
                }, {
                    name: this.props.t('AverageRefRate'),
                    id: 'mortgageRate',
                    color: 'rgb(199,200,201)',//style.colorPrimary3_40,
                    yAxis: 1,
                    legendIndex: 2,
                    dashStyle: 'shortdash',

                    marker: {
                        enabled: false,
                        symbol: 'circle'
                    },

                    states: {
                        hover: {
                            enabled: true,
                            lineWidthPlus: 0,
                            shadow: false
                        }
                    },

                    data: []
                }, {
                    name: this.props.t('WACPrc'),
                    id: 'wac',
                    color: 'rgb(47,68,78)',//style.colorPrimary1,
                    yAxis: 1,
                    legendIndex: 1,
                    dashStyle: 'shortdash',

                    marker: {
                        enabled: false,
                        symbol: 'circle'
                    },

                    states: {
                        hover: {
                            enabled: true,
                            lineWidthPlus: 0,
                            shadow: false
                        }
                    },
                    data: []
                }]
            },

            cdrChartOptions: {

                exporting: {

                    enabled: true,

                    width: 1024,
                    height: 720,

                    buttons: {
                        contextButton: {
                            // symbol:'SaveFileIcon',
                            // symbolStroke:'0',
                            menuItems: ['downloadCSV', 'downloadXLS', 'downloadJPEG'],
                            // onclick:function(){this.exportChart({ width: 2048 });}
                        }
                    },
                    chartOptions: {
                        legend: {
                            itemDistance: 40,
                            itemStyle: {
                                fontSize: '7px',
                                overflow: 'hidden',
                            },
                        },
                    }
                },

                title: {
                    text: ''
                },

                legend: {
                    margin: 0,
                    y: -15,
                    itemStyle: {
                        fontSize: '12px',
                        fontWeight: 'bold',
                        fill: style.colorPrimary1,
                        color: style.colorPrimary1,
                        paddingLeft: 0,
                        paddingRight: 0,
                        cursor: 'default',
                        textDecoration: 'none'
                    }
                },

                credits: {
                    enabled: false
                },

                chart: {
                    marginLeft: 55, // Keep all charts left aligned
                    marginRight: 55,
                    plotBorderWidth: 1,
                    height: 400,
                    // plotBorderWidth:10,
                    marginBottom: 80,
                    spacingBottom: 13,
                    events: {
                        render: (event) => this.formExportFileName(event, 'CDR')
                    }
                },

                plotOptions: {
                    series: {

                        states: {
                            //hover: {
                            //enabled: false
                            //},
                            inactive: {
                                //enabled: false
                                opacity: 1
                            }
                            //select: {
                            //enabled: truelegend
                            //}
                        },

                        marker: {
                            enabled: false
                        }

                        //stickyTracking: false
                    }
                },

                tooltip: {
                    animation: false,
                    shape: "square",
                    hideDelay: 0,
                    useHTML: true,
                    borderColor: style.colorPrimary3_60,
                    shared: true,
                    positioner: function (labelWidth, labelHeight, point) {
                        return {
                            x: this.chart.plotLeft + 10,
                            y: this.chart.plotTop + 50
                        };
                    },

                    formatter: function () {

                        var orderPoints = [...this.points];

                        orderPoints.sort((a, b) => ((a.series.options.legendIndex ?? 0) - (b.series.options.legendIndex ?? 0)));

                        var temp = '<table style="min-width:270px;max-width:270px"><tr>' +
                            '<td>' + Localization.Translate('Month') + ':</td>' +
                            '<td style="text-align:end"><b>' + upperFirstChar(Localization.FormatDateHumanReadable(new Date(this.x))) + '</b></td> ' +
                            '</tr>';

                        orderPoints.forEach(function (point, index) {
                            temp += '<tr><td style="padding:0"><span style="color:' + point.series.color + '">●</span><span>' + point.series.name + ':</span></td>' +
                                '<td style="padding:0;text-align:end"><b>' + Localization.FormatNumber(point.y) + '</b></td></tr>';
                        });

                        temp += '</table>';

                        return temp;
                    },

                    /*
                    headerFormat: '<table><tr>' +
                        '<td>' + this.props.t('Date') + ':</td>' +
                        '<td style="text-align:end"><b>{point.key}</b></td>' +
                        '</tr>',
                    pointFormat: '<tr><td style="padding:0"><span style="color:{series.color}">●</span><span>{series.name}:</span></td>' +
                        '<td style="padding:0;text-align:end"><b>{point.y:.1f}</b></td></tr>',
                    footerFormat: '</table>',
                    */
                },

                xAxis: {
                    tickLength: 5,
                    tickColor: alpha(style.colorPrimary3, 0.15),
                    type: 'datetime',
                    title: {
                        enabled: true,
                        // text: this.props.t('cdrChartAxisXName'),
                        style: {
                            color: style.colorPrimary1,
                            fontSize: '0.8rem'
                        }
                    },
                    labels: {
                        y: 18,
                        style: {
                            fontSize: '11px',
                            color: style.colorPrimary1
                        },
                        formatter: function () {
                            // console.log('this.value', this.value)
                            if (this.value == null)
                                return ''
                            const date = new Date(this.value)
                            // console.log('date', date)
                            const y = date.getFullYear()
                            const m = date?.getMonth()
                            const d = date?.getDate()
                            if (m == 0 && d == 1) {
                                // console.log('date?.getDate()', this.value, date, y, m, d)
                                return y
                            }

                            return null
                        }
                    },

                    startOnTick: true,
                    //endOnTick: true,
                    minPadding: 0,
                    //showLastLabel: true,
                    // tickInterval: 6 * 28 * 24 * 3600 * 1000,
                    //tickPixelInterval: 50,

                    gridLineWidth: 1,
                    lineWidth: 1,
                    lineColor: style.colorPrimary1
                },

                yAxis: {
                    title: {
                        text: this.props.t('CDRPrc'),
                        style: {
                            color: style.colorPrimary1
                        }
                    },
                    labels: {
                        style: {
                            color: style.colorPrimary1
                        }
                    },
                    min: 0,
                    softMax: 5,
                    startOnTick: true,
                    endOnTick: true,
                    gridLineWidth: 1,
                    lineWidth: 1,
                    lineColor: style.colorPrimary1
                },

                series: [{
                    name: this.props.t('WCDR'),
                    id: 'cdr',
                    color: 'rgb(109,123,130)', //style.colorPrimary3,
                    dashStyle: 'solid',
                    marker: {
                        enabled: false
                    },

                    states: {
                        hover: {
                            enabled: true,
                            lineWidthPlus: 0,
                            shadow: false
                        }
                    },

                    data: []
                }]
            },
            lastSelectedOptionId: null
        };
    }

    poolChart = null;
    sCurveChart = null;
    cprChart = null;
    cdrChart = null;
    switchButtonsContainer = null;

    labelUpdating = false;

    updateYAxisLimits = (chartOptions, step, axisPos = null) => {
        let min = 0
        let max = 0
        chartOptions.series?.forEach(serie => {
            serie?.data?.forEach(data => {
                if (min > data?.y) min = roundDown(data.y + step / 2, step)
                if (max < data?.y) max = roundUp(data.y + step / 2, step)
            })
        })

        if (min != max) {
            if (Array.isArray(chartOptions?.yAxis) && axisPos != null) {
                chartOptions.yAxis[axisPos].min = min
                chartOptions.yAxis[axisPos].max = max
                chartOptions.yAxis[axisPos].softMax = max
                // chartOptions.yAxis.forEach((t, index) => { chartOptions.yAxis[index].alignTicks = false })
            }
            else {
                chartOptions.yAxis.min = min
                chartOptions.yAxis.max = max
                chartOptions.yAxis.softMax = max
            }
        }

        // console.log('min, max', chartOptions, min, max)
    }

    autocompletePopperComponent = (props) => {
        if (!props.open) return <></>

        return (
            <Box className={this.props.classes.clickAway}>
                <Popper {...props}
                    placement='bottom-start'
                    // className={this.props.classes.autocompletePopper}
                    disablePortal={true}
                />
            </Box >
        )
    }

    formExportFileName = (event, name) => {

        var innerName = '';
        if (this.state.selection.length == 0) {
            innerName = this.props.t(name + 'ChartName');
        }
        else {
            var dest = this.state.selection.map(index => this.getBondName(this.state.poolStatistics[index], this.state.bondNameType));

            innerName = this.props.t(name + 'ChartNameForISINs') + ' ' + dest.join(' ');
        }

        event.target.options.exporting.filename = innerName;
    }

    formatDataLabels(options) {

        if (this.series.points[0].selected)
            return this.series.name;

        return '';
    }

    formatSCurveDataLabels(options) {
        if (this.point.selected) {
            // console.log('this.point', this?.point?.dataLabel.styles)
            return `${this.point.name}`; //заголовок точки
        }
        return '';
    }

    formatSCurveDataLabelsPosition(options) {
        //console.log('this', this)
        // if (this.point.selected){
        //     console.log('this.point', this?.point?.dataLabel?.alignAttr?.y)
        //     return `${this.point.name}`; //заголовок точки
        // }
        return '';
    }

    onChartRedraw(event) {
        // console.log('chart redraw')
        updateChartLabel.call(this);
    }

    LightenDarkenColor(col, amt) {

        var usePound = false;

        if (col[0] == "#") {
            col = col.slice(1);
            usePound = true;
        }

        var num = parseInt(col, 16);

        var r = (num >> 16) + amt;

        if (r > 255) r = 255;
        else if (r < 0) r = 0;

        var b = ((num >> 8) & 0x00FF) + amt;

        if (b > 255) b = 255;
        else if (b < 0) b = 0;

        var g = (num & 0x0000FF) + amt;

        if (g > 255) g = 255;
        else if (g < 0) g = 0;

        return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);

    }

    onChartRender(event) {
        // console.log(this.series)
        var parent = this.options.customData();

        if (this.series.length == 0)
            return;

        if (!this.series.some(series => series.points[0].selected)) {
            this.series.forEach(series => {
                var point = series.points[0];
                if (point.dataLabel)
                    point.dataLabel.css({ opacity: 1 });
                if (point.graphic)
                    point.graphic.attr({ opacity: 1 });
            });
            return;
        }

        // console.log(parent.state)
        this.series.forEach(series => {

            var point = series.points[0];
            // console.log('pointId',point.id);

            if (point.selected) {
                const item = parent.state.bondsColorMapping.filter(i => i.isin === point.id)
                const color = poolsColors[item.length && item[0].colorIndex]

                if (point.dataLabel)
                    point.dataLabel.css({ opacity: 1 });
                if (point.graphic)
                    point.graphic.attr({ opacity: 1, ...(item.length ? { fill: color, stroke: parent.LightenDarkenColor(color, -20) } : {}) });
            }
            else {
                if (point.dataLabel)
                    point.dataLabel.css({ opacity: 0.3 });
                if (point.graphic)
                    point.graphic.attr({ opacity: 0.3 });
            }
        });

        updateChartLabel.call(this);
    }

    onPoolMouseOver = (event) => {

        var id = event.target.options.id;
        this.poolChart.chart.series.forEach(s => {
            var point = s.points[0];

            // if(point.selected) console.log(id, point.selected, point.id ,point)

            if (!point.selected && point.id != id) {
                //point.dataLabel.css({color: hexToRgbA(style.colorPrimary1, 0.3)})

                if (point.dataLabel)
                    point.dataLabel.css({ opacity: 0.3 });
                if (point.graphic)
                    point.graphic.attr({ opacity: 0.3 });
            }
            else {
                if (point.dataLabel)
                    point.dataLabel.css({ opacity: 1 });
                if (point.graphic)
                    point.graphic.attr({ opacity: 1 });
            }
        });
    }

    onPoolMouseOut = (event) => {
        if (this.poolChart && this.poolChart.chart && this.poolChart.chart.series) {
            var id = event.target.options.id;

            // console.log(this.state.selection.length,this.poolChart)

            if (this.state.selection.length == 0) {
                //we doesn't have selections
                this.poolChart.chart.series.forEach(s => {
                    var point = s.points[0];
                    if (point.dataLabel)
                        point.dataLabel.css({ opacity: 1 });
                    if (point.graphic)
                        point.graphic.attr({ opacity: 1 });
                });
            }
            else
                if (this.poolChart && this.poolChart.chart) {

                    var index = this.poolChart.chart.series.findIndex(s => !s.points[0].selected && s.points[0].id == id);
                    if (index != -1) {

                        //restore
                        var point = this.poolChart.chart.series[index].points[0];
                        if (point.dataLabel)
                            point.dataLabel.css({ opacity: 0.3 });
                        if (point.graphic)
                            point.graphic.attr({ opacity: 0.3 });
                    }
                }
        }
    }

    onPointClick = (evt: any) => {
        var selected = [...this.state.selection];
        var selectedPoints = [];
        var removedPoints = [];

        var index = this.state.poolStatistics.findIndex(x => x.isin == evt.point.id);
        if (index == -1)
            return;

        if (!evt.point.selected) {
            selected.push(index);
            evt.point.colorIndex = 100;
            selectedPoints.push(evt.point);
            // point
        }
        else {
            var pointIndex = selected.findIndex(x => x == index);
            selected.splice(pointIndex, 1);
            removedPoints.push(evt.point);
            // this.point.colorIndex=100
        }

        evt.point.select(!evt.point.selected, true);

        if (selectedPoints.length != 0) //select
        {
            this.selectPoints(selectedPoints, true);
        }

        if (removedPoints.length != 0) { //remove
            this.selectPoints(removedPoints, false);
        }

        var items = selected.map(index => this.state.poolStatistics[index].isin);

        //CHECKME!!! DRY
        var newBondsColorMapping = [...this.state.bondsColorMapping];
        newBondsColorMapping = newBondsColorMapping.filter(x => selected.some(y => y == x.id));
        var toAdd = selected.filter(x => !this.state.bondsColorMapping.some(y => y.id == x));

        toAdd.forEach(x => {
            for (var i = 0; i < poolsColors.length; i++) {
                if (!newBondsColorMapping.some(x => x.colorIndex == i)) {
                    newBondsColorMapping.push({ id: x, isin: this.state.poolStatistics[x].isin, colorIndex: i });
                    // evt.point.colorIndex = i
                    // console.log(evt.point)
                    break;
                }
            }
        });

        this.setState({ selection: selected, bondsColorMapping: newBondsColorMapping });

        this.getBondsInfoDebounced(items, this.getLastDate());

        setTimeout(() => {

            if (evt.point.series.chart.tooltip != null)
                evt.point.series.chart.tooltip.hide();

        }, 0);

        return false;
    }

    getBondsInfoDebounced = debounce((items, date) => this.props.getBondsInfo(items, date), 1000);

    containerResize = debounce(async () => {
        if (this.props.statistics == null)
            return;

        var rate = this.props.statistics.mortgageRate;

        var changes = {};

        if (this.poolChart != null) {
            var options = { ...this.state.poolChartOptions }
            const yAxis = this.poolChart.chart.yAxis[0]
            const offset = this.poolChart.chart.xAxis[0].toPixels(rate, false)
            options.yAxis.offset = yAxis.left + yAxis.top - offset - this.state.poolChartOptions.chart?.marginLeft ?? 0;

            var plot = options.xAxis.plotLines[0];
            // var offset = this.poolChart.chart.xAxis[0].toPixels(rate, false);
            // if (this.poolChart.chart.xAxis[0].width - offset < 200)//px
            // {
            //     plot.label.x = -250;
            // }

            changes.poolChartOptions = options;
        }

        // if (this.sCurveChart != null) {
        //     var curveOptions = { ...this.state.sCurveChartOptions };
        //     curveOptions.yAxis.offset = -this.sCurveChart.chart.xAxis[0].toPixels(0, false) + this.state.sCurveChartOptions.chart.marginLeft ?? 0;
        //     changes.sCurveChartOptions = curveOptions;
        // }

        this.setState(changes);
    }, 100);

    windowMouseMove = (e) => {

        var isAtChart = false;
        for (var i = 0; i < Highcharts.charts.length; i++) {

            var chart = Highcharts.charts[i];

            // Find coordinates within the chartgreen
            //var event = chart.pointer.normalize(e);
            if (chart == null ||
                (chart != this.poolChart.chart &&
                    chart != this.sCurveChart.chart))
                continue;

            var bounds = chart.container.getBoundingClientRect();
            isAtChart = isAtChart || (e.clientX >= bounds.left && e.clientX <= bounds.right && e.clientY >= bounds.top && e.clientY <= bounds.bottom);
            if (isAtChart)
                break;
        }

        if (isAtChart)
            return;

        for (var i = 0; i < Highcharts.charts.length; i++) {

            var chart = Highcharts.charts[i];

            if (chart == null ||
                (chart != this.poolChart.chart &&
                    chart != this.sCurveChart.chart))
                continue;

            if (chart.hoverSeries != null) {
                Highcharts.Pointer.prototype.reset.call(chart.pointer);
            }
        }
    }

    cprcdrWindowMouseMove = (e) => {

        var isAtChart = false;
        for (var i = 0; i < Highcharts.charts.length; i++) {

            var chart = Highcharts.charts[i];

            // Find coordinates within the chartgreen
            //var event = chart.pointer.normalize(e);
            if (chart == null ||
                (chart != this.cprChart.chart &&
                    chart != this.cdrChart.chart))
                continue;

            var bounds = chart.container.getBoundingClientRect();
            isAtChart = isAtChart || (e.clientX >= bounds.left && e.clientX <= bounds.right && e.clientY >= bounds.top && e.clientY <= bounds.bottom);
            if (isAtChart)
                break;
        }

        if (isAtChart)
            return;

        for (var i = 0; i < Highcharts.charts.length; i++) {

            var chart = Highcharts.charts[i];

            if (chart == null ||
                (chart != this.cprChart.chart &&
                    chart != this.cdrChart.chart))
                continue;

            if (chart.hoverSeries != null) {
                Highcharts.Pointer.prototype.reset.call(chart.pointer);
            }
        }
    }

    containerMouseMove = (e) => {
        var chart,
            point,
            i,
            event;

        //checkme!!! skip first chart
        //sapprox
        for (i = 0; i < Highcharts.charts.length; i++) {

            chart = Highcharts.charts[i];
            if (chart?.pointer == null)
                continue;

            // Find coordinates within the chartgreen
            event = chart.pointer.normalize(e);
            // Get the hovered point

            var series = chart.series.find(s => s.userOptions.id == 'sapprox');
            if (series == null)
                continue;
            else {
                point = series.searchPoint(event, true);
                // console.log('e', point, event)

                if (point) {
                    point.highlight(event);
                }
            }
        }
    }

    cprcdrContainerMouseMove = (e) => {

        var points,
            event;

        var foundIndex = -1;
        var charts = [this.cprChart?.chart, this.cdrChart?.chart];


        for (var i = 0; i < charts.length; i++) {

            var bounds = charts[i].container.getBoundingClientRect();
            if (e.clientX >= bounds.left && e.clientX <= bounds.right && e.clientY >= bounds.top && e.clientY <= bounds.bottom) {
                foundIndex = i;
                break;
            }
        }

        if (foundIndex == -1)
            return;

        for (var i = 0; i < charts.length; i++) {

            if (i == foundIndex) {
                //if we move cursor over buttons, point not tracking and we need to update pointer manually

                var buttonBounds = this.switchButtonsContainer.getBoundingClientRect();
                if (!(e.clientX >= buttonBounds.left && e.clientX <= buttonBounds.right && e.clientY >= buttonBounds.top && e.clientY <= buttonBounds.bottom))
                    continue;
            }

            event = charts[i].pointer.normalize(e);
            if (charts[i]?.series[0]) {
                var point = charts[i].series[0].searchPoint(event, true);
                if (point && point.length != 0) {
                    point?.highlight(event);
                }
            }
        }
    }

    orientationCh = () => {
        var mql = window.matchMedia("(orientation: landscape)");

        if (window && window.screen &&
            (mql.matches || window.screen.width > 700)
        )
            this.setState({ mobileLocker: false })
        else {
            this.setState({ mobileLocker: true })
        }
    }
    mobileDetect = () => {
        var mql = window.matchMedia("(min-width: 1024px)");
        // console.log(mql)
        if (mql.matches)
            return false
        return true
    }

    landscapeDetect = () => {
        var mql = window.matchMedia("(orientation: landscape)");
        console.log(mql)

        // if(window && window.screen &&
        //     (mql.matches || window.screen.width > 700)
        // )
        if (mql.matches)
            return true
        else
            return false
    }

    componentDidMount() { // после вставки компонента в DOM

        if (window)
            window.addEventListener('resize', debounce(() => this.setState({ isMobile: this.mobileDetect() }), 100))
        this.setState({ isMobile: this.mobileDetect() })

        if (window) {
            window.addEventListener('resize', debounce(this.orientationCh, 300))
            this.orientationCh();
        }

        Localization.onLanguageChanged(this.onLanguageChanged);

        var startDate = this.state.queryData.date != null ? this.state.queryData.date : new Date();
        var date = Localization.FormatDateISO(startDate);

        /*
        if (this.props.router.location.search) {
            const values = queryString.parse(this.props.router.location.search);
            if (values != null && values.isins != null) {
                var arr = values.isins.split(',').slice(0, 5);
                this.setState({ selectedIsinParams: arr });
            }
        }
        */

        this.setState({ prevDate: date });
        this.props.getPoolStatistics(date, !!this.state.queryData?.showredeemed);
        this.props.getPoolsTimeline();
        //this.props.getPoolsSCurve(date);


        ['mousemove', 'touchmove', 'touchstart'].forEach((eventType) => {
            const el = document.getElementById('chartsContainer')
            if (el)
                el.addEventListener(eventType, this.containerMouseMove)

            const el1 = document.getElementById('cprcdrChartsContainer')
            if (el1)
                el1.addEventListener(eventType, this.cprcdrContainerMouseMove)

            if (window) {
                window.addEventListener(eventType, this.windowMouseMove)
                window.addEventListener(eventType, this.cprcdrWindowMouseMove)
            }
        });

        if (window) {
            window.addEventListener('resize', this.containerResize)
        }

        //if (window.pointReset == null)
        //window.pointReset = Highcharts.Pointer.prototype.reset;

        /**
         * Override the reset function, we don't need to hide the tooltips and
         * crosshairs.
         */
        //if (Highcharts.Pointer.prototype.customReset == null) {
        //this.pointReset = Highcharts.Pointer.prototype.reset;
        //Highcharts.Pointer.prototype.customReset = Highcharts.Pointer.prototype.reset;

        /*
        Highcharts.Pointer.prototype.reset = function (e, c) {
            if (this.chart.renderTo.id == 'cprChartContainer' ||
                this.chart.renderTo.id == 'cdrChartContainer')
                window.pointReset.call(this, e, c);
        }
        */

        /*
        Highcharts.Pointer.prototype.reset = (e, c) {
            if (this.chart.renderTo.id == 'cprChartContainer' ||
                this.chart.renderTo.id == 'cdrChartContainer')
                this.customReset(e, c);
        };
        */
        /**
         * Highlight a point by showing tooltip, setting hover state and draw crosshair
         */

        Highcharts.Point.prototype.highlight = function (event) {
            //  console.log('event', this, event)
            //event = this.series.chart.pointer.normalize(event);
            if (this?.series?.visible)
                this.onMouseOver(); // Show the hover marker
            //this.series.chart.tooltip.refresh(this); // Show the tooltip
            //this.series.chart.xAxis[0].drawCrosshair(event, this); // Show the crosshair
        };

        /*
        Highcharts.Chart.prototype.highlight = function (event) {
            event = this.pointer.normalize(event);
            this.xAxis[0].drawCrosshair(event, this); // Show the crosshair
        };
        */
        //this.updateDataSource();

        /*
        setTimeout(() => {
            this.poolChart.chart.reflow();
            this.sCurveChart.chart.reflow();

            this.cprChart.chart.reflow();
            this.cdrChart.chart.reflow();
        }, 0);
        */

        //log user activity
        Globals.LogActivity(3); //pools

        // if(Globals.hasAccess(Globals.Administrator_Role)) this.handleRedeemedBondsCheck()
        this.props.setTitle(this.props.t('MortgagePool')) //Устанавлимваем заголовок в Store

    }

    componentWillUnmount() {

        Localization.offLanguageChanged(this.onLanguageChanged);

        ['mousemove', 'touchmove', 'touchstart'].forEach((eventType) => {
            document.getElementById('chartsContainer').removeEventListener(
                eventType,
                this.containerMouseMove
            );

            document.getElementById('cprcdrChartsContainer').removeEventListener(
                eventType,
                this.cprcdrContainerMouseMove
            );

            window.removeEventListener(
                eventType,
                this.windowMouseMove
            );

            window.removeEventListener(
                eventType,
                this.cprcdrWindowMouseMove
            );
        });
        window.removeEventListener(
            'resize',
            this.containerResize
        );

        //this.sCurveChart.chart.pointer.reset = Highcharts.Pointer.prototype.reset;
        //this.poolChart.chart.pointer.reset = Highcharts.Pointer.prototype.reset;
        //Highcharts.Pointer.prototype.reset = window.pointReset;
        //window.pointReset = null;
        this.props.setTitle('') //Устанавлимваем заголовок в Store
    }

    componentDidUpdate(prevProps, prevState) {
        this.props.setTitle(this.props.t('MortgagePool')) //Устанавлимваем заголовок в Store

        // if (prevState.lastSelectedOptionId != this.state.lastSelectedOptionId) {
        //     setTimeout(() => {
        //         const optionEl = document.querySelector(
        //             `[data-name="${this.state.lastSelectedOptionId}"]`
        //         )
        //         if (optionEl != null)
        //             optionEl?.scrollIntoView();
        //     }, 1);
        //     //     // console.log('lastSelectedOptionId', this.state.lastSelectedOptionId)
        //     //     console.log('this.selectedOptionRef?.current', this.selectedOptionRef?.current)
        //     //     // this.selectedOptionRef?.current?.scrollIntoView()
        // }

        if (prevState.cdrSwitch != this.state.cdrSwitch || prevState.showAverageCPRCDR != this.state.showAverageCPRCDR) {

            var changes = {};

            if (prevState.showAverageCPRCDR != this.state.showAverageCPRCDR) {

                var newCPRChartOptions = { ...this.state.cprChartOptions };

                newCPRChartOptions.series.forEach(item => {
                    if (item.id == 'cpr') {
                        if (this.state.showAverageCPRCDR)
                            item.data = this.props.statistics.poolAverage.map(x => { return { x: toUnixDate(new Date(x.date)), y: x.averageCPR } });
                        else
                            item.data = this.props.statistics.poolAverage.map(x => { return { x: toUnixDate(new Date(x.date)), y: x.cprwa } });
                    }
                    else {

                        if (this.props.bonds == null)
                            return;

                        //update bonds
                        var currentBond = this.props.bonds.cprcdr.find(x => x.isin == item.id);
                        if (currentBond == null)
                            return;

                        if (this.state.showAverageCPRCDR) {
                            item.data = currentBond.averageCPR.map(x => { return { x: toUnixDate(new Date(x.x)), y: x.y } });
                        }
                        else {
                            item.data = currentBond.cpr.map(x => { return { x: toUnixDate(new Date(x.x)), y: x.y } });
                        }
                    }
                });


                changes.cprChartOptions = newCPRChartOptions;
            }

            var newCDRChartOptions = { ...this.state.cdrChartOptions };
            newCDRChartOptions.series.forEach(item => {

                if (item.id == 'cdr') {
                    if (this.state.cdrSwitch == 2) {
                        if (this.state.showAverageCPRCDR)
                            item.data = this.props.statistics.poolAverage.map(x => { return { x: toUnixDate(new Date(x.date)), y: x.averageCDRBuyouts } });
                        else
                            item.data = this.props.statistics.poolAverage.map(x => { return { x: toUnixDate(new Date(x.date)), y: x.cdrBuyoutsWA } });
                    }
                    else {
                        if (this.state.showAverageCPRCDR)
                            item.data = this.props.statistics.poolAverage.map(x => { return { x: toUnixDate(new Date(x.date)), y: x.averageCDR } });
                        else
                            item.data = this.props.statistics.poolAverage.map(x => { return { x: toUnixDate(new Date(x.date)), y: x.cdrwa } });
                    }
                }
                else {
                    //update bonds
                    var currentBond = this.props.bonds.cprcdr.find(x => x.isin == item.id);
                    if (currentBond == null)
                        return;

                    if (this.state.cdrSwitch == 2) {
                        if (this.state.showAverageCPRCDR) {
                            //cdrBuyoutsAverage!
                            item.data = currentBond.averageCDRBuyouts.map(x => { return { x: toUnixDate(new Date(x.x)), y: x.y } });
                        }
                        else {
                            item.data = currentBond.cdrBuyouts.map(x => { return { x: toUnixDate(new Date(x.x)), y: x.y } });
                        }
                    }
                    else {
                        if (this.state.showAverageCPRCDR) {
                            item.data = currentBond.averageCDR.map(x => { return { x: toUnixDate(new Date(x.x)), y: x.y } });
                        }
                        else {
                            item.data = currentBond.cdr.map(x => { return { x: toUnixDate(new Date(x.x)), y: x.y } });
                        }
                    }
                }
            });

            this.updateYAxisLimits(newCDRChartOptions, 1)
            changes.cdrChartOptions = newCDRChartOptions;

            this.setState(changes);
        }

        if (prevProps.bonds != this.props.bonds && this.props.bonds != null) {

            var newCPRChartOptions = { ...this.state.cprChartOptions };
            var newCDRChartOptions = { ...this.state.cdrChartOptions };

            var changes = { bonds: this.props.bonds.compareStats };

            //remove all series belongs to pools cpr/cdr
            var cprChanged = newCPRChartOptions.series.filter(item => item.subId != "bond");
            if (cprChanged.length != 0) {
                newCPRChartOptions.series = cprChanged;
                changes.cprChartOptions = newCPRChartOptions;
            }

            var cdrChanged = newCDRChartOptions.series.filter(item => item.subId != "bond");
            if (cdrChanged.length != 0) {
                newCDRChartOptions.series = cdrChanged;
                changes.cdrChartOptions = newCDRChartOptions;
            }

            //var utils = new DateFnsUtils();

            if (this.props.bonds.cprcdr != null && this.props.bonds.cprcdr.length != 0) {

                //sync problem - we can remove pool before data loads.
                //so, data we have, but pool already deselected.
                //we need to compare data and available pool and leave exist only

                var atDest = this.state.selection.map(index => this.state.poolStatistics[index].isin);
                var avialableItems = atDest.filter(isin => this.props.bonds.cprcdr.some(y => y?.isin == isin));
                var seriesData = this.props.bonds.cprcdr.filter(x => avialableItems.some(isin => isin == x.isin));

                var cprSeries = seriesData.map((item, index) => {

                    var name = item.isin;
                    var pool = this.state.poolStatistics.find(x => x.isin == item.isin);
                    if (pool != null) {
                        name = this.getBondName(pool, this.state.bondNameType);
                    }

                    var seriesData = null;
                    if (this.state.showAverageCPRCDR)
                        seriesData = item.averageCPR.map(pt => { return { x: toUnixDate(new Date(pt.x)), y: pt.y } })
                    else
                        seriesData = item.cpr.map(pt => { return { x: toUnixDate(new Date(pt.x)), y: pt.y } })

                    return {
                        name: name,
                        id: item.isin,
                        subId: 'bond',
                        color: poolsColors[this.state.bondsColorMapping.find(x => x.isin == item.isin).colorIndex],
                        yAxis: 0,

                        marker: {
                            enabled: false,
                            symbol: 'circle'
                        },
                        data: seriesData
                    }
                });

                newCPRChartOptions.series[0].legendIndex = 0;
                newCPRChartOptions.series[0].color = hexToRgbA(style.colorPrimary3, 0.5);
                newCPRChartOptions.series[0].visible = true;
                newCPRChartOptions.series[0].showInLegend = false;
                newCPRChartOptions.series[0].states.hover.enabled = false;

                if (this.props.statistics != null) {
                    if (this.state.showAverageCPRCDR)
                        newCPRChartOptions.series[0].data = this.props.statistics.poolAverage.map(x => { return { x: toUnixDate(new Date(x.date)), y: x.averageCPR } });
                    else
                        newCPRChartOptions.series[0].data = this.props.statistics.poolAverage.map(x => { return { x: toUnixDate(new Date(x.date)), y: x.cprwa } });
                }


                newCPRChartOptions.series[1].visible = false;
                newCPRChartOptions.series[1].showInLegend = false;

                newCPRChartOptions.series[2].visible = false;
                newCPRChartOptions.series[2].showInLegend = false;

                newCPRChartOptions.series = newCPRChartOptions.series.concat(cprSeries);
                newCPRChartOptions.yAxis[1].visible = false;
                newCPRChartOptions.chart.marginRight = undefined;

                this.updateYAxisLimits(newCPRChartOptions, 5, 0)
                changes.cprChartOptions = newCPRChartOptions;

                var cdrSeries = seriesData.map((item, index) => {

                    var name = item.isin;
                    var pool = this.state.poolStatistics.find(x => x.isin == item.isin);
                    if (pool != null) {
                        name = this.getBondName(pool, this.state.bondNameType);
                    }

                    var seriesData = null;
                    if (this.state.cdrSwitch == 2) {
                        if (this.state.showAverageCPRCDR)
                            seriesData = item.averageCDRBuyouts.map(pt => { return { x: toUnixDate(new Date(pt.x)), y: pt.y } });
                        else
                            seriesData = item.cdrBuyouts.map(pt => { return { x: toUnixDate(new Date(pt.x)), y: pt.y } });
                    }
                    else {
                        if (this.state.showAverageCPRCDR)
                            seriesData = item.averageCDR.map(pt => { return { x: toUnixDate(new Date(pt.x)), y: pt.y } });
                        else
                            seriesData = item.cdr.map(pt => { return { x: toUnixDate(new Date(pt.x)), y: pt.y } });
                    }

                    return {
                        name: name,
                        id: item.isin,
                        subId: 'bond',
                        color: poolsColors[this.state.bondsColorMapping.find(x => x.isin == item.isin).colorIndex],
                        yAxis: 0,

                        marker: {
                            enabled: false,
                            symbol: 'circle'
                        },
                        data: seriesData
                    }
                });


                newCDRChartOptions.chart.marginRight = undefined;


                newCDRChartOptions.series[0].color = hexToRgbA(style.colorPrimary3, 0.5);
                newCDRChartOptions.series[0].visible = true;
                newCDRChartOptions.series[0].showInLegend = false;
                newCDRChartOptions.series[0].states.hover.enabled = false;

                if (this.props.statistics != null) {
                    if (this.state.cdrSwitch == 2) {
                        if (this.state.showAverageCPRCDR)
                            newCDRChartOptions.series[0].data = this.props.statistics.poolAverage.map(x => { return { x: toUnixDate(new Date(x.date)), y: x.averageCDRBuyouts } });
                        else
                            newCDRChartOptions.series[0].data = this.props.statistics.poolAverage.map(x => { return { x: toUnixDate(new Date(x.date)), y: x.cdrBuyoutsWA } });
                    }
                    else {
                        if (this.state.showAverageCPRCDR)
                            newCDRChartOptions.series[0].data = this.props.statistics.poolAverage.map(x => { return { x: toUnixDate(new Date(x.date)), y: x.averageCDR } });
                        else
                            newCDRChartOptions.series[0].data = this.props.statistics.poolAverage.map(x => { return { x: toUnixDate(new Date(x.date)), y: x.cdrwa } });
                    }
                }

                // console.log('cdrSeries', cdrSeries)
                newCDRChartOptions.series = newCDRChartOptions.series.concat(cdrSeries);
                this.updateYAxisLimits(newCDRChartOptions, 1)
                changes.cdrChartOptions = newCDRChartOptions;
            }
            else if (this.props.statistics != null) {

                var isVisible = this.state.queryData.isins == null || this.state.queryData.isins.length == 0;

                newCPRChartOptions.yAxis[1].visible = true;

                newCPRChartOptions.series[0].visible = isVisible;
                newCPRChartOptions.series[0].legendIndex = 3;

                newCPRChartOptions.series[1].visible = isVisible;
                newCPRChartOptions.series[1].showInLegend = isVisible;
                newCPRChartOptions.series[1].data = this.props.statistics.mortgageRates.map(x => { return { x: toUnixDate(new Date(x.date)), y: x.refinancingRate } });



                newCPRChartOptions.series[0].color = 'rgb(167,209,111)' //style.colorPrimary3
                newCPRChartOptions.series[0].showInLegend = isVisible;
                newCPRChartOptions.series[0].states.hover.enabled = isVisible;

                newCPRChartOptions.series[2].visible = isVisible;
                newCPRChartOptions.series[2].showInLegend = isVisible;
                newCPRChartOptions.series[2].data = this.props.statistics.poolAverage.map(x => { return { x: toUnixDate(new Date(x.date)), y: x.wacwa } });


                newCDRChartOptions.chart.marginRight = 61;

                newCDRChartOptions.series[0].color = 'rgb(109,123,130)' //style.colorPrimary3
                newCDRChartOptions.series[0].visible = isVisible;
                newCDRChartOptions.series[0].showInLegend = isVisible;
                newCDRChartOptions.series[0].states.hover.enabled = isVisible;

                this.updateYAxisLimits(newCPRChartOptions, 5, 0)
                changes.cprChartOptions = newCPRChartOptions;
                this.updateYAxisLimits(newCDRChartOptions, 1)
                changes.cdrChartOptions = newCDRChartOptions;
            }

            this.setState(changes);
        }

        if (prevState.selection != this.state.selection) {
            var changes = {};
            var newPoolChartOptions = { ...this.state.poolChartOptions };

            // console.log('changes.poolStatistics', changes.poolStatistics)
            // changes.poolStatistics = this.props.statistics.statistics.map(x => { /*console.log(x);*/ return { name: x.name, isin: x.isin, regNumber: x.regNumber, ticker: x.ticker, bloombergTicker: x.bloombergTicker, status: x.status, issueDate: x.issueDate } });

            //Выдержка
            //от 0 до 1.5 до 2.5 
            var rate = this.props.statistics.mortgageRate;

            var minX = Infinity;
            var maxX = -Infinity;

            // var statisticsFiltered = this.props.statistics.statistics.filter((x,index) =>{ 
            //                     //фильтруем облигации если ( тип НЕ 6 или входят в "выбранные")
            //                     // return ((x.couponType != CouponType.TYPE_KEYRATE_AND_PREMIUM) || !!(this.state.selection.filter(item_pos=>item_pos===index).length) )
            //                     return true
            //                 }
            //     );
            this.props.statistics.statistics.filter((x, index) => {
                //фильтруем облигации если ( тип НЕ 6 или входят в "выбранные")
                return ((x.couponType != CouponType.TYPE_KEYRATE_AND_PREMIUM) || !!(this.state.selection.filter(item_pos => item_pos === index).length))
            }).forEach((item) => {
                if (item.wac < minX)
                    minX = item.wac;

                if (item.wac > maxX) {
                    maxX = item.wac;
                }
            });

            if (rate < minX)
                minX = Math.floor(rate) - 1;

            else if (rate > minX && rate < maxX)
                minX = Math.floor(minX) - 1;

            else
                minX = Math.floor(minX);


            if (rate > maxX)
                maxX = Math.round(rate) + 1
            else
                maxX = Math.round(maxX);

            maxX = 24

            newPoolChartOptions.xAxis.min = minX;
            newPoolChartOptions.xAxis.max = maxX;

            // console.log('changes')
            var ticks = [];
            var position = minX;
            for (var i = 0; i <= (maxX - minX); i++) {
                ticks.push(position);
                position = Math.round((position + 1) * 100) / 100.0;
            }
            newPoolChartOptions.xAxis.tickPositions = ticks;



            //get scurvedata with bounds
            var date = this.state.timeline[this.state.timelineValueCommitted].originalValue;
            // console.log('maxX', maxX)

            this.props.getPoolsSCurve(date, minX - rate, maxX - rate);

            changes.poolChartOptions = newPoolChartOptions;

            changes.minX = minX;
            changes.maxX = maxX;

            this.setState(
                changes
                , () => {
                    var options = { ...this.state.poolChartOptions };
                    options.yAxis.offset = -this.poolChart.chart.xAxis[0].toPixels(rate, false) + this.state.poolChartOptions.chart.marginLeft ?? 0;
                    this.setState({ poolChartOptions: options }/*, () => this.updateChartLabel()*/);
                }
            ); //cleanup selected bonds

        }

        if (prevProps.statistics != this.props.statistics && this.props.statistics != null && this.props.statistics.statistics != null) {
            var changes = {};
            var newPoolChartOptions = { ...this.state.poolChartOptions };

            changes.poolStatistics = this.props.statistics.statistics.map(x => { /*console.log(x);*/ return { name: x.name, isin: x.isin, regNumber: x.regNumber, ticker: x.ticker, bloombergTicker: x.bloombergTicker, status: x.status, issueDate: x.issueDate } });

            //Выдержка
            //от 0 до 1.5 до 2.5 
            var rate = this.props.statistics.mortgageRate;

            var minX = Infinity;
            var maxX = -Infinity;

            // var statisticsFiltered = this.props.statistics.statistics.filter((x,index) =>{ 
            //                     //фильтруем облигации если ( тип НЕ 6 или входят в "выбранные")
            //                     // return ((x.couponType != CouponType.TYPE_KEYRATE_AND_PREMIUM) || !!(this.state.selection.filter(item_pos=>item_pos===index).length) )
            //                     return true
            //                 }
            //     );
            this.props.statistics.statistics.filter((x, index) => {
                //фильтруем облигации если ( тип НЕ 6 или входят в "выбранные")
                return ((x.couponType != CouponType.TYPE_KEYRATE_AND_PREMIUM) || !!(this.state.selection.filter(item_pos => item_pos === index).length))
            }).forEach((item) => {
                if (item.wac < minX)
                    minX = item.wac;

                if (item.wac > maxX)
                    maxX = item.wac;
            });

            if (rate < minX) {
                minX = Math.floor(rate) - 1;
            }
            else if (rate > minX && rate < maxX) {
                minX = Math.floor(minX) - 1;
            }
            else {
                minX = Math.floor(minX);
            }

            if (rate > maxX) {
                maxX = Math.round(rate) + 1
            } else {
                maxX = Math.round(maxX);
            }
            maxX = 24
            newPoolChartOptions.xAxis.min = minX;
            newPoolChartOptions.xAxis.max = maxX;

            var ticks = [];
            var position = minX;
            for (var i = 0; i <= (maxX - minX); i++) {
                ticks.push(position);
                position = Math.round((position + 1) * 100) / 100.0;
            }
            newPoolChartOptions.xAxis.tickPositions = ticks;
            //get scurvedata with bounds
            var date = this.state.timeline[this.state.timelineValueCommitted]?.originalValue;
            // console.log('ss',date, rate, minX - rate, maxX - rate)
            // console.log('minX - rate', minX - rate)

            this.props.getPoolsSCurve(date, minX - rate, maxX - rate);

            changes.poolChartOptions = newPoolChartOptions;

            var plot = newPoolChartOptions.xAxis.plotLines[0];
            plot.value = rate;
            //plot.label.text = 'TEST';
            //CHECKME!!!
            plot.label.text = ''; //'<div style="width: 200px;white-space: normal;">' + this.props.t('MortgageRefinancingRate') + ' <b>' + rate + '%</b><div>';

            var series = this.props.statistics.statistics.map(x => {

                var color = makeGradient(style.colorPrimary2, style.colorPrimary1, (x.wac - minX) / (maxX - minX));

                return {
                    name: this.getBondName(x, this.state.bondNameType),
                    //selected: false,
                    color: hexToRgbA(color, 0.5),

                    marker: {
                        enabled: true,
                        symbol: 'circle',
                        lineColor: color,
                        lineWidth: 1,
                        states: {
                            select: {
                                //enabled: true,
                                radius: 8,
                                fillColor: hexToRgbA(color, 0.5),
                                lineColor: color,
                                lineWidth: 1
                            }
                        }
                    },
                    data: [{
                        id: x.isin,
                        x: x.wac,
                        y: x.wala,
                    }]
                }
            });

            newPoolChartOptions.series = series;

            ///////////////////////////////////////////////////////////////////////////////////////
            //var utils = new DateFnsUtils();

            var newCPRChartOptions = { ...this.state.cprChartOptions };
            // console.log('this.state.cprChartOptions', this.state.cprChartOptions)
            //CHECKME!!! temporary, until I will add chart data 'save' between the dates change
            newCPRChartOptions.series.splice(3, newCPRChartOptions.series.length - 3);

            newCPRChartOptions.series[0].data = this.props.statistics.poolAverage.map(x => { return { x: toUnixDate(new Date(x.date)), y: x.cprwa } });
            newCPRChartOptions.series[1].data = this.props.statistics.mortgageRates.map(x => { return { x: toUnixDate(new Date(x.date)), y: x.refinancingRate } });
            newCPRChartOptions.series[2].data = this.props.statistics.poolAverage.map(x => { return { x: toUnixDate(new Date(x.date)), y: x.wacwa } });

            this.updateYAxisLimits(newCPRChartOptions, 5, 0)
            changes.cprChartOptions = newCPRChartOptions;

            ///////////////////////////////////////////////////////////////////////////////////////
            var newCDRChartOptions = { ...this.state.cdrChartOptions };
            newCDRChartOptions.series.splice(1, newCDRChartOptions.series.length - 1);
            if (this.state.cdrSwitch == 2) {
                if (this.state.showAverageCPRCDR)
                    newCDRChartOptions.series[0].data = this.props.statistics.poolAverage.map(x => { return { x: toUnixDate(new Date(x.date)), y: x.averageCDRBuyouts } });
                else
                    newCDRChartOptions.series[0].data = this.props.statistics.poolAverage.map(x => { return { x: toUnixDate(new Date(x.date)), y: x.cdrBuyoutsWA } });
            }
            else {
                if (this.state.showAverageCPRCDR)
                    newCDRChartOptions.series[0].data = this.props.statistics.poolAverage.map(x => { return { x: toUnixDate(new Date(x.date)), y: x.averageCDR } });
                else
                    newCDRChartOptions.series[0].data = this.props.statistics.poolAverage.map(x => { return { x: toUnixDate(new Date(x.date)), y: x.cdrwa } });
            }

            this.updateYAxisLimits(newCDRChartOptions, 1)
            changes.cdrChartOptions = newCDRChartOptions;

            if (this.state.queryData.isins != null && this.state.queryData.isins.length != 0) {
                newCPRChartOptions.series.forEach(s => {
                    s.visible = false;
                    s.showInLegend = false;
                })

                newCDRChartOptions.series.forEach(s => {
                    s.visible = false;
                    s.showInLegend = false;
                })
            }
            ///////////////////////////////////////////////////////////////////////////////////////
            //changes.selectedIsinParams = [];
            if ((this.state.selection?.length ?? 0) != 0) {
                var currentItems = this.state.selection.map(index => prevProps.statistics.statistics[index].isin);
                changes.selection = [];
                var stats = this.props.statistics.statistics;
                for (var i = 0; i < stats.length; i++) {
                    if (currentItems.includes(stats[i].isin))
                        changes.selection.push(i);
                }
                changes.bonds = this.state.bonds.filter(x => currentItems.includes(x.isin));
            }

            //changes.bonds = [];
            //changes.selection = [];
            changes.minX = minX;
            changes.maxX = maxX;

            this.setState(
                changes
                //{
                //poolChartOptions: newPoolChartOptions,
                //poolStatistics: poolStatistics,
                //cprChartOptions: newCPRChartOptions,
                //cdrChartOptions: newCDRChartOptions,
                //selectedIsinParams: [],
                //bonds: [],
                //selection: [],
                //minX: minX,
                //maxX: maxX
            /*}*/, () => {

                    var options = { ...this.state.poolChartOptions };
                    options.yAxis.offset = -this.poolChart.chart.xAxis[0].toPixels(rate, false) + this.state.poolChartOptions.chart.marginLeft ?? 0;

                    var plot = options.xAxis.plotLines[0];
                    var offset = this.poolChart.chart.xAxis[0].toPixels(rate, false);
                    if (this.poolChart.chart.xAxis[0].width - offset < 200)//px
                    {
                        plot.label.x = -250;
                    }

                    this.setState({ poolChartOptions: options }/*, () => this.updateChartLabel()*/);

                    //CHECKME!!! temporary removed
                    //if (this.props.scurve != null)
                    //this.updateSCurve(this.props.scurve, rate);

                }); //cleanup selected bonds
        }

        if (prevProps.timeline != this.props.timeline && this.props.timeline != null) {

            var bondsTimeline = this.props.timeline.map((item, index) => {
                return {
                    value: index,
                    valueLabel: Localization.FormatDateStringLocale(item),
                    label: null,
                    originalValue: Localization.FormatDateStringISO(item)
                };
            });

            var currentYear = 0;
            for (var i = 0; i < bondsTimeline.length; i++) {
                var fullYear = (new Date(bondsTimeline[i].originalValue)).getFullYear();
                if (currentYear != fullYear) {
                    currentYear = fullYear;
                    bondsTimeline[i].label = currentYear.toString();
                }
                else
                    bondsTimeline[i].label = null;
            }

            var timelineValue = bondsTimeline.length - 1;
            if (this.state.queryData.date != null) {
                var index = bondsTimeline.findIndex(x => Date.parse(x.originalValue) == this.state.queryData.date.getTime());
                if (index != -1) {
                    timelineValue = index;
                }
            }

            this.setState({ timeline: bondsTimeline, timelineValue: timelineValue, timelineValueCommitted: timelineValue });
        }

        if (prevState.timelineValueCommitted != this.state.timelineValueCommitted) {

            //remove all selections. temporary?
            //this.onSelectionChanged([], true);

            var date = this.state.timeline[this.state.timelineValueCommitted].originalValue;
            if (this.state.prevDate != date) {
                this.props.getPoolStatistics(date, this.state.showRedeemedBonds);
                this.setState({ prevDate: date });
            }
            //this.props.getPoolsSCurve(date);
        }

        if (this.props.scurve && prevProps.scurve != this.props.scurve && this.props.statistics != null) {
            var points = this.sCurveChart.chart.getSelectedPoints();
            points.forEach(p => p.select(false, true));
            this.setState({ pointsMap: [] });

            this.updateSCurve(this.props.scurve, this.props.statistics.mortgageRate);
        }

        if (prevProps.poolCDR != this.props.poolCDR) {

            //var utils = new DateFnsUtils();

            ///////////////////////////////////////////////////////////////////////////////////////
            var newCDRChartOptions = { ...this.state.cdrChartOptions };
            newCDRChartOptions.series[0].data = this.props.poolCDR.averageCDR.map(x => { return { x: toUnixDate(new Date(x.reportDate)), y: x.cdr } });
            if (this.props.poolCDR != null && this.props.poolCDR.poolsCDR.length != 0) {

                for (var i = 1; i < newCDRChartOptions.series.length; i++) {
                    var data = this.props.poolCDR.poolsCDR.find(x => x.isin == newCDRChartOptions.series[i].id);
                    if (data != null) {
                        newCDRChartOptions.series[i].data = data.cdr.map(x => {
                            if (x?.cdr < min) min = x.cdr
                            if (x?.cdr > max) max = x.cdr
                            return {
                                x: toUnixDate(new Date(x.reportDate)),
                                y: x.cdr
                            }
                        });
                    }
                }
            }

            this.setState({ cdrChartOptions: newCDRChartOptions });
        }

        if (this.state.queryData.isins != null && this.state.queryData.isins.length != 0 &&
            prevState.sCurveChartOptions != this.state.sCurveChartOptions &&
            this.props.statistics != null) {

            var indexes = [];

            this.state.queryData.isins.forEach((item, index) => {
                var itemIndex = this.props.statistics.statistics.findIndex(x => x.isin == item);
                if (itemIndex != -1)
                    indexes.push(itemIndex);
            });

            var colorIndexes = this.state.queryData.colorIndexes;

            this.setState({ queryData: {} }, () => this.setSelectionChange(this.state.selection, indexes, false, colorIndexes));
        }

        if (prevState.selection != this.state.selection && this.state.poolStatistics != null) {
            // console.log('poolStatistics', this.state.poolStatistics)
            this.setSelectionChange(prevState.selection, this.state.selection);

            if (this.state.showAverageCPRCDR && this.state.queryData.showaverages === false) {

                this.setState({ showAverageCPRCDR: false }); //reset
            }
        }

        //check for tracked properties change
        if (prevState.selection != this.state.selection ||
            prevState.showAverageCPRCDR != this.state.showAverageCPRCDR ||
            prevState.cdrSwitch != this.state.cdrSwitch ||
            prevState.timelineValueCommitted != this.state.timelineValueCommitted ||
            prevState.showRedeemedBonds != this.state.showRedeemedBonds) {
            setTimeout(() => this.updateQueryString(), 0);
        }

        if (prevState.showRedeemedBonds != this.state.showRedeemedBonds) {

            if (!this.state.showRedeemedBonds) //it means we can have selected redeemed bonds. We need to cleanup it
            {
                var selected = this.state.selection.filter(index => this.state.poolStatistics[index].status != 4);
                this.setState({ selection: selected });
            }

            this.props.getPoolStatistics(this.getLastDate());//, this.state.showRedeemedBonds);
        }
    }

    validateQuery = (data) => {

        var output = {};

        //parsing input data


        if (data == null)
            return output;

        //const isinRegex = /^[A-Za-z0-9]{12}$/;
        if (data.isins != null) {
            var arr = data.isins.split(',').slice(0, 5); //5 only

            output.colorIndexes = [];
            for (var i = 0; i < arr.length; i++) {
                if (arr[i].length != 0) //we skip empty places
                    output.colorIndexes.push(i);
            }

            output.isins = arr.filter(x => x.length != 0 /*&& isinRegex.test(x)*/);
        }

        //date
        if (data.date != null) {
            var date = Date.parse(data.date);
            if (!isNaN(date)) {
                output.date = new Date(date);
            }
        }

        //showaverages
        if (data.showaverages != null) {
            if (data.showaverages == 'true' || data.showaverages == 1)
                output.showaverages = true;
            else if ((data.showaverages == 'false' || data.showaverages == 0))
                output.showaverages = false;
        }

        //showRedeemedBonds
        if (data.showredeemed != null) {
            if (data.showredeemed == 'true' || data.showredeemed == 1) {
                output.showredeemed = Globals.hasAccess(Globals.Administrator_Role);
            }
            else if ((data.showredeemed == 'false' || data.showredeemed == 0))
                output.showredeemed = false;
        }

        //check cdr type
        //1 - issues
        //2 - buyouts

        if (data.cdrtype != null) {
            var cdrtype = parseFloat(data.cdrtype);
            if (!isNaN(cdrtype) && cdrtype > 0 && cdrtype < 2) {
                output.cdrtype = cdrtype;
            }
        }

        return output;
    }

    updateQueryString = () => {

        // console.log(this.state.poolStatistics)
        var isins = null;
        if (this.state.bondsColorMapping != null && this.state.bondsColorMapping.length != 0) {

            const maxIndex = Math.max(...this.state.bondsColorMapping.map(x => x.colorIndex), 0);
            isins = Array(maxIndex + 1).fill('');
            for (var i = 0; i < this.state.bondsColorMapping.length; i++) {
                var item = this.state.bondsColorMapping[i];
                isins[item.colorIndex] = item.isin;
            }

            isins = isins.join(',');
        }

        var date = null; //if we have tha last date, we doesn't show it
        if (!this.isTimelineLastDate()) {
            var date = this.getLastDate();
            if (date != null)
                date = new Date(date);

            date = Localization.FormatDateISO(date);
        }

        var query = {
            isins: isins,
            date: date,
            showaverages: this.state.showAverageCPRCDR == false ? null : this.state.showAverageCPRCDR,
            showredeemed: this.state.showRedeemedBonds,
            cdrtype: this.state.cdrSwitch == 2 ? null : this.state.cdrSwitch
        }

        var urlQueryParameters = Object.keys(query).filter(x => query[x] != null).map(function (k) {
            return encodeURIComponent(k) + '=' + encodeURIComponent(query[k])
        }).join('&');

        if (urlQueryParameters.length != 0) {
            window.history.replaceState(null, '', `${window.location.origin + window.location.pathname}?${urlQueryParameters}`);
        }
        else {
            window.history.replaceState(null, '', window.location.origin + window.location.pathname);
        }
    }

    onLanguageChanged = (lng: any) => {

        //TODO!!!
        //this.setState({ columns: this.getColumns(), tableMessages: this.getMessages() });

        setHighchartsLocalization(Highcharts, this.props.t);
        this.updateHighchartsOptions();
    }

    updateHighchartsOptions = () => {

        var poolOptions = { ...this.state.poolChartOptions };

        poolOptions.xAxis.title.text = this.props.t('PoolWACPrc');
        poolOptions.yAxis.title.text = this.props.t('WALADescription');

        //var plot = poolOptions.xAxis.plotLines[0];
        //plot.label.text = '<div style="width: 200px;white-space: normal;">' + this.props.t('MortgageRefinancingRate') + ' <b>' + plot.value + '%</b><div>';


        var scurveOptions = { ...this.state.sCurveChartOptions };

        scurveOptions.series[0].name = this.props.t('SCurve');
        scurveOptions.series[1].name = this.props.t('SCurveApproximation');
        scurveOptions.xAxis.title.text = this.props.t('RateDifferencePrc');
        scurveOptions.yAxis.title.text = this.props.t('CPRPrc');


        var cprOptions = { ...this.state.cprChartOptions };
        var cprExisted = cprOptions.series.find(x => x.id == "cpr");
        var mortgageExisted = cprOptions.series.find(x => x.id == "mortgageRate");
        var wacExisted = cprOptions.series.find(x => x.id == "wac");
        if (cprExisted != null) {
            cprExisted.name = this.props.t('WCPR');
        }
        if (mortgageExisted != null) {
            mortgageExisted.name = this.props.t('AverageRefRate');
        }
        if (wacExisted != null) {
            wacExisted.name = this.props.t('WACPrc');
        }

        cprOptions.yAxis[0].title.text = this.props.t('CPRPrc');
        if (cprOptions.yAxis.length > 1)
            cprOptions.yAxis[1].title.text = this.props.t('WACAndAverageRefRate');

        //cprOptions.tooltip.headerFormat = '<table><tr><td>' + this.props.t('Month') + ':</td><td style="text-align:end"><b>{point.key}</b></td></tr>';

        var cdrOptions = { ...this.state.cdrChartOptions };
        cdrOptions.series[0].name = this.props.t('WCDR');
        cdrOptions.tooltip.headerFormat = '<table><tr><td>' + this.props.t('Date') + ':</td><td style="text-align:end"><b>{point.key}</b></td></tr>';

        cdrOptions.yAxis.title.text = this.props.t('CDRPrc');
        // cdrOptions.xAxis.title.text = this.props.t('cdrChartAxisXName');


        /////////////////////////////////////////////////////////////
        var columns = [...this.state.poolColumns];
        columns[0].title = this.props.t('Name'); //CHECKME!!! make function to create columns array

        this.setState({
            poolChartOptions: poolOptions,
            sCurveChartOptions: scurveOptions,
            cprChartOptions: cprOptions,
            cdrChartOptions: cdrOptions,
            poolColumns: columns
        }/*, () => this.updateChartLabel()*/);
    }

    /*
    approximateSCurve(scurve) {

        if (scurve == null || scurve.length == 0)
            return [];

        var x = scurve.map(item => item.x);
        var y = scurve.map(item => item.y);

        var coeffs = Approximation.Polynomial(x, y, 5);
        var points = [];
        for (var i = 0; i < x.length; i++) {

            var sum = 0;
            var pow = x[i];
            for (var j = 1; j < coeffs.length; j++) {
                sum = sum + coeffs[j] * pow;
                pow = pow * x[i];
            }

            sum += coeffs[0]; //constant

            //var y = Math.pow(x[i], 3) * coeffs[3] + Math.pow(x[i], 2) * coeffs[2] + x[i] * coeffs[1] + coeffs[0];

            points.push([x[i], sum * 100]);
        }

        return points;
    }
    */

    updateSCurve(scurve, mortgageRate) {

        if (this.state.minX == null || this.state.maxX == null)
            return;

        var curveOptions = { ...this.state.sCurveChartOptions };

        var minX = Infinity;
        var maxX = -Infinity;
        //var sCurveMinX = null;

        // for (var i = 0; i < scurve.sCurve.length; i++) {
        //     if (scurve.sCurve[i].x > maxX)
        //         maxX = scurve.sCurve[i].x;
        //     if (scurve.sCurve[i].x < minX)
        //         minX = scurve.sCurve[i].x;
        // }
        // console.log('approximated',scurve.approximated)
        if (scurve.approximated)
            for (var i = 0; i < scurve.approximated.length; i++) {
                if (scurve.approximated[i].x > maxX)
                    maxX = scurve.approximated[i].x;
                if (scurve.approximated[i].x < minX)
                    minX = scurve.approximated[i].x;
            }

        /*
        if (this.poolChart.chart.xAxis[0].dataMin != null) {
            sCurveMinX = minX;
            minX = this.poolChart.chart.xAxis[0].dataMin - mortgageRate;
        }
        */

        var rateDiff = Math.round((mortgageRate - this.state.minX) * 100) / 100.0;
        //set extremes
        maxX = 19 + 5
        curveOptions.xAxis.min = minX;
        curveOptions.xAxis.max = maxX;

        var ticks = [];
        var position = -rateDiff;
        for (var i = 0; i <= (this.state.maxX - this.state.minX); i++) {
            ticks.push(position);
            position = Math.round((position + 1) * 100) / 100.0;
        }

        curveOptions.xAxis.tickPositions = ticks;
        curveOptions.series[0].data = scurve?.sCurve?.filter(data => data.x > -13)?.map(x => [x.x, x.y * 100]); //in percents;

        //create approximation
        var points = null;
        if (scurve.approximated != null)
            points = scurve.approximated.map(x => [x.x, x.y * 100]); //in percents //this.approximateSCurve(scurve);


        /*need to fill interval with specific step
        if (sCurveMinX != null && points != null) {
            var y = points[0][1];
            points.splice(0, 0, [minX, y]);
        }
        */

        var minY = Infinity;
        var maxY = -Infinity;
        // console.log('scurve.sCurve', scurve.sCurve)
        // scurve.sCurve.filter(item => item.x >= curveOptions.xAxis.min && item.x <= curveOptions.xAxis.max).forEach(item => {
        //         scurve.sCurve?.filter(item => item.x >= minX && item.x <= maxX)?.forEach(item => {
        //             // console.log(curveOptions.xAxis)
        //             if (minY > item.y)
        //                 minY = item.y;
        // console.log('item.y', item.y)
        //             if (maxY < item.y)
        //                 maxY = item.y;
        //         });

        // if (scurve.approximated != null) {
        //     // scurve.approximated.filter(item => item.x >= curveOptions.xAxis.min && item.x <= curveOptions.xAxis.max).forEach(item => {
        //     scurve.approximated.filter(item => item.x >= minX && item.x <= maxX).forEach(item => {
        //         if (minY > item.y)
        //             minY = item.y;

        //         if (maxY < item.y)
        //             maxY = item.y;
        //     });
        // }
        // console.log('scurve.approximated', scurve.approximated)

        // console.log('maxY', maxY)
        // if (scurve.debt != null) {
        //     console.log('scurve.debt', scurve.debt)
        // }

        minY = 5
        maxY = 35
        // maxY = Math.round(maxY * 100 * 100) / 100;
        // var whole = Math.round(maxY / 5);
        // if (maxY > whole * 5)
        //     maxY = (whole + 1) * 5;
        // else
        //     maxY = whole * 5;
        // console.log(minY, maxY);

        // minY = Math.round(minY * 100 * 100) / 100;
        // var whole = Math.round(minY / 5);

        // if (minY > whole * 5)
        //     minY = whole * 5;
        // else
        //     minY = (whole - 1) * 5;

        // console.log('whole', whole)

        curveOptions.yAxis[0].min = minY;
        curveOptions.yAxis[0].max = maxY;
        curveOptions.yAxis[0].alignTicks = false;

        //от 0 до 1.5 до 2.5 
        //curveOptions.series[1].zoneAxis = 'x';
        curveOptions.series[1].color = {
            linearGradient: { x1: 0, x2: 1, y1: 0, y2: 0 },
            stops: [
                [0, style.colorPrimary2], // start
                [1, style.colorPrimary1] // end
            ]
        };

        // console.log(curveOptions.series)
        // curveOptions.series[1].min = 5;
        // curveOptions.series[0].min = 5;
        // curveOptions.series[1].max = 30;
        // curveOptions.series[0].max = 30;
        curveOptions.series[1].data = points;

        if (scurve?.debt != null) {
            const maxDebt = Math.max(...scurve.debt?.filter(pair => pair.x >= minX && pair.x <= maxX)?.map(pair => pair.y)) // Billions
            // console.log('curveOptions.yAxis[1].max', curveOptions.yAxis[1].max)
            // console.log('roundUp(maxDebt,1000)', maxDebt,roundUp(1000+250,500))

            if (maxDebt > curveOptions?.yAxis[1]?.max ?? 1000.0)
                curveOptions.yAxis[1].max = roundUp(maxDebt + 100, 1000) // +100 for make space under top

            // console.log('maxDebt', maxDebt, maxY)
            // console.log('curveOptions', curveOptions.tickPositions)
            // console.log('maxY', maxY)
            // console.log('minY', minY)
            // const ration = (maxY - minY) / maxDebt
            // console.log('ration ', ration)
            curveOptions.series[2].data = scurve?.debt?.filter(pair => pair.x >= minX && pair.x <= maxX)?.map(pair => [pair.x, pair.y])
        }

        this.setState({ sCurveChartOptions: curveOptions }, () => {

            var curveOptions = { ...this.state.sCurveChartOptions };
            curveOptions.yAxis[0].offset = -this.sCurveChart.chart.xAxis[0].toPixels(0, false) + this.state.sCurveChartOptions.chart.marginLeft ?? 0;
            this.setState({ sCurveChartOptions: curveOptions }, () => {

                var items = this.state.selection.map(index => this.state.poolStatistics[index].isin);
                this.updateSCurveSelection(items, []);
            });
        });
    }

    //CHECKME!!! move to utilities
    GetBondOption = (bond, selected) => {
        const bondStatus = bond.status
        const issueDate = new Date(bond.issueDate)
        const Spacer = () => <Box sx={{ color: alpha(style.colorPrimary3, 0.4), margin: '0 1rem' }}>/</Box>
        return (<>
            <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'row', flexWrap: 'nowrap' }}>
                {bond.ticker}
                {Boolean(bond?.isin != null) && <><Spacer /><Box sx={{ color: style.colorPrimary3_60 }}>{bond.isin}</Box></>}
                {Boolean(bond?.regNumber != null) && <><Spacer /><Box sx={{ color: style.colorPrimary3_60 }}>{bond.regNumber}</Box></>}
            </Box>
            {/* <ListItemText id={`labelId-${name}`} primary={`${name}`} /> */}
            <Box sx={{ '&>*': { mr: 3, fontSize: '13px', textTransform: 'uppercase', } }}>
                {
                    (bondStatus == BondStatus.STATUS_NOT_ISSUED) && ( //no need to check is it an employee
                        <span style={{ color: style.colorError }}>
                            {this.props.t('Preparing')}
                        </span>
                    )
                }
                {
                    (bondStatus == BondStatus.STATUS_PLACEMENT) && (
                        <span style={{ color: style.colorPrimary2 }}>
                            {this.props.t('PreIssue')}
                        </span>
                    )
                }
                {
                    (bondStatus == BondStatus.STATUS_ISSUED) && (
                        <span style={{ color: style.colorPrimary2 }}>
                            {this.props.t('Issued')}
                        </span>
                    )
                }
                {
                    (bondStatus == BondStatus.STATUS_REDEEMED) && ( //no need to check is it an employee
                        <span style={{ fontSize: '11px', textTransform: 'uppercase', color: style.colorError }}>
                            {this.props.t('Redeemed')}
                        </span>
                    )
                }
                {!!issueDate && <span>{Localization.FormatDateLocale(issueDate)}</span>}
            </Box>
        </>)
    }

    getBondName = (bond, type) => {

        var name = null;
        switch (type) {
            case 1:
                {
                    if (bond.status == BondStatus.STATUS_PLACEMENT)
                        name = bond.regNumber;
                    else
                        name = bond.isin;
                }
                break;
            case 2:
                {
                    name = bond.regNumber;
                }
                break;
            case 3:
                {
                    name = bond.ticker;
                }
                break;
            case 4:
                {
                    name = bond.bloombergTicker;
                }
                break;
            case 5:
                {
                    name = bond.name;
                }
                break;
        }

        if (name == null)
            name = this.props.t("Unknown");

        return name;
    }

    renderProductCell = (row: any) => {
        return row.productName;
    };

    renderOriginatorCell = (row: any) => {
        return row.originatorName;
    }

    renderCouponTypeCell = (row: any) => {
        return ""; //CHECKME!!!
    }

    tableColumnExtensions = [];
    //{ columnName: 'OrderNumber', align: 'right' },
    //{ columnName: 'SaleAmount', align: 'right' },
    //];
    /*
    updateDataSource = () => {

        var filter = SiteHelper.CreateFilterNew(this.state.searchValue, this.state.currentPage, this.state.pageSize, this.state.columns, this.state.sortColumns);
        this.props.getBonds(filter);
    }
    */

    tableMessages = {
        noData: this.props.t('NoData'),
    };

    pagingPanelMessages = {
        showAll: this.props.t('ShowAll'),
        rowsPerPage: this.props.t('RowsPerPage'),
        info: this.props.t('GateInfo'),
    };

    searchPanelMessages = {
        searchPlaceholder: this.props.t('Searching'),
    };

    /*
    onSortChange = (value) => {
        this.setState({ sortColumns: value }, this.updateDataSource);
    }

    onCurrentPageChange = (page) => {

        this.setState({ currentPage: page }, this.updateDataSource);
    }

    onPageSizeChange = (pageSize) => {

        var totalPages = Math.ceil(this.props.bonds?.count / pageSize);
        var updatedCurrentPage = Math.min(this.state.currentPage, totalPages - 1);

        this.setState({ pageSize: pageSize, currentPage: updatedCurrentPage }, this.updateDataSource);
    }

    onSearchStateChange = (value) => {
        this.setState({ searchValue: value }, this.loadDataSourceDebounce);
    }

    loadDataSourceDebounce = debounce(500, async () => {
        this.updateDataSource();
    });

    handle  CompareCheckChange = (isin) => (event) => {

        var res = { ...this.state.compareBonds };
        if (event.target.checked) {
            res[isin] = true;
        }
        else {
            if (res[isin] == true)
                delete res[isin];
        }

        this.setState({ compareBonds: res });
    }
    */

    poolCell = ({ row, ...restProps }) => {

        return (
            // <Table.Cell row={row} className={
            <div row={row} className={
                clsx(this.props.classes.tableCell,
                    row.status == 2 ? this.props.classes.selectedTableCell : "",
                    restProps.column.name == "status" ? "text-right" : ""
                )} {...restProps}>
                {
                    (restProps.column.name == "name") ? (
                        <span>{this.getBondName(row, this.state.bondNameType)}</span>
                    ) : (
                        (restProps.column.name == "status" && row.status == BondStatus.STATUS_REDEEMED) ? (
                            <span className={this.props.classes.bondMarker}>{this.props.t("Redeemed")}</span>
                        ) : (<span />)
                    )
                }
            </div>
            // </Table.Cell>
        );
    }



    getLastDate = () => {

        if (this.state.timeline.length == 0)
            return null;

        return this.state.timeline[this.state.timelineValue].originalValue;
    }

    isTimelineLastDate = () => {
        var date = new Date();
        var currentDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
        var lastDate = this.getLastDate();
        if (lastDate != null)
            date = new Date(lastDate);

        return currentDate.getTime() == date.getTime();
    }

    timelineValueLabelFormat = (value) => {
        if (this.state.timeline?.length != 0)
            return this.state.timeline[value].valueLabel;

        return '';
    }

    handleTimelineChange = (event, newValue) => {
        this.setState({ timelineValue: newValue });
    }

    handleTimelineChangeCommitted = (event, newValue) => {

        this.setState({ timelineValueCommitted: newValue });
    }

    handleBondNameChange = (event) => {

        /*
        var columnWidths = [...this.state.columnWidths];
        if(event.target.value == 2)
        {
            columnWidths[0].width = 170;
        }
        else
        {
            columnWidths[0].width = 150;
        }
        */
        this.setState({ bondNameType: event.target.value }, () => {
            this.refreshPoolNames();
        });
    }

    refreshPoolNames = () => {
        var newPoolChartOptions = { ...this.state.poolChartOptions };
        //var newPoolStatistics = [...this.state.poolStatistics];

        var newCprChartOptions = { ...this.state.cprChartOptions };
        var newCdrChartOptions = { ...this.state.cdrChartOptions };

        if (newPoolChartOptions.series && newPoolChartOptions.series.length) {
            newPoolChartOptions.series.forEach(series => {
                var pool = this.state.poolStatistics.find(x => x.isin == series.data[0].id);
                if (pool != null) {
                    series.name = this.getBondName(pool, this.state.bondNameType);
                }
            });
        }

        if (newCprChartOptions.series && newCprChartOptions.series.length) {
            newCprChartOptions.series.forEach(series => {
                var pool = this.state.poolStatistics.find(x => x.isin == series.id);
                if (pool != null) {
                    series.name = this.getBondName(pool, this.state.bondNameType);
                }
            });
        }

        if (newCdrChartOptions.series && newCdrChartOptions.series.length) {
            newCdrChartOptions.series.forEach(series => {
                var pool = this.state.poolStatistics.find(x => x.isin == series.id);
                if (pool != null) {
                    series.name = this.getBondName(pool, this.state.bondNameType);
                }
            });
        }

        var sorting = [...this.state.poolsSorting];
        this.setState({
            poolChartOptions: newPoolChartOptions,
            //poolStatistics: newPoolStatistics,
            cprChartOptions: newCprChartOptions,
            cdrChartOptions: newCdrChartOptions,

            poolsSorting: sorting,
            gridState: this.state.gridState + 1, //to update grid content
        }, () => {
            //scurve
            if (this.state.pointsMap.length != 0) {

                this.state.pointsMap.forEach(pm => {
                    pm.point.update({ name: pm.series.name });
                });
            }

            //this.updateChartLabel();
        });

    }

    updateSCurveSelection = (items, removed) => {

        var selectedPoints = [];
        var removedPoints = [];
        this.poolChart.chart.series.forEach(series => {

            var point = series.points[0];

            if (items.findIndex(isin => isin === series.data[0].id) != -1) {

                point.select(true, true);

                selectedPoints.push(point);

            }
            else if (removed.findIndex(isin => isin === series.data[0].id) != -1) {
                point.select(false, true);

                removedPoints.push(point);
            }
        });
        // console.log(selectedPoints, removedPoints)
        if (removedPoints.length != 0) { //remove
            this.selectPoints(removedPoints, false);
        }

        if (selectedPoints.length != 0) //select
        {
            this.selectPoints(selectedPoints, true);
        }


    }

    setSelectionChange = (prevSelected, selected, isImmediately = false, forceColorIndexes = null) => {
        // console.log(2);

        var items = selected.map(index => this.state.poolStatistics[index].isin);
        var newSelection = selected;

        //check for 5 selections
        if (items.length > 5) {
            items.splice(0, items.length - 5);
            newSelection = [...selected];
            newSelection.splice(0, newSelection.length - 5);
        }

        var prevItems = !!prevSelected && prevSelected.map(index => !!this.state.poolStatistics && this.state.poolStatistics[index] && this.state.poolStatistics[index].isin);
        var removed = !!prevItems && prevItems.filter(item => !items.some(x => x == item));
        var added = !!items && items.filter(item => !prevItems.some(x => x == item));
        // console.log('removed',removed,'added',added)

        //if no changes, = return
        var changes = { bondsColorMapping: [] };
        if (removed.length != 0 || added.length != 0) {

            this.updateSCurveSelection(items, removed);

            var newBondsColorMapping = [...this.state.bondsColorMapping];
            newBondsColorMapping = newBondsColorMapping.filter(x => selected.some(y => y == x.id));
            var toAdd = selected.filter(x => !this.state.bondsColorMapping.some(y => y.id == x));

            if (forceColorIndexes != null && forceColorIndexes.length == toAdd.length) {
                toAdd.forEach((x, index) => {
                    newBondsColorMapping.push({ id: x, isin: this.state.poolStatistics[x].isin, colorIndex: forceColorIndexes[index] });
                });
            }
            else {
                toAdd.forEach(x => {
                    for (var i = 0; i < poolsColors.length; i++) {
                        if (!newBondsColorMapping.some(x => x.colorIndex == i)) {
                            newBondsColorMapping.push({ id: x, isin: this.state.poolStatistics[x].isin, colorIndex: i });
                            break;
                        }
                    }
                });
            }

            changes.bondsColorMapping = newBondsColorMapping;
        }

        if (isImmediately == true)
            this.props.getBondsInfo(items, this.getLastDate());
        else
            this.getBondsInfoDebounced(items, this.getLastDate());
        //this.props.getBondsInfo(items, this.getLastDate());

        this.setState({ selection: newSelection, ...changes });
    }

    onSelectionChanged = (selected, isImmediately = false) => {
        // console.log(1);
        this.setState({ selection: selected });
        //this.setSelectionChange(selected, isImmediately);
    }

    selectPoints = (selectedPoints, select) => {

        var pointsMap = [...this.state.pointsMap];
        // console.log(pointsMap)
        selectedPoints.forEach(p => {

            if (this.sCurveChart.chart.series[1].points.length == 0)
                return; //nothing to set

            var x = this.poolChart.chart.xAxis[0].translate(p.x) + this.sCurveChart.chart.margin[3]; //left margin

            var value = this.sCurveChart.chart.xAxis[0].toValue(x);

            //find nearest point
            var points = this.sCurveChart.chart.series[1].points;
            var prev = null;
            var next = null;

            for (var i = 0; i < points.length - 1; i++) {
                prev = points[i];
                next = points[i + 1];
                if (value >= prev.x && value <= next.x)
                    break;
            }

            if (prev?.x == value) {
                prev.select(select, true);
                prev.name = p.series.name;

                if (select)
                    pointsMap.push({ point: prev, series: p.series });
                else {
                    var index = pointsMap.findIndex(x => x.point == prev);
                    if (index != -1) {
                        pointsMap.splice(index, 1);
                    }
                }
            }
            else if (next?.x == value) {
                next.select(select, true);
                next.name = p.series.name;

                if (select)
                    pointsMap.push({ point: next, series: p.series });
                else {
                    var index = pointsMap.findIndex(x => x.point == next);
                    if (index != -1) {
                        pointsMap.splice(index, 1);
                    }
                }
            }
            else {
                if (Math.abs(prev.x - value) <= Math.abs(next.x - value)) {
                    prev.select(select, true);
                    prev.name = p.series.name;

                    if (select)
                        pointsMap.push({ point: prev, series: p.series });
                    else {
                        var index = pointsMap.findIndex(x => x.point == prev);
                        if (index != -1) {
                            pointsMap.splice(index, 1);
                        }
                    }
                }
                else {
                    next.select(select, true);
                    next.name = p.series.name;

                    if (select)
                        pointsMap.push({ point: next, series: p.series });
                    else {
                        var index = pointsMap.findIndex(x => x.point == next);
                        if (index != -1) {
                            pointsMap.splice(index, 1);
                        }
                    }
                }
            }
        });

        this.setState({ pointsMap: pointsMap }/*, () => this.updateChartLabel()*/);
    }

    onChartCreated = (chart) => {
        chart.pointer.reset = function (e, c) { };
    }

    onPoolsChartCreated = (chart) => {

        chart.pointer.reset = function (e, c) { };
    }

    handleCDRSwitch = (event: React.MouseEvent<HTMLElement>, value: number | null) => {

        if (value == null)
            return;

        this.setState({ cdrSwitch: value });
    };

    handleAverageCPRChange = (event) => {

        /*
        var newCprChartOptions = { ...this.state.cprChartOptions };
        var newCdrChartOptions = { ...this.state.cdrChartOptions };


        var atDest = this.state.selection.map(index => this.state.poolStatistics[index].isin);
        var avialableItems = atDest.filter(isin => this.props.bonds.cprcdr.some(y => y.isin == isin));
        var seriesData = this.props.bonds.cprcdr.filter(x => avialableItems.some(isin => isin == x.isin));

        newCprChartOptions.series.forEach((item, index) => {

            var data = seriesData.find(x => x.isin == item.id)
            if (data == null)
                return;

            var finalData = null;
            if (event.target.checked)
                finalData = data.averageCPR.map(pt => { return { x: toUnixDate(new Date(pt.x)), y: pt.y } })
            else
                finalData = data.cpr.map(pt => { return { x: toUnixDate(new Date(pt.x)), y: pt.y } })

            item.data = finalData;
        });

        newCdrChartOptions.series.forEach((item, index) => {

            var data = seriesData.find(x => x.isin == item.id)
            if (data == null)
                return;

            var finalData = null;
            if (event.target.checked)
                finalData = data.averageCDR.map(pt => { return { x: toUnixDate(new Date(pt.x)), y: pt.y } })
            else
                finalData = data.cdr.map(pt => { return { x: toUnixDate(new Date(pt.x)), y: pt.y } })

            item.data = finalData;
        });
        */

        this.setState({ showAverageCPRCDR: event.target.checked });
    }

    onExportAtExcelButtonClick = (event) => {
        window.open('/api/DomRf/DownloadPoolsStatistics?locale=' + Localization.code, '_blank');
    }

    onClearButtonClick = (event) => {
        var changes = { pointsMap: [] };
        var isChanged = false;

        if (this.state.selection.length != 0) {
            changes.selection = [];
            isChanged = true;
        }

        if (this.state.showAverageCPRCDR) {
            changes.showAverageCPRCDR = false;
            isChanged = true;
        }

        if (this.state.cdrSwitch != 2) {
            changes.cdrSwitch = 2;
            isChanged = true;
        }

        if (this.state.timelineValue != this.state.timeline.length - 1) {
            changes.timelineValue = this.state.timeline.length - 1;
            changes.timelineValueCommitted = this.state.timeline.length - 1;
            isChanged = true;
        }

        if (isChanged)
            this.setState(changes);
    }

    handleRedeemedBondsCheck = (event) => {
        this.setState({ showRedeemedBonds: event.target.checked });
    }

    handleChange = (event: SelectChangeEvent<typeof this.state.boundList>) => {
        const {
            target: { value },
        } = event
        this.setState({ boundList: typeof value === 'string' ? value.split(',') : value, })
    }


    render() {
        // const ContainerXXL = styled((props)=>{ 
        //     const {children, ...otherProps}=rops
        //     return (<Container {...otherProps}>
        //                 {children}
        //             </Container>)
        //     })(({ theme }) => {
        //         console.log(theme.breakpoints.up('xxl'));
        //         return({
        //     [theme.breakpoints.up('xxl')]:{
        //         maxWidth: '1520px !important',
        //     },
        //     [theme.breakpoints.down('xxl')]:{
        //         maxWidth: 'initial'
        //     }
        //   })
        // });

        var { t, classes } = this.props;

        var isLastDate = this.isTimelineLastDate();
        var currentDate = this.getLastDate();

        // console.log('store',this.props)
        // 

        // setTitle(t('MortgagePool')) //Устанавлимваем заголовок в Store

        // this.setState({boundList:[]})
        return (
            <>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{t('PoolsPageNameInstrumentTitle')}</title>
                </Helmet>
                {this.state.mobileLocker === true ? <RotateLocker /> : <></>}
                <Container maxWidth="xxl">
                    {/* <Spinner show={this.props.loading} /> */}
                    {Boolean(this.props.loading) &&
                        <Box className={classes.bgCover}>
                            <Box className={classes.spinnerBase}>
                                <CustomCircularProgress
                                    color={style.colorPrimary2}
                                    size={48}
                                    thickness={6}
                                    classes={{ ValueRoot: classes.progressValueRoot, ProgressBase: classes.progressBase, Progress: classes.progress }}
                                />
                            </Box>
                        </Box>
                    }
                    <Stack
                        sx={{
                            p: { xs: 1.5, md: '0.5rem 0 0.7rem 0' }, /*height: '100%', width: '100%'*/
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignContent: 'center',
                            flexWrap: 'nowrap',
                            position: 'relative'
                        }}
                    >
                        <Box
                            sx={{
                                position: 'relative',
                                border: 0,
                                width: '100%',
                                height: '2rem'
                            }}
                        >
                            <Box sx={{
                                position: 'absolute',
                                top: 0,
                                bottom: 0,
                                left: 0,
                                right: 0,
                                display: 'flex',
                                flexDirection: 'row',
                                '&>*': { mr: '1rem !important' }
                            }}>
                                {/* <Stack sx={{ display: { xs: 'none', lg: 'initial' } }}>
                                    <TooltipInfoLabel content={t('TypeOfBondSelection')} >
                                        <FormControl variant="standard" sx={{ width: 170, }}>
                                            <InputLabel id="bond-select-header">{t('BondIdentity')}</InputLabel>
                                            <Select
                                                sx={{ '& > :focus': { backgroundColor: 'unset !important' } }}
                                                fullWidth
                                                variant="standard"
                                                labelId="bond-name-select-label"
                                                id="bond-name-select"
                                                value={this.state.bondNameType}
                                                onChange={this.handleBondNameChange}
                                            >
                                                <MenuItem value={3}>{t('Ticker')}</MenuItem>
                                                <MenuItem value={1}>{t('ISIN')}</MenuItem>
                                                <MenuItem value={2}>{t('RegistrationNumber')}</MenuItem>
                                                {/  * <MenuItem value={4}>{t('BloombergTicker')}</MenuItem> * /}
                                                {
                                                    Globals.user.isEmployee &&
                                                    <MenuItem value={5}>{t('Name')}</MenuItem>
                                                }
                                            </Select>
                                        </FormControl>
                                    </TooltipInfoLabel>
                                </Stack> */}

                                <Box sx={{
                                    flexGrow: 1,
                                    alignItems: 'stretch',
                                    justifyContent: 'center'
                                }}>
                                    <TooltipInfoLabel
                                        fullWidth
                                        content={t('TooltipPoolsComboBoxBondChoice')}>
                                        {!this.state.isMobile ?
                                            <Autocomplete
                                                classes={{ listbox: classes.autocompletePopper }}
                                                multiple fullWidth
                                                id="Bounds-Tags-Select-autocompete"
                                                key="Bounds-Tags-Select-autocompetes"
                                                options={this.state.poolStatistics}
                                                disableListWrap={true}
                                                autoHighlight={false}
                                                inputValue={this.state.inputValue}
                                                // autoSelect={true}
                                                // blurOnSelect={false}
                                                disableCloseOnSelect={true}
                                                // autoComplete={false}
                                                PopperComponent={this.autocompletePopperComponent}
                                                value={
                                                    (!!this.state.selection && this.state.selection.length > 0 &&
                                                        !!this.state.poolStatistics && this.state.poolStatistics.length > 0) ?
                                                        this.state.selection.map((i) => {
                                                            return this.state.poolStatistics[i]
                                                        }) : []
                                                }
                                                getOptionLabel={(option) => { //формирование строки ииспользуемой при поиске
                                                    //this.getBondName(option, this.state.bondNameType)
                                                    return `${option?.ticker}  ` + (option?.isin ? `${option.isin}   ` : ``) + (option?.regNumber ? `${option.regNumber}   ` : ``)
                                                }}
                                                loading={!this.state.poolStatistics.length}
                                                loadingText={`${t("Loading")}...`}
                                                noOptionsText={`${t("NotFound")}`}
                                                onChange={(event, value, reason, details) => {
                                                    const arr = []
                                                    if (value.length)
                                                        value.map((v) => {
                                                            this.state.poolStatistics.map((i, index) => { if (i === v) arr.push(index) })
                                                        })

                                                    // const colArr = this.state.bondsColorMapping.filter(item => arr.some((id) => id === item.id))
                                                    // console.log('bondsColorMapping', this.state.bondsColorMapping, '<=',colArr, arr)
                                                    this.setState({ selection: arr, /* bondsColorMapping: colArr,*/ lastSelectedOptionId: value?.length ? value[value?.length - 1]?.isin : null })
                                                }}
                                                onOpen={() => {
                                                    setTimeout(() => {
                                                        const optionEl = document.querySelector(
                                                            `[data-name="${this.state.lastSelectedOptionId}"]`
                                                        );
                                                        optionEl?.scrollIntoView();
                                                    }, 1);
                                                }}
                                                renderTags={(value, getTagProps) =>
                                                    <Box>
                                                        {
                                                            value.map((option, index) => {
                                                                const item = this.state.bondsColorMapping.filter(i => i.isin === option.isin)
                                                                const color = poolsColors[item.length && item[0].colorIndex]
                                                                return (
                                                                    <Chip
                                                                        size="small"
                                                                        variant="outlined"
                                                                        avatar={<Box sx={{ backgroundColor: color, borderRadius: '100%' }} />}
                                                                        // sx={{alignSelf: 'baseline'}}
                                                                        deleteIcon={<Clear sx={{ ':hover, :active': { color: "#000 !important" } }} />}
                                                                        label={this.getBondName(option, this.state.bondNameType)}
                                                                        {...getTagProps({ index })}
                                                                    />
                                                                )
                                                            })
                                                        }
                                                    </Box>
                                                }

                                                renderOption={(props, option, { selected }) => {
                                                    const name = this.getBondName(option, this.state.bondNameType)
                                                    return (
                                                        <li {...props}
                                                            key={`option-labelId-${name}`}
                                                            data-name={`${option?.isin}`}
                                                            aria-disabled={this.state.selection &&
                                                                this.state.selection.length >= poolsColors.length &&
                                                                !selected}
                                                        >
                                                            <Checkbox size="small" checked={selected} />
                                                            {this.GetBondOption(option)}
                                                        </li>
                                                    )
                                                }}

                                                renderInput={(params) => (
                                                    <TextField

                                                        {...params}
                                                        autoComplete="off"
                                                        // onClick={(e)=>{
                                                        //     console.log(document.activeElement)
                                                        //     document.activeElement.blur()
                                                        //     e.target.blur()
                                                        // }}
                                                        InputLabelProps={{ disabled: true, }}
                                                        variant="standard"
                                                        // label={this.state.selection.length ? t('ConventionNameBondChoice') :t('SelectMBS')}
                                                        placeholder={this.state.selection.length ? '' : t('SelectMBS')}
                                                        onChange={(e) => this.setState({ inputValue: e.target.value })}
                                                        onBlur={() => this.setState({ inputValue: '' })}
                                                    />
                                                )}
                                            />
                                            :
                                            <FormControl variant="standard" sx={{ width: '100%' }}>
                                                <InputLabel id="bond-selects-header">{this.state.selection.length ? t('ConventionNameBondChoice') : t('SelectMBS')}</InputLabel>
                                                <Select
                                                    sx={{ '& > :focus': { backgroundColor: 'unset !important' } }}
                                                    fullWidth
                                                    variant="standard"
                                                    multiple
                                                    labelId="bond-name-select-label"

                                                    MenuProps={{
                                                        BackdropProps: { style: { position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: +99997, backgroundColor: 'unset', } },
                                                        style: { zIndex: +99998 },
                                                        PaperProps: { sx: { zIndex: +99999, maxHeight: '65vh', minWidth: 'unset !important' } },
                                                    }}
                                                    value={
                                                        (!!this.state.selection && this.state.selection.length > 0 &&
                                                            !!this.state.poolStatistics && this.state.poolStatistics.length > 0) ?
                                                            this.state.selection.map((i) => {
                                                                return this.state.poolStatistics[i]
                                                            }) : []
                                                    }
                                                    onChange={(event: SelectChangeEvent<typeof this.state.poolStatistics>) => {
                                                        const {
                                                            target: { value },
                                                        } = event
                                                        const arr = []
                                                        value.map((v) => {
                                                            this.state.poolStatistics.map((i, index) => { if (i === v) arr.push(index) })
                                                        })

                                                        const colArr = this.state.bondsColorMapping.filter(item => arr.some((id) => id === item.id))
                                                        this.setState({ selection: arr, bondsColorMapping: colArr })
                                                    }
                                                    }

                                                    renderValue={(selected) =>
                                                        <Box sx={{
                                                            display: 'flex', flexWrap: 'wrap', gap: 0.5, position: 'relative',
                                                            '.MuiChip-sizeSmall': { display: 'flex', padding: 0, margin: 0, flexWrap: 'nowrap', flexDirection: 'row', alignItems: 'center', alignContent: 'center', alignSelf: 'center' },
                                                            '.MuiChip-label': { margin: 0, top: '50%', mt: '2px', transformOrigin: 'center', fontSize: '14px' },
                                                            '.MuiChip-avatarSmall': { width: '16px', height: '16px', borderRadius: '100%', overflow: 'hidden', }
                                                        }}
                                                        >
                                                            {selected.map((option, index) => {
                                                                const item = this.state.bondsColorMapping.filter(i => i.isin === option.isin)
                                                                const color = poolsColors[item.length && item[0].colorIndex]
                                                                return (
                                                                    <Chip
                                                                        size="small"
                                                                        variant="outlined"
                                                                        key={`chip-${index}`}
                                                                        sx={{

                                                                        }}
                                                                        avatar={<Box sx={{ backgroundColor: color }} />}
                                                                        // sx={{alignSelf: 'baseline'}}
                                                                        // deleteIcon={<Clear sx={{':hover, :active':{color:"#000 !important"}}}/>}
                                                                        label={this.getBondName(option, this.state.bondNameType)}
                                                                        // onDelete={(e)=>console.log(e)}
                                                                        sx={{ margin: 'auto 0' }}
                                                                    />
                                                                )
                                                            })}
                                                        </Box>
                                                    }
                                                    input={<Input id="select-multiple-chip" />}
                                                >
                                                    {
                                                        this.state.poolStatistics.map((option, index) => {
                                                            // console.log(option.isin, this.state.selection)
                                                            const name = this.getBondName(option, this.state.bondNameType)
                                                            const bondStatus = option.status
                                                            const issueDate = new Date(option.issueDate)
                                                            return (
                                                                <MenuItem
                                                                    key={`bounds-key-${index}`}
                                                                    value={option}
                                                                    disabled={this.state.selection &&
                                                                        this.state.selection.length >= poolsColors.length && !(this.state.selection.indexOf(index) > -1)}
                                                                >
                                                                    <Checkbox size="small" checked={this.state.selection.indexOf(index) > -1} />
                                                                    <ListItemText id={`labelId-${name}`} primary={`${name}`} />
                                                                    <Box sx={{ '&': { ml: 5, mr: 3, fontSize: '13px', textTransform: 'uppercase', } }}>
                                                                        {
                                                                            (bondStatus == BondStatus.STATUS_NOT_ISSUED) && ( //no need to check is it an employee
                                                                                <Box component="span" sx={{ mr: 2, color: style.colorError }}>
                                                                                    {t('Preparing')}
                                                                                </Box>
                                                                            )
                                                                        }
                                                                        {
                                                                            (bondStatus == BondStatus.STATUS_PLACEMENT) && (
                                                                                <Box component="span" sx={{ mr: 2, color: style.colorPrimary2 }}>
                                                                                    {t('PreIssue')}
                                                                                </Box>
                                                                            )
                                                                        }
                                                                        {
                                                                            (bondStatus == BondStatus.STATUS_ISSUED) && (
                                                                                <Box component="span" sx={{ mr: 2, color: style.colorPrimary2 }}>
                                                                                    {t('Issued')}
                                                                                </Box>
                                                                            )
                                                                        }
                                                                        {
                                                                            (bondStatus == BondStatus.STATUS_REDEEMED) && ( //no need to check is it an employee
                                                                                <Box component="span" sx={{ mr: 2, color: style.colorError }}>
                                                                                    {t('Redeemed')}
                                                                                </Box>
                                                                            )
                                                                        }
                                                                        {!!issueDate && <span>{Localization.FormatDateLocale(issueDate)}</span>}
                                                                    </Box>
                                                                </MenuItem>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </FormControl>
                                        }
                                        {/* </FormControl> */}
                                    </TooltipInfoLabel>
                                </Box>
                            </Box>
                        </Box>

                        <Box
                            sx={{
                                justifyContent: 'space-between',
                                alignContent: 'center',
                                flexWrap: 'nowrap',
                                // alignItems: 'center',
                                alignItems: 'flex-end',
                                '&>*': { ml: 1, whiteSpace: 'nowrap' },
                                display: { xs: 'none', lg: 'flex' }
                            }}>
                            {/* <Tooltip
                                disableFocusListener
                                //interactive
                                title={this.props.t('Clear_Info')}>
                                <span>
                                    <StyledButton variant="outlined" color="secondary" onClick={this.onClearButtonClick}>
                                        {this.props.t('Clear')}
                                    </StyledButton>
                                </span>
                            </Tooltip> */}
                            {/* кнопка API*/}
                            <Tooltip
                                disableFocusListener
                                disableInteractive
                                title={t('convention2.APITooltip')}
                                enterDelay={0} leaveDelay={0}
                                TransitionComponent={null}
                                TransitionProps={{ timeout: 0 }}
                                classes={{ tooltip: classes.wideTooltip }}
                            >
                                <span>
                                    <StyledButton variant="outlined" color="secondary" onClick={() => { window?.open('/files/getPoolsData.pdf', '_blank') }}>
                                        {this.props.t("convention2.API")}
                                    </StyledButton>
                                </span>
                            </Tooltip>
                            <Tooltip
                                disableFocusListener
                                //interactive
                                title={this.props.t('TooltipPoolExcelButton')}>
                                <span>
                                    <StyledButton variant="outlined" color="secondary" onClick={this.onExportAtExcelButtonClick}>
                                        {this.props.t('PoolExcelButton')}
                                    </StyledButton>
                                </span>
                            </Tooltip>
                        </Box>
                    </Stack>
                    {/* <Stack> {/ * SLIDER * /}
                        <Box sx={{ display: { xs: 'none', lg: 'initial' } }}
                            className={classes.sliderContainer} >
                            <Slider
                                classes={{
                                    markLabel: classes.markLabel,
                                    thumb: classes.sliderThumb,
                                    root: classes.sliderLine
                                }}
                                components={{
                                    ValueLabel: SliderLabelComponent
                                }}
                                defaultValue={this.state.timeline?.length ?? 0}
                                value={this.state.timelineValue}
                                //getAriaValueText={valuetext}
                                aria-labelledby="discrete-slider"
                                valueLabelDisplay="auto"
                                valueLabelFormat={this.timelineValueLabelFormat}
                                onChange={this.handleTimelineChange}
                                onChangeCommitted={this.handleTimelineChangeCommitted}
                                step={1}
                                marks={this.state.timeline}
                                min={0}
                                max={(this.state.timeline?.length - 1) ?? 0}
                            // color={style.colorPrimary3}
                            />
                        </Box>
                    </Stack> */}
                    <Stack>
                        <Grid container>
                            <Grid item xs={12} md={6} id="chartsContainer" >
                                <Paper sx={{ p: 0, mr: { xs: 0, md: 1 }, position: 'relative' }}>
                                    <Box sx={{
                                        display: 'flex', pl: 1,
                                        flexWrap: 'nowrap',
                                        flexDirection: 'row',
                                        alignContent: 'center',
                                        alignItems: 'center',
                                        maxHeight: '2rem',
                                        minHeight: '2rem',
                                    }}>
                                        <Typography sx={{
                                            textTransform: 'uppercase', lineHeight: '2rem', maxHeight: '2rem',
                                            overflow: 'hidden', ml: 1,
                                            display: 'flex', alignItems: 'center',
                                            flexGrow: 1, fontSize: '14px', color: style.colorPrimary3
                                        }} >
                                            {
                                                // this.state..map((item, index) =>item)
                                                t("PoolsMapAndSCurve")
                                            }
                                            {/* <TooltipInfoLabel right content={t('PoolsMapAndSCurve_Info')} /> */}
                                            <CustomTooltip
                                                hideUnderline
                                                // classes={{ popper: cls.wideTooltip }}
                                                // sx={{ '& .MuiButtonBase-root': { marginLeft: '4px', }, '& svg': { color: style.colorPrimary3_40, width: '20px', height: '20px', } }}
                                                right
                                                content={getTooltip({ tooltip: 'PoolsMapAndSCurve_Info' })}
                                            // enterDelay={0} leaveDelay={0}
                                            />

                                        </Typography>

                                        <Box component="span" sx={{ pr: 1 }}>

                                            <Button variant="text"
                                                sx={{ fontSize: '14px' }}
                                                onClick={() => window.open('/files/Конвенция ИЦБ ДОМ.РФ 2.0.pdf#sCurveEstimation', '_blank')}
                                            >
                                                {t('Methodics')}
                                            </Button>
                                        </Box>
                                    </Box>
                                    <Divider />

                                    {/* <Stack className={classes.flexShrink}>
                                <div className="d-flex flex-column">
                                    <div className="text-center">
                                        <TooltipInfoLabel content={t('PoolsMapAndSCurve_Info')} link='https://xn--90an6b.xn--d1aqf.xn--p1ai/upload/iblock/91b/91b5cea7aea3c7fe28a3d0735aaa2326.pdf'>
                                            {t('PoolsMapAndSCurve')}
                                        </TooltipInfoLabel>
                                    </div>
                                </div>
                            </Stack> */}

                                    <Stack>
                                        {/* <div className="d-flex flex-column"> */}
                                        <HighchartsReact
                                            ref={x => this.poolChart = x}
                                            highcharts={Highcharts}
                                            // containerProps={{ style: { height: '100%' } }}
                                            options={this.state.poolChartOptions}
                                        // callback={this.onPoolsChartCreated}
                                        />
                                        <HighchartsLabelComponent
                                            labelAnchor={this.poolChart}
                                            // tooltip={isLastDate ? stringInject(t('RefinancingRateCurrentTooltip_Params'), [Localization.FormatDateStringLocale(currentDate)]) : stringInject(t('RefinancingRateDateTooltip_Params'), [Localization.FormatDateStringLocale(currentDate)])}
                                            tooltip={stringInject(t('RefinancingRateDateTooltip_Params'), [Localization.FormatDateStringLocale(currentDate)])}
                                            link='https://xn--d1aqf.xn--p1ai/analytics/archive/?set_filter=Y&filter_ff%5bSECTION_ID%5d=886'
                                            linkName={t('SeeMore')}
                                            // label={<React.Fragment><span>{(isLastDate ? t('CurrentMortgageRefinancingRate') : t('MortgageRefinancingRate')) + ' '}</span><b><span>{this.props.statistics?.mortgageRate + '%'}</span></b></React.Fragment>}
                                            label={<><span>{t('MortgageRefinancingRate') + ' '}</span><b><span>{this.props.statistics?.mortgageRate + '%'}</span></b></>}
                                            // label='123'
                                            refresh={this.state.refreshLabelRequired}
                                        />
                                        {/* </div> */}
                                    </Stack>
                                    <Stack >
                                        {/* <div className="d-flex flex-column"> */}
                                        <HighchartsReact
                                            ref={x => this.sCurveChart = x}
                                            highcharts={Highcharts}
                                            containerProps={{ style: { height: '100%' } }}
                                            options={this.state.sCurveChartOptions}
                                            callback={(chart) => {
                                                if (chart?.legend) {
                                                    // console.log(chart?.legend)
                                                    Highcharts.objectEach(chart.legend.allItems, function (x) {
                                                        // console.log('x', x)
                                                        if (x.legendItem && x.legendItem?.textStr == t('SCurveApproximation')) {
                                                            x.legendItem.textSetter(t('SCurveApproximationLabel'))
                                                            if (x.legendLine) {
                                                                x.legendLine.element.style.stroke = style.colorPrimary2;
                                                                // x.legendSymbol.d = 'M 0 10 L 16 10 L 16 12 L 0 12 Z'
                                                            }
                                                        }
                                                    });
                                                }
                                                this.onChartCreated(chart)
                                            }}
                                        />
                                        {/* </div> */}
                                    </Stack>
                                </Paper>
                            </Grid>

                            <Grid item xs={12} md={6} id="cprcdrChartsContainer">
                                <Paper sx={{ p: 0, position: 'relative' }}>
                                    <Box sx={{
                                        display: 'flex', pl: 1,
                                        flexWrap: 'nowrap',
                                        flexDirection: 'row',
                                        alignContent: 'center',
                                        alignItems: 'center',
                                        maxHeight: '2rem',
                                        minHeight: '2rem',
                                    }}>
                                        <Typography sx={{
                                            textTransform: 'uppercase', lineHeight: '2rem', maxHeight: '2rem',
                                            overflow: 'hidden', ml: 1,
                                            display: 'flex', alignItems: 'center',
                                            flexGrow: 1, fontSize: '14px', color: style.colorPrimary3,
                                            whiteSpace: 'nowrap', textOverflow: 'ellipsis'
                                        }} >

                                            {t("HistoricalCPRCDROfPools")}
                                            {/* <TooltipInfoLabel right content={t('HistoricalCPRCDROfPools_Info')} link='Амортизация_ИЦБ' /> */}
                                            <CustomTooltip
                                                hideUnderline
                                                // classes={{ popper: cls.wideTooltip }}
                                                // sx={{ '& .MuiButtonBase-root': { marginLeft: '4px', }, '& svg': { color: style.colorPrimary3_40, width: '20px', height: '20px', } }}
                                                right
                                                content={getTooltip({ tooltip: 'HistoricalCPRCDROfPools_Info' })}
                                            // enterDelay={0} leaveDelay={0}
                                            />

                                        </Typography>

                                        {
                                            (this.state.selection.length != 0) && (
                                                <Box component="span" sx={{ maxHeight: '2rem', pr: 0, whiteSpace: 'nowrap', display: 'flex', flexDirection: 'row', flexWrap: 'nowrap' }}>
                                                    {/* <TooltipInfoLabel content={t('PoolsTooltip3')}> 
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    id="averageCPRSwitch"
                                                                    name="averageCPRSwitch"
                                                                    checked={this.state.showAverageCPRCDR}
                                                                    onChange={this.handleAverageCPRChange}
                                                                    color="primary"

                                                                />
                                                            }
                                                            label={this.props.t('ShowAverages')}
                                                        />
                                                    </TooltipInfoLabel> */}
                                                    <CustomTooltip
                                                        hideUnderline
                                                        // classes={{ popper: cls.wideTooltip }}
                                                        // sx={{ '& .MuiButtonBase-root': { marginLeft: '4px', }, '& svg': { color: style.colorPrimary3_40, width: '20px', height: '20px', } }}
                                                        // right
                                                        content={getTooltip({ tooltip: 'PoolsTooltip3' })}
                                                    // enterDelay={0} leaveDelay={0}
                                                    />
                                                    <FormControlLabel
                                                        sx={{ maxHeight: '2rem' }}
                                                        control={
                                                            <Checkbox
                                                                id="averageCPRSwitch"
                                                                name="averageCPRSwitch"
                                                                checked={this.state.showAverageCPRCDR}
                                                                onChange={this.handleAverageCPRChange}
                                                                color="primary"

                                                            />
                                                        }
                                                        label={this.props.t('ShowAverages')}
                                                    />
                                                </Box>
                                            )
                                        }
                                    </Box>
                                    <Divider />
                                    {/* <Stack className={classes.flexShrink}>
                                <div className="d-flex flex-column">
                                    <div className="text-center">
                                        <TooltipInfoLabel content={t('HistoricalCPRCDROfPools_Info')} link='Амортизация_ИЦБ'>
                                            {t('HistoricalCPRCDROfPools')}
                                        </TooltipInfoLabel>
                                    </div>
                                </div>
                            </Stack> */}

                                    <Stack >
                                        {/* <div className={classes.buttonsContainer}> */}
                                        {/* {
                                        (this.state.selection.length != 0) && (
                                            <div className={classes.checkboxContainer}>
                                                <TooltipInfoLabel content={t('PoolsTooltip3')}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            id="averageCPRSwitch"
                                                            name="averageCPRSwitch"
                                                            checked={this.state.showAverageCPRCDR}
                                                            onChange={this.handleAverageCPRChange}
                                                            color="primary"
                                                        />
                                                    }
                                                    label={this.props.t('ShowAverages')}
                                                />
                                            </TooltipInfoLabel>
                                            </div>
                                            )
                                    } */}
                                        <HighchartsReact
                                            ref={x => this.cprChart = x}
                                            highcharts={Highcharts}
                                            containerProps={{ id: "cprChartContainer", /*style: { height: '100%' }*/ }}
                                            options={this.state.cprChartOptions}
                                            callback={this.onChartCreated}
                                        />
                                        {/* </div> */}
                                    </Stack>
                                    <Stack>
                                        <div className={classes.buttonsContainer}>
                                            <div ref={x => this.switchButtonsContainer = x} className={classes.switchContainer}>
                                                {/* <TooltipInfoLabel content={t('PoolsTooltipCDRDelinquencyBuyoutFormat')}
                                                    link='Амортизация_ИЦБ#темп-выкупа-дефолтов-cdr'
                                                    linkName={t('SeeMore')}>

                                                    <ToggleButtonGroup
                                                        value={this.state.cdrSwitch}
                                                        size='small'
                                                        exclusive
                                                        onChange={this.handleCDRSwitch}
                                                    >
                                                        <ToggleButton value={1} className={classes.toggleButton}>
                                                            <span>
                                                                {t('IssuesFormat')}
                                                            </span>
                                                        </ToggleButton>

                                                        <ToggleButton value={2} className={classes.toggleButton}>
                                                            <span>
                                                                {t('BuyoutsFormat')}
                                                            </span>
                                                        </ToggleButton>

                                                    </ToggleButtonGroup>
                                                </TooltipInfoLabel> */}
                                                <CustomTooltip
                                                    hideUnderline
                                                    // classes={{ popper: cls.wideTooltip }}
                                                    // sx={{ '& .MuiButtonBase-root': { marginLeft: '4px', }, '& svg': { color: style.colorPrimary3_40, width: '20px', height: '20px', } }}
                                                    // right
                                                    content={getTooltip({ tooltip: 'PoolsTooltipCDRDelinquencyBuyoutFormat' })}
                                                // enterDelay={0} leaveDelay={0}
                                                >
                                                    <ToggleButtonGroup
                                                        value={this.state.cdrSwitch}
                                                        size='small'
                                                        exclusive
                                                        onChange={this.handleCDRSwitch}
                                                    >
                                                        <ToggleButton value={1} className={classes.toggleButton}>
                                                            <span>
                                                                {t('IssuesFormat')}
                                                            </span>
                                                        </ToggleButton>

                                                        <ToggleButton value={2} className={classes.toggleButton}>
                                                            <span>
                                                                {t('BuyoutsFormat')}
                                                            </span>
                                                        </ToggleButton>
                                                    </ToggleButtonGroup>
                                                </CustomTooltip>
                                            </div>
                                            <HighchartsReact
                                                ref={x => this.cdrChart = x}
                                                highcharts={Highcharts}
                                                containerProps={{ id: "cdrChartContainer", style: { height: '100%' } }}
                                                options={this.state.cdrChartOptions}
                                                callback={this.onChartCreated}
                                            />
                                        </div>
                                    </Stack>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Stack>
                    <Stack className={this.state.bonds != null ? 'pt-3' : ''}>
                        <CompareBondsControl loading={this.props.bondsLoading} bonds={this.state.bonds} />
                    </Stack>
                </Container>
            </>
        )
    }
}


var component =

    connect(
        state => { return { ...(state as any).pool, ...(state as any).topNavbar } }, // Selects which state properties are merged into the component's props
        { ...PoolStore.actionCreators, ...NavbarStore.actionCreators } // Selects which action creators are merged into the component's props
    )(PoolsPage)


export default withRouter(withStyles(styles)(withTranslation('translations')(component)))