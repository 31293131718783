import React, { useRef, useState, useEffect, useMemo, useLayoutEffect } from 'react';
import stringInject from 'stringinject';
import Localization from '@Components/Localization';
import style from '@Styles/domrf-theme.module.scss';
import { makeStyles } from '@mui/styles';
import { useTranslation } from "react-i18next";
import { IconButton, Tooltip, Box, Typography, Divider, alpha, Paper, Button } from '@mui/material';
import { FileCopyOutlined, SaveOutlined, ZoomInOutlined, ZoomOutOutlined } from '@mui/icons-material';
import { hexToRgbA, copyTextToClipboard, roundUp, roundDown } from '@Utilities';
import TooltipInfoLabel from '@Components/shared/TooltipInfoLabel';
import useMobileDetect from '@Components/hook/useMobileDetect';
import { FlowTypes } from '@Components/Definitions';
// import Highcharts from 'highcharts/highstock';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
// import HC_patternFill from "highcharts-pattern-fill";
import { useSelector } from 'react-redux';
import HC_exporting from 'highcharts/modules/exporting';
import CustomTooltip, { getTooltip } from '@Base/components/shared/CustomTooltip';
import SpinnerBars from '@Base/components/shared/SpinnerBars';
// import HC_more from "highcharts/highcharts-more"; //module
import { actionCreators as actionConvention } from '@Base/store/ConventionStore'
import { useDispatch } from 'react-redux';

const useStyles = makeStyles({
    fullPanelAlert: {
        position: 'absolute',
        left: 0, top: 0, right: 0, bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: style.colorPrimary3_40,
        textAlign: 'center',
        userSelect: 'none',
    },
    wideTooltip: {
        maxWidth: '500px !important',
    },
    textIcon: {
        fontSize: '1.2rem !important',
        textTransform: 'initial',
        padding: '0 !important',
        minWidth: '25px !important',
        letterSpacing: 'initial',
        color: style.colorPrimary3,
        fontFamily: 'auto',
        fontWeight: 100,
    },
    coverLayout: {
        position: 'absolute',
        left: 0, top: 0, bottom: 0, right: 0,
        zIndex: '+100',
        backgroundColor: 'rgba(255, 255, 255, 0.5)',

    },
    root: {
        // marginRight: '0.2rem',
        // marginLeft: '0.2rem',
        // maxHeight: '400px',
        // position: 'relative',
        position: 'absolute',
        left: '0.2rem', top: 0, bottom: 0, right: '0.2rem',
        // maxHeight:'400px',
        '& $panelHeader *': {
            fontSize: '1rem'
        }
    },
    rootPaper: {
        position: 'absolute',
        left: 0, top: 0, bottom: 0, right: 0,
        borderRadius: '0.5rem',
        border: `solid 1px ${style.colorPrimary3_40}`,
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        justifyContent: 'flex-start',
        overflow: 'hidden',
    },
    panelHeader: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignContent: 'center',
        alignItems: 'center',
        height: '2rem !important',
        minHeight: '2rem !important',
        overflow: 'hidden',
        borderBottom: `solid 1px ${style.colorPrimary3_40}`,
        padding: '0 0.2rem 0 0',
        justifyContent: 'space-between',
        '& *': { userSelect: 'none', },
    },
    panelHeaderTitle: {
        textTransform: 'uppercase',
        color: style.colorPrimary3_40,
        whiteSpace: 'nowrap',
        marginLeft: '0.5rem',
    },
    panelIconsBox: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignContent: 'center',
        alignItems: 'center',
    },
    icon: {
        width: '23px',
        height: '23px',
        // color: style.colorPrimary3_80
    },
    panelData: {
        margin: '0.8rem',
        overflow: 'hidden',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        wlexWrap: 'nopwrap',
    },
    tooltip: {
        '& *': { fontSize: '0.8rem !important' },
        // '&>span': {
        //     outline: 'solid',
        //     border: 'solid 1px #000',
        // }
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    }
});

interface IProp {
    // showHistoryFlows: any;
}


const SCurveChartPanel = ({ }: IProp) => {
    // HC_patternFill(Highcharts)
    HC_exporting(Highcharts)
    // HC_more(Highcharts)

    const cls = useStyles();
    const { t, i18n } = useTranslation();
    const device = useMobileDetect();
    const sCurveChartRef = useRef();
    const [bondResults, setBondResults] = useState(null)
    const { selectedBond = null, sCurveGraph = null, loading = false, calculatedParameters = null, ...otherStore } = useSelector((state: any) => state.convention)
    const dispatch = useDispatch()

    useLayoutEffect(() => {
        actionConvention.clearValue('calculatedParameters')(dispatch)
    }, [])

    // console.log('calculatedParameters', calculatedParameters)
    // console.log('sCurveGraph', sCurveGraph)
    const [sCurveChartOptions, setsCurveChartOptions] = useState({
        //График Стимул к рефинансированию

        exporting: {
            enabled: false,
            filename: ``,
            chartOptions: {
                plotOptions: {
                    line: {
                        marker: {
                            enabled: false
                        }
                    }
                },
                legend: {
                    align: 'center',
                    verticalAlign: 'bottom',
                    itemDistance: 40,
                    itemStyle: {
                        fontSize: '7px',
                        overflow: 'hidden',
                    },
                    floating: false
                }
            },

        },
        legend: {
            margin: 0,
            y: 10,
            itemStyle: {
                cursor: 'default'
            },
            itemHiddenStyle: {
                opacity: 0,
            },
        },
        chart: {
            animation: false,
            marginLeft: 10, // Keep all charts left aligned
            // height: 300,
            //plotAreaHeight: 290,
            padding: 0,
            // plotBorderWidth:10,
            // marginBottom: 57,
            // spacingBottom: 13,
            plotBorderWidth: 0,
            plotBorderColor: hexToRgbA(style.colorPrimary3, 0.2),
            events: {
                render: (e) => {
                    if (e.target?.callback) {
                        e.target?.callback(e.target)

                    }
                    // setTimeout(onResize, 0);
                },
                load: function () {
                    var chart = this

                    chart.exportChart = function () {
                        chart.series[3].setVisible(false) // Hide the scatter series before exporting
                        Highcharts.Chart.prototype.exportChart.call(this, { width: 2048, height: 1365 })
                        chart.series[3].setVisible(true) // Show the scatter series after exporting
                    };
                }
            },
        },

        // dataLabels: {
        //     enabled: true,
        //     formatter: function () {
        //         return '<span style="color: ' + this.color + '">' + this.point.label + '</span>';
        //     },
        // },

        title: {
            text: ''
        },

        credits: {
            enabled: false
        },

        plotOptions: {
            series: {
                animation: false,
                states: {
                    inactive: {
                        opacity: 0,
                        enabled: false,
                    },
                    hover: {
                        halo: {
                            size: 0
                        }
                    }
                },
                marker: {
                    symbol: 'circle', // Set the marker symbol to 'circle'
                    radius: 2,
                },
                events: {
                    legendItemClick: function () {
                        return false; // Prevent the default legendItemClick behavior
                    }
                }
            },
            column: {
                states: {
                    inactive: {
                        enabled: false,
                    },
                    hover: {
                        enabled: false,
                    }
                },
            },
            scatter: {
                color: style.colorPrimary2, // Set the color for all points
                // enableMouseTracking: true,
                zIndex: 100,
                marker: {
                    radius: 6,
                    lineWidth: 1,
                    lineColor: style.colorPrimary3,
                    symbol: 'circle' // Set the shape for all points
                },
                states: {
                    inactive: {
                        opacity: 1,
                    },
                    hover: {
                        enabled: false,
                        //     halo: {
                        //         size: 0
                        //     }
                    }
                },
            },
        },

        xAxis: [{
            // crosshair: true,
            // startOnTick: true,
            // endOnTick: true,
            minPixelPadding: 0,
            minPointOffset: 0,

            gridLineColor: alpha(style.colorPrimary3, 0.1),
            gridLineWidth: 1,
            gridLineDashStyle: 'solid',
            tickInterval: 10,
            startOnTick: true,
            endOnTick: true,
            minPadding: 0,
            maxPadding: 0,
            tickWidth: 0,
            // gridLineWidth: 1,
            // lineWidth: 1,
            // lineColor: style.colorPrimary1,
            title: {
                text: t('Convention2_RateDifferencePrc'),
                style: {
                    color: style.colorPrimary3,
                    fontSize: '0.8rem'
                }
            },
            labels: {
                rotation: 0,
                // step: 10,
                allowOverlap: true,
                style: {
                    overflow: 'visible',
                    textOverflow: 'visible',
                    color: style.colorPrimary3
                }
            },
            lineWidth: 1,
            lineColor: alpha(style.colorPrimary3, 0.5),
            categories: [],
        },
        {
            tickWidth: 0,
            minPadding: 0,
            maxPadding: 0,
            visible: false
        }],

        yAxis: [{
            crosshair: false,
            // startOnTick: true,
            // endOnTick: true,
            gridLineColor: alpha(style.colorPrimary3, 0.1),
            gridLineWidth: 1,
            lineWidth: 1,
            lineColor: alpha(style.colorPrimary3, 0.5),
            min: 0,
            max: 100,
            // tickInterval: 15,
            tickAmount: 8,
            alignTicks: true,
            title: {
                text: t('Convention2_CPRPrc'),
                style: {
                    color: style.colorPrimary3,
                    // fontSize: '0.8rem'
                }
            },
            labels: {
                fontSize: '0.8rem',
                x: -10,
                style: {
                    color: style.colorPrimary3
                }
            },
            offset: 100, //for hide
            tickWidth: 0,
        },
        {
            tickWidth: 0,
            min: 0,
            max: 1000.0,
            visible: false,
            alignTicks: true,
        }
        ],

        tooltip: {
            animation: false,
            shape: "square",
            positioner: function (labelWidth, labelHeight, point) {
                return {
                    x: this.chart.plotLeft + 7,//this.chart.plotWidth - labelWidth - 3,
                    y: this.chart.plotTop + 5//this.chart.plotHeight - labelHeight - 3
                };
            },
            formatter: function () {
                // console.log('this', this.points)
                if (this?.points == null || this?.points?.length < 3) return

                var points = [
                    { name: 'convention2.GraphIncentiveToRefinance', value: this.x, fraction: 1 },
                    { name: 'convention2.GraphCPR', value: this.points[1].y, fraction: 1 },
                    { name: 'convention2.GraphEmpiricalCPR', value: this.points[0].y, fraction: 1 },
                    { name: 'convention2.GraphEmpiricalDataVolume', value: this.points[2].y, fraction: 0 },
                ];
                try {
                    if (this.points[3] != null)
                        points.push({ name: 'Convention2_mbsPosition', value: this.points[3].y, fraction: 1 },)
                } catch (error) {

                }

                return '<table style="min-width:200px;max-width:600px">' +
                    points.reduce(function (s, point) {
                        return s + '<tr><td style="padding:0">' + t(point.name) + ': </td>' +
                            '<td style="padding:0;text-align:end"><b>' + Localization.FormatNumber(point.value, point?.fraction ?? 0, point?.fraction ?? 0) + '</b></td></tr>'
                    }, '') +
                    '</table>';
            },
            hideDelay: 0,
            shared: true,
            useHTML: true
        },

        series: [{
            name: t('Convention2_SCurve'),
            id: 'Scurve',
            type: 'line',
            xAxis: 0,
            yAxis: 0,
            color: hexToRgbA(style.colorPrimary3, 0.3),
            // marker: {
            //     states: {
            //         hover: {
            //             enabled: false
            //         }
            //     }
            // },
            states: {
                hover: {
                    enabled: false,
                },
            },
            marker: {
                enabled: false,
            },
            zIndex: 1,
            pointPlacement: 'between',
            data: []
        },
        {
            name: t('Convention2_SCurveApproximation'),
            id: 'sapprox',
            type: 'line',
            xAxis: 0,
            yAxis: 0,
            lineWidth: 2,
            marker: {
                enabled: true,
                radius: 0, //hack to remove markers
                lineWidth: 1,
                lineColor: style.colorPrimary3,
                symbol: 'circle', // Set the shape for all points
                states: {
                    hover: {
                        enabled: true,
                        lineWidth: 1,
                        radius: 4,
                        // radiusPlus: 0
                    },
                }
            },
            color: style.colorPrimary1,
            dataLabels: {
                enabled: false,
                // formatter: formatSCurveDataLabels,
                allowOverlap: true,
            },
            states: {
                hover: {
                    lineWidth: 2,
                    enabled: true,
                },
            },
            zIndex: 2,
            pointPlacement: 'between',
            data: []
        },
        {
            name: t("Convention2_DebtValue"),
            id: "debtBars",
            type: "column",
            xAxis: 1,
            yAxis: 1,
            grouping: false,
            pointPadding: 0.0, // Space between individual columns within a group
            groupPadding: 0.0, // Space between different groups of columns
            zIndex: 0,
            color: alpha(style.colorPrimary3, 0.1),
            borderColor: 'none',
            borderWidth: 0,
            pointPlacement: 'between',
            data: [],
        },
        {
            name: 'mbsPosition',
            id: 'mbsPosition',
            type: 'scatter',
            data: null,
            yAxis: 0,
            xAxis: 0,
            lineWidth: 1,
            grouping: false,
            color: style.colorPrimary2,
            enableMouseTracking: true,
            showInLegend: false,
            dataLabels: {
                enabled: false
            },
        },
        ]
    });

    // console.log('sCurveGraph', sCurveGraph)
    // console.log('sCurveChartOptions', sCurveChartOptions)

    const onResize = () => {
        if (sCurveChartRef.current != null) {
            sCurveChartRef.current.chart.reflow();
        }
    }
    // const createFlowsLocalization = () => {
    // var newFlowsLocalization = {};
    // newFlowsLocalization.title = t('ConventionNameCashflowGraphRubles');
    // newFlowsLocalization.tooltipFormatter = function () {
    //     // console.log('this?.points[0]?.series?.name', this?.points)
    //     const dx = new Date(PricingParameters?.PricingDate)?.getTime()
    //     return this?.points?.map(point => {
    //         // console.log('first', first)
    //         // if (point?.series?.name == 'ZCYC_MACALEY') {
    //         //     return '<table style="min-width:150px;max-width:200px;"><tr><td>' + t('Convention2_Duration') + ': </td>' +
    //         //         // '<td style="text-align:end"><b>' + Localization.FormatDateLocale(new Date(this.x)) + '</b></td > ' +
    //         //         '<td style="text-align:end"><b>' + Localization.FormatNumber((this.x - dx) / (1000 * 60 * 60 * 24) / 365, 2, 2) + '</b></td > ' +
    //         //         '</tr>' +
    //         //         '<tr><td style="padding:0">' + t('Convention2_YMT') + ': </td>' +
    //         //         '<td style="padding:0;text-align:end"><b>' + Localization.FormatNumberString(this.y) + '</b></td></tr>'
    //         //         + '</table>';
    //         // }
    //         if (point?.series?.name == 'ZCYC_CHART') {
    //             return '<table style="min-width:150px;max-width:200px;"><tr><td>' + t('Convention2_Duration') + ': </td>' +
    //                 // '<td style="text-align:end"><b>' + Localization.FormatDateLocale(new Date(this.x)) + '</b></td > ' +
    //                 '<td style="text-align:end"><b>' + Localization.FormatNumber((this.x - dx) / (1000 * 60 * 60 * 24) / 365, 2, 2) + '</b></td > ' +
    //                 '</tr>' +
    //                 '<tr><td style="padding:0">' + t(point?.point?.highTitle) + ': </td>' +
    //                 '<td style="padding:0;text-align:end"><b>' + Localization.FormatNumberString(point?.point?.high) + '</b></td></tr>'
    //                 + '<tr><td style="padding:0">' + t(point?.point?.lowTitle) + ': </td>' +
    //                 '<td style="padding:0;text-align:end"><b>' + Localization.FormatNumberString(point?.point?.low) + '</b></td></tr>'
    //                 + '</table>';
    //         }

    //         return null
    //     }
    //     )
    // };

    // newFlowsLocalization.RealPayment = t('ConventionNameCashflowGraphRealPayment');
    // newFlowsLocalization.Plan = t('ConventionNameCashflowGraphPlan');
    // newFlowsLocalization.Default = t('ConventionNameCashflowGraphDefault');
    // newFlowsLocalization.Prepay = t('ConventionNameCashflowGraphPrepay');
    // newFlowsLocalization.CleanUp = t('ConventionNameCashflowGraphCleanUp');
    // newFlowsLocalization.RealCoupon = t('ConventionNameCashflowGraphRealCoupon');
    // newFlowsLocalization.Coupon = t('ConventionNameCashflowGraphCoupon');

    // return newFlowsLocalization;
    // }

    // useEffect(() => {
    //     updateChartLocalization();
    // }, [Localization.locale, CalculatedParameters?.NextCouponDate]);

    useLayoutEffect(() => {
        // console.log('update Chart')
        updateChartData()
    }, [sCurveGraph]);

    const updateChartData = () => {
        // console.log('calculatedParameters', calculatedParameters?.calculationSCurveReportDate)
        if (!sCurveGraph?.hasOwnProperty('graphEmpiricalCPR') && !Boolean(calculatedParameters?.calculationSCurveReportDate != null))
            return;

        var newChart = { ...sCurveChartOptions };

        // console.log('sCurveGraph', sCurveGraph)
        newChart.xAxis[0].categories = sCurveGraph.graphIncentiveToRefinance
        // console.log('sCurveGraph.', sCurveGraph)
        newChart.series[0].data = sCurveGraph.graphEmpiricalCPR
        newChart.series[1].data = sCurveGraph.graphCPR
        newChart.series[2].data = sCurveGraph.graphEmpiricalDataVolume
        // console.log('newChart.series[0].data', newChart.series[0].data)
        const min = Math.min.apply(null, sCurveGraph.graphIncentiveToRefinance)
        const max = Math.max.apply(null, sCurveGraph.graphIncentiveToRefinance)

        var chart = sCurveChartRef.current.chart;
        // console.log('chart', chart)
        var xAxis = chart.xAxis[0];
        // console.log('xAxis', xAxis)
        const xMin = 0
        const xLen = sCurveGraph?.graphIncentiveToRefinance?.length - 1
        // console.log('min: xMin, max: xMax', xMin, xMax, Math.floor(xMax - xMin) + 1)
        // xAxis.update({ tickAmount: Math.floor(xMax - xMin) + 1, min: xMin, max: xMax })
        // xAxis.update({ tickAmount: Math.floor(xAxis.max - xAxis.min) + 1 })
        // console.log('xAxis.min', xAxis.min)
        newChart.xAxis[0] = { ...newChart.xAxis[0], min: xMin, max: xLen }
        // console.log('newChart', newChart)
        const w = xAxis.width / (sCurveGraph.graphIncentiveToRefinance.length - 1)
        // const offset = xAxis.categories.indexOf(0) * w
        // // // console.log('newChart.marginLeft', newChart.chart.marginLeft)
        // // newChart.yAxis[0].offset = -offset + (newChart?.chart?.marginLeft / 2) + 1
        // console.log('xAxis.categories', sCurveGraph.graphIncentiveToRefinance.indexOf(0))
        newChart.yAxis[0].offset = -sCurveGraph.graphIncentiveToRefinance.indexOf(0) * w

        let minEmp = roundDown(Math.min.apply(null, sCurveGraph.graphCPR), 5) - 5
        const maxEmp = roundUp(Math.max.apply(null, sCurveGraph.graphCPR), 5) + 5
        // console.log('minEmp, maxEmp', minEmp, maxEmp)
        // newChart.yAxis[0].tickInterval = 5
        minEmp = minEmp > 0 ? minEmp : 0
        newChart.yAxis[0].min = minEmp
        newChart.yAxis[0].max = maxEmp
        let tickPositions = []
        for (let i = minEmp; i <= maxEmp; i = i + 5)
            tickPositions.push(i)
        newChart.yAxis[0].tickPositions = tickPositions
        // newChart.yAxis[0].tickAmount = (maxEmp - minEmp) / 5
        // console.log('maxEmp', maxEmp)

        if (sCurveGraph?.mbsPosition != null) {
            // console.log('sCurveGraph?.mbsPosition', sCurveGraph?.mbsPosition)
            // console.log('sCurveGraph.graphCPR', sCurveGraph.graphCPR)
            newChart.series[3].data = [{ x: newChart.xAxis[0]?.categories?.indexOf(sCurveGraph?.mbsPosition?.incentiveToRefinance), y: sCurveGraph?.mbsPosition?.sCurveCPR }]
            // newChart.series[3].data = [{ x: sCurveGraph.graphIncentiveToRefinance.indexOf(sCurveGraph?.mbsPosition?.IncentiveToRefinance), y: sCurveGraph?.mbsPosition?.SCurveCPR }]
            // newChart.series[3].data = [{ x: 5, y: 10 }]

        }

        newChart.exporting.filename = `${t('Convention2_SCurveChartName')} на ${Localization.FormatDateStringLocale(calculatedParameters?.calculationSCurveReportDate)}`
        // console.log('newChart', newChart)
        setsCurveChartOptions(newChart);
    }

    // const updateChartLocalization = () => {
    //     // const localization = createFlowsLocalization()
    //     // var newOptions = { ...sCurveChartOptions };
    //     // newOptions.yAxis[0].title.text = localization.title;
    //     // newOptions.tooltip.formatter = localization.tooltipFormatter;

    //     // newOptions.series[0].name = localization.RealPayment;
    //     // newOptions.series[1].name = localization.Plan;
    //     // newOptions.series[2].name = localization.Default;
    //     // newOptions.series[3].name = localization.Prepay;
    //     // newOptions.series[4].name = localization.RealCoupon;
    //     // newOptions.series[5].name = localization.Coupon;
    //     // newOptions.series[6].name = localization.CleanUp;

    //     // setsCurveChartOptions(newOptions);
    // }

    // const onSaveFlowAsImageButtonClick = (event) => {
    //     if (sCurveChartRef.current)
    //         sCurveChartRef.current.chart.exportChart({ width: 2048 });
    // }

    // const onSaveGraphFlowsButtonClick = (event) => {

    // }
    const onSaveAsImageButtonClick = (event) => {
        if (sCurveChartRef?.current) {
            sCurveChartRef.current.chart.exportChart({ width: 1920, height: 960 });
        }
    }



    const getNumber = (theNumber) => {
        if (theNumber > 0) {
            return " + " + Localization.FormatNumber(theNumber, 5, 5);
        } else {
            return Localization.FormatNumber(theNumber, 5, 5).toString();
        }
    }

    const makeFormula = () => {
        return `${Localization.FormatNumber(calculatedParameters?.calculationSCurveBeta0, 5, 5)} 
        ${getNumber(calculatedParameters?.calculationSCurveBeta1)}
        × arctg( ${Localization.FormatNumber(calculatedParameters?.calculationSCurveBeta2, 5, 5)}
        ${getNumber(calculatedParameters?.calculationSCurveBeta3)} × s )`
    }

    if (calculatedParameters == null) return <></>

    // console.log('sCurveGraph', sCurveGraph)
    return (
        <Box className={cls.root}>
            <Box className={cls.rootPaper}>
                {loading && <Box className={cls.coverLayout} />}

                <Box className={cls.panelHeader}>

                    {Boolean(!calculatedParameters?.calculationSCurveReportDate) ?
                        // {Boolean(!sCurveGraph?.hasOwnProperty('graphEmpiricalCPR')) ?
                        <Box className={cls.panelHeaderTitle}>
                            {t('Convention2_SCurveChartName')}
                        </Box>
                        :
                        <>
                            <Box className={cls.panelHeaderTitle}>
                                <CustomTooltip
                                    // hideIcon
                                    classes={{ popper: cls.wideTooltip }}
                                    sx={{ '& .MuiButtonBase-root': { marginLeft: '4px', }, '& svg': { color: style.colorPrimary3_40, width: '20px', height: '20px', } }}
                                    right
                                    content={getTooltip({ tooltip: 'Convention2_SCurveTooltip', source: { ...calculatedParameters, isin: selectedBond?.isin } })}
                                >
                                    {`${t('Convention2_SCurveChartName')} на ${Localization.FormatDateStringLocale(calculatedParameters?.calculationSCurveReportDate)}`}
                                </CustomTooltip>
                            </Box>
                            <Box>
                                <Tooltip
                                    disableFocusListener
                                    classes={{ tooltip: cls.wideTooltip }}
                                    title={<div dangerouslySetInnerHTML={{ __html: getTooltip({ tooltip: 'Convention2_BettaFormulaTooltop', source: { ...calculatedParameters, formula: makeFormula() } }) }} />}
                                >
                                    <Button variant="text" className={cls.textIcon}>
                                        β
                                    </Button>
                                </Tooltip>

                                <Tooltip
                                    disableFocusListener
                                    disableInteractive
                                    title={t('SaveAsImage')}>
                                    <IconButton size="small" disabled={sCurveChartOptions == null} onClick={onSaveAsImageButtonClick}>
                                        <SaveOutlined className={cls.icon} />
                                    </IconButton>
                                </Tooltip>

                            </Box>
                        </>
                    }
                </Box>
                {/* <FlowsChart data={sCurveChartOptions}/> */}

                {Boolean(!calculatedParameters?.calculationSCurveReportDate) ?
                    <Box className={cls.fullPanelAlert}>
                        {selectedBond?.isin == 'RU000A100DQ4' ?
                            <div dangerouslySetInnerHTML={{ __html: t('Convention2_sCurveChartDQ4Alert') }} />
                            :
                            <div dangerouslySetInnerHTML={{ __html: t('Convention2_sCurveChartNotDQ4Alert') }} />
                        }
                    </Box>
                    :
                    <>
                        <HighchartsReact
                            ref={sCurveChartRef}
                            highcharts={Highcharts}
                            containerProps={{ style: { height: "100%", width: "100%" } }}
                            options={sCurveChartOptions ? sCurveChartOptions : {}}
                            callback={(chart) => {
                                chart?.reflow()
                            }}
                        />
                    </>
                }
            </Box>
        </Box >
    );
}

export default SCurveChartPanel