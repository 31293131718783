import { Button, Grid, IconButton, InputAdornment, Paper, Popover, Stack, TextField, Typography } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import React, { useEffect, useRef, useState } from 'react'
import ScrollableList from './ScrollableList';
// import { ConstructionOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import style from '@Styles/domrf-theme.module.scss';
// import useMobileDetect from '../../../components/hook/useMobileDetect';
// import ScrollableList2 from './ScrollableList2';

const useStyles = makeStyles({
    disabled: {
        color: `${style.colorPrimary3_40} !important`,
        pointerEvents: 'none'
    },
    inputField: {
        maxWidth: '5rem',
    }
});

function TimePicker(props) {
    const cls = useStyles()
    const { selectedcolor, digheight, neighborcnt, minutesstep, hoursto, hoursfrom, label, value,
        onChange, sx, disabled, InputProps, inputProps,
        hideTitle = false,
        ...otherProps } = props
    const ref = useRef()
    const { t /*, i18n*/ } = useTranslation()

    // const [timeInputValue, setTimeInputValue] = useState(value)
    const [isOpen, setIsOpen] = useState(false)
    // const [touchStartY, setTouchStartY] = useState(0)    
    const [time, setTime] = useState({
        minutes: 0,
        hours: 0,
    })

    const [oldTime, setOldTime] = useState({
        minutes: 0,
        hours: 0,
    })

    const showPicker = () => {
        // console.log('show')
        if (!isOpen) setIsOpen(true)
    }
    const hidePicker = () => {
        // console.log('close')
        if (isOpen) setIsOpen(false)
    }

    // let disabledAdornment = false

    const Cancell = () => {
        // console.log('cancell')
        setTime({ hours: `0${oldTime.hours}`.slice(-2), minutes: `0${oldTime.minutes}`.slice(-2) })
        const d = new Date(value)
        // onChange(new Date(d.setHours(oldTime.hours, oldTime.minutes)))
        hidePicker();
    }
    const saveVal = () => {
        // console.log('SaveVal')
        const d = new Date(value)
        setTime({ hours: `0${time.hours}`.slice(-2), minutes: `0${time.minutes}`.slice(-2) })
        onChange(new Date(d.setHours(time.hours, time.minutes)))
        hidePicker();
    }
    // const device = useMobileDetect()

    useEffect(() => {
        // console.log('isOpen')
        if (value && isOpen) {
            const d = new Date(value)
            let hours = `0${d.getHours()}`.slice(-2)
            let minutes = `0${d.getMinutes()}`.slice(-2)
            setOldTime({ minutes: minutes, hours: hours })
        }
        else {
            setOldTime({ minutes: 0, hours: 0 })
        }
    }, [isOpen])

    useEffect(() => {
        // console.log('Value',value)
        if (value) {
            const d = new Date(value)
            // console.log('d', d)
            let hours = `0${d.getHours()}`.slice(-2)
            let minutes = `0${d.getMinutes()}`.slice(-2)
            setTime((prev) => ({ minutes: minutes, hours: hours }))
        }
        else {
            setTime({ minutes: 0, hours: 0 })
        }
    }, [value])

    // useEffect(() => {
    //     if(window != undefined) {
    //         window.addEventListener("keypress", debounce((event)=>{
    //                         console.log(event)
    //                                                                     if (event.key === "Enter") {
    //                                                                             saveVal();
    //                                                                     }
    //                                                                     if( event.key === 'Escape') {
    //                                                                         Cancell();
    //                                                                     }
    //                                                                 }) , 200);
    //     }
    // }, [])
    const PickerContent = () => {
        return (<Paper {...props}
            sx={{ pl: 3, pr: 3, pt: 1, pb: 1 }}
        >
            <Stack sx={{ mb: 2 }}>
                <Grid container
                // sx={{ '& > *':{alignSelf:'start'}}}
                >
                    {!Boolean(hideTitle) && <Grid item xs={10}>
                        <Typography variant="h6" sx={{ whiteSpace: 'nowrap' }}>
                            {t("SelectTime")}
                        </Typography>
                    </Grid>}
                    {/* <Grid item xs={2} >
                                <Tooltip arrow title={t("SetCurrentTime")}>
                                    <IconButton size="large"
                                             sx={{pt:0.4}}
                                             onClick={()=>{
                                                const d = new Date(Date.now())
                                                // console.log(d)
                                                // setoldTime((prev)=>({hours:`0${ d.getHours()}`.slice(-2), minutes:`0${d.getMinutes()}`.slice(-2)}) )
                                                setTime((prev)=>({hours:`0${ d.getHours()}`.slice(-2), minutes:`0${d.getMinutes()}`.slice(-2)}) )
                                            }
                                        }>
                                        <AccessTimeIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid> */}
                </Grid>
            </Stack>
            <Stack>
                <Grid container>

                    {/* //TEST
                                <Grid item xs={4}>
                                <ScrollableList2 
                                            id='list-hours'  
                                            title = {t("Hours")}
                                            digheight={digheight}
                                            neighborcnt={3}  
                                            data={Array.from({length: 24}, (x, i) => i).filter(i=>(i<(hoursto?hoursto:24)&&i>=(hoursfrom?hoursfrom:0)))} 
                                            selected={parseInt(time.hours)}
                                            selectedcolor={selectedcolor}
                                            setData={(value)=>{if(value && value!='undefined'){ 
                                                                    setTime((prev)=>({...prev, hours: `0${value}`.slice(-2)}))
                                                                }
                                                            } 
                                                    }
                                            />
                            </Grid> */}
                    {/* {console.log('val', Array.from({length: 24}, (x, i) => i).filter(i=>(i<(hoursto?hoursto:24)&&i>=(hoursfrom?hoursfrom:0))))} */}
                    <Grid item xs={6}>
                        <ScrollableList
                            id='list-hours'
                            title={t("Hours")}
                            digheight={digheight}
                            neighborcnt={neighborcnt}
                            data={Array.from({ length: 24 }, (x, i) => i).filter(i => (i <= (hoursto ?? 23) && i >= (hoursfrom ?? 0)))}
                            selected={parseInt(time.hours)}
                            selectedcolor={selectedcolor}
                            setData={(value) => {
                                if (value != null) {
                                    setTime((prev) => ({ ...prev, hours: `0${value}`.slice(-2) }))
                                }
                            }
                            }
                        />
                    </Grid>
                    <Grid item xs={6} sx={{ borderRight: 'solid 1px #0002', }}>
                        <ScrollableList
                            id='list-minutes'
                            title={t("Minutes")}
                            digheight={digheight}
                            neighborcnt={neighborcnt}
                            data={Array.from({ length: 60 }, (x, i) => i).filter(i => i % (minutesstep ?? 1) === 0)}
                            selected={parseInt(Math.floor(time.minutes / minutesstep) * minutesstep)}
                            selectedcolor={selectedcolor}
                            setData={(value) => {
                                if (value != null) {
                                    setTime((prev) => ({ ...prev, minutes: `0${value}`.slice(-2) }))
                                }
                            }
                            }
                        />
                    </Grid>
                </Grid>
            </Stack>
            <Stack>
                <Grid container sx={{ mt: 2, textAlign: 'center', }}>
                    <Grid item xs={6}>
                        <Button onClick={() => Cancell()} >{t("Cancel")}</Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button onClick={() => saveVal()}>{t("OK")}</Button>
                    </Grid>
                </Grid>
            </Stack>
        </Paper>
        )
    }
    // console.log('InputProps!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!', InputProps)
    //  console.log('disabled!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!', disabled)
    return <>
        <TextField
            sx={sx}
            ref={ref}
            disabled={disabled}
            shrink="true"
            fullWidth
            autoComplete='off'
            // key="time-picker-value"
            value={value !== null ? `${`0${time.hours}`.slice(-2)}:${`0${time.minutes}`.slice(-2)}` : ''}
            // onChange={handleInputChange}
            className={[disabled ? cls.disabled : ''].join(' ')}
            onClick={() => { if (!disabled) showPicker() }}
            variant="standard"
            label={label}
            // sx={{ width: '100px' }}
            InputLabelProps={{ ...(value !== null ? { shrink: true, userSelect: 'none' } : { userSelect: 'none' }) }}
            inputProps={{ ...inputProps, inputMode: 'none', className: cls.inputField }} //ОТКЛЮЧЕНИЕ ПОКАЗА КЛАВИАТУРЫ НА МОБИЛЬНЫХ УСТРОЙСТВАХ
            InputProps={
                InputProps ?? {
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton disabled={disabled} onClick={showPicker} size="small">
                                <AccessTimeIcon />
                            </IconButton>
                        </InputAdornment>
                    )
                }}

        />
        {
            // device?.isMobile ?
            //         <Drawer
            //                 id={'time-picker'}
            //                 // anchorEl = {ref.current}
            //                 anchor={device?.isLandscape?"right":"bottom"}
            //                 anchorOrigin={{
            //                     vertical: 'bottom',
            //                     horizontal: 'left',
            //                 }}
            //                 open={isOpen}
            //                 onClose={Cancell}
            //         >
            //                 {PickerContent()}
            //         </Drawer>
            //         :
            <Popover
                id={'time-picker'}
                anchorEl={ref.current}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={isOpen}
                onClose={Cancell}
            // BackdropProps={{sx:{position:'fixed',left:0,right:0,top:0,bottom:0, backgroundColor:'#0002'}}}
            >
                {PickerContent()}
            </Popover>
        }
    </>;
}

export default TimePicker