import React, { useState, useEffect, useLayoutEffect, useRef } from 'react'
import { useDispatch, useSelector } from "react-redux"
import Globals from "@Base/Globals"
import { makeStyles } from '@mui/styles'
import { useTranslation } from "react-i18next"
import Highcharts from 'highcharts'
import HC_exporting from 'highcharts/modules/exporting'
import HC_exporting_data from 'highcharts/modules/export-data'
import HighchartsReact from 'highcharts-react-official'

import style from '@Styles/domrf-theme.module.scss'
import Localization from '@Components/Localization'


import { actionCreators, reducer } from '@Store/SecuritizationStore';
import { alpha } from '@mui/system'
import { setHighchartsLocalization } from '../../../../Utilities'
import { Box, debounce, IconButton, Slider, Stack, ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material'
import SpinnerBars from '../../../../components/shared/SpinnerBars'
import CustomTooltip from '../../../../components/shared/CustomTooltip'
import { SaveOutlined } from '@mui/icons-material'

const useStyles = makeStyles({
    saveButton: {
        position: 'absolute',
        top: '5px',
        right: '60px',
    },
    info: {
        display: 'flex',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
        alignItems: 'center',
        textAlign: 'center',
        fontSize: '1.2rem',
        color: style.colorPrimary3_40
    },
    markActive: {
        boxShadow: `0px 0px 0px 8px ${alpha(style.colorPrimary2, 0.5)} !important`
    },
    mark: {
        backgroundColor: 'transparent'
    },
    container: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
    },

    controlsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        //alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        left: '60px',
        top: '7px',
    },

    controlBox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: 'max-content',
        flexWrap: 'nowrap',
        // width: '30%'
    },
    spinnerContainer: {
        position: 'relative',
        width: '100%',
        height: '100%'
    }
});


const CurrentLTVTab = ({ poolId
}) => {

    const classes = useStyles()
    const chart = useRef()
    const { t, i18n } = useTranslation()
    const dispatch = useDispatch()
    const { chartsData, deal = null, selectedNode = null, ...otherStore } = useSelector((state: any) => state.securitization)
    const { nodeWithChangesLoad = false, CurrentLTVLoad = false } = useSelector((state: any) => state.loader);
    const [chartData, setChartData] = useState(null)
    const [chartLoading, setChartLoading] = useState(false);
    const [step, setStep] = useState(null)
    const [steps, setSteps] = useState([1, 5, 10])

    const onSaveChartAsImageButtonClick = (event) => {
        if (chart.current)
            chart.current.chart.exportChart({ width: 3840, height: 1920 });
    }

    // console.log('selectedNode.node.', selectedNode?.node)
    const chartTitle = `${deal?.deal?.name}, ${t("CurrentLTVTab")}, пул от ${selectedNode?.node?.pools?.length ? Localization.FormatDateStringLocale(selectedNode.node.pools[0]?.poolDate) : ''}`

    const [chartOptions, setChartOptions] = useState({
        exporting: {
            enabled: false,
            filename: chartTitle,
            sourceWidth: 1280,
            sourceHeight: 520,
            chartOptions: {
                title: {
                    text: chartTitle,
                    style: { display: "block", "color": "#2F444E", "fontSize": "20px", "fontWeight": "bold", 'float': 'left' },
                },
                chart: {
                    // style: { "fontSize": "5px" },
                    marginBottom: 40,
                    // events: {
                    // load: function () {
                    //     var renderer = this.renderer;
                    //     console.log('this', this, renderer)
                    //         renderer.path(['M', 30, 385, 'L', 570, 385, 'Z']).attr({
                    //             stroke: 'black',
                    //             'stroke-width': 1
                    //         }).add();

                    //         renderer.text('Some text...', 30, 400).add();
                    // }
                    // }
                },
                legend: {
                    verticalAlign: 'bottom',
                    align: 'center',
                    y: 15,
                    x: 0,
                    itemDistance: 40,
                    itemStyle: {
                        fontSize: '10px',
                        overflow: 'hidden',
                    },
                },
            }
        },
        chart: {
            animation: false,
            // marginBottom:25,
            // height: 300,
            marginTop: 40,
            // marginBottom: 20,
            marginLeft: 60,
            marginRight: 65,
            //events: {
            //load: () => setChartLoading(false)
            //}
        },
        plotOptions: {
            series: {
                /*
                dataLabels: {
                    enabled: true,
                    color: '#2F444E',
                    format: '{y:.3f}'
                },
                */
                pointPadding: 0,
                groupPadding: 0.1,

            },

        },

        title: {
            text: t("CurrentLTV"),
            style: { display: "none", "color": "#2F444E", "fontSize": "18px", "fontWeight": "bold", 'float': 'left' },
            align: 'left',
            verticalAlign: 'top',
            x: 50,
        },

        xAxis: {
            crosshair: {
                color: alpha(style.colorPrimary2, 0.1)
            },
            categories: null,
            labels: {
                enabled: true,
                rotation: 0
            },

            title: {
                enabled: false
            },

            gridLineWidth: 0,
            gridZIndex: 1,
            lineWidth: 1,
            lineColor: style.colorPrimary1,
            tickmarkPlacement: 'between',

            plotLines: [
                {
                    color: style.colorPrimary2,
                    dashStyle: 'dash',
                    width: 2,
                    value: -0.2,
                    zIndex: 2,
                    label: {
                        rotation: 0,
                        textAlign: 'right',
                        x: -5,
                        y: 15,
                        style: {
                            color: style.colorPrimary1,
                            fontWeight: 'bolder',
                            fontSize: '0.9rem'
                        },
                    }
                }]
        },

        yAxis: [{
            labels: {
                enabled: true,
            },
            stackLabels: {
                enabled: true
            },
            title: {
                text: t("CurrentDebtShare"),
                // margin: 4,
                style: { color: style.colorPrimary3_40 }
            },
            tickPositions: null,
            gridLineWidth: 1,
            gridZIndex: 1,
            lineWidth: 1,
            lineColor: style.colorPrimary1,
            gridLineColor: style.colorPrimary3_5,
        }, {
            labels: {
                enabled: true,
            },
            stackLabels: {
                enabled: true
            },
            title: {
                text: t("WACPercents"),
                style: { color: style.colorPrimary3_40 }
            },
            opposite: true,
            tickPositions: null,

            gridLineWidth: 1,
            gridZIndex: 1,
            lineWidth: 1,
            lineColor: style.colorPrimary1,
            gridLineColor: style.colorPrimary3_5,
            plotLines: [
                {
                    color: style.colorPrimary2,
                    dashStyle: 'dash',
                    width: 2,
                    value: -0.2,
                    zIndex: 2,
                    label: {
                        rotation: 0,
                    }
                }]
        }],

        legend: {
            floating: true,
            enabled: true,
            // layout: 'vertical',
            align: 'right',
            verticalAlign: 'top',
            x: -75,
            itemMarginTop: -4,
            itemMarginBottom: 0
        },

        tooltip: {
            shape: "square",
            positioner: function (labelWidth, labelHeight, point) {
                return {
                    x: this.chart.plotLeft + 10,
                    y: this.chart.plotTop + 10
                };
            },
            headerFormat: '<table>'
                + '<tr><td>' + t("CurrentLTV") + ':</td>' +
                '<td style="text-align:end"><b>{point.key:.2f}</b></td>' +
                '</tr>',
            pointFormat: '<tr><td style="padding:0">{series.name}: </td>' +
                '<td style="padding-left:0.5rem;text-align:end"><b>{point.y: .1f}</b></td></tr>',
            footerFormat: '</table>',
            shared: true,
            useHTML: true,
            hideDelay: 20
        },

        credits: {
            enabled: false
        },

        series: [{
            type: 'column',
            name: t('CurrentDebt_short'),
            color: alpha(style.colorPrimary3, 0.1),
            borderColor: alpha(style.colorPrimary3, 0.5),
            yAxis: 0,
            data: null,
            animation: false,
            column: {
                zones: {
                    fillColor: style.colorPrimary2_20
                }
            },
            visible: true,
        }, {
            type: 'line',
            name: t('WACPercents_short'),
            color: style.colorPrimary1,
            yAxis: 1,
            data: null,
            marker: {
                fillColor: '#FFFFFF',
                lineWidth: 2,
                lineColor: null
            },
            animation: false,
            visible: true,
        }]
    })

    HC_exporting(Highcharts)
    HC_exporting_data(Highcharts)
    setHighchartsLocalization(Highcharts, t)

    useEffect(() => {
        setStep(steps[1])
        setChartData(null)
        if (window) {
            window.addEventListener('resize', debounce(onResize, 300))
            onResize();
        }

        return () => {
            window.removeEventListener('resize', debounce(onResize, 300))
        }
    }, [])

    useLayoutEffect(() => {
        if (poolId != null) {
            actionCreators.getPoolData(poolId, 'CurrentLTV', step)(dispatch)
            //setChartLoading(true)
        }
    }, [step, poolId])

    useEffect(() => {
        // if (chartsData != null && chartsData.poolID == poolId)
        setChartData(chartsData);
        // else {
        //     // setChartData(null)
        //     setChartLoading(false)
        // }
    }, [chartsData]);

    useEffect(() => {
        //if (operationLoading == false) {
        setChartLoading(nodeWithChangesLoad || CurrentLTVLoad);
        //}
    }, [nodeWithChangesLoad, CurrentLTVLoad])

    useLayoutEffect(() => {
        updateChart();
    }, [chartData]);

    const updateChart = () => {
        // console.log('chartData*', chartData)
        if (chartData == null || chartData?.chartName?.toLowerCase() != "currentltv" || chartsData.poolID != poolId) {
            // setChartLoading(false)
            return;
        }

        //make chart
        var opts = { ...chartOptions };

        var selectedValue = chartData.data[0]

        opts.xAxis.categories = selectedValue.x1
        let y2format10 = false, y2format100 = false
        selectedValue.y2.some(y => {
            if (y * 10 % 1) {
                //сотые
                y2format100 = true
                return true
            }

            if (!y2format10 && y % 1) {
                //десятые
                y2format10 = true
            }
        }
        )
        opts.yAxis[1].labels.format = y2format100 ? '{value:.2f}' : (y2format10 ? '{value:.1f}' : '{value:.0f}')

        let y1format10 = false, y1format100 = false
        selectedValue.y1.some(y => {
            if (y * 10 % 1) {
                //сотые
                y1format100 = true
                return true
            }

            if (!y1format10 && y % 1) {
                //десятые
                y1format10 = true
            }
        }
        )
        opts.yAxis[0].labels.format = y1format100 ? '{value:.2f}' : (y1format10 ? '{value:.1f}' : '{value:.0f}')

        opts.yAxis[0].tickPositions = selectedValue.y1
        opts.yAxis[1].tickPositions = selectedValue.y2

        opts.series[0].data = selectedValue.chart1
        opts.series[1].data = selectedValue.chart2.map((item, index) => [selectedValue.x1[index], item])
        // if (step == steps[0]) opts.series[1].data = []
        opts.series[1].visible = (step == steps[0] ? false : true)
        // console.log(opts.series[1])

        /*draw x ticks START*/
        const lineStyle =
        {
            color: style.colorPrimary3_5,
            dashStyle: 'solid',
            width: 1,
            zIndex: 2,
            label: {
                rotation: 0
            }
        }
        opts.xAxis.plotLines = [opts?.xAxis?.plotLines[0]]//opts.xAxis.plotLines = Array()
        for (let i = 1; i <= selectedValue?.x1?.length; i++) {
            if (i > 0 && !(parseFloat(selectedValue?.x1[i]?.replace(/[\+<>=]/g, '')) % 10)) opts.xAxis.plotLines.push({ ...lineStyle, value: i - 0.5 })
        }

        opts.xAxis.labels.step = step == 1 ? 10 : step == 5 ? 2 : 1
        /*draw x ticks END*/

        // opts.yAxis[1].plotLines[0].value = chartData.currentWAC
        // opts.yAxis[1].plotLines[0].label.text = `${t('WAC')}: ${Localization.FormatNumber(chartData.currentWAC)}`

        opts.xAxis.plotLines[0].value = chartData.ltv / step - 0.5
        // console.log('chartData.CurrentLTV', chartData.CurrentLTV, step)
        opts.xAxis.plotLines[0].label.text = `${t('CurrentLTV_short')}: ${Localization.FormatNumber(chartData.ltv, 1, 1)}`


        setChartOptions(opts)
        // setChartLoading(false)
    }

    const onResize = () => {
        if (chart.current != null) {
            chart.current.chart.reflow();
            // console.log('resize')
        }
    }

    const handleStep = (event: React.MouseEvent<HTMLElement>, newValue: number | null) => {
        if (newValue != null)
            setStep(newValue)
    }

    return (
        <Stack sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
            <Stack sx={{ flex: 1, overflow: 'hidden' }}>
                {chartLoading ? <SpinnerBars withBackground /> : <></>}
                {(chartLoading || !chartData || chartData?.chartName.toLowerCase() != "currentltv" || chartOptions?.series == null || chartOptions.series?.length == 0) ?
                    <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'center', alignItems: 'center', color: style.colorPrimary3_60 }}>
                        {/* {(chartLoading == false && ((chartData == null && chartOptions?.series?.data == null) || chartData?.chartName.toLowerCase() == "currentltv")) && <Box className={classes.info}>{t("DataLoadingFail")}</Box>} */}
                    </Box> :
                    <>

                        <HighchartsReact
                            ref={chart}
                            highcharts={Highcharts}
                            options={chartOptions}
                            containerProps={{ style: { height: "100%", width: "100%" } }}
                            callback={() => {
                                setTimeout(() => {
                                    onResize()
                                }, 1)
                            }
                            }
                        />
                        <Tooltip title={t("SaveAsAttachment")} disableInteractive>
                            <IconButton size="small" className={classes.saveButton} disabled={false} onClick={onSaveChartAsImageButtonClick}>
                                <SaveOutlined />
                            </IconButton>
                        </Tooltip>
                        <Stack className={classes.controlsContainer} sx={{ mb: 1 }}>
                            <Box className={classes.controlBox}>
                                {/* <CustomTooltip id={`chart_step_${poolId}`} hideIcon sx={{ mr: 1 }} content={'?????'}>
                                    {t('Step')}
                                </CustomTooltip> */}
                                <ToggleButtonGroup
                                    value={step}
                                    exclusive
                                    onChange={handleStep}
                                    aria-label="step"
                                >
                                    {
                                        steps?.map(x => {
                                            return (
                                                <ToggleButton
                                                    size='small'
                                                    sx={{ pt: 0, pb: 0 }}
                                                    key={`step_${x}`} value={x}>
                                                    {Localization.FormatNumber(x, 0, 0)}
                                                </ToggleButton>
                                            )
                                        })
                                    }
                                </ToggleButtonGroup>
                            </Box>
                        </Stack>
                    </>
                }
            </Stack>

        </Stack>
    )
}

export default CurrentLTVTab;