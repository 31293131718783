import MuiTable from '@mui/material/Table';
import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead';

import * as React from 'react';
import { ITbRow } from '../BareBones/TbRow';
import { IDataGrid } from '../DataGridInterfaces/IDataGrid';
import IDetailComponet from '../DataGridInterfaces/IDetailComponent';
import { GridOptions } from './GridOptions';
import { GridBody } from './GridBody';
import { GridHeader } from './GridHeader';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { withStyles } from '@mui/styles';

interface IProps {
    grid: IDataGrid;
    rowComponent: React.FunctionComponent<ITbRow> | undefined;
    footerComponent: React.FunctionComponent<any> | undefined;
    detailComponent?: React.ReactElement<IDetailComponet> | null;
    onRowClick?(row: any, selected: any[]): void;
    onRowDoubleClick?(row: any): void;
    gridOptions: GridOptions;
    classes: any;
}

interface IState {
    xOffset: any;
}

const styles:any = (theme: any) => ({

    tableRoot: {
        outline: 'none',
    },
    tableWrapperFullWidth: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'scroll',
        height: '0',
    },
    tableWrapperPartial: {
        display: 'flex',
        flex: '1 1 auto',
        height: '0',
        flexDirection: 'column'
    },
    tableWrapperFull: {
        display: 'flex',
        flex: '1 1 auto',
        height: '100%',
        flexDirection: 'column'
    },
    tableFullHeight: {
        //maxHeight: 'none',
        //display: 'flex',
        //flexFlow: 'column',
        //flex: '1 1 auto',
        //height: '0'
        //height: '100%' //CHECKME!!! can be used somethines, must be checked
    },
    tableFullHeightAndWidth: {
        maxHeight: 'none',
        flexFlow: 'column',
        flex: '1 1 auto',
        height: '0'
    },
    tableScrollableHeight: {
        display: 'flex',
        flexFlow: 'column',
        width: '100%',
        //overflowY: 'hidden',

        '& thead': {
            display: 'table',
            tableLayout: 'fixed',
            flex: '0 0 auto',
            width: 'calc(100% - 0.9em)'
        },

        '& tbody': {
            /* body takes all the remaining available space */
            flex: '1 1 auto',
            display: 'block',
            overflowY: 'scroll',

            '& tr': {
                width: '100%',
                display: 'table',
                tableLayout: 'fixed'
            }
        },
    }
});


export class DataGridTable extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
    }

    render() {

        const {
            classes
        } = this.props;

        var Footer: any = this.props.footerComponent;
        var tableClass = '';
        var tableWrapperClass = this.props.gridOptions.fullHeight ? classes.tableWrapperFull : classes.tableWrapperPartial;
        if (this.props.gridOptions) {
            if (this.props.gridOptions.fullHeight) {
                if (this.props.gridOptions.fullWidth) {
                    tableClass = classes.tableFullHeightAndWidth;
                    tableWrapperClass = classes.tableWrapperFullWidth;
                }
                //else {
                    //tableClass = classes.tableFullHeight; //none for now
                //}
            }

            if (this.props.gridOptions.fixedHeader) {
                tableClass = clsx(tableClass, classes.tableScrollableHeight);
            }
        }

        return (
            <div className={tableWrapperClass} >
                <MuiTable data-testid='data-grid-table' role={'grid'} className={clsx(classes.tableRoot, tableClass)}>
                    <TableHead>
                        <GridHeader grid={this.props.grid} gridOptions={this.props.gridOptions} />
                    </TableHead>
                    <GridBody
                        grid={this.props.grid}
                        gridOptions={this.props.gridOptions}
                        rowComponent={this.props.rowComponent}
                        onRowClick={this.props.onRowClick}
                        onRowDoubleClick={this.props.onRowDoubleClick}
                        detailComponent={this.props.detailComponent}
                    />
                    {
                        this.props.footerComponent && (
                            <TableFooter>
                                <Footer aggregates={this.props.grid.state.aggregate} />
                            </TableFooter>
                        )
                    }
                </MuiTable>
            </div>
        )
    }
};

export default withStyles(styles)(DataGridTable);