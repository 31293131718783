import SensitivityService from "@Services/SensitivityService";

const Actions = {
    SendRequest: "SENSITIVITY_REQUEST_START",
    RequestSuccess: "SENSITIVITY_REQUEST_SUCCESS",
    RequestFailed: "SENSITIVITY_REQUEST_FAILURE"
}

export const actionCreators = {

    getDOMRFFactoryStatistics: (bondId, date) => async (dispatch) => {
        dispatch({ type: Actions.SendRequest });

        var result = await SensitivityService.getDOMRFFactoryStatistics(bondId, date);
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailed });
            return;
        }

        dispatch({ type: Actions.RequestSuccess, payload: { statistics: result.value } });
    }

};

const initialState = {
    operationLoading: false
};

export const reducer = (currentState, incomingAction) => {

    if (currentState === undefined)
        return initialState; 

    const action = incomingAction;

    switch (action.type) {
        case Actions.Init:
            return initialState;

        case Actions.RequestStart:
            return { ...currentState, operationLoading: true };

        case Actions.RequestSuccess:
            return { ...currentState, operationLoading: false, ...action.payload};

        case Actions.RequestFailure:
            return { ...currentState, operationLoading: false };
        default:
            return currentState || initialState;
    }
};