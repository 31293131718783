import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import LabelImportant from '@mui/icons-material/LabelImportant';
import makeStyles from '@mui/styles/makeStyles';
import * as React from 'react';
import { ColumnDataType, ColumnModel } from '../Common';
import { humanize } from '../../uno-react';
import { IDataGrid } from '../DataGridInterfaces/IDataGrid';
import { RenderCellContent } from '../utils/RenderCellContent';

const useStyles = makeStyles(({ palette }: any) => ({
    cardActions: {
        justifyContent: 'flex-end',
        paddingTop: 0,
    },
    cardBtn: {
        color: palette.primary.dark,
        textDecoration: 'none',
    },
    cardMobile: {
        marginBottom: '2px',
        marginLeft: '10px',
        marginRight: '10px',
        maxHeight: '400px',
        minHeight: '200px',
        minWidth: '95%',
    },
    dataLabel: {
        flexDirection: 'column',
        flexGrow: 1,
        fontWeight: 'bold',
        margin: '4px',
        textAlign: 'right',
        width: '50%',
    },
    dataRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start ',
    },
    dataValue: {
        flexDirection: 'column',
        flexGrow: 1,
        margin: '2px',
        textAlign: 'left',
        width: '50%',
    },
}));

interface IProps {
    grid: IDataGrid,
    columns: ColumnModel[];
    item: any;
    onClickCallback: any;
}

export const DataGridCard = (props : any) => {
    const { grid, columns, item, gridOptions, onClickCallback } = props;
    const classes = useStyles({});

    return (
        <Card className={classes.cardMobile}>
            <CardContent>
                {
                    columns
                        .filter((column: ColumnModel, index: number) => column.Visible)
                        .map((column: ColumnModel, index: number) => (
                        <div className={classes.dataRow} key={index}>
                            <Typography
                                component='div'
                                variant='body2'
                                color='textSecondary'
                                className={classes.dataLabel}
                            >
                                    {/*{humanize(column.Name)}:*/}
                                    {column.Label}:
                            </Typography>
                            <Typography
                                component='div'
                                variant='body2'
                                color='textSecondary'
                                className={classes.dataValue}
                            >
                                    {/*{columnRender(column, item)}*/}
                                    {RenderCellContent(grid, column, item, gridOptions)}
                            </Typography>
                        </div>
                    ))
                }
                <CardActions className={classes.cardActions}>
                    {onClickCallback &&
                        (
                            <IconButton
                                className={classes.cardBtn}
                                color='default'
                                onClick={onClickCallback}
                                size='small'
                            >
                                <LabelImportant />
                            </IconButton>
                        )
                    }
                </CardActions>
            </CardContent>
        </Card>
    );
};

const columnRender = (column: any, item: any) => {

    if (column.Render != null)
        return column.Render(item);
    if (column.IsAction)
        return 'action!';

    switch (column.DataType) {
        case ColumnDataType.BOOLEAN:
            return renderBoolean(column, item);
        case ColumnDataType.STRING:
            return renderString(column, item);
        default:
            return renderGeneral(column, item);
    }
};

const renderBoolean = (column: any, item: any) => <input type='checkbox' checked={item[column.Name]} disabled={true} />;

const renderString = (column: any, item: any) =>
    item[column.Name].length > 50 ? item[column.Name].substring(0, 50) + '...' : renderGeneral(column, item);

const renderGeneral = (column: any, item: any) => item[column.Name];
