import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next'

import IconButton from '@mui/material/IconButton';

import EditIcon from '@mui/icons-material/Edit';
import AddBox from '@mui/icons-material/AddBox';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import LoginIcon from '@mui/icons-material/Login';

import { debounce } from 'throttle-debounce';
import SearchPanelInput from '@Components/shared/SearchPanelInput';

import { createStyles, makeStyles } from '@mui/styles'
import { Paper, Stack, alpha, Box, Container, Tab, Tabs, Theme, Button, Typography, InputAdornment, Tooltip } from '@mui/material'
import ConfirmationForm from "@Components/shared/ConfirmationForm";
import UserEditorPage from './UserEditorPage';

import { actionCreators as administrationStore } from "@Store/AdministrationStore";
import { actionCreators as actionTopNavbar } from '@Store/TopNavbarStore'
import { actionCreators as accountStore } from '@Store/AccountStore'

import {
    DataGridPremium,
    GridColDef,
    GridRowsProp,
    GridActionsCellItem,
    GridColumnGroupingModel,
    GridComparatorFn,
    GridValueFormatterParams,
    ruRU,
    enUS
} from '@mui/x-data-grid-premium';


const useStyles = makeStyles((theme: Theme) => createStyles({

    container: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '0.5rem',
        flex: '1 1 auto',
        height: 0,
        width: '100%',
        minWidth: '100%'
    },
    searchField: {
        marginLeft: 'auto'
    },
}));

const UsersPage = () => {

    const classes = useStyles()
    const { i18n, t } = useTranslation()
    const dispatch = useDispatch();

    const [items, setItems] = useState([]);
    const [count, setCount] = useState(0);
    const [searchValue, setSearchValue] = useState('');

    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 50
    });

    const [locale, setLocale] = useState(ruRU);

    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [showEditPopup, setShowEditPopup] = useState(false);
    const [editedRow, setEditedRow] = useState(null);

    const rowRef = useRef(null);

    const {
        users,
        updatedUser,
        removedUser,
    } = useSelector((state: any) => state.administration)

    const {
        usersLoad
    } = useSelector((state: any) => state.loader)

    useEffect(() => {

        actionTopNavbar.setTitle(t('Users'))(dispatch)
        return () => {
            actionTopNavbar.setTitle("")(dispatch)
            searchDebounced.cancel();
        }
    }, [])

    useEffect(() => {
        searchDebounced(searchValue, paginationModel.page, paginationModel.pageSize);
    }, [searchValue, paginationModel]);

    /*
    useEffect(() => {

        if (createdGroups == null || createdGroups.length == 0)
            return;

        var newItems = [...items, ...createdGroups];
        setItems(newItems);
        setCount(count + createdGroups.length);

    }, [createdGroups]);
    */

    useEffect(() => {

        if (updatedUser == null || updatedUser.length == 0)
            return;

        var newItems = [...items];

        updatedUser.forEach(x => {
            var index = newItems.findIndex(item => item.id == x.id);
            if (index == -1) {
                throw `Updated element with id = ${x.id} not found`;
            }

            newItems[index] = x;
        });


        setItems(newItems);

    }, [updatedUser]);

    useEffect(() => {

        if (removedUser == null || removedUser.length == 0)
            return;


        setItems(items.filter(row => !removedUser.includes(row.id)));
        setCount(count - removedUser.length); //CHECKME!!! not all elements can be excluded from items

    }, [removedUser]);

    useEffect(() => {
        if (i18n.language == 'ru') {
            setLocale(ruRU);
        }
        else {
            setLocale(enUS);
        }

    }, [i18n]);

    useEffect(() => {

        if (users?.items != null) {

            users.items.forEach(x => {
                if (x.expiresAt != null) {
                    x.expiresAt = new Date(x.expiresAt);
                }
            });

            setItems(users?.items);
            setCount(users?.count);
        }

    }, [users]);

    const handleEdit = (params: any) => {
        rowRef.current = params;
        setEditedRow({ ...params.row }); //copy of row

        setShowEditPopup(true);
    }

    const handleChangeAccount = (params: any) => {
        accountStore.enterAtAccount(params.id)(dispatch);
    }

    const handleDelete = (params: any) => {
        rowRef.current = params;
        setShowConfirmDialog(true);
    }

    const applyRecordRemove = () => {
        setShowConfirmDialog(false);

        //CHECKME!!! add remove result and bind table update
        administrationStore.removeUser([rowRef.current.id])(dispatch);

        rowRef.current = null;
    }

    const declineRecordRemove = () => {
        setShowConfirmDialog(false);
        rowRef.current = null;
    }

    const handleRowChange = (name, value) => {

        setEditedRow({ ...editedRow, [name]: value });
    }

    const applyRowChanges = (row, updatedFields) => {

        if (row.id == null) {
            //add row
            administrationStore.createGroups([row])(dispatch);
        }
        else {
            //update row
            var updated = [];
            var item = { id: row.id };
            Object.entries(updatedFields).forEach(([key, value]) => {
                item[key] = { Value: value };
            });
            updated.push(item);

            administrationStore.updateUser(updated)(dispatch);
        }

        setShowEditPopup(false);
        rowRef.current = null;
        setEditedRow(null);
    }

    const cancelRowChanges = (test) => {
        setShowEditPopup(false);
        rowRef.current = null;
        setEditedRow(null);
    }

    //const addNewRecord = () => {
    //    var dummy = {};
    //    rowRef.current = dummy;
    //    setEditedRow(dummy);
    //
    //    setShowEditPopup(true);
    //}
    const isDev = (process.env.REACT_APP_BUILD == 'dev');
    const columns: GridColDef[] = [

        {
            field: 'actions',
            type: 'actions',
            width: isDev ? 90 : 60,
            getActions: (params) => {
                var actions = [];

                if (isDev) {
                    actions.push(
                        <GridActionsCellItem icon={
                            <Tooltip title={t('EnterAtAccount')}>
                                <LoginIcon />
                            </Tooltip>
                        } label={t('EnterAtAccount')} onClick={() => handleChangeAccount(params)} />
                    );
                }

                actions.push(
                    <GridActionsCellItem icon={
                        <Tooltip title={t('Edit')}>
                            <EditIcon />
                        </Tooltip>
                    } label={t('Edit')} onClick={() => handleEdit(params)} />
                );

                actions.push(
                    <GridActionsCellItem icon={
                        <Tooltip title={t('RemoveRecord')}>
                            <DeleteIcon />
                        </Tooltip>
                    } label={t('RemoveRecord')} onClick={() => handleDelete(params)} />
                );
                return actions;
            }
            //renderHeader: () => (
            //    <Tooltip
            //        disableFocusListener
            //        title={t('Add')}
            //        aria-label={t('Add')}>
            //        <IconButton onClick={addNewRecord}>
            //            <AddBox />
            //        </IconButton>
            //    </Tooltip>
            //
            //),
        },

        { field: 'id', headerName: t('ID'), description: "Пользователь или группа участвуют в рассылке", width: 50 },
        { field: 'userName', headerName: t('Name'), description: "Исключить пользователя из группы рассылки", width: 300 },
        { field: 'email', headerName: t('Email'), description: "Название группы рассылки", width: 300 },
        { field: 'emailConfirmed', headerName: t('EmailConfirmed'), description: "Получатель рассылки, зарегистрированный пользователь", type: 'boolean', width: 200 },
        { field: 'isEmployee', headerName: t('IsEmployee'), description: "", type: 'boolean', width: 100 },
        { field: 'webToken', headerName: t('WebToken'), description: "", width: 600 },
        { field: 'expiresAt', headerName: t('ExpiresAt'), description: "", type: 'date', width: 150 },
        { field: 'taskPriority', headerName: t('TaskPriority'), description: "", width: 150 }
    ];

    const updateDataSource = (searchValue, page, pageSize) => {

        var filterItem = null;

        //console.log("---- searchValue = " + searchValue);

        if (searchValue != '') {

            if (paginationModel.page != 0) {
                setPaginationModel({ ...paginationModel, page: 0 });
                return;
            }

            filterItem = [{
                Operator: "or",
                Values: [
                    {
                        Name: "UserName",
                        Type: "contains",
                        FirstValue: searchValue,
                        DataType: "string"
                    },
                    {
                        Name: "Email",
                        Type: "contains",
                        FirstValue: searchValue,
                        DataType: "string"
                    },
                    {
                        Name: "PhoneNumber",
                        Type: "contains",
                        FirstValue: searchValue,
                        DataType: "string"
                    }
                ]
            }];
        }

        var filter = {
            Filters: filterItem,
            ItemIndex: page * pageSize,
            PageSize: pageSize
        };

        administrationStore.getUsersList(filter)(dispatch);
    }

    const searchDebounced = useCallback(debounce(500, updateDataSource), []);

    return (
        <Container className={classes.container}>
            {
                showEditPopup &&
                <UserEditorPage
                    row={editedRow}
                    onChange={handleRowChange}
                    onApplyChanges={applyRowChanges}
                    onCancelChanges={cancelRowChanges}
                    open={showEditPopup}
                />
            }
            <ConfirmationForm
                isOpen={showConfirmDialog}
                header='Удаление'
                content='Вы хотите удалить запись?'
                onAccept={applyRecordRemove}
                onDecline={declineRecordRemove}
            />

            <Stack sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingX: '1rem', paddingY: '0.5rem' }}>
                <SearchPanelInput className={classes.searchField} value={searchValue} onValueChange={setSearchValue} placeHolder={t('Search')} />
            </Stack>

            <DataGridPremium
                sx={{
                    ["& .bandHeaderCell .MuiDataGrid-columnHeaderTitleContainer"]: {
                        justifyContent: 'center'
                    }
                }}
                experimentalFeatures={{ columnGrouping: true }}
                disableColumnMenu
                loading={usersLoad}
                columnHeaderHeight={30}
                rowHeight={25}
                rows={items}
                columns={columns}
                localeText={locale.components.MuiDataGrid.defaultProps.localeText}

                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                rowCount={count}
                paginationMode="server"
                pagination
                /*
                initialState={{
                    pagination: {
                        paginationModel: {
                            page: startPageIndex,
                            pageSize: pageSize
                        }
                    },
                }}
                */
                pageSizeOptions={[30, 50, 100]}
            />
        </Container>
    )
}

export default UsersPage;

/*
const styles = {

    container: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '0.5rem',
        flex: '1 1 auto',
        height: 0
    },
    gridContainer: {
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1 auto',
        height: '100%'
        //marginTop: '1.5rem'
    },
    headerCell: {
        textAlign: 'center',
        '& div': {
            justifyContent: 'center'
        },
        //paddingLeft: '0 !important',
        //paddingRight: '0'
    },
    rowHeader: {
        '& :first-child': {
            paddingLeft: '0'
        }
    },
    table: {
        height: '100%', //to fill container
        '& tbody tr:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.05)'
        }
    },
    iconButton: {
        padding: '0'
    }
};

const EditButton = ({ onExecute }) => (
    <IconButton style={{ padding: '0' }} onClick={onExecute} size="large">
        <Tooltip
            disableFocusListener
            title={Localization.Translate("Edit")}>
            <EditIcon />
        </Tooltip>
    </IconButton>
);

const DeleteButton = ({ onExecute }) => (
    <IconButton
        style={{ padding: '0' }}
        onClick={() => {
            // eslint-disable-next-line
            if (window.confirm('Вы хотите удалить запись?')) {
                onExecute();
            }
        }}
        size="large">
        <Tooltip
            disableFocusListener
            title='Удалить запись'>
            <DeleteIcon />
        </Tooltip>
    </IconButton>
);

interface IState {

    items: any[];
    count: number;

    startPageIndex: number;
    pageSize: number;
    pageSizes: number[];

    locale: any;
    columns: any[];
    boolColumns: any[];
    dateColumns: any[];
    columnWidths: any[];

    searchValue: string;
    defaultRecord: any;
}

interface IProps {
    classes: any;
    history: any;

    getUsersList: (filter: any) => void;
    data: any;

    isLoading: boolean;
    isProceeding: boolean;

    updateUser: (data: any) => void;
    removeUser: (data: any) => void;

    t: any;
}

class UsersPage extends React.Component<IProps, IState> {

    constructor(props) {
        super(props);

        this.state = {

            items: [],
            count: 0,
            startPageIndex: 0, //CHECKME!!! add to user settings
            pageSize: 50, //CHECKME!!! add to user settings

            pageSizes: [
                50, 100, 150
            ],

            searchValue: '',

            locale: getTableLocalization(t),

            defaultRecord: {
                userName: '',
                email: '',
                emailConfirmed: false,
                isEmployee: false,
                webToken: '',
                expiresAt: '',

                taskPriority: 8,
            },

            columns: [
                { name: 'id', title: this.props.t('ID'), tooltip: "Пользователь или группа участвуют в рассылке" },
                { name: 'userName', title: this.props.t('Name'), tooltip: "Исключить пользователя из группы рассылки" },
                { name: 'email', title: this.props.t('Email'), tooltip: "Название группы рассылки" },
                { name: 'emailConfirmed', title: this.props.t('EmailConfirmed'), tooltip: "Получатель рассылки, зарегистрированный пользователь" },
                //{ name: 'password', title: this.props.t('Password'), tooltip: "Фильтр Active Directory, определяющий корпоративную группу рассылки" },
                //{ name: 'phoneNumber', title: this.props.t('PhoneNumber'), tooltip: "Если пользователья не зарегистрирован в системе, то его можно подключить к рассылке, указав в этом поле email" },

                //{ name: 'phoneNumberConfirmed', title: this.props.t('PhoneNumberConfirmed'), tooltip: "" },
                //{ name: 'accessFailedCount', title: this.props.t('AccessFailedCount'), tooltip: "" },
                { name: 'isEmployee', title: this.props.t('IsEmployee'), tooltip: "" },
                { name: 'webToken', title: this.props.t('WebToken'), tooltip: "" },
                { name: 'expiresAt', title: this.props.t('ExpiresAt'), tooltip: "" },
                { name: 'taskPriority', title: this.props.t('TaskPriority'), tooltip: "" }
            ],

            boolColumns: [
                'emailConfirmed',
                'isEmployee'
            ],
            dateColumns: [
                'expiresAt'
            ],

            columnWidths: [
                { columnName: 'id', width: 50 },
                { columnName: 'userName', width: 300 },
                { columnName: 'email', width: 300 },
                { columnName: 'emailConfirmed', width: 200 },
                //{ columnName: 'password', width: 100 },
                //{ columnName: 'phoneNumber', width: 100 },

                //{ columnName: 'phoneNumberConfirmed', width: 200 },
                //{ columnName: 'accessFailedCount', width: 100 },
                { columnName: 'isEmployee', width: 100 },
                { columnName: 'webToken', width: 600 },
                { columnName: 'expiresAt', width: 150 },
                { columnName: 'taskPriority', width: 150 }
            ]
        }
    }

    componentDidMount() {

        var savedData = Globals.state.users;
        if (savedData != null) {
            //reset if columns changed
            if (savedData.columnWidths?.length != this.state.columnWidths.length) {
                savedData.columnWidths = this.state.columnWidths;
            }

            this.setState({ ...savedData }, this.updateDataSource);
        }
        else
            this.updateDataSource();
    }

    componentDidUpdate(prevProps, prevState) {

        if (this.props.users != prevProps.users && this.props.users != null) {

            this.setState({ items: this.props.users.items || [], count: this.props.users.count })
        }

        if (this.props.updatedUser != prevProps.updatedUser) {
            var itemIndex = this.state.items.findIndex(item => item.id == this.props.updatedUser.id);
            var items = [...this.state.items];
            items[itemIndex] = this.props.updatedUser;

            this.setState({ items: items });
        }

        if (this.props.removedUser != prevProps.removedUser) {
            var items = this.state.items.filter(
                row => (row.id != this.props.removedUser)
            );

            this.setState({ items: items });
        }
    }

    saveSettings = () => {

        const data = (({
            startPageIndex,
            pageSize,
            columnWidths
        }) => ({
            startPageIndex,
            pageSize,
            columnWidths,
        }))(this.state);

        Globals.setState('users', data);
    }

    updateDataSource = () => {

        var filterItem = null;

        var index = this.state.startPageIndex * this.state.pageSize;
        var pageSize = this.state.pageSize;

        if (this.state.searchValue != '') {

            index = 0;
            this.setState({ startPageIndex: 0 });

            filterItem = [{
                Operator: "or",
                Values: [
                    {
                        Name: "UserName",
                        Type: "contains",
                        FirstValue: this.state.searchValue,
                        DataType: "string"
                    },
                    {
                        Name: "Email",
                        Type: "contains",
                        FirstValue: this.state.searchValue,
                        DataType: "string"
                    },
                    {
                        Name: "PhoneNumber",
                        Type: "contains",
                        FirstValue: this.state.searchValue,
                        DataType: "string"
                    }
                ]
            }];
        }

        var filter = {
            Filters: filterItem,
            ItemIndex: index,
            PageSize: pageSize
        };

        this.props.getUsersList(filter);
    }

    tableRoot = props => (<Grid.Root {...props} className={clsx(props.className, this.props.classes.table)} />);

    headerCell = (props) => {

        const {
            children,
            ...rest
        } = props;

        return <TableHeaderRow.Cell {...rest} className={clsx(props.className, this.props.classes.headerCell)}>
            {
                (props.tableColumn.isActionColumn !== true) ? (
                    (props.column.tooltip != null) ? (
                        <TableInfoTooltip content={props.column.tooltip}>
                            {children}
                        </TableInfoTooltip>
                    ) : children
                ) : null
            }
        </TableHeaderRow.Cell>;
    }

    searchInput = props => (<CustomSearchPanel.Input {...props} />);

    commandComponents = {
        edit: EditButton,
        delete: DeleteButton,
    };

    Command = ({ id, onExecute }) => {
        const CommandButton = this.commandComponents[id];
        return (
            <CommandButton
                onExecute={onExecute}
            />
        );
    };

    onColumnWidthsChange = (columns: any) => {
        this.setState({ columnWidths: columns }, this.saveSettings);
    }

    onCurrentPageChanged = (pageIndex: number) => {
        this.setState({ startPageIndex: pageIndex }, () => { this.saveSettings(); this.updateDataSource(); });
    }

    onPageSizeChanged = (pageSize: number) => {
        this.setState({ pageSize: pageSize }, () => { this.saveSettings(); this.updateDataSource(); });
    }

    onSearchQueryChanged = (query: string) => {
        this.setState({ searchValue: query }, this.updateDataSource);
    }

    onSearchQueryChangedDebounced = debounce(1000, this.onSearchQueryChanged);

    commitChanges = ({ added, changed, deleted }) => {

        let { items } = this.state;
        if (changed) {

            var updated = [];
            Object.entries(changed).forEach(([key, value]) => {
                var item = { id: key, ...(value as object) };
                updated.push(item);
            });

            this.props.updateUser(updated[0]); //only one item to update
        }
        if (deleted) {
            this.props.removeUser(deleted[0]);
        }
    }

    render() {

        const { classes, t } = this.props;

        return (

            <Container fluid className={classes.container}>
                <Spinner show={this.props.isLoading || this.props.isProceeding} fullScreen content={t('Loading')} />
                <Paper className={classes.gridContainer}>
                    <Grid
                        rows={this.state.items}
                        columns={this.state.columns}
                        getRowId={(row) => row.id}
                        rootComponent={this.tableRoot}
                    >
                        <BooleanTypeProvider for={this.state.boolColumns} />
                        <DateTimeTypeProvider for={this.state.dateColumns} />

                        <SearchState
                            onValueChange={this.onSearchQueryChangedDebounced}
                        />

                        <PagingState
                            currentPage={this.state.startPageIndex}
                            onCurrentPageChange={this.onCurrentPageChanged}
                            pageSize={this.state.pageSize}
                            onPageSizeChange={this.onPageSizeChanged}
                        />

                        <CustomPaging
                            totalCount={this.state.count}
                        />

                        <EditingState
                            onCommitChanges={this.commitChanges} />

                        <VirtualTable
                            //height="auto" //to fill container
                            estimatedRowHeight={25}
                            messages={this.state.locale.tableMessages} />

                        <TableColumnResizing
                            columnWidths={this.state.columnWidths}
                            onColumnWidthsChange={this.onColumnWidthsChange}
                            resizingMode={'widget'}
                        />

                        <TableEditColumn
                            showDeleteCommand
                            showEditCommand
                            commandComponent={this.Command} />

                        <PopupEditor
                            popupComponent={UserEditorPage}
                        />

                        <TableHeaderRow
                            cellComponent={this.headerCell}
                        />

                        <Toolbar />

                        <Template
                            name="toolbarContent">
                            <h3 className="me-5">{t('Users')}</h3>

                            <TemplatePlaceholder />

                        </Template>

                        <CustomSearchPanel
                            messages={this.state.locale.searchPanelMessages}
                            inputComponent={this.searchInput}
                        />

                        <PagingPanel
                            pageSizes={this.state.pageSizes}
                            messages={this.state.locale.pagingPanelMessages}
                        />

                    </Grid>

                </Paper>
            </Container>
        );
    }
}

var component = connect(
    (state: any) => state.administration, // Selects which state properties are merged into the component's props
    AdministrationStore.actionCreators
)(UsersPage);

export default withRouter(withStyles(styles)(withTranslation('translations')(component)));
*/
