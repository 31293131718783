import * as React from "react";
import InstrumentForm from "./InstrumentForm";
import { withTranslation } from "react-i18next";

/*
const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)'
    },
    titleBar: {
        background:
            'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
            'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
    icon: {
        color: 'white',
    },
});
*/

class InstrumentsList extends React.Component {

    // constructor(props) {
    //     super(props);
    // }

    onOpenInstrument = (event, data) => {

        if (this.props.onOpenInstrument)
            this.props.onOpenInstrument(event, data);
    }

    render() {

        const { t } = this.props;

        var content = null;
        if (this.props.Instruments) {
            if (this.props.Instruments.length === 0) {
                content = (
                    <div className="is-items-container">
                        <h4>{t('NotFound')}</h4>
                    </div>
                );
            }
            else {

                content = (<div className="is-items-container">
                    {this.props.Instruments.map(tile => (
                        <InstrumentForm key={tile.id} data={tile} onOpenInstrument={this.onOpenInstrument}/>
                    ))}
                </div>
                );
            }
        }
        else {
            content = (
                <div className="is-items-container">
                    <h4>t('NoData')</h4>
                </div>
            );
        }

        return content;
    }
}

export default withTranslation('translations')(InstrumentsList);