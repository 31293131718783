import * as React from "react";

import { withTranslation } from "react-i18next";
import { withStyles } from '@mui/styles';

import { /*Paper, IconButton, Button,*/ Typography } from "@mui/material";
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
// import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faStar } from '@fortawesome/free-solid-svg-icons';
// import { Instruments } from "../Instruments";
import style from '../../styles/domrf-theme.module.scss';

const styles = (theme) => ({

    isItemsItem: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '300px',
        maxHeight: '350px',
        width: '300px',
        height: '250px',
        margin: '1rem'
    },

    isItemActionArea: {
        display: 'flex !important',
        flex: '1 1 auto !important',
        flexDirection: 'column',
        justifyContent: 'start !important', //override of buttons center
    },

    isItemMedia: {
        height: '140px',
        width: '100%'
    },

    isItemFavourite: {
        position: 'absolute !important',
        zIndex: '1'
    },

    favouriteButton: {
        color: style.colorPrimary2_40
    },

    favouriteButtonSelected: {
        color: style.colorPrimary2
    }
});

class InstrumentForm extends React.Component {

    // constructor(props) {
    //     super(props);
    // }

    openInstrument = (event) => {

        if (this.props.onOpenInstrument) {
            this.props.onOpenInstrument(event, this.props.data);
        }
    }

    handleLibraryRedirect = (event) => {

        if (this.props.data.libraryUrl == null)
            return;

        if (this.props.data.isLibraryExternalLink === true) {
            window.location.href = this.props.data.libraryUrl;
        }
        else {
            this.props.router.navigate(this.props.data.libraryUrl);
        }
    }

    render() {

        const { classes } = this.props;

        return (
            <Card className={classes.isItemsItem}>
                {/* CHECKME!!!
                <IconButton className={classes.isItemFavourite} aria-label="favourites" title="Добавить в избранное">
                    <FontAwesomeIcon icon={faStar} className={this.props.data.favourite ? classes.favouriteButtonSelected : classes.favouriteButton} />
                </IconButton>
                */}
                <CardActionArea className={classes.isItemActionArea} onClick={this.openInstrument}>
                    <CardMedia className={classes.isItemMedia} image={this.props.data.img} title={this.props.data.title} />
                    <CardContent sx={{ width: '100%', m: '0 1rem 0 1rem', boxSizing: 'border-box' }}>
                        <Typography gutterBottom variant="h5" component="h2">
                            <div style={{ textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: this.props.data.title }} />
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                            {this.props.data.description}
                        </Typography>
                    </CardContent>
                </CardActionArea>
                {/*
                <CardActions className="justify-content-end">
                    <Button size="small" color="primary" onClick={this.handleLibraryRedirect}>
                        Узнать больше
                            </Button>
                </CardActions>
                */}
            </Card>
        );
    }
}

export default withTranslation('translations')(withStyles(styles)(InstrumentForm));