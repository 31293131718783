export enum ColumnDataType {
    UNKNOWN = 'unknown',
    STRING = 'string',
    FLOAT = 'float',
    INT = 'int',
    BOOLEAN = 'boolean',
    BOOLEAN_TRISTATE = 'boolean_nullable',
    DATE = 'date',
    DATE_TIME = 'datetime',
    DATE_TIME_UTC = 'datetimeutc',
    ENUM = 'enum',
    ENUM_AUTOCOMPLETE = 'enum_autocomplete',
    ENUM_AUTOCOMPLETE_MULTIPLE = 'enum_autocomplete_multiple',
    FILE = 'file'
}

export enum ColumnSortDirection {
    NONE = 'None',
    ASCENDING = 'Ascending',
    DESCENDING = 'Descending'
}

export enum CompareOperators {
    NONE = 'None',
    AUTO = 'Auto',
    EQUALS = 'Equals',
    NOT_EQUALS = 'NotEquals',
    CONTAINS = 'Contains',
    STARTS_WITH = 'StartsWith',
    ENDS_WITH = 'EndsWith',
    GTE = 'Gte',
    GT = 'Gt',
    LTE = 'Lte',
    LT = 'Lt',
    MULTIPLE = 'Multiple',
    BETWEEN = 'Between',
    NOT_CONTAINS = 'NotContains',
    NOT_STARTS_WITH = 'NotStartsWith',
    NOT_ENDS_WITH = 'NotEndsWith'
}

export enum AggregateFunctions {
    NONE = 'None',
    SUM = 'Sum',
    AVERAGE = 'Average',
    COUNT = 'Count',
    DISTINCT_COUNT = 'DistinctCount',
    MAX = 'Max',
    MIN = 'Min'
}
export enum TrimContentType {
    ELLIPSIS = 'ellipsis',
    CLIP = 'clip'
}

export enum ActionType {
    TYPE_UNDEFINED = 'undefined',
    TYPE_ADD = 'add',
    TYPE_EDIT = 'edit',
    TYPE_REMOVE = 'remove',
    TYPE_CUSTOM = 'custom'
}

export enum SelectionType {
    TYPE_NONE = 'none',
    TYPE_SINGLE = 'singleselect',
    TYPE_MULTI = 'multiselect'
}