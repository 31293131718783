import { Box, Button, Divider, Paper, Typography, Stack, alpha, CircularProgress } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'
import style from '@Styles/domrf-theme.module.scss'
import { t } from 'i18next'
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { PoolProcessingStatus } from '../../../../components/Definitions'
import usePrevious from '../../../../components/hook/usePrevious'
import Localization from '../../../../components/Localization'
import { actionCreators } from '../../../../store/SecuritizationStore'
import { humanReadableStatuses } from '../../../../Utilities'
import { useSelector } from 'react-redux'
import CustomButton from '@Base/components/shared/CustomButton'
import ConfirmationForm from '@Base/components/shared/ConfirmationForm'

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        height: '34px',
        position: 'relative',
    },
    disabled: {
    },
    button: {
        position: 'relative',
        color: `${alpha(style.colorSecondary2, 1)}`,
        backgroundColor: `${alpha(style.colorSecondary2, 0.1)}`,
        borderColor: `${alpha(style.colorSecondary2, 1)}`,
        '&:hover': {
            // color:`${alpha(style.colorSecondary2,1)}`,
            backgroundColor: `${alpha(style.colorSecondary2, 0.2)}`,
            borderColor: `${alpha(style.colorSecondary2, 1)}`,
        }
    },
    infoRow: {
        display: 'flex',
        flexDirection: 'row',
        lineHeight: '2rem',
        '&>*': {
            display: 'inherit',
        },
        '& .data': {
            '& .pole': {
                paddingLeft: '0.5rem',
            },
            paddingRight: '0.5rem',
            paddingLeft: '0.5rem',
            // width:'100%',
            cursor: 'default',
        },
        '& .rowNumber': {
            minWidth: '30px',
            textAlign: 'right',
            paddingRight: '0.5rem',
        },
        '&:nth-of-type(2n)': {
            backgroundColor: `${alpha(style.colorPrimary3, 0.05)}`
        },
        '&:hover': {
            '& .error': {
                backgroundColor: 'rgb(255,241,242)',
                //color:'rgb(192,0,0)'
            },
            '& .warning': {
                backgroundColor: 'rgb(255, 250, 235)',
                //color:'rgb(255, 185, 20)',
            }
        }
    },
    scrollablePanel: {
        position: 'absolute', left: 0, right: 0, top: 0, bottom: 0,
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: '4px',
        },
        '&:hover': {
            '&::-webkit-scrollbar-track': {
                backgroundColor: '#00000008',
            },
            '&::-webkit-scrollbar-thumb': {
                background: '#00000018',
            }
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'unset',
            borderRadius: '3px',
            // border: 'solid 1px #00000000',
            backgroundColor: '#00000000',
            transition: 'all 0.3s ease'
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#0000',
            borderRadius: '3px',
            height: '4px'
        },
    },
    container: {
        '& *': {
            userSelect: 'none !important',
        },
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        position: 'absolute', left: 0, top: 0, right: 0, bottom: 0,
    },
    header: {
        display: 'flex', paddingLeft: 1,
        flexWrap: 'nowrap',
        flexDirection: 'row',
        alignContent: 'center',
        alignItems: 'baseline',

    },
    alertWin: {
        display: 'flex',
        fontSize: '1.3rem',
        color: style.colorPrimary3_40,
        justifyContent: 'center',
        // alignItems: 'center',
    }
}));

interface IProps {
    pool: any,
    header?: any;
}

function PoolProcessingPage({ pool = null, header }: IProps) {
    const dispatch = useDispatch()
    const classes = useStyles();
    const [statuses, setStatuses] = useState([])
    const prevPoolState = usePrevious(pool)
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)
    // console.log('pool status', Localization.Translate(humanReadableStatuses.get(pool.status)), pool.statusString)
    const {
        selectedNode = null,
    } = useSelector((state: any) => state.securitization)

    useEffect(() => {
        // console.log('pool', pool)
        // console.log('prevPoolState', prevPoolState)

        const date = new Date().toISOString()
        if (
            (prevPoolState?.status == null && pool?.status != null) ||
            (prevPoolState?.status != pool?.status)
        )
            setStatuses([...(pool?.id == prevPoolState?.id ? statuses : []), { body: Localization.Translate(humanReadableStatuses.get(pool?.status)), date: date }])

        if (/*prevPoolState?.status == pool.status &&*/ prevPoolState?.statusString != pool?.statusString) {
            setStatuses([...(pool?.id == prevPoolState?.id ? statuses : []), { body: pool?.statusString, date: date }])
        }
    }, [pool])

    // console.log('poolProcessing----------------------------------------', pool)
    if (pool == null)
        return (selectedNode?.node?.id==null? <Box className={classes.alertWin}>{t("PoolNotLoaded")}</Box>:'')

    const title = header != `Пул ${pool?.id} от ${Localization.FormatDateTimeStringLocale(pool.createdDate)}` && header


    return (
        <Box className={classes.container}>
            <ConfirmationForm
                isOpen={showConfirmationDialog}
                header={t("CancellPoolUpload")}
                content={t("CancellPoolUploadWarning")}
                onAccept={() => { actionCreators.cancelPoolCalculation(pool.id)(dispatch); setShowConfirmationDialog(false)}}
                acceptButton={t("ConfirmButton")}
                onDecline={() => setShowConfirmationDialog(false)}
                declineButton={t("CancellButton")}
            />
            {title ? <>
                <Box className={classes.header}>
                    <Typography sx={{ textTransform: 'uppercase', lineHeight: '2rem', maxHeight: '2rem', overflow: 'hidden', ml: 1, flexGrow: 1, fontSize: '14px', color: style.colorPrimary3 }} >
                        {title}
                    </Typography>
                </Box>
                <Divider />
            </> : <></>
            }
            <Stack sx={{ p: 1, flexGrow: 1, position: 'relative' }}>
                <Box className={classes.scrollablePanel}>
                    {
                        !!statuses.length && statuses?.map((row, index) =>
                            <Stack key={`infoRow-${index}`} className={classes.infoRow}>
                                <Box className='data'>
                                    {/* <Box className='rowNumber'>{index + 1}</Box> */}
                                    <Box className='pole'>{Localization.FormatDateTimeStringLocale(row?.date)}</Box>
                                    <Box className='pole'>{row?.body}</Box>
                                </Box>
                            </Stack>)
                    }
                </Box>
            </Stack>
            <Stack sx={{ flexDirection: 'row', justifyContent: 'flex-end', p: 1 }}>
                {
                    (pool.status > PoolProcessingStatus.STATUS_UNKNOWN &&
                        pool.status <= PoolProcessingStatus.STATUS_SENT
                    ) &&
                    (
                        <>
                            <CustomButton
                                sx={{ ml: 1 }}
                                variant="contained"
                                color="primary"
                                onClick={() => setShowConfirmationDialog(true)}
                                progress={"cancelPoolCalculationLoad"}
                                progressProps={{ size: 22 }}
                                classes={{ root: classes.root, disabled: classes.disabled }}
                            >
                                {t('CancellPoolUpload')}
                            </CustomButton>
                        </>
                        // <Button variant="outlined" className={classes.button} /*color="error"*/ onClick={() => actionCreators.cancelPoolCalculation(pool.id)(dispatch)}>
                        //     {t('BreakPoolUpload')}
                        // </Button>

                    )
                }
            </Stack>
        </Box>
    )
}

export default PoolProcessingPage