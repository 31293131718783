import CalculatorService from "@Services/CalculatorService";
import DomRfService from "@Services/DomRfService";

const Actions = {

    Init: "BOND_INIT",

    RequestStart: "BOND_REQUEST_START",
    RequestSuccess: "BOND_REQUEST_SUCCESS",
    RequestSuccessUpdate: "BOND_REQUEST_UPDATE_SUCCESS",
    RequestFailure: "BOND_REQUEST_FAILURE",
    RequestUpdate: "BOND_UPODATE_PARAMETERS",

    RequestData: "BOND_REQUEST_DATA_FROM_STATE",
    // PartialRequestStart: "BOND_PARTIAL_REQUEST_START",
    // PartialRequestSuccess: "BOND_PARTIAL_REQUEST_SUCCESS",
    // PartialRequestFailure: "BOND_PARTIAL_REQUEST_FAILURE",
}


export const actionCreators = {

    init: () => async (dispatch, getState) => {
        dispatch({ type: Actions.Init });
    },

    getBonds: (request) => async (dispatch, getState) => {
        dispatch({ type: Actions.RequestStart })

        var filter = {
            ItemIndex: request?.Skip,
            PageSize: request?.Take,
            Filters: request?.Filters,
            Sorters: request?.Sorters,
            Params: null,

        }

        var result = await CalculatorService.getBonds(filter)
        // console.log('result', result)
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailure });
            return;
        }

        const items = result.value.items

        dispatch({ type: Actions.RequestSuccess, payload: { count: result.value.count, items: items } });
    },

    updateBond: (request) => async (dispatch, getState) => {
        dispatch({ type: Actions.RequestStart })

        var result = await CalculatorService.updateBond(request)
        // console.log('result', result)
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailure });
            return;
        }

        dispatch({ type: Actions.RequestSuccessUpdate, payload: { updatedBond: result.value } });
    },

    updateBondData: (params = {}) => async (dispatch, getState) => {
        dispatch({ type: Actions.RequestUpdate, payload: params });
    },

    getBond: (id) => async (dispatch, getState) => {

        // dispatch({ type: Actions.RequestStart })

        // var result = await CalculatorService.getBonds(filter)
        // // console.log('result', result)
        // if (result.hasErrors) {
        //     dispatch({ type: Actions.RequestFailure });
        //     return;
        // }

        dispatch({ type: Actions.RequestData, payload: id });
    },

    getBondsInfo: (items, date) => async (dispatch, getState) => {

        dispatch({ type: Actions.RequestStart });

        var result = await CalculatorService.getBondsInfo(items, date);
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailure });
            return;
        }

        dispatch({ type: Actions.RequestFailure, payload: { bonds: result.value } });
    },


};

const initialState = {
    loading: false,
    items: []
};

export const reducer = (currentState, incomingAction) => {

    if (currentState === undefined)
        return initialState;

    const action = incomingAction;

    switch (action.type) {
        case Actions.Init:
            return initialState;

        case Actions.RequestStart:
            return { ...currentState, loading: true };

        case Actions.RequestSuccess:
            return { ...currentState, loading: false, ...action.payload };

        case Actions.RequestFailure:
            return { ...currentState, loading: false };

        case Actions.RequestSuccessUpdate: {
            const index = currentState?.items?.findIndex(item => item?.id == action.payload.updatedBond?.id)
            if (index == -1)
                return { ...currentState, loading: false };

            const newItems = [...currentState?.items]
            newItems[index] = action.payload.updatedBond

            // console.log('action.payload.updatedBond', action.payload.updatedBond)
            // console.log('newItems', newItems)
            return { ...currentState, loading: false, items: newItems, selectedBond: action.payload.updatedBond }
        }

        case Actions.RequestUpdate:
            return { ...currentState, loading: false, selectedBond: { ...currentState.selectedBond, ...action.payload } };

        case Actions.RequestData: {
            const index = currentState?.items?.findIndex(x => x.id == action.payload)
            if (index == -1)
                return
            // console.log('action.payload', action.payload, currentState)
            return { ...currentState, loading: false, selectedBond: currentState?.items[index] };
        }
        // case Actions.PartialRequestStart:
        //     return { ...currentState, bondsLoading: true };

        // case Actions.PartialRequestSuccess:
        //     return { ...currentState, bondsLoading: false, ...action.payload };

        // case Actions.PartialRequestFailure:
        //     return { ...currentState, bondsLoading: false };


        default:
            return currentState || initialState;
    }
};