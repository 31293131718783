import React, { Component } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
//import IconButton from '@mui/material/IconButton';
//import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { ProcessingState } from "@Components/Definitions";
import { ValidatorForm, TextValidator } from "@Framework/components/Form";

import "@Styles/pages/PasswordResetPage.scss";

export default class ForgotPasswordDialog extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            visible: this.props.show,
            email: ""
        };
    }

    componentDidUpdate(prevProps) {
        
        if (this.props.show !== prevProps.show) {
            this.setState({ visible: this.props.show });
        }
    }

    onWindowClose = (event) => {

        if (this.props.onClose)
            this.props.onClose(event);

        this.setState({
            visible: false,
            email: ""
        });
    }

    handleEmailChange = (event) => {
        this.setState({ email: event.target.value });
    }

    handleEmailSubmit = async (event) => {

        if (this.props.onClose)
            this.props.onClose(event, { email: this.state.email });

        this.setState({
            visible: false,
            email: ""
        });
    };

    render() {
        return (
            <Dialog open={this.state.visible} aria-labelledby="form-dialog-title">
                <ValidatorForm
                    onSubmit={this.handleEmailSubmit}>

                    <MuiDialogTitle id="form-dialog-title">Восстановление пароля</MuiDialogTitle>
                    <MuiDialogContent>
                        <DialogContentText>
                            Для восстановления пароля введите адрес почты по которому вы регистрировались.
                        </DialogContentText>

                        <TextValidator
                            value={this.state.email}
                            onChange={this.handleEmailChange}
                            autoFocus
                            margin="dense"
                            id="email"
                            label="E-mail"
                            type="email"
                            fullWidth
                            validators={['required', 'isEmail']}
                            errorMessages={['Поле обязательно к заполнению', 'Некорректный формат почты']}
                        />

                    </MuiDialogContent>
                    <MuiDialogActions>
                        <Button
                            onClick={this.onWindowClose}
                            variant="contained"
                            color="primary">Отмена</Button>
                        <Button
                            type="submit"
                            variant="contained"
                            color="secondary">OK</Button>
                    </MuiDialogActions>

                </ValidatorForm>
            </Dialog>
        );
    }
}