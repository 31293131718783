import "@Styles/pages/PasswordResetPage.scss";
import { Button, Paper, Container } from "@mui/material";
import { TabContent } from "@Framework/components/TabContent";
import { ValidatorForm, TextValidator } from "@Framework/components/Form";

import * as React from "react";
//import * as ReactDOM from "react-dom";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { withRouter } from "../../components/shared/CustomRouter";
import queryString from "query-string";

import * as PasswordResetStore from "@Store/PasswordResetStore";
import { Spinner } from "@Components/shared/Spinner";
import * as Utilities from '@Utilities';
import { ProcessingState } from "@Components/Definitions";
import { PageLogo } from "@Components/shared/PageLogo";
import PasswordChangeForm from "@Components/shared/PasswordChangeForm";
import InformationForm from "@Components/shared/InformationForm";

class PasswordResetPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            userId: "",
            code: ""
        };

        if (this.props.router.location.search) {
            const values = queryString.parse(this.props.router.location.search);
            if (values.userId)
                this.state.userId = values.userId;
            if (values.code)
                this.state.code = values.code;
        }
    }

    passwordChangeForm;

    handleResetPasswordSubmit = (event, password) => {

        var model = {
            userId: this.state.userId,
            code: this.state.code,
            password: password
        };

        this.props.resetPassword(model);

        this.passwordChangeForm.reset();
    };

    handleRedirectToMain = (event) => {
        let path = "/login";
        this.props.router.navigate(path);
    };

    render() {

        let failedProcessing = !(this.state.code && this.state.userId) || this.props.resetPasswordState == ProcessingState.Response_Failed;

        let content = (
            <PasswordChangeForm ref={x => this.passwordChangeForm = x} onSubmit={this.handleResetPasswordSubmit} />
        );

        if (this.props.resetPasswordState == ProcessingState.Response_Success) {
            
            content = (
                <InformationForm type="success" onSubmit={this.handleRedirectToMain} content="Пароль успешно сменён. Теперь вы можете залогиниться используя новый пароль."/>
            );
        }

        return (
            <Container sx={{ height: '100%', marginTop: '1rem' }}>

                <Spinner show={this.props.operationLoading} fullScreen content="Обработка..." />
                {content}

            </Container>
        );
    }
}

var component = connect(
    state => state.passwordReset, // Selects which state properties are merged into the component's props
    PasswordResetStore.actionCreators // Selects which action creators are merged into the component's props
)(PasswordResetPage);

export default withRouter(component);