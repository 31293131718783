import CalculatorService from "@Services/CalculatorService";

const Actions = {
}

export const actionCreators = {
};

const initialState = {
};

export const reducer = (currentState, incomingAction) => {

    if (currentState === undefined)
        return initialState; 

    const action = incomingAction;

    switch (action.type) {

        default:
            return currentState || initialState;
    }
};