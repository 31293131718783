import { clone } from "@Utilities";
import DomRfService from "../services/DomRfService";
import { ProcessingState } from "@Components/Definitions";
import { SharedActions } from "./SharedActions";
import CommonService from "@Services/CommonService";
import CalculatorService from "@Services/CalculatorService";
import Localization from '@Components/Localization';

const Actions = {

    Init: "DOMRF_INIT",

    SendRequest: "DOMRF_SEND",
    RequestFailed: "DOMRF_REQUEST_FAILED",
    RequestSuccess: "DOMRF_REQUEST_SUCCESS",

    MainSendRequest: "DOMRF_MAIN_SEND",
    MainRequestFailed: "DOMRF_MAIN_REQUEST_FAILED",
    MainRequestSuccess: "DOMRF_MAIN_REQUEST_SUCCESS",

    ParametersRequest: "DOMRF_PARAMETERS_SEND",
    ParametersFailed: "DOMRF_PARAMETERS_FAILED",
    ParametersLoaded: "DOMRF_PARAMETERS_LOADED",

    ZCYCDataLoading: "DOMRF_ZCYC_LOADING",
    ZCYCDataLoaded: "DOMRF_ZCYC_LOADED"
}


export const actionCreators = {

    init: () => async (dispatch, getState) => {
        dispatch({ type: Actions.Init });
    },

    setData: (objectData) => async (dispatch, getState) => {
        dispatch({
            type: Actions.RequestSuccess, payload: { ...objectData }
        });
    },

    loadBonds: (query, existId) => async (dispatch) => {

        dispatch({ type: Actions.SendRequest, payload: { bondsLoading: true } });
        var result = await DomRfService.getBonds(query, existId);
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailed, payload: { bondsLoading: false } });
            return;
        }
        dispatch({
            type: Actions.RequestSuccess, payload: {
                bondsLoading: false, options: result.value.items
            }
        });
    },

    getBonds: () => async (dispatch) => {

        dispatch({ type: Actions.SendRequest });
        var result = await DomRfService.getAllBonds();
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailed });
            return;
        }

        var items = result.value.map(item => { return { id: item.id, displayName: item.displayName, description: item.description } });
        items.sort((a, b) => a.displayName < b.displayName ? -1 : (a.displayName > b.displayName ? 1 : 0));

        dispatch({ type: Actions.RequestSuccess, payload: { bondsId: items } });
    },

    calculateResults: (data) => async (dispatch) => {

        dispatch({ type: Actions.MainSendRequest });
        var result = await DomRfService.calculateBondsStatistics(data);
        if (result.hasErrors) {
            dispatch({ type: Actions.MainRequestFailed });
            return;
        }
        // console.log('store calculateResults',data, result)
        dispatch({ type: Actions.MainRequestSuccess, payload: { bond: result.value } });
    },

    getBondData: (date, id, skipEvaluationDateDueLoad) => async (dispatch) => {

        dispatch({ type: Actions.ParametersRequest });
        var result = await DomRfService.getBondData(date, id);
        // console.log('store getBondData',date, id,result)
        if (result.hasErrors) {
            dispatch({ type: Actions.ParametersFailed });
            return;
        }

        dispatch({ type: Actions.ParametersLoaded, payload: { cprcdr: result.value, skipEvaluationDateDueLoad: skipEvaluationDateDueLoad } });
    },

    getBondsChartData: (ids) => async (dispatch) => {
        dispatch({ type: Actions.SendRequest });
        var result = await DomRfService.getBondsChartData(ids);
        // console.log('bond chart data',result)
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailed });
            return;
        }

        dispatch({ type: Actions.RequestSuccess, payload: { bondStatistics: result.value } });
    },

    getComplexYZCYCC: (date) => async (dispatch) => {

        dispatch({ type: Actions.ZCYCDataLoading });

        var result = await CommonService.getComplexYZCYCC(Localization.FormatDateTimeStringISO(date));
        if (result.hasErrors) {
            return;
        }

        dispatch({ type: Actions.ZCYCDataLoaded, payload: result.value });
    },

    getChartData: (id) => async (dispatch) => {

        dispatch({ type: Actions.SendRequest, payload: { isLoading: true } });

        var result = await DomRfService.getChartData(id);
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailed, payload: { isLoading: false } });
            return;
        }

        dispatch({ type: Actions.RequestSuccess, payload: { flowData: result.value, isLoading: false } });
    },
    getSystemCalculations: (dates) => async (dispatch) => {

        dispatch({ type: Actions.SendRequest, payload: { isCalculationLoading: true } });

        var result = await DomRfService.getSystemCalculations(dates);
        // console.log('result', result)
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailed, payload: { isCalculationLoading: false } });
            return;
        }

        dispatch({ type: Actions.RequestSuccess, payload: { data: result.value, isCalculationLoading: false } });
    },
    getSystemCalculationsRange: (dateFrom, dateTo) => async (dispatch) => {

        dispatch({ type: Actions.SendRequest, payload: { isCalculationLoading: true } });

        var result = await DomRfService.getSystemCalculationsRange(dateFrom, dateTo);
        // console.log('result', result, dateFrom, dateTo)
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailed, payload: { isCalculationLoading: false } });
            return;
        }

        dispatch({ type: Actions.RequestSuccess, payload: { calculations: result.value?.calculations, isCalculationLoading: false } });
    },
    getZCYCChartData: (arrDates) => async (dispatch) => {

        dispatch({ type: Actions.SendRequest });

        var result = await CommonService.getZCYCChartDataFull(arrDates);
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailed });
            return;
        }

        dispatch({ type: Actions.RequestSuccess, payload: { zcycData: result.value } });
    },

    loadLayouts: (id) => async (dispatch) => {
        dispatch({ type: Actions.SendRequest });

        var result = await CalculatorService.loadUserLayouts(id);
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailed });
            return;
        }

        dispatch({ type: Actions.RequestSuccess, payload: { loadedLayouts: result.value } });
    },

    saveLayout: (data) => async (dispatch) => {
        dispatch({ type: Actions.SendRequest });

        var result = await CalculatorService.saveUserLayout(data);
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailed });
            return;
        }

        dispatch({ type: Actions.RequestSuccess, payload: { newLayout: result.value } });
    },

    removeLayout: (id) => async (dispatch) => {
        dispatch({ type: Actions.SendRequest });

        var result = await CalculatorService.removeUserLayout(id);
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailed });
            return;
        }

        dispatch({ type: Actions.RequestSuccess });
    },


    loadMoexSecurities: (date) => async (dispatch, getState) => {

        dispatch({ type: Actions.SendRequest });

        var result = await CalculatorService.loadMoexSecurities(Localization.FormatDateStringISO(date));
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailed });
            return;
        }

        dispatch({ type: Actions.RequestSuccess, payload: { moexSecurities: result.value } });
    }
};

const initialState = {
    operationLoading: false
};

export const reducer = (currentState, incomingAction) => {

    if (currentState === undefined)
        return initialState;

    const action = incomingAction;

    var cloneIndicators = () => clone(currentState.indicators);

    switch (action.type) {
        case Actions.Init:
            return initialState;

        case Actions.MainSendRequest:
            {
                return { ...currentState, operationLoading: true };
            }
        case Actions.MainRequestFailed:
            {
                return { ...currentState, operationLoading: false };
            }
        case Actions.MainRequestSuccess:
            {
                return { ...currentState, ...action.payload, operationLoading: false };
            }

        case Actions.SendRequest:
            {
                return { ...currentState, ...action.payload };
            }
        case Actions.RequestFailed:
            {
                return { ...currentState, ...action.payload };
            }
        case Actions.RequestSuccess:
            {
                return { ...currentState, ...action.payload };
            }
        case Actions.ParametersRequest:
            {
                return { ...currentState, ...action.payload, parametersLoading: true }
            }
        case Actions.ParametersFailed:
            {
                return { ...currentState, parametersLoading: false }
            }
        case Actions.ParametersLoaded:
            {
                return { ...currentState, ...action.payload, parametersLoading: false }
            }
        case Actions.ZCYCDataLoading:
            {
                return { ...currentState, zcycLoaded: false };
            }
        case Actions.ZCYCDataLoaded:
            {
                if (action.payload == null)
                    return;

                var zcy_curve_base_chart_data = [];

                action.payload.zcycCurve.forEach(function (item, i, arr) {
                    zcy_curve_base_chart_data.push([item.year, item.value]);
                });

                var zcyDate = Date.parse(action.payload.zcycDate);
                if (isNaN(zcyDate))
                    zcyDate = null;
                else
                    zcyDate = new Date(zcyDate);

                return {
                    ...currentState,

                    ZCYCDate: zcyDate,
                    zcy_curve_base_chart_data: zcy_curve_base_chart_data,
                    bonds_points: action.payload.bonds,
                    zcycLoaded: true
                };
            }
        default:
            return currentState || initialState;
    }
};