/* eslint-disable */
import React, { useEffect, useState, useRef } from 'react';
import { TextField, Box } from '@mui/material';
/* eslint-enable */
import useFormValidation from './ValidationForm/useFormValidation';
import TooltipInfoLabel from '../../../components/shared/TooltipInfoLabel';
import withStyles from '@mui/styles/withStyles';
import ErrorTooltip from '../../../components/shared/ErrorTooltip';
import { InputAdornment, Popper } from "@mui/material";

import { makeStyles, createStyles } from '@mui/styles'
import style from '@Styles/domrf-theme.module.scss'

const useStyles = makeStyles((theme: Theme) =>

    createStyles({
        error: {
            backgroundColor: 'rgb(255,237,237) !important',
        },
        errorPopper: {
            backgroundColor: style.colorPrimary3,
            color: '#FFF',
            fontSize: '1rem',
            padding: '0.2rem 0.5rem',
            borderRadius: '4px',
        },
        tooltip: {
            display: 'flex'
        },
        errorAdornment: {
            marginRight: theme.spacing(3),
        },
        menuPaper: { maxHeight: '300px !important' }
    })
);

const SelectValidator = ({
    value,
    name,
    error,
    errorMessages,
    validators,
    requiredError,
    helperText,
    validatorListener,
    withRequiredValidator,
    tooltip,
    readOnly,
    disabled,
    inputProps,
    hideValidationIcon = false,
    SelectProps,

    ...rest
}) => {

    const { isValid, errorMessage } = useFormValidation(disabled, name, value, errorMessages, validators);
    const classes = useStyles();
    const [displayWarning, setDisplayWarning] = useState(false)
    const [displayActiveWarning, setDisplayActiveWarning] = useState(false)
    const elRef = useRef()

    useEffect(() => {
        if (isValid) {
            setDisplayWarning(false)
            setDisplayActiveWarning(false)
        }
    }, [isValid])

    return (
        <>
            {(hideValidationIcon == true && !isValid) &&
                <Popper open={displayWarning || displayActiveWarning} anchorEl={elRef?.current}
                    // disablePortal={false}
                    className={classes.errorPopper}
                >
                    {errorMessage}
                </Popper>
            }
            {/* {console.log('SelectProps', SelectProps)} */}

            <TooltipInfoLabel className={classes.tooltip} content={tooltip}>
                <TextField
                    {...rest}
                    select
                    SelectProps={{
                        className: [readOnly ? 'Mui-readOnly' : ''].join(' '),
                        ...SelectProps,
                        ...readOnly ? { open: false } : {},
                    }}
                    name={name}
                    value={value}
                    error={!isValid || error}
                    helperText={helperText}
                    disabled={disabled /*|| readOnly*/}
                    inputProps={{
                        ...inputProps
                        , className: [(hideValidationIcon == true && !isValid) && classes.error,
                        ].join(' ')
                    }}
                    ref={elRef}
                    // onFocusCapture={()=>{if (hideValidationIcon == true && !isValid && !displayActiveWarning) setDisplayActiveWarning(true)}}
                    // onBlurCapture={()=>{if (hideValidationIcon == true && !isValid && displayActiveWarning) setDisplayActiveWarning(false)}}
                    onMouseOver={(e) => { if (hideValidationIcon == true && !isValid && !displayWarning) setDisplayWarning(true) }}
                    onMouseOut={(e) => { if (hideValidationIcon == true && !isValid && displayWarning) setDisplayWarning(false) }}
                    InputProps={{
                        // disableUnderline: true,
                        endAdornment: (
                            <>
                                {
                                    (hideValidationIcon == false && !isValid) && (
                                        <InputAdornment className={classes.errorAdornment} position="end">
                                            <ErrorTooltip content={errorMessage} />
                                        </InputAdornment>
                                    )
                                }
                            </>
                        ),
                    }}
                    className={['Mui-readOnly'].join(' ')}
                />
            </TooltipInfoLabel>
        </>
    )
}

export default SelectValidator;
