import makeStyles from '@mui/styles/makeStyles';
import TablePagination from '@mui/material/TablePagination';
import * as React from 'react';
import { useResolutionSwitch } from '../../uno-react';
import { IDataGrid } from '../DataGridInterfaces/IDataGrid';
import { AdvancePaginationActions } from './AdvancePaginationActions';
import { Localization } from '../DataGrid/Localization';

const useStyles = makeStyles({
    caption: {
        flexShrink: 1,
        height: '55px',
    },
    root: {
        height: '75px',
        maxWidth: '95%',
    },
});

const outerWidth = 800;
const timeout = 400;

const message = (locale: Localization, totalRecordCount: number, filteredRecordCount: number) => ({
    from,
    to,
    count,
}: any) =>
    totalRecordCount === filteredRecordCount
        ? `${from}-${to} ${locale.of} ${count}`
        : filteredRecordCount === 0
            ? `0 ${locale.recordsFound}`
            : `${from} ${locale.to} ${to} ${locale.of} ${count} ${locale.from} ${totalRecordCount} ${locale.records}`;

interface IPaginatorProps {
    grid: IDataGrid;
    rowsPerPageOptions: number[];
    advancePagination: boolean;
    locale: Localization;
}

export const Paginator: React.FunctionComponent<IPaginatorProps> =
    ({ grid, rowsPerPageOptions, advancePagination, locale }) => {
        const [isMobileResolution] = useResolutionSwitch(outerWidth, timeout);
        const classes = useStyles({});
        const { state, api } = grid;

        if (!state.itemsPerPage) {
            return null;
        }

        const newProps = {
            count: state.filteredRecordCount,
            labelDisplayedRows: message(
                locale,
                state.totalRecordCount,
                state.filteredRecordCount,
            ),
            labelRowsPerPage: locale.RowsPerPage,
            onPageChange: (e: any, page: number) => api.goToPage(page),
            onRowsPerPageChange: (e: any) =>
                api.updateItemPerPage(Number(e.target.value)),
            page: state.filteredRecordCount > 0 ? state.page : 0,
            rowsPerPage: state.itemsPerPage,
            rowsPerPageOptions: rowsPerPageOptions || [10, 20, 50, 100],
        } as any;

        newProps.ActionsComponent = () => (
            <AdvancePaginationActions
                locale={locale}
                count={newProps.count}
                isAdvanced={advancePagination}
                isLoading={newProps.isLoading}
                onChangePage={newProps.onPageChange}
                page={newProps.page}
                rowsPerPage={newProps.onRowsPerPageChange}
            />
        );

        return (
            <TablePagination
                classes={{ /*caption: isMobileResolution && classes.caption,*/ root: classes.root }}
                {...newProps}
            />
        );
    };
