import { connect } from 'react-redux';
import * as React from 'react';
// import Button from '@mui/material/Button';
// import Dialog from '@mui/material/Dialog';
// import MuiDialogTitle from '@mui/material/DialogTitle';
// import MuiDialogContent from '@mui/material/DialogContent';
// import MuiDialogActions from '@mui/material/DialogActions';
import Table from '@mui/material/Table';
import TextField from '@mui/material/TextField';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import stringInject from 'stringinject';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import withStyles from '@mui/styles/withStyles';
/*
import {
    MobileDateTimePicker
} from '@mui/x-date-pickers/MobileDateTimePicker';
*/
import Typography from '@mui/material/Typography';
import Localization from '../../../components/Localization';
import Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import HC_exporting_data from 'highcharts/modules/export-data';
import HighchartsReact from 'highcharts-react-official';
import HC_more from "highcharts/highcharts-more"; //module
import * as DomRfStore from "@Store/DomRfStore";
import { setHighchartsLocalization, copyTextToClipboard } from '@Utilities';
import style from '@Styles/domrf-theme.module.scss';
import { withTranslation } from "react-i18next";
import { Box, Button, Dialog, DialogActions, DialogContent, Divider, IconButton, Tooltip } from '@mui/material';
import { CloseOutlined, HelpOutline, SaveOutlined } from '@mui/icons-material';
import { TimePickerValidator } from '../../../framework/components/Form';
import TimePicker from '../../../framework/components/Form/TimePicker';
import { MobileDatePicker } from '@mui/x-date-pickers';
import { PickersActionBarProps } from '@mui/x-date-pickers/PickersActionBar';
import { isValid } from 'date-fns'
//import '@mui/lab';

const styles = {

    controlsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'start',
        paddingBottom: '1rem'
    },
};

const chartsColours = [
    style.colorPrimary1,
    style.colorPrimary2,
    style.colorPrimary3,

    style.colorPrimary1_80,
    style.colorPrimary2_80,
];

interface IProps {
    isin?: string;
    name?: string;
    show: boolean,
    onClose: any,
    getZCYCChartData?: (id: any[]) => void;
    firstZCYCDate?: Date | string;
    zcycData?: any[],
    classes: any;
    t: any;
}

interface IState {
    locale: {},
    tooltipHeader: string,
    visible: boolean,
    zcycChartOptions: any,
    firstZCYCDate: Date | string,
    firstZCYCDateInner: Date | string,
    secondZCYCDate: Date | string,
    secondZCYCDateInner: Date | string,
    notUpdateCharts: boolean
}

class ZCYCDialog extends React.Component<IProps, IState> {
    zcycChart = null

    constructor(props) {
        super(props);
        HC_exporting(Highcharts);
        HC_more(Highcharts);
        setHighchartsLocalization(Highcharts, this.props.t);
        Highcharts.SVGRenderer.prototype.symbols.SaveFileIcon = function (x, y, w, h) {
            const icon = 'M17 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V7l-4-4zm2 16H5V5h11.17L19 7.83V19zm-7-7c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3zM6 6h9v4H6z'
            return icon.split(' ')
        }

        this.state = {
            locale: Localization.locale,
            tooltipHeader: '',
            visible: this.props.show || false,
            firstZCYCDate: null,
            firstZCYCDateInner: null,
            secondZCYCDate: null,
            secondZCYCDateInner: null,

            notUpdateCharts: false,

            zcycChartOptions: {
                chart: {
                    zoomType: 'x',
                    events: {
                        // render: (event) => console.log('this', this),
                        // load:()=>console.log('useData', Localization.Translate('ConventionNameZCYCComparisonGraphDurationRunner'))
                    }
                },

                exporting: {
                    enabled: false,
                    //     width: 1024,
                    //     height: 720,
                    //     buttons: {
                    //         contextButton: {
                    //             symbol:'SaveFileIcon',
                    //             symbolStroke:'0',
                    //             // menuItems: ['downloadCSV', 'downloadXLS', 'downloadJPEG'],
                    //             onclick:function(){this.exportChart({ width: 2048 });}
                    //         }
                    //     },
                    chartOptions: {
                        legend: {
                            itemDistance: 40,
                            itemStyle: {
                                fontSize: '7px',
                                overflow: 'hidden',
                            },
                        },
                    }
                    //     // buttons: {
                    //     //     contextButton: {
                    //     //         menuItems: [
                    //     //             {
                    //     //                 textKey: 'printChart',
                    //     //                 onclick: function () { this.print(); }
                    //     //             },
                    //     //             {
                    //     //                 separator: true
                    //     //             },
                    //     //             {
                    //     //                 textKey: 'downloadPNG',
                    //     //                 onclick: function () { this.exportChart(); }
                    //     //             },
                    //     //             {
                    //     //                 textKey: 'downloadJPEG',
                    //     //                 onclick: function () { this.exportChart({ type: 'image/jpeg' }); }
                    //     //             }
                    //     //         ]
                    //     //     }
                    //     // }
                },

                title: {
                    text: '',
                    //style: { "color": "#2F444E", "fontSize": "18px", "fontWeight": "bold" }
                },

                credits: {
                    enabled: false
                },

                xAxis: {
                    title: this.props.t('Years'),
                    gridLineWidth: 1,
                    lineWidth: 1,
                    lineColor: style.colorPrimary1
                },

                yAxis: {
                    title: null
                    //min: 0
                },

                tooltip: {
                    shared: false,
                    split: true,
                    formatter: function () {
                        // console.log('this', this)
                        const vals = this.points.map(point => `<span style="color:${point.color}">\u25CF</span> ${point.series.name} <b>${Localization.FormatNumber(point.y, 2, 2)}</b>`).join('<br/>')
                        return `<b>${Localization.Translate('ConventionNameZCYCComparisonGraphDurationRunner')}: ${Localization.FormatNumber(this.x, 2, 2)}</b><br>${vals}`
                    },
                    // headerFormat: `<b>${this.props.t('ConventionNameZCYCComparisonGraphDurationRunner')}: {point.x:.2f}</b><br>`,
                    // pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y:.2f}</b><br/>',
                    // shared: true
                },

                series: []
            }
        };
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState) {

        if (this.props.firstZCYCDate != prevProps.firstZCYCDate && this.props.firstZCYCDate != null) {

            this.setState({ firstZCYCDate: this.props.firstZCYCDate, firstZCYCDateInner: this.props.firstZCYCDate });
        }

        if ((prevState.firstZCYCDate != this.state.firstZCYCDate || prevState.secondZCYCDate != this.state.secondZCYCDate)) {
            if (!this.state.notUpdateCharts) {
                var args = [];
                if (isValid(this.state.firstZCYCDate))
                    args.push(Localization.FormatDateTimeISO(this.state.firstZCYCDate));
                if (isValid(this.state.secondZCYCDate)) {
                    // console.log('this.state.secondZCYCDate', this.state.secondZCYCDate)
                    args.push(Localization.FormatDateTimeISO(this.state.secondZCYCDate));
                }
                if (args.length != 0)
                    this.props.getZCYCChartData(args);
            }
        }

        if (this.props.show !== prevProps.show) {
            this.setState({ visible: this.props.show });
        }

        if (this.state.zcycChartOptions != prevState.zcycChartOptions) {
            this.setState({ notUpdateCharts: false });
        }

        if ((prevState.locale != Localization.locale || this.props.zcycData != prevProps.zcycData) && this.props.zcycData != null) {

            var newZCYCChartOptions = { ...this.state.zcycChartOptions };



            // this.setState({tooltipHeader: this.props.t('ConventionNameZCYCComparisonGraphDurationRunner')})    

            newZCYCChartOptions.series = this.props.zcycData.map((data, index) => {

                var zcycData = data.zcycCurve.map(item => { return { x: item.year, y: item.value } });

                var dateNow = new Date();
                var date = new Date(data.zcycDate);
                var timeLabel = '';
                var tooltipLabel = '';

                // if (
                //     dateNow.getFullYear() == date.getFullYear() &&
                //     dateNow.getMonth() == date.getMonth() &&
                //     dateNow.getDate() == date.getDate()
                // ) { //если дата == текущему деню то вывести только время
                //     var time = Localization.FormatTimeLocale(date);
                //     timeLabel = stringInject(this.props.t('YCurveAt'), [time]);
                //     //tooltipLabel = stringInject(this.props.t('YCurveAt'), [time]);
                // }
                // else
                 {
                    var time = Localization.FormatDateTimeLocale(date);
                    timeLabel = stringInject(this.props.t('YCurveOn'), [time]);
                    //tooltipLabel = stringInject(this.props.t('YCurveOn'), [time]);
                }

                return {
                    name: timeLabel,
                    color: chartsColours[index],
                    showInLegend: true,
                    lineWidth: 1,
                    //tooltip: {
                    //headerFormat: `<b>${tooltipLabel}</b><br>`
                    //pointFormat: `<span style="color:{point.color}">\u25CF</span> ${timeLabel}: <b>{point.y:.2f}</b><br/>`,
                    //},
                    data: zcycData,
                    marker: {
                        symbol: 'circle',
                        enabled: false
                    }
                }
            });

            var changes: any = {};
            changes.locale = Localization.locale
            if (isValid(this.state.firstZCYCDateInner) && this.props.zcycData.length > 0) {
                changes['firstZCYCDateInner'] = this.props.zcycData[0].zcycDate;
                changes['notUpdateCharts'] = true;
            }
            // console.log('this.state.secondZCYCDateInner', this.props.zcycData)
            if (isValid(this.state.secondZCYCDateInner) && this.props.zcycData.length > 1) {
                changes['secondZCYCDateInner'] = new Date(this.props.zcycData[1].zcycDate);
                changes['notUpdateCharts'] = true;
            }

            this.setState({ zcycChartOptions: newZCYCChartOptions, ...changes });
        }
    }

    onWindowClose = (event) => {

        if (this.props.onClose)
            this.props.onClose(event, false);
    }

    handleDateChange = (name, date) => {
        // console.log('date', date)
        // console.log(date,    )
        if (isValid(date))
            this.setState({ [name]: date } as Pick<IState, keyof IState>);
    }

    handleInnerDateChange = (name, date) => {
        var innerDate = isValid(date) ? new Date(date.setHours(23, 59, 59)) : date;

        this.setState({ [name]: innerDate } as Pick<IState, keyof IState>);
    }

    render() { 

        const { classes, t } = this.props;

        const dateNow = new Date();
        const maxDate = new Date(dateNow.getFullYear(), dateNow.getMonth(), dateNow.getDate(), 23, 59, 59);
        const CustomPaper = () => { <div>TEST!</div> }
        const checkDates = (day) => {
            const curTime = new Date(day).getTime()
            const minTime = new Date(2014, 1, 9).getTime()
            const maxTime = maxDate.getTime()
            return !(curTime > minTime && curTime < maxTime)
        }

        const MyActionBar = ({
            onAccept,
            onCancel,
            onClear,
            onSetToday,
        }: PickersActionBarProps) => {

            return (
                <DialogActions>
                    <Button onClick={onCancel}> {t('Cancel')} </Button>
                    <Button onClick={onAccept}> {t('OK')} </Button>
                </DialogActions>
            );
        };
        return (
            <>
                <Dialog maxWidth="md" fullWidth onClose={this.onWindowClose} open={this.state.visible} disableScrollLock>
                    <Box sx={{
                        display: 'flex', pl: 1,
                        flexWrap: 'nowrap',
                        flexDirection: 'row',
                        alignContent: 'center',
                        alignItems: 'baseline',
                    }}
                    >
                        <Typography sx={{ textTransform: 'uppercase', lineHeight: '2rem', maxHeight: '2rem', overflow: 'hidden', ml: 1, flexGrow: 1, fontSize: '14px', color: style.colorPrimary3 }} >
                            {t('ConventionNameZCYCComparisonWindow')}
                            {/* <span>
                                <Tooltip title={t('KeyRateTooltip')}>
                                    <IconButton sx={{width:30, height:30, p:0,ml:1, mb:'3px', alignItems:'center', textAlign:'center'}}>
                                        <HelpOutline />
                                    </IconButton>
                                </Tooltip>
                            </span> */}
                        </Typography>

                        <Box component="span" sx={{ pr: 1 }}>
                            <IconButton sx={{ width: 30, height: 30 }} onClick={() => this.zcycChart.chart.exportChart({ width: 2048 })}>
                                <SaveOutlined />
                            </IconButton>
                        </Box>

                        <Box component="span" sx={{ pr: 1 }}>
                            {
                                // кнопка Close
                            }
                            <IconButton size="small" onClick={this.onWindowClose}>
                                <CloseOutlined />
                            </IconButton>
                        </Box>
                    </Box>
                    <Divider />
                    <DialogContent dividers>
                        <Box>
                            {/* <div className={classes.controlsContainer}> */}
                            <Box sx={{ mr: 3 }} component="span">
                                <MobileDatePicker
                                    //disableCloseOnSelect={true}
                                    label={t('ConventionNameEnterFirstZCYCDate')}
                                    //ampm={false}
                                    //showTodayButton={true}
                                    components={{
                                        ActionBar: MyActionBar
                                    }}
                                    // cancelText={t('Cancel')}
                                    // okText={t('OK')}
                                    //todayText={t('Today')}
                                    value={this.state.firstZCYCDateInner}
                                    inputFormat={Localization.ShortDatePattern()}
                                    minDate={new Date(2014, 1, 9)}
                                    maxDate={maxDate}
                                    shouldDisableDate={checkDates}
                                    shouldDisableMonth={checkDates}
                                    //shouldDisableYear={checkDates}
                                    renderInput={(params) => <TextField sx={{ mr: 1, maxWidth: '130px' }} /*className="pe-3"*/ {...params} variant="standard" />}
                                    onChange={(e) => this.handleInnerDateChange("firstZCYCDateInner", e)}
                                    onAccept={(e) => this.handleDateChange("firstZCYCDate", e)}
                                />
                                <TimePicker
                                    sx={{ ml: 0, mr: 1, maxWidth: '150px' }}
                                    InputProps={{}} //убирает правую иконку
                                    name={t('ConventionNameEnterFirstZCYCDateTime')}
                                    value={this.state.firstZCYCDateInner}
                                    label={t('ConventionNameEnterFirstZCYCDateTime')}
                                    onChange={(e) => this.handleDateChange("firstZCYCDate", e)}
                                    minutesstep={10}
                                    hoursfrom={10}
                                    hoursto={19}
                                    digheight={3}
                                    neighborcnt={2}
                                    selectedcolor={'#8bc54094'}
                                />
                            </Box>
                            <Box sx={{ mr: 3 }} component="span">
                                <MobileDatePicker
                                    //disableCloseOnSelect={true}
                                    label={t('ConventionNameEnterSecondZCYCDate')}
                                    //ampm={false}
                                    //showTodayButton={true}
                                    components={{
                                        ActionBar: MyActionBar
                                    }}
                                    // cancelText={t('Cancel')}
                                    // okText={t('OK')}
                                    // todayText={t('Today')}
                                    value={this.state.secondZCYCDateInner}
                                    inputFormat={Localization.ShortDatePattern()}
                                    minDate={new Date(2014, 1, 9)}
                                    maxDate={maxDate}
                                    shouldDisableDate={checkDates}
                                    shouldDisableMonth={checkDates}
                                    //shouldDisableYear={checkDates}
                                    renderInput={(params) => <TextField sx={{ mr: 1, maxWidth: '130px' }} {...params} variant="standard" />}
                                    //onChange={(e) => this.handleDateChange("secondZCYCDate", e ?? new Date(e?.setHours(23, 59, 59))?.toString())}
                                    onChange={(e) => this.handleInnerDateChange("secondZCYCDateInner", e) }
                                    onAccept={(e) => this.handleDateChange("secondZCYCDate", e)}
                                />
                                <TimePicker
                                    sx={{ ml: 0, mr: 1, maxWidth: '150px' }}
                                    InputProps={{}} //убирает правую иконку
                                    name={t('ConventionNameEnterSecondZCYCDateTime')}
                                    value={this.state.secondZCYCDateInner}
                                    disabled={!this.state.secondZCYCDateInner}
                                    label={t('ConventionNameEnterSecondZCYCDateTime')}
                                    onChange={(e) => this.handleDateChange("secondZCYCDate", e)}
                                    minutesstep={10}
                                    hoursfrom={10}
                                    hoursto={19}
                                    digheight={3}
                                    neighborcnt={2}
                                    selectedcolor={'#8bc54094'}
                                />
                            </Box>
                            {/* </div> */}
                        </Box>

                        <div className="w-100">
                            <HighchartsReact
                                ref={x => this.zcycChart = x}
                                highcharts={Highcharts}
                                options={this.state.zcycChartOptions}
                            />
                        </div>

                        <div>
                            {
                                this.props.zcycData != null && (
                                    <Table aria-label="zcyc table" className={this.props.classes.tableStyle}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell key={"zcyc-x-date"} className={this.props.classes.tableHeader}></TableCell>
                                                {
                                                    this.props.zcycData[0].tableZCYCCurve.map((item, index) => {
                                                        return (
                                                            <TableCell key={"zcyc-x-" + index} className={this.props.classes.tableHeader} align='right'>
                                                                {item.year < 1 ? Localization.FormatNumberString(item.year) : Localization.FormatNumberString(item.year, 0, 0)}
                                                            </TableCell>
                                                        )
                                                    })
                                                }
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                this.props.zcycData.map((item, index) => {
                                                    return (
                                                        <TableRow key={'zcyc-series-' + index}>
                                                            <TableCell key={"zcyc-y-date"}>{Localization.FormatDateTimeStringLocale(item.zcycDate) + ', Y(t), %'}</TableCell>
                                                            {
                                                                item.tableZCYCCurve.map((curve, index) => {
                                                                    return (
                                                                        <TableCell key={"zcyc-y-" + index} align='right'>
                                                                            {Localization.FormatNumberString(curve.value)}
                                                                        </TableCell>
                                                                    )
                                                                })
                                                            }
                                                        </TableRow>
                                                    )
                                                })
                                            }
                                        </TableBody>
                                    </Table>
                                )}
                        </div>

                    </DialogContent>
                </Dialog>
            </>
        );
    }
}

var component = connect(
    state => state.domrf, // Selects which state properties are merged into the component's props
    DomRfStore.actionCreators // Selects which action creators are merged into the component's props
)(ZCYCDialog);

export default withStyles(styles)(withTranslation('translations')(component));