import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
//import IconButton from '@mui/material/IconButton';
//import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Localization from '../components/Localization';
import { withTranslation } from "react-i18next";

interface IProps {
    show: boolean,
    title: string,
    information: string,
    onClose: any
    renderHeader: any;
    renderBody: any;
    t: any;
}

interface IState {
    visible: boolean
}

class ConfirmationDialog extends React.Component<IProps, IState> {

    constructor(props) {
        super(props);

        this.state = {
            visible: this.props.show || false
        };
    }

    componentDidUpdate(prevProps) {

        if (this.props.show !== prevProps.show) {
            this.setState({ visible: this.props.show });
        }
    }

    onWindowClose = (event, reason) => {

        if (reason == 'backdropClick') {
            event.preventDefault();
            return;
        }

        if (this.props.onClose)
            this.props.onClose(event, false);

        this.setState({
            visible: false
        });
    }

    handleOK = (event) => {

        if (this.props.onClose)
            this.props.onClose(event, true);

        this.setState({
            visible: false
        });
    }

    render() {

        const { t } = this.props;

        return (

            <Dialog /*disableBackdropClick*/ onClose={this.onWindowClose} aria-labelledby="confirm-dialog-title" open={this.state.visible}>
                <MuiDialogTitle disableTypography id="confirm-dialog-title" onClose={this.onWindowClose}>
                    {
                        (this.props.renderHeader != null && typeof this.props.renderHeader == 'function') && (
                                this.props.renderHeader()
                            )
                    }
                    <h4>
                        {(this.props.title != null) && (this.props.title)}
                    </h4>
                </MuiDialogTitle>
                <MuiDialogContent dividers>
                    {
                        (this.props.renderBody != null && typeof this.props.renderBody == 'function') && (
                            this.props.renderBody()
                        )
                    }
                    {
                        (this.props.information != null) && (this.props.information)
                    }
                </MuiDialogContent>
                <MuiDialogActions>
                    <Button variant="contained" color="primary" onClick={this.onWindowClose}>
                        {t('Cancel')}
                    </Button>
                    <Button variant="contained" color="secondary" onClick={this.handleOK}>
                        {t('OK')}
                    </Button>
                </MuiDialogActions>
            </Dialog>
        );
    }
}

export default withTranslation('translations')(ConfirmationDialog);