import { connect } from 'react-redux';
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
//import IconButton from '@mui/material/IconButton';
//import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Localization from '../../../components/Localization';
import Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import HC_exporting_data from 'highcharts/modules/export-data';
import HighchartsReact from 'highcharts-react-official';
import HC_more from "highcharts/highcharts-more"; //module
import * as DomRfStore from "@Store/DomRfStore";
import { setHighchartsLocalization, copyTextToClipboard } from '@Utilities';
import style from '@Styles/domrf-theme.module.scss';
import { withTranslation } from "react-i18next";

interface IProps {
    isin?: string;
    name?: string;
    show: boolean,
    onClose: any,
    objectId: number,
    flowData?: any;
    getChartData?: (id: number) => void;
    t: any;
}

interface IState {
    title: string;
    visible: boolean,
    cprChartOptions: any
    cdrChartOptions: any;
}

class ChartsDialog extends React.Component<IProps, IState> {

    constructor(props) {
        super(props);

        HC_more(Highcharts);
        setHighchartsLocalization(Highcharts, this.props.t);

        this.state = {

            title: '',
            visible: this.props.show || false,

            cprChartOptions: {

                chart: {
                    zoomType: 'x'
                },

                exporting: { enabled: false },

                title: {
                    text: this.props.t('CPRPrc'),
                    style: { "color": "#2F444E", "fontSize": "18px", "fontWeight": "bold" }
                },

                credits: {
                    enabled: false
                },

                xAxis: {
                    type: 'datetime',
                    title: this.props.t('Years')
                },

                yAxis: {
                    title: null
                    //min: 0
                },

                tooltip: {
                    headerFormat: `<b>${this.props.t('CPR')}</b><br/><span>${this.props.t('Date')}</span>: <b>{point.x:%Y-%m-%d}</b><br/>`,
                    pointFormat: `<span>{series.name}</span>: <b>{point.y:.2f}</b><br/>`,
                    useHTML: true,
                    shared: true
                },

                series: []
            },

            cdrChartOptions: {

                chart: {
                    zoomType: 'x'
                },

                exporting: { enabled: false },

                title: {
                    text: this.props.t('CDRPrc'),
                    style: { "color": "#2F444E", "fontSize": "18px", "fontWeight": "bold" }
                },

                credits: {
                    enabled: false
                },

                xAxis: {
                    type: 'datetime',
                    title: this.props.t('Years')
                },

                yAxis: {
                    title: null
                    //min: 0
                },

                tooltip: {
                    headerFormat: `<b>${this.props.t('CDR')}</b><br/><span>${this.props.t('Date')}</span>: <b>{point.x:%Y-%m-%d}</b><br/>`,
                    pointFormat: `<span>{series.name}</span>: <b>{point.y:.2f}</b><br/>`,
                    useHTML: true,
                    shared: true
                },

                series: []
            }
        };
    }

    componentDidUpdate(prevProps) {

        if (this.props.objectId != prevProps.objectId && this.props.objectId != null) {
            this.props.getChartData(this.props.objectId);
        }

        if (this.props.show !== prevProps.show) {
            this.setState({ visible: this.props.show });
        }

        if (this.props.flowData != prevProps.flowData && this.props.flowData != null) {

            var newCPRChartOptions = { ...this.state.cprChartOptions };

            newCPRChartOptions.series.splice();

            var histCPR = [];
            if(this.props.flowData.historical != null)
            {
                histCPR = this.props.flowData.historical.map(item => { return { x: item.date, y: item.cprRange50 } });
            }

            //var modelCPR05 = this.props.data.model.map(item => { return { x: item.date, y: item.cprRange05 } });
            //var modelCPR25 = this.props.data.model.map(item => { return { x: item.date, y: item.cprRange25 } });
            var modelCPR = this.props.flowData.model.map(item => { return { x: item.date, y: item.cprRange50 } });
            //var modelCPR75 = this.props.data.model.map(item => { return { x: item.date, y: item.cprRange75 } });
            //var modelCPR95 = this.props.data.model.map(item => { return { x: item.date, y: item.cprRange95 } });

            var modelCPR_05_95 = this.props.flowData.model.map(item => { return [item.date, item.cprRange05, item.cprRange95] });
            var modelCPR_25_75 = this.props.flowData.model.map(item => { return [item.date, item.cprRange25, item.cprRange75] });

            newCPRChartOptions.series = [
                {
                    name: this.props.t('CPRHist'),
                    color: style.colorPrimary1_40,
                    showInLegend: true,
                    lineWidth: 1,
                    data: histCPR,
                    marker: {
                        symbol: 'circle',
                        enabled: false
                    },
                    /*
                    data: [].concat(histCPR, modelCPR),
                    zoneAxis: 'x',
                    zones: [{ value: histCPR[histCPR.length - 1].x, color: style.colorPrimary3 }, { color: style.colorPrimary2 }]
                    */
                },
                {
                    name: this.props.t('Level_05_95'),
                    type: 'arearange',
                    color: style.colorPrimary1_20,
                    showInLegend: true,
                    lineWidth: 0,
                    fillOpacity: 0.3,
                    data: modelCPR_05_95,
                    marker: {
                        symbol: 'square',
                        enabled: false
                    }
                },
                {
                    name: this.props.t('Level_25_75'),
                    type: 'arearange',
                    color: style.colorPrimary1_40,
                    showInLegend: true,
                    lineWidth: 0,
                    fillOpacity: 0.3,
                    data: modelCPR_25_75,
                    marker: {
                        symbol: 'triangle',
                        enabled: false
                    }
                },
                /*
                {
                    name: Localization.Level_05,
                    color: style.colorPrimary1_20,
                    showInLegend: true,
                    lineWidth: 1,
                    data: modelCPR05,
                    marker: {
                        symbol: 'square'
                    }
                },
                {
                    name: this.props.t('Level_25'),
                    color: style.colorPrimary1_40,
                    showInLegend: true,
                    lineWidth: 1,
                    data: modelCPR25,
                    marker: {
                        symbol: 'triangle'
                    }
                },
                */
                {
                    name: this.props.t('CPRModel'),
                    color: style.colorPrimary1,
                    showInLegend: true,
                    lineWidth: 1,
                    data: modelCPR,
                    marker: {
                        symbol: 'circle',
                        enabled: false
                    }
                }
                /*
                {
                    name: this.props.t('Level_75'),
                    color: style.colorPrimary1_40,
                    showInLegend: true,
                    lineWidth: 1,
                    data: modelCPR75,
                    marker: {
                        symbol: 'triangle'
                    }
                },
                {
                    name: this.props.t('Level_95'),
                    color: style.colorPrimary1_20,
                    showInLegend: true,
                    lineWidth: 1,
                    data: modelCPR95,
                    marker: {
                        symbol: 'square'
                    }
                },
                */
            ];

            //////////////////////////////////////////////////////////////////////////////
            var newCDRChartOptions = { ...this.state.cdrChartOptions };

            newCDRChartOptions.series.splice();

            var histCDR = [];
            if(this.props.flowData.historical != null)
            {
                histCDR = this.props.flowData.historical.map(item => { return { x: item.date, y: item.cdrRange50 } });
            }

            //var modelCDR05 = this.props.data.model.map(item => { return { x: item.date, y: item.cdrRange05 } });
            //var modelCDR25 = this.props.data.model.map(item => { return { x: item.date, y: item.cdrRange25 } });
            var modelCDR = this.props.flowData.model.map(item => { return { x: item.date, y: item.cdrRange50 } });
            //var modelCDR75 = this.props.data.model.map(item => { return { x: item.date, y: item.cdrRange75 } });
            //var modelCDR95 = this.props.data.model.map(item => { return { x: item.date, y: item.cdrRange95 } });

            var modelCDR_05_95 = this.props.flowData.model.map(item => { return [item.date, item.cdrRange05, item.cdrRange95] });
            var modelCDR_25_75 = this.props.flowData.model.map(item => { return [item.date, item.cdrRange25, item.cdrRange75] });

            newCDRChartOptions.series = [
                {
                    name: this.props.t('CDRHist'),
                    color: style.colorPrimary1_40,
                    showInLegend: true,
                    lineWidth: 1,
                    data: histCDR,
                    marker: {
                        symbol: 'circle',
                        enabled: false
                    },
                    /*
                    data: [].concat(histCPR, modelCPR),
                    zoneAxis: 'x',
                    zones: [{ value: histCPR[histCPR.length - 1].x, color: style.colorPrimary3 }, { color: style.colorPrimary2 }]
                    */
                },
                {
                    name: this.props.t('Level_05_95'),
                    type: 'arearange',
                    color: style.colorPrimary1_20,
                    showInLegend: true,
                    lineWidth: 0,
                    fillOpacity: 0.3,
                    data: modelCDR_05_95,
                    marker: {
                        symbol: 'square',
                        enabled: false
                    }
                },
                {
                    name: this.props.t('Level_25_75'),
                    type: 'arearange',
                    color: style.colorPrimary1_40,
                    showInLegend: true,
                    lineWidth: 0,
                    fillOpacity: 0.3,
                    data: modelCDR_25_75,
                    marker: {
                        symbol: 'triangle',
                        enabled: false
                    }
                },
                /*
                {
                    name: Localization.Level_05,
                    color: style.colorPrimary1_20,
                    showInLegend: true,
                    lineWidth: 1,
                    data: modelCDR05,
                    marker: {
                        symbol: 'square'
                    }
                },
                {
                    name: this.props.t('Level_25'),
                    color: style.colorPrimary1_40,
                    showInLegend: true,
                    lineWidth: 1,
                    data: modelCDR25,
                    marker: {
                        symbol: 'triangle'
                    }
                },
                */
                {
                    name: this.props.t('CDRModel'),
                    color: style.colorPrimary1,
                    showInLegend: true,
                    lineWidth: 1,
                    data: modelCDR,
                    marker: {
                        symbol: 'circle',
                        enabled: false
                    }
                }
                /*
                {
                    name: this.props.t('Level_75'),
                    color: style.colorPrimary1_40,
                    showInLegend: true,
                    lineWidth: 1,
                    data: modelCDR75,
                    marker: {
                        symbol: 'triangle'
                    }
                },
                {
                    name: this.props.t('Level_95'),
                    color: style.colorPrimary1_20,
                    showInLegend: true,
                    lineWidth: 1,
                    data: modelCDR95,
                    marker: {
                        symbol: 'square'
                    }
                },
                */
            ];

            this.setState({ cprChartOptions: newCPRChartOptions, cdrChartOptions: newCDRChartOptions, title: this.props.flowData.isin + ' (' + this.props.flowData.name + ')' });
        }
    }

    onWindowClose = (event) => {

        if (this.props.onClose)
            this.props.onClose(event, false);
    }

    render() {

        const { t } = this.props;

        return (
            <Dialog maxWidth="md" fullWidth onClose={this.onWindowClose} aria-labelledby="charts-dialog-title" open={this.state.visible}>
                <MuiDialogTitle disableTypography id="charts-dialog-title" onClose={this.onWindowClose}>
                    <h4>{this.state.title}</h4>
                </MuiDialogTitle>
                <MuiDialogContent dividers>
                    <div>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={this.state.cprChartOptions}
                        />
                    </div>
                    <div>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={this.state.cdrChartOptions}
                        />
                    </div>

                </MuiDialogContent>
                <MuiDialogActions>

                    <Button variant="contained" color="secondary" onClick={this.onWindowClose}>
                        {t('Close')}
                    </Button>
                </MuiDialogActions>
            </Dialog>
        );
    }
}

var component = connect(
    state => state.domrf, // Selects which state properties are merged into the component's props
    DomRfStore.actionCreators // Selects which action creators are merged into the component's props
)(ChartsDialog);

export default withTranslation('translations')(component);