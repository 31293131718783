import React, { useEffect } from 'react'
import style from '@Styles/domrf-theme.module.scss'
import useLocalStorage from '@Base/components/hook/useLocalStorage'
import { Box, Tooltip } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { actionCreators as actionDomRf } from '@Base/store/DomRfStore'
import { useDispatch } from 'react-redux'
import { t } from 'i18next'

const useStyles = makeStyles((theme) => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
        overflow: 'hidden',
        position: 'relative',
        marginLeft: '0.5rem',
    },
    item: {
        cursor: 'pointer',
        height: '1.7rem',
        userSelect: 'none',
        '&:not(:first-child)': {
            marginLeft: '1rem',
        }
    },
    selected: {
        color: style.colorPrimary2,
        position: 'relative',
        cursor: 'default',
        '&:after': {
            content: '""',
            position: 'absolute',
            left: 0, right: 0, bottom: 0, height: '2px',
            borderBottom: `solid 2px ${style.colorPrimary2}`
        }
    }
}))

const CompareDateSelector = () => {
    const cls = useStyles()
    const dispatch = useDispatch()
    const [currentDateRange, setCurrentDateRange] = useLocalStorage(7, 'quotes.dateSelector.value')

    const dateRangeVariants = [{ value: 7, name: '1Н', tooltip: 'quotes.oneWeek' },
    { value: 30, name: '30Д', tooltip: 'quotes.30Days' },
    { value: 90, name: '90Д', tooltip: 'quotes.90Days' },
    { value: 365, name: '1Г', tooltip: 'quotes.oneYear' },
    { value: 1825, name: '5Л', tooltip: 'quotes.fiveYears' }]

    const handleChangeDareRange = (value) => {
        if (value == null || value == currentDateRange)
            return
        setCurrentDateRange(value)
    }

    useEffect(() => {
        dispatch(actionDomRf.setData({ currentDateRange: currentDateRange }))
    }, [currentDateRange])

    return (
        <Box className={cls.root}>
            {dateRangeVariants?.map(range => <Box key={`dateRange_${range.value}`}
                className={[cls.item, currentDateRange == range.value ? cls.selected : '']}
                onClick={() => handleChangeDareRange(range.value)}>
                <Tooltip title={t(range.tooltip)}>
                    {range.name}
                </Tooltip>
            </Box>)
            }
        </Box >
    )
}

export default CompareDateSelector