import { alpha, Box, Divider, Paper, Stack, Switch, Theme, Typography } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import style from '@Styles/domrf-theme.module.scss'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import CustomTooltip from '../../../../components/shared/CustomTooltip'
import SpinnerBars from '../../../../components/shared/SpinnerBars'
import { actionCreators as actionSeciritization } from '../../../../store/SecuritizationStore'
import { humanReadableProducts } from '@Base/Utilities'

const useStyles = makeStyles((theme: Theme) => createStyles({
    info: {
        display: 'flex',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
        alignItems: 'center',
        textAlign: 'center',
        fontSize: '1.2rem',
        color: style.colorPrimary3_40
    },
    'capitalize': {
        textTransform: 'capitalize'
    },
    line: {
        lineHeight: '2.1rem',
        flexDirection: 'row',
        position: 'relative',
        paddingLeft: '8px',
        paddingRight: '8px',
        justifyContent: 'space-between',
        alignItems: 'center',
        '&:hover': {
            backgroundColor: alpha(style.colorPrimary2, 0.17),
            '& $percentsBar': {
                borderColor: style.colorPrimary2_80,
                backgroundColor: alpha(style.colorPrimary2, 0.17),
            }
        },

    },
    switch: {
        '& .MuiSwitch-switchBase .MuiSwitch-thumb': {
            color: 'rgb(245,245,245)',
            border: `solid 1px rgb(189,189,189)`,

        },
        '& .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb': {
            color: 'rgb(197,226,158)',
            border: `solid 1px rgb(139,197,64)`,

        },
        '& .MuiSwitch-switchBase.Mui-focusVisible': {
            color: `${alpha(style.colorPrimary3, 1)}`,
            // padding:'5px',
            // paddingRight:0,
            // paddingBottom:0,
        },
        '& .MuiSwitch-switchBase + .MuiSwitch-track': {
            backgroundColor: 'rgb(255,255,255)',
            border: `solid 1px rgb(189,189,189)`,
        },
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
            backgroundColor: 'rgb(242,248,232)',
            border: `solid 1px  rgb(139,197,64)`
        },
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'baseline',
        '&>*:not(:first-child)': {
            marginLeft: '0.5rem',
        }
    },
    columnHeader: {
        textTransform: 'uppercase',
        lineHeight: '2rem',
        maxHeight: '2rem',
        overflow: 'hidden',
        marginLeft: 1,
        flexGrow: 1,
        fontSize: '14px',
        color: style.colorPrimary3_40
    },
    tableDataSign: {
        color: style.colorPrimary3_40,
        fontSize: '0.9rem',
        display: 'inline-block',
        marginLeft: '4px',
    },
    percentsBar: {
        position: 'absolute',
        left: 0, height: '8px',
        bottom: '4px',
        backgroundColor: style.colorPrimary3_5,
        border: `solid 1px ${style.colorPrimary3_60}`
    },
    scrollablePanel: {
        position: 'absolute', left: 0, right: 0, top: 0, bottom: 0,
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: '4px',
        },
        '&:hover': {
            '&::-webkit-scrollbar-track': {
                backgroundColor: '#00000008',
            },
            '&::-webkit-scrollbar-thumb': {
                background: '#00000018',
            }
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'unset',
            borderRadius: '3px',
            // border: 'solid 1px #00000000',
            backgroundColor: '#00000000',
            transition: 'all 0.3s ease'
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#0000',
            borderRadius: '3px',
            height: '4px'
        },
    },
    wrappedText: {
        fontSize: '14px',
        color: style.colorPrimary3_60
    },
    grayscaled: {
        color: style.colorPrimary3_60
    }
}))

function ProductsDistribution({ poolId = null }) {
    const classes = useStyles()
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { productDistribution = null } = useSelector((state: any) => state.securitization)
    const [isInnerProduct, setIsInnerProduct] = useState(false)
    const [innerProductsPresent, setInnerProductsPresent] = useState(false)
    useEffect(() => {
        if (poolId != null)
            actionSeciritization.getPoolProductDistribution(poolId)(dispatch)
    }, [poolId])

    useEffect(() => {
        const state = productDistribution?.distribution?.some(product => product?.product != null && Boolean(product.isInnerProduct) == true)
        setInnerProductsPresent(state)
        if (!state) setIsInnerProduct(false)
    }, [productDistribution])

    // console.log('productDistribution', productDistribution)

    // const formatProductTitle = ({ name, type }) => {

    //     // const Capitalize = ({ name }) => <span className={classes.capitalize}>{name}</span>

    //     if (name == null) return null

    //     // switch (type) {
    //     //     case 'республика':
    //     //         let typeStr = type[0].toUpperCase() + type.slice(1)
    //     //         return (<>{typeStr}&nbsp;<Capitalize name={name} /></>)

    //     //     case 'область':
    //     //     case 'край':
    //     //         return (<><Capitalize name={name} />&nbsp;{type}</>)

    //     //     default:
    //     //         return (<><Capitalize name={name} /></>)
    //     // }
    // }

    const ProductsOutput = ({ products = [] }) => {
        if (!products?.length)
            return <></>

        const LineOutput = (id, name, percents, wac) =>
            <Stack key={`product_${id}`} className={classes.line}>
                <Box sx={{ zIndex: +1, width: '1.5rem !important', minWidth: '2rem !important', textAlign: 'right', whiteSpace: 'nowrap' }}>
                    {parseFloat(percents)?.toFixed(0)}<span className={classes.tableDataSign}>%</span>
                </Box>
                <Typography component='div' sx={{ ml: 1.5, height: '2rem', position: 'relative', flexGrow: 1, fontSize: '0.9rem' }}>
                    <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                        <Box sx={{ right: `${100 - parseFloat(percents)}%` }} className={classes.percentsBar} />
                        <CustomTooltip
                            id={`${id}-field-id`}
                            content={`${percents?.toFixed(2)}% - ${name}`}
                            hideIcon
                            component='span'
                            placement="left-start"
                        >
                            {name}
                        </CustomTooltip>
                    </Box>
                </Typography>
                <Box sx={{ zIndex: +1, width: '4rem !important', minWidth: '4rem !important', textAlign: 'right', whiteSpace: 'nowrap' }}>
                    {parseFloat(wac)?.toFixed(1)}
                    <span className={classes.tableDataSign}>%</span>
                </Box>
            </Stack>

        let otherProducts: any = null
        let unknownProducts: any = null
        let knowProductsCount = 0

        return (
            <>
                {products
                    .filter(item => item.isInnerProduct == isInnerProduct)
                    .sort((a, b) => parseFloat(a?.percents) > parseFloat(b?.percents) ? -1 : parseFloat(a?.percents) < parseFloat(b?.percents) ? 1 : 0)
                    .map((item, index) => {
                        let name = item?.product
                        //formatProductTitle(item)
                        const itemPercents = parseFloat(item.percents)
                        const itemWac = parseFloat(item.wac)

                        if (name == null) {
                            const percents = parseFloat(unknownProducts?.percents)
                            const fraction_sum = parseFloat(unknownProducts?.wac_fraction_sum)
                            const count = parseInt(unknownProducts?.count)
                            unknownProducts = {
                                percents: (!isNaN(percents) ? percents : 0) + (!isNaN(itemPercents) ? itemPercents : 0),
                                wac_fraction_sum: (!isNaN(fraction_sum) ? fraction_sum : 0) + ((!isNaN(itemWac) && !isNaN(itemPercents)) ? itemWac * itemPercents : 0),
                                count: (!isNaN(count) ? count + 1 : 1)
                            }
                        }
                        else {
                            knowProductsCount++
                            return LineOutput(index, isInnerProduct ? t(humanReadableProducts?.get(parseInt(item.product))) : name, item.percents, item.wac)
                        }
                    })}
                {
                    knowProductsCount == 0 ?
                        <Box className={classes.info}>{t("ProductDistributionLoadingFail")}</Box> :
                        <>{
                            unknownProducts != null &&
                            LineOutput('unknown_products', t("Unknown"), unknownProducts.percents?.toFixed(0) == 0 ? 1 : unknownProducts.percents, (unknownProducts.wac_fraction_sum / unknownProducts.percents)?.toFixed(2))
                        }</>
                }
            </>)
    }

    return (
        <Paper sx={{
            // position: 'absolute', left: 0, top: 0, right: 0, bottom: 0,
            ml: '2px', mt: 1, mb: '2px', mr: '4px',
            flexGrow: 1,
            display: 'flex', flexDirection: 'column',
            boxShadow: 'none',
            minHeight:'120px'
        }}>
            <Stack sx={{ flexShrink: 1 }}>
                <Box sx={{
                    display: 'flex', pl: 1,
                    flexWrap: 'nowrap',
                    flexDirection: 'row',
                    alignContent: 'center',
                    alignItems: 'center',
                    height: '30px !important', overflow: 'hidden'
                }}
                >
                    <Box className={classes.header}>
                        <Typography className={classes.columnHeader} >
                            {t("PoolProductsDistribution")}
                        </Typography>
                        {innerProductsPresent &&
                            <Switch
                                className={classes.switch}
                                size="small"
                                checked={isInnerProduct}
                                onChange={() => setIsInnerProduct(!isInnerProduct)}
                            />}
                    </Box>
                    <Typography className={classes.columnHeader} sx={{ textAlign: 'right', pr: 3 }}>
                        {t("WAC")}
                    </Typography>
                </Box>
                <Divider />
            </Stack>
            <Stack sx={{
                position: 'relative', flexGrow: 1,
                ...Boolean(productDistribution?.distribution?.length > 8)
                    ? { flexBasis: '310px' }
                    : { ...Boolean(productDistribution?.distribution?.length) ? { flexBasis: `${productDistribution.distribution.length * 35}px` } : { flexBasis: '200px' } }
            }}>
                {/* {console.log('productDistribution', productDistribution)} */}
                {!(productDistribution?.distribution?.length && productDistribution.poolId == poolId) ?
                    <><SpinnerBars withBackground />
                        {/* <Box className={classes.info}>{t("ProductDistribution")}</Box> */}
                    </>
                    :
                    <>
                        <Stack sx={{ position: 'relative', flexGrow: 1, }}>
                            <Box className={classes.scrollablePanel}>
                                <ProductsOutput products={productDistribution.distribution} />
                            </Box>
                        </Stack>
                    </>

                }
            </Stack>
        </Paper>

    )
}

export default ProductsDistribution