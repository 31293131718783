import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import { useTranslation } from "react-i18next";
import DateFnsUtils from '@date-io/date-fns';
import ChipAuthor from '@Components/shared/ChipAuthor';
import { CouponType, efNodeValidationState } from '@Components/Definitions';
import stringInject from 'stringinject';
import { valueOrDefault, getNameAbbreviation } from '@Utilities'
import Localization from '@Components/Localization';
import style from '@Styles/domrf-theme.module.scss';
import UniversalInputField, { InputDataType } from "@Framework/components/Form/UniversalInputField";
import CustomTooltip from '../../../../components/shared/CustomTooltip';
import Globals from '../../../../Globals';
import { alpha, Theme } from '@mui/material/styles';
import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { ReactComponent as ScurveIcon } from "../../../../images/s-curve.svg"
import { SsidChart, Restore as RestoreIcon, WarningAmber } from '@mui/icons-material'
import { actionCreators as actionSecuritization } from '../../../../store/SecuritizationStore'
import { useDispatch, useSelector } from 'react-redux';
import ZCYCDialog from './ZCYCDialog';
import { ValidatorForm } from '@Base/framework/components/Form';
import { debounce } from 'throttle-debounce';

const useStyles = makeStyles((theme: Theme) => createStyles({
    tooltip: {
        marginLeft: '0.5rem',
        paddingBottom: '4px'
    },
    iconButton: {
        width: 30,
        height: 30,
        margin: 0,
        padding: 0
    },
    dataAlertBlock: {
        position: 'relative',
        flexGrow: 1,
        justifyContent: 'center',
        '& *': {
            color: '#ff6e6e'
        }
    },
    icon: {
        width: '40px',
        height: '40px',
    },
    resetButton: {
        color: style.colorPrimary1,
        position: 'absolute',
        right: '5px',
        top: 0,
    },
    buttonsBlock: {
        alignSelf: 'center',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignItems: 'center',
        marginRight: '0.5rem',
    },
    infoField: {
        position: 'relative',
        paddingRight: '35px'
        // right: '35px'
    },
    disabled: {
        userSelect: 'none',
        color: `${style.colorPrimary3_120} !important`,
        '& .MuiInput-underline': {
            '&:before': {
                borderBottom: 'none !important',
            }
        }
    },
    changed: {
        backgroundColor: /*`rgb(248,248,250)`*/`#f2f8e8`,
    },
    error: {
        backgroundColor: /*`rgb(255,240,240)`*/`#f2f8e8`,
    },
    Block: {
        display: 'flex',
        flexDirection: 'column',
        '& *': {
            fontSize: '1rem',
        },
        // marginLeft: '8px',
        marginRight: 0,
        // marginBottom:'33px',
        // '&>$rowBlock:nth-child(even)': {
        //     backgroundColor: `${alpha(style.colorPrimary3, 0.07)}`,
        // }
        // '&>$rowBlock:nth-child(n)': {
        //     boxShadow: '1px 1px #00000015',
        // },
    },
    blockName: {
        color: `${style.colorPrimary3_40}`,
        textTransform: 'uppercase',
        boxShadow: `0px 1px ${alpha(style.colorPrimary3, 0.7)}`,
        // borderBottom: `1px solid ${alpha(style.colorPrimary3, 1)}`,
        padding: '0',
        margin: '4px 0',
        // lineHeight:'2rem',
        // fontSize:'0.8rem'
    },
    rowBlock: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'baseline',
        lineHeight: '2rem',
        height: '2rem !important',
    },
    rowName: {
        flexShrink: 1,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        lineHeight: '1rem',
    },
    rowValuesBlock: {
        display: 'flex',
        flexWrap: 'nowrap',
        textAlign: 'right',
        justifyContent: 'flex-end',
        flexGrow: 1,
        // lineHeight: '32px',
        alignItems: 'baseline',
    },
    valueData: {
        width: '117px !important',
        whiteSpace: 'nowrap',
        height: '32px',
        '& svg': {
            height: '19px',
            width: '19px',
            padding: 0,
        },
        '& .MuiInputAdornment-root': {
        },
        '& .MuiInputAdornment-root svg': {
            margin: '6px',
        },
        '& input': {
            '&::selection': {
                backgroundColor: `rgb(200,232,250)`
            },
            textAlign: 'right',
            paddingRight: '0.5rem',
            paddingLeft: '0.5rem',
        }
    },
    valueUnit: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        width: '3rem !important',
        fontSize: '0.8rem',
        paddingLeft: '0.3rem',
        color: `${alpha(style.colorPrimary3, 0.5)}`,
        textAlign: 'left',
    },
    valueAuthor: {
        display: 'flex',
        width: '25px !important',
        textAlign: 'right',
        alignSelf: 'center',
        '& *': {
            fontSize: '0.8rem',
        },
    },
    container: {
        flex: '1 1 auto',
        margin: '0 5px 0 0',
        maxWidth: '472px',
        position: 'relative',
        [theme.breakpoints.down('xxl')]: {
            margin: '0 auto',
        }
    },
    subheader: {
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
        textTransform: 'uppercase',
        color: `${alpha(style.colorPrimary3, 0.6)}`,
        backgroundColor: '#FFFFFF',
        borderBottom: `1px solid ${alpha(style.colorPrimary3, 0.6)}`
    }
}));

// interface IProps {
//     updatedFields: any,
//     deal: any,
//     pool: any,
//     iteration: any,
//     isEdit: any,
//     onFieldUpdate: any,
//     // onFieldLeft?: any
// }

const PoolParametersPanel = ({ newPoolReceived = false } /*{ pool, iteration, isEdit, onFieldUpdate  }: IProps*/) => {
    const { deal = null, selectedNode = null, backupNode = null, backupDeal = null, SCurveSPR, ZCYCDate, proceededIteration, permissions } = useSelector((state: any) => state.securitization)
    const { onlineIterationChanges } = (selectedNode || {});
    const updatedFields = useRef({})
    const submitForm = useRef(null)
    const { validationState: nodeValidationState = null } = (selectedNode?.node || {})

    // console.log('selectedNode------------------!', selectedNode)

    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch()
    ///states
    const [zcycDate, setZCYCDate] = useState(selectedNode?.node?.zcycDate || '');
    const [updateZCYC, setUpdateZCYC] = useState(false)
    const [newZCYCDate, setNewZCYCDate] = useState(null);
    // const dateUtils = new DateFnsUtils();
    // const [maxZCYCDate, setMaxZCYCDate] = useState(getDate(dateUtils.addDays(new Date(), 0)));
    // const [minZCYCDate, setMinZCYCDate] = useState(new Date('2014-01-07'));

    const [cpr, setCPR] = useState(valueOrDefault(selectedNode?.node?.cpr, ''));
    const [updateCPR, setUpdateCPR] = useState(false)
    const [cdr, setCDR] = useState(valueOrDefault(selectedNode?.node?.cdr, ''));
    const [zSpread, setZSpread] = useState(valueOrDefault(selectedNode?.node?.zSpread, ''));
    const [guaranteeRate, setGuaranteeRate] = useState(valueOrDefault(selectedNode?.node?.guaranteeRate, '')) //GuaranteeRate	Тариф поручительства, % год.
    const [requiredIncrement, setRequiredIncrement] = useState(valueOrDefault(selectedNode?.node?.requiredIncrement || ''));
    const [useMinimalWAC, setUseMinimalWAC] = useState(valueOrDefault(selectedNode?.node?.useMinimalWAC || false));

    const [showZCYCChart, setShowZCYCChart] = useState(false)
    const [requiredIncrementVisible, setRequiredIncrementVisible] = useState(false);

    // const [currentIterationDate, setCurrentIterationDate] = useState()
    const [currentIterationDateTime, setCurrentIterationDateTime] = useState('')
    const [currentIterationAuthor, setCurrentIterationAuthor] = useState(null)
    const [currentIterationPoolId, setCurrentIterationPoolId] = useState('')
    // const [currentIterationPoolDate, setCurrentIterationPoolDate] = useState()

    const [useMinimalWACVisible, setUseMinimalWACVisible] = useState(false)

    const [dealParametersChanged, setDealParametersChanged] = useState(false)
    const [nodeParametersChanged, setNodeParametersChanged] = useState(false)

    const currentDate = new Date()
    const dateLimits = {
        minDate: new Date(2014, 1, 9),
        maxDate: new Date(currentDate.getFullYear() + 15, currentDate.getMonth(), currentDate.getDate()), //+15 лет к тукущей дате
    }

    // useEffect(() => {
    //     Object.keys(updatedFields).map((key) => {

    //         const field = fields.find(x => x.name == key);
    //         if (field.value != updatedFields[key].Value) {
    //             console.log('field', field, key, updatedFields[key].Value)
    //             updateField(key, field.updateValue, updatedFields[key].Value)
    //         }
    //     })
    // }, [updatedFields])


    // console.log('updatedFields********************', Object.keys(updatedFields?.current??{}).length>0)
    const checkIterationOnlineChanges = () => {
        // console.log('backupNode', backupNode)

        if (onlineIterationChanges != null) {

            updatedFields.current = {};

            Object.entries(onlineIterationChanges).forEach(entry => {

                if (entry[1] === null) {
                    return;
                }
                //if (entry[1]?.newValue != null)
                if (backupNode[entry[0]] != entry[1].newValue) {
                    // console.log('entry[1].newValue', entry[1].newValue)
                    updatedFields.current[entry[0]] = { ...entry[1], newValue: entry[1]?.newValue ?? '' }//, oldValue: deal.deal[entry[0]] }
                }
            });
        }
        else {
            updatedFields.current = {}
        }
    }

    const updateIterationByOnlineContents = (nodeData) => {

        var isChanged = false

        if (nodeData == null)
            return isChanged

        if (onlineIterationChanges == null)
            return isChanged

        Object.entries(onlineIterationChanges).forEach(entry => {
            // console.log(`changing name = ${entry[0]} value = ${entry[1].newValue}`, entry, localIteration)
            if (entry[1] == null)
                return


            //if (entry[1].oldValue !== entry[1].newValue) {
            nodeData[entry[0]] = entry[1].newValue
            isChanged = true
            //}
        })


        // console.log('nodeData!!!!!!!!!!!!!!!!!!!!!!!!', nodeData)
        return isChanged;
    }

    useEffect(() => {
        checkIterationOnlineChanges()

        // console.log('onlineIterationChanges', onlineIterationChanges)
        // console.log('selectedNode', selectedNode)
        if (selectedNode?.onlineIterationChanges != null) {
            var nodeData = { ...selectedNode.node }

            // console.log('++++++++++++++++nodeData', nodeData)
            updateIterationByOnlineContents(nodeData);
            // console.log('----------------nodeData', nodeData)
            // console.log('{...selectedNode, node:nodeData}', { ...selectedNode, node: nodeData })
            actionSecuritization.setNode({ ...selectedNode, node: nodeData })(dispatch)

        }
    }, [onlineIterationChanges])

    useEffect(() => {
        if (proceededIteration != null) {
            updatedFields.current = {}
        }
    }, [proceededIteration])

    useLayoutEffect(() => {
        // useEffect(() => {
        // console.log('selectedNode', selectedNode)

        // setCurrentIterationDate(Localization.FormatDateStringLocale(selectedNode?.pools?.length? selectedNode?.node?.pools[0].createdDate))

        setNewZCYCDate(null)
        setUpdateZCYC(true)
        setZCYCDate(selectedNode?.node?.zcycDate || '')
        setCPR(valueOrDefault(selectedNode?.node?.cpr, ''))
        setCDR(valueOrDefault(selectedNode?.node?.cdr, ''))
        // setCPR(valueOrDefault(selectedNode?.node?.cpr || '', null))
        // setCDR(valueOrDefault(selectedNode?.node?.cdr || '', null))
        setZSpread(valueOrDefault(selectedNode?.node?.zSpread, ''))
        setGuaranteeRate(valueOrDefault(selectedNode?.node?.guaranteeRate, ''));
        setRequiredIncrement(valueOrDefault(selectedNode?.node?.requiredIncrement || ''))
        setUseMinimalWAC(valueOrDefault(selectedNode?.node?.useMinimalWAC || false))

        // console.log('deal?.deal?.couponType', deal?.deal?.couponType, deal)
        setRequiredIncrementVisible(deal?.deal?.couponType == CouponType.TYPE_KEYRATE_AND_PREMIUM || false)

        setCurrentIterationDateTime(Localization.FormatDateTimeStringLocale(selectedNode?.lastIteration?.createdDate || ''))
        setCurrentIterationAuthor(getNameAbbreviation(selectedNode?.lastIteration?.user?.userName || ''))
        // setCurrentIterationPoolDate(Localization.FormatDateStringLocale(selectedNode?.lastIteration?.poolDate || null))
        setCurrentIterationPoolId(selectedNode?.lastIteration?.poolID || '')

        // console.log('selectedNode?.lastIteration', selectedNode?.lastIteration)
        //visibility
        if (selectedNode?.node?.pools != null && selectedNode.node.pools.length > 0) {
            setUseMinimalWACVisible(selectedNode.node.pools[0].minimalWAC > 0);
        }
        else
            setUseMinimalWACVisible(false);

    }, [selectedNode, deal.deal]);

    // const CheckDateLimits = (value) => {
    //     // console.log('value', value)
    //     return (
    //         (dateLimits.minDate != null && new Date(dateLimits.minDate)?.getTime() < new Date(value)?.getTime())
    //         &&
    //         (dateLimits.maxDate != null && new Date(dateLimits.maxDate)?.getTime() > new Date(value)?.getTime())
    //     )
    // }
    const validateFormFields = (fields) => {

        if (fields.cpr === '' || fields.cdr === '') {
            return null;
        }

        var isSumCorrect = (parseFloat(fields.cpr.toString()) + parseFloat(fields.cdr.toString())) <= 100;
        if (!isSumCorrect)
            return [{ name: 'cpr', message: t('CPRCDRSumMustBeLessOf100') }, { name: 'cdr', message: t('CPRCDRSumMustBeLessOf100') }];

        return null;
    }

    // console.log('selectedNode.node.validationState', selectedNode.node.validationState)
    const onFormStateChanged = (isValid: boolean) => {
        // console.log('-------onFormStateChanged', selectedNode, isValid)
        // console.log('updatedFields', updatedFields)

        //actionSecuritization.setNodeDataParametr("isNodeParametersValid", isValid)(dispatch)

        if (selectedNode == null)
            return;


        var state = selectedNode.node.validationState;
        // console.log('state!!!!!!!!!!!!!!', state)
        if (isValid) {
            state &= ~efNodeValidationState.STATE_NODE_PARAMETERS_INVALID;
        }
        else {
            state |= efNodeValidationState.STATE_NODE_PARAMETERS_INVALID;
        }

        //notify if state changed only
        if (state != selectedNode.node.validationState) {

            var update = {
                ID: selectedNode.node.id, ValidationState: { Value: state }
            };

            //console.log(`===onFormStateChanged pool state changed: ${state}`);

            //force to set state locally
            actionSecuritization.setNodeData("validationState", state)(dispatch);
            //update & notify others
            //CHECKME!!! temporary. no need i assume
            //actionSecuritization.updateNode(update)(dispatch);
        }
    }

    const onFieldChanged = (name: string, value: any, isValid: boolean) => {
        //проверка валидных полей формы на соблюдение условий
        if (!isValid) {
            // console.log('value', value)
            return; //non-valid is not checking
        }
    }

    useEffect(() => {
        if (SCurveSPR?.value != null && updateCPR) {
            // updateField('cpr', setCPR, Math.round(SCurveSPR.value * 100) / 100)
            updateFields({ ['cpr']: { Value: Math.round(SCurveSPR.value * 100) / 100 } })
            setUpdateCPR(false)
        }
    }, [SCurveSPR])

    useEffect(() => {
        setDealParametersChanged(checkDealUpdates())
        setNodeParametersChanged(checkNodeUpdates())
    })


    const checkDealUpdates = () => {
        // console.log('deal?.onlineDealChanges', deal?.onlineDealChanges)
        if (deal?.onlineDealChanges != null) {
            return !Object.entries(deal.onlineDealChanges).every(([key, value]) => {
                if (value != null && backupDeal[key] != value?.newValue) { //CHECKME!!! need a normal comparsion
                    return false
                }

                return true
            })
        }

        return false
    }

    const checkNodeUpdates = () => {
        if (selectedNode?.onlineIterationChanges != null) {
            // console.log('selectedNode?.onlineIterationChanges', selectedNode?.onlineIterationChanges, backupNode)

            return !Object.entries(selectedNode.onlineIterationChanges).every(([key, value]) => {
                if (value != null && backupNode[key] != value?.newValue) { //CHECKME!!! need a normal comparsion
                    return false
                }

                return true
            })
        }

        return false
    }

    const handleGetSCurveCPR = () => {
        if (Array.isArray(selectedNode?.node?.pools) && selectedNode?.node?.pools.length && selectedNode?.node?.pools[0].currentWAC != null) {
            actionSecuritization.getScurveCPR(Localization.FormatDateISO(currentDate), selectedNode?.node?.pools[0].currentWAC)(dispatch)
            setUpdateCPR(true)
        }
    }

    const cprStartAdorment = () => {
        if (!(permissions?.isAdmin || permissions?.isManager))
            return

        {/*<InputAdornment position="start">*/ }
        return (<Tooltip
            disableFocusListener
            title={t('UseSCurveCPR')}
            disableInteractive
        >
            <IconButton
                className={classes.iconButton}
                onClick={handleGetSCurveCPR}
                size="small"
            // tabIndex="-1"
            >
                <ScurveIcon style={{ width: '13px', height: '13px', margin: 0, padding: 0 }} />
            </IconButton>
        </Tooltip>
        )
        {/*</InputAdornment>*/ }

    }

    const zcycMinDate = new Date(2014, 1, 6)

    // Максимальная дата Календаря определяется по следующему алгоритму:
    // если текущее время между 00:00 и 19:10, то максимальная доступная дата - вчера
    // если иначе, то максимальная доступная дата - сегодня. то есть "сегодня" только когда между 19:10 и 23:59 включительно
    const zcycMaxDate = new Date();
    if (zcycMaxDate.getTime() <= new Date(Date.now()).setHours(19, 10)) {//19:10
        zcycMaxDate.setHours(0, 0, -1)
    }

    // const checkDates = (day) => {
    //     const curTime = new Date(day).getTime()
    //     const minTime = zcycMinDate.getTime()
    //     const maxTime = maxDate.getTime()
    //     return !(curTime >= minTime && curTime < maxTime)
    // }

    const ZCYCLabel = () => {
        return <Box className={classes.buttonsBlock}>
            {(zcycDate != null && zcycDate != '') &&
                <Tooltip
                    disableFocusListener
                    title={t('TooltipConventionCompareCurvesButton')}
                    disableInteractive
                >
                    <IconButton size="small" onClick={(e) => { /*e.stopPropagation();*/ setShowZCYCChart(true) }}
                    // tabIndex="-1"
                    >
                        <SsidChart style={{ width: '20px', height: '20px', margin: 0, padding: 0 }} />
                    </IconButton>
                </Tooltip>
            }
        </Box>
    }

    useEffect(() => {
        // console.log('---------------------------------------newZCYCDate', newZCYCDate)
        if (newZCYCDate != null) {
            actionSecuritization.getZCYCDate(Localization.FormatDateTimeISO(newZCYCDate))(dispatch)
            setZCYCDate(newZCYCDate)
            setUpdateZCYC(true)
        }
    }, [newZCYCDate])

    useEffect(() => {
        // console.log('--------------------*****************ZCYCDate', ZCYCDate)
        // ZCYCDate: {requertedDate: date, resultDate: result.value}
        if (ZCYCDate?.resultDate == null || ZCYCDate.requertedDate == null || updateZCYC == false)
            return

        if (new Date(ZCYCDate.resultDate).setHours(0, 0, 0) != new Date(ZCYCDate.requstedDate).setHours(0, 0, 0)) {
            updateFields({ ['zcycDate']: { Value: ZCYCDate.resultDate } })
        }
    }, [ZCYCDate])

    const pricingFields = [

        {
            name: 'MarketParametersPanel', label: t('MarketParameters'), fields: [
                { name: 'zcycDate', label: t('ZCYCDate'), /*startAdornment:*/ labelButton: ZCYCLabel(), tooltip: t('ZCYCDate_tooltip'), value: zcycDate, updateValue: setNewZCYCDate /*setZCYCDate*/, badgeContent: onlineIterationChanges?.zcycDate, dataType: InputDataType.DATE, customProps: { allowKeyboardControl: false, disableCloseOnSelect: false, maxDate: zcycMaxDate, minDate: zcycMinDate, format: Localization.ShortDatePattern() }, validators: { validators: ['required'/*, CheckDateLimits*/], errorMessages: [t('FieldRequired')/*, '*Date is out of range*'*/] } },

                { name: 'zSpread', unit: "BPS", label: t('RequiredZSpread'), tooltip: t('RequiredZSpread_tooltip'), value: zSpread, /*updateValue: setZSpread,*/ badgeContent: onlineIterationChanges?.zSpread, dataType: InputDataType.INT, validators: { validators: ['required', 'minFloat:0.0', 'maxFloat:500.0'], errorMessages: [t('FieldRequired'), stringInject(t('ValueMustBeBetweenTwo_Params'), [0, 500.0]), stringInject(t('ValueMustBeBetweenTwo_Params'), [0, 500.0])] } },

                { name: 'cpr', unit: "percentInYear", label: t('ExpectedCPR'), startAdornment: cprStartAdorment(), tooltip: t('ExpectedCPR_tooltip'), value: cpr, /*updateValue: setCPR,*/ badgeContent: onlineIterationChanges?.cpr, dataType: InputDataType.FLOAT, validators: { validators: ['required', 'minFloat:0.0', 'maxFloat:100.0'], errorMessages: [t('FieldRequired'), stringInject(t('ValueMustBeBetweenTwo_Params'), [0, 100.0]), stringInject(t('ValueMustBeBetweenTwo_Params'), [0, 100.0])] } },

                { name: 'cdr', unit: "percentInYear", label: t('ExpectedCDR'), tooltip: t('ExpectedCDR_tooltip'), value: cdr, /*updateValue: setCDR,*/ badgeContent: onlineIterationChanges?.cdr, dataType: InputDataType.FLOAT, validators: { validators: ['required', 'minFloat:0.0', 'maxFloat:100.0'], errorMessages: [t('FieldRequired'), stringInject(t('ValueMustBeBetweenTwo_Params'), [0, 100.0]), stringInject(t('ValueMustBeBetweenTwo_Params'), [0, 100.0])] } },
                //GuaranteeRate	Тариф поручительства, % год.
                { name: 'guaranteeRate', unit: 'percentInYear', label: t('GuaranteeRate'), tooltip: t('GuaranteeRate1_tooltip'), value: guaranteeRate, /*updateValue: setGuaranteeRate,*/ badgeContent: onlineIterationChanges?.guaranteeRate, dataType: InputDataType.FLOAT, validators: { validators: ['minFloat:0.0', 'maxFloat:3.0'], errorMessages: [stringInject(t('ValueMustBeBetweenTwo_Params'), [0, 3.0]), stringInject(t('ValueMustBeBetweenTwo_Params'), [0, 3.0])] } },

                { name: 'useMinimalWAC', label: t('UseMinimalWAC'), tooltip: t('UseMinimalWAC_tooltip'), value: useMinimalWAC, /*updateValue: setUseMinimalWAC,*/ badgeContent: onlineIterationChanges?.useMinimalWAC, dataType: InputDataType.BOOLEAN, visible: useMinimalWACVisible },

            ]

        },
        {
            name: 'PoolInformationPanel', label: t('PoolCurrentIteration'), customProps: { disabled: true }, fields: [
                // { name: 'currentIterationDate', label: t('currentIterationDate'), tooltip: t('currentIterationDate_tooltip'), value: currentIterationDate, dataType: InputDataType.STRING, customProps: { disabled: true }, readOnly: true, },
                { name: 'currentIterationDateTime', label: t('currentIterationDateTime'), tooltip: t('currentIterationDateTime_tooltip'), value: currentIterationDateTime, dataType: InputDataType.UNKNOWN, customProps: { disabled: true }, readOnly: true, },
                { name: 'currentIterationAuthor', label: t('currentIterationAuthor'), tooltip: t('currentIterationAuthor_tooltip'), value: currentIterationAuthor, dataType: InputDataType.UNKNOWN, customProps: { disabled: true }, readOnly: true, },
                // { name: 'currentIterationPoolDate', label: t('currentIterationPoolDate'), tooltip: t('currentIterationPoolDate_tooltip'), value: currentIterationPoolDate, dataType: InputDataType.UNKNOWN, customProps: { disabled: true }, readOnly: true, },
                { name: 'currentIterationPoolId', label: t('currentIterationPoolId'), tooltip: t('currentIterationPoolId_tooltip'), value: currentIterationPoolId, dataType: InputDataType.UNKNOWN, customProps: { disabled: true }, readOnly: true, },
            ]
        }
    ];

    if (requiredIncrementVisible) {
        pricingFields[0].fields = [
            ...pricingFields[0].fields.slice(0, 2),
            { name: 'requiredIncrement', unit: "percentInYear", label: t('RequiredIncrement'), tooltip: t('RequiredIncrement_tooltip1'), value: requiredIncrement, /*updateValue: setRequiredIncrement,*/ badgeContent: onlineIterationChanges?.requiredIncrement, dataType: InputDataType.FLOAT, validators: { validators: ['required', 'minFloat:0.0', 'maxFloat:3.0'], errorMessages: [t('FieldRequired'), stringInject(t('ValueMustBeBetweenTwo_Params'), [0, 3.0]), stringInject(t('ValueMustBeBetweenTwo_Params'), [0, 3.0])] } }
            , ...pricingFields[0].fields.slice(2),
        ]
    }

    useEffect(() => {

        // console.log("------------------- revalidate form", submitForm.current);
        //re-validate on node change
        //submitForm.current?.isFormValid(false, true);
        handleFormValidation();

    }, [selectedNode, pricingFields])

    const handleFormValidation = debounce(700, async () => {

        await submitForm.current?.isFormValid(false, true);
    });

    const updateFields = (updates: any) => {

        var keys = Object.keys(updates);
        // console.log('updates', updates)
        if (keys.length == 0)
            return;

        //CHECKME!!! update selected deal state
        var update = { NodeID: selectedNode?.node?.id, ...updates };

        // keys.forEach(key => {
        //     //iterationChanges[key] = updates[key].Value;
        //     updatedFields.current[key] = { ...updatedFields.current[key], newValue: updates[key].Value };
        // });

        // console.log('sendIterationChange update', update)
        actionSecuritization.sendIterationChange(update)(dispatch);
    }

    const updateField = (name, setValueFn, value) => {
        if (setValueFn != null) {
            setValueFn(value)
        }
        else
            updateFields({ [name]: { Value: value } });
    }

    const getBadgeContent = (updFields = {}, row = {}) => {
        if (Object.hasOwn(updFields, row?.name)) {
            // if (updFields[row?.name]?.userID !== Globals.user.id)
            if (updFields[row?.name]?.userID != null)
                return updFields[row?.name]
        }
        // else
        //     if (row?.badgeContent?.userID !== Globals.user.id) {
        //         // {console.log('row', row.name, !row?.badgeContent?.userID, row?.badgeContent)}
        //         // if (!row?.badgeContent?.userID) return ({ userName: 'UNDEFINED' })
        //         if (row.dataSource != null) {
        //             var content = { ...row?.badgeContent };
        //             var index = row.dataSource.findIndex(x => x.key == content.oldValue);
        //             if (index != -1) {
        //                 content.oldValue = row.dataSource[index].value;
        //                 return content;
        //             }
        //         }

        //         return row?.badgeContent
        //     }

        return {}
    }

    const initialData = () => {
        //restore state
        // actionSecuritization.setDeal({ ...backupDeal })(dispatch);
        //actionSecuritization.setNodeDataParametr("node", { ...backupNode })(dispatch);
        //actionSecuritization.setDealData("isDealParametersValid", true)(dispatch);

        //reset data
        // actionSecuritization.resetDealChanges(deal.deal.id)(dispatch);
        actionSecuritization.resetIterationChanges(selectedNode.node.id)(dispatch);
    }

    return (
        <>

            <ZCYCDialog show={showZCYCChart} firstZCYCDate={zcycDate} onClose={() => setShowZCYCChart(false)} />
            <div className={classes.container}>
                {(permissions?.isAdmin || permissions?.isManager) &&
                    <IconButton
                        size='small'
                        color='primary'
                        onClick={initialData}
                        className={classes.resetButton}
                        disabled={Object.keys(updatedFields?.current ?? {}).length == 0}
                    >
                        <Tooltip title={t('RestoreParams')} disableInteractive>
                            <RestoreIcon />
                        </Tooltip>
                    </IconButton>
                }
                <ValidatorForm onSubmit={() => { }} ref={submitForm} validators={[validateFormFields]} onFieldChanged={onFieldChanged} onFormStateChanged={onFormStateChanged}>
                    {
                        pricingFields?.map((group) => {
                            return (
                                <Box key={group.name + '_block'} className={classes.Block}>
                                    <Box className={[classes.blockName/*, group?.customProps?.disabled ? classes.disabled : ''*/].join(' ')}>
                                        {group.label}
                                    </Box>
                                    {
                                        group?.fields?.map((row, index) => {
                                            if (row)
                                                return (
                                                    row.visible === false ? null : (
                                                        <Box key={row.name + '_id'}
                                                            className={[classes.rowBlock,
                                                                // (Object.hasOwn(updatedFields, row.name) && 
                                                                //     updatedFields[row.name]?.oldValue?.toString() !== updatedFields[row.name]?.newValue?.toString())?classes.changed:''
                                                            ].join(' ')}
                                                        >
                                                            <Box className={[classes.rowName, row?.customProps?.disabled ? classes.disabled : ''].join(' ')}>
                                                                <CustomTooltip
                                                                    id={`${row.name}-field-id`}
                                                                    content={row.tooltip}
                                                                    hideIcon
                                                                    placement="right-start"
                                                                    className={classes.tooltip}
                                                                >
                                                                    {row.label}
                                                                </CustomTooltip>
                                                            </Box>
                                                            <Box className={classes.rowValuesBlock}>
                                                                {(permissions?.isAdmin || permissions?.isManager) &&
                                                                    row.labelButton != null && row.labelButton}
                                                                {row.dataType == InputDataType.UNKNOWN ?
                                                                    <Box className={[classes.infoField, row?.customProps?.disabled ? classes.disabled : ''].join(' ')}>
                                                                        {row.value}
                                                                    </Box>
                                                                    :
                                                                    <>
                                                                        <Box className={[classes.valueData, row?.customProps?.disabled ? classes.disabled : ''].join(' ')}>
                                                                            {/* {row?.name == 'cpr' && console.log('row', row)} */}
                                                                            <UniversalInputField
                                                                                {...row.validators}
                                                                                needConfirm
                                                                                isChanged={Object.hasOwn(updatedFields.current, row.name)}
                                                                                dataType={row.dataType}
                                                                                minDate={
                                                                                    row.customProps?.minDate ? row.customProps.minDate :
                                                                                        (row.dataType == InputDataType.DATE ||
                                                                                            row.dataType == InputDataType.DATE_TIME ||
                                                                                            row.dataType == InputDataType.DATE_TIME_UTC) ?
                                                                                            dateLimits.minDate : null
                                                                                }
                                                                                maxDate={
                                                                                    row.customProps?.maxDate ? row.customProps.maxDate :
                                                                                        (row.dataType == InputDataType.DATE ||
                                                                                            row.dataType == InputDataType.DATE_TIME ||
                                                                                            row.dataType == InputDataType.DATE_TIME_UTC) ?
                                                                                            dateLimits.maxDate : null
                                                                                }
                                                                                {...row.customProps}

                                                                                name={row.name}
                                                                                readOnly={row.readOnly || !(permissions?.isAdmin || permissions?.isManager)}
                                                                                value={row.value}
                                                                                fullWidth={true}
                                                                                fieldProps={row.fieldProps}
                                                                                handleValueChange={(name, value) => updateField(name, row.updateValue, value)}
                                                                                hideValidationIcon={true}
                                                                                sx={{
                                                                                    '& .Mui-disabled:before': { borderBottomStyle: 'solid !important', borderBottomColor: `${alpha(style.colorPrimary3, 0.5)} !important` },
                                                                                    '& .MuiInput-root:hover:not(.Mui-disabled):before': { borderBottomColor: `${alpha(style.colorPrimary3, 0.5)} !important` },
                                                                                    '& .MuiInput-underline:before': { borderBottomColor: `${alpha(style.colorPrimary3, 0.5)} !important` },
                                                                                    // '& .MuiInput-underline:after': { borderBottomColor: `${alpha(style.colorPrimary3, 0.5)}` },
                                                                                    '& .Mui-focused:not(.Mui-readOnly):after': !row?.readOnly ? { border: `solid 2px ${alpha(style.colorPrimary2, 1)} !important`, top: '1px' } : {},
                                                                                    '& .Mui-error:after': { border: `none` },
                                                                                    '& .Mui-readOnly': { cursor: 'text', '& *': { cursor: 'text' } },
                                                                                    '& .Mui-readOnly:after': { border: `none` },
                                                                                    '& .Mui-readOnly:before': { border: `none !important` },
                                                                                    '& .Mui-readOnly:hover:before': { border: `none` },
                                                                                }}
                                                                                InputProps={{
                                                                                    startAdornment: ((row.startAdornment != null) ?
                                                                                        row.startAdornment
                                                                                        : null)
                                                                                }
                                                                                }
                                                                            // onBlur={() => onFieldLeft()}
                                                                            // onKeyUp={(event) => handleKeyPress(event)}
                                                                            />

                                                                        </Box>
                                                                        <Box className={classes.valueUnit}>
                                                                            {row?.unit ? t(row.unit) : ''}
                                                                        </Box>
                                                                        <Box className={classes.valueAuthor}>
                                                                            {(permissions?.isAdmin || permissions?.isManager) &&
                                                                                <ChipAuthor content={getBadgeContent({ ...selectedNode?.iterationChanges, ...selectedNode.onlineIterationChanges }, row)} />
                                                                            }
                                                                        </Box>
                                                                    </>
                                                                }
                                                            </Box>
                                                        </Box>
                                                    )
                                                )
                                        })
                                    }
                                </Box>
                            )
                        })
                    }
                </ValidatorForm>
                {(permissions?.isAdmin || permissions?.isManager) &&
                    <Box>
                        {/* {console.log('(nodeValidationState & efNodeValidationState.STATE_ITERATION_OUTDATED) != 0 || nodeParametersChanged || dealParametersChanged)', (nodeValidationState & efNodeValidationState.STATE_ITERATION_OUTDATED) != 0, selectedNode?.structure != null, nodeParametersChanged, dealParametersChanged)} */}
                        {
                            (!newPoolReceived && ((nodeValidationState & efNodeValidationState.STATE_ITERATION_OUTDATED) != 0 || nodeParametersChanged || dealParametersChanged) && selectedNode?.structure != null) &&
                            <Stack className={classes.dataAlertBlock}>
                                {/* {console.log('nodeValidationState', structure != null,  '(',(nodeValidationState & efNodeValidationState.STATE_ITERATION_OUTDATED) != 0,'||', dealParametersChanged,') = ', structure != null && (((nodeValidationState & efNodeValidationState.STATE_ITERATION_OUTDATED) != 0) || dealParametersChanged ))} */}
                                <Typography color='error' sx={{ textAlign: 'center' }}>
                                    <WarningAmber className={classes.icon} />
                                    <br />{t('IterationOutdated')}
                                    <br />{t('RecalcIteration')}
                                </Typography>
                            </Stack>
                        }
                        {/* {console.log('selectedNode?.lastIteration', selectedNode)} */}
                        {(selectedNode?.lastIteration != null && newPoolReceived) &&
                            <Stack className={classes.dataAlertBlock}>
                                <Typography color='error' sx={{ textAlign: 'center' }}>
                                    <WarningAmber className={classes.icon} />
                                    <br />{t("PoolWasChanged")}
                                    <br />{t("RecalcIteration")}
                                </Typography>
                            </Stack>
                        }
                        {/* ( !isCalculating && newPoolReceived && (iteration != null)) ?
                    
} */}
                    </Box>
                }
            </div>
        </>
    )
}

export default PoolParametersPanel;
