import { Button, Paper } from "@mui/material";
import { useTranslation } from "react-i18next";
import clsx from 'clsx';

import '@Styles/components/InformationForm.scss'
import '@Styles/components/Animations.scss'

//import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes, faDownload } from '@fortawesome/free-solid-svg-icons'
import SpinnerBars from "@Components/shared/SpinnerBars";
import { makeStyles, createStyles } from '@mui/styles';
import { Theme, Container } from '@mui/material';
import style from '@Styles/domrf-theme.module.scss';

interface IProps {
    type: "success" | "failure" | "download" | "processing";
    header?: string;
    content: string;
    onSubmit: (e) => void;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    spinner: {
        display: 'block'
    },
    iconSuccess: {
        marginBottom: '1rem',
        color: style.colorPrimary2
    },
    iconFailure: {
        marginBottom: '1rem',
        color: style.colorError
    },
    iconDownload: {
        marginBottom: '1rem',
        color: style.colorSecondary2
    },
    pb3: {
        paddingBottom: '1rem'
    },
    paper: {
        padding: '1rem'
    },
    paperBody: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    paperFooter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
//border - top: 0; //1px solid #dee2e6;
    }
}));

const InformationForm = ({ type, header, content, onSubmit }: IProps) => {

    const { t, i18n } = useTranslation();
    const classes = useStyles();

    const onSubmitButtonClick = (event) => {
        onSubmit(event);
    }

    const formIcon = (type: string) => {
        let icon = null;

        switch (type) {
            case 'success':
                {
                    icon = <FontAwesomeIcon icon={faCheck} size="4x" className={clsx(classes.iconSuccess, "animate-1 rotate-cclockwise")} />
                }
                break;
            case 'failure':
                {
                    icon = <FontAwesomeIcon icon={faTimes} size="4x" className={clsx(classes.iconFailure, "animate-1 rotate-cclockwise")} />
                }
                break;
            case 'download':
                {
                    icon = <FontAwesomeIcon icon={faDownload} size="4x" className={clsx(classes.iconDownload, "animate-1 rotate-cclockwise")} />
                }
                break;
            case 'processing':
                {
                    icon = <SpinnerBars />
                }
                break;
        }

        return icon;
    }

    return (

        <Container maxWidth='sm'>
            <Paper id="informationForm" className={classes.paper}>
                {
                    header != null && (
                        <div className={clsx(classes.pb3, "paper-header")}>
                            {header}
                        </div>
                    )
                }
                <div className={classes.paperBody} >
                    {formIcon(type)}
                    <p>{content}</p>
                </div>
                <div className={classes.paperFooter}>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={onSubmitButtonClick}>{t('AtMain')}</Button>
                </div>
            </Paper>
        </Container>
    )
}

export default InformationForm;