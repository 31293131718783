import TableCell from '@mui/material/TableCell/TableCell';
import TableRow from '@mui/material/TableRow/TableRow';
import Typography from '@mui/material/Typography/Typography';
import Warning from '@mui/icons-material/Warning';
import * as React from 'react';
import { ColumnModel } from '../Common';
import makeStyles from '@mui/styles/makeStyles';
//import * as theme from '@Styles/domrf-theme.module.scss';

interface IProps {
    columns: ColumnModel[];
    styles: any;
    title: string;
}


const useStyles = makeStyles({
    tableRow: {
        height: '100%',

        '& td': {
            border: 0,

            '& .MuiTypography-root': {
                paddingLeft: '0 !important',
                fontSize: '18px', //theme.fontSizeMain
                textAlign: 'center'
            }

        }
    }
});


export const NoDataRow: React.FunctionComponent<IProps> = ({ columns, styles, title }) => {

    const classes = useStyles({});

    return (
        <TableRow className={classes.tableRow}>
            <TableCell
                colSpan={columns.filter((col: any) => col.Visible).length}
            >
                <Typography
                    style={styles.title}
                    variant='body2'
                    gutterBottom={true}
                >
                    <Warning /> {title}
            </Typography>
            </TableCell>
        </TableRow>
    );
}
