import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import CloudDownload from '@mui/icons-material/CloudDownload';
import Print from '@mui/icons-material/Print';
import * as React from 'react';
import { ColumnModel } from '../Common';
import { exportGrid } from './GridToolbarFunctions';

interface IExportButtonProps {
    type: string;
    gridName: string;
    filteredRecordCount: number;
    toolTip?: string;
    exportTo: (allRows: boolean, exportFunc: (payload: any[], columns: ColumnModel[]) => void) => void;
}

export const ExportButton: React.FunctionComponent<IExportButtonProps> = ({
    type,
    gridName,
    toolTip,
    exportTo,
    filteredRecordCount
}) => {
    const [anchorPrint, setAnchorPrint] = React.useState<any>(null);

    const handlePrintMenu = (event: React.MouseEvent<HTMLElement>): void =>
        setAnchorPrint(event ? event.currentTarget : null);

    const closePrint = () => setAnchorPrint(null);

    const partialExport = (data: any, columns: ColumnModel[]) => {
        exportGrid(type, data, columns, gridName);
        closePrint();
    };

    const printCurrent = () => exportTo(false, partialExport);
    const printAll = () => exportTo(true, partialExport);

    return (
        <React.Fragment>
            <IconButton
                disabled={filteredRecordCount === 0}
                onClick={handlePrintMenu}
                size="large">
                {type === 'print' ?
                    (
                        <Tooltip
                            disableFocusListener
                            title={toolTip || 'Print'}>
                            <Print />
                        </Tooltip>
                    )
                    :
                    (
                        <Tooltip
                            disableFocusListener
                            title={toolTip || 'Download'}>
                            <CloudDownload />
                        </Tooltip>
                    )
                }
            </IconButton>
            <Menu
                anchorEl={anchorPrint}
                open={Boolean(anchorPrint)}
                onClose={closePrint}
            >
                <MenuItem onClick={printCurrent}>
                    Current rows
                </MenuItem>
                <MenuItem onClick={printAll}>
                    All rows
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
};
