export enum DataType {
    STRING = 'string',
    FLOAT = 'float',
    FLOAT1 = 'float1',
    INT = 'int',
    BOOLEAN = 'boolean',
    BOOLEAN_TRISTATE = 'boolean_nullable',
    DATE = 'date',
    DATE_TIME = 'datetime',
    DATE_TIME_UTC = 'datetimeutc'
}

export enum Categories {
    NONE = 0,
    GREEN = 1,
    YELLOW = 2,
    RED = 3
}