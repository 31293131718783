import * as React from 'react';
import { createPortal } from "react-dom";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
//import IconButton from '@mui/material/IconButton';
//import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Localization from '../../../components/Localization';
import { withTranslation } from "react-i18next";
import { Theme } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import { Categories, DataType } from '../../../Definitions';
import { CouponType } from '../../../components/Definitions';
import TooltipInfoLabel from '../../../components/shared/TooltipInfoLabel';
import { Spinner } from '../../../components/shared/Spinner';
import stringInject from 'stringinject';
import CustomTooltip, { getTooltip } from '@Base/components/shared/CustomTooltip';

const styles = (theme: Theme) => ({
    poolTooltipLabel: {
        width: '200px',
        whiteSpace: 'normal'
    }
});

interface IProps {
    t: any;
    classes: any;
    labelAnchor: any;
    tooltip: string;
    link: string;
    linkName: string;
    label: React.ReactNode;
    refresh: boolean; //to update with highcharts
}

interface IState {
    refresh: boolean | null;
}

class HighchartsLabelComponent extends React.Component<IProps, IState> {

    constructor(props) {
        super(props);

        this.state = {
            refresh: null
        };
    }

    componentDidMount() {
    }
    /*
    shouldComponentUpdate(nextProps, nextState) {
        return true;
    }
    */

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.options != this.props.refresh && this.props.refresh != this.state.refresh) {
            this.setState({ refresh: this.props.refresh });
        }
    }

    render() {
        const { classes, labelAnchor, tooltip, link, linkName, label } = this.props;
        var rendered = null;

        if (labelAnchor &&
            labelAnchor.chart &&
            labelAnchor.chart.xAxis[0] &&
            labelAnchor.chart.xAxis[0].plotLinesAndBands.length != 0 &&
            labelAnchor.chart.xAxis[0].plotLinesAndBands[0]?.label != null) {
            // console.log('labelAnchor.chart.xAxis[0].plotLinesAndBands[0].label.element', labelAnchor.chart.xAxis[0].plotLinesAndBands[0].label.element)
            rendered = (
                <React.Fragment>
                    {createPortal(
                        (
                            // <TooltipInfoLabel
                            //     key="rate-tooltip"
                            //     content={tooltip}
                            //     link={link}
                            //     linkName={linkName}
                            // >
                            //     <div className={classes.poolTooltipLabel}>
                            //         {label}
                            //     </div>
                            // </TooltipInfoLabel>
                            <CustomTooltip
                                key="rate-tooltip"
                                // classes={{ popper: cls.wideTooltip }}
                                // sx={{ '& .MuiButtonBase-root': { marginLeft: '4px', }, '& svg': { color: style.colorPrimary3_40, width: '20px', height: '20px', } }}
                                content={getTooltip({ tooltip: tooltip })}
                            // enterDelay={0} leaveDelay={0}
                            >
                                <div className={classes.poolTooltipLabel}>
                                    {label}
                                </div>
                            </CustomTooltip>
                        ),
                        labelAnchor.chart.xAxis[0].plotLinesAndBands[0].label.element)
                    }
                </React.Fragment>
            );
        }

        return rendered;
    }
}

export default withStyles(styles)(withTranslation('translations')(HighchartsLabelComponent));