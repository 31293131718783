import TableRow from '@mui/material/TableRow/TableRow';
import * as React from 'react';
import ColumnModel from '../Common/Models/ColumnModel';
import { RenderCells } from '../utils/RenderCells';
import { Localization } from '../DataGrid/Localization';
import { GridOptions } from '../DataGrid';
import { IDataGrid } from '../DataGridInterfaces/IDataGrid';

export interface ITbRow {
    grid: IDataGrid,
    row: any;
    rowIndex: number;
    onRowClick?: (ev: any) => any;
    onRowDoubleClick?: (ev: any) => any;
    selected?: boolean;
    options: GridOptions;
}

export const TbRow: React.FunctionComponent<ITbRow> = ({
    grid,
    row,
    onRowClick,
    onRowDoubleClick,
    selected,
    options
}) => (
        <TableRow
            hover={true}
            onClick={onRowClick}
            onDoubleClick={onRowDoubleClick}
            selected={selected}>
            {RenderCells(grid, grid.state.columns, row, options)}

        </TableRow>
    );
