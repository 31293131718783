import amber from '@mui/material/colors/amber';
import blue from '@mui/material/colors/blue';
import green from '@mui/material/colors/green';
import red from '@mui/material/colors/red';
import IconButton from '@mui/material/IconButton';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import Typography from '@mui/material/Typography';
import CheckCircle from '@mui/icons-material/CheckCircle';
import Close from '@mui/icons-material/Close';
import Error from '@mui/icons-material/Error';
import Info from '@mui/icons-material/Info';
import Warning from '@mui/icons-material/Warning';
import makeStyles from '@mui/styles/makeStyles';
import * as React from 'react';

const useStyles = makeStyles((theme: any) => ({
    closeIcon: {
        color: '#fff !important',
        fontSize: '20px  !important',
    },
    error: {
        backgroundColor: theme.palette ? `${theme.palette.error.main} !important` : `${red[600]} !important`,
        display: 'flex !important',
    },
    icon: {
        fontSize: '20px  !important',
        marginRight: '15px  !important',
    },
    iconMobile: {
        fontSize: '34px  !important',
        marginRight: '15px  !important',
    },
    info: {
        backgroundColor: theme.palette ? `${theme.palette.primary.main} !important` : `${blue[600]} !important`,
        display: 'flex !important',
    },
    success: {
        backgroundColor: `${green[600]} !important`,
        display: 'flex !important',
    },
    text: {
        alignItems: 'center',
        color: '#fff !important',
        display: 'inline-flex !important',
        fontSize: '18px !important',
    },
    textMobile: {
        color: '#fff !important',
        display: 'inline-flex !important',
        fontSize: '28px !important',
    },
    warning: {
        backgroundColor: `${amber[700]} !important`,
        display: 'flex !important',
    },
}));

export const GlobalSnackbar = ({ message, seconds = 2500, mobile = false }: any) => {
    const classes = useStyles({});
    const [open, setOpen] = React.useState(false);

    const getIcon = () => {
        switch (message.messageType) {
            case 'info': return <Info className={getIconStyle()} />;
            case 'warning': return <Warning className={getIconStyle()} />;
            case 'error': return <Error className={getIconStyle()} />;
            default: return <CheckCircle className={getIconStyle()} />;
        }
    };

    const getStyle = () => {
        switch (message.messageType) {
            case 'info': return classes.info;
            case 'warning': return classes.warning;
            case 'error': return classes.error;
            default: return classes.success;
        }
    };

    const getTextStyle = () => (mobile ? classes.textMobile : classes.text);
    const getIconStyle = () => (mobile ? classes.iconMobile : classes.icon);

    const onClose = () => setOpen(false);

    React.useEffect(() => {
        if (message && message.messageText !== '') {
            setOpen(true);
            setTimeout(() => {
                setOpen(false);
            }, seconds);
        }
    }, [message]);

    const anchorOrigin: SnackbarOrigin = {
        horizontal: mobile ? 'center' : 'right',
        vertical: 'bottom',
    };

    return (
        <Snackbar
            anchorOrigin={anchorOrigin}
            className={getStyle()}
            open={open}
        >
            <SnackbarContent
                className={getStyle()}
                message={(
                    <Typography className={getTextStyle()}>
                        {getIcon()} {message.messageText}
                    </Typography>
                )}
                action={!mobile && (
                    <IconButton
                        onClick={onClose}
                    >
                        <Close className={classes.closeIcon} />
                    </IconButton>
                )}
            />
        </Snackbar>
    );
};
