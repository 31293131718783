import React, { useState, useEffect } from 'react'

export function useUpdatedFields(initialValue = {}) {
    const getInitialValue = () => {
        return initialValue
    }

    const [data, setData] = useState(getInitialValue);

    const add = (item = {}) => {
        setData({ ...data, ...item })
    }
    const update = add

    const remove = (key = null) => {
        if (key != null) {
            const newData = data
            delete newData[key]
            setData({ ...newData })
        }
    }

    const reset = () => {
        setData({})
    }

    // useEffect(() => {
    //     console.log('data', data)
    // }, [data]);

    return { data, add, update, remove, reset }

}

