import ServiceBase from "./ServiceBase";
//import Globals from "../Globals";

export default class PoolService extends ServiceBase {

    static async getPoolLLDForChart(isin, wacwamDate, step = 1) {
        var result = await this.requestJson({
            url: "api/Convention/GetPoolLLD",
            method: "GET",
            data: { isin: isin, dtWACWAM: wacwamDate, step: step }
        });

        return result;
    }

}
