import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import Close from '@mui/icons-material/Close';
import Search from '@mui/icons-material/Search';
import Tooltip from '@mui/material/Tooltip';

import * as React from 'react';

const styles: any = {
    formControl: {
        margin: '10px',
        width: 250,
    },
};

interface ISearchTextInput {
    searchText: string;
    updateSearchText: (value: string) => any;
    toolTip: string;
}

export const SearchTextInput: React.FunctionComponent<ISearchTextInput> = ({ searchText, updateSearchText, toolTip }) => {
    const onChange = (e: any) => updateSearchText(e.target.value);
    const onClear = () => updateSearchText('');

    const adorment = (
        <InputAdornment position='end'>
            <Tooltip
                disableFocusListener
                title={toolTip || 'Search'}>
                <Search />
            </Tooltip>
        </InputAdornment>
    );

    return (
        <FormControl style={styles.formControl}>

            <Input
                fullWidth={true}
                type='text'
                value={searchText}
                onChange={onChange}
                startAdornment={adorment}
                endAdornment={
                    searchText !== '' && (
                        <InputAdornment position='end'>
                            <IconButton onClick={onClear} size="large">
                                <Close />
                            </IconButton>
                        </InputAdornment>
                    )
                }
            />
        </FormControl>
    );
};
