import * as React from 'react';
import Transformer, {
    ColumnModel, CompareOperators,
    GridRequest, GridResponse, IDataGridStorage,
    IFilterWrapper, ITubularHttpClient, LocalStorage,
    NullStorage, parsePayload, TubularHttpClient,
    ColumnSortDirection
} from '../Common';
import GridFilterState from '../Common/Models/GridFilterState';
import { IDataGrid } from '../DataGridInterfaces/IDataGrid';
import { IDataGridApi } from '../DataGridInterfaces/IDataGridApi';
import { IDataGridConfig } from '../DataGridInterfaces/IDataGridConfig';
import { IDataSource } from '../DataGridInterfaces/IDataSource';
import useEffectWithDebounce from '../../uno-react/hooks/useEffectWithDebounce';
import { createNewRow } from '../Common/utils';
import { DataSourceChangeType } from "../../Common/Definitions";
import { IEditDialogState } from "../DataGridInterfaces/IEditDialogState";
import { IConfirmDialogState } from "../DataGridInterfaces/IConfirmDialogState";

const getRemoteDataSource = (request: string | Request | ITubularHttpClient) =>
    async (gridRequest: GridRequest): Promise<GridResponse> => {
        const httpCast = request as ITubularHttpClient;
        const httpClient: ITubularHttpClient = httpCast.request
            ? httpCast
            : new TubularHttpClient(request);

        const data = await httpClient.fetch(gridRequest);
        if (!TubularHttpClient.isValidResponse(data)) {
            throw new Error('Server response is a invalid Tubular object');
        }

        data.Payload = data.Payload
            .map((row: any) => parsePayload(row, gridRequest.Columns));

        return data;
    };

const getLocalDataSource = (source: any[]) =>
    (request: GridRequest): Promise<GridResponse> => {
        return new Promise((resolve, reject) => {
            try {
                resolve(Transformer.getResponse(request, source));
            } catch (error) {
                reject(error);
            }
        });
    };

const getEmptyDataSource = (source: any[]) =>
    (request: GridRequest): Promise<GridResponse> => {
        return new Promise((resolve, reject) => {
            try {
                resolve(new GridResponse());
            } catch (error) {
                reject(error);
            }
        });
    };
const getCustomDataSource = (source: IDataSource) =>
    (request: GridRequest): Promise<GridResponse> => {
        return new Promise(async (resolve, reject) => {
            try {
                var response = await source.query(request);
                resolve(Transformer.getDataSourceResponse(request, response));
            } catch (error) {
                reject(error);
            }
        });
    };

//for datasource change
const getCustomDataSourceUpdate = (source: IDataSource) =>
    (type: string, request: any): Promise<any> => {
        return new Promise(async (resolve, reject) => {
            try {
                var response = await source.update(type, request);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });
    };

const useDataGrid =
    (
        initColumns: ColumnModel[],
        config: Partial<IDataGridConfig>,
        source: any[] | string | Request | ITubularHttpClient | IDataSource,
        deps?: any[]
    ): IDataGrid => {

        const [isLoading, setIsLoading] = React.useState(false);
        const [getColumns, setColumns] = React.useState<ColumnModel[]>(initColumns);
        const [initialized, setInitialized] = React.useState(false);
        const [getActiveColumn, setActiveColumn] = React.useState<ColumnModel>(new ColumnModel(''));
        const [getMultiSort, setMultiSort] = React.useState(false);
        const [getItemsPerPage, setItemsPerPage] = React.useState<number>(config.itemsPerPage || 20);
        const [getStorage] = React.useState<IDataGridStorage>(config.storage || new NullStorage());
        const [getPage, setPage] = React.useState<number>(config.page || 0);
        const [getSearchText, setSearchText] = React.useState<string>("");
        const [getError, setError] = React.useState(null);
        const [getDialogState, setDialogState] = React.useState<IEditDialogState>({ initialRowData: null, isEdit: false, isShow: false, isProcessing: false });
        const [getConfirmDialogState, setConfirmDialogState] = React.useState<IConfirmDialogState>({
            isShow: false, onClose: () => { }, onConfirm: () => { }
        });
        const [getPreventProcessing, setPreventProcessing] = React.useState<boolean>(false);
        const isMounted = React.useRef(false);

        //CHEKME!!! replace by common interface
        const getAllRecords = (
            (source != null)
                ?
                (source instanceof Array
                    ?
                    getLocalDataSource(source)
                    :
                    ((source as ITubularHttpClient).fetch != null)
                        ?
                        getRemoteDataSource(source as ITubularHttpClient)
                        :
                        getCustomDataSource(source as IDataSource)
                )
                :
                getEmptyDataSource(source as any[])
        );

        const [getState, setState] = React.useState<{
            aggregate: object | null,
            data: any[],
            filteredRecordCount: number,
            totalRecordCount: number
        }>
            ({
                aggregate: null,
                data: [],
                filteredRecordCount: 0,
                totalRecordCount: 0,
            });

        if (getStorage instanceof LocalStorage) {
            getStorage.setGridName(config.gridName || '');
        }

        const handleKeyDown = (event: any) => {
            if (event.key === 'Control' && !getMultiSort) {
                setMultiSort(true);
            }
        };

        const handleKeyUp = (event: any) => {
            if (event.key === 'Control' && getMultiSort) {
                setMultiSort(false);
            }
        };

        const api: IDataGridApi = {
            exportTo: async (allRows: boolean, exportFunc: (payload: any[], columns: ColumnModel[]) => void) => {
                if (getState.filteredRecordCount === 0) {
                    return;
                }

                let payload: any[] = getState.data;
                if (allRows) {
                    const { Payload } =
                        await getAllRecords(new GridRequest(getColumns, -1, 0, getSearchText));
                    payload = Payload;
                }

                exportFunc(payload, getColumns);
            },
            goToPage: (page: number) => {
                if (getPage !== page) {
                    setPage(page);
                }
            },
            handleFilterChange: (value: IFilterWrapper) => {
                setActiveColumn({
                    ...getActiveColumn,
                    Filter: {
                        ...getActiveColumn.Filter,
                        ...value as any,
                    },
                } as ColumnModel);
            },
            processRequest: async () => {

                //console.log("processing request " + new Date());

                setIsLoading(true);

                try {

                    const request = new GridRequest(getColumns, getItemsPerPage, getPage, getSearchText);
                    const response: GridResponse = await getAllRecords(request);

                    const maxPage = Math.ceil(response.TotalRecordCount / getItemsPerPage);
                    let currentPage = response.CurrentPage > maxPage ? maxPage : response.CurrentPage;
                    currentPage = currentPage === 0 ? 0 : currentPage - 1;

                    getStorage.setPage(currentPage);
                    getStorage.setColumns(getColumns);
                    getStorage.setTextSearch(getSearchText);

                    if (isMounted.current != true)
                        return;

                    setState({
                        aggregate: response.AggregationPayload,
                        data: response.Payload,
                        filteredRecordCount: response.FilteredRecordCount || 0,
                        totalRecordCount: response.TotalRecordCount || 0,
                    });

                    setIsLoading(false);
                    //setInitialized(true);
                    setError(null);
                    //setPage(currentPage);
                }
                catch (err) {
                    if (config.onError) {
                        config.onError(err);
                    }

                    setIsLoading(false);
                    setError(err);
                }

                //console.log("finished request " + new Date());
            },
            setActiveColumn,
            setFilter: (value: IFilterWrapper) => {

                const columns = [...getColumns];
                const column = columns.find(
                    (c: ColumnModel) => c.Name === getActiveColumn.Name,
                );
                if (!column) {
                    return;
                }

                column.Filter = {
                    ...getActiveColumn.Filter,
                    ...value,
                };
                setPage(0); //reset to default
                setColumns([...columns]);
            },
            sortColumn: (property: string) => {
                const columns = ColumnModel.sortColumnArray(
                    property,
                    [...getColumns],
                    getMultiSort,
                );

                if (columns)
                    setColumns(columns);
            },
            updateItemPerPage: (itemsPerPage: number) => {
                if (getItemsPerPage !== itemsPerPage) {
                    setPage(0); //reset to default
                    setItemsPerPage(itemsPerPage);
                }
            },
            updateSearchText: (searchText: string) => {
                if (getSearchText !== searchText) {
                    setPage(0); //reset to default
                    setSearchText(searchText);
                }
            },
            createNewRecord: () => {

                setDialogState({ ...getDialogState, initialRowData: createNewRow(getColumns), isShow: true, isEdit: false });
                //console.log("record created");
            },
            removeRecord: async (row: any) => {
                try {

                    setConfirmDialogState({
                        ...getConfirmDialogState,
                        isShow: true,
                        onClose: () => {
                            setConfirmDialogState({
                                ...getConfirmDialogState,
                                isShow: false,
                                onClose: () => { },
                                onConfirm: () => { }
                            });
                        },
                        onConfirm: () => {

                            if (row == null)
                                return;

                            (async () => {
                                var result = await (source as IDataSource).update(DataSourceChangeType.REMOVE, row);

                                if (result) {

                                    var newState = { ...getState };

                                    //we're gust cut last element and add first at top

                                    var index = newState.data.findIndex(x => x.id == row.id);
                                    if (index != -1) {
                                        newState.data.splice(index, 1);
                                        newState.filteredRecordCount -= 1;
                                        newState.totalRecordCount -= 1;
                                    }

                                    setState(newState);
                                }

                                //console.log("record removed");
                            })();
                        }
                    });
                }
                catch (err) {
                    if (config.onError) {
                        config.onError(err);
                    }

                    setError(err);
                }
            },
            updateRecord: (row: any) => {

                if (row == null)
                    return;

                setDialogState({ ...getDialogState, initialRowData: row, isShow: true, isEdit: true });
            },
            applyChanges: async (applyState: boolean | null, row: any) => {

                try {

                    if (row == null) {
                        setDialogState({ ...getDialogState, initialRowData: null, isShow: false });
                        return;
                    }

                    if (applyState === true) {
                        //console.log("changes applied");

                        setDialogState({ ...getDialogState, isProcessing: true });

                        //send request
                        var state = getDialogState;
                        var type = (state.isEdit) ? DataSourceChangeType.UPDATE : DataSourceChangeType.CREATE;
                        var result = await (source as IDataSource).update(type, row);


                        setDialogState({ ...getDialogState, isProcessing: false });

                        if (result != null) {

                            //CHEKME!!! add row to grid

                            setDialogState({ ...getDialogState, initialRowData: null, isShow: false });

                            var newState = { ...getState };

                            switch (type) {
                                case DataSourceChangeType.CREATE:
                                    {
                                        if (newState.data == null) {
                                            newState.data = new Array(result);
                                            newState.filteredRecordCount = 1;
                                            newState.totalRecordCount = 1;
                                        }
                                        else {
                                            //we're gust cut last element and add first at top
                                            newState.data.unshift(result);
                                            newState.filteredRecordCount += 1;
                                            newState.totalRecordCount += 1;
                                        }
                                    }
                                    break;
                                case DataSourceChangeType.UPDATE:
                                    {
                                        var keyColumn = getColumns.find(x => x.IsKey === true);
                                        if (keyColumn != null) {
                                            var idName = keyColumn.Name;
                                            var index = newState.data.findIndex((x: any) => (x[idName] == result[idName]));
                                            if (index != -1) {
                                                newState.data[index] = result;
                                            }
                                        }
                                    }
                                    break;
                            }

                            setState(newState);
                        }
                        else {

                            if (config.onError) {
                                config.onError("Error processing operation");
                            }
                        }
                    }
                    else if (applyState === false) {

                        //console.log("changes cancelled");

                        setDialogState({ ...getDialogState, initialRowData: null, isShow: false });
                    }
                }
                catch (err) {

                    setDialogState({ ...getDialogState, isProcessing: false });

                    if (config.onError) {
                        config.onError(err);
                    }

                    setError(err);
                }
            },
            reflectDataSourceChanges: async (data: any, updater: (currentItem: any, newItem: any) => void) => {

                if (data.length == 0)
                    return;

                var newState = { ...getState };

                data.forEach((item: any) => {

                    var index = newState.data.findIndex(x => x.id == item.id);
                    if (index != -1) {
                        var found = newState.data[index];
                        updater(found, item);
                    }
                });

                setState(newState);
            },
            setFiltersState: async (filter: GridFilterState) => {

                setPreventProcessing(true);

                if ((filter.SearchText || '') == '' && filter.ColumnState.length == 0) {
                    setPreventProcessing(false);
                    return;
                }

                //update state of visible columns
                var columns = getColumns;
                columns.forEach((item) => {
                    if (!item.Visible)
                        return;

                    if (!item.Filterable && !item.Sortable)
                        return;

                    var element = filter.ColumnState.find((element) => element.Id === item.Name);
                    if (element == null) {

                        //reset
                        item.Filter.HasFilter = false;
                        item.SortDirection = ColumnSortDirection.NONE;
                        item.SortOrder = -1;
                        return;
                    }

                    if (item.Filterable) {

                        item.Filter = element.Filter;
                    }
                    else {
                        //reset
                        item.Filter.HasFilter = false;
                    }

                    if (item.Sortable) {
                        item.SortDirection = element.SortDirection;
                        item.SortOrder = element.SortOrder;
                    }
                    else {
                        //reset
                        item.SortDirection = ColumnSortDirection.NONE;
                        item.SortOrder = -1;
                    }
                });

                setColumns(columns);
                setSearchText(filter.SearchText);
                setItemsPerPage(filter.PageSize);

                getStorage.setTextSearch(filter.SearchText);
                getStorage.setColumns(columns);
                getStorage.setPage(filter.RowIndex / filter.PageSize);
                setPage(filter.RowIndex / filter.PageSize);

                setPreventProcessing(false);
            }
        };

        //for text search
        //useEffectWithDebounce((async () => {
        //await api.processRequest();
        //}), config.searchDelayInterval || 500, [getSearchText]);

        React.useEffect(() => {

            if (isMounted.current) {
                const handler = setTimeout(async () => {
                    await api.processRequest();
                }, config.searchDelayInterval || 500);

                return () => {
                    clearTimeout(handler);
                };
            }
            else
                isMounted.current = true;

        }, [getSearchText]);

        let dependencies = [getColumns, getPage, getItemsPerPage, source];

        if (deps) {
            dependencies = dependencies.concat(deps);
        }

        React.useEffect(() => {
            document.addEventListener('keydown', handleKeyDown);
            document.addEventListener('keyup', handleKeyUp);

            return (() => {
                document.removeEventListener('keydown', handleKeyDown);
                document.removeEventListener('keyup', handleKeyUp);
            });
        }, [getMultiSort]);

        React.useEffect(() => {

            if (isMounted.current != true)
                return;

            if (getPreventProcessing === true)
                return;

            api.processRequest();
        }, dependencies);

        React.useEffect(() => {
            setColumns(initColumns);
        }, [initColumns]);

        const initGrid = async () => {
            if (getStorage.getPage()) {
                setPage(getStorage.getPage());
            }

            const storedColumns = getStorage.getColumns();

            if (storedColumns) {
                const columns = [...getColumns];

                storedColumns.forEach((column) => {
                    const currentColumn = columns.find((col: ColumnModel) => col.Name === column.Name);

                    if (!currentColumn) {
                        return;
                    }

                    currentColumn.Visible = column.Visible;

                    if (currentColumn.Filter !== null && currentColumn.Filter.Value !== null) {
                        return;
                    }

                    if (column.Filter != null &&
                        column.Filter.Value != null &&
                        column.Filter.Operator !== CompareOperators.NONE) {
                        currentColumn.Filter = column.Filter;
                    }
                });

                setColumns(columns);
            }

            setInitialized(true);
        };

        if (!initialized) {
            initGrid();
        }

        const state = {
            ...getState,
            activeColumn: getActiveColumn,
            columns: getColumns,
            error: getError,
            initialized,
            isLoading,
            itemsPerPage: getItemsPerPage,
            multiSort: getMultiSort,
            page: getPage,
            searchText: getSearchText,
            storage: getStorage,
            editDialogState: getDialogState,
            confirmDialogState: getConfirmDialogState
        };

        const result = {
            api,
            state,
        };

        return result;
    };

export default useDataGrid;
