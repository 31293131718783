import * as React from "react";
import Tooltip from '@mui/material/Tooltip';
// import Typography from '@mui/material/Typography';
// import Button from '@mui/material/Button';
import { Theme } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
// import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
// import ClickAwayListener from '@mui/material/ClickAwayListener';
// import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
// import ButtonBase from '@mui/material/ButtonBase';
// import clsx from 'clsx';
import style from '@Styles/domrf-theme.module.scss';
import Globals from '../../Globals';

// import Localization from "../Localization";

const styles = (theme: Theme) => createStyles({

    /* Styles applied to the root element. */
    root: {
        cursor: 'pointer',
        display: 'inline-flex',
        justifyContent: 'flex-start',
        flexDirection: 'inherit'
        //marginLeft: 5
    },
    /* Styles applied to the icon component. */
    icon: {
        fontSize: 18,
        //marginLeft: 15,
        userSelect: 'none',
        color: style.colorPrimary3,
        opacity: 0.8
    },

    button: {
        color: 'white'
    },
    buttonContainer: {
        textAlign: 'end'
    },
    popper: {
        zIndex: 10001
    }

    //children: {
    //cursor: 'pointer'
    //}
});

interface IProps {
    classes: any;
    className: any;
    IconComponent?: any;
    content: any;
}

interface IState {
    tooltipOpened: boolean;
}

class TableInfoTooltip extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            tooltipOpened: false
        }
    }

    handleClick = (evt: any) => {
        window.location.href = Globals.WikiServer + "/ru/%D0%93%D0%BB%D0%B0%D0%B2%D0%BD%D0%B0%D1%8F";
    }

    handleTooltipClose = () => {
        this.setState({ tooltipOpened: false });
    };

    handleTooltipOpen = () => {
        this.setState({ tooltipOpened: true });
    };

    render() {

        const
            {
                // classes,
                children,
                className,
                // IconComponent = HelpOutlineIcon,
                content,
                // ...other
            } = this.props;

        return (
            <Tooltip
                //{...other}
                className={className}
                //disableInteractive
                disableFocusListener
                enterDelay={300}
                title={content}
            /*
            title={
                <React.Fragment>
                    <div dangerouslySetInnerHTML={{ __html: content }}></div>
                    {
                    <div className={classes.buttonContainer}>
                        <Button className={classes.button} onClick={this.handleClick}>{t('KnowMore')}</Button>
                    </div>
                    }
                </React.Fragment>
            }
*/
            >
                <div>
                    {children}
                </div>
            </Tooltip>
        );
    }
}

export default withStyles(styles)(TableInfoTooltip);