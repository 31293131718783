import ImageList from '@mui/material/ImageList';
import * as React from 'react';
import { Localization } from './Localization';

import { TbMobileRow } from '../BareBones/TbMobileRow';
import { ITbRow } from '../BareBones/TbRow';
import { IDataGrid } from '../DataGridInterfaces/IDataGrid';
import { GridOptions } from './GridOptions';
import { SelectionType } from '../Common/Models/Column';

interface IProps {
    grid: IDataGrid;
    rowComponent?: React.FunctionComponent<ITbRow>;
    onRowClick?(row: any, selected: any[]): void;
    onRowDoubleClick?(row: any): void;
    gridOptions: GridOptions;
}

const generateOnRowClickProxy = (onRowClick : any, selected: any[]) => {
    return (row: any) => (ev: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
        if (onRowClick) {
            onRowClick(row, selected);
        }
    };
};

export const MobileDataGridTable: React.FunctionComponent<IProps> = ({ grid, gridOptions, rowComponent, onRowClick, onRowDoubleClick }) => {
    const RowComponent = rowComponent ? rowComponent : TbMobileRow;
    const onRowClickProxy = onRowClick ? (row: any) => (ev: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {

        if (row != null && keyColumnName != null) {

            var value = row[keyColumnName.Name];
            switch (gridOptions.selectionType) {
                case SelectionType.TYPE_SINGLE:
                    {
                        setSelected(row[keyColumnName.Name]);
                    }
                    break;
                case SelectionType.TYPE_MULTI:
                    {
                        var selected = getSelected;
                        var index = selected.indexOf((x: any) => x === value);
                        if (index != -1) {
                            //exist - remove
                            selected = selected.splice(index, 1);
                        }
                        else {
                            selected.push(value);
                        }

                        setSelected(selected);
                    }
                    break;
            }
        }

        if (onRowClick) {
            onRowClick(row, getSelected);
        }

    } : () => void 0;

    const onRowDoubleClickProxy = onRowDoubleClick ? (row: any) => (ev: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {

        if (onRowDoubleClick) {
            onRowDoubleClick(row);
        }

    } : () => void 0;

    const [getSelected, setSelected] = React.useState<any[]>([]);
    const keyColumnName = grid.state.columns.find(x => x.IsKey === true);

    return (
        <ImageList
            rowHeight='auto'
            cols={1}
        >
            {
                grid.state.data.map((row: any, index: number) =>
                    (
                        <RowComponent
                            grid={grid}
                            row={row}
                            rowIndex={index}
                            onRowClick={onRowClickProxy(row)}
                            onRowDoubleClick={onRowDoubleClickProxy(row)}
                            key={index}
                            options={gridOptions}
                        />
                    ))
            }
        </ImageList>
    );
};
