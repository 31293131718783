
export enum DataSourceChangeType {
    CREATE = 'Create',
    UPDATE = 'Update',
    REMOVE = 'Remove'
}

export enum BondIssueStatus {
    UNDEFINED = 0,
    NOT_ISSUED = 1,
    PREPARING_TO_ISSUE = 2,
    ISSUED = 3
}


//used in selection or else
export const DefaultNullValue = "null";