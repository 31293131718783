/* eslint-disable */
import React, { useRef, useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import Promise from 'promise-polyfill';
/* eslint-enable */
import Rules from './ValidationRules';

interface FormContextType {
    form: {
        attachToForm: (component: any) => void;
        detachFromForm: (component: any) => void;
        instantValidate: boolean;
        debounceTime: number;
        validate: (validator, value) => boolean;
    }
};

interface IProps {
    onSubmit?: (event: any) => void;
    instantValidate?: boolean;
    onError?: () => void;
    debounceTime?: number;
    children: any[];
    validators?: any[];
    onFieldChanged?: (name, value, isValid) => void;
    onFormStateChanged?: (isValid) => void;
}

const FormContext = React.createContext<FormContextType | null>(null);

const ValidatorForm = forwardRef(({ onSubmit, instantValidate, onError, debounceTime, children, validators, onFieldChanged, onFormStateChanged, ...rest }: IProps, ref) => {

    const [localDebounceTime, setLocalDebounceTime] = useState(debounceTime);
    const [localInstantValidate, setLocalInstantValidate] = useState(instantValidate !== undefined ? instantValidate : true);

    const localData = useRef({
        childs: [],
        isValid: undefined
    });

    useImperativeHandle(ref, () => ({
        isFormValid: isFormValid,
        submit: submit,
        resetValidations: resetValidations
    }));

    useEffect(() => {

        return () => {
            localData.current.childs.forEach(input => {
                if (input.current != null) {
                    input.current.onFormUnmounted();
                }
            });

            localData.current.childs = [];
            localData.current.isValid = undefined;
        };

    }, [])

    const attachToForm = (component) => {
        if (localData.current.childs.indexOf(component) === -1) {
            localData.current.childs.push(component);
        }
    }

    const detachFromForm = (component) => {
        const componentPos = localData.current.childs.indexOf(component);
        if (componentPos !== -1) {
            localData.current.childs = localData.current.childs.slice(0, componentPos)
                .concat(localData.current.childs.slice(componentPos + 1));
        }
    }

    const submit = (event) => {
        if (event) {
            event.preventDefault();
            event.persist();
        }
        //localData.current.errors = [];
        walk(localData.current.childs).then((result) => {
            //if (localData.current.errors.length) {
            //onError != null && onError(localData.current.errors);
            //}

            if (result) {

                return proceedGlobalRules().then(result => {
                    if (result.length == 0) //doesn't have errors
                    {
                        onSubmit(event);
                        return true;
                    }

                    return false;
                })
            }

            return result;
        });
    }

    const proceedGlobalRules = (dryRun = false) => {

        //console.log('-----------------proceed global rules');

        if (validators == null || validators.length == 0) {
            return Promise.resolve([]);
        }

        //apply global parameters
        var data = localData.current.childs
            .filter(x => x.current != null)
            .map(input => { return { input: input, state: input.current.getState() } });

        if (data.length == 0) //no children
            return Promise.resolve([]);

        var fields = {};
        data.forEach(item => fields[item.state.name] = item.state.value);


        return Promise.all(validators.map(validator => validator(fields))).then((result) => {

            var merged = [].concat.apply([], result).filter(x => x != null);

            //distinct invalid values
            var hash = {}
            merged.forEach(item => {

                if (item != null) {

                    if (hash[item.name] == null) {
                        hash[item.name] = item.message;
                    }
                }
            });

            //apply updates
            data.forEach(item => {

                if (item.input.current == null)
                    return;

                var isValid = undefined;
                if (hash[item.state.name] != null && !dryRun) {

                    item.input.current.setState(false, hash[item.state.name]);
                    isValid = false;

                } else {

                    item.input.current.setState(true);
                    isValid = true;
                }

                //if (onFieldChanged)
                //onFieldChanged(item.state.name, item.state.value, isValid);
            });

            return merged;
        });
    }

    const walk = (children, dryRun = false, notifyFormChange = false) => {
        return Promise
            .all(children
                .filter(x => x.current != null)
                .map(input => {
                    input.current.setState(true); //cleanup global state
                    return validateChild(input, dryRun)
                })
            )
            .then((data) => {

                //console.log('=====walk ', data);

                if (data.length == 0)
                    return true;

                if (data.indexOf(false) != -1) {
                    //Promise.all(children.filter(x => x.current != null).map(input => input.current.setState(true)));
                    return false;
                }

                return proceedGlobalRules(dryRun).then(result => {

                    return result.length == 0; //doesn't have errors
                });

            }).then(result => {


                notifyFormStateChanged(result, notifyFormChange);

                return result;
            });
    }

    const validateChild = (input, dryRun) => {

        return input.current?.validate(dryRun).then((result) => {
            return result.isValid;
        });
    }

    const getValidator = (validator, value) => {
        let result = true;
        if (typeof (validator) == 'function') {
            result = validator(value);
        }
        else {
            let name = validator;
            let extra;

            const splitIdx = validator.indexOf(':');
            if (splitIdx !== -1) {
                name = validator.substring(0, splitIdx);
                extra = validator.substring(splitIdx + 1);
            }

            result = Rules[name](value, extra);

            //console.log('Validator name: ' + name + ' value: ' + value + ' result: ' + result);;
        }
        return result;
    }

    const notifyFormStateChanged = (isValid: boolean, forceToNotify = false) => {

        //console.log(`====notifyFormStateChanged, current: ${localData.current.isValid}, new: ${isValid}`);

        if (localData.current.isValid != isValid || forceToNotify) {

            localData.current.isValid = isValid;

            if (onFormStateChanged) {
                onFormStateChanged(isValid);
            }
        }
    }

    const onChildStateChanged = (child, name, value, isValid, isStateChanged) => {

        //console.log(`====onChildStateChanged, name: ${name}, value: ${value}, isValid: ${isValid}, isStateChanged: ${isStateChanged}`);

        if (onFieldChanged && isStateChanged) {
            onFieldChanged(name, value, isValid);

            isFormValid(false);
        }
        //CHECKME!!! think about optimization - instead of calling isFormValid and check all fialds, handle changed only

            /*
        var childsState = localData.current.childs
            .filter(x => x != child && x.current != null )
            .map(input => { return { child: input, state: input.current.getState() } });
        var isChildsValid = childsState.every(x => x.state.isValid);

        //nothing to do here
        if (validators == null || validators.length == 0) {
            notifyFormStateChanged(isChildsValid);
            return;
        };

        //var index = childsState.findIndex(x => x.name == name);

        if (!isValid) //we update global rules if some of fields becomes invalid
        {
            Promise.all(childsState.map(input => input.child.current.setState(true)));

            notifyFormStateChanged(isChildsValid);

        } else {

            //it was the only one invlid child
            if (childsState.every(input => input.state.isValid)) {

                proceedGlobalRules().then(result => {

                    var isValid = result.length == 0;

                    notifyFormStateChanged(isValid);
                });
            }
        }
        */
    }

    const resetValidations = () => {
        localData.current.childs.forEach((child) => {
            child.current.cancelValidation();
            child.current.setState({ isValid: true });
        });
    }

    const isFormValid = (dryRun = true, notifyFormChange = false) => walk(localData.current.childs, dryRun, notifyFormChange);

    const context = {
        form: {
            attachToForm: attachToForm,
            detachFromForm: detachFromForm,
            onChildStateChanged: onChildStateChanged,
            instantValidate: localInstantValidate,
            debounceTime: localDebounceTime,
            getValidator: getValidator
        }
    };

    return (
        <FormContext.Provider value={context} ref={ref}>
            <form {...rest} onSubmit={submit} style={{ display: 'contents' }}>
                {children}
            </form>
        </FormContext.Provider>
    );
})

export default ValidatorForm;
export { FormContext };