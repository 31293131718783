/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import TextField from '@mui/material/TextField';
import { useTranslation } from "react-i18next";

import { Button, DialogActions, IconButton, InputAdornment } from "@mui/material";
import EventIcon from '@mui/icons-material/Event';

/* eslint-enable */
import useFormValidation from './ValidationForm/useFormValidation';
import TooltipInfoLabel from '../../../components/shared/TooltipInfoLabel';
import { withTranslation } from "react-i18next";
import { parse } from 'date-fns';

import { makeStyles } from '@mui/styles';
import { PickersActionBarProps } from '@mui/x-date-pickers/PickersActionBar';

const useStyles = makeStyles({
    tooltip: {
        display: 'flex',
        width: '100%'
    }
});

const DateTimePickerValidator = ({
    name,
    errorMessages,
    validators,
    requiredError,
    helperText,
    tooltip,
    readOnly,
    //disabled,
    value,
    onChange,
    cancelLabel,
    okLabel,
    todayLabel,
    tReady,
    fullWidth,
    extraButtonsProps,
    format,
    disabled,

    ...rest
}) => {

    const classes = useStyles();
    const { t, i18n } = useTranslation();

    const [isOpen, setIsOpen] = useState(false);
    const [dateValue, setDateValue] = useState(typeof (value) == 'string' ? new Date(value) : value);
    const [dateInputValue, setDateInputValue] = useState(value);

    useEffect(() => {

        setDateValue(typeof (value) == 'string' ? new Date(value) : value);

    }, [value]);

    const showDatePicker = (evt) => {
        this.setState({ isOpen: true });
    }

    const handleDateChange = (newValue) => {
        setIsOpen(newValue);
    }

    const handleDateAccept = (newValue) => {
        if (onChange != null) {
            onChange(newValue);
        }
    }

    const handleInputChange = (event) => {
        setDateInputValue(parse(event.target.value, format, new Date()));
    }

    const { isValid, errorMessage } = useFormValidation(disabled, name, value, errorMessages, validators);

    const MyActionBar = ({
        onAccept,
        onCancel,
        onClear,
        onSetToday,
    }: PickersActionBarProps) => {

        return (
            <DialogActions>
                <Button onClick={onCancel}> {cancelLabel || t('Cancel')} </Button>
                <Button onClick={onAccept}> {okLabel || t('OK')} </Button>
            </DialogActions>
        );
    };

    return (
        <TooltipInfoLabel className={classes.tooltip} content={tooltip}>
            <MobileDateTimePicker
                name={name}
                ampm={false}
                disabled={disabled}
                showTodayButton={true}
                value={dateValue}
                onChange={handleDateChange}
                onAccept={handleDateAccept}
                mask={undefined}
                format={format}
                disableCloseOnSelect={true}
                components={{
                    ActionBar: MyActionBar
                }}
                // cancelText={cancelLabel != null ? cancelLabel : t('Cancel')}
                // okText={okLabel != null ? okLabel : t('OK')}
                todayText={todayLabel != null ? todayLabel : t('Today')}
                open={isOpen}
                onOpen={() => (!!readOnly) ? {} : setIsOpen(true)}
                onClose={() => (!!readOnly) ? {} : setIsOpen(false)}

                //variant="inline"
                //margin="none"

                renderInput={(params) =>
                    <TextField
                        {...params}
                        value={dateInputValue}
                        onChange={handleInputChange}
                        fullWidth={fullWidth}
                        variant="standard"
                        error={!isValid}
                        helperText={(!isValid && errorMessage) || helperText}
                        InputProps={{
                            readOnly: !!readOnly,
                            endAdornment: (
                                <InputAdornment position="end">
                                    {extraButtonsProps}

                                    <IconButton disabled={params.disabled || (!!readOnly)} onClick={showDatePicker} size="large">
                                        <EventIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />}

                {...rest} />
        </TooltipInfoLabel>
    );
}

export default DateTimePickerValidator;