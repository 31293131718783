import CalculatorService from "@Services/CalculatorService";
import DomRfService from "@Services/DomRfService";
import Localization from '@Components/Localization';

const Actions = {

    Init: "POOL_INIT",

    RequestStart: "POOL_REQUEST_START",
    RequestSuccess: "POOL_REQUEST_SUCCESS",
    RequestFailure: "POOL_REQUEST_FAILURE",

    PartialRequestStart: "POOL_PARTIAL_REQUEST_START",
    PartialRequestSuccess: "POOL_PARTIAL_REQUEST_SUCCESS",
    PartialRequestFailure: "POOL_PARTIAL_REQUEST_FAILURE",
}


export const actionCreators = {

    init: () => async (dispatch, getState) => {
        dispatch({ type: Actions.Init });
    },

    getBondsInfo: (items, date) => async (dispatch, getState) => {

        dispatch({ type: Actions.PartialRequestStart, payload: { loading: true } });

        var result = await CalculatorService.getBondsInfo(items, date);
        if (result.hasErrors) {
            dispatch({ type: Actions.PartialRequestFailure });
            return;
        }

        dispatch({ type: Actions.PartialRequestSuccess, payload: { bonds: result.value, loading: false } });
    },

    getPoolStatistics: (date, isLoadRedeemedBonds = false) => async (dispatch, getState) => {
        // console.log('start GetPoolStatistics')
        dispatch({ type: Actions.RequestStart, payload: { loading: true } });

        var result = await CalculatorService.getPoolStatistics(date, isLoadRedeemedBonds);
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailure });
            return;
        }

        dispatch({ type: Actions.RequestSuccess, payload: { statistics: result.value, loading: false } });
        // console.log('end GetPoolStatistics')
    },

    getPoolCDR: (date, items, isCorrectedCDR) => async (dispatch, getState) => {

        dispatch({ type: Actions.RequestStart, payload: { loading: true } });

        var result = await CalculatorService.getPoolCDR(date, items, isCorrectedCDR);
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailure });
            return;
        }

        dispatch({ type: Actions.RequestSuccess, payload: { poolCDR: result.value, loading: false } });
    },

    getPoolsTimeline: () => async (dispatch, getState) => {
        // console.log('start getPoolsTimeline')
        dispatch({ type: Actions.RequestStart, payload: { loadingTimeline: true } });

        var result = await CalculatorService.getPoolsTimeline();
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailure });
            return;
        }

        dispatch({ type: Actions.RequestSuccess, payload: { timeline: result.value, loadingTimeline: false } });
        // console.log('end getPoolsTimeline')
    },

    getPoolsSCurve: (date, min, max) => async (dispatch, getState) => {
        // console.log('start getPoolsSCurve')
        dispatch({ type: Actions.RequestStart, payload: { loading: true } });

        var result = await DomRfService.getSCurveData(date, min, max);
        if (result.hasErrors) {
            dispatch({ type: Actions.RequestFailure });
            return;
        }
        // // console.log('result.value', min, max, result.value)
        dispatch({ type: Actions.RequestSuccess, payload: { scurve: result.value, loading: false } });
        // console.log('end getPoolsSCurve')
    }
};

const initialState = {
    loading: false
};

export const reducer = (currentState, incomingAction) => {

    if (currentState === undefined)
        return initialState;

    const action = incomingAction;

    switch (action.type) {
        case Actions.Init:
            return initialState;

        case Actions.RequestStart:
            return { ...currentState, ...action.payload };

        case Actions.RequestSuccess:
            return { ...currentState, ...action.payload };

        case Actions.RequestFailure:
            return { ...currentState, loading: false };

        case Actions.PartialRequestStart:
            return { ...currentState, bondsLoading: true };

        case Actions.PartialRequestSuccess:
            return { ...currentState, bondsLoading: false, ...action.payload };

        case Actions.PartialRequestFailure:
            return { ...currentState, bondsLoading: false };


        default:
            return currentState || initialState;
    }
};