import TableBody from '@mui/material/TableBody';
import * as React from 'react';
import { ITbRow, TbRow } from '../BareBones/TbRow';
import { IDataGrid } from '../DataGridInterfaces/IDataGrid';
import IDetailComponet from '../DataGridInterfaces/IDetailComponent';
import { RenderCells } from '../utils/RenderCells';
import MasterDetailRow from './MasterDetailRow';
import { NoDataRow } from './NoDataRow';
import classes from '*.module.sass';
import makeStyles from '@mui/styles/makeStyles';
import { GridOptions } from './GridOptions';
import { SelectionType } from '../Common/Models/Column';

interface IProps {
    detailComponent?: React.ReactElement<IDetailComponet> | null;
    grid: IDataGrid;
    rowComponent: React.FunctionComponent<ITbRow> | undefined;
    onRowClick?(row: any, selected: any[] | null): void;
    onRowDoubleClick?(row: any): void;
    gridOptions: GridOptions;
}

const getStyles = (isPointer: boolean) => ({
    row: { cursor: isPointer ? 'pointer' : 'auto' },
    title: { paddingLeft: '15px' },
});

/*
const generateOnRowClickProxy = (onRowClick: any, selected: any[]) => {
    return (row: any) => (ev: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {

        if (onRowClick) {
            onRowClick(row, selected);
        }
    };
};
*/

const useStyles = makeStyles({
    tableBody: {
        overflowY: 'scroll'
    }
});


export const GridBody: React.FunctionComponent<IProps> = ({ grid, gridOptions, rowComponent, onRowClick, onRowDoubleClick, detailComponent}) => {
    const styles = getStyles(Boolean(onRowClick));
    const RowComponent = rowComponent ? rowComponent : TbRow;
    const onRowClickProxy = onRowClick ? (row: any) => (ev: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {

        var selected = [];

        if (row != null && keyColumnName != null) {

            var value = row[keyColumnName.Name];
            switch (gridOptions.selectionType) {
                case SelectionType.TYPE_SINGLE:
                    {
                        selected = [row[keyColumnName.Name]];
                        setSelected(selected);
                    }
                    break;
                case SelectionType.TYPE_MULTI:
                    {
                        selected = getSelected;
                        var index = selected.indexOf((x: any) => x === value);
                        if (index != -1) {
                            //exist - remove
                            selected = selected.splice(index, 1);
                        }
                        else {
                            selected.push(value);
                        }

                        setSelected(selected);
                    }
                    break;
            }
        }

        if (onRowClick) {
            onRowClick(row, selected);
        }

    } : () => void 0;

    const onRowDoubleClickProxy = onRowDoubleClick ? (row: any) => (ev: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
        if (onRowDoubleClick)
            onRowDoubleClick(row);
    } : () => void 0;

    const classes = useStyles({});
    const [getSelected, setSelected] = React.useState<any[]>([]);
    const keyColumnName = grid.state.columns.find(x => x.IsKey === true);

    let content = null;

    if (gridOptions.selectionType != SelectionType.TYPE_NONE) {
        if (keyColumnName == null)
            throw "For selection functionality key column required";
    }

    if (grid.state.filteredRecordCount === 0 && !grid.state.isLoading) {
        content = <NoDataRow columns={grid.state.columns} styles={styles} title={gridOptions.locale.NoData} />;
    } else {
        content = grid.state.data
            .map((row: any, rowIndex: number) => {
                if (detailComponent) {
                    return (
                        <MasterDetailRow
                            detail={detailComponent}
                            renderCells={RenderCells(grid.state.columns, row)}
                            clickEvent={onRowClickProxy}
                            style={styles.row}
                            key={rowIndex}
                            rowData={row}
                            columns={grid.state.columns}
                        />
                    );
                }

                return (
                    <RowComponent
                        grid={grid}
                        row={row}
                        key={rowIndex}
                        rowIndex={rowIndex}
                        onRowClick={onRowClickProxy(row)}
                        onRowDoubleClick={onRowDoubleClickProxy(row)}
                        selected={keyColumnName != null ? getSelected.some((item) => item == row[keyColumnName.Name]) : false}
                        options={gridOptions}
                    />
                );

            });
    }

    return (
        <TableBody className={gridOptions.fullHeight ? '' : classes.tableBody}>
            {content}
        </TableBody>
    );
};
