import ServiceBase from "./ServiceBase";
//import Globals from "../Globals";

export default class CalculatorService extends ServiceBase {

    static async getOriginators(filter) {
        var result = await this.requestJson({
            url: "api/Calculator/GetOriginators",
            method: "POST",
            data: filter
        });

        return result;
    }

    static async getOriginatorsQuery(value, existId, index, pageSize) {
        var result = await this.requestJson({
            url: "api/Calculator/GetOriginatorsQuery",
            method: "GET",
            data: { query: value, existId: existId, top: pageSize }
        });

        return result;
    }

    static async getAreasQuery(value, bondId, existId, index, pageSize) {
        var result = await this.requestJson({
            url: "api/Calculator/GetAreasQuery",
            method: "GET",
            data: { query: value, bondId: bondId, existId: existId, top: pageSize }
        });

        return result;
    }

    static async createOriginator(data) {

        var result = await this.requestJson({
            url: "api/Calculator/CreateOriginator",
            method: "POST",
            data: data
        });

        return result;
    }

    static async updateOriginator(data) {

        var result = await this.requestJson({
            url: "api/Calculator/UpdateOriginator",
            method: "POST",
            data: data
        });

        return result;
    }

    static async removeOriginator(id) {
        var result = await this.requestJson({
            url: "api/Calculator/RemoveOriginator",
            method: "GET",
            data: { id: id }
        });

        return result;
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    static async getBondsQuery(value, existId, index, pageSize) {
        var result = await this.requestJson({
            url: "api/Calculator/GetBondsQuery",
            method: "GET",
            data: { query: value, existId: existId, top: pageSize }
        });

        return result;
    }

    static async getBonds(filter) {
        var result = await this.requestJson({
            url: "api/Calculator/GetBonds",
            method: "POST",
            data: filter
        });

        return result;
    }

    static async getBondsInfo(items, date) {
        var result = await this.requestJson({
            url: "api/Calculator/GetBondsInfo",
            method: "POST",
            data: { items: items, date: date }
        });

        return result;
    }


    static async createBond(data) {

        var result = await this.requestJson({
            url: "api/Calculator/CreateBond",
            method: "POST",
            data: data
        });

        return result;
    }

    static async updateBond(data) {

        var result = await this.requestJson({
            url: "api/Calculator/UpdateBond",
            method: "POST",
            data: data
        });

        return result;
    }

    static async removeBond(id) {
        var result = await this.requestJson({
            url: "api/Calculator/RemoveBond",
            method: "GET",
            data: { id: id }
        });

        return result;
    }

    static async getBondInformation(id, actualDate) {
        var result = await this.requestJson({
            url: "api/Calculator/GetBondInformation",
            method: "GET",
            data: { id: id, actualDate: actualDate }
        });

        return result;
    }

    static async getBondStatistics(id, actualDate) {
        var result = await this.requestJson({
            url: "api/Calculator/GetBondStatistics",
            method: "GET",
            data: { id: id, actualDate: actualDate }
        });

        return result;
    }

    static async getBondNominal(bondId, date) {
        var result = await this.requestJson({
            url: "api/Calculator/GetBondNominal",
            method: "GET",
            data: { bondId: bondId, date: date }
        });

        return result;
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    static async getPortfoliosStatus(ids) {
        var result = await this.requestJson({
            url: "api/Calculator/GetPortfoliosStatus",
            method: "POST",
            data: ids
        });

        return result;
    }

    static async getPortfolios(filter) {
        var result = await this.requestJson({
            url: "api/Calculator/GetPortfolios",
            method: "POST",
            data: filter
        });

        return result;
    }

    static async createPortfolio(data) {

        var result = await this.sendFormData({
            url: "api/Calculator/CreatePortfolio",
            method: "POST",
            data: data
        });

        return result;
    }

    static async updatePortfolio(data) {

        var result = await this.requestJson({
            url: "api/Calculator/UpdatePortfolio",
            method: "POST",
            data: data
        });

        return result;
    }

    static async removePortfolio(id) {
        var result = await this.requestJson({
            url: "api/Calculator/RemovePortfolio",
            method: "GET",
            data: { id: id }
        });

        return result;
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    static async getCalculations(filter) {
        var result = await this.requestJson({
            url: "api/Calculator/GetCalculations",
            method: "POST",
            data: filter
        });

        return result;
    }

    static async createCalculation(data) {

        var result = await this.requestJson({
            url: "api/Calculator/CreateCalculation",
            method: "POST",
            data: data
        });

        return result;
    }

    static async createSimpleCalculation(data) {

        var result = await this.requestJson({
            url: "api/Calculator/CreateSimpleCalculation",
            method: "POST",
            data: data
        });

        return result;
    }

    static async updateCalculation(data) {

        var result = await this.requestJson({
            url: "api/Calculator/UpdateCalculation",
            method: "POST",
            data: data
        });

        return result;
    }

    static async removeCalculation(id) {
        var result = await this.requestJson({
            url: "api/Calculator/RemoveCalculation",
            method: "GET",
            data: { id: id }
        });

        return result;
    }

    static async getCalculationsStatus(ids) {
        var result = await this.requestJson({
            url: "api/Calculator/GetCalculationsStatus",
            method: "POST",
            data: ids
        });

        return result;
    }

    static async getCalculationResults(id) {
        var result = await this.requestJson({
            url: "api/Calculator/GetCalculationResults",
            method: "GET",
            data: { id: id }
        });

        return result;
    }

    static async getLLD(calculationId) {
        var result = await this.requestJson({
            url: "api/Calculator/GetLLD",
            method: "GET",
            data: { calculationId: calculationId }
        });

        return result;
    }

    static async downloadCalculationResults(calculationId) {
        var result = await this.requestJson({
            url: "api/Calculator/DownloadCalculationResults",
            method: "GET",
            data: { calculationId: calculationId }
        });

        return result;
    }

    static async getCalculation(calculationId) {
        var result = await this.requestJson({
            url: "api/Calculator/GetCalculation",
            method: "GET",
            data: { calculationId: calculationId }
        });

        return result;
    }

    static async recalculateCalculation(data) {
        var result = await this.requestJson({
            url: "api/Calculator/RecalculateCalculation",
            method: "POST",
            data: data
        });

        return result;
    }

    static async recalculateCalculationById(id) {
        var result = await this.requestJson({
            url: "api/Calculator/RecalculateCalculation",
            method: "GET",
            data: { id: id }
        });

        return result;
    }

    static async getProcessingMessages(filter) {
        var result = await this.requestJson({
            url: "api/Calculator/GetProcessingMessages",
            method: "POST",
            data: filter
        });

        return result;
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    static async getZCYCCurves(filter) {
        var result = await this.requestJson({
            url: "api/Calculator/GetZCYCCurves",
            method: "POST",
            data: filter
        });

        return result;
    }

    static async getCurveData(id, date) {
        var result = await this.requestJson({
            url: "api/Calculator/GetCurveData",
            method: "GET",
            data: { id: id, curveDate: date }
        });

        return result;
    }

    static async saveZCYCValues(data) {
        var result = await this.requestJson({
            url: "api/Calculator/SaveZCYCValues",
            method: "POST",
            data: data
        });

        return result;
    }

    static async getBondCPRCDR(id) {
        var result = await this.requestJson({
            url: "api/Calculator/GetBondCPRCDR",
            method: "GET",
            data: { id: id }
        });

        return result;
    }

    static async loadUserLayouts(id) {
        var result = await this.requestJson({
            url: "api/Calculator/LoadUserLayouts",
            method: "GET",
            data: { id: id }
        });

        return result;
    }

    static async removeUserLayout(id) {
        var result = await this.requestJson({
            url: "api/Calculator/RemoveUserLayout",
            method: "GET",
            data: { id: id }
        });

        return result;
    }

    static async saveUserLayout(data) {
        var result = await this.requestJson({
            url: "api/Calculator/SaveUserLayout",
            method: "POST",
            data: data
        });

        return result;
    }
    /////////////////////////////////////////////////////////////////////////////
    static async saveUserLayout(data) {
        var result = await this.requestJson({
            url: "api/Calculator/SaveUserLayout",
            method: "POST",
            data: data
        });

        return result;
    }

    static async getPoolStatistics(date, isLoadRedeemedBonds) {
        var result = await this.requestJson({
            url: "api/Calculator/GetPoolStatistics",
            method: "GET",
            data: { date: date, isLoadRedeemedBonds: isLoadRedeemedBonds }
        });

        return result;
    }

    static async getPoolCDR(date, items, isCorrectedCDR) {
        var result = await this.requestJson({
            url: "api/Calculator/GetPoolCDR",
            method: "POST",
            data: { date: date, items: items, IsCorrectedCDR: isCorrectedCDR }
        });

        return result;
    }

    static async loadMoexSecurities(date) {
        var result = await this.requestJson({
            url: "api/Misc/LoadMoexSecurities",
            method: "GET",
            showError: false,
            data: { date: date }
        });

        return result;
    }

    static async getPoolsTimeline() {
        var result = await this.requestJson({
            url: "api/Calculator/GetPoolsTimeline",
            method: "GET"
        });

        return result;
    }
}