import LinearProgress from '@mui/material/LinearProgress';
import makeStyles from '@mui/styles/makeStyles';
import * as React from 'react';

const useStyles = makeStyles({
    linearProgress: {
        height: '10px',
        marginTop: '5px',
    },
});

export const FixedLinearProgress = ({ isLoading = false }) => {
    const classes = useStyles({});
    return (
        <div className={classes.linearProgress}>
            {
                isLoading &&
                <LinearProgress />
            }
        </div>
    );
};
