import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
// import Select from '@mui/material/Select';
// import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
// import Input from '@mui/material/Input';
import Localization from '../../../compinents/Localization';
//import DatePicker from '@mui/lab/DatePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import { useTheme, Theme } from '@mui/material/styles';
// import createStyles from '@mui/styles/createStyles';
// import makeStyles from '@mui/styles/makeStyles';
// import DateFnsUtils from '@date-io/date-fns';
// import format from 'date-fns/format';
import isValid from 'date-fns/isValid';
import { DefaultNullValue } from "../Common/Definitions";
import { debounce } from 'throttle-debounce';
import { withStyles } from '@mui/styles';
import { DropzoneArea } from '../DropZone';
import { convertBytesToMbsOrKbs } from '../DropZone/helpers';

import TooltipInfoLabel from '../../../components/shared/TooltipInfoLabel';

const BooleanInputOperators = [
    { Value: 'true', Title: 'True' },
    { Value: 'false', Title: 'False' }
];

export enum InputDataType {
    UNKNOWN = 'unknown',
    STRING = 'string',
    FLOAT = 'float',
    INT = 'int',
    BOOLEAN = 'boolean',
    BOOLEAN_TRISTATE = 'boolean_nullable',
    DATE = 'date',
    DATE_TIME = 'datetime',
    DATE_TIME_UTC = 'datetimeutc',
    ENUM = 'enum',
    ENUM_AUTOCOMPLETE = 'enum_autocomplete',
    FILE = 'file'
}

interface IProps {
    handleValueChange(name: string, value: any): void;
    locale: Localization;
    label: string;
    disabled?: boolean;
    value: any;
    fullWidth?: boolean;
    //style?: any;
    classes?: any; //from withStyles
    object?: any;
    tooltip?: string;
    //array
    dataSource: any[];
    loadDataSource: any;

    dataType: InputDataType;
    name: string;
    dataChanged: any[] | any;
}

interface IState {
    open: boolean,
    dataSource: any[],
    selectValue: any,
    loading: boolean
}

const ColumnDataTypeToHtmlType = {
    boolean: 'text',
    date: 'date',
    datetime: 'datetime-local',
    datetimeutc: 'datetime-local',
    numeric: 'number',
    string: 'text',
};

const styles = (theme: any) => ({
    dropzone: {
        minHeight: 'inherit',
        border: '2px dashed ' + theme.palette.grey[500]
    },
    dropZoneLabel: {
        fontSize: theme.typography.body1.fontSize,
        lineHeight: theme.typography.body1.lineHeight,
        letterSpacing: theme.typography.body1.letterSpacing,
    },
    dropZoneCaption: {
        marginTop: '16px',
        fontSize: theme.typography.caption.fontSize,
        lineHeight: theme.typography.caption.lineHeight,
        letterSpacing: theme.typography.caption.letterSpacing,
        color: 'rgba(0, 0, 0, 0.47)'
    },
    preview: {
        '& .MuiGrid-spacing-xs-8': {
            marginTop: '0',
            marginBottom: '0',
            width: 'inherit',
            margin: 'inherit',
            '& .MuiGrid-item':
            {
                padding: '0',
                margin: '32px',
                '& svg': {
                    height: '50px'
                },
                '& p': {
                    fontSize: theme.typography.caption.fontSize,
                    lineHeight: theme.typography.caption.lineHeight,
                    letterSpacing: theme.typography.caption.letterSpacing
                }
            }
        }
    },
    flex: {
        display: 'flex'
    },
    checkbox: {
        marginRight: 0,
        marginBottom: 0
    },
    autocomplete: {
        width: '100%'
    }
});


class DialogInput extends React.PureComponent<IProps, IState>
{
    constructor(props: IProps) {
        super(props);

        this.state = {
            open: false,
            dataSource: [],
            selectValue: '',
            loading: false
        };
    }

    componentDidMount() {

        if (this.props.loadDataSource) {
            if (typeof this.props.loadDataSource === 'function') {
                (async () => {

                    if (this.props.dataSource != null) {
                        this.updateDataSourceState();
                        return;
                    }

                    var localValue = this.getValue(
                        this.props.dataType,
                        this.props.value
                    );

                    var queryValue = localValue;
                    if (queryValue === DefaultNullValue)
                        queryValue = null; //for our query

                    await this.props.loadDataSource("", queryValue, null, null);
                })();
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {

        if (prevProps.dataSource !== this.props.dataSource) {
            this.updateDataSourceState();
        }
    }

    updateDataSourceState = () => {

        var items = [];
        if (this.props.dataSource != null) {
            items = this.props.dataSource.map((x: any) => x.key === null ? { key: DefaultNullValue, value: x.value } : x);
        }

        var localValue = this.getValue(
            this.props.dataType,
            this.props.value
        );

        var emptyValue = items.find((x: any) => x.key === DefaultNullValue);
        if (emptyValue == null) {
            emptyValue = { key: DefaultNullValue, value: '' };
        }

        //response.count - total count of records
        if (this.state.selectValue != null && (this.state.selectValue !== '')) {
            this.setState({ dataSource: items, loading: false });
        }
        else {
            var selectedValue = items.find((x: any) => x.key === localValue);
            if (selectedValue == null) {
                //if (this.props.column.ForceSelection)
                //selectedValue = items.find((x: any) => x.key === DefaultNullValue);
                //else
                selectedValue = emptyValue;
            }

            this.setState({ dataSource: items, selectValue: selectedValue, loading: false });
        }
    }

    getValue = (
        dataType: InputDataType,
        value: any
    ) => {
        switch (dataType) {
            case InputDataType.DATE:
                {
                    if (typeof value == 'string')
                        return value;

                    if (value)
                        return this.props.locale.FormatDateLocale(value);

                    //var date = getDefaultValue(dataType);

                    return null;
                }
            case InputDataType.DATE_TIME:
            case InputDataType.DATE_TIME_UTC:
                {
                    if (typeof value == 'string')
                        return value;

                    if (value)
                        return this.props.locale.FormatDateTimeLocale(value);
                    return null;
                }
            case InputDataType.BOOLEAN:
                {
                    return typeof value === 'boolean' ? value : (value != null ? true : false);
                }
            case InputDataType.ENUM:
            case InputDataType.ENUM_AUTOCOMPLETE:
                {
                    if (value == null)
                        return DefaultNullValue;

                    return value;
                }

            default:
                return value || '';
        }
    };

    loadDataSource = debounce(500, async (searchValue: string) => {

        this.setState({ loading: true });

        this.props.loadDataSource(searchValue, null, null);

        /*
        this.setState({ loading: true });

        var localValue = this.getValue(
            this.props.dataType,
            searchValue
        );

        const response = await this.props.loadDataSource(searchValue, null, null);
        var items = [];
        if (response != null) {
            items = response.items.map((x: any) => x.key === null ? { key: DefaultNullValue, value: x.value } : x);
        }

        var selected = items.find((x: any) => x.key === localValue);
        if (selected == null) {
            this.setState({ loading: false, dataSource: items, selectValue: { key: localValue, value: localValue } });
            this.props.handleValueChange(this.props.name, localValue);
        }
        else {
            this.setState({ loading: false, dataSource: items, selectValue: selected });
        }
        */
    });

    handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.props.handleValueChange(e.target.name, e.target.value);
    }

    handleDateChange = (name: string, date: Date | null, dataType: InputDataType) => {

        if (date != null && isValid(date)) {
            switch (dataType) {
                case InputDataType.DATE:
                        this.props.handleValueChange(name, this.props.locale.FormatDateISO(date));
                    break;
                case InputDataType.DATE_TIME_UTC:

                        this.props.handleValueChange(name, this.props.locale.FormatDateTimeISO(date));
                    break;
            }
        }
    };
    handleCheckChange = (e: React.ChangeEvent<HTMLInputElement>) => this.props.handleValueChange(e.target.name, e.target.checked);
    handleSelectChange = (e: React.ChangeEvent<HTMLInputElement>) => this.props.handleValueChange(e.target.name, e.target.value === DefaultNullValue ? null : e.target.value);
    handleDynamicSelectChange = (e: React.ChangeEvent<{}>, value: any) => {

        this.setState({ selectValue: value });

        this.props.handleValueChange(this.props.name, value == null ? null : (value.key === DefaultNullValue ? null : value.key));

        if (value == null) {
            //update datasource
            this.loadDataSource('');
        }
    }
    handleFileChange = (files: any) => {

        //called later, that user can override data
        if (this.props.dataChanged != null && typeof this.props.dataChanged === 'function') {
            var data = this.props.dataChanged(this.props.name, this.props.object, files);
            if (data != null) {

                data[this.props.name] = files.length !== 0 ? files[0].name : null;

                for (var propertyName in data) {
                    this.props.handleValueChange(propertyName, data[propertyName]);
                }
            }
        }
        else {
            this.props.handleValueChange(this.props.name, files.length !== 0 ? files[0].name : null);
        }
    };

    render() {
        var content = null;

        const {
            dataType,
            name,
            tooltip,
            locale,
            label,
            disabled,
            value,
            fullWidth,
            //style,
            classes
        } = this.props;

        const localValue =
            this.getValue(
                dataType,
                value
            );

        switch (dataType) {
            case InputDataType.DATE:
            case InputDataType.DATE_TIME:
            case InputDataType.DATE_TIME_UTC:
                    content = (
                        <TooltipInfoLabel className={classes.flex} content={tooltip}>
                            <DatePicker
                                views={['date']}
                                allowKeyboardControl={false}
                                //name={name}
                                //variant="inline"
                                //margin="none"
                                disabled={disabled || false}
                                value={localValue}
                                label={label}
                                //style={style}
                                mask={undefined}
                                format={locale.ShortDatePattern()}
                                renderInput={(params) => <TextField {...params} variant="standard"/>}
                                onChange={(e: any) => this.handleDateChange(name, e, dataType)}
                            //fullWidth={fullWidth}
                            />
                        </TooltipInfoLabel>
                    );
                break;
            case InputDataType.BOOLEAN:
                    content = (
                        <TooltipInfoLabel className={classes.flex} content={tooltip}>
                            <FormControlLabel
                                className={classes.checkbox}
                                control={
                                    <Checkbox
                                        id={name}
                                        name={name}
                                        checked={localValue}
                                        onChange={this.handleCheckChange}
                                        color="primary"
                                    />
                                }
                                label={label}
                            />
                        </TooltipInfoLabel>
                    );
                break;
            case InputDataType.FLOAT:
            case InputDataType.INT:
            case InputDataType.BOOLEAN_TRISTATE:
            case InputDataType.STRING:
                    content = (

                        <TooltipInfoLabel className={classes.flex} content={tooltip}>
                            <TextField
                                select={dataType === InputDataType.BOOLEAN_TRISTATE}
                                margin="none"
                                style={style}
                                id={name}
                                name={name}
                                disabled={disabled || false}
                                value={localValue}
                                label={label}
                                type={(ColumnDataTypeToHtmlType as any)[dataType]}
                                onChange={this.handleChange}
                                fullWidth={fullWidth}>
                                {dataType === InputDataType.BOOLEAN_TRISTATE &&
                                    BooleanInputOperators.map((option) => (
                                        <MenuItem key={option.Value} value={option.Value}>
                                            {(locale as any)[option.Title]}
                                        </MenuItem>
                                    ))}
                            </TextField>
                        </TooltipInfoLabel>
                    );
                break;
            case InputDataType.ENUM:
                    content = (
                        <TooltipInfoLabel className={classes.flex} content={tooltip}>
                            <TextField
                                select
                                margin="none"
                                style={style}
                                id={name}
                                name={name}
                                disabled={disabled || false}
                                value={localValue}
                                label={label}
                                type={(ColumnDataTypeToHtmlType as any)[dataType]}
                                onChange={this.handleSelectChange}
                                fullWidth={fullWidth}>
                                {
                                    (this.state.dataSource || []).map((option: any) => (
                                        <MenuItem key={option.key || DefaultNullValue} value={option.key || DefaultNullValue}>
                                            {option.value}
                                        </MenuItem>))

                                    /*
                                    ((typeof column.DataSource == "function" ? column.DataSource() : column.DataSource) || [])
                                            .items.map((option: any) => (
                                                <MenuItem key={option.key || DefaultNullValue} value={option.key || DefaultNullValue}>
                                                    {option.value}
                                </MenuItem>))                       
                                */
                                }
                            </TextField>
                        </TooltipInfoLabel>
                    );
                break;
            case InputDataType.ENUM_AUTOCOMPLETE:
                    content = (
                        <TooltipInfoLabel className={classes.flex} content={tooltip}>
                            <Autocomplete
                                className={classes.autocomplete}
                                id={name + '_autocomplete'}
                                freeSolo={false}//!column.ForceSelection
                                open={this.state.open}
                                onOpen={() => this.setState({ open: true })}
                                onClose={() => this.setState({ open: false })}
                                getOptionLabel={option => option?.value || ''}
                                isOptionEqualToValue={(option, value) => option.key === value.key}
                                value={this.state.selectValue}
                                loadingText={this.props.locale.LoadingWithEllipsis}
                                noOptionsText={this.props.locale.NoData}
                                onChange={this.handleDynamicSelectChange}
                                options={this.state.dataSource}
                                loading={this.state.open && this.state.loading}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        id={name}
                                        name={name}
                                        margin="none"
                                        label={label}
                                        onChange={(event) => {
                                            this.loadDataSource(event.target.value);
                                        }}
                                        fullWidth={fullWidth}
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <React.Fragment>
                                                    {this.state.loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </TooltipInfoLabel>);
                break;
            case InputDataType.FILE:
                    content = (
                        <div className={classes.preview}>
                            <InputLabel className={classes.dropZoneCaption}>{label}</InputLabel>
                            <DropzoneArea
                                filesLimit={1}
                                dropzoneClass={classes.dropzone}
                                dropzoneParagraphClass={classes.dropZoneLabel}
                                dropzoneText={locale.DragOrSelectFileText}
                                showAlerts={false}
                                showPreviews={true}
                                showPreviewsInDropzone={false}
                                showFileNames={true}
                                showFileNamesInPreview={true}
                                maxFileSize={50000000}
                                previewText=''
                                onChange={this.handleFileChange}
                                getFileLimitExceedMessage={(filesLimit) => locale.MaximumNumberFilesExceededFn(filesLimit)}
                                getFileAddedMessage={(fileName) => locale.FileAddedFn(fileName)}
                                getFileRemovedMessage={(fileName) => locale.FileRemoved(fileName)}
                                getDropRejectMessage={(rejectedFile, acceptedFiles, maxFileSize) => {
                                    let message = locale.FileRejectedFn(rejectedFile.name as string);
                                    if (!acceptedFiles.includes(rejectedFile.type)) {
                                        message += locale.FileTypeNotSupported
                                    }
                                    if (rejectedFile.size > maxFileSize) {
                                        message += locale.FileIsTooBigFn(convertBytesToMbsOrKbs(maxFileSize))
                                    }
                                    return message;
                                }}
                            />
                        </div>
                    );
                break;
        }

        return (content);
    }
}

export default withStyles(styles)(DialogInput);