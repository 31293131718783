import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import "@Styles/pages/InstrumentsPage.scss";

import * as InstrumentsStore from "@Store/InstrumentsStore";
import { withRouter } from "../../components/shared/CustomRouter";
import { Theme } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import List from '@mui/material/List';
import ListItem, { ListItemProps } from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import SecurityIcon from '@mui/icons-material/Security';
import DraftsIcon from '@mui/icons-material/Drafts';
import Button from "@mui/material/Button";
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { ValidatorForm, TextValidator } from "@Framework/components/Form";
import { Instruments } from "@Components/Instruments";
import { Spinner } from '../components/shared/Spinner';
import Localization from '@Components/Localization';
import { ProcessingState } from "@Components/Definitions";
import Globals from "../../Globals";
import { withTranslation } from "react-i18next";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

const styles = (theme: Theme) => ({

    formControl: {
        margin: theme.spacing(1),
        minWidth: 200
    },
    container: {
        width: '100%',
        paddingX: '1rem'
    }
});

interface IProps {
    init: any;
    classes: any;
    visible: boolean;
    data: any; //user data
    settingsChanged(data: any): void;
    t: any;
}

interface IState {
}

class CommonSubPage extends React.Component<IProps, IState> {

    constructor(props) {
        super(props);

    }

    componentDidMount() {

    }

    handleStartPageChange = (event: object, child?: object): void => {
        if (this.props.settingsChanged != null) {
            this.props.settingsChanged({ startupUrl: event.target.value});
        }
    }

    render() {

        const { classes, t } = this.props;

        if (this.props.visible === false)
            return null;

        var elements = Instruments()
            .filter(inst => inst.actions != null)
            .map((category, index) => {
                return [].concat(
                    {
                        id: category.id,
                        title: category.title,
                        category: true
                    },
                    (category.actions == null) ? (
                        [{ id: category.id, title: Localization.Translate('StartPage') }]
                    ) : (
                            category.actions
                                .filter(item => item.isHidden !== true && Globals.hasPermissionAccess(item.permissionRequired))
                                .map((action, actionIndex) => (
                                    { id: category.id + '/' + action.id, title: action.name }
                                ))
                        )
                )
            }).flat();

        return (
            <Paper className={classes.container}>
                <Grid container>
                    <FormControl variant="standard" className={classes.formControl}>
                        <InputLabel htmlFor="grouped-select">{t('StartPage')}</InputLabel>
                        <Select defaultValue="" value={this.props.data != null ? this.props.data.startupUrl || "" : ""} id="grouped-select" onChange={this.handleStartPageChange}>
                            <MenuItem value="">{t('Undefined')}</MenuItem>
                            {
                                elements.map((item) => (item.category === true) ? <ListSubheader key={item.id + '_category'}>{item.title}</ListSubheader> : <MenuItem key={item.id} value={item.id}>{item.title}</MenuItem>)
                            }
                        </Select>
                    </FormControl>
                </Grid>
            </Paper>
        );
    }
}

export default withStyles(styles)(withTranslation('translations')(CommonSubPage));

/*
<h4 className="paper col-xl-5 col-md-8">
    Нет компонентов для отображения
</h4>
*/