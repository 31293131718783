import React from 'react';
import { withRouter } from "./CustomRouter";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';

import { alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
// import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
// import Typography from '@mui/material/Typography';
// import InputBase from '@mui/material/InputBase';
// import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { withStyles } from '@mui/styles';

import PageLogo from "@Components/shared/PageLogo";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { /*faEnvelope, faBars,*/ faBell, faUserCircle, faEllipsisV } from '@fortawesome/free-solid-svg-icons'

import * as TopNavbarStore from '@Store/TopNavbarStore';
// import { ProcessingState } from "@Components/Definitions";

import MenuIcon from '@mui/icons-material/Menu';

// import Fab from '@mui/material/Fab';
import Zoom from '@mui/material/Zoom';
// import  from '@mui/icons-material/ExitToApp';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import Globals from "@Base/Globals";
import Localization from "@Components/Localization";

import style from '@Styles/domrf-theme.module.scss';
import { withTranslation } from "react-i18next";

import { LocalizationContext } from "../../LocalizationWrapper";
import { Box, Container, Stack } from '@mui/material';
import { ExitToApp, Language } from '@mui/icons-material';
import useMobileDetect from '../hook/useMobileDetect';
import i18next from 'i18next';

//CHEKME!!! move to scss
const styles = theme => ({
    navbarRoot: {
        alignSelf: 'center',
        // [theme.breakpoints.up(1024)]: {
        //     position: 'sticky',
        //     top: 0,
        // }
    },
    logoImg: {
        '& svg': {
            width: '32px',
            height: '32px',
        }
    },
    titleRoot: {
        display: 'flex',
        flexWrap: 'nowrap',
        flexDirection: 'row',
        margin: 0,
        padding: 0,
        flexGrow: 1,
    },
    titleNavRoot: {
        display: 'flex',
        flexWrap: 'nowrap',
        flexDirection: 'column',
        justifyContent: 'space-between',
        margin: 0,
        padding: 0,
        flexGrow: 1,
        textTransform: 'uppercase',
        position: 'relative',
        paddingLeft: '1rem',
        lineHeight: '1.15rem',
        fontSize: '1.15rem',
        fontWeight: '600',
        '&:before': {
            content: '""',
            position: 'absolute',
            left: 0, width: '3px', top: 0, bottom: 0,
            borderLeft: `solid 3px ${style.colorPrimary2}`
        }
    },
    globalRoot: {
        '& *': {
            userSelect: 'none',
        },
    },
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    sidebarButton: {
        marginLeft: theme.spacing(2),
        //border: 'solid 2px ' + theme.palette.secondary.main
    },
    menuIcon: {
        height: '2.5rem',
        width: '2.5rem'
    },
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    /*
    searchIcon: {
        width: theme.spacing(7),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    */
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 7),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 200,
        },
    },
    sectionDesktop: {
        paddingRight:'1rem',
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    returnToAccountButton:
    {
        color: style.colorError
    },
    languageButton: {
        textTransform: 'uppercase',
        fontSize: 'inherit'
    },
    navbarContainer: {
        zIndex: 1500
    },
    logo: {
        marginRight: '1rem'
    },
    logoText: {
        paddingLeft: '1rem',
        marginBottom: '0',

        textTransform: 'uppercase',
        fontWeight: 'bold',
        fontSize: '1rem',
        lineHeight: '1',
        borderLeft: '3px solid ' + style.colorPrimary2
    },
    linkNoChange: {
        textDecoration: 'none',
        color: 'inherit',

        '&:hover': {
            color: 'inherit',
            textDecoration: 'none'
        }
    },
    paddingLeft: {
        paddingLeft: '1rem'
    },
    marginRight: {
        marginRight: '1rem'
    }
});

class TopNavbar extends React.PureComponent {

    static contextType = LocalizationContext;

    constructor(props) {
        super(props);

        this.state = {
            anchorEl: null,
            menuAnchorEl: null,
            mobileMoreAnchorEl: null,
            buttonVisible: (this.props.area != null && this.props.showHamburger),
            isUserChanged: Globals.isUserSwitched,
            activeLocale: Localization.activeLocale(),
            title: null
        };
    }

    componentDidMount() {
        this.props.init();
        // console.log('navbar',this.props)

        Globals.subscribeToSwitch(this.userChanged);

        if (Localization?.locale?.code != 'ru') { //принудительно переключаем на русский
            // console.log('Localization.locale', Localization.locale)
            Localization.setLocalization('ru')
            localStorage.setItem('lang', 'ru')
        }
    }

    componentDidUpdate(prevProps, prevState) {

        var buttonVisible = (this.props.area !== null && this.props.showHamburger && (this.props.buttonVisible == undefined || this.props.buttonVisible === true));

        if (prevState.buttonVisible !== buttonVisible)
            this.setState({ buttonVisible: buttonVisible });

        //if (this.props.url != prevProps.url) {
        //this.setState({ url: this.props.url });
        //}
    }

    componentWillUnmount() {
        Globals.unsubscribeFromSwitch(this.userChanged);
    }

    userChanged = (changed) => {
        this.setState({ isUserChanged: changed });
    }

    handleMenuOpen = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleLogin = event => {
        this.props.router.navigate("/login");
    }

    handleNotificationsOpen = event => {
        this.props.router.navigate("/notifications");
    };

    handleMobileMenuClose = () => {
        this.setState({ mobileMoreAnchorEl: null });
        //setMobileMoreAnchorEl(null);
    };

    handleMenuClose = () => {
        this.setState({ anchorEl: null });
        //setAnchorEl(null);
        this.handleMobileMenuClose();
    };

    handleLangMenuOpen = (event) => {
        this.setState({ menuAnchorEl: event.currentTarget });
    }

    handleLangMenuClose = () => {
        this.setState({ menuAnchorEl: null });
        this.handleMobileMenuClose();
    }

    handleMenuLang = () => {
        this.handleMenuClose();

        //CHECKME!!! add 
        //this.props.router.navigate("/profile");
    };

    handleMenuProfile = () => {
        this.handleMenuClose();

        this.props.router.navigate("/profile");
    };

    handleMenuLocale = (event) => {

        this.handleLangMenuClose();

        // Globals.locale = event.target.id;

        // this.context.updateLocalization();

        // this.setState({ activeLocale: Localization.activeLocale() });
    }

    handleMenuSignOut = () => {

        this.handleMenuClose();
        this.props.logoutRequest();//.then((results) => {
        //});
    };

    handleMenuSignIn = () => {

        this.handleMenuClose();
        this.props.router.navigate("/login");
    };

    handleMobileMenuOpen = event => {
        this.setState({ mobileMoreAnchorEl: event.currentTarget });
        //setMobileMoreAnchorEl(event.currentTarget);
    };

    showSidebar = (show) => event => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        if (show)
            this.props.showSidebar(show);
    };

    handleReturnToAccount = () => {
        Globals.restoreToUser();
    }

    render() {

        const { classes, t } = this.props;
        const menuId = 'account-menu';
        const langMenuId = 'language-menu';
        const renderLangMenu = (
            <Menu
                anchorEl={this.state.menuAnchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                id={langMenuId}
                keepMounted
                disableScrollLock
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={Boolean(this.state.menuAnchorEl)}
                onClose={this.handleLangMenuClose}
            >
                {
                    Localization.localesList().map(item => {
                        return (<MenuItem key={item.key} id={item.key} onClick={this.handleMenuLocale}>{item.name}</MenuItem>);
                    })
                }
            </Menu>
        );

        const renderMenu = (
            <Menu
                anchorEl={this.state.anchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                id={menuId}
                keepMounted
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={Boolean(this.state.anchorEl)}
                onClose={this.handleMenuClose}
            >
                <MenuItem key='Profile_key' onClick={this.handleMenuProfile}>{t('Profile')}</MenuItem>
                <MenuItem key='Logout_key' onClick={this.handleMenuSignOut}>{t('Logout')}</MenuItem>
            </Menu>
        );

        const mobileMenuId = 'account-menu-mobile';
        const renderMobileMenu = (
            <Menu
                anchorEl={this.state.mobileMoreAnchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                id={mobileMenuId}
                keepMounted
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={Boolean(this.state.mobileMoreAnchorEl)}
                onClose={this.handleMobileMenuClose}>
                {/* //Убираенм переключатель языка
                    <MenuItem key='LanguageSelect_key'
                    onClick={this.handleLangMenuOpen}
                >
                    <IconButton
                        aria-label="ui language"
                        aria-controls={this.langMenuId}
                        aria-haspopup="true"
                        color="inherit"
                        size="large">
                        <Language />
                    </IconButton>
                    <span className={classes.languageButton}>{this.state.activeLocale}</span>
                </MenuItem> */}
                {
                    this.state.isUserChanged && (
                        <MenuItem key='ReturnToAccount_key'
                            onClick={this.handleReturnToAccount}
                        >
                            <IconButton
                                className={classes.returnToAccountButton}
                                aria-label="return to main account"

                                size="large">
                                <ExitToApp />
                            </IconButton>
                            <span>{t('ReturnToMainAccount')}</span>
                        </MenuItem>
                    )
                }

                {/*
                <MenuItem>
                    <IconButton aria-label="show 4 new mails" color="inherit">
                        <Badge badgeContent={4} color="secondary">
                            <FontAwesomeIcon icon={faEnvelope} />
                        </Badge>
                    </IconButton>
                    <p>Сообщения</p>
                </MenuItem>
                <MenuItem>
                    <IconButton aria-label="show 11 new notifications" color="inherit">
                        <Badge badgeContent={11} color="secondary">
                            <FontAwesomeIcon icon={faBell} />
                        </Badge>
                    </IconButton>
                    <p>Уведомления</p>
                </MenuItem>
                */}

                {/*
                    <MenuItem onClick={this.handleMenuLang}>
                        <IconButton
                            aria-label="account of current user"
                            aria-controls="account-menu"
                            aria-haspopup="true"
                            color="inherit">
                            <FontAwesomeIcon icon={faUserCircle} />
                        </IconButton>
                        <p>Профиль</p>
                    </MenuItem>
                */}
                {
                    Globals.isAuthenticated ? [
                        <MenuItem key='Profile_key' onClick={this.handleMenuProfile}>
                            <IconButton
                                aria-label="account of current user"
                                aria-controls="account-menu"
                                aria-haspopup="true"
                                color="inherit"
                                size="large">
                                <FontAwesomeIcon icon={faUserCircle} />
                            </IconButton>
                            <span>{t('Profile')}</span>
                        </MenuItem>,
                        <MenuItem key='Logout_key' onClick={this.handleMenuSignOut}>
                            <IconButton color="inherit" size="large">
                                <ExitToApp />
                            </IconButton>
                            <span>{t('Logout')}</span>
                        </MenuItem>
                    ] : [
                        <MenuItem key='Login_key' onClick={this.handleLogin}>
                            <IconButton color="inherit" size="large">
                                <ExitToApp />
                            </IconButton>
                            <span>{t('Login')}</span>
                        </MenuItem>
                    ]
                }


            </Menu>
        );

        // console.log(Globals.user)
        return (
            <>
                <AppBar //position = "sticky" 
                    className={classes.globalRoot}
                    sx={{ position: { xl: "relative", xs: "sticky" } }}
                >
                    <Container className={[classes.navbarRoot].join(' ')} maxWidth="xxl" sx={{ m: '0 !important', p: '0 !important' }}>
                        <Toolbar sx={{ m: '0 !important', p: '0 !important' }}>
                            <Box className={classes.titleRoot} /*style={{ display: 'flex', flexGrow: 1 }}*/>
                                <PageLogo className={[classes.marginRight, classes.paddingLeft].join(' ')} color="white" />
                                {/* <Stack className={classes.logoText}
                                    sx={{ paddingRight: '1.5rem', marginBottom: 0, justifyContent: 'space-between', width: '100%' }}
                                > */}
                                <Box className={classes.titleNavRoot}>
                                    <Box>
                                        <Link className={classes.linkNoChange} to="/instruments" title={t('Main')}>{t('Instruments')}</Link>
                                    </Box>
                                    {!!this.props.title ?
                                        <Box sx={{
                                            fontSize: '1.15rem', whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            maxWidth: '65vw',
                                            overflow: 'hidden'
                                        }}>
                                            {this.props.title}
                                        </Box> : <></>
                                    }
                                </Box>
                                {/* </Stack> */}
                            </Box>
                            {
                                this.state.isUserChanged && (
                                    <Typography>
                                        {
                                            t('User') + ': ' + Globals.user.firstName
                                        }
                                    </Typography>
                                )
                            }
                            <div>
                                <Zoom
                                    in={this.state.buttonVisible}
                                    timeout={500}
                                    style={{ transitionDelay: "250ms" }}
                                    unmountOnExit>
                                    <IconButton
                                        className={classes.sidebarButton}
                                        aria-label="show sidebar"
                                        color="secondary"
                                        onClick={this.showSidebar(true)}
                                        size="large">
                                        <MenuIcon className={classes.menuIcon} />
                                    </IconButton>
                                </Zoom>
                            </div>
                            {/* <div className={classes.grow} /> */}
                            <div className={classes.sectionDesktop}>
                                {
                                    this.state.isUserChanged && (
                                        <Tooltip
                                            disableFocusListener
                                            disableInteractive
                                            title={t('ReturnToMainAccount')}
                                            aria-label={t('ReturnToMainAccount')}>
                                            <IconButton
                                                className={classes.returnToAccountButton}
                                                aria-label="return to main account"
                                                onClick={this.handleReturnToAccount}
                                                size="large">
                                                <ExitToApp />
                                            </IconButton>
                                        </Tooltip>
                                    )
                                }
                                {/*
                                <Tooltip title={t('Messages')} aria-label={t('Messages')}>
                                    <IconButton aria-label="show 4 new mails" color="inherit">
                                        <Badge badgeContent={4} color="secondary">
                                            <FontAwesomeIcon icon={faEnvelope} />
                                        </Badge>
                                    </IconButton>
                                </Tooltip>
                             */}
                                {/*  //Убираем переключатель языка
                                <Tooltip
                                    disableFocusListener
                                    disableInteractive
                                    title={t('Language')}
                                    aria-label={t('Language')}>
                                    <IconButton
                                        className={classes.languageButton}
                                        aria-label="ui language"
                                        aria-controls={this.langMenuId}
                                        aria-haspopup="true"
                                        onClick={this.handleLangMenuOpen}
                                        color="inherit"
                                        size="large">
                                        {this.state.activeLocale}
                                    </IconButton>
                                </Tooltip> */}

                                {
                                    Globals.hasAccess(Globals.Administrator_Role) && (
                                        <Tooltip
                                            disableFocusListener
                                            disableInteractive
                                            title={t('Notifications')}
                                            aria-label={t('Notifications')}>
                                            <IconButton
                                                aria-label="show 17 new notifications"
                                                color="inherit"
                                                onClick={this.handleNotificationsOpen}
                                                size="large">
                                                {
                                                    <FontAwesomeIcon icon={faBell} />
                                            /*
                                            <Badge badgeContent={17} color="secondary">
                                                <FontAwesomeIcon icon={faBell} />
                                            </Badge>
                                            */}
                                            </IconButton>
                                        </Tooltip>
                                    )
                                }
                                {
                                    Globals.isAuthenticated ? (
                                        <Tooltip
                                            disableFocusListener
                                            disableInteractive
                                            title={t('Profile')}
                                            aria-label={t('Profile')}>
                                            <IconButton
                                                edge="end"
                                                aria-label="account of current user"
                                                aria-controls={this.menuId}
                                                aria-haspopup="true"
                                                onClick={this.handleMenuOpen}
                                                color="inherit"
                                                size="large">
                                                <FontAwesomeIcon icon={faUserCircle} />
                                            </IconButton>
                                        </Tooltip>
                                    ) : (
                                        <Tooltip
                                            disableFocusListener
                                            title={t('Login')}
                                            aria-label={t('Login')}>
                                            <IconButton edge="end" onClick={this.handleLogin} color="inherit" size="large">
                                                <ExitToApp />
                                            </IconButton>
                                        </Tooltip>
                                    )
                                }
                            </div>
                            <div className={classes.sectionMobile}>
                                <IconButton
                                    aria-label="show more"
                                    aria-controls={this.mobileMenuId}
                                    aria-haspopup="true"
                                    onClick={this.handleMobileMenuOpen}
                                    color="inherit"
                                    size="large">
                                    <FontAwesomeIcon icon={faEllipsisV} />
                                </IconButton>
                            </div>
                        </Toolbar>
                    </Container>
                </AppBar>
                {renderMobileMenu}
                {renderLangMenu}
                {renderMenu}
            </>
        );
    }
}

var component = connect(
    state => state.topNavbar, // Selects which state properties are merged into the component's props
    TopNavbarStore.actionCreators // Selects which action creators are merged into the component's
    //dispatch => bindActionCreators(actionCreators, dispatch)
)(TopNavbar);

export default withRouter(withStyles(styles)(withTranslation('translations')(component)));