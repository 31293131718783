import * as React from 'react';
import Globals from "@Base/Globals";

import "@Styles/pages/CalculationResultsPage.scss";

import { Theme } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';

import IconButton from '@mui/material/IconButton';
//import Grid from '@mui/material/Grid';
// import FileCopyOutlined from '@mui/icons-material/FileCopy';

import { default as MuiTable } from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Localization from '@Components/Localization';
import style from '@Styles/domrf-theme.module.scss';
import { CalculationType, BondStatus, FlowTypes, CouponType, CouponTypeStr } from '../../../../components/Definitions';
import { copyTextToClipboard } from '@Utilities';

import TooltipInfoLabel from '@Components/shared/TooltipInfoLabel';
import { withTranslation } from "react-i18next";
import { Box, debounce, Divider, Stack, Typography } from '@mui/material';
import { QueryStatsOutlined, FileCopyOutlined } from '@mui/icons-material';
import { ReactComponent as ExcelIcon } from "../../../../../src/images/excel-icon.svg"
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faFileExcel } from '@fortawesome/free-solid-svg-icons'

const styles = (theme: Theme) => ({
    description: {
        color: style.colorPrimary3,
        fontFamily: style.fontFamilyBase,
        fontSize: theme.typography.subtitle2.fontSize,
        lineHeight: theme.typography.subtitle2.lineHeight,
        letterSpacing: theme.typography.subtitle2.letterSpacing
    },
    tableHeader: {
        fontSize: '14px',
        //fontWeight: '600',
        textTransform: 'uppercase',
        color: style.colorPrimary3
    },
    overflow: {
        //height: '100%',
        overflowY: 'scroll',
        overflowX: 'auto',
        height: '450px'
    },
    toListButton: {
        position: 'absolute',
        left: '15px',
        bottom: '15px',
        zIndex: '1100'
    },
    tabContainer: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    tabButtons: {
        '& .MuiTab-root': {
            fontSize: '14px',
            minWidth: '72px'
        },
    },
    tab: {

        '& div': { //CHECKME!!! temporary!
            padding: '0'
        }
    },
    fullTab: {

        display: 'flex',
        flexDirection: 'column',
        height: '100%',

        '& >div': {
            padding: '0',
            height: '100%'
        }
    },
    fullRowHeight: {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'row',
        height: '100%',
        boxSizing: 'border-box'
    },
    fullColumnHeight: {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column'
        //height: '100%',
        //boxSizing: 'border-box'
    },
    header: {

        [theme.breakpoints.down('sm')]: {
            paddingRight: '10px',
            paddingLeft: '10px',
        }
    },
    dialogTitle: {

        [theme.breakpoints.down('sm')]: {
            padding: '16px'
        }
    },
    container: {

        width: '100%',
        height: '100%',
        paddingRight: '15px',
        paddingLeft: '15px',
        marginRight: 'auto',
        marginLeft: 'auto',
        overflowX: 'hidden',

        [theme.breakpoints.up('xl')]: {
            maxWidth: '1400px'
        },
        [theme.breakpoints.down('xl')]: {
            maxWidth: '1400px'
        },
        [theme.breakpoints.down('xl')]: {
            maxWidth: '1300px'
        },
        [theme.breakpoints.down('lg')]: {
            maxWidth: '800px'
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: '550px'
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
            paddingRight: '0',
            paddingLeft: '0',
        }
    },

    cell: {
        fontWeight: '700',
        padding: '0.25rem 0.5rem'
    },
    toolbar: {
        display: 'flex',
        minHeight: '24px',
        justifyContent: 'flex-end',
        paddingLeft: '2px',
        paddingRight: '2px',
        borderBottom: '1px solid #e0e0e0'
    },
    aboutButton: {
        position: 'absolute',
        left: '15px',
        bottom: '15px',
        zIndex: '1100'
    },
    selectDescription: {
        fontSize: '14px'
    },
    chartButton: {
        border: '1px solid #8BC540'
    },
    buttonsContainer: {
        display: 'flex',
        justifyContent: 'space-between'
        //paddingLeft: '0.5rem',
        //paddingRight: '0.5rem'
    },
    infoText: {
        fontStyle: 'italic',
        fontSize: '14px'
    },
    none: {
        display: 'none'
    },
    flex: {
        display: 'flex'
    },
    historyTableRow: {
        '& td': {
            color: style.colorPrimary3
        }
    },
    usedHistoryTableRow: {
        '& td': {
            color: style.colorPrimary2
        }
    },
    hideButton: {
        position: 'absolute',
        cursor: 'pointer',
        fontSize: '20px',
        top: '0',
        right: '5px',
        zIndex: '1000'
    },
    hidden: {
        display: 'none'
    },
    bondDialog: {
        '& .MuiDialog-container': {

            alignItems: 'flex-start',

            '& .MuiDialog-paper': {
                top: '5%'
            }
        },
    },
    dialogContent: {
        padding: '10px'
    },
    layoutGrid: {
        '& .react-grid-item': {
            padding: '0.2rem'
        }
    },
    toggleLabel: {
        marginBottom: '0'
    },
    flowChartHeader: {
        textAlign: 'center',
        width: '100%',
        position: 'absolute'
    },
    flowChartHeaderContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        minHeight: '30px'
    },
    switchContainer:
    {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    leftValue: {
        borderRight: '1px solid ' + style.colorPrimary3,
        paddingTop: '2px',
        paddingBottom: '1px',
        verticalAlign: 'baseline',
        paddingLeft: 0,//'1rem',
        paddingRight: '1rem'
    },
    panel: {
        //overflow: 'auto'
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
    },
    leftCell: {
        paddingTop: '1px',
        paddingBottom: '1px',
        verticalAlign: 'baseline',
        paddingLeft: '1rem',
        paddingRight: 0
    },
    rightCell: {
        paddingTop: '1px',
        paddingBottom: '1px',
        verticalAlign: 'baseline',
        // width: '10px',
        paddingLeft: '1rem',
        paddingRight: '1rem',
        whiteSpace: 'nowrap'
    },
    warning: {
        color: '#7bad38',
        fontSize: '16px'
    },
    flexContainer: {
        display: 'flex',
        width: '100%',
        justifyItems: 'stretch'
    },
    sCurveButton: {
        padding: '0px 5px 1px 5px',
        fontSize: '1.5rem'
    },
    emptyLabel: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        textAlign: 'center',
        color: style.colorPrimary3
    }
});

//CHEKME!!! move to utilities
const isBondVisible = (status: BondStatus) => {
    return status == BondStatus.STATUS_PLACEMENT || (status == BondStatus.STATUS_NOT_ISSUED && Globals.user.isEmployee);
}

interface IProp {
    classes: any
    t: any
    isUseAdjustedWAM: boolean
    conventionVersion: number
    bondResults: any
    emptyLabel: string
    isFixedCashflow: boolean
    onExportAtExcelButtonClick: any
    evaluationMode: number
}

interface IState {
    bondsHeader: [];
    convMetrics: [];
    bondData: [];
    poolData: [];
    isMobile: boolean
    isLandscape: boolean
    ZCYCDate: typeof Date
}

class StatisticsPanel extends React.PureComponent<IProp, IState> {

    //static whyDidYouRender = true;

    constructor(props) {
        super(props);

        this.state = {
            bondsHeader: [],
            convMetrics: [],
            bondData: [],
            poolData: [],
            isMobile: false,
            isLandscape: false,
        }
    }

    couponFormatter = (value) => {
        switch (value) {
            case CouponTypeStr.FIXED:
                return this.props.t('Fixed');
            case CouponTypeStr.FLOATING:
                return this.props.t('Floating');
            case CouponTypeStr.KEYRATE_AND_PREMIUM:
                {
                    //if (this.state.bondResults != null)
                    //return stringInject(this.props.t('KeyRateAndPremium_Params'), [Localization.FormatNumberString(this.state.bondResults.couponRate)]);

                    return this.props.t('KeyRateAndPremium');
                }
            default:
                return this.props.t('Unknown');
        }
    }


    dateFormatter = (value) => {
        return value != null ? Localization.FormatDateStringLocale(value) : '-';
    }

    handleChartsButtonClick = () => {

        var query = '';
        if (this.props.bondResults != null) {
            query = '?isins=' + this.props.bondResults.isin;
        }
        window.open("/instruments/pools/statistics" + query, "_blank");
    }

    createConvTable = () => [
        [{ property: 'accruedInterest', extraProperty: 'accruedInterestRub', propertyUnit: '%', extraPropertyUnit: '₽', propertyName: this.props.t('ConventionNameAccruedInterest'), propertyDescription: this.props.t('TooltipConventionAccruedInterest'), decimalPlaces: 2 }],
        [{ property: 'dirtyPrice', extraProperty: 'dirtyPriceRub', propertyUnit: '%', extraPropertyUnit: '₽', propertyName: this.props.t('ConventionNameDirtyPrice'), propertyDescription: this.props.t('TooltipConventionDirtyPrice'), decimalPlaces: 2 }],
        [{ property: 'cleanPrice', extraProperty: 'cleanPriceRub', propertyUnit: '%', extraPropertyUnit: '₽', propertyName: this.props.t('ConventionNameCleanPrice'), propertyDescription: '' /*required to update*/, decimalPlaces: 2 }],
        [{ property: 'ytm', propertyName: this.props.t('ConventionNameYTM'), propertyDescription: this.props.t('TooltipConventionYTM'), decimalPlaces: 2 }],
        [{ property: 'zspread', propertyName: this.props.t('ConventionNameZSpread'), propertyDescription: this.props.t('TooltipConventionZspread'), decimalPlaces: 0 }],
        [{ property: 'gspread', propertyName: this.props.t('ConventionNameGSpread'), propertyDescription: this.props.t('TooltipConventionGspread'), decimalPlaces: 0 }],
        [{ property: 'durMac', propertyName: this.props.t('ConventionNameMacaulayDuration'), propertyDescription: this.props.t('TooltipConventionMacDur'), decimalPlaces: 2 }],
        [{ property: 'durMod', propertyName: this.props.t('ConventionNameModifiedDuration'), propertyDescription: this.props.t('TooltipConventionModDur'), decimalPlaces: 2 }]
    ];

    createBondTable = () => [
        [{ property: 'currentNominal', propertyName: this.props.t('ConventionNameCurrentPrincipal'), propertyDescription: this.props.t('TooltipConventionCurNominal'), decimalPlaces: 2 /*required to update*/ }],
        [{ property: 'couponType', propertyName: this.props.t('CouponType'), propertyDescription: ''/*required to update*/, formatter: this.couponFormatter }],
        [{ property: 'paymentFrequency', propertyName: this.props.t('PaymentFrequency'), propertyDescription: this.props.t('PaymentFrequency_Info'), decimalPlaces: 0 }],
        [{ property: 'couponRate', propertyName: ''/*required to update*/, propertyDescription: ''/*required to update*/, decimalPlaces: 2 }],
        [{ property: 'expenses', propertyName: this.props.t('ConventionNameExpenses'), propertyDescription: this.props.t('TooltipConventionExpenses'), decimalPlaces: 2 }],
        [{ property: 'cleanUp', propertyName: this.props.t('CleanUp'), propertyDescription: this.props.t('CleanUp_Info'), decimalPlaces: 0 }],
        [{ property: 'maturityDate', propertyName: this.props.t('LegalMaturityDate'), propertyDescription: this.props.t('LegalMaturityDate_Info'), formatter: this.dateFormatter }]
    ];

    createPoolTable = () => [
        [{ property: 'wac', propertyName: this.props.t('ConventionNameWAC'), propertyDescription: this.props.t('TooltipConventionWAC'), decimalPlaces: 2 }],
        [{ property: 'wala', propertyName: this.props.t('ConventionNameWALA'), propertyDescription: this.props.t('TooltipConventionWALA'), decimalPlaces: 2 }],
        [{ property: 'wam'/*required to update*/, propertyName: this.props.t('ConventionNameWAM')/*required to update*/, propertyDescription: this.props.t('TooltipConventionWAM')/*required to update*/, decimalPlaces: 2, link: 'ru/Сценарный_анализ' }],

        [{ property: 'incentiveToRefinance', propertyName: this.props.t('IncentiveToRefinance'), propertyDescription: this.props.t('IncentiveToRefinance_Info'), decimalPlaces: 2, linkName: this.props.t('Statistics'), onLinkClick: this.handleChartsButtonClick }],
        [{ property: 'zcyccpr', propertyName: this.props.t('ZCYCCPR'), propertyDescription: this.props.t('ZCYCCPR_Info'), decimalPlaces: 2, linkName: this.props.t('Statistics'), onLinkClick: this.handleChartsButtonClick }],
        [{ property: 'cpr', propertyName: this.props.t('ConventionNameHistoricalCPR'), propertyDescription: this.props.t('TooltipConventionHistCPR')/*required to update*/, decimalPlaces: 2, linkName: this.props.t('Statistics'), onLinkClick: this.handleChartsButtonClick }],
        //[{ property: 'totalAverageCPR', propertyName: this.props.t('ConventionNameHistoricalAverageCPR'), propertyDescription: this.props.t('TooltipConventionNameHistoricalAverageCPR')/*required to update*/, decimalPlaces: 2, linkName: this.props.t('Statistics'), onLinkClick: this.handleChartsButtonClick }],
        [{ property: 'cdr', propertyName: this.props.t('ConventionNameHistoricalCDR'), propertyDescription: this.props.t('TooltipConventionHistCDR')/*required to update*/, decimalPlaces: 2, linkName: this.props.t('Statistics'), onLinkClick: this.handleChartsButtonClick }],
        //[{ property: 'totalAverageCDR', propertyName: this.props.t('ConventionNameHistoricalAverageCDR'), propertyDescription: this.props.t('TooltipConventionNameHistoricalAverageCDR')/*required to update*/, decimalPlaces: 2, linkName: this.props.t('Statistics'), onLinkClick: this.handleChartsButtonClick }],
    ];

    convTable = this.createConvTable();
    bondTable = this.createBondTable();
    poolTable = this.createPoolTable();

    onLanguageChanged = (lng: any) => {

        this.convTable = this.createConvTable();
        this.bondTable = this.createBondTable();
        this.poolTable = this.createPoolTable();

        var changes = this.updateVisibleStatsLanguage(this.props.bondResults);

        this.setState(changes);
    }

    updateVisibleStatsLanguage = (bondResults) => {

        var changes: any = {};
        if (bondResults == null)
            return changes;

        var cleanPriceDescription = '';
        var couponTypeDescription = '';
        var couponRateDescription = '';
        var couponRate = '';

        switch (bondResults.couponType) {
            case CouponTypeStr.FIXED:
                {
                    cleanPriceDescription = this.props.t('TooltipConventionCleanPriceFixed');
                    couponTypeDescription = this.props.t('TooltipConventionCouponTypeFixed');
                    couponRate = this.props.t('ConventionNameCouponRate');
                    couponRateDescription = this.props.t('TooltipConventionCouponRate');
                }
                break;
            case CouponTypeStr.FLOATING:
                {
                    cleanPriceDescription = this.props.t('TooltipConventionCleanPriceDirty');
                    couponTypeDescription = this.props.t('TooltipConventionCouponTypeFloating');
                    couponRate = this.props.t('ConventionNameCouponRate');
                    couponRateDescription = this.props.t('TooltipConventionCouponRate');
                }
                break;
            case CouponTypeStr.KEYRATE_AND_PREMIUM:
                {
                    cleanPriceDescription = this.props.t('TooltipConventionCleanPriceFixed');
                    couponTypeDescription = this.props.t('TooltipConventionCouponTypeKeyrate');
                    couponRate = this.props.t('Premium');
                    couponRateDescription = this.props.t('TooltipConventionKeyRate');
                }
                break;
        }

        this.convTable[2][0].propertyDescription = cleanPriceDescription;

        this.bondTable[0][0].decimalPlaces = (bondResults?.currentNominal === 1000 ? 0 : 2);

        this.bondTable[1][0].propertyDescription = couponTypeDescription;
        this.bondTable[3][0].propertyDescription = couponRateDescription;
        this.bondTable[3][0].propertyName = couponRate;

        var isAdjustedWAM = (this.props.isUseAdjustedWAM && this.props.conventionVersion == 1);
        this.poolTable[2][0].property = isAdjustedWAM ? 'adjustedWAM' : 'wam';
        this.poolTable[2][0].propertyName = isAdjustedWAM ? this.props.t('ConventionNameAdjustedWAM') : this.props.t('ConventionNameWAM');
        this.poolTable[2][0].propertyDescription = isAdjustedWAM ? this.props.t('TooltipConventionAdjustedWAM') : this.props.t('TooltipConventionWAM');
        // console.log(this.props.isUseAdjustedWAM, this.props.conventionVersion,this.poolTable[2])
        //this.poolTable[5][0].propertyDescription = (this.props.bondResults?.cpr == null) ? this.props.t('TooltipConventionHistCPRPlacement') : this.props.t('TooltipConventionHistCPR');
        //this.poolTable[7][0].propertyDescription = (this.props.bondResults?.cdr == null) ? this.props.t('TooltipConventionHistCDRPlacement') : this.props.t('TooltipConventionHistCDR');
        // console.log('this.props.bondResults', this.props.bondResults)
        //CHECKME!!! move to utils
        switch (this.props.bondResults.bondStatus) {
            case BondStatus.STATUS_NOT_ISSUED:
                {
                    changes.bondsHeader = [this.props.t("NotIssued")];
                }
                break;
            case BondStatus.STATUS_PLACEMENT:
                {
                    changes.bondsHeader = [this.props.t("BidSubmissions")];
                }
                break;
            case BondStatus.STATUS_ISSUED:
                {
                    changes.bondsHeader = [this.props.t("Outstanding")];
                }
                break;
            case BondStatus.STATUS_REDEEMED:
                {
                    changes.bondsHeader = [this.props.t("Redeemed")];
                }
                break;
        }

        changes.convMetrics = this.convTable.map((item) =>
            [...item].concat(item[0].extraProperty != null ? [this.props.bondResults[item[0].property], this.props.bondResults[item[0].extraProperty]] : [this.props.bondResults[item[0].property] ?? '', null]));
        changes.bondData = this.bondTable.filter(item => {
            switch (this.props.bondResults.couponType) {
                case CouponTypeStr.FIXED:
                case CouponTypeStr.KEYRATE_AND_PREMIUM:
                    return item[0].property != 'expenses';
                case CouponTypeStr.FLOATING:
                    return item[0].property != 'couponRate';
                default:
                    return true;
            }
        }).map((item) => [...item].concat(this.props.bondResults[item[0].property]));

        changes.poolData = this.poolTable.map((item) => [...item].concat(this.props.bondResults[item[0].property] ?? '–'));

        return changes;
    }

    mobileDetect = () => {
        var mql = window.matchMedia("(min-width: 1024px)");
        if (mql.matches)
            return false
        return true
    }

    landscapeDetect = () => {
        var mql = window.matchMedia("(orientation: landscape)");
        if (mql.matches)
            return true
        else
            return false
    }

    componentDidMount() {
        Localization.onLanguageChanged(this.onLanguageChanged);

        if (window)
            window.addEventListener('resize', debounce(() => {
                this.setState({ isMobile: this.mobileDetect(), isLandscape: this.landscapeDetect() })
            }, 100))

        this.setState({ isMobile: this.mobileDetect(), isLandscape: this.landscapeDetect() })
    }

    componentDidUpdate(prevProps, prevState) {

        if (prevProps.bondResults != this.props.bondResults /*&& this.props.bondResults != null*/) {

            var changes = this.updateVisibleStatsLanguage(this.props.bondResults);

            this.setState(changes);
        }
    }

    componentWillUnmount() {
        Localization.offLanguageChanged(this.onLanguageChanged);
    }


    onSaveStatsButtonClick = () => {

        var textToCopy = ""

        // textToCopy += this.props.t('EvaluationParameters') + '\r\n';

        // textToCopy += this.props.t('Bond') + '\t' + this.state.bondsHeader[0] + '\r\n';

        // textToCopy += this.props.t('ConventionNameEnterZCYCDateTime') + '\t' + Localization.FormatDateTimeStringLocale(this.state.ZCYCDate) + '\r\n';
        // textToCopy += (isBondVisible(this.props.bondResults?.bondStatus) ? this.props.t('ConventionNameEnterEvaluationDatePlacement') : this.props.t('ConventionNameEnterEvaluationDate')) + '\t' + Localization.FormatDateStringLocale(this.state.evaluationDate) + '\r\n';
        //textToCopy += this.props.t('ConventionNameEnterCPR') + '\t' + Localization.FormatNumberString(this.state.cpr) + '\r\n';
        //textToCopy += this.props.t('ConventionNameEnterCDR') + '\t' + Localization.FormatNumberString(this.state.cdr) + '\r\n' + '\r\n';

        textToCopy += this.props.t('ConventionNamePricingMetrics') + '\r\n';

        //textToCopy += convMetrics.map((item) => item[0].propertyName + '\t' + Localization.FormatNumberString(item[1])).join('\r\n');

        textToCopy += this.state.convMetrics.map((row) =>
            row.filter(x => x != null).map((cell, index) =>
                index == 0 ? (cell.propertyName) : (row[0].extraProperty != null ?
                    (
                        Localization.FormatNumberString(cell, row[0].decimalPlaces, row[0].decimalPlaces) + ' ' + (index == 1 ? row[0].propertyUnit : row[0].extraPropertyUnit)
                    ) : (
                        row[0].formatter != null ? row[0].formatter(cell) : Localization.FormatNumberString(cell, row[0].decimalPlaces, row[0].decimalPlaces)
                    )
                )).join('\t')
        ).join('\r\n');

        textToCopy += '\r\n' + '\r\n';
        textToCopy += this.props.t('ConventionNameBondParameters') + '\r\n';

        //textToCopy += bondData.map((item) => (item[0].propertyName + '\t' + Localization.FormatNumberString(item[1]))).join('\r\n');

        textToCopy += this.state.bondData.map((row) =>
            row.map((cell, index) =>
                index == 0 ? (cell.propertyName) :
                    (
                        row[0].formatter != null ? row[0].formatter(cell) : Localization.FormatNumberString(cell, row[0].decimalPlaces, row[0].decimalPlaces)
                    )).join('\t')
        ).join('\r\n');

        textToCopy += '\r\n' + '\r\n';
        textToCopy += this.props.t('PoolParameters') + '\r\n';

        textToCopy += this.state.poolData.map((row) =>
            row.map((cell, index) =>
                index == 0 ? (cell.propertyName) : (
                    row[0].formatter != null ? row[0].formatter(cell) : Localization.FormatNumberString(cell, row[0].decimalPlaces, row[0].decimalPlaces)
                )).join('\t')
        ).join('\r\n');

        copyTextToClipboard(textToCopy);
    }

    render() {

        const { classes, t } = this.props;

        return (
            <React.Fragment>
                <Box sx={{
                    display: 'flex', pl: 1,
                    flexWrap: 'nowrap',
                    flexDirection: 'row',
                    alignContent: 'center',
                    alignItems: 'baseline',
                }}>
                    <Typography sx={{ textTransform: 'uppercase', lineHeight: '2rem', maxHeight: '2rem', overflow: 'hidden', ml: 1, flexGrow: 1, fontSize: '14px', color: style.colorPrimary3 }} >
                        {
                            // this.state..map((item, index) =>item)
                            t('ResultPanelTitle')
                        }
                    </Typography>
                    <Box component="span" sx={{ pr: 1 }}>
                        {/* кнопка статистика  */}
                        <Tooltip
                            disableFocusListener
                            title={this.props.t('TooltipConventionCPRGraphsButton')}>
                            {/* <span> */}
                            <IconButton sx={{ width: 30, height: 30 }} onClick={() => this.handleChartsButtonClick()}>
                                <QueryStatsOutlined style={{ fill: style.colorPrimary3 }} />
                            </IconButton>
                            {/* </span> */}
                        </Tooltip>
                    </Box>
                    <Box component="span" sx={(this.props.isFixedCashflow || this.state.isMobile) ? { display: 'none' } : { pr: 1 }}>
                        {/* кнопка 'Excel'  */}
                        {/* {console.log('this.props.evaluationMode', this.props.evaluationMode)} */}
                        <Tooltip
                            disableFocusListener
                            title={
                                (this.props.evaluationMode === 2 || this.props.evaluationMode === 3) ?
                                    this.props.t('TooltipConventionExcelButtonBlocked') :
                                    this.props.bondResults !== null ?
                                        this.props.t('TooltipConventionExcelButton') :
                                        ''
                            }>
                            <span>
                                <IconButton sx={{ width: 29, height: 29, p: 0 }}
                                    disabled={((this.props.bondResults == null) || (this.props.evaluationMode === 2 || this.props.evaluationMode === 3)) || (this.props.bondResults?.bondStatus == BondStatus.STATUS_REDEEMED)}
                                    onClick={() => this.props.onExportAtExcelButtonClick()}>
                                    <ExcelIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </Box>

                    <Box component="span" sx={(this.state.isMobile) ? { display: 'none' } : { pr: 1 }}>
                        {/* кнопка 'Copy'  */}
                        <Tooltip
                            disableFocusListener
                            title={this.props.t('CopyResult')}>
                            <span> { /*A disabled element does not fire events. Tooltip needs to listen to the child element's events to display the title. */}
                                <IconButton sx={{ width: 30, height: 30 }} disabled={this.props.bondResults == null} onClick={() => this.onSaveStatsButtonClick()}>
                                    <FileCopyOutlined />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </Box>

                </Box>
                <Divider/>
                {(this.props.bondResults != null) ? (
                    <Stack sx={{ fontSize: '1rem', lineHeight: '23px' }}>
                        <Box className={classes.cell}>{t('ConventionNamePricingMetrics')}</Box>
                        {this.state.convMetrics.map((row) => {
                            return (
                                <Box key={"row-metrics-" + row[0].property}
                                    sx={{
                                        display: 'flex', width: '100%',
                                        ':nth-of-type(even)': { backgroundColor: style.colorPrimary3_5_tr50 }
                                    }}>
                                    {
                                        row.filter(x => x != null).filter((x, index) => index === 0).map((cell, index) =>
                                            <Box className={classes.leftCell} key={"cell-main-" + row[0].property}
                                                sx={{}}
                                                component="span">
                                                <TooltipInfoLabel key={"tooltip-metrics-" + row[0].property} content={cell.propertyDescription} style={{ verticalAlign: 'bottom' }}>
                                                    {cell.propertyName}
                                                </TooltipInfoLabel>
                                            </Box>
                                        )
                                    }
                                    <Box sx={{ textAlign: 'right', display: 'flex', flexGrow: 1 }}>
                                        {
                                            row[0].extraProperty != null ? (
                                                row.filter(x => x != null).filter((x, index) => index !== 0).map((cell, index) => (
                                                    <Box className={index == 0 ? this.props.classes.leftValue : classes.rightCell}
                                                        key={"cell-metrics-" + row[0].property + '-' + index}
                                                        component="span"
                                                        sx={index === 0 ? { textAlign: "right", marginLeft: 'auto' } :
                                                            { textAlign: "right", minWidth: '110px !important' }}>
                                                        {Localization.FormatNumberString(cell, row[0].decimalPlaces, row[0].decimalPlaces) + ' ' + (index == 0 ? row[0].propertyUnit : row[0].extraPropertyUnit)}
                                                    </Box>
                                                )
                                                )
                                            ) : (
                                                row.filter(x => x != null).filter((x, index) => index !== 0).map((cell, index) => (
                                                    <Box className={classes.rightCell}
                                                        key={"cell-metrics-" + row[0].property + '-' + index}
                                                        component="span"
                                                        sx={{ textAlign: "right", marginLeft: 'auto' }}>
                                                        {row[0].formatter != null ? row[0].formatter(cell) : Localization.FormatNumberString(cell, row[0].decimalPlaces, row[0].decimalPlaces)
                                                        }</Box>
                                                )
                                                )
                                            )
                                        }


                                    </Box>
                                </Box>
                            )
                        })}

                        {/* <MuiTable aria-label="results table" >
                    <TableBody>
                        <TableRow xs={{verticalAlign: 'baseline'}}><TableCell colSpan={3} className={classes.cell}>{t('ConventionNamePricingMetrics')}</TableCell></TableRow>
                        {
                            this.state.convMetrics.map((row) => {
                                return (
                                    <TableRow key={"row-metrics-" + row[0].property}>
                                        {
                                            row.filter(x => x != null).map((cell, index) =>
                                                index == 0 ? (
                                                    <TableCell className={classes.leftCell} colSpan={row[0].extraProperty != null ? null : 2} key={"cell-main-" + row[0].property}>
                                                        <TooltipInfoLabel key={"tooltip-metrics-" + row[0].property} content={cell.propertyDescription} style={{verticalAlign: 'bottom'}}>
                                                            {cell.propertyName}
                                                        </TooltipInfoLabel>
                                                    </TableCell>
                                                ) : (
                                                    row[0].extraProperty != null ? (
                                                        <TableCell className={index == 1 ? this.props.classes.leftValue : classes.rightCell} key={"cell-metrics-" + row[0].property + '-' + index} align="right">{Localization.FormatNumberString(cell, row[0].decimalPlaces, row[0].decimalPlaces) + ' ' + (index == 1 ? row[0].propertyUnit : row[0].extraPropertyUnit)}</TableCell>
                                                    ) : (
                                                        <TableCell className={classes.rightCell} key={"cell-metrics-" + row[0].property + '-' + index} align="right">{row[0].formatter != null ? row[0].formatter(cell) : Localization.FormatNumberString(cell, row[0].decimalPlaces, row[0].decimalPlaces)}</TableCell>
                                                    )
                                                ))
                                        }
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </MuiTable> */}
                        <Box className={classes.cell}>{t('ConventionNameBondParameters')}</Box>
                        {this.state.bondData.map((row) => {
                            return (
                                <Box key={"row-metrics-" + row[0].property}
                                    sx={{
                                        display: 'flex', width: '100%',
                                        ':nth-of-type(even)': { backgroundColor: style.colorPrimary3_5_tr50 }
                                    }}>
                                    {
                                        row.map((cell, index) =>
                                            index == 0 ? (<Box className={classes.leftCell} key={"cell-main-" + row[0].property}
                                                sx={{}}
                                                component="span">
                                                <TooltipInfoLabel key={"tooltip-metrics-" + row[0].property} content={cell.propertyDescription} style={{ verticalAlign: 'bottom' }}>
                                                    {cell.propertyName}
                                                </TooltipInfoLabel>
                                            </Box>
                                            ) :
                                                (
                                                    <Box className={classes.rightCell}
                                                        key={"cell-metrics-" + row[0].property + '-' + index}
                                                        component="span"
                                                        sx={{ textAlign: "right", marginLeft: 'auto' }}>
                                                        {row[0].formatter != null ? row[0].formatter(cell) : Localization.FormatNumberString(cell, row[0].decimalPlaces, row[0].decimalPlaces)
                                                        }</Box>
                                                )

                                        )
                                    }

                                </Box>
                            )
                        })}
                        {/* <MuiTable aria-label="bond table">
                    <TableBody>
                        <TableRow><TableCell colSpan={3} className={classes.cell}>{t('ConventionNameBondParameters')}</TableCell></TableRow>
                        {
                            this.state.bondData.map((row) => {
                                return (
                                    <TableRow key={'row-' + row[0].property}>
                                        {
                                            row.map((cell, index) =>
                                                index == 0 ? (
                                                    <TableCell className={classes.leftCell} colSpan={2} key={"main-cell-bond-" + row[0].property}>
                                                        <TooltipInfoLabel style={{verticalAlign: 'bottom'}} key={"tooltip-bond-" + row[0].property + '-' + index} content={cell.propertyDescription} link={row[0].link} linkName={row[0].linkName} onLinkClick={row[0].onLinkClick}>
                                                            {cell.propertyName}
                                                        </TooltipInfoLabel>
                                                    </TableCell>
                                                ) : (
                                                    <TableCell className={classes.rightCell} key={"cell-bond-" + row[0].property + '-' + index} align="right">{row[0].formatter != null ? row[0].formatter(cell) : Localization.FormatNumberString(cell, row[0].decimalPlaces, row[0].decimalPlaces)}</TableCell>
                                                ))
                                        }
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </MuiTable> */}
                        <Box className={classes.cell}>{t('PoolParameters')}</Box>
                        {this.state.poolData.map((row) => {
                            return (
                                <Box key={"row-metrics-" + row[0].property}
                                    sx={{
                                        display: 'flex', width: '100%',
                                        ':nth-of-type(even)': { backgroundColor: style.colorPrimary3_5_tr50 }
                                    }}>
                                    {
                                        row.map((cell, index) =>
                                            index == 0 ? (<Box className={classes.leftCell} key={"cell-main-" + row[0].property}
                                                sx={{}}
                                                component="span">
                                                <TooltipInfoLabel key={"tooltip-metrics-" + row[0].property} content={cell.propertyDescription} style={{ verticalAlign: 'bottom' }}>
                                                    {cell.propertyName}
                                                </TooltipInfoLabel>
                                            </Box>
                                            ) :
                                                (
                                                    <Box className={classes.rightCell}
                                                        key={"cell-metrics-" + row[0].property + '-' + index}
                                                        component="span"
                                                        sx={{ textAlign: "right", marginLeft: 'auto' }}>
                                                        {row[0].formatter != null ? row[0].formatter(cell) : Localization.FormatNumberString(cell, row[0].decimalPlaces, row[0].decimalPlaces)
                                                        }</Box>
                                                )

                                        )
                                    }

                                </Box>
                            )
                        })}
                        {/* <MuiTable aria-label="pool table">
                    <TableBody>
                        <TableRow><TableCell colSpan={3} className={classes.cell}>{t('PoolParameters')}</TableCell></TableRow>
                        {
                            this.state.poolData.map((row) => {
                                return (
                                    <TableRow key={'row-pool-' + row[0].property}>
                                        {
                                            row.map((cell, index) =>
                                                index == 0 ? (
                                                    <TableCell className={classes.leftCell} sx={{whiteSpace:'nowrap'}} colSpan={2} key={"main-cell-pool-" + row[0].property}>
                                                        <TooltipInfoLabel sx={{verticalAlign: 'bottom'}} key={"tooltip-pool-" + row[0].property} content={cell.propertyDescription} link={row[0].link} linkName={row[0].linkName} onLinkClick={row[0].onLinkClick}>
                                                            {cell.propertyName}
                                                        </TooltipInfoLabel>
                                                    </TableCell>
                                                ) : (
                                                    <TableCell className={classes.rightCell} key={"cell-pool-" + row[0].property} align="right">{row[0].formatter != null ? row[0].formatter(cell) : Localization.FormatNumberString(cell, row[0].decimalPlaces, row[0].decimalPlaces)}</TableCell>
                                                ))
                                        }
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </MuiTable> */}
                    </Stack>
                ) : (
                    <Box className={classes.emptyLabel} sx={{ m: 2 }}>{this.props.t(this.props.emptyLabel)}</Box>
                )
                }
            </React.Fragment>
        )
    }
}

export default withStyles(styles)(withTranslation('translations')(StatisticsPanel));