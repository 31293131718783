import * as React from 'react';

import { withStyles } from '@mui/styles';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
// import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';

import ErrorIcon from '@mui/icons-material/Error';

import { DataGrid, GridOptions } from "../../framework/components/Table";
import { ColumnModel, SelectionType } from "../../framework/components/Table/Common";

import CalculatorService from "../../services/CalculatorService";
// import CommonService from "../../services/CommonService";
import SiteHelper from "../../shared/SiteHelper";
// import { CouponType } from "../../components/Definitions";

// import { DataSourceChangeType } from '../../framework/components/Common/Definitions';
import { /*AggregateFunctions, ColumnSortDirection,*/ ColumnDataType, TrimContentType } from '../../framework/components/Table/Common/Models/Column';

// import Localization from '../../components/Localization';

import { MessageType, EntityType } from '../Definitions';
import style from '@Styles/domrf-theme.module.scss';
import { withTranslation } from "react-i18next";

interface IProps {
    show: false,
    onClose: any;
    classes: any;
    entityId: number;
    entityType: EntityType;
    t: any;
}

interface IState {
    entityId: number;
    entityType: EntityType;
    selected: any;
}

const styles = (theme: any) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
    },
    warningIcon: {
        color: style.colorWarning,
        marginLeft: '0.5rem',
        marginRight: '0.5rem'
    },
    errorIcon: {
        color: style.colorError,
        marginLeft: '0.5rem',
        marginRight: '0.5rem'

    },
    infoIcon: {
        marginLeft: '0.5rem',
        marginRight: '0.5rem'
    }
});

class MessagesListDialog extends React.Component<IProps, IState> {

    constructor(props: any) {
        super(props);
        this.state = {
            entityId: this.props.entityId,
            entityType: this.props.entityType,
            selected: ''
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.entityId !== this.props.entityId) {
            this.setState({ entityId: this.props.entityId, entityType: this.props.entityType });
        }
    }

    onWindowClose = (event) => {
        if (this.props.onClose)
            this.props.onClose(event);
    }

    dataSourceQuery = async (request: any) => {

        var filter = SiteHelper.CreateFilter(request);
        filter.Params = { id: this.state.entityId, type: this.state.entityType };

        var result = await CalculatorService.getProcessingMessages(filter);
        if (result.hasErrors) {
            throw ('Error while processing request');
        }

        //IGridPayload
        return {
            totalCount: result.value.count,
            payload: result.value.items
        };
    }

    renderTypeCell = (row: any) => {
        switch (row.type) {
            case MessageType.TYPE_INFORMATIONAL:
                {
                    return (
                        <div>
                            <ErrorIcon className={this.props.classes.infoIcon} color="secondary" /><span>{this.props.t('Information')}</span>
                        </div>
                    );
                }
            case MessageType.TYPE_WARNING:
                {
                    return (
                        <div>
                            <ErrorIcon className={this.props.classes.warningIcon} /><span>{this.props.t('Warning')}</span>
                        </div>
                    );
                }
            case MessageType.TYPE_ERROR:
                {
                    return (
                        <div>
                            <ErrorIcon className={this.props.classes.errorIcon} /><span>{this.props.t('Error')}</span>
                        </div>
                    );
                }
        }

        return this.props.t('Unknown');
    }

    columns = [

        new ColumnModel('id', { DataType: ColumnDataType.INT, IsKey: true, Visible: false }),
        new ColumnModel('type', { Label: this.props.t('Type'), DataType: ColumnDataType.INT, Render: this.renderTypeCell }),
        new ColumnModel('position', { Label: this.props.t('Position'), DataType: ColumnDataType.STRING, TrimContent: TrimContentType.ELLIPSIS }),
        new ColumnModel('description', { Label: this.props.t('Description'), DataType: ColumnDataType.STRING, TrimContent: TrimContentType.ELLIPSIS })
    ];

    settings = { query: this.dataSourceQuery };

    gridOptions = new GridOptions({
        topPager: false,
        fixedHeader: true,
        fullHeight: true,
        canSearch: true,
        selectionType: SelectionType.TYPE_SINGLE
    });

    handleRowClick = (row: any, selected: any[]) => {
        this.setState({ selected: row.description });
    }

    render() {

        const {
            classes,
            t
        } = this.props;

        return (

            <Dialog fullScreen onClose={this.onWindowClose} aria-labelledby="errors-dialog-title" open={this.props.show}>
                <MuiDialogContent className={classes.root}>
                    <DataGrid
                        gridName={t('Results')}
                        dataSource={{ query: this.dataSourceQuery }}
                        columns={this.columns}
                        gridOptions={this.gridOptions}
                        onRowClick={this.handleRowClick}
                    />
                    <TextField
                        id="details-id"
                        multiline
                        disabled
                        rows="4"
                        value={this.state.selected}
                        variant="outlined"
                    />
                </MuiDialogContent>
                <MuiDialogActions>
                    <Button variant="contained" color="secondary" onClick={this.onWindowClose}>
                        {t('Close')}
                    </Button>
                </MuiDialogActions>
            </Dialog>
        );
    }
}

export default withStyles(styles)(withTranslation('translations')(MessagesListDialog));