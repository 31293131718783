import React, { useState, useEffect } from 'react'

function useLocalStorage(initialValue, key) {
    const getValue = () => {
        const storage = localStorage.getItem(key); //string || null
        // console.log('storage', storage)
        if (Array.isArray(storage)) {
            return storage
        }
        else if (storage) {
            return JSON.parse(storage); // '[]'
        }
        return initialValue;
    }

    const [value, setValue] = useState(getValue);

    useEffect(() => {
        localStorage.setItem(key, JSON.stringify(value));
    }, [value]);

    return [value, setValue];

}

export default useLocalStorage