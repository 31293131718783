import * as React from 'react';
import ColumnModel from '../Common/Models/ColumnModel';
import { DataGridCard, GridOptions } from '../DataGrid';
import { IDataGrid } from '../DataGridInterfaces/IDataGrid';

interface IProps {
    grid: IDataGrid,
    onRowClick: any;
    row: any;
    options: GridOptions;
}

export const TbMobileRow: React.FunctionComponent<IProps> = ({
    grid,
    onRowClick,
    row,
    options
}) => {
    return (
        <DataGridCard
            grid={grid}
            columns={grid.state.columns}
            item={row}
            gridOptions={options}
            onClickCallback={onRowClick}
        />
    );
};
