import React, { useEffect } from 'react'
import { Theme, Grid, Box, Container } from '@mui/material'
import Helmet from "react-helmet"
import { useDispatch } from 'react-redux'
import { actionCreators as actionTopNavbar } from '@Base/store/TopNavbarStore'
import { t } from 'i18next'
import CompareBondList from './components/CompareBondList'
import CompareFieldList from './components/CompareFieldList'
import CompareDateSelector from './components/CompareDateSelector'
import CompareCharts from './components/CompareCharts'
import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) => createStyles({
    rootContainer: {
        '&:parent': { backgroundColor: '#0001', },
        '& >*>*': {
            // border: 'solid 1px #0002',
        },
        marginTop: '1rem',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        overflow: 'hidden',
        position: 'relative',
    },
    rootGrid: {
        flexGrow: 1,
        alignContent: 'flex-start',
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        display: 'flex',
        // border: 'solid 10px #000'
    },
    gridItem: {
        position: 'relative',
        display: 'flex',
    },
    chartArea: {
        flexGrow: 1,
    },
    rowBlock: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
    },
    topBlock: {

    },
    bottomBlock: {
        flexGrow: 1,
        position: 'relative',
    }
}))
const Comparation = () => {
    const cls = useStyles()
    const dispatch = useDispatch()

    useEffect(() => { //установка\сброс title в NavBar
        dispatch(actionTopNavbar.setTitle(t('quotes.instrumentTitle')))

        return () => {
            dispatch(actionTopNavbar.setTitle(''))
        }
    }, [])

    return (<>
        <Helmet>
            <meta charSet="utf-8" />
            <title>{t('quotes.instrumentTitle')}</title>
        </Helmet>
        {/* <Box className={cls.alertLayout}>
            {
                alerts.map(alert => <Alert key={`alert_${alert.id}`} id={alert.id} text={alert.message} color={alert.color} timeout={6000} />)
            }
        </Box> */}
        <Container
            maxWidth={'xxl'}
            className={cls.rootContainer}
        >
            <Grid container wrap='nowrap' className={cls.rootGrid}>
                <Grid xs={4.5} minWidth={520} item className={cls.gridItem}>
                    <Grid container wrap='nowrap' className={cls.rootGrid}>
                        <Grid xs={6.8} minWidth={305} item className={cls.gridItem}>
                            <CompareBondList />
                        </Grid>
                        <Grid xs={4.2} minWidth={215} item>
                            <CompareFieldList />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid xs={7.5} item className={cls.rowBlock}>
                    <Box className={cls.topBlock}>
                        <CompareDateSelector />
                    </Box>
                    <Box className={cls.bottomBlock}>
                        <CompareCharts />
                    </Box>
                </Grid>
            </Grid>
        </Container>
    </>
    )
}

export default Comparation