import useLocalStorage from '@Base/components/hook/useLocalStorage';
import { CompareArrowsOutlined, CompareOutlined, Menu as MenuIcon } from '@mui/icons-material'
import { alpha, Box, IconButton, Popover, Popper, SpeedDial, SpeedDialAction, Tooltip } from '@mui/material'
import { makeStyles } from '@mui/styles';
import style from '@Styles/domrf-theme.module.scss'
import { t } from 'i18next'
import { actionCreators as actionSecuritization } from '@Base/store/SecuritizationStore'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import CustomMenu from '@Base/components/shared/CustomMenu';
import ConfirmationForm from '@Base/components/shared/ConfirmationForm';
import CreateNodePool from './CreateNodePool';

const useStyles = makeStyles({
    root: {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        // opacity: 0,
        // transition: 'all 0.7s ease-in-out',
        left: 0, top: 0,
        // pointerEvents: 'auto',
    },
    popper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '75px',
        '&>*': {
            margin: '0 5px',
        },
    },
    actionIcon: {
        width: '24px',
        // height: '24px',
        '& *': { padding: '0 !important', margin: 0 },
    },
    fab: {
        // backgroundColor: style.colorPrimary2,
        // '&:hover': {
        //     backgroundColor: `${alpha(style.colorPrimary3, 0.3)}`
        // }
    }
})


function ServiceMenu({ pool = null, anchor = null, mouseOutEvent, poolId = null }) {

    const classes = useStyles()
    const dispatch = useDispatch()
    // const anchorEl = useRef(null)
    const [poolCompareArray, setPoolCompareArray] = useLocalStorage([], 'Securitization/PoolCompareArray')
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)
    const [openAddNodePool, setOpenAddNodePool] = useState(false)

    useEffect(() => {
        actionSecuritization.setPoolCompareArray(poolCompareArray)(dispatch)
    }, [poolCompareArray])

    const atStorage = poolCompareArray?.some(x => x == poolId)

    const addFunc = () => {
        setPoolCompareArray([...poolCompareArray, poolId])
    }

    const removeFunc = () => {
        if (atStorage) {
            const index = poolCompareArray.findIndex(x => x == poolId)
            setPoolCompareArray([...poolCompareArray.slice(0, index), ...poolCompareArray.slice(index + 1)])
        }
    }

    // const addNodePool = () => {
    //     if (pool?.nodeType != null) {
    //         // setCurrentNodePoolId(selectedNodeId)
    //         // setAddNodePoolType(type)
    //         setOpenAddNodePool(true)
    //     }
    //     else {
    //         console.log('type is empty')
    //     }
    // }
    // const downloadPool = () => {
    //     if (pool?.id != null)
    //         window.open(`/api/securitization/DownloadPoolLLD?poolId=${pool?.id}`, "_blank");
    // }

    // const downloadStatistics = () => {
    //     if (pool?.id != null)
    //         window.open(`/api/securitization/DownloadPoolStatistics?poolId=${pool?.id}`, "_blank");
    // }

    // const downloadPoolFlows = () => {
    //     if (pool?.id != null)
    //         window.open(`/api/securitization/DownloadPoolSchduledFlows?poolId=${pool?.id}`, "_blank");
    // }

    // const downloadMonthlyPoolFlows = () => {
    //     if (pool?.id != null)
    //         window.open(`/api/securitization/DownloadPoolSchduledFlowsMonthly?poolId=${pool?.id}`, "_blank");
    // }
    // const removeNodeHandler = () => {
    //     setShowConfirmationDialog(true);
    // }
    // const applyNodeRemove = () => {

    //     setShowConfirmationDialog(false);

    //     if (pool?.poolNodeID == null)
    //         return;

    //     actionSecuritization.removeNode(pool?.poolNodeID)(dispatch);
    // }

    // const declineNodeRemove = () => {
    //     setShowConfirmationDialog(false);
    // }

    // const closeAddNodePool = () => {
    //     setOpenAddNodePool(false)
    // }

    // const downloadTemplate = () => {
    //     // console.log('download template')
    //     window.open('/files/Шаблон для загрузки пула.xlsx', '_blank')
    // }

    const actions = [
        {
            key: 'serviceMenu_0',
            icon: <CompareArrowsOutlined className={classes.actionIcon} />,
            tooltip: t(!atStorage ? "AddToCompare" : "RemoveFromCompare"),
            tooltipPlacement: 'top',
            action: !atStorage ? addFunc : removeFunc,
            sx: {
                backgroundColor: !atStorage ? '#FFF' : style.colorPrimary2_40,
                borderColor: !atStorage ? style.colorPrimary3_20 : style.colorPrimary2_60,
                borderStyle: 'solid',
                borderWidth: '1px',
                minHeight: '34px', height: '34px', width: '34px',
                '&:hover': {

                    color: !atStorage ? style.colorPrimary3 : '#FFF',
                    backgroundColor: !atStorage ? style.colorPrimary3_20 : style.colorPrimary2_40,
                }
            }
        },
        // {
        //     key: 'serviceMenu_1',
        //     icon: <CustomMenu
        //         icon={<MenuIcon />}
        //         className={classes.actionIcon}
        //         // disabled={selectedPool?.status !== PoolProcessingStatus.STATUS_SUCCESS && selectedPool?.status !== PoolProcessingStatus.STATUS_FAILED}
        //         items={
        //             [
        //                 { id: 1, name: t("RenewPool"), handle: () => addNodePool() },
        //                 { id: 2, name: t("SecuritizationDownloadPool"), handle: downloadPool },
        //                 { id: 3, name: t("DownloadPoolStatistics"), handle: downloadStatistics },
        //                 { id: 4, name: t("SecuritizationDownloadPoolFlows"), handle: downloadPoolFlows },
        //                 { id: 5, name: t("SecuritizationDownloadMonthlyPoolFlows"), handle: downloadMonthlyPoolFlows },
        //                 { id: 6, divider: true },
        //                 { id: 7, name: t("RemovePool"), handle: removeNodeHandler }
        //                 /*{ id: 3, name: t("SucuritizationRemovePool"), handle: deletePool },*/
        //             ]}
        //     />,
        //     tooltip: "",
        //     tooltipPlacement: 'bottom',
        //     // action: !atStorage ? addFunc : removeFunc,
        //     sx: {
        //         minHeight: '34px', height: '34px', width: '34px',
        //     }
        // },
    ]

    if (anchor == null) return (<></>)

    return <>
        {/* {showConfirmationDialog && <ConfirmationForm
            isOpen={showConfirmationDialog}
            header={t("RemovePool")}
            content={t("PoolRemoveWarning")}
            onAccept={applyNodeRemove}
            onDecline={declineNodeRemove}
        />}
        {openAddNodePool &&
            <CreateNodePool
                currentNodePoolId={pool?.poolNodeID}
                addNodePoolType={pool?.nodeType}
                closeAddNodePool={closeAddNodePool}
                downloadTemplate={downloadTemplate}
            />} */}
        <Popper
            anchorEl={anchor}
            open={Boolean(anchor)}
            placement={'right-start'}
            className={classes.popper}
        >
            {actions.map((action) => (
                <SpeedDialAction
                    open={Boolean(anchor)}
                    delay={300}
                    key={action.key}
                    icon={action.icon}
                    tooltipTitle={action?.tooltip}
                    onClick={action?.action}
                    sx={action?.sx}
                    tooltipPlacement={action?.tooltipPlacement}
                // classes={{}}
                />
            ))}
        </Popper>
    </>
}

export default ServiceMenu