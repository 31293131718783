import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import AddBox from '@mui/icons-material/AddBox';
import * as React from 'react';

interface IButtonProps {
    toolTip?: string;
    onButtonClick: () => void;
}

export const CreateNewButton: React.FunctionComponent<IButtonProps> = ({
    toolTip,
    onButtonClick
}) => {

    return (
        <React.Fragment>
            <IconButton onClick={onButtonClick} size="large">
                <Tooltip
                    disableFocusListener
                    title={toolTip || 'Create new record'}>
                    <AddBox />
                </Tooltip>
            </IconButton>
        </React.Fragment>
    );
};
