import TableCell from '@mui/material/TableCell';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import CheckBoxOutlineBlank from '@mui/icons-material/CheckBoxOutlineBlank';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import Tooltip from '@mui/material/Tooltip';

import * as React from 'react';
import { ColumnDataType, ColumnModel, formatDate, getColumnAlign, ActionType } from '../Common';


//import { Localization } from '../DataGrid/Localization';
//import { makeStyles, createStyles, Theme } from '@mui/material/styles';

import SpeedDial, { SpeedDialProps } from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import { GridOptions } from '../DataGrid';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import HelpIcon from '@mui/icons-material/Help';
import { IDataGrid } from '../DataGridInterfaces/IDataGrid';
import { DataSourceChangeType } from "../../Common/Definitions";
import Action from "../Common/Models/Action";
import { DSResult } from "../Common/Models/ColumnModel";

/*
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        speedDial: {
            '& .MuiSpeedDial-actions': {
                zIndex: 1
            }
        }
    })
);
*/

export const RenderCellContent = (grid: IDataGrid, column: ColumnModel, row: any, options: GridOptions) => {

    const [open, setOpen] = React.useState(false);
    //const classes = useStyles({});

    const handleMenuClick = (evt: any, action: Action, row: any) => {

        if (action.onClick != null && typeof action.onClick === 'function') {
            var result = action.onClick(action, row);
            if (result === true) {
                return;
            }
        }

        switch (action.Type) {
            case ActionType.TYPE_EDIT:
                {
                    grid.api.updateRecord(row);
                }
                break;
            case ActionType.TYPE_REMOVE:
                {
                    grid.api.removeRecord(row);
                }
                break;
        }

        setOpen(false);
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {

        setOpen(true);
    };

    if (column.IsAction) {

        var actions : any[] = [];

        if (column.Actions != null) {
            for (var item of column.Actions) {

                switch (item.Type) {
                    case ActionType.TYPE_REMOVE:
                        {
                            var result = { ...{ Icon: <DeleteIcon />, Name: options.locale.Delete }, ...item };
                            actions.push(result);
                        }
                        break;
                    case ActionType.TYPE_EDIT:
                        {
                            var result = { ...{ Icon: <EditIcon />, Name: options.locale.Edit }, ...item };
                            actions.push(result);
                        }
                        break;
                    case ActionType.TYPE_CUSTOM:
                        {
                            var result = { ...{ Icon: <HelpIcon />, Name: options.locale.Undefined }, ...item };
                            actions.push(result);
                        }
                        break;
                }   
            }
        }

        return (
            <SpeedDial
                ariaLabel="Actions"
                icon={<SpeedDialIcon />}
                onClose={handleClose}
                onOpen={handleOpen}
                open={open}
                direction="left"
                FabProps={{ color: "default" }}>

                {actions.map(action => (
                    <SpeedDialAction
                        key={action.Name}
                        icon={action.Icon}
                        tooltipTitle={action.Name}
                        onClick={(evt: any) => handleMenuClick(evt, action, row)}
                    />
                ))}
            </SpeedDial>
        );
    }
    else {

        var content : any = null;

        if (column.Render != null)
            content = column.Render(row);
        else {
            switch (column.DataType) {
                case ColumnDataType.FLOAT:
                    {
                        if (row[column.Name] != null)
                            content = options.locale.FormatNumber(row[column.Name]);
                    }
                    break;
                case ColumnDataType.INT:
                    {
                        if (row[column.Name] != null)
                            content = options.locale.FormatNumber(row[column.Name], 0, 0);
                    }
                    break;
                case ColumnDataType.DATE:
                    {
                        content = formatDate(row[column.Name], (column.Format != null) ? column.Format : options.locale.ShortDatePattern());
                    }
                    break;
                case ColumnDataType.DATE_TIME:
                case ColumnDataType.DATE_TIME_UTC: //CHECKME!!!
                    {
                        content = formatDate(row[column.Name], (column.Format != null) ? column.Format : options.locale.ShortDateTimePattern());
                    }
                    break;
                case ColumnDataType.BOOLEAN:
                    {
                        if (row[column.Name] === null)
                            content = <IndeterminateCheckBoxOutlinedIcon />
                        else
                            content = row[column.Name] === true ? <CheckBoxOutlinedIcon /> : <CheckBoxOutlineBlank />;
                    }
                    break;
                case ColumnDataType.ENUM:
                case ColumnDataType.ENUM_AUTOCOMPLETE:
                    {
                        if (column.DataSource == null)
                            content = row[column.Name];
                        else {
                            var dataDS: DSResult | null = null;
                            var data: any[] = [];
                            if (typeof column.DataSource == 'function') {
                                dataDS = column.DataSource();
                                //CHECKME!!! temporary, till the paging is not supported
                                if (dataDS != null)
                                    data = dataDS.items;
                            }
                            else if (Array.isArray(column.DataSource))
                                data = column.DataSource;

                            if (data == null)
                                content = row[column.Name];
                            else
                                content = data[row[column.Name]];
                        }
                    }
                    break;
                case ColumnDataType.FILE:
                    {
                        content = row[column.Name]; //just name if need
                    }
                    break;

                default:
                    {
                        content = row[column.Name];
                    }
                    break;
            }
        }

        if (column.TrimContent != null && typeof content === 'string') {
            return (
                <Tooltip
                    disableFocusListener
                    key={column.Name}
                    id={column.Name}
                    title={content}
                    enterDelay={300}>
                    <span>{content}</span>
                </Tooltip>
            );
        }

        return content;
    }
};