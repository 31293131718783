import * as React from 'react';
import { connect } from 'react-redux';

import * as CalculatorStore from "../../../store/CalculatorStore";
import { withRouter } from "../../../components/shared/CustomRouter";
import { Paper } from "@mui/material";
import { withStyles } from '@mui/styles';

import { DataGrid, GridOptions } from "../../../framework/components/Table";
import { ColumnModel } from "../../../framework/components/Table/Common";

import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import CheckBoxOutlineBlank from '@mui/icons-material/CheckBoxOutlineBlank';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';

import CalculatorService from "../../../services/CalculatorService";
import CommonService from "../../../services/CommonService";
import SiteHelper from "../../../shared/SiteHelper";
import { CouponType } from "../../../components/Definitions";

import { DataSourceChangeType, BondIssueStatus } from '../../../framework/components/Common/Definitions';
import { AggregateFunctions, ColumnDataType, ColumnSortDirection, TrimContentType, ActionType } from '../../../framework/components/Table/Common/Models/Column';

import Localization from '../../../components/Localization';
import Globals from '../../../Globals';
import BondInfoDialog from "../../../components/shared/BondInfoDialog";
import { withTranslation } from "react-i18next";

import { getCouponTypes } from "@Queries";

const styles = theme => ({
    container: {
        //display: 'flex',
        //flexDirection: 'column',
        padding: '0.5rem 1rem 0 1rem',
        //flex: '1 1 auto',
        height: '100%'
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    statusButton: {
        padding: '0'
    }
});


interface IProps {
    classes: any;
    t: any;
}

interface IState {

    showInfo: any;
    objectId: any;
    actualDate: any;
}


class BondsPage extends React.Component<IProps, IState> {

    constructor(props: any) {
        super(props);
        this.state = {
            showInfo: false,
            objectId: null,
            actualDate: null
        };
    }

    grid = null;

    componentDidMount() {

        (async () => {

            var state = Globals.state.bonds;
            if (state != null)
                await this.grid.SetFiltersState(state);

            var products = await CommonService.getProducts();
            if (products != null) {

                this.productDataSource = {
                    items: [{ key: null, value: this.props.t('Undefined') }],
                    count: 0
                }

                this.productDataSource.count = products.count;

                if (products.items != null)
                    this.productDataSource.items = this.productDataSource.items.concat(products.items.map((item: any) => ({ key: item.id, value: item.name })));
            }

        })();
    }

    productDataSource = {
        items: [{ key: null, value: this.props.t('Undefined') }],
        count: 0
    };

    dataSourceQuery = async (request: any) => {

        console.log('request', request)
        //save actual state
        var columnsState = SiteHelper.CreateFilterState(request);
        Globals.setState('bonds', columnsState);

        var filter = SiteHelper.CreateFilter(request);

        console.log('filter', filter)
        var result = await CalculatorService.getBonds(filter);
        if (result.hasErrors) {
            throw ('Error while processing request');
        }

        //IGridPayload
        return {
            totalCount: result.value.count,
            payload: result.value.items
        };
    }

    dataSourceUpdate = async (type: DataSourceChangeType, data: any) => {
        if (data == null)
            return false;

        var result = null;
        switch (type) {
            case DataSourceChangeType.CREATE:
                {
                    data.visibility = data.visibility.map(x => {
                        return { accessAreaTypeID: x };
                    });

                    result = await CalculatorService.createBond(data);
                }
                break;
            case DataSourceChangeType.UPDATE:
                {
                    //form update structure
                    var toUpdate = new Object();
                    var keyField = "id";

                    if (data.changedFields != null) {
                        (toUpdate as any)[keyField] = data[keyField];
                        data.changedFields.forEach((item: string) => {
                            (toUpdate as any)[item] = { Value: data[item] instanceof Date ? Localization.FormatDateISO(data[item]) : data[item] };
                        });
                    }
                    else {
                        //update all?
                        //Object.keys(data).forEach((key) => {
                        //(toUpdate as any)[key] = { Value: data[key] };
                        //});
                        return data;
                    }

                    if (toUpdate.visibility != null && Array.isArray(toUpdate.visibility.Value)) {
                        toUpdate.visibility.Value = toUpdate.visibility.Value.map(x => {
                            return { accessAreaTypeID: x };
                        });
                    }

                    result = await CalculatorService.updateBond(toUpdate);
                }
                break;
            case DataSourceChangeType.REMOVE:
                {
                    result = await CalculatorService.removeBond(data.id);
                }
                break;
        }

        if (result.hasErrors) {
            //throw ('Error while processing request');
            return false;
        }

        return result.value;
    };

    renderProductCell = (row: any) => {
        return row.productName;
    };

    renderOriginatorCell = (row: any) => {
        return row.originatorName;
    }

    renderAreaCell = (row: any) => {
        return 'TEST!'; //row.originatorName;
    }

    renderCouponTypeCell = (row: any) => {
        return ""; //CHECKME!!!
    }

    productQuery = async (value?: string, index?: number, pageSize?: number) => {
        return this.productDataSource;
    };

    originatorQuery = async (value?: string, existId?: number, index?: number, pageSize?: number) => {

        var originatorsDataSource = {
            items: [{ key: null, value: this.props.t('Undefined') }],
            count: 0
        }

        var originators = await CalculatorService.getOriginatorsQuery(value, existId, index, pageSize);

        if (originators.hasErrors) {
            return originatorsDataSource;
        }

        originatorsDataSource.count = originators.value.count;
        originatorsDataSource.items = originatorsDataSource.items.concat(originators.value.items.map((item) => {
            return { key: item.id, value: item.name }
        }));

        return originatorsDataSource;
    };

    couponQuery = async (value?: string, index?: number, pageSize?: number) => {

        return getCouponTypes();

        /*
        var couponDataSource = {
            items: [
                //{ key: null, value: this.props.t('Undefined') },
                { key: CouponType.TYPE_FIXED, value: this.props.t('FixedCoupon') },
                { key: CouponType.TYPE_FLOAT, value: this.props.t('FloatCoupon') },
                { key: CouponType.TYPE_KEYRATE_AND_PREMIUM, value: this.props.t('KeyRateAndPremium') }
                //{ key: CouponType.TYPE_AHML, value: this.props.t('AHMLCoupon') },
                //{ key: CouponType.TYPE_THIRD, value: this.props.t('ThirdCoupon') },
                //{ key: CouponType.TYPE_LOSSES, value: this.props.t('LossesCoupon') }
            ],
            count: 6
        }

        return couponDataSource;
        */
    };

    manAccCoeffQuery = async (value?: string, existId?: number, index?: number, pageSize?: number) => {

        var dataSource = {
            items: [
                { key: null, value: this.props.t('Undefined') },
                { key: 0.0005, value: this.props.t('IssueAmountBigger30') },
                { key: 0.0007, value: this.props.t('IssueAmountBetween15And30') },
                { key: 0.0009, value: this.props.t('IssueAmountLesser15') }
            ],
            count: 4
        }

        if (value != undefined) {
            var data = dataSource.items.filter((item: any) => item.key == null || item.key.toString().includes(value));
            dataSource.items = data;
            dataSource.count = data.length;
        }

        return dataSource;
    };

    couponPeriodQuery = async (value?: string, index?: number, pageSize?: number) => {

        var periodDataSource = {
            items: [
                { key: null, value: this.props.t('Undefined') },
                { key: 1, value: '1' },
                { key: 3, value: '3' },
                { key: 6, value: '6' }
            ],
            count: 4
        }

        return periodDataSource;
    };

    swapTypeQuery = async (value?: string, index?: number, pageSize?: number) => {

        var periodDataSource = {
            items: [
                { key: null, value: this.props.t('Undefined') },
                { key: 1, value: this.props.t('MonthlyPayments') },
                { key: 2, value: this.props.t('NettsPerCouponPeriod') },
            ],
            count: 3
        }

        return periodDataSource;
    };

    async areaVisibilityQuery(value?: string, existId?: number, index?: number, pageSize?: number) {

        var areaDataSource = {
            items: [],
            count: 0
        }

        var areas = await CalculatorService.getAreasQuery(value, this.id, existId, index, pageSize);

        if (areas.hasErrors) {
            return areaDataSource;
        }

        areaDataSource.count = areas.value.count;
        areaDataSource.items = areas.value.items.map((item) => {
            return { key: item.id, value: item.name/*, isSelected: item.isSelected*/ }
        });

        return areaDataSource;
    };

    bondStatuses = async (value?: string, index?: number, pageSize?: number) => {
        //need for change to humanReadableBondStatus
        var bondStatusDataSource = {
            items: [
                //{ key: 0, value: this.props.t('Undefined') },
                { key: 1, value: this.props.t('NotIssued') },
                { key: 2, value: this.props.t('PreparingToIssue') },
                { key: 3, value: this.props.t('Issued') },
                { key: 4, value: this.props.t('Redeemed') },
            ],
            count: 4
        }

        return bondStatusDataSource;
    };

    handleRowDoubleClick = (row) => {
        this.setState({ showInfo: true, objectId: row['id'] });
    };

    handleInfoDialogClose = (event) => {
        this.setState({ showInfo: false });
    };

    renderStatusCell = (row: any) => {

        //const { classes } = this.props;
        switch (row.bondStatus) {
            case BondIssueStatus.NOT_ISSUED:
                {
                    return (<CheckBoxOutlineBlank color="primary" />)
                }
            case BondIssueStatus.ISSUED:
                {
                    return (<CheckBoxOutlinedIcon color="primary" />)
                }
            default:
                {
                    return (<IndeterminateCheckBoxOutlinedIcon color="primary" />)
                }
        }
    }

    columns = [
        new ColumnModel('id', { Label: this.props.t('OtherNonRecurringExpensesID'), DataType: ColumnDataType.INT, IsKey: true, Visible: false, DefaultValue: 0 }),

        new ColumnModel('isDomRF', { Label: this.props.t('IsDomRF'), Tooltip: this.props.t('IsDomRF_Info'), DataType: ColumnDataType.BOOLEAN, Visible: false, DefaultValue: true }),
        new ColumnModel('bondStatus', { Label: this.props.t('BondStatus'), Tooltip: this.props.t('BondStatus_Info'), DataType: ColumnDataType.ENUM, DataSource: this.bondStatuses, Render: this.renderStatusCell, DefaultValue: 1 /*NotIssued*/ }),
        new ColumnModel('isRegular', { Label: this.props.t('IsRegular'), Tooltip: this.props.t('IsRegular_Info'), DataType: ColumnDataType.BOOLEAN, Visible: false, DefaultValue: true }),
        new ColumnModel('createdDate', { Label: this.props.t('CreatedDate'), Tooltip: this.props.t('BondCreatedDate_Info'), DataType: ColumnDataType.DATE, Visible: false, VisibleInEdit: false, DefaultValue: Localization.FormatDateISO(new Date()) }),
        new ColumnModel('regNumber', { Label: this.props.t('RegistrationNumber'), Tooltip: this.props.t('RegistrationNumber_Info'), DataType: ColumnDataType.STRING, Visible: false }),
        new ColumnModel('ticker', { Label: this.props.t('Ticker'), Tooltip: this.props.t('Ticker_Info'), DataType: ColumnDataType.STRING, Visible: false }),
        new ColumnModel('isin', { Label: this.props.t('ISIN'), Tooltip: this.props.t('ISIN_Info'), DataType: ColumnDataType.STRING, Searchable: true, Filterable: true, Sortable: true }),
        new ColumnModel('name', { Label: this.props.t('Name'), Tooltip: this.props.t('BondName_Info'), DataType: ColumnDataType.STRING, Searchable: true, Filterable: true, Sortable: true }),
        //new ColumnModel('bloombergTicker', { Label: this.props.t('BloombergTicker'), Tooltip: this.props.t('BloombergTicker_Info'), DataType: ColumnDataType.STRING, Visible: false }),
        new ColumnModel('originatorId', { Label: this.props.t('Originator'), Tooltip: this.props.t('Originator_Info'), DataType: ColumnDataType.ENUM_AUTOCOMPLETE, Render: this.renderOriginatorCell, DataSource: this.originatorQuery, Visible: false }),
        //new ColumnModel('infospaceName1', { Label: this.props.t('InfospaceName1'), Tooltip: this.props.t('InfospaceName1_Info'), DataType: ColumnDataType.STRING, Visible: false }),
        //new ColumnModel('infospaceName2', { Label: this.props.t('InfospaceName2'), Tooltip: this.props.t('InfospaceName2_Info'), DataType: ColumnDataType.STRING, Visible: false }),
        new ColumnModel('correctReportsStartDate', { Label: this.props.t('CorrectReportsStartDate'), Tooltip: this.props.t('CorrectReportsStartDate_Info'), DataType: ColumnDataType.DATE, Visible: false }),
        //new ColumnModel('fromServiceOut', { Label: this.props.t('FromServiceOut'), Tooltip: this.props.t('FromServiceOut_Info'), DataType: ColumnDataType.BOOLEAN, Visible: false }),

        new ColumnModel('inclusionDebtDate', { Label: this.props.t('InclusionDebtDate'), Tooltip: this.props.t('InclusionDebtDate_Info'), DataType: ColumnDataType.DATE, Visible: false }),
        new ColumnModel('inclusionDate', { Label: this.props.t('InclusionDate'), Tooltip: this.props.t('InclusionDate_Info'), DataType: ColumnDataType.DATE, Visible: false }),
        new ColumnModel('fixationDate', { Label: this.props.t('FixationDate'), Tooltip: this.props.t('FixationDate_Info'), DataType: ColumnDataType.DATE, Visible: false }),
        new ColumnModel('actDeliveryDate', { Label: this.props.t('DateOfAcceptanceCertificate'), Tooltip: this.props.t('DateOfAcceptanceCertificate_Info'), DataType: ColumnDataType.DATE, Visible: false }),
        new ColumnModel('issueDate', { Label: this.props.t('IssueDate'), Tooltip: this.props.t('BondIssueDate_Info'), DataType: ColumnDataType.DATE, Searchable: true, Filterable: true, Sortable: true, SortDirection: ColumnSortDirection.DESCENDING, SortOrder: 1 }),
        new ColumnModel('secondPeriodStartDate', { Label: this.props.t('SecondPeriodStartDate'), Tooltip: this.props.t('SecondPeriodStartDate_tooltip'), DataType: ColumnDataType.DATE, Visible: false }),
        new ColumnModel('firstCouponDate', { Label: this.props.t('FirstCouponDate'), Tooltip: this.props.t('FirstCouponDate_tooltip'), DataType: ColumnDataType.DATE, Visible: false }),
        new ColumnModel('maturityDate', { Label: this.props.t('LegalMaturityDate'), Tooltip: this.props.t('LegalMaturityDate_Info'), DataType: ColumnDataType.DATE, Visible: false }),
        new ColumnModel('actualRedemptionDate', { Label: this.props.t('ActualRedemptionDate'), Tooltip: this.props.t('ActualRedemptionDate_Info'), DataType: ColumnDataType.DATE, Visible: false }),


        new ColumnModel('fixationWAC', { Label: this.props.t('FixationWAC'), Tooltip: this.props.t('FixationWAC_Info'), DataType: ColumnDataType.FLOAT, Visible: false }),
        new ColumnModel('fixationWALA', { Label: this.props.t('FixationWALA'), Tooltip: this.props.t('FixationWALA_Info'), DataType: ColumnDataType.FLOAT, Visible: false }),
        new ColumnModel('fixationWAM', { Label: this.props.t('FixationWAM'), Tooltip: this.props.t('FixationWAM_Info'), DataType: ColumnDataType.FLOAT, Visible: false }),
        new ColumnModel('fixationAdjustedWAM', { Label: this.props.t('FixationAdjustedWAM'), Tooltip: this.props.t('FixationAdjustedWAM_Info'), DataType: ColumnDataType.FLOAT, Visible: false }),

        new ColumnModel('actDeliveryDebt', { Label: this.props.t('PrincipalDutyUnderAnActOfAcceptance'), Tooltip: this.props.t('PrincipalDutyUnderAnActOfAcceptance_Info'), DataType: ColumnDataType.FLOAT, Visible: false }),
        new ColumnModel('actDeliveryYield', { Label: this.props.t('YieldOfTheActOfAcceptance'), Tooltip: this.props.t('YieldOfTheActOfAcceptance_Info'), DataType: ColumnDataType.FLOAT, Visible: false }),
        new ColumnModel('actDeliveryPremium', { Label: this.props.t('ActDeliveryPremium'), Tooltip: this.props.t('ActDeliveryPremium_Info'), DataType: ColumnDataType.FLOAT, Visible: false }),
        //new ColumnModel('poolCredit', { Label: this.props.t('PoolCredit'), Tooltip: this.props.t('PoolCredit_Info'), DataType: ColumnDataType.BOOLEAN, Visible: false }),
        //new ColumnModel('poolCreditSum', { Label: this.props.t('PoolCreditSum'), Tooltip: this.props.t('PoolCreditSum_Info'), DataType: ColumnDataType.FLOAT, Visible: false }),
        //new ColumnModel('poolCreditRate', { Label: this.props.t('PoolCreditRate'), Tooltip: this.props.t('PoolCreditRate_Info'), DataType: ColumnDataType.FLOAT, Visible: false }),
        new ColumnModel('couponType', { Label: this.props.t('CouponType'), Tooltip: this.props.t('CouponType_Info'), DataType: ColumnDataType.ENUM, DataSource: this.couponQuery, Visible: false, DefaultValue: CouponType.TYPE_FIXED }),
        new ColumnModel('couponPeriod', { Label: this.props.t('CouponPeriod'), Tooltip: this.props.t('CouponPeriod_Info'), DataType: ColumnDataType.ENUM, DataSource: this.couponPeriodQuery, Visible: false, DefaultValue: 0 }),
        new ColumnModel('startIssueAmount', { Label: this.props.t('StartIssueAmount'), Tooltip: this.props.t('StartIssueAmount_Info'), DataType: ColumnDataType.FLOAT, Visible: false }),
        new ColumnModel('nominal', { Label: this.props.t('StartNominal'), Tooltip: this.props.t('StartNominal_Info'), DataType: ColumnDataType.FLOAT, Visible: false }),
        new ColumnModel('couponPercentage', { Label: this.props.t('CouponPercentage'), Tooltip: this.props.t('CouponPercentage_Info'), DataType: ColumnDataType.FLOAT, Visible: false, DefaultValue: '' }),
        new ColumnModel('cleanUpCall', { Label: this.props.t('CleanUpCall'), Tooltip: this.props.t('CleanUpCall_Info'), DataType: ColumnDataType.FLOAT, Visible: false }),

        new ColumnModel('guaranteeRate', { Label: this.props.t('GuaranteeRate'), Tooltip: this.props.t('GuaranteeRate_Info'), DataType: ColumnDataType.FLOAT, Visible: false }),
        new ColumnModel('loanServRate', { Label: this.props.t('LoanServRate'), Tooltip: this.props.t('LoanServRate_Info'), DataType: ColumnDataType.FLOAT, Visible: false }),
        //new ColumnModel('loanServVAT', { Label: this.props.t('LoanServVAT'), Tooltip: this.props.t('LoanServVAT_Info'), DataType: ColumnDataType.BOOLEAN, Visible: false }),
        new ColumnModel('loanServMonthly', { Label: this.props.t('LoanServMonthly'), Tooltip: this.props.t('LoanServMonthly_Info'), DataType: ColumnDataType.BOOLEAN, Visible: false }),
        new ColumnModel('resLoanServRate', { Label: this.props.t('ResLoanServRate'), Tooltip: this.props.t('ResLoanServRate_Info'), DataType: ColumnDataType.FLOAT, Visible: false }),
        //new ColumnModel('resLoanServVAT', { Label: this.props.t('ResLoanServVAT'), Tooltip: this.props.t('ResLoanServVAT_Info'), DataType: ColumnDataType.BOOLEAN, Visible: false }),

        new ColumnModel('specDepRate', { Label: this.props.t('SpecDepRate'), Tooltip: this.props.t('SpecDepRate_Info'), DataType: ColumnDataType.FLOAT, Visible: false }),
        new ColumnModel('specDepMin', { Label: this.props.t('SpecDepMin'), Tooltip: this.props.t('SpecDepMin_Info'), DataType: ColumnDataType.FLOAT, Visible: false }),
        new ColumnModel('specDepFixed', { Label: this.props.t('SpecDepFixed'), Tooltip: this.props.t('SpecDepFixed_Info'), DataType: ColumnDataType.FLOAT, Visible: false }),
        new ColumnModel('insuranceRate', { Label: this.props.t('InsuranceRate'), Tooltip: this.props.t('InsuranceRate_Info'), DataType: ColumnDataType.FLOAT, Visible: false }),
        new ColumnModel('manAccFloating', { Label: this.props.t('ManAccFloating'), Tooltip: this.props.t('ManAccFloating_Info'), DataType: ColumnDataType.BOOLEAN, Visible: false, DefaultValue: true }),
        new ColumnModel('manAccCoef', { Label: this.props.t('ManAccCoef'), Tooltip: this.props.t('ManAccCoef_Info'), DataType: ColumnDataType.ENUM_AUTOCOMPLETE, ForceSelection: false, Visible: false, DataSource: this.manAccCoeffQuery }),
        new ColumnModel('manAccFixedSum', { Label: this.props.t('ManAccFixedSum'), Tooltip: this.props.t('ManAccFixedSum_Info'), DataType: ColumnDataType.FLOAT, Visible: false }),
        new ColumnModel('settlementAgentFirst', { Label: this.props.t('SettlementAgentFirst'), Tooltip: this.props.t('SettlementAgentFirst_Info'), DataType: ColumnDataType.FLOAT, Visible: false }),
        new ColumnModel('settlementAgentYearly', { Label: this.props.t('SettlementAgentYearly'), Tooltip: this.props.t('SettlementAgentYearly_Info'), DataType: ColumnDataType.FLOAT, Visible: false }),


        new ColumnModel('comissionMOEXFirst', { Label: this.props.t('ComissionMOEXFirst'), Tooltip: this.props.t('ComissionMOEXFirst_Info'), DataType: ColumnDataType.FLOAT, Visible: false }),
        new ColumnModel('comissionMOEXYearly', { Label: this.props.t('ComissionMOEXYearly'), Tooltip: this.props.t('ComissionMOEXYearly_Info'), DataType: ColumnDataType.FLOAT, Visible: false }),
        new ColumnModel('comissionNSDFirst', { Label: this.props.t('ComissionNSDFirst'), Tooltip: this.props.t('ComissionNSDFirst_Info'), DataType: ColumnDataType.FLOAT, Visible: false }),
        new ColumnModel('comissionNSDQuarter', { Label: this.props.t('ComissionNSDQuarter'), Tooltip: this.props.t('ComissionNSDQuarter_Info'), DataType: ColumnDataType.FLOAT, Visible: false }),
        new ColumnModel('underwriterFirst', { Label: this.props.t('UnderwriterFirst'), Tooltip: this.props.t('UnderwriterFirst_Info'), DataType: ColumnDataType.FLOAT, Visible: false }),
        new ColumnModel('marketMakerMonth', { Label: this.props.t('MarketMakerMonth'), Tooltip: this.props.t('MarketMakerMonth_Info'), DataType: ColumnDataType.FLOAT, Visible: false }),
        new ColumnModel('bankAccountPaymentsMonth', { Label: this.props.t('BankAccountPaymentsMonth'), Tooltip: this.props.t('BankAccountPaymentsMonth_Info'), DataType: ColumnDataType.FLOAT, Visible: false }),
        new ColumnModel('bankAccountIssuerAndAgentMonth', { Label: this.props.t('BankAccountIssuerAndAgentMonth'), Tooltip: this.props.t('BankAccountIssuerAndAgentMonth_Info'), DataType: ColumnDataType.FLOAT, Visible: false }),

        //new ColumnModel('insuranceFraction', { Label: this.props.t('InsuranceFraction'), Tooltip: this.props.t('InsuranceFraction_Info'), DataType: ColumnDataType.FLOAT, Visible: false }),

        //new ColumnModel('otherNonRecurringExpenses', { Label: this.props.t('OtherNonRecurringExpenses'), Tooltip: this.props.t('OtherNonRecurringExpenses_Info'), DataType: ColumnDataType.FLOAT, Visible: false }),
        //new ColumnModel('otherConstantExpenses', { Label: this.props.t('OtherConstantExpenses'), Tooltip: this.props.t('OtherConstantExpenses_Info'), DataType: ColumnDataType.FLOAT, Visible: false }),
        new ColumnModel('swapMA', { Label: this.props.t('SwapMA'), Tooltip: this.props.t('SwapMA_Info'), DataType: ColumnDataType.BOOLEAN, Visible: false }),
        new ColumnModel('swapORIG', { Label: this.props.t('SwapORIG'), Tooltip: this.props.t('SwapORIG_Info'), DataType: ColumnDataType.BOOLEAN, Visible: false }),
        new ColumnModel('swapType', { Label: this.props.t('SwapType'), Tooltip: this.props.t('SwapType_Info'), DataType: ColumnDataType.ENUM, Visible: false, DataSource: this.swapTypeQuery }),
        //new ColumnModel('fixDayMA', { Label: this.props.t('FixDayMA'), Tooltip: this.props.t('FixDayMA_Info'), DataType: ColumnDataType.INT, Visible: false }),
        //new ColumnModel('floatDayMA', { Label: this.props.t('FloatDayMA'), Tooltip: this.props.t('FloatDayMA_Info'), DataType: ColumnDataType.INT, Visible: false }),
        //new ColumnModel('fixDayORIG', { Label: this.props.t('FixDayORIG'), Tooltip: this.props.t('FixDayORIG_Info'), DataType: ColumnDataType.INT, Visible: false }),
        //new ColumnModel('floatDayOrig', { Label: this.props.t('FloatDayOrig'), Tooltip: this.props.t('FloatDayOrig_Info'), DataType: ColumnDataType.INT, Visible: false }),

        //new ColumnModel('description', { Label: this.props.t('Description'), Tooltip: this.props.t('Description_Info'), DataType: ColumnDataType.STRING, Searchable: true, Filterable: true, Sortable: true, TrimContent: TrimContentType.ELLIPSIS, Visible: false }),

        new ColumnModel('productId', { Label: this.props.t('Product'), Tooltip: this.props.t('Product_Info'), DataType: ColumnDataType.ENUM, DataSource: this.productQuery, Visible: false }),

        new ColumnModel('placementCPR', { Label: this.props.t('PlacementCPR'), Tooltip: this.props.t('PlacementCPR_Info'), DataType: ColumnDataType.FLOAT, Visible: false }),
        new ColumnModel('placementCDR', { Label: this.props.t('PlacementCDR'), Tooltip: this.props.t('PlacementCDR_Info'), DataType: ColumnDataType.FLOAT, Visible: false }),


        new ColumnModel('lockPlacementPrice', { Label: this.props.t('LockPlacementPrice'), Tooltip: this.props.t('LockPlacementPrice_Info'), DataType: ColumnDataType.BOOLEAN, Visible: false }),
        new ColumnModel('lockPlacementCoupon', { Label: this.props.t('LockPlacementCoupon'), Tooltip: this.props.t('LockPlacementCoupon_Info'), DataType: ColumnDataType.BOOLEAN, Visible: false }),
        //new ColumnModel('defaultPlacementPrice', { Label: this.props.t('DefaultPlacementPrice'), Tooltip: this.props.t('DefaultPlacementPrice_Info'), DataType: ColumnDataType.FLOAT, Visible: false }),
        new ColumnModel('usePlacementCoupon', { Label: this.props.t('UsePlacementCoupon'), Tooltip: this.props.t('UsePlacementCoupon_Info'), DataType: ColumnDataType.BOOLEAN, Visible: false }),

        new ColumnModel('useProduct', { Label: this.props.t('UseProduct'), Tooltip: this.props.t('UseProduct_Info'), DataType: ColumnDataType.BOOLEAN, Visible: false }),

        //new ColumnModel('defaultPlacementPrice', { Label: this.props.t('DefaultPlacementPrice'), Tooltip: this.props.t('DefaultPlacementPrice_Info'), DataType: ColumnDataType.FLOAT, Visible: false }),


        new ColumnModel('firstCouponExpensesIssueDoc', { Label: this.props.t('FirstCouponExpensesIssueDoc'), Tooltip: this.props.t('FirstCouponExpensesIssueDoc'), DataType: ColumnDataType.FLOAT, Visible: false }),
        new ColumnModel('otherCouponsExpensesIssueDoc', { Label: this.props.t('OtherCouponsExpensesIssueDoc'), Tooltip: this.props.t('OtherCouponsExpensesIssueDoc'), DataType: ColumnDataType.FLOAT, Visible: false }),
        new ColumnModel('specDepRateIssueDoc', { Label: this.props.t('SpecDepRateIssueDoc'), Tooltip: this.props.t('SpecDepRateIssueDoc'), DataType: ColumnDataType.FLOAT, Visible: false }),
        new ColumnModel('specDepMinMonthIssueDoc', { Label: this.props.t('SpecDepMinMonthIssueDoc'), Tooltip: this.props.t('SpecDepMinMonthIssueDoc'), DataType: ColumnDataType.FLOAT, Visible: false }),
        new ColumnModel('specDepCompensationMonthIssueDoc', { Label: this.props.t('SpecDepCompensationMonthIssueDoc'), Tooltip: this.props.t('SpecDepCompensationMonthIssueDoc'), DataType: ColumnDataType.FLOAT, Visible: false }),
        new ColumnModel('partialPaymentAllowedAnytime', { Label: this.props.t('PartialPaymentAllowedAnytime'), Tooltip: this.props.t('PartialPaymentAllowedAnytime'), DataType: ColumnDataType.BOOLEAN, Visible: false }),

        //new ColumnModel('maxCouponRate', { Label: this.props.t('MaxCouponRate'), Tooltip: this.props.t('MaxCouponRate_Info'), DataType: ColumnDataType.FLOAT, Visible: false }),
        new ColumnModel('visibility', { Label: this.props.t('VisibilityAreas'), Tooltip: this.props.t('VisibilityAreas_Info'), DataType: ColumnDataType.ENUM_AUTOCOMPLETE_MULTIPLE, ForceSelection: false, Render: this.renderAreaCell, DataSource: this.areaVisibilityQuery, Visible: false }),
        new ColumnModel('isShared', { Label: this.props.t('VisibleToAll'), Tooltip: this.props.t('BondVisibleToAll_Info'), DataType: ColumnDataType.BOOLEAN, Visible: false, DefaultValue: true }),
        new ColumnModel('isValid', { Label: this.props.t('Valid'), Tooltip: this.props.t('BondValid_Info'), DataType: ColumnDataType.BOOLEAN, Visible: false }),


        //for admins only
        ...(Globals.hasAccess(Globals.Administrator_Role) ? [
            new ColumnModel('', {
                Actions: [{ Type: ActionType.TYPE_ADD, Name: this.props.t('AddNewMS') }, { Type: ActionType.TYPE_REMOVE }, { Type: ActionType.TYPE_EDIT }],
                Label: this.props.t('Actions'),
                IsAction: true
            })
        ] : []
        )
    ];

    settings = { query: this.dataSourceQuery, update: this.dataSourceUpdate };

    gridOptions = new GridOptions({
        topPager: false,
        fixedHeader: false,
        fullHeight: true,
        fullWidth: false,
        floatingToolbar: true,
        canSearch: true,

        confirmTitle: this.props.t('AreYouSureQuestion'),
        confirmDescription: this.props.t('RemoveBondDescription')
    });

    render() {

        const { t } = this.props;

        return (
            <div className={this.props.classes.container}>
                <BondInfoDialog show={this.state.showInfo} objectId={this.state.objectId} onClose={this.handleInfoDialogClose} />
                <DataGrid
                    ref={x => this.grid = x}
                    gridName={t('Bonds')}
                    dataSource={this.settings}
                    columns={this.columns}
                    gridOptions={this.gridOptions}
                    onRowDoubleClick={this.handleRowDoubleClick}
                />
            </div>
        );
    }
}

var component = connect(
    state => (state as any).calculator, // Selects which state properties are merged into the component's props
    CalculatorStore.actionCreators // Selects which action creators are merged into the component's props
)(BondsPage);

export default withRouter(withStyles(styles)(withTranslation('translations')(component)));