export default class Result {
    value;
    stauts;
    errors;

    get hasErrors() {
        return this.errors != null && Array.isArray(this.errors) && this.errors.length > 0;
    }

    get isDenied() {
        return this.status == 401;
    }

    constructor(value, status, ...errors) {
        this.value = value;
        this.status = status;
        this.errors = errors[0] == undefined || errors[0] == null ? [] : errors;
    }
}