import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography';
import * as React from 'react';

const styles = () => createStyles({
    errorBoundBody: {
        color: 'red',
        marginBottom: '30px',
    },
});

class ErrorBoundaryComponent extends React.Component<WithStyles<typeof styles>> {
    public state = {
        error: null,
        errorData: null,
        errorInfo: null,
    };

    public componentDidCatch(error: any, errorInfo: any) {
        this.setState({
            error,
            errorInfo,
        });
    }

    public render() {
        if (this.state.errorInfo) {
            const { classes } = this.props;

            return (
                <React.Fragment>
                    <Typography className={classes.errorBoundBody} variant='h4'>
                        Something went terribly wrong...
                    </Typography>
                    <Typography variant='h5'>
                        Please, refresh the page.
                    </Typography>
                </React.Fragment>
            );
        }

        // eslint-disable-next-line react/prop-types
        return this.props.children;
    }
}

export const ErrorBoundary = withStyles(styles)(ErrorBoundaryComponent);
