import { ScreenRotation } from '@mui/icons-material'
import { Paper } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

function RotateLocker() {
    const {t} = useTranslation()

  return (
    <Paper //перекрыть вывод данных при не правильной ориентации устройства
                sx={{
                        // display: this.state.mobileLocker==false?'none':'flex',
                        display:'flex',
                        flexDirection:'column',
                        position:'fixed', 
                        m:0, 
                        top:0, 
                        bottom:0,
                        // height:'100px',
                        left:0,
                        right:0, 
                        backgroundColor:'unset',
                        // height:'100%',
                        zIndex:+99999,
                        alignItems: 'center',
                        justifyContent: 'center',
                        backdropFilter: 'blur(10px)',
                    }}
            >
                <ScreenRotation sx={{
                    width:'200px',
                    height:'200px',
                    opacity:'0.8',
                    animation: 'rotateDivice 2s infinite',
                    transformOrigin: 'center',
                    '@-webkit-keyframes rotateDivice': {
                        '0%': {
                            transform: 'rotate(45deg)'
                        },
                        '100%': {
                            transform: 'rotate(-45deg)'
                        }
                        }
                    }}/> 
                    {t("TurnThePhone")}
            </Paper>
  )
}

export default RotateLocker